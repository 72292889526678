import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import ModalFieldTransport from "./ModalExtend/ModalCosechaTransporteCampoView";
import { row_fertilizer_production, row_transport_cosecha, get_permission } from "network/ApiAxios";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export default class CosechaTransporteCampoView extends React.Component {
    state={
    dataTransport:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTransport = await row_fertilizer_production(jsondata._id,gerParam,null,'transport_cosecha')
        this.setState({dataTransport:dataTransport.trazaData})
}
  render(){

  return (
    <>
        <ModalFieldTransport sendInfoProps={this.state} setParamsUrl={window.location.search} refreshData={this.initData} />
    </>
  );
 }
}

// import React, {createRef} from "react";

// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import ModalFieldTransportView from "./ModalExtend/ModalCosechaTransporteCampoView";
// import { row_fertilizer_production, row_transport_cosecha } from "network/ApiAxios";

// export default class CosechaTransporteCampoView extends React.Component {
//     state={
//     dataTransport:[],
//   }
// async componentDidMount(){
//       if(this.props.paramsCode==undefined || this.props.paramsCode==null){
//       var codigoTraza = 'nocode';
//       }else{
//         var codigoTraza = this.props.paramsCode;
//       }
//         var userdata = localStorage.getItem('user');
//         var jsondata = JSON.parse(userdata);
//         var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//         var dataTransport = await row_transport_cosecha(jsondata._id,codigoTraza,codigoTrazaTwo)
//         this.setState({dataTransport:dataTransport})
// }
//   render(){

//   return (
//     <>
//         <ModalFieldTransportView sendInfoProps={this.state} />
//     </>
//   );
//  }
// }

//export default Elements;
