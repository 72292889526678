// Autenticación
import QrMapDynamic from "views/pages/examples/QrMapDynamic.js";

import Login from "views/pages/examples/Login.js";
import Register from "views/pages/examples/Register.js";
import ViewValidate from "views/pages/examples/Validate.js";

import RegisterMRV from "views/pages/examples/RegisterMRV.js";
import RegisterCollab from "views/pages/examples/RegisterCollab.js";
import ResetPassword from "views/pages/examples/ResetPassword";
import ScreenSuccess from "views/pages/examples/SectionCheck.js";
// Iniciar trazabilidad
import StartVegFruits from "views/pages/forms/Trazability/StartVegFruits.js";
import StartSeafood from "views/pages/forms/Trazability/StartSeafood.js";
import StartProcessed from "views/pages/forms/Trazability/StartProcessed.js";
// Forms frutas y vegetales
import Comercialization from "views/pages/forms/Trazability/VegetalesYFrutas/StartComercializationForm";
import FieldTransport from "views/pages/forms/Trazability/VegetalesYFrutas/StartFieldTransportForm";
// Cosecha
// Administración
import AuthUsers from "views/pages/tables/AuthUsers.js";
import ServicePay from "views/pages/forms/ServicePay.js";
import ServiceInvoice from "views/pages/forms/ServiceInvoice.js";
import Payments from "views/pages/forms/Payment.js";
//import AuthUsers from "views/pages/forms/AuthUsers.js";
import Building from "views/pages/forms/Trazability/Building.js";
import FinalizedTrace from "views/pages/forms/Trazability/StartFinalized.js";
import Profile from "views/pages/examples/Profile.js";
import ProfileWallet from "views/pages/examples/UserWallet.js";
import ProfileCompany from "views/pages/examples/UserCompany.js";
import Timeline from "views/pages/examples/Timeline.js";
// Operaciones
import TraceView from "views/pages/tables/Trazability/TraceView.js";
import Documents from "views/pages/tables/Trazability/Documents.js";
import DocumentsMeat from "views/pages/tables/Trazability/DocumentsMeats.js";

import AddCollab from "views/pages/forms/AddCollab";
//Otros
import Calendar from "views/pages/Calendar.js";
import Dashboard from "views/pages/dashboards/HomeDashboard.js";
import ScreenTerms from "views/pages/dashboards/ScreenPrivateTerms.js";
import Categories from "views/pages/examples/Categories";
import Users from "views/pages/examples/Users";
import AddUser from "views/pages/examples/AddUser";
//Vista Trace
import VistaTrace from "views/pages/examples/Trazability/VistaTraceView";

import VistaQr from "views/pages/examples/VistaQrView";
import NotificationScreen from "views/pages/dashboards/ScreenNotifications";

import VistaListaBlockchain from "views/pages/tables/VistaListaBlockchainView";
//Carnicos
import StartMeats from "views/pages/forms/Trazability/StartMeats.js";

import VistaTraceCarne from "views/pages/examples/Trazability/VistaTraceCarnicos";

import InventoryTraza from "views/pages/tables/Trazability/InventoryTraza.js";

//Producción
import GNama from "views/pages/forms/Production/Grains/GrainNama/StartGrainNamaForm.js";

import StartGrains from "views/pages/forms/Trazability/GrainTrazaStart.js";
import VistaTraceTG from "views/pages/examples/Trazability/VistaTraceTrazaGView";

import VistaTraceNG from "views/pages/examples/Production/VistaTraceNamaGView";
import TraceViewProduction from "views/pages/tables/Production/TraceViewProduction.js";
import ViewObservationMVR from "views/pages/tables/Production/ViewObservationMVR.js";
import ViewObservationBeforeMVR from "views/pages/tables/Production/ViewObservationBeforeMVR.js";
import TableVistaBlockchainProduction from "views/pages/tables/Production/TableVistaBlockchainProduction.js";

import BuildingProduction from "views/pages/forms/Production/BuildingProduction.js";
import BuildingSatelite from "views/pages/forms/Production/BuildingSatelite.js";

import FinalizedTraceProduction from "views/pages/forms/Production/StartFinalized.js";
import ReportProductionMVR from "views/pages/forms/Production/MRV/StartListProductionMVR.js";
import ReportProductionMVRHistory from "views/pages/forms/Production/MRV/StartListProductionMVRHistory.js";

import FavProductionMVR from "views/pages/forms/Production/MRV/StartListFavProductionMVR.js";

import Contacts from "views/pages/examples/Contacts.js";

import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

const routes = [  
  {
    path: "/home",
    icon: "fas fa-home",
    name: "Inicio",
    miniName: "I",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Producción",
    icon: "fas fa-tractor",
    state: "prodCollapse",
    views: [
        {
          path: "/admin/info_producer_g",
          name: "Registrar Nueva Producción",
          miniName: "RNP",
          component: GNama,
          layout: "/admin",
          redirect: false
        },
         {
           path: "/list-trace-production",
           name: "Producción en Proceso",
           miniName: "VTAP",
           component: BuildingProduction,
           layout: "/admin",
           redirect: false
         },
         {
           path: "/list-trace-satelital",
           name: "Satélites en Proceso",
           miniName: "SEP",
           component: BuildingSatelite,
           layout: "/admin",
           redirect: false
         },
        {
          path: "/prouction-observations-mvr",
          name: "Observaciones",
          miniName: undefined,
          component: ViewObservationMVR,
          layout: "/admin",
          redirect: false
        },
        {
          path: "/view_observations_mvr",
          name: "Observaciones Anteriores",
          miniName: undefined,
          component: ViewObservationBeforeMVR,
          layout: "/admin",
          redirect: false
        },
        {
          path: "/process-production",
          name: "Vista Producción",
          miniName: "PEP",
          component: TraceViewProduction,
          layout: "/admin",
          redirect: false
        },
        {
          path: "/blockchain-production",
          name: "Blockchain",
          miniName: "BP",
          component: TableVistaBlockchainProduction,
          layout: "/admin",
          redirect: false
        },
        {
          path: "/finalized-production",
          name: "Historial",
          miniName: "FTP",
          component: FinalizedTraceProduction,
          layout: "/admin",
          redirect: false
        },
    ]
  },
  {
    collapse: true,
    name: "Productores",
    icon: "fas fa-tractor",
    state: "prodMRVCollapse",
    views: [
        {
          path: "/report-production-users",
          name: "Lista de Productores",
          miniName: "LP",
          component: ReportProductionMVR,
          layout: "/admin",
          redirect: false
        },
        {
          path: "/history-mrv",
          name: "Historial",
          miniName: "HMRV",
          component: ReportProductionMVRHistory,
          layout: "/admin",
          redirect: false
        },
        // {
        //   path: "/fav-production-users",
        //   name: "Productores Favoritos Seleccionados",
        //   miniName: "PFS",
        //   component: FavProductionMVR,
        //   layout: "/admin",
        //   redirect: false
        // },
    ]
  },
  {
    collapse: false,
    name: "Iniciar Trazabilidad",
    icon: "fas fa-map",
    state: "trazaCollapse",
    views: [

    ]
  },

  {
    collapse: true,
    name: "Trazabilidad",
    icon: "fas fa-map",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        name: "Iniciar sesión",
        miniName: "L",
        component: Login,
        layout: "/home",
        redirect: true
      },
      {
        path: "/trazabilidad_inventory",
        name: "Inventario Trazabilidad",
        miniName: "L",
        component: InventoryTraza,
        layout: "/home",
        redirect: true
      },
      {
        path: "/register",
        name: "Registro",
        miniName: "R",
        component: Register,
        layout: "/home",
        redirect: true
      },
      {
        path: "/register-mrv",
        name: "Registro MRV",
        miniName: "R",
        component: RegisterMRV,
        layout: "/home",
        redirect: true
      },
      {
        path: "/register-collab",
        name: "Registro colaborador",
        miniName: "R",
        component: RegisterCollab,
        layout: "/home",
        redirect: true
      },
        {
          path: "/vista-trace",
          name: "Home",
          miniName: "H",
          component: VistaTrace,
          layout: "/home",
          redirect: true
        },
        {
          path: "/vista-trace-admin",
          name: "Home",
          miniName: undefined,
          component: VistaTrace,
          layout: "/admin",
          redirect: true
        },
        {
          path: "/vista-trace-meat",
          name: "Vista Trace Carnicos",
          miniName: "H",
          component: VistaTraceCarne,
          layout: "/home",
          redirect: true
        },
        {
          path: "/vista-trace-grain-nama",
          name: "Home",
          miniName: "H",
          component: VistaTraceNG,
          layout: "/home",
          redirect: true
        },
        {
          path: "/vista_trace_grain_traza",
          name: "Home",
          miniName: "VTH",
          component: VistaTraceTG,
          layout: "/home",
          redirect: true
        },
        {
          path: "/vista-trace-meat-admin",
          name: "Vista Trace Carnicos Admin",
          miniName: undefined,
          component: VistaTraceCarne,
          layout: "/admin",
          redirect: true
        },
        {
          path: "/vista-qr",
          name: "Codigo",
          miniName: undefined,
          component: VistaQr,
          layout: "/admin",
          redirect: true
        },
        {
          path: "/notifications",
          name: "Notificación",
          miniName: undefined,
          component: NotificationScreen,
          layout: "/admin",
          redirect: true
        },

        /*{
          path: "/trazabilidad",
          name: "Frutas y Vegetales",
          miniName: "H",
          component: SectionTraza,
          layout: "/home",
          redirect: true
        },*/
     /*       {
        path: "/vista-trace",
        name: "Vista Trace",
        miniName: "R",
        component: VistaTrace,
        layout: "/home",
      },*/
      {
        path: "/reset-password",
        name: "Restablecer contraseña",
        miniName: "R",
        component: ResetPassword,
        layout: "/home",
        redirect: true
      },
      {
        path: "/start-trace/vegetables-fruits",
        name: "Vegetales y frutas",
        miniName: "IT",
        component: StartVegFruits,
        layout: "/admin"
      },
      {
        path: "/start-trace/meats",
        name: "Cárnicos",
        miniName: "M",
        component: StartMeats,
        layout: "/admin"
      },
      {
        path: "/info_crops_t",
        name: "Granos",
        miniName: "G",
        component: StartGrains,
        layout: "/admin"
      },
      {
        path: "/start-trace/processed",
        name: "Procesados",
        miniName: "IT",
        component: StartProcessed,
        layout: "/admin"
      },
      {
        path: "/start-trace/seafood",
        name: "Pescados y mariscos",
        miniName: "IT",
        component: StartSeafood,
        layout: "/admin"
      },
      {
        path: "/process-trace",
        name: "Trazabilidad en proceso",
        miniName: "TP",
        component: Building,
        layout: "/admin"
      },
      {
        path: "/view-trace",
        name: "Vista trace",
        miniName: "VT",
        component: TraceView,
        layout: "/admin"
      },
      {
        path: "/finalized-trace",
        name: "Historial",
        miniName: "FT",
        component: FinalizedTrace,
        layout: "/admin"
      },
      {
          path: "/vista-blockchain",
          name: "Blockchain",
          miniName: 'BC',
          component: VistaListaBlockchain,
          layout: "/admin"
        },
      {
        path: "/docs",
        name: "Documentos",
        miniName: undefined,
        component: Documents,
        layout: "/admin"
      },
      {
        path: "/validation",
        name: "Validación",
        miniName: "VA",
        component: ViewValidate,
        layout: "/home",
        redirect: true
      },
      {
        path: "/login",
        name: "Iniciar sesión",
        miniName: "L",
        component: Login,
        layout: "/home",
        redirect: true
      },
      {
        path: "/terms",
        icon: "fas fa-home",
        name: "Terminos",
        miniName: "I",
        component: ScreenTerms,
        layout: "/home",
        redirect: true
      },
      {
        path: "/reset-password",
        name: "Restablecer contraseña",
        miniName: "R",
        component: ResetPassword,
        layout: "/home",
        redirect: true
      },
      {
        path: "/qr-map-dynamic",
        name: "Ver Qr",
        miniName: "R",
        component: QrMapDynamic,
        layout: "/home",
        redirect: true
      },
    ]
  },
  {
    path: "/home",
    icon: "fas fa-store",
    name: "Comercialización",
    miniName: "CMM",
    component: Dashboard,
    layout: "/admin"
  },
  // {
  //   collapse: true,
  //   name: "Operaciones",
  //   icon: "fas fa-folder-open",
  //   state: "operatCollapse",
  //   views: [
  //     {
  //       path: "/repots-operation",
  //       name: "Reportes",
  //       miniName: "R",
  //       component: Dashboard,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/reports-cicle",
  //       name: "Ciclo Fenologico",
  //       miniName: "CF",
  //       component: Dashboard,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/reports-img",
  //       name: "Imagenes",
  //       miniName: "IMG",
  //       component: Dashboard,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/docs",
  //       name: "Documentos",
  //       miniName: undefined,
  //       component: Documents,
  //       layout: "/admin"
  //     },
  //   ]
  // },
  {
    collapse: true,
    name: "Usuarios",
    icon: "fas fa-users",
    state: "usersCollapse",
    views: [
      {
        path: "/add-collaborator",
        name: "Agregar Usuario",
        miniName: "AC",
        component: AddCollab,
        layout: "/admin"
      },
      {
        path: "/users",
        name: "Usuarios autorizados",
        miniName: "U",
        component: AuthUsers,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    name: "Administración",
    icon: "fas fa-marker",
    state: "formsCollapse",
    views: [
      {
        path: "/adm-profile",
        name: "Mi Perfil",
        miniName: "PRL",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/adm-wallet",
        name: "Mi Billetera",
        miniName: "WLT",
        component: ProfileWallet,
        layout: "/admin",
      },
      {
        path: "/services-invoice",
        name: "Servicio Contratado",
        miniName: "SI",
        component: ServiceInvoice,
        layout: "/admin",
      },
      {
        path: "/services-pay",
        name: "Comprar Servicios",
        miniName: "P",
        component: ServicePay,
        layout: "/admin",
      },
      {
        path: "/payment",
        name: "Efectuar pago",
        miniName: undefined,
        component: Payments,
        layout: "/admin",
      },
      {
        path: "/success",
        name: "Efectuado",
        miniName: undefined,
        component: ScreenSuccess,
        layout: "/admin",
      }      
    ]
  },
  {
    collapse: true,
    name: "Capacitación",
    icon: "fas fa-book",
    state: "capadCollapse",
    views: [
        {
          path: "/user-manual",
          name: "Manual del Usuario",
          miniName: "MDU",
          component: Dashboard,
          layout: "/admin"
        },
        {
          path: "/questions",
          name: "Preguntas Frecuentes",
          miniName: "PF",
          component: Dashboard,
          layout: "/admin"
        },
    ]
  },
  {
    path: "/send-mail",
    icon: "fas fa-sms",
    name: "Contactenos",
    miniName: "CTS",
    component: Contacts,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-circle-08 text-green",
    component: Profile,
    layout: "/admin",
    api: true,
    access: ["member", "admin", "creator"]
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: "ni ni-circle-08 text-green",
    component: ProfileWallet,
    layout: "/admin",
    api: true,
    access: ["member", "admin", "creator"]
  },
    {
    path: "/company",
    name: "Wallet",
    icon: "ni ni-circle-08 text-green",
    component: ProfileCompany,
    layout: "/admin",
    api: true,
    access: ["member", "admin", "creator"]
  },
  {
    path: "/timeline",
    name: "Timeline",
    icon: "ni ni-bullet-list-67 text-dafault",
    component: Timeline,
    layout: "/admin",
    api: true,
    access: ["member", "admin", "creator"]
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin",
    api: true,
    access: ["member", "admin", "creator"]
  },
  {
    path: "/add-user",
    name: "Add User",
    icon: "ni ni-fat-add text-orange",
    component: AddUser,
    layout: "/admin",
    api: true,
    access: ["admin"]
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-tag text-primary",
    component: Categories,
    layout: "/admin",
    api: true,
    access: ["admin", "creator"]
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-badge text-info",
    component: Users,
    layout: "/admin",
    api: true,
    access: ["admin"]
  },
];

export default routes;
