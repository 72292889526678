import { Link } from "react-router-dom";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import logo from "../../assets/img/brand/favicon.png";

function IndexHeader() {
  return (
    <>
      <div className="header bg-traza-blue pt-5 pb-7">
        <Container>
          <div className="header-body">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="pr-5">
                  <h1 className="display-2 text-white font-weight-bold mb-0">
                    Foodchain
                  </h1>
                  <h2 className="display-4 text-white font-weight-light">
                    {/*Una plataforma donde puedes realizar trazabilidad de distintos productos.*/}
                    Foodchain conecta el ciclo de producción de alimentos, desde el productor hasta el comprador mediante una plataforma digital eficiente confiable y transparente.
                  </h2>
                  <div className="mt-5">
                    <Button
                      className="btn-white my-2"
                      color="traza-green"
                      to="/admin/home"
                      tag={Link}
                    >
                      Entrar
                    </Button>
                    <Button
                      className="my-2"
                      color="traza-gray"
                      to="/home/register"
                      tag={Link}
                    >
                      ¿No tienes una cuenta?
                    </Button>
                    {/*<Button
                                          className="my-2"
                                          color="traza-gray"
                                          to="/home/vista-trace"
                                          tag={Link}
                                        >
                                          Vista Trace
                                        </Button>*/}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <Row className="pt-5">
                  <Col md="3">
                  </Col>
                  <Col className="pt-lg-5 pt-4" md="6">
                    <Card>
                      <CardBody>
                        <div style={{"textAlign": "center"}}>
                          <img width="50%" src={logo} alt="Foodchain Trace" />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-traza-grayblue" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
