import React, {useEffect,useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_lote, save_info_peso, delete_traza_animal, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import jQuery from "jquery";
import NotificationAlert from "react-notification-alert";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class InventoryAnimal extends React.Component {
  state={
    data_traza_inventory:[],
    animal_register:[],
    btnForSend:'none',
    sendOpenModalLote:false,
    notificationAlertRef: React.createRef(),
    codigoParams:null,
    openModal:false,
    idDeleteAnimal:'',
    openColapseChild:[false],

  }
 async componentDidMount(){
  this.initData()
  }
  initData = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      this.setState({codigoParams:gerParam})
      var dataAnimal = await row_traza_lote(jsondata._id,gerParam)
      if (dataAnimal.trazaDataLote[0]==undefined) {
        this.props.history.push('/admin/meats/inventory-animal?code='+gerParam)
      }
      this.setState({
        data_traza_inventory:dataAnimal.trazaDataLote
      })
  }
  saveInfoPeso = () =>{
    console.log('hola')
  }
render(){
  const deleteAnimal = async (selectMotive,fecha_muerte,motivo,lugar_siniestro) => {
    if (motivo=="") {
      notify("danger","¡Fallo de eliminación!", 'Por favor escriba un motivo.');
    }else{
      var selectMotiveChange;
      if (selectMotive=="2") {
        selectMotiveChange = selectMotive
      }else{
        selectMotiveChange =lugar_siniestro
      }
       if (window.confirm("Deseas eliminar este registro?")) {
       var dataReturn = await delete_traza_animal(this.state.idDeleteAnimal,fecha_muerte,motivo,selectMotiveChange)
               if (dataReturn=="exito") {
                this.setState({
                  openModal:false,
                })

               notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
               this.initData()
               this.props.history.push('/admin/meats/eliminate-animal?code='+this.state.codigoParams)
             }else{
               notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
             }
     }
    }

}
  const modificarPeso = (valores) =>{


  }
  const funcOpenModal = (chan,idAnimal) =>{
    this.setState({
      openModal:chan,
      idDeleteAnimal:idAnimal
    })
  }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <br /><span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }

  return (
    <>
    <LoteModalEliminate sendOpenModal={this.state.openModal} openModalFunc={funcOpenModal} deleteAnimalExtend={deleteAnimal}/>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Inventario" parentName="Inventario" />


        <Container className="mt--6" fluid>
            {this.state.data_traza_inventory.map((value,index)=>{
              return(
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col md="9" onClick={()=> {openedChild(this.state.openColapseChild['matade'+index],'matade'+index)}}>
                <h3 className="mb-0">Inventario Lote: {value.lote_numero}</h3>
              </Col>
              <Col md="3">
                                      <ComponentLink
                                        className="font-weight-bold"
                                                              to={{
                                          pathname:"/admin/meats/job-lote",
                                          search: "?code="+this.state.codigoParams+'&lote='+value.lote_numero,
                                          state:{
                                            codigoTraza:this.state.codigoParams
                                          }
                                          }}
                                        >
                                       Trabajar en este lote
                                      </ComponentLink>
              </Col>
            </Row>
          </CardHeader>

          <Collapse isOpen={this.state.openColapseChild['matade'+index]} data-tab={"datall"+index}>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Foodchain ID</th>
                  <th>Fecha registro</th>
                  <th>Madre arete (ID)</th>
                  <th>Animal arete (ID)</th>
                  <th>Fecha nacimiento</th>
                  <th>Raza</th>
                  <th>Edad</th>
                  <th>Sexo</th>
                  <th>Peso</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
              <TrRegister paramsUrl={this.state.codigoParams} sendValue={value} getEventModify={modificarPeso} notifyPeso={notify} openModalFunc={funcOpenModal}/>
              </tbody>
            </Table>
          </Collapse>
        </Card>
                 )
            })
          }
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}
export class TrRegister extends React.Component {
  state = {
    animal_id_register:[],
    codigo:[],
    fecha_registro:[],
    madre_arete:[],
    animal_arete:[],
    fecha_nacimiento:[],
    raza:[],
    edad:[],
    sexo:[],
    peso:[],
  }
  async componentDidMount(){
    this.setState({
    animal_id_register: String(this.props.sendValue.animal_register_id).split("*"),
    codigo: String(this.props.sendValue.register_codigo_animal).split("*"),
    fecha_registro: String(this.props.sendValue.fecha_registro).split("*"),
    madre_arete: String(this.props.sendValue.madre_id).split("*"),
    animal_arete: String(this.props.sendValue.animal_id).split("*"),
    fecha_nacimiento: String(this.props.sendValue.fecha_nacimiento).split("*"),
    raza: String(this.props.sendValue.raza).split("*"),
    edad: String(this.props.sendValue.edad).split("*"),
    sexo: String(this.props.sendValue.sexo).split("*"),
    peso: String(this.props.sendValue.peso_inicial).split("*"),
    peso_compare: String(this.props.sendValue.peso_inicial).split("*"),
    })
  }
  eventOnclick = ()=>{
    console.log('hola')
  }
  render(){
    const modificarPeso = (valuePeso,index,id_animal,pesoOriginal)=>{
      if (valuePeso==pesoOriginal) {
        jQuery(".saveBtnAnimal"+id_animal)[0].style.display = 'none'
      }else{
        jQuery(".saveBtnAnimal"+id_animal)[0].style.display = 'block'
      }
        this.setState({peso:{[index]:valuePeso }})
          //this.setState({peso:{[index]:valuePeso}})
        //this.setState({forindex:{[index]:index}})
        //jQuery("#setButon").append(<Button >Guardar Cambios</Button>)
    }
    const btnSavePeso = async (idAnimal,peso,index) => {
    jQuery(".saveBtnAnimal"+idAnimal)[0].style.display = 'none'
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
     var data = await save_info_peso(idAnimal,peso,this.props.paramsUrl,jsondata._id)
        if (data=='exito') {
         this.props.notifyPeso("success","¡Registrado exitosamente!", 'Se guardo exitosamente.');
         //notify("success","¡Registrado exitosamente!", 'Se guardo exitosamente.');
        }
    }

    return(
      this.state.animal_id_register.map((value,index)=>{
                  return (<tr>
                                     <td>
                                      <span className="text-muted">
                                      </span>
                                      <ComponentLink
                                        className="font-weight-bold"
                                                              to={{
                                          pathname:"/admin/meats/details-animal",
                                          search: "?code="+this.state.codigo[index]+"&id_animal="+value+'&lote='+this.props.sendValue.lote_numero+"&edit",
                                          state:{
                                            codigoTraza:this.state.codigo[index]
                                          }
                                          }}
                                        >
                                       Ver animal {value}
                                       </ComponentLink>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.fecha_registro[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.madre_arete[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.animal_arete[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.fecha_nacimiento[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.raza[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.edad[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.sexo[index]}
                                      </span>
                                     </td>
                                     <td style={{padding: 0}}>
                                      <span className="text-muted">
                                      <Input
                                      style={{width: '100px'}}
                                        value={this.state.peso[index]}
                                        onChange={(e) => {modificarPeso(e.target.value,index,value,this.state.peso_compare[index])} }
                                        style={{padding: "5px"}}
                                        id="example3cols2Input"
                                        placeholder="Escribe un peso"
                                        type="text"
                                      />
                                      </span>
                                     </td>
                                     <td>
                                    <a className="font-weight-bold" href="javascript:;" onClick={()=>{this.props.openModalFunc(true,value)}} >
                                       Eliminar animal
                                     </a>         
                                     <br />
                                    <a href="javascript:;" style={{display:'none',boxShadow: 'none'}} onClick={()=>{btnSavePeso(value,this.state.peso[index],index)}} className={"font-weight-bold saveBtnAnimal"+value}> Guardar peso</a>
                                     </td>
                                   </tr>)
                })
      )
  }
}
export class LoteModalEliminate extends React.Component {
  state={
    fecha_muerte:'',
    motivo:'',
    lugar_siniestro:'',
    selectMotive:'2',
  }
  componentDidMount(){
      var date = new Date();
        var completeDate = date.toLocaleDateString("en-CA")
        this.setState({
          fecha_muerte:completeDate
        })
  }
  render(){
    const onchangeMotive = (value) => {
      this.setState({selectMotive:value})
    }
    const functionDelete = () => {
      this.props.deleteAnimalExtend(this.state.selectMotive,this.state.fecha_muerte,this.state.motivo,this.state.lugar_siniestro)
    }
    return(
      <Modal isOpen={this.props.sendOpenModal} size="xl">
        <ModalBody>
          <Card>
          <CardHeader className="border-0">
            <Row>
              <Col md="12">
                <h3 className="mb-0">Eliminar Animal</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <Row>
                  <Col md="12" style={{marginBottom: '10px'}}>
                  <label>Motivo:</label><br />
                  <Col md="6">
                    <span style={{marginRight: '30px'}}>Por muerte</span><Input type="radio" className="selectmotivo" name="selectmotivo" onClick={(e)=>{onchangeMotive(e.target.value)}} value="1" />
                  </Col>
                    <Col md="6">
                    <span style={{marginRight: '30px'}}>Otro Motivo</span><Input type="radio" className="selectmotivo" name="selectmotivo" onClick={(e)=>{onchangeMotive(e.target.value)}} value="2" />
                  </Col>
                  </Col>
                    <Col md="4">
                                    <label>Fecha de eliminación:</label><br />
                                    <Input
                                      defaultValue={this.state.fecha_muerte}
                                      onChange={(e)=>{this.setState({fecha_muerte:e.target.value})}}
                                      type="date"
                                    />
                                    </Col>
                  <Col md="4">
                  <label>Motivo:</label><br />
                  <Input onChange={(e)=>{this.setState({motivo:e.target.value})}} id="example-date-input" type="textarea" />
                  </Col>
                  {(this.state.selectMotive=='2')?(null):
                  <Col md="4">
                  <label>Lugar de siniestro:</label><br />
                    <Input onChange={(e)=>{this.setState({lugar_siniestro:e.target.value})}} id="example-date-input" type="textarea" />
                  </Col>}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ModalFooter>
          <Button onClick={()=> {functionDelete(false)} }>
            Guardar
          </Button>
          <Button onClick={()=> {this.props.openModalFunc(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
        </ModalBody>
        </Modal>
    )
  }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
          var dataPermission = this.context.valueGlobal
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }    
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <InventoryAnimal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
