import React, {useState} from 'react';
import {
  Navbar,
  NavbarBrand,
  NavLink,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";
import jQuery from "jquery";

  class GrainTrazaNavBar extends React.Component {
    static contextType = UserContext
    state ={
      modal:false,
      color:'transparent',
      paramUrlLote:false,
      information_grain_t:'none',
      production_grain_t:'none',
      harvest_grain_t:'none',
      information_traza_end:'none',
      document_grain_t:'none',
      paramUrlCode:null
    }
    componentDidMount(){
      jQuery(".documentFormAll").hide()
      jQuery("#insertDocumentShow").val('none')
      if (this.props.getParams.indexOf('lote=')!=-1) {
      this.setState({paramUrlLote:true})
      }
       var gerParam = new URLSearchParams(this.props.getParams).get("code");
      this.setState({paramUrlCode:gerParam})
    }
    componentDidUpdate(){
      if (jQuery('.section_header_prod').text()=='Información General') {
        jQuery(".section_general").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Producción') {
        jQuery(".section_production").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Manejo Fitosanitario') {
        jQuery(".section_fitosanitario").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Cosecha') {
        jQuery(".section_cosecha").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Documentos') {
        jQuery(".section_documentos").css('color','#012b5d')
      }
      if (jQuery('.section_header_prod').text()=='Finalizar Producción') {
        jQuery(".section_prod_end").css('color','#012b5d')
      }

    }
  render(){
      const setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    var getPermission = this.context.valueGlobal
    if (getPermission.user_id_connection==null) {
      jQuery("#insertIdUser").val(getPermission.user_id)
    }else{
      jQuery("#insertIdUser").val(getPermission.user_id_connection)
    }
      if (getPermission.role==1) {
        jQuery("#insertDocumentShow").val('block')
        jQuery(".documentFormAll").show()
            this.setState({
              information_grain_t:'block',
              production_grain_t:'block',
              harvest_grain_t:'block',
              information_traza_end:'block',
              document_grain_t:'block'
            })
      }else{
        if (getPermission.type=='options') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              information_grain_t:(getPermission.information_grain_t==0)?'none':'block',
              production_grain_t:(getPermission.production_grain_t==0)?'none':'block',
              harvest_grain_t:(getPermission.harvest_grain_t==0)?'none':'block',
              document_grain_t:(getPermission.document_grain_t==0)?'none':'block',
              
              information_traza_end:'none',
            })
        }
        if (getPermission.type=='admin') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              information_grain_t:'block',
              production_grain_t:'block',
              harvest_grain_t:'block',
              information_traza_end:'none',
            })
        }

      }
        if (this.context.valueGlobal.length!=0) {
        this.setState({setinfoState:true})
        }
      }    
    const openToggler = (valOpen) =>{
      if (this.state.modal==true) {
       this.setState({modal:false,color:'transparent'})
      }else{
       this.setState({modal:true,color:'#2d597f'})
      }
    }
  return (
    <>
      <input type="hidden" id="insertIdUser" />
      <input type="hidden" id="insertDocumentShow" />
      <div>
      {setinfoInit()}
          <Navbar style={{backgroundColor:'#90c226', height: '50px'}} expand="lg" light>
            <NavbarToggler onClick={() => {openToggler(true)}} />
            <Collapse isOpen={this.state.modal} navbar style={{zIndex: '10', backgroundColor:this.state.color}}>
              <Nav className="me-auto" navbar style={{fontSize: '14px'}}>

                  {(this.props.getParams=="")
                  ?
              (
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700' }}
                  to="/admin/info_crops_t"
                >
                  Información General
                </ComponentLink>
              )
                  :
                  (<>
              <UncontrolledDropdown inNavbar nav style={{display: this.state.information_grain_t}}>
                <DropdownToggle className="section_general" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Información General
                </DropdownToggle>
                <DropdownMenu right style={{left: '0px'}}>

                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/info_crops_t"+this.props.getParams}>
                        Info del Cultivo
                      </ComponentLink>
                    </div>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar nav style={{display: this.state.production_grain_t}}>
                <DropdownToggle className="section_production" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Producción
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grain_seed_t"+this.props.getParams}>
                        Semilla
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grain_fertilizer_t"+this.props.getParams}>
                        Fertilizante
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/grain_fert_agro_t"+this.props.getParams}>
                        Agroquimicos
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grain_info_satelite_t"+this.props.getParams}>
                        Satelite
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown inNavbar nav style={{display: this.state.harvest_grain_t}}>
                <DropdownToggle className="section_cosecha" style={{ fontWeight:'700', color:"white" }} caret nav>
                  Cosecha
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/grain_harvest_info_harvest_t"+this.props.getParams}>
                        Cosecha
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grain_harvest_info_transport_t"+this.props.getParams}>
                        Transporte
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/grain_harvest_info_pos_harvest_t"+this.props.getParams}>
                        Pos-Cosecha
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                <ComponentLink className="nav-link section_documentos"
                  style={{ color:"white", fontWeight:'700',display: this.state.document_grain_t }}
                  to={"/admin/grains_traza_documents"+this.props.getParams}
                >
                  Documentos
                </ComponentLink>
              
              
                <ComponentLink className="nav-link section_prod_end"
                  style={{ color:"white", fontWeight:'700',display: this.state.information_traza_end }}
                  to={"/admin/grains_traza_end"+this.props.getParams}
                >
                  Finalizar Trazabilidad
                </ComponentLink>
        </>)}

            </Nav>
          </Collapse>
        </Navbar>
      </div>        
    </>
  );
 }
}

export default GrainTrazaNavBar;
