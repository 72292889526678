import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import {PutContent, PutContentSub, PutContentStatus} from './InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
const datacontent = [
{table:'production_create',title:'Datos Producción',icon:'far fa-newspaper','multiple_tables':0},
{table:'production_fertilizer',title:'Datos Fertilizantes/Agroquímicos',icon:'fa fa-atom','multiple_tables':0},
]

export default class SectionProduction extends React.Component {
  render(){
  return (
    <>
      <ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />
    </>
  );
}
}

//export default Elements;
