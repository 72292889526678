import React from "react";
import classnames from "classnames";
import { Chart } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import HomeCardsHeader from "components/Headers/HomeCardsHeader.js";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

function ScreenTerms() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <IndexNavbar />

      <Container className="mt-6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Politicas de provacidad</h5>
              </CardHeader>
              <CardBody>
                El App de Foodchain FoodchainTrace,  solicita su información personal con el fin de proporcionar y mejorar continuamente nuestros productos y servicios.
Esta es alguna de la información personal que requerimos:<br /><br />

<b style={{fontWeight:600 }}>1.- Nombre y Apellidos</b><br />
<b style={{fontWeight:600 }}>2.- Empresa</b><br />
<b style={{fontWeight:600 }}>3.- País</b><br />
<b style={{fontWeight:600 }}>4.- Rubro en el que trabaja</b><br />
<b style={{fontWeight:600 }}>5.- Productos con los que labora.</b><br /><br />

La información que usted nos facilita, la almacenamos en su cuenta, esta información no es compartida ni vendida a terceros. La información que usted registra para su empresa o productos, es para su propio uso, y usted será quien decide si comparte la información o no.
La información que usted decide subir al  blockchain, estará encriptada, y solo podrá ser vista a quien usted o sus colaboradores le compartan el Hash.
<br /><br />
La información que desea que sus compradores finales vean, compartidas mediante el código QR que este impreso en sus productos, será de su dominio, y es usted quien podrá compartirla.
<br /><br />
Información de otras fuentes: Es posible que recibamos información sobre usted de otras fuentes que usamos para completar la información de su perfil de manera más rápida.
Si usted ingresa con Facebook a FoodchainTrace recopilamos su nombre de perfil, foto, correo y fecha de nacimiento.<br /><br />

Si usted ingresa con Google a FoodchainTrace recopilamos su nombre de perfil, foto, correo y fecha de nacimiento.<br /><br />

Al verificar su teléfono con Whatsapp lo utilizamos para enviarle información de sus servicios.<br /><br />

<b style={{fontWeight:600 }}>¿Con qué fines utiliza FoodchainTrace de Foodchain su información personal?</b><br /><br />

Utilizamos su información personal para operar, proporcionar, desarrollar y mejorar los productos y servicios que ofrecemos a nuestros clientes. Estos fines incluyen:
Compra y entrega de productos y servicios. Usamos su información personal para tomar y manejar pedidos, entregar productos y servicios, procesar pagos y comunicarnos con usted acerca de pedidos, productos y servicios, y ofertas promocionales.
<br /><br />
Solucionar problemas. Utilizamos su información personal para proporcionar funcionalidad, analizar el rendimiento, corregir errores y mejorar la usabilidad y eficacia de los Servicios de FoodchainTrace.
<br /><br />
Recomendaciones y personalización. Utilizamos su información personal para recomendar funciones, productos y servicios que puedan ser de su interés, identificar sus preferencias y personalizar su experiencia con FoodchainTrace.
Cumplir con las obligaciones legales. Recopilamos y utilizamos su información personal para cumplir con las leyes, entregar facturas de venta y liquidaciones aduaneras en los países de destino.
<br /><br />
Comunicarnos con usted. Utilizamos su información personal para comunicarnos con usted en relación con FoodchainTrace a través de diferentes canales (por ejemplo, por teléfono, correo electrónico, notificación de la FoodchainTrace).
<br /><br />
<b style={{fontWeight:600 }}>¿Cómo puedo eliminar mis datos de Foodchain?</b><br /><br />

Para eliminar todos tus datos, debes eliminar tu cuenta, al eliminar tu cuenta todos tus datos serán eliminados de nuestro servidor de manera permanente y no podrás recuperar tu información de compras o transacciones que hayas realizado, por esta razón no recomendamos que elimines tu cuenta, en caso de querer hacerlo debe comunicarse con nosotros.
<br /><br />
Te enviaremos un correo de confirmación a tu mail proporcionado como usuario en nuestro sistema para que puedas confirmar la eliminación de tu cuenta, una vez confirmado en tu correo que deseas eliminar tu cuenta , toda tu información será eliminada, podrás crearte nuevamente una cuenta con tu mismo usuario pero los datos eliminados no se podrán recuperar.


              </CardBody>
            </Card>
          </Col>
        </Row>
        <br/><br/><br/><br/><br/><br/><br/>
      </Container>
    </>
  );
}

export default ScreenTerms;
