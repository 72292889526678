import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

function Elements() {
  return (
    <>
      <SimpleHeader name="Iniciar trazabilidad" parentName="Procesados" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Iniciar trazabilidad: procesados</h3>
          </CardHeader>
          <CardBody>
            Acceso denegado. Contáctanos para más información.
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
}

export default Elements;
