import React,{useContext} from "react";
import classnames from "classnames";
import { Chart } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import HomeCardsHeader from "components/Headers/HomeCardsHeader.js";
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";

function Dashboard(props) {
  const data_tp = useContext(UserContext).valueGlobal
  const type_prod = data_tp.type_traza
  const [activeNav, setActiveNav] = React.useState(1);
  const [nextOption, setNextOption] = React.useState(false);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [forModal, setForModal] = React.useState(false);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  const nextOptions = (tc) => {
    setNextOption(tc)
  }
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const funModal=(typeBol,redirectUrl)=>{
    if (redirectUrl!='modalPerfile') {
      props.history.push(redirectUrl)
      //console.log(props)
      return
    }
      setForModal(typeBol)
  }
  const contentCard = () => {
    //(type_prod?.indexOf('G')!=-1)?():(null)
  if (type_prod=="ON-RE-VA" || type_prod==undefined) {
    var data=[]
  }else{    
    var data = [
    {title:'Producción',pathname:'/admin/list-trace-production',description:'En esta Plataforma podrá documentar todo el ciclo de su producción, para ser mas eficiente, productivo y ahorrar costos e incrementar el rendimiento.'},
    {title:'Trazabilidad',pathname:'/admin/process-trace',description:'En esta Plataforma podrá realizar la trazabilidad de sus Productos desde el campo hasta el Consumidor y cumplir con la normas nacionales e internacionales.'},
    {title:'Comercialización',pathname:null,description:'En esta Plataforma podrá comercializar sus productos directo a compradores reales y evitar los intermediarios que no aportan valor.'},
    {title:'Perfil del usuario',pathname:'modalPerfile',description:'Aqui podrá cambiar el area de trabajo que desee gestionar.'},
    {title:'Operaciones',pathname:'/admin/repots-operation',description:'Aqui podrá ver los documentos que ha subido al Sistema, y accesar al Blockchain.'},
    {title:'Usuarios',pathname:'/admin/users',description:'Aqui podrá agregar a los usuarios que realicen las actividades dentro de su empresa.'},
    {title:'Administración',pathname:'/admin/services-pay',description:'Desde aqui podrá realizar las actividades administrativas, pagos por los servicios y compras realizadas.'},
    {title:'Capacitación',pathname:null,description:'Desde aqui podrá obtener capacitacion sobre el uso de las plataformas de Foodchain.'},
    ]
  }
var userdata = localStorage.getItem('user');
var jsondata = JSON.parse(userdata);

var getTypeAreaWork = localStorage.getItem('type_area_work')
var dataFilter = data
if (jsondata.userType==2) {
  dataFilter = data.filter((a,b)=> a.title!='Trazabilidad' && a.title!='Operaciones' && a.title!='Usuarios' && a.title!='Administración' && a.title!='Producción')
}else{
  if (getTypeAreaWork=='trazability') {
    dataFilter = data.filter((a,b)=>a.title!='Producción' && a.title!='Comercialización')
  }
  if (getTypeAreaWork=='production') {
    dataFilter = data.filter((a,b)=>a.title!='Trazabilidad' && a.title!='Comercialización')
  }
  if (getTypeAreaWork=='commerce') {
    dataFilter = data.filter((a,b)=>a.title!='Producción' && a.title!='Trazabilidad')
  }
}
if (type_prod=='ON' || type_prod=='RE' || type_prod=='VA') {
  props.history.push('report-production-users')
}
if (type_prod!=undefined) {
  if (type_prod?.indexOf('T')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Trazabilidad')
  }
  if (type_prod?.indexOf('P')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Producción')
  }
  if (type_prod?.indexOf('C')==-1) {
    dataFilter = dataFilter.filter((a,b)=> a.title!='Comercialización')
  }
}
//to={{ pathname:(val.pathname=='modalPerfile')?('#'):(val.pathname) }}
    var content = []
    dataFilter.map((val,indx)=>{
      content[indx]=<><Col xl="4">
            <a href="javascript:;" className="font-weight-bold" onClick={()=>{funModal( (val.pathname=='modalPerfile')?(true):(false),val.pathname )}}  >
              <Card style={{height: '140px'}}>
                <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <Row>
                  <Col md={12}>
                    <h5 className="h3 mb-0" style={{color:'white'}}>{val.title}</h5>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody style={{fontWeight: '100',color: 'gray', display: 'flex',padding: '10px'}}>
                <div style={{margin:'auto'}}>
                  {val.description}
                </div>
                </CardBody>
              </Card>
            </a>
          </Col></>
    })

    return content
  }

  return (
    <>
      <HomeCardsHeader name="Home" parentName="Bienvenido" forModalProp={forModal} closeModal={funModal} {...props} />
      <Container className="mt--6" fluid>
        <Row style={{textAlign:'justify'}}>
        {contentCard()}
{/*{(type_prod?.indexOf('G')!=-1)?(
      <Col xl="4">
        <ComponentLink className="font-weight-bold" to={{ pathname:"/admin/process-production" }} >
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
              <Row>
                <Col md={12}>
                  <h5 className="h3 mb-0" style={{color:'white'}}>Producción</h5>
                </Col>
              </Row>
              </CardHeader>
              <CardBody style={{fontWeight: '100',color: 'gray', display: 'flex'}}>
              <div style={{margin:'auto'}}>
                En esta Plataforma podra documentar todo el ciclo de su producción, para ser mas eficiente, productivo y ahorrar costos e incrementar el rendimiento.
              </div>
              </CardBody>
            </Card>
            </ComponentLink>
          </Col>
          ):(null)}
          <Col xl="4">
           <ComponentLink className="font-weight-bold" to={{ pathname:"/admin/process-trace" }} >
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
              <Row>
                <Col md={12}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Trazabilidad</h5>
                </Col>
              </Row>
              </CardHeader>
              <CardBody style={{fontWeight: '100',color: 'gray', display: 'flex'}}>
              <div style={{margin:'auto'}}>
                En esta Plataforma podra realizar la trazabilidad de sus Productos desde el campo hasta el Consumidor y cumplir con la normas nacionales e internacionales.
              </div>
              </CardBody>
            </Card>
           </ComponentLink>
          </Col>
          <Col xl="4">
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Comercialización</h5>
              </CardHeader>
              <CardBody style={{display: 'flex'}}>
              <div style={{margin:'auto'}}>
                En esta Plataforma podra comercializar sus productos directo a compradores reales y evitar los intermediarios que no aportan valor.
              </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Operaciones</h5>
              </CardHeader>
              <CardBody style={{display: 'flex'}}>
              <div style={{margin:'auto'}}>
                Aqui podra ver los documentos que ha subido al Sistema, y accesar al Blockchain.
              </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Usuarios</h5>
              </CardHeader>
              <CardBody style={{display: 'flex'}}>
              <div style={{margin:'auto'}}>
                Aqui podra agregar a los usuarios que realicen las actividades dentro de su empresa.
              </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Administración</h5>
              </CardHeader>
              <CardBody style={{display: 'flex'}}>
              <div style={{margin:'auto'}}>
                Desde aqui podra realizar las actividades administrativas, pagos por los servicios y compras realizadas.
              </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card style={{height: '170px'}}>
              <CardHeader style={{padding: '5px', backgroundColor:'#7ac142'}}>
                <h5 className="h3 mb-0" style={{color:'white'}}>Capacitación</h5>
              </CardHeader>
              <CardBody style={{display: 'flex'}}>
              <div style={{margin:'auto'}}>
                Desde aqui podra obtener capacitacion sobre el uso de las plataformas de Foodchain.
              </div>
              </CardBody>
            </Card>
          </Col>*/}
        </Row>
        
      </Container>
      <CardsHeader name="Home" parentName="Bienvenido" />

    </>
  );
}

export default Dashboard;
