import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormCommerceMarket from "./Modal/Subs/FormCommerce/FormCommerceMarket";
import FormCommerceExport from "./Modal/Subs/FormCommerce/FormCommerceExport";
export class Elements extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Iniciar trazabilidad" parentName="Comercialización" section="fuits_vegetable" section_two="Comercialización" section_table_form="null" />
      <Container className="mt--6" fluid>
      <FormCommerceMarket {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormCommerceExport {...this.props} putLinkId={this.state.link_id} />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.comercializa==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_commercialization_market, create_commercialization_export, data_export_market,get_permission } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import config from "config";
// import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
// import {UserContext} from "layouts/store";

// export class Elements extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//           this.initData()
//   }
//   initData = async () =>{
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var gerParam = new URLSearchParams(this.props.location.search).get("code");
//           var dataTrazabilidadvf = await data_export_market(jsondata._id,gerParam)
//           if (dataTrazabilidadvf.market==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//           if (dataTrazabilidadvf.export==null) {
//             this.setDataCamposEmptyExport()
//           }else{
//             this.setDataCamposExport(dataTrazabilidadvf)
//           }
//   }
//   setDataCamposEmptyExport = ()=>{
//             this.setState({
//                 export_id:'noind',
//                 comprador_nombre_export:'',
//                 contacto_export:'',
//                 telefono_contacto_export:'',
//                 email_export:'',
//                 numero_factura_export:'',
//                 informacion_adicional_export:'',
//                 file_document_export:undefined,
//             })
//   }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 market_id:'noind',
//                 comprador_nombre:'',
//                 empresa:'',
//                 ciudad:'',
//                 provincia:'',
//                 persona_contacto:'',
//                 telefono_contacto:'',
//                 email:'',
//                 numero_factura:'',
//                 informacion_adicional_market:'',
//                 file_document_market:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.market!=null) {
//         this.setState({
//                 market_id:data.market.id,
//                 comprador_nombre:data.market.comprador_nombre,
//                 empresa:data.market.empresa,
//                 ciudad:data.market.ciudad,
//                 provincia:data.market.provincia,
//                 persona_contacto: data.market.persona_contacto,
//                 telefono_contacto: data.market.telefono_contacto,
//                 email: data.market.email,
//                 numero_factura: data.market.numero_factura,
//                 informacion_adicional_market:data.market.informacion_adicional,
//                 file_document_market:data.market.archivo
//         })
//       }

//     }
//     setDataCamposExport = (data)=>{
//       if (data.export!=null) {
//         this.setState({
//                 export_id:data.export.id,
//                 comprador_nombre_export:data.export.comprador_nombre,
//                 contacto_export:data.export.contacto,
//                 telefono_contacto_export:data.export.telefono_contacto,
//                 email_export:data.export.email,
//                 numero_factura_export: data.export.numero_factura,
//                 informacion_adicional_export: data.export.informacion_adicional_export,
//                 file_document_export:data.export.archivo
//         })
//       }
//     }
//   render(){
//     const saveInfoMarket = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess:'Enviando...'})

//       var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_request = await create_commercialization_market(this.state,jsondata._id,gerParam);

//         if (var_request.data.message=="exito") {
//           this.setState({mess:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data);
//           this.setState({btnd:false})
//         }
//   }
//       const saveInfoExport = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess2:'Enviando...'})

//       if (this.state.comprador_nombre_export=="") {
//         this.setState({mess2:'El Campo Empresa es necesario.'})
//         this.setState({btnd:false})
//       }else{
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         //const var_request_trasport = await create_commercialization_market(this.state,jsondata._id,gerParam);
//         const var_request = await create_commercialization_export(this.state, jsondata._id, 0,gerParam);
//         if (var_request.data.message=="exito") {
//           this.setState({mess2:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data.message);
//           this.setState({btnd:false})
//         }
//       }
//   }


//       const notify = (type, title, message) => {
//     let options = {
//       place: "tc",
//       message: (
//         <div className="alert-text">
//           <span className="alert-title" data-notify="title">
//             {" "}
//             {title}
//           </span>
//           <span data-notify="message">
//             {message}
//           </span>
//         </div>
//       ),
//       type: type,
//       icon: "ni ni-bell-55",
//       autoDismiss: 10,
//     };
//     this.state.notificationAlertRef.current.notificationAlert(options);
//   };

//   return (
//     <>
//         <div className="rna-wrapper">
//         <NotificationAlert ref={this.state.notificationAlertRef} />
//       </div>
//       <VegetablesNavBar getParams={this.props.location.search}/>  
//       <SimpleHeader name="Iniciar trazabilidad" parentName="Comercialización" />
//       <Container className="mt--6" fluid>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Mercado Nacional</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
// {/*              <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Empresa
//                   </label>
//                   <Input
//                     value={this.state.comprador_nombre}
//                     onChange={(e)=> {this.setState({comprador_nombre:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el nombre del Comprador"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>*/}
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Empresa
//                   </label>
//                   <Input
//                     value={this.state.empresa}
//                     onChange={(e)=> {this.setState({empresa:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la empresa"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Ciudad
//                   </label>
//                   <Input
//                     value={this.state.ciudad}
//                     onChange={(e)=> {this.setState({ciudad:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la Ciudad"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Provincia
//                   </label>
//                   <Input
//                     value={this.state.provincia}
//                     onChange={(e)=> {this.setState({provincia:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la provincia"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Persona de Contacto
//                   </label>
//                   <Input
//                     value={this.state.persona_contacto}
//                     onChange={(e)=> {this.setState({persona_contacto:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el nombre completo"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Telefono de Contacto
//                   </label>
//                   <Input
//                     value={this.state.telefono_contacto}
//                     onChange={(e)=> {this.setState({telefono_contacto:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el telefono"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Email
//                   </label>
//                   <Input
//                     value={this.state.email}
//                     onChange={(e)=> {this.setState({email:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el email"
//                     type="email"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Numero de Factura
//                   </label>
//                   <Input
//                     value={this.state.numero_factura}
//                     onChange={(e)=> {this.setState({numero_factura:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el numero de factura"
//                     type="email"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       onChange={(e)=> {this.setState({file_document_market:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document_market==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_market}>{String(this.state.file_document_market).split('/').pop()}</a>)}
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     value={this.state.informacion_adicional_market}
//                     onChange={(e)=> {this.setState({informacion_adicional_market:e.target.value})}}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//             </Row>
//                   {this.state.mess ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="4">
//                 <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoMarket}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Exportacion</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre del comprador
//                   </label>
//                   <Input
//                     value={this.state.comprador_nombre_export}
//                     onChange={(e)=> {this.setState({comprador_nombre_export:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el nombre del Comprador"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Contacto
//                   </label>
//                   <Input
//                     value={this.state.contacto_export}
//                     onChange={(e)=> {this.setState({contacto_export:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el nombre completo"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Telefono de Contacto
//                   </label>
//                   <Input
//                     value={this.state.telefono_contacto_export}
//                     onChange={(e)=> {this.setState({telefono_contacto_export:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el telefono"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Email
//                   </label>
//                   <Input
//                     value={this.state.email_export}
//                     onChange={(e)=> {this.setState({email_export:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el email"
//                     type="email"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Numero de Factura
//                   </label>
//                   <Input
//                     value={this.state.numero_factura_export}
//                     onChange={(e)=> {this.setState({numero_factura_export:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el numero de factura"
//                     type="email"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     value={this.state.informacion_adicional_export}
//                     onChange={(e)=> {this.setState({informacion_adicional_export:e.target.value})}}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       onChange={(e)=> {this.setState({file_document_export:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                     {(this.state.file_document_export==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_export}>{String(this.state.file_document_export).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess2 ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess2}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="3">
//                 <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoExport}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Container>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//     </>
//   );
//  }
// }
// class SwiftComponent extends React.Component {
//   static contextType = UserContext
//   state={
//     get_permission:'load',
//     setinfoState:false
//   }
//  async componentDidMount(){
//     this.context.getAffiliates()
//     // var userdata = localStorage.getItem('user');
//     // var jsondata = JSON.parse(userdata);
//     // var gerParam = new URLSearchParams(this.props.location.search).get("company");
//     // var idcompany = null;
//     // if (jsondata.userType==2) {
//     //   if (gerParam!=null) {
//     //     idcompany = gerParam
//     //   }
//     // }
//     // var dataPermission = await get_permission(jsondata._id,idcompany)
//   }
//  setInfoInit = () =>{
//     if (this.state.setinfoState) {
//     return;  
//     }
//     if (this.context.valueGlobal.length!=0) {
//           this.setState({setinfoState:true})
//         }else{
//           return
//         }
//     var dataPermission = this.context.valueGlobal
//     if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
//   this.setState({get_permission:false})
//   return;
// }
//         if (dataPermission.role==1) {
//           this.setState({get_permission:true})
//         }else{
//             if (dataPermission.type=='admin') {
//               this.setState({get_permission:true})
//             }
//           if (dataPermission.type=='options') {
//             if (dataPermission.comercializa==1) {
//               this.setState({get_permission:true})
//             }else{
//               this.setState({get_permission:false})
//             }
//           }
//           if (dataPermission.type=='view') {
//             this.setState({get_permission:false})
//           }
//         }
//       }  
// render(){
//   {this.setInfoInit()}
//   return (
//     <>
//     {(this.state.get_permission=='load')?(
//       <h1 className="text-center">Cargando...</h1>
//       ):(
//       (this.state.get_permission)?(
//         <Elements {...this.props} />
//         ):(
//         <ViewPermision />
//         )
//       )}
//     </>
//   );
//  }
// }
// export default SwiftComponent;

// //export default Elements;
