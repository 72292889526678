import React, {useState} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter
} from "reactstrap";
import jQuery from 'jquery';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import FinalNama from "./DetailProductionTime/FinalNamaGrain";
import { generate_trazabilidad_grain, get_data_row_admin_grain, get_permission, data_obv_vr, data_obv_mvr, save_report_mrv } from "network/ApiAxios";
import { generateChain } from "network/GenerateTokenChain";
import NotificationAlert from "react-notification-alert";
import { NavLink } from "react-router-dom";
import $ from 'jquery'
import QRCode from "react-qr-code";
import {UserContext} from "layouts/store";
import './style.css';
import Web3 from 'web3';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import SelectSearch from 'react-select-search';
import DetailsObservationsRev from "./DetailProductionTime/DetailsObservationsRev";
import SignatureCanvas from 'react-signature-canvas';
import VistaTraceNGMRV from "views/pages/examples/Production/VistaTraceNamaGViewMRV";
var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')

var tituloGlobal=[];
var activityGlobal=[];
export  class ViewDetailMRV extends React.Component {
  static contextType = UserContext
  state={
    collapse:[],
    notificationAlertRef: React.createRef(),
    displayCard:"none",
    dataQr:"Sin datos",
    loading:'Cargando...',
    collapsefuve:true,
    idTraza:null,
    codigoTrza:null,
    routeget: new URLSearchParams(window.location.search).get("code"),
    routegetTwo: new URLSearchParams(window.location.search).get("up"),
    routegetThree: new URLSearchParams(window.location.search).get("db"),
    loadingupload:'none',
    btnend:'block',
    info_producer_g:'none',
    fordisplay:{},
    walletData:null,
    openModal:false,
    disabledbtn:false,
    db_prod:null,
    code_prod:null,
    form_prod:null,
    set_type_content:1,
    set_type_content_onre:null,
    getDataMVR:[],
    all_data_info:{},
    data_user:null,
    data_information:[],
    type_traza_data_user:null
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    //console.log(jsondata)
    var status='';
    var walletdata = localStorage.getItem('user_wallet');
    var jsondatawallet = JSON.parse(walletdata);
    var dataPermission = await get_permission(jsondata._id)
    if (jsondata.type_traza=='ON-RE-VA') {
      alert("Antes de hacer alguna modificación es necesario que elija un rol en el panel de inicio")
      this.props.history.push('/admin/home')
      return
    }
    this.setState({walletData:jsondatawallet,data_user:dataPermission,type_traza_data_user:jsondata.type_traza})

    this.getDataMVRUpdate()
      var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var getParamDb = new URLSearchParams(window.location.search).get("db");

      // var dataRow = await get_data_row_admin_grain(jsondata._id,this.state.routeget,codigoTrazaTwo,getParamDb)
      // Object.entries(dataRow.end_data_grain).map((a,b)=>{
      //   var name_nama = null
      //   // if(a[0]=='info_producer_g'){
      //   //   this.setState({info_producer_g:(a[1].length==0)?'none':'flex'})
      //   //   name_nama = 'Información Productor'
      //   // }
      //   if(a[0]=='info_crops_g'){
      //     name_nama = 'Info del Cultivo'
      //   }
        
      //    // if(a[0]=='info_prev_g'){
      //    //   name_nama = 'Información Previa'
      //    // }
      //   // if(a[0]=='info_financing_g'){
      //   //   name_nama = 'Financiamiento'
      //   // }
      //   if(a[0]=='production_land_preparation_g'){
      //     name_nama = 'Preparación de tierra'
      //   }
      //   if(a[0]=='production_irrigation_g'){
      //     name_nama = 'Riego'
      //   }
      //   if(a[0]=='production_seed_g'){
      //     name_nama = 'Semilla'
      //   }
      //   if(a[0]=='production_fertilizer_g'){
      //     name_nama = 'Fertilización'
      //   }
      //   if(a[0]=='production_sowing_g'){
      //     name_nama = 'Siembra'
      //   }
      //   if(a[0]=='production_sowing_transplant'){
      //     name_nama = 'Transplante'
      //   }
      //   if(a[0]=='production_producer_monitoring_g'){
      //     name_nama = 'Monitoreo Productor'
      //   }
      //   if(a[0]=='production_info_satelite'){
      //     name_nama = 'Satélite'
      //   }
      //   if(a[0]=='hading_fert_agro'){
      //     name_nama = 'Información de Agroquimicos'
      //   }
      //   if(a[0]=='hading_info_weeds'){
      //     name_nama = 'Información de malezas'
      //   }
      //   if(a[0]=='hading_inventory_weeds'){
      //     name_nama = 'Inventario de malezas'
      //   }
      //   if(a[0]=='hading_info_plague'){
      //     name_nama = 'Información de Plagas'
      //   }
      //   if(a[0]=='hading_inventory_plague'){
      //     name_nama = 'Inventario de Plagas'
      //   }
      //   if(a[0]=='hading_info_diseases'){
      //     name_nama = 'Información de enferedades'
      //   }
      //   if(a[0]=='harvest_info_harvest'){
      //     name_nama = 'Información de cosecha'
      //   }
      //   if(a[0]=='harvest_info_machinery'){
      //     name_nama = 'Calibración de maquinaria'
      //   }
      //   if(a[0]=='harvest_info_transport'){
      //     name_nama = 'Información de transporte'
      //   }
      //   if(a[0]=='harvest_info_pos_harvest'){
      //     name_nama = 'Información de Pos-Cosecha'
      //   }
      //   if (a[0]!='production_info_satelite_ndre' && a[0]!='production_info_satelite_ndmi' && a[0]!='production_info_satelite_ndhs' && a[0]!='info_producer_g' && a[0]!='info_prev_g' && a[0]!='info_financing_g') {
      //     if (a[0]!='info_financing_g' && a[0]!='info_producer_g') {
      //       if (a[1].length!=0) {
      //         activityGlobal[b] = {id:'none', name: name_nama, value: a[0]+"*"+name_nama+'|', datastate: {activity:{[0]:a[0]+"*"+name_nama+'|'}} }
      //       }
      //     }
      //   tituloGlobal[b] = {Titulos:name_nama,fordisplay: a[0], Componente:<FinalNama title={name_nama} table_ex={a[0]} getData={this.getDataForqr} putDataAll={a[1]} dbCode={this.props.location?.state} fullData={dataRow.end_data_grain} />}
      //   }
      //   this.setState({fordisplay:{...this.state.fordisplay,[a[0]]:(a[1].length==0)?'none':'flex'} })
      // })

  }
  componentWillMount(){
    tituloGlobal = []
  }
  getDataForqr = (data) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (data==null) {
      this.setState({
        loading:'Sin Producción.'
      })
    }else{
      if (this.state.routeget==null || this.state.routeget==undefined) {
        var codigo_set = 'FCTGN-'+jsondata._id+data.id
      }else{
        var codigo_set = this.state.routeget
      }
      this.setState({
        displayCard:"flex",
        dataQr:"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+codigo_set+"&db="+jsondata.database,
        loading:null,
        idTraza:data.id,
        codigoTrza:codigo_set,
      })
    }
  }
  getDataMVRUpdate = async () =>{
    var get_data_obv = await data_obv_mvr('general',this.state.routeget,this.state.routegetThree)
    //this.setState({getDataMVR:get_data_obv.data})
   if (get_data_obv.data!=null) {
     if (JSON.parse(get_data_obv.data.data_adjunt)!=null) {
      var status_data = []
      var get_data_pr = []
      var get_state = []
      var all_data={}
      JSON.parse(get_data_obv.data.data_adjunt).map((a,b)=>{
              var groupByCategory =[]
              if (a.value.split('*')[0]!='general') {
                    Object.entries(a.datastate).map((val,ind)=>{
                    if (val[0]=='state_status') {
                      groupByCategory = Object.values(val[1]).reduce((group, product) => {
                         const category = product;
                         group[category] = group[category] ?? [];
                          group[category].push(product);
                          return group;
                      }, {});
                    }
                    get_data_pr[ind] = Object.keys(val[1]).length
                   })
              var get_numb_max = Math.max(...get_data_pr)
              var cr = ''
              if (groupByCategory['Realizado']!=undefined) {
                cr = ' - Realizado: '+groupByCategory['Realizado'].length
              }
              var rv = ''
              if (groupByCategory['Revisar']!=undefined) {
                rv = <button style={{color:'orange',padding: 0, border: '0px', background: 'transparent'}} onClick={()=>{this.funcOpenModal(true,'general')}}>{' - Por Revisar: '+groupByCategory['Revisar'].length}</button>
              }
              var ph = ''
              if (groupByCategory['Por Hacer']!=undefined) {
                ph = ' - Por Hacer: '+groupByCategory['Por Hacer'].length
              }
              all_data[a.value.split('*')[0]]= <><span>Observaciones: </span><span>{get_numb_max}</span> {ph} {rv} {cr}</>
              }
          })
      this.setState({all_data_info:all_data})
     }
   }
  }
 funcOpenModal = (chan,form) =>{
    this.setState({
      openModal:chan,
      db_prod:this.state.routegetThree,
      code_prod:this.state.routeget,
      form_prod:form
    })
    if (this.state.set_type_content==1) {
      this.setState({set_type_content:2})
    }else{
      this.setState({set_type_content:1})
    }
  }
funcObs = async (chan,type_user_role) =>{
    var type_user_p=null
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (this.state.data_user!=null) {
     type_user_p = jsondata.type_traza
     //type_user_p = this.state.data_user[0].type_traza
    }
    if (this.state.set_type_content_onre==type_user_role) {
      this.setState({set_type_content_onre:null})  
    }else{
      this.setState({set_type_content_onre:type_user_role})      
    }
}
render(){

  const toggleFunctionFirst = () => {
      if (this.state.collapsefuve==true) {
        this.state.collapsefuve = false;
      }else{
        this.state.collapsefuve = true;
      }
  }
  const toggleFunction = (index,res) =>{
    // if (res==undefined) {
    //   this.state.collapse[index] = true;
    // }else{
    //   if (this.state.collapse[index]==true) {
    //     this.state.collapse[index] = false;
    //   }else{
    //     this.state.collapse[index] = true;
    //   }
    // }
    this.setState({collapse:{[index]:!this.state.collapse[index]}})
  }

    const optionModal = (type) => {
    this.setState({openModal:type})
  };
  const accessAccount = (password) => {
    this.setState({disabledbtn:true})
    try{
        this.setState({disabledbtn:false})
        var privateWalletEncrip = web3.eth.accounts.decrypt(this.state.walletData,password)
        this.setState({walletData:privateWalletEncrip})
        optionModal(false)
        notify("success", "Cambio exitoso", "Cuenta desbloqueada.");
    }catch(error){
        notify("danger", "No se pudo guardar", "La contraseña es incorrecta.");
        this.setState({disabledbtn:false})
    }


  };
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };

  const insertDataObservation = async () =>{
       notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
  }
  return (
    <>

      <div className="rna-wrapper">
              <NotificationAlert ref={this.state.notificationAlertRef} />
            </div>

            <SimpleHeader name="Vista trace" parentName="Detalles y Observaciones" section="namas" section_two="Finalizar_Nama" section_table_form="null" />
            <Container className="mt--6" fluid>
{/*              <Card className="mb-2">
                <CardHeader style={{padding: '8px',borderRadius: '20px'}}>
                        <Row>
                          <Col md={2}>
                            <h3 className="mb-0">Vista MRV</h3>
                          </Col>
                          <Col md={2}>
                            <button className="btn" onClick={()=>{this.funcOpenModal(true,'general')}} style={{border: 'solid 1px',height: '56px'}}>{(this.state.set_type_content==1)?('Agregar Observación / Recomendación'):('Volver a Ver Información')}</button>
                          </Col>
                          {(this.state.data_user!=null)?((this.state.data_user[0].type_traza=='VA')?(
                            <Col md={2}>
                              <button className="btn" onClick={()=>{this.funcOpenModal(true,'validar')}} style={{border: 'solid 1px',height: '56px'}}>{(this.state.set_type_content==1)?('Validar / Certificar Información'):('Volver a Ver Información')}</button>
                            </Col>
                          ):(null)):(null)}
                          {(this.state.data_user!=null)?(
                            (this.state.data_user[0].type_traza=='RE' || this.state.data_user[0].type_traza=='VA')?(
                                                        <Col md={2}>
                                                          <button className="btn" onClick={()=>{this.funcObs(true,'ON')}} style={{border: 'solid 1px',height: '56px'}}>{(this.state.set_type_content_onre!="ON")?('Ver Observaciones de Monitoreo'):('Volver a Ver Información')}</button>
                                                        </Col>
                                                    ):(null)
                            ):(null)}
                          {(this.state.data_user!=null)?((this.state.data_user[0].type_traza=='VA')?(
                            <Col md={2}>
                              <button className="btn" onClick={()=>{this.funcObs(true,'RE')}} style={{border: 'solid 1px',height: '56px'}}>{(this.state.set_type_content_onre!="RE")?('Ver Observaciones de Revisión'):('Volver a Ver Información')}</button>
                            </Col>
                          ):(null)):(null)}
                        </Row>
                </CardHeader>
              </Card>*/}

              <VistaTraceNGMRV {...this.props} code={this.state.routeget} db_user={this.state.routegetThree} />
{/*{ (this.state.set_type_content==1)?(
  (this.state.set_type_content_onre==null)?(
  <div className="contentBlockChain">
              { tituloGlobal.map((value,index)=>{
              return (
                <Card className="mb-1" style={{display: this.state.fordisplay[value.fordisplay]}}>
                        <CardHeader id={"section"+index} style={{padding: '8px'}}>
                        <Row>
                          <Col md={12}>
                            <a style={{color: "#32325d",fontWeight:"600"}} href={"#section"+index} onClick={() => {toggleFunction('collapse'+index,this.state.collapse['collapse'+index])}} className="mb-0">
                            {value.Titulos} </a>{(this.state.all_data_info[value.fordisplay]==undefined)?(null):(<span style={{fontWeight: '100'}}>*{this.state.all_data_info[value.fordisplay]}</span>)}
                          </Col>
                        </Row>
                        </CardHeader>
                          <Collapse isOpen={this.state.collapse['collapse'+index]}>
                              <CardBody className="text-center p-3">
                             {value.Componente}
                              </CardBody>
                          </Collapse>
                      </Card>
      
                       )  
                     })
                    }
            </div>
    ):(
      (this.state.set_type_content_onre=='ON' || this.state.set_type_content_onre=='RE')?(
        <DetailsObservationsRev thisStates={this.state} />
      ):(null)
    )
  ):(<ModalObservation thisStates={this.state} sendOpenModal={this.state.openModal} openModalFunc={this.funcOpenModal} insertDataObservation={insertDataObservation}/>)}*/}
            </Container>
    </>
  );
 }
}
export default ViewDetailMRV;