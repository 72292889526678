import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Modal,
  Table,
  Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {edit_colab,data_list_addcoll} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";
import config from "config";
//function Elements() {
class ModalRegisterActivity extends React.Component {
  static contextType = UserContext
  state = {
        info:[],
        setInfoDes:null,
        collapseOne:false,
        collapseTwo:false,
        collapseThree:false
  }
  async componentDidMount(){
  }
  async componentDidUpdate(){
  }
  putContentInfo=(data)=>{
    var content = []
    content = <>
      <Row style={{textAlign: 'center'}}>
        {this.breakDownTd(data.descripcion_detalles)}
      </Row>
    </>
    this.setState({setInfoDes:content})
  }
  breakDownTd = (data)=>{
    var dataBreak = data.replaceAll('Detalles de los campos actualizados: ','').split('*')
    var dataContent = []
    if (dataBreak.length>0) {
      for (var i = 0; i < dataBreak.length; i++) {
        if(dataBreak[i].split(': ')[1].indexOf('Sin Cambios')!=-1){
        //   dataContent[i] = <>
        //   <Col md={4} title={'Información Previa: '+dataBreak[i].split(': ')[1]?.split('->')[0]}>
        //     <div style={{fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
        //       <label style={{fontWeight: '700'}}>{dataBreak[i].split(': ')[0]}</label><br />
        //       {dataBreak[i].split(': ')[1]?.split('->')[1]}
        //     </div>
        //     </Col>
        //  </>
        }else{
         dataContent[i] = <>
          <Col md={4} title={'Información Previa: '+dataBreak[i].split(': ')[1]?.split('->')[0]}>
            <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
              <label style={{fontWeight: '700'}}>{dataBreak[i].split(': ')[0]}</label><br />
              {dataBreak[i].split(': ')[1]?.split('->')[1]}
            </div>
            </Col>
         </>
        }

      }
    }
    return dataContent
  }
render(){
  const setInfoActivityAll = (data) => {

  }
  const setInfoActivity = (type) => {
    this.props.closeSections(type)
  }
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const breakDown = (data) => {
      var dataBreak = data.replaceAll('Detalles de los campos actualizados: ','').split('*')
      var dataContent = []
      if (dataBreak.length>0) {
        for (var i = 0; i < dataBreak.length; i++) {
          dataContent[i] = <>
                          <p className="mb-0" style={{fontWeight: '500',color: 'black', fontSize: '12px'}}>{dataBreak[i]}.</p>
                        </>
        }
      }
      return dataContent
    }
    const breakDownTh = (data)=>{
      var dataBreak = data.replaceAll('Detalles de los campos actualizados: ','').split('*')
      var dataContent = []
      if (dataBreak.length>0) {
        for (var i = 0; i < dataBreak.length; i++) {
          dataContent[i] = <>
                          <th style={{border: 'solid 1px'}}>{dataBreak[i].split(': ')[0]}</th>
                        </>
        }
      }
      return dataContent
    }

    const openInfo = (inde) => {
      if (this.state.info[inde]=='show') {
        this.setState({info:[]})
      }else{
        this.setState({info:{[inde]:'show'}})
      }
    }  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
  {/* <Modal isOpen={this.props.sendOpenModalRegister} size="xl">
    <ModalBody> */}
        <Card className="mb-4">
          <CardHeader style={{padding: '1px',backgroundColor: '#7ac142'}}>
            <Row>
            <Col md={11}>
            <h3 className="mb-0" style={{color:'white'}}></h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoActivity(false)} }>
              <h3 className="mb-0" style={{color:'white'}}>X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>

          <div>
            <Row>

            <Col md="12">
              <button onClick={()=>{this.setState({collapseThree:!this.state.collapseThree})}} className="text-center" style={{padding: '1px',backgroundColor: '#7ac142', color:'white',border: '0px',width: '100%'}}>Datos del usuario</button>
              <Collapse isOpen={this.state.collapseThree}>
              <Card>
                <CardBody>
                  <Row style={{textAlign:'center'}}>
                    <Col md={12}>
                      {(this.props.allDataUser.photo==null)?('Sin imagen'):((this.props.allDataUser.photo=='null')?('Sin imagen'):(
                      <img style={{width: '200px',borderRadius: '100px'}} src={config.URL_PRODUCTION+"/storage/file_documents/"+this.props.allDataUser.photo} />
                      ))}
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Nombre</label><br />
                        {(this.props.allDataUser.name==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.name)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Apellido</label><br />
                        {(this.props.allDataUser.second_name==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.second_name)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Cédula</label><br />
                        {(this.props.allDataUser.dni==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.dni)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Teléfono</label><br />
                        {(this.props.allDataUser.telefono==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.telefono)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Empresa</label><br />
                        {(this.props.allDataUser.empresa==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.empresa)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>País</label><br />
                        {(this.props.allDataUser.pais==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.pais)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Ubicación</label><br />
                        {(this.props.allDataUser.ubicacion==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.ubicacion)}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Correo electrónico</label><br />
                        {(this.props.allDataUser.email==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.email)}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Mi firma digital</label><br />
                        {(this.props.allDataUser.system_digital_signature==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(this.props.allDataUser.system_digital_signature)}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div style={{color: 'black',fontSize: '12px',border: 'solid 1px',margin:'1px'}}>
                        <label style={{fontWeight: '700'}}>Mi firma dibujo</label><br />
                        {
                                            (this.props.allDataUser.draw_digital_signature==null)?(<i class="fas fa-times" title="Sin Datos"></i>):(<>
                                              <div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                                                <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                                                  <img 
                                                    style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                                                    src={this.props.allDataUser.draw_digital_signature} 
                                                    />
                                                </div>
                                              </div>
                                            </>)
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              </Collapse>
           </Col>

             <Col md="12">
              <button onClick={()=>{this.setState({collapseOne:!this.state.collapseOne})}} className="text-center" style={{padding: '1px',backgroundColor: '#7ac142', color:'white',border: '0px',width: '100%'}}>Seguimientos de Actividades</button>
              <Collapse isOpen={this.state.collapseOne}>
                <Row>
                  <Col md={3}>
                      {
                        this.props.setDataUserRegister.map((value,index) => {
                          return (<>
                            <button onClick={()=>{this.putContentInfo(value)}} style={{margin:'5px',border: '0px',padding: '5px',borderRadius: '2px',backgroundColor: '#2d5980',color: 'white'}}>
                              <div style={{fontSize: '12px',textAlign: 'right', borderBottom: 'solid 1px'}}>{value.fecha_subida}</div>
                              <div style={{fontSize: '12px',textAlign: 'justify',}}>{value.descripcion}</div>
                            </button>

                          </>)
                        })
                      }
                  </Col>
                  <Col md={9}>
                      {this.state.setInfoDes}
                  </Col>
                </Row>
              </Collapse>
           </Col>

             <Col md="12">
             <button onClick={()=>{this.setState({collapseTwo:!this.state.collapseTwo})}} className="text-center" style={{padding: '1px',backgroundColor: '#7ac142', color:'white',border: '0px',width: '100%'}}>Historial de actividades</button>
              {/* {this.props.dataSession.map((value,index) => { return (<Col xl="12">
              <Card>
                <CardBody>
                  <p className="mb-0" style={{fontWeight: '500',color: 'black', fontSize: '12px'}}>{value.close_sessions_date}.</p>
                </CardBody>
              </Card>
            </Col>)})} */}
            <Collapse isOpen={this.state.collapseTwo}>
              <div className="table-responsive">
              <table className="align-items-center table-flush" style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}}>
                  <thead className="thead-light">
                    <tr>
                    <th>Fechas de actividades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.dataSession.map((value,index) => {
                        return (<>
                        <tr>
                          <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>{value.close_sessions_date}</td>
                        </tr>
                        </>)
                      })
                    }
                  </tbody>
                </table>
                </div>
              </Collapse>

           </Col>

          </Row>
          </div>
        </Card>
        {/* </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {setInfoActivity(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
 }
}

export default ModalRegisterActivity;
