import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormPlantFreezPreCold from "./Modal/Subs/FormPlant/FormPlantFreezPreCold";
import FormPlantFreezCold from "./Modal/Subs/FormPlant/FormPlantFreezCold";
export class Elements extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Iniciar trazabilidad" parentName="Enfriamiento" section="fuits_vegetable" section_two="Planta" section_table_form="create_plant_pre_cold" />
      <Container className="mt--6" fluid>
      <FormPlantFreezPreCold {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormPlantFreezCold {...this.props} putLinkId={this.state.link_id} />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.planta==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import config from "config";
// import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
// import {UserContext} from "layouts/store";

// export class Elements extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//           this.initData()
//   }
//   initData= async ()=>{
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var gerParam = new URLSearchParams(this.props.location.search).get("code");
//           var dataTrazabilidadvf = await data_cold_pre_cold(jsondata._id,gerParam)
//           var dataTrazabilidadProduction = await data_production(jsondata._id,gerParam)
//           if (dataTrazabilidadvf.pre_cold==null) {
//             this.setDataCamposEmpty(dataTrazabilidadProduction,gerParam)
//           }else{
//             this.setDataCampos(dataTrazabilidadvf,dataTrazabilidadProduction,gerParam)
//           }
//   }
//       setDataCamposEmpty = (dataprod,gerParam)=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//               var completeDate = date.toLocaleDateString("en-CA")
//               if (gerParam==null) {
//                 var proddata = ''
//                 var proddatafinca = ''
//               }else{
//                 var proddata = (dataprod==null)? '' : dataprod.lote_numero
//                 var proddatafinca = (dataprod==null)? '' : dataprod.nombre_finca
//               }
//             this.setState({
//                 plant_pre_cold_id:'noind',
//                 lote_numero:proddata,
//                 fecha_inicio:completeDate,
//                 hora_inicio:timeString,
//                 temperatura_inicio:'',
//                 temperatura_final_pre_cold:'',
//                 unidad_refrigerar_pre_cold:'',
//                 total_unidades_refrigerar_pre_cold:'',
//                 informacion_adicional_pre_cold:'',
//                 file_document_pre_cold:undefined,

//                 plant_cold_id:'noind',
//                 lote_numero_cold:proddata,
//                 fecha_inicio_cold:completeDate,
//                 hora_inicio_cold:timeString,
//                 temperatura_inicio_cold:'',
//                 temperatura_final_cold:'',
//                 unidad_refrigerar:'',
//                 total_unidades_refrigerar:'',
//                 informacion_adicional_cold:'',
//                 file_document_cold:undefined,
//             })
//       }

//     setDataCampos = (data,dataprod,gerParam)=>{
//                 var date = new Date();
//                 var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//                 var completeDate = date.toLocaleDateString("en-CA")
//                 var proddata = dataprod.lote_numero
//                 var proddatafinca = dataprod.nombre_finca
//      if (data.pre_cold!=null) {
//         this.setState({
//                 plant_pre_cold_id:data.pre_cold.id,
//                 lote_numero:proddata,
//                 fecha_inicio:data.pre_cold.fecha_inicio,
//                 hora_inicio:data.pre_cold.hora_inicio,
//                 temperatura_inicio:data.pre_cold.temperatura_inicio,
//                 temperatura_final_pre_cold: data.pre_cold.temperatura_final,
//                 unidad_refrigerar_pre_cold:data.pre_cold.unidad_refrigerar,
//                 total_unidades_refrigerar_pre_cold:data.pre_cold.total_unidades_refrigerar,
//                 informacion_adicional_pre_cold:data.pre_cold.informacion_adicional,
//                 file_document_pre_cold:data.pre_cold.archivo,
//         })
//       }
//       if (data.cold!=null) {
//         this.setState({
//                 plant_cold_id:data.cold.id,
//                 lote_numero_cold:proddata,
//                 fecha_inicio_cold:data.cold.fecha_inicio,
//                 hora_inicio_cold:data.cold.hora_inicio,
//                 temperatura_inicio_cold:data.cold.temperatura_inicio,
//                 temperatura_final_cold: data.cold.temperatura_final,
//                 unidad_refrigerar: data.cold.unidad_refrigerar,
//                 total_unidades_refrigerar: data.cold.total_unidades_refrigerar,
//                 informacion_adicional_cold: data.cold.informacion_adicional,
//                 file_document_cold:data.cold.archivo,
//         })
//       }else{
//               this.setState({
//                 plant_cold_id:'noind',
//                 lote_numero_cold:proddata,
//                 fecha_inicio_cold:completeDate,
//                 hora_inicio_cold:timeString,
//                 temperatura_inicio_cold:'',
//                 temperatura_final_cold:'',
//                 unidad_refrigerar:'',
//                 total_unidades_refrigerar:'',
//                 informacion_adicional_cold:'',
//                 file_document_cold:undefined,
//             })
//       }
//     }
//   render(){
//     const saveInfoPreCold = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess:'Enviando...'})
//     if (this.state.lote_numero=="") {
//       this.setState({mess:'El Campo Lote Numero es necesario.'})
//       this.setState({btnd:false})
//     }else{
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_request = await create_plant_pre_cold(this.state,jsondata._id,gerParam);

//         if (var_request.data.message=="exito") {
//           this.setState({mess:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data);
//           this.setState({btnd:false})
//         }
//     }
//   }
//       const saveInfoCold = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess:'Enviando...'})
//     if (this.state.lote_numero=="") {
//       this.setState({mess:'El Campo Lote Numero es necesario.'})
//       this.setState({btnd:false})
//     }else{
//       if (this.state.lote_numero_cold=="") {
//         this.setState({mess:'El Campo Lote Numero es necesario.'})
//         this.setState({btnd:false})
//       }else{
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_request_trasport = await create_plant_pre_cold(this.state,jsondata._id,gerParam);
//         const var_request = await create_plant_cold(this.state, jsondata._id, var_request_trasport.data.plant_pre_cold_id,gerParam);
//         if (var_request.data.message=="exito") {
//           this.setState({mess:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data.message);
//           this.setState({btnd:false})
//         }
//       }
//     }
//   }


//       const notify = (type, title, message) => {
//     let options = {
//       place: "tc",
//       message: (
//         <div className="alert-text">
//           <span className="alert-title" data-notify="title">
//             {" "}
//             {title}
//           </span>
//           <span data-notify="message">
//             {message}
//           </span>
//         </div>
//       ),
//       type: type,
//       icon: "ni ni-bell-55",
//       autoDismiss: 10,
//     };
//     this.state.notificationAlertRef.current.notificationAlert(options);
//   };

//   return (
//     <>
//       <div className="rna-wrapper">
//         <NotificationAlert ref={this.state.notificationAlertRef} />
//       </div>
//       <VegetablesNavBar getParams={this.props.location.search}/>  
//       <SimpleHeader name="Iniciar trazabilidad" parentName="Enfriamiento" />
//       <Container className="mt--6" fluid>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Pre-enfriamiento</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                   <Input
//                     value={this.state.lote_numero}
//                     disabled
//                     onChange={(e)=>{this.setState({lote_numero:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el numero de lote"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Inicio
//                   </label>
//                   <Input
//                     value={this.state.fecha_inicio}
//                     onChange={(e)=>{this.setState({fecha_inicio:e.target.value})}}
//                     id="example-date-input"
//                     type="date"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora de Inicio
//                   </label>
//                   <Input 
//                   value={this.state.hora_inicio}
//                   onChange={(e)=>{this.setState({hora_inicio:e.target.value})}}
//                   id="example-date-input" type="time" />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Inicio
//                   </label>
//                   <Input
//                     value={this.state.temperatura_inicio}
//                     onChange={(e)=>{this.setState({temperatura_inicio:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la temperatura inicial"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Final
//                   </label>
//                   <Input
//                     value={this.state.temperatura_final_pre_cold}
//                     onChange={(e)=>{this.setState({temperatura_final_pre_cold: e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la temperatura final"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Unidad a Refrigerar
//                   </label>
                  

//                   <Input
//                     value={this.state.unidad_refrigerar_pre_cold}
//                     onChange={(e)=>{this.setState({unidad_refrigerar_pre_cold:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Ejem. Pallets, Cajas, etc."
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades a Refrigerar
//                   </label>
//                   <Input
//                     value={this.state.total_unidades_refrigerar_pre_cold}
//                     onChange={(e)=>{this.setState({total_unidades_refrigerar_pre_cold:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Ingresa el total de unidades"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     value={this.state.informacion_adicional_pre_cold}
//                     onChange={(e)=>{this.setState({informacion_adicional_pre_cold:e.target.value})}}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       onChange={(e)=>{this.setState({file_document_pre_cold:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document_pre_cold==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_pre_cold}>{String(this.state.file_document_pre_cold).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="4" className="pt-3">
//                 <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoPreCold}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Enfriamiento</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                   <Input
//                     value={this.state.lote_numero_cold}
//                     disabled
//                     onChange={(e)=>{this.setState({lote_numero_cold:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe el numero de lote"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Inicio
//                   </label>
//                   <Input
//                     value={this.state.fecha_inicio_cold}
//                     onChange={(e)=>{this.setState({fecha_inicio_cold:e.target.value})}}
//                     id="example-date-input"
//                     type="date"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora de Inicio
//                   </label>
//                   <Input 
//                   value={this.state.hora_inicio_cold}
//                   onChange={(e)=>{this.setState({hora_inicio_cold:e.target.value})}}
//                   id="example-date-input" type="time" />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Inicial
//                   </label>
//                   <Input
//                     value={this.state.temperatura_inicio_cold}
//                     onChange={(e)=>{this.setState({temperatura_inicio_cold:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la temperatura inicial"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Temperatura Final
//                   </label>
//                   <Input
//                     value={this.state.temperatura_final_cold}
//                     onChange={(e)=>{this.setState({temperatura_final_cold:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Escribe la temperatura final"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Unidad a Refrigerar
//                   </label>
//                   <Input
//                     value={this.state.unidad_refrigerar}
//                     onChange={(e)=>{this.setState({unidad_refrigerar:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Ejem. Pallets, Cajas, etc."
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades a Refrigerar
//                   </label>
//                   <Input
//                     value={this.state.total_unidades_refrigerar}
//                     onChange={(e)=>{this.setState({total_unidades_refrigerar:e.target.value})}}
//                     id="example3cols2Input"
//                     placeholder="Ingresa el total de unidades"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     value={this.state.informacion_adicional_cold}
//                     onChange={(e)=>{this.setState({informacion_adicional_cold:e.target.value})}}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       onChange={(e)=>{this.setState({file_document_cold:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document_cold==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_cold}>{String(this.state.file_document_cold).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="3">
//                 <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoCold}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Container>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//     </>
//   );
//  }
// }

// class SwiftComponent extends React.Component {
//   static contextType = UserContext
//   state={
//     get_permission:'load',
//     setinfoState:false
//   }
//  async componentDidMount(){
//     this.context.getAffiliates()
//     // var userdata = localStorage.getItem('user');
//     // var jsondata = JSON.parse(userdata);
//     //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
//     // var idcompany = null;
//     // if (jsondata.userType==2) {
//     //   if (gerParam!=null) {
//     //     idcompany = gerParam
//     //   }
//     // }
//     // var dataPermission = await get_permission(jsondata._id,idcompany)
//   }
//  setInfoInit = () =>{
//     if (this.state.setinfoState) {
//     return;  
//     }
//     if (this.context.valueGlobal.length!=0) {
//           this.setState({setinfoState:true})
//         }else{
//           return
//         }
//     var dataPermission = this.context.valueGlobal
//     if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
//   this.setState({get_permission:false})
//   return;
// }
//         if (dataPermission.role==1) {
//           this.setState({get_permission:true})
//         }else{
//             if (dataPermission.type=='admin') {
//               this.setState({get_permission:true})
//             }
//           if (dataPermission.type=='options') {
//             if (dataPermission.planta==1) {
//               this.setState({get_permission:true})
//             }else{
//               this.setState({get_permission:false})
//             }
//           }
//           if (dataPermission.type=='view') {
//             this.setState({get_permission:false})
//           }
//         }
//       }  
// render(){
//   {this.setInfoInit()}  
//   return (
//     <>
//     {(this.state.get_permission=='load')?(
//       <h1 className="text-center">Cargando...</h1>
//       ):(
//       (this.state.get_permission)?(
//         <Elements {...this.props} />
//         ):(
//         <ViewPermision />
//         )
//       )}
//     </>
//   );
//  }
// }
// export default SwiftComponent;
