import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { save_vf, data_trazabilidad, main_content, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
//import Select from 'react-select';
//import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import axios from 'axios';
import {getContent, PutFormContent, forNumeric, PutFormContentPro} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
 export class StartVegFruits extends React.Component {
  static contextType = UserContext
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null
  }
  async componentDidMount(){
    var getData = await main_content('fruit_vegetable')
    this.setState({field_content_log:getData})
    //var dateTim = date.getDate() + 3;
    this.initData()
  }
  initData = async ()=>{
  this.setState({field_content: getContent(this.state.field_content_log).s_data})

    //this.setState({codigoAran:CodigoAran})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataTrazabilidadvf = await data_trazabilidad(jsondata._id,gerParam,null,'fruit_vegetable',JSON.stringify(getContent(this.state.field_content_log).f_data))
        //this.state.myVarTime = setInterval(() => this.setDataCode(), 500);
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevState.field_params !== this.state.field_params) {
      //console.log(this.state.optionsSelect)

          //this.setState({field_params: this.state.field_params})
          // Object.entries(this.state.field_params).map((a,b)=>{
          //   console.log(a)
          // })
          //this.setState({content_render:this.putFormContent(this.state.field_content)})
        }
      }  
    setDataCampos = (data)=>{
      var date = new Date()
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
         if (a.type_field=='date') {
          if (data[a.name_field]==null) {
           this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
         }
         if (a.type_field=='time') {
          if (data[a.name_field]==null) {
             this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         }
          if (a.type_field=='file') {
              if (data[a.name_field]==null) {
                var multi_fil = [{name:'',filed:null}]
                this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
              }else{
                if (typeof data[a.name_field]=='string') {
                 if (data[a.name_field].split('|').length>0) {
                  var multi_fil = []
                   data[a.name_field].split('|').map((a,b)=>{
                     multi_fil[b] = {name:a,filed:null}
                   })
                    var item = []
                    item = [...multi_fil]
                  //field_params_params[a.name_field]=item
                  this.setState({field_params: {...this.state.field_params, [a.name_field]:item }})
                 }
                }
              }
          }
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        if (a.type_field=='file') {
              var multi_fil = [{name:'',filed:null}]
              this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{
      //this.setState({btnd:true})
      this.setState({mess:'Enviando...'})
      if (this.state.field_params_required==undefined) {
        //this.setState({btnd:false})
        return
      }
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {        
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {         
          if (field_data[b][0]==a[0]) {
           if ((a[1]==1 || a[1]=='1') && field_data[b][1]=='') {
            counter++
            data_mess[counter] = 'El campo '+a[0]+' es requerido';
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.setState({mess: data_mess.reverse() })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      this.setState({btnd:true})
       const var_vf = await save_vf(jsondata._id,field_data,field_data_name,gerParam,'fruit_vegetable',this.state.idT,data_for_file);
      if (var_vf==null) {
        this.setState({btnd:false})
        return
      }
           if (var_vf.data=="exito") {
             this.setState({mess:''})
             this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
             await this.initData()
             this.setState({btnd:false})
             if (this.props.location.search=='') {
              this.props.history.push('/admin/process-trace')
             }
           }
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (options) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              // this.setState({pais_origen: String(data.results[0].components.country) })
              // this.setState({ciudad:String(data.results[0].components.city)})
              // this.changeState(data.results[0].components.country_code.toUpperCase())
              // var dataClean = data.results[0].components.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('Estado','').trim()+'-'+data.results[0].components.country_code.toUpperCase()
              // this.setState({provincia:dataClean})
              //this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})

              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
              //<option value={data_obj[i]}>{data_obj[i]}</option>
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
      // if (this.state.field_params[field]!=undefined) {
      //       return this.state.field_params[field]
      // }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
      // if (code_country.length==2) {
      // }
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        // if (list_default=='ldp') {
        //   if (if_states!=undefined) {
        //     this.setState({field_params: {...this.state.field_params, [field]:value }})
        //   }
        // }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
                          //if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf("Bolivar".toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired, type_field) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})

       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            //this.setState({field_params: {...this.state.field_params, [a]:value }})
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})

                       }
                       //this.setState({dataStatesS:1})
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
        
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default==null || list_default=='0') {
          var for_put_data=this.contentForSelect(value_option,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]: for_put_data }})

        }else{
          if (list_default=='ldp') {
            var var_data = []
            var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})

          }
          if (list_default=='cafv') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})

          }
          if (list_default=='cac') {
            this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})

          }
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       console.log(item,field)
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,'fruit_vegetable',field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
 forSearhData = (query,data) => {
    return new Promise((resolve, reject) => {
        // fetch(`https://www.thecocktaildb.com/api/json/v1/1/search.php?s=${query}`)
        //     .then(response => response.json())
        //     .then(({ drinks }) => {
            // })
            // .catch(reject);
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });
            //   return drinks.map(({ idDrink, strDrink }) => ({
            //   value: idDrink,
            //   name: strDrink
            // }))
  //this.state.optionsSelect[content[i].name_field]
  // if (field.length>0) {
  // }
   // var data = field.filter((a,b) => a.value==query)
   // console.log(data)
   //  return data

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
     listCountryCode = CountrysData.sort(this.listaArray)
  render(){
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Iniciar trazabilidad" parentName="Vegetales y frutas frescas" section="fuits_vegetable" section_two="Trazabilidad" section_table_form="null" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            {
              (this.props.location.search=='')
              ?
              null
              :
              <Button style={{background: 'none', boxShadow: 'none', border: 'none'}} onClick={()=>{window.location.href = "/admin/start-trace/vegetables-fruits"}}>
              <h5 className="mb-0">Nueva trazabilidad</h5>
              </Button>
            }
            <h3 className="mb-0">Iniciar trazabilidad: vegetales y frutas frescas</h3>
            {/*<GuideMessage section="fuits_vegetable" section_two="Trazabilidad" section_table_form="null" />*/}
          </CardHeader>
          <CardBody>
            <Row>
            <PutFormContentPro 
            content={this.state.field_content}
            field_params={this.state.field_params}
            optionsSelect={this.state.optionsSelect}
            setFieldValueFiles={this.setFieldValueFiles}
            deleteImgState={this.deleteImgState}
            setFieldValue={this.setFieldValue}
            setFieldValueSelect={this.setFieldValueSelect}
            forSearhData={this.forSearhData}
            geolocacion={this.geolocacion}

             />
            {/*{this.putFormContent(this.state.field_content)}*/}
{/*              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Trazabilidad
                  </label>
                  <Input id="exampleFormControlSelect1" value={this.state.trazabilidad} type="select" onChange={(e) => this.setState({trazabilidad:e.target.value})}>
                    <option value="0">Seleccione...</option>
                    <option value="Fruta">Fruta</option>
                    <option value="Vegetal">Vegetal</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-date-input"
                >
                  Fecha
                </label>
                <Input
                  defaultValue={this.state.fecha}
                  onChange={(e) => this.setState({fecha:e.target.value})}
                  id="example-date-input"
                  type="date"
                />
              </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Producto
                  </label>
                  <Input
                    value={this.state.producto}
                    id="example3cols2Input"
                    onChange={(e) => this.setState({producto:e.target.value})}
                    placeholder="Escribe un producto"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Variedad
                  </label>
                  <Input value={this.state.variedad} id="exampleFormControlSelect1" placeholder="Escribe una variedad" type="text" onChange={(e) => this.setState({variedad:e.target.value})} />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Código arancelario
                  </label>
                  {
                    (this.state.codigo_arancelario==undefined)?
                    null
                    :
                  <Select 
                  defaultInputValue={this.state.codigo_arancelario}
                  options={this.state.codigoAran} 
                  getOptionLabel={(option)=> option.Codigo+'-'+option.Titulo }
                  getOptionValue={(option)=> option.Codigo}
                  onChange={(e) => this.setState({codigo_arancelario:e})}
                   />
                  }
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Nombre científico
                  </label>
                  <Input value={this.state.nombre_cientifico} id="exampleFormControlSelect1" placeholder="Escribe un nombre cientifico" type="text" onChange={(e) => this.setState({nombre_cientifico:e.target.value})} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Origen
                  </label>
                  <Input value={this.state.origen} id="exampleFormControlSelect1" type="select" onChange={(e) => this.setState({origen:e.target.value})}>
                    <option value="0">Seleccione...</option>
                    {  listCountryCode.map((value,index)=>{
                       return <option key={index} value={value.Name}>{value.Name}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Información adicional
                </label>
                <Input
                  value={this.state.informacion}
                  id="exampleFormControlTextarea1"
                  rows="1"
                  type="textarea"
                  onChange={(e) => this.setState({informacion:e.target.value})}
                />
              </FormGroup>
              </Col>
              <Col md="4" sm="6" className="documentFormAll">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Documento/Imagen
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                      onChange={(e) => this.setState({file_document:e.target.files[0]})}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                    {(this.state.file_document==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document)}}>{String(this.state.file_document).split('/').pop()}</Button>)}
              </Col>*/}
            </Row>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}            
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                disabled={this.state.btnd}
                onClick={()=>{this.saveInfo()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/>
    </>
  );
}
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    this.setInfoInit()
  }
componentDidUpdate(){
  this.setInfoInit()
}

 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_t = dataPermission.type_traza.indexOf('VF')
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
        if (data_type_t==-1 || dataPermission.type_traza==null) {
          this.setState({get_permission:false})
          return;
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return
         }

        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.trazabilidad==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }
render(){
  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <StartVegFruits {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
