import React,{useEffect} from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { verify_email } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import {useHistory} from "react-router-dom";

const ViewValidate = (props) => {
  const [email, setEmail] = React.useState("");
  const [verifyCode, setVerifyCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [btnclick, setbtnclick] = React.useState(false);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedCode, setfocusedCode] = React.useState(false);
  const history = useHistory();

useEffect(()=>{
  if (history.location.state!=undefined) {
    setEmail(history.location.state.email)
  }else{
    alert('Sin correo')
    history.push('/home/login')
  }
})

 const tryLogin = async () => {

    setError('Cargando')
    setbtnclick(true)
     if (email=='' || verifyCode=='') {
       setError('Todos los campos son obligatorios')
       return;
     }
     setError('Cargando, Espere mientras la base de datos está siendo creada..')
     var varThis = this
      const response = await verify_email(email, verifyCode)
      .catch(function (message){
        setbtnclick(false)
        setError('Ha ocuttido un error')
      })
      setError(response.msg)
      if (!response.success) {
        setbtnclick(false)
        return
      }
    setError('Su cuenta ha sido creado exitosamente, espere que se redirija automaticamente.')
    setbtnclick(true)
    setTimeout(function(){history.push('/home/login')}, 3000);
  };
  return (
    <>
      <AuthHeader
        title="¡Bienvenido!"
      />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center text-muted">
                  Verificación de cuenta
                </div>
                <div className="text-center text-muted">
                  *Se le ha enviado un código a su correo.
                </div>
                <div className="text-center text-muted mb-2">
                  *Tome en cuenta en tomar el último código enviado.
                </div>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedCode,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-barcode" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Código"
                        type="number"
                        onFocus={() => setfocusedCode(true)}
                        onBlur={() => setfocusedCode(true)}
                        onChange={(e) => setVerifyCode(e.target.value)}
                        value={verifyCode}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-at" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Correo electrónico"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        disabled
                      />
                    </InputGroup>
                  </FormGroup>
                  {error ? (
                    <div className="text-muted font-italic">
                      <small>
                        Mensaje:{" "}
                        <span className="text-red font-weight-700">
                          {error}
                        </span>
                      </small>
                    </div>
                  ) : null}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="traza-green"
                      type="button"
                      onClick={tryLogin}
                      disabled={btnclick}
                    >
                      Validar
                    </Button>
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewValidate;
