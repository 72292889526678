import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import ModalCompartiment from "./ModalExtend/ModalCompartimentExtend";
import { row_compartiment } from "network/ApiAxios";

export default class Compartiment extends React.Component {
    state={
    compartiment:[],
    compartiment_exit:[],

  }
async componentDidMount(){
        this.initData()
}

initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = this.props.paramsCode
        var getParamLote = this.props.paramsCodLote
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var compartiment = await row_compartiment(jsondata._id,gerParam,getParamLote,codigoTrazaTwo)
        this.setState({
          compartiment:compartiment.compartment_receipt_animal,
          compartiment_exit:compartiment.compartment_exit_animal,
        })
}


  render(){

  return (
    <>
      <Container className="mt--6" fluid>
        <ModalCompartiment sendInfoProps={this.state} refreshData={this.initData} setParamsUrl={this.props.paramsCode} />
      </Container>
    </>
  );
 }
}

//export default Elements;
