import React from 'react';
import {Route, Redirect} from 'react-router-dom';
//import {checkSession} from "../../network/ApiAxios";
import {useHistory} from "react-router-dom";

const PrivateRoute = ({component: Component, ...rest}) => {

    const history = useHistory();

    const check = async () => {
        //const response = await checkSession();
        //const {data} = response;
        //if (!data.success) {
           /* localStorage.removeItem("token");
            localStorage.removeItem("user");
            history.push("/home/login");*/
        //}
        //return data.success;
    }
    return (
        <Route {...rest} render={(props) => (
            localStorage.getItem("token") && localStorage.getItem("user")
                ? <Component {...props} />
                : <Redirect to='/home/login'/>
        )}/>
    )
}

export default PrivateRoute;