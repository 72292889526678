import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";

export default class SectionTraza extends React.Component {
  state={
    validateStatus:null,
    openColapse:false
  }

  render(){
    const opened = (isChange) =>{
      this.setState({openColapse: !isChange})
    }
  return (
    <>
                    <div id="vegefru-tab" className="clearfix eael-tab-content-item active" data-title-link="vegefru-tab">
                                  <div data-elementor-type="page" data-elementor-id="5649" className="elementor elementor-5649">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8ec3272" data-id="8ec3272" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-eed6dbe elementor-widget elementor-widget-spacer" data-id="eed6dbe" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-28d08b1 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="28d08b1" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
      <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.traza_meat==null)?('#f84d43'):((this.props.DataRow.traza_meat.status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="far fa-newspaper" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/3.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content">
      <h3 className="elementor-image-box-title">Info general</h3></div>
      </div>    </div>
        </div>
        <div className="elementor-element elementor-element-42682be elementor-widget elementor-widget-toggle" data-id="42682be" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse)}} id="elementor-tab-title-6961" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-6961" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Información del producto</div>
          </h6>

          <Collapse isOpen={this.state.openColapse} style={{width: '250%'}} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">
          <ul>
            <Col md="12" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                <Row>
                  <Col md={9}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                  </Col>
                  <Col md={3}>
                    <h4 className="mb-0" style={{color: '#ffffff'}}>{(this.props.DataRow.traza_meat==null)?(null):((this.props.DataRow.traza_meat.status=='desarrollo')?('Trazabilidad en proceso'):(this.props.DataRow.traza_meat.status))}</h4>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                {
                  (this.props.DataRow.traza_meat==null)
                  ?
                  'Sin datos'
                  :
                  [this.props.DataRow.traza_meat].map((value,index)=>{
                 return (<>
                 {(value.nombre_traza==null)?(null):((value.nombre_traza=='null')?(null):((value.nombre_traza=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre de la trazabilidad:</label><br /> {value.nombre_traza}</Col>)))}
                 {(value.fecha==null)?(null):((value.fecha=='null')?(null):((value.fecha=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Registro:</label><br /> {value.fecha}</Col>)))}
                 {(value.nombre_finca==null)?(null):((value.nombre_finca=='null')?(null):((value.nombre_finca=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre de la finca:</label><br /> {value.nombre_finca}</Col>)))}
                 {(value.tamano_finca==null)?(null):((value.tamano_finca=='null')?(null):((value.tamano_finca=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Tamaño de la finca:</label><br /> {value.tamano_finca+' '+value.unidad}</Col>)))}
                 {(value.georeferencia==null)?(null):((value.georeferencia=='null')?(null):((value.georeferencia=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Georeferencia:</label><br /> {value.georeferencia}</Col>)))}
                 {(value.pais_origen==null)?(null):((value.pais_origen=='null')?(null):((value.pais_origen=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>País de origen:</label><br /> {value.pais_origen}</Col>)))}
                 {(value.ciudad==null)?(null):((value.ciudad=='null')?(null):((value.ciudad=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Ciudad:</label><br /> {value.ciudad}</Col>)))}
                 {(value.provincia==null)?(null):((value.provincia=='null')?(null):((value.provincia=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Provincia:</label><br /> {value.provincia}</Col>)))}
                 {(value.especie==null)?(null):((value.especie=='null')?(null):((value.especie=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Especie:</label><br /> {value.especie}</Col>)))}
                 {(value.raza==null)?(null):((value.raza=='null')?(null):((value.raza=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Raza:</label><br /> {value.raza}</Col>)))}
                 {(value.proposito==null)?(null):((value.proposito=='null')?(null):((value.proposito=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Propósito:</label><br /> {value.proposito}</Col>)))}
                 {(value.codigo_arancelario==null)?(null):((value.codigo_arancelario=='null')?(null):((value.codigo_arancelario=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Código arancelario:</label><br /> {value.codigo_arancelario}</Col>)))}
                 {(value.informacion_adicional==null)?(null):((value.informacion_adicional=='null')?(null):((value.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {value.informacion_adicional}</Col>)))}
                  </>)
                })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
{/*                {
                  (this.props.DataRow.traza_meat==null)
                  ?
                  'Sin datos'
                  :
                  Object.values(this.props.DataRow.traza_meat).map((value,index)=>{
                 return ((value==null)?(null):((value=='null')?(null):((value=='undefined')?(null):(<li key={index}>{value}</li>))))
                })}*/}
          </ul>
          <div>&nbsp;</div>
          </Collapse>
        </div>
                </div>
        </div>
        </div>
          </div>
    </div>


              </div>
    </section>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section>
    </div>
                                        </div>
    </>
  );
}
}

//export default Elements;
