import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class DetailSignature extends React.Component {
  state={
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
              <Col md="12" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>
                <Row>
                  <div style={{paddingLeft: '12px'}}>
                    <label className="form-control-label" style={styles.labelStyleTree}>
                      8- FIRMAS:
                    </label>
                  </div>

                    <Col md="5">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{textAlign: 'right',color: '#000000',fontSize: '12px', width: '165px',fontWeight: '600',marginRight: '9px'}}>Fecha de emisión de la guía</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.fecha_emision_guia==undefined)?((this.props.validSwith.type_change_prod==1)?('2021-12-06'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('2021-12-08'):(null))):(this.state.fecha_emision_guia)}
                         // onChange={(e)=>{this.setState({fecha_emision_guia:e.target.value})}}
                       value={(this.props.validSwith.fecha_emision_guia==undefined)?(null):(this.props.validSwith.fecha_emision_guia)}
                       onChange={(e)=>{
                        this.props.setValFieldSignature('fecha_emision_guia',e.target.value)
                      }}
                         type="date"                  
                        />
                        </div>
                      </div>
                    </div>
                  </Col>

                    <Col md="6">
                    <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{textAlign: 'right',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '9px'}}>Cédula de identidad de la firma autorizada</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '19px'}}                    
                         // value={(this.state.cedula_autorizada==undefined)?((this.props.validSwith.type_change_prod==1)?('16392867'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.cedula_autorizada)}
                         // onChange={(e)=>{this.setState({cedula_autorizada:e.target.value})}}
                         value={(this.props.validSwith.cedula_autorizada==undefined)?(null):(this.props.validSwith.cedula_autorizada)}
                         onChange={(e)=>{
                          this.props.setValFieldSignature('cedula_autorizada',e.target.value)
                        }}
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                  </Col>

                    <Col md="6">
                    <div style={{width: '98.5%',alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                      <div>
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{alignSelf:'center',color: '#000000',fontSize: '12px', width: '240px',fontWeight: '600',marginRight: '9px',marginBottom: '0'}}>RUT VENDEDOR</label>
                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '120%',color: 'black',    letterSpacing: '10px'}}                    
                         // value={(this.state.rut_vendedor==undefined)?((this.props.validSwith.type_change_prod==1)?('218879910017'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.rut_vendedor)}
                         // onChange={(e)=>{this.setState({rut_vendedor:e.target.value})}}
                         value={(this.props.validSwith.rut_vendedor==undefined)?(null):(this.props.validSwith.rut_vendedor)}
                         onChange={(e)=>{
                          this.props.setValFieldSignature('rut_vendedor',e.target.value)
                        }}
                         type="text"                  
                        />
                        </div>
                      </div>
                    </div>
                  </Col>
                    <Col md="6" style={{marginTop: '30px'}}>
                      <input
                        style={{height: '20px',textAlign: 'center',fontSize: '20px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.signature_auto==undefined)?(null):(this.state.signature_auto)}
                         // onChange={(e)=>{this.setState({signature_auto:e.target.value})}}
                         value={(this.props.validSwith.signature_auto==undefined)?(null):(this.props.validSwith.signature_auto)}
                         onChange={(e)=>{
                          this.props.setValFieldSignature('signature_auto',e.target.value)
                        }}
                         type="text"                  
                        />
                      <div className="form-control-label" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px'}}>
                        Firma autorizada
                      </div>                        
                  </Col> 
                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailSignature;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
  }
}