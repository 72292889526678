import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Button,
  Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes,get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import VistaTrace from "views/pages/examples/Trazability/VistaTraceViewTable";

export default class Elements extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    setinfoState:false,
    collapse:[],
    tm:'none',
    tvf:'none',
  }
 async componentDidMount(){
  this.context.getAffiliates()
  // var userdata = localStorage.getItem('user');
  // var jsondata = JSON.parse(userdata);
  //   var gerParam = new URLSearchParams(this.props.location.search).get("company");
  //   var idcompany = null;
  //   if (jsondata.userType==2) {
  //     if (gerParam!=null) {
  //       idcompany = gerParam
  //     }
  //   }
  //   var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
          var dataPermission = this.context.valueGlobal
            if (dataPermission.type_traza==null) {
          // jQuery('#tm').remove()
          // jQuery('#tvf').remove()
          this.setState({tm:'none',tvf:'none'})
          return
        }else{
          if (dataPermission.type_traza.indexOf("VF")!=-1) {
            //jQuery('#tm').remove()
            this.setState({tvf:'block'})
          }
          if (dataPermission.type_traza.indexOf("M")!=-1) {
            //jQuery('#tvf').remove()
            this.setState({tm:'block'})
          }
        }
        var thisExtends = this
        //  data_list_processes(jsondata.user_id_origin,dataPermission.user_id_connection,dataPermission.type).then((result)=>{
        //   thisExtends.setState({
        //     company_id:gerParam,
        //     data_traza:result.trazabilidad,
        //     trazabilidad_meat:result.trazabilidad_meat,
        //   })
        // })
  var data = await data_list_processes(jsondata.user_id_origin,dataPermission.user_id_connection,dataPermission.type,'tvt',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
    data_traza:data.trazabilidad[1],
    data_traza_th:data.trazabilidad[0],
    data_traza_g:data.traza_grain[1],
    data_traza_g_th:data.traza_grain[0],
    trazabilidad_meat:data.trazabilidad_meat,
  })
      }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }

setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      // Object.keys(this.state.data_traza[0]).map((val,ind)=>{
      //   Object.values(data).map((a,b)=>{
      //     if (val==a.split('*')[1]) {
      //       console.log(a.split('*')[0])
      //     }
      //   })
      // })
      Object.values(data).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }
render(){
  this.setInfoInit()
  const setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data.length!=0) {
    var content=[]
    var contentd=[]
      data.map((a,b)=>{
        var get_codigo = null
         var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/vista-trace-admin-grain-nama"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/vista_trace_admin_grain_traza"
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/vista-trace-admin"
              }
            }
            if (val[0]=='codigo') {
              get_codigo=val[1]
            }
 var content_two = <>
  {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td>
      <td>
                    <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:url_selected,
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver en vista trace
                    </ComponentLink>
{/*                    <br />
      <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.toggleFunction(b,this.state.collapse[val[1]],val[1])}}>
        Desplegar para ver mas detalle
      </button>*/}
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <>
                        <tr>
                          {content_one}
                        </tr>
                        <tr>
                          <td colspan="6">
                          <Collapse style={{width: '80%'}} isOpen={this.state.collapse[get_codigo]}>
                            <VistaTrace code={this.state.codeTTV}/>
                          </Collapse>
                          </td>
                        </tr>
                      </>
      })
    }
    return content
  }
  return (
    <>
      <SimpleHeader name="Vista Trace" parentName="Vista Trace" section="fuits_vegetable" section_two="Tabla" section_table_form="table_view_trace" />
        <Container className="mt--6" fluid>
        <Card id="tvf" style={{display: this.state.tvf}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Vista Trace Frutas y Vegetales</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
                {this.setContentTh(this.state.data_traza_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {setContent(this.state.data_traza)}
{/*            {this.state.data_traza.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.producto}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.codigo_arancelario}
                    </span>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/vista-trace-admin",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     Ver en vista trace
                     </ComponentLink>
                   </td>
                 </tr>)
            })
          }
*/}
            </tbody>
          </Table>
        </Card>

         <Card id="tm" style={{display: this.state.tm}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Vista Trace Carnicos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
                <th>especie</th>
                <th>Creado</th>
                <th>Nombre de la trazabilidad</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {this.state.trazabilidad_meat.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.especie}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.nombre_traza}
                    </span>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/vista-trace-meat-admin",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     Ver en vista trace
                     </ComponentLink>
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>

        <Card id="tg" style={{display:(this.state.data_traza_g==undefined)?('none'):((this.state.data_traza_g[0]==undefined)?('none'):('block'))}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Grano: Trazabilidad arroz</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_traza_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
                {setContent(this.state.data_traza_g)}
            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

//export default Elements;
