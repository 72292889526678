import React, {useEffect,useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_lote_job, save_info_peso, delete_traza_animal } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import jQuery from "jquery";
import NotificationAlert from "react-notification-alert";
export default class AnimalDetails extends React.Component {
  state={
    data_traza_inventory:[],
    animal_register:[],
    btnForSend:'none',
    sendOpenModalLote:false,
    notificationAlertRef: React.createRef(),
    codigoParams:null,
    openModal:false,
    idDeleteAnimal:'',
  }
 async componentDidMount(){
  this.initData()
  }
  initData = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = this.props.paramsCode
      var gerParamLote = this.props.paramsCodLote

      this.setState({codigoParams:gerParam})
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
      var dataAnimal = await row_traza_lote_job(jsondata._id,gerParam,gerParamLote,codigoTrazaTwo)
      if (dataAnimal.trazaDataLote[0]==undefined) {
        this.props.history.push('/admin/meats/inventory-animal?code='+gerParam)
      }
      this.setState({
        data_traza_inventory:dataAnimal.trazaDataLote
      })
  }
  saveInfoPeso = () =>{
    console.log('hola')
  }
render(){
  const deleteAnimal = async (selectMotive,fecha_muerte,motivo,lugar_siniestro) => {
    if (motivo=="") {
      notify("danger","¡Fallo de eliminación!", 'Por favor escriba un motivo.');
    }else{
      var selectMotiveChange;
      if (selectMotive=="2") {
        selectMotiveChange = selectMotive
      }else{
        selectMotiveChange =lugar_siniestro
      }
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = this.props.paramsCode
      var gerParamLote = this.props.paramsCodLote
    var result = this.state.data_traza_inventory.filter(val => val.lote_numero==gerParamLote)
    if (result[0]==undefined) {
      var cantidad_animales = []
          }else{
      var cantidad_animales = String(result[0].animal_register_id).split('*')
    }

        if (window.confirm("Deseas eliminar este registro?")) {
        var dataReturn = await delete_traza_animal(this.state.idDeleteAnimal,fecha_muerte,motivo,selectMotiveChange,cantidad_animales.length,jsondata._id,gerParam)
                if (dataReturn=="exito") {
                 this.setState({
                   openModal:false,
                 })

                notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
                this.initData()
                this.props.history.push('/admin/meats/eliminate-animal?code='+this.state.codigoParams+'&lote='+gerParamLote)

              }else{
                notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
              }
      }
    }

}
  const modificarPeso = (valores) =>{


  }
  const funcOpenModal = (chan,idAnimal) =>{
    this.setState({
      openModal:chan,
      idDeleteAnimal:idAnimal
    })
  }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <br /><span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
            {this.state.data_traza_inventory.map((value,index)=>{
              return(
        <Card>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Animal número</th>
                <th>Fecha registro</th>
                <th>Madre arete (ID)</th>
                <th>Animal arete (ID)</th>
                <th>Fecha nacimiento</th>
                <th>Raza</th>
                <th>Edad</th>
                <th>Sexo</th>
                <th>Peso</th>
              </tr>
            </thead>
            <tbody>
            <TrRegister paramsUrl={this.state.codigoParams} sendValue={value} getEventModify={modificarPeso} notifyPeso={notify} openModalFunc={funcOpenModal}/>
            </tbody>
          </Table>
        </Card>
                 )
            })
          }
    </>
  );
 }
}
export class TrRegister extends React.Component {
  state = {
    animal_id_register:[],
    codigo:[],
    fecha_registro:[],
    madre_arete:[],
    animal_arete:[],
    fecha_nacimiento:[],
    raza:[],
    edad:[],
    sexo:[],
    peso:[],
  }
  async componentDidMount(){
    this.setState({
    animal_id_register: String(this.props.sendValue.animal_register_id).split("*"),
    codigo: String(this.props.sendValue.register_codigo_animal).split("*"),
    fecha_registro: String(this.props.sendValue.fecha_registro).split("*"),
    madre_arete: String(this.props.sendValue.madre_id).split("*"),
    animal_arete: String(this.props.sendValue.animal_id).split("*"),
    fecha_nacimiento: String(this.props.sendValue.fecha_nacimiento).split("*"),
    raza: String(this.props.sendValue.raza).split("*"),
    edad: String(this.props.sendValue.edad).split("*"),
    sexo: String(this.props.sendValue.sexo).split("*"),
    peso: String(this.props.sendValue.peso_inicial).split("*"),
    peso_compare: String(this.props.sendValue.peso_inicial).split("*"),
    })
  }
  eventOnclick = ()=>{
    console.log('hola')
  }
  render(){
    const modificarPeso = (valuePeso,index,id_animal,pesoOriginal)=>{
      if (valuePeso==pesoOriginal) {
        jQuery(".saveBtnAnimal"+id_animal)[0].style.display = 'none'
      }else{
        jQuery(".saveBtnAnimal"+id_animal)[0].style.display = 'block'
      }
        this.setState({peso:{[index]:valuePeso }})
          //this.setState({peso:{[index]:valuePeso}})
        //this.setState({forindex:{[index]:index}})
        //jQuery("#setButon").append(<Button >Guardar Cambios</Button>)
    }
    const btnSavePeso = async (idAnimal,peso,index) => {
    jQuery(".saveBtnAnimal"+idAnimal)[0].style.display = 'none'
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
     var data = await save_info_peso(idAnimal,peso,this.props.paramsUrl,jsondata._id)
        if (data=='exito') {
         this.props.notifyPeso("success","¡Registrado exitosamente!", 'Se guardo exitosamente.');
         //notify("success","¡Registrado exitosamente!", 'Se guardo exitosamente.');
        }
    }

    return(
      this.state.animal_id_register.map((value,index)=>{
                  return (<tr>
                                     <td>
                                      <span className="text-muted">
                                       Animal {value}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.fecha_registro[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.madre_arete[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.animal_arete[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.fecha_nacimiento[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.raza[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.edad[index]}
                                      </span>
                                     </td>
                                     <td>
                                      <span className="text-muted">
                                       {this.state.sexo[index]}
                                      </span>
                                     </td>
                                     <td style={{padding: 0}}>
                                      <span className="text-muted">
                                        {this.state.peso[index]}
                                      </span>
                                     </td>
                                   </tr>)
                })
      )
  }
}
//export default Elements;

// import React, {createRef} from "react";

// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
// import ModalAnimalExtend from "./ModalExtend/ModalAnimalExtend";
// import { row_traza_animal_final } from "network/ApiAxios";

// export default class AnimalDetails extends React.Component {
//     state={
//     dataAnimal:[],
//   }
// async componentDidMount(){
//         this.initData()
// }

// initData = async () =>{
//         var userdata = localStorage.getItem('user');
//         var jsondata = JSON.parse(userdata);
//         var gerParam = this.props.paramsCode;
//         var dataAnimal = await row_traza_animal_final(jsondata._id,gerParam)
//         console.log(dataAnimal)
//         this.setState({dataAnimal:dataAnimal.trazaDataAnimal})
// }


//   render(){

//   return (
//     <>
//       <Container className="mt--6" fluid>
//         <ModalAnimalExtend sendInfoProps={this.state} refreshData={this.initData} setParamsUrl={this.props.paramsCode} />
//       </Container>
//     </>
//   );
//  }
// }

//export default Elements;
