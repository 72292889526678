import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, data_trazabilidad, main_content, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContent, forNumeric, PutFormContentPro} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";

 export class Cosecha extends React.Component {
  static contextType = UserContext
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null
  }
  async componentDidMount(){
    var getData = await main_content('create_cosecha')
    this.setState({field_content_log:getData})
    this.initData()
  }
  initData = async ()=>{
    var data_select = []
        this.setState({field_content: getContent(this.state.field_content_log).s_data})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataTrazabilidadvf = await data_trazabilidad(jsondata._id,gerParam,null,'create_cosecha',JSON.stringify(getContent(this.state.field_content_log).f_data))
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
      }
        if (prevState.field_params !== this.state.field_params) {
        }
      }  
    setDataCampos = (data)=>{
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
         if (a.type_field=='date') {
          if (data[a.name_field]==null) {
           this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
         }
         if (a.type_field=='time') {
          if (data[a.name_field]==null) {
             this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         }
          if (a.type_field=='file') {
              if (data[a.name_field]==null) {
                var multi_fil = [{name:'',filed:null}]
                this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
              }else{
                if (typeof data[a.name_field]=='string') {
                 if (data[a.name_field].split('|').length>0) {
                  var multi_fil = []
                   data[a.name_field].split('|').map((a,b)=>{
                     multi_fil[b] = {name:a,filed:null}
                   })
                    var item = []
                    item = [...multi_fil]
                  //field_params_params[a.name_field]=item
                  this.setState({field_params: {...this.state.field_params, [a.name_field]:item }})
                 }
                }
              }
          }
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })
    }
    cleanData = () =>{
          this.setState({idT:'noind'})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        if (a.type_field=='file') {
              var multi_fil = [{name:'',filed:null}]
              this.setState({field_params: {...this.state.field_params, [a.name_field]:multi_fil }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })          
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async () =>{
      this.setState({btnd:true})
      this.setState({mess:'Enviando...'})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    var field_data = Object.entries(this.state.field_params)
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {        
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {         
          if (field_data[b][0]==a[0]) {
           if ((a[1]==1 || a[1]=='1') && field_data[b][1]=='') {
            counter++
            data_mess[counter] = 'El campo '+a[0]+' es requerido';
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.setState({mess: data_mess.reverse() })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      this.setState({btnd:true})
      const var_vf = await create_production(jsondata._id,field_data,field_data_name,gerParam,'create_cosecha',this.state.idT,data_for_file,'Datos Cosecha');
      if (var_vf==null) {
        this.setState({btnd:false})
        return
      }
          if (var_vf.message=="exito") {
            this.setState({mess:''})
            this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
            await this.initData()
            this.setState({btnd:false})
          }
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (options) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(e,options)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (posicion,optcon)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }

    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired) =>{
      if (field!="") {
       this.setState({field_params: {...this.state.field_params, [field]:value }})
      }       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            //this.setState({field_params: {...this.state.field_params, [a]:value }})
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
     setFieldValueFiles = (value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       console.log(item,field)
       var items = item[field]
       items = [...items,...multi_fil]
       item[field]=items
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,'create_cosecha',field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             //var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
          }
         }
      this.setState({field_params: item })
       }
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
     listCountryCode = CountrysData.sort(this.listaArray)    
     putFormContent = (content) =>{
      var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (content.length>0) {
      for (var i = 0; i < content.length; i++) {
          switch (content[i].type_field) {
            case 'text':
               data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {content[i].label_title}
                    </label>
                    <Input
                      value={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                      id={content[i].name_field}
                      isrequired={content[i].req_field}
                      onChange={(e) => {this.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(content[i].dis_field==1)? (true) : (false)}
                      placeholder={content[i].label_title}
                      type="text"
                    />
                  </FormGroup>
                </Col>            
              break;
            case 'numeric':
               data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {content[i].label_title}
                    </label>
                    <Input
                      value={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                      id={content[i].name_field}
                      isrequired={content[i].req_field}
                      onChange={(e) => {this.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(content[i].dis_field==1)? (true) : (false)}
                      placeholder={content[i].label_title}
                      type="number"
                    />
                  </FormGroup>
                </Col>
              break;
            case 'textarea':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {content[i].label_title}
                  </label>
                  <Input
                    value={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                    id={content[i].name_field}
                    isrequired={content[i].req_field}
                    onChange={(e) => {this.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(content[i].dis_field==1)? (true) : (false)}
                    placeholder={content[i].label_title}
                    type="textarea"
                  />
                </FormGroup>
              </Col>    
              break;
            case 'select':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {content[i].label_title}
                  </label>
{(this.state.optionsSelect[content[i].name_field]==undefined)?(null):(<SelectSearch 
                      value={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                      placeholder={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                      options={this.state.optionsSelect[content[i].name_field]}
                      getOptions={(e,a)=>this.forSearhData(e,a)}
                      search
                      disabled={(content[i].dis_field==1)? (true) : (false)}
                      onChange={(e,a) => {this.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />)}
                </FormGroup>
              </Col>    
              break;
            case 'date':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {content[i].label_title}
                  </label>
                  <Input
                    defaultValue={(this.state.field_params[content[i].name_field]==undefined?(completeDate):(this.state.field_params[content[i].name_field]))}
                    id={content[i].name_field}
                    isrequired={content[i].req_field}
                    onChange={(e) => {this.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(content[i].dis_field==1)? (true) : (false)}
                    placeholder={content[i].label_title}
                    type="date" />
                </FormGroup>
              </Col>    
              break;
            case 'time':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {content[i].label_title}
                  </label>
                  <Input
                    value={(this.state.field_params[content[i].name_field]==undefined?(timeString):(this.state.field_params[content[i].name_field]))}
                    id={content[i].name_field}
                    isrequired={content[i].req_field}
                    onChange={(e) => {this.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(content[i].dis_field==1)? (true) : (false)}
                    placeholder={content[i].label_title}
                    type="time" />
                </FormGroup>
              </Col>
              break;
            case 'file':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                <Form>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {content[i].label_title}
                  </label>
                  <Input
                    id={content[i].name_field}
                    isrequired={content[i].req_field}
                    onChange={(e) => {this.setFieldValue(e.target.files[0], e.target.id, e.target)}}
                    disabled={(content[i].dis_field==1)? (true) : (false)}
                    type="file" />
                </Form>
                {(this.state.field_params[content[i].name_field]==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+this.state.field_params[content[i].name_field])}}>{String(this.state.field_params[content[i].name_field]).split('/').pop()}</Button>)}                
              </Col>
              break;
            case 'georeference':
      data_content[content[i].order_field] = <Col md={content[i].col_field} key={i}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        value={(this.state.field_params[content[i].name_field]==undefined)?(''):(this.state.field_params[content[i].name_field])}
                                        id={content[i].name_field}
                                        isrequired={content[i].req_field}
                                        onChange={(e) => {this.setFieldValue(e.target.files[0], e.target.id, e.target)}}
                                        disabled={(content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {this.state.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {this.geolocacion(e.target)}}>
                                          <span isrequired={content[i].req_field} id={content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col>              
              break;              
            default:
              
              break;
          }
        }
    }
      return data_content;
    }  
  render(){
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Iniciar trazabilidad" parentName="Cosecha" section="fuits_vegetable" section_two="Cosecha" section_table_form="create_cosecha" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
           <CardHeader>
             <h3 className="mb-0">Ingresar Datos Cosecha</h3>
           </CardHeader>
          <CardBody>
            <Row>
            <PutFormContentPro 
            content={this.state.field_content}
            field_params={this.state.field_params}
            optionsSelect={this.state.optionsSelect}
            setFieldValueFiles={this.setFieldValueFiles}
            deleteImgState={this.deleteImgState}
            setFieldValue={this.setFieldValue}
            setFieldValueSelect={this.setFieldValueSelect}
            forSearhData={this.forSearhData}
            geolocacion={this.geolocacion}

             />
            </Row>
                  {this.state.mess?.length>0 ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess[0]}
                        </span>
                      </small>
                    </div>
                  ) : null}            
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button"
                disabled={this.state.btnd}
                onClick={()=>{this.saveInfo()}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/>
    </>
  );
}
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    this.setInfoInit()
  }
componentDidUpdate(){
  this.setInfoInit()
}

 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.cosecha==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }
render(){
  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Cosecha {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

// import React, {createRef} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import { create_cosecha, data_cosecha,data_production,data_trazabilidad, get_permission } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import config from "config";
// import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
// import {UserContext} from "layouts/store";

// export class Elements extends React.Component {
//     state={
//     codigo:'',
//     user_id:'',
//     btnd:false,
//     notificationAlertRef: React.createRef()
//   }
//   async componentDidMount(){
//     this.initData()
//   }
//   initData= async ()=>{
//             var userdata = localStorage.getItem('user');
//         var jsondata = JSON.parse(userdata);
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         var dataTrazabilidadCosecha = await data_cosecha(jsondata._id,gerParam)
//         var dataTrazabilidadProduction = await data_production(jsondata._id,gerParam)
//         var dataTrazabilidadvf = await data_trazabilidad(jsondata._id,gerParam)
//         var date = new Date();
//         var completeDate = date.toLocaleDateString("en-CA")
//         var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             if (gerParam==null) {
//               var proddata = ''
//               var proddatafinca = ''
//               var trzp = ''
//               var trzv = ''
//             }else{
//               var proddata = (dataTrazabilidadProduction==null)? '' : dataTrazabilidadProduction.lote_numero
//               var proddatafinca = (dataTrazabilidadProduction==null)? '' : dataTrazabilidadProduction.nombre_finca
//               var trzp = (dataTrazabilidadvf==null)? '' : dataTrazabilidadvf.producto
//               var trzv = (dataTrazabilidadvf==null)? '' : dataTrazabilidadvf.variedad
//             }
//         if (dataTrazabilidadCosecha==null) {
//           this.setState({
//               cosecha_id:'noind',
//               nombre_finca:proddatafinca,
//               lote_numero:proddata,
//               producto:trzp,
//               variedad:trzv,
//               fecha_cosecha: completeDate,
//               hora_cosecha: timeString,
//               hora_final: timeString,
//               georeferencia:dataTrazabilidadProduction.georeferencia,
//               peso_unidad:'',
//               empaque:'',
//               peso_por_unidad:'0',
//               peso_total:'',
//               total_unidades:'0',
//               informacion_adicional:'',
//               file_document: undefined,

//           })
//         }else{
//           this.setDataCampos(dataTrazabilidadCosecha,dataTrazabilidadProduction,dataTrazabilidadvf)
//         }
//   }
//     setDataCampos = (data,dataprod,datatraza)=>{
//       this.setState({
//               cosecha_id:data.id,
//               nombre_finca:dataprod.nombre_finca,
//               lote_numero:dataprod.lote_numero,
//               producto:datatraza.producto,
//               variedad:datatraza.variedad,
//               fecha_cosecha:data.fecha_cosecha,
//               hora_cosecha:data.hora_cosecha,
//               hora_final:data.hora_final,
//               georeferencia:data.georeferencia,
//               peso_unidad:data.peso_unidad,
//               empaque:data.empaque,
//               peso_por_unidad:data.peso_por_unidad,
//               peso_total:data.peso_total,
//               total_unidades:data.total_unidades,
//               informacion_adicional:data.informacion_adicional,
//               file_document:data.archivo
//       })
//     }
//   render(){
//   const saveInfo = async () =>{

//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess:'Enviando...'})
//     if (this.state.nombre_finca=="") {
//       this.setState({mess:'El primer campo es necesario.'})
//       this.setState({btnd:false})
//     }else{
//       //if (this.state.file_document==undefined) {
//       //this.setState({mess:'Necesita subir un archivo.'})
//       //this.setState({btnd:false})
//       //}else{
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_cp = await create_cosecha(this.state,jsondata._id,gerParam);
//         if (var_cp.data=="exito") {
//           this.setState({mess:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           this.setState({btnd:false})
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.initData()
//         }else{
//           //this.setState({mess:'Se guardo exitosamente.'})
//           notify("danger", "¡Registrado Fallido!", var_cp.data);
//           this.setState({btnd:false})
//         }
//       //}
//     }
//   }
//       const geolocacion = () => {
//       this.setState({loadlocali:'Cargando...'})
//        if (navigator.geolocation) {
//        navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
//        }else{
//          this.setState({loadlocali:null})
//        }
//     };
//     function VerPosicion(posicion){
//     var lat = posicion.coords.latitude;
//     var lon = posicion.coords.longitude;
//     var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
//     fetch(locapi)
//     .then(response => response.json()
//         .then(data => {
//             if (data) {
//               setinfolocation(lat,lon,data)
//             }

//         })
//         .catch(err => console.log(err.message)));
//     }
//         const setinfolocation = (lat,lon,data) => {
//               this.setState({loadlocali:null})
//               this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})
//     }

//     function noPermitir(argument) {
//       this.setState({loadlocali:null})
//     // $('.cargadores').hide();
//     }
//     const notify = (type, title, message) => {
//     let options = {
//       place: "tc",
//       message: (
//         <div className="alert-text">
//           <span className="alert-title" data-notify="title">
//             {" "}
//             {title}
//           </span>
//           <span data-notify="message">
//             {message}
//           </span>
//         </div>
//       ),
//       type: type,
//       icon: "ni ni-bell-55",
//       autoDismiss: 10,
//     };
//     this.state.notificationAlertRef.current.notificationAlert(options);
//   };
//   return (
//     <>
//       <div className="rna-wrapper">
//         <NotificationAlert ref={this.state.notificationAlertRef} />
//       </div>
//       <VegetablesNavBar getParams={this.props.location.search}/>  
//       <SimpleHeader name="Iniciar trazabilidad" parentName="Cosecha" />
//       <Container className="mt--6" fluid>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Ingresar Datos Cosecha</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre de la finca
//                   </label>
//                   <Input
//                     value={this.state.nombre_finca}
//                     disabled
//                     onChange={(e) => this.setState({nombre_finca:e.target.value})}
//                     id="example3cols2Input"
//                     placeholder="Escribe una Finca"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Numero
//                   </label>
//                   <Input
//                     value={this.state.lote_numero}
//                     disabled
//                     onChange={(e) => this.setState({lote_numero:e.target.value})}
//                     id="example3cols2Input"
//                     placeholder="Escribe el numero de lote"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Producto
//                   </label>
//                   <Input
//                     value={this.state.producto}
//                     disabled
//                     onChange={(e) => this.setState({producto:e.target.value})}
//                     id="example3cols2Input"
//                     placeholder="Escribe el producto"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Variedad
//                   </label>
//                   <Input
//                     value={this.state.variedad}
//                     disabled
//                     onChange={(e) => this.setState({variedad:e.target.value})}
//                     id="example3cols2Input"
//                     placeholder="Escribe una variedad"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha Cosecha
//                   </label>
//                   <Input
//                     value={this.state.fecha_cosecha}
//                     defaultValue={new Date().getFullYear() + "-11-23"}
//                     id="example-date-input"
//                     type="date"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora Cosecha
//                   </label>
//                   <Input
//                     value={this.state.hora_cosecha}
//                     onChange={(e) => this.setState({hora_cosecha:e.target.value})} id="example-date-input" type="time" />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora Final
//                   </label>
//                   <Input
//                     value={this.state.hora_final}
//                     onChange={(e) => this.setState({hora_final:e.target.value})} id="example-date-input" type="time" />
//                 </FormGroup>
//               </Col>
//             <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Georeferencia
//                   </label>
//                   <Row>
//                     <Col md="10">
//                     <Input
//                       value={this.state.georeferencia}
//                       id="example3cols2Input"
//                       className="ubicacionex"
//                       placeholder="Escribe la referencia"
//                       type="textarea"
//                       onChange={(e) => this.setState({georeferencia:e.target.value})}
//                       disabled
//                     />
//                       {this.state.loadlocali}
//                     </Col>  
//                     <Col md="2">
//                     <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
//                       <span style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
//                     </a>
//                     </Col>  
//                   </Row>
//                   </FormGroup>
//               </Col> 
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Peso/Unidad
//                   </label>
//                   <Input
//                     value={this.state.peso_unidad}
//                     onChange={(e) => this.setState({peso_unidad:e.target.value})} id="exampleFormControlSelect1" type="select">
//                     <option value="0">Seleccione...</option>
//                     <option value="Peso">Peso</option>
//                     <option value="Unidad">Unidad</option>
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Tipo de Empaque
//                   </label>
//                   <Input
//                     value={this.state.empaque}
//                     onChange={(e) => this.setState({empaque:e.target.value})} id="exampleFormControlSelect1" type="select">
//                     <option value="0">Seleccione...</option>
//                     <option value="Saco">Saco</option>
//                     <option value="Bolsa">Bolsa</option>
//                     <option value="Caja">Caja</option>
//                     <option value="Granel">Granel</option>
//                     <option value="Otro">Otro</option>
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <Input
//                     value={this.state.total_unidades}
//                     onChange={(e) => {
//                                       this.setState({peso_total:parseInt(this.state.peso_por_unidad)*parseInt(e.target.value)})
//                                       this.setState({total_unidades:e.target.value})
//                                       }}
//                     id="example3cols2Input"
//                     placeholder="Escribe una marca"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso por Unidad
//                   </label>
//                   <Input
//                     value={this.state.peso_por_unidad}
//                     onChange={(e) => {
//                                       this.setState({peso_total:parseInt(e.target.value)*parseInt(this.state.total_unidades)})
//                                       this.setState({peso_por_unidad:e.target.value})
//                                       }}
//                     id="example3cols2Input"
//                     placeholder="Escribe un peso"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso total
//                   </label>
//                   <Input
//                     value={this.state.peso_total}
//                     //onChange={(e) => this.setState({peso_total:e.target.value})}
//                     id="example3cols2Input"
//                     placeholder="Escribe un peso"
//                     type="text"
//                     disabled
//                   />
//                 </FormGroup>
//               </Col>

//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     value={this.state.informacion_adicional}
//                     onChange={(e) => this.setState({informacion_adicional:e.target.value})}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                       onChange={(e) => this.setState({file_document:e.target.files[0]})}
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="3">
//                <Button 
//                 className="btn-icon" 
//                 color="traza-blue" 
//                 type="button" 
//                 disabled={this.state.btnd}
//                 onClick={saveInfo}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Container>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//     </>
//   );
//  }
// }
// class SwiftComponent extends React.Component {
//   static contextType = UserContext
//   state={
//     get_permission:'load',
//     setinfoState:false
//   }
//  async componentDidMount(){
//   this.context.getAffiliates()
//     // var userdata = localStorage.getItem('user');
//     // var jsondata = JSON.parse(userdata);
//     //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
//     // var idcompany = null;
//     // if (jsondata.userType==2) {
//     //   if (gerParam!=null) {
//     //     idcompany = gerParam
//     //   }
//     // }
//     // var dataPermission = await get_permission(jsondata._id,idcompany)
//   }
//  setInfoInit = () =>{
//     if (this.state.setinfoState) {
//     return;  
//     }
//     if (this.context.valueGlobal.length!=0) {
//           this.setState({setinfoState:true})
//         }else{
//           return
//         }
//     var dataPermission = this.context.valueGlobal
//     if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
//   this.setState({get_permission:false})
//   return;
// }
//         if (dataPermission.role==1) {
//           this.setState({get_permission:true})
//         }else{
//             if (dataPermission.type=='admin') {
//               this.setState({get_permission:true})
//             }
//           if (dataPermission.type=='options') {
//             if (dataPermission.cosecha==1) {
//               this.setState({get_permission:true})
//             }else{
//               this.setState({get_permission:false})
//             }
//           }
//           if (dataPermission.type=='view') {
//             this.setState({get_permission:false})
//           }
//         }
//       }
// render(){
//   {this.setInfoInit()}
//   return (
//     <>
//     {(this.state.get_permission=='load')?(
//       <h1 className="text-center">Cargando...</h1>
//       ):(
//       (this.state.get_permission)?(
//         <Elements {...this.props} />
//         ):(
//         <ViewPermision />
//         )
//       )}
//     </>
//   );
//  }
// }
// export default SwiftComponent;

// //export default Elements;
