import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class DetailRecive extends React.Component {
  state={
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
              <Col md="12" className="pb-2">
                <div style={styles.cardStyleSectionTwo}>
                <Row>
                  <Col md="3">
                    <label className="form-control-label" style={styles.labelStyleTree}>
                      15- RECIBIDO PARA ENTREGAR A DI.CO.SE
                    </label>
                  </Col>   
                  <Col md="5">
                    <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px',backgroundColor: '#c9edfd',borderRadius: '5px',padding: '3px'}}>
                        <div className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '100%',fontWeight: '600',marginRight: '9px'}}>Dia/Mes/Año</div>                        
                        <div style={{display:'flex'}}>
                        <label className="form-control-label" style={{textAlign: 'center',color: '#000000',fontSize: '12px', width: '120px',fontWeight: '600',marginRight: '9px'}}>Fecha</label>

                        <Input
                        style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '100%',color: 'black',    letterSpacing: '0px'}}                    
                         // value={(this.state.date_receive==undefined)?((this.props.validSwith.type_change_prod==1)?('2021-12-08'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.date_receive)}
                         // onChange={(e)=>{this.setState({date_receive:e.target.value})}}
                       value={(this.props.validSwith.date_receive==undefined)?(null):(this.props.validSwith.date_receive)}
                       onChange={(e)=>{
                        this.props.setValFieldRecibe('date_receive',e.target.value)
                     }}                         
                         type="date"                  
                        />
                        </div>
                    </div> 
                  </Col>

                    <Col md="4" style={{alignSelf: 'end'}}>
                      <input
                        style={{height: '15px',textAlign: 'justify',fontSize: '15px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.receive_reparti==undefined)?((this.props.validSwith.type_change_prod==1)?('Jefatura'):((this.props.validSwith.seleccionar_operacion_prop=='1')?(null):(null))):(this.state.receive_reparti)}
                         // onChange={(e)=>{this.setState({receive_reparti:e.target.value})}}
                       value={(this.props.validSwith.receive_reparti==undefined)?(null):(this.props.validSwith.receive_reparti)}
                       onChange={(e)=>{
                        this.props.setValFieldRecibe('receive_reparti',e.target.value)
                     }}                         
                         type="text"                  
                        />
                      <div className="form-control-label mr-1" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Repartición
                      </div>
                  </Col> 
                  <Col md="4" className="pt-2" >                
                  <div className="col-md-12" style={{border: 'solid 2px #c9edfd', textAlign: 'left', borderRadius: '5px', backgroundColor: 'white'}}>
                          <div style={{color: '#000000',fontSize: '12px',fontWeight: '500', height: '90px'}}>
{/*                          CAMIÓN CONTROLADO<br />
                          Declaro que son de mi conocimiento y cumplo con los reglamentos<br />
                          (CE) N<sup>o</sup> 852/2004 y (CE) N<sup>o</sup> 853/2004 en lo que es de mi cometencia.*/}
                        </div> 
                  </div>
                  </Col>

                  <Col md="8" style={{alignSelf: 'end'}}>
                    <Row>
                  <Col md="6">
                      <input
                        style={{height: '30px',textAlign: 'justify',fontSize: '15px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.receive_cargo==undefined)?(null):(this.state.receive_cargo)}
                         // onChange={(e)=>{this.setState({receive_cargo:e.target.value})}}
                       value={(this.props.validSwith.receive_cargo==undefined)?(null):(this.props.validSwith.receive_cargo)}
                       onChange={(e)=>{
                        this.props.setValFieldRecibe('receive_cargo',e.target.value)
                     }}                         
                         type="text"                  
                        />
                      <div className="form-control-label mr-1" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Cargo y nombre del funcionacio actualmente
                      </div>
                  </Col>
                  <Col md="6">
                      <input
                        style={{height: '30px',textAlign: 'justify',fontSize: '15px',borderRadius: '0px',width: '100%',color: 'black',backgroundColor: 'transparent', border: 'black solid 1px', borderStyle: 'dotted',borderTop: 'none',borderLeft: 'none',borderRight: 'none'}}
                         // value={(this.state.receive_firma==undefined)?(null):(this.state.receive_firma)}
                         // onChange={(e)=>{this.setState({receive_firma:e.target.value})}}
                       value={(this.props.validSwith.receive_firma==undefined)?(null):(this.props.validSwith.receive_firma)}
                       onChange={(e)=>{
                        this.props.setValFieldRecibe('receive_firma',e.target.value)
                     }}                         
                         type="text"                  
                        />
                      <div className="form-control-label mr-1" style={{textAlign:'center',color: '#000000',fontSize: '11px',width: '100%',fontWeight: '500',marginRight: '0px',top: '4px',position: 'relative',}}>
                        Firma
                      </div>
                  </Col>
                      
                    </Row>
                  </Col>

                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailRecive;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  //width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
  }
}