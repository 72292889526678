import React, {createRef, useEffect,useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { row_traza_animal, register_lote, row_traza_lote_select, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import jQuery from "jquery";
import ModalAnimal from "./ModalInsertAnimal";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class InventoryAnimal extends React.Component {
  state={
    data_traza_inventory:[],
    trazabilidad_meat:[],
    btnForSend:'none',
    sendOpenModalLote:false,
    urlParamsCodeExtend:[]

  }
 async componentDidMount(){
  this.initData()
  }
  initData = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(this.props.location.search).get("code");
      var dataLote = await row_traza_lote_select(jsondata._id,gerParam)
      this.setState({urlParamsCodeExtend:dataLote.trazaDataLoteList})

      var dataAnimal = await row_traza_animal(jsondata._id,gerParam)

      this.setState({
        data_traza_inventory:dataAnimal.trazaDataAnimal,
        data_lote_count: dataAnimal.countLote
      })
  }
render(){
  const checkSendLote = () =>{
    var _check = jQuery(".lote_check_send:checked").length;
    if (_check > 0) {
      this.setState({btnForSend:'block'})
    }else{
      this.setState({btnForSend:'none'})
    }
  }
  const sendLote = async (lote_numero,type_code,type_redirect,code_param,lote_alias) =>{
    // if (lote_alias=='') {
    //   alert('Es necesario un alias')
    // }
   if (window.confirm('Está seguro con lo seleccionado?')) {
      jQuery(".btnDisabled").prop("disabled",true)
      var getValue = [];
      var documentTag = document.querySelectorAll(".lote_check_send:checked");
      if (documentTag.length==0) {
        alert('Seleccione un animal')
      }else{
      for (var i = 0; i < documentTag.length; i++) {
          getValue[i] = documentTag[i].value
        }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");

        var data = await register_lote(getValue.filter(Boolean),jsondata._id,gerParam,lote_numero,type_code,lote_alias)
        if (data.messages=="exito") {
          if (type_redirect==2) {
            if (type_code==2) {
              this.props.history.push('/admin/meats/job-lote?code='+code_param+'&lote='+lote_numero)
            }else{
              this.props.history.push('/admin/meats/job-lote?code='+data.codigo+'&lote='+lote_numero)
            }
          }else{
            if (type_code!=2) {
              this.props.history.push('/admin/meats/inventory-animal?code='+data.codigo+'&lote='+lote_numero)
            }else{
              alert('Animale(s) Asignado al codigo: '+code_param+', lote: '+lote_numero)
            }
          }
          openModalLote(false)
          checkAll(false)
          this.initData()
        }else{
          jQuery(".btnDisabled").removeAttr("disabled")
          alert('No se pudo actualizar')
        }
      }
    }
  }
  const checkAll = (type) =>{
    var checked;
    if (type==undefined) {
      checked = jQuery(".check_all").prop("checked");
    }else{
      checked = type
    }
    jQuery(".lote_check_send").prop("checked",checked);

    checkSendLote()
  }
  const openModalLote = (open) =>{
    this.setState({sendOpenModalLote:open})
  }
  const changeLoteVal = (values) =>{
    this.setState({data_lote_count:values})
  }

  return (
    <>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Inventario" parentName="Inventario" />
        <ModalLote 
          isOpenModal={this.state.sendOpenModalLote}
          dataCountLote={this.state.data_lote_count}
          changeValLote={changeLoteVal}
          changeEventModal={openModalLote}
          setDataLote={sendLote}
          urlParamsExtend={this.state.urlParamsCodeExtend}

          />
        <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col md="8" sm="12">
                <h3 className="mb-0">Inventario de Animales</h3>
              </Col>
              <Col md="4" sm="12">
                <ModalAnimal dataAnimal={this.state.data_traza_inventory} refreshData={this.initData} setParamsUrl={this.props.location.search} />
              </Col>
              <Col xs="12" style={{textAlign: 'end', display:this.state.btnForSend}}>
                <Button onClick={()=>{ openModalLote(true) }}>Enviar a lote</Button>
              </Col>

            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Foodchain ID</th>
                <th>Fecha registro</th>
                <th>Madre arete (ID)</th>
                <th>Animal arete (ID)</th>
                <th>Fecha nacimiento</th>
                <th>Raza</th>
                <th>Edad</th>
                <th>Sexo</th>
                <th>Peso</th>
                <th>Enviar a lote <Col md={12}><Input style={{width: '100%',margin: 0}} className="check_all" name="check_all" onClick={()=>{checkAll()}} type="checkbox"/></Col> </th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {this.state.data_traza_inventory.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.madre_id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.animal_id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.fecha_nacimiento}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.raza}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.edad}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.sexo}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.peso_inicial}
                    </span>
                   </td>
                   <td>
                      <Col md="12">
                        <Input style={{width: '100%',margin: 0}} className="lote_check_send" name="lote_check_send" onClick={()=>{checkSendLote()}} type="checkbox" value={value.id}/>
                      </Col>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/meats/details-animal",
                        search: "?code="+value.codigo+"&id_animal="+value.id+'&lote='+value.type_lote+"&edit",
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     Editar
                     </ComponentLink>
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}
export function ModalLote(props){
  const [value,setValue] = useState()
  const [lote,setLote] = useState('0')


  const setInfoLote = (type_redirect) => {
    var numero_lote_select = jQuery("#selectLoteVal").val()
    var numero_lote;
    var type_code;
    var lote_alias = jQuery("#lote_alias").val();
    var code_param = jQuery("#selectLoteVal option:selected").text().split('*')[1];
     if (numero_lote_select=='0') {
       numero_lote = jQuery("#lote_numero").val()
       type_code = 1
     }else{
       numero_lote = jQuery("#selectLoteVal").val()
       type_code = 2
     }
     props.setDataLote(numero_lote,type_code,type_redirect,code_param,lote_alias)
  }
  const changeLoteVal = (valor) => {
    props.changeValLote(valor)
    //setValue(valor)
  }
  const changeLoteValSelect = (valor) => {
    var alias_lote = jQuery("#selectLoteVal option:selected").text().split('*')[2];
    if (alias_lote!=undefined) {
      jQuery("#lote_alias").val(alias_lote)
    }
    setLote(valor)
  }

  useEffect(()=>{
    setValue(props.dataCountLote)
  });
return(
    <Modal isOpen={props.isOpenModal} size="md">
        <ModalBody>
            <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Elegir Lote Existente
                    </label>
                    <Input
                    onChange={(e)=>{changeLoteValSelect(e.target.value)}}
                    id="selectLoteVal" type="select" >
                      <option value="0">Seleccione...</option>
                      {props.urlParamsExtend.map((value,index)=>{
                        return (<option value={value.lote_numero} key={index}>{(value.alias_lote==null?(value.lote_numero+'*'+value.codigo): value.lote_numero+'*'+value.codigo+'*'+value.alias_lote ) }</option>)
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                  <Col md={12}>
                    <label style={{fontWeight: '700'}}>Nombre Alias</label>
                    <Input style={{width: '100%',margin: 0}} id="lote_alias" name="lote_alias" type="text" />
                  </Col>
          {(lote!='0')?(null):(
            <Col md={12}>
                              <label style={{fontWeight: '700'}}>Lote Número</label>
                                <Input style={{width: '100%',margin: 0}} id="lote_numero" name="lote_numero" onChange={(e)=>{changeLoteVal(e.target.value)}} type="text" />
                              </Col>
                              )}
        </ModalBody>
        <ModalFooter>
          <Button className="btnDisabled" onClick={()=> {setInfoLote(1)} }>
            Guardar y permanecer
          </Button>
          <Button className="btnDisabled" onClick={()=> {setInfoLote(2)} }>
            Guardar e ir al lote
          </Button>
          <Button className="btnDisabled" onClick={()=> {props.changeEventModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
  )
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
          var dataPermission = this.context.valueGlobal
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <InventoryAnimal {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
