import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";

export default class SectionCompartiment extends React.Component {
    state={
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false]
    }
  async componentDidMount(){
  }
  render(){
        const opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
    const openedChild = (isChange,ident) =>{
      console.log(isChange)
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
      const openedNextChild = (isChange,ident) =>{
      console.log(isChange)
      if (isChange==undefined) {
        this.setState({openColapseNextChild:{[ident]: true }});
        }else{
        this.setState({openColapseNextChild:{[ident]: !isChange }});
        }
      }
  return (
    <>
 <div id="compartiment-tab" className="clearfix eael-tab-content-item inactive" data-title-link="compartiment-tab">
                                  <div data-elementor-type="page" data-elementor-id="5520" className="elementor elementor-5520">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">

    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7d2af1d" data-id="fead0bd" data-element_type="column">
    <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-94d79b2 elementor-widget elementor-widget-spacer" data-id="94d79b2" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-eb1f800 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="eb1f800" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
            <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.compartment_receipt_animal[0]==undefined)?('#f84d43'):((this.props.DataRow.compartment_receipt_animal[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-info" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Recibo de animales</h3></div>
      </div> 
      </div>
        </div>
        <div className="elementor-element elementor-element-b563099 elementor-widget elementor-widget-toggle" data-id="b563099" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[1],1)}} id="elementor-tab-title-1901" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-1901" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Información</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[1]} id="elementor-tab-content-1901" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-1901">
          <ul>
              {
                  (this.props.DataRow.compartment_receipt_animal[0]==undefined)
                  ?
                  'Sin datos'
                  :
               this.props.DataRow.compartment_receipt_animal.map((value,index)=>{
                                return (<li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=>{openedChild(this.state.openColapseChild['data'+index],'data'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"data"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Datos {index+1}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['data'+index]} style={{width: '280%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"data"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                    <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.nombre_compartimento==null)?(null):((valuetwo.nombre_compartimento=='null')?(null):((valuetwo.nombre_compartimento=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre del Compartimento:</label><br /> {valuetwo.nombre_compartimento}</Col>)))}
                                                     {(valuetwo.nombre_productor==null)?(null):((valuetwo.nombre_productor=='null')?(null):((valuetwo.nombre_productor=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre del productor:</label><br /> {valuetwo.nombre_productor}</Col>)))}
                                                     {(valuetwo.nombre_finca==null)?(null):((valuetwo.nombre_finca=='null')?(null):((valuetwo.nombre_finca=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre de la finca:</label><br /> {valuetwo.nombre_finca}</Col>)))}
                                                     {(valuetwo.georeferencia==null)?(null):((valuetwo.georeferencia=='null')?(null):((valuetwo.georeferencia=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Georeferencia:</label><br /> {valuetwo.georeferencia}</Col>)))}
                                                     {(valuetwo.ciudad==null)?(null):((valuetwo.ciudad=='null')?(null):((valuetwo.ciudad=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Ciudad:</label><br /> {valuetwo.ciudad}</Col>)))}
                                                     {(valuetwo.estado==null)?(null):((valuetwo.estado=='null')?(null):((valuetwo.estado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Estado:</label><br /> {valuetwo.estado}</Col>)))}
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.guia_numero==null)?(null):((valuetwo.guia_numero=='null')?(null):((valuetwo.guia_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Guía Numero:</label><br /> {valuetwo.guia_numero}</Col>)))}
                                                     {(valuetwo.precinto_numero==null)?(null):((valuetwo.precinto_numero=='null')?(null):((valuetwo.precinto_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Precinto Numero:</label><br /> {valuetwo.precinto_numero}</Col>)))}
                                                     {(valuetwo.fecha_recibido==null)?(null):((valuetwo.fecha_recibido=='null')?(null):((valuetwo.fecha_recibido=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Recibido:</label><br /> {valuetwo.fecha_recibido}</Col>)))}
                                                     {(valuetwo.hora_recibido==null)?(null):((valuetwo.hora_recibido=='null')?(null):((valuetwo.hora_recibido=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de Recibido:</label><br /> {valuetwo.hora_recibido}</Col>)))}
                                                     {(valuetwo.cantidad_animales==null)?(null):((valuetwo.cantidad_animales=='null')?(null):((valuetwo.cantidad_animales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de Animales:</label><br /> {valuetwo.cantidad_animales}</Col>)))}
                                                     {(valuetwo.recibido_por==null)?(null):((valuetwo.recibido_por=='null')?(null):((valuetwo.recibido_por=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Recibido Por:</label><br /> {valuetwo.recibido_por}</Col>)))}
                                                     {(valuetwo.nro_identificacion==null)?(null):((valuetwo.nro_identificacion=='null')?(null):((valuetwo.nro_identificacion=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro. de Identificación:</label><br /> {valuetwo.nro_identificacion}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                        {/*{Object.values(value).map((valuetwo,index)=>{
                                       return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={index}>{valuetwo}</li>))))
                                      })}*/}
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>)
                })
              }
          </ul>
          </Collapse>
        </div>
                </div>
        </div>
        </div>

        <div className="elementor-element elementor-element-5726141 elementor-widget elementor-widget-spacer" data-id="5726141" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
    </div>
    </div>
    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7d2af1d" data-id="fead0bd" data-element_type="column">
    <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-94d79b2 elementor-widget elementor-widget-spacer" data-id="94d79b2" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-eb1f800 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="eb1f800" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
            <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.compartment_exit_animal[0]==undefined)?('#f84d43'):((this.props.DataRow.compartment_exit_animal[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-info" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Salida de animales</h3></div>
      </div> 
      </div>
        </div>
        <div className="elementor-element elementor-element-b563099 elementor-widget elementor-widget-toggle" data-id="b563099" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
              <div className="elementor-toggle-item">
          <h6 onClick={()=>{opened(this.state.openColapse[2],2)}} id="elementor-tab-title-1901" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-1901" aria-expanded="false">
                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                              <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                          </span>
                        <div className="elementor-toggle-title">Información</div>
          </h6>

          <Collapse isOpen={this.state.openColapse[2]} id="elementor-tab-content-1901" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-1901">
          <ul>
              {
                  (this.props.DataRow.compartment_exit_animal[0]==undefined)
                  ?
                  'Sin datos'
                  :
               this.props.DataRow.compartment_exit_animal.map((value,index)=>{
                                return (<li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=>{openedChild(this.state.openColapseChild['datasa'+index],'datasa'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datasa"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Datos {index+1}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['datasa'+index]} style={{width: '265%',position: 'relative',right: '100%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datasa"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                          <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.nombre_finca==null)?(null):((valuetwo.nombre_finca=='null')?(null):((valuetwo.nombre_finca=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre de la finca:</label><br /> {valuetwo.nombre_finca}</Col>)))}
                                                     {(valuetwo.nombre_compartimento==null)?(null):((valuetwo.nombre_compartimento=='null')?(null):((valuetwo.nombre_compartimento=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre del Compartimento:</label><br /> {valuetwo.nombre_compartimento}</Col>)))}
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.guia_numero==null)?(null):((valuetwo.guia_numero=='null')?(null):((valuetwo.guia_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Guía Numero:</label><br /> {valuetwo.guia_numero}</Col>)))}
                                                     {(valuetwo.precinto_numero==null)?(null):((valuetwo.precinto_numero=='null')?(null):((valuetwo.precinto_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Precinto Numero:</label><br /> {valuetwo.precinto_numero}</Col>)))}
                                                     {(valuetwo.fecha_salida==null)?(null):((valuetwo.fecha_salida=='null')?(null):((valuetwo.fecha_salida=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Salida:</label><br /> {valuetwo.fecha_salida}</Col>)))}
                                                     {(valuetwo.hora_salida==null)?(null):((valuetwo.hora_salida=='null')?(null):((valuetwo.hora_salida=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de Salida:</label><br /> {valuetwo.hora_salida}</Col>)))}
                                                     {(valuetwo.cantidad_animales==null)?(null):((valuetwo.cantidad_animales=='null')?(null):((valuetwo.cantidad_animales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de Animales:</label><br /> {valuetwo.cantidad_animales}</Col>)))}
                                                     {(valuetwo.enviado_por==null)?(null):((valuetwo.enviado_por=='null')?(null):((valuetwo.enviado_por=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Enviando Por:</label><br /> {valuetwo.enviado_por}</Col>)))}
                                                     {(valuetwo.nro_identificacion==null)?(null):((valuetwo.nro_identificacion=='null')?(null):((valuetwo.nro_identificacion=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro. de Identificación:</label><br /> {valuetwo.nro_identificacion}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                        {/*{Object.values(value).map((valuetwo,index)=>{
                                       return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={index}>{valuetwo}</li>))))
                                      })}*/}
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>)
                })
              }
          </ul>
          </Collapse>
        </div>
                </div>
        </div>
        </div>

        <div className="elementor-element elementor-element-5726141 elementor-widget elementor-widget-spacer" data-id="5726141" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
    </div>
    </div>

              </div>
    </section><section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section></div>
                                        </div>
    </>
  );
}
}

//export default Elements;
