import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Modal
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import { get_permission_main,get_permission } from "network/ApiAxios";
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import AlertMRV from "components/Headers/AlertMRVOR.js";
import { view_content } from "network/ApiAxios";
class CardsHeader extends React.Component {
  static contextType = UserContext
  state = {
    data_permission_company:[],
    data_service:[],
    show_tool:false,
    type_traza_user:null,
    type_traza_user_t:null,
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var data = await get_permission_main(jsondata._id)
    var dataPermission = await get_permission(jsondata._id)
          data.datosPermission.forEach(el => {
            if (el.type_traza_permission=="VF-M") {
              el.type_traza = "Trazabilidad carnicos"
            }
            if (el.type_traza_permission=="VF") {
              el.type_traza = "Trazabilidad de frutas y vegetales"
            }

        if (el.type=='admin'||el.type=="admin\n") {
          el.type_other = 'Admin';
        }
        if (el.type=='options'||el.type=="options\n") {
          el.type_other='Perfil para editar solo secciones'
        }
        if (el.type=='view'||el.type=="view\n") {
          el.type_other='Perfil para que vea el proceso solamente'
        }
      });
    this.setState({data_permission_company:data.datosPermission,
     data_service:data.dataService,
     type_traza_user:dataPermission[0].type_traza,
     type_traza_user_t:jsondata.type_traza
   })
  }
  changeCompany=async(valueUrl,typeU)=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    jsondata.userType=typeU
    localStorage.setItem("user", JSON.stringify(jsondata));
    window.location.href=valueUrl
  }
  changeRoleMrv=(typeChange)=>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    jsondata.type_traza=typeChange
    localStorage.setItem("user", JSON.stringify(jsondata));
    this.props.history.push('/admin/report-production-users');
    //window.location.reload()
  }
render(){
  const sendService = (data) => {
    // console.log(data[0])
    var getDataJson = data[0]
    this.props.history.push('/admin/payment',{getDataJson});
  }
  const contentSectionService = (data) => {
    var content = []
    for (var i = 0; i < data.length; i++) {
      let date_1 = new Date(data[i].fecha_limite);
      let date_2 = new Date();      
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      var typeTraza = ''
      var fullnfo = data[i].full_info.split('>')
      var infoData = null ;
      if (fullnfo.length>0) {
        infoData = fullnfo[0]
      }
      if (data[i].type_traza.indexOf('VF')!=-1) {
          typeTraza = typeTraza+' Frutas y Vegetales';
      }
      if (data[i].type_traza.indexOf('M')!=-1) {
          typeTraza = typeTraza+' Carnicos';
      }
      if (data[i].type_traza.indexOf('G')!=-1) {
          typeTraza = typeTraza+' Granos';
      }
      // if (data[i].type_traza=='VF-M') {
      //     typeTraza = infoData+', Vegetales y frutas - Carnicos';
      // }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
      content = <>
                  <div className="col-md-12" style={{fontWeight: '600', color:(TotalDays>5)?('gray'):('red')}}>*Servicio: {typeTraza}.
                  <br />
                    Costo del Servicio: {(data[i].precio=='0')?('Gratis por '+data[i].dias+' dias'):('$'+data[i].precio_total+' por '+data[i].dias+' dias')}
                  <br /> 
                    Fecha Vencimiento: {data[i].fecha_limite}. 
                  <br />
                    {(TotalDays>0)?'Faltan: '+TotalDays+' Dia(s)':'Servicio Finalizado'}
                  <br />
                    {(jsondata.userType!=1)?(null):((TotalDays>5)?(null):((data[i].precio=='0')?(null):(<a href="javascript:void(0);" onClick={()=>{sendService(data)}}>Volver a adquirir este sevicio</a>)))}
                  </div>
                </>
    }
    return content
  }
  const componentOne=(typeC)=>{
    var component = []
if (typeC==1) {
    component = <>
                           <Col xl="12">
                                  <Row>
                                    <div className="col-md-11">
                                      <span className="h2 font-weight-bold mb-0">Perfil del usuario</span>
                                    </div>
                                        <Col md={1}>
                                        <a href="#" onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                                          <h3 class="mb-0">X</h3>
                                        </a>
                                        </Col>
{/*                                    <Col className="col-auto">
                                      <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                                        <i className="fas fa-building" />
                                      </div>
                                    </Col>*/}
                                  </Row>
                                  <p className="mb-0 text-sm">
                                    <span className="text-nowrap">Seleccione la empresa con la que desea trabajar.</span>
                                  </p>
                                  <Row>
                                    <Col md={4}>
                                       <Card>
                                        <CardBody>
                                        <span>Perfil Productor</span><hr style={{margin: '10px'}} />
                                       <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeCompany("/admin/home",1)}}> Mi empresa</button>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    {this.state.data_permission_company.map((value,index)=>{
                                    return(<><Col md={4}>
                                               <Card>
                                                <CardBody>
                                                <span>{value.type_other}</span><hr style={{margin: '10px'}} />
                                               <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeCompany("/admin/home?company="+value.user_id_connection,2)}}> Invitación como colaborador para la empresa {value.empresa_collab} </button>
                                                </CardBody>
                                              </Card>
                                            </Col></>)
                                    })}
                                  </Row>
                            </Col>
    </>
}
if (typeC==2) {
  component = <>

        <Col xl="12">
          <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">Perfil del usuario</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                      <i className="fas fa-building" />
                    </div>
                  </Col>
{/*            <Col md={1}>
            <a href="#" onClick={()=> {this.initData(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>*/}
                </Row>
                <p className="mb-0 text-sm">
                  <span className="text-nowrap">Seleccione la empresa con la que desea trabajar.</span>
                </p>
                <Row>
                {this.state.data_permission_company.map((value,index)=>{
                  return(<><Col md={4}>
                                           <Card>
                                            <CardBody>
                                            <hr />
                                            <span>{value.type_other}</span><hr />
                                           <a className="font-weight-bold" href={"/admin/home?company="+value.user_id_connection}>Empresa {value.empresa_collab}</a>
                                            </CardBody>
                                          </Card>
                                        </Col></>)
                })}
                </Row>
              </CardBody>
            </Card>
          </Col>


  </>
}
    return component
  }
const componentTwo=()=>{
  var component=[]
  component = <>
        <Col xl="12">
          <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">MRV</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                      <i className="fas fa-user-tag" />
                    </div>
                  </Col>
                </Row>
                <p className="mb-0 text-sm">
                  <span className="text-nowrap">Seleccione el Rol con la que desea trabajar.</span>
                </p>
                <Row>
                  <Col md={4}>
                     <Card>
                      <CardBody>
                      <span className="fas fa-desktop"></span> 
                        {(this.state.type_traza_user_t=="ON")?(<span style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#6fc142'}}> - Rol actualmente seleccionado</span>):(null)}
                      <hr />
                     <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeRoleMrv("ON")}}> Monitoreador</button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                     <Card>
                      <CardBody>
                      <span className="fas fa-search"></span>
                      {(this.state.type_traza_user_t=="RE")?(<span style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#6fc142'}}> - Rol actualmente seleccionado</span>):(null)}
                      <hr />
                     <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeRoleMrv("RE")}}> Revisor</button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                     <Card>
                      <CardBody>
                      <span className="fas fa-check"></span>
                      {(this.state.type_traza_user_t=="VA")?(<span style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#6fc142'}}> - Rol actualmente seleccionado</span>):(null)}
                      <hr />
                     <button style={{fontWeight: 'bold',border: 'none',backgroundColor: 'transparent',color: '#2d5980'}} onClick={()=>{this.changeRoleMrv("VA")}}> Validador</button>
                      </CardBody>
                    </Card>
                  </Col>
{/*                {this.state.data_permission_company.map((value,index)=>{
                  return(<><Col md={4}>
                                           <Card>
                                            <CardBody>
                                            <hr />
                                            <span>{value.type_other}</span><hr />
                                           <a className="font-weight-bold" href={"/admin/home?company="+value.user_id_connection}>Empresa {value.empresa_collab}</a>
                                            </CardBody>
                                          </Card>
                                        </Col></>)
                })}*/}
                </Row>
              </CardBody>
            </Card>
          </Col>
  </>
  return component  
}

  return (
    <>
      <div className="header bg-traza-blue pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/*<h6 className="h2 text-white d-inline-block mb-0">{this.props.name}</h6>{" "}*/}
                {(this.props.name=="Notificaciones")?(null):(
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
{/*                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {this.props.parentName}
                    </a>
                  </BreadcrumbItem>
                */}
                  <BreadcrumbItem aria-current="page" className="active">
                    {this.props.name}
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                      <GuideMessage section="home" section_two="none" section_table_form="null" />
                  </BreadcrumbItem>

                </Breadcrumb>
                  )}

              </Col>
            </Row>
            <Row>
{(this.props.name=='Pagos por servicios')?(<Col xl="12">
              <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Bienvenido
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">Foodchain Trace</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-traza-green text-white rounded-circle shadow">
                          <i className="fas fa-tasks" />
                        </div>
                      </Col>
                    </Row>

                    <hr />
                    <Row>
                      {contentSectionService(this.state.data_service)}
                    </Row>
                  </CardBody>
                </Card>
              </Col>):(<AlertMRV {...this.props} content_area="0"/>)}

              {(this.state.data_permission_company[0]==null)?(null):(<>
                {(this.state.data_permission_company[0].role==1)?(<>
                  {(this.state.data_permission_company.length>0)?(<>
                    <Modal isOpen={this.props.forModalProp} size="xl">
                      <ModalBody>
                        {componentOne(this.state.data_permission_company[0].role)}
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                              Cerrar
                            </Button>
                          </ModalFooter>
                        </Modal>

                    </>):(null)}
                </>):(null)}
                {(this.state.data_permission_company[0].role==2)?(<>

                    <Modal isOpen={this.props.forModalProp} size="xl">
                      <ModalBody>
                        {componentOne(this.state.data_permission_company[0].role)}
                          </ModalBody>
                          <ModalFooter>
                            <Button onClick={()=> {this.props.closeModal(false,'modalPerfile')} }>
                              Cerrar
                            </Button>
                          </ModalFooter>
                        </Modal>

                  </>):(null)}
              </>)}
              {(this.state.type_traza_user=='ON-RE-VA')?(componentTwo()):(null)}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
 }
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
