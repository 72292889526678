import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, row_transport_plant_view, data_trazabilidad, main_content,main_content_filter, data_fertilizer_production, add_transport_plant, fertilizer_production, row_transport_plant, delete_trans_vehicule, delete_fertilizer } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import FormPlantProduct from "./Subs/FormPlant/FormPlantProduct";
import FormPlantSample from "./Subs/FormPlant/FormPlantSample";
import FormPlantTreatment from "./Subs/FormPlant/FormPlantTreatment";
import FormPlantWeight from "./Subs/FormPlant/FormPlantWeight";
import InfoPlantTransport from "./Subs/InfoPlantTransport/InfoPlantTransport";
import InfoPlantVehicule from "./Subs/InfoPlantTransport/InfoPlantVehicule";
import jQuery from 'jquery';

export class SectionPlant extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    link_id:null,
    sendOpenModalTransport:false,
    dataTransporte:[],
    link_id_transport:null,
    return_breack:1,
    return_breack_p:1,
    return_breack_s:1,
    return_breack_w:1,
    return_breack_t:1,
    data_spread:[],
    data_cross_plant:[],
    delete_register:null,
    is_change:'no',
    get_content:[],
    get_first_info:''
  }
  async componentDidMount(){  
    var getData = await main_content('transport_cosecha')
    this.setState({field_content:getData})
    this.initData(this.props.getIdPlant)
  }
   async componentDidUpdate(prevProps, prevState, snapshot) {
    var get_data_plant = this.props.sendInfoProps.dataPlant
       if (get_data_plant.length!=0) {
        if (this.state.get_content.length==0) {
           var getDataPlant = await main_content_filter('create_plant_product',get_data_plant)
           getDataPlant.map((a,b)=>{
             if (a.include_info==1) {
               this.setState({get_first_info:a.name_field})
             }
           })
           this.setState({get_content:getDataPlant})
        }
       }
      }
  initData = async (id_set,verify)=>{
    this.setState({sendOpenModal:verify,link_id:id_set})
    this.setInfoTransport(id_set,true)
  }
deleteTransVehi = async (id) => {
   var userdata = localStorage.getItem('user');
   var jsondata = JSON.parse(userdata);
   var gerParam = new URLSearchParams(window.location.search).get("code");
   if (window.confirm("Deseas eliminar este registro?")) {
   var dataReturn = await delete_trans_vehicule(id,jsondata._id,gerParam,this.state.vehicule_id)
           if (dataReturn=="exito") {
           this.setState({mess:'Se eliminó exitosamente.'})
           this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
           //setTimeout(function(){window.location.reload()}, 3000);
         }else{
           this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
           this.setState({btnd:false})
         }
   }
 }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }

    setInfoTransport = async (id_set,isOpen) => {
    var data_select = []
    if (this.state.field_content.length>0) {
      for (var i = 0; i < this.state.field_content.length; i++) {
        if (this.state.field_content[i].link!=null) {
          var get_csc=this.state.field_content[i].link.split('.')[1]
          if (get_csc=='vgp' || get_csc=='vge' || get_csc=='vgc') {
            data_select[i]={field:this.state.field_content[i].name_field}
          }else{
            data_select[i]={field:this.state.field_content[i].name_field,link:this.state.field_content[i].link}
          }
        }else{
          data_select[i]={field:this.state.field_content[i].name_field}
        }
      }
    }
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
       var gerParam = new URLSearchParams(window.location.search).get("code");
       //var data = await data_fertilizer_production()
       var data = await row_transport_plant(id_set,'transport_cosecha',JSON.stringify(data_select),gerParam)
       // var dataCosecha = await data_cosecha(jsondata.user_id_origin,gerParam)
       // this.setState({peso_unidad:(dataCosecha==null)?(null):(dataCosecha.peso_unidad=="null")?(null):(dataCosecha.peso_unidad),})
       var plant_data = data.filter((data,index)=>{return data.verifyCheck==1})
       if (plant_data[0]!=undefined) {
                             setTimeout(function(){
                       data.map((value,index)=>{
                       const verifyCheck = value.verifyCheck && value.id_set==id_set;
                       if (document.getElementsByClassName("thisValueTransport")[index]!=undefined) {
                         document.getElementsByClassName("thisValueTransport")[index].checked=verifyCheck
                       }
                       })
                     }, 500);
        // if (id_set==null) {
        // }
         this.putValueTransport(plant_data[0].cosecha_id,true,'no')
       }
       this.setState({
         sendOpenModalTransport:isOpen,
         dataTransporte:data,
         plant_product_id:id_set
       })
     }
     putValueTransport = async (changeValue,checked,ich) =>{
      this.setState({is_change:ich})
      this.setState({idCosechaTransport:[[changeValue,checked]]})
      this.setState({link_id_transport:changeValue})
      this.changeBackClk('glo')
     }
    changeBackClk = (type,data,resp) =>{
      if (type=='glo') {
      this.setState({return_breack:0})
        this.setState({return_breack_p:0})
        this.setState({return_breack_s:0})
        this.setState({return_breack_t:0})
        this.setState({return_breack_w:0})
      }

      if (data!=undefined) {
        this.setState({data_spread:data})
      }
      if (resp=='ipt') {
        this.setState({return_breack:type})
        this.setState({return_breack_p:0})
        this.setState({return_breack_s:0})
        this.setState({return_breack_t:0})
        this.setState({return_breack_w:0})
      }
      if (resp=='fpp') {
        this.setState({return_breack_p:type})
      }
      if (resp=='fpt') {
        this.setState({return_breack_t:type})
      }
      if (resp=='fps') {
        this.setState({return_breack_s:type})
      }
      if (resp=='fpw') {
        this.setState({return_breack_w:type})
      }
     }
deleteRegister = async (id_set) => {
  jQuery('#id_register_prod').val(id_set)
  await this.initData(id_set,true)
   if (window.confirm("Deseas eliminar este registro?")) {
    this.setState({delete_register:'delete_register'})
  }else{
    this.initData(null,false)
  }
  jQuery('.modal').hide()
 }  
  deleteRegisterListe = async (id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name) => {
    var get_id_register_prod=jQuery('#id_register_prod').val()
    this.setState({delete_register:null})
    this.initData(null,false)
  }     
  render(){
    const insertTransport = async (plant_id) => {
       //this.setState({sendOpenModalTransport:false})

          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(window.location.search).get("code");
if (this.state.idCosechaTransport!=undefined) {
          var resultData = await add_transport_plant(this.state.idCosechaTransport.filter(Boolean),plant_id,jsondata.user_id_origin,gerParam)
}
         
         //messageReturn(resultData,'mess1','Transporte Guardado')
    }
    const insertTransportData = async (getData) => {
      this.setState({data_cross_plant:getData})
    }
  return (
    <>
{(this.state.field_content==undefined)?(null):(<>
              <InfoPlantTransport return_breack={this.state.return_breack} changeBack={this.changeBackClk} field_content={this.state.field_content} link_id_transport={this.state.link_id_transport} putLinkId={this.state.link_id}/>
              <InfoPlantVehicule return_breack={this.state.return_breack} changeBack={this.changeBackClk} link_id_transport={this.state.link_id_transport} putLinkId={this.state.link_id}/>
  </>)}

              
               <hr />
{(this.state.link_id==null)?(null):(<>
             <CardHeader style={{padding: 0}}>
             <h3 className="mb-0">Registro {this.props.getIndex+1}</h3>
           </CardHeader>
        <FormPlantProduct get_content={this.state.get_content} is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  insertTransportAfter={insertTransport} {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_p={this.state.return_breack_p} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal}/>
        <FormPlantSample is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_s={this.state.return_breack_s} putLinkId={this.state.link_id}/>
        <FormPlantTreatment is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_t={this.state.return_breack_t} putLinkId={this.state.link_id}/>
        <FormPlantWeight is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_w={this.state.return_breack_w} putLinkId={this.state.link_id}/>
  </>)}
    </>
  );
 }
}

 export default class ModalPlantDatosView extends React.Component {
    
   render(){

     const setInfoTransport = async (id_plant,isOpen) => {
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
       var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
       var data = await row_transport_plant_view(id_plant,jsondata._id,this.props.passRouteTraza,codigoTrazaTwo)

       this.setState({
         sendOpenModalTransport:isOpen,
         dataTransporte:data,
         plant_product_id:id_plant
       })
     }
     const setModalTransport = async (changeOpen) =>{
       this.setState({sendOpenModalTransport:changeOpen})
     }
   return (
     <>
             <Row>
             {(this.props.sendInfoProps.dataPlant.length==0)?(null):
                   this.props.sendInfoProps.dataPlant?.create_plant_product.map((value,index)=>{
                             return (
                                      <Col md="12" className="text-center">
                                      <SectionPlant {...this.props} getIdPlant={value.id} getIndex={index} passCode={this.props.passRouteTraza} />
                                      </Col>
                                     ) 
                      })
            }
             </Row>
     </>
   );
  }
 }



// import React, {createRef, useEffect,useState} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { all_data_plant, row_transport_plant_view, add_transport_plant, row_transport_plant, get_data_transport } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import jQuery from 'jquery';
// import config from "config";

// export default class ModalPlantDatosView extends React.Component {
    
//   render(){


//     const setInfoTransport = async (id_plant,isOpen) => {
//       var userdata = localStorage.getItem('user');
//       var jsondata = JSON.parse(userdata);
//       var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//       var data = await row_transport_plant_view(id_plant,jsondata._id,this.props.passRouteTraza,codigoTrazaTwo)

//       this.setState({
//         sendOpenModalTransport:isOpen,
//         dataTransporte:data,
//         plant_product_id:id_plant
//       })
//     }
//     const setModalTransport = async (changeOpen) =>{
//       this.setState({sendOpenModalTransport:changeOpen})
//     }
//   return (
//     <>
//           <CardBody>
//             <Row>
//             {
//                   this.props.sendInfoProps.dataPlant.map((value,index)=>{
//                             return (
//                                      <Col md="12" className="text-center">
//                                      <SectionPlant getIdPlant={value.id} getIndex={index} passCode={this.props.passRouteTraza} />
//                                      </Col>
//                                     ) 
//                      })
//            }
//             </Row>
//          </CardBody>
//     </>
//   );
//  }
// }
// export class SectionPlant extends React.Component {
//   state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//     sendOpenModalTransport:false,
//     dataTransporte:[],
//   }
//     async componentDidMount(){

//       this.setInfoPlant(this.props.getIdPlant)
//     }
//        setInfoPlant = async (id_set) =>{
//               var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//         if (id_set!=null) {      
//                 var dataTrazabilidadvf = await all_data_plant(id_set)
//             if (dataTrazabilidadvf==null) {
//               this.setDataCamposEmpty()
//             }else{
//               this.setDataCampos(dataTrazabilidadvf)
//             }
//         }else{
//               this.setDataCamposEmpty()
//         }
//       }    
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 plant_product_id:'noind',
//                 fecha:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora:timeString,
//                 guia_numero:'',
//                 lote_numero:'',
//                 informacion_adicional_plant_product:'',
//                 file_document_plant_product:undefined,

//                 plant_sample_id:'noind',
//                 cantidad_muestreada:'',
//                 porcentaje_muestras:'',
//                 seleccion_utilizada:'',
//                 informacion_adicional_plant_sample:'',
//                 file_document_plant_sample:undefined,

//                 plant_weight_id:'noind',
//                 peso_unidad:'',
//                 tipo_empaque:'',
//                 total_unidades:'',
//                 peso_por_unidad:'',
//                 peso_total:'',
//                 informacion_adicional_plant_weight:'',
//                 file_document_plant_weight:undefined,

//                 plant_treatment_id:'noind',
//                 fecha_inicio:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora_inicial:timeString,
//                 fecha_final:date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate(),
//                 hora_final:timeString,
//                 tipo_tratamiento:'',
//                 informacion_adicional_plant_treatment:'',
//                 file_document_plant_treatment:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.product!=null) {
//         this.setState({
//                 plant_product_id:data.product.id,
//                 fecha:data.product.fecha_salida,
//                 hora:data.product.hora,
//                 guia_numero:data.product.guia_numero,
//                 lote_numero:data.product.lote_numero,
//                 informacion_adicional_plant_product:data.product.informacion_adicional,
//                 file_document_plant_product:data.product.archivo
//         })
//       }
//       if (data.sample!=null) {
//         this.setState({
//                 plant_sample_id:data.sample.id,
//                 cantidad_muestreada:data.sample.cantidad_muestreada,
//                 porcentaje_muestras:data.sample.porcentaje_muestras,
//                 seleccion_utilizada:data.sample.seleccion_utilizada,
//                 informacion_adicional_plant_sample:data.sample.informacion_adicional,
//                 file_document_plant_sample:data.sample.archivo
//         })
//       }
//       if (data.weight!=null) {
//         this.setState({
//                 plant_weight_id:data.weight.id,
//                 peso_unidad:data.weight.peso_unidad,
//                 tipo_empaque:data.weight.tipo_empaque,
//                 total_unidades:data.weight.total_unidades,
//                 peso_por_unidad:data.weight.peso_por_unidad,
//                 peso_total:data.weight.peso_total,
//                 informacion_adicional_plant_weight:data.weight.informacion_adicional,
//                 file_document_plant_weight:data.weight.archivo
//         })
//       }
//       if (data.treatment!=null) {
//         this.setState({
//                 plant_treatment_id:data.treatment.id,
//                 fecha_inicio:data.treatment.fecha_inicio,
//                 hora_inicial:data.treatment.hora_inicial,
//                 fecha_final:data.treatment.fecha_final,
//                 hora_final:data.treatment.hora_final,
//                 tipo_tratamiento:data.treatment.tipo_tratamiento,
//                 informacion_adicional_plant_treatment:data.treatment.informacion_adicional,
//                 file_document_plant_treatment:data.treatment.archivo
//         })
//       }
//     }
//   render(){
//     return(
//       <>
//       <ModalTransport plantId={this.props.getIdPlant} passCodeTrans={this.props.passCode} />
//       <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Ingreso del Producto {this.props.getIndex+1}</h3>
//           </CardHeader>
//           <CardBody className="text-center">
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha
//                   </label>
//                   <br />
//                   <span>{this.state.fecha}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora
//                   </label>
//                   <br />
//                   <span>{this.state.hora}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Guia Numero
//                   </label>
//                   <br />
//                   <span>{this.state.guia_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Número
//                   </label>
//                   <br />
//                   <span>{this.state.lote_numero}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_plant_product}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_plant_product==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_plant_product}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.cantidad_muestreada==null)?'none':'flex'}}>
//           <CardHeader>
//             <h3 className="mb-0">Muestra de Calidad</h3>
//           </CardHeader>
//           <CardBody className="text-center">
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Cantidad Muestreada
//                   </label>
//                   <br />
//                   <span>{this.state.cantidad_muestreada}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Porcentaje de Muestras
//                   </label>
//                   <br />
//                   <span>{this.state.porcentaje_muestras}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Seleccion Utilizada
//                   </label>
//                   <br />
//                   <span>{this.state.seleccion_utilizada}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_plant_sample}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_plant_sample==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_plant_sample}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//            </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.total_unidades==null)?'none':'flex'}}>
//           <CardHeader>
//             <h3 className="mb-0">Peso del Producto</h3>
//           </CardHeader>
//           <CardBody className="text-center">
//             <Row>
//             <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Peso/Unidad
//                   </label>
//                   <br />
//                   <span>{this.state.peso_unidad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Tipo de Empaque
//                   </label>
//                   <br />
//                   <span>{this.state.tipo_empaque}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <br />
//                   <span>{this.state.total_unidades}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso por Unidad
//                   </label>
//                   <br />
//                   <span>{this.state.peso_por_unidad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso total
//                   </label>
//                   <br />
//                   <span>{this.state.peso_total}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_plant_weight==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_plant_weight}>Ver archivo</a>)}</span>
//               </Col>)}
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_plant_weight}</span>

//                 </FormGroup>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.tipo_tratamiento==null)?'none':'flex'}}>
//           <CardHeader>
//             <h3 className="mb-0">Tratamiento</h3>
//           </CardHeader>
//           <CardBody className="text-center">
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Inicio
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_inicio}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora inicial
//                   </label>
//                   <br />
//                   <span>{this.state.hora_inicial}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha Final
//                   </label>
//                   <br />
//                   <span>{this.state.fecha_final}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora Final
//                   </label>
//                   <br />
//                   <span>{this.state.hora_final}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Tipo de Tratamiento
//                   </label>
//                   <br />
//                   <span>{this.state.tipo_tratamiento}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_plant_treatment==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_plant_treatment}>Ver archivo</a>)}</span>
//               </Col>)}
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_plant_treatment}</span>
//                 </FormGroup>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </>
//       )
//   }
// }
// export class ModalTransport extends React.Component{
//   state={
// fecha_salida_transport:null,
// hora_salida_transport:null,
// numero_guia_transport:null,
// nombre_finca_transport:null,
// lote_numero_transport:null,
// nombre_producto_transport:null,
// variedad_producto_transport:null,
// tipo_empaque_transport:null,
// total_unidades_transport:null,
// peso_unidad_transport:null,
// peso_total_transport:null,
// informacion_adicional_transport_transport:null,
// file_document_trasport:null,
// tipo_vehiculo_vehicule:null,
// capacidad_vehicule:null,
// placa_vehicule:null,
// nombre_chofer_vehicule:null,
// vehiculo_desinfectado_vehicule:null,
// informacion_adicional_vehicule_vehicule:null,
// file_document_vehicule:null,
//   }
//   async componentDidMount(){
//     var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     var gerParam = this.props.passCodeTrans
//     if (gerParam!=null) {
//       var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//     var data = await row_transport_plant(this.props.plantId,jsondata._id,gerParam,codigoTrazaTwo)
//     var plant_data = data.filter((data,index)=>{return data.verifyCheck==1})
//     this.putValueTransport(plant_data[0].cosecha_id,true)
//     }
//   }
//     putValueTransport = async (changeValue) =>{
//       var data_transport = await get_data_transport(changeValue)
  
//       if (data_transport.transport!=null) {
//         this.setState({
//                 fecha_salida_transport:(data_transport.transport.fecha_salida=="null")?(null):(data_transport.transport.fecha_salida),
//                 hora_salida_transport:(data_transport.transport.hora_salida=="null")?(null):(data_transport.transport.hora_salida),
//                 numero_guia_transport:(data_transport.transport.numero_guia=="null")?(null):(data_transport.transport.numero_guia),
//                 //guia_numero:(data_transport.transport.numero_guia=="null")?(null):(data_transport.transport.numero_guia),
//                 nombre_finca_transport:(data_transport.transport.nombre_finca=="null")?(null):(data_transport.transport.nombre_finca),
//                 lote_numero_transport:(data_transport.transport.lote_numero=="null")?(null):(data_transport.transport.lote_numero),
//                 nombre_producto_transport:( data_transport.transport.nombre_producto=="null")?(null):( data_transport.transport.nombre_producto),
//                 variedad_producto_transport:( data_transport.transport.variedad_producto=="null")?(null):( data_transport.transport.variedad_producto),
//                 tipo_empaque_transport:(data_transport.transport.tipo_empaque=="null")?(null):(data_transport.transport.tipo_empaque),
//                 total_unidades_transport:(data_transport.transport.total_unidades=="null")?(null):(data_transport.transport.total_unidades),
//                 peso_unidad_transport:(data_transport.transport.peso_unidad=="null")?(null):(data_transport.transport.peso_unidad),
//                 peso_total_transport:(data_transport.transport.peso_total=="null")?(null):(data_transport.transport.peso_total),
//                 informacion_adicional_transport_transport:(data_transport.transport.informacion_adicional=="null")?(null):(data_transport.transport.informacion_adicional),
//                 file_document_trasport:(data_transport.transport.archivo=="null")?(null):(data_transport.transport.archivo),
//         })
//       }
//       if (data_transport.vehicule!=null) {
//         this.setState({
//                 tipo_vehiculo_vehicule:(data_transport.vehicule.tipo_vehiculo=="null")?(null):(data_transport.vehicule.tipo_vehiculo),
//                 capacidad_vehicule:(data_transport.vehicule.capacidad=="null")?(null):(data_transport.vehicule.capacidad),
//                 placa_vehicule:(data_transport.vehicule.placa=="null")?(null):(data_transport.vehicule.placa),
//                 nombre_chofer_vehicule:(data_transport.vehicule.nombre_chofer=="null")?(null):(data_transport.vehicule.nombre_chofer),
//                 vehiculo_desinfectado_vehicule:(data_transport.vehicule.vehiculo_desinfectado=="null")?(null):(data_transport.vehicule.vehiculo_desinfectado),
//                 informacion_adicional_vehicule_vehicule:(data_transport.vehicule.informacion_adicional=="null")?(null):(data_transport.vehicule.informacion_adicional),
//                 file_document_vehicule:(data_transport.vehicule.archivo=="null")?(null):(data_transport.vehicule.archivo),
//         })
//       }
//       //this.setState({sendOpenModalTransport:changeOpen})

//     }
// render(){
//   return (
// <>
// <Card className="mb-4">
//           <CardHeader>
//           <Row>
//             <Col md={12}>
//               <h3 className="mb-0">Transporte</h3>
//             </Col>
//             </Row>
//           </CardHeader>
//             <CardBody>
// <br />
// {
//   (this.state.numero_guia_transport==undefined) 
// ? null
// :            <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre de la finca
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.nombre_finca_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Lote Número
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.lote_numero_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Número de Guía
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.numero_guia_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre del Producto
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.nombre_producto_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha Salida
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.fecha_salida_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Hora Salida
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.hora_salida_transport}</span>
//                 </FormGroup>
//               </Col>

//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Variedad del producto
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.variedad_producto_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Tipo de Empaque
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.tipo_empaque_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso por Unidad
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.peso_unidad_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.total_unidades_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Peso total
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.peso_total_transport}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.informacion_adicional_transport_transport}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label><br />
//                 {(this.state.file_document_trasport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_trasport}>{String(this.state.file_document_trasport).split('/').pop()}</a>)}
//               </Col>)}

//               </Row>}
//               <hr />
// {
//   (this.state.tipo_vehiculo_vehicule==undefined) 
// ? null
// :            <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Tipo de Vehiculo
//                   </label>
//                   <br /><span className="alert-title" data-notify="title">{this.state.tipo_vehiculo_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Capacidad
//                   </label><br />
//                   <span className="alert-title" data-notify="title">{this.state.capacidad_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Placa
//                   </label><br />
//                   <span className="alert-title" data-notify="title">{this.state.placa_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Nombre del Chofer
//                   </label><br />
//                   <span className="alert-title" data-notify="title">{this.state.nombre_chofer_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Vehiculo desinfectado
//                   </label><br />
//                   <span className="alert-title" data-notify="title">{this.state.vehiculo_desinfectado_vehicule}</span>

//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label><br />
//                   <span className="alert-title" data-notify="title">{this.state.informacion_adicional_vehicule_vehicule}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label><br />
//                 {(this.state.file_document_vehicule==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_vehicule}>{String(this.state.file_document_vehicule).split('/').pop()}</a>)}
//               </Col>)}
//             </Row>}

//             </CardBody>
//           </Card>
// </>
//     )
//   }
//  }
