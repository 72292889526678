import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { get_service_pay, get_permission, set_info_service } from "network/ApiAxios";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";
import HomeCardsHeader from "components/Headers/HomeCardsHeader.js";
class ServicePay extends React.Component {
  static contextType = UserContext
  state={
    selectBtnStyle:{[0]:style.btnSelect},
    stateService:[],
    stateServiceActual:[],
    typeMA:'Mes',
    type_traza:null,
    type_traza_d:'',
    setinfoState:false,
    priceConfig:[],
    pricePercentState:[],
    areawork:''
  }
  async componentDidMount(){
    this.context.getAffiliates()
      // var userdata = localStorage.getItem('user');
      // var jsondata = JSON.parse(userdata);
      // var gerParam = new URLSearchParams(this.props.location.search).get("company");
      // var idcompany = null;
      // if (jsondata.userType==2) {
      //   if (gerParam!=null) {
      //     idcompany = gerParam
      //   }
      // }
      // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
        if (dataPermission) {

          this.setState({type_traza:dataPermission.type_traza.split('|')[1]})
          this.setState({type_traza_d:dataPermission.type_traza})
          var thisExtend = this
          get_service_pay(jsondata._id,dataPermission.type_traza.split('|')[1],this.state.typeMA,0).then((get_pays)=>{          
            thisExtend.setState({stateServiceActual:get_pays.service_actual})
            thisExtend.setState({stateService:get_pays.service_plan})
          })
        }
      }
   changeAreaWork = (val) => {
      var data = ''
      if (val.checked) {
        data = this.state.areawork+val.value
      }else{
        data = this.state.areawork.replace(val.value,'')
      }
      this.setState({areawork:data})
    }
  render(){
    this.setInfoInit()
    const changeColorMA = async (type,valma) =>{
        this.setState({typeMA:valma})
        this.setState({selectBtnStyle: { [type]: style.btnSelect }})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var get_pays = await get_service_pay(jsondata._id,this.state.type_traza,valma,0)
        this.setState({stateService:get_pays.service_plan})  
    }
    const contentServiceFunction = (data) =>{
      var dataFunction = data.split('_')
       var content = []
       for (var i = 0; i < dataFunction.length; i++) {
       content[i] = <li style={{color:'black',fontWeight:'500'}}>{dataFunction[i]}</li>
       }
       return content
    }
    const selectService = async (data,forInput) =>{
      //if (window.confirm("Deseas adquirir este servicio?")) {
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);      
          var getDataString = jQuery(data).attr('data-id')
          var getDataJsonVar = JSON.parse(getDataString)
          // var getDataString = await set_info_service(jsondata._id,getDataJson.id,getDataJson.precio,getDataJson.tipo_traza,getDataJson.time_date);
          // if (getDataString.result) {
          // }
          var getDataJson=null;
          if (getDataJsonVar.type_asigned_users==0) {
            getDataJson = getDataJsonVar
          }
          var gPrice = 0
          if (getDataJsonVar.type_asigned_users==1) {
            gPrice = setPriceG(getDataJsonVar,this.state.priceConfig,forInput,this.state.pricePercentState)
          }else{
            if (getDataJsonVar.cantidad_usuarios_hasta=='Ilimitado') {
              gPrice = parseInt(getDataJsonVar.precio)
            }else{
              gPrice = parseInt(getDataJsonVar.precio)*parseInt(getDataJsonVar.cantidad_usuarios_hasta)
            }
          }
          // 0
          // if (parseFloat(this.state.pricePercentState[forInput])==0 || this.state.pricePercentState[forInput]==0) {
          //   if (this.state.priceConfig[forInput]==NaN) {
          //     gPrice = getDataJsonVar.precio
          //   }else{
          //     gPrice = this.state.priceConfig[forInput]
          //   }
          // }else{
          //   gPrice = parseFloat(this.state.pricePercentState[forInput])
          // }
          if (getDataJsonVar.type_asigned_users==1) {
            getDataJson = {...getDataJsonVar,cantidad_usuarios_hasta:this.state.priceConfig[forInput],global_price:parseFloat(gPrice).toFixed(2),areawork:this.state.areawork}
          }else{
            getDataJson = {...getDataJsonVar,global_price:parseFloat(gPrice).toFixed(2),areawork:this.state.areawork}
          }
          if (this.state.areawork=='') {
            alert('Es Necesario un Area de Trabajo')
            return
          }
          this.props.history.push('/admin/payment',{getDataJson});
      //}
    }
    const putInputQuantity = async (data,dataId,indx,dataRowPrice) =>{
      var replace_indx = parseInt(dataId.replace('input_',''))
      var priceConfigPercent = putPrice(dataRowPrice[replace_indx].quantity_price_user,dataRowPrice[replace_indx].precio,data)
      var dataPrice = parseInt(data)
      var dataPriceTwo = 1
      if (!isNaN(dataPrice)) {
        dataPriceTwo=dataPrice
      }
      // var forSetPrice = dataPrice
      // if (priceConfigPercent==0) {
      //   forSetPrice = parseInt(priceConfigPercent)
      // }
      if (dataPriceTwo<0) {
        return
      }
      this.setState({priceConfig: {[dataId]:dataPriceTwo}, pricePercentState: {[dataId]:priceConfigPercent} })
    }
    const putPrice = (data,pricest,inputPrice) => {
      if (data==null) {
        return 0
      }
      var obj_data = data.split('*')
      if (obj_data.length<0) {
        return
      }
      var result = 0;
      obj_data.map((val,ind)=>{
        var globVal = val.split('_');
        var priceOne = parseInt(globVal[0])
        var priceTwo = parseInt(globVal[1])
        var pricePercent = parseFloat(globVal[2])
        var priceInput = parseInt(inputPrice)
        if (parseInt(inputPrice)>=priceOne && parseInt(inputPrice)<=priceTwo) {
             var numVal1 = Number(pricest*priceInput);
             var numVal2 = Number((globVal[2]=='')?(0):(pricePercent)) / 100;
             var totalValue = numVal1 - (numVal1 * numVal2)
          result = totalValue.toFixed(2)
        }else{
          if (parseInt(inputPrice)>=priceOne && parseInt(inputPrice)>priceTwo) {
               var numVal1 = Number(pricest*priceInput);
               var numVal2 = Number((globVal[2]=='')?(0):(pricePercent)) / 100;
               var totalValue = numVal1 - (numVal1 * numVal2)
            result = totalValue.toFixed(2)
          }
        }
      })
        return result
    }
    const setPriceG = (data,priceConfig,priceConfigInput,pricePercentStateConfig) => {
      var priceG = 0
        if (priceConfig[priceConfigInput]==0 || priceConfig[priceConfigInput]==undefined) {
          priceG = parseInt(data.precio)
        }else{
          if (pricePercentStateConfig[priceConfigInput]==0 || pricePercentStateConfig[priceConfigInput]==undefined) {
            if (priceConfig[priceConfigInput]==undefined) {
              priceG = parseInt(data.precio)
            }else{
              if (priceConfig[priceConfigInput]=='') {
                priceG = parseInt(data.precio)
              }else{
                priceG = parseInt(data.precio)*parseInt(priceConfig[priceConfigInput])
              }
            }
          }else{
            priceG = pricePercentStateConfig[priceConfigInput]
          }
        }
      return priceG
    }
    const setValuePrice = (priceConfigInput,pricePercentStateInput,priceInput,priceConfig,pricePercentStateConfig) => {
      var result = 0
      if (priceConfigInput[priceInput]==undefined || pricePercentStateInput[priceInput]==undefined) {
        result = ''
      }else{
        if (pricePercentStateConfig==0) {
          result = priceConfig
        }else{
          result = pricePercentStateConfig
        }
      }
      return result
    }
    const contentService = (data,selecty) =>{
      var content = []
      for (var i = 0; i < data.length; i++) {
                     content[i] = <Col md={4}>
                      <Card style={{width: '75%'}}>
                        <CardHeader style={
                              ( data[i].tipo_traza=='VF' || data[i].tipo_traza.split('|')[1]=='VF')?({background:'linear-gradient(90deg, #7ac142 50%, #7ac142 50%)'}):(null)+
                              ( data[i].tipo_traza=='M' || data[i].tipo_traza.split('|')[1]=='M')?({background:'linear-gradient(90deg, #9d0e0d 50%, #9d0e0d 50%)'}):(null)+
                              ( data[i].tipo_traza=='G' || data[i].tipo_traza.split('|')[1]=='G')?({background:'linear-gradient(90deg, #0998df 50%, #0998df 50%)'}):(null)+
                              ( data[i].tipo_traza=='VF-M' || data[i].tipo_traza.split('|')[1]=='VF-M')?({background:'linear-gradient(90deg, #7ac142 50%, #9d0e0d 50%)'}):(null)+
                              ( data[i].tipo_traza=='VF-G' || data[i].tipo_traza.split('|')[1]=='VF-G')?({background:'linear-gradient(90deg, #7ac142 50%, #0998df 50%)'}):(null)+
                              ( data[i].tipo_traza=='M-G' || data[i].tipo_traza.split('|')[1]=='M-G')?({background:'linear-gradient(90deg, #9d0e0d 50%, #0998df 50%)'}):(null)+
                              ( data[i].tipo_traza=='VF-M-G' || data[i].tipo_traza.split('|')[1]=='VF-M-G')?({background:'linear-gradient(90deg, rgb(122, 193, 66) 30%, rgb(157, 14, 13) 30%,rgb(157, 14, 13) 65%, rgb(9, 152, 223) 65%)'}):(null)
                            }>
                           <h3 style={{color:'white'}} className="mb-0 text-center">
                           {
                            ( data[i].tipo_traza=='VF' || data[i].tipo_traza.split('|')[1]=='VF')?('Vegetales y frutas'):(null)+
                            ( data[i].tipo_traza=='M' || data[i].tipo_traza.split('|')[1]=='M')?('Carnicos'):(null)+
                            ( data[i].tipo_traza=='G' || data[i].tipo_traza.split('|')[1]=='G')?('Granos'):(null)+
                            ( data[i].tipo_traza=='VF-M' || data[i].tipo_traza.split('|')[1]=='VF-M')?('Vegetales y frutas - Carnicos'):(null)+
                            ( data[i].tipo_traza=='VF-G' || data[i].tipo_traza.split('|')[1]=='VF-G')?('Vegetales y frutas - Granos'):(null)+
                            ( data[i].tipo_traza=='M-G' || data[i].tipo_traza.split('|')[1]=='M-G')?('Carnicos - Granos'):(null)+
                            ( data[i].tipo_traza=='VF-M-G' || data[i].tipo_traza.split('|')[1]=='VF-M-G')?('Vegetales y frutas - Carnicos - Granos'):(null)
                           }
                           </h3>   
                        </CardHeader>
                        <CardBody className="text-center">
                          <span style={{color:'black',fontWeight:'500'}}>{data[i].descripcion}</span>
                          <hr style={{margin:'10px'}}/>
                          <h3>Funciones principales</h3>
                          <Col md={12}>
                          {
                            (data[i].type_asigned_users==0)?(
                                <li style={{color:'black',fontWeight:'500'}}>{(data[i].cantidad_usuarios_hasta=='Ilimitado')?(data[i].cantidad_usuarios_hasta):(data[i].cantidad_usuarios_desde+' a '+data[i].cantidad_usuarios_hasta+' Usuario')}</li>
                              ):( 
                                <li style={{color:'black',fontWeight:'500'}}>Cantidad De usuarios: 
                                <input type="number" className="form-control" value={setValuePrice(this.state.priceConfig,this.state.pricePercentState,'input_'+i,this.state.priceConfig[i],this.state.pricePercentState[i])} onChange={(e)=>{ putInputQuantity(e.target.value,e.target.id,i,data) }} id={'input_'+i} /></li>
                              )
                          }
                          {contentServiceFunction(data[i].funciones)}
{/*                         <li style={{color:'black',fontWeight:'500'}}>2 a 10 Usuario</li>
                            <li style={{color:'black',fontWeight:'500'}}>Aplicación web</li>
                            <li style={{color:'black',fontWeight:'500'}}>Campos y siembras ilimitadas</li>
                            <li style={{color:'black',fontWeight:'500'}}>Registro trazabilidades limitados</li>
                            <li style={{color:'black',fontWeight:'500'}}>Reportes avanzados</li>
                            <li style={{color:'black',fontWeight:'500'}}>*El costo para subir al blockchain es adicional</li>*/}
                          </Col>
                          <hr style={{margin:'10px'}}/>
{/*                          <Col md={12}>
                            <select style={{width: '75%', border: 'solid 1px #7ac142', margin: 'auto'}} className="form-control">
                              <option value="100">100 trazabilidades</option>
                            </select>
                          </Col>*/}
                          <br />
                          <Col md={12}>
                            <div>
                          {
                            (data[i].type_asigned_users==0)?(
                                <h1 className="mb-0" style={{fontSize:'40px'}}>${(data[i].cantidad_usuarios_hasta=='Ilimitado')?(parseInt(data[i].precio)):(parseInt(data[i].precio)*parseInt(data[i].cantidad_usuarios_hasta) )}<span style={{fontSize:'12px',color:'gray'}}>/al {data[i].mes_ano}</span></h1>
                              ):(
                                <h1 className="mb-0" style={{fontSize:'40px'}}>${setPriceG(data[i],this.state.priceConfig,'input_'+i,this.state.pricePercentState)}<span style={{fontSize:'12px',color:'gray'}}>/al {data[i].mes_ano}</span></h1>
                              )
                          }                            
                            </div>
                          </Col>
                          {(selecty==0)?(null):(<Col md={12}>
                                                                                <button className="btn" 
                                                                                onClick={(e)=>{selectService(e.target,e.target.id)}}
                                                                                data-id={JSON.stringify(data[i])}
                                                                                id={'input_'+i}
                                                                                style={
                                                                                      (data[i].tipo_traza=='VF')?({background:'linear-gradient(90deg, #7ac142 50%, #7ac142 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='M')?({background:'linear-gradient(90deg, #9d0e0d 50%, #9d0e0d 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='G')?({background:'linear-gradient(90deg, #0998df 50%, #0998df 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='VF-M')?({background:'linear-gradient(90deg, #7ac142 50%, #9d0e0d 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='VF-G')?({background:'linear-gradient(90deg, #7ac142 50%, #0998df 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='M-G')?({background:'linear-gradient(90deg, #9d0e0d 50%, #0998df 50%)',color:'white'}):(null)+
                                                                                      (data[i].tipo_traza=='VF-M-G')?({background:'linear-gradient(90deg, rgb(122, 193, 66) 30%, rgb(157, 14, 13) 30%,rgb(157, 14, 13) 65%, rgb(9, 152, 223) 65%)',color:'white'}):(null)
                                                                                  // (data[i].tipo_traza=='VF-M')?({background:'linear-gradient(90deg, #7ac142 50%, #9d0e0d 50%)',color:'white'}):(
                                                                                  // (data[i].tipo_traza=='VF')?({background:'linear-gradient(90deg, #7ac142 50%, #7ac142 50%)',color:'white'}):(
                                                                                  //   (data[i].tipo_traza=='M')?({background:'linear-gradient(90deg, #9d0e0d 50%, #9d0e0d 50%)',color:'white'}):(null)
                                                                                  //   ))
                                                                                }>
                                                                                  Adquirir este plan
                                                                                </button>
                                                                              </Col>)}
                        </CardBody>
                      </Card>
              </Col>        
      }
      return content
    }
    const changeTraza = async (valor) =>{
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
        this.setState({type_traza:valor})
        var get_pays = await get_service_pay(jsondata._id,valor,this.state.typeMA,0)
        this.setState({stateService:get_pays.service_plan})
    }
const asignedPreference = async (valor) =>{
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
       var get_pays;
      if (jQuery(valor).prop('checked')) {
        get_pays = await get_service_pay(jsondata._id,this.state.type_traza,this.state.typeMA,1)
      }else{
        get_pays = await get_service_pay(jsondata._id,this.state.type_traza,this.state.typeMA,0)
      }
       this.setState({stateService:get_pays.service_plan})
    }
  return (
    <>
      <HomeCardsHeader name="Pagos por servicios" parentName="Administración" {...this.props} section="pay_service" section_two="none" section_table_form="null" />
      {/*<HomeCardsHeader name="Foodchain Trace" parentName="Bienvenido" {...this.props} />*/}
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Pagos por servicios {(this.state.type_traza_d.indexOf('D')==-1)?(null):('Demo')}</h3>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md={12} className="pb-3">
              <h4 className="mb-0">Servicio Actual</h4>
            </Col>
              {contentService(this.state.stateServiceActual,0)}
            </Row>

{(this.state.type_traza_d.indexOf('D')!=-1)?(null):(
            <Row>
                          <Col md={12} className="pb-3">
                            <select style={{width: '75%', border: 'solid 1px #00295b', margin: 'auto'}} value={this.state.type_traza} className="form-control" onChange={(e)=>{changeTraza(e.target.value)}}>
                              <option value="0">Trazabilidad</option>
                              <option value="VF">Frutas y Vegetales</option>
                              <option value="M">Carnicos</option>
                              <option value="G">Granos</option>
                              <option value="VF-M">Frutas y Vegetales - Carnicos</option>
                              <option value="VF-G">Frutas y Vegetales - Granos</option>
                              <option value="M-G">Carnicos - Granos</option>
                              <option value="VF-M-G">Frutas y Vegetales - Carnicos - Granos</option>
                            </select>
                          </Col>

            <Col md={12} className="text-center">
              <Row>
                <Col md={6}>
                                <button onClick={()=>{changeColorMA(0,'Mes')}} className="btn" style={this.state.selectBtnStyle[0]}>
                                  Pago Mensual
                                </button>
                </Col>
                <Col md={6}>
                                <button onClick={()=>{changeColorMA(1,'Año')}} className="btn" style={this.state.selectBtnStyle[1]}>
                                  Pago Anual
                                </button>
                </Col>
              </Row>
            <br />
            </Col>
            <Col md={12} className="text-center">
              <FormGroup>
                <label>Seleccione Área de Trabajo</label><br />
                <span>Producción: </span><input type="checkbox" value="P" onChange={(e)=>{this.changeAreaWork(e.target)}}/>{' '}
                <span>Trazabilidad: </span><input type="checkbox" value="T" onChange={(e)=>{this.changeAreaWork(e.target)}}/>{' '}
                <span>Comercialización: </span><input type="checkbox" value="C" onChange={(e)=>{this.changeAreaWork(e.target)}}/>{' '}
              </FormGroup>
            </Col>

            <Col md={12} className="text-center" style={{display:'flex'}}>
              <Row style={{margin:'auto'}}>
                <input type="checkbox" style={{width:12,marginRight:'5px'}} className="form-control" onClick={(e)=>{asignedPreference(e.target)}}/>
                <span style={{alignSelf:'center'}}> Asignar Cantodad de Usuarios de preferencias</span>
              </Row>
            </Col>
            {contentService(this.state.stateService,1)}
            </Row>)}

          </CardBody>
        </Card>
      </Container>
    </>
  );
 }
}
const style = {
  btnSelect:{
    backgroundColor: "#00295b",
    color:"white"
  }
}
export default ServicePay;
