import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Modal,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {edit_colab,data_list_addcoll} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
//function Elements() {
class ModalEditUserPermission extends React.Component {
state = {
  user_id_connection:'idNone',
  user_id:'idNone',
  autorization:'0',
  trazabilidad:'0',
  check_guide_pt:'0',
  check_guide_bool:false,
  establishment:'',
  number_incription:'',
  entidad:'',
  nombre:'',
  email:'',
  password:'',
  checked_traza:false,
  checked_production:false,
  checked_cosecha:false,
  checked_planta:false,
  checked_logist:false,
  checked_doc:false,
  checked_commerce:false,
  checked_traza_m:false,
  checked_lote_m:false,
  checked_logist_m:false,
  checked_compart_m:false,
  checked_fridge_m:false,
  checked_doc_m:false,
  setAreawork:"",
  mess:'',
  notificationAlertRef: React.createRef(),
  btndisab:false,
  data_production_user:[],
  data_traza_g:[],
  data_traza:[],
  data_traza_m:[],
  check_nama_data:[]
}
async componentDidMount(){

}
  async componentDidUpdate(previousProps, previousState){

 if (this.props.setDataUser!=null) {
         if (this.props.setDataUser!=previousProps.setDataUser) {
       const data_traz = await data_list_addcoll(this.props.setDataUser.user_id_connection,this.props.setDataUser.user_id)
       this.setState({data_traza:data_traz.trazabilidad,data_traza_m:data_traz.trazabilidad_meat,data_production_user:data_traz.data_production,data_traza_g:data_traz.traza_grain})
       
       this.setState({
           user_id_connection:this.props.setDataUser.user_id_connection,
           user_id:this.props.setDataUser.user_id,
           autorization:this.props.setDataUser.type,
           trazabilidad:this.props.setDataUser.type_traza.split('|')[1],
           entidad:this.props.setDataUser.empresa,
           nombre:this.props.setDataUser.name,
           email:this.props.setDataUser.email,
           check_guide_pt:this.props.setDataUser.guide_m,
           establishment:this.props.setDataUser.guide_establishment_m,
           number_incription:this.props.setDataUser.guide_incription_m,
           setAreawork:this.props.setDataUser.type_traza.split('|')[0]
       })
       if (this.props.setDataUser.guide_m==1) {
        this.setState({check_guide_bool:true})
       }
       this.setChecked(data_traz.trazabilidad)
       this.setCheckedM(data_traz.trazabilidad_meat)
       this.setCheckedG(data_traz.data_production)
       this.setCheckedTG(data_traz.traza_grain)

       Object.entries(this.props.setDataUser).map((a,b)=>{
        if (a[0] != 'code_satelital' && a[0] != 'system_digital_signature' && a[0] != 'code_app_verify' && a[0] != 'asigned_signature' && a[0] != 'file_signature' && a[0] != 'draw_digital_signature' &&     a[0] != 'id' && a[0] != 'dni' && a[0] != 'second_name_ap' && a[0] != 'remember_token' && a[0] != 'cargo' && a[0] != 'role' && a[0] != 'email' && a[0] != 'password' && a[0] != 'creted_at' && a[0] != 'updated_at' && a[0] != 'name' && a[0] != 'telefono' && a[0] != 'empresa' && a[0] != 'pais' && a[0] != 'ciudad' && a[0] != 'ubicacion' && a[0] != 'photo' && a[0] != 'active_validation' && a[0] != 'user_access' && a[0] != 'code_retrieve' && a[0] != 'tokenfb' && a[0] != 'motivo' && a[0] != 'guide_users' && a[0] != 'user_id' && a[0] != 'user_id_connection' && a[0] != 'type' && a[0] != 'created_at' && a[0] != 'type_traza' && a[0] != 'psw_string' && a[0] != 'user_email' && a[0] != 'second_name' && a[0] != 'type_other') {
          this.setState({check_nama_data: {...this.state.check_nama_data,[a[0]]:a[1] }})
        }
       })
       if (this.props.setDataUser.type=='options') {
        if (this.props.setDataUser.type_traza.indexOf("G")!=-1) {
          this.setState({setTypeOption:'nama'})
        }
       }
     }
}
  }
   setChecked  = (data) => {
    data.map((value,index)=>{
       if (value.active==1) {
        jQuery('.lote_check_checked'+value.codigo).prop('checked',true)
       }
    })
  }
setCheckedM  = (data) => {
    data.map((value,index)=>{
       if (value.active==1) {
        jQuery('.lote_check_checked_m'+value.codigo).prop('checked',true)
       }
    })
  }
setCheckedG  = (data) => {
    data.map((value,index)=>{
       if (value.active==1) {
        jQuery('.lote_check_checked_n_g'+value.codigo).prop('checked',true)
       }
    })
  }
setCheckedTG  = (data) => {
    data.map((value,index)=>{
       if (value.active==1) {
        jQuery('.check_checked_t_g'+value.codigo).prop('checked',true)
       }
    })
  }
  checkedAllInputs=(selector)=>{
    if (jQuery('.'+selector).length==jQuery('.'+selector+':checked').length) {
      jQuery('.'+selector).map((a,b)=>{
            jQuery(b).click()
      })
    }else{
      jQuery('.'+selector).map((a,b)=>{
          if (!b.checked) {
            jQuery(b).click()
          }
      })
    }
  }
render(){
  const logistSwitch = () => {
      if (this.state.setAreawork.indexOf("P")!=-1) {
        var contentViewsProd = []
        if (this.state.autorization=="view") {
          if (this.state.data_production_user.length!=0) {
            contentViewsProd[0]= sectionTableP(this.state.data_production_user)
          }
        }
        if (this.state.autorization=="options") {
             contentViewsProd[0]= contentG()
             contentViewsProd[1]= sectionTableP(this.state.data_production_user)
        }
        return contentViewsProd
      }

      if (this.state.setAreawork.indexOf("T")!=-1) {
        
        if (this.state.autorization=="view") {
          return sectionViewSelection()
        }

        var contentOptions = []
        var contentViews = []
        if (this.state.autorization=="options") {
        //   if (this.state.trazabilidad.indexOf("VF")!=-1) {
        //     contentOptions[0]= contentVF()
        //     contentViews[0]= sectionTableVF(this.state.data_traza)
        //   }
        //   if (this.state.trazabilidad.indexOf("M")!=-1) {
        //     contentOptions[1]= contentM()
        //     contentViews[1]= sectionTableM(this.state.data_traza_m)
        //   }
        //   if (this.state.trazabilidad.indexOf("G")!=-1) {
        //     contentOptions[2]= contentTG()
        //     contentViews[2]= sectionTableG(this.state.data_traza_g)
        //   }
        //   return [contentOptions,contentViews]
        return sectionViewSelectionTwo()
         }
      }
  }
  const sectionViewSelectionTwo =()=>{
        var contentViews = []
          if (this.state.trazabilidad.indexOf("VF")!=-1) {
            if (this.state.data_traza.length!=0) {
              contentViews[0]= contentVF()
              contentViews[1]= sectionTableVF(this.state.data_traza)
            }
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            if (this.state.data_traza_m.length!=0) {
              contentViews[2]= contentM()
              contentViews[3]= sectionTableM(this.state.data_traza_m)
            }
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            if (this.state.data_traza_g.length!=0) {
              contentViews[4]= contentTG()
              contentViews[5]= sectionTableG(this.state.data_traza_g)
            }
          }
        return contentViews
  }
  const sectionViewSelection =()=>{
        var contentViews = []
          if (this.state.trazabilidad.indexOf("VF")!=-1) {
            if (this.state.data_traza.length!=0) {
              contentViews[0]= sectionTableVF(this.state.data_traza)
            }
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            if (this.state.data_traza_m.length!=0) {
              contentViews[1]= sectionTableM(this.state.data_traza_m)
            }
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            if (this.state.data_traza_g.length!=0) {
              contentViews[2]= sectionTableG(this.state.data_traza_g)
            }
          }
        return contentViews
  }
  const saveColab = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var globlalthis = this
            this.setState({btndisab: true})
    if (  this.state.autorization=='0' || this.state.entidad=='' || this.state.nombre=='' || this.state.email=='') {
            this.setState({mess: 'Todos los campos son requeridos'})
            this.setState({btndisab: false})
    }else{
        var getValue = [];
      if (this.state.data_traza.length!=0) {
        var documentTag = document.querySelectorAll(".lote_check_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.trazabilidad.indexOf('VF')!=-1)) {
          if (documentTag.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad')
          return
        }else{
          for (var i = 0; i < documentTag.length; i++) {
              getValue[i] = documentTag[i].value
            }
          }
        }
      }
        var getValueM = [];
      if (this.state.data_traza_m.length!=0) {
        var documentTagM = document.querySelectorAll(".lote_check_m_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.trazabilidad.indexOf('M')!=-1) ) {
          if (documentTagM.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en carnicos')
          return
        }else{
          for (var i = 0; i < documentTagM.length; i++) {
              getValueM[i] = documentTagM[i].value
            }
          }
        }
      }
        var getValueGT = [];
      if (this.state.data_traza_g.length!=0) {
        var documentTagGrain = document.querySelectorAll(".check_traza_g_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.trazabilidad.indexOf('G')!=-1)) {
          if (documentTagGrain.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en granos')
          return
        }else{
          for (var i = 0; i < documentTagGrain.length; i++) {
              getValueGT[i] = documentTagGrain[i].value
            }
          }
        }
      }

        var getValueG = [];
      if (this.state.data_production_user.length!=0) {
        var documentTagG = document.querySelectorAll(".lote_check_n_g_send:checked");
        if ((this.state.autorization=='view' || this.state.autorization=='options') && (this.state.setAreawork.indexOf('P')!=-1) ) {
          if (documentTagG.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una sección para produccion')
          return
        }else{
          for (var i = 0; i < documentTagG.length; i++) {
              getValueG[i] = documentTagG[i].value
            }
          }
        }
      }

       const response = await edit_colab(this.state.nombre, this.state.email, this.state.entidad, this.state.password, this.state.autorization, this.state.setAreawork+'|'+this.state.trazabilidad, 2, jsondata._id, this.state.user_id_connection, this.state.user_id, JSON.stringify(this.state.check_nama_data),getValue.filter(Boolean),getValueM.filter(Boolean),getValueG.filter(Boolean),getValueGT.filter(Boolean), this.state.check_guide_pt, this.state.establishment, this.state.number_incription)
       .catch(function (message){
        if (message.response.status==500) {
         globlalthis.setState({mess: 'Ha ocurrido un error, intente mas tarde.'})
        }
        if (message.response.status==401) {
         globlalthis.setState({mess: message.response.data.errors.email[0]})
        }
         globlalthis.setState({btndisab: false})
       })
        if (response==undefined) {
        return;
      }else{
        const {data} = response;
        if (!data.success) {
        this.setState({mess: data.message})
            return;
        }
      }
      notify("success", "Editado exitosamente!", "Cambios realizados exitosamente.");
        this.setState({btndisab: false})
        this.props.refreshData()
    }
  }
  const setInfoUser = (type) => {
    this.props.returnResponse(type)
  }

  const logistGuides = () => {
      var component=null;
      if (this.state.trazabilidad=="0") {
        return;
      }
      if (this.state.trazabilidad.indexOf("M")==-1) {
        return;
      }
      if ((this.state.trazabilidad.indexOf("M")!=-1) && (this.state.autorization=="view" || this.state.autorization=="0")) {
        return;
      }
    component =<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Asignar Guias de propiedad y transito
                  </label>
                  <br />
                <input
                  checked={this.state.check_guide_bool}
                  onChange={(e)=>{this.setState({check_guide_pt:e.target.value,check_guide_bool:!this.state.check_guide_bool})}}
                  id="guide_pt" name="guide_pt" type="checkbox" value={(this.state.check_guide_bool)?("0"):("1")}/>
                </FormGroup>
              </Col></>
              return component
  }
  const sectionTableVF = (data) => {

    var componenteData = []

componenteData = <Col md={4} className="table-responsive">
                <Col>
                  <span
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1">
                    Vegetales y frutas
                  </span>
                </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>producto</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}><button className="btn" onClick={()=>{this.checkedAllInputs('lote_check_send')}} style={{padding:0}}>Selección</button></th>
                      </tr>
                    </thead>
                    <tbody>

                  {
                    data.map((value,index)=>{

                     return(<tr key={index}>
                              <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                <span className="text-muted">
                                {value.codigo}
                                </span>
                              </td>
                              <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                <span className="text-muted">
                                {value.producto}
                                </span>
                              </td>
                              <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                 <span className="text-muted">
                                {value.created_at}
                                </span>
                              </td>
                              <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                
                                  <input style={{width: '100%',margin: 0}} className={"lote_check_send lote_check_checked"+value.codigo} name="lote_check_send" type="checkbox" value={value.codigo}/>
                                
                              </td>
                            </tr>)
                    })
                  }
                    </tbody>
                  </table>
                  </Col>
          return componenteData
  }
const sectionTableM = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
                <Col>
                  <span
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1">
                    Carnicos
                  </span>
                </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>Nombre</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>
                        <button className="btn" onClick={()=>{this.checkedAllInputs('lote_check_m_send')}} style={{padding:0}}>Selección</button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.nombre_traza}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                      
                                                        <input style={{width: '100%',margin: 0}} className={"lote_check_m_send lote_check_checked_m"+value.codigo} name="lote_check_m_send" type="checkbox" value={value.codigo}/>
                                                      
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table>
                  </Col>
          return componenteData
  }
  const sectionTableG = (data) => {
    var componenteData = []
    componenteData = <Col md={4} className="table-responsive">
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista granos arroz
                        </span>
              </Col>
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}>
                        <button className="btn" onClick={()=>{this.checkedAllInputs('check_traza_g_send')}} style={{padding:0}}>Selección</button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                      
                                                        <input style={{width: '100%',margin: 0}} className={"check_traza_g_send check_checked_t_g"+value.codigo} name="check_traza_g_send" type="checkbox" value={value.codigo}/>
                                                      
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table>
                  </Col>
          return componenteData
  }
  const sectionTableP = (data) => {
    var componenteData = []
    componenteData = 
              <Col md={4} className="table-responsive">
                <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th style={{border: 'solid 1px'}}>Foodchain ID</th>
                        <th style={{border: 'solid 1px'}}>producto</th>
                        <th style={{border: 'solid 1px'}}>Creado</th>
                        <th style={{border: 'solid 1px'}}> 
                          {/*<input style={{width: '100%',margin: 0}} name="checkAllP" onClick={(e)=>{this.checkedAllInputs(e.target.checked,'lote_check_n_g_send')}} type="checkbox"/>*/}
                          <button className="btn" onClick={()=>{this.checkedAllInputs('lote_check_n_g_send')}} style={{padding:0}}>Selección</button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.producto}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
                                                      
                                                        <input style={{width: '100%',margin: 0}} className={"lote_check_n_g_send lote_check_checked_n_g"+value.codigo} name="lote_check_n_g_send" type="checkbox" value={value.codigo}/>
                                                      
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </table>
                </Col>
          return componenteData
  }
  // const sectionTableG = (data) => {
  //   var componenteData = []
  //   componenteData = 
  //               <table style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}} className="align-items-center table-flush">
  //                   <thead className="thead-light">
  //                     <tr>
  //                       <th style={{border: 'solid 1px'}}>Foodchain ID</th>
  //                       <th style={{border: 'solid 1px'}}>Nombre</th>
  //                       <th style={{border: 'solid 1px'}}>Creado</th>
  //                       <th style={{border: 'solid 1px'}}>Selección</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //   {                    data.map((value,index)=>{
  //                        return(<tr key={index}>
  //                                                   <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
  //                                                    <span className="text-muted">
  //                                                     {value.codigo}
  //                                                    </span>
  //                                                   </td>
  //                                                   <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
  //                                                    <span className="text-muted">
  //                                                     {value.nombre_productor}
  //                                                    </span>
  //                                                   </td>
  //                                                   <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
  //                                                    <span className="text-muted">
  //                                                     {value.created_at}
  //                                                    </span>
  //                                                   </td>
  //                                                   <td style={{color: 'black',fontSize: '12px',border: 'solid 1px'}}>
  
  //                                                       <input style={{width: '100%',margin: 0}} className={"lote_check_n_g_send lote_check_checked_n_g"+value.codigo} name="lote_check_n_g_send" type="checkbox" value={value.codigo}/>
  
  //                                                   </td>
  //                                                 </tr>)
  //                       })
  //   }
  //                   </tbody>
  //                 </table>
  //         return componenteData
  // }
  const contentVF = () => {
const data = [
{title:'Trazabilidad',attr:'trazabilidad',stateVal:'trazabilidad'},
{title:'Producción',attr:'produccion',stateVal:'produccion'},
{title:'Cosecha',attr:'cosecha',stateVal:'cosecha'},
{title:'Planta',attr:'planta',stateVal:'planta'},
{title:'Logistíca y Transporte',attr:'logistica',stateVal:'logistica'},
{title:'Comercialización',attr:'comercializa',stateVal:'logistica'},
{title:'Documentos',attr:'documento',stateVal:'documento'},
]
return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Vegetales Y frutas
                                                </span>
                                      {getDataState(data)}
                                    </Col>)
  }

const contentM = () => {
const data = [

{title:'Trazabilidad',attr:'trazabilidad_m',stateVal:'trazabilidad_m'},
{title:'Lote',attr:'lote_m',stateVal:'lote_m'},
{title:'Logistíca',attr:'logist_m',stateVal:'logistica_m'},
{title:'Compartimento',attr:'compart_m',stateVal:'compartimento_m'},
{title:'Frigorífico',attr:'fridge_m',stateVal:'frigorifico_m'},
{title:'Documentos',attr:'documento_m',stateVal:'documentos_m'},
]
return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Carnicos
                                                </span>
                                      {getDataState(data)}
                                    </Col>)
  }
const contentTG = () => {
const data = [
{title:'Información General',attr:'information_grain_t',stateVal:'information_grain_t'},
{title:'Producción',attr:'production_grain_t',stateVal:'production_grain_t'},
{title:'Cosecha',attr:'harvest_grain_t',stateVal:'harvest_grain_t'},
{title:'Documento',attr:'document_grain_t',stateVal:'document_grain_t'},
]
return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Arroz
                                                </span>
                                      {getDataState(data)}
                                    </Col>)
  }
const contentG = () => {

                            return(<Col md={2} style={{border: '1px solid',marginTop: '5px'}}>
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Selección de Producción
                                                </span>
                                      {setContentP()}
                                   </Col>)
  }
  const contentGSelect = (setTypeOption) => {
    if (this.state.setTypeOption=='nama') {
      return setContentP()
    }
    if (this.state.setTypeOption=='trazabilidad') {
      return null
    }

  }
  const setContentP = () => {
const data = [
{title:'Información General',attr:'information_nama_g',stateVal:'information_nama_g'},
{title:'Producción',attr:'prodction_nama_g',stateVal:'production_nama_g'},
{title:'Manejo Fitosanitario',attr:'hading_nama_g',stateVal:'hading_nama_g'},
{title:'Cosecha',attr:'harvest_nama_g',stateVal:'harvest_nama_g'},
{title:'Documento',attr:'documento_n_g',stateVal:'document_nama_g'},
]
return(
        <>
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Opciones de Namas Arroz
                        </span>
                {getDataState(data)}
          </>
        )
  }
  const getDataState = (data) => {
    var content = []
      data.map((val,ind)=>{
      content[ind] = <>
            <Col key={ind} md="12" sm="12">
              <Row>
                <div style={{paddingRight: '5px'}}>
                    <input
                     checked={(this.state.check_nama_data[val.stateVal]==1)?(true):(false)}
                     name={val.attr} 
                     onChange={(e)=>{this.setState({check_nama_data: {...this.state.check_nama_data,[val.stateVal]:(e.target.checked)?('1'):('0')} })}}
                     id={val.attr} 
                     type="checkbox" 
                     value={this.state.check_nama_data[val.stateVal]}/>
                </div>
                <div>
                      <span
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        {val.title}
                      </span>
                </div>
              </Row>
            </Col>
            </>
          })
    return content
  }
      const contentTraza = () => {
        if (this.props.setDataUser==null) {
          return
        }
        return(<>
{/*          {()?(<>
                    <option value="VF">Vegetales y frutas</option>
            </>):(null)}
          {()?(<>
                    <option value="M">Carnicos</option>
            </>):(null)}
          {()?(<>
                    <option value="G">Granos</option>
            </>):(null)}
          {()?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="M">Carnicos</option>
                    <option value="VF-M">Vegetales y frutas - Carnicos</option>
            </>):(null)}
          {()?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="G">Granos</option>
                    <option value="VF-G">Vegetales y frutas - Granos</option>
            </>):(null)}
          {()?(<>
                    <option value="M">Carnicos</option>
                    <option value="G">Granos</option>
                    <option value="M-G">Carnicos - Granos</option>
            </>):(null)}
          {()?(<>
            </>):(null)}*/}
                    <option selected={this.props.setDataUser.type_traza.indexOf('VF')!=-1} value="VF">Vegetales y frutas</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('M')!=-1} value="M">Carnicos</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('G')!=-1} value="G">Granos</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('VF-M')!=-1} value="VF-M">Vegetales y frutas - Carnicos</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('VF-G')!=-1} value="M-G">Carnicos - Granos</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('M-G')!=-1} value="VF-G">Vegetales y frutas - Granos</option>
                    <option selected={this.props.setDataUser.type_traza.indexOf('VF-M-G')!=-1} value="VF-M-G">Vegetales y frutas - Carnicos - Granos</option>
               </>)
    }
      const contentAreaWork = () => {
        if (this.props.setDataUser!=undefined) {
        var get_data = this.props.setDataUser.type_traza.split('|')[0]
        return(<>
          <span>Trazabilidad: </span><input type="radio" name="work_area" checked={this.state.setAreawork.indexOf('T')!=-1} value="T" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
          <span>Producción: </span><input type="radio" name="work_area" checked={this.state.setAreawork.indexOf('P')!=-1} value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
{/*          {(get_data.indexOf('P')!=-1)?(<>
                  <span>Producción: </span><input type="radio" name="work_area" checked value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(<><span>Producción: </span><input type="radio" name="work_area" value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}</>)}
          {(get_data.indexOf('T')!=-1)?(<>
                  
            </>):(<><span>Trazabilidad: </span><input type="radio" name="work_area" value="T" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}</>)}*/}
          {(get_data.indexOf('C')!=-1)?(<>
                  <span>Comercialización: </span><input type="radio" name="work_area" value="C" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
               </>)
        }
    }
    const changeAreaWork = (val) => {
      this.setState({setAreawork:val.value})
    }
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
  <Modal isOpen={this.props.sendOpenModal} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Editar colaborador</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoUser(false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre y apellido
                  </label>
                  <Input
                  value={this.state.nombre}
                  onChange={(e)=>{this.setState({nombre:e.target.value})}}
                    placeholder="Escribe un nombre"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Entidad/Empresa
                  </label>
                  <Input
                  value={this.state.entidad}
                  onChange={(e)=>{this.setState({entidad:e.target.value})}}
                    placeholder="Escribe un entidad"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Área de Trabajo
                  </label>
                  <br/>
                    {contentAreaWork()}
                </FormGroup>
              </Col>
              {(this.state.setAreawork.indexOf('T')!=-1)?(
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Trazabilidad
                      </label>
                      <Input
                      value={this.state.trazabilidad}
                      onChange={(e)=>{this.setState({trazabilidad:e.target.value})}}
                       id="exampleFormControlSelect1" type="select">
                        <option value="0">Seleccione...</option>
                        {contentTraza()}
                      </Input>
                    </FormGroup>
                  </Col>
                ):(null)}
              {(this.state.setAreawork.indexOf('T')!=-1 || this.state.setAreawork.indexOf('P')!=-1)?(
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Autorización
                      </label>
                      <Input
                      value={this.state.autorization}
                      onChange={(e)=>{this.setState({autorization:e.target.value})}}
                      id="exampleFormControlSelect1" type="select">
                        <option value="0">Seleccione...</option>
                        <option value="admin">Agregar administrador</option>
                        <option value="view">Agregar Colaborador para que vea el proceso solamente</option>
                        <option value="options">Agregar Colaborador para editar solo secciones</option>
                      </Input>
                    </FormGroup>
                  </Col>
                ):(null)}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Correo electrónico
                  </label>
                  <Input
                  value={this.state.email}
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Escribe un correo"
                    type="email"
                  />
                </FormGroup>
              </Col>
              {logistGuides()}
{(this.state.check_guide_pt=='1')?(<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Establecimiento
                  </label>
                  <Input
                  value={this.state.establishment}
                  onChange={(e)=>{this.setState({establishment:e.target.value})}}
                  id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="P">Propietario</option>
                    <option value="C">Consignatario</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Número de inscripción del establecimiento o empresa
                  </label>
                  <Input
                  value={this.state.number_incription}
                  onChange={(e)=>{this.setState({number_incription:e.target.value})}}
                    type="numer"
                  />
                </FormGroup>
              </Col></>):(null)}              
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contraseña
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({password:e.target.value})}}
                    placeholder="Escribe una contraseña para el colaborador"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
{/*            {(this.state.autorization!="view")?(null):((this.state.trazabilidad!="VF")?
              (
                (this.state.trazabilidad!="VFM")?(null):(sectionTableF(this.state.data_traza))
              ):(sectionTableF(this.state.data_traza)))}
            {(this.state.autorization!="options")?(null):()}*/}
            <Row>
              {logistSwitch()}
            </Row>

            <hr /><br />
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button onClick={()=>{saveColab()}} disabled={this.state.btndisab} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {setInfoUser(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
 }
}

export default ModalEditUserPermission;
