import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormLogisticShipment from "./Modal/Subs/FormLogistics/FormLogisticShipment";
import FormLogisticsTransporte from "./Modal/Subs/FormLogistics/FormLogisticsTransporte";
export class Elements extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Iniciar trazabilidad" parentName="Logística y Transporte" section="fuits_vegetable" section_two="Logistica y transporte" section_table_form="null" />
      <Container className="mt--6" fluid>
      <FormLogisticShipment {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormLogisticsTransporte {...this.props} putLinkId={this.state.link_id} />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.logistica==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  {this.setInfoInit()}  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_transport_shipment, create_transport_transport, data_transport_shipment, get_permission } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import config from "config";
// import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
// import {UserContext} from "layouts/store";

// export class Elements extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//       this.initData()
//   }
//   initData= async ()=>{
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var gerParam = new URLSearchParams(this.props.location.search).get("code");
//           var dataTrazabilidadvf = await data_transport_shipment(jsondata._id,gerParam)
//           if (dataTrazabilidadvf.shipment==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//   }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//               var completeDate = date.toLocaleDateString("en-CA")

//             this.setState({
//                 transport_shipment_id:'noind',
//                 modo_transporte:'',
//                 fecha_carga:completeDate,
//                 contenedor_numero:'',
//                 sello_numero:'',
//                 unidad_carga:'',
//                 total_unidades:'',
//                 informacion_adicional_shipment:'',
//                 file_document_shipment:undefined,

//                 transport_transport_id:'noind',
//                 fecha_embarque:completeDate,
//                 envio_desde:'',
//                 guia_numero:'',
//                 georeferencia:'',
//                 puerto_embarque:'',
//                 informacion_adicional_transport:'',
//                 file_document_transport:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//     var date = new Date();
//     var completeDate = date.toLocaleDateString("en-CA")

//      if (data.shipment!=null) {
//         this.setState({
//                 transport_shipment_id:data.shipment.id,
//                 modo_transporte:data.shipment.modo_transporte,
//                 fecha_carga:data.shipment.fecha_carga,
//                 contenedor_numero:data.shipment.contenedor_numero,
//                 sello_numero:data.shipment.sello_numero,
//                 unidad_carga: data.shipment.unidad_carga,
//                 total_unidades: data.shipment.total_unidades,
//                 informacion_adicional_shipment:data.shipment.informacion_adicional,
//                 file_document_shipment:data.shipment.archivo
//         })
//       }
//       if (data.transport!=null) {
//         this.setState({
//                 transport_transport_id:data.transport.id,
//                 fecha_embarque:data.transport.fecha_embarque,
//                 envio_desde:data.transport.envio_desde,
//                 guia_numero:data.transport.guia_numero,
//                 georeferencia:data.transport.georeferencia,
//                 puerto_embarque: data.transport.puerto_embarque,
//                 informacion_adicional_transport: data.transport.informacion_adicional,
//                 file_document_transport:data.transport.archivo
//         })
//       }else{
//         this.setState({

//                 transport_transport_id:'noind',
//                 fecha_embarque:completeDate,
//                 envio_desde:'',
//                 guia_numero:'',
//                 georeferencia:'',
//                 puerto_embarque:'',
//                 informacion_adicional_transport:'',
//                 file_document_transport:undefined,
//         })
//       }
//     }
//   render(){
//     const saveInfoShipment = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//     this.setState({mess:'Enviando...'})
//     if (this.state.contenedor_numero=="") {
//       this.setState({mess:'El Campo Contenedor Número es necesario.'})
//       this.setState({btnd:false})
//     }else{
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_request = await create_transport_shipment(this.state,jsondata._id,gerParam);

//         if (var_request.data.message=="exito") {
//           this.setState({mess:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data);
//           this.setState({btnd:false})
//         }
//     }
//   }
//       const saveInfoTransport = async () =>{
//     var userdata = localStorage.getItem('user');
//     var jsondata = JSON.parse(userdata);
//     this.setState({btnd:true})
//         var gerParam = new URLSearchParams(this.props.location.search).get("code");
//         const var_request_trasport = await create_transport_shipment(this.state,jsondata._id,gerParam);
//         const var_request = await create_transport_transport(this.state, jsondata._id, var_request_trasport.data.shipment_id,gerParam);
//         if (var_request.data.message=="exito") {
//           this.setState({mess2:''})
//           notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
//           //setTimeout(function(){window.location.reload()}, 3000);
//           this.setState({btnd:false})
//           this.initData()
//         }else{
//           notify("danger", "¡Registrado Fallido!", var_request.data.message);
//           this.setState({btnd:false})
//         }

//   }

//     const geolocacion = () => {
//       this.setState({loadlocali:'Cargando...'})
//        if (navigator.geolocation) {
//        navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
//        }else{
//          this.setState({loadlocali:null})
//        }
//     };
// function VerPosicion(posicion){
// var lat = posicion.coords.latitude;
// var lon = posicion.coords.longitude;
// var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
// fetch(locapi)
// .then(response => response.json()
//     .then(data => {
//         if (data) {
//           setinfolocation(lat,lon,data)
//         }

//     })
//     .catch(err => console.log(err.message)));
// }
//     const setinfolocation = (lat,lon,data) => {
//           this.setState({loadlocali:null})
//           this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})
// }

// function noPermitir(argument) {
//   this.setState({loadlocali:null})
// // $('.cargadores').hide();
// }
//       const notify = (type, title, message) => {
//     let options = {
//       place: "tc",
//       message: (
//         <div className="alert-text">
//           <span className="alert-title" data-notify="title">
//             {" "}
//             {title}
//           </span>
//           <span data-notify="message">
//             {message}
//           </span>
//         </div>
//       ),
//       type: type,
//       icon: "ni ni-bell-55",
//       autoDismiss: 10,
//     };
//     this.state.notificationAlertRef.current.notificationAlert(options);
//   };

//   return (
//     <>
//         <div className="rna-wrapper">
//         <NotificationAlert ref={this.state.notificationAlertRef} />
//       </div>
//       <VegetablesNavBar getParams={this.props.location.search}/>  
//       <SimpleHeader
//         name="Iniciar trazabilidad"
//         parentName="Logística y Transporte"
//       />
//       <Container className="mt--6" fluid>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Embarque</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlSelect1"
//                   >
//                     Modo de transporte
//                   </label>
//                   <Input 
//                   onChange={(e) => {this.setState({modo_transporte:e.target.value})}}
//                   value={this.state.modo_transporte}
//                   id="exampleFormControlSelect1"
//                   type="select">
//                     <option value="0">Seleccione...</option>
//                     <option value="Aereo">Aereo</option>
//                     <option value="Maritimo">Maritimo</option>
//                     <option value="Terrestre">Terrestre</option>
//                   </Input>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Carga
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({fecha_carga:e.target.value})}}
//                     value={this.state.fecha_carga}
//                     id="example-date-input"
//                     type="date"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Contenedor Número
//                   </label>
//                   <Input
//                   onChange={(e) => {this.setState({contenedor_numero:e.target.value})}}
//                   value={this.state.contenedor_numero}
//                     id="example3cols2Input"
//                     placeholder="Escribe el número de embarque"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Sello Número
//                   </label>
//                   <Input
//                   onChange={(e) => {this.setState({sello_numero:e.target.value})}}
//                   value={this.state.sello_numero}
//                     id="example3cols2Input"
//                     placeholder="Escribe el número de embarque"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Unidad de carga
//                   </label>
//                   <Input
//                   onChange={(e) => {this.setState({unidad_carga:e.target.value})}}
//                   value={this.state.unidad_carga}
//                     id="example3cols2Input"
//                     placeholder="Ejem. Pallets, Cajas, etc."
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Total Unidades
//                   </label>
//                   <Input
//                   onChange={(e) => {this.setState({total_unidades:e.target.value})}}
//                   value={this.state.total_unidades}
//                     id="example3cols2Input"
//                     placeholder="Ejem. 20 Pallets, 100 Cajas, etc."
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                   onChange={(e) => {this.setState({informacion_adicional_shipment:e.target.value})}}
//                   value={this.state.informacion_adicional_shipment}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                     onChange={(e)=>{this.setState({file_document_shipment:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document_shipment==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_shipment}>{String(this.state.file_document_shipment).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="4">
//                 <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoShipment}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Transporte</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example-date-input"
//                   >
//                     Fecha de Embarque
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({fecha_embarque:e.target.value})}}
//                     value={this.state.fecha_embarque}
//                     id="example-date-input"
//                     type="date"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Envio desde
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({envio_desde:e.target.value})}}
//                     value={this.state.envio_desde}
//                     id="example3cols2Input"
//                     placeholder="Escribe el emisor del Envio"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Guia Numero
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({guia_numero:e.target.value})}}
//                     value={this.state.guia_numero}
//                     id="example3cols2Input"
//                     placeholder="Escribe el número de embarque"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//             <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Georeferencia
//                   </label>
//                   <Row>
//                     <Col md="10">
//                     <Input
//                       value={this.state.georeferencia}
//                       id="example3cols2Input"
//                       className="ubicacionex"
//                       placeholder="Escribe la referencia"
//                       type="textarea"
//                       onChange={(e) => this.setState({georeferencia:e.target.value})}
//                     />
//                       {this.state.loadlocali}
//                     </Col>  
//                     <Col md="2">
//                     <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
//                       <span style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
//                     </a>
//                     </Col>  
//                   </Row>
//                   </FormGroup>
//               </Col> 
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Puerto de Embarque
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({puerto_embarque:e.target.value})}}
//                     value={this.state.puerto_embarque}
//                     id="example3cols2Input"
//                     placeholder="Escribe el puerto de embarque"
//                     type="text"
//                   />
//                 </FormGroup>
//               </Col>
//                <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <Input
//                     onChange={(e) => {this.setState({informacion_adicional_transport:e.target.value})}}
//                     value={this.state.informacion_adicional_transport}
//                     id="exampleFormControlTextarea1"
//                     rows="1"
//                     type="textarea"
//                   />
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6" className="documentFormAll">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <Form>
//                   <div className="custom-file">
//                     <input
//                       onChange={(e)=>{this.setState({file_document_transport:e.target.files[0]})}}
//                       className="custom-file-input"
//                       id="customFileLang"
//                       lang="es"
//                       type="file"
//                     />
//                     <label
//                       className="custom-file-label"
//                       htmlFor="customFileLang"
//                     >
//                       Elegir archivo
//                     </label>
//                   </div>
//                 </Form>
//                 {(this.state.file_document_transport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_transport}>{String(this.state.file_document_transport).split('/').pop()}</a>)}
//               </Col>
//             </Row>
//                   {this.state.mess2 ? (
//                     <div className="text-muted font-italic">
//                       <small>
//                         <span className="text-red font-weight-700">
//                           {this.state.mess2}
//                         </span>
//                       </small>
//                     </div>
//                   ) : null}
//             <Row>
//               <Col md="3">
//                  <Button 
//                   className="btn-icon" 
//                   color="traza-blue" 
//                   type="button" 
//                   disabled={this.state.btnd}
//                   onClick={saveInfoTransport}>
//                   <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-check-circle" />
//                   </span>
//                   <span className="btn-inner--text">Guardar</span>
//                 </Button>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Container>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//     </>
//   );
//  }
// }
// class SwiftComponent extends React.Component {
//   static contextType = UserContext
//   state={
//     get_permission:'load',
//     setinfoState:false
//   }
//  async componentDidMount(){
//   this.context.getAffiliates()
//     // var userdata = localStorage.getItem('user');
//     // var jsondata = JSON.parse(userdata);
//     //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
//     // var idcompany = null;
//     // if (jsondata.userType==2) {
//     //   if (gerParam!=null) {
//     //     idcompany = gerParam
//     //   }
//     // }
//     // var dataPermission = await get_permission(jsondata._id,idcompany)
//   }
//  setInfoInit = () =>{
//     if (this.state.setinfoState) {
//     return;  
//     }
//     if (this.context.valueGlobal.length!=0) {
//           this.setState({setinfoState:true})
//         }else{
//           return
//         }
//     var dataPermission = this.context.valueGlobal
//     if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
//   this.setState({get_permission:false})
//   return;
// }
//         if (dataPermission.role==1) {
//           this.setState({get_permission:true})
//         }else{
//             if (dataPermission.type=='admin') {
//               this.setState({get_permission:true})
//             }
//           if (dataPermission.type=='options') {
//             if (dataPermission.logistica==1) {
//               this.setState({get_permission:true})
//             }else{
//               this.setState({get_permission:false})
//             }
//           }
//           if (dataPermission.type=='view') {
//             this.setState({get_permission:false})
//           }
//         }
//       }  
// render(){
//   {this.setInfoInit()}  
//   return (
//     <>
//     {(this.state.get_permission=='load')?(
//       <h1 className="text-center">Cargando...</h1>
//       ):(
//       (this.state.get_permission)?(
//         <Elements {...this.props} />
//         ):(
//         <ViewPermision />
//         )
//       )}
//     </>
//   );
//  }
// }
// export default SwiftComponent;

// //export default Elements;
