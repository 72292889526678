import React, {useState} from 'react';
import {
  Navbar,
  NavbarBrand,
  NavLink,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import {UserContext} from "layouts/store";
import jQuery from "jquery";

  class MeatsNavBar extends React.Component {
    static contextType = UserContext
    state ={
      modal:false,
      color:'transparent',
      paramUrlLote:false,
      trazabilidad_m:'none',
      lote_m:'none',
      logistics_m:'none',
      compartiment_m:'none',
      fridge_m:'none',
      documents_m:'none',
      trazabilidad_end:'none',
      paramUrlCode:null
    }
    componentDidMount(){
      jQuery(".documentFormAll").hide()
      jQuery("#insertDocumentShow").val('none')
      if (this.props.getParams.indexOf('lote=')!=-1) {
      this.setState({paramUrlLote:true})
      }
       var gerParam = new URLSearchParams(this.props.getParams).get("code");
      this.setState({paramUrlCode:gerParam})

    }
  render(){
      const setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    var getPermission = this.context.valueGlobal
    if (getPermission.user_id_connection==null) {
      jQuery("#insertIdUser").val(getPermission.user_id)
    }else{
      jQuery("#insertIdUser").val(getPermission.user_id_connection)
    }
      if (getPermission.role==1) {
        jQuery("#insertDocumentShow").val('block')
        jQuery(".documentFormAll").show()
            this.setState({
              trazabilidad_m:'block',
              lote_m:'block',
              logistics_m:'block',
              compartiment_m:'block',
              fridge_m:'block',
              documents_m:'block',
              trazabilidad_end:'block',
            })
      }else{
        if (getPermission.type=='options') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad_m:(getPermission.trazabilidad_m==0)?'none':'block',
              lote_m:(getPermission.lote_m==0)?'none':'block',
              logistics_m:(getPermission.logistica_m==0)?'none':'block',
              compartiment_m:(getPermission.compartimento_m==0)?'none':'block',
              fridge_m:(getPermission.frigorifico_m==0)?'none':'block',
              documents_m:(getPermission.documentos_m==0)?'none':'block',
              trazabilidad_end:'none',
            })
        }
        if (getPermission.type=='admin') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad_m:'block',
              lote_m:'block',
              logistics_m:'block',
              compartiment_m:'block',
              fridge_m:'block',
              documents_m:'block',
              trazabilidad_end:'none',
            })
        }

      }
        if (this.context.valueGlobal.length!=0) {
        this.setState({setinfoState:true})
        }
      }    
    const openToggler = (valOpen) =>{
      if (this.state.modal==true) {
       this.setState({modal:false,color:'transparent'})
      }else{
       this.setState({modal:true,color:'#2d597f'})
      }
      console.log(this.state)
    }
  return (
    <>
      <input type="hidden" id="insertIdUser" />
      <input type="hidden" id="insertDocumentShow" />
      <div>
      {setinfoInit()}
          <Navbar style={{backgroundColor:'#9d0e0d', height: '50px'}} expand="lg" light>
            <NavbarToggler onClick={() => {openToggler(true)}} />
            <Collapse isOpen={this.state.modal} navbar style={{zIndex: '10', backgroundColor:this.state.color}}>
              <Nav className="me-auto" navbar style={{fontSize: '14px'}}>

                  {(this.props.getParams=="")
                  ?
              (
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700' }}
                  to="/admin/start-trace/meats"
                >
                  Trazabilidad
                </ComponentLink>
              )
                  :
                  (<>
              <UncontrolledDropdown inNavbar nav style={{display: this.state.trazabilidad_m}}>
                <DropdownToggle style={{ fontWeight:'700', color:"white" }} caret nav>
                  Trazabilidad
                </DropdownToggle>
                <DropdownMenu right style={{left: '0px'}}>
                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink  style={{ fontWeight:'700' }} to={"/admin/start-trace/meats"+this.props.getParams}>
                        Trazabilidad
                      </ComponentLink>
                    </div>
                  </DropdownItem>
 {/*                 <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink className="nav-link" style={{ fontWeight:'700' }} to={"/admin/meats/add-animal"+this.props.getParams}>
                        Agregar animal
                      </ComponentLink>
                    </div>
                  </DropdownItem>*/}
                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/meats/inventory-animal"+this.props.getParams}>
                        Inventario de Animales
                      </ComponentLink>
                    </div>
                  </DropdownItem>

                  <DropdownItem style={{padding: '0.5rem 0rem'}}>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/meats/eliminate-animal"+this.props.getParams}>
                        Animales Eliminados
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                    </>)}
              
              {(this.props.getParams=="")
                  ?
                  (null)
                  :
                  (<>
              
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.lote_m }}
                  to={"/admin/meats/job-lote"+this.props.getParams}
                >
                  Lote
                </ComponentLink>
              

{(!this.state.paramUrlLote)?(null):(
  <>
                
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.logistics_m }}
                  to={"/admin/meats/logistics"+this.props.getParams}
                >
                  Logística
                </ComponentLink>
              
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.compartiment_m }}
                  to={"/admin/meats/guide"+this.props.getParams}
                >
                  Guía
                </ComponentLink>
              
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.compartiment_m }}
                  to={"/admin/meats/compartiment"+this.props.getParams}
                >
                  Compartimento
                </ComponentLink>
              
              <UncontrolledDropdown inNavbar nav style={{display: this.state.fridge_m}}>
                <DropdownToggle style={{ fontWeight:'700', color:"white" }} caret nav>
                  Frigorífico
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700'}} to={"/admin/meats/fridge"+this.props.getParams}>
                        Frigorífico
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink style={{ fontWeight:'700' }} to={"/admin/meats/slaughterhouse"+this.props.getParams}>
                        Matadero
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.documents_m }}
                  to={"/admin/meats/doc-meats"+this.props.getParams}
                >
                  Documentos
                </ComponentLink>
              
              
                <ComponentLink className="nav-link"
                  style={{ color:"white", fontWeight:'700',display: this.state.trazabilidad_end }}
                  to={"/admin/meats/end-traceability"+this.props.getParams}
                >
                  Finalizar trazabilidad
                </ComponentLink>
              
              </>)}
{/*              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle style={{ fontWeight:'700', color:"white" }} caret nav>
                  Comercialización
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink className="nav-link" style={{ fontWeight:'700', color:"white"}} to="/admin/meats/comercialization">
                        Comercialización
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div style={{ paddingLeft: "1vw" }}>
                      <ComponentLink className="nav-link" style={{ fontWeight:'700' }} to="/admin/meats/Packaging">
                        Consumidor
                      </ComponentLink>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>*/}

              </>)}
            </Nav>
          </Collapse>
        </Navbar>
      </div>        
    </>
  );
 }
}

export default MeatsNavBar;
