import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";
import ModalInviteUser from "../Modal/ModalInviteUser";
export class Elements extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    jsondataBase:null,
    sendOpenModal:false,
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tm').remove()
    jQuery('#tvf').remove()
    return
  }else{
    if (this.props.sendDataCompany.type_traza=="VF") {
      jQuery('#tm').remove()
    }
    if (this.props.sendDataCompany.type_traza=="M") {
      jQuery('#tvf').remove()
    }
  }
  // || this.props.sendDataCompany.type_traza=="VF-M"
  var data = await data_list_processes(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  // if (data.trazabilidad[1].length!=0) {
  //   data.trazabilidad[1].map((a,b)=>{
  //     data.trazabilidad[0].map((val,ind)=>{
  //     console.log(b)

  //       //console.log(a[val.split('*')[1]])
  //     })
  //   })
  // }
  this.setState({
    company_id:gerParam,
    data_traza:data.trazabilidad[1],
    data_traza_th:data.trazabilidad[0],
    data_traza_g:data.traza_grain[1],
    data_traza_g_th:data.traza_grain[0],
    trazabilidad_meat:data.trazabilidad_meat,
    jsondataBase:jsondata.database
  })
  }
  logictRouteParam=()=>{
      var routeParamNameFV = 'start-trace/vegetables-fruits'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.trazabilidad:
            routeParamNameFV='start-trace/vegetables-fruits';
            break;
          case this.props.sendDataCompany.produccion:
            routeParamNameFV='fruits-vegetables/production';
            break;
          case this.props.sendDataCompany.cosecha:
            routeParamNameFV='fruits-vegetables/harvest';
            break;
          case this.props.sendDataCompany.planta:
            routeParamNameFV='fruits-vegetables/plant';
            break;
          case this.props.sendDataCompany.logistica:
            routeParamNameFV='fruits-vegetables/logistics';
            break;
          case this.props.sendDataCompany.documento:
            routeParamNameFV='docs';
            break;
          case this.props.sendDataCompany.comercializa:
            routeParamNameFV='fruits-vegetables/Comercialization';
            break;
          default:
            routeParamNameFV='start-trace/vegetables-fruits';
        }
      }
      var routeParamNameGrain = 'info_crops_t'
      if (this.props.sendDataCompany.role==2) {
        switch (1) {
          case this.props.sendDataCompany.information_grain_t:
            routeParamNameGrain='info_crops_t';
            break;
          case this.props.sendDataCompany.production_grain_t:
            routeParamNameGrain='grain_seed_t';
            break;
          case this.props.sendDataCompany.harvest_grain_t:
            routeParamNameGrain='grain_harvest_info_harvest_t';
            break;
          case this.props.sendDataCompany.document_grain_t:
            routeParamNameGrain='grains_traza_documents';
            break;
          default:
            routeParamNameGrain='info_crops_t';
        }
      }
    return {routeParamNameGrain,routeParamNameFV}
  }
  setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
        data.map((a,b)=>{
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/grains-nama-info_crop"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/"+this.logictRouteParam().routeParamNameGrain
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/"+this.logictRouteParam().routeParamNameFV
              }
            }
   var content_two = <>
    
    {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1]
                     }
                     }}
                   >
                  Ver Esta Trazabilidad
                  </ComponentLink>
                </td>
                <td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>
                {/* <td><button className="btn" title="Compartir con usuario" onClick={()=>{this.setInfoInvoice(true)}}> <span className="fas fa-share-alt"></span> </button></td> */}
                </>):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      Object.values(data).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }
  setInfoInvoice=(changeModal)=>{
    this.setState({sendOpenModal:changeModal})
  }
render(){
  return (
    <>
    {/* <ModalInviteUser sendOpenModal={this.state.sendOpenModal} setInfoInvoice={this.setInfoInvoice} contexto={this.context}/> */}
      <SimpleHeader name="Trazabilidad en proceso" parentName="Trazabilidad en proceso" section="fuits_vegetable" section_two="Tabla" section_table_form="table_traza_proccess" />
        <Container className="mt--6" fluid>
          <Row>
          <Col md={12}>
            <Card id="tvf" style={{display:(this.state.data_traza==undefined)?('none'):((this.state.data_traza[0]==undefined)?('none'):('block'))}}>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Trazabilidad Vegetales y frutas frescas</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>id</th>
                  {this.setContentTh(this.state.data_traza_th)}
                    <th>Creado</th>
                    <th>Código</th>
                    <th>Opciones</th>
                    <th>QR</th>
                    {/* <th>Invitar/Venta</th> */}
                  </tr>
                </thead>
                <tbody>
                    {this.setContent(this.state.data_traza)}
                </tbody>
              </Table>
            </Card>

            <Card id="tm" style={{display:(this.state.trazabilidad_meat[0]==undefined)?('none'):('block')}}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Trazabilidad Carnicos</h3>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>id</th>
                  <th>especie</th>
                  <th>Creado</th>
                  <th>Trazabilidad Código</th>
                  <th>Nombre de la trazabilidad</th>
                  <th>Lote</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
              {this.state.trazabilidad_meat.map((value,index)=>{
                return(
                  <tr>
                    <td>
                      <span className="text-muted">
                      {value.id}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.especie}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.created_at}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.codigo}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.nombre_traza}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                      {value.lote_numero}
                      </span>
                    </td>
                    <td>
                      <ComponentLink
                        className="font-weight-bold"
                                              to={{
                          pathname:"/admin/meats/job-lote",
                          search: (this.state.company_id==null)?("?code="+value.codigo+'&lote='+value.lote_numero):("?company="+this.state.company_id+"&code="+value.codigo+'&lote='+value.lote_numero),
                          state:{
                            codigoTraza:value.codigo
                          }
                          }}
                        >
                      Ver Esta Trazabilidad
                      </ComponentLink>
                    </td>
                  </tr>)
              })
            }

              </tbody>
            </Table>
          </Card>
        <Card id="tg" style={{display:(this.state.data_traza_g==undefined)?('none'):((this.state.data_traza_g[0]==undefined)?('none'):('block'))}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Grano: Trazabilidad arroz</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_traza_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
                <th>QR</th>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_traza_g)}
            </tbody>
          </Table>
        </Card>
        </Col>

        {/* <Col md={6}>
        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Invitar usuario para la participacion de esta trazabilidad</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            
          </CardBody>
        </Card>
        </Col> */}
        </Row>
      </Container>
      <br/><br/>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false,
  }
 async componentDidMount(){
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
          this.setState({company_id_act:dataPermission})
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
