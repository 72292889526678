import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Collapse,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { history_list_production_mrv, add_favorite_mrv, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import MRVProd from "views/pages/forms/Production/MRV/MRVProViewTable";
import * as firedatabase from "firebase/database";
import { db } from "network/ConfigFirebase";
export class ReportProductionMVRHistory extends React.Component {
  state={
    data_users_prod:[],
    company_id:null,
    collapse:[],
    type_search:1,
    dropdownOpen:[]
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.dataTypeRole==null) {
    jQuery('#tg').remove()
    return
  }else{
    if (this.props.dataTypeRole=="G") {
      jQuery('#tg').remove()
    }
  }
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
  })
  this.putInfoProdSelect(this.state.type_search)
  this.initFireBase()
  }
  get_db = (codigo,data) => {
    var get_data = data.filter((a,b)=>a.btnides==codigo)
    return 'db_traza_'+get_data[0].user_id
  }
  addDataFavorite = async (code_prod,user_id_db,check) =>{
    await add_favorite_mrv(code_prod,user_id_db,check)
  }
  putCheck = async (selec,code,data) =>{
    await data
    jQuery('.'+selec).prop('checked',false)
    data.map((a,b)=>{
      if (a.code_prod!=null) {
        jQuery('.check_'+a.code_prod).prop('checked',true)
      }
    })
  }
  changetogle= async(indx,dropdownOpen,ele)=>{
    await this.state.dropdownOpen
    //adjustTable
    // if (dropdownOpen==undefined) {
    //   jQuery(".adjustTable").height(String(getHDD+10))
    // }else{
    //    if (dropdownOpen) {
    //        jQuery(".adjustTable").height(String(getHDD+10))
    //    }else{
    //    jQuery(".adjustTable").height('0')
    //    }
    // }
    this.setState({dropdownOpen:{[indx]:!dropdownOpen}})
    var getHDD = document.querySelector('.ddmgeth_'+indx)?.offsetHeight
    if (this.state.dropdownOpen[indx]) {
      jQuery(".adjustTable").height(String(getHDD+10))
    }else{
      jQuery(".adjustTable").height('0')
    }
    //document.querySelector('.adjustTable').style.height=getHDD+"px"
  }
  rowData=(data,data_db_user,codigo)=>{
    var getData = JSON.parse(data)
    var content = []
    getData.map((a,b)=>{
      if (JSON.stringify(a.datastate.state_status).split('Revisar').length-1!=0) {
        content[b]=<><DropdownItem>
                  <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"detail_monitoring",
                        search: "?db="+data_db_user+"&code="+codigo,
                        state:{
                          table_params:a.datastate.activity_table[0],
                          title_params:a.datastate.activity[0]
                        }
                        }}
                      >
                      {a.datastate.activity[0]}
                     </ComponentLink>
        </DropdownItem></>
      }
    })
    return content
  }
  tracking=(data,code_row)=>{
    var contetn = []
    Object.values(data).map((a,b)=>{
    var getdataiter = []
    Object.values(this.state.data_users_prod[2]).map((a,b)=>{
      getdataiter.push(...a)
    })
      if (a!=null) {
        if (a.codigo==code_row) {
          if (a.data_adjunt!=null) {          
            if (a.data_adjunt.split('Por Hacer').length-1) {
              contetn[0]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{a.data_adjunt.split('Por Hacer').length-1} Por Hacer</span><br /></>
            }
            if (a.data_adjunt.split('Revisar').length-1) {
              contetn[1]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{a.data_adjunt.split('Revisar').length-1} Revisar</span><br />
              <br />
              </>
            }
            if (a.data_adjunt.split('No Conforme').length-1) {
              contetn[2]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{a.data_adjunt.split('No Conforme').length-1} No Conforme</span><br /></>
            }
            if (a.data_adjunt.split('Realizado Conforme').length-1) {
              contetn[3]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{a.data_adjunt.split('Realizado Conforme').length-1} Realizados Conforme</span><br /></>
            }
          }

        }
      }
    })
    return contetn
  }
  setContent = (data,data_ind,data_three) => {
    if (data==undefined) {
      return
    }
    var contentth = []

    if (Object.values(data).length!=0) {
    var content=[]
    var getdataiter = []
    Object.values(data).map((a,b)=>{
      getdataiter.push(...a)
    })
    if (getdataiter.length==0) {
        content = <><tr><td>Sin Resultado</td></tr></>
      return content
    }

      getdataiter.reverse().map((a,b)=>{
        var get_codigo = null
        var content_one = []
if (a.stado_status=="desarrollo") {  
         content_one[b] = Object.entries(a).map((val,ind)=>{
            if (val[0]=='btnides') {
              get_codigo=val[1]
            }
         data_ind.map((valind,indind)=>{
             if (valind.split('*')[1].indexOf(val[0])!=-1) {
               if (val[0]!='comunidad') {
                 contentth[ind+1]='<th>'+valind.split('*')[0]+'</th>'
               }
               if (val[0]=="nombre_productor") {
                 contentth[ind+1]='<th>Nombre</th>'
               }
               if (val[0]=="apellido_productor") {
                 contentth[ind+1]='<th>Apellido</th>'
               }
             }
           })
 var content_two = <>
  {(val[0]=='code_tracking')?(
    <td><span className="text-muted">
      {
        this.tracking(data_three,val[1])
      }
    </span></td>
    ):(null)}
  {(val[0]=='btnides_fav')?(<><td style={{textAlign: 'center'}}><input type="checkbox" className={"check_"+val[1]} onLoad={this.putCheck("check_"+val[1],val[1],getdataiter)} onClick={(e)=>{this.addDataFavorite(val[1],this.get_db(val[1],getdataiter),e.target.checked)}} /></td></>):(null)}
  {(val[0]!='btnides_fav' && val[0]!='btnides' && val[0]!='user_id' && val[0]!='code_prod' && val[0]!='code_tracking' && val[0]!='stado_status' && val[0]!='comunidad')?(<td ><span className="text-muted" style={{fontSize: "12px"}}>{val[1]}</span></td>):(null)}
  {(val[0]=='btnides')?(<>
                  <td>
                    <ComponentLink
                        className="font-weight-bold"
                                              to={{
                          pathname:"prouction-observations-mvr",
                          search: "?db="+this.get_db(val[1],getdataiter)+"&code="+val[1]+"&mrv="+this.props.dataTypeRole,
                          state:{
                            db_codigo:this.get_db(val[1],getdataiter)
                          }
                          }}
                        >
                        Ver 
                     </ComponentLink>
                   </td>
                   </>):(null)}
                      </>
                      return content_two
        })
}

        content[b] = <>
                    <tr>
                    {content_one}
                    </tr>
                    </>
      })
    }
    //console.log(data_three,'<-')
    contentth[contentth.length] = '<th style="width: 10px;">Empresa</th>'
    if (data_three.length!=0) {
      contentth[contentth.length] = '<th style="width: 10px;">Seguimiento</th>'
    }
    if (data_three.length!=0) {
      contentth[contentth.length] = '<th>Mis Observaciones</th>'
    }
    jQuery('.putContentTh').html(contentth)
    return content
  }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }
// setContentTh = (data) => {
//   var content = []
//     if (data!=undefined) {
//       Object.values(data).map((a,b)=>{
//         content[b]=<th>{a.split('*')[0]}</th>
//       })
//     }
//     return content
//   }
 setContentTh = (data,data_ind) => {
   var content = []
      if (data!=undefined) {
      data_ind.map((valind,indind)=>{
        data.map((a,b)=>{
            Object.entries(a).map((val,ind)=>{
                if (valind.split('*')[1].indexOf(val[0])!=-1) {
                 content[ind]=<th>{valind.split('*')[0]}</th>
                }
                })
            })
        })
      }
      return content
   }
initFireBase = () => {
  var userId = this.getUserId()
        firedatabase.onValue(this.getDBFire(), (snapshot) => {
          if (snapshot.exists()) {
             var data = snapshot.val();
             var dataKey = Object.keys(data);
             var getData = Object.values(data)
             if (getData[getData.length-1].notifications!=undefined) {
              if (getData[getData.length-1].collab=='Si') {
                this.putInfoProdSelect(this.state.type_search)
                //this.notify("info", "¡Nueva Notificación!", 'Nueva Notificación: '+getData[getData.length-1].titulo);
              }
               // if (getData[getData.length-1].user_id!=userId) {
               // }
             }
          }
      });
}
getUserId = () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var data_response = null
  if (jsondata.userType==4) {
    data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
  }else{
    if (jsondata.database==undefined) {
      data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
    }else{
      data_response = {id_user:jsondata._id,database_user:jsondata.database+'/'+'user/'+jsondata._id}
    }
  }
    return data_response
}
getDBFire = () => {
  const productsCollection = firedatabase.ref(db,this.getUserId().database_user)
  return productsCollection
};
   putInfoProdSelect = async (type_search) => {
    this.setState({type_search:type_search})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var get_value = jQuery('#input_search').val()
    if (type_search==1) {
      var data = await history_list_production_mrv(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',get_value,1,jsondata.type_traza)
      this.setState({data_users_prod:data.data_productions })
    }
   }
render(){
  return (
    <>
      <SimpleHeader name="Historial" parentName="Historial" section="mrv" section_two="Tabla" section_table_form="table_traza_proccess" />
        <Container className="mt--6" fluid>

        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col md="2">
                <h3 className="mb-0">Historial</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush adjustTable" responsive>
            <thead className="thead-light">
              <tr className={"putContentTh"}>
{/*              {this.setContentTh(this.state.data_users_prod[2],this.state.data_users_prod[1])}
                <th>Opciones</th>*/}
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_users_prod[2],this.state.data_users_prod[1],this.state.data_users_prod[3])}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()

  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }

    var dataPermission = this.context.valueGlobal
          var data_type_area_on = dataPermission.type_traza.indexOf('ON')
          var data_type_area_re = dataPermission.type_traza.indexOf('RE')
          var data_type_area_va = dataPermission.type_traza.indexOf('VA')

          if (dataPermission.type_traza=="ON-RE-VA") {
            this.setState({data_user_traza:jsondata.type_traza})
          }else{
            this.setState({data_user_traza:dataPermission.type_traza})
          }

          if (data_type_area_on==-1 || data_type_area_re==-1 || data_type_area_va==-1) {
            this.setState({get_permission:false})
          }
          // if (data_type_area_on==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_re==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_va==-1) {
          //   this.setState({get_permission:false})
          // }
        this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
          if (this.context.valueDataMain[0]==undefined) {
            this.setState({get_permission:false})
          }
        }else{
          if (dataPermission.role==4) {
            this.setState({get_permission:true})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <ReportProductionMVRHistory {...this.props} dataTypeRole={this.state.data_user_traza} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default BuildingProduction;
