import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { login } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
// import { getToken, onMessage } from "firebase/messaging";
// import { messaging } from "network/ConfigFirebase";
const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState(true);

 const tryLogin = async () => {
  setCheckbox(false)
    setError("");
    var valid_email = String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (!valid_email) {
      setCheckbox(true)
      setError('Correo Invalido')
      return
    }
    const response = await login(email, password).catch(err => { 
      setCheckbox(true)
      alert(err.response.data.message)      
    });
    if (response==undefined) {
      return
    }
    //console.log(response.data)
    if (response.data=='incorrecta') {
      setCheckbox(true)
      setError("Correo incorrecto.");
  }else{
    if (response.data=='deshabilitar') {
      setCheckbox(true)
      setError("Esta cuenta se encuentra temporalmente inactiva.");
      return;
    }
    if (response.data=='validate_email') {
      setCheckbox(true)
      setError("Esta cuenta no se encuentra verificada, se le ha enviado un correo para su activación.");
      setTimeout(function(){ props.history.push('/home/validation',{email:email}) }, 3000);
      return;
    }
      if (response.data=='contrasena') {
        setCheckbox(true)
        setError("Contraseña incorrecta.");
      }else{
        const { data } = response;
        if (data) {
          setCheckbox(true)
          setError("");
          localStorage.setItem("token", data.token);
          localStorage.setItem("photo_profile", data.namephoto);
          localStorage.setItem("user_wallet", data.wallet);
          localStorage.setItem("user", JSON.stringify(data));
            if (data.type_traza=='ON' || data.type_traza=='RE' || data.type_traza=='VA') {
              props.history.push('/admin/report-production-users')
            }else{
              props.history.push("/admin/home");
            }
        } else {
          setCheckbox(true)
          setPassword("");
          setError("Hubo un error al iniciar sesión. Intenta más tarde.");
        }
      }
    }
  };
  const saveTokenFirebase = (getIdUser) =>{
  }
  /*const tryLogin = async () => {
    const response = await login(email, password);
    const { data } = response;

    if (data) {
      setError("");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      props.history.push("/admin/home");
    } else {
      setPassword("");
      setError("Hubo un error al iniciar sesión. Intenta más tarde.");
    }
  };*/
  return (
    <>
      <AuthHeader
        title="¡Bienvenido!"
      />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb--4">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center text-muted mb-4">
                  Ingrese su correo electronico y contraseña
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-at" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Correo electrónico"
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Col>
                     <ComponentLink
                      className="font-weight-bold" to={{pathname:"/home/reset-password"}}>
                     Se me olvidó la contraseña
                     </ComponentLink>
                  </Col>
                  {error ? (
                    <div className="text-muted font-italic">
                      <small>
                        error:{" "}
                        <span className="text-red font-weight-700">
                          {error}
                        </span>
                      </small>
                    </div>
                  ) : null}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="traza-green"
                      type="button"
                      onClick={tryLogin}
                      disabled={!email || !password || !checkbox}
                    >
                      Entrar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
