import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import { create_plant_pre_cold, create_plant_cold, data_cold_pre_cold, data_production, get_permission } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import FormCommerceMarket from "./ModalExtend/Subs/FormCommerce/FormCommerceMarket";
import FormCommerceExport from "./ModalExtend/Subs/FormCommerce/FormCommerceExport";
export default class Comercializa extends React.Component {
  state={
    link_id:null
  }
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
  }  
  render(){
  return (
    <>
      <FormCommerceMarket {...this.props} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal} />
      <FormCommerceExport {...this.props} putLinkId={this.state.link_id} />

    </>
  );
 }
}
// import React, {createRef, useEffect} from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   Container,
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody
// } from "reactstrap";
// import { create_commercialization_market, create_commercialization_export, data_export_market } from "network/ApiAxios";
// import NotificationAlert from "react-notification-alert";
// import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
// import SimpleHeader from "components/Headers/SimpleHeader.js";
// import jQuery from 'jquery';
// import config from "config";

// export default class Elements extends React.Component {
//     state={
//     verifyModal:false,
//     btnd:false,
//     notificationAlertRef: React.createRef(),
//     sendOpenModal:false,
//   }
//     async componentDidMount(){
//             var documentForm = jQuery("#insertDocumentShow").val()
//           if (documentForm=='none') {
//             this.setState({documentForm:documentForm})
//           }
//           if(this.props.paramsCode==undefined || this.props.paramsCode==null){
//             var codigoTraza = 'nocode';
//           }else{
//             var codigoTraza = this.props.paramsCode;
//           }
//           var userdata = localStorage.getItem('user');
//           var jsondata = JSON.parse(userdata);
//           var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
//           var dataTrazabilidadvf = await data_export_market(jsondata._id,codigoTraza,codigoTrazaTwo)
//           if (dataTrazabilidadvf.market==null) {
//             this.setDataCamposEmpty()
//           }else{
//             this.setDataCampos(dataTrazabilidadvf)
//           }
//   }
//       setDataCamposEmpty = ()=>{
//               var date = new Date();
//               var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
//             this.setState({
//                 market_id:'noind',
//                 comprador_nombre:'',
//                 empresa:'',
//                 ciudad:'',
//                 provincia:'',
//                 persona_contacto:'',
//                 telefono_contacto:'',
//                 email:'',
//                 numero_factura:'',
//                 informacion_adicional_market:'',
//                 file_document_market:undefined,

//                 export_id:'noind',
//                 comprador_nombre_export:'',
//                 contacto_export:'',
//                 telefono_contacto_export:'',
//                 email_export:'',
//                 numero_factura_export:'',
//                 informacion_adicional_export:'',
//                 file_document_export:undefined,
//             })
//       }

//     setDataCampos = (data)=>{
//      if (data.market!=null) {
//         this.setState({
//                 market_id:data.market.id,
//                 comprador_nombre:data.market.comprador_nombre,
//                 empresa:data.market.empresa,
//                 ciudad:data.market.ciudad,
//                 provincia:data.market.provincia,
//                 persona_contacto: data.market.persona_contacto,
//                 telefono_contacto: data.market.telefono_contacto,
//                 email: data.market.email,
//                 numero_factura: data.market.numero_factura,
//                 informacion_adicional_market:data.market.informacion_adicional,
//                 file_document_market:data.market.archivo
//         })
//       }
//       if (data.export!=null) {
//         this.setState({
//                 export_id:data.export.id,
//                 comprador_nombre_export:data.export.comprador_nombre,
//                 contacto_export:data.export.contacto,
//                 telefono_contacto_export:data.export.telefono_contacto,
//                 email_export:data.export.email,
//                 numero_factura_export: data.export.numero_factura,
//                 informacion_adicional_export: data.export.informacion_adicional_export,
//                 file_document_export:data.export.archivo
//         })
//       }
//     }
//   render(){

//   return (
//     <>
//         <Card className="mb-4">
//           <CardHeader>
//             <h3 className="mb-0">Mercado Nacional</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
// {/*              <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Comprador Nombre
//                   </label>
//                   <br />
//                   <span>{this.state.comprador_nombre}</span>
//                 </FormGroup>
//               </Col>*/}
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Empresa
//                   </label>
//                   <br />
//                   <span>{this.state.empresa}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Ciudad
//                   </label>
//                   <br />
//                   <span>{this.state.ciudad}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Provincia
//                   </label>
//                   <br />
//                   <span>{this.state.provincia}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Persona de Contacto
//                   </label>
//                   <br />
//                   <span>{this.state.persona_contacto}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Telefono de Contacto
//                   </label>
//                   <br />
//                   <span>{this.state.telefono_contacto}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Email
//                   </label>
//                   <br />
//                   <span>{this.state.email}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Numero de Factura
//                   </label>
//                   <br />
//                   <span>{this.state.numero_factura}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_market==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_market}>Ver archivo</a>)}</span>
//               </Col>)}
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_market}</span>
//                 </FormGroup>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//         <Card className="mb-4" style={{display:(this.state.comprador_nombre_export==undefined)?('none'):('block')}}>
//           <CardHeader>
//             <h3 className="mb-0">Exportacion</h3>
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Comprador Nombre
//                   </label>
//                   <br />
//                   <span>{this.state.comprador_nombre_export}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Contacto
//                   </label>
//                   <br />
//                   <span>{this.state.contacto_export}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Telefono de Contacto
//                   </label>
//                   <br />
//                   <span>{this.state.telefono_contacto_export}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Email
//                   </label>
//                   <br />
//                   <span>{this.state.email_export}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="example3cols2Input"
//                   >
//                     Numero de Factura
//                   </label>
//                   <br />
//                   <span>{this.state.numero_factura_export}</span>
//                 </FormGroup>
//               </Col>
//               <Col md="4" sm="6">
//                 <FormGroup>
//                   <label
//                     className="form-control-label"
//                     htmlFor="exampleFormControlTextarea1"
//                   >
//                     Información adicional
//                   </label>
//                   <br />
//                   <span>{this.state.informacion_adicional_export}</span>
//                 </FormGroup>
//               </Col>
//               {(this.state.documentForm=='none')?(null):(<Col className="documentFormAll" md="4" sm="6">
//                 <label
//                   className="form-control-label"
//                   htmlFor="exampleFormControlTextarea1"
//                 >
//                   Subir Documento
//                 </label>
//                 <br />
//                 <span className="alert-title" data-notify="title">{(this.state.file_document_export==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_export}>Ver archivo</a>)}</span>
//               </Col>)}
//             </Row>
//           </CardBody>
//         </Card>

//     </>
//   );
//  }
// }

// //export default Elements;
