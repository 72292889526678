import React, {useState} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Table
} from "reactstrap";
import SelectSearch from 'react-select-search';
import "react-select-search/style.css";
import jQuery from 'jquery';
import config from "config";

export function PutFormContent(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[i].type_field) {
            case 'text':
               data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                 <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <InputGroup>
                    {(props.content[i].type_field_include=='select_include')?(<>
                    <Input
                      value={(props.field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[i].name_field.replace('select_','')])}
                      id={props.content[i].name_field.replace('select_','')}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />

                    <Input
                         value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                         id={props.content[i].name_field}
                         onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                         disabled={(props.content[i].dis_field==1)? (true) : (false)}
                         isrequired={props.content[i].req_field}
                         type="select">
                         <option value="0">Seleccione...</option>
                         {props.content[i]?.value_option?.split('|').map((a,b)=>{
                         return <option value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(<>
                      <Input
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="text"
                    /> </>)}

                  </InputGroup>
                 </FormGroup>
                </Col>            
              break;
            case 'numeric':
               data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                    <Input
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,'numeric')}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />
                  </FormGroup>
                </Col>
              break;
            case 'textarea':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                    value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="textarea"
                  />
                </FormGroup>
              </Col>    
              break;
            case 'select':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
      
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
{(props.optionsSelect[props.content[i].name_field]==undefined)?(null):(<SelectSearch 
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      placeholder={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      options={props.optionsSelect[props.content[i].name_field]}
                      getOptions={(e,a)=>props.forSearhData(e,a)}
                      search
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      onChange={(e,a) => {props.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />)}
                </FormGroup>
              </Col>    
              break;
            case 'date':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                    defaultValue={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="date" />
                </FormGroup>
              </Col>    
              break;
            case 'time':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                    value={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="time" />
                </FormGroup>
              </Col>
              break;
            case 'file':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.files[0], e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    type="file" />
                {(props.field_params[props.content[i].name_field]==null) ? "Sin archivo": (<Button data-url={config.URL_PRODUCTION+"/storage/file_documents/"+props.field_params[props.content[i].name_field]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(props.field_params[props.content[i].name_field]).split('/')[1]}</Button>)}
                </FormGroup>
              </Col>
              break;
            case 'georeference':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                                        id={props.content[i].name_field}
                                        isrequired={props.content[i].req_field}
                                        onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {props.geolocacion(e.target)}}>
                                          <span isrequired={props.content[i].req_field} id={props.content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content);
}
const forStyleCursive = (data,field)=>{
  if (data==1) {
    jQuery('#'+field).find('.select-search__input').css('font-style','oblique')
    jQuery('#'+field).find('.select-search__option').css('font-style','oblique')
  }
}
const setValueMultiFunc = (value,checked)=>{
  var valores=[]
  jQuery('input[class*="set_value_multi_"]:checked').map((a,e)=>{
    valores[a]=e.value
  })
  return valores.join('|')
}
const loadValueMultiFunc = (classSelect,value, valueIdent)=>{
  //console.log(classSelect,value, valueIdent)
  jQuery('.'+classSelect).prop('checked',false)
  if (value.indexOf(valueIdent)!=-1) {
    jQuery('.'+classSelect).prop('checked',true)
  }
}
const putContentMultiple =(data,indx)=>{
  var content = []
  //data.setFieldValue(e.target.value)
  //setValueMultiFunc(e.target.value,e.target.checked)
          content=<Row>
                    {data.optionsSelect[data.content[indx].name_field].map((val,ind)=>{
                      if (val.value=="") {
                         return <Col md={12}>
                            <label className="form-control-label" style={{fontSize: '11px'}}>Sin lote</label>
                          </Col>
                      }else{
                        return <Col md={6}>
                        <label className="form-control-label" style={{fontSize: '11px'}}>{val.value}</label>
                        <input
                        className={"set_value_multi_"+ind}
                        type="checkbox"
                        id={data.content[indx].name_field}
                        onChange={(e) => {data.setFieldValue(setValueMultiFunc(e.target.value,e.target.checked), e.target.id, e.target)}}
                        value={val.value}
                        onLoad={loadValueMultiFunc('set_value_multi_'+ind, (data.field_params[data.content[indx].name_field]==undefined)?(''):(data.field_params[data.content[indx].name_field]), val.value )} 
                        />
                        </Col>
                      }
                    })}
                  </Row>
          return content
}
const putContentMultipleFTable =(idData,data,indx)=>{
  var content = []
  //data.setFieldValue(e.target.value)
  //setValueMultiFunc(e.target.value,e.target.checked)
          content=<Row>
                    {data.optionsSelect[data.content[indx].name_field].map((val,ind)=>{
                      if (val.value=="") {
                         return <Col md={12}>
                            <label className="form-control-label" style={{fontSize: '11px'}}>Sin lote</label>
                          </Col>
                      }else{
                        return <Col md={6}>
                        <label className="form-control-label" style={{fontSize: '11px'}}>{val.value}</label>
                        <input
                        className={"set_value_multi_"+ind}
                        type="checkbox"
                        id={data.content[indx].name_field}
                        onChange={(e) => {data.setFieldValue(idData,setValueMultiFunc(e.target.value,e.target.checked), e.target.id, e.target)}}
                        value={val.value}
                        onLoad={loadValueMultiFunc('set_value_multi_'+ind, (data.field_params[data.content[indx].name_field]==undefined)?(''):(data.field_params[data.content[indx].name_field]), val.value )} 
                        />
                        </Col>
                      }
                    })}
                  </Row>
          return content
}
export function forTdTab(props){
  var tdContent = []
  if (props.content.length>0) {
    for (var i = 0; i < props.content.length; i++) {
      tdContent[props.content[i].order_field]=<><th style={{paddingLeft: '55px',paddingRight: '55px'}}>{props.content[i].label_title}</th></>
    }
  }
  return tdContent
}
export function forTrTab(props,propsData,dataId){
  var tdContent = []
  if (props.content.length>0) {
    for (var i = 0; i < props.content.length; i++) {
      tdContent[props.content[i].order_field]= dataRegisters(props,i,propsData,dataId)
    }
              tdContent[tdContent.length+1]=<><td key={i}>
                <button className="btn" onClick={()=>{props.saveInfoProp(dataId)}}>
                  <span style={{fontSize: '15px'}} className="fas fa-edit"></span>
                </button>
                <button className="btn" onClick={()=>{props.deleteInfoProp(dataId)}}>
                  <span style={{fontSize: '15px'}} className="fas fa-trash-alt"></span>
                </button>
              </td></>
  }
  return tdContent
}
export function dataRegisters(props,i,propsData,dataId) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
       if (props.content.length>0) {
        if (propsData[props.content[i].name_field]!=undefined) {
          switch (props.content[i].type_field) {
            case 'text':
               data_content[props.content[i].order_field] = <td key={i}>
                  <InputGroup>
                    {(props.content[i].type_field_include=='select_include')?(<>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(propsData[props.content[i].name_field.replace('select_','')]==undefined)?(''):(propsData[props.content[i].name_field.replace('select_','')])}
                      id={props.content[i].name_field.replace('select_','')}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />

                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                         value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                         id={props.content[i].name_field}
                         onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                         disabled={(props.content[i].dis_field==1)? (true) : (false)}
                         isrequired={props.content[i].req_field}
                         type="select">
                         <option value="0">Seleccione...</option>
                         {props.content[i]?.value_option?.split('|').map((a,b)=>{
                         return <option value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(<>
                      <Input
                      style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="text"
                    /> </>)}

                  </InputGroup>
                </td>            
              break;
            case 'numeric':
               data_content[props.content[i].order_field] = <td key={i}>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target,'numeric')}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />
                </td>
              break;
            case 'textarea':
      data_content[props.content[i].order_field] = <td key={i}>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="textarea"
                  />
              </td>    
              break;
            case 'select':
      data_content[props.content[i].order_field] = 
            <td key={i}>
                  {(props.optionsSelect[dataId][props.content[i].name_field]==undefined)?(null):(
                    (props.content[i].multiple_field==1)?(
                        putContentMultipleFTable(dataId,props,i)
                      ):(
                        <SelectSearch
                        //style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                        id={props.content[i].name_field+'_'+props.content[i].order_field}
                        value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                        placeholder={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                        options={props.optionsSelect[dataId][props.content[i].name_field]}
                        getOptions={(e,a)=>props.forSearhData(e,a)}
                        search
                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                        onChange={(e,a) => {props.setFieldValueSelect(dataId,a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />
                    )
                  )}
                {forStyleCursive(props.content[i].cursive_field,props.content[i].name_field+'_'+props.content[i].order_field)}
              </td>    
              break;
            case 'date':
      data_content[props.content[i].order_field] = <td key={i}>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(propsData[props.content[i].name_field]==undefined?(null):(propsData[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="date" />
              </td>    
              break;
            case 'time':
      data_content[props.content[i].order_field] = <td key={i}>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(propsData[props.content[i].name_field]==undefined?(null):(propsData[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="time" />
              </td>
              break;
            case 'file':
      data_content[props.content[i].order_field] = <td key={i}>
                      {contentFileInput(props,dataId,i)}
                    <button 
                      className="btn" 
                      style={{border: 'solid 1px',marginLeft: '5px',borderRadius: '2px',padding: '5px'}}
                      data-id={props.content[i].name_field} 
                      onClick={(e)=>{
                        jQuery('#'+e.target.getAttribute('data-id')+'_'+dataId).click()
                      }}>+ Añadir Documentos
                    </button>
                    {contentFilesShow(propsData[props.content[i].name_field],props,props.content[i].name_field)}
                {/*{(propsData[props.content[i].name_field]==null) ? "Sin archivo": (<Button data-url={config.URL_PRODUCTION+"/storage/file_documents/"+propsData[props.content[i].name_field]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(propsData[props.content[i].name_field]).split('/')[1]}</Button>)}*/}
              </td>
              break;
            case 'georeference':
      data_content[props.content[i].order_field] = <td key={i}>
                                      <Row>
                                        <Col md="9">
                                        <Input
                                        style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                                        value={(propsData[props.content[i].name_field]==undefined)?(''):(propsData[props.content[i].name_field])}
                                        id={props.content[i].name_field}
                                        isrequired={props.content[i].req_field}
                                        onChange={(e) => {props.setFieldValue(dataId,e.target.value, e.target.id, e.target)}}
                                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <div>
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {
                                          props.geolocacion(dataId,e.target)
                                        }
                                      }>
                                          <span isrequired={props.content[i].req_field} id={props.content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </div>  
                                      </Row>
                                  </td>              
              break;              
            default:
              
              break;
        }
      }
    }
  return (data_content);
}
const contentFileInput =(props,dataId,indx)=>{
  var content = []
  //data.setFieldValue(e.target.value)
  //setValueMultiFunc(e.target.value,e.target.checked)
          content=<Input
                      id={props.content[indx].name_field+'_'+dataId}
                      className={"upload_files"}
                      isrequired={props.content[indx].req_field}
                      data-id={props.content[indx].name_field}
                      onChange={(e) => {
                        //console.log(e.target,indx,dataId)
                        props.setFieldValueFiles(dataId,e.target.files, e.target.getAttribute('data-id'), e.target)
                        }
                      }
                      disabled={(props.content[indx].dis_field==1)? (true) : (false)}
                      type="file" 
                      multiple
                      style={{display:'none'}} />
          return content
}
export function PutFormContentProTable(props) {
  var content = []
            content = <><Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                {forTdTab(props)}
              </tr>
            </thead>
            <tbody>
            {Object.entries(props.field_params).reverse().map((value,index)=>{
                //props.initDataFunct(value.id,true)
                return (<tr>{forTrTab(props,value[1],value[0])}</tr>)
              })}
            </tbody>
          </Table></>
        return (content)
}
export function PutFormContentPro(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[i].type_field) {
            case 'text':
               data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                 <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <InputGroup>
                    {(props.content[i].type_field_include=='select_include')?(<>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(props.field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[i].name_field.replace('select_','')])}
                      id={props.content[i].name_field.replace('select_','')}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />

                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                         value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                         id={props.content[i].name_field}
                         onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                         disabled={(props.content[i].dis_field==1)? (true) : (false)}
                         isrequired={props.content[i].req_field}
                         type="select">
                         <option value="0">Seleccione...</option>
                         {props.content[i]?.value_option?.split('|').map((a,b)=>{
                         return <option value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(<>
                      <Input
                      style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="text"
                    /> </>)}

                  </InputGroup>
                 </FormGroup>
                </Col>            
              break;
            case 'numeric':
               data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                    <Input
                    style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                      value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,'numeric')}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                    />
                  </FormGroup>
                </Col>
              break;
            case 'textarea':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="textarea"
                  />
                </FormGroup>
              </Col>    
              break;
            case 'select':
      data_content[props.content[i].order_field] = 
            <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  {(props.optionsSelect[props.content[i].name_field]==undefined)?(null):(
                    (props.content[i].multiple_field==1)?(
                        putContentMultiple(props,i)
                      ):(
                        <SelectSearch
                        //style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                        id={props.content[i].name_field+'_'+props.content[i].order_field}
                        value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                        placeholder={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                        options={props.optionsSelect[props.content[i].name_field]}
                        getOptions={(e,a)=>props.forSearhData(e,a)}
                        search
                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                        onChange={(e,a) => {props.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />
                    )
                  )}
                {forStyleCursive(props.content[i].cursive_field,props.content[i].name_field+'_'+props.content[i].order_field)}
                </FormGroup>
              </Col>    
              break;
            case 'date':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    defaultValue={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="date" />
                </FormGroup>
              </Col>    
              break;
            case 'time':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                  <Input
                  style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                    value={(props.field_params[props.content[i].name_field]==undefined?(null):(props.field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="time" />
                </FormGroup>
              </Col>
              break;
            case 'file':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                    <Input
                      id={props.content[i].name_field}
                      className="upload_files"
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValueFiles(e.target.files, e.target.id, e.target)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      type="file" 
                      multiple
                      style={{display:'none'}} />
                    <button 
                      className="btn" 
                      style={{border: 'solid 1px',marginLeft: '5px',borderRadius: '2px',padding: '5px'}}
                      data-id={props.content[i].name_field} 
                      onClick={(e)=>{
                        jQuery('#'+e.target.getAttribute('data-id')).click()
                      }}>+ Añadir Documentos
                    </button>
                    {contentFilesShow(props.field_params[props.content[i].name_field],props,props.content[i].name_field)}
                {/*{(props.field_params[props.content[i].name_field]==null) ? "Sin archivo": (<Button data-url={config.URL_PRODUCTION+"/storage/file_documents/"+props.field_params[props.content[i].name_field]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(props.field_params[props.content[i].name_field]).split('/')[1]}</Button>)}*/}
                </FormGroup>
              </Col>
              break;
            case 'georeference':
      data_content[props.content[i].order_field] = <Col md={props.content[i].col_field} key={i}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        style={{fontStyle: (props.content[i].cursive_field==1)?('oblique'):('normal') }}
                                        value={(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}
                                        id={props.content[i].name_field}
                                        isrequired={props.content[i].req_field}
                                        onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {props.geolocacion(e.target)}}>
                                          <span isrequired={props.content[i].req_field} id={props.content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content);
}
const contentFilesShow = (data,props,fields) => {
  var content = []
  if (data!='') {
    if (data!=undefined) {
      if (data.length>0) {
        if (typeof data=='object') {
          data.map((a,b)=>{
           if (a.name!='') {      
             if (a.filed==null) {
               var url_selected = config.URL_PRODUCTION+"/storage/file_documents/"+a.name
               var url_selected_two = "/storage/file_documents/"+a.name
                     content[b]=<Row> <Col md="1"><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {props.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><Button data-url={url_selected} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(a.name).split('/')[1]}</Button></Col></Row>
             }else{
                     content[b]=<Row> <Col md="1"><button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {props.deleteImgState(b,fields,a,url_selected_two)}}>
                     <i className="fas fa-eraser" style={{color:'#00234d'}}></i>
                     </button></Col>
                     <Col md="11"><label className="form-control-label" htmlFor="example3cols2Input">{a.name}</label></Col></Row>
             }
           }
          })
        }
       }
    }

  }
  return content
}
export function PutFormContentFinal(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[i].type_field) {
            case 'text':
               data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <FormGroup style={{margin:'0px'}}>
                    {(props.content[i].type_field_include=='select_include')?(<>
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[i].name_field.replace('select_','')])}</span>
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                      </>):(
                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                      )}
                  </FormGroup>
                </Col>            
              break;
            case 'numeric':
               data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      {props.content[i].label_title}
                    </label>
                  <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                  </FormGroup>
                </Col>
              break;
            case 'textarea':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'select':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'date':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>    
              break;
            case 'time':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                <FormGroup style={{margin:'0px'}}>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span>
                </FormGroup>
              </Col>
              break;
            case 'file':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                <Form>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    {props.content[i].label_title}
                  </label>
                    <span className="form-control-label" style={{color:'gray'}} data-notify="title">
                    {/*{contentFilesShow(props.field_params[props.content[i].name_field],props,props.content[i].name_field)}*/}
                    {(props.field_params[props.content[i].name_field]==null) ? "Sin archivo": (<Button data-url={config.URL_PRODUCTION+"/storage/file_documents/"+props.field_params[props.content[i].name_field]} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={(e)=> { window.open(e.target.getAttribute('data-url')) }}>{String(props.field_params[props.content[i].name_field]).split('/')[1]}</Button>)}
                    </span>
                </Form>
              </Col>
              break;
            case 'georeference':
      data_content[props.content[i].order_field] = <Col style={{display:(props.field_params[props.content[i].name_field]==null)?('none'):('block'), border: 'solid 1px #f2f2f2'}} md={2} key={i}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                    <FormGroup style={{margin:'0px'}}>
                                      <Row>
                                      <span className="form-control-label" style={{color:'gray'}} data-notify="title">{(props.field_params[props.content[i].name_field]==undefined)?(''):(props.field_params[props.content[i].name_field])}</span> 
                                      </Row>
                                      </FormGroup>
                                  </Col>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content);
}
const getContentSelectTr=(prps)=>{
var content = []
  prps.content.map((val,indx)=>{
    content[indx] = <>
                  <tr>
                   <td>
                    <span className="text-muted">
                     {val.label_title}
                    </span>
                   </td>
                      <PutFormContentSelectTr {...prps} idxCon={indx} />
                      <PutFormContentSelectTr {...prps} idxCon={indx} />
                      <PutFormContentSelectTr {...prps} idxCon={indx} />
                      <PutFormContentSelectTr {...prps} idxCon={indx} />
                 </tr>
    </>
  })
  return content
}
export function PutFormContentSelectTr(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content_th = []
      var data_content_tr = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
          switch (props.content[props.idxCon].type_field) {
            case 'text':
               data_content_th[props.idxCon] = <td key={i}>
                    {(props.content[props.idxCon].type_field_include=='select_include')?(<>
                    <Input
                      value={(props.field_params[props.content[props.idxCon].name_field.replace('select_','')]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field.replace('select_','')])}
                      id={props.content[props.idxCon].name_field.replace('select_','')}
                      isrequired={props.content[props.idxCon].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                      placeholder={props.content[props.idxCon].label_title}
                      type="number"
                    />

                    <Input
                         value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                         id={props.content[props.idxCon].name_field}
                         onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                         disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                         isrequired={props.content[props.idxCon].req_field}
                         type="select">
                         <option value="0">Seleccione...</option>
                         {props.content[props.idxCon]?.value_option?.split('|').map((a,b)=>{
                         return <option value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(
                      <Input
                      value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                      id={props.content[props.idxCon].name_field}
                      data_filter={props.content[props.idxCon].list_default}
                      isrequired={props.content[props.idxCon].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                      disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                      placeholder={props.content[props.idxCon].label_title}
                      type="text"
                    />)}
                </td>            
              break;
            case 'numeric':
               data_content_th[props.idxCon] = <td key={i}>
                    <Input
                      value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                      id={props.content[props.idxCon].name_field}
                      data_filter={props.content[props.idxCon].list_default}
                      isrequired={props.content[props.idxCon].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,'numeric')}}
                      disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                      placeholder={props.content[props.idxCon].label_title}
                      type="number"
                    />
                </td>
              break;
            case 'textarea':
      data_content_th[props.idxCon] = <td key={i}>
                  <Input
                    value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                    id={props.content[props.idxCon].name_field}
                    isrequired={props.content[props.idxCon].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                    placeholder={props.content[props.idxCon].label_title}
                    type="textarea"
                  />
              </td>    
              break;
            case 'select':
      data_content_th[props.idxCon] = <td key={i}>
        {(props.optionsSelect[props.content[props.idxCon].name_field]==undefined)?(null):(<SelectSearch 
                      value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                      placeholder={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                      options={props.optionsSelect[props.content[props.idxCon].name_field]}
                      getOptions={(e,a)=>props.forSearhData(e,a)}
                      search
                      disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                      onChange={(e,a) => {props.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code')}}
                      />)}
              </td>    
              break;
            case 'date':
      data_content_th[props.idxCon] = <td key={i}>
                  <Input
                    defaultValue={(props.field_params[props.content[props.idxCon].name_field]==undefined?(null):(props.field_params[props.content[props.idxCon].name_field]))}
                    id={props.content[props.idxCon].name_field}
                    isrequired={props.content[props.idxCon].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                    placeholder={props.content[props.idxCon].label_title}
                    type="date" />
              </td>    
              break;
            case 'time':
      data_content_th[props.idxCon] = <td key={i}>
                  <Input
                    value={(props.field_params[props.content[props.idxCon].name_field]==undefined?(null):(props.field_params[props.content[props.idxCon].name_field]))}
                    id={props.content[props.idxCon].name_field}
                    isrequired={props.content[props.idxCon].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target)}}
                    disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                    placeholder={props.content[props.idxCon].label_title}
                    type="time" />
              </td>
              break;
            case 'file':
      data_content_th[props.idxCon] = <td key={i}>
                  <Input
                    id={props.content[props.idxCon].name_field}
                    isrequired={props.content[props.idxCon].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.files[0], e.target.id, e.target)}}
                    disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                    type="file" />
                {(props.field_params[props.content[props.idxCon].name_field]==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+props.field_params[props.content[props.idxCon].name_field])}}>{String(props.field_params[props.content[props.idxCon].name_field]).split('/')[1]?.split('.')[1]}</Button>)}                
              </td>
              break;
            case 'georeference':
      data_content_th[props.idxCon] = <td key={i}>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        value={(props.field_params[props.content[props.idxCon].name_field]==undefined)?(''):(props.field_params[props.content[props.idxCon].name_field])}
                                        id={props.content[props.idxCon].name_field}
                                        isrequired={props.content[props.idxCon].req_field}
                                        onChange={(e) => {props.setFieldValue(e.target.files[0], e.target.id, e.target)}}
                                        disabled={(props.content[props.idxCon].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {props.geolocacion(e.target)}}>
                                          <span isrequired={props.content[props.idxCon].req_field} id={props.content[props.idxCon].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                  </td>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content_th);
}

export function PutFormContentTableSatel(props) {
        var data_content=[]
         data_content = <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Inidice del Cultivo</th>
                <th>NDVI</th>
                <th>NDRE</th>
                <th>NDMI</th>
                <th>HR Suelo</th>
              </tr>
            </thead>
            <tbody>
            {getContentSelectTr(props)}
            </tbody>
          </Table>
          return(data_content)
}
export function PutFormContentProdTr(props) {
 var date = new Date()
      //.toISOString().slice(0, 10);
      var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
      var completeDateBefore = completeDateAfter.split('/')
      var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
      var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      var data_content_th = []
      var data_content_tr = []
    if (props.content.length>0) {
      for (var i = 0; i < props.content.length; i++) {
              data_content_th[0] = <td style={{padding: 1}}>
                                        <button onClick={(e)=>{props.saveInfo(props.put_field_params,props.put_field_params.id,e.target)}} style={{color: '#002a5c',background: 'transparent',border: '0px'}}>
                                          <span style={{fontSize: '12px'}} className="fa fa-edit"></span>
                                        </button>
                                        <button onClick={(e)=>{props.deleteFer(props.put_field_params.id,e.target)}} className="btnRemoveRegisterProd" style={{color: '#002a5c',background: 'transparent',border: '0px'}}>
                                          <span style={{fontSize: '12px'}} className="fa fa-eraser"></span>
                                        </button>
                                   </td>
          switch (props.content[i].type_field) {
            case 'text':
               data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                    {(props.content[i].type_field_include=='select_include')?(<>
                  <InputGroup>
                    {(props.content[i].type_field_include=='select_include')?(<>
                    <Input
                      value={(props.put_field_params[props.content[i].name_field.replace('select_','')]==undefined)?(''):(props.put_field_params[props.content[i].name_field.replace('select_','')])}
                      id={props.content[i].name_field.replace('select_','')}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                      style={{width: '50px', paddingTop: '1px', paddingBottom: '1px', height: '30px'}}
                    />

                    <Input
                         value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                         id={props.content[i].name_field}
                         onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                         disabled={(props.content[i].dis_field==1)? (true) : (false)}
                         isrequired={props.content[i].req_field}
                         type="select"
                         style={{width: '50px',paddingTop: '1px', paddingBottom: '1px', height: '30px'}}>
                         <option value="0">Seleccione...</option>
                         {props.content[i]?.value_option?.split('|').map((a,b)=>{
                         return <option value={a}>{a}</option>
                         })}
                       </Input>
                      </>):(<>
                      <Input
                      value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="text"
                      style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                    /> </>)}

                  </InputGroup>
                      </>):(
                      <Input
                      value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params,props.indx_field_params)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="text"
                      style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                    />)}
                </td>            
              break;
            case 'numeric':
               data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                    <Input
                      value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                      id={props.content[i].name_field}
                      data_filter={props.content[i].list_default}
                      isrequired={props.content[i].req_field}
                      onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      placeholder={props.content[i].label_title}
                      type="number"
                      style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                    />
                </td>
              break;
            case 'textarea':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                  <Input
                    value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="textarea"
                    style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                  />
              </td>    
              break;
            case 'select':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
        {(props.optionsSelect[props.content[i].name_field]==undefined)?(null):(<SelectSearch 
                      value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                      placeholder={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                      options={props.optionsSelect[props.content[i].name_field]}
                      getOptions={(e,a)=>props.forSearhData(e,a)}
                      search
                      disabled={(props.content[i].dis_field==1)? (true) : (false)}
                      onChange={(e,a) => {props.setFieldValueSelect(a.value,a.id,a.isrequired,a.code,a.default,'code',null,props.indx_field_params)}}
                      style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                      onFocus={()=>{jQuery('.putTableHei').css('height','500px')}}
                      onBlur={()=>{jQuery('.putTableHei').removeAttr('style')}}
                      />
                      )}
              </td>    
              break;
            case 'date':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                  <Input
                    defaultValue={(props.put_field_params[props.content[i].name_field]==undefined?(null):(props.put_field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="date"
                    style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}} />
              </td>    
              break;
            case 'time':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                  <Input
                    value={(props.put_field_params[props.content[i].name_field]==undefined?(null):(props.put_field_params[props.content[i].name_field]))}
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.value, e.target.id, e.target,props.indx_field_params)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    placeholder={props.content[i].label_title}
                    type="time"
                    style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}} />
              </td>
              break;
            case 'file':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                  <Input
                    id={props.content[i].name_field}
                    isrequired={props.content[i].req_field}
                    onChange={(e) => {props.setFieldValue(e.target.files[0], e.target.id, e.target,props.indx_field_params)}}
                    disabled={(props.content[i].dis_field==1)? (true) : (false)}
                    type="file"
                    style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}} />
                {(props.put_field_params[props.content[i].name_field]==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+props.put_field_params[props.content[i].name_field])}}>{String(props.put_field_params[props.content[i].name_field]).split('/')[1]?.split('.')[1]}</Button>)}                
              </td>
              break;
            case 'georeference':
      data_content_th[props.content[i].order_field+1] = <td key={i} style={{padding: 1}}>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                        value={(props.put_field_params[props.content[i].name_field]==undefined)?(''):(props.put_field_params[props.content[i].name_field])}
                                        id={props.content[i].name_field}
                                        isrequired={props.content[i].req_field}
                                        onChange={(e) => {props.setFieldValue(e.target.files[0], e.target.id, e.target,props.indx_field_params)}}
                                        disabled={(props.content[i].dis_field==1)? (true) : (false)}
                                        type="textarea"
                                        style={{width: 'auto',paddingTop: '1px',paddingBottom: '1px', height: '30px'}}
                                        />
                                          {props.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <button style={{color: '#002a5c',background: 'transparent',border: '0px'}} onClick={(e)=> {props.geolocacion(e.target,props.indx_field_params)}}>
                                          <span isrequired={props.content[i].req_field} id={props.content[i].name_field} style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </button>
                                        </Col>  
                                      </Row>
                                  </td>              
              break;              
            default:
              
              break;
          }
        }
    }
  return (data_content_th);
}
const setThContent =(props)=>{
  var content =[]
  content[0] = <><th style={{padding: 5}}>Opciones</th></>
props.content.map((val,indx)=>{
    content[props.content[indx].order_field+1] = <><th style={{padding: 5}}>{val.label_title}</th></>
  })
return content
}
export function PutFormContentTableProd(props) {
        var data_content=[]
         data_content = <div className="putTableHei table-responsive"><Table className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
              {setThContent(props)}
              </tr>
            </thead>
            <tbody>
            {
            props.field_params.map((val,indx)=>{
            return <><tr id={val.id} className={'tr_register_'+val.id}><PutFormContentProdTr {...props} put_field_params={val} indx_field_params={indx} /></tr></>
            })
          }
            </tbody>
          </Table></div>
          return(data_content)
}
export const getContent=(field_content_log)=>{
    var data_select = []
    var data_select_t = []
    if (field_content_log!=undefined) {    
      if (field_content_log.length>0) {
        var counter=field_content_log.length-1
        for (var i = 0; i < field_content_log.length; i++) {
            data_select_t[i]=field_content_log[i]
          if (field_content_log[i].link!=null) {
            var get_csc=field_content_log[i].link.split('.')[1]
            if (get_csc=='vgp' || get_csc=='vge' || get_csc=='vgc') {
              data_select[i]={field:field_content_log[i].name_field}
            }else{
              data_select[i]={field:field_content_log[i].name_field,link:field_content_log[i].link}
            }
          }else{
              data_select[i]={field:field_content_log[i].name_field}
            if (field_content_log[i].list_default=='av'||field_content_log[i].list_default=='iut'||field_content_log[i].list_default=='iumt'||field_content_log[i].list_default=='iump'||field_content_log[i].list_default=='iumd'||field_content_log[i].list_default=='iuml') {
              counter++
              data_select[counter]={field:'select_'+field_content_log[i].name_field}
              data_select_t[counter]={col_field:field_content_log[i].col_field,value_option:field_content_log[i].value_option,name_field:'select_'+field_content_log[i].name_field,order_field:field_content_log[i].order_field,label_title:field_content_log[i].label_title,req_field:field_content_log[i].req_field,dis_field:field_content_log[i].dis_field,list_default:field_content_log[i].list_default,type_field:field_content_log[i].type_field,type_field_include:'select_include'}
            }
          }
        }
      }
    }
    return {f_data:data_select,s_data:data_select_t}
  }
export const forNumeric=(prevState,states)=>{
  var data_result = null
         Object.values(states.field_content).map((a,b)=>{
               if (a.type_field=='numeric') {
                 if (a.list_default!=null) {
                   var data_calculer = a.list_default.split('*')
                   var data_for_factor = data_calculer[1].split('=')
                   var factor_one = data_calculer[0]
                   var factor_two = data_for_factor[0]
                   var field_product = data_for_factor[1]
                    if (states.field_params[a.name_field]!=null || states.field_params[factor_two]!=null) {                    
                      if (prevState.field_params[a.name_field]!==states.field_params[a.name_field] || prevState.field_params[factor_two]!==states.field_params[factor_two]) {
                        var product_result = parseInt(states.field_params[a.name_field]||0)*parseInt(states.field_params[factor_two]||0)
                        data_result = {field_product,product_result}
                      }
                    }
                 }
               }
            })
         return data_result
}

// const putFormContent = (content,field_params,optionsSelect) =>{
     
//       return data_content;
//     }