import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_traza_animal, create_traza_animal, delete_register_traza_animal } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

export default class ModalAnimal extends React.Component {
  state={
    animal_id_register:'noind',
    animal_id:'',
    fecha_registro:'',
    hora_registro:'',
    madre_id:'',
    animal_nro:'',
    foodchain_id:'',
    fecha_nacimiento:'',
    edad:'',
    raza:'',
    sexo:'',
    peso_inicial:'',
    informacion_adicional:'',
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    botonsc:false,
  }
    async componentDidMount(){
        
  }
    setDataCampos = (data)=>{
      this.setState({
              animal_id_register:(data.id=="null")?(null):(data.id),
              animal_id:(data.animal_id=="null")?(null):(data.animal_id),
              fecha_registro:(data.fecha_registro=="null")?(null):(data.fecha_registro),
              hora_registro:(data.hora_registro=="null")?(null):(data.hora_registro),
              madre_id:(data.madre_id=="null")?(null):(data.madre_id),
              animal_nro:(data.animal_nro=="null")?(null):(data.animal_nro),
              foodchain_id:(data.foodchain_id=="null")?(null):(data.foodchain_id),
              fecha_nacimiento:( data.fecha_nacimiento=="null")?(null):( data.fecha_nacimiento),
              edad:( data.edad=="null")?(null):( data.edad),
              raza:(data.raza=="null")?(null):(data.raza),
              sexo:(data.sexo=="null")?(null):(data.sexo),
              peso_inicial:(data.peso_inicial=="null")?(null):(data.peso_inicial),
              informacion_adicional:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
              file_document:(data.archivo=="null")?(null):(data.archivo),
      })
    }
    async componentDidUpdate(){
    }
  render(){

    const setInfoAnimal = async (id_set, verify, closeTrunc) =>{

      if (closeTrunc == 1 ) {
        optionBotom = true
      }else{
        var optionBotom;
        if (id_set!=null) {
          optionBotom = true
        }else{
          optionBotom = false
        }
      }
      this.setState({botonsc: optionBotom})
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          var dataTrazabilidadvf = await data_traza_animal(id_set)

        if (dataTrazabilidadvf==null) {
        var date = new Date()
        var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
        var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
          this.setState({
            animal_id_register:'noind',
            animal_id :'',
            fecha_registro:completeDate,
            hora_registro:timeString,
            madre_id:'',
            animal_nro:'',
            foodchain_id:'',
            fecha_nacimiento:completeDate,
            edad:'',
            raza:'',
            sexo:'',
            peso_inicial:'',
            informacion_adicional:'',
          })
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
          this.setState({
        sendOpenModal:verify,
      })

    }
  const saveInfo = async (type) =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({mess:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (this.state.animal_id=="") {
      this.setState({mess:'El campo Animal ID es necesario.'})
      this.setState({btnd:false})
    }else{
        const var_request = await create_traza_animal(this.state,jsondata._id,gerParam);

        if (var_request.data=="exito") {
          this.setState({mess:'Se guardo exitosamente.'})
          this.setState({mess:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          if (type==1) {
            setInfoAnimal(null,true)
          }
          if(type==2){
            setInfoAnimal(null,false)
          }
          this.props.refreshData()
          this.setState({btnd:false})
        }else{
          notify("danger", "¡Registrado Fallido!", var_request.data);
          this.setState({mess:''})
          this.setState({btnd:false})
        }
    }
  }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
const deleteAnimal = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
  if (window.confirm("Deseas eliminar este registro?")) {
  var dataReturn = await delete_register_traza_animal(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
          this.setState({mess:'Se eliminó exitosamente.'})
          notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
          this.props.refreshData()
        }else{
          notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
          this.setState({btnd:false})
        }
}
}
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
  <Modal isOpen={this.state.sendOpenModal} size="xl">
    <ModalBody>
       <CardHeader>
                      <Row>
            <Col md={11}>
              <h3 className="mb-0">Registrar Animal</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoAnimal(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Registro
                  </label>
                  <Input
                    onChange={(e) => this.setState({fecha_registro:e.target.value})}
                    value={this.state.fecha_registro}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Registro
                  </label>
                  <Input
                    onChange={(e) => this.setState({hora_registro:e.target.value})}
                    value={this.state.hora_registro}
                    id="example-date-input"
                    type="time"
                  />
                </FormGroup>
              </Col>
             <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Madre ID (Arete Numero)
                  </label>
                  <Input
                    onChange={(e) => this.setState({madre_id:e.target.value})}
                    value={this.state.madre_id}
                    placeholder="Escribe el id del arete de la madre"
                    type="text"
                  />
                </FormGroup>
              </Col>
             <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Animal ID (Arete Numero)
                  </label>
                  <Input
                    value={this.state.animal_id}
                    onChange={(e) => this.setState({animal_id:e.target.value})}
                    id="example3cols2Input"
                    placeholder="Escribe el id del arete del animal"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Nacimiento
                  </label>
                  <Input
                    onChange={(e) => this.setState({fecha_nacimiento:e.target.value})}
                    value={this.state.fecha_nacimiento}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
             <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Edad
                  </label>
                  <Input
                    onChange={(e) => this.setState({edad:e.target.value})}
                    value={this.state.edad}
                    id="example3cols2Input"
                    placeholder="Escribe la edad"
                    type="text"
                  />
                </FormGroup>
              </Col>
             <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Raza
                  </label>
                  <Input
                    onChange={(e) => this.setState({raza:e.target.value})}
                    value={this.state.raza}
                    id="example3cols2Input"
                    placeholder="Escribe la raza"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Sexo
                  </label>
                  <Input id="exampleFormControlSelect1" type="select" value={this.state.sexo} onChange={(e) => this.setState({sexo: e.target.value})}>
                    <option value="0">Seleccione...</option>
                    <option value="F">F</option>
                    <option value="M">M</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Peso Inicial
                  </label>
                  <Input
                    onChange={(e) => this.setState({peso_inicial:e.target.value})}
                    value={this.state.peso_inicial}
                    id="example3cols2Input"
                    placeholder="Escribe un peso"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    onChange={(e) => this.setState({informacion_adicional:e.target.value})}
                    value={this.state.informacion_adicional}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Foto
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e) => this.setState({file_document:e.target.files[0]})}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('_').pop()}</a>)}
              </Col>
            </Row>
                  {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
{(this.state.botonsc)?
            (null)
  :

              (<Col md="3">
                              <Button 
                              className="btn-icon" 
                              color="traza-blue" 
                              type="button" 
                              disabled={this.state.btnd}
                              onClick={() => {saveInfo(1)} }>
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-check-circle" />
                                </span>
                                <span className="btn-inner--text">Guardar y agregar otro</span>
                              </Button>
                            </Col>)}
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={() => {saveInfo(2)} }>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-times-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar y Cerrar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </ModalBody>
        <ModalFooter>

          <Button onClick={()=> {setInfoAnimal(null,false,1)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
       <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar Dato Animal
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoAnimal(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataAnimal.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                       <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.animal_id}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoAnimal(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteAnimal(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

