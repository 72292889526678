import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentStatusSatelite, PutContentSubPlant, PutContentSubTablaSatelite} from 'views/pages/examples/LogisticsForVistaTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
import { get_data_satelite_vt } from "network/ApiAxios";

    const datacontent = [
    {table:'production_land_preparation_g',title:'Preparación de tierra',icon:'fas fa-chart-area','multiple_tables':0},
    {table:'production_irrigation_g',title:'Riego',icon:'fas fa-tint','multiple_tables':0},
    {table:'production_seed_g',title:'Semilla',icon:'fas fa-seedling','multiple_tables':0},
    {table:'production_fertilizer_g',title:'Fertilización',icon:'fas fa-spa','multiple_tables':0},
    {table:'production_sowing_g',title:'Siembra',icon:'fas fa-leaf','multiple_tables':0},
    {table:'production_sowing_transplant',title:'Transplante',icon:'fas fa-sign-language','multiple_tables':0},
    {table:'production_producer_monitoring_g',title:'Monitoreo Productor',icon:'fab fa-watchman-monitoring','multiple_tables':0},
    {title:'Satelite',table:'production_info_satelite',
     data_table: [{title:'NDVI',table:'production_info_satelite'},
        {title:'NDRE',table:'production_info_satelite_ndre'},
        {title:'NDMI',table:'production_info_satelite_ndmi'},
        {title:'HR Suelo',table:'production_info_satelite_ndhs'}],
    icon:'fas fa-satellite','multiple_tables':3},
    ]
    //,
    // {table:'production_info_satelite_ndre',title:'Satelite NDRE (Salud del Cultivo)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndmi',title:'Satelite NDMI (Humedad de la Vegetación)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndhs',title:'Satelite HR-SUELO (Humedad Relativa del Suelo)',icon:'fas fa-satellite','multiple_tables':0}
export default class SectionNamaG extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false],
    data_satelital:null
  }
  async componentDidMount(){
    var getCode = new URLSearchParams(window.location.search).get("code");
    var getData = await get_data_satelite_vt(null,getCode)
    this.setState({data_satelital:getData.satelite})
  }
  contentRows = () =>{

    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
            <Col md={12}>
            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
            <div className="elementor-widget-wrap"></div></div>

            <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
            <div className="elementor-widget-container">
            <div className="elementor-spacer">
            </div>
            </div>
            </div>
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item" style={{zIndex: '99'}}>
            <h6 onClick={()=>{this.opened(this.state.openColapse[indx+1],indx+1)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
              <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
              <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
              <figure className="elementor-image-box-img">
                {(val.multiple_tables==3)?(<>
                  <div style={{width:30,height:30,backgroundColor: PutContentStatus(this.props.DataRow.allDataTrza,(this.state.data_satelital==null)?(val.table):((this.state.data_satelital==null)?(val.table):('info_producer_g'))).color,borderRadius: '100px', textAlign: 'center'}}>
                    <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
                  </div>
                </>):(<>
                  <div style={{width:30,height:30,backgroundColor: PutContentStatus(this.props.DataRow.allDataTrza,val.table).color,borderRadius: '100px', textAlign: 'center'}}>
                    <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
                  </div>
                </>)}
              </figure>
              <div className="elementor-image-box-content">
              <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>{val.title}</span>
              </div>
              </div>
              </div>
              </div>
            </h6>
                </div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </Col>
                  </>
    })
    return content
  }
  contentRowsCard = () =>{
    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
      {(this.props.DataRow.allDataTrza[val.table]==undefined)?(null):(
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item">
            <Collapse isOpen={this.state.openColapse[indx+1]} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">
              <ul style={{padding: '0px'}}>
            {(val.multiple_tables==0)?(<>
              {(this.props.DataRow.allDataTrza[val.table]['data_array'].length>1)?(
                      <PutContentSub
                      data_function={this.props.DataRow} 
                      type_function={val.table}
                      states={this.state}
                      openedChild={this.openedChild}
                      chillSelector={"data"}
                      />
                ):(
                  <Col md="12" style={{margin: 'auto'}}>
                    <Card>
                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                      <Row>
                        <Col md={1}>
                          <h4 className="mb-0" style={{color: '#ffffff'}}>Estado:</h4>
                        </Col>
                        <div>
                          {(val.multiple_tables==3)?(<>
                            <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,(this.state.data_satelital==null)?(val.table):((this.state.data_satelital==null)?(val.table):('info_producer_g'))).register_status}</h4>
                          </>):(
                          <>
                            <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(this.props.DataRow.allDataTrza,val.table).register_status}</h4>
                          </>
                          )}
                        </div>
                      </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                        <PutContent 
                        data_function={this.props.DataRow} 
                        type_function={val.table} />                  
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </>):(null)}
            {(val.multiple_tables==1)?(<>
              <PutContentSubTwo
              data_function={this.props.DataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datat"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              />
              </>):(null)}
            {(val.multiple_tables==2)?(<>
              <PutContentSubPlant
              data_function={this.props.DataRow} 
              type_function={val.table_one}
              type_function_two={val.table_two}
              type_function_tree={val.table_three}
              type_function_four={val.table_fuor}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datadp"}
              titleType={val.title_one}
              titleTypeTwo={val.title_two}
              titleTypeTree={val.title_three}
              titleTypeFour={val.title_fuor}
              />
              </>):(null)}
            {(val.multiple_tables==3)?(<>
              <PutContentSubTablaSatelite
              data_function={this.props.DataRow}
              data_f_satelite={this.state.data_satelital}
              first_data_table={val.table}
              data_table={val.data_table}
              states={this.state}
              openedChild={this.openedChild}
              openedNextChild={this.openedNextChild}
              chillSelector={"datadp"}
              />
              </>):(null)}
              </ul>
              </Collapse>
            </div>
            </div>
            </div>
            </div>
            )}

                  </>
    })
    return content
  }
     opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
     openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
  render(){
  return (
    <>
{/*<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />*/}

    <div id="produccion-tab" className="clearfix eael-tab-content-item inactive" data-title-link="produccion-tab">
        <article data-elementor-type="section" data-elementor-id="5420" className="elementor elementor-5420">
        <div className="elementor-section-wrap">
          <Row>
            <Col md={2} style={{left:'0%'}}>
              {this.contentRows()}
            </Col>
            <Col md={10} className="pt-2">
              {this.contentRowsCard()}
            </Col>
          </Row>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section>
    </div>
    </article>
    </div>
    </>
  );
}
}

