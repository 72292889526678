import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import ModalFridgeExtend from "./ModalExtend/ModalFridgeExtend";
import { row_fridge } from "network/ApiAxios";

export default class Frigorifico extends React.Component {
    state={
    dataFridge:[],
    dataFridgeAll:[],
  }
async componentDidMount(){
        this.initData()
}
initData = async () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = this.props.paramsCode
        var getParamLote = this.props.paramsCodLote
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var dataFridge = await row_fridge(jsondata._id,gerParam,getParamLote,codigoTrazaTwo)
        this.setState({dataFridgeAll:dataFridge})
        this.setState({dataFridge:dataFridge.fridge})
        //this.setState({dataTransportAll:dataTransport})
}
  render(){

  return (
    <>
      <Container className="mt--6" fluid>
        <ModalFridgeExtend sendInfoProps={this.state} setParamsUrl={this.props.paramsCode} refreshData={this.initData} />
      </Container>
    </>
  );
 }
}

//export default Elements;
