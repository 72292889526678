import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse,
  Table
} from "reactstrap";
import {PutContent, PutContentSub, PutContentSubTwo, PutContentStatus, PutContentSubPlant, PutContentSubTablaSatelite} from 'views/pages/examples/LogisticsForVistaTrace/InfoLogistic'
import ContentLogistic from 'views/pages/examples/LogisticsForVistaTrace/ContentLogistic'
import config from "config";

    const datacontent = [
    {table:'report_mrv',title:'Monitoreo',icon:'fas fa-chart-area','multiple_tables':0},
    {table:'report_mrv',title:'Revisiones',icon:'fas fa-eye','multiple_tables':0},
    {table:'report_mrv',title:'Validación',icon:'fas fa-check','multiple_tables':0}]
    //,
    // {table:'production_info_satelite_ndre',title:'Satelite NDRE (Salud del Cultivo)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndmi',title:'Satelite NDMI (Humedad de la Vegetación)',icon:'fas fa-satellite','multiple_tables':0},
    // {table:'production_info_satelite_ndhs',title:'Satelite HR-SUELO (Humedad Relativa del Suelo)',icon:'fas fa-satellite','multiple_tables':0}
export default class SectionMVR extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false]
  }
  async componentDidMount(){
  }
  contentRows = () =>{

    var content = []
    datacontent.map((val,indx)=>{
      content[indx] = <>
            <Col md={12}>
            <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
            <div className="elementor-widget-wrap"></div></div>

            <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
            <div className="elementor-widget-container">
            <div className="elementor-spacer">
            </div>
            </div>
            </div>
            <div style={{marginBottom:'0px'}} className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
            <div className="elementor-widget-container">
            <div className="elementor-toggle" role="tablist">
            <div className="elementor-toggle-item" style={{zIndex: '99'}}>
            <h6 onClick={()=>{this.opened(this.state.openColapse[indx+1],indx+1)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
              <div style={{marginBottom:'10px'}} className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
              <div className="elementor-widget-container">
              <div className="elementor-image-box-wrapper">
              <figure className="elementor-image-box-img">
              <div style={{width:30,height:30,backgroundColor:'orange',borderRadius: '100px', textAlign: 'center'}}>
              <span className={val.icon} style={{color:'#204d74',fontSize: '23px',position: 'relative',bottom: '0px',left: '0px'}}></span>
              </div>
              </figure>
              <div className="elementor-image-box-content">
              <span className="eael-tab-title title-after-icon" style={{fontWeight: 'normal',fontSize: '12px'}}>Datos {val.title}</span>
              </div>
              </div>
              </div>
              </div>
            </h6>
                </div>
                </div>
                </div>
                </div>
                <div className="elementor-element elementor-element-81e561d elementor-widget elementor-widget-spacer" data-id="81e561d" data-element_type="widget" data-widget_type="spacer.default">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
            </div>
            </div>
            </div>
            </div>
        </Col>
                  </>
    })
    return content
  }
  putContentInformation =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black'}}>{a}</Col></>
    })
    return data_content
  }
  putContentInformationPrio =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black',borderRadius: '3px',textAlign: 'center', margin: '1px', backgroundColor: a.split('-')[1]}}>{a.split('-')[0]}</Col></>
    })
    return data_content
  }
  putContentInformationStatus =(data,ind_two,ind_three,ind_general)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = 
      <>
        <Col md={12} style={{color: 'black',borderRadius: '3px', margin: '1px', backgroundColor: a.split('-')[1]}}>
          {a.split('-')[0]}
        </Col>
      </>
    })
    return data_content
  }
  getContetnTwo =(data,ind_two,ind_general)=>{

                                       var data_content_td=[]
                                       var data_content_tr=[]
                                       var order_data = Object.entries(data).sort()
                                               order_data.map((valdata,inddata)=>{
                                              if (valdata[0]!='activity') {
                                                if (valdata[0]=='observation') {
                                                  data_content_td[0]=<><td>{this.putContentInformation(valdata[1],inddata)}</td></>
                                                }
                                               if (valdata[0]=='recommendation') {
                                                  data_content_td[1]=<><td>{this.putContentInformation(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='priority') {
                                                 data_content_td[2]=<><td>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='state_status') {
                                                 data_content_td[3]=<><td>{this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general)}</td></>
                                               }
                                               data_content_tr=<tr>{data_content_td}</tr>
                                              }
                                               })
                                                 return (data_content_tr)
  }
  contentRowsCard = () =>{
    var content = []
                  {
                    this.props.DataRow.allDataMVR.map((val,indx)=>{
                      content[indx]= <>
            <Collapse isOpen={this.state.openColapse[indx+1]} id="elementor-tab-content-6961" className="elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-6961">

                                <Col md={12} style={{fontWeight: '700',color: '#002a5c'}}>
                                  {(val.monitor_type=='ON')?('Monitoreo'):(null)}
                                  {(val.monitor_type=='RE')?('Revisión'):(null)}
                                  {(val.monitor_type=='VA')?('Validación'):(null)}
                                </Col>
                                {JSON.parse(val.data_adjunt).map((valadjun,ind)=>{
                                    return (<>
                                                  <Col md={12} className="card mb-2" style={{padding: '1px',backgroundColor: 'white', textAlign:'center'}}>
                                                      <label className="form-control-label m-0" style={{color: 'black'}}>Titulo/Actividad: {' '}
                                                      {(valadjun.value!='general')?(valadjun.name):((valadjun.datastate.activity==undefined)?(null):(valadjun.datastate.activity[0]))}
                                                      </label>
                                                      <Col md={12}>
                                                        <Row>
                                                        <Table className="align-items-center table-flush" responsive>
                                                          <thead className="thead-light">
                                                            <tr>
                                                              <th>Observaciones</th>
                                                              <th>Recomendación</th>
                                                              <th>Prioridad</th>
                                                              <th>Estado de Cumplimiento</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {this.getContetnTwo(valadjun.datastate,ind,indx)}
                                                          </tbody>
                                                        </Table>
                                                      </Row>
                                                    </Col>
                                                  </Col>

                                        </>)
                                  })}
                                {(val.monitor_type=='VA')?(<>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                      <label className="form-control-label">Firma:</label><br />
                                        <img 
                                        style={{ backgroundSize: '200px 50px',width: '200px',height: '50px',backgroundColor: 'white'}} 
                                        src={val.signature} 
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <label className="form-control-label">Nro de Autorización:</label><br />
                                      <span className="alert-title" data-notify="title">{val.nro_autorization}</span>
                                      </Col>
                                      <Col md={4}>
                                      <label className="form-control-label">Documento:</label><br />
                                      {(val.document==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+val.document)}}>{String(val.document).split('/')[1]?.split('.')[1]}</Button>)}
                                      </Col>
                                    </Row>
                                  </Col>
                                  </>):(null)}
                                </Collapse>
                                </>
                    })
                  }

    return content
  }
     opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
     openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
  render(){
  return (
    <>
{/*<ContentLogistic dataTable={datacontent} dataRow={this.props.DataRow} />*/}

    <div id="produccion-tab" className="clearfix eael-tab-content-item inactive" data-title-link="produccion-tab">
        <article data-elementor-type="section" data-elementor-id="5420" className="elementor elementor-5420">
        <div className="elementor-section-wrap">
          <Row>
            <Col md={3} style={{left:'4%'}}>
              {this.contentRows()}
            </Col>
            <Col md={9} className="pt-2">
              {this.contentRowsCard()}
            </Col>
          </Row>
    <section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section>
    </div>
    </article>
    </div>
    </>
  );
}
}

