// Change this file as you need it

const config = {
	port: 5001
	//gateway: 8080
}
const projectId = '2Q9QL4YlpwehliW1NOyiII4XX15';
const projectSecret = 'f053e5dec9a91cf0a48906e52ecd1f35';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
export const node = {
	// default remote Node
	default: 'remote',
	remote: {
		host: 'ipfs.infura.io',
		port: '5001',
		protocol: 'https',
		headers: {
				  authorization: auth,
				  },
	},

	// default local node
	local: {
		host: '127.0.0.1',
		...config,
		protocol: 'http'
	}
}
