import React from "react";
import ReactDOM from "react-dom"
import {
//   Button,
   Card,
   CardHeader,
   CardBody,
//   FormGroup,
//   Form,
//   Input,
   Container,
//   Row,
   Col,
//   Table
 } from "reactstrap";
import { //Col,
 Divider, Row, Table } from 'antd';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { get_service_pay, set_info_service, edit_user_location } from "network/ApiAxios";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

class Payments extends React.Component {
  static contextType = UserContext
  state={
    dataPermissionUser:null,
    setinfoState:false,
    data_service:this.props.location?.state?.getDataJson
  }
  async componentDidMount(){
    var data = this.props.location?.state?.getDataJson
    this.setState({data_service:data})
    this.context.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
      }else{
          return
    }  
    var dataPermission = this.context.valueGlobal
        if (dataPermission) {
          this.setState({dataPermissionUser:dataPermission})
        }  
      }  
  render(){
    this.setInfoInit()
const geolocacion = () => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    function VerPosicion(posicion){
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(lat,lon,data)
            }

        })
        .catch(err => console.log(err.message)));
    }
        const setinfolocation = async (lat,lon,data) => {
              var userdata = localStorage.getItem('user');
              var jsondata = JSON.parse(userdata);
              var result = await edit_user_location(jsondata._id, data.results[0].formatted)
              if (result) {
                this.setState({dataPermissionUser:result})
                this.setState({loadlocali:null})
              }
              //this.setState({georeferencia:data.results[0].formatted})
    }

    function noPermitir(argument) {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }      
    const contentServiceFunction = (data) =>{
      var dataFunction = [];
      if (data!=undefined) {
       dataFunction = data?.split('_')
     }
       var content = []
       for (var i = 0; i < dataFunction.length; i++) {
       content[i] = <div>{dataFunction[i]}</div>
       }
       return content
    }
  const off_date = () =>{
      var result = new Date();
      result.setDate(result.getDate() + 31);
      return String(result.toLocaleDateString("en-CA"));
  }
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: String(parseFloat(this.state.data_service?.global_price).toFixed(2)*1),
          },
        },
      ],
    });
  };
  const onApprove = (data, actions) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var traza_name = null;
    if (this.state.data_service?.tipo_traza.indexOf('VF')!=-1) {
      traza_name = 'Vegetales y frutas';
    }
    if (this.state.data_service?.tipo_traza.indexOf('M')!=-1) {
      traza_name = 'Carnicos';
    }
    if (this.state.data_service?.tipo_traza.indexOf('G')!=-1) {
      traza_name = 'Granos';
    }
    if (this.state.data_service?.tipo_traza.indexOf('VF-M')!=-1) {
      traza_name = 'Vegetales y frutas - Carnicos';
    }
    if (this.state.data_service?.tipo_traza.indexOf('VF-G')!=-1) {
      traza_name = 'Vegetales y frutas - Granos';
    }
    if (this.state.data_service?.tipo_traza.indexOf('M-G')!=-1) {
      traza_name = 'Carnicos - Granos';
    }
    if (this.state.data_service?.tipo_traza.indexOf('VF-M-G')!=-1) {
      traza_name = 'Vegetales y frutas - Carnicos - Granos';
    }
    var extendThis = this
    return actions.order.capture().then(async function () {
            var getDataString = await set_info_service(jsondata._id,extendThis.state.data_service.id,extendThis.state.data_service.precio,extendThis.state.data_service.areawork+'|'+extendThis.state.data_service.tipo_traza,extendThis.state.data_service.time_date,String(parseInt(extendThis.state.data_service?.precio)*parseInt(extendThis.state.data_service?.cantidad_usuarios_hasta)*1),data.orderID,traza_name,extendThis.state.data_service?.cantidad_usuarios_hasta,extendThis.state.data_service?.type_asigned_users);
                 if (getDataString.result) {
                  extendThis.props.history.push('/admin/success');
                 }
            });
  };    
  return (
    <>
      <SimpleHeader name="Finalizar pago" parentName="Administración" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Facturación</h3>
          </CardHeader>
          <CardBody>
            <div style={{ padding: 20 }}>

        <Col md={12} >
          <Row gutter={24} style={{ marginTop: 32 }}>
              <Col md={8} >
                <h3>{this.state.dataPermissionUser?.name}</h3>
                <div>{(this.state.dataPermissionUser?.ubicacion==null)?(<a href="#" onClick={()=> {geolocacion()}}>Establecer ubicación</a>):(this.state.dataPermissionUser?.ubicacion?.split(',').map((valu,ind)=>{
                                  return <>{valu}<br /></>
                                }))}</div>
                {this.state.loadlocali}
              </Col>
              <Col md={4} >
                <div><b>Empresa:</b> {this.state.dataPermissionUser?.empresa}</div>
                <div><b>Pais:</b> {this.state.dataPermissionUser?.pais}</div>
                <div><b>Correo:</b> {this.state.dataPermissionUser?.email}</div>
              </Col>
          </Row>
        </Col>

{/*      <Row style={{ marginTop: 48 }}>
        <div>Bangalore - 560076</div>
      </Row>*/}


      <Row style={{ marginTop: 48 }}>
        <Table dataSource={[{
            id: 1,
            name:(this.state.data_service?.tipo_traza.indexOf('VF')!=-1)?('Vegetales y frutas - Carnicos'):(
              (this.state.data_service?.tipo_traza.indexOf('M')!=-1)?('Vegetales y frutas'):(
                (this.state.data_service?.tipo_traza.indexOf('G')!=-1)?('Carnicos'):(
                  (this.state.data_service?.tipo_traza.indexOf('VF-M')!=-1)?('Granos'):(
                    (this.state.data_service?.tipo_traza.indexOf('VF-G')!=-1)?('Vegetales y frutas - Granos'):(
                      (this.state.data_service?.tipo_traza.indexOf('M-G')!=-1)?('Carnicos - Granos'):(
                        (this.state.data_service?.tipo_traza.indexOf('VF-M-G')!=-1)?('Vegetales y frutas - Carnicos - Granos'):(null)
                        )
                      )
                    )
                  )
                )
              ),
            description: contentServiceFunction(this.state.data_service?.funciones),
            date_final: off_date(),
            price: parseFloat(this.state.data_service?.global_price).toFixed(2),
            quantity: parseInt(this.state.data_service?.cantidad_usuarios_hasta)
        }]}
        style={{ width: '100%' }}
        pagination={false}
        >
          <Table.Column title="Trazabilidad" dataIndex='name' />
          <Table.Column title="Funciones" dataIndex='description' />
          <Table.Column title="Cantidad Usuarios" dataIndex='quantity' />
          <Table.Column title="Fecha corte" dataIndex='date_final' />
          <Table.Column title="Precio" dataIndex='price' />
        </Table>
      </Row>

      <Row style={{ marginTop: 48 }}>
        <Col span={8} offset={16}>
          <table style={{ textAlign: 'end', width: '100%' }}>
{/*            <tr>
              <th>Gross Total :</th>
              <td>Rs. 1599</td>
            </tr>
            <tr>
              <th>IGST @6% :</th>
              <td>Rs. 95.94</td>
            </tr>
            <tr>
              <th>CGST @6% :</th>
              <td>Rs. 95.94</td>
            </tr>*/}
            <tr>
              <th>Nett Total :</th>
              <td style={{ width: '0' }}>${parseFloat(this.state.data_service?.global_price)*1}</td>
            </tr>
          </table>
        </Col>
      </Row>
      <div style={{ marginTop: 48, textAlign: 'center' }}>
        <PayPalButton
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
        />    
      </div>
      <Row style={{ marginTop: 48, textAlign: 'center' }}>
        Al finalizar la compra le llegara la facturación a su correo.
      </Row>
    </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
 }
}
const style = {
  btnSelect:{
    backgroundColor: "#00295b",
    color:"white"
  }
}
class SwiftComponent extends React.Component {
    static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    dataUser:[]
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    this.setState({dataUser:dataPermission})
    if (dataPermission.role==1) {
      this.setState({get_permission:true})
    }else{
      this.setState({get_permission:false})
    }
      }  
render(){
  this.setInfoInit()  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Payments {...this.props} dataUser={this.state.dataUser} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
