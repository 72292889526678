/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useRef } from "react";

// reactstrap components
import {
  Button,
  Container,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";
import { forgot_password, change_forgot_password } from "network/ApiAxios";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import config from "config";
import { NavLink as ComponentLink } from "react-router-dom";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [sectionForm, setSectionForm] = useState(1);
  const [codigo, setCodigo] = useState();
  const [password, setPassword] = useState();
  const [password_repeat, setPasswordRepeat] = useState();
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);

  const notificationAlertRef = useRef(null);


  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const sectionGoBack = async () => {
    setSectionForm(1)
  };
  const sendEmail = async () => {
    setbtnDisabled(true)
    if (email == "") {
      setbtnDisabled(false)
      setError("Por favor ingrese un correo!.");
      return;
    }
    const response = await forgot_password(email,1,null).catch(function (message){
      setbtnDisabled(false)
      setError(message.response.data.errors.email[0])
    });
    if (response != undefined) {
      const { data } = response;
      if (data.success) {
        setbtnDisabled(false)
      //   if (config.DEMO) {
      //     setToastMessage(
      //       "This is a demo, so we will not send you an email. Instead, click on this link to reset your password:"
      //     );
      //     setUserID(data.userID);
      //   }
        setSectionForm(2)
        notify("success", "Success!", 'Información enviada');
        setError("")
      } else {
        setbtnDisabled(false)
        setError(data.msg);
      }
    }
  };
      const sendCodigo = async () => {
        setbtnDisabled(true)
      const response = await forgot_password(email,2,codigo).catch(function (message){
        setbtnDisabled(false)
        setError(message.response.data.errors.email[0])
      });
      if (response != undefined) {
        const { data } = response;
        if (data.success) {
        setbtnDisabled(false)
        //   if (config.DEMO) {
        //     setToastMessage(
        //       "This is a demo, so we will not send you an email. Instead, click on this link to reset your password:"
        //     );
        //     setUserID(data.userID);
        //   }
          setSectionForm(3)
          notify("success", "Success!", 'Código Valido');
          setError("")
        } else {
          setbtnDisabled(false)
          setError(data.msg);
        }
      }
    };
    const changePassword = async () => {
      setbtnDisabled(true)
    if (password == "" || password_repeat == "") {
      setbtnDisabled(false)
      setError("No se puede dejar campos vacios.");
      return;
    }
    if (password != password_repeat) {
      setbtnDisabled(false)
      setError("Las contraseñas no cionciden.");
      return;
    }
    const response = await change_forgot_password(email,codigo,password).catch(function (message){
      setbtnDisabled(false)
      setError(message.response.data.errors.email[0])
    });
    if (response != undefined) {
      const { data } = response;
      if (data.success) {
      //   if (config.DEMO) {
      //     setToastMessage(
      //       "This is a demo, so we will not send you an email. Instead, click on this link to reset your password:"
      //     );
      //     setUserID(data.userID);
      //   }
        notify("success", "Success!", 'Contraseña cambiada exitosamente');
        setError("")
        setTimeout(() => {
          props.history.push("/auth/login");
        }, 3000);
      } else {
        setbtnDisabled(false)
        setError(data.msg);
      }
    }
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader
        title="Recuperar Contraseña!"
        lead="Introduzca su correo registrado para enviarle un código de recuperación para cambiar su contraseña anterior a una nueva."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">

                {(sectionForm==1)?(<Form role="form">
                                                  <FormGroup className="mb-3">
                                                    <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input
                                                        placeholder="Email"
                                                        type="email"
                                                        autoComplete="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                      />
                                                    </InputGroup>
                                                  </FormGroup>
                                                  {error ? (
                                                    <div className="text-muted font-italic">
                                                      <small>
                                                        error:{" "}
                                                        <span className="text-red font-weight-700">
                                                          {error}
                                                        </span>
                                                      </small>
                                                    </div>
                                                  ) : null}
                                                  <div className="text-center">
                                                    <Button
                                                      className="my-4"
                                                      color="primary"
                                                      type="button"
                                                      disabled={btnDisabled}
                                                      onClick={sendEmail}
                                                    >
                                                      Enviar
                                                    </Button>
                                                  </div>
                                                </Form>):(null)}

                {(sectionForm==2)?(<Form role="form">
                                                  <FormGroup className="mb-3">
                                                    <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input
                                                        placeholder="Codigo"
                                                        type="text"
                                                        autoComplete="codigo"
                                                        value={codigo}
                                                        onChange={(e) => setCodigo(e.target.value)}
                                                      />
                                                    </InputGroup>
                                                  </FormGroup>
                                                  {error ? (
                                                    <div className="text-muted font-italic">
                                                      <small>
                                                        error:{" "}
                                                        <span className="text-red font-weight-700">
                                                          {error}
                                                        </span>
                                                      </small>
                                                    </div>
                                                  ) : null}
                                                  <div className="text-center">
                                                    <Button
                                                      className="my-4"
                                                      color="primary"
                                                      type="button"
                                                      disabled={btnDisabled}
                                                      onClick={sendCodigo}
                                                    >
                                                      Confirmar
                                                    </Button>
                                                    <Button
                                                      className="my-4"
                                                      color="primary"
                                                      type="button"
                                                      disabled={btnDisabled}
                                                      onClick={sectionGoBack}
                                                    >
                                                      Volver
                                                    </Button>
                                                  </div>
                                                </Form>):(null)}

                {(sectionForm==3)?(<Form role="form">
                                                  <FormGroup className="mb-3">
                                                    <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input
                                                        placeholder="Contraseña"
                                                        type="password"
                                                        autoComplete="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                      />
                                                    </InputGroup>
                                                  </FormGroup>

                                                  <FormGroup className="mb-3">
                                                    <InputGroup className="input-group-alternative">
                                                      <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                          <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                      </InputGroupAddon>
                                                      <Input
                                                        placeholder="Repetir Contraseña"
                                                        type="password"
                                                        autoComplete="password"
                                                        value={password_repeat}
                                                        onChange={(e) => setPasswordRepeat(e.target.value)}
                                                      />
                                                    </InputGroup>
                                                  </FormGroup>

                                                  {error ? (
                                                    <div className="text-muted font-italic">
                                                      <small>
                                                        error:{" "}
                                                        <span className="text-red font-weight-700">
                                                          {error}
                                                        </span>
                                                      </small>
                                                    </div>
                                                  ) : null}
                                                  <div className="text-center">
                                                    <Button
                                                      className="my-4"
                                                      color="primary"
                                                      type="button"
                                                      disabled={btnDisabled}
                                                      onClick={changePassword}
                                                    >
                                                      Confirmar
                                                    </Button>
                                                  </div>
                                                </Form>):(null)}
                  <Col className="text-center">
                     <ComponentLink
                      className="font-weight-bold" to={{pathname:"/home/register"}}>
                     Si no estas registrado presione aquí
                     </ComponentLink>
                  </Col>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
