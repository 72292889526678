import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production_nama_g, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_prodction_nama_g, delete_file_prod } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContentPro, PutFormContentProTable, PutFormContentTableProd, forNumeric, PutFormContentFinal} from 'views/pages/forms/LogisticsForm';
import {GuideMessage} from "views/pages/components/GuideMessage";
import jQuery from 'jquery';
export default class GNamaTable extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    field_params:{},
    field_params_ftable:[],
    field_params_view:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],    
    dataStatesS:0,
    content_render:null,
    get_content:[],
    get_first_info:'',
    btnd:false,
    change_mrv_data:0,
    urlpathname:null
  }
  async componentDidMount(){
     var getData = await Object.values(this.props.sendInfoProps.dataMainContent)
   getData.map((a,b)=>{
     if (a.include_info==1) {
       this.setState({get_first_info:a.name_field})
     }
   })
   const urlParam = window.location.pathname
   this.setState({urlpathname:urlParam})
  // this.setState({get_content:getData})    
  // this.setState({field_content_log:getData})
  //this.initData(this.props.sendInfoProps)
    if (this.props.setParamsUrl.state!=null) {
      if (this.props.setParamsUrl.state.verify_mrv=='ON') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvON').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='RE') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvRE').click()
        })
      }
      if (this.props.setParamsUrl.state.verify_mrv=='VA') {
        jQuery(document).ready(function(e){
          jQuery('#btnMrvVA').click()
        })
      }
    }
    this.initData()
  }
  initData = async ()=>{
    this.props.sendInfoProps.trazaData.map((value,index)=>{
                this.initDataTwo(value,true)
              })
  }
  initDataTwo = async (set_data,verify)=>{
     // this.setState({
     //      sendOpenModal:verify,
     //    })
    var data_select = []
      if (this.props.sendInfoProps.dataMainContent!=null) {
        this.setState({field_content: Object.values(this.props.sendInfoProps.dataMainContent) })
      }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(window.location.search).get("code");
        var dataTrazabilidadvf = set_data
        //var dataTrazabilidadvf = await data_fertilizer_production(id_set,this.props.tableCall,JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
            this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),verify)
        }else{
            this.setDataCampos(dataTrazabilidadvf,Object.values(this.props.sendInfoProps.dataMainContent),verify)
        }
  }
     componentDidUpdate(prevProps, prevState, snapshot) {
      //console.log(this.state.idT,this.state.field_params)
      var return_result = forNumeric(prevState,this.state)
      if (return_result!=null) {
        const full_data = this.state.field_params
        full_data[return_result.field_product]=parseInt(return_result.product_result)
        this.setState({field_params: full_data })
        //this.setState({field_params: {...this.state.field_params, [return_result.field_product]: parseInt(return_result.product_result) }})
        
      }
        if (prevState.field_params !== this.state.field_params) {
        }
      } 
     setDataCampos = (data,dataMainContent,pass_select)=>{
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
       //delete data.id
       delete data.count_table
       //this.setState({field_params:data})
       var field_params_title={}
       var field_params_required={}
       var field_params_select={}
       var field_params_params={}
       var field_params_detail_mrv={}
       var new_content=[]
       dataMainContent.map((a,b)=>{
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field

         if (data[a.name_field]==null) {
          field_params_params[a.name_field]=''
          field_params_detail_mrv[a.name_field]=''
         }else{
          field_params_params[a.name_field]=data[a.name_field]
          field_params_detail_mrv[a.name_field]=""
            if (a.type_field=='date') {
              field_params_params[a.name_field]=data[a.name_field]
              //field_params_detail_mrv[a.name_field]=data[a.name_field]
            }
            if (a.type_field=='time') {
              field_params_params[a.name_field]=data[a.name_field]
              //field_params_detail_mrv[a.name_field]=data[a.name_field]
            }
          if (a.type_field=='file') {
            if (typeof data[a.name_field]=='string') {
             if (data[a.name_field].split('|').length>0) {
              var multi_fil = []
               data[a.name_field].split('|').map((a,b)=>{
                 multi_fil[b] = {name:a,filed:null}
               })
                var item = []
                item = [...multi_fil]
              field_params_params[a.name_field]=item
             }
            }
          }
          if (a.include_info==1) {
            field_params_detail_mrv[a.name_field]=data[a.name_field]
          }
         }
          if (a.type_field=='date') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=completeDate
            }
            //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
          }
          if (a.type_field=='time') {
            if (data[a.name_field]==null) {
              field_params_params[a.name_field]=timeString
            }
              //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
          }
         if (a.type_field=='select') {
            var get_data_select = this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            //field_params_select[a.name_field] = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')
            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
            var getFullOption = this.state.optionsSelect
            getFullOption[data?.id]=field_params_select
         this.setState({optionsSelect: getFullOption})
         this.setState({selectForFilter: getFullOption})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
       var thisState= this
      jQuery(document).ready(function(e) {
          if (jQuery(e.target).hasClass('view_detail_panel')) {
            thisState.setState({idT:'null'})
            thisState.setState({idTP:data?.id})
            thisState.setState({change_mrv_data:1})
            thisState.setState({field_params_view:field_params_params })
            var getFull = thisState.state.field_params
            getFull[data?.id]=field_params_detail_mrv
            thisState.setState({field_params: getFull,field_params_view:[] })

            dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=1}})
            var listItem = jQuery(e.target);
            var getIndex = jQuery( ".view_detail_panel" ).index( listItem )
            thisState.setState({putPositionBtn:getIndex})
          }else{
            thisState.setState({change_mrv_data:0})
            if (jQuery(e.target).hasClass('newRegister')) {
              thisState.setState({idT:'null'})
              thisState.setState({field_params_view:[] })
            }else{
              if (!jQuery(e.target).hasClass('upload_files')) {
                thisState.setState({idT:data?.id})
                //thisState.setState({field_params: field_params_params,field_params_view:[] })
                // if (getFull[data?.id]==undefined) {
                // }
                var getFull = thisState.state.field_params
                getFull[data?.id]=field_params_params
                thisState.setState({field_params: getFull,field_params_view:[] })
                thisState.setState({field_params_view:[] })
              }
            }
             dataMainContent.map((a,b)=>{if (a.include_info==1) {a.dis_field=0}})
          }
      });
         this.setState({field_params_title: field_params_title})
         this.setState({field_params_required: field_params_required})
     }
     cleanData = (dataMainContent,pass_select) =>{
           this.setState({idT:'null'})
           var date = new Date()
           var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
           var completeDateBefore = completeDateAfter.split('/')
           var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
           var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
           var field_params_title={}
           var field_params_required={}
           var field_params_select={}
           var field_params_params={}
       dataMainContent.map((a,b)=>{
         field_params_params[a.name_field]=''
         field_params_title[a.name_field]=a.label_title
         field_params_required[a.name_field]=a.req_field
         if (pass_select) {
           if (a.type_field=='date') {
            field_params_params[a.name_field]=completeDate
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=timeString
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }else{
           if (a.type_field=='date') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
           }
           if (a.type_field=='time') {
            field_params_params[a.name_field]=null
             //this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
           }
         }
          // if (a.type_field=='file') {
          //     var item = [{name:'',filed:null}]
          //     field_params_params[a.name_field]=item
          // }
         if (a.type_field=='select') {
            var get_data_select =this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
            field_params_select[a.name_field] = Object.values(get_data_select)
           if (a.list_default=='ldp') {
            var get_data_select_l = this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')

            field_params_select[a.name_field] = Object.values(get_data_select_l)
           }
         }
       })
       if (pass_select) {
            // var getFullOption = this.state.optionsSelect
            // getFullOption[data?.id]=field_params_select
          var get_arr_options = Object.keys(this.state.optionsSelect)
          var get_sec_arr_opt = get_arr_options[get_arr_options.length-1]
          var getFullOption = this.state.optionsSelect
          getFullOption['null_'+(parseInt(get_sec_arr_opt)+1)]=field_params_select

         this.setState({optionsSelect: getFullOption})
         this.setState({selectForFilter: getFullOption})
       }else{
         this.setState({optionsSelect: []})
         this.setState({selectForFilter: []})
       }
          var get_arr_element = Object.keys(this.state.field_params)
          var get_sec_arr = get_arr_element[get_arr_element.length-1]
          var getFull = this.state.field_params
          getFull['null_'+(parseInt(get_sec_arr)+1)]=field_params_params
          this.setState({field_params: getFull })

         this.setState({field_params_title: field_params_title })
         this.setState({field_params_required: field_params_required })
     }

  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  saveInfo = async (dataId) =>{

      this.setState({btnd:true})
      this.setState({mess:['Enviando...']})
      if (this.state.field_params_required==undefined) {
        this.setState({btnd:false})
        return
      }
    
    var field_data = Object.entries(this.state.field_params[dataId])
     var field_data_remove=[]
     var counter= 0
     field_data.map((c,i)=>{
       if (c[0]!='created_at' && c[0]!='id') {
         field_data_remove[counter]=c
       }
       counter++
     })
    var field_data_name = Object.entries(this.state.field_params_title)
    var data_mess = []
    var data_for_file = []
    var counter = -1
    Object.entries(this.state.field_params_required).map((a,b)=>{
        if (field_data[b]!=undefined) {
          if (field_data[b][0].indexOf('file_')!=-1) {
            counter++
           data_for_file[counter] = {[field_data[b][0]]:field_data[b][1]}
          }
        }
        if (field_data[b]!=undefined) {
          if (field_data[b][0]==a[0]) {
           if (a[1]==1 && (field_data[b][1]=='' || field_data[b][1]==null)) {
            counter++
            data_mess[b] = 'El campo '+a[0]+' es requerido';
           }
          }
        }
    })
    if (data_mess.length>0) {
      this.setState({mess: data_mess.filter(Boolean) })
      this.setState({btnd:false})
      return
    }else{
      this.setState({btnd:false})
      this.setState({mess:''})
    }

       var gerParam = new URLSearchParams(window.location.search).get("code");
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
         this.setState({btnd:true})
          if (dataId.indexOf('null')!=-1) {
            var idRegister = 'null'
          }else{
            var idRegister = dataId
          }
          const var_vf = await create_production_nama_g(jsondata._id,field_data_remove.sort(),field_data_name.sort(),gerParam,this.props.tableCall,idRegister,data_for_file,this.props.titleCall);
          if (var_vf==null) {
            this.setState({btnd:false})
            return
          }
              if (var_vf.message=="exito") {
                this.setState({mess:''})
                this.notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
                this.setState({btnd:false})
                this.setState({field_params: [] })
                this.setState({optionsSelect: []})
                this.setState({selectForFilter: []})
                await this.props.refreshData()
                this.initData()
               if (this.state.change_mrv_data==1) {
                jQuery(jQuery('.btn_change_state')[this.state.putPositionBtn]).click()
               }
              }
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
 geolocacion = (dataId,options,indx_position) => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition((e)=>{this.VerPosicion(dataId,e,options,indx_position)},this.noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    VerPosicion = (dataId,posicion,optcon,indx_position)=>{
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    this.setFieldValue(dataId,lat+','+lon+'-(Información restante)',optcon.id,optcon)
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              this.setinfolocation(lat,lon,data,optcon,dataId,indx_position)
            }

        }).catch(err => console.log(err.message)));
    }
         setinfolocation = (lat,lon,data,optcon,idRow) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                  var full_data_one = this.state.field_params

                  var itemsData = full_data_one[idRow]
                  itemsData[val.name_field]=String(data.results[0].components.country)
                  full_data_one[idRow]=itemsData

                  this.setState({field_params: full_data_one})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    var full_data_two = this.state.field_params
                    //full_data_two[val.name_field]=String(data.results[0].components.state)
                    var itemsDataTwo = full_data_two[idRow]
                    itemsDataTwo[val.name_field]=String(data.results[0].components.state)
                    full_data_two[idRow]=itemsDataTwo
                    this.setState({field_params: full_data_two})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    var full_data_three = this.state.field_params
                    //full_data_three[val.name_field]=String(data.results[0].components.city)
                    var itemsDataThree = full_data_three[idRow]
                    itemsDataThree[val.name_field]=String(data.results[0].components.city)
                    full_data_three[idRow]=itemsDataThree
                    this.setState({field_params: full_data_three})
                    //this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(idRow,String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(idRow,String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(idRow,lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }
    noPermitir = (argument) => {
      this.setState({loadlocali:null})
    }
     contentForSelect = (data,field_name,isrequerid,list_default)=>{
      
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (idRow,value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
                    // var full_data_one = this.state.field_params
                    // full_data_one[field]=value
                  const full_data_one = this.state.field_params
                  var itemsData = full_data_one[idRow]
                  itemsData[field]=value
                  full_data_one[idRow]=itemsData

                    this.setState({field_params: full_data_one})
            //this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            var full_data_two = this.state.field_params
            full_data_two[field]=value_for_states[0].value
            this.setState({field_params: full_data_two})
            //this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
    //  setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states,indx_position) => {
    //     this.setState({dataStatesS:0})
    //     this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
    //       if (if_states==undefined) {
    //          var itemState = this.state.field_params
    //          var itemStates = itemState[indx_position]
    //          itemStates[field] = value
    //          itemState[indx_position]=itemStates
    //         this.setState({field_params: itemState})
    //       }
    //     if (list_default=='lde') {
    //       if (if_states!=undefined) {
    //         var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
    //          var item = this.state.field_params
    //          var items = item[indx_position]
    //          items[field] = value_for_states[0].value
    //          item[indx_position]=items
    //         this.setState({field_params: item})
    //       }
    //   }
    //     this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    // }
     setFieldValue = (idRow,value, field, isrequired) =>{
      const full_data = this.state.field_params
      var itemsData = full_data[idRow]
      itemsData[field]=value
      full_data[idRow]=itemsData
       this.setState({field_params: full_data })
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     setFieldValueFiles = (idRow,value, field, isrequired) =>{
      var multi_fil = []
      Object.values(value).map((a,b)=>{
        //console.log(value[b])
        multi_fil[b] = {name:a.name,filed:a}
      })
       var item = this.state.field_params
       var itemsData = item[idRow]
       //var items = itemsData[field]
       itemsData[field] = [...itemsData[field],...multi_fil]
       item[idRow]=itemsData
       this.setState({field_params: item })
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
       if (isrequired.getAttribute('data_filter')!=null) {
         isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
           if (a!='upf') {
             //this.setState({field_params: {...this.state.field_params, [a]:value }})
             Object.entries(this.state.selectForFilter).map((val,indx)=>{
               if (val[0]==a) {
                 var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                 this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
               }
             })
           }
         })
       }
    }
     deleteImgState = async (indx,field,data,url_selected) =>{
       var item = this.state.field_params
       var items = item[field]
       var fill_data_f=items.filter((a,b)=> b!=indx)
       items = fill_data_f
       item[field]=items
       if (window.confirm('Estas seguro en eliminar este archivo?')) {
         if (data.field==null) {
          var data_clean=[]
          var counter=0
          items.map((val,indx)=>{
            if (val.name!='') {
              data_clean[counter]=val.name
              counter++
            }
          })
          var data_s = await delete_file_prod(this.state.idT,this.props.tableCall,field,data_clean.join('|'),url_selected)
          if (data_s.message=='exito') {
            this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
             var get_data_new = this.props.sendInfoProps.trazaData.filter((a,b)=>a.id==this.state.idT)
             // this.setState({optionsSelect: []})
             // this.setState({selectForFilter: []})
             // this.initData(get_data_new[0],true)
          }
         }
      this.setState({field_params: item })
       }
    }

    // setFieldValue = (value, field, isrequired,indx_position) =>{
    //   var item = this.state.field_params
    //   var items = item[indx_position]
    //   items[field] = value
    //   item[indx_position]=items
    //   this.setState({field_params: item })
    //   this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') } })
    //   if (isrequired.getAttribute('data_filter')!=null) {
    //     isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
    //       if (a!='upf') {
    //         Object.entries(this.state.selectForFilter).map((val,indx)=>{
    //           if (val[0]==a) {
    //             var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
    //             this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
    //           }
    //         })
    //       }
    //     })
    //   }
    // }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          var dataSelect = {}
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        dataSelect = this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default)
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
          return dataSelect
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      var dataSelect = {}
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          dataSelect = this.contentForSelect(CodigoAranF,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          dataSelect = this.contentForSelect(CodigoAranM,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null || list_default=='0') {
          dataSelect = this.contentForSelect(value_option,name_field,req_field,list_default)
          //this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      //this.setState({selectForFilter: this.state.optionsSelect})
      return dataSelect
          //console.log(list_default,value_option,name_field,req_field,for_values,this.state.optionsSelect)
    }
 forSearhData = (query,data) => {

    return new Promise((resolve, reject) => {
            if (data.length!=0) {
                resolve(data.filter((a,b) => a.value.toUpperCase().indexOf(query.toUpperCase())!=-1))
            }
    });

}
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
deleteFer = async (id_set) => {
      //await this.initData(set_val,false)
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      var gerParam = new URLSearchParams(window.location.search).get("code");
      if (window.confirm("Deseas eliminar este registro?")) {
    var field_data = Object.entries(this.state.field_params[id_set])
    var field_data_remove=[]
    var counter= -1
    field_data.map((c,i)=>{
      if (c[0]!='created_at') {
        field_data_remove[counter]=c
      }
      counter++
    })
       var field_data_name = Object.entries(this.state.field_params_title)
          if (id_set.indexOf('null')!=-1) {
            delete this.state.field_params[id_set]
            this.setState({field_params: this.state.field_params })
          }else{
          var dataReturn = await delete_prodction_nama_g(id_set,jsondata._id,field_data_remove,field_data_name,gerParam,this.props.tableCall,this.props.titleCall)
               if (dataReturn=="exito") {
               this.setState({mess:'Se eliminó exitosamente.'})
               this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
               //this.cleanData(Object.values(this.props.sendInfoProps.dataMainContent),false)
               this.setState({field_params: [] })
               this.setState({optionsSelect: []})
               this.setState({selectForFilter: []})
               await this.props.refreshData()
               this.initData()
               //window.location.reload()
             }else{
               this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
               this.setState({btnd:false})
             }
          }
     }
 }
 insertNewRegister =()=>{
  var date = new Date()
  var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
  var completeDateBefore = completeDateAfter.split('/')
  var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
  var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
  //if (this.state.field_params[this.state.field_params.length-1]!=undefined) {
  // var push_shift = [...this.state.field_params,{id:this.state.field_params[this.state.field_params.length-1].id+1}]
  // }else{
  // }
  var push_shift = [...this.state.field_params,{id:null}]
  this.setState({field_params: push_shift })
 }
     listCountryCode = CountrysData.sort(this.listaArray)

  render(){
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
       <Row>
        <Card className="mb-1" md="4" style={{backgroundColor:'transparent',boxShadow: 'none'}}>
        <CardHeader className="p-0" style={{borderRadius: '20px',backgroundColor:'transparent'}}>
        <Row>
            <Button className="btn-icon ml-3 newRegister" color="traza-blue" type="button" onClick={()=>{ this.initDataTwo(null,true) }}>
            <h3 className="mb-0 newRegister" style={{color:'white'}}>
             Ingresar {this.props.titleCall} +
            </h3>
              {/*<span className="btn-inner--text">+</span>*/}
            </Button>
            {/*<GuideMessage  />*/}
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
              <PutFormContentProTable 
              content={this.state.field_content}
              field_params={this.state.field_params}
              optionsSelect={this.state.optionsSelect}
              setFieldValue={this.setFieldValue}
              setFieldValueFiles={this.setFieldValueFiles}
              setFieldValueSelect={this.setFieldValueSelect}
              deleteImgState={this.deleteImgState}
              forSearhData={this.forSearhData}
              geolocacion={this.geolocacion}
              initDataFunct={this.initDataTwo}
              rowDataAll={this.props.sendInfoProps.trazaData}
              saveInfoProp={this.saveInfo}
              deleteInfoProp={this.deleteFer}/>
            </Row>
         </CardBody>
    </>
  );
 }
}

