import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class DetailSection extends React.Component {
  state={
  }
    async componentDidMount(){
        localStorage.removeItem('detail_carnero')
        localStorage.removeItem('detail_carnero_two')
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
    const setValueSession = (valOne, valTwo,inputVal) => {

      if (this.props.validSwith.type_change_prod==1) {
        localStorage.setItem(valOne,inputVal)
      }
      if (this.props.validSwith.seleccionar_operacion_prop=='1') {
        localStorage.setItem(valTwo,inputVal)
     }
    };
    const resultSum = (detail_carnero,detail_ovejos,detail_capones,detail_borregas,detail_corderas,detail_corderos,detail_mamones) => {
      //console.log(parseInt(detail_carnero),parseInt(detail_ovejos),parseInt(detail_capones),parseInt(detail_borregas),parseInt(detail_corderas),parseInt(detail_corderos),parseInt(detail_mamones))
      var numOne = parseInt(detail_carnero) || 0;
      var numTwo = parseInt(detail_ovejos) || 0;
      var numTree = parseInt(detail_capones) || 0;
      var numFor = parseInt(detail_borregas) || 0;
      var numFive = parseInt(detail_corderas) || 0;
      var numSix = parseInt(detail_corderos) || 0;
      var numSeven = parseInt(detail_mamones) || 0;
      var result = [numOne,numTwo,numTree,numFor,numFive,numSix,numSeven].reduce((a, b) => a + b, 0)
      this.props.setValField('resultSum',result)
      this.setState({resultSum:result})
      // if (this.props.getValField(valOne, valTwo)==undefined) {
      // return null
      // }else{
      // }
    };
    const resultSumVacunos = (detail_toros,detail_vacas,detail_movillosbueyes,only_movillos_2a3,only_movillos_1a2,detail_vaquillonas,detail_vaquillonas_1a2,detail_terneros) => {
      //console.log(parseInt(detail_carnero),parseInt(detail_ovejos),parseInt(detail_capones),parseInt(detail_borregas),parseInt(detail_corderas),parseInt(detail_corderos),parseInt(detail_mamones))
      var numOne = parseInt(detail_toros) || 0;
      var numTwo = parseInt(detail_vacas) || 0;
      var numTree = parseInt(detail_movillosbueyes) || 0;
      var numFor = parseInt(only_movillos_2a3) || 0;
      var numFive = parseInt(only_movillos_1a2) || 0;
      var numSix = parseInt(detail_vaquillonas) || 0;
      var numSeven = parseInt(detail_vaquillonas_1a2) || 0;
      var numEighth = parseInt(detail_terneros) || 0;

      var result = [numOne,numTwo,numTree,numFor,numFive,numSix,numSeven,numEighth].reduce((a, b) => a + b, 0)
      this.props.setValField('resultSumVacuno',result)
      this.setState({resultSumVacuno:result})
      // if (this.props.getValField(valOne, valTwo)==undefined) {
      // return null
      // }else{
      // }
    };    
    // const forLoadValid = (valOne, valTwo) => {
    //       if (localStorage.getItem(valTwo)==null) {
    //         if (this.props.validSwith.set_id==3) {
    //         this.setState({detail_carnero:''})
    //         return 
    //         }
    //          this.setState({detail_carnero:localStorage.getItem(valOne)})
    //       }else{
    //          this.setState({detail_carnero:localStorage.getItem(valTwo)})
    //       }      

    // };

  return (
    <>
              <Col md="6" className="pb-2">
              <Col md="12">
                <label className="form-control-label" style={styles.labelStyleTree}>
                  3- DETALLE DEL GANADO A TRANSPORTAR:
                </label>
              </Col>              
                <div style={styles.cardStyleSectionTwo}>
                <Row>

                  <Col md={6}>
                  <div className="form-control-label text-center" style={{color: '#000000',fontSize: '11px',width: '100%',fontWeight: '700',marginRight: '0px'}}>
                    Vacunos
                  </div>

                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Toros</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>01</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?((this.props.validSwith.type_change_prod==1)?('41'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('41'):(null))):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_toros==undefined)?(this.props.validSwith.detail_toros):(this.props.validSwith.detail_toros)}
                       onChange={(e)=>{
                        this.props.setValField('detail_toros',e.target.value)
                        resultSumVacunos(e.target.value,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Vacas</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>02</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_vacas==undefined)?(this.props.validSwith.detail_vacas):(this.props.validSwith.detail_vacas)}
                       onChange={(e)=>{
                        this.props.setValField('detail_vacas',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,e.target.value,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>

<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Novillos + de 3 años y Bueyes</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>04</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_movillosbueyes==undefined)?(this.props.validSwith.detail_movillosbueyes):(this.props.validSwith.detail_movillosbueyes)}
                       onChange={(e)=>{
                        this.props.setValField('detail_movillosbueyes',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,e.target.value,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Novillos 2 a 3 años</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>05</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.only_movillos_2a3==undefined)?(this.props.validSwith.only_movillos_2a3):(this.props.validSwith.only_movillos_2a3)}
                       onChange={(e)=>{
                        this.props.setValField('only_movillos_2a3',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,e.target.value,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Novillos 1 a 2 años</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>06</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.only_movillos_1a2==undefined)?(this.props.validSwith.only_movillos_1a2):(this.props.validSwith.only_movillos_1a2)}
                       onChange={(e)=>{
                        this.props.setValField('only_movillos_1a2',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,e.target.value,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Vaquillonas + de 2 años</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>07</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_vaquillonas==undefined)?(this.props.validSwith.detail_vaquillonas):(this.props.validSwith.detail_vaquillonas)}
                       onChange={(e)=>{
                        this.props.setValField('detail_vaquillonas',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,e.target.value,this.props.validSwith.detail_vaquillonas_1a2,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Vaquillonas 1 a 2 años</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>08</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_vaquillonas_1a2==undefined)?(this.props.validSwith.detail_vaquillonas_1a2):(this.props.validSwith.detail_vaquillonas_1a2)}
                       onChange={(e)=>{
                        this.props.setValField('detail_vaquillonas_1a2',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,e.target.value,this.props.validSwith.detail_terneros)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Terneros/as</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>09</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?(null):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       className="field_vacuno"
                       value={(this.props.validSwith.detail_terneros==undefined)?(this.props.validSwith.detail_terneros):(this.props.validSwith.detail_terneros)}
                       onChange={(e)=>{
                        this.props.setValField('detail_terneros',e.target.value)
                        resultSumVacunos(this.props.validSwith.detail_toros,this.props.validSwith.detail_vacas,this.props.validSwith.detail_movillosbueyes,this.props.validSwith.only_movillos_2a3,this.props.validSwith.only_movillos_1a2,this.props.validSwith.detail_vaquillonas,this.props.validSwith.detail_vaquillonas_1a2,e.target.value)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '97px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>TOTAL VACUNOS</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>10</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       // value={(this.state.establecimiento==undefined)?((this.props.validSwith.type_change_prod==1)?('41'):((this.props.validSwith.seleccionar_operacion_prop=='1')?('41'):(null))):(this.state.establecimiento)}
                       // onChange={(e)=>{this.setState({establecimiento:e.target.value})}}
                       value={(this.state.resultSumVacuno==undefined)?(this.props.validSwith.resultSumVacuno):(this.props.validSwith.resultSumVacuno)}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
                  </Col>
{/*Ovinos*/}
                  <Col md={6}>
                  <div className="form-control-label text-center" style={{color: '#000000',fontSize: '11px',width: '100%',fontWeight: '700',marginRight: '0px'}}>
                    Ovinos
                  </div>
                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Carneros</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>01</label>
                      {/*getValueSession('detail_carnero','detail_carnero_two')*/}
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_carnero==undefined)?(this.props.validSwith.detail_carnero):(this.props.validSwith.detail_carnero)}
                       onChange={(e)=>{
                        //this.setState({detail_carnero:e.target.value})
                        this.props.setValField('detail_carnero',e.target.value)
                        resultSum(e.target.value,this.props.validSwith.detail_ovejos,this.props.validSwith.detail_capones,this.props.validSwith.detail_borregas,this.props.validSwith.detail_corderas,this.props.validSwith.detail_corderos,this.props.validSwith.detail_mamones)
                     }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
                  <div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Ovejas</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>02</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_ovejos==undefined)?(this.props.validSwith.detail_ovejos):(this.props.validSwith.detail_ovejos)}
                       onChange={(e)=>{
                        this.props.setValField('detail_ovejos',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,e.target.value,this.props.validSwith.detail_capones,this.props.validSwith.detail_borregas,this.props.validSwith.detail_corderas,this.props.validSwith.detail_corderos,this.props.validSwith.detail_mamones)
                        //this.setState({detail_ovejos:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>

<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Capones</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>04</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_capones==undefined)?(this.props.validSwith.detail_capones):(this.props.validSwith.detail_capones)}
                       onChange={(e)=>{
                        this.props.setValField('detail_capones',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,this.props.validSwith.detail_ovejos,e.target.value,this.props.validSwith.detail_borregas,this.props.validSwith.detail_corderas,this.props.validSwith.detail_corderos,this.props.validSwith.detail_mamones)
                        //this.setState({detail_capones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Borregas 2-4D</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>05</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_borregas==undefined)?(this.props.validSwith.detail_borregas):(this.props.validSwith.detail_borregas)}
                       onChange={(e)=>{
                        this.props.setValField('detail_borregas',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,this.props.validSwith.detail_ovejos,this.props.validSwith.detail_capones,e.target.value,this.props.validSwith.detail_corderas,this.props.validSwith.detail_corderos,this.props.validSwith.detail_mamones)
                        //this.setState({detail_borregas:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Corderas DL</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>06</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_corderas==undefined)?(this.props.validSwith.detail_corderas):(this.props.validSwith.detail_corderas)}
                       onChange={(e)=>{
                        this.props.setValField('detail_corderas',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,this.props.validSwith.detail_ovejos,this.props.validSwith.detail_capones,this.props.validSwith.detail_borregas,e.target.value,this.props.validSwith.detail_corderos,this.props.validSwith.detail_mamones)
                        //this.setState({detail_corderas:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Corderos DL</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>07</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_corderos==undefined)?(this.props.validSwith.detail_corderos):(this.props.validSwith.detail_corderos)}
                       onChange={(e)=>{
                        this.props.setValField('detail_corderos',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,this.props.validSwith.detail_ovejos,this.props.validSwith.detail_capones,this.props.validSwith.detail_borregas,this.props.validSwith.detail_corderas,e.target.value,this.props.validSwith.detail_mamones)
                        //this.setState({detail_corderos:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>Corderos/as Mamones</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>08</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.props.validSwith.detail_mamones==undefined)?(this.props.validSwith.detail_mamones):(this.props.validSwith.detail_mamones)}
                       onChange={(e)=>{
                        this.props.setValField('detail_mamones',e.target.value)
                        resultSum(this.props.validSwith.detail_carnero,this.props.validSwith.detail_ovejos,this.props.validSwith.detail_capones,this.props.validSwith.detail_borregas,this.props.validSwith.detail_corderas,this.props.validSwith.detail_corderos,e.target.value)
                        //this.setState({detail_mamones:e.target.value})
                      }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
<div style={{alignSelf:'center',textAlign: 'center',display:'flex',marginTop: '5px'}}>
                    <div style={{margin:'auto'}}>
                      <div style={{display:'flex'}}>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '10px', width: '95px',textAlign:'justify',fontWeight: '600',marginRight: '20px'}}>TOTAL OVINOS</label>
                      <label className="form-control-label" style={{color: '#000000',fontSize: '11px', width: '22px',fontWeight: '600',marginRight: '0px'}}>10</label>
                      <Input
                      style={{textAlign: 'center',fontSize: '22px',height: '35px',borderRadius: '0px',width: '95px',color: 'black',letterSpacing: '5px'}}                    
                       value={(this.state.resultSum==undefined)?(this.props.validSwith.resultSum):(this.props.validSwith.resultSum)}
                       onChange={(e)=>{
                        //this.setState({total_ovinos:e.target.value})
                        //this.props.setValField('total_ovinos',e.target.value)
                        //resultSum()
                     }}
                       type="text"                  
                      />
                      </div>
                    </div>
                  </div>
                  </Col>
                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailSection;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '70px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  }
}