import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_trans_vehicule } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import {getContent, PutFormContentFinal} from 'views/pages/forms/LogisticsForm';

export default class FormCosechaVehicule extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    field_params:[],
    field_content:[],
    field_params_title:[],
    loadlocali:null,
    codigoAran:[],
    optionsSelect:[],
    selectForFilter:[],
    dataStatesS:0,
    content_render:null,
    return_breack:0
  }
  async componentDidMount(){
    var getData = await main_content('transport_vehicule_cosecha')
    this.setState({field_content_log:getData})
    await this.initData(this.props.putLinkId)
    if (this.props.for_delete=='delete_register') {
      this.deleteRegister(this.props.putLinkId)
    }    
  }
  initData = async (id_set)=>{
        this.setState({field_content: getContent(this.state.field_content_log).s_data})
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);

        var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
        var dataTrazabilidadvf = await data_fertilizer_production(id_set,'transport_vehicule_cosecha',JSON.stringify(getContent(this.state.field_content_log).f_data),gerParam)
        if (dataTrazabilidadvf==null) {
          var dateTime = new Date();
          this.cleanData()
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
        this.setState({field_params_title: {...this.state.field_params_title, ['register_link_id']:'Link Forms' }})
        this.setState({field_params: {...this.state.field_params, ['register_link_id']:id_set }})
  }
     componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.return_breack==0) {
            if (this.props.putLinkId!=null) {
             this.setState({field_params_title: {...this.state.field_params_title, ['register_link_id']:'Link Forms' }})
             this.setState({field_params: {...this.state.field_params, ['register_link_id']:this.props.putLinkId }})
             this.setState({return_breack:1})
            }
        }
      } 
    setDataCampos = (data)=>{

      this.setState({idT:data?.id})
      delete data.id
      delete data.count_table
      this.setState({field_params:data})
      this.state.field_content.map((a,b)=>{
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })     
    }
    cleanData = () =>{
          this.setState({idT:null})
          var date = new Date()
          var completeDateAfter = date.toLocaleDateString("es-PA",{timeZone:'America/Panama'})
          var completeDateBefore = completeDateAfter.split('/')
          var completeDate = completeDateBefore[2]+'-'+completeDateBefore[0]+'-'+completeDateBefore[1]
          var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
      this.state.field_content.map((a,b)=>{
        //console.log(a.name_field,a.label_title,a.req_field)
        this.setState({field_params: {...this.state.field_params, [a.name_field]:'' }})
        if (a.type_field=='date') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:completeDate }})
        }
        if (a.type_field=='time') {
          this.setState({field_params: {...this.state.field_params, [a.name_field]:timeString }})
        }
        this.setState({field_params_title: {...this.state.field_params_title, [a.name_field]:a.label_title }})
        this.setState({field_params_required: {...this.state.field_params_required, [a.name_field]:a.req_field }})
        if (a.type_field=='select') {
          this.selectOptions(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field])
          if (a.list_default=='ldp') {
            this.selectOptionsState(a.list_default,a.value_option,a.name_field,a.req_field,this.state.field_params[a.name_field],'name')        
          }
        }
      })   
    }
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranF})
      }
      this.setState({counter:this.state.counter+1})
  }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
         setinfolocation = (lat,lon,data,optcon) => {
              this.setState({loadlocali:null})
              this.state.field_content.map((val,ind)=>{
                if (val.link!=null) {
                  var get_csc=val.link.split('.')[1]
                  if (get_csc=='vgp') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.country) }})
                  }
                  if (get_csc=='vge') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.state) }})
                  }
                  if (get_csc=='vgc') {
                    this.setState({field_params: {...this.state.field_params, [val.name_field]:String(data.results[0].components.city) }})
                  }
                }
                if (val.select_geo=="geo_country") {
                  if (val.list_default=='ldp') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code')
                  }
                  if (val.list_default=='lde') {
                    this.setFieldValueSelect(String(data.results[0].components.country),val.name_field,val.req_field,String(data.results[0].components.country_code),val.list_default,'code',String(data.results[0].components.state))
                  }
                }
              })
              this.setFieldValue(lat+','+lon+'-'+data.results[0].formatted,optcon.id,optcon)
    }
     contentForSelect =(data,field_name,isrequerid,list_default)=>{
      var content = []
      if (data!=null) {
        var data_obj = data
        var count = -1
          if (list_default==null || list_default=='' || list_default=='0') {
            var data_default_save = data_obj.split('|')
            for (var i = 0; i < data_default_save.length; i++) {
              content[i] = {value:data_default_save[i],id:field_name,isrequired:isrequerid,name:data_default_save[i]}
            }
          }else{
            for (var i = 0; i < data_obj.length; i++) {
              count++
              if (list_default=='ldp') {
                content[i] = {name:data_obj[i].Name,value:data_obj[i].Name,id:field_name,isrequired:isrequerid,code:data_obj[i].Code,default:list_default}
              }
              if (list_default=='lde') {
                content[i] = {name:data_obj[i],value:data_obj[i],id:field_name,isrequired:isrequerid,code:data_obj[i]}
              }              
              if (list_default=='cafv' || list_default=='cac') {
                content[i] = {name:data_obj[i].Codigo+' '+data_obj[i].Titulo,value:data_obj[i].Codigo+' '+data_obj[i].Titulo,id:field_name,isrequired:isrequerid}
              }          
            }
          }
      }
      return content
    }
     getFieldValue = (field,isrequired,name,value_option,list_default) => {
      this.selectOptions(list_default,value_option,field,isrequired,this.state.field_params[field])
      this.selectOptionsState(list_default,value_option,field,isrequired,this.state.field_params[field],'name')
      if (this.state.field_params[field]==undefined) {
        this.setState({field_params: {...this.state.field_params, [field]:'' }})
        this.setState({field_params_title: {...this.state.field_params_title, [field]:name }})
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
        return
      }
    }

     setFieldValueSelect = (value, field, isrequired,code_country,list_default,type_cn,if_states) => {
        this.setState({dataStatesS:0})
        this.selectOptionsState(list_default,null,field,isrequired,code_country,type_cn,if_states)
          
          if (if_states==undefined) {
            this.setState({field_params: {...this.state.field_params, [field]:value }})
          }
        if (list_default=='lde') {
          if (if_states!=undefined) {
            var value_for_states = this.state.optionsSelect[field].filter((a,b) => if_states.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(a.value.toLowerCase())!=-1)
            this.setState({field_params: {...this.state.field_params, [field]:value_for_states[0].value }})
          }
      }
        this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired }})
    }
     setFieldValue = (value, field, isrequired) =>{
       this.setState({field_params: {...this.state.field_params, [field]:value }})
       this.setState({field_params_required: {...this.state.field_params_required, [field]:isrequired.getAttribute('isrequired') }})
      if (isrequired.getAttribute('data_filter')!=null) {
        isrequired.getAttribute('data_filter').split(',').map((a,b)=>{
          if (a!='upf') {
            //this.setState({field_params: {...this.state.field_params, [a]:value }})
            Object.entries(this.state.selectForFilter).map((val,indx)=>{
              if (val[0]==a) {
                var get_results = val[1].filter((fila,filindx) => fila.value.indexOf(value)!=-1)
                this.setState({optionsSelect: {...this.state.optionsSelect, [val[0]]:get_results }})
              }
            })
          }
        })
      }
    }
     selectOptionsState = (list_default,value_option,name_field,req_field,for_values,type,if_states) =>{
          var dataState=[]
          if (list_default=='ldp') {
            if (for_values!=undefined && for_values!='') {
               if (this.state.field_content.length>0) {
                 for (var i = 0; i < this.state.field_content.length; i++) {
                   if (this.state.field_content[i].list_default!=null) {
                     if (this.state.field_content[i].list_default=='lde') {
                      if (type=='name') {
                        dataState = stateData.filter((a,b) => a.country.indexOf(for_values)!=-1 )
                      }
                      if (type=='code') {
                        dataState = stateData.filter((a,b) => a.alpha2Code.indexOf(for_values.toUpperCase())!=-1 )
                      }
                       if (dataState.length>0) {
                        this.setState({optionsSelect: {...this.state.optionsSelect, [this.state.field_content[i].name_field]:this.contentForSelect(dataState[0].states,this.state.field_content[i].name_field,this.state.field_content[i].req_field,this.state.field_content[i].list_default) }})
                       }
                     }
                   }
                 }
               }
            }
          }
    }

     selectOptions = (list_default,value_option,name_field,req_field,for_values) =>{
      if (this.state.optionsSelect[name_field]==undefined) {
        if (list_default=='ldp') {
          var var_data = []
          var_data =this.contentForSelect(this.listCountryCode,name_field,req_field,list_default)
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:var_data }})
        }
        if (list_default=='cafv') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranF,name_field,req_field,list_default) }})
        }
        if (list_default=='cac') {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(CodigoAranM,name_field,req_field,list_default) }})
        }
        if (list_default==null) {
          this.setState({optionsSelect: {...this.state.optionsSelect, [name_field]:this.contentForSelect(value_option,name_field,req_field,list_default) }})
        }
      }
      this.setState({selectForFilter: this.state.optionsSelect})
    }
   listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
     listCountryCode = CountrysData.sort(this.listaArray)
  render(){
  return (
    <> 

          <Row>
            <Col md={12}>
            <h3 className="mb-0">Vehiculo</h3>
            </Col>
            </Row>

            <Row>
            <PutFormContentFinal 
            content={this.state.field_content}
            field_params={this.state.field_params}
            optionsSelect={this.state.optionsSelect}
            setFieldValue={this.setFieldValue}
            setFieldValueSelect={this.setFieldValueSelect}
             />
            </Row>
    </>
  );
 }
}
