import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes,get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import VistaTraceProd from "views/pages/examples/Production/VistaTraceProViewTable";

export default class TraceViewProduction extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    setinfoState:false,
    collapse:[]
  }
 async componentDidMount(){
  this.context.getAffiliates()
  }
 setInfoInit = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
          var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('P')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
            if (dataPermission.type_traza==null) {
          jQuery('#tg').remove()
          return
        }else{
          if (dataPermission.type_traza.indexOf("P")==-1) {
            jQuery('#tg').remove()
          }
        }
        var thisExtends = this
  var data = await data_list_processes(jsondata.user_id_origin,dataPermission.user_id_connection,dataPermission.type,'tvt',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
    data_nama_g:data.nama_grain[1],
    data_nama_g_th:data.nama_grain[0],
  })
      }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }
  setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data.length!=0) {
    var content=[]
      data.map((a,b)=>{
        var get_codigo = null
         var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/vista-trace-admin-grain-nama"
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/vista-trace-admin"
              }
            }
            if (val[0]=='codigo') {
              get_codigo=val[1]
            }
 var content_two = <>
  {(val[0]!='codigo')?(<td ><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='codigo')?(<><td ><span className="text-muted">{val[1]}</span></td><td><ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:url_selected,
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver en Producción
                     </ComponentLink>
                     <br />
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/prouction-observations-mvr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver Todas las Observaciones
                     </ComponentLink>
{/*                    <br />
                    <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.toggleFunction(b,this.state.collapse[val[1]],val[1])}}>
                      Desplegar para ver mas detalle
                    </button>
                  */}
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <>
                    <tr>
                    {content_one}
                    </tr>
                        <tr>
                          <td colspan="5">
                          <Collapse isOpen={this.state.collapse[get_codigo]}>
                          <div style={{width: '56%'}}>
                            <VistaTraceProd code={this.state.codeTTV}/>
                          </div>
                          </Collapse>
                          </td>
                        </tr>
                    </>
      })
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }
render(){
  this.setInfoInit()
  return (
    <>
      <SimpleHeader name="Producción" parentName="Producción" section="Producción" section_two="Tabla" section_table_form="table_view_trace" />
        <Container className="mt--6" fluid>
        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Tabla Producción Resumen de Actividades</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_nama_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th >Opciones</th>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_nama_g)}
            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

//export default TraceViewProduction;
