import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

 class ModalStartLogistics extends React.Component {
      state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModalFrom:false,
    sendOpenModalExport:false,
  }
    async componentDidMount(){
        
  }
          setDataCamposFrom = (data)=>{
              var date = new Date();
                        var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
     if (data!=null) {
        this.setState({
                transport_id:(data.transport.id=="null")?(null):(data.transport.id),
                seleccionar_ruta:(data.transport.seleccionar_ruta=="null")?(null):(data.transport.seleccionar_ruta),
                otra_ruta:(data.transport.otra_ruta=="null")?(null):(data.transport.otra_ruta),
                fecha_salida:(data.transport.fecha_salida=="null")?(null):(data.transport.fecha_salida),
                hora_salida:(data.transport.hora_salida=="null")?(null):(data.transport.hora_salida),
                guia_numero:(data.transport.guia_numero=="null")?(null):(data.transport.guia_numero),
                precinto_numero:(data.transport.precinto_numero=="null")?(null):(data.transport.precinto_numero),
                nombre_chofer:( data.transport.nombre_chofer=="null")?(null):( data.transport.nombre_chofer),
                cedula:(data.transport.cedula=="null")?(null):(data.transport.cedula),
                id_chofer:(data.transport.id_chofer=="null")?(null):(data.transport.id_chofer),
                tipo_camion:(data.transport.tipo_camion=="null")?(null):(data.transport.tipo_camion),
                placa_camion:(data.transport.placa_camion=="null")?(null):(data.transport.placa_camion),
                vehiculo_desinfectado:(data.transport.vehiculo_desinfectado=="null")?(null):(data.transport.vehiculo_desinfectado),
                producto_utilizado:(data.transport.producto_utilizado=="null")?(null):(data.transport.producto_utilizado),
                informacion_adicional_transport:(data.transport.informacion_adicional=="null")?(null):(data.transport.informacion_adicional),
                file_document_trasport:(data.transport.archivo=="null")?(null):(data.transport.archivo),
        })
      }else{
          this.setState({
                transport_id:'noind',
                seleccionar_ruta:'',
                otra_ruta:'',
                fecha_salida:completeDate,
                hora_salida:timeString,
                guia_numero:'',
                precinto_numero:'',
                nombre_chofer:'',
                cedula:'',
                id_chofer:'',
                tipo_camion:'',
                placa_camion:'',
                vehiculo_desinfectado:'',
                producto_utilizado:'',
                informacion_adicional_transport:'',
                file_document_trasport:undefined,
        })
      }
    }
   setDataCamposExport = (data)=>{
                    var date = new Date();
                        var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

      if (data.export!=undefined) {
        this.setState({
                export_id:(data.export.id=="null")?(null):(data.export.id),
                modo_transporte:(data.export.modo_transporte=="null")?(null):(data.export.modo_transporte),
                fecha_carga:(data.export.fecha_carga=="null")?(completeDate):(data.export.fecha_carga),
                nro_documento:(data.export.nro_documento=="null")?(null):(data.export.nro_documento),
                contenedor_numero:(data.export.contenedor_numero=="null")?(null):(data.export.contenedor_numero),
                sello_numero:(data.export.sello_numero=="null")?(null):(data.export.sello_numero),
                unidad_carga:(data.export.unidad_carga=="null")?(null):(data.export.unidad_carga),
                total_unidades:(data.export.total_unidades=="null")?(null):(data.export.total_unidades),
                informacion_adicional_export:(data.export.informacion_adicional=="null")?(null):(data.export.informacion_adicional),
                file_document_export:(data.export.archivo=="null")?(null):(data.export.archivo),
        })
      }else{
            this.setState({

                export_id:'noind',
                modo_transporte:'',
                fecha_carga:completeDate,
                nro_documento:'',
                contenedor_numero:'',
                sello_numero:'',
                unidad_carga:'',
                total_unidades:'',
                informacion_adicional_export:'',
                file_document_export:undefined,
            })
            this.setState({
                fecha_carga:completeDate,
            })

      }
    }

  render(){
      const saveInfoTransportFrom = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messf:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    

      if (this.state.guia_numero=="") {
      this.setState({messf:'El Guia Numero es necesario.'})
      this.setState({btnd:false})
      }else{
        const var_request_trasport = await logistic_create_trasport(this.state,jsondata._id,gerParam);
        //const var_request = await logistic_create_export(this.state,jsondata._id,var_request_trasport.trasport_id,gerParam);
        if (var_request_trasport.message=="exito") {
          this.setState({messf:'Se guardo exitosamente.'})
          this.setState({messf:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          /*if (this.props.setParamsUrl==null) {
            setTimeout(function(){window.location.reload()}, 3000);
          }*/
          this.setState({btnd:false})
            this.props.refreshData()
            this.setState({transport_id:var_request_trasport.trasport_id})
            //this.setState({export_id:var_request.export_id})
            //setInfoTransFrom(this.props.sendInfoProps.dataTransportAll,true)
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_trasport);
          this.setState({btnd:false})
        }
      }
  }
const saveInfoTransportExport = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messe:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    

      if (this.state.nro_documento=="") {
      this.setState({messe:'El Nro de Documento es necesario.'})
      this.setState({btnd:false})
      }else{
        //const var_request_trasport = await logistic_create_trasport(this.state,jsondata._id,gerParam);
        const var_request = await logistic_create_export(this.state,jsondata._id,gerParam);
        if (var_request.message=="exito") {
          this.setState({messe:'Se guardo exitosamente.'})
          this.setState({messe:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          /*if (this.props.setParamsUrl==null) {
            setTimeout(function(){window.location.reload()}, 3000);
          }*/
          this.setState({btnd:false})
            this.props.refreshData()
            //this.setState({transport_id:var_request_trasport.trasport_id})
            this.setState({export_id:var_request.export_id})
            //setInfoTransFrom(this.props.sendInfoProps.dataTransportAll,true)
        }else{
          notify("danger", "¡Registrado Fallido!", var_request);
          this.setState({btnd:false})
        }
      }
  }
       const setInfoTransFrom = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          if (id_set==null) {
            this.setDataCamposFrom(null)
          }else{
          var dataTransExportLogisc = await data_logstic_transport(this.props.sendInfoProps.dataTransportAll,id_set)
          this.setDataCamposFrom(dataTransExportLogisc)
          }
          this.setState({
            sendOpenModalFrom:verify,
          })
    }
       const setInfoTransExport = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");              
          var dataTransExportLogisc = await data_logstic_export(this.props.sendInfoProps.dataTransportAll,id_set)    
          this.setDataCamposExport(dataTransExportLogisc)
          this.setState({
            sendOpenModalExport:verify,
          })
    }
const deleteTransExportFuncion = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteTransExport(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("success", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
const deleteTransFromFuncion = async (id) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteTransFrom(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("success", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>

    <Modal isOpen={this.state.sendOpenModalFrom} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Transporte desde</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoTransFrom(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                  Seleccionar Ruta:
                  </label>
                  <Input
                    value={(this.state.seleccionar_ruta=="undefined")?(null):(this.state.seleccionar_ruta)}
                    onChange={(e)=>{this.setState({seleccionar_ruta:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="Transporte de Campo a Frigorífico">Transporte de Campo a Frigorífico</option>
                    <option value="Transporte de Campo a Compartimento">Transporte de Campo a Compartimento</option>
                    <option value="Transporte de Compartimento a Frigorífico">Transporte de Compartimento a Frigorífico</option>
                    <option value="otro">Otro</option>
                  </Input>
                </FormGroup>
              </Col>
              {(this.state.seleccionar_ruta!='otro')?(null):(
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Otra ruta
                  </label>
                  <Input
                    value={(this.state.otra_ruta=="undefined")?(null):(this.state.otra_ruta)}
                    onChange={(e)=>{this.setState({otra_ruta:e.target.value})}}
                    
                    placeholder="Escribe una Ruta"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
                )}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Salida
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_salida:e.target.value})}}
                    defaultValue={(this.state.fecha_salida=="undefined")?(null):(this.state.fecha_salida)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Salida
                  </label>
                  <Input
                    value={(this.state.hora_salida=="undefined")?(null):(this.state.hora_salida)}
                    onChange={(e)=>{this.setState({hora_salida:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Guia Numero
                  </label>
                  <Input
                    value={(this.state.guia_numero=="undefined")?(null):(this.state.guia_numero)}
                    onChange={(e)=>{this.setState({guia_numero:e.target.value})}}
                    
                    placeholder="Escribe un Numero"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Precinto Numero
                  </label>
                  <Input
                    value={(this.state.precinto_numero=="undefined")?(null):(this.state.precinto_numero)}
                    onChange={(e)=>{this.setState({precinto_numero:e.target.value})}}
                    
                    placeholder="Escribe un número de precinto"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre del Chofer
                  </label>
                  <Input
                    value={(this.state.nombre_chofer=="undefined")?(null):(this.state.nombre_chofer)}
                    onChange={(e)=>{this.setState({nombre_chofer:e.target.value})}}
                    
                    placeholder="Escribe el Nombre del chofer"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Cédula
                  </label>
                  <Input
                    value={(this.state.cedula=="undefined")?(null):(this.state.cedula)}
                    onChange={(e)=>{this.setState({cedula:e.target.value})}}
                    
                    placeholder="Escribe la cédula del chofer"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    ID del Chofer
                  </label>
                  <Input
                    value={(this.state.id_chofer=="undefined")?(null):(this.state.id_chofer)}
                    onChange={(e)=>{this.setState({id_chofer:e.target.value})}}
                    
                    placeholder="Escribe el ID del chofer"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Tipo/Marca de Camion
                  </label>
                  <Input
                    value={(this.state.tipo_camion=="undefined")?(null):(this.state.tipo_camion)}
                    onChange={(e)=>{this.setState({tipo_camion:e.target.value})}}
                    
                    placeholder="Escribe el tipo de camion"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Placa del Camion
                  </label>
                  <Input
                    value={(this.state.placa_camion=="undefined")?(null):(this.state.placa_camion)}
                    onChange={(e)=>{this.setState({placa_camion:e.target.value})}}
                    
                    placeholder="Escribe la Placa"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                  Vehiculo desinfectado
                  </label>
                  <Input
                    value={(this.state.vehiculo_desinfectado=="undefined")?(null):(this.state.vehiculo_desinfectado)}
                    onChange={(e)=>{this.setState({vehiculo_desinfectado:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
{/*              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Producto Utilizado
                  </label>
                  <Input
                    value={(this.state.producto_utilizado=="undefined")?(null):(this.state.producto_utilizado)}
                    onChange={(e)=>{this.setState({producto_utilizado:e.target.value})}}
                    
                    placeholder="Escribe un producto utilizado"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>*/}
              <Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                    Producto Utilizado
                                    </label>
                                    <Input
                                      value={(this.state.producto_utilizado=="undefined")?(null):(this.state.producto_utilizado)}
                                      onChange={(e)=>{this.setState({producto_utilizado:e.target.value})}}
                                      id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="Cloro">Cloro</option>
                                      <option value="Solución Clorada 5%">Solución Clorada 5%</option>
                                      <option value="Producto 1">Producto 1</option>
                                      <option value="Producto 2">Producto 2</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_transport=="undefined")?(null):(this.state.informacion_adicional_transport)}
                    onChange={(e)=>{this.setState({informacion_adicional_transport:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_trasport:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_trasport==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_trasport}>{String(this.state.file_document_trasport).split('_').pop()}</a>)}

              </Col>
            </Row>
            {this.state.messf ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messf}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoTransportFrom}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoTransFrom(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


              <Modal isOpen={this.state.sendOpenModalExport} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Transporte Exportación</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoTransExport(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Modo de Transtporte
                  </label>
                  <Input
                    value={(this.state.modo_transporte=="undefined")?(null):(this.state.modo_transporte)}  
                    id="exampleFormControlSele"
                    onChange={(e)=>{this.setState({modo_transporte:e.target.value})}} id="exampleFormControlSelect1" type="select">
                  <option value="0">Seleccione...</option>
                    <option value="Aereo">Aereo</option>
                    <option value="Maritimo">Maritimo</option>
                    <option value="Terrestre">Terrestre</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Carga
                  </label>
                  <Input
                    defaultValue={(this.state.fecha_carga=="undefined")?(null):(this.state.fecha_carga)}
                    onChange={(e)=>{this.setState({fecha_carga:e.target.value})}}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nro de Documento
                  </label>
                  <Input
                    value={(this.state.nro_documento=="undefined")?(null):(this.state.nro_documento)}
                    onChange={(e)=>{this.setState({nro_documento:e.target.value})}}
                    
                    placeholder="Escribe un Numero"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Contenedor Numero
                  </label>
                  <Input
                    value={(this.state.contenedor_numero=="undefined")?(null):(this.state.contenedor_numero)}
                    onChange={(e)=>{this.setState({contenedor_numero:e.target.value})}}
                    
                    placeholder="Escribe un Numero"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Sello Numero
                  </label>
                  <Input
                    value={(this.state.sello_numero=="undefined")?(null):(this.state.sello_numero)}
                    onChange={(e)=>{this.setState({sello_numero:e.target.value})}}
                    
                    placeholder="Escribe un Numero"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
            <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Unidad de Carga
                  </label>
                  <Input
                    value={(this.state.unidad_carga=="undefined")?(null):(this.state.unidad_carga)}
                    onChange={(e)=>{this.setState({unidad_carga:e.target.value})}}
                    
                    placeholder="Escribe la unidad de carga"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Total Unidades
                  </label>
                  <Input
                    value={(this.state.total_unidades=="undefined")?(null):(this.state.total_unidades)}
                    onChange={(e)=>{this.setState({total_unidades:e.target.value})}}
                    
                    placeholder="Escribe el total de unidades"
                    type="text"
                    autoComplete='none'
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_export=="undefined")?(null):(this.state.informacion_adicional_export)}
                    onChange={(e)=>{this.setState({informacion_adicional_export:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_export:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_export==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_export}>{String(this.state.file_document_export).split('_').pop()}</a>)}

              </Col>
            </Row>

           {this.state.messe ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messe}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoTransportExport}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoTransExport(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar Datos Transporte
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoTransFrom(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataTransport.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                          <Col md="12 text-right">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                         <Col md="12">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.seleccionar_ruta=="null") ? ("Sin ruta") : (value.seleccionar_ruta)}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoTransFrom(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteTransFromFuncion(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>

        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar Datos Transporte Exportación
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoTransExport(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {this.props.sendInfoProps.dataTransportExport.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.nro_documento=="null") ? ("Sin número") : (value.nro_documento)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoTransExport(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteTransExportFuncion(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

export default ModalStartLogistics;
