import React, {useState,useEffect} from 'react';
import { get_permission, get_permission_main } from "network/ApiAxios";
export const UserContext = React.createContext([]);

export const DataProvider =  ({children}) => {
	const [valueGlobal, setValueGlobal] = useState([])
	const [valueGuide, setValueGuide] = useState([])
	const [valuePanel, setValuePanel] = useState([])
	const [valueGlobalMain, setValueGlobalMain] = useState([])
	const [valueDataMain, setValueDataMain] = useState([])
	const [valueCountNotifi, setvalueCountNotifi] = useState(0)
	
	var userdata = localStorage.getItem('user');
	var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(window.location.search).get("company");
    var idcompany = null;
    if (jsondata.userType==2) {
      if (gerParam!=null) {
        idcompany = gerParam
      }
    }
  const getAffiliates = async ()=>{
	    var dataPermission = await get_permission(jsondata._id,idcompany)
	    localStorage.setItem('colab_id',String(dataPermission[0].user_id_connection))
	    var dataPermissionMain = await get_permission_main(jsondata._id)
	    setValueDataMain(dataPermissionMain.datosPermission)
	    setValueGlobal(dataPermission[0])
	    setValueGuide(dataPermission[1])
	    setValuePanel(dataPermission[2])
	    setValueGlobalMain(dataPermissionMain.notifications)
	    setvalueCountNotifi(dataPermissionMain.notificationsCount)
  }

  useEffect(()=> {
    getAffiliates()
  },[])
	return (

			    <UserContext.Provider value={{
			    	valueGlobal,
			    	setValueGlobal,
			    	valueDataMain,
			    	valueGlobalMain,
			    	setValueGlobalMain,
			    	valueCountNotifi, 
			    	setvalueCountNotifi,
			    	valueGuide,
			    	valuePanel,
			    	getAffiliates
			      }}>
				    {/*(valueGlobal.length==0)?('Buscando permisos...'):()*/}
			    	{children}
    			</UserContext.Provider>
		)
}