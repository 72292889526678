import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, data_trazabilidad, main_content, data_fertilizer_production, fertilizer_production, delete_fertilizer } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import FormCosechaTransport from "./Subs/FormCosecha/FormCosechaTransport";
import FormCosechaVehicule from "./Subs/FormCosecha/FormCosechaVehicule";
import {GuideMessage} from "views/pages/components/GuideMessage";

export default class ModalFertilizer extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    link_id:null,
    delete_register:null,
    get_content:[],
    get_first_info:'',
    userTypeH:1
  }
async componentDidMount(){
  var getData = await main_content('transport_cosecha')
  getData.map((a,b)=>{
    if (a.include_info==1) {
      this.setState({get_first_info:a.name_field})
    }
  })
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  this.setState({userTypeH:jsondata.userType})
  this.setState({get_content:getData})
}
  initData = async (id_set,verify)=>{
    this.setState({sendOpenModal:verify,link_id:id_set})
  }
deleteRegister = async (id_set) => {
  await this.initData(id_set,true)
   if (window.confirm("Deseas eliminar este registro?")) {
    this.setState({delete_register:'delete_register'})
//     var field_data = Object.entries(this.state.field_params)
//     var field_data_name = Object.entries(this.state.field_params_title)
// console.log(field_data)
    // var dataReturn = await delete_fertilizer(id_set,jsondata.user_id_origin,field_data,field_data_name,gerParam,'transport_cosecha','Datos Fertilizantes / Agroquímicos')
    //         if (dataReturn=="exito") {
    //         this.setState({mess:'Se eliminó exitosamente.'})
    //         this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
    //         this.props.refreshData()
    //       }else{
    //         this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
    //         this.setState({btnd:false})
    //       }
  }
  this.initData(id_set,false)
 }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
     this.props.refreshData()
  }
  deleteRegisterListen = async (id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name) => {
      var dataReturn = await delete_fertilizer(id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name)
      this.setState({delete_register:null})
              if (dataReturn=="exito") {
              this.setState({mess:'Se eliminó exitosamente.'})
              this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
              this.props.refreshData()
            }else{
              this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
              this.setState({btnd:false})
            }    
  }
  render(){
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>

    <Modal isOpen={this.state.sendOpenModal} size="xl">
        <ModalBody>
        <FormCosechaTransport notify={this.notify} {...this.props} get_content={this.state.get_content} deleteRegisterListen={this.deleteRegisterListen} for_delete={this.state.delete_register} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal}/>
        <FormCosechaVehicule notify={this.notify} {...this.props} deleteRegisterListen={this.deleteRegisterListen} for_delete={this.state.delete_register} putLinkId={this.state.link_id}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.initData(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
             <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar Datos Transporte de Campo
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ this.initData(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataTransport.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >

                                             {Object.entries(value).map((a,b)=>{
                                              if (a[0]==this.state.get_first_info) {
                                                return a[1]
                                              }
                                             })}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => this.initData(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                                          {(this.state.userTypeH==1)?(
                                             <Col md="6">
                                             <Button 
                                               className="btn-icon" 
                                               type="button"
                                               onClick={() => this.deleteRegister(value.id)}>
                                                 <span className="btn-inner--icon mr-1">
                                                   <i className="fas fa-trash" />
                                                 </span>
                                               </Button>
                                             </Col>
                                            ):(null)}

                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

