import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import { NavLink as ComponentLink } from "react-router-dom";

//function Elements() {
class ViewPermision extends React.Component {
async componentDidMount(){
}
render(){
  return (
    <>
      <SimpleHeader name="Acceso denegado" parentName="Acceso denegado" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Sin permiso</h3>
          </CardHeader>
          <CardBody>
            Acceso denegado. Contáctanos para más información.
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      
    </>
  );
 }
}

export default ViewPermision;
