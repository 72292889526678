export const CodigoAranF = [
 {
  "Codigo": "060021000090",
  "Titulo": "CAPÍTULO 6 - PLANTAS VIVAS Y PRODUCTOS DE LA FLORICULTURA"
 },
 {
  "Codigo": "060100000080",
  "Titulo": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en reposo vegetativo, en vegetación o en flor; plantas y raíces de achicoria, excepto las raíces de la partida 1212"
 },
 {
  "Codigo": "060110000080",
  "Titulo": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en reposo vegetativo"
 },
 {
  "Codigo": "060110100080",
  "Titulo": "Jacintos"
 },
 {
  "Codigo": "060110200080",
  "Titulo": "Narcisos"
 },
 {
  "Codigo": "060110300080",
  "Titulo": "Tulipanes"
 },
 {
  "Codigo": "060110400080",
  "Titulo": "Gladiolos"
 },
 {
  "Codigo": "060110900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060120000080",
  "Titulo": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en vegetación o en flor; plantas y raíces de achicoria"
 },
 {
  "Codigo": "060120100080",
  "Titulo": "Plantas y raíces de achicoria"
 },
 {
  "Codigo": "060120300080",
  "Titulo": "Orquídeas, jacintos, narcisos y tulipanes"
 },
 {
  "Codigo": "060120900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060200000080",
  "Titulo": "Las demás plantas vivas (incluidas sus raíces), esquejes e injertos; micelios"
 },
 {
  "Codigo": "060210000080",
  "Titulo": "Esquejes sin enraizar e injertos"
 },
 {
  "Codigo": "060210100080",
  "Titulo": "De vid"
 },
 {
  "Codigo": "060210900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060220000080",
  "Titulo": "Árboles, arbustos y matas, de frutas o de otros frutos comestibles, incluso injertados"
 },
 {
  "Codigo": "060220100080",
  "Titulo": "Plantas de vid, injertadas o con raíces (barbados)"
 },
 {
  "Codigo": "060220200010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060220200080",
  "Titulo": "Con raíces desnudas"
 },
 {
  "Codigo": "060220300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060220300080",
  "Titulo": "Agrios (cítricos)"
 },
 {
  "Codigo": "060220800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060230000080",
  "Titulo": "Rododendros y azaleas, incluso injertados"
 },
 {
  "Codigo": "060240000080",
  "Titulo": "Rosales, incluso injertados"
 },
 {
  "Codigo": "060290000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290100080",
  "Titulo": "Micelios"
 },
 {
  "Codigo": "060290200080",
  "Titulo": "Plantas de piña (ananá)"
 },
 {
  "Codigo": "060290300080",
  "Titulo": "Plantas de hortalizas y plantas de fresas"
 },
 {
  "Codigo": "060290410010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290410020",
  "Titulo": "Plantas de exterior"
 },
 {
  "Codigo": "060290410030",
  "Titulo": "Árboles, arbustos y matas de tallo leñoso"
 },
 {
  "Codigo": "060290410080",
  "Titulo": "Forestales"
 },
 {
  "Codigo": "060290450010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290450080",
  "Titulo": "Esquejes enraizados y plantas jóvenes"
 },
 {
  "Codigo": "060290460010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290460080",
  "Titulo": "Con raíces desnudas"
 },
 {
  "Codigo": "060290470010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290470080",
  "Titulo": "Coníferas y plantas de hoja perenne"
 },
 {
  "Codigo": "060290480080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060290500080",
  "Titulo": "Las demás plantas de exterior"
 },
 {
  "Codigo": "060290700010",
  "Titulo": "Plantas de interior"
 },
 {
  "Codigo": "060290700080",
  "Titulo": "Esquejes enraizados y plantas jóvenes (excepto las cactáceas)"
 },
 {
  "Codigo": "060290910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "060290910080",
  "Titulo": "Plantas de flores, en capullo o en flor (excepto las cactáceas)"
 },
 {
  "Codigo": "060290990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "060300000080",
  "Titulo": "Flores y capullos, cortados para ramos o adornos, frescos, secos, blanqueados, teñidos, impregnados o preparados de otra forma"
 },
 {
  "Codigo": "060311000010",
  "Titulo": "Frescos"
 },
 {
  "Codigo": "060311000080",
  "Titulo": "Rosas"
 },
 {
  "Codigo": "060312000080",
  "Titulo": "Claveles"
 },
 {
  "Codigo": "060313000080",
  "Titulo": "Orquídeas"
 },
 {
  "Codigo": "060314000080",
  "Titulo": "Crisantemos"
 },
 {
  "Codigo": "060315000080",
  "Titulo": "Azucenas (Lilium spp.)"
 },
 {
  "Codigo": "060319000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060319100080",
  "Titulo": "Gladiolos"
 },
 {
  "Codigo": "060319200080",
  "Titulo": "Ranúnculos"
 },
 {
  "Codigo": "060319700080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060390000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060400000080",
  "Titulo": "Follaje, hojas, ramas y demás partes de plantas, sin flores ni capullos, y hierbas, musgos y líquenes, para ramos o adornos, frescos, secos, blanqueados, teñidos, impregnados o preparados de otra forma"
 },
 {
  "Codigo": "060420000080",
  "Titulo": "Frescos"
 },
 {
  "Codigo": "060420110010",
  "Titulo": "Musgos y líquenes"
 },
 {
  "Codigo": "060420110080",
  "Titulo": "Liquen de los renos"
 },
 {
  "Codigo": "060420190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060420200080",
  "Titulo": "Árboles de Navidad"
 },
 {
  "Codigo": "060420400080",
  "Titulo": "Ramas de coníferas"
 },
 {
  "Codigo": "060420900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060490110010",
  "Titulo": "Musgos y líquenes"
 },
 {
  "Codigo": "060490110080",
  "Titulo": "Liquen de los renos"
 },
 {
  "Codigo": "060490190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060490910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "060490910080",
  "Titulo": "Simplemente secos"
 },
 {
  "Codigo": "060490990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070021000090",
  "Titulo": "CAPÍTULO 7 - HORTALIZAS, PLANTAS, RAÍCES Y TUBÉRCULOS ALIMENTICIOS"
 },
 {
  "Codigo": "070100000080",
  "Titulo": "Patatas (papas) frescas o refrigeradas"
 },
 {
  "Codigo": "070110000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "070190000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070190100080",
  "Titulo": "Que se destinen a la fabricación de fécula"
 },
 {
  "Codigo": "070190500010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070190500080",
  "Titulo": "Tempranas, del 1 de enero al 30 de junio"
 },
 {
  "Codigo": "070190900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070200000080",
  "Titulo": "Tomates frescos o refrigerados"
 },
 {
  "Codigo": "070300000080",
  "Titulo": "Cebollas, chalotes, ajos, puerros y demás hortalizas aliáceas, frescos o refrigerados"
 },
 {
  "Codigo": "070310000080",
  "Titulo": "Cebollas y chalotes"
 },
 {
  "Codigo": "070310110010",
  "Titulo": "Cebollas"
 },
 {
  "Codigo": "070310110080",
  "Titulo": "Para simiente"
 },
 {
  "Codigo": "070310190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070310900080",
  "Titulo": "Chalotes"
 },
 {
  "Codigo": "070320000080",
  "Titulo": "Ajos"
 },
 {
  "Codigo": "070390000080",
  "Titulo": "Puerros y demás hortalizas aliáceas"
 },
 {
  "Codigo": "070400000080",
  "Titulo": "Coles, incluidos los repollos, coliflores, coles rizadas, colinabos y productos comestibles similares del género Brassica, frescos o refrigerados"
 },
 {
  "Codigo": "070410000080",
  "Titulo": "Coliflores y brócolis"
 },
 {
  "Codigo": "070410100080",
  "Titulo": "Coliflores y brócolis «broccoli»"
 },
 {
  "Codigo": "070410900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070420000080",
  "Titulo": "Coles (repollitos) de Bruselas"
 },
 {
  "Codigo": "070490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070490100080",
  "Titulo": "Coles blancas y rojas (lombardas)"
 },
 {
  "Codigo": "070490900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070500000080",
  "Titulo": "Lechugas (Lactuca sativa) y achicorias, comprendidas la escarola y la endibia (Cichorium spp.), frescas o refrigeradas"
 },
 {
  "Codigo": "070511000010",
  "Titulo": "Lechugas"
 },
 {
  "Codigo": "070511000080",
  "Titulo": "Repolladas"
 },
 {
  "Codigo": "070519000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070521000010",
  "Titulo": "Achicorias, comprendidas la escarola y la endibia"
 },
 {
  "Codigo": "070521000080",
  "Titulo": "Endibia witloof (Cichorium intybus var. foliosum)"
 },
 {
  "Codigo": "070529000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070600000080",
  "Titulo": "Zanahorias, nabos, remolachas para ensalada, salsifíes, apionabos, rábanos y raíces comestibles similares, frescos o refrigerados"
 },
 {
  "Codigo": "070610000080",
  "Titulo": "Zanahorias y nabos"
 },
 {
  "Codigo": "070690000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070690100080",
  "Titulo": "Apionabos"
 },
 {
  "Codigo": "070690300080",
  "Titulo": "Rábanos rusticanos (Cochlearia armoracia)"
 },
 {
  "Codigo": "070690900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070700000080",
  "Titulo": "Pepinos y pepinillos, frescos o refrigerados"
 },
 {
  "Codigo": "070700050080",
  "Titulo": "Pepinos"
 },
 {
  "Codigo": "070700900080",
  "Titulo": "Pepinillos"
 },
 {
  "Codigo": "070800000080",
  "Titulo": "Hortalizas de vaina, aunque estén desvainadas, frescas o refrigeradas"
 },
 {
  "Codigo": "070810000080",
  "Titulo": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "Codigo": "070820000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "Codigo": "070890000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070900000080",
  "Titulo": "Las demás hortalizas, frescas o refrigeradas"
 },
 {
  "Codigo": "070920000080",
  "Titulo": "Espárragos"
 },
 {
  "Codigo": "070930000080",
  "Titulo": "Berenjenas"
 },
 {
  "Codigo": "070940000080",
  "Titulo": "Apio, excepto el apionabo"
 },
 {
  "Codigo": "070951000010",
  "Titulo": "Hongos y trufas"
 },
 {
  "Codigo": "070951000080",
  "Titulo": "Hongos del género Agaricus"
 },
 {
  "Codigo": "070952000080",
  "Titulo": "Hongos del género Boletus"
 },
 {
  "Codigo": "070953000080",
  "Titulo": "Hongos del género Cantharellus"
 },
 {
  "Codigo": "070954000080",
  "Titulo": "Shiitake (Lentinus edodes)"
 },
 {
  "Codigo": "070955000080",
  "Titulo": "Matsutake (Tricholoma matsutake, Tricholoma magnivelare, Tricholoma anatolicum, Tricholoma dulciolens, Tricholoma caligatum)"
 },
 {
  "Codigo": "070956000080",
  "Titulo": "Trufas (Tuber spp.)"
 },
 {
  "Codigo": "070959000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070960000080",
  "Titulo": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "Codigo": "070960100080",
  "Titulo": "Pimientos dulces"
 },
 {
  "Codigo": "070960910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070960910080",
  "Titulo": "Del género Capsicum que se destinen a la fabricación de capsicina o de colorantes de oleorresinas de Capsicum"
 },
 {
  "Codigo": "070960950080",
  "Titulo": "Que se destinen a la fabricación industrial de aceites esenciales o de resinoides"
 },
 {
  "Codigo": "070960990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "070970000080",
  "Titulo": "Espinacas (incluida la de Nueva Zelanda) y armuelles"
 },
 {
  "Codigo": "070991000010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070991000080",
  "Titulo": "Alcachofas (alcauciles)"
 },
 {
  "Codigo": "070992000080",
  "Titulo": "Aceitunas"
 },
 {
  "Codigo": "070992100080",
  "Titulo": "Que no se destinen a la producción de aceite"
 },
 {
  "Codigo": "070992900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070993000080",
  "Titulo": "Calabazas (zapallos) y calabacines (Cucurbita spp.)"
 },
 {
  "Codigo": "070993100080",
  "Titulo": "Calabacines (zapallitos)"
 },
 {
  "Codigo": "070993900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070999000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "070999100080",
  "Titulo": "Ensaladas [excepto las lechugas (Lactuca sativa)] y achicorias [comprendidas la escarola y la endivia (Cichorium spp.)]"
 },
 {
  "Codigo": "070999200080",
  "Titulo": "Acelgas y cardos"
 },
 {
  "Codigo": "070999400080",
  "Titulo": "Alcaparras"
 },
 {
  "Codigo": "070999500080",
  "Titulo": "Hinojo"
 },
 {
  "Codigo": "070999600080",
  "Titulo": "Maíz dulce"
 },
 {
  "Codigo": "070999900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071000000080",
  "Titulo": "Hortalizas, aunque estén cocidas en agua o vapor, congeladas"
 },
 {
  "Codigo": "071010000080",
  "Titulo": "Patatas (papas)"
 },
 {
  "Codigo": "071021000010",
  "Titulo": "Hortalizas de vaina, estén o no desvainadas"
 },
 {
  "Codigo": "071021000080",
  "Titulo": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "Codigo": "071022000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "Codigo": "071029000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071030000080",
  "Titulo": "Espinacas (incluida la de Nueva Zelanda) y armuelles"
 },
 {
  "Codigo": "071040000080",
  "Titulo": "Maíz dulce"
 },
 {
  "Codigo": "071080000080",
  "Titulo": "Las demás hortalizas"
 },
 {
  "Codigo": "071080100080",
  "Titulo": "Aceitunas"
 },
 {
  "Codigo": "071080510010",
  "Titulo": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "Codigo": "071080510080",
  "Titulo": "Pimientos dulces"
 },
 {
  "Codigo": "071080590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071080610010",
  "Titulo": "Setas"
 },
 {
  "Codigo": "071080610080",
  "Titulo": "Del género Agaricus"
 },
 {
  "Codigo": "071080690080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071080700080",
  "Titulo": "Tomates"
 },
 {
  "Codigo": "071080800080",
  "Titulo": "Alcachofas (alcauciles)"
 },
 {
  "Codigo": "071080850080",
  "Titulo": "Espárragos"
 },
 {
  "Codigo": "071080950080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071090000080",
  "Titulo": "Mezclas de hortalizas"
 },
 {
  "Codigo": "071100000080",
  "Titulo": "Hortalizas conservadas provisionalmente, pero todavía impropias, en este estado, para consumo inmediato"
 },
 {
  "Codigo": "071120000080",
  "Titulo": "Aceitunas"
 },
 {
  "Codigo": "071120100080",
  "Titulo": "Que no se destinen a la producción de aceite"
 },
 {
  "Codigo": "071120900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071140000080",
  "Titulo": "Pepinos y pepinillos"
 },
 {
  "Codigo": "071151000010",
  "Titulo": "Hongos y trufas"
 },
 {
  "Codigo": "071151000080",
  "Titulo": "Hongos del género Agaricus"
 },
 {
  "Codigo": "071159000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071190000080",
  "Titulo": "Las demás hortalizas; mezclas de hortalizas"
 },
 {
  "Codigo": "071190100010",
  "Titulo": "Hortalizas"
 },
 {
  "Codigo": "071190100080",
  "Titulo": "Frutos de los géneros Capsicum o Pimenta (excepto los pimientos dulces)"
 },
 {
  "Codigo": "071190300080",
  "Titulo": "Maíz dulce"
 },
 {
  "Codigo": "071190500080",
  "Titulo": "Cebollas"
 },
 {
  "Codigo": "071190700080",
  "Titulo": "Alcaparras"
 },
 {
  "Codigo": "071190800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071190900080",
  "Titulo": "Mezclas de hortalizas"
 },
 {
  "Codigo": "071200000080",
  "Titulo": "Hortalizas secas, incluidas las cortadas en trozos o en rodajas o las trituradas o pulverizadas, pero sin otra preparación"
 },
 {
  "Codigo": "071220000080",
  "Titulo": "Cebollas"
 },
 {
  "Codigo": "071231000010",
  "Titulo": "Orejas de Judas (Auricularia spp.), hongos gelatinosos (Tremella spp.) y demás hongos; trufas"
 },
 {
  "Codigo": "071231000080",
  "Titulo": "Hongos del género Agaricus"
 },
 {
  "Codigo": "071232000080",
  "Titulo": "Orejas de Judas (Auricularia spp.)"
 },
 {
  "Codigo": "071233000080",
  "Titulo": "Hongos gelatinosos (Tremella spp.)"
 },
 {
  "Codigo": "071234000080",
  "Titulo": "Shiitake (Lentinus edodes)"
 },
 {
  "Codigo": "071239000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071290000080",
  "Titulo": "Las demás hortalizas; mezclas de hortalizas"
 },
 {
  "Codigo": "071290050080",
  "Titulo": "Patatas (papas), incluso en trozos o en rodajas, pero sin otra preparación"
 },
 {
  "Codigo": "071290110010",
  "Titulo": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "Codigo": "071290110080",
  "Titulo": "Híbrido, para siembra"
 },
 {
  "Codigo": "071290190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071290300080",
  "Titulo": "Tomates"
 },
 {
  "Codigo": "071290500080",
  "Titulo": "Zanahorias"
 },
 {
  "Codigo": "071290900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071300000080",
  "Titulo": "Hortalizas de vaina secas desvainadas, aunque estén mondadas o partidas"
 },
 {
  "Codigo": "071310000080",
  "Titulo": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "Codigo": "071310100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "071310900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071320000080",
  "Titulo": "Garbanzos"
 },
 {
  "Codigo": "071331000010",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "Codigo": "071331000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) de las especies Vigna mungo (L) Hepper o Vigna radiata (L) Wilczek"
 },
 {
  "Codigo": "071332000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) Adzuki (Phaseolus o Vigna angularis)"
 },
 {
  "Codigo": "071333000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) comunes (Phaseolus vulgaris)"
 },
 {
  "Codigo": "071333100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "071333900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071334000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) bambara (Vigna subterranea o Voandzeia subterranea)"
 },
 {
  "Codigo": "071335000080",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) salvajes o caupí (Vigna unguiculata)"
 },
 {
  "Codigo": "071339000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071340000080",
  "Titulo": "Lentejas"
 },
 {
  "Codigo": "071350000080",
  "Titulo": "Habas (Vicia faba var. major), haba caballar (Vicia faba var. equina) y haba menor (Vicia faba var. minor)"
 },
 {
  "Codigo": "071360000080",
  "Titulo": "Guisantes (arvejas, chícharos) de palo, gandú o gandul (Cajanus cajan)"
 },
 {
  "Codigo": "071390000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071400000080",
  "Titulo": "Raíces de mandioca (yuca), arrurruz o salep, aguaturmas (patacas), batatas (boniatos, camotes) y raíces y tubérculos similares ricos en fécula o inulina, frescos, refrigerados, congelados o secos, incluso troceados o en pellets; médula de sagú"
 },
 {
  "Codigo": "071410000080",
  "Titulo": "Raíces de mandioca (yuca)"
 },
 {
  "Codigo": "071420000080",
  "Titulo": "Batatas (boniatos, camotes)"
 },
 {
  "Codigo": "071420100080",
  "Titulo": "Frescas, enteras, para el consumo humano"
 },
 {
  "Codigo": "071420900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "071430000080",
  "Titulo": "Ñame (Dioscorea spp.)"
 },
 {
  "Codigo": "071440000080",
  "Titulo": "Taro (Colocasia spp.)"
 },
 {
  "Codigo": "071450000080",
  "Titulo": "Yautía (malanga) (Xanthosoma spp.)"
 },
 {
  "Codigo": "071490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "071490200080",
  "Titulo": "Raíces de arrurruz y de salep, y raíces y tubérculos similares ricos en fécula"
 },
 {
  "Codigo": "071490900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080021000090",
  "Titulo": "CAPÍTULO 8 - FRUTAS Y FRUTOS COMESTIBLES; CORTEZAS DE AGRIOS (CÍTRICOS), MELONES O SANDÍAS"
 },
 {
  "Codigo": "080100000080",
  "Titulo": "Cocos, nueces del Brasil y nueces de marañón (merey, cajuil, anacardo, cajú), frescos o secos, incluso sin cáscara o mondados"
 },
 {
  "Codigo": "080111000010",
  "Titulo": "Cocos"
 },
 {
  "Codigo": "080111000080",
  "Titulo": "Secos"
 },
 {
  "Codigo": "080112000080",
  "Titulo": "Con la cáscara interna (endocarpio)"
 },
 {
  "Codigo": "080119000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080121000010",
  "Titulo": "Nueces del Brasil"
 },
 {
  "Codigo": "080121000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080122000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080131000010",
  "Titulo": "Nueces de marañón (merey, cajuil, anacardo, cajú)"
 },
 {
  "Codigo": "080131000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080132000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080200000080",
  "Titulo": "Los demás frutos de cáscara frescos o secos, incluso sin cáscara o mondados"
 },
 {
  "Codigo": "080211000010",
  "Titulo": "Almendras"
 },
 {
  "Codigo": "080211000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080211100080",
  "Titulo": "Amargas"
 },
 {
  "Codigo": "080211900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080212000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080212100080",
  "Titulo": "Amargas"
 },
 {
  "Codigo": "080212900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080221000010",
  "Titulo": "Avellanas (Corylus spp.)"
 },
 {
  "Codigo": "080221000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080222000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080231000010",
  "Titulo": "Nueces de nogal"
 },
 {
  "Codigo": "080231000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080232000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080241000010",
  "Titulo": "Castañas (Castanea spp.)"
 },
 {
  "Codigo": "080241000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080242000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080251000010",
  "Titulo": "Pistachos"
 },
 {
  "Codigo": "080251000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080252000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080261000010",
  "Titulo": "Nueces de macadamia"
 },
 {
  "Codigo": "080261000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "080262000080",
  "Titulo": "Sin cáscara"
 },
 {
  "Codigo": "080270000080",
  "Titulo": "Nueces de cola (Cola spp.)"
 },
 {
  "Codigo": "080280000080",
  "Titulo": "Nueces de areca"
 },
 {
  "Codigo": "080291000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080291000080",
  "Titulo": "Piñones con cáscara"
 },
 {
  "Codigo": "080292000080",
  "Titulo": "Piñones sin cáscara"
 },
 {
  "Codigo": "080299000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080299100080",
  "Titulo": "Pacanas"
 },
 {
  "Codigo": "080299900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080300000080",
  "Titulo": "Plátanos (bananas), incluidos los «plantains» (plátanos macho), frescos o secos"
 },
 {
  "Codigo": "080310000080",
  "Titulo": "«Plantains» (plátanos macho)"
 },
 {
  "Codigo": "080310100080",
  "Titulo": "Frescos"
 },
 {
  "Codigo": "080310900080",
  "Titulo": "Secos"
 },
 {
  "Codigo": "080390000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080390100080",
  "Titulo": "Frescos"
 },
 {
  "Codigo": "080390900080",
  "Titulo": "Secos"
 },
 {
  "Codigo": "080400000080",
  "Titulo": "Dátiles, higos, piñas (ananás), aguacates (paltas), guayabas, mangos y mangostanes, frescos o secos"
 },
 {
  "Codigo": "080410000080",
  "Titulo": "Dátiles"
 },
 {
  "Codigo": "080420000080",
  "Titulo": "Higos"
 },
 {
  "Codigo": "080420100080",
  "Titulo": "Frescos"
 },
 {
  "Codigo": "080420900080",
  "Titulo": "Secos"
 },
 {
  "Codigo": "080430000080",
  "Titulo": "Piñas (ananás)"
 },
 {
  "Codigo": "080440000080",
  "Titulo": "Aguacates (paltas)"
 },
 {
  "Codigo": "080450000080",
  "Titulo": "Guayabas, mangos y mangostanes"
 },
 {
  "Codigo": "080500000080",
  "Titulo": "Agrios (cítricos) frescos o secos"
 },
 {
  "Codigo": "080510000080",
  "Titulo": "Naranjas"
 },
 {
  "Codigo": "080510220010",
  "Titulo": "Naranjas dulces, frescas"
 },
 {
  "Codigo": "080510220080",
  "Titulo": "Naranjas navel"
 },
 {
  "Codigo": "080510240080",
  "Titulo": "Naranjas blancas"
 },
 {
  "Codigo": "080510280080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080510800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080521000010",
  "Titulo": "Mandarinas (incluidas las tangerinas y satsumas); clementinas, «wilkings» e híbridos similares de agrios (cítricos)"
 },
 {
  "Codigo": "080521000080",
  "Titulo": "Mandarinas (incluidas las tangerinas y satsumas)"
 },
 {
  "Codigo": "080521100080",
  "Titulo": "Satsumas"
 },
 {
  "Codigo": "080521900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080522000080",
  "Titulo": "Clementinas"
 },
 {
  "Codigo": "080529000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080540000080",
  "Titulo": "Toronjas y pomelos"
 },
 {
  "Codigo": "080550000080",
  "Titulo": "Limones (Citrus limon, Citrus limonum) y limas (Citrus aurantifolia, Citrus latifolia)"
 },
 {
  "Codigo": "080550100080",
  "Titulo": "Limones (Citrus limon, Citrus limonum)"
 },
 {
  "Codigo": "080550900080",
  "Titulo": "Limas (Citrus aurantifolia, Citrus latifolia)"
 },
 {
  "Codigo": "080590000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080600000080",
  "Titulo": "Uvas, frescas o secas, incluidas las pasas"
 },
 {
  "Codigo": "080610000080",
  "Titulo": "Frescas"
 },
 {
  "Codigo": "080610100080",
  "Titulo": "De mesa"
 },
 {
  "Codigo": "080610900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080620000080",
  "Titulo": "Secas, incluidas las pasas"
 },
 {
  "Codigo": "080620100080",
  "Titulo": "Pasas de Corinto"
 },
 {
  "Codigo": "080620300080",
  "Titulo": "Pasas sultaninas"
 },
 {
  "Codigo": "080620900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080700000080",
  "Titulo": "Melones, sandías y papayas, frescos"
 },
 {
  "Codigo": "080711000010",
  "Titulo": "Melones y sandías"
 },
 {
  "Codigo": "080711000080",
  "Titulo": "Sandías"
 },
 {
  "Codigo": "080719000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "080720000080",
  "Titulo": "Papayas"
 },
 {
  "Codigo": "080800000080",
  "Titulo": "Manzanas, peras y membrillos, frescos"
 },
 {
  "Codigo": "080810000080",
  "Titulo": "Manzanas"
 },
 {
  "Codigo": "080810100080",
  "Titulo": "Manzanas para sidra, a granel, del 16 de septiembre al 15 de diciembre"
 },
 {
  "Codigo": "080810800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080830000080",
  "Titulo": "Peras"
 },
 {
  "Codigo": "080830100080",
  "Titulo": "Peras para perada, a granel, del 1 de agosto al 31 de diciembre"
 },
 {
  "Codigo": "080830900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080840000080",
  "Titulo": "Membrillos"
 },
 {
  "Codigo": "080900000080",
  "Titulo": "Albaricoques (damascos, chabacanos), cerezas, melocotones (duraznos) (incluidos los griñones y nectarinas), ciruelas y endrinas, frescos"
 },
 {
  "Codigo": "080910000080",
  "Titulo": "Albaricoques (damascos, chabacanos)"
 },
 {
  "Codigo": "080921000010",
  "Titulo": "Cerezas"
 },
 {
  "Codigo": "080921000080",
  "Titulo": "Guindas (cerezas ácidas) (Prunus cerasus)"
 },
 {
  "Codigo": "080929000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080930000080",
  "Titulo": "Melocotones (duraznos), incluidos los griñones y nectarinas"
 },
 {
  "Codigo": "080930100080",
  "Titulo": "Griñones y nectarinas"
 },
 {
  "Codigo": "080930900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "080940000080",
  "Titulo": "Ciruelas y endrinas"
 },
 {
  "Codigo": "080940050080",
  "Titulo": "Ciruelas"
 },
 {
  "Codigo": "080940900080",
  "Titulo": "Endrinas"
 },
 {
  "Codigo": "081000000080",
  "Titulo": "Las demás frutas u otros frutos, frescos"
 },
 {
  "Codigo": "081010000080",
  "Titulo": "Fresas (frutillas)"
 },
 {
  "Codigo": "081020000080",
  "Titulo": "Frambuesas, zarzamoras, moras y moras-frambuesa"
 },
 {
  "Codigo": "081020100080",
  "Titulo": "Frambuesas"
 },
 {
  "Codigo": "081020900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081030000080",
  "Titulo": "Grosellas negras, blancas o rojas y grosellas espinosas"
 },
 {
  "Codigo": "081030100080",
  "Titulo": "Grosellas negras (casis)"
 },
 {
  "Codigo": "081030300080",
  "Titulo": "Grosellas rojas"
 },
 {
  "Codigo": "081030900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081040000080",
  "Titulo": "Arándanos rojos, mirtilos y demás frutos del género Vaccinium"
 },
 {
  "Codigo": "081040100080",
  "Titulo": "Frutos del Vaccinium vitis-idaea (arándanos rojos)"
 },
 {
  "Codigo": "081040300080",
  "Titulo": "Frutos del Vaccinium myrtillus (arándanos, mirtilos)"
 },
 {
  "Codigo": "081040500080",
  "Titulo": "Frutos del Vaccinium macrocarpon y del Vaccinium corymbosum"
 },
 {
  "Codigo": "081040900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081050000080",
  "Titulo": "Kiwis"
 },
 {
  "Codigo": "081060000080",
  "Titulo": "Duriones"
 },
 {
  "Codigo": "081070000080",
  "Titulo": "Caquis (persimonios)"
 },
 {
  "Codigo": "081090000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081090200080",
  "Titulo": "Tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), frutos del árbol del pan, litchis, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "Codigo": "081090750080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081100000080",
  "Titulo": "Frutas y otros frutos, sin cocer o cocidos en agua o vapor, congelados, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "081110000080",
  "Titulo": "Fresas (frutillas)"
 },
 {
  "Codigo": "081110110010",
  "Titulo": "Con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "081110110080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "081110190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081110900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081120000080",
  "Titulo": "Frambuesas, zarzamoras, moras, moras-frambuesa y grosellas"
 },
 {
  "Codigo": "081120110010",
  "Titulo": "Con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "081120110080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "081120190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081120310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081120310080",
  "Titulo": "Frambuesas"
 },
 {
  "Codigo": "081120390080",
  "Titulo": "Grosellas negras (casis)"
 },
 {
  "Codigo": "081120510080",
  "Titulo": "Grosellas rojas"
 },
 {
  "Codigo": "081120590080",
  "Titulo": "Zarzamoras, moras y moras-frambuesa"
 },
 {
  "Codigo": "081120900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081190110010",
  "Titulo": "Con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "081190110020",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "081190110080",
  "Titulo": "Frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "081190190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081190310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081190310080",
  "Titulo": "Frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "081190390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081190500010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081190500080",
  "Titulo": "Frutos del Vaccinium myrtillus (arándanos, mirtilos)"
 },
 {
  "Codigo": "081190700080",
  "Titulo": "Frutos de las especies Vaccinium myrtilloides y Vaccinium angustifolium"
 },
 {
  "Codigo": "081190750010",
  "Titulo": "Cerezas"
 },
 {
  "Codigo": "081190750080",
  "Titulo": "Guindas (Prunus cerasus)"
 },
 {
  "Codigo": "081190800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081190850080",
  "Titulo": "Frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "081190950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081200000080",
  "Titulo": "Frutas y otros frutos, conservados provisionalmente, pero todavía impropios, en este estado, para consumo inmediato"
 },
 {
  "Codigo": "081210000080",
  "Titulo": "Cerezas"
 },
 {
  "Codigo": "081290000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081290250080",
  "Titulo": "Albaricoques (damascos, chabacanos); naranjas"
 },
 {
  "Codigo": "081290300080",
  "Titulo": "Papayas"
 },
 {
  "Codigo": "081290400080",
  "Titulo": "Arándanos, mirtilos (frutos del Vaccinium myrtillus)"
 },
 {
  "Codigo": "081290700080",
  "Titulo": "Guayabas, mangos, mangostanes, tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), litchis, frutos del árbol del pan, sapotillos, frutos de la pasión, carambolas, pitahayas y nueces tropicales"
 },
 {
  "Codigo": "081290980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081300000080",
  "Titulo": "Frutas y otros frutos, secos, excepto los de las partidas 0801 a 0806; mezclas de frutas u otros frutos, secos, o de frutos de cáscara de este Capítulo"
 },
 {
  "Codigo": "081310000080",
  "Titulo": "Albaricoques (damascos, chabacanos)"
 },
 {
  "Codigo": "081320000080",
  "Titulo": "Ciruelas"
 },
 {
  "Codigo": "081330000080",
  "Titulo": "Manzanas"
 },
 {
  "Codigo": "081340000080",
  "Titulo": "Las demás frutas u otros frutos"
 },
 {
  "Codigo": "081340100080",
  "Titulo": "Melocotones, incluidos los griñones y nectarinas"
 },
 {
  "Codigo": "081340300080",
  "Titulo": "Peras"
 },
 {
  "Codigo": "081340500080",
  "Titulo": "Papayas"
 },
 {
  "Codigo": "081340650080",
  "Titulo": "Tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), frutos del árbol del pan, litchis, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "Codigo": "081340950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081350000080",
  "Titulo": "Mezclas de frutas u otros frutos, secos, o de frutos de cáscara de este Capítulo"
 },
 {
  "Codigo": "081350120010",
  "Titulo": "Macedonias de frutos secos (excepto los de las partidas 0801 a 0806)"
 },
 {
  "Codigo": "081350120020",
  "Titulo": "Sin ciruelas pasas"
 },
 {
  "Codigo": "081350120080",
  "Titulo": "De papayas, tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), litchis, frutos del árbol del pan, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "Codigo": "081350150080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081350190080",
  "Titulo": "Con ciruelas pasas"
 },
 {
  "Codigo": "081350310010",
  "Titulo": "Mezclas constituidas exclusivamente por frutos de cáscara de las partidas 0801 y 0802"
 },
 {
  "Codigo": "081350310080",
  "Titulo": "De nueces tropicales"
 },
 {
  "Codigo": "081350390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "081350910010",
  "Titulo": "Las demás mezclas"
 },
 {
  "Codigo": "081350910080",
  "Titulo": "Sin ciruelas pasas ni higos"
 },
 {
  "Codigo": "081350990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "081400000080",
  "Titulo": "Cortezas de agrios (cítricos), melones o sandías, frescas, congeladas, secas o presentadas en agua salada, sulfurosa o adicionada de otras sustancias para su conservación provisional"
 },
 {
  "Codigo": "090021000090",
  "Titulo": "CAPÍTULO 9 - CAFÉ, TÉ, YERBA MATE Y ESPECIAS"
 },
 {
  "Codigo": "090100000080",
  "Titulo": "Café, incluso tostado o descafeinado; cáscara y cascarilla de café; sucedáneos del café que contengan café en cualquier proporción"
 },
 {
  "Codigo": "090111000010",
  "Titulo": "Café sin tostar"
 },
 {
  "Codigo": "090111000080",
  "Titulo": "Sin descafeinar"
 },
 {
  "Codigo": "090112000080",
  "Titulo": "Descafeinado"
 },
 {
  "Codigo": "090121000010",
  "Titulo": "Café tostado"
 },
 {
  "Codigo": "090121000080",
  "Titulo": "Sin descafeinar"
 },
 {
  "Codigo": "090122000080",
  "Titulo": "Descafeinado"
 },
 {
  "Codigo": "090190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "090190100080",
  "Titulo": "Cáscara y cascarilla de café"
 },
 {
  "Codigo": "090190900080",
  "Titulo": "Sucedáneos del café que contengan café"
 },
 {
  "Codigo": "090200000080",
  "Titulo": "Té, incluso aromatizado"
 },
 {
  "Codigo": "090210000080",
  "Titulo": "Té verde (sin fermentar) presentado en envases inmediatos con un contenido inferior o igual a 3 kg"
 },
 {
  "Codigo": "090220000080",
  "Titulo": "Té verde (sin fermentar) presentado de otra forma"
 },
 {
  "Codigo": "090230000080",
  "Titulo": "Té negro (fermentado) y té parcialmente fermentado, presentados en envases inmediatos con un contenido inferior o igual a 3 kg"
 },
 {
  "Codigo": "090240000080",
  "Titulo": "Té negro (fermentado) y té parcialmente fermentado, presentados de otra forma"
 },
 {
  "Codigo": "090300000080",
  "Titulo": "Yerba mate"
 },
 {
  "Codigo": "090400000080",
  "Titulo": "Pimienta del género Piper; frutos de los géneros Capsicum o Pimenta, secos, triturados o pulverizados"
 },
 {
  "Codigo": "090411000010",
  "Titulo": "Pimienta"
 },
 {
  "Codigo": "090411000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090412000080",
  "Titulo": "Triturada o pulverizada"
 },
 {
  "Codigo": "090421000010",
  "Titulo": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "Codigo": "090421000080",
  "Titulo": "Secos, sin triturar ni pulverizar"
 },
 {
  "Codigo": "090421100080",
  "Titulo": "Pimientos dulces (Capsicum annuum)"
 },
 {
  "Codigo": "090421900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "090422000080",
  "Titulo": "Triturados o pulverizados"
 },
 {
  "Codigo": "090500000080",
  "Titulo": "Vainilla"
 },
 {
  "Codigo": "090510000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090520000080",
  "Titulo": "Triturada o pulverizada"
 },
 {
  "Codigo": "090600000080",
  "Titulo": "Canela y flores de canelero"
 },
 {
  "Codigo": "090611000010",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090611000080",
  "Titulo": "Canela (Cinnamomum zeylanicum Blume)"
 },
 {
  "Codigo": "090619000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "090620000080",
  "Titulo": "Triturada o pulverizada"
 },
 {
  "Codigo": "090700000080",
  "Titulo": "Clavos (frutos enteros, clavillos y pedúnculos)"
 },
 {
  "Codigo": "090710000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090720000080",
  "Titulo": "Triturados o pulverizados"
 },
 {
  "Codigo": "090800000080",
  "Titulo": "Nuez moscada, macis, amomos y cardamomos"
 },
 {
  "Codigo": "090811000010",
  "Titulo": "Nuez moscada"
 },
 {
  "Codigo": "090811000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090812000080",
  "Titulo": "Triturada o pulverizada"
 },
 {
  "Codigo": "090821000010",
  "Titulo": "Macis"
 },
 {
  "Codigo": "090821000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090822000080",
  "Titulo": "Triturado o pulverizado"
 },
 {
  "Codigo": "090831000010",
  "Titulo": "Amomos y cardamomos"
 },
 {
  "Codigo": "090831000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090832000080",
  "Titulo": "Triturados o pulverizados"
 },
 {
  "Codigo": "090900000080",
  "Titulo": "Semillas de anís, badiana, hinojo, cilantro, comino o alcaravea; bayas de enebro"
 },
 {
  "Codigo": "090921000010",
  "Titulo": "Semillas de cilantro"
 },
 {
  "Codigo": "090921000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090922000080",
  "Titulo": "Trituradas o pulverizadas"
 },
 {
  "Codigo": "090931000010",
  "Titulo": "Semillas de comino"
 },
 {
  "Codigo": "090931000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090932000080",
  "Titulo": "Trituradas o pulverizadas"
 },
 {
  "Codigo": "090961000010",
  "Titulo": "Semillas de anís, badiana, alcaravea o hinojo; bayas de enebro"
 },
 {
  "Codigo": "090961000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "090962000080",
  "Titulo": "Trituradas o pulverizadas"
 },
 {
  "Codigo": "091000000080",
  "Titulo": "Jengibre, azafrán, cúrcuma, tomillo, hojas de laurel, curri y demás especias"
 },
 {
  "Codigo": "091011000010",
  "Titulo": "Jengibre"
 },
 {
  "Codigo": "091011000080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "091012000080",
  "Titulo": "Triturado o pulverizado"
 },
 {
  "Codigo": "091020000080",
  "Titulo": "Azafrán"
 },
 {
  "Codigo": "091020100080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "091020900080",
  "Titulo": "Triturado o pulverizado"
 },
 {
  "Codigo": "091030000080",
  "Titulo": "Cúrcuma"
 },
 {
  "Codigo": "091091000010",
  "Titulo": "Las demás especias"
 },
 {
  "Codigo": "091091000080",
  "Titulo": "Mezclas previstas en la Nota 1 b) de este Capítulo"
 },
 {
  "Codigo": "091091050080",
  "Titulo": "Curri"
 },
 {
  "Codigo": "091091100010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "091091100080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "091091900080",
  "Titulo": "Trituradas o pulverizadas"
 },
 {
  "Codigo": "091099000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "091099100080",
  "Titulo": "Semillas de alholva"
 },
 {
  "Codigo": "091099310010",
  "Titulo": "Tomillo"
 },
 {
  "Codigo": "091099310020",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "091099310080",
  "Titulo": "Serpol (Thymus serpyllum L.)"
 },
 {
  "Codigo": "091099330080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "091099390080",
  "Titulo": "Triturado o pulverizado"
 },
 {
  "Codigo": "091099500080",
  "Titulo": "Hojas de laurel"
 },
 {
  "Codigo": "091099910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "091099910080",
  "Titulo": "Sin triturar ni pulverizar"
 },
 {
  "Codigo": "091099990080",
  "Titulo": "Trituradas o pulverizadas"
 },
 {
  "Codigo": "100021000090",
  "Titulo": "CAPÍTULO 10 - CEREALES"
 },
 {
  "Codigo": "100100000080",
  "Titulo": "Trigo y morcajo (tranquillón)"
 },
 {
  "Codigo": "100111000010",
  "Titulo": "Trigo duro"
 },
 {
  "Codigo": "100111000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100119000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100191000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100191000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100191100080",
  "Titulo": "Escanda"
 },
 {
  "Codigo": "100191200080",
  "Titulo": "Trigo blando y morcajo (tranquillón)"
 },
 {
  "Codigo": "100191900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100199000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100200000080",
  "Titulo": "Centeno"
 },
 {
  "Codigo": "100210000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100290000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100300000080",
  "Titulo": "Cebada"
 },
 {
  "Codigo": "100310000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100390000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "100400000080",
  "Titulo": "Avena"
 },
 {
  "Codigo": "100410000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100500000080",
  "Titulo": "Maíz"
 },
 {
  "Codigo": "100510000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100510130010",
  "Titulo": "Híbrido"
 },
 {
  "Codigo": "100510130080",
  "Titulo": "Híbrido triple"
 },
 {
  "Codigo": "100510150080",
  "Titulo": "Híbrido simple"
 },
 {
  "Codigo": "100510180080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100510900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100590000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100600000080",
  "Titulo": "Arroz"
 },
 {
  "Codigo": "100610000080",
  "Titulo": "Arroz con cáscara (arroz paddy)"
 },
 {
  "Codigo": "100610100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100610300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100610300080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100610500080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100610710010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100610710080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100610790080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100620000080",
  "Titulo": "Arroz descascarillado (arroz cargo o arroz pardo)"
 },
 {
  "Codigo": "100620110010",
  "Titulo": "Escaldado (parboiled)"
 },
 {
  "Codigo": "100620110080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100620130080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100620150010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100620150080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100620170080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100620920010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100620920080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100620940080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100620960010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100620960080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100620980080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100630000080",
  "Titulo": "Arroz semiblanqueado o blanqueado, incluso pulido o glaseado"
 },
 {
  "Codigo": "100630210010",
  "Titulo": "Arroz semiblanqueado"
 },
 {
  "Codigo": "100630210020",
  "Titulo": "Escaldado (parboiled)"
 },
 {
  "Codigo": "100630210080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100630230080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100630250010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100630250080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100630270080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100630420010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100630420080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100630440080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100630460010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100630460080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100630480080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100630610010",
  "Titulo": "Arroz blanqueado"
 },
 {
  "Codigo": "100630610020",
  "Titulo": "Escaldado (parboiled)"
 },
 {
  "Codigo": "100630610080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100630630080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100630650010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100630650080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100630670080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100630920010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100630920080",
  "Titulo": "De grano redondo"
 },
 {
  "Codigo": "100630940080",
  "Titulo": "De grano medio"
 },
 {
  "Codigo": "100630960010",
  "Titulo": "De grano largo"
 },
 {
  "Codigo": "100630960080",
  "Titulo": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "Codigo": "100630980080",
  "Titulo": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "Codigo": "100640000080",
  "Titulo": "Arroz partido"
 },
 {
  "Codigo": "100700000080",
  "Titulo": "Sorgo de grano (granífero)"
 },
 {
  "Codigo": "100710000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100710100080",
  "Titulo": "Híbrido, para siembra"
 },
 {
  "Codigo": "100710900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100790000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100800000080",
  "Titulo": "Alforfón, mijo y alpiste; los demás cereales"
 },
 {
  "Codigo": "100810000080",
  "Titulo": "Alforfón"
 },
 {
  "Codigo": "100821000010",
  "Titulo": "Mijo"
 },
 {
  "Codigo": "100821000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "100829000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "100830000080",
  "Titulo": "Alpiste"
 },
 {
  "Codigo": "100840000080",
  "Titulo": "Fonio (Digitaria spp.)"
 },
 {
  "Codigo": "100850000080",
  "Titulo": "Quinua (quinoa) (Chenopodium quinoa)"
 },
 {
  "Codigo": "100860000080",
  "Titulo": "Triticale"
 },
 {
  "Codigo": "100890000080",
  "Titulo": "Los demás cereales"
 },
 {
  "Codigo": "110021000090",
  "Titulo": "CAPÍTULO 11 - PRODUCTOS DE LA MOLINERÍA; MALTA; ALMIDÓN Y FÉCULA; INULINA; GLUTEN DE TRIGO"
 },
 {
  "Codigo": "110100000080",
  "Titulo": "Harina de trigo o de morcajo (tranquillón)"
 },
 {
  "Codigo": "110100110010",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110100110080",
  "Titulo": "De trigo duro"
 },
 {
  "Codigo": "110100150080",
  "Titulo": "De trigo blando y de escanda"
 },
 {
  "Codigo": "110100900080",
  "Titulo": "De morcajo (tranquillón)"
 },
 {
  "Codigo": "110200000080",
  "Titulo": "Harina de cereales, excepto de trigo o de morcajo (tranquillón)"
 },
 {
  "Codigo": "110220000080",
  "Titulo": "Harina de maíz"
 },
 {
  "Codigo": "110220100080",
  "Titulo": "Con un contenido de materias grasas inferior o igual al 1,5 % en peso"
 },
 {
  "Codigo": "110220900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110290000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110290100080",
  "Titulo": "De cebada"
 },
 {
  "Codigo": "110290300080",
  "Titulo": "De avena"
 },
 {
  "Codigo": "110290500080",
  "Titulo": "De arroz"
 },
 {
  "Codigo": "110290700080",
  "Titulo": "De centeno"
 },
 {
  "Codigo": "110290900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110300000080",
  "Titulo": "Grañones, sémola y pellets, de cereales"
 },
 {
  "Codigo": "110311000010",
  "Titulo": "Grañones y sémola"
 },
 {
  "Codigo": "110311000080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110311100080",
  "Titulo": "De trigo duro"
 },
 {
  "Codigo": "110311900080",
  "Titulo": "De trigo blando y de escanda"
 },
 {
  "Codigo": "110313000080",
  "Titulo": "De maíz"
 },
 {
  "Codigo": "110313100080",
  "Titulo": "Con un contenido de materias grasas inferior o igual al 1,5 % en peso"
 },
 {
  "Codigo": "110313900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110319000080",
  "Titulo": "De los demás cereales"
 },
 {
  "Codigo": "110319200080",
  "Titulo": "De centeno o cebada"
 },
 {
  "Codigo": "110319400080",
  "Titulo": "De avena"
 },
 {
  "Codigo": "110319500080",
  "Titulo": "De arroz"
 },
 {
  "Codigo": "110319900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110320000080",
  "Titulo": "Pellets"
 },
 {
  "Codigo": "110320250080",
  "Titulo": "De centeno o cebada"
 },
 {
  "Codigo": "110320300080",
  "Titulo": "De avena"
 },
 {
  "Codigo": "110320400080",
  "Titulo": "De maíz"
 },
 {
  "Codigo": "110320500080",
  "Titulo": "De arroz"
 },
 {
  "Codigo": "110320600080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110320900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110400000080",
  "Titulo": "Granos de cereales trabajados de otro modo (por ejemplo: mondados, aplastados, en copos, perlados, troceados o quebrantados), excepto del arroz de la partida 1006; germen de cereales entero, aplastado, en copos o molido"
 },
 {
  "Codigo": "110412000010",
  "Titulo": "Granos aplastados o en copos"
 },
 {
  "Codigo": "110412000080",
  "Titulo": "De avena"
 },
 {
  "Codigo": "110412100080",
  "Titulo": "Granos aplastados"
 },
 {
  "Codigo": "110412900080",
  "Titulo": "Copos"
 },
 {
  "Codigo": "110419000080",
  "Titulo": "De los demás cereales"
 },
 {
  "Codigo": "110419100080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110419300080",
  "Titulo": "De centeno"
 },
 {
  "Codigo": "110419500080",
  "Titulo": "De maíz"
 },
 {
  "Codigo": "110419610010",
  "Titulo": "De cebada"
 },
 {
  "Codigo": "110419610080",
  "Titulo": "Granos aplastados"
 },
 {
  "Codigo": "110419690080",
  "Titulo": "Copos"
 },
 {
  "Codigo": "110419910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110419910080",
  "Titulo": "Copos de arroz"
 },
 {
  "Codigo": "110419990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110422000010",
  "Titulo": "Los demás granos trabajados (por ejemplo: mondados, perlados, troceados o quebrantados)"
 },
 {
  "Codigo": "110422000080",
  "Titulo": "De avena"
 },
 {
  "Codigo": "110422400080",
  "Titulo": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "Codigo": "110422500080",
  "Titulo": "Perlados"
 },
 {
  "Codigo": "110422950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110423000080",
  "Titulo": "De maíz"
 },
 {
  "Codigo": "110423400080",
  "Titulo": "Mondados (descascarillados o pelados), incluso troceados o quebrantados; perlados"
 },
 {
  "Codigo": "110423980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110429000080",
  "Titulo": "De los demás cereales"
 },
 {
  "Codigo": "110429040010",
  "Titulo": "De cebada"
 },
 {
  "Codigo": "110429040080",
  "Titulo": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "Codigo": "110429050080",
  "Titulo": "Perlados"
 },
 {
  "Codigo": "110429080080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110429170010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110429170080",
  "Titulo": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "Codigo": "110429300080",
  "Titulo": "Perlados"
 },
 {
  "Codigo": "110429510010",
  "Titulo": "Solamente quebrantados"
 },
 {
  "Codigo": "110429510080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110429550080",
  "Titulo": "De centeno"
 },
 {
  "Codigo": "110429590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110429810010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110429810080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110429850080",
  "Titulo": "De centeno"
 },
 {
  "Codigo": "110429890080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110430000080",
  "Titulo": "Germen de cereales entero, aplastado, en copos o molido"
 },
 {
  "Codigo": "110430100080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110430900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110500000080",
  "Titulo": "Harina, sémola, polvo, copos, gránulos y pellets, de patata (papa)"
 },
 {
  "Codigo": "110510000080",
  "Titulo": "Harina, sémola y polvo"
 },
 {
  "Codigo": "110520000080",
  "Titulo": "Copos, gránulos y pellets"
 },
 {
  "Codigo": "110600000080",
  "Titulo": "Harina, sémola y polvo de las hortalizas de la partida 0713, de sagú o de las raíces o tubérculos de la partida 0714 o de los productos del Capítulo 8"
 },
 {
  "Codigo": "110610000080",
  "Titulo": "De las hortalizas de la partida 0713"
 },
 {
  "Codigo": "110620000080",
  "Titulo": "De sagú o de las raíces o tubérculos de la partida 0714"
 },
 {
  "Codigo": "110620100080",
  "Titulo": "Desnaturalizada"
 },
 {
  "Codigo": "110620900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110630000080",
  "Titulo": "De los productos del Capítulo 8"
 },
 {
  "Codigo": "110630100080",
  "Titulo": "De plátanos"
 },
 {
  "Codigo": "110630900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110700000080",
  "Titulo": "Malta (de cebada u otros cereales), incluso tostada"
 },
 {
  "Codigo": "110710000080",
  "Titulo": "Sin tostar"
 },
 {
  "Codigo": "110710110010",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "110710110080",
  "Titulo": "Harina"
 },
 {
  "Codigo": "110710190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110710910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110710910080",
  "Titulo": "Harina"
 },
 {
  "Codigo": "110710990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "110720000080",
  "Titulo": "Tostada"
 },
 {
  "Codigo": "110800000080",
  "Titulo": "Almidón y fécula; inulina"
 },
 {
  "Codigo": "110811000010",
  "Titulo": "Almidón y fécula"
 },
 {
  "Codigo": "110811000080",
  "Titulo": "Almidón de trigo"
 },
 {
  "Codigo": "110812000080",
  "Titulo": "Almidón de maíz"
 },
 {
  "Codigo": "110813000080",
  "Titulo": "Fécula de patata (papa)"
 },
 {
  "Codigo": "110814000080",
  "Titulo": "Fécula de mandioca (yuca)"
 },
 {
  "Codigo": "110819000080",
  "Titulo": "Los demás almidones y féculas"
 },
 {
  "Codigo": "110819100080",
  "Titulo": "Almidón de arroz"
 },
 {
  "Codigo": "110819900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "110820000080",
  "Titulo": "Inulina"
 },
 {
  "Codigo": "110900000080",
  "Titulo": "Gluten de trigo, incluso seco"
 },
 {
  "Codigo": "120021000090",
  "Titulo": "CAPÍTULO 12 - SEMILLAS Y FRUTOS OLEAGINOSOS; SEMILLAS Y FRUTOS DIVERSOS; PLANTAS INDUSTRIALES O MEDICINALES; PAJA Y FORRAJE"
 },
 {
  "Codigo": "120100000080",
  "Titulo": "Habas (porotos, frijoles, fréjoles) de soja (soya), incluso quebrantadas"
 },
 {
  "Codigo": "120110000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120190000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120200000080",
  "Titulo": "Cacahuates (cacahuetes, maníes) sin tostar ni cocer de otro modo, incluso sin cáscara o quebrantados"
 },
 {
  "Codigo": "120230000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120241000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120241000080",
  "Titulo": "Con cáscara"
 },
 {
  "Codigo": "120242000080",
  "Titulo": "Sin cáscara, incluso quebrantados"
 },
 {
  "Codigo": "120300000080",
  "Titulo": "Copra"
 },
 {
  "Codigo": "120400000080",
  "Titulo": "Semilla de lino, incluso quebrantada"
 },
 {
  "Codigo": "120400100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120400900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120500000080",
  "Titulo": "Semillas de nabo (nabina) o de colza, incluso quebrantadas"
 },
 {
  "Codigo": "120510000080",
  "Titulo": "Semillas de nabo (nabina) o de colza con bajo contenido de ácido erúcico"
 },
 {
  "Codigo": "120510100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120510900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120590000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120600000080",
  "Titulo": "Semilla de girasol, incluso quebrantada"
 },
 {
  "Codigo": "120600100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120600910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120600910080",
  "Titulo": "Sin cáscara; con cáscara estriada gris y blanca"
 },
 {
  "Codigo": "120600990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120700000080",
  "Titulo": "Las demás semillas y frutos oleaginosos, incluso quebrantados"
 },
 {
  "Codigo": "120710000080",
  "Titulo": "Nueces y almendras de palma"
 },
 {
  "Codigo": "120721000010",
  "Titulo": "Semillas de algodón"
 },
 {
  "Codigo": "120721000080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120729000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120730000080",
  "Titulo": "Semillas de ricino"
 },
 {
  "Codigo": "120740000080",
  "Titulo": "Semillas de sésamo (ajonjolí)"
 },
 {
  "Codigo": "120740100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120740900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120750000080",
  "Titulo": "Semillas de mostaza"
 },
 {
  "Codigo": "120750100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120750900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120760000080",
  "Titulo": "Semillas de cártamo (Carthamus tinctorius)"
 },
 {
  "Codigo": "120770000080",
  "Titulo": "Semillas de melón"
 },
 {
  "Codigo": "120791000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120791000080",
  "Titulo": "Semilla de amapola (adormidera)"
 },
 {
  "Codigo": "120791100080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120791900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120799000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120799200080",
  "Titulo": "Para siembra"
 },
 {
  "Codigo": "120799910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120799910080",
  "Titulo": "Semilla de cáñamo"
 },
 {
  "Codigo": "120799960080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120800000080",
  "Titulo": "Harina de semillas o de frutos oleaginosos, excepto la harina de mostaza"
 },
 {
  "Codigo": "120810000080",
  "Titulo": "De habas (porotos, frijoles, fréjoles) de soja (soya)"
 },
 {
  "Codigo": "120890000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120900000080",
  "Titulo": "Semillas, frutos y esporas, para siembra"
 },
 {
  "Codigo": "120910000080",
  "Titulo": "Semilla de remolacha azucarera"
 },
 {
  "Codigo": "120921000010",
  "Titulo": "Semillas forrajeras"
 },
 {
  "Codigo": "120921000080",
  "Titulo": "De alfalfa"
 },
 {
  "Codigo": "120922000080",
  "Titulo": "De trébol (Trifolium spp.)"
 },
 {
  "Codigo": "120922100080",
  "Titulo": "Trébol violeta o rojo (Trifolium pratense L.)"
 },
 {
  "Codigo": "120922800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120923000080",
  "Titulo": "De festucas"
 },
 {
  "Codigo": "120923110080",
  "Titulo": "Festuca de los prados (Festuca pratensis Huds.)"
 },
 {
  "Codigo": "120923150080",
  "Titulo": "Festuca roja (Festuca rubra L.)"
 },
 {
  "Codigo": "120923800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120924000080",
  "Titulo": "De pasto azul de Kentucky (Poa pratensis L.)"
 },
 {
  "Codigo": "120925000080",
  "Titulo": "De ballico (Lolium multiflorum Lam., Lolium perenne L.)"
 },
 {
  "Codigo": "120925100080",
  "Titulo": "Ray-grass de Italia (Lolium multiflorum Lam.)"
 },
 {
  "Codigo": "120925900080",
  "Titulo": "Ray-grass inglés (Lolium perenne L.)"
 },
 {
  "Codigo": "120929000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120929450080",
  "Titulo": "Semillas de fleo de los prados (Phleum pratensis); vezas; semillas de la especie Poa palustris L. y Poa trivialis L.; dactilo (Dactylis glomerata L.); agrostis (Agrostides)"
 },
 {
  "Codigo": "120929500080",
  "Titulo": "Semillas de altramuz"
 },
 {
  "Codigo": "120929600080",
  "Titulo": "Semillas de remolacha forrajera (Beta vulgaris var. alba)"
 },
 {
  "Codigo": "120929800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120930000080",
  "Titulo": "Semillas de plantas herbáceas utilizadas principalmente por sus flores"
 },
 {
  "Codigo": "120991000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120991000080",
  "Titulo": "Semillas de hortalizas"
 },
 {
  "Codigo": "120991300080",
  "Titulo": "Semillas de remolacha de ensalada o de mesa (Beta vulgaris var. conditiva)"
 },
 {
  "Codigo": "120991800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120999000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "120999100080",
  "Titulo": "Semillas forestales"
 },
 {
  "Codigo": "120999910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "120999910080",
  "Titulo": "Semillas de plantas utilizadas principalmente por sus flores (excepto las de la subpartida 120930)"
 },
 {
  "Codigo": "120999990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "121000000080",
  "Titulo": "Conos de lúpulo frescos o secos, incluso triturados, molidos o en pellets; lupulino"
 },
 {
  "Codigo": "121010000080",
  "Titulo": "Conos de lúpulo sin triturar ni moler ni en pellets"
 },
 {
  "Codigo": "121020000080",
  "Titulo": "Conos de lúpulo triturados, molidos o en pellets; lupulino"
 },
 {
  "Codigo": "121020100080",
  "Titulo": "Conos de lúpulo triturados, molidos o en pellets, enriquecidos con lupulino; lupulino"
 },
 {
  "Codigo": "121020900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121100000080",
  "Titulo": "Plantas, partes de plantas, semillas y frutos de las especies utilizadas principalmente en perfumería, medicina o para usos insecticidas, parasiticidas o similares, frescos, refrigerados, congelados o secos, incluso cortados, quebrantados o pulverizados"
 },
 {
  "Codigo": "121120000080",
  "Titulo": "Raíces de ginseng"
 },
 {
  "Codigo": "121130000080",
  "Titulo": "Hojas de coca"
 },
 {
  "Codigo": "121140000080",
  "Titulo": "Paja de adormidera"
 },
 {
  "Codigo": "121150000080",
  "Titulo": "Efedra"
 },
 {
  "Codigo": "121160000080",
  "Titulo": "Corteza de cerezo africano (Prunus africana)"
 },
 {
  "Codigo": "121190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121190300080",
  "Titulo": "Habas de sarapia"
 },
 {
  "Codigo": "121190860080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121200000080",
  "Titulo": "Algarrobas, algas, remolacha azucarera y caña de azúcar, frescas, refrigeradas, congeladas o secas, incluso pulverizadas; huesos (carozos) y almendras de frutos y demás productos vegetales (incluidas las raíces de achicoria sin tostar de la variedad Cichorium intybus sativum) empleados principalmente en la alimentación humana, no expresados ni comprendidos en otra parte"
 },
 {
  "Codigo": "121221000010",
  "Titulo": "Algas"
 },
 {
  "Codigo": "121221000080",
  "Titulo": "Aptas para la alimentación humana"
 },
 {
  "Codigo": "121229000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "121291000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121291000080",
  "Titulo": "Remolacha azucarera"
 },
 {
  "Codigo": "121291200080",
  "Titulo": "Seca, incluso pulverizada"
 },
 {
  "Codigo": "121291800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "121292000080",
  "Titulo": "Algarrobas"
 },
 {
  "Codigo": "121293000080",
  "Titulo": "Caña de azúcar"
 },
 {
  "Codigo": "121294000080",
  "Titulo": "Raíces de achicoria"
 },
 {
  "Codigo": "121299000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121299410010",
  "Titulo": "Semillas de algarrobas (garrofín)"
 },
 {
  "Codigo": "121299410080",
  "Titulo": "Sin mondar, quebrantar ni moler"
 },
 {
  "Codigo": "121299490080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "121299950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121300000080",
  "Titulo": "Paja y cascabillo de cereales, en bruto, incluso picados, molidos, prensados o en «pellets»"
 },
 {
  "Codigo": "121400000080",
  "Titulo": "Nabos forrajeros, remolachas forrajeras, raíces forrajeras, heno, alfalfa, trébol, esparceta, coles forrajeras, altramuces, vezas y productos forrajeros similares, incluso en «pellets»"
 },
 {
  "Codigo": "121410000080",
  "Titulo": "Harina y «pellets» de alfalfa"
 },
 {
  "Codigo": "121490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "121490100080",
  "Titulo": "Remolachas, nabos y demás raíces forrajeras"
 },
 {
  "Codigo": "121490900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "130021000090",
  "Titulo": "CAPÍTULO 13 - GOMAS, RESINAS Y DEMÁS JUGOS Y EXTRACTOS VEGETALES"
 },
 {
  "Codigo": "130100000080",
  "Titulo": "Goma laca; gomas, resinas, gomorresinas y oleorresinas (por ejemplo: bálsamos), naturales"
 },
 {
  "Codigo": "130120000080",
  "Titulo": "Goma arábiga"
 },
 {
  "Codigo": "130190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "130200000080",
  "Titulo": "Jugos y extractos vegetales; materias pécticas, pectinatos y pectatos; agar-agar y demás mucílagos y espesativos derivados de los vegetales, incluso modificados"
 },
 {
  "Codigo": "130211000010",
  "Titulo": "Jugos y extractos vegetales"
 },
 {
  "Codigo": "130211000080",
  "Titulo": "Opio"
 },
 {
  "Codigo": "130212000080",
  "Titulo": "De regaliz"
 },
 {
  "Codigo": "130213000080",
  "Titulo": "De lúpulo"
 },
 {
  "Codigo": "130214000080",
  "Titulo": "De efedra"
 },
 {
  "Codigo": "130219000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "130219050080",
  "Titulo": "Oleorresina de vainilla"
 },
 {
  "Codigo": "130219700080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "130220000080",
  "Titulo": "Materias pécticas, pectinatos y pectatos"
 },
 {
  "Codigo": "130220100080",
  "Titulo": "Secos"
 },
 {
  "Codigo": "130220900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "130231000010",
  "Titulo": "Mucílagos y espesativos derivados de los vegetales, incluso modificados"
 },
 {
  "Codigo": "130231000080",
  "Titulo": "Agar-agar"
 },
 {
  "Codigo": "130232000080",
  "Titulo": "Mucílagos y espesativos de la algarroba o de su semilla o de las semillas de guar, incluso modificados"
 },
 {
  "Codigo": "130232100080",
  "Titulo": "De algarroba o de la semilla (garrofín)"
 },
 {
  "Codigo": "130232900080",
  "Titulo": "De semillas de guar"
 },
 {
  "Codigo": "130239000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "140021000090",
  "Titulo": "CAPÍTULO 14 - MATERIAS TRENZABLES Y DEMÁS PRODUCTOS DE ORIGEN VEGETAL, NO EXPRESADOS NI COMPRENDIDOS EN OTRA PARTE"
 },
 {
  "Codigo": "140100000080",
  "Titulo": "Materias vegetales de las especies utilizadas principalmente en cestería o espartería (por ejemplo: bambú, roten (ratán), caña, junco, mimbre, rafia, paja de cereales limpiada, blanqueada o teñida, corteza de tilo)"
 },
 {
  "Codigo": "140110000080",
  "Titulo": "Bambú"
 },
 {
  "Codigo": "140120000080",
  "Titulo": "Roten (ratán)"
 },
 {
  "Codigo": "140190000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "140400000080",
  "Titulo": "Productos vegetales no expresados ni comprendidos en otra parte"
 },
 {
  "Codigo": "140420000080",
  "Titulo": "Línteres de algodón"
 },
 {
  "Codigo": "140490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150011000090",
  "Titulo": "SECCIÓN III - GRASAS Y ACEITES, ANIMALES, VEGETALES O DE ORIGEN MICROBIANO, Y PRODUCTOS DE SU DESDOBLAMIENTO; GRASAS ALIMENTICIAS ELABORADAS;  CERAS DE ORIGEN ANIMAL O VEGETAL"
 },
 {
  "Codigo": "150021000090",
  "Titulo": "CAPÍTULO 15 - GRASAS Y ACEITES, ANIMALES, VEGETALES O DE ORIGEN MICROBIANO, Y PRODUCTOS DE SU DESDOBLAMIENTO; GRASAS ALIMENTICIAS ELABORADAS; CERAS DE ORIGEN ANIMAL O VEGETAL"
 },
 {
  "Codigo": "150100000080",
  "Titulo": "Grasa de cerdo (incluida la manteca de cerdo) y grasa de ave, excepto las de las partidas 0209 o 1503"
 },
 {
  "Codigo": "150110000080",
  "Titulo": "Manteca de cerdo"
 },
 {
  "Codigo": "150110100080",
  "Titulo": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150110900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150120000080",
  "Titulo": "Las demás grasas de cerdo"
 },
 {
  "Codigo": "150120100080",
  "Titulo": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150120900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150190000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150200000080",
  "Titulo": "Grasa de animales de las especies bovina, ovina o caprina, excepto las de la partida 1503"
 },
 {
  "Codigo": "150210000080",
  "Titulo": "Sebo"
 },
 {
  "Codigo": "150210100080",
  "Titulo": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150210900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150290000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150290100080",
  "Titulo": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150290900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150300000080",
  "Titulo": "Estearina solar, aceite de manteca de cerdo, oleoestearina, oleomargarina y aceite de sebo, sin emulsionar, mezclar ni preparar de otro modo"
 },
 {
  "Codigo": "150300110010",
  "Titulo": "Estearina solar y oleoestearina"
 },
 {
  "Codigo": "150300110080",
  "Titulo": "Que se destinen a usos industriales"
 },
 {
  "Codigo": "150300190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150300300080",
  "Titulo": "Aceite de sebo que se destine a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150300900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150400000080",
  "Titulo": "Grasas y aceites, y sus fracciones, de pescado o de mamíferos marinos, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "150410000080",
  "Titulo": "Aceites de hígado de pescado y sus fracciones"
 },
 {
  "Codigo": "150410100080",
  "Titulo": "Con un contenido de vitamina A inferior o igual a 2500 unidades internacionales por gramo"
 },
 {
  "Codigo": "150410910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150410910080",
  "Titulo": "De halibut (fletán)"
 },
 {
  "Codigo": "150410990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150420000080",
  "Titulo": "Grasas y aceites de pescado y sus fracciones, excepto los aceites de hígado"
 },
 {
  "Codigo": "150420100080",
  "Titulo": "Fracciones sólidas"
 },
 {
  "Codigo": "150420900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150430000080",
  "Titulo": "Grasas y aceites de mamíferos marinos y sus fracciones"
 },
 {
  "Codigo": "150430100080",
  "Titulo": "Fracciones sólidas"
 },
 {
  "Codigo": "150430900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150500000080",
  "Titulo": "Grasa de lana y sustancias grasas derivadas, incluida la lanolina"
 },
 {
  "Codigo": "150500100080",
  "Titulo": "Grasa de lana en bruto (suarda o suintina)"
 },
 {
  "Codigo": "150500900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "150600000080",
  "Titulo": "Las demás grasas y aceites animales, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "150700000080",
  "Titulo": "Aceite de soja (soya) y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "Codigo": "150710000080",
  "Titulo": "Aceite en bruto, incluso desgomado"
 },
 {
  "Codigo": "150710100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150710900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150790000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150790100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150790900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150800000080",
  "Titulo": "Aceite de cacahuete (cacahuate, maní) y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "Codigo": "150810000080",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "150810100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150810900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150890000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150890100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "150890900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "150900000080",
  "Titulo": "Aceite de oliva y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "Codigo": "150920000080",
  "Titulo": "Aceite de oliva virgen extra"
 },
 {
  "Codigo": "150930000080",
  "Titulo": "Aceite de oliva virgen"
 },
 {
  "Codigo": "150940000080",
  "Titulo": "Los demás aceites de oliva vírgenes"
 },
 {
  "Codigo": "150990000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151000000080",
  "Titulo": "Los demás aceites y sus fracciones obtenidos exclusivamente de aceituna, incluso refinados, pero sin modificar químicamente, y mezclas de estos aceites o fracciones con los aceites o fracciones de la partida 1509"
 },
 {
  "Codigo": "151010000080",
  "Titulo": "Aceite de orujo de oliva en bruto"
 },
 {
  "Codigo": "151090000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151100000080",
  "Titulo": "Aceite de palma y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "Codigo": "151110000080",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151110100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151110900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151190110010",
  "Titulo": "Fracciones sólidas"
 },
 {
  "Codigo": "151190110080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151190190080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151190910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151190910080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151190990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151200000080",
  "Titulo": "Aceites de girasol, cártamo o algodón, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "151211000010",
  "Titulo": "Aceites de girasol o cártamo, y sus fracciones"
 },
 {
  "Codigo": "151211000080",
  "Titulo": "Aceites en bruto"
 },
 {
  "Codigo": "151211100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151211910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151211910080",
  "Titulo": "De girasol"
 },
 {
  "Codigo": "151211990080",
  "Titulo": "De cártamo"
 },
 {
  "Codigo": "151219000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151219100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151219900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151221000010",
  "Titulo": "Aceite de algodón y sus fracciones"
 },
 {
  "Codigo": "151221000080",
  "Titulo": "Aceite en bruto, incluso sin gosipol"
 },
 {
  "Codigo": "151221100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151221900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151229000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151229100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151229900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151300000080",
  "Titulo": "Aceites de coco (de copra), de almendra de palma (palmiste) o de babasú, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "151311000010",
  "Titulo": "Aceite de coco (de copra) y sus fracciones"
 },
 {
  "Codigo": "151311000080",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151311100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151311910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151311910080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151311990080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151319000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151319110010",
  "Titulo": "Fracciones sólidas"
 },
 {
  "Codigo": "151319110080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151319190080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151319300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151319300080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151319910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151319910080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151319990080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151321000010",
  "Titulo": "Aceites de almendra de palma (palmiste) o de babasú, y sus fracciones"
 },
 {
  "Codigo": "151321000080",
  "Titulo": "Aceites en bruto"
 },
 {
  "Codigo": "151321100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151321300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151321300080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151321900080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151329000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151329110010",
  "Titulo": "Fracciones sólidas"
 },
 {
  "Codigo": "151329110080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151329190080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151329300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151329300080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151329500010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151329500080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151329900080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151400000080",
  "Titulo": "Aceites de nabo (de nabina), colza o mostaza, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "151411000010",
  "Titulo": "Aceites de nabo (de nabina) o de colza con bajo contenido de ácido erúcico y sus fracciones"
 },
 {
  "Codigo": "151411000080",
  "Titulo": "Aceites en bruto"
 },
 {
  "Codigo": "151411100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151411900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151419000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151419100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151419900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151491000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151491000080",
  "Titulo": "Aceites en bruto"
 },
 {
  "Codigo": "151491100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151491900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151499000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151499100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151499900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151500000080",
  "Titulo": "Las demás grasas y aceites, vegetales (incluido el aceite de jojoba) o de origen microbiano, fijos, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "Codigo": "151511000010",
  "Titulo": "Aceite de lino (de linaza) y sus fracciones"
 },
 {
  "Codigo": "151511000080",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151519000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151519100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151519900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151521000010",
  "Titulo": "Aceite de maíz y sus fracciones"
 },
 {
  "Codigo": "151521000080",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151521100080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151521900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151529000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151529100080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151529900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151530000080",
  "Titulo": "Aceite de ricino y sus fracciones"
 },
 {
  "Codigo": "151530100080",
  "Titulo": "Que se destinen a la producción de ácido aminoundecanoico que se utilice en fabricación de fibras textiles sintéticas o plásticos artificiales"
 },
 {
  "Codigo": "151530900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151550000080",
  "Titulo": "Aceite de sésamo (ajonjolí) y sus fracciones"
 },
 {
  "Codigo": "151550110010",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151550110080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151550190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151550910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151550910080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151550990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151560000080",
  "Titulo": "Grasas y aceites, de origen microbiano, y sus fracciones"
 },
 {
  "Codigo": "151560110010",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151560110080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151560510010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151560510080",
  "Titulo": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151560590080",
  "Titulo": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "Codigo": "151560600010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151560600080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151560910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151560910080",
  "Titulo": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151560990080",
  "Titulo": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "Codigo": "151590000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590110080",
  "Titulo": "Aceite de tung; aceites de jojoba y de oiticica; cera de mírica y cera del Japón; sus fracciones"
 },
 {
  "Codigo": "151590210010",
  "Titulo": "Aceite de semilla de tabaco y sus fracciones"
 },
 {
  "Codigo": "151590210020",
  "Titulo": "Aceite en bruto"
 },
 {
  "Codigo": "151590210080",
  "Titulo": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151590290080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590310080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151590390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590400010",
  "Titulo": "Los demás aceites y sus fracciones"
 },
 {
  "Codigo": "151590400020",
  "Titulo": "Aceites en bruto"
 },
 {
  "Codigo": "151590400080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151590510010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590510080",
  "Titulo": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151590590080",
  "Titulo": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "Codigo": "151590600010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590600080",
  "Titulo": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151590910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151590910080",
  "Titulo": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151590990080",
  "Titulo": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "Codigo": "151600000080",
  "Titulo": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, parcial o totalmente hidrogenados, interesterificados, reesterificados o elaidinizados, incluso refinados, pero sin preparar de otro modo"
 },
 {
  "Codigo": "151610000080",
  "Titulo": "Grasas y aceites, animales, y sus fracciones"
 },
 {
  "Codigo": "151610100080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151610900080",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151620000080",
  "Titulo": "Grasas y aceites, vegetales, y sus fracciones"
 },
 {
  "Codigo": "151620100080",
  "Titulo": "Aceite de ricino hidrogenado, llamado opalwax"
 },
 {
  "Codigo": "151620910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151620910080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151620950010",
  "Titulo": "Que se presenten de otro modo"
 },
 {
  "Codigo": "151620950080",
  "Titulo": "Aceites de nabo (de nabina), de colza, de linaza, de girasol, de ilipé, de karité, de makoré, de tulucuná o de babasú, que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151620960010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151620960080",
  "Titulo": "Aceites de cacahuete (de cacahuate, de maní), de algodón, de soja (de soya) o de girasol; los demás aceites con un contenido en ácidos grasos libres inferior al 50 % en peso [excluidos los aceites de almendra de palma, de ilipé, de coco, de nabo (de nabina), de colza o de copaiba]"
 },
 {
  "Codigo": "151620980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151630000080",
  "Titulo": "Grasas y aceites, de origen microbiano, y sus fracciones"
 },
 {
  "Codigo": "151630910080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "151630980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151700000080",
  "Titulo": "Margarina; mezclas o preparaciones alimenticias de grasas o aceites, animales, vegetales o de origen microbiano, o de fracciones de diferentes grasas o aceites, de este Capítulo, excepto las grasas y aceites, alimenticios o sus fracciones, de la partida 1516"
 },
 {
  "Codigo": "151710000080",
  "Titulo": "Margarina, excepto la margarina líquida"
 },
 {
  "Codigo": "151710100080",
  "Titulo": "Con un contenido de materias grasas de la leche superior al 10 % pero inferior o igual al 15 % en peso"
 },
 {
  "Codigo": "151710900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "151790000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "151790100080",
  "Titulo": "Con un contenido de materias grasas de la leche superior al 10 % pero inferior o igual al 15 % en peso"
 },
 {
  "Codigo": "151790910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "151790910080",
  "Titulo": "Aceites vegetales fijos, fluidos, mezclados"
 },
 {
  "Codigo": "151790930080",
  "Titulo": "Mezclas o preparaciones culinarias para desmoldeo"
 },
 {
  "Codigo": "151790990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "151800000080",
  "Titulo": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, cocidos, oxidados, deshidratados, sulfurados, soplados, polimerizados por calor en vacío o atmósfera inerte («estandolizados»), o modificados químicamente de otra forma, excepto los de la partida 1516; mezclas o preparaciones no alimenticias de grasas o de aceites, animales, vegetales o de origen microbiano, o de fracciones de diferentes grasas o aceites, de este Capítulo, no expresadas ni comprendidas en otra parte"
 },
 {
  "Codigo": "151800100080",
  "Titulo": "Linoxina"
 },
 {
  "Codigo": "151800310010",
  "Titulo": "Aceites vegetales fijos, fluidos, mezclados, que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "Codigo": "151800310080",
  "Titulo": "En bruto"
 },
 {
  "Codigo": "151800390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151800910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151800910080",
  "Titulo": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, cocidos, oxidados, deshidratados, sulfurados, soplados, polimerizados por calor en vacío o atmósfera inerte («estandolizados»), o modificados químicamente de otra forma, excepto los de la partida 1516"
 },
 {
  "Codigo": "151800950010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "151800950080",
  "Titulo": "Mezclas y preparaciones no alimenticias de grasas y aceites animales o de grasas y aceites animales, vegetales o de origen microbiano, y sus fracciones"
 },
 {
  "Codigo": "151800990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "152000000080",
  "Titulo": "Glicerol en bruto; aguas y lejías glicerinosas"
 },
 {
  "Codigo": "152100000080",
  "Titulo": "Ceras vegetales (excepto los triglicéridos), cera de abejas o de otros insectos y esperma de ballena o de otros cetáceos (espermaceti), incluso refinadas o coloreadas"
 },
 {
  "Codigo": "152110000080",
  "Titulo": "Ceras vegetales"
 },
 {
  "Codigo": "152190000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "152190100080",
  "Titulo": "Esperma de ballena y de otros cetáceos (espermaceti), incluso refinada o coloreada"
 },
 {
  "Codigo": "152190910010",
  "Titulo": "Ceras de abejas o de otros insectos, incluso refinadas o coloreadas"
 },
 {
  "Codigo": "152190910080",
  "Titulo": "En bruto"
 },
 {
  "Codigo": "152190990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "152200000080",
  "Titulo": "Degrás; residuos procedentes del tratamiento de grasas o ceras, animales o vegetales"
 },
 {
  "Codigo": "152200100080",
  "Titulo": "Degrás"
 },
 {
  "Codigo": "152200310010",
  "Titulo": "Residuos procedentes del tratamiento de grasas o ceras, animales o vegetales"
 },
 {
  "Codigo": "152200310020",
  "Titulo": "Que contengan aceite con las características del aceite de oliva"
 },
 {
  "Codigo": "152200310080",
  "Titulo": "Pastas de neutralización soap-stocks"
 },
 {
  "Codigo": "152200390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "152200910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "152200910080",
  "Titulo": "Borras o heces de aceites, pastas de neutralización soap-stocks"
 },
 {
  "Codigo": "152200990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160011000090",
  "Titulo": "SECCIÓN IV - PRODUCTOS DE LAS INDUSTRIAS ALIMENTARIAS; BEBIDAS, LÍQUIDOS ALCOHÓLICOS Y VINAGRE; TABACO Y SUCEDÁNEOS DEL TABACO  ELABORADOS; PRODUCTOS, INCLUSO CON NICOTINA, DESTINADOS  PARA LA INHALACIÓN SIN COMBUSTIÓN;"
 },
 {
  "Codigo": "160021000090",
  "Titulo": "CAPÍTULO 16 - PREPARACIONES DE CARNE, PESCADO, CRUSTÁCEOS, MOLUSCOS O DEMÁS INVERTEBRADOS ACUÁTICOS, O DE INSECTOS"
 },
 {
  "Codigo": "160100000080",
  "Titulo": "Embutidos y productos similares de carne, despojos, sangre o de insectos; preparaciones alimenticias a base de estos productos"
 },
 {
  "Codigo": "160100100080",
  "Titulo": "De hígado"
 },
 {
  "Codigo": "160100910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160100910080",
  "Titulo": "Embutidos, secos o para untar, sin cocer"
 },
 {
  "Codigo": "160100990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160200000080",
  "Titulo": "Las demás preparaciones y conservas de carne, despojos,  sangre o de insectos"
 },
 {
  "Codigo": "160210000080",
  "Titulo": "Preparaciones homogeneizadas"
 },
 {
  "Codigo": "160220000080",
  "Titulo": "De hígado de cualquier animal"
 },
 {
  "Codigo": "160220100080",
  "Titulo": "De ganso o de pato"
 },
 {
  "Codigo": "160220900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160231000010",
  "Titulo": "De aves de la partida 0105"
 },
 {
  "Codigo": "160231000080",
  "Titulo": "De pavo (gallipavo)"
 },
 {
  "Codigo": "160231110010",
  "Titulo": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "Codigo": "160231110080",
  "Titulo": "Que contengan exclusivamente carne de pavo sin cocer"
 },
 {
  "Codigo": "160231190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160231800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160232000080",
  "Titulo": "De aves de la especie Gallus domesticus"
 },
 {
  "Codigo": "160232110010",
  "Titulo": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "Codigo": "160232110080",
  "Titulo": "Sin cocer"
 },
 {
  "Codigo": "160232190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160232300080",
  "Titulo": "Con un contenido de carne o despojos de aves superior o igual al 25 % pero inferior al 57 % en peso"
 },
 {
  "Codigo": "160232900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160239000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160239210010",
  "Titulo": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "Codigo": "160239210080",
  "Titulo": "Sin cocer"
 },
 {
  "Codigo": "160239290080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160239850080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160241000010",
  "Titulo": "De la especie porcina"
 },
 {
  "Codigo": "160241000080",
  "Titulo": "Jamones y trozos de jamón"
 },
 {
  "Codigo": "160241100080",
  "Titulo": "De la especie porcina doméstica"
 },
 {
  "Codigo": "160241900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160242000080",
  "Titulo": "Paletas y trozos de paleta"
 },
 {
  "Codigo": "160242100080",
  "Titulo": "De la especie porcina doméstica"
 },
 {
  "Codigo": "160242900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160249000080",
  "Titulo": "Las demás, incluidas las mezclas"
 },
 {
  "Codigo": "160249110010",
  "Titulo": "De la especie porcina doméstica"
 },
 {
  "Codigo": "160249110020",
  "Titulo": "Con un contenido de carne o despojos de cualquier clase superior o igual al 80 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "Codigo": "160249110080",
  "Titulo": "Chuleteros (excepto los espinazos) y sus trozos, incluidas las mezclas de chuleteros y piernas"
 },
 {
  "Codigo": "160249130080",
  "Titulo": "Espinazos y sus trozos, incluidas las mezclas de espinazos y paletas"
 },
 {
  "Codigo": "160249150080",
  "Titulo": "Las demás mezclas que contengan piernas, paletas, chuleteros o espinazos y sus trozos"
 },
 {
  "Codigo": "160249190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160249300080",
  "Titulo": "Con un contenido de carne o despojos de cualquier clase superior o igual al 40 % pero inferior al 80 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "Codigo": "160249500080",
  "Titulo": "Con un contenido de carne o despojos de cualquier clase inferior al 40 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "Codigo": "160249900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160250000080",
  "Titulo": "De la especie bovina"
 },
 {
  "Codigo": "160250100080",
  "Titulo": "Sin cocer; mezclas de carnes o despojos cocidos y de carne o despojos sin cocer"
 },
 {
  "Codigo": "160250310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160250310080",
  "Titulo": "Corned beef en envases herméticamente cerrados"
 },
 {
  "Codigo": "160250950080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290000080",
  "Titulo": "Las demás, incluidas las preparaciones de sangre de cualquier animal"
 },
 {
  "Codigo": "160290100080",
  "Titulo": "Preparaciones de sangre de cualquier animal"
 },
 {
  "Codigo": "160290310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290310080",
  "Titulo": "De caza o de conejo"
 },
 {
  "Codigo": "160290510010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290510080",
  "Titulo": "Que contengan carne o despojos de la especie porcina doméstica, sin cocer"
 },
 {
  "Codigo": "160290610010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290610020",
  "Titulo": "Que contengan carne o despojos de la especie bovina"
 },
 {
  "Codigo": "160290610080",
  "Titulo": "Sin cocer; mezclas de carne o despojos cocidos y de carne o despojos sin cocer"
 },
 {
  "Codigo": "160290690080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160290910080",
  "Titulo": "De ovinos"
 },
 {
  "Codigo": "160290950080",
  "Titulo": "De caprinos"
 },
 {
  "Codigo": "160290990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160300000080",
  "Titulo": "Extractos y jugos de carne, pescado o de crustáceos, moluscos o demás invertebrados acuáticos"
 },
 {
  "Codigo": "160300100080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "160300800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160400000080",
  "Titulo": "Preparaciones y conservas de pescado; caviar y sus sucedáneos preparados con huevas de pescado"
 },
 {
  "Codigo": "160411000010",
  "Titulo": "Pescado entero o en trozos, excepto el pescado picado"
 },
 {
  "Codigo": "160411000080",
  "Titulo": "Salmones"
 },
 {
  "Codigo": "160412000080",
  "Titulo": "Arenques"
 },
 {
  "Codigo": "160412100080",
  "Titulo": "Filetes crudos simplemente rebozados con pasta o con pan rallado (empanados), incluso precocinados en aceite, congelados"
 },
 {
  "Codigo": "160412910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160412910080",
  "Titulo": "En envases herméticamente cerrados"
 },
 {
  "Codigo": "160412990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160413000080",
  "Titulo": "Sardinas, sardinelas y espadines"
 },
 {
  "Codigo": "160413110010",
  "Titulo": "Sardinas"
 },
 {
  "Codigo": "160413110080",
  "Titulo": "En aceite de oliva"
 },
 {
  "Codigo": "160413190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160413900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414000080",
  "Titulo": "Atunes, listados y bonitos (Sarda spp.)"
 },
 {
  "Codigo": "160414210010",
  "Titulo": "Atunes y listados"
 },
 {
  "Codigo": "160414210020",
  "Titulo": "Listados o bonitos de vientre rayado"
 },
 {
  "Codigo": "160414210080",
  "Titulo": "En aceite vegetal"
 },
 {
  "Codigo": "160414260010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414260080",
  "Titulo": "Filetes llamados lomos"
 },
 {
  "Codigo": "160414280080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414310010",
  "Titulo": "Atunes de aleta amarilla (rabiles) (Thunnus albacares)"
 },
 {
  "Codigo": "160414310080",
  "Titulo": "En aceite vegetal"
 },
 {
  "Codigo": "160414360010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414360080",
  "Titulo": "Filetes llamados lomos"
 },
 {
  "Codigo": "160414380080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414410010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414410080",
  "Titulo": "En aceite vegetal"
 },
 {
  "Codigo": "160414460010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414460080",
  "Titulo": "Filetes llamados lomos"
 },
 {
  "Codigo": "160414480080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160414900080",
  "Titulo": "Bonitos (Sarda spp.)"
 },
 {
  "Codigo": "160415000080",
  "Titulo": "Caballas"
 },
 {
  "Codigo": "160415110010",
  "Titulo": "De las especies Scomber scombrus y Scomber japonicus"
 },
 {
  "Codigo": "160415110080",
  "Titulo": "Filetes"
 },
 {
  "Codigo": "160415190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160415900080",
  "Titulo": "De la especie Scomber australasicus"
 },
 {
  "Codigo": "160416000080",
  "Titulo": "Anchoas"
 },
 {
  "Codigo": "160417000080",
  "Titulo": "Anguilas"
 },
 {
  "Codigo": "160418000080",
  "Titulo": "Aletas de tiburón"
 },
 {
  "Codigo": "160419000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160419100080",
  "Titulo": "Salmónidos (excepto los salmones)"
 },
 {
  "Codigo": "160419310010",
  "Titulo": "Pescados del género Euthynnus [excepto los listados (Katsuwonus pelamis)]"
 },
 {
  "Codigo": "160419310080",
  "Titulo": "Filetes llamados lomos"
 },
 {
  "Codigo": "160419390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160419500080",
  "Titulo": "Pescados de las especies Orcynopsis unicolor"
 },
 {
  "Codigo": "160419910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160419910080",
  "Titulo": "Filetes crudos, simplemente rebozados con pasta o con pan rallado (empanados), incluso precocinados en aceite, congelados"
 },
 {
  "Codigo": "160419920010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160419920080",
  "Titulo": "Bacalaos (Gadus morhua, Gadus ogac y Gadus macrocephalus)"
 },
 {
  "Codigo": "160419930080",
  "Titulo": "Carboneros (Pollachius virens)"
 },
 {
  "Codigo": "160419940080",
  "Titulo": "Merluza (Merluccius spp., Urophycis spp.)"
 },
 {
  "Codigo": "160419950080",
  "Titulo": "Abadejos (Theragra chalcogramma y Pollachius pollachius)"
 },
 {
  "Codigo": "160419970080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160420000080",
  "Titulo": "Las demás preparaciones y conservas de pescado"
 },
 {
  "Codigo": "160420050080",
  "Titulo": "Preparaciones de surimi"
 },
 {
  "Codigo": "160420100010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "160420100080",
  "Titulo": "De salmones"
 },
 {
  "Codigo": "160420300080",
  "Titulo": "De salmónidos (excepto los salmones)"
 },
 {
  "Codigo": "160420400080",
  "Titulo": "De anchoas"
 },
 {
  "Codigo": "160420500080",
  "Titulo": "De sardinas, de bonito o de caballas de las especies Scomber scombrus y Scomber japonicus y pescados de las especies Orcynopsis unicolor"
 },
 {
  "Codigo": "160420700080",
  "Titulo": "De atunes, listados y los demás pescados del género Euthynnus"
 },
 {
  "Codigo": "160420900080",
  "Titulo": "De los demás pescados"
 },
 {
  "Codigo": "160431000010",
  "Titulo": "Caviar y sus sucedáneos"
 },
 {
  "Codigo": "160431000080",
  "Titulo": "Caviar"
 },
 {
  "Codigo": "160432000080",
  "Titulo": "Sucedáneos del caviar"
 },
 {
  "Codigo": "160500000080",
  "Titulo": "Crustáceos, moluscos y demás invertebrados acuáticos, preparados o conservados"
 },
 {
  "Codigo": "160510000080",
  "Titulo": "Cangrejos (excepto macruros)"
 },
 {
  "Codigo": "160521000010",
  "Titulo": "Camarones, langostinos y demás decápodos Natantia"
 },
 {
  "Codigo": "160521000080",
  "Titulo": "Presentados en envases no herméticos"
 },
 {
  "Codigo": "160521100080",
  "Titulo": "En envases inmediatos de contenido neto inferior o igual a 2 kg"
 },
 {
  "Codigo": "160521900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160529000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160530000080",
  "Titulo": "Bogavantes"
 },
 {
  "Codigo": "160530100080",
  "Titulo": "Carne de bogavante cocida, destinada a la industria de la transformación para la fabricación de manteca de bogavante, terrinas, sopas o salsas"
 },
 {
  "Codigo": "160530900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160540000080",
  "Titulo": "Los demás crustáceos"
 },
 {
  "Codigo": "160551000010",
  "Titulo": "Moluscos"
 },
 {
  "Codigo": "160551000080",
  "Titulo": "Ostras"
 },
 {
  "Codigo": "160552000080",
  "Titulo": "Veneras (vieiras ), volandeiras y demás moluscos"
 },
 {
  "Codigo": "160553000080",
  "Titulo": "Mejillones"
 },
 {
  "Codigo": "160553100080",
  "Titulo": "En envases herméticamente cerrados"
 },
 {
  "Codigo": "160553900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160554000080",
  "Titulo": "Jibias (sepias)*, globitos, calamares y potas"
 },
 {
  "Codigo": "160555000080",
  "Titulo": "Pulpos"
 },
 {
  "Codigo": "160556000080",
  "Titulo": "Almejas, berberechos y arcas"
 },
 {
  "Codigo": "160557000080",
  "Titulo": "Abulones u orejas de mar"
 },
 {
  "Codigo": "160558000080",
  "Titulo": "Caracoles, excepto los de mar"
 },
 {
  "Codigo": "160559000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "160561000010",
  "Titulo": "Los demás invertebrados acuáticos"
 },
 {
  "Codigo": "160561000080",
  "Titulo": "Pepinos de mar"
 },
 {
  "Codigo": "160562000080",
  "Titulo": "Erizos de mar"
 },
 {
  "Codigo": "160563000080",
  "Titulo": "Medusas"
 },
 {
  "Codigo": "160569000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170021000090",
  "Titulo": "CAPÍTULO 17 - AZÚCARES Y ARTÍCULOS DE CONFITERÍA"
 },
 {
  "Codigo": "170100000080",
  "Titulo": "Azúcar de caña o de remolacha y sacarosa químicamente pura, en estado sólido"
 },
 {
  "Codigo": "170112000010",
  "Titulo": "Azúcar en bruto sin adición de aromatizante ni colorante"
 },
 {
  "Codigo": "170112000080",
  "Titulo": "De remolacha"
 },
 {
  "Codigo": "170112100080",
  "Titulo": "Que se destine al refinado"
 },
 {
  "Codigo": "170112900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170113000080",
  "Titulo": "Azúcar de caña mencionado en la Nota 2 de subpartida de este Capítulo"
 },
 {
  "Codigo": "170113100080",
  "Titulo": "Que se destine al refinado"
 },
 {
  "Codigo": "170113900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170114000080",
  "Titulo": "Los demás azúcares de caña"
 },
 {
  "Codigo": "170114100080",
  "Titulo": "Que se destine al refinado"
 },
 {
  "Codigo": "170114900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170191000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170191000080",
  "Titulo": "Con adición de aromatizante o colorante"
 },
 {
  "Codigo": "170199000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170199100080",
  "Titulo": "Azúcar blanco"
 },
 {
  "Codigo": "170199900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170200000080",
  "Titulo": "Los demás azúcares, incluidas la lactosa, maltosa, glucosa y fructosa (levulosa) químicamente puras, en estado sólido; jarabe de azúcar sin adición de aromatizante ni colorante; sucedáneos de la miel, incluso mezclados con miel natural; azúcar y melaza caramelizados"
 },
 {
  "Codigo": "170211000010",
  "Titulo": "Lactosa y jarabe de lactosa"
 },
 {
  "Codigo": "170211000080",
  "Titulo": "Con un contenido de lactosa superior o igual al 99 % en peso, expresado en lactosa anhidra, calculado sobre producto seco"
 },
 {
  "Codigo": "170219000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170220000080",
  "Titulo": "Azúcar y jarabe de arce (maple)"
 },
 {
  "Codigo": "170220100080",
  "Titulo": "Azúcar sólido de arce, con aromatizantes o colorantes añadidos"
 },
 {
  "Codigo": "170220900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170230000080",
  "Titulo": "Glucosa y jarabe de glucosa, sin fructosa o con un contenido de fructosa sobre producto seco, inferior al 20 % en peso"
 },
 {
  "Codigo": "170230100080",
  "Titulo": "Isoglucosa"
 },
 {
  "Codigo": "170230500010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170230500080",
  "Titulo": "En polvo cristalino blanco, incluso aglomerado"
 },
 {
  "Codigo": "170230900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170240000080",
  "Titulo": "Glucosa y jarabe de glucosa, con un contenido de fructosa sobre producto seco superior o igual al 20 % pero inferior al 50 % en peso, excepto el azúcar invertido"
 },
 {
  "Codigo": "170240100080",
  "Titulo": "Isoglucosa"
 },
 {
  "Codigo": "170240900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170250000080",
  "Titulo": "Fructosa químicamente pura"
 },
 {
  "Codigo": "170260000080",
  "Titulo": "Las demás fructosas y jarabe de fructosa, con un contenido de fructosa sobre producto seco superior al 50 % en peso, excepto el azúcar invertido"
 },
 {
  "Codigo": "170260100080",
  "Titulo": "Isoglucosa"
 },
 {
  "Codigo": "170260800080",
  "Titulo": "Jarabe de inulina"
 },
 {
  "Codigo": "170260950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170290000080",
  "Titulo": "Los demás, incluido el azúcar invertido y demás azúcares y jarabes de azúcar, con un contenido de fructosa sobre producto seco de 50 % en peso"
 },
 {
  "Codigo": "170290100080",
  "Titulo": "Maltosa químicamente pura"
 },
 {
  "Codigo": "170290300080",
  "Titulo": "Isoglucosa"
 },
 {
  "Codigo": "170290500080",
  "Titulo": "Maltodextrina y jarabe de maltodextrina"
 },
 {
  "Codigo": "170290710010",
  "Titulo": "Azúcar y melaza, caramelizados"
 },
 {
  "Codigo": "170290710080",
  "Titulo": "Con un contenido de sacarosa, en estado seco, superior o igual al 50 % en peso"
 },
 {
  "Codigo": "170290750010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170290750080",
  "Titulo": "En polvo, incluso aglomerado"
 },
 {
  "Codigo": "170290790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170290800080",
  "Titulo": "Jarabe de inulina"
 },
 {
  "Codigo": "170290950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170300000080",
  "Titulo": "Melaza procedente de la extracción o del refinado del azúcar"
 },
 {
  "Codigo": "170310000080",
  "Titulo": "Melaza de caña"
 },
 {
  "Codigo": "170390000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "170400000080",
  "Titulo": "Artículos de confitería sin cacao (incluido el chocolate blanco)"
 },
 {
  "Codigo": "170410000080",
  "Titulo": "Chicles y demás gomas de mascar, incluso recubiertos de azúcar"
 },
 {
  "Codigo": "170410100080",
  "Titulo": "Con un contenido de sacarosa inferior al 60 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "170410900080",
  "Titulo": "Con un contenido de sacarosa superior o igual al 60 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "170490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170490100080",
  "Titulo": "Extracto de regaliz con un contenido de sacarosa superior al 10 % en peso, sin adición de otras sustancias"
 },
 {
  "Codigo": "170490300080",
  "Titulo": "Preparación llamada «chocolate blanco»"
 },
 {
  "Codigo": "170490510010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170490510080",
  "Titulo": "Pastas y masas, incluido el mazapán, en envases inmediatos con un contenido neto superior o igual a 1 kg"
 },
 {
  "Codigo": "170490550080",
  "Titulo": "Pastillas para la garganta y caramelos para la tos"
 },
 {
  "Codigo": "170490610080",
  "Titulo": "Grageas, peladillas y dulces con recubrimiento similar"
 },
 {
  "Codigo": "170490650010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170490650080",
  "Titulo": "Gomas y otros artículos de confitería, a base de gelificantes, incluidas las pastas de frutas en forma de artículos de confitería"
 },
 {
  "Codigo": "170490710080",
  "Titulo": "Caramelos de azúcar cocido, incluso rellenos"
 },
 {
  "Codigo": "170490750080",
  "Titulo": "Los demás caramelos"
 },
 {
  "Codigo": "170490810010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "170490810080",
  "Titulo": "Obtenidos por compresión"
 },
 {
  "Codigo": "170490990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "180021000090",
  "Titulo": "CAPÍTULO 18 - CACAO Y SUS PREPARACIONES"
 },
 {
  "Codigo": "180100000080",
  "Titulo": "Cacao en grano, entero o partido, crudo o tostado"
 },
 {
  "Codigo": "180200000080",
  "Titulo": "Cáscara, películas y demás desechos de cacao"
 },
 {
  "Codigo": "180300000080",
  "Titulo": "Pasta de cacao, incluso desgrasada"
 },
 {
  "Codigo": "180310000080",
  "Titulo": "Sin desgrasar"
 },
 {
  "Codigo": "180320000080",
  "Titulo": "Desgrasada total o parcialmente"
 },
 {
  "Codigo": "180400000080",
  "Titulo": "Manteca, grasa y aceite de cacao"
 },
 {
  "Codigo": "180500000080",
  "Titulo": "Cacao en polvo sin adición de azúcar ni otro edulcorante"
 },
 {
  "Codigo": "180600000080",
  "Titulo": "Chocolate y demás preparaciones alimenticias que contengan cacao"
 },
 {
  "Codigo": "180610000080",
  "Titulo": "Cacao en polvo con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "180610150080",
  "Titulo": "Sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "180610200080",
  "Titulo": "Con un contenido de sacarosa o isoglucosa superior o igual al 5 % pero inferior al 65 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "180610300080",
  "Titulo": "Con un contenido de sacarosa o isoglucosa superior o igual al 65 % pero inferior al 80 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "180610900080",
  "Titulo": "Con un contenido de sacarosa o isoglucosa superior o igual al 80 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "Codigo": "180620000080",
  "Titulo": "Las demás preparaciones, en bloques, tabletas o barras con peso superior a 2 kg, o en forma líquida, pastosa o en polvo, gránulos o formas similares, en recipientes o en envases inmediatos con un contenido superior a 2 kg"
 },
 {
  "Codigo": "180620100080",
  "Titulo": "Con un contenido de manteca de cacao superior o igual al 31 % en peso, o con un contenido total de manteca de cacao y materias grasas de la leche superior o igual al 31 % en peso"
 },
 {
  "Codigo": "180620300080",
  "Titulo": "Con un contenido total de manteca de cacao y materias grasas de la leche superior o igual al 25 % pero inferior al 31 % en peso"
 },
 {
  "Codigo": "180620500010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "180620500080",
  "Titulo": "Con un contenido de manteca de cacao superior o igual al 18 % en peso"
 },
 {
  "Codigo": "180620700080",
  "Titulo": "Preparaciones llamadas chocolate milk crumb"
 },
 {
  "Codigo": "180620800080",
  "Titulo": "Baño de cacao"
 },
 {
  "Codigo": "180620950080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "180631000010",
  "Titulo": "Los demás, en bloques, tabletas o barras"
 },
 {
  "Codigo": "180631000080",
  "Titulo": "Rellenos"
 },
 {
  "Codigo": "180632000080",
  "Titulo": "Sin rellenar"
 },
 {
  "Codigo": "180632100080",
  "Titulo": "Con cereales, nueces u otros frutos"
 },
 {
  "Codigo": "180632900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "180690000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "180690110010",
  "Titulo": "Chocolate y artículos de chocolate"
 },
 {
  "Codigo": "180690110020",
  "Titulo": "Bombones, incluso rellenos"
 },
 {
  "Codigo": "180690110080",
  "Titulo": "Con alcohol"
 },
 {
  "Codigo": "180690190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "180690310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "180690310080",
  "Titulo": "Rellenos"
 },
 {
  "Codigo": "180690390080",
  "Titulo": "Sin rellenar"
 },
 {
  "Codigo": "180690500080",
  "Titulo": "Artículos de confitería y sucedáneos fabricados con productos sustitutivos del azúcar, que contengan cacao"
 },
 {
  "Codigo": "180690600080",
  "Titulo": "Pastas para untar que contengan cacao"
 },
 {
  "Codigo": "180690700080",
  "Titulo": "Preparaciones para bebidas que contengan cacao"
 },
 {
  "Codigo": "180690900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190021000090",
  "Titulo": "CAPÍTULO 19 - PREPARACIONES A BASE DE CEREALES, HARINA, ALMIDÓN, FÉCULA O LECHE; PRODUCTOS DE PASTELERÍA"
 },
 {
  "Codigo": "190100000080",
  "Titulo": "Extracto de malta; preparaciones alimenticias de harina, grañones, sémola, almidón, fécula o extracto de malta, que no contengan cacao o con un contenido de cacao inferior al 40 % en peso calculado sobre una base totalmente desgrasada, no expresadas ni comprendidas en otra parte; preparaciones alimenticias de productos de las partidas 0401 a 0404 que no contengan cacao o con un contenido de cacao inferior al 5 % en peso calculado sobre una base totalmente desgrasada, no expresadas ni comprendidas en otra parte"
 },
 {
  "Codigo": "190110000080",
  "Titulo": "Preparaciones para la alimentación de lactantes o niños de corta edad, acondicionadas para la venta al por menor"
 },
 {
  "Codigo": "190120000080",
  "Titulo": "Mezclas y pastas para la preparación de productos de panadería, pastelería o galletería de la partida 1905"
 },
 {
  "Codigo": "190190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190190110010",
  "Titulo": "Extracto de malta"
 },
 {
  "Codigo": "190190110080",
  "Titulo": "Con un contenido de extracto seco superior o igual al 90 % en peso"
 },
 {
  "Codigo": "190190190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190190910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190190910080",
  "Titulo": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa, incluido el azúcar invertido, o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con menos del 5 % en peso (excepto las preparaciones alimenticias en polvo de productos de las partidas 0401 a 0404)"
 },
 {
  "Codigo": "190190950080",
  "Titulo": "Preparaciones alimenticias en polvo, constituidas por una mezcla de leche desnatada o de lactosuero y de grasas o aceites vegetales, con un contenido de grasas o aceites inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "190190990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190200000080",
  "Titulo": "Pastas alimenticias, incluso cocidas o rellenas (de carne u otras sustancias) o preparadas de otra forma, tales como espaguetis, fideos, macarrones, tallarines, lasañas, ñoquis, ravioles, canelones; cuscús, incluso preparado"
 },
 {
  "Codigo": "190211000010",
  "Titulo": "Pastas alimenticias sin cocer, rellenar ni preparar de otra forma"
 },
 {
  "Codigo": "190211000080",
  "Titulo": "Que contengan huevo"
 },
 {
  "Codigo": "190219000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190219100080",
  "Titulo": "Sin harina ni sémola de trigo blando"
 },
 {
  "Codigo": "190219900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190220000080",
  "Titulo": "Pastas alimenticias rellenas, incluso cocidas o preparadas de otra forma"
 },
 {
  "Codigo": "190220100080",
  "Titulo": "Con un contenido de pescados y crustáceos, moluscos y otros invertebrados acuáticos superior al 20 % en peso"
 },
 {
  "Codigo": "190220300080",
  "Titulo": "Con un contenido de embutidos y similares, de carne y despojos de cualquier clase superior al 20 % en peso, incluida la grasa de cualquier naturaleza u origen"
 },
 {
  "Codigo": "190220910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190220910080",
  "Titulo": "Cocidas"
 },
 {
  "Codigo": "190220990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190230000080",
  "Titulo": "Las demás pastas alimenticias"
 },
 {
  "Codigo": "190230100080",
  "Titulo": "Secas"
 },
 {
  "Codigo": "190230900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190240000080",
  "Titulo": "Cuscús"
 },
 {
  "Codigo": "190240100080",
  "Titulo": "Sin preparar"
 },
 {
  "Codigo": "190240900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190300000080",
  "Titulo": "Tapioca y sus sucedáneos preparados con fécula, en copos, grumos, granos perlados, cemiduras o formas similares"
 },
 {
  "Codigo": "190400000080",
  "Titulo": "Productos a base de cereales obtenidos por inflado o tostado (por ejemplo: hojuelas o copos de maíz); cereales (excepto el maíz) en grano o en forma de copos u otro grano trabajado (excepto la harina, grañones y sémola), precocidos o preparados de otro modo, no expresados ni comprendidos en otra parte"
 },
 {
  "Codigo": "190410000080",
  "Titulo": "Productos a base de cereales obtenidos por inflado o tostado"
 },
 {
  "Codigo": "190410100080",
  "Titulo": "A base de maíz"
 },
 {
  "Codigo": "190410300080",
  "Titulo": "A base de arroz"
 },
 {
  "Codigo": "190410900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190420000080",
  "Titulo": "Preparaciones alimenticias obtenidas con copos de cereales sin tostar o con mezclas de copos de cereales sin tostar y copos de cereales tostados o cereales inflados"
 },
 {
  "Codigo": "190420100080",
  "Titulo": "Preparaciones a base de copos de cereales, sin tostar, del tipo Müsli"
 },
 {
  "Codigo": "190420910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190420910080",
  "Titulo": "A base de maíz"
 },
 {
  "Codigo": "190420950080",
  "Titulo": "A base de arroz"
 },
 {
  "Codigo": "190420990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190430000080",
  "Titulo": "Trigo bulgur"
 },
 {
  "Codigo": "190490000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190490100080",
  "Titulo": "A base de arroz"
 },
 {
  "Codigo": "190490800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190500000080",
  "Titulo": "Productos de panadería, pastelería o galletería, incluso con adición de cacao; hostias, sellos vacíos de los tipos utilizados para medicamentos, obleas para sellar, pastas secas de harina, almidón o fécula, en hojas, y productos similares"
 },
 {
  "Codigo": "190510000080",
  "Titulo": "Pan crujiente llamado Knäckebrot"
 },
 {
  "Codigo": "190520000080",
  "Titulo": "Pan de especias"
 },
 {
  "Codigo": "190520100080",
  "Titulo": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, inferior al 30 % en peso"
 },
 {
  "Codigo": "190520300080",
  "Titulo": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, superior o igual al 30 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "190520900080",
  "Titulo": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, superior o igual al 50 % en peso"
 },
 {
  "Codigo": "190531000010",
  "Titulo": "Galletas dulces (con adición de edulcorante); barquillos y obleas, incluso rellenos (gaufrettes, wafers) y waffles(gaufres)"
 },
 {
  "Codigo": "190531000080",
  "Titulo": "Galletas dulces (con adición de edulcorante)"
 },
 {
  "Codigo": "190531110010",
  "Titulo": "Total o parcialmente recubiertos o revestidos de chocolate o de otras preparaciones que contengan cacao"
 },
 {
  "Codigo": "190531110080",
  "Titulo": "En envases inmediatos con un contenido inferior o igual a 85 g"
 },
 {
  "Codigo": "190531190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190531300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190531300080",
  "Titulo": "Con un contenido de materias grasas de la leche superior o igual al 8 % en peso"
 },
 {
  "Codigo": "190531910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190531910080",
  "Titulo": "Galletas dobles rellenas"
 },
 {
  "Codigo": "190531990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "190532000080",
  "Titulo": "Barquillos y obleas, incluso rellenos (gaufrettes, wafers) y waffles(gaufres)"
 },
 {
  "Codigo": "190532050080",
  "Titulo": "Con un contenido de agua superior al 10 % en peso"
 },
 {
  "Codigo": "190532110010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190532110020",
  "Titulo": "Total o parcialmente recubiertos o revestidos de chocolate o de otras preparaciones que contengan cacao"
 },
 {
  "Codigo": "190532110080",
  "Titulo": "En envases inmediatos con un contenido inferior o igual a 85 g"
 },
 {
  "Codigo": "190532190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190532910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190532910080",
  "Titulo": "Salados, rellenos o sin rellenar"
 },
 {
  "Codigo": "190532990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190540000080",
  "Titulo": "Pan tostado y productos similares tostados"
 },
 {
  "Codigo": "190540100080",
  "Titulo": "Pan a la brasa"
 },
 {
  "Codigo": "190540900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190590000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190590100080",
  "Titulo": "Pan ázimo (mazoth)"
 },
 {
  "Codigo": "190590200080",
  "Titulo": "Hostias, sellos vacíos de los tipos utilizados para medicamentos, obleas para sellar, pastas secas de harina, almidón o fécula, en hojas y productos similares"
 },
 {
  "Codigo": "190590300010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190590300080",
  "Titulo": "Pan sin miel, huevos, queso o frutos, con unos contenidos de azúcares y de materias grasas inferiores o iguales al 5 % en peso calculados sobre materia seca"
 },
 {
  "Codigo": "190590450080",
  "Titulo": "Galletas"
 },
 {
  "Codigo": "190590550080",
  "Titulo": "Productos extrudidos o expandidos, salados o aromatizados"
 },
 {
  "Codigo": "190590700010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "190590700080",
  "Titulo": "Con un contenido en peso de sacarosa, azúcar invertido y\/o isoglucosa igual o superior al 5 %"
 },
 {
  "Codigo": "190590800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200021000090",
  "Titulo": "CAPÍTULO 20 - PREPARACIONES DE HORTALIZAS, FRUTAS U OTROS FRUTOS O DEMÁS PARTES DE PLANTAS"
 },
 {
  "Codigo": "200100000080",
  "Titulo": "Hortalizas, frutas u otros frutos y demás partes comestibles de plantas, preparados o conservados en vinagre o en ácido acético"
 },
 {
  "Codigo": "200110000080",
  "Titulo": "Pepinos y pepinillos"
 },
 {
  "Codigo": "200190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200190100080",
  "Titulo": "Chutney de mango"
 },
 {
  "Codigo": "200190200080",
  "Titulo": "Frutos del género Capsicum (excepto los pimientos dulces)"
 },
 {
  "Codigo": "200190300080",
  "Titulo": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "Codigo": "200190400080",
  "Titulo": "Ñames, boniatos y partes comestibles similares de plantas, con un contenido de almidón o de fécula superior o igual al 5 % en peso"
 },
 {
  "Codigo": "200190500080",
  "Titulo": "Setas"
 },
 {
  "Codigo": "200190650080",
  "Titulo": "Aceitunas"
 },
 {
  "Codigo": "200190700080",
  "Titulo": "Pimientos dulces"
 },
 {
  "Codigo": "200190920080",
  "Titulo": "Frutos tropicales y nueces tropicales; palmitos"
 },
 {
  "Codigo": "200190970080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200200000080",
  "Titulo": "Tomates preparados o conservados (excepto en vinagre o en ácido acético)"
 },
 {
  "Codigo": "200210000080",
  "Titulo": "Tomates enteros o en trozos"
 },
 {
  "Codigo": "200210100080",
  "Titulo": "Pelados"
 },
 {
  "Codigo": "200210900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200290000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200290110010",
  "Titulo": "Con un contenido de materia seca inferior al 12 % en peso"
 },
 {
  "Codigo": "200290110080",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200290190080",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200290310010",
  "Titulo": "Con un contenido de materia seca superior o igual al 12 % pero inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200290310080",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200290390080",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200290910010",
  "Titulo": "Con un contenido de materia seca superior al 30 % en peso"
 },
 {
  "Codigo": "200290910080",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200290990080",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200300000080",
  "Titulo": "Hongos y trufas, preparados o conservados (excepto en vinagre o en ácido acético)"
 },
 {
  "Codigo": "200310000080",
  "Titulo": "Hongos del género Agaricus"
 },
 {
  "Codigo": "200310200080",
  "Titulo": "Conservados provisionalmente, cocidos completamente"
 },
 {
  "Codigo": "200310300080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200390000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200390100080",
  "Titulo": "Trufas"
 },
 {
  "Codigo": "200390900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200400000080",
  "Titulo": "Las demás hortalizas preparadas o conservadas (excepto en vinagre o en ácido acético), congeladas, excepto los productos de la partida 2006"
 },
 {
  "Codigo": "200410000080",
  "Titulo": "Patatas (papas)"
 },
 {
  "Codigo": "200410100080",
  "Titulo": "Simplemente cocidas"
 },
 {
  "Codigo": "200410910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200410910080",
  "Titulo": "En forma de harinas, sémolas o copos"
 },
 {
  "Codigo": "200410990080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200490000080",
  "Titulo": "Las demás hortalizas y las mezclas de hortalizas"
 },
 {
  "Codigo": "200490100080",
  "Titulo": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "Codigo": "200490300080",
  "Titulo": "Choucroute, alcaparras y aceitunas"
 },
 {
  "Codigo": "200490500080",
  "Titulo": "Guisantes (Pisum sativum) y judías verdes"
 },
 {
  "Codigo": "200490910010",
  "Titulo": "Las demás, incluidas las mezclas"
 },
 {
  "Codigo": "200490910080",
  "Titulo": "Cebollas, simplemente cocidas"
 },
 {
  "Codigo": "200490980080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200500000080",
  "Titulo": "Las demás hortalizas preparadas o conservadas (excepto en vinagre o en ácido acético), sin congelar, excepto los productos de la partida 2006"
 },
 {
  "Codigo": "200510000080",
  "Titulo": "Hortalizas homogeneizadas"
 },
 {
  "Codigo": "200520000080",
  "Titulo": "Patatas (papas)"
 },
 {
  "Codigo": "200520100080",
  "Titulo": "En forma de harinas, sémolas o copos"
 },
 {
  "Codigo": "200520200010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200520200080",
  "Titulo": "En rodajas finas, fritas, incluso saladas o aromatizadas, en envases herméticamente cerrados, idóneos para su consumo inmediato"
 },
 {
  "Codigo": "200520800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200540000080",
  "Titulo": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "Codigo": "200551000010",
  "Titulo": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "Codigo": "200551000080",
  "Titulo": "Desvainadas"
 },
 {
  "Codigo": "200559000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200560000080",
  "Titulo": "Espárragos"
 },
 {
  "Codigo": "200570000080",
  "Titulo": "Aceitunas"
 },
 {
  "Codigo": "200580000080",
  "Titulo": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "Codigo": "200591000010",
  "Titulo": "Las demás hortalizas y las mezclas de hortalizas"
 },
 {
  "Codigo": "200591000080",
  "Titulo": "Brotes de bambú"
 },
 {
  "Codigo": "200599000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200599100080",
  "Titulo": "Frutos del género Capsicum (excepto los pimientos dulces)"
 },
 {
  "Codigo": "200599200080",
  "Titulo": "Alcaparras"
 },
 {
  "Codigo": "200599300080",
  "Titulo": "Alcachofas"
 },
 {
  "Codigo": "200599500080",
  "Titulo": "Mezclas de hortalizas"
 },
 {
  "Codigo": "200599600080",
  "Titulo": "Choucroute"
 },
 {
  "Codigo": "200599800080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200600000080",
  "Titulo": "Hortalizas, frutas u otros frutos o sus cortezas y demás partes de plantas, confitados con azúcar (almibarados, glaseados o escarchados)"
 },
 {
  "Codigo": "200600100080",
  "Titulo": "Jengibre"
 },
 {
  "Codigo": "200600310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200600310020",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200600310080",
  "Titulo": "Cerezas"
 },
 {
  "Codigo": "200600350080",
  "Titulo": "Frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "200600380080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200600910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200600910080",
  "Titulo": "Frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "200600990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200700000080",
  "Titulo": "Confituras, jaleas y mermeladas, purés y pastas de frutas u otros frutos, obtenidos por cocción, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "200710000080",
  "Titulo": "Preparaciones homogeneizadas"
 },
 {
  "Codigo": "200710100080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200710910010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200710910080",
  "Titulo": "De frutos tropicales"
 },
 {
  "Codigo": "200710990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200791000010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200791000080",
  "Titulo": "De agrios (cítricos)"
 },
 {
  "Codigo": "200791100080",
  "Titulo": "Con un contenido de azúcares superior al 30 % en peso"
 },
 {
  "Codigo": "200791300080",
  "Titulo": "Con un contenido de azúcares superior al 13 % pero inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200791900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200799000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200799100010",
  "Titulo": "Con un contenido de azúcares superior al 30 % en peso"
 },
 {
  "Codigo": "200799100080",
  "Titulo": "Puré y pasta de ciruelas, en envases inmediatos con un contenido neto superior a 100 kg, que se destinen a una transformación industrial"
 },
 {
  "Codigo": "200799200080",
  "Titulo": "Puré y pasta de castañas"
 },
 {
  "Codigo": "200799310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200799310080",
  "Titulo": "De cerezas"
 },
 {
  "Codigo": "200799330080",
  "Titulo": "De fresas (frutillas)"
 },
 {
  "Codigo": "200799350080",
  "Titulo": "De frambuesas"
 },
 {
  "Codigo": "200799390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200799500080",
  "Titulo": "Con un contenido de azúcares superior al 13 % pero inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200799930010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200799930080",
  "Titulo": "De frutos tropicales y nueces tropicales"
 },
 {
  "Codigo": "200799970080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200800000080",
  "Titulo": "Frutas u otros frutos y demás partes comestibles de plantas, preparados o conservados de otro modo, incluso con adición de azúcar u otro edulcorante o alcohol, no expresados ni comprendidos en otra parte"
 },
 {
  "Codigo": "200811000010",
  "Titulo": "Frutos de cáscara, cacahuates (cacahuetes, maníes) y demás semillas, incluso mezclados entre sí"
 },
 {
  "Codigo": "200811000080",
  "Titulo": "Cacahuates (cacahuetes, maníes)"
 },
 {
  "Codigo": "200811100080",
  "Titulo": "Manteca de cacahuete (cacahuate, maní)"
 },
 {
  "Codigo": "200811910010",
  "Titulo": "Los demás, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200811910080",
  "Titulo": "Superior a 1 kg"
 },
 {
  "Codigo": "200811960010",
  "Titulo": "Inferior o igual a 1 kg"
 },
 {
  "Codigo": "200811960080",
  "Titulo": "Tostados"
 },
 {
  "Codigo": "200811980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200819000080",
  "Titulo": "Los demás, incluidas las mezclas"
 },
 {
  "Codigo": "200819120010",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200819120080",
  "Titulo": "Nueces tropicales; mezclas con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200819130010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200819130080",
  "Titulo": "Almendras y pistachos, tostados"
 },
 {
  "Codigo": "200819190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200819920010",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200819920080",
  "Titulo": "Nueces tropicales; mezclas con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200819930010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200819930020",
  "Titulo": "Frutos de cáscara tostados"
 },
 {
  "Codigo": "200819930080",
  "Titulo": "Almendras y pistachos"
 },
 {
  "Codigo": "200819950080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200819990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200820000080",
  "Titulo": "Piñas (ananás)"
 },
 {
  "Codigo": "200820110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200820110020",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200820110080",
  "Titulo": "Con un contenido de azúcares superior al 17 % en peso"
 },
 {
  "Codigo": "200820190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200820310010",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200820310080",
  "Titulo": "Con un contenido de azúcares superior al 19 % en peso"
 },
 {
  "Codigo": "200820390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200820510010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200820510020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200820510080",
  "Titulo": "Con un contenido de azúcares superior al 17 % en peso"
 },
 {
  "Codigo": "200820590080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200820710010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200820710080",
  "Titulo": "Con un contenido de azúcares superior al 19 % en peso"
 },
 {
  "Codigo": "200820790080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200820900080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200830000080",
  "Titulo": "Agrios (cítricos)"
 },
 {
  "Codigo": "200830110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200830110020",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200830110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200830190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200830310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200830310080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200830390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200830510010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200830510020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200830510080",
  "Titulo": "Gajos de toronja y de pomelo"
 },
 {
  "Codigo": "200830550080",
  "Titulo": "Mandarinas, incluidas las tangerinas y satsumas; clementinas, wilkings y demás híbridos similares de agrios"
 },
 {
  "Codigo": "200830590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200830710010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200830710080",
  "Titulo": "Gajos de toronja y de pomelo"
 },
 {
  "Codigo": "200830750080",
  "Titulo": "Mandarinas, incluidas las tangerinas y satsumas; clementinas, wilkings y demás híbridos similares de agrios"
 },
 {
  "Codigo": "200830790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200830900080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200840000080",
  "Titulo": "Peras"
 },
 {
  "Codigo": "200840110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200840110020",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200840110030",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200840110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200840190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840210010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840210080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200840290080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840310010",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200840310080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200840390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840510010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200840510020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200840510080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200840590080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840710010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200840710080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200840790080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200840900080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200850000080",
  "Titulo": "Albaricoques (damascos, chabacanos)"
 },
 {
  "Codigo": "200850110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200850110020",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200850110030",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200850110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200850190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850310080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200850390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850510010",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200850510080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200850590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850610010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200850610020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200850610080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200850690080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850710010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200850710080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200850790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200850920010",
  "Titulo": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200850920080",
  "Titulo": "Superior o igual a 5 kg"
 },
 {
  "Codigo": "200850980080",
  "Titulo": "Inferior a 5 kg"
 },
 {
  "Codigo": "200860000080",
  "Titulo": "Cerezas"
 },
 {
  "Codigo": "200860110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200860110020",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200860110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200860190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200860310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200860310080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200860390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200860500010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200860500020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200860500080",
  "Titulo": "Superior a 1 kg"
 },
 {
  "Codigo": "200860600080",
  "Titulo": "Inferior o igual a 1 kg"
 },
 {
  "Codigo": "200860700010",
  "Titulo": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200860700080",
  "Titulo": "Superior o igual a 4,5 kg"
 },
 {
  "Codigo": "200860900080",
  "Titulo": "Inferior a 4,5 kg"
 },
 {
  "Codigo": "200870000080",
  "Titulo": "Melocotones (duraznos), incluidos los griñones y nectarinas"
 },
 {
  "Codigo": "200870110010",
  "Titulo": "Con alcohol añadido;"
 },
 {
  "Codigo": "200870110020",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200870110030",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200870110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200870190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870310080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200870390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870510010",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200870510080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200870590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870610010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200870610020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200870610080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200870690080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870710010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200870710080",
  "Titulo": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "Codigo": "200870790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200870920010",
  "Titulo": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200870920080",
  "Titulo": "Superior o igual a 5 kg"
 },
 {
  "Codigo": "200870980080",
  "Titulo": "Inferior a 5 kg"
 },
 {
  "Codigo": "200880000080",
  "Titulo": "Fresas (frutillas)"
 },
 {
  "Codigo": "200880110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200880110020",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200880110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200880190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200880310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200880310080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200880390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200880500010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200880500080",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200880700080",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200880900080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200891000010",
  "Titulo": "Los demás, incluidas las mezclas, excepto las mezclas de la subpartida 200819"
 },
 {
  "Codigo": "200891000080",
  "Titulo": "Palmitos"
 },
 {
  "Codigo": "200893000080",
  "Titulo": "Arándanos agrios, trepadores o palustres (Vaccinium macrocarpon, Vaccinium oxycoccos); arándanos rojos o encarnados (Vaccinium vitis-idaea)"
 },
 {
  "Codigo": "200893110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200893110020",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200893110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200893190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200893210010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200893210080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200893290080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200893910010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200893910080",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200893930080",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200893990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200897000080",
  "Titulo": "Mezclas"
 },
 {
  "Codigo": "200897030010",
  "Titulo": "De nueces tropicales y frutos tropicales, con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897030080",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200897050080",
  "Titulo": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "200897120010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897120020",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200897120030",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200897120040",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200897120080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897140080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897160010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897160080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897180080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897320010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897320020",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200897320080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897340080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897360010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897360080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897380080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897510010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200897510020",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200897510030",
  "Titulo": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200897510080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897590080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897720010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897720020",
  "Titulo": "Mezclas en las que ningún fruto sea superior al 50 % en peso del total de los frutos presentados"
 },
 {
  "Codigo": "200897720080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897740080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897760010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897760080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897780080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897920010",
  "Titulo": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200897920020",
  "Titulo": "Superior o igual a 5 kg"
 },
 {
  "Codigo": "200897920080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897930080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897940010",
  "Titulo": "Superior o igual a 4,5 kg pero inferior a 5 kg"
 },
 {
  "Codigo": "200897940080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897960080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200897970010",
  "Titulo": "Inferior a 4,5 kg"
 },
 {
  "Codigo": "200897970080",
  "Titulo": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "Codigo": "200897980080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200899000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899110010",
  "Titulo": "Con alcohol añadido"
 },
 {
  "Codigo": "200899110020",
  "Titulo": "Jengibre"
 },
 {
  "Codigo": "200899110080",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200899190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899210010",
  "Titulo": "Uvas"
 },
 {
  "Codigo": "200899210080",
  "Titulo": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "Codigo": "200899230080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200899240010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899240020",
  "Titulo": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "Codigo": "200899240030",
  "Titulo": "Con un contenido alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200899240080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899280080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899310080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899340080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899360010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899360020",
  "Titulo": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "Codigo": "200899360080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899370080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899380010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899380080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899400080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899410010",
  "Titulo": "Sin alcohol añadido"
 },
 {
  "Codigo": "200899410020",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "Codigo": "200899410080",
  "Titulo": "Jengibre"
 },
 {
  "Codigo": "200899430080",
  "Titulo": "Uvas"
 },
 {
  "Codigo": "200899450080",
  "Titulo": "Ciruelas"
 },
 {
  "Codigo": "200899480080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899490080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899510010",
  "Titulo": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg o menos"
 },
 {
  "Codigo": "200899510080",
  "Titulo": "Jengibre"
 },
 {
  "Codigo": "200899630080",
  "Titulo": "Frutos tropicales"
 },
 {
  "Codigo": "200899670080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200899720010",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200899720020",
  "Titulo": "Ciruelas, en envases inmediatos con un contenido neto"
 },
 {
  "Codigo": "200899720080",
  "Titulo": "Superior o igual a 5 kg"
 },
 {
  "Codigo": "200899780080",
  "Titulo": "Inferior a 5 kg"
 },
 {
  "Codigo": "200899850080",
  "Titulo": "Maíz [excepto el maíz dulce (Zea mays var. saccharata)]"
 },
 {
  "Codigo": "200899910080",
  "Titulo": "Ñames, batatas (boniatos) y partes comestibles similares de plantas con un contenido de almidón o de fécula superior o igual al 5 % en peso"
 },
 {
  "Codigo": "200899990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200900000080",
  "Titulo": "Jugos de frutas u otros frutos (incluido el mosto de uva y el agua de coco) o de hortalizas, sin fermentar y sin adición de alcohol, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "Codigo": "200911000010",
  "Titulo": "Jugo de naranja"
 },
 {
  "Codigo": "200911000080",
  "Titulo": "Congelado"
 },
 {
  "Codigo": "200911110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200911110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200911190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200911910010",
  "Titulo": "De valor Brix inferior o igual a 67"
 },
 {
  "Codigo": "200911910080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto y un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200911990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200912000080",
  "Titulo": "Sin congelar, de valor Brix inferior o igual a 20"
 },
 {
  "Codigo": "200919000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200919110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200919110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200919190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200919910010",
  "Titulo": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "Codigo": "200919910080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto y con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200919980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200921000010",
  "Titulo": "Jugo de toronja; jugo de pomelo"
 },
 {
  "Codigo": "200921000080",
  "Titulo": "De valor Brix inferior o igual a 20"
 },
 {
  "Codigo": "200929000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200929110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200929110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200929190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200929910010",
  "Titulo": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "Codigo": "200929910080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200929990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200931000010",
  "Titulo": "Jugo de cualquier otro agrio (cítrico)"
 },
 {
  "Codigo": "200931000080",
  "Titulo": "De valor Brix inferior o igual a 20"
 },
 {
  "Codigo": "200931110010",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200931110080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200931190080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200931510010",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200931510020",
  "Titulo": "Jugo de limón"
 },
 {
  "Codigo": "200931510080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200931590080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200931910010",
  "Titulo": "Jugo de los demás agrios (cítricos)"
 },
 {
  "Codigo": "200931910080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200931990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200939000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200939110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200939110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200939190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200939310010",
  "Titulo": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "Codigo": "200939310020",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200939310080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200939390080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200939510010",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200939510020",
  "Titulo": "Jugo de limón"
 },
 {
  "Codigo": "200939510080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200939550080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200939590080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200939910010",
  "Titulo": "Jugo de los demás agrios (cítricos)"
 },
 {
  "Codigo": "200939910080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200939950080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200939990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200941000010",
  "Titulo": "Jugo de piña (ananá)"
 },
 {
  "Codigo": "200941000080",
  "Titulo": "De valor Brix inferior o igual a 20"
 },
 {
  "Codigo": "200941920080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200941990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200949000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200949110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200949110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200949190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200949300010",
  "Titulo": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "Codigo": "200949300080",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "Codigo": "200949910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200949910080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200949930080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200949990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200950000080",
  "Titulo": "Jugo de tomate"
 },
 {
  "Codigo": "200950100080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200950900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200961000010",
  "Titulo": "Jugo de uva (incluido el mosto)"
 },
 {
  "Codigo": "200961000080",
  "Titulo": "De valor Brix inferior o igual a 30"
 },
 {
  "Codigo": "200961100080",
  "Titulo": "De valor superior a 18 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200961900080",
  "Titulo": "De valor inferior o igual a 18 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200969000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200969110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200969110080",
  "Titulo": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200969190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200969510010",
  "Titulo": "De valor Brix superior a 30 pero inferior o igual a 67"
 },
 {
  "Codigo": "200969510020",
  "Titulo": "De valor superior a 18 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200969510080",
  "Titulo": "Concentrados"
 },
 {
  "Codigo": "200969590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200969710010",
  "Titulo": "De valor inferior o igual a 18 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200969710020",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200969710080",
  "Titulo": "Concentrado"
 },
 {
  "Codigo": "200969790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200969900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200971000010",
  "Titulo": "Jugo de manzana"
 },
 {
  "Codigo": "200971000080",
  "Titulo": "De valor Brix inferior o igual a 20"
 },
 {
  "Codigo": "200971200080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200971990080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200979000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200979110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200979110080",
  "Titulo": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200979190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200979300010",
  "Titulo": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "Codigo": "200979300080",
  "Titulo": "De valor superior a 18 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "Codigo": "200979910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200979910080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200979980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200981000010",
  "Titulo": "Jugo de cualquier otra fruta o fruto u hortaliza"
 },
 {
  "Codigo": "200981000080",
  "Titulo": "Jugo de arándanos agrios, trepadores o palustres (Vaccinium macrocarpon, Vaccinium oxycoccos); jugo de arándanos rojos o encarnados (Vaccinium vitis-idaea)"
 },
 {
  "Codigo": "200981110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200981110080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200981190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200981310010",
  "Titulo": "De valor Brix inferior o igual a 67"
 },
 {
  "Codigo": "200981310080",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "Codigo": "200981510010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200981510080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200981590080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200981950010",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200981950080",
  "Titulo": "Jugo de fruta de la especie Vaccinium macrocarpon"
 },
 {
  "Codigo": "200981990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200989110020",
  "Titulo": "Jugo de peras"
 },
 {
  "Codigo": "200989110080",
  "Titulo": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200989190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989340010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989340020",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200989340080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989350080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989360010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989360080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989380080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989500010",
  "Titulo": "De valor Brix inferior o igual a 67"
 },
 {
  "Codigo": "200989500020",
  "Titulo": "Jugo de peras"
 },
 {
  "Codigo": "200989500080",
  "Titulo": "De valor superior a 18 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "Codigo": "200989610010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989610080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200989630080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200989690080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200989710010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989710020",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "Codigo": "200989710080",
  "Titulo": "Jugo de cereza"
 },
 {
  "Codigo": "200989730080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989790080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989850010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989850020",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200989850080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989860080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989880010",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200989880080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989890080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200989960010",
  "Titulo": "Sin azúcar añadidos"
 },
 {
  "Codigo": "200989960080",
  "Titulo": "Jugo de cereza"
 },
 {
  "Codigo": "200989970080",
  "Titulo": "Jugo de frutos tropicales"
 },
 {
  "Codigo": "200989990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "200990000080",
  "Titulo": "Mezclas de jugos"
 },
 {
  "Codigo": "200990110010",
  "Titulo": "De valor Brix superior a 67"
 },
 {
  "Codigo": "200990110020",
  "Titulo": "Mezclas de jugo de manzana y de pera"
 },
 {
  "Codigo": "200990110080",
  "Titulo": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200990190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990210010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990210080",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200990290080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990310010",
  "Titulo": "De valor Brix inferior o igual a 67"
 },
 {
  "Codigo": "200990310020",
  "Titulo": "Mezclas de jugo de manzana y de pera"
 },
 {
  "Codigo": "200990310080",
  "Titulo": "De valor inferior o igual a 18 € por 100 kg de peso neto, con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200990390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990410010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990410020",
  "Titulo": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200990410030",
  "Titulo": "Mezclas de jugo de agrios (cítricos) y jugo de piña (ananá)"
 },
 {
  "Codigo": "200990410080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200990490080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990510010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990510080",
  "Titulo": "Con azúcar añadido"
 },
 {
  "Codigo": "200990590080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990710010",
  "Titulo": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "Codigo": "200990710020",
  "Titulo": "Mezclas de jugo de agrios (cítricos) y jugo de piña (ananá)"
 },
 {
  "Codigo": "200990710080",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200990730080",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200990790080",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200990920010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990920020",
  "Titulo": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "Codigo": "200990920080",
  "Titulo": "Mezclas de jugo de frutos tropicales"
 },
 {
  "Codigo": "200990940080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990950010",
  "Titulo": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "200990950080",
  "Titulo": "Mezclas de jugo de frutos tropicales"
 },
 {
  "Codigo": "200990960080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "200990970010",
  "Titulo": "Sin azúcar añadido"
 },
 {
  "Codigo": "200990970080",
  "Titulo": "Mezclas de jugo de frutos tropicales"
 },
 {
  "Codigo": "200990980080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210021000090",
  "Titulo": "CAPÍTULO 21 - PREPARACIONES ALIMENTICIAS DIVERSAS"
 },
 {
  "Codigo": "210100000080",
  "Titulo": "Extractos, esencias y concentrados de café, té o yerba mate y preparaciones a base de estos productos o a base de café, té o yerba mate; achicoria tostada y demás sucedáneos del café tostados y sus extractos, esencias y concentrados"
 },
 {
  "Codigo": "210111000010",
  "Titulo": "Extractos, esencias y concentrados de café y preparaciones a base de estos extractos, esencias o concentrados o a base de café"
 },
 {
  "Codigo": "210111000080",
  "Titulo": "Extractos, esencias y concentrados"
 },
 {
  "Codigo": "210112000080",
  "Titulo": "Preparaciones a base de extractos, esencias o concentrados o a base de café"
 },
 {
  "Codigo": "210112920080",
  "Titulo": "A base de extractos, esencias o concentrados de café"
 },
 {
  "Codigo": "210112980080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210120000080",
  "Titulo": "Extractos, esencias y concentrados de té o de yerba mate y preparaciones a base de estos extractos, esencias o concentrados o a base de té o de yerba mate"
 },
 {
  "Codigo": "210120200080",
  "Titulo": "Extractos, esencias y concentrados"
 },
 {
  "Codigo": "210120920010",
  "Titulo": "Preparaciones"
 },
 {
  "Codigo": "210120920080",
  "Titulo": "A base de extractos, de esencias o de concentrados de té o yerba mate"
 },
 {
  "Codigo": "210120980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210130000080",
  "Titulo": "Achicoria tostada y demás sucedáneos del café tostados y sus extractos, esencias y concentrados"
 },
 {
  "Codigo": "210130110010",
  "Titulo": "Achicoria tostada y demás sucedáneos del café tostados"
 },
 {
  "Codigo": "210130110080",
  "Titulo": "Achicoria tostada"
 },
 {
  "Codigo": "210130190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210130910010",
  "Titulo": "Extractos, esencias y concentrados de achicoria tostada y demás sucedáneos del café tostados"
 },
 {
  "Codigo": "210130910080",
  "Titulo": "De achicoria tostada"
 },
 {
  "Codigo": "210130990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210200000080",
  "Titulo": "Levaduras (vivas o muertas); los demás microorganismos monocelulares muertos (excepto las vacunas de la partida 3002); polvos preparados para esponjar masas"
 },
 {
  "Codigo": "210210000080",
  "Titulo": "Levaduras vivas"
 },
 {
  "Codigo": "210210100080",
  "Titulo": "Levaduras madres seleccionadas (levaduras de cultivo)"
 },
 {
  "Codigo": "210210310010",
  "Titulo": "Levaduras para panificación"
 },
 {
  "Codigo": "210210310080",
  "Titulo": "Secas"
 },
 {
  "Codigo": "210210390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210210900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210220000080",
  "Titulo": "Levaduras muertas; los demás microorganismos monocelulares muertos"
 },
 {
  "Codigo": "210220110010",
  "Titulo": "Levaduras muertas"
 },
 {
  "Codigo": "210220110080",
  "Titulo": "En tabletas, cubos o presentaciones similares, o bien, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "Codigo": "210220190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210220900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210230000080",
  "Titulo": "Polvos preparados para esponjar masas"
 },
 {
  "Codigo": "210300000080",
  "Titulo": "Preparaciones para salsas y salsas preparadas; condimentos y sazonadores, compuestos; harina de mostaza y mostaza preparada"
 },
 {
  "Codigo": "210310000080",
  "Titulo": "Salsa de soja (soya)"
 },
 {
  "Codigo": "210320000080",
  "Titulo": "Kétchup y demás salsas de tomate"
 },
 {
  "Codigo": "210330000080",
  "Titulo": "Harina de mostaza y mostaza preparada"
 },
 {
  "Codigo": "210330100080",
  "Titulo": "Harina de mostaza"
 },
 {
  "Codigo": "210330900080",
  "Titulo": "Mostaza preparada"
 },
 {
  "Codigo": "210390000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210390100080",
  "Titulo": "Chutney de mango, líquido"
 },
 {
  "Codigo": "210390300080",
  "Titulo": "Amargos aromáticos de grado alcohólico volumétrico superior o igual a 44,2 % pero inferior o igual al 49,2 % vol, y con un contenido de gencianas, de especias y de ingredientes diversos superior o igual al 1,5 % pero inferior o igual al 6 % en peso, de azúcar superior o igual al 4 % pero inferior o igual al 10 % en peso, y que se presenten en recipientes de capacidad inferior o igual a 0,5 l"
 },
 {
  "Codigo": "210390900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210400000080",
  "Titulo": "Preparaciones para sopas, potajes o caldos; sopas, potajes o caldos, preparados; preparaciones alimenticias compuestas homogeneizadas"
 },
 {
  "Codigo": "210410000080",
  "Titulo": "Preparaciones para sopas, potajes o caldos; sopas, potajes o caldos, preparados"
 },
 {
  "Codigo": "210420000080",
  "Titulo": "Preparaciones alimenticias compuestas homogeneizadas"
 },
 {
  "Codigo": "210500000080",
  "Titulo": "Helados, incluso con cacao"
 },
 {
  "Codigo": "210500100080",
  "Titulo": "Sin materias grasas de la leche o con un contenido de materias grasas de la leche inferior al 3 % en peso"
 },
 {
  "Codigo": "210500910010",
  "Titulo": "Con un contenido de materias grasas de la leche"
 },
 {
  "Codigo": "210500910080",
  "Titulo": "Superior o igual al 3 % pero inferior al 7 % en peso"
 },
 {
  "Codigo": "210500990080",
  "Titulo": "Superior o igual al 7 % en peso"
 },
 {
  "Codigo": "210600000080",
  "Titulo": "Preparaciones alimenticias no expresadas ni comprendidas en otra parte"
 },
 {
  "Codigo": "210610000080",
  "Titulo": "Concentrados de proteínas y sustancias proteicas texturadas"
 },
 {
  "Codigo": "210610200080",
  "Titulo": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con un contenido inferior al 5 % en peso"
 },
 {
  "Codigo": "210610800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210690000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210690200080",
  "Titulo": "Preparaciones alcohólicas compuestas (excepto las preparadas con sustancias aromáticas), del tipo de las utilizadas para la elaboración de bebidas"
 },
 {
  "Codigo": "210690300010",
  "Titulo": "Jarabes de azúcar aromatizados o con colorantes añadidos"
 },
 {
  "Codigo": "210690300080",
  "Titulo": "De isoglucosa"
 },
 {
  "Codigo": "210690510010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210690510080",
  "Titulo": "De lactosa"
 },
 {
  "Codigo": "210690550080",
  "Titulo": "De glucosa o de maltodextrina"
 },
 {
  "Codigo": "210690590080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "210690920010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "210690920080",
  "Titulo": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con un contenido inferior al 5 % en peso"
 },
 {
  "Codigo": "210690980080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220021000090",
  "Titulo": "CAPÍTULO 22 - BEBIDAS, LÍQUIDOS ALCOHÓLICOS Y VINAGRE"
 },
 {
  "Codigo": "220100000080",
  "Titulo": "Agua, incluidas el agua mineral natural o artificial y la gaseada, sin adición de azúcar u otro edulcorante ni aromatizada; hielo y nieve"
 },
 {
  "Codigo": "220110000080",
  "Titulo": "Agua mineral y agua gaseada"
 },
 {
  "Codigo": "220110110010",
  "Titulo": "Agua mineral natural"
 },
 {
  "Codigo": "220110110080",
  "Titulo": "Sin dióxido de carbono"
 },
 {
  "Codigo": "220110190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220110900080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220190000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220200000080",
  "Titulo": "Agua, incluidas el agua mineral y la gaseada, con adición de azúcar u otro edulcorante o aromatizada, y demás bebidas no alcohólicas, excepto los jugos de frutas u otros frutos o de hortalizas de la partida 2009"
 },
 {
  "Codigo": "220210000080",
  "Titulo": "Agua, incluidas el agua mineral y la gaseada, con adición de azúcar u otro edulcorante o aromatizada"
 },
 {
  "Codigo": "220291000010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220291000080",
  "Titulo": "Cerveza sin alcohol"
 },
 {
  "Codigo": "220299000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220299110010",
  "Titulo": "Que no contengan productos de las partidas 0401 a 0404 o materias grasas procedentes de dichos productos"
 },
 {
  "Codigo": "220299110080",
  "Titulo": "Bebidas a base de soja con un contenido de proteínas en peso  igual o superior al 2,8 %"
 },
 {
  "Codigo": "220299150080",
  "Titulo": "Bebidas a base de soja con un contenido de proteínas en peso inferior al 2,8 %; bebidas a base de frutos de cáscara del Capítulo 08, de cereales del Capítulo 10 o de semillas del Capítulo 12"
 },
 {
  "Codigo": "220299190080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220299910010",
  "Titulo": "Las demás, con un contenido de materias grasas procedentes de los productos de las partidas 0401 a 0404"
 },
 {
  "Codigo": "220299910080",
  "Titulo": "Inferior al 0,2 % en peso"
 },
 {
  "Codigo": "220299950080",
  "Titulo": "Superior o igual al 0,2 % pero inferior al 2 % en peso"
 },
 {
  "Codigo": "220299990080",
  "Titulo": "Superior o igual al 2 % en peso"
 },
 {
  "Codigo": "220300000080",
  "Titulo": "Cerveza de malta"
 },
 {
  "Codigo": "220300010010",
  "Titulo": "En recipientes de contenido inferior o igual a 10 l"
 },
 {
  "Codigo": "220300010080",
  "Titulo": "En botellas"
 },
 {
  "Codigo": "220300090080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220300100080",
  "Titulo": "En recipientes de contenido superior a 10 l"
 },
 {
  "Codigo": "220400000080",
  "Titulo": "Vino de uvas frescas, incluso encabezado; mosto de uva, excepto el de la partida 2009"
 },
 {
  "Codigo": "220410000080",
  "Titulo": "Vino espumoso"
 },
 {
  "Codigo": "220410110010",
  "Titulo": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "Codigo": "220410110080",
  "Titulo": "Champán"
 },
 {
  "Codigo": "220410130080",
  "Titulo": "Cava"
 },
 {
  "Codigo": "220410150080",
  "Titulo": "Prosecco"
 },
 {
  "Codigo": "220410910080",
  "Titulo": "Asti spumante"
 },
 {
  "Codigo": "220410930080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220410940080",
  "Titulo": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220410960080",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220410980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421000010",
  "Titulo": "Los demás vinos; mosto de uva en el que la fermentación se ha impedido o cortado añadiendo alcohol"
 },
 {
  "Codigo": "220421000080",
  "Titulo": "En recipientes con capacidad inferior o igual a 2 l"
 },
 {
  "Codigo": "220421060010",
  "Titulo": "Vino (excepto el de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "Codigo": "220421060080",
  "Titulo": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "Codigo": "220421070080",
  "Titulo": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220421080080",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220421090080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421110010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421110020",
  "Titulo": "Originarios de la Unión Europea"
 },
 {
  "Codigo": "220421110030",
  "Titulo": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "Codigo": "220421110040",
  "Titulo": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "Codigo": "220421110050",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421110080",
  "Titulo": "Alsace (Alsacia)"
 },
 {
  "Codigo": "220421120080",
  "Titulo": "Bordeaux (Burdeos)"
 },
 {
  "Codigo": "220421130080",
  "Titulo": "Bourgogne (Borgoña)"
 },
 {
  "Codigo": "220421170080",
  "Titulo": "Val de Loire (Valle del Loira)"
 },
 {
  "Codigo": "220421180080",
  "Titulo": "Mosel"
 },
 {
  "Codigo": "220421190080",
  "Titulo": "Pfalz"
 },
 {
  "Codigo": "220421220080",
  "Titulo": "Rheinhessen"
 },
 {
  "Codigo": "220421230080",
  "Titulo": "Tokaj"
 },
 {
  "Codigo": "220421240080",
  "Titulo": "Lazio (Lacio)"
 },
 {
  "Codigo": "220421260080",
  "Titulo": "Toscana"
 },
 {
  "Codigo": "220421270080",
  "Titulo": "Trentino, Alto Adige y Friuli"
 },
 {
  "Codigo": "220421280080",
  "Titulo": "Veneto"
 },
 {
  "Codigo": "220421310080",
  "Titulo": "Sicilia"
 },
 {
  "Codigo": "220421320080",
  "Titulo": "Vinho Verde"
 },
 {
  "Codigo": "220421340080",
  "Titulo": "Penedès"
 },
 {
  "Codigo": "220421360080",
  "Titulo": "Rioja"
 },
 {
  "Codigo": "220421370080",
  "Titulo": "Valencia"
 },
 {
  "Codigo": "220421380080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421420010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421420080",
  "Titulo": "Bordeaux (Burdeos)"
 },
 {
  "Codigo": "220421430080",
  "Titulo": "Bourgogne (Borgoña)"
 },
 {
  "Codigo": "220421440080",
  "Titulo": "Beaujolais"
 },
 {
  "Codigo": "220421460080",
  "Titulo": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "Codigo": "220421470080",
  "Titulo": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "Codigo": "220421480080",
  "Titulo": "Val de Loire (Valle del Loira)"
 },
 {
  "Codigo": "220421610080",
  "Titulo": "Sicilia"
 },
 {
  "Codigo": "220421620080",
  "Titulo": "Piemonte (Piamonte)"
 },
 {
  "Codigo": "220421660080",
  "Titulo": "Toscana"
 },
 {
  "Codigo": "220421670080",
  "Titulo": "Trentino y Alto Adige"
 },
 {
  "Codigo": "220421680080",
  "Titulo": "Veneto"
 },
 {
  "Codigo": "220421690080",
  "Titulo": "Dão, Bairrada y Douro (Duero)"
 },
 {
  "Codigo": "220421710080",
  "Titulo": "Navarra"
 },
 {
  "Codigo": "220421740080",
  "Titulo": "Penedès"
 },
 {
  "Codigo": "220421760080",
  "Titulo": "Rioja"
 },
 {
  "Codigo": "220421770080",
  "Titulo": "Valdepeñas"
 },
 {
  "Codigo": "220421780080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421790010",
  "Titulo": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220421790080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421810010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220421810080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421820080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421830010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421830080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421840080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421850010",
  "Titulo": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "Codigo": "220421850020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220421850080",
  "Titulo": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "Codigo": "220421860080",
  "Titulo": "Vino de Jerez"
 },
 {
  "Codigo": "220421870080",
  "Titulo": "Vino de Marsala"
 },
 {
  "Codigo": "220421880080",
  "Titulo": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "Codigo": "220421890080",
  "Titulo": "Vino de Oporto"
 },
 {
  "Codigo": "220421900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421910080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421930010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421930020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220421930080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421940080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421950010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220421950080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421960080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421970010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220421970080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220421980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422000080",
  "Titulo": "En recipientes con capacidad superior a 2 l pero inferior o igual a 10 l"
 },
 {
  "Codigo": "220422100080",
  "Titulo": "Vino (excepto los de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "Codigo": "220422220010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422220020",
  "Titulo": "Originarios de la Unión Europea"
 },
 {
  "Codigo": "220422220030",
  "Titulo": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "Codigo": "220422220040",
  "Titulo": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "Codigo": "220422220080",
  "Titulo": "Bordeaux (Burdeos)"
 },
 {
  "Codigo": "220422230080",
  "Titulo": "Bourgogne (Borgoña)"
 },
 {
  "Codigo": "220422240080",
  "Titulo": "Beaujolais"
 },
 {
  "Codigo": "220422260080",
  "Titulo": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "Codigo": "220422270080",
  "Titulo": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "Codigo": "220422280080",
  "Titulo": "Val de Loire (Valle del Loira)"
 },
 {
  "Codigo": "220422320080",
  "Titulo": "Piemonte (Piamonte)"
 },
 {
  "Codigo": "220422330080",
  "Titulo": "Tokaj"
 },
 {
  "Codigo": "220422380010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422380080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422780080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422790010",
  "Titulo": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220422790080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422810010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220422810080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422820080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422830010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422830080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422840080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422850010",
  "Titulo": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "Codigo": "220422850020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220422850080",
  "Titulo": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "Codigo": "220422860080",
  "Titulo": "Vino de Jerez"
 },
 {
  "Codigo": "220422880080",
  "Titulo": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "Codigo": "220422900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422910080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422930010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422930020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220422930080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422940080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422950010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220422950080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422960080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422970010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220422970080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220422980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429100080",
  "Titulo": "Vino (excepto los de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "Codigo": "220429220010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429220020",
  "Titulo": "Originarios de la Unión Europea"
 },
 {
  "Codigo": "220429220030",
  "Titulo": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "Codigo": "220429220040",
  "Titulo": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "Codigo": "220429220080",
  "Titulo": "Bordeaux (Burdeos)"
 },
 {
  "Codigo": "220429230080",
  "Titulo": "Bourgogne (Borgoña)"
 },
 {
  "Codigo": "220429240080",
  "Titulo": "Beaujolais"
 },
 {
  "Codigo": "220429260080",
  "Titulo": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "Codigo": "220429270080",
  "Titulo": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "Codigo": "220429280080",
  "Titulo": "Val de Loire (Valle del Loira)"
 },
 {
  "Codigo": "220429320080",
  "Titulo": "Piemonte (Piamonte)"
 },
 {
  "Codigo": "220429380010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429380080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429780080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429790010",
  "Titulo": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220429790080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429800080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429810010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220429810080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429820080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429830010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429830080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429840080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429850010",
  "Titulo": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "Codigo": "220429850020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220429850080",
  "Titulo": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "Codigo": "220429860080",
  "Titulo": "Vino de Jerez"
 },
 {
  "Codigo": "220429880080",
  "Titulo": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "Codigo": "220429900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429910080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429930010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429930020",
  "Titulo": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "Codigo": "220429930080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429940080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429950010",
  "Titulo": "Otros vinos de variedades"
 },
 {
  "Codigo": "220429950080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429960080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429970010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220429970080",
  "Titulo": "Vino blanco"
 },
 {
  "Codigo": "220429980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220430000080",
  "Titulo": "Los demás mostos de uva"
 },
 {
  "Codigo": "220430100080",
  "Titulo": "Parcialmente fermentados, incluso «apagados», sin utilización del alcohol"
 },
 {
  "Codigo": "220430920010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220430920020",
  "Titulo": "De masa volúmica inferior o igual a 1,33 g\/cm³ a 20 °C y de grado alcohólico adquirido inferior o igual a 1 % vol"
 },
 {
  "Codigo": "220430920080",
  "Titulo": "Concentrados"
 },
 {
  "Codigo": "220430940080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220430960010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220430960080",
  "Titulo": "Concentrados"
 },
 {
  "Codigo": "220430980080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220500000080",
  "Titulo": "Vermut y demás vinos de uvas frescas preparados con plantas o sustancias aromáticas"
 },
 {
  "Codigo": "220510000080",
  "Titulo": "En recipientes con capacidad inferior o igual a 2 l"
 },
 {
  "Codigo": "220510100080",
  "Titulo": "De grado alcohólico adquirido inferior o igual a 18 % vol"
 },
 {
  "Codigo": "220510900080",
  "Titulo": "De grado alcohólico adquirido superior a 18 % vol"
 },
 {
  "Codigo": "220590000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220590100080",
  "Titulo": "De grado alcohólico adquirido inferior o igual a 18 % vol"
 },
 {
  "Codigo": "220590900080",
  "Titulo": "De grado alcohólico adquirido superior a 18 % vol"
 },
 {
  "Codigo": "220600000080",
  "Titulo": "Las demás bebidas fermentadas (por ejemplo: sidra, perada, aguamiel, sake); mezclas de bebidas fermentadas y mezclas de bebidas fermentadas y bebidas no alcohólicas, no expresadas ni comprendidas en otra parte"
 },
 {
  "Codigo": "220600100080",
  "Titulo": "Piquetas"
 },
 {
  "Codigo": "220600310010",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220600310020",
  "Titulo": "Espumosas"
 },
 {
  "Codigo": "220600310080",
  "Titulo": "Sidra y perada"
 },
 {
  "Codigo": "220600390080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220600510010",
  "Titulo": "No espumosas, en recipientes de contenido"
 },
 {
  "Codigo": "220600510020",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220600510080",
  "Titulo": "Sidra y perada"
 },
 {
  "Codigo": "220600590080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220600810010",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220600810080",
  "Titulo": "Sidra y perada"
 },
 {
  "Codigo": "220600890080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "220700000080",
  "Titulo": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico superior o igual al 80 % vol.; alcohol etílico y aguardiente desnaturalizados, de cualquier graduación"
 },
 {
  "Codigo": "220710000080",
  "Titulo": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico superior o igual al 80 % vol"
 },
 {
  "Codigo": "220720000080",
  "Titulo": "Alcohol etílico y aguardiente desnaturalizados, de cualquier graduación"
 },
 {
  "Codigo": "220800000080",
  "Titulo": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico inferior al 80 % vol.; aguardientes, licores y demás bebidas espirituosas"
 },
 {
  "Codigo": "220820000080",
  "Titulo": "Aguardiente de vino o de orujo de uvas"
 },
 {
  "Codigo": "220820120010",
  "Titulo": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "Codigo": "220820120020",
  "Titulo": "Aguardiente de vino"
 },
 {
  "Codigo": "220820120080",
  "Titulo": "Cognac"
 },
 {
  "Codigo": "220820140080",
  "Titulo": "Armagnac"
 },
 {
  "Codigo": "220820160010",
  "Titulo": "Brandy o Weinbrand"
 },
 {
  "Codigo": "220820160080",
  "Titulo": "Brandy de Jerez"
 },
 {
  "Codigo": "220820180080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220820190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220820260010",
  "Titulo": "Aguardiente de orujo de uva"
 },
 {
  "Codigo": "220820260080",
  "Titulo": "Grappa"
 },
 {
  "Codigo": "220820280080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220820620010",
  "Titulo": "En recipientes de contenido superior a 2 l"
 },
 {
  "Codigo": "220820620020",
  "Titulo": "Aguardiente de vino"
 },
 {
  "Codigo": "220820620080",
  "Titulo": "Cognac"
 },
 {
  "Codigo": "220820660080",
  "Titulo": "Brandy o Weinbrand"
 },
 {
  "Codigo": "220820690080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220820860010",
  "Titulo": "Aguardiente de orujo de uva"
 },
 {
  "Codigo": "220820860080",
  "Titulo": "Grappa"
 },
 {
  "Codigo": "220820880080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220830000080",
  "Titulo": "Whisky"
 },
 {
  "Codigo": "220830110010",
  "Titulo": "Whisky Bourbon, en recipientes de contenido"
 },
 {
  "Codigo": "220830110080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220830190080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220830300010",
  "Titulo": "Whisky Scotch"
 },
 {
  "Codigo": "220830300080",
  "Titulo": "Whisky de malta «single malt»"
 },
 {
  "Codigo": "220830410010",
  "Titulo": "Whisky de malta «blended», en recipientes de contenido"
 },
 {
  "Codigo": "220830410080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220830490080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220830610010",
  "Titulo": "Whisky de grano «single grain» y «blended», en recipientes de contenido"
 },
 {
  "Codigo": "220830610080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220830690080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220830710010",
  "Titulo": "Whisky «blended» de otro tipo, en recipientes de contenido"
 },
 {
  "Codigo": "220830710080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220830790080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220830820010",
  "Titulo": "Los demás, en recipientes de contenido"
 },
 {
  "Codigo": "220830820080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220830880080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220840000080",
  "Titulo": "Ron y demás aguardientes procedentes de la destilación, previa fermentación, de productos de la caña de azúcar"
 },
 {
  "Codigo": "220840110010",
  "Titulo": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "Codigo": "220840110080",
  "Titulo": "Ron con un contenido en sustancias volátiles distintas del alcohol etílico y del alcohol metílico superior o igual a 225 g por hectolitro de alcohol puro (con una tolerancia del 10 %)"
 },
 {
  "Codigo": "220840310010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220840310080",
  "Titulo": "De valor superior a 7,9 € por l de alcohol puro"
 },
 {
  "Codigo": "220840390080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220840510010",
  "Titulo": "En recipientes de contenido superior a 2 l"
 },
 {
  "Codigo": "220840510080",
  "Titulo": "Ron con un contenido en sustancias volátiles distintas del alcohol etílico y del alcohol metílico superior o igual a 225 g por hectolitro de alcohol puro (con una tolerancia del 10 %)"
 },
 {
  "Codigo": "220840910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220840910080",
  "Titulo": "De valor superior a 2 € por l de alcohol puro"
 },
 {
  "Codigo": "220840990080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220850000080",
  "Titulo": "Gin y ginebra"
 },
 {
  "Codigo": "220850110010",
  "Titulo": "Gin, en recipientes de contenido"
 },
 {
  "Codigo": "220850110080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220850190080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220850910010",
  "Titulo": "Ginebra, en recipientes de contenido"
 },
 {
  "Codigo": "220850910080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220850990080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220860000080",
  "Titulo": "Vodka"
 },
 {
  "Codigo": "220860110010",
  "Titulo": "Con grado alcohólico volumétrico inferior o igual a 45,4 % vol., en recipientes de contenido"
 },
 {
  "Codigo": "220860110080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220860190080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220860910010",
  "Titulo": "De grado alcohólico volumétrico superior a 45,4 % vol., en recipientes de contenido"
 },
 {
  "Codigo": "220860910080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220860990080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220870000080",
  "Titulo": "Licores"
 },
 {
  "Codigo": "220870100080",
  "Titulo": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "Codigo": "220870900080",
  "Titulo": "En recipientes de contenido superior a 2 l"
 },
 {
  "Codigo": "220890000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890110010",
  "Titulo": "Arak, en recipientes de contenido"
 },
 {
  "Codigo": "220890110080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220890190080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220890330010",
  "Titulo": "Aguardientes de ciruelas, de peras o de cerezas, en recipientes de contenido"
 },
 {
  "Codigo": "220890330080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220890380080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220890410010",
  "Titulo": "Los demás aguardientes y demás bebidas espirituosas, en recipientes de contenido"
 },
 {
  "Codigo": "220890410020",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220890410080",
  "Titulo": "Ouzo"
 },
 {
  "Codigo": "220890450010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890450020",
  "Titulo": "Aguardientes"
 },
 {
  "Codigo": "220890450030",
  "Titulo": "De frutas"
 },
 {
  "Codigo": "220890450080",
  "Titulo": "Calvados"
 },
 {
  "Codigo": "220890480080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890540010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890540080",
  "Titulo": "Tequila"
 },
 {
  "Codigo": "220890560080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890690080",
  "Titulo": "Las demás bebidas espirituosas"
 },
 {
  "Codigo": "220890710010",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220890710020",
  "Titulo": "Aguardientes"
 },
 {
  "Codigo": "220890710080",
  "Titulo": "De frutas"
 },
 {
  "Codigo": "220890750080",
  "Titulo": "Tequila"
 },
 {
  "Codigo": "220890770080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "220890780080",
  "Titulo": "Otras bebidas espirituosas"
 },
 {
  "Codigo": "220890910010",
  "Titulo": "Alcohol etílico sin desnaturalizar de grado alcohólico volumétrico inferior a 80 % vol., en recipientes de contenido"
 },
 {
  "Codigo": "220890910080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220890990080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220900000080",
  "Titulo": "Vinagre y sucedáneos del vinagre obtenidos a partir del ácido acético"
 },
 {
  "Codigo": "220900110010",
  "Titulo": "Vinagre de vino, en recipientes de contenido"
 },
 {
  "Codigo": "220900110080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220900190080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "220900910010",
  "Titulo": "Los demás, en recipientes de contenido"
 },
 {
  "Codigo": "220900910080",
  "Titulo": "Inferior o igual a 2 l"
 },
 {
  "Codigo": "220900990080",
  "Titulo": "Superior a 2 l"
 },
 {
  "Codigo": "230021000090",
  "Titulo": "CAPÍTULO 23 - RESIDUOS Y DESPERDICIOS DE LAS INDUSTRIAS ALIMENTARIAS; ALIMENTOS PREPARADOS PARA ANIMALES"
 },
 {
  "Codigo": "230100000080",
  "Titulo": "Harina, polvo y pellets, de carne, despojos, pescado o de crustáceos, moluscos o demás invertebrados acuáticos, impropios para la alimentación humana; chicharrones"
 },
 {
  "Codigo": "230110000080",
  "Titulo": "Harina, polvo y pellets, de carne o despojos; chicharrones"
 },
 {
  "Codigo": "230120000080",
  "Titulo": "Harina, polvo y pellets, de pescado o de crustáceos, moluscos o demás invertebrados acuáticos"
 },
 {
  "Codigo": "230200000080",
  "Titulo": "Salvados, moyuelos y demás residuos del cernido, de la molienda o de otros tratamientos de los cereales o de las leguminosas, incluso en pellets"
 },
 {
  "Codigo": "230210000080",
  "Titulo": "De maíz"
 },
 {
  "Codigo": "230210100080",
  "Titulo": "Con un contenido de almidón inferior o igual al 35 % en peso"
 },
 {
  "Codigo": "230210900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230230000080",
  "Titulo": "De trigo"
 },
 {
  "Codigo": "230230100080",
  "Titulo": "Con un contenido de almidón inferior o igual al 28 % en peso, si la proporción de producto que pase por un tamiz de 0,2 mm de anchura de malla es inferior o igual al 10 % en peso o, en caso contrario, si el producto que pase por el tamiz tiene un contenido de cenizas, calculado sobre materia seca, superior o igual al 1,5 % en peso"
 },
 {
  "Codigo": "230230900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230240000080",
  "Titulo": "De los demás cereales"
 },
 {
  "Codigo": "230240020010",
  "Titulo": "De arroz"
 },
 {
  "Codigo": "230240020080",
  "Titulo": "Con un contenido de almidón inferior o igual al 35 % en peso"
 },
 {
  "Codigo": "230240080080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230240100010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230240100080",
  "Titulo": "Con un contenido de almidón inferior o igual al 28 % en peso, si la proporción de producto que pase por un tamiz de 0,2 mm de anchura de malla es inferior o igual al 10 % en peso o, en caso contrario, si el producto que pase por el tamiz tiene un contenido de cenizas, calculado sobre materia seca, superior o igual al 1,5 % en peso"
 },
 {
  "Codigo": "230240900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230250000080",
  "Titulo": "De leguminosas"
 },
 {
  "Codigo": "230300000080",
  "Titulo": "Residuos de la industria del almidón y residuos similares, pulpa de remolacha, bagazo de caña de azúcar y demás desperdicios de la industria azucarera, heces y desperdicios de cervecería o de destilería, incluso en «pellets»"
 },
 {
  "Codigo": "230310000080",
  "Titulo": "Residuos de la industria del almidón y residuos similares"
 },
 {
  "Codigo": "230310110010",
  "Titulo": "Residuos de la industria del almidón de maíz (excepto los de las aguas de remojo concentradas), con un contenido de proteínas, calculado sobre extracto seco"
 },
 {
  "Codigo": "230310110080",
  "Titulo": "Superior al 40 % en peso"
 },
 {
  "Codigo": "230310190080",
  "Titulo": "Inferior o igual al 40 % en peso"
 },
 {
  "Codigo": "230310900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230320000080",
  "Titulo": "Pulpa de remolacha, bagazo de caña de azúcar y demás desperdicios de la industria azucarera"
 },
 {
  "Codigo": "230320100080",
  "Titulo": "Pulpa de remolacha"
 },
 {
  "Codigo": "230320900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230330000080",
  "Titulo": "Heces y desperdicios de cervecería o de destilería"
 },
 {
  "Codigo": "230400000080",
  "Titulo": "Tortas y demás residuos sólidos de la extracción del aceite de soja (soya), incluso molidos o en «pellets»"
 },
 {
  "Codigo": "230500000080",
  "Titulo": "Tortas y demás residuos sólidos de la extracción del aceite de cacahuate (cacahuete, maní), incluso molidos o en «pellets»"
 },
 {
  "Codigo": "230600000080",
  "Titulo": "Tortas y demás residuos sólidos de la extracción de grasas o aceites, vegetales o de origen microbiano, incluso molidos o en «pellets», excepto los de las partidas 2304 o 2305"
 },
 {
  "Codigo": "230610000080",
  "Titulo": "De semillas de algodón"
 },
 {
  "Codigo": "230620000080",
  "Titulo": "De semillas de lino"
 },
 {
  "Codigo": "230630000080",
  "Titulo": "De semillas de girasol"
 },
 {
  "Codigo": "230641000010",
  "Titulo": "De semillas de nabo (nabina) o de colza"
 },
 {
  "Codigo": "230641000080",
  "Titulo": "Con bajo contenido de ácido erúcico"
 },
 {
  "Codigo": "230649000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230650000080",
  "Titulo": "De coco o de copra"
 },
 {
  "Codigo": "230660000080",
  "Titulo": "De nuez o de almendra de palma"
 },
 {
  "Codigo": "230690000080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230690050080",
  "Titulo": "De germen de maíz"
 },
 {
  "Codigo": "230690110010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230690110020",
  "Titulo": "Orujo de aceitunas y demás residuos de la extracción del aceite de oliva"
 },
 {
  "Codigo": "230690110080",
  "Titulo": "Con un contenido de aceite de oliva inferior o igual al 3 % en peso"
 },
 {
  "Codigo": "230690190080",
  "Titulo": "Con un contenido de aceite de oliva superior al 3 % en peso"
 },
 {
  "Codigo": "230690900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230700000080",
  "Titulo": "Lías o heces de vino; tártaro bruto"
 },
 {
  "Codigo": "230700110010",
  "Titulo": "Lías de vino"
 },
 {
  "Codigo": "230700110080",
  "Titulo": "Con un grado alcohólico total inferior o igual al 7,9 % mas y con un contenido de materia seca superior o igual al 25 % en peso"
 },
 {
  "Codigo": "230700190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230700900080",
  "Titulo": "Tártaro bruto"
 },
 {
  "Codigo": "230800000080",
  "Titulo": "Materias vegetales y desperdicios vegetales, residuos y subproductos vegetales, incluso en «pellets», de los tipos utilizados para la alimentación de los animales, no expresados ni comprendidos en otra parte"
 },
 {
  "Codigo": "230800110010",
  "Titulo": "Orujo de uvas"
 },
 {
  "Codigo": "230800110080",
  "Titulo": "Con un grado alcohólico total inferior o igual al 4,3 % mas y un contenido de materia seca superior o igual al 40 % en peso"
 },
 {
  "Codigo": "230800190080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230800400080",
  "Titulo": "Bellotas y castañas de Indias; orujo de frutos (excepto el de uvas)"
 },
 {
  "Codigo": "230800900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230900000080",
  "Titulo": "Preparaciones del tipo de las utilizadas para la alimentación de los animales"
 },
 {
  "Codigo": "230910000080",
  "Titulo": "Alimentos para perros o gatos, acondicionados para la venta al por menor"
 },
 {
  "Codigo": "230910110010",
  "Titulo": "Con almidón, fécula, glucosa o jarabe de glucosa, maltodextrina o jarabe de maltodextrina, de las subpartidas 17023050, 17023090, 17024090, 17029050 y 21069055, o productos lácteos"
 },
 {
  "Codigo": "230910110020",
  "Titulo": "Que contengan almidón, fécula, glucosa, maltodextrina o jarabe de glucosa o de maltodextrina"
 },
 {
  "Codigo": "230910110030",
  "Titulo": "Sin almidón ni fécula o con un contenido de estas materias inferior o igual al 10 % en peso"
 },
 {
  "Codigo": "230910110080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230910130080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230910150080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % pero inferior al 75 % en peso"
 },
 {
  "Codigo": "230910190080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 75 % en peso"
 },
 {
  "Codigo": "230910310010",
  "Titulo": "Con un contenido de almidón o de fécula superior al 10 % pero inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "230910310080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230910330080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230910390080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "Codigo": "230910510010",
  "Titulo": "Con un contenido de almidón o de fécula superior al 30 % en peso"
 },
 {
  "Codigo": "230910510080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230910530080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230910590080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "Codigo": "230910700080",
  "Titulo": "Sin almidón, féculas, glucosa o jarabe de glucosa, maltodextrina, ni jarabe de maltodextrina, pero que contengan productos lácteos"
 },
 {
  "Codigo": "230910900080",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230990000080",
  "Titulo": "Las demás"
 },
 {
  "Codigo": "230990100080",
  "Titulo": "Productos llamados «solubles» de pescado o de mamíferos marinos"
 },
 {
  "Codigo": "230990200080",
  "Titulo": "Contemplados en la Nota complementaria 5 del presente Capítulo"
 },
 {
  "Codigo": "230990310010",
  "Titulo": "Los demás, incluidas las premezclas"
 },
 {
  "Codigo": "230990310020",
  "Titulo": "Con almidón, fécula, glucosa o jarabe de glucosa, maltodextrina o jarabe de maltodextrina, de las subpartidas 17023050, 17023090, 17024090, 17029050 y 21069055, o productos lácteos"
 },
 {
  "Codigo": "230990310030",
  "Titulo": "Que contengan almidón, fécula, glucosa, maltodextrina o jarabes de glucosa o maltodextrina"
 },
 {
  "Codigo": "230990310040",
  "Titulo": "Sin almidón ni fécula o con un contenido de estas materias inferior o igual al 10 % en peso"
 },
 {
  "Codigo": "230990310080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230990330080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230990350080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % pero inferior al 75 % en peso"
 },
 {
  "Codigo": "230990390080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 75 % en peso"
 },
 {
  "Codigo": "230990410010",
  "Titulo": "Con un contenido de almidón o de fécula superior al 10 % pero inferior o igual al 30 % en peso"
 },
 {
  "Codigo": "230990410080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230990430080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230990490080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "Codigo": "230990510010",
  "Titulo": "Con un contenido de almidón o de fécula superior al 30 % en peso"
 },
 {
  "Codigo": "230990510080",
  "Titulo": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "Codigo": "230990530080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "Codigo": "230990590080",
  "Titulo": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "Codigo": "230990700080",
  "Titulo": "Sin almidón, fécula, glucosa o jarabe de glucosa, maltodextrina ni jarabe de maltodextrina, pero que contengan productos lácteos"
 },
 {
  "Codigo": "230990910010",
  "Titulo": "Los demás"
 },
 {
  "Codigo": "230990910080",
  "Titulo": "Pulpa de remolacha con melaza añadida"
 },
 {
  "Codigo": "230990960080",
  "Titulo": "Los demás"
 }
]
/*export const CodigoAran = [{
  "CNKEY": "010011000090",
  "CN": "I",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN I - ANIMALES VIVOS Y PRODUCTOS DEL REINO ANIMAL"
 },
 {
  "CNKEY": "010021000090",
  "CN": "01",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 1 - ANIMALES VIVOS"
 },
 {
  "CNKEY": "010100000080",
  "CN": "0101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caballos, asnos, mulos y burdéganos, vivos"
 },
 {
  "CNKEY": "010121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caballos"
 },
 {
  "CNKEY": "010121000080",
  "CN": "0101 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010129000080",
  "CN": "0101 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010129100080",
  "CN": "0101 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010129900080",
  "CN": "0101 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010130000080",
  "CN": "0101 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Asnos"
 },
 {
  "CNKEY": "010190000080",
  "CN": "0101 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010200000080",
  "CN": "0102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Animales vivos de la especie bovina"
 },
 {
  "CNKEY": "010221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bovinos domésticos"
 },
 {
  "CNKEY": "010221000080",
  "CN": "0102 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010221100080",
  "CN": "0102 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Terneras (que no hayan parido nunca)"
 },
 {
  "CNKEY": "010221300080",
  "CN": "0102 21 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Vacas"
 },
 {
  "CNKEY": "010221900080",
  "CN": "0102 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229000080",
  "CN": "0102 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229050080",
  "CN": "0102 29 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los subgéneros Bibos o Poephagus"
 },
 {
  "CNKEY": "010229100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229100080",
  "CN": "0102 29 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De peso inferior o igual a 80 kg"
 },
 {
  "CNKEY": "010229210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De peso superior a 80 kg pero inferior o igual a 160 kg"
 },
 {
  "CNKEY": "010229210080",
  "CN": "0102 29 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010229290080",
  "CN": "0102 29 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De peso superior a 160 kg pero inferior o igual a 300 kg"
 },
 {
  "CNKEY": "010229410080",
  "CN": "0102 29 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010229490080",
  "CN": "0102 29 49",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De peso superior a 300 kg"
 },
 {
  "CNKEY": "010229510020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Terneras (que no hayan parido nunca)"
 },
 {
  "CNKEY": "010229510080",
  "CN": "0102 29 51",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010229590080",
  "CN": "0102 29 59",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229610010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Vacas"
 },
 {
  "CNKEY": "010229610080",
  "CN": "0102 29 61",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010229690080",
  "CN": "0102 29 69",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "010229910010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010229910080",
  "CN": "0102 29 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Que se destinen al matadero"
 },
 {
  "CNKEY": "010229990080",
  "CN": "0102 29 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Búfalos"
 },
 {
  "CNKEY": "010231000080",
  "CN": "0102 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010239000080",
  "CN": "0102 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010239100080",
  "CN": "0102 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las especies domésticas"
 },
 {
  "CNKEY": "010239900080",
  "CN": "0102 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010290000080",
  "CN": "0102 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010290200080",
  "CN": "0102 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010290910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010290910080",
  "CN": "0102 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las especies domésticas"
 },
 {
  "CNKEY": "010290990080",
  "CN": "0102 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010300000080",
  "CN": "0103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Animales vivos de la especie porcina"
 },
 {
  "CNKEY": "010310000080",
  "CN": "0103 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010391000080",
  "CN": "0103 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso inferior a 50 kg"
 },
 {
  "CNKEY": "010391100080",
  "CN": "0103 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las especies domésticas"
 },
 {
  "CNKEY": "010391900080",
  "CN": "0103 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010392000080",
  "CN": "0103 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior o igual a 50 kg"
 },
 {
  "CNKEY": "010392110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De las especies domésticas"
 },
 {
  "CNKEY": "010392110080",
  "CN": "0103 92 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cerdas que hayan parido por lo menos una vez y con un peso superior o igual a 160 kg"
 },
 {
  "CNKEY": "010392190080",
  "CN": "0103 92 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010392900080",
  "CN": "0103 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010400000080",
  "CN": "0104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Animales vivos de las especies ovina o caprina"
 },
 {
  "CNKEY": "010410000080",
  "CN": "0104 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie ovina"
 },
 {
  "CNKEY": "010410100080",
  "CN": "0104 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010410300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010410300080",
  "CN": "0104 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Corderos (que no tengan más de un año)"
 },
 {
  "CNKEY": "010410800080",
  "CN": "0104 10 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010420000080",
  "CN": "0104 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie caprina"
 },
 {
  "CNKEY": "010420100080",
  "CN": "0104 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Reproductores de raza pura"
 },
 {
  "CNKEY": "010420900080",
  "CN": "0104 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010500000080",
  "CN": "0105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Gallos, gallinas, patos, gansos, pavos (gallipavos) y pintadas, de las especies domésticas, vivos"
 },
 {
  "CNKEY": "010511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De peso inferior o igual a 185 g"
 },
 {
  "CNKEY": "010511000080",
  "CN": "0105 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "010511110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pollitos hembras de selección y de multiplicación"
 },
 {
  "CNKEY": "010511110080",
  "CN": "0105 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Razas ponedoras"
 },
 {
  "CNKEY": "010511190080",
  "CN": "0105 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010511910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010511910080",
  "CN": "0105 11 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Razas ponedoras"
 },
 {
  "CNKEY": "010511990080",
  "CN": "0105 11 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010512000080",
  "CN": "0105 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pavos (gallipavos)"
 },
 {
  "CNKEY": "010513000080",
  "CN": "0105 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Patos"
 },
 {
  "CNKEY": "010514000080",
  "CN": "0105 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Gansos"
 },
 {
  "CNKEY": "010515000080",
  "CN": "0105 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pintadas"
 },
 {
  "CNKEY": "010594000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010594000080",
  "CN": "0105 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "010599000080",
  "CN": "0105 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "010599100080",
  "CN": "0105 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Patos"
 },
 {
  "CNKEY": "010599200080",
  "CN": "0105 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Gansos"
 },
 {
  "CNKEY": "010599300080",
  "CN": "0105 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pavos (gallipavos)"
 },
 {
  "CNKEY": "010599500080",
  "CN": "0105 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pintadas"
 },
 {
  "CNKEY": "010600000080",
  "CN": "0106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás animales vivos"
 },
 {
  "CNKEY": "010611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mamíferos"
 },
 {
  "CNKEY": "010611000080",
  "CN": "0106 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Primates"
 },
 {
  "CNKEY": "010612000080",
  "CN": "0106 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Ballenas, delfines y marsopas (mamíferos del orden Cetacea); manatíes y dugones o dugongos (mamíferos del orden Sirenia); otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "CNKEY": "010613000080",
  "CN": "0106 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Camellos y demás camélidos (Camelidae)"
 },
 {
  "CNKEY": "010614000080",
  "CN": "0106 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Conejos y liebres"
 },
 {
  "CNKEY": "010614100080",
  "CN": "0106 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Conejos domésticos"
 },
 {
  "CNKEY": "010614900080",
  "CN": "0106 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010619000080",
  "CN": "0106 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010620000080",
  "CN": "0106 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "CNKEY": "010631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aves"
 },
 {
  "CNKEY": "010631000080",
  "CN": "0106 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aves de rapiña"
 },
 {
  "CNKEY": "010632000080",
  "CN": "0106 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Psitaciformes (incluidos los loros, guacamayos, cacatúas y demás papagayos)"
 },
 {
  "CNKEY": "010633000080",
  "CN": "0106 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Avestruces; emúes (Dromaius novaehollandiae)"
 },
 {
  "CNKEY": "010639000080",
  "CN": "0106 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "010639100080",
  "CN": "0106 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Palomas"
 },
 {
  "CNKEY": "010639800080",
  "CN": "0106 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Insectos"
 },
 {
  "CNKEY": "010641000080",
  "CN": "0106 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abejas"
 },
 {
  "CNKEY": "010649000080",
  "CN": "0106 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "010690000080",
  "CN": "0106 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020021000090",
  "CN": "02",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 2 - CARNE Y DESPOJOS COMESTIBLES"
 },
 {
  "CNKEY": "020100000080",
  "CN": "0201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne de animales de la especie bovina, fresca o refrigerada"
 },
 {
  "CNKEY": "020110000080",
  "CN": "0201 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020120000080",
  "CN": "0201 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020120200080",
  "CN": "0201 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos llamados «compensados»"
 },
 {
  "CNKEY": "020120300080",
  "CN": "0201 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos delanteros, unidos o separados"
 },
 {
  "CNKEY": "020120500080",
  "CN": "0201 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos traseros, unidos o separados"
 },
 {
  "CNKEY": "020120900080",
  "CN": "0201 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020130000080",
  "CN": "0201 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Deshuesada"
 },
 {
  "CNKEY": "020200000080",
  "CN": "0202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne de animales de la especie bovina, congelada"
 },
 {
  "CNKEY": "020210000080",
  "CN": "0202 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020220000080",
  "CN": "0202 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020220100080",
  "CN": "0202 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos llamados «compensados»"
 },
 {
  "CNKEY": "020220300080",
  "CN": "0202 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos delanteros unidos o separados"
 },
 {
  "CNKEY": "020220500080",
  "CN": "0202 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos traseros unidos o separados"
 },
 {
  "CNKEY": "020220900080",
  "CN": "0202 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020230000080",
  "CN": "0202 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Deshuesada"
 },
 {
  "CNKEY": "020230100080",
  "CN": "0202 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuartos delanteros enteros o cortados en cinco trozos como máximo, presentándose cada cuarto delantero en un solo bloque de congelación, cuartos llamados «compensados» presentados en dos bloques de congelación que contengan, uno, el cuarto delantero completo o cortado en cinco trozos como máximo y, el otro, el cuarto trasero en un solo trozo (excepto el del solomillo)"
 },
 {
  "CNKEY": "020230500080",
  "CN": "0202 30 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cortes de cuartos delanteros y cortes de pecho, llamados «australianos»"
 },
 {
  "CNKEY": "020230900080",
  "CN": "0202 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020300000080",
  "CN": "0203",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne de animales de la especie porcina, fresca, refrigerada o congelada"
 },
 {
  "CNKEY": "020311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fresca o refrigerada"
 },
 {
  "CNKEY": "020311000080",
  "CN": "0203 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020311100080",
  "CN": "0203 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020311900080",
  "CN": "0203 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020312000080",
  "CN": "0203 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Piernas, paletas, y sus trozos, sin deshuesar"
 },
 {
  "CNKEY": "020312110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020312110080",
  "CN": "0203 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Piernas y trozos de pierna"
 },
 {
  "CNKEY": "020312190080",
  "CN": "0203 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Paletas y trozos de paleta"
 },
 {
  "CNKEY": "020312900080",
  "CN": "0203 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020319000080",
  "CN": "0203 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "020319110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020319110080",
  "CN": "0203 19 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Partes delanteras y trozos de partes delanteras"
 },
 {
  "CNKEY": "020319130080",
  "CN": "0203 19 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Chuleteros y trozos de chuletero"
 },
 {
  "CNKEY": "020319150080",
  "CN": "0203 19 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Panceta y trozos de panceta"
 },
 {
  "CNKEY": "020319550010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "020319550080",
  "CN": "0203 19 55",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Deshuesadas"
 },
 {
  "CNKEY": "020319590080",
  "CN": "0203 19 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020319900080",
  "CN": "0203 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Congelada"
 },
 {
  "CNKEY": "020321000080",
  "CN": "0203 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020321100080",
  "CN": "0203 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020321900080",
  "CN": "0203 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020322000080",
  "CN": "0203 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Piernas, paletas, y sus trozos, sin deshuesar"
 },
 {
  "CNKEY": "020322110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020322110080",
  "CN": "0203 22 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Piernas y trozos de pierna"
 },
 {
  "CNKEY": "020322190080",
  "CN": "0203 22 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Paletas y trozos de paleta"
 },
 {
  "CNKEY": "020322900080",
  "CN": "0203 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020329000080",
  "CN": "0203 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "020329110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De animales de la especie porcina doméstica"
 },
 {
  "CNKEY": "020329110080",
  "CN": "0203 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Partes delanteras y trozos de partes delanteras"
 },
 {
  "CNKEY": "020329130080",
  "CN": "0203 29 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Chuleteros y trozos de chuletero"
 },
 {
  "CNKEY": "020329150080",
  "CN": "0203 29 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Panceta y trozos de panceta"
 },
 {
  "CNKEY": "020329550010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "020329550080",
  "CN": "0203 29 55",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Deshuesadas"
 },
 {
  "CNKEY": "020329590080",
  "CN": "0203 29 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020329900080",
  "CN": "0203 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020400000080",
  "CN": "0204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne de animales de las especies ovina o caprina, fresca, refrigerada o congelada"
 },
 {
  "CNKEY": "020410000080",
  "CN": "0204 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Canales o medias canales de cordero, frescas o refrigeradas"
 },
 {
  "CNKEY": "020421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás carnes de animales de la especie ovina, frescas o refrigeradas"
 },
 {
  "CNKEY": "020421000080",
  "CN": "0204 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020422000080",
  "CN": "0204 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020422100080",
  "CN": "0204 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte anterior de la canal o cuarto delantero"
 },
 {
  "CNKEY": "020422300080",
  "CN": "0204 22 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "CNKEY": "020422500080",
  "CN": "0204 22 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte trasera de la canal o cuarto trasero"
 },
 {
  "CNKEY": "020422900080",
  "CN": "0204 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020423000080",
  "CN": "0204 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Deshuesadas"
 },
 {
  "CNKEY": "020430000080",
  "CN": "0204 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Canales o medias canales de cordero, congeladas"
 },
 {
  "CNKEY": "020441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás carnes de animales de la especie ovina, congeladas"
 },
 {
  "CNKEY": "020441000080",
  "CN": "0204 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020442000080",
  "CN": "0204 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020442100080",
  "CN": "0204 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte anterior de la canal o cuarto delantero"
 },
 {
  "CNKEY": "020442300080",
  "CN": "0204 42 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "CNKEY": "020442500080",
  "CN": "0204 42 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte trasera de la canal o cuarto trasero"
 },
 {
  "CNKEY": "020442900080",
  "CN": "0204 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020443000080",
  "CN": "0204 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Deshuesadas"
 },
 {
  "CNKEY": "020443100080",
  "CN": "0204 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cordero"
 },
 {
  "CNKEY": "020443900080",
  "CN": "0204 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "020450000080",
  "CN": "0204 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carne de animales de la especie caprina"
 },
 {
  "CNKEY": "020450110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fresca o refrigerada"
 },
 {
  "CNKEY": "020450110080",
  "CN": "0204 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020450130080",
  "CN": "0204 50 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte anterior de la canal o cuarto delantero"
 },
 {
  "CNKEY": "020450150080",
  "CN": "0204 50 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "CNKEY": "020450190080",
  "CN": "0204 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte trasera de la canal o cuarto trasero"
 },
 {
  "CNKEY": "020450310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "020450310080",
  "CN": "0204 50 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020450390080",
  "CN": "0204 50 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cortes (trozos) deshuesados"
 },
 {
  "CNKEY": "020450510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelada"
 },
 {
  "CNKEY": "020450510080",
  "CN": "0204 50 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En canales o medias canales"
 },
 {
  "CNKEY": "020450530080",
  "CN": "0204 50 53",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte anterior de la canal o cuarto delantero"
 },
 {
  "CNKEY": "020450550080",
  "CN": "0204 50 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Chuleteros de palo o de riñonada o medios chuleteros de palo o de riñonada"
 },
 {
  "CNKEY": "020450590080",
  "CN": "0204 50 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Parte trasera de la canal o cuarto trasero"
 },
 {
  "CNKEY": "020450710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020450710080",
  "CN": "0204 50 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cortes (trozos) sin deshuesar"
 },
 {
  "CNKEY": "020450790080",
  "CN": "0204 50 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cortes (trozos) deshuesados"
 },
 {
  "CNKEY": "020500000080",
  "CN": "0205 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne de animales de las especies caballar, asnal o mular, fresca, refrigerada o congelada"
 },
 {
  "CNKEY": "020500200080",
  "CN": "0205 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fresca o refrigerada"
 },
 {
  "CNKEY": "020500800080",
  "CN": "0205 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Congelada"
 },
 {
  "CNKEY": "020600000080",
  "CN": "0206",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Despojos comestibles de animales de las especies bovina, porcina, ovina, caprina, caballar, asnal o mular, frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "020610000080",
  "CN": "0206 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie bovina, frescos o refrigerados"
 },
 {
  "CNKEY": "020610100080",
  "CN": "0206 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "CNKEY": "020610950010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020610950080",
  "CN": "0206 10 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Entraña gruesa y entraña fina"
 },
 {
  "CNKEY": "020610980080",
  "CN": "0206 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie bovina, congelados"
 },
 {
  "CNKEY": "020621000080",
  "CN": "0206 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lenguas"
 },
 {
  "CNKEY": "020622000080",
  "CN": "0206 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020629000080",
  "CN": "0206 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020629100080",
  "CN": "0206 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "CNKEY": "020629910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020629910080",
  "CN": "0206 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Entraña gruesa y entraña fina"
 },
 {
  "CNKEY": "020629990080",
  "CN": "0206 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020630000080",
  "CN": "0206 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De la especie porcina, frescos o refrigerados"
 },
 {
  "CNKEY": "020641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie porcina, congelados"
 },
 {
  "CNKEY": "020641000080",
  "CN": "0206 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020649000080",
  "CN": "0206 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020680000080",
  "CN": "0206 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, frescos o refrigerados"
 },
 {
  "CNKEY": "020680100080",
  "CN": "0206 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "CNKEY": "020680910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020680910080",
  "CN": "0206 80 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies caballar, asnal y mular"
 },
 {
  "CNKEY": "020680990080",
  "CN": "0206 80 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies ovina y caprina"
 },
 {
  "CNKEY": "020690000080",
  "CN": "0206 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, congelados"
 },
 {
  "CNKEY": "020690100080",
  "CN": "0206 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación de productos farmacéuticos"
 },
 {
  "CNKEY": "020690910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020690910080",
  "CN": "0206 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies caballar, asnal y mular"
 },
 {
  "CNKEY": "020690990080",
  "CN": "0206 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies ovina y caprina"
 },
 {
  "CNKEY": "020700000080",
  "CN": "0207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne y despojos comestibles, de aves de la partida 0105, frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "020711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "020711000080",
  "CN": "0207 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, frescos o refrigerados"
 },
 {
  "CNKEY": "020711100080",
  "CN": "0207 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados y destripados, con la cabeza y las patas, llamados «pollos 83 %»"
 },
 {
  "CNKEY": "020711300080",
  "CN": "0207 11 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin cabeza ni patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pollos 70 %»"
 },
 {
  "CNKEY": "020711900080",
  "CN": "0207 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin cabeza, patas, cuello, corazón, hígado ni molleja, llamados «pollos 65 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020712000080",
  "CN": "0207 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, congelados"
 },
 {
  "CNKEY": "020712100080",
  "CN": "0207 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pollos 70 %»"
 },
 {
  "CNKEY": "020712900080",
  "CN": "0207 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas y sin el cuello, el corazón, el hígado ni la molleja, llamados «pollos 65 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020713000080",
  "CN": "0207 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Trozos y despojos, frescos o refrigerados"
 },
 {
  "CNKEY": "020713100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020713100080",
  "CN": "0207 13 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020713200010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020713200080",
  "CN": "0207 13 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020713300080",
  "CN": "0207 13 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020713400080",
  "CN": "0207 13 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020713500080",
  "CN": "0207 13 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020713600080",
  "CN": "0207 13 60",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020713700080",
  "CN": "0207 13 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020713910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020713910080",
  "CN": "0207 13 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020713990080",
  "CN": "0207 13 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020714000080",
  "CN": "0207 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Trozos y despojos, congelados"
 },
 {
  "CNKEY": "020714100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020714100080",
  "CN": "0207 14 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020714200010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020714200080",
  "CN": "0207 14 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020714300080",
  "CN": "0207 14 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020714400080",
  "CN": "0207 14 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020714500080",
  "CN": "0207 14 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020714600080",
  "CN": "0207 14 60",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020714700080",
  "CN": "0207 14 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020714910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020714910080",
  "CN": "0207 14 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020714990080",
  "CN": "0207 14 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020724000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De pavo (gallipavo)"
 },
 {
  "CNKEY": "020724000080",
  "CN": "0207 24",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, frescos o refrigerados"
 },
 {
  "CNKEY": "020724100080",
  "CN": "0207 24 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pavos 80 %»"
 },
 {
  "CNKEY": "020724900080",
  "CN": "0207 24 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza, el cuello, las patas, el corazón, el hígado ni la molleja, llamados «pavos 73 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020725000080",
  "CN": "0207 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, congelados"
 },
 {
  "CNKEY": "020725100080",
  "CN": "0207 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «pavos 80 %»"
 },
 {
  "CNKEY": "020725900080",
  "CN": "0207 25 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza, el cuello, las patas, el corazón, el hígado ni la molleja, llamados «pavos 73 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020726000080",
  "CN": "0207 26",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Trozos y despojos, frescos o refrigerados"
 },
 {
  "CNKEY": "020726100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020726100080",
  "CN": "0207 26 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020726200010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020726200080",
  "CN": "0207 26 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020726300080",
  "CN": "0207 26 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020726400080",
  "CN": "0207 26 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020726500080",
  "CN": "0207 26 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020726600010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020726600080",
  "CN": "0207 26 60",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Muslos y trozos de muslo"
 },
 {
  "CNKEY": "020726700080",
  "CN": "0207 26 70",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020726800080",
  "CN": "0207 26 80",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020726910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020726910080",
  "CN": "0207 26 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020726990080",
  "CN": "0207 26 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020727000080",
  "CN": "0207 27",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Trozos y despojos, congelados"
 },
 {
  "CNKEY": "020727100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020727100080",
  "CN": "0207 27 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020727200010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020727200080",
  "CN": "0207 27 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020727300080",
  "CN": "0207 27 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020727400080",
  "CN": "0207 27 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020727500080",
  "CN": "0207 27 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020727600010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020727600080",
  "CN": "0207 27 60",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Muslos y trozos de muslo"
 },
 {
  "CNKEY": "020727700080",
  "CN": "0207 27 70",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020727800080",
  "CN": "0207 27 80",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020727910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020727910080",
  "CN": "0207 27 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020727990080",
  "CN": "0207 27 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De pato"
 },
 {
  "CNKEY": "020741000080",
  "CN": "0207 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, frescos o refrigerados"
 },
 {
  "CNKEY": "020741200080",
  "CN": "0207 41 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, sangrados, sin intestinos pero sin eviscerar, con la cabeza y las patas, llamados «patos 85 %»"
 },
 {
  "CNKEY": "020741300080",
  "CN": "0207 41 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, con el cuello, el corazón, el hígado y la molleja, llamados «patos 70 %»"
 },
 {
  "CNKEY": "020741800080",
  "CN": "0207 41 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas y sin el cuello, el corazón, el hígado ni la molleja, llamados «patos 63 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020742000080",
  "CN": "0207 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, congelados"
 },
 {
  "CNKEY": "020742300080",
  "CN": "0207 42 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, pero con el cuello, el corazón, el hígado y la molleja, llamados «patos 70 %»"
 },
 {
  "CNKEY": "020742800080",
  "CN": "0207 42 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza, las patas, el cuello, el hígado ni la molleja, llamados «patos 63 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020743000080",
  "CN": "0207 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hígados grasos, frescos o refrigerados"
 },
 {
  "CNKEY": "020744000080",
  "CN": "0207 44",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, frescos o refrigerados"
 },
 {
  "CNKEY": "020744100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020744100080",
  "CN": "0207 44 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020744210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020744210080",
  "CN": "0207 44 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020744310080",
  "CN": "0207 44 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020744410080",
  "CN": "0207 44 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020744510080",
  "CN": "0207 44 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020744610080",
  "CN": "0207 44 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020744710080",
  "CN": "0207 44 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Patos semideshuesados"
 },
 {
  "CNKEY": "020744810080",
  "CN": "0207 44 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020744910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020744910080",
  "CN": "0207 44 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados (excepto los hígados grasos de pato)"
 },
 {
  "CNKEY": "020744990080",
  "CN": "0207 44 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020745000080",
  "CN": "0207 45",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, congelados"
 },
 {
  "CNKEY": "020745100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020745100080",
  "CN": "0207 45 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020745210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020745210080",
  "CN": "0207 45 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020745310080",
  "CN": "0207 45 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020745410080",
  "CN": "0207 45 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020745510080",
  "CN": "0207 45 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020745610080",
  "CN": "0207 45 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020745710080",
  "CN": "0207 45 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Patos semideshuesados"
 },
 {
  "CNKEY": "020745810080",
  "CN": "0207 45 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020745930010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020745930020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Hígados"
 },
 {
  "CNKEY": "020745930080",
  "CN": "0207 45 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Hígados grasos de pato"
 },
 {
  "CNKEY": "020745950080",
  "CN": "0207 45 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020745990080",
  "CN": "0207 45 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De ganso"
 },
 {
  "CNKEY": "020751000080",
  "CN": "0207 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, frescos o refrigerados"
 },
 {
  "CNKEY": "020751100080",
  "CN": "0207 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, sangrados, sin eviscerar, con la cabeza y las patas, llamados «gansos 82 %»"
 },
 {
  "CNKEY": "020751900080",
  "CN": "0207 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, con el corazón y la molleja o sin ellos, llamados «gansos 75 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020752000080",
  "CN": "0207 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin trocear, congelados"
 },
 {
  "CNKEY": "020752100080",
  "CN": "0207 52 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, sangrados, sin eviscerar, con la cabeza y las patas, llamados «gansos 82 %»"
 },
 {
  "CNKEY": "020752900080",
  "CN": "0207 52 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desplumados, eviscerados, sin la cabeza ni las patas, con el corazón y la molleja o sin ellos, llamados «gansos 75 %», o presentados de otro modo"
 },
 {
  "CNKEY": "020753000080",
  "CN": "0207 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hígados grasos, frescos o refrigerados"
 },
 {
  "CNKEY": "020754000080",
  "CN": "0207 54",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, frescos o refrigerados"
 },
 {
  "CNKEY": "020754100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020754100080",
  "CN": "0207 54 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020754210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020754210080",
  "CN": "0207 54 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020754310080",
  "CN": "0207 54 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020754410080",
  "CN": "0207 54 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020754510080",
  "CN": "0207 54 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020754610080",
  "CN": "0207 54 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020754710080",
  "CN": "0207 54 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Gansos semideshuesados"
 },
 {
  "CNKEY": "020754810080",
  "CN": "0207 54 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020754910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020754910080",
  "CN": "0207 54 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados (excepto los hígados grasos de ganso)"
 },
 {
  "CNKEY": "020754990080",
  "CN": "0207 54 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020755000080",
  "CN": "0207 55",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, congelados"
 },
 {
  "CNKEY": "020755100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020755100080",
  "CN": "0207 55 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020755210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020755210080",
  "CN": "0207 55 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020755310080",
  "CN": "0207 55 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020755410080",
  "CN": "0207 55 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020755510080",
  "CN": "0207 55 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020755610080",
  "CN": "0207 55 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020755710080",
  "CN": "0207 55 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Gansos semideshuesados"
 },
 {
  "CNKEY": "020755810080",
  "CN": "0207 55 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020755930010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020755930020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Hígados"
 },
 {
  "CNKEY": "020755930080",
  "CN": "0207 55 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Hígados grasos de ganso"
 },
 {
  "CNKEY": "020755950080",
  "CN": "0207 55 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020755990080",
  "CN": "0207 55 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020760000080",
  "CN": "0207 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De pintada"
 },
 {
  "CNKEY": "020760050080",
  "CN": "0207 60 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin trocear, frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "020760100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "020760100020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Trozos"
 },
 {
  "CNKEY": "020760100080",
  "CN": "0207 60 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "020760210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "020760210080",
  "CN": "0207 60 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mitades o cuartos"
 },
 {
  "CNKEY": "020760310080",
  "CN": "0207 60 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Alas enteras, incluso sin la punta"
 },
 {
  "CNKEY": "020760410080",
  "CN": "0207 60 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Troncos, cuellos, troncos con cuello, rabadillas y puntas de alas"
 },
 {
  "CNKEY": "020760510080",
  "CN": "0207 60 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Pechugas y trozos de pechuga"
 },
 {
  "CNKEY": "020760610080",
  "CN": "0207 60 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Muslos, contramuslos, y sus trozos"
 },
 {
  "CNKEY": "020760810080",
  "CN": "0207 60 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020760910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "020760910080",
  "CN": "0207 60 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "020760990080",
  "CN": "0207 60 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020800000080",
  "CN": "0208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás carnes y despojos comestibles, frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "020810000080",
  "CN": "0208 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De conejo o liebre"
 },
 {
  "CNKEY": "020810100080",
  "CN": "0208 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De conejos domésticos"
 },
 {
  "CNKEY": "020810900080",
  "CN": "0208 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020830000080",
  "CN": "0208 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De primates"
 },
 {
  "CNKEY": "020840000080",
  "CN": "0208 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De ballenas, delfines y marsopas (mamíferos del orden Cetacea); de manatíes y dugones o dugongos (mamíferos del orden Sirenia); de otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "CNKEY": "020840100080",
  "CN": "0208 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carne de ballenas"
 },
 {
  "CNKEY": "020840200080",
  "CN": "0208 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carne de focas"
 },
 {
  "CNKEY": "020840800080",
  "CN": "0208 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020850000080",
  "CN": "0208 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "CNKEY": "020860000080",
  "CN": "0208 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De camellos y demás camélidos (Camelidae)"
 },
 {
  "CNKEY": "020890000080",
  "CN": "0208 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "020890100080",
  "CN": "0208 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De palomas domésticas"
 },
 {
  "CNKEY": "020890300080",
  "CN": "0208 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De caza (excepto de conejo o liebre)"
 },
 {
  "CNKEY": "020890600080",
  "CN": "0208 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De renos"
 },
 {
  "CNKEY": "020890700080",
  "CN": "0208 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ancas (patas) de rana"
 },
 {
  "CNKEY": "020890980080",
  "CN": "0208 90 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "020900000080",
  "CN": "0209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tocino sin partes magras y grasa de cerdo o de ave sin fundir ni extraer de otro modo, frescos, refrigerados, congelados, salados o en salmuera, secos o ahumados"
 },
 {
  "CNKEY": "020910000080",
  "CN": "0209 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De cerdo"
 },
 {
  "CNKEY": "020910110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tocino"
 },
 {
  "CNKEY": "020910110080",
  "CN": "0209 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fresco, refrigerado, congelado, salado o en salmuera"
 },
 {
  "CNKEY": "020910190080",
  "CN": "0209 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Seco o ahumado"
 },
 {
  "CNKEY": "020910900080",
  "CN": "0209 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grasa de cerdo"
 },
 {
  "CNKEY": "020990000080",
  "CN": "0209 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021000000080",
  "CN": "0210",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carne y despojos comestibles, salados o en salmuera, secos o ahumados; harina y polvo comestibles, de carne o de despojos"
 },
 {
  "CNKEY": "021011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carne de la especie porcina"
 },
 {
  "CNKEY": "021011000080",
  "CN": "0210 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jamones, paletas, y sus trozos, sin deshuesar"
 },
 {
  "CNKEY": "021011110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "021011110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Salados o en salmuera"
 },
 {
  "CNKEY": "021011110080",
  "CN": "0210 11 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Jamones y trozos de jamón"
 },
 {
  "CNKEY": "021011190080",
  "CN": "0210 11 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Paletas y trozos de paleta"
 },
 {
  "CNKEY": "021011310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Secos o ahumados"
 },
 {
  "CNKEY": "021011310080",
  "CN": "0210 11 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Jamones y trozos de jamón"
 },
 {
  "CNKEY": "021011390080",
  "CN": "0210 11 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Paletas y trozos de paleta"
 },
 {
  "CNKEY": "021011900080",
  "CN": "0210 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021012000080",
  "CN": "0210 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tocino entreverado de panza (panceta) y sus trozos"
 },
 {
  "CNKEY": "021012110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "021012110080",
  "CN": "0210 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Salados o en salmuera"
 },
 {
  "CNKEY": "021012190080",
  "CN": "0210 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Secos o ahumados"
 },
 {
  "CNKEY": "021012900080",
  "CN": "0210 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021019000080",
  "CN": "0210 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "021019100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "021019100020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Salados o en salmuera"
 },
 {
  "CNKEY": "021019100080",
  "CN": "0210 19 10",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Medias canales de tipo «bacon» o tres cuartos delanteros"
 },
 {
  "CNKEY": "021019200080",
  "CN": "0210 19 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Tres cuartos traseros o centros"
 },
 {
  "CNKEY": "021019300080",
  "CN": "0210 19 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Partes delanteras y trozos de partes delanteras"
 },
 {
  "CNKEY": "021019400080",
  "CN": "0210 19 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Chuleteros y trozos de chuletero"
 },
 {
  "CNKEY": "021019500080",
  "CN": "0210 19 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021019600010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Secos o ahumados"
 },
 {
  "CNKEY": "021019600080",
  "CN": "0210 19 60",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Partes delanteras y trozos de partes delanteras"
 },
 {
  "CNKEY": "021019700080",
  "CN": "0210 19 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Chuleteros y partes de chuletero"
 },
 {
  "CNKEY": "021019810010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "021019810080",
  "CN": "0210 19 81",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Deshuesados"
 },
 {
  "CNKEY": "021019890080",
  "CN": "0210 19 89",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021019900080",
  "CN": "0210 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021020000080",
  "CN": "0210 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carne de la especie bovina"
 },
 {
  "CNKEY": "021020100080",
  "CN": "0210 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "021020900080",
  "CN": "0210 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Deshuesada"
 },
 {
  "CNKEY": "021091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidos la harina y polvo comestibles, de carne o de despojos"
 },
 {
  "CNKEY": "021091000080",
  "CN": "0210 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De primates"
 },
 {
  "CNKEY": "021092000080",
  "CN": "0210 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ballenas, delfines y marsopas (mamíferos del orden Cetacea); de manatíes y dugones o dugongos (mamíferos del orden Sirenia); de otarios y focas, leones marinos y morsas (mamíferos del suborden Pinnipedia)"
 },
 {
  "CNKEY": "021092100080",
  "CN": "0210 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De ballenas, delfines y marsopas (mamíferos del orden Cetáceos); de manatíes y dugones o dugongos (mamíferos del orden Sirenios)"
 },
 {
  "CNKEY": "021092910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "021092910080",
  "CN": "0210 92 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Carne"
 },
 {
  "CNKEY": "021092920080",
  "CN": "0210 92 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Despojos"
 },
 {
  "CNKEY": "021092990080",
  "CN": "0210 92 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Harina y polvo comestibles, de carne o de despojos"
 },
 {
  "CNKEY": "021093000080",
  "CN": "0210 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De reptiles (incluidas las serpientes y tortugas de mar)"
 },
 {
  "CNKEY": "021099000080",
  "CN": "0210 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Carne"
 },
 {
  "CNKEY": "021099100080",
  "CN": "0210 99 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De caballo, salada, en salmuera o seca"
 },
 {
  "CNKEY": "021099210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De las especies ovina y caprina"
 },
 {
  "CNKEY": "021099210080",
  "CN": "0210 99 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin deshuesar"
 },
 {
  "CNKEY": "021099290080",
  "CN": "0210 99 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Deshuesada"
 },
 {
  "CNKEY": "021099310080",
  "CN": "0210 99 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De renos"
 },
 {
  "CNKEY": "021099390080",
  "CN": "0210 99 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "021099410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Despojos"
 },
 {
  "CNKEY": "021099410020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "021099410080",
  "CN": "0210 99 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Hígados"
 },
 {
  "CNKEY": "021099490080",
  "CN": "0210 99 49",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De la especie bovina"
 },
 {
  "CNKEY": "021099510080",
  "CN": "0210 99 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Entraña gruesa y entraña fina"
 },
 {
  "CNKEY": "021099590080",
  "CN": "0210 99 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099710020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Hígados de aves"
 },
 {
  "CNKEY": "021099710080",
  "CN": "0210 99 71",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Hígados grasos de ganso o de pato, salados o en salmuera"
 },
 {
  "CNKEY": "021099790080",
  "CN": "0210 99 79",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099850080",
  "CN": "0210 99 85",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "021099900080",
  "CN": "0210 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Harina y polvo comestibles, de carne o de despojos"
 },
 {
  "CNKEY": "030021000090",
  "CN": "03",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 3 - PESCADOS Y CRUSTÁCEOS, MOLUSCOS Y DEMÁS INVERTEBRADOS ACUÁTICOS"
 },
 {
  "CNKEY": "030100000080",
  "CN": "0301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Peces vivos"
 },
 {
  "CNKEY": "030111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peces ornamentales"
 },
 {
  "CNKEY": "030111000080",
  "CN": "0301 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De agua dulce"
 },
 {
  "CNKEY": "030119000080",
  "CN": "0301 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás peces vivos"
 },
 {
  "CNKEY": "030191000080",
  "CN": "0301 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030191100080",
  "CN": "0301 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Oncorhynchus apache y Oncorhynchus chrysogaster"
 },
 {
  "CNKEY": "030191900080",
  "CN": "0301 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030192000080",
  "CN": "0301 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Anguilas (Anguilla spp.)"
 },
 {
  "CNKEY": "030192100080",
  "CN": "0301 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De longitud inferior a 12 cm"
 },
 {
  "CNKEY": "030192300080",
  "CN": "0301 92 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De longitud igual o superior a 12 cm pero inferior a 20 cm"
 },
 {
  "CNKEY": "030192900080",
  "CN": "0301 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De longitud igual o superior a 20 cm"
 },
 {
  "CNKEY": "030193000080",
  "CN": "0301 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.)"
 },
 {
  "CNKEY": "030194000080",
  "CN": "0301 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul, del Atlántico y del Pacífico (Thunnus thynnus, Thunnus orientalis)"
 },
 {
  "CNKEY": "030194100080",
  "CN": "0301 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Atunes comunes o de aleta azul del Atlántico (Thunnus thynnus)"
 },
 {
  "CNKEY": "030194900080",
  "CN": "0301 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Atunes comunes o de aleta azul del Pacífico (Thunnus orientalis)"
 },
 {
  "CNKEY": "030195000080",
  "CN": "0301 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Atunes del sur (Thunnus maccoyii)"
 },
 {
  "CNKEY": "030199000080",
  "CN": "0301 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030199110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De agua dulce"
 },
 {
  "CNKEY": "030199110080",
  "CN": "0301 99 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030199170080",
  "CN": "0301 99 17",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030199850080",
  "CN": "0301 99 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030200000080",
  "CN": "0302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pescado fresco o refrigerado (excepto los filetes y demás carne de pescado de la partida 0304)"
 },
 {
  "CNKEY": "030211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Salmónidos, excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030211000080",
  "CN": "0302 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030211100080",
  "CN": "0302 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Oncorhynchus apache y Oncorhynchus chrysogaster"
 },
 {
  "CNKEY": "030211200080",
  "CN": "0302 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Oncorhynchus mykiss, sin descabezar, con branquias y evisceradas, de peso superior a 1,2 kg por unidad, o descabezadas, sin branquias y evisceradas, de peso superior a 1 kg por unidad"
 },
 {
  "CNKEY": "030211800080",
  "CN": "0302 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030213000080",
  "CN": "0302 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus)"
 },
 {
  "CNKEY": "030214000080",
  "CN": "0302 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030219000080",
  "CN": "0302 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescados planos (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae y Citharidae), excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030221000080",
  "CN": "0302 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fletanes («halibut») (Reinhardtius hippoglossoides, Hippoglossus hippoglossus, Hippoglossus stenolepis)"
 },
 {
  "CNKEY": "030221100080",
  "CN": "0302 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») negro (Reinhardtius hippoglossoides)"
 },
 {
  "CNKEY": "030221300080",
  "CN": "0302 21 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») atlántico (Hippoglossus hippoglossus)"
 },
 {
  "CNKEY": "030221900080",
  "CN": "0302 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») del Pacífico (Hippoglossus stenolepis)"
 },
 {
  "CNKEY": "030222000080",
  "CN": "0302 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sollas (Pleuronectes platessa)"
 },
 {
  "CNKEY": "030223000080",
  "CN": "0302 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lenguados (Solea spp.)"
 },
 {
  "CNKEY": "030224000080",
  "CN": "0302 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rodaballos («turbots») (Psetta maxima)"
 },
 {
  "CNKEY": "030229000080",
  "CN": "0302 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030229100080",
  "CN": "0302 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Gallos (Lepidorhombus spp.)"
 },
 {
  "CNKEY": "030229800080",
  "CN": "0302 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Atunes (del género Thunnus), listados (bonitos de vientre rayado)  (Katsuwonus pelamis), excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030231000080",
  "CN": "0302 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Albacoras o atunes blancos (Thunnus alalunga)"
 },
 {
  "CNKEY": "030231100080",
  "CN": "0302 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030231900080",
  "CN": "0302 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030232000080",
  "CN": "0302 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes de aleta amarilla (rabiles) (Thunnus albacares)"
 },
 {
  "CNKEY": "030232100080",
  "CN": "0302 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030232900080",
  "CN": "0302 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030233000080",
  "CN": "0302 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Listados (bonitos de vientre rayado) (Katsuwonus pelamis)"
 },
 {
  "CNKEY": "030233100080",
  "CN": "0302 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030233900080",
  "CN": "0302 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030234000080",
  "CN": "0302 34",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Patudos o atunes ojo grande (Thunnus obesus)"
 },
 {
  "CNKEY": "030234100080",
  "CN": "0302 34 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030234900080",
  "CN": "0302 34 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030235000080",
  "CN": "0302 35",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul, del Atlántico y del Pacífico (Thunnus thynnus, Thunnus orientalis)"
 },
 {
  "CNKEY": "030235110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul del Atlántico (Thunnus thynnus)"
 },
 {
  "CNKEY": "030235110080",
  "CN": "0302 35 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030235190080",
  "CN": "0302 35 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030235910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul del Pacífico (Thunnus orientalis)"
 },
 {
  "CNKEY": "030235910080",
  "CN": "0302 35 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030235990080",
  "CN": "0302 35 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030236000080",
  "CN": "0302 36",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes del sur (Thunnus maccoyii)"
 },
 {
  "CNKEY": "030236100080",
  "CN": "0302 36 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030236900080",
  "CN": "0302 36 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030239000080",
  "CN": "0302 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030239200080",
  "CN": "0302 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030239800080",
  "CN": "0302 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arenques (Clupea harengus, Clupea pallasii), anchoas (Engraulis spp.), sardinas (Sardina pilchardus, Sardinops spp.), sardinelas (Sardinella spp.), espadines (Sprattus sprattus), caballas (Scomber scombrus, Scomber australasicus, Scomber japonicus), caballas de la India (Rastrelliger spp.), carites (Scomberomorus spp.), jureles (Trachurus spp.), pámpanos (Caranx spp.), cobias (Rachycentron canadum), palometones plateados (Pampus spp.), papardas del Pacífico (Cololabis saira), macarelas (Decapterus spp.), capelanes (Mallotus villosus), peces espada (Xiphias gladius), bacoretas orientales (Euthynnus affinis), bonitos (Sarda spp.), agujas, marlines, peces vela o picudos (Istiophoridae), excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030241000080",
  "CN": "0302 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030242000080",
  "CN": "0302 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anchoas (Engraulis spp.)"
 },
 {
  "CNKEY": "030243000080",
  "CN": "0302 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sardinas (Sardina pilchardus, Sardinops spp.), sardinelas (Sardinella spp.) y espadines (Sprattus sprattus)"
 },
 {
  "CNKEY": "030243100080",
  "CN": "0302 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sardinas de la especie Sardina pilchardus"
 },
 {
  "CNKEY": "030243300080",
  "CN": "0302 43 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sardinas del género Sardinops; sardinelas (Sardinella spp.)"
 },
 {
  "CNKEY": "030243900080",
  "CN": "0302 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Espadines (Sprattus sprattus)"
 },
 {
  "CNKEY": "030244000080",
  "CN": "0302 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caballas (Scomber scombrus, Scomber australasicus, Scomber japonicus)"
 },
 {
  "CNKEY": "030245000080",
  "CN": "0302 45",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jureles (Trachurus spp.)"
 },
 {
  "CNKEY": "030245100080",
  "CN": "0302 45 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Jureles (Trachurus trachurus)"
 },
 {
  "CNKEY": "030245300080",
  "CN": "0302 45 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Jureles chilenos (Trachurus murphyi)"
 },
 {
  "CNKEY": "030245900080",
  "CN": "0302 45 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030246000080",
  "CN": "0302 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cobias (Rachycentron canadum)"
 },
 {
  "CNKEY": "030247000080",
  "CN": "0302 47 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030249000080",
  "CN": "0302 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030249110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bacoretas orientales (Euthynnus affinis)"
 },
 {
  "CNKEY": "030249110080",
  "CN": "0302 49 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030249190080",
  "CN": "0302 49 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030249900080",
  "CN": "0302 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae, excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030251000080",
  "CN": "0302 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030251100080",
  "CN": "0302 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Gadus morhua"
 },
 {
  "CNKEY": "030251900080",
  "CN": "0302 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030252000080",
  "CN": "0302 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Eglefinos (Melanogrammus aeglefinus)"
 },
 {
  "CNKEY": "030253000080",
  "CN": "0302 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "030254000080",
  "CN": "0302 54",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Merluzas (Merluccius spp., Urophycis spp.)"
 },
 {
  "CNKEY": "030254110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Merluza del género Merluccius"
 },
 {
  "CNKEY": "030254110080",
  "CN": "0302 54 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza del Cabo (Merluccius capensis) y merluza de altura (Merluccius paradoxus)"
 },
 {
  "CNKEY": "030254150080",
  "CN": "0302 54 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza austral (merluza sureña) (Merluccius australis)"
 },
 {
  "CNKEY": "030254190080",
  "CN": "0302 54 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030254900080",
  "CN": "0302 54 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merluza del género Urophycis"
 },
 {
  "CNKEY": "030255000080",
  "CN": "0302 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abadejos de Alaska (Theragra chalcogramma)"
 },
 {
  "CNKEY": "030256000080",
  "CN": "0302 56 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bacaladillas (Micromesistius poutassou, Micromesistius australis)"
 },
 {
  "CNKEY": "030259000080",
  "CN": "0302 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030259100080",
  "CN": "0302 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030259200080",
  "CN": "0302 59 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merlanes (Merlangius merlangus)"
 },
 {
  "CNKEY": "030259300080",
  "CN": "0302 59 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Abadejos (Pollachius pollachius)"
 },
 {
  "CNKEY": "030259400080",
  "CN": "0302 59 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Maruca y escolanos (Molva spp.)"
 },
 {
  "CNKEY": "030259900080",
  "CN": "0302 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030271000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp.,Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.), excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030271000080",
  "CN": "0302 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.)"
 },
 {
  "CNKEY": "030272000080",
  "CN": "0302 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bagres o pez gato (Pangasius spp., Silurus spp., Clariasspp., Ictalurus spp.)"
 },
 {
  "CNKEY": "030273000080",
  "CN": "0302 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.)"
 },
 {
  "CNKEY": "030274000080",
  "CN": "0302 74 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anguilas (Anguilla spp.)"
 },
 {
  "CNKEY": "030279000080",
  "CN": "0302 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030281000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás pescados, excepto los despojos comestibles de pescado de las subpartidas 030291 a 030299"
 },
 {
  "CNKEY": "030281000080",
  "CN": "0302 81",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030281150080",
  "CN": "0302 81 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias)y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030281300080",
  "CN": "0302 81 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030281400080",
  "CN": "0302 81 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030281800080",
  "CN": "0302 81 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030282000080",
  "CN": "0302 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030283000080",
  "CN": "0302 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030284000080",
  "CN": "0302 84",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Róbalos (Dicentrarchus spp.)"
 },
 {
  "CNKEY": "030284100080",
  "CN": "0302 84 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Róbalos o lubinas (Dicentrarchus labrax)"
 },
 {
  "CNKEY": "030284900080",
  "CN": "0302 84 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030285000080",
  "CN": "0302 85",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sargos (doradas, espáridos) (Sparidae)"
 },
 {
  "CNKEY": "030285100080",
  "CN": "0302 85 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Dentex dentex y Pagellus spp."
 },
 {
  "CNKEY": "030285300080",
  "CN": "0302 85 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pargos dorados (Sparus aurata)"
 },
 {
  "CNKEY": "030285900080",
  "CN": "0302 85 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030289000080",
  "CN": "0302 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030289100080",
  "CN": "0302 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De agua dulce"
 },
 {
  "CNKEY": "030289210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030289210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pescados del género Euthynnus (excepto las bacoretas orientales (Euthynnus affinis) incluidas en la subpartida 030249)"
 },
 {
  "CNKEY": "030289210080",
  "CN": "0302 89 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030289290080",
  "CN": "0302 89 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030289310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Gallinetas nórdicas (Sebastes spp.)"
 },
 {
  "CNKEY": "030289310080",
  "CN": "0302 89 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De la especie Sebastes marinus"
 },
 {
  "CNKEY": "030289390080",
  "CN": "0302 89 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030289400080",
  "CN": "0302 89 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Japutas (Brama spp.)"
 },
 {
  "CNKEY": "030289500080",
  "CN": "0302 89 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rapes (Lophius spp.)"
 },
 {
  "CNKEY": "030289600080",
  "CN": "0302 89 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rosadas (Genypterus blacodes)"
 },
 {
  "CNKEY": "030289900080",
  "CN": "0302 89 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hígados, huevas, lechas, aletas, cabezas,colas, vejigas natatorias y demás despojos comestibles de pescado"
 },
 {
  "CNKEY": "030291000080",
  "CN": "0302 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hígados, huevas y lechas"
 },
 {
  "CNKEY": "030292000080",
  "CN": "0302 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aletas de tiburón"
 },
 {
  "CNKEY": "030299000080",
  "CN": "0302 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030300000080",
  "CN": "0303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pescado congelado (excepto los filetes y demás carne de pescado de la partida 0304)"
 },
 {
  "CNKEY": "030311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Salmónidos, excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030311000080",
  "CN": "0303 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones rojos (Oncorhynchus nerka)"
 },
 {
  "CNKEY": "030312000080",
  "CN": "0303 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás salmones del Pacífico (Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus)"
 },
 {
  "CNKEY": "030313000080",
  "CN": "0303 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030314000080",
  "CN": "0303 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030314100080",
  "CN": "0303 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Oncorhynchus apache y Oncorhynchus chrysogaster"
 },
 {
  "CNKEY": "030314200080",
  "CN": "0303 14 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Oncorhynchus mykiss, sin descabezar, con branquias y evisceradas, de peso superior a 1,2 kg por unidad, o descabezadas, sin branquias y evisceradas, de peso superior a 1 kg por unidad"
 },
 {
  "CNKEY": "030314900080",
  "CN": "0303 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030319000080",
  "CN": "0303 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030323000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp.,Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.), excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030323000080",
  "CN": "0303 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.)"
 },
 {
  "CNKEY": "030324000080",
  "CN": "0303 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)"
 },
 {
  "CNKEY": "030325000080",
  "CN": "0303 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.)"
 },
 {
  "CNKEY": "030326000080",
  "CN": "0303 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anguilas (Anguilla spp.)"
 },
 {
  "CNKEY": "030329000080",
  "CN": "0303 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescados planos (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae y Citharidae), excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030331000080",
  "CN": "0303 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fletanes («halibut») (Reinhardtius hippoglossoides, Hippoglossus hippoglossus, Hippoglossus stenolepis)"
 },
 {
  "CNKEY": "030331100080",
  "CN": "0303 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») negro (Reinhardtius hippoglossoides)"
 },
 {
  "CNKEY": "030331300080",
  "CN": "0303 31 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») atlántico (Hippoglossus hippoglossus)"
 },
 {
  "CNKEY": "030331900080",
  "CN": "0303 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») del Pacífico (Hippoglossus stenolepis)"
 },
 {
  "CNKEY": "030332000080",
  "CN": "0303 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sollas (Pleuronectes platessa)"
 },
 {
  "CNKEY": "030333000080",
  "CN": "0303 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lenguados (Solea spp.)"
 },
 {
  "CNKEY": "030334000080",
  "CN": "0303 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rodaballos («turbots») (Psetta maxima)"
 },
 {
  "CNKEY": "030339000080",
  "CN": "0303 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030339100080",
  "CN": "0303 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Platija (Platichthys flesus)"
 },
 {
  "CNKEY": "030339300080",
  "CN": "0303 39 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pescados del género Rhombosolea"
 },
 {
  "CNKEY": "030339500080",
  "CN": "0303 39 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pescados de las especiesPelotreis flavilatus y Peltorhamphus novaezelandiae"
 },
 {
  "CNKEY": "030339850080",
  "CN": "0303 39 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Atunes (del género Thunnus), listados (bonitos de vientre rayado) (Katsuwonus pelamis), excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030341000080",
  "CN": "0303 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Albacoras o atunes blancos (Thunnus alalunga)"
 },
 {
  "CNKEY": "030341100080",
  "CN": "0303 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030341900080",
  "CN": "0303 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030342000080",
  "CN": "0303 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes de aleta amarilla (rabiles) (Thunnus albacares)"
 },
 {
  "CNKEY": "030342200080",
  "CN": "0303 42 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030342900080",
  "CN": "0303 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030343000080",
  "CN": "0303 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Listados (bonitos de vientre rayado) (Katsuwonus pelamis)"
 },
 {
  "CNKEY": "030343100080",
  "CN": "0303 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030343900080",
  "CN": "0303 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030344000080",
  "CN": "0303 44",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Patudos o atunes ojo grande (Thunnus obesus)"
 },
 {
  "CNKEY": "030344100080",
  "CN": "0303 44 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030344900080",
  "CN": "0303 44 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030345000080",
  "CN": "0303 45",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul, del Atlántico y del Pacífico (Thunnus thynnus, Thunnus orientalis)"
 },
 {
  "CNKEY": "030345120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul del Atlántico (Thunnus thynnus)"
 },
 {
  "CNKEY": "030345120080",
  "CN": "0303 45 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030345180080",
  "CN": "0303 45 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030345910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Atunes comunes o de aleta azul del Pacífico (Thunnus orientalis)"
 },
 {
  "CNKEY": "030345910080",
  "CN": "0303 45 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030345990080",
  "CN": "0303 45 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030346000080",
  "CN": "0303 46",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes del sur (Thunnus maccoyii)"
 },
 {
  "CNKEY": "030346100080",
  "CN": "0303 46 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030346900080",
  "CN": "0303 46 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030349000080",
  "CN": "0303 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030349200080",
  "CN": "0303 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030349850080",
  "CN": "0303 49 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030351000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arenques (Clupea harengus, Clupea pallasii), anchoas (Engraulis spp.), sardinas (Sardina pilchardus, Sardinops spp.), sardinelas (Sardinella spp.), espadines (Sprattus sprattus), caballas (Scomber scombrus, Scomber australasicus, Scomber japonicus), caballas de la India (Rastrelliger spp.), carites (Scomberomorus spp.), jureles (Trachurus spp.), caballas, pámpanos (Caranx spp.), cobias (Rachycentron canadum),  palometones plateados (Pampus spp.), papardas del Pacífico (Cololabis saira), macarelas (Decapterus spp.), capelanes (Mallotus villosus), peces espada (Xiphias gladius), bacoretas orientales (Euthynnus affinis), bonitos (Sarda spp.), agujas, marlines, peces vela o picudos (Istiophoridae), excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030351000080",
  "CN": "0303 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030353000080",
  "CN": "0303 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sardinas (Sardina pilchardus, Sardinops spp.), sardinelas (Sardinella spp.) y espadines (Sprattus sprattus)"
 },
 {
  "CNKEY": "030353100080",
  "CN": "0303 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sardinas de la especie Sardina pilchardus"
 },
 {
  "CNKEY": "030353300080",
  "CN": "0303 53 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sardinas del género Sardinops; sardinelas (Sardinella spp.)"
 },
 {
  "CNKEY": "030353900080",
  "CN": "0303 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Espadines (Sprattus sprattus)"
 },
 {
  "CNKEY": "030354000080",
  "CN": "0303 54",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Caballas (Scomber scombrus, Scomber australasicus, Scomber japonicus)"
 },
 {
  "CNKEY": "030354100080",
  "CN": "0303 54 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Scomber scombrus y Scomber japonicus"
 },
 {
  "CNKEY": "030354900080",
  "CN": "0303 54 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Scomber australasicus"
 },
 {
  "CNKEY": "030355000080",
  "CN": "0303 55",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jureles (Trachurus spp.)"
 },
 {
  "CNKEY": "030355100080",
  "CN": "0303 55 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Jureles (Trachurus trachurus)"
 },
 {
  "CNKEY": "030355300080",
  "CN": "0303 55 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Jureles chilenos (Trachurus murphyi)"
 },
 {
  "CNKEY": "030355900080",
  "CN": "0303 55 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030356000080",
  "CN": "0303 56 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cobias (Rachycentron canadum)"
 },
 {
  "CNKEY": "030357000080",
  "CN": "0303 57 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030359000080",
  "CN": "0303 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030359100080",
  "CN": "0303 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Anchoas (Engraulis spp.)"
 },
 {
  "CNKEY": "030359210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bacoretas orientales (Euthynnus affinis)"
 },
 {
  "CNKEY": "030359210080",
  "CN": "0303 59 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030359290080",
  "CN": "0303 59 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030359900080",
  "CN": "0303 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030363000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae, excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030363000080",
  "CN": "0303 63",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030363100080",
  "CN": "0303 63 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Gadus morhua"
 },
 {
  "CNKEY": "030363300080",
  "CN": "0303 63 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Gadus ogac"
 },
 {
  "CNKEY": "030363900080",
  "CN": "0303 63 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Gadus macrocephalus"
 },
 {
  "CNKEY": "030364000080",
  "CN": "0303 64 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Eglefinos (Melanogrammus aeglefinus)"
 },
 {
  "CNKEY": "030365000080",
  "CN": "0303 65 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "030366000080",
  "CN": "0303 66",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Merluzas (Merluccius spp., Urophycis spp.)"
 },
 {
  "CNKEY": "030366110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Merluzas del género Merluccius"
 },
 {
  "CNKEY": "030366110080",
  "CN": "0303 66 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza del Cabo (Merluccius capensis) y merluza de altura (Merluccius paradoxus)"
 },
 {
  "CNKEY": "030366120080",
  "CN": "0303 66 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza argentina (merluza sudamericana) (Merluccius hubbsi)"
 },
 {
  "CNKEY": "030366130080",
  "CN": "0303 66 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza austral (merluza sureña) (Merluccius australis)"
 },
 {
  "CNKEY": "030366190080",
  "CN": "0303 66 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030366900080",
  "CN": "0303 66 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merluzas del género Urophycis"
 },
 {
  "CNKEY": "030367000080",
  "CN": "0303 67 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abadejos de Alaska (Theragra chalcogramma)"
 },
 {
  "CNKEY": "030368000080",
  "CN": "0303 68",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bacaladillas (Micromesistius poutassou, Micromesistius australis)"
 },
 {
  "CNKEY": "030368100080",
  "CN": "0303 68 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacaladilla (Micromesistius poutassou)"
 },
 {
  "CNKEY": "030368900080",
  "CN": "0303 68 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Polacas australes (Micromesistius australis)"
 },
 {
  "CNKEY": "030369000080",
  "CN": "0303 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030369100080",
  "CN": "0303 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030369300080",
  "CN": "0303 69 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merlanes (Merlangius merlangus)"
 },
 {
  "CNKEY": "030369500080",
  "CN": "0303 69 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Abadejos (Pollachius pollachius)"
 },
 {
  "CNKEY": "030369700080",
  "CN": "0303 69 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colas de rata azul (Macruronus novaezelandiae)"
 },
 {
  "CNKEY": "030369800080",
  "CN": "0303 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marucas y escolanos (Molva spp.)"
 },
 {
  "CNKEY": "030369900080",
  "CN": "0303 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030381000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás pescados, excepto los despojos comestibles de pescado de las subpartidas 030391 a 030399"
 },
 {
  "CNKEY": "030381000080",
  "CN": "0303 81",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030381150080",
  "CN": "0303 81 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias) y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030381300080",
  "CN": "0303 81 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030381400080",
  "CN": "0303 81 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030381900080",
  "CN": "0303 81 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030382000080",
  "CN": "0303 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030383000080",
  "CN": "0303 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030384000080",
  "CN": "0303 84",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Róbalos (Dicentrarchus spp.)"
 },
 {
  "CNKEY": "030384100080",
  "CN": "0303 84 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Lubinas (Dicentrarchus labrax)"
 },
 {
  "CNKEY": "030384900080",
  "CN": "0303 84 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030389000080",
  "CN": "0303 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030389100080",
  "CN": "0303 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De agua dulce"
 },
 {
  "CNKEY": "030389210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030389210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pescados del género Euthynnus (excepto las bacoretas orientales (Euthynnus affinis) incluidas en la subpartida 030359)"
 },
 {
  "CNKEY": "030389210080",
  "CN": "0303 89 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Destinados a la fabricación industrial de productos de la partida 1604"
 },
 {
  "CNKEY": "030389290080",
  "CN": "0303 89 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030389310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Gallinetas nórdicas (Sebastes spp.)"
 },
 {
  "CNKEY": "030389310080",
  "CN": "0303 89 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De la especie Sebastes marinus"
 },
 {
  "CNKEY": "030389390080",
  "CN": "0303 89 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030389400080",
  "CN": "0303 89 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Tasartes (Orcynopsis unicolor)"
 },
 {
  "CNKEY": "030389500080",
  "CN": "0303 89 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las especies Dentex dentex y Pagellus spp."
 },
 {
  "CNKEY": "030389550080",
  "CN": "0303 89 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pargos dorados (Sparus aurata)"
 },
 {
  "CNKEY": "030389600080",
  "CN": "0303 89 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Japutas (Brama spp.)"
 },
 {
  "CNKEY": "030389650080",
  "CN": "0303 89 65",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rapes (Lophius spp.)"
 },
 {
  "CNKEY": "030389700080",
  "CN": "0303 89 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rosadas (Genypterus blacodes)"
 },
 {
  "CNKEY": "030389900080",
  "CN": "0303 89 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hígados, huevas, lechas, aletas, cabezas,colas, vejigas natatorias y demás despojos comestibles de pescado"
 },
 {
  "CNKEY": "030391000080",
  "CN": "0303 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hígados, huevas y lechas"
 },
 {
  "CNKEY": "030391100080",
  "CN": "0303 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Huevas y lechas de pescado, destinadas a la producción del ácido desoxirribonucleico o de sulfato de protamina"
 },
 {
  "CNKEY": "030391900080",
  "CN": "0303 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030392000080",
  "CN": "0303 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aletas de tiburón"
 },
 {
  "CNKEY": "030399000080",
  "CN": "0303 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030400000080",
  "CN": "0304",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Filetes y demás carne de pescado (incluso picada), frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "030431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes frescos o refrigerados de tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y de peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030431000080",
  "CN": "0304 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.)"
 },
 {
  "CNKEY": "030432000080",
  "CN": "0304 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)"
 },
 {
  "CNKEY": "030433000080",
  "CN": "0304 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Percas del Nilo (Lates niloticus)"
 },
 {
  "CNKEY": "030439000080",
  "CN": "0304 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes de los demás pescados, frescos o refrigerados"
 },
 {
  "CNKEY": "030441000080",
  "CN": "0304 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030442000080",
  "CN": "0304 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030442100080",
  "CN": "0304 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Oncorhynchus mykiss de peso superior a 400 g por unidad"
 },
 {
  "CNKEY": "030442500080",
  "CN": "0304 42 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Oncorhynchus apache y Oncorhynchus chrysogaster"
 },
 {
  "CNKEY": "030442900080",
  "CN": "0304 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030443000080",
  "CN": "0304 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pescados planos (Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae y Citharidae)"
 },
 {
  "CNKEY": "030444000080",
  "CN": "0304 44",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae"
 },
 {
  "CNKEY": "030444100080",
  "CN": "0304 44 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus) y bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030444300080",
  "CN": "0304 44 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "030444900080",
  "CN": "0304 44 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030445000080",
  "CN": "0304 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030446000080",
  "CN": "0304 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030447000080",
  "CN": "0304 47",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030447100080",
  "CN": "0304 47 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias)y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030447200080",
  "CN": "0304 47 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030447300080",
  "CN": "0304 47 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030447900080",
  "CN": "0304 47 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030448000080",
  "CN": "0304 48 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030449000080",
  "CN": "0304 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030449100080",
  "CN": "0304 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pescados de agua dulce"
 },
 {
  "CNKEY": "030449500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030449500080",
  "CN": "0304 49 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Gallinetas nórdicas (Sebastes spp.)"
 },
 {
  "CNKEY": "030449900080",
  "CN": "0304 49 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, frescos o refrigerados"
 },
 {
  "CNKEY": "030451000080",
  "CN": "0304 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030452000080",
  "CN": "0304 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmónidos"
 },
 {
  "CNKEY": "030453000080",
  "CN": "0304 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae"
 },
 {
  "CNKEY": "030454000080",
  "CN": "0304 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030455000080",
  "CN": "0304 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030456000080",
  "CN": "0304 56",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030456100080",
  "CN": "0304 56 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias) y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030456200080",
  "CN": "0304 56 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030456300080",
  "CN": "0304 56 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030456900080",
  "CN": "0304 56 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030457000080",
  "CN": "0304 57 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030459000080",
  "CN": "0304 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030459100080",
  "CN": "0304 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pescados de agua dulce"
 },
 {
  "CNKEY": "030459500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030459500080",
  "CN": "0304 59 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Lomos de arenque"
 },
 {
  "CNKEY": "030459900080",
  "CN": "0304 59 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes congelados de tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percasdel Nilo (Lates niloticus) y de peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030461000080",
  "CN": "0304 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.)"
 },
 {
  "CNKEY": "030462000080",
  "CN": "0304 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.)"
 },
 {
  "CNKEY": "030463000080",
  "CN": "0304 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Percas del Nilo (Lates niloticus)"
 },
 {
  "CNKEY": "030469000080",
  "CN": "0304 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030471000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes congelados de pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae"
 },
 {
  "CNKEY": "030471000080",
  "CN": "0304 71",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030471100080",
  "CN": "0304 71 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos de la especie Gadus macrocephalus"
 },
 {
  "CNKEY": "030471900080",
  "CN": "0304 71 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030472000080",
  "CN": "0304 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Eglefinos (Melanogrammus aeglefinus)"
 },
 {
  "CNKEY": "030473000080",
  "CN": "0304 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "030474000080",
  "CN": "0304 74",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Merluzas (Merluccius spp., Urophycis spp.)"
 },
 {
  "CNKEY": "030474110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Merluzas del género Merluccius"
 },
 {
  "CNKEY": "030474110080",
  "CN": "0304 74 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza del Cabo (Merluccius capensis) y merluza de altura (Merluccius paradoxus)"
 },
 {
  "CNKEY": "030474150080",
  "CN": "0304 74 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluza argentina (merluza sudamericana) (Merluccius hubbsi)"
 },
 {
  "CNKEY": "030474190080",
  "CN": "0304 74 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030474900080",
  "CN": "0304 74 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merluzas del género Urophycis"
 },
 {
  "CNKEY": "030475000080",
  "CN": "0304 75 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abadejos de Alaska (Theragra chalcogramma)"
 },
 {
  "CNKEY": "030479000080",
  "CN": "0304 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030479100080",
  "CN": "0304 79 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030479300080",
  "CN": "0304 79 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Merlanes (Merlangius merlangus)"
 },
 {
  "CNKEY": "030479500080",
  "CN": "0304 79 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colas de rata azul (Macruronus novaezelandiae)"
 },
 {
  "CNKEY": "030479800080",
  "CN": "0304 79 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marucas y escolanos (Molvaspp.)"
 },
 {
  "CNKEY": "030479900080",
  "CN": "0304 79 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030481000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes congelados de los demás pescados"
 },
 {
  "CNKEY": "030481000080",
  "CN": "0304 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030482000080",
  "CN": "0304 82",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030482100080",
  "CN": "0304 82 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Oncorhynchus mykiss de peso superior a 400 g por unidad"
 },
 {
  "CNKEY": "030482500080",
  "CN": "0304 82 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las especies Oncorhynchus apache y Oncorhynchus chrysogaster"
 },
 {
  "CNKEY": "030482900080",
  "CN": "0304 82 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030483000080",
  "CN": "0304 83",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pescados planos (Pleuronéctidos, Bótidos, Cynoglósidos, Soleidos, Escoftálmidos y Citáridos)"
 },
 {
  "CNKEY": "030483100080",
  "CN": "0304 83 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sollas (Pleuronectes platessa)"
 },
 {
  "CNKEY": "030483300080",
  "CN": "0304 83 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Platijas (Platichthys flesus)"
 },
 {
  "CNKEY": "030483500080",
  "CN": "0304 83 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Gallos (Lepidorhombus spp.)"
 },
 {
  "CNKEY": "030483900080",
  "CN": "0304 83 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030484000080",
  "CN": "0304 84 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030485000080",
  "CN": "0304 85 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030486000080",
  "CN": "0304 86 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030487000080",
  "CN": "0304 87 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Atunes (del género Thunnus), listados (bonitos de vientre rayado) (Katsuwonus pelamis)"
 },
 {
  "CNKEY": "030488000080",
  "CN": "0304 88",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones, demás escualos y rayas (Rajidae)"
 },
 {
  "CNKEY": "030488110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030488110080",
  "CN": "0304 88 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias)y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030488150080",
  "CN": "0304 88 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030488180080",
  "CN": "0304 88 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030488190080",
  "CN": "0304 88 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030488900080",
  "CN": "0304 88 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030489000080",
  "CN": "0304 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030489100080",
  "CN": "0304 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De pescados de agua dulce"
 },
 {
  "CNKEY": "030489210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030489210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De gallineta nórdica (Sebastes spp.)"
 },
 {
  "CNKEY": "030489210080",
  "CN": "0304 89 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De la especie Sebastes marinus"
 },
 {
  "CNKEY": "030489290080",
  "CN": "0304 89 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030489300080",
  "CN": "0304 89 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pescados del género Euthynnus"
 },
 {
  "CNKEY": "030489410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De caballa y estornino (Scomber scombrus, Scomber australasicus, Scomber japonicus) y de pescados de la especie Orcynopsis unicolor"
 },
 {
  "CNKEY": "030489410080",
  "CN": "0304 89 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De la especie Scomber australasicus"
 },
 {
  "CNKEY": "030489490080",
  "CN": "0304 89 49",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030489600080",
  "CN": "0304 89 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rapes (Lophius spp.)"
 },
 {
  "CNKEY": "030489900080",
  "CN": "0304 89 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, congelados"
 },
 {
  "CNKEY": "030491000080",
  "CN": "0304 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peces espada (Xiphias gladius)"
 },
 {
  "CNKEY": "030492000080",
  "CN": "0304 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Austromerluzas antárticas y austromerluzas negras (merluzas negras, bacalaos de profundidad, nototenias negras) (Dissostichus spp.)"
 },
 {
  "CNKEY": "030493000080",
  "CN": "0304 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030493100080",
  "CN": "0304 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Surimi"
 },
 {
  "CNKEY": "030493900080",
  "CN": "0304 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030494000080",
  "CN": "0304 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Abadejos de Alaska (Theragra chalcogramma)"
 },
 {
  "CNKEY": "030494100080",
  "CN": "0304 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Surimi"
 },
 {
  "CNKEY": "030494900080",
  "CN": "0304 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030495000080",
  "CN": "0304 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae, excepto los abadejos de Alaska (Theragra chalcogramma)"
 },
 {
  "CNKEY": "030495100080",
  "CN": "0304 95 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Surimi"
 },
 {
  "CNKEY": "030495210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030495210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus) y bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030495210080",
  "CN": "0304 95 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Bacalaos de la especie Gadus macrocephalus"
 },
 {
  "CNKEY": "030495250080",
  "CN": "0304 95 25",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Bacalaos de la especie Gadus morhua"
 },
 {
  "CNKEY": "030495290080",
  "CN": "0304 95 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030495300080",
  "CN": "0304 95 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Eglefinos (Melanogrammus aeglefinus)"
 },
 {
  "CNKEY": "030495400080",
  "CN": "0304 95 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "030495500080",
  "CN": "0304 95 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Merluzas del género Merluccius"
 },
 {
  "CNKEY": "030495600080",
  "CN": "0304 95 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Bacaladilla (Micromesistius poutassou)"
 },
 {
  "CNKEY": "030495900080",
  "CN": "0304 95 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030496000080",
  "CN": "0304 96",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cazones y demás escualos"
 },
 {
  "CNKEY": "030496100080",
  "CN": "0304 96 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mielgas (Squalus acanthias)y pintarrojas (Scyliorhinus spp.)"
 },
 {
  "CNKEY": "030496200080",
  "CN": "0304 96 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Marrajo sardinero (Lamna nasus)"
 },
 {
  "CNKEY": "030496300080",
  "CN": "0304 96 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tintoreras (Prionace glauca)"
 },
 {
  "CNKEY": "030496900080",
  "CN": "0304 96 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030497000080",
  "CN": "0304 97 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rayas (Rajidae)"
 },
 {
  "CNKEY": "030499000080",
  "CN": "0304 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030499100080",
  "CN": "0304 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Surimi"
 },
 {
  "CNKEY": "030499210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030499210080",
  "CN": "0304 99 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pescados de agua dulce"
 },
 {
  "CNKEY": "030499230010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030499230080",
  "CN": "0304 99 23",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030499290080",
  "CN": "0304 99 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Gallinetas nórdicas (Sebastes spp.)"
 },
 {
  "CNKEY": "030499550080",
  "CN": "0304 99 55",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Gallos (Lepidorhombus spp.)"
 },
 {
  "CNKEY": "030499610080",
  "CN": "0304 99 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Japutas (Brama spp.)"
 },
 {
  "CNKEY": "030499650080",
  "CN": "0304 99 65",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Rapes (Lophius spp.)"
 },
 {
  "CNKEY": "030499990080",
  "CN": "0304 99 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030500000080",
  "CN": "0305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pescado seco, salado o en salmuera; pescado ahumado, incluso cocido antes o durante el ahumado"
 },
 {
  "CNKEY": "030520000080",
  "CN": "0305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hígados, huevas y lechas, de pescado, secos, ahumados, salados o en salmuera"
 },
 {
  "CNKEY": "030531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Filetes de pescado, secos, salados o en salmuera, sin ahumar"
 },
 {
  "CNKEY": "030531000080",
  "CN": "0305 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030532000080",
  "CN": "0305 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae"
 },
 {
  "CNKEY": "030532110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus) y bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030532110080",
  "CN": "0305 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Bacalaos de la especie Gadus macrocephalus"
 },
 {
  "CNKEY": "030532190080",
  "CN": "0305 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030532900080",
  "CN": "0305 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030539000080",
  "CN": "0305 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030539100080",
  "CN": "0305 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), de salmones del Atlántico (Salmo salar) y de salmones del Danubio (Hucho hucho), salados o en salmuera"
 },
 {
  "CNKEY": "030539500080",
  "CN": "0305 39 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fletán («halibut») negro (Reinhardtius hippoglossoides), salados o en salmuera"
 },
 {
  "CNKEY": "030539900080",
  "CN": "0305 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescados ahumados, incluidos los filetes, excepto los despojos comestibles de pescado"
 },
 {
  "CNKEY": "030541000080",
  "CN": "0305 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030542000080",
  "CN": "0305 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030543000080",
  "CN": "0305 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Truchas (Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache y Oncorhynchus chrysogaster)"
 },
 {
  "CNKEY": "030544000080",
  "CN": "0305 44",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030544100080",
  "CN": "0305 44 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Anguilas (Anguilla spp.)"
 },
 {
  "CNKEY": "030544900080",
  "CN": "0305 44 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030549000080",
  "CN": "0305 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030549100080",
  "CN": "0305 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») negro (Reinhardtius hippoglossoides)"
 },
 {
  "CNKEY": "030549200080",
  "CN": "0305 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») atlántico (Hippoglossus hippoglossus)"
 },
 {
  "CNKEY": "030549300080",
  "CN": "0305 49 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Caballas y estorninos (Scomber scombrus, Scomber australasicus, Scomber japonicus)"
 },
 {
  "CNKEY": "030549800080",
  "CN": "0305 49 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescado seco, incluso salado, sin ahumar, excepto los despojos comestibles"
 },
 {
  "CNKEY": "030551000080",
  "CN": "0305 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030551100080",
  "CN": "0305 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Secos, sin salar"
 },
 {
  "CNKEY": "030551900080",
  "CN": "0305 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Secos, salados"
 },
 {
  "CNKEY": "030552000080",
  "CN": "0305 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030553000080",
  "CN": "0305 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pescados de las familias Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae y Muraenolepididae, excepto los bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030553100080",
  "CN": "0305 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030553900080",
  "CN": "0305 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030554000080",
  "CN": "0305 54",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arenques (Clupea harengus, Clupea pallasii), anchoas (Engraulis spp.), sardinas (Sardina pilchardus, Sardinops spp.), sardinelas (Sardinella spp.), espadines (Sprattus sprattus), caballas (Scomber scombrus, Scomber australasicus, Scomber japonicus), caballas de la India (Rastrelliger spp.), carites (Scomberomorus spp.), jureles (Trachurus spp.), pámpanos (Caranx spp.), cobias (Rachycentron canadum), palometones plateados (Pampus spp.), papardas del Pacífico (Cololabis saira), macarelas (Decapterus spp.), capelanes (Mallotus villosus), peces espada (Xiphias gladius), bacoretas orientales (Euthynnus affinis), bonitos (Sarda spp.), agujas, marlines, peces vela, o picudos (Istiophoridae)"
 },
 {
  "CNKEY": "030554300080",
  "CN": "0305 54 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030554500080",
  "CN": "0305 54 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Anchoas (Engraulis spp.)"
 },
 {
  "CNKEY": "030554900080",
  "CN": "0305 54 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030559000080",
  "CN": "0305 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030559700080",
  "CN": "0305 59 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») atlántico (Hippoglossus hippoglossus)"
 },
 {
  "CNKEY": "030559850080",
  "CN": "0305 59 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030561000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescado salado sin secar ni ahumar y pescado en salmuera, excepto los despojos comestibles"
 },
 {
  "CNKEY": "030561000080",
  "CN": "0305 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arenques (Clupea harengus, Clupea pallasii)"
 },
 {
  "CNKEY": "030562000080",
  "CN": "0305 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bacalaos (Gadus morhua, Gadus ogac, Gadus macrocephalus)"
 },
 {
  "CNKEY": "030563000080",
  "CN": "0305 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anchoas (Engraulis spp.)"
 },
 {
  "CNKEY": "030564000080",
  "CN": "0305 64 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilapias (Oreochromis spp.), bagres o peces gato (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carpas (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), anguilas (Anguilla spp.), percas del Nilo (Lates niloticus) y peces cabeza de serpiente (Channa spp.)"
 },
 {
  "CNKEY": "030569000080",
  "CN": "0305 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030569100080",
  "CN": "0305 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bacalaos polares (Boreogadus saida)"
 },
 {
  "CNKEY": "030569300080",
  "CN": "0305 69 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fletán («halibut») atlántico (Hippoglossus hippoglossus)"
 },
 {
  "CNKEY": "030569500080",
  "CN": "0305 69 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Salmones del Pacífico (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou y Oncorhynchus rhodurus), salmones del Atlántico (Salmo salar) y salmones del Danubio (Hucho hucho)"
 },
 {
  "CNKEY": "030569800080",
  "CN": "0305 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030571000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aletas, cabezas, colas, vejigas natatorias y demás despojos comestibles de pescado"
 },
 {
  "CNKEY": "030571000080",
  "CN": "0305 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aletas de tiburón"
 },
 {
  "CNKEY": "030572000080",
  "CN": "0305 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cabezas, colas y vejigas natatorias, de pescado"
 },
 {
  "CNKEY": "030579000080",
  "CN": "0305 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030600000080",
  "CN": "0306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Crustáceos, incluso pelados, vivos, frescos, refrigerados, congelados, secos, salados o en salmuera; crustáceos ahumados, incluso pelados, incluso cocidos antes o durante el ahumado; crustáceos sin pelar, cocidos en agua o vapor, incluso refrigerados, congelados, secos, salados o en salmuera"
 },
 {
  "CNKEY": "030611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Congelados"
 },
 {
  "CNKEY": "030611000080",
  "CN": "0306 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Langostas (Palinurus spp., Panulirus spp., Jasus spp.)"
 },
 {
  "CNKEY": "030611100080",
  "CN": "0306 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colas de langostas"
 },
 {
  "CNKEY": "030611900080",
  "CN": "0306 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030612000080",
  "CN": "0306 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bogavantes (Homarus spp.)"
 },
 {
  "CNKEY": "030612100080",
  "CN": "0306 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Enteros"
 },
 {
  "CNKEY": "030612900080",
  "CN": "0306 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030614000080",
  "CN": "0306 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cangrejos (excepto macruros)"
 },
 {
  "CNKEY": "030614100080",
  "CN": "0306 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cangrejos de las especies Paralithodes camchaticus, Chionoecetes spp. y Callinectes sapidus"
 },
 {
  "CNKEY": "030614300080",
  "CN": "0306 14 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bueyes (Cancer pagurus)"
 },
 {
  "CNKEY": "030614900080",
  "CN": "0306 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030615000080",
  "CN": "0306 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cigalas (Nephrops norvegicus)"
 },
 {
  "CNKEY": "030616000080",
  "CN": "0306 16",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Camarones, langostinos y demás decápodos Natantia, de agua fría (Pandalus spp., Crangon crangon)"
 },
 {
  "CNKEY": "030616910080",
  "CN": "0306 16 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Camarones de la especie Crangon crangon"
 },
 {
  "CNKEY": "030616990080",
  "CN": "0306 16 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030617000080",
  "CN": "0306 17",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás camarones, langostinos y demás decápodos Natantia"
 },
 {
  "CNKEY": "030617910080",
  "CN": "0306 17 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Gambas de altura (Parapenaeus longirostris)"
 },
 {
  "CNKEY": "030617920080",
  "CN": "0306 17 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Langostinos del género Penaeus spp."
 },
 {
  "CNKEY": "030617930080",
  "CN": "0306 17 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Camarones de la familia Pandalidae, excepto los del género Pandalus"
 },
 {
  "CNKEY": "030617940080",
  "CN": "0306 17 94",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Camarones del género Crangon, excepto los de la especie Crangon crangon"
 },
 {
  "CNKEY": "030617990080",
  "CN": "0306 17 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030619000080",
  "CN": "0306 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030619100080",
  "CN": "0306 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cangrejos de río"
 },
 {
  "CNKEY": "030619900080",
  "CN": "0306 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030631000080",
  "CN": "0306 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Langostas (Palinurus spp., Panulirus spp., Jasus spp.)"
 },
 {
  "CNKEY": "030632000080",
  "CN": "0306 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bogavantes (Homarus spp.)"
 },
 {
  "CNKEY": "030632100080",
  "CN": "0306 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Vivos"
 },
 {
  "CNKEY": "030632910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030632910080",
  "CN": "0306 32 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Enteros"
 },
 {
  "CNKEY": "030632990080",
  "CN": "0306 32 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030633000080",
  "CN": "0306 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cangrejos"
 },
 {
  "CNKEY": "030633100080",
  "CN": "0306 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bueyes (Cancer pagurus)"
 },
 {
  "CNKEY": "030633900080",
  "CN": "0306 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030634000080",
  "CN": "0306 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cigalas (Nephrops norvegicus)"
 },
 {
  "CNKEY": "030635000080",
  "CN": "0306 35",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Camarones y langostinos y demás decápodos Natantia, de agua fría (Pandalus spp., Crangon crangon)"
 },
 {
  "CNKEY": "030635100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Camarones de la especie Crangon crangon"
 },
 {
  "CNKEY": "030635100080",
  "CN": "0306 35 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Frescos o refrigerados"
 },
 {
  "CNKEY": "030635500080",
  "CN": "0306 35 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030635900080",
  "CN": "0306 35 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030636000080",
  "CN": "0306 36",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás camarones, langostinos y demás decápodos Natantia"
 },
 {
  "CNKEY": "030636100080",
  "CN": "0306 36 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Camarones de la familia Pandalidae, excepto los del género Pandalus"
 },
 {
  "CNKEY": "030636500080",
  "CN": "0306 36 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Camarones del género Crangon, excepto los de la especie Crangon crangon"
 },
 {
  "CNKEY": "030636900080",
  "CN": "0306 36 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030639000080",
  "CN": "0306 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030639100080",
  "CN": "0306 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cangrejos de río"
 },
 {
  "CNKEY": "030639900080",
  "CN": "0306 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030691000080",
  "CN": "0306 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Langostas (Palinurus spp., Panulirus spp., Jasus spp.)"
 },
 {
  "CNKEY": "030692000080",
  "CN": "0306 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bogavantes (Homarus spp.)"
 },
 {
  "CNKEY": "030692100080",
  "CN": "0306 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Enteros"
 },
 {
  "CNKEY": "030692900080",
  "CN": "0306 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030693000080",
  "CN": "0306 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cangrejos"
 },
 {
  "CNKEY": "030693100080",
  "CN": "0306 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bueyes (Cancer pagurus)"
 },
 {
  "CNKEY": "030693900080",
  "CN": "0306 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030694000080",
  "CN": "0306 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cigalas (Nephrops norvegicus)"
 },
 {
  "CNKEY": "030695000080",
  "CN": "0306 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Camarones y langostinos y demás decápodos Natantia"
 },
 {
  "CNKEY": "030695110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Camarones, langostinos y demás decápodos Natantia, de agua fría (Pandalus spp., Crangon crangon)"
 },
 {
  "CNKEY": "030695110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Camarones de la especie Crangon crangon"
 },
 {
  "CNKEY": "030695110080",
  "CN": "0306 95 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Simplemente cocidos con agua o vapor"
 },
 {
  "CNKEY": "030695190080",
  "CN": "0306 95 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030695200080",
  "CN": "0306 95 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Camarones del géneroPandalus"
 },
 {
  "CNKEY": "030695300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás camarones, langostinos y demás decápodos Natantia"
 },
 {
  "CNKEY": "030695300080",
  "CN": "0306 95 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Camarones de la familia Pandalidae, excepto los del género Pandalus"
 },
 {
  "CNKEY": "030695400080",
  "CN": "0306 95 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Camarones del género Crangon, excepto los de la especie Crangon crangon"
 },
 {
  "CNKEY": "030695900080",
  "CN": "0306 95 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030699000080",
  "CN": "0306 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030699100080",
  "CN": "0306 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cangrejos de río"
 },
 {
  "CNKEY": "030699900080",
  "CN": "0306 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030700000080",
  "CN": "0307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Moluscos, incluso separados de sus valvas, vivos, frescos, refrigerados, congelados, secos, salados o en salmuera; moluscos ahumados, incluso pelados, incluso cocidos antes o durante el ahumado"
 },
 {
  "CNKEY": "030711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ostras"
 },
 {
  "CNKEY": "030711000080",
  "CN": "0307 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vivas, frescas o refrigeradas"
 },
 {
  "CNKEY": "030711100080",
  "CN": "0307 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ostras planas (género Ostrea), vivas, con sus conchas, con un peso inferior o igual a 40 g por unidad"
 },
 {
  "CNKEY": "030711900080",
  "CN": "0307 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030712000080",
  "CN": "0307 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congeladas"
 },
 {
  "CNKEY": "030719000080",
  "CN": "0307 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vieiras, volandeiras y demás moluscos de la familia Pectinidae"
 },
 {
  "CNKEY": "030721000080",
  "CN": "0307 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030721100080",
  "CN": "0307 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Veneras (vieiras), volandeiras y demás moluscos de los géneros Pecten, Chlamys o Placopecten"
 },
 {
  "CNKEY": "030721900080",
  "CN": "0307 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030722000080",
  "CN": "0307 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelados"
 },
 {
  "CNKEY": "030722100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Veneras (vieiras), volandeiras y demás moluscos de los géneros Pecten, Chlamys o Placopecten"
 },
 {
  "CNKEY": "030722100080",
  "CN": "0307 22 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Veneras (vieiras) (Pecten maximus)"
 },
 {
  "CNKEY": "030722900080",
  "CN": "0307 22 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030722950080",
  "CN": "0307 22 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030729000080",
  "CN": "0307 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030729100080",
  "CN": "0307 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Veneras (vieiras), volandeiras y demás moluscos de los géneros Pecten, Chlamys o Placopecten"
 },
 {
  "CNKEY": "030729900080",
  "CN": "0307 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030731000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mejillones (Mytilus spp., Perna spp.)"
 },
 {
  "CNKEY": "030731000080",
  "CN": "0307 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030731100080",
  "CN": "0307 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mytilus spp."
 },
 {
  "CNKEY": "030731900080",
  "CN": "0307 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perna spp."
 },
 {
  "CNKEY": "030732000080",
  "CN": "0307 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelados"
 },
 {
  "CNKEY": "030732100080",
  "CN": "0307 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mytilus spp."
 },
 {
  "CNKEY": "030732900080",
  "CN": "0307 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perna spp."
 },
 {
  "CNKEY": "030739000080",
  "CN": "0307 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030739200080",
  "CN": "0307 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mytilus spp."
 },
 {
  "CNKEY": "030739800080",
  "CN": "0307 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perna spp."
 },
 {
  "CNKEY": "030742000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sepias (jibias) y globitos; calamares y potas"
 },
 {
  "CNKEY": "030742000080",
  "CN": "0307 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030742100080",
  "CN": "0307 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sepias (jibias) (Sepia officinalis, Rossia macrosoma) y globitos (Sepiola spp.)"
 },
 {
  "CNKEY": "030742200080",
  "CN": "0307 42 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Loligo spp."
 },
 {
  "CNKEY": "030742300080",
  "CN": "0307 42 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Calamares y potas (Ommastrephes spp., Nototodarus spp., Sepioteuthis spp.)"
 },
 {
  "CNKEY": "030742400080",
  "CN": "0307 42 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Potas europeas (Todarodes sagittatus)"
 },
 {
  "CNKEY": "030742900080",
  "CN": "0307 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030743000080",
  "CN": "0307 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelados"
 },
 {
  "CNKEY": "030743210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sepias (jibias) (Sepia officinalis, Rossia macrosoma) y globitos (Sepiola spp.)"
 },
 {
  "CNKEY": "030743210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Globitos (Sepiola spp.)"
 },
 {
  "CNKEY": "030743210080",
  "CN": "0307 43 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sepiola rondeleti"
 },
 {
  "CNKEY": "030743250080",
  "CN": "0307 43 25",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030743290080",
  "CN": "0307 43 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sepias (jibias) (Sepia officinalis, Rossia macrosoma)"
 },
 {
  "CNKEY": "030743310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Loligo spp."
 },
 {
  "CNKEY": "030743310080",
  "CN": "0307 43 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Loligo vulgaris"
 },
 {
  "CNKEY": "030743330080",
  "CN": "0307 43 33",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Loligo pealei"
 },
 {
  "CNKEY": "030743350080",
  "CN": "0307 43 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Loligo gahi"
 },
 {
  "CNKEY": "030743380080",
  "CN": "0307 43 38",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030743910080",
  "CN": "0307 43 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ommastrephes spp., excepto Ommastrephes sagittatus, Nototodarus spp., Sepioteuthis spp."
 },
 {
  "CNKEY": "030743920080",
  "CN": "0307 43 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Illex spp."
 },
 {
  "CNKEY": "030743950080",
  "CN": "0307 43 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Potas europeas (Todarodes sagittatus) (Ommastrephes sagittatus)"
 },
 {
  "CNKEY": "030743990080",
  "CN": "0307 43 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030749000080",
  "CN": "0307 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030749200080",
  "CN": "0307 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sepias(jibias) (Sepia officinalis, Rossia macrosoma) y globitos(Sepiola spp.)"
 },
 {
  "CNKEY": "030749400080",
  "CN": "0307 49 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Loligo spp."
 },
 {
  "CNKEY": "030749500080",
  "CN": "0307 49 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ommastrephes spp., distintas de Ommastrephes sagittatus, Nototodarus spp., Sepioteuthis spp."
 },
 {
  "CNKEY": "030749600080",
  "CN": "0307 49 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Potas europeas (Todarodes sagittatus) (Ommastrephes sagittatus)"
 },
 {
  "CNKEY": "030749800080",
  "CN": "0307 49 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pulpos (Octopus spp.)"
 },
 {
  "CNKEY": "030751000080",
  "CN": "0307 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030752000080",
  "CN": "0307 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congelados"
 },
 {
  "CNKEY": "030759000080",
  "CN": "0307 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030760000080",
  "CN": "0307 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caracoles (excepto los de mar)"
 },
 {
  "CNKEY": "030771000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Almejas, berberechos y arcas (familias Arcidae, Arcticidae, Cardiidae, Donacidae, Hiatellidae, Mactridae, Mesodesmatidae, Myidae, Semelidae, Solecurtidae, Solenidae, Tridacnidae y Veneridae)"
 },
 {
  "CNKEY": "030771000080",
  "CN": "0307 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030772000080",
  "CN": "0307 72",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelados"
 },
 {
  "CNKEY": "030772100080",
  "CN": "0307 72 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Almejas o otras especies de las familias de los Venéridos"
 },
 {
  "CNKEY": "030772900080",
  "CN": "0307 72 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030779000080",
  "CN": "0307 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030781000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Abulones u orejas de mar (Haliotis spp.) y caracoles de mar (cobos) (Strombus spp.)"
 },
 {
  "CNKEY": "030781000080",
  "CN": "0307 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abulones u orejas de mar (Haliotis spp.) vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030782000080",
  "CN": "0307 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caracoles de mar (cobos) (Strombus spp.) vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030783000080",
  "CN": "0307 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abulones u orejas de mar (Haliotis spp.) congelados"
 },
 {
  "CNKEY": "030784000080",
  "CN": "0307 84 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caracoles de mar (cobos) (Strombus spp.) congelados"
 },
 {
  "CNKEY": "030787000080",
  "CN": "0307 87 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás abulones u orejas de mar (Haliotis spp.)"
 },
 {
  "CNKEY": "030788000080",
  "CN": "0307 88 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás cobos (caracoles de mar) (Strombus spp.)"
 },
 {
  "CNKEY": "030791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030791000080",
  "CN": "0307 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030792000080",
  "CN": "0307 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congelados"
 },
 {
  "CNKEY": "030799000080",
  "CN": "0307 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030800000080",
  "CN": "0308",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Invertebrados acuáticos, excepto los crustáceos y moluscos, vivos, frescos, refrigerados, congelados, secos, salados o en salmuera; invertebrados acuáticos ahumados, excepto los crustáceos y moluscos, incluso cocidos antes o durante el ahumado"
 },
 {
  "CNKEY": "030811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pepinos de mar (Stichopus japonicus, Holothuroidea)"
 },
 {
  "CNKEY": "030811000080",
  "CN": "0308 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030812000080",
  "CN": "0308 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congelados"
 },
 {
  "CNKEY": "030819000080",
  "CN": "0308 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Erizos de mar (Strongylocentrotus spp., Paracentrotus lividus, Loxechinus albus, Echinus esculentus)"
 },
 {
  "CNKEY": "030821000080",
  "CN": "0308 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030822000080",
  "CN": "0308 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congelados"
 },
 {
  "CNKEY": "030829000080",
  "CN": "0308 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030830000080",
  "CN": "0308 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Medusas (Rhopilema spp.)"
 },
 {
  "CNKEY": "030830500080",
  "CN": "0308 30 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congeladas"
 },
 {
  "CNKEY": "030830800080",
  "CN": "0308 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "030890000080",
  "CN": "0308 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "030890100080",
  "CN": "0308 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vivos, frescos o refrigerados"
 },
 {
  "CNKEY": "030890500080",
  "CN": "0308 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Congelados"
 },
 {
  "CNKEY": "030890900080",
  "CN": "0308 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "030900000080",
  "CN": "0309",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina, polvo y «pellets» de pescado, crustáceos, moluscos y demás invertebrados acuáticos, aptos para la alimentación humana"
 },
 {
  "CNKEY": "030910000080",
  "CN": "0309 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De pescado"
 },
 {
  "CNKEY": "030990000080",
  "CN": "0309 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040021000090",
  "CN": "04",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 4 - LECHE Y PRODUCTOS LÁCTEOS; HUEVOS DE AVE; MIEL NATURAL; PRODUCTOS COMESTIBLES DE ORIGEN ANIMAL NO EXPRESADOS NI COMPRENDIDOS EN OTRA PARTE"
 },
 {
  "CNKEY": "040100000080",
  "CN": "0401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Leche y nata (crema), sin concentrar, sin adición de azúcar ni otro edulcorante"
 },
 {
  "CNKEY": "040110000080",
  "CN": "0401 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 1 % en peso"
 },
 {
  "CNKEY": "040110100080",
  "CN": "0401 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040110900080",
  "CN": "0401 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040120000080",
  "CN": "0401 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 1 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040120110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 3 %"
 },
 {
  "CNKEY": "040120110080",
  "CN": "0401 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040120190080",
  "CN": "0401 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040120910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior al 3 %"
 },
 {
  "CNKEY": "040120910080",
  "CN": "0401 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040120990080",
  "CN": "0401 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040140000080",
  "CN": "0401 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 6 % pero inferior o igual al 10 % en peso"
 },
 {
  "CNKEY": "040140100080",
  "CN": "0401 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040140900080",
  "CN": "0401 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040150000080",
  "CN": "0401 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 10 % en peso"
 },
 {
  "CNKEY": "040150110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 21 %"
 },
 {
  "CNKEY": "040150110080",
  "CN": "0401 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040150190080",
  "CN": "0401 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040150310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior al 21 % pero inferior o igual al 45 %"
 },
 {
  "CNKEY": "040150310080",
  "CN": "0401 50 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040150390080",
  "CN": "0401 50 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040150910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior al 45 %"
 },
 {
  "CNKEY": "040150910080",
  "CN": "0401 50 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 l"
 },
 {
  "CNKEY": "040150990080",
  "CN": "0401 50 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040200000080",
  "CN": "0402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Leche y nata (crema), concentradas o con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "040210000080",
  "CN": "0402 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas, con un contenido de materias grasas inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040210110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante"
 },
 {
  "CNKEY": "040210110080",
  "CN": "0402 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040210190080",
  "CN": "0402 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040210910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040210910080",
  "CN": "0402 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040210990080",
  "CN": "0402 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas, con un contenido de materias grasas superior al 1,5 % en peso"
 },
 {
  "CNKEY": "040221000080",
  "CN": "0402 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante"
 },
 {
  "CNKEY": "040221110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040221110080",
  "CN": "0402 21 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040221180080",
  "CN": "0402 21 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040221910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 27 % en peso"
 },
 {
  "CNKEY": "040221910080",
  "CN": "0402 21 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040221990080",
  "CN": "0402 21 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040229000080",
  "CN": "0402 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040229110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040229110080",
  "CN": "0402 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Leche especial para lactantes en recipientes herméticamente cerrados de contenido neto inferior o igual a 500 g, con grasas en proporción superior al 10 % en peso"
 },
 {
  "CNKEY": "040229150010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040229150080",
  "CN": "0402 29 15",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040229190080",
  "CN": "0402 29 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040229910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 27 % en peso"
 },
 {
  "CNKEY": "040229910080",
  "CN": "0402 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040229990080",
  "CN": "0402 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040291000080",
  "CN": "0402 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante"
 },
 {
  "CNKEY": "040291100080",
  "CN": "0402 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas inferior o igual al 8 % en peso"
 },
 {
  "CNKEY": "040291300080",
  "CN": "0402 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior al 8 % pero inferior o igual al 10 % en peso"
 },
 {
  "CNKEY": "040291510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 10 % pero inferior o igual al 45 % en peso"
 },
 {
  "CNKEY": "040291510080",
  "CN": "0402 91 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040291590080",
  "CN": "0402 91 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040291910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 45 % en peso"
 },
 {
  "CNKEY": "040291910080",
  "CN": "0402 91 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040291990080",
  "CN": "0402 91 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040299000080",
  "CN": "0402 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040299100080",
  "CN": "0402 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas inferior o igual al 9,5 % en peso"
 },
 {
  "CNKEY": "040299310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 9,5 % pero inferior o igual al 45 % en peso"
 },
 {
  "CNKEY": "040299310080",
  "CN": "0402 99 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040299390080",
  "CN": "0402 99 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040299910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas superior al 45 % en peso"
 },
 {
  "CNKEY": "040299910080",
  "CN": "0402 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 2,5 kg"
 },
 {
  "CNKEY": "040299990080",
  "CN": "0402 99 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040300000080",
  "CN": "0403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Yogur; suero de mantequilla (de manteca), leche y nata (crema) cuajadas, kéfir y demás leches y natas (cremas), fermentadas o acidificadas, incluso concentrados o con adición de azúcar u otro edulcorante, aromatizados o con frutas u otros frutos o cacao"
 },
 {
  "CNKEY": "040320000080",
  "CN": "0403 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Yogur"
 },
 {
  "CNKEY": "040320110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin aromatizar y sin frutas u otros frutos, cacao, chocolate, especias, café o extracto de café, plantas, partes de plantas, cereales o productos de panadería"
 },
 {
  "CNKEY": "040320110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040320110080",
  "CN": "0403 20 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040320130080",
  "CN": "0403 20 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040320190080",
  "CN": "0403 20 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040320310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de materias grasas"
 },
 {
  "CNKEY": "040320310080",
  "CN": "0403 20 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040320330080",
  "CN": "0403 20 33",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040320390080",
  "CN": "0403 20 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040320410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con adición de chocolate, especias, café o extracto de café, plantas, partes de plantas, cereales o productos de panadería"
 },
 {
  "CNKEY": "040320410080",
  "CN": "0403 20 41",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas de la leche inferior al 1,5 % en peso, de sacarosa (incluido el azúcar invertido) o isoglucosa inferior al 5 % en peso, de glucosa o almidón o fécula inferior al 5 % en peso"
 },
 {
  "CNKEY": "040320490080",
  "CN": "0403 20 49",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040320510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, aromatizados o con frutas u otros frutos o cacao"
 },
 {
  "CNKEY": "040320510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas, con un contenido de materias grasas de leche"
 },
 {
  "CNKEY": "040320510080",
  "CN": "0403 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040320530080",
  "CN": "0403 20 53",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040320590080",
  "CN": "0403 20 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040320910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás con un contenido de materias grasas de leche"
 },
 {
  "CNKEY": "040320910080",
  "CN": "0403 20 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040320930080",
  "CN": "0403 20 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040320990080",
  "CN": "0403 20 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040390000080",
  "CN": "0403 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040390110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin aromatizar y sin frutas u otros frutos ni cacao"
 },
 {
  "CNKEY": "040390110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas"
 },
 {
  "CNKEY": "040390110030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040390110080",
  "CN": "0403 90 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040390130080",
  "CN": "0403 90 13",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040390190080",
  "CN": "0403 90 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040390310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de materias grasas"
 },
 {
  "CNKEY": "040390310080",
  "CN": "0403 90 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040390330080",
  "CN": "0403 90 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040390390080",
  "CN": "0403 90 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040390510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040390510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040390510080",
  "CN": "0403 90 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040390530080",
  "CN": "0403 90 53",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040390590080",
  "CN": "0403 90 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040390610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de materias grasas"
 },
 {
  "CNKEY": "040390610080",
  "CN": "0403 90 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040390630080",
  "CN": "0403 90 63",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040390690080",
  "CN": "0403 90 69",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040390710010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aromatizados o con frutas u otros frutos o cacao"
 },
 {
  "CNKEY": "040390710020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas, con un contenido de materias grasas de la leche"
 },
 {
  "CNKEY": "040390710080",
  "CN": "0403 90 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040390730080",
  "CN": "0403 90 73",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040390790080",
  "CN": "0403 90 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040390910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de materias grasas de la leche"
 },
 {
  "CNKEY": "040390910080",
  "CN": "0403 90 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "040390930080",
  "CN": "0403 90 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 3 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "040390990080",
  "CN": "0403 90 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "040400000080",
  "CN": "0404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lactosuero, incluso concentrado o con adición de azúcar u otro edulcorante; productos constituidos por los componentes naturales de la leche, incluso con adición de azúcar u otro edulcorante, no expresados o incluidos en otra parte"
 },
 {
  "CNKEY": "040410000080",
  "CN": "0404 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lactosuero, aunque esté modificado, incluso concentrado o con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "040410020010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En polvo, gránulos o demás formas sólidas"
 },
 {
  "CNKEY": "040410020020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de proteínas (contenido de nitrógeno × 6,38)"
 },
 {
  "CNKEY": "040410020030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 15 % y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410020080",
  "CN": "0404 10 02",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410040080",
  "CN": "0404 10 04",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410060080",
  "CN": "0404 10 06",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410120010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Superior al 15 % y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410120080",
  "CN": "0404 10 12",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410140080",
  "CN": "0404 10 14",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410160080",
  "CN": "0404 10 16",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410260010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de proteínas (contenido de nitrógeno × 6,38)"
 },
 {
  "CNKEY": "040410260020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 15 % y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410260080",
  "CN": "0404 10 26",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410280080",
  "CN": "0404 10 28",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410320080",
  "CN": "0404 10 32",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410340010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Superior al 15 % y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410340080",
  "CN": "0404 10 34",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410360080",
  "CN": "0404 10 36",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410380080",
  "CN": "0404 10 38",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410480010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040410480020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de proteínas (contenido de nitrógeno × 6,38)"
 },
 {
  "CNKEY": "040410480030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 15 % en peso y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410480080",
  "CN": "0404 10 48",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410520080",
  "CN": "0404 10 52",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410540080",
  "CN": "0404 10 54",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410560010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Superior al 15 % en peso y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410560080",
  "CN": "0404 10 56",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410580080",
  "CN": "0404 10 58",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410620080",
  "CN": "0404 10 62",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410720010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de proteínas (contenido de nitrógeno × 6,38)"
 },
 {
  "CNKEY": "040410720020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 15 % en peso y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410720080",
  "CN": "0404 10 72",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410740080",
  "CN": "0404 10 74",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410760080",
  "CN": "0404 10 76",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040410780010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Superior al 15 % en peso y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040410780080",
  "CN": "0404 10 78",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040410820080",
  "CN": "0404 10 82",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040410840080",
  "CN": "0404 10 84",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040490000080",
  "CN": "0404 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040490210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin adición de azúcar ni otro edulcorante y con un contenido de materias grasas"
 },
 {
  "CNKEY": "040490210080",
  "CN": "0404 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040490230080",
  "CN": "0404 90 23",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040490290080",
  "CN": "0404 90 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040490810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido de materias grasas"
 },
 {
  "CNKEY": "040490810080",
  "CN": "0404 90 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "040490830080",
  "CN": "0404 90 83",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 1,5 % pero inferior o igual al 27 % en peso"
 },
 {
  "CNKEY": "040490890080",
  "CN": "0404 90 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 27 % en peso"
 },
 {
  "CNKEY": "040500000080",
  "CN": "0405",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mantequilla (manteca) y demás materias grasas de la leche; pastas lácteas para untar"
 },
 {
  "CNKEY": "040510000080",
  "CN": "0405 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mantequilla (manteca)"
 },
 {
  "CNKEY": "040510110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 85 % en peso"
 },
 {
  "CNKEY": "040510110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mantequilla natural"
 },
 {
  "CNKEY": "040510110080",
  "CN": "0405 10 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "040510190080",
  "CN": "0405 10 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040510300080",
  "CN": "0405 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mantequilla recombinada"
 },
 {
  "CNKEY": "040510500080",
  "CN": "0405 10 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mantequilla de lactosuero"
 },
 {
  "CNKEY": "040510900080",
  "CN": "0405 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040520000080",
  "CN": "0405 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pastas lácteas para untar"
 },
 {
  "CNKEY": "040520100080",
  "CN": "0405 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior o igual al 39 % pero inferior al 60 % en peso"
 },
 {
  "CNKEY": "040520300080",
  "CN": "0405 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior o igual al 60 % pero inferior o igual al 75 % en peso"
 },
 {
  "CNKEY": "040520900080",
  "CN": "0405 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior al 75 % pero inferior al 80 % en peso"
 },
 {
  "CNKEY": "040590000080",
  "CN": "0405 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040590100080",
  "CN": "0405 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior o igual al 99,3 % en peso, y de agua inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "040590900080",
  "CN": "0405 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040600000080",
  "CN": "0406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Quesos y requesón"
 },
 {
  "CNKEY": "040610000080",
  "CN": "0406 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Queso fresco (sin madurar), incluido el del lactosuero, y requesón"
 },
 {
  "CNKEY": "040610300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 40 % en peso"
 },
 {
  "CNKEY": "040610300080",
  "CN": "0406 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mozzarella, también en líquido"
 },
 {
  "CNKEY": "040610500080",
  "CN": "0406 10 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040610800080",
  "CN": "0406 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040620000080",
  "CN": "0406 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Queso de cualquier tipo, rallado o en polvo"
 },
 {
  "CNKEY": "040630000080",
  "CN": "0406 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Queso fundido (excepto el rallado o en polvo)"
 },
 {
  "CNKEY": "040630100080",
  "CN": "0406 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En cuya fabricación solo hayan entrado el emmental, el gruyère y el appenzell y, eventualmente, como adición, el Gladis con hierbas (llamado schabziger), acondicionados para la venta al por menor y con un contenido de materias grasas en la materia seca inferior o igual al 56 % en peso"
 },
 {
  "CNKEY": "040630310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040630310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 36 % en peso y de materias grasas en la materia seca"
 },
 {
  "CNKEY": "040630310080",
  "CN": "0406 30 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 48 % en peso"
 },
 {
  "CNKEY": "040630390080",
  "CN": "0406 30 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior al 48 %"
 },
 {
  "CNKEY": "040630900080",
  "CN": "0406 30 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas superior al 36 % en peso"
 },
 {
  "CNKEY": "040640000080",
  "CN": "0406 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Queso de pasta azul y demás quesos que presenten vetas producidas por Penicillium roqueforti"
 },
 {
  "CNKEY": "040640100080",
  "CN": "0406 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Roquefort"
 },
 {
  "CNKEY": "040640500080",
  "CN": "0406 40 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Gorgonzola"
 },
 {
  "CNKEY": "040640900080",
  "CN": "0406 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040690000080",
  "CN": "0406 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás quesos"
 },
 {
  "CNKEY": "040690010080",
  "CN": "0406 90 01",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación"
 },
 {
  "CNKEY": "040690130010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040690130080",
  "CN": "0406 90 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Emmental"
 },
 {
  "CNKEY": "040690150080",
  "CN": "0406 90 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Gruyère, sbrinz"
 },
 {
  "CNKEY": "040690170080",
  "CN": "0406 90 17",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bergkäse, appenzell"
 },
 {
  "CNKEY": "040690180080",
  "CN": "0406 90 18",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fromage fribourgeois, vacherin mont d'or y tête de moine"
 },
 {
  "CNKEY": "040690210080",
  "CN": "0406 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cheddar"
 },
 {
  "CNKEY": "040690230080",
  "CN": "0406 90 23",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Edam"
 },
 {
  "CNKEY": "040690250080",
  "CN": "0406 90 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tilsit"
 },
 {
  "CNKEY": "040690290080",
  "CN": "0406 90 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Kashkaval"
 },
 {
  "CNKEY": "040690320080",
  "CN": "0406 90 32",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Feta"
 },
 {
  "CNKEY": "040690350080",
  "CN": "0406 90 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Kefalotyri"
 },
 {
  "CNKEY": "040690370080",
  "CN": "0406 90 37",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Finlandia"
 },
 {
  "CNKEY": "040690390080",
  "CN": "0406 90 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Jarlsberg"
 },
 {
  "CNKEY": "040690500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040690500080",
  "CN": "0406 90 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De oveja o de búfala en recipientes con salmuera o en odres de piel de oveja o de cabra"
 },
 {
  "CNKEY": "040690610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040690610020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas inferior o igual al 40 % en peso, y un contenido de agua en la materia no grasa"
 },
 {
  "CNKEY": "040690610030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Inferior o igual al 47 % en peso"
 },
 {
  "CNKEY": "040690610080",
  "CN": "0406 90 61",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Grana padano, parmigiano reggiano"
 },
 {
  "CNKEY": "040690630080",
  "CN": "0406 90 63",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Fiore sardo, pecorino"
 },
 {
  "CNKEY": "040690690080",
  "CN": "0406 90 69",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040690730010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior al 47 % pero inferior o igual al 72 % en peso"
 },
 {
  "CNKEY": "040690730080",
  "CN": "0406 90 73",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Provolone"
 },
 {
  "CNKEY": "040690740080",
  "CN": "0406 90 74",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Maasdam"
 },
 {
  "CNKEY": "040690750080",
  "CN": "0406 90 75",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Asiago, caciocavallo, montasio, ragusano"
 },
 {
  "CNKEY": "040690760080",
  "CN": "0406 90 76",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Danbo, fontal, fontina, fynbo, havarti, maribo, samso"
 },
 {
  "CNKEY": "040690780080",
  "CN": "0406 90 78",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Gouda"
 },
 {
  "CNKEY": "040690790080",
  "CN": "0406 90 79",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Esrom, itálico, kernhem, saint-nectaire, saint-paulin, taleggio"
 },
 {
  "CNKEY": "040690810080",
  "CN": "0406 90 81",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Cantal, cheshire, wensleydale, lancashire, double gloucester, blarney, colby, monterey"
 },
 {
  "CNKEY": "040690820080",
  "CN": "0406 90 82",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Camembert"
 },
 {
  "CNKEY": "040690840080",
  "CN": "0406 90 84",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Brie"
 },
 {
  "CNKEY": "040690850080",
  "CN": "0406 90 85",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Kefalograviera, kasseri"
 },
 {
  "CNKEY": "040690860010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás quesos, con un contenido de agua en la materia no grasa"
 },
 {
  "CNKEY": "040690860080",
  "CN": "0406 90 86",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "Superior al 47 % pero inferior o igual al 52 % en peso"
 },
 {
  "CNKEY": "040690890080",
  "CN": "0406 90 89",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "Superior al 52 % pero inferior o igual al 62 % en peso"
 },
 {
  "CNKEY": "040690920080",
  "CN": "0406 90 92",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "Superior al 62 % pero inferior o igual al 72 % en peso"
 },
 {
  "CNKEY": "040690930080",
  "CN": "0406 90 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior al 72 % en peso"
 },
 {
  "CNKEY": "040690990080",
  "CN": "0406 90 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040700000080",
  "CN": "0407",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Huevos de ave con cáscara (cascarón), frescos, conservados o cocidos"
 },
 {
  "CNKEY": "040711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Huevos fecundados para incubación"
 },
 {
  "CNKEY": "040711000080",
  "CN": "0407 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De gallina de la especie Gallus domesticus"
 },
 {
  "CNKEY": "040719000080",
  "CN": "0407 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040719110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De aves de corral, distintos de los de las aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "040719110080",
  "CN": "0407 19 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De pava o de gansa"
 },
 {
  "CNKEY": "040719190080",
  "CN": "0407 19 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040719900080",
  "CN": "0407 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás huevos frescos"
 },
 {
  "CNKEY": "040721000080",
  "CN": "0407 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "1000 p\/st",
  "ES": "De gallina de la especie Gallus domesticus"
 },
 {
  "CNKEY": "040729000080",
  "CN": "0407 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040729100080",
  "CN": "0407 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "1000 p\/st",
  "ES": "De aves de corral, distintos de los de las aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "040729900080",
  "CN": "0407 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040790000080",
  "CN": "0407 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040790100080",
  "CN": "0407 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "1000 p\/st",
  "ES": "De aves de corral"
 },
 {
  "CNKEY": "040790900080",
  "CN": "0407 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040800000080",
  "CN": "0408",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Huevos de ave sin cáscara (cascarón) y yemas de huevo, frescos, secos, cocidos en agua o vapor, moldeados, congelados o conservados de otro modo, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "040811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Yemas de huevo"
 },
 {
  "CNKEY": "040811000080",
  "CN": "0408 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Secas"
 },
 {
  "CNKEY": "040811200080",
  "CN": "0408 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropias para el consumo humano"
 },
 {
  "CNKEY": "040811800080",
  "CN": "0408 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "040819000080",
  "CN": "0408 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040819200080",
  "CN": "0408 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropias para el consumo humano"
 },
 {
  "CNKEY": "040819810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "040819810080",
  "CN": "0408 19 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Líquidas"
 },
 {
  "CNKEY": "040819890080",
  "CN": "0408 19 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás, incluso congeladas"
 },
 {
  "CNKEY": "040891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040891000080",
  "CN": "0408 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Secos"
 },
 {
  "CNKEY": "040891200080",
  "CN": "0408 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropios para el consumo humano"
 },
 {
  "CNKEY": "040891800080",
  "CN": "0408 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040899000080",
  "CN": "0408 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "040899200080",
  "CN": "0408 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropios para el consumo humano"
 },
 {
  "CNKEY": "040899800080",
  "CN": "0408 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "040900000080",
  "CN": "0409 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Miel natural"
 },
 {
  "CNKEY": "041000000080",
  "CN": "0410",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Insectos y demás productos comestibles de origen animal, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "041010000080",
  "CN": "0410 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Insectos"
 },
 {
  "CNKEY": "041010100080",
  "CN": "0410 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frescos, refrigerados o congelados"
 },
 {
  "CNKEY": "041010910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "041010910080",
  "CN": "0410 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Harina y polvo comestibles, de carne o de despojos"
 },
 {
  "CNKEY": "041010990080",
  "CN": "0410 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "041090000080",
  "CN": "0410 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "050021000090",
  "CN": "05",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 5 - LOS DEMÁS PRODUCTOS DE ORIGEN ANIMAL NO EXPRESADOS NI COMPRENDIDOS EN OTRA PARTE"
 },
 {
  "CNKEY": "050100000080",
  "CN": "0501 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cabello en bruto, incluso lavado o desgrasado; desperdicios de cabello"
 },
 {
  "CNKEY": "050200000080",
  "CN": "0502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cerdas de cerdo o de jabalí; pelo de tejón y demás pelos para cepillería; desperdicios de dichas cerdas o pelos"
 },
 {
  "CNKEY": "050210000080",
  "CN": "0502 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cerdas de cerdo o de jabalí y sus desperdicios"
 },
 {
  "CNKEY": "050290000080",
  "CN": "0502 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "050400000080",
  "CN": "0504 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tripas, vejigas y estómagos de animales, excepto los de pescado, enteros o en trozos, frescos, refrigerados, congelados, salados o en salmuera, secos o ahumados"
 },
 {
  "CNKEY": "050500000080",
  "CN": "0505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pieles y demás partes de ave, con sus plumas o plumón, plumas y partes de plumas (incluso recortadas) y plumón, en bruto o simplemente limpiados, desinfectados o preparados para su conservación; polvo y desperdicios de plumas o de partes de plumas"
 },
 {
  "CNKEY": "050510000080",
  "CN": "0505 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Plumas de las utilizadas para relleno; plumón"
 },
 {
  "CNKEY": "050510100080",
  "CN": "0505 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "050510900080",
  "CN": "0505 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "050590000080",
  "CN": "0505 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "050600000080",
  "CN": "0506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Huesos y núcleos córneos, en bruto, desgrasados, simplemente preparados (pero sin cortar en forma determinada), acidulados o desgelatinizados; polvo y desperdicios de estas materias"
 },
 {
  "CNKEY": "050610000080",
  "CN": "0506 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Oseína y huesos acidulados"
 },
 {
  "CNKEY": "050690000080",
  "CN": "0506 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "050700000080",
  "CN": "0507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Marfil, concha (caparazón) de tortuga, ballenas de mamíferos marinos (incluidas las barbas), cuernos, astas, cascos, pezuñas, uñas, garras y picos, en bruto o simplemente preparados, pero sin cortar en forma determinada; polvo y desperdicios de estas materias"
 },
 {
  "CNKEY": "050710000080",
  "CN": "0507 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Marfil; polvo y desperdicios de marfil"
 },
 {
  "CNKEY": "050790000080",
  "CN": "0507 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "050800000080",
  "CN": "0508 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Coral y materias similares, en bruto o simplemente preparados, pero sin otro trabajo; valvas y caparazones de moluscos, crustáceos o equinodermos, y jibiones, en bruto o simplemente preparados, pero sin cortar en forma determinada, incluso en polvo y desperdicios"
 },
 {
  "CNKEY": "050800100080",
  "CN": "0508 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coral rojo (Corallium rubrum)"
 },
 {
  "CNKEY": "050800900080",
  "CN": "0508 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "051000000080",
  "CN": "0510 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Ámbar gris, castóreo, algalia y almizcle; cantáridas; bilis, incluso desecada; glándulas y demás sustancias de origen animal utilizadas para la preparación de productos farmacéuticos, frescas, refrigeradas, congeladas o conservadas provisionalmente de otra forma"
 },
 {
  "CNKEY": "051100000080",
  "CN": "0511",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos de origen animal no expresados ni comprendidos en otra parte; animales muertos de los Capítulos 1 o 3, impropios para la alimentación humana"
 },
 {
  "CNKEY": "051110000080",
  "CN": "0511 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Semen de bovino"
 },
 {
  "CNKEY": "051191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "051191000080",
  "CN": "0511 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Productos de pescado o de crustáceos, moluscos o demás invertebrados acuáticos; animales muertos del Capítulo 3"
 },
 {
  "CNKEY": "051191100080",
  "CN": "0511 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desperdicios de pescado"
 },
 {
  "CNKEY": "051191900080",
  "CN": "0511 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "051199000080",
  "CN": "0511 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "051199100080",
  "CN": "0511 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tendones, nervios, recortes y otros desperdicios similares de piel en bruto"
 },
 {
  "CNKEY": "051199310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Esponjas naturales de origen animal"
 },
 {
  "CNKEY": "051199310080",
  "CN": "0511 99 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "051199390080",
  "CN": "0511 99 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "051199850080",
  "CN": "0511 99 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060011000090",
  "CN": "II",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN II - PRODUCTOS DEL REINO VEGETAL"
 },
 {
  "CNKEY": "060021000090",
  "CN": "06",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 6 - PLANTAS VIVAS Y PRODUCTOS DE LA FLORICULTURA"
 },
 {
  "CNKEY": "060100000080",
  "CN": "0601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en reposo vegetativo, en vegetación o en flor; plantas y raíces de achicoria, excepto las raíces de la partida 1212"
 },
 {
  "CNKEY": "060110000080",
  "CN": "0601 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en reposo vegetativo"
 },
 {
  "CNKEY": "060110100080",
  "CN": "0601 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Jacintos"
 },
 {
  "CNKEY": "060110200080",
  "CN": "0601 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Narcisos"
 },
 {
  "CNKEY": "060110300080",
  "CN": "0601 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tulipanes"
 },
 {
  "CNKEY": "060110400080",
  "CN": "0601 10 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Gladiolos"
 },
 {
  "CNKEY": "060110900080",
  "CN": "0601 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060120000080",
  "CN": "0601 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bulbos, cebollas, tubérculos, raíces y bulbos tuberosos, turiones y rizomas, en vegetación o en flor; plantas y raíces de achicoria"
 },
 {
  "CNKEY": "060120100080",
  "CN": "0601 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plantas y raíces de achicoria"
 },
 {
  "CNKEY": "060120300080",
  "CN": "0601 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Orquídeas, jacintos, narcisos y tulipanes"
 },
 {
  "CNKEY": "060120900080",
  "CN": "0601 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060200000080",
  "CN": "0602",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás plantas vivas (incluidas sus raíces), esquejes e injertos; micelios"
 },
 {
  "CNKEY": "060210000080",
  "CN": "0602 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esquejes sin enraizar e injertos"
 },
 {
  "CNKEY": "060210100080",
  "CN": "0602 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De vid"
 },
 {
  "CNKEY": "060210900080",
  "CN": "0602 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060220000080",
  "CN": "0602 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Árboles, arbustos y matas, de frutas o de otros frutos comestibles, incluso injertados"
 },
 {
  "CNKEY": "060220100080",
  "CN": "0602 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plantas de vid, injertadas o con raíces (barbados)"
 },
 {
  "CNKEY": "060220200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060220200080",
  "CN": "0602 20 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con raíces desnudas"
 },
 {
  "CNKEY": "060220300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060220300080",
  "CN": "0602 20 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Agrios (cítricos)"
 },
 {
  "CNKEY": "060220800080",
  "CN": "0602 20 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060230000080",
  "CN": "0602 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Rododendros y azaleas, incluso injertados"
 },
 {
  "CNKEY": "060240000080",
  "CN": "0602 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Rosales, incluso injertados"
 },
 {
  "CNKEY": "060290000080",
  "CN": "0602 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290100080",
  "CN": "0602 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Micelios"
 },
 {
  "CNKEY": "060290200080",
  "CN": "0602 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plantas de piña (ananá)"
 },
 {
  "CNKEY": "060290300080",
  "CN": "0602 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plantas de hortalizas y plantas de fresas"
 },
 {
  "CNKEY": "060290410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Plantas de exterior"
 },
 {
  "CNKEY": "060290410030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Árboles, arbustos y matas de tallo leñoso"
 },
 {
  "CNKEY": "060290410080",
  "CN": "0602 90 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Forestales"
 },
 {
  "CNKEY": "060290450010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290450080",
  "CN": "0602 90 45",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Esquejes enraizados y plantas jóvenes"
 },
 {
  "CNKEY": "060290460010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290460080",
  "CN": "0602 90 46",
  "CN_LEVEL": 10,
  "T_SU_SU": "p\/st",
  "ES": "Con raíces desnudas"
 },
 {
  "CNKEY": "060290470010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290470080",
  "CN": "0602 90 47",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Coníferas y plantas de hoja perenne"
 },
 {
  "CNKEY": "060290480080",
  "CN": "0602 90 48",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060290500080",
  "CN": "0602 90 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás plantas de exterior"
 },
 {
  "CNKEY": "060290700010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Plantas de interior"
 },
 {
  "CNKEY": "060290700080",
  "CN": "0602 90 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Esquejes enraizados y plantas jóvenes (excepto las cactáceas)"
 },
 {
  "CNKEY": "060290910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "060290910080",
  "CN": "0602 90 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Plantas de flores, en capullo o en flor (excepto las cactáceas)"
 },
 {
  "CNKEY": "060290990080",
  "CN": "0602 90 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "060300000080",
  "CN": "0603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Flores y capullos, cortados para ramos o adornos, frescos, secos, blanqueados, teñidos, impregnados o preparados de otra forma"
 },
 {
  "CNKEY": "060311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frescos"
 },
 {
  "CNKEY": "060311000080",
  "CN": "0603 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Rosas"
 },
 {
  "CNKEY": "060312000080",
  "CN": "0603 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Claveles"
 },
 {
  "CNKEY": "060313000080",
  "CN": "0603 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Orquídeas"
 },
 {
  "CNKEY": "060314000080",
  "CN": "0603 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Crisantemos"
 },
 {
  "CNKEY": "060315000080",
  "CN": "0603 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Azucenas (Lilium spp.)"
 },
 {
  "CNKEY": "060319000080",
  "CN": "0603 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060319100080",
  "CN": "0603 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Gladiolos"
 },
 {
  "CNKEY": "060319200080",
  "CN": "0603 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Ranúnculos"
 },
 {
  "CNKEY": "060319700080",
  "CN": "0603 19 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060390000080",
  "CN": "0603 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060400000080",
  "CN": "0604",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Follaje, hojas, ramas y demás partes de plantas, sin flores ni capullos, y hierbas, musgos y líquenes, para ramos o adornos, frescos, secos, blanqueados, teñidos, impregnados o preparados de otra forma"
 },
 {
  "CNKEY": "060420000080",
  "CN": "0604 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frescos"
 },
 {
  "CNKEY": "060420110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Musgos y líquenes"
 },
 {
  "CNKEY": "060420110080",
  "CN": "0604 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Liquen de los renos"
 },
 {
  "CNKEY": "060420190080",
  "CN": "0604 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060420200080",
  "CN": "0604 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Árboles de Navidad"
 },
 {
  "CNKEY": "060420400080",
  "CN": "0604 20 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ramas de coníferas"
 },
 {
  "CNKEY": "060420900080",
  "CN": "0604 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060490000080",
  "CN": "0604 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060490110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Musgos y líquenes"
 },
 {
  "CNKEY": "060490110080",
  "CN": "0604 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Liquen de los renos"
 },
 {
  "CNKEY": "060490190080",
  "CN": "0604 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "060490910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "060490910080",
  "CN": "0604 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Simplemente secos"
 },
 {
  "CNKEY": "060490990080",
  "CN": "0604 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070021000090",
  "CN": "07",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 7 - HORTALIZAS, PLANTAS, RAÍCES Y TUBÉRCULOS ALIMENTICIOS"
 },
 {
  "CNKEY": "070100000080",
  "CN": "0701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Patatas (papas) frescas o refrigeradas"
 },
 {
  "CNKEY": "070110000080",
  "CN": "0701 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "070190000080",
  "CN": "0701 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "070190100080",
  "CN": "0701 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a la fabricación de fécula"
 },
 {
  "CNKEY": "070190500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "070190500080",
  "CN": "0701 90 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tempranas, del 1 de enero al 30 de junio"
 },
 {
  "CNKEY": "070190900080",
  "CN": "0701 90 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070200000080",
  "CN": "0702 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tomates frescos o refrigerados"
 },
 {
  "CNKEY": "070300000080",
  "CN": "0703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cebollas, chalotes, ajos, puerros y demás hortalizas aliáceas, frescos o refrigerados"
 },
 {
  "CNKEY": "070310000080",
  "CN": "0703 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cebollas y chalotes"
 },
 {
  "CNKEY": "070310110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cebollas"
 },
 {
  "CNKEY": "070310110080",
  "CN": "0703 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para simiente"
 },
 {
  "CNKEY": "070310190080",
  "CN": "0703 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070310900080",
  "CN": "0703 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chalotes"
 },
 {
  "CNKEY": "070320000080",
  "CN": "0703 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ajos"
 },
 {
  "CNKEY": "070390000080",
  "CN": "0703 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Puerros y demás hortalizas aliáceas"
 },
 {
  "CNKEY": "070400000080",
  "CN": "0704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Coles, incluidos los repollos, coliflores, coles rizadas, colinabos y productos comestibles similares del género Brassica, frescos o refrigerados"
 },
 {
  "CNKEY": "070410000080",
  "CN": "0704 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Coliflores y brócolis"
 },
 {
  "CNKEY": "070410100080",
  "CN": "0704 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coliflores y brócolis «broccoli»"
 },
 {
  "CNKEY": "070410900080",
  "CN": "0704 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070420000080",
  "CN": "0704 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coles (repollitos) de Bruselas"
 },
 {
  "CNKEY": "070490000080",
  "CN": "0704 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "070490100080",
  "CN": "0704 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coles blancas y rojas (lombardas)"
 },
 {
  "CNKEY": "070490900080",
  "CN": "0704 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070500000080",
  "CN": "0705",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lechugas (Lactuca sativa) y achicorias, comprendidas la escarola y la endibia (Cichorium spp.), frescas o refrigeradas"
 },
 {
  "CNKEY": "070511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lechugas"
 },
 {
  "CNKEY": "070511000080",
  "CN": "0705 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Repolladas"
 },
 {
  "CNKEY": "070519000080",
  "CN": "0705 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Achicorias, comprendidas la escarola y la endibia"
 },
 {
  "CNKEY": "070521000080",
  "CN": "0705 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Endibia witloof (Cichorium intybus var. foliosum)"
 },
 {
  "CNKEY": "070529000080",
  "CN": "0705 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070600000080",
  "CN": "0706",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Zanahorias, nabos, remolachas para ensalada, salsifíes, apionabos, rábanos y raíces comestibles similares, frescos o refrigerados"
 },
 {
  "CNKEY": "070610000080",
  "CN": "0706 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Zanahorias y nabos"
 },
 {
  "CNKEY": "070690000080",
  "CN": "0706 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "070690100080",
  "CN": "0706 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Apionabos"
 },
 {
  "CNKEY": "070690300080",
  "CN": "0706 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rábanos rusticanos (Cochlearia armoracia)"
 },
 {
  "CNKEY": "070690900080",
  "CN": "0706 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070700000080",
  "CN": "0707 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pepinos y pepinillos, frescos o refrigerados"
 },
 {
  "CNKEY": "070700050080",
  "CN": "0707 00 05",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pepinos"
 },
 {
  "CNKEY": "070700900080",
  "CN": "0707 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pepinillos"
 },
 {
  "CNKEY": "070800000080",
  "CN": "0708",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas de vaina, aunque estén desvainadas, frescas o refrigeradas"
 },
 {
  "CNKEY": "070810000080",
  "CN": "0708 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "CNKEY": "070820000080",
  "CN": "0708 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "CNKEY": "070890000080",
  "CN": "0708 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070900000080",
  "CN": "0709",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas, frescas o refrigeradas"
 },
 {
  "CNKEY": "070920000080",
  "CN": "0709 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Espárragos"
 },
 {
  "CNKEY": "070930000080",
  "CN": "0709 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Berenjenas"
 },
 {
  "CNKEY": "070940000080",
  "CN": "0709 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Apio, excepto el apionabo"
 },
 {
  "CNKEY": "070951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hongos y trufas"
 },
 {
  "CNKEY": "070951000080",
  "CN": "0709 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hongos del género Agaricus"
 },
 {
  "CNKEY": "070952000080",
  "CN": "0709 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hongos del género Boletus"
 },
 {
  "CNKEY": "070953000080",
  "CN": "0709 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hongos del género Cantharellus"
 },
 {
  "CNKEY": "070954000080",
  "CN": "0709 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Shiitake (Lentinus edodes)"
 },
 {
  "CNKEY": "070955000080",
  "CN": "0709 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Matsutake (Tricholoma matsutake, Tricholoma magnivelare, Tricholoma anatolicum, Tricholoma dulciolens, Tricholoma caligatum)"
 },
 {
  "CNKEY": "070956000080",
  "CN": "0709 56 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trufas (Tuber spp.)"
 },
 {
  "CNKEY": "070959000080",
  "CN": "0709 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070960000080",
  "CN": "0709 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "CNKEY": "070960100080",
  "CN": "0709 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pimientos dulces"
 },
 {
  "CNKEY": "070960910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "070960910080",
  "CN": "0709 60 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Del género Capsicum que se destinen a la fabricación de capsicina o de colorantes de oleorresinas de Capsicum"
 },
 {
  "CNKEY": "070960950080",
  "CN": "0709 60 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a la fabricación industrial de aceites esenciales o de resinoides"
 },
 {
  "CNKEY": "070960990080",
  "CN": "0709 60 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "070970000080",
  "CN": "0709 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Espinacas (incluida la de Nueva Zelanda) y armuelles"
 },
 {
  "CNKEY": "070991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "070991000080",
  "CN": "0709 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alcachofas (alcauciles)"
 },
 {
  "CNKEY": "070992000080",
  "CN": "0709 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceitunas"
 },
 {
  "CNKEY": "070992100080",
  "CN": "0709 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que no se destinen a la producción de aceite"
 },
 {
  "CNKEY": "070992900080",
  "CN": "0709 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070993000080",
  "CN": "0709 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Calabazas (zapallos) y calabacines (Cucurbita spp.)"
 },
 {
  "CNKEY": "070993100080",
  "CN": "0709 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Calabacines (zapallitos)"
 },
 {
  "CNKEY": "070993900080",
  "CN": "0709 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "070999000080",
  "CN": "0709 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "070999100080",
  "CN": "0709 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ensaladas [excepto las lechugas (Lactuca sativa)] y achicorias [comprendidas la escarola y la endivia (Cichorium spp.)]"
 },
 {
  "CNKEY": "070999200080",
  "CN": "0709 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acelgas y cardos"
 },
 {
  "CNKEY": "070999400080",
  "CN": "0709 99 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Alcaparras"
 },
 {
  "CNKEY": "070999500080",
  "CN": "0709 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hinojo"
 },
 {
  "CNKEY": "070999600080",
  "CN": "0709 99 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Maíz dulce"
 },
 {
  "CNKEY": "070999900080",
  "CN": "0709 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071000000080",
  "CN": "0710",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas, aunque estén cocidas en agua o vapor, congeladas"
 },
 {
  "CNKEY": "071010000080",
  "CN": "0710 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Patatas (papas)"
 },
 {
  "CNKEY": "071021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hortalizas de vaina, estén o no desvainadas"
 },
 {
  "CNKEY": "071021000080",
  "CN": "0710 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "CNKEY": "071022000080",
  "CN": "0710 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "CNKEY": "071029000080",
  "CN": "0710 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071030000080",
  "CN": "0710 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Espinacas (incluida la de Nueva Zelanda) y armuelles"
 },
 {
  "CNKEY": "071040000080",
  "CN": "0710 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Maíz dulce"
 },
 {
  "CNKEY": "071080000080",
  "CN": "0710 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas"
 },
 {
  "CNKEY": "071080100080",
  "CN": "0710 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceitunas"
 },
 {
  "CNKEY": "071080510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "CNKEY": "071080510080",
  "CN": "0710 80 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pimientos dulces"
 },
 {
  "CNKEY": "071080590080",
  "CN": "0710 80 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "071080610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Setas"
 },
 {
  "CNKEY": "071080610080",
  "CN": "0710 80 61",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Del género Agaricus"
 },
 {
  "CNKEY": "071080690080",
  "CN": "0710 80 69",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071080700080",
  "CN": "0710 80 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tomates"
 },
 {
  "CNKEY": "071080800080",
  "CN": "0710 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alcachofas (alcauciles)"
 },
 {
  "CNKEY": "071080850080",
  "CN": "0710 80 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Espárragos"
 },
 {
  "CNKEY": "071080950080",
  "CN": "0710 80 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071090000080",
  "CN": "0710 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mezclas de hortalizas"
 },
 {
  "CNKEY": "071100000080",
  "CN": "0711",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas conservadas provisionalmente, pero todavía impropias, en este estado, para consumo inmediato"
 },
 {
  "CNKEY": "071120000080",
  "CN": "0711 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceitunas"
 },
 {
  "CNKEY": "071120100080",
  "CN": "0711 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que no se destinen a la producción de aceite"
 },
 {
  "CNKEY": "071120900080",
  "CN": "0711 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071140000080",
  "CN": "0711 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pepinos y pepinillos"
 },
 {
  "CNKEY": "071151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hongos y trufas"
 },
 {
  "CNKEY": "071151000080",
  "CN": "0711 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg\/net eda",
  "ES": "Hongos del género Agaricus"
 },
 {
  "CNKEY": "071159000080",
  "CN": "0711 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "071190000080",
  "CN": "0711 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas; mezclas de hortalizas"
 },
 {
  "CNKEY": "071190100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hortalizas"
 },
 {
  "CNKEY": "071190100080",
  "CN": "0711 90 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos de los géneros Capsicum o Pimenta (excepto los pimientos dulces)"
 },
 {
  "CNKEY": "071190300080",
  "CN": "0711 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Maíz dulce"
 },
 {
  "CNKEY": "071190500080",
  "CN": "0711 90 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cebollas"
 },
 {
  "CNKEY": "071190700080",
  "CN": "0711 90 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Alcaparras"
 },
 {
  "CNKEY": "071190800080",
  "CN": "0711 90 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071190900080",
  "CN": "0711 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclas de hortalizas"
 },
 {
  "CNKEY": "071200000080",
  "CN": "0712",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas secas, incluidas las cortadas en trozos o en rodajas o las trituradas o pulverizadas, pero sin otra preparación"
 },
 {
  "CNKEY": "071220000080",
  "CN": "0712 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cebollas"
 },
 {
  "CNKEY": "071231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Orejas de Judas (Auricularia spp.), hongos gelatinosos (Tremella spp.) y demás hongos; trufas"
 },
 {
  "CNKEY": "071231000080",
  "CN": "0712 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hongos del género Agaricus"
 },
 {
  "CNKEY": "071232000080",
  "CN": "0712 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Orejas de Judas (Auricularia spp.)"
 },
 {
  "CNKEY": "071233000080",
  "CN": "0712 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hongos gelatinosos (Tremella spp.)"
 },
 {
  "CNKEY": "071234000080",
  "CN": "0712 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Shiitake (Lentinus edodes)"
 },
 {
  "CNKEY": "071239000080",
  "CN": "0712 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "071290000080",
  "CN": "0712 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas; mezclas de hortalizas"
 },
 {
  "CNKEY": "071290050080",
  "CN": "0712 90 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Patatas (papas), incluso en trozos o en rodajas, pero sin otra preparación"
 },
 {
  "CNKEY": "071290110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "CNKEY": "071290110080",
  "CN": "0712 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Híbrido, para siembra"
 },
 {
  "CNKEY": "071290190080",
  "CN": "0712 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "071290300080",
  "CN": "0712 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tomates"
 },
 {
  "CNKEY": "071290500080",
  "CN": "0712 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Zanahorias"
 },
 {
  "CNKEY": "071290900080",
  "CN": "0712 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071300000080",
  "CN": "0713",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas de vaina secas desvainadas, aunque estén mondadas o partidas"
 },
 {
  "CNKEY": "071310000080",
  "CN": "0713 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "CNKEY": "071310100080",
  "CN": "0713 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "071310900080",
  "CN": "0713 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "071320000080",
  "CN": "0713 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Garbanzos"
 },
 {
  "CNKEY": "071331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "CNKEY": "071331000080",
  "CN": "0713 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) de las especies Vigna mungo (L) Hepper o Vigna radiata (L) Wilczek"
 },
 {
  "CNKEY": "071332000080",
  "CN": "0713 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) Adzuki (Phaseolus o Vigna angularis)"
 },
 {
  "CNKEY": "071333000080",
  "CN": "0713 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) comunes (Phaseolus vulgaris)"
 },
 {
  "CNKEY": "071333100080",
  "CN": "0713 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "071333900080",
  "CN": "0713 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071334000080",
  "CN": "0713 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) bambara (Vigna subterranea o Voandzeia subterranea)"
 },
 {
  "CNKEY": "071335000080",
  "CN": "0713 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) salvajes o caupí (Vigna unguiculata)"
 },
 {
  "CNKEY": "071339000080",
  "CN": "0713 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071340000080",
  "CN": "0713 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lentejas"
 },
 {
  "CNKEY": "071350000080",
  "CN": "0713 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Habas (Vicia faba var. major), haba caballar (Vicia faba var. equina) y haba menor (Vicia faba var. minor)"
 },
 {
  "CNKEY": "071360000080",
  "CN": "0713 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guisantes (arvejas, chícharos) de palo, gandú o gandul (Cajanus cajan)"
 },
 {
  "CNKEY": "071390000080",
  "CN": "0713 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071400000080",
  "CN": "0714",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Raíces de mandioca (yuca), arrurruz o salep, aguaturmas (patacas), batatas (boniatos, camotes) y raíces y tubérculos similares ricos en fécula o inulina, frescos, refrigerados, congelados o secos, incluso troceados o en pellets; médula de sagú"
 },
 {
  "CNKEY": "071410000080",
  "CN": "0714 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Raíces de mandioca (yuca)"
 },
 {
  "CNKEY": "071420000080",
  "CN": "0714 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Batatas (boniatos, camotes)"
 },
 {
  "CNKEY": "071420100080",
  "CN": "0714 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frescas, enteras, para el consumo humano"
 },
 {
  "CNKEY": "071420900080",
  "CN": "0714 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "071430000080",
  "CN": "0714 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ñame (Dioscorea spp.)"
 },
 {
  "CNKEY": "071440000080",
  "CN": "0714 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Taro (Colocasia spp.)"
 },
 {
  "CNKEY": "071450000080",
  "CN": "0714 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yautía (malanga) (Xanthosoma spp.)"
 },
 {
  "CNKEY": "071490000080",
  "CN": "0714 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "071490200080",
  "CN": "0714 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Raíces de arrurruz y de salep, y raíces y tubérculos similares ricos en fécula"
 },
 {
  "CNKEY": "071490900080",
  "CN": "0714 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "080021000090",
  "CN": "08",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 8 - FRUTAS Y FRUTOS COMESTIBLES; CORTEZAS DE AGRIOS (CÍTRICOS), MELONES O SANDÍAS"
 },
 {
  "CNKEY": "080100000080",
  "CN": "0801",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cocos, nueces del Brasil y nueces de marañón (merey, cajuil, anacardo, cajú), frescos o secos, incluso sin cáscara o mondados"
 },
 {
  "CNKEY": "080111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cocos"
 },
 {
  "CNKEY": "080111000080",
  "CN": "0801 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secos"
 },
 {
  "CNKEY": "080112000080",
  "CN": "0801 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con la cáscara interna (endocarpio)"
 },
 {
  "CNKEY": "080119000080",
  "CN": "0801 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "080121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nueces del Brasil"
 },
 {
  "CNKEY": "080121000080",
  "CN": "0801 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080122000080",
  "CN": "0801 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nueces de marañón (merey, cajuil, anacardo, cajú)"
 },
 {
  "CNKEY": "080131000080",
  "CN": "0801 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080132000080",
  "CN": "0801 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080200000080",
  "CN": "0802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás frutos de cáscara frescos o secos, incluso sin cáscara o mondados"
 },
 {
  "CNKEY": "080211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Almendras"
 },
 {
  "CNKEY": "080211000080",
  "CN": "0802 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080211100080",
  "CN": "0802 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Amargas"
 },
 {
  "CNKEY": "080211900080",
  "CN": "0802 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080212000080",
  "CN": "0802 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080212100080",
  "CN": "0802 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Amargas"
 },
 {
  "CNKEY": "080212900080",
  "CN": "0802 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Avellanas (Corylus spp.)"
 },
 {
  "CNKEY": "080221000080",
  "CN": "0802 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080222000080",
  "CN": "0802 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nueces de nogal"
 },
 {
  "CNKEY": "080231000080",
  "CN": "0802 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080232000080",
  "CN": "0802 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Castañas (Castanea spp.)"
 },
 {
  "CNKEY": "080241000080",
  "CN": "0802 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080242000080",
  "CN": "0802 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pistachos"
 },
 {
  "CNKEY": "080251000080",
  "CN": "0802 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080252000080",
  "CN": "0802 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080261000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nueces de macadamia"
 },
 {
  "CNKEY": "080261000080",
  "CN": "0802 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "080262000080",
  "CN": "0802 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara"
 },
 {
  "CNKEY": "080270000080",
  "CN": "0802 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Nueces de cola (Cola spp.)"
 },
 {
  "CNKEY": "080280000080",
  "CN": "0802 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Nueces de areca"
 },
 {
  "CNKEY": "080291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "080291000080",
  "CN": "0802 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Piñones con cáscara"
 },
 {
  "CNKEY": "080292000080",
  "CN": "0802 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Piñones sin cáscara"
 },
 {
  "CNKEY": "080299000080",
  "CN": "0802 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "080299100080",
  "CN": "0802 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pacanas"
 },
 {
  "CNKEY": "080299900080",
  "CN": "0802 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "080300000080",
  "CN": "0803",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Plátanos (bananas), incluidos los «plantains» (plátanos macho), frescos o secos"
 },
 {
  "CNKEY": "080310000080",
  "CN": "0803 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "«Plantains» (plátanos macho)"
 },
 {
  "CNKEY": "080310100080",
  "CN": "0803 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frescos"
 },
 {
  "CNKEY": "080310900080",
  "CN": "0803 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secos"
 },
 {
  "CNKEY": "080390000080",
  "CN": "0803 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "080390100080",
  "CN": "0803 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frescos"
 },
 {
  "CNKEY": "080390900080",
  "CN": "0803 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secos"
 },
 {
  "CNKEY": "080400000080",
  "CN": "0804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Dátiles, higos, piñas (ananás), aguacates (paltas), guayabas, mangos y mangostanes, frescos o secos"
 },
 {
  "CNKEY": "080410000080",
  "CN": "0804 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dátiles"
 },
 {
  "CNKEY": "080420000080",
  "CN": "0804 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Higos"
 },
 {
  "CNKEY": "080420100080",
  "CN": "0804 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frescos"
 },
 {
  "CNKEY": "080420900080",
  "CN": "0804 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secos"
 },
 {
  "CNKEY": "080430000080",
  "CN": "0804 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piñas (ananás)"
 },
 {
  "CNKEY": "080440000080",
  "CN": "0804 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aguacates (paltas)"
 },
 {
  "CNKEY": "080450000080",
  "CN": "0804 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guayabas, mangos y mangostanes"
 },
 {
  "CNKEY": "080500000080",
  "CN": "0805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Agrios (cítricos) frescos o secos"
 },
 {
  "CNKEY": "080510000080",
  "CN": "0805 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Naranjas"
 },
 {
  "CNKEY": "080510220010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Naranjas dulces, frescas"
 },
 {
  "CNKEY": "080510220080",
  "CN": "0805 10 22",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Naranjas navel"
 },
 {
  "CNKEY": "080510240080",
  "CN": "0805 10 24",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Naranjas blancas"
 },
 {
  "CNKEY": "080510280080",
  "CN": "0805 10 28",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080510800080",
  "CN": "0805 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mandarinas (incluidas las tangerinas y satsumas); clementinas, «wilkings» e híbridos similares de agrios (cítricos)"
 },
 {
  "CNKEY": "080521000080",
  "CN": "0805 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mandarinas (incluidas las tangerinas y satsumas)"
 },
 {
  "CNKEY": "080521100080",
  "CN": "0805 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Satsumas"
 },
 {
  "CNKEY": "080521900080",
  "CN": "0805 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080522000080",
  "CN": "0805 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clementinas"
 },
 {
  "CNKEY": "080529000080",
  "CN": "0805 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080540000080",
  "CN": "0805 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Toronjas y pomelos"
 },
 {
  "CNKEY": "080550000080",
  "CN": "0805 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Limones (Citrus limon, Citrus limonum) y limas (Citrus aurantifolia, Citrus latifolia)"
 },
 {
  "CNKEY": "080550100080",
  "CN": "0805 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Limones (Citrus limon, Citrus limonum)"
 },
 {
  "CNKEY": "080550900080",
  "CN": "0805 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Limas (Citrus aurantifolia, Citrus latifolia)"
 },
 {
  "CNKEY": "080590000080",
  "CN": "0805 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "080600000080",
  "CN": "0806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Uvas, frescas o secas, incluidas las pasas"
 },
 {
  "CNKEY": "080610000080",
  "CN": "0806 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frescas"
 },
 {
  "CNKEY": "080610100080",
  "CN": "0806 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De mesa"
 },
 {
  "CNKEY": "080610900080",
  "CN": "0806 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080620000080",
  "CN": "0806 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Secas, incluidas las pasas"
 },
 {
  "CNKEY": "080620100080",
  "CN": "0806 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pasas de Corinto"
 },
 {
  "CNKEY": "080620300080",
  "CN": "0806 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pasas sultaninas"
 },
 {
  "CNKEY": "080620900080",
  "CN": "0806 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080700000080",
  "CN": "0807",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Melones, sandías y papayas, frescos"
 },
 {
  "CNKEY": "080711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Melones y sandías"
 },
 {
  "CNKEY": "080711000080",
  "CN": "0807 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sandías"
 },
 {
  "CNKEY": "080719000080",
  "CN": "0807 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "080720000080",
  "CN": "0807 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papayas"
 },
 {
  "CNKEY": "080800000080",
  "CN": "0808",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manzanas, peras y membrillos, frescos"
 },
 {
  "CNKEY": "080810000080",
  "CN": "0808 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Manzanas"
 },
 {
  "CNKEY": "080810100080",
  "CN": "0808 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Manzanas para sidra, a granel, del 16 de septiembre al 15 de diciembre"
 },
 {
  "CNKEY": "080810800080",
  "CN": "0808 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080830000080",
  "CN": "0808 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peras"
 },
 {
  "CNKEY": "080830100080",
  "CN": "0808 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peras para perada, a granel, del 1 de agosto al 31 de diciembre"
 },
 {
  "CNKEY": "080830900080",
  "CN": "0808 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080840000080",
  "CN": "0808 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Membrillos"
 },
 {
  "CNKEY": "080900000080",
  "CN": "0809",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Albaricoques (damascos, chabacanos), cerezas, melocotones (duraznos) (incluidos los griñones y nectarinas), ciruelas y endrinas, frescos"
 },
 {
  "CNKEY": "080910000080",
  "CN": "0809 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Albaricoques (damascos, chabacanos)"
 },
 {
  "CNKEY": "080921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cerezas"
 },
 {
  "CNKEY": "080921000080",
  "CN": "0809 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guindas (cerezas ácidas) (Prunus cerasus)"
 },
 {
  "CNKEY": "080929000080",
  "CN": "0809 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080930000080",
  "CN": "0809 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Melocotones (duraznos), incluidos los griñones y nectarinas"
 },
 {
  "CNKEY": "080930100080",
  "CN": "0809 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Griñones y nectarinas"
 },
 {
  "CNKEY": "080930900080",
  "CN": "0809 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "080940000080",
  "CN": "0809 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ciruelas y endrinas"
 },
 {
  "CNKEY": "080940050080",
  "CN": "0809 40 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciruelas"
 },
 {
  "CNKEY": "080940900080",
  "CN": "0809 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Endrinas"
 },
 {
  "CNKEY": "081000000080",
  "CN": "0810",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás frutas u otros frutos, frescos"
 },
 {
  "CNKEY": "081010000080",
  "CN": "0810 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fresas (frutillas)"
 },
 {
  "CNKEY": "081020000080",
  "CN": "0810 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frambuesas, zarzamoras, moras y moras-frambuesa"
 },
 {
  "CNKEY": "081020100080",
  "CN": "0810 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frambuesas"
 },
 {
  "CNKEY": "081020900080",
  "CN": "0810 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081030000080",
  "CN": "0810 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grosellas negras, blancas o rojas y grosellas espinosas"
 },
 {
  "CNKEY": "081030100080",
  "CN": "0810 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grosellas negras (casis)"
 },
 {
  "CNKEY": "081030300080",
  "CN": "0810 30 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grosellas rojas"
 },
 {
  "CNKEY": "081030900080",
  "CN": "0810 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081040000080",
  "CN": "0810 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arándanos rojos, mirtilos y demás frutos del género Vaccinium"
 },
 {
  "CNKEY": "081040100080",
  "CN": "0810 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frutos del Vaccinium vitis-idaea (arándanos rojos)"
 },
 {
  "CNKEY": "081040300080",
  "CN": "0810 40 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frutos del Vaccinium myrtillus (arándanos, mirtilos)"
 },
 {
  "CNKEY": "081040500080",
  "CN": "0810 40 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frutos del Vaccinium macrocarpon y del Vaccinium corymbosum"
 },
 {
  "CNKEY": "081040900080",
  "CN": "0810 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081050000080",
  "CN": "0810 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Kiwis"
 },
 {
  "CNKEY": "081060000080",
  "CN": "0810 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Duriones"
 },
 {
  "CNKEY": "081070000080",
  "CN": "0810 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caquis (persimonios)"
 },
 {
  "CNKEY": "081090000080",
  "CN": "0810 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "081090200080",
  "CN": "0810 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), frutos del árbol del pan, litchis, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "CNKEY": "081090750080",
  "CN": "0810 90 75",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081100000080",
  "CN": "0811",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Frutas y otros frutos, sin cocer o cocidos en agua o vapor, congelados, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "081110000080",
  "CN": "0811 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fresas (frutillas)"
 },
 {
  "CNKEY": "081110110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "081110110080",
  "CN": "0811 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "081110190080",
  "CN": "0811 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081110900080",
  "CN": "0811 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081120000080",
  "CN": "0811 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frambuesas, zarzamoras, moras, moras-frambuesa y grosellas"
 },
 {
  "CNKEY": "081120110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "081120110080",
  "CN": "0811 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "081120190080",
  "CN": "0811 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081120310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "081120310080",
  "CN": "0811 20 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frambuesas"
 },
 {
  "CNKEY": "081120390080",
  "CN": "0811 20 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Grosellas negras (casis)"
 },
 {
  "CNKEY": "081120510080",
  "CN": "0811 20 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Grosellas rojas"
 },
 {
  "CNKEY": "081120590080",
  "CN": "0811 20 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Zarzamoras, moras y moras-frambuesa"
 },
 {
  "CNKEY": "081120900080",
  "CN": "0811 20 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081190000080",
  "CN": "0811 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "081190110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "081190110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "081190110080",
  "CN": "0811 90 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "081190190080",
  "CN": "0811 90 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081190310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "081190310080",
  "CN": "0811 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "081190390080",
  "CN": "0811 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081190500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "081190500080",
  "CN": "0811 90 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos del Vaccinium myrtillus (arándanos, mirtilos)"
 },
 {
  "CNKEY": "081190700080",
  "CN": "0811 90 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos de las especies Vaccinium myrtilloides y Vaccinium angustifolium"
 },
 {
  "CNKEY": "081190750010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cerezas"
 },
 {
  "CNKEY": "081190750080",
  "CN": "0811 90 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Guindas (Prunus cerasus)"
 },
 {
  "CNKEY": "081190800080",
  "CN": "0811 90 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081190850080",
  "CN": "0811 90 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "081190950080",
  "CN": "0811 90 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081200000080",
  "CN": "0812",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Frutas y otros frutos, conservados provisionalmente, pero todavía impropios, en este estado, para consumo inmediato"
 },
 {
  "CNKEY": "081210000080",
  "CN": "0812 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cerezas"
 },
 {
  "CNKEY": "081290000080",
  "CN": "0812 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "081290250080",
  "CN": "0812 90 25",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Albaricoques (damascos, chabacanos); naranjas"
 },
 {
  "CNKEY": "081290300080",
  "CN": "0812 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papayas"
 },
 {
  "CNKEY": "081290400080",
  "CN": "0812 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arándanos, mirtilos (frutos del Vaccinium myrtillus)"
 },
 {
  "CNKEY": "081290700080",
  "CN": "0812 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guayabas, mangos, mangostanes, tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), litchis, frutos del árbol del pan, sapotillos, frutos de la pasión, carambolas, pitahayas y nueces tropicales"
 },
 {
  "CNKEY": "081290980080",
  "CN": "0812 90 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081300000080",
  "CN": "0813",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Frutas y otros frutos, secos, excepto los de las partidas 0801 a 0806; mezclas de frutas u otros frutos, secos, o de frutos de cáscara de este Capítulo"
 },
 {
  "CNKEY": "081310000080",
  "CN": "0813 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Albaricoques (damascos, chabacanos)"
 },
 {
  "CNKEY": "081320000080",
  "CN": "0813 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ciruelas"
 },
 {
  "CNKEY": "081330000080",
  "CN": "0813 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Manzanas"
 },
 {
  "CNKEY": "081340000080",
  "CN": "0813 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás frutas u otros frutos"
 },
 {
  "CNKEY": "081340100080",
  "CN": "0813 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Melocotones, incluidos los griñones y nectarinas"
 },
 {
  "CNKEY": "081340300080",
  "CN": "0813 40 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peras"
 },
 {
  "CNKEY": "081340500080",
  "CN": "0813 40 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papayas"
 },
 {
  "CNKEY": "081340650080",
  "CN": "0813 40 65",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), frutos del árbol del pan, litchis, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "CNKEY": "081340950080",
  "CN": "0813 40 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081350000080",
  "CN": "0813 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mezclas de frutas u otros frutos, secos, o de frutos de cáscara de este Capítulo"
 },
 {
  "CNKEY": "081350120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Macedonias de frutos secos (excepto los de las partidas 0801 a 0806)"
 },
 {
  "CNKEY": "081350120020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin ciruelas pasas"
 },
 {
  "CNKEY": "081350120080",
  "CN": "0813 50 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De papayas, tamarindos, peras de marañón (merey, cajuil, anacardo, cajú), litchis, frutos del árbol del pan, sapotillos, frutos de la pasión, carambolas y pitahayas"
 },
 {
  "CNKEY": "081350150080",
  "CN": "0813 50 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081350190080",
  "CN": "0813 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con ciruelas pasas"
 },
 {
  "CNKEY": "081350310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezclas constituidas exclusivamente por frutos de cáscara de las partidas 0801 y 0802"
 },
 {
  "CNKEY": "081350310080",
  "CN": "0813 50 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De nueces tropicales"
 },
 {
  "CNKEY": "081350390080",
  "CN": "0813 50 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "081350910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás mezclas"
 },
 {
  "CNKEY": "081350910080",
  "CN": "0813 50 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin ciruelas pasas ni higos"
 },
 {
  "CNKEY": "081350990080",
  "CN": "0813 50 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "081400000080",
  "CN": "0814 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cortezas de agrios (cítricos), melones o sandías, frescas, congeladas, secas o presentadas en agua salada, sulfurosa o adicionada de otras sustancias para su conservación provisional"
 },
 {
  "CNKEY": "090021000090",
  "CN": "09",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 9 - CAFÉ, TÉ, YERBA MATE Y ESPECIAS"
 },
 {
  "CNKEY": "090100000080",
  "CN": "0901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Café, incluso tostado o descafeinado; cáscara y cascarilla de café; sucedáneos del café que contengan café en cualquier proporción"
 },
 {
  "CNKEY": "090111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Café sin tostar"
 },
 {
  "CNKEY": "090111000080",
  "CN": "0901 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin descafeinar"
 },
 {
  "CNKEY": "090112000080",
  "CN": "0901 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Descafeinado"
 },
 {
  "CNKEY": "090121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Café tostado"
 },
 {
  "CNKEY": "090121000080",
  "CN": "0901 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin descafeinar"
 },
 {
  "CNKEY": "090122000080",
  "CN": "0901 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Descafeinado"
 },
 {
  "CNKEY": "090190000080",
  "CN": "0901 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "090190100080",
  "CN": "0901 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cáscara y cascarilla de café"
 },
 {
  "CNKEY": "090190900080",
  "CN": "0901 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sucedáneos del café que contengan café"
 },
 {
  "CNKEY": "090200000080",
  "CN": "0902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Té, incluso aromatizado"
 },
 {
  "CNKEY": "090210000080",
  "CN": "0902 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Té verde (sin fermentar) presentado en envases inmediatos con un contenido inferior o igual a 3 kg"
 },
 {
  "CNKEY": "090220000080",
  "CN": "0902 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Té verde (sin fermentar) presentado de otra forma"
 },
 {
  "CNKEY": "090230000080",
  "CN": "0902 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Té negro (fermentado) y té parcialmente fermentado, presentados en envases inmediatos con un contenido inferior o igual a 3 kg"
 },
 {
  "CNKEY": "090240000080",
  "CN": "0902 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Té negro (fermentado) y té parcialmente fermentado, presentados de otra forma"
 },
 {
  "CNKEY": "090300000080",
  "CN": "0903 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Yerba mate"
 },
 {
  "CNKEY": "090400000080",
  "CN": "0904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pimienta del género Piper; frutos de los géneros Capsicum o Pimenta, secos, triturados o pulverizados"
 },
 {
  "CNKEY": "090411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pimienta"
 },
 {
  "CNKEY": "090411000080",
  "CN": "0904 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090412000080",
  "CN": "0904 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturada o pulverizada"
 },
 {
  "CNKEY": "090421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frutos de los géneros Capsicum o Pimenta"
 },
 {
  "CNKEY": "090421000080",
  "CN": "0904 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Secos, sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090421100080",
  "CN": "0904 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pimientos dulces (Capsicum annuum)"
 },
 {
  "CNKEY": "090421900080",
  "CN": "0904 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "090422000080",
  "CN": "0904 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturados o pulverizados"
 },
 {
  "CNKEY": "090500000080",
  "CN": "0905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vainilla"
 },
 {
  "CNKEY": "090510000080",
  "CN": "0905 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090520000080",
  "CN": "0905 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triturada o pulverizada"
 },
 {
  "CNKEY": "090600000080",
  "CN": "0906",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Canela y flores de canelero"
 },
 {
  "CNKEY": "090611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090611000080",
  "CN": "0906 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Canela (Cinnamomum zeylanicum Blume)"
 },
 {
  "CNKEY": "090619000080",
  "CN": "0906 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "090620000080",
  "CN": "0906 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triturada o pulverizada"
 },
 {
  "CNKEY": "090700000080",
  "CN": "0907",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Clavos (frutos enteros, clavillos y pedúnculos)"
 },
 {
  "CNKEY": "090710000080",
  "CN": "0907 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090720000080",
  "CN": "0907 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triturados o pulverizados"
 },
 {
  "CNKEY": "090800000080",
  "CN": "0908",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Nuez moscada, macis, amomos y cardamomos"
 },
 {
  "CNKEY": "090811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nuez moscada"
 },
 {
  "CNKEY": "090811000080",
  "CN": "0908 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090812000080",
  "CN": "0908 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturada o pulverizada"
 },
 {
  "CNKEY": "090821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Macis"
 },
 {
  "CNKEY": "090821000080",
  "CN": "0908 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090822000080",
  "CN": "0908 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturado o pulverizado"
 },
 {
  "CNKEY": "090831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amomos y cardamomos"
 },
 {
  "CNKEY": "090831000080",
  "CN": "0908 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090832000080",
  "CN": "0908 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturados o pulverizados"
 },
 {
  "CNKEY": "090900000080",
  "CN": "0909",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Semillas de anís, badiana, hinojo, cilantro, comino o alcaravea; bayas de enebro"
 },
 {
  "CNKEY": "090921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de cilantro"
 },
 {
  "CNKEY": "090921000080",
  "CN": "0909 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090922000080",
  "CN": "0909 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trituradas o pulverizadas"
 },
 {
  "CNKEY": "090931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de comino"
 },
 {
  "CNKEY": "090931000080",
  "CN": "0909 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090932000080",
  "CN": "0909 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trituradas o pulverizadas"
 },
 {
  "CNKEY": "090961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de anís, badiana, alcaravea o hinojo; bayas de enebro"
 },
 {
  "CNKEY": "090961000080",
  "CN": "0909 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "090962000080",
  "CN": "0909 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trituradas o pulverizadas"
 },
 {
  "CNKEY": "091000000080",
  "CN": "0910",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Jengibre, azafrán, cúrcuma, tomillo, hojas de laurel, curri y demás especias"
 },
 {
  "CNKEY": "091011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jengibre"
 },
 {
  "CNKEY": "091011000080",
  "CN": "0910 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "091012000080",
  "CN": "0910 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturado o pulverizado"
 },
 {
  "CNKEY": "091020000080",
  "CN": "0910 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Azafrán"
 },
 {
  "CNKEY": "091020100080",
  "CN": "0910 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "091020900080",
  "CN": "0910 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triturado o pulverizado"
 },
 {
  "CNKEY": "091030000080",
  "CN": "0910 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cúrcuma"
 },
 {
  "CNKEY": "091091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás especias"
 },
 {
  "CNKEY": "091091000080",
  "CN": "0910 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezclas previstas en la Nota 1 b) de este Capítulo"
 },
 {
  "CNKEY": "091091050080",
  "CN": "0910 91 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Curri"
 },
 {
  "CNKEY": "091091100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "091091100080",
  "CN": "0910 91 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "091091900080",
  "CN": "0910 91 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Trituradas o pulverizadas"
 },
 {
  "CNKEY": "091099000080",
  "CN": "0910 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "091099100080",
  "CN": "0910 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas de alholva"
 },
 {
  "CNKEY": "091099310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Tomillo"
 },
 {
  "CNKEY": "091099310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "091099310080",
  "CN": "0910 99 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Serpol (Thymus serpyllum L.)"
 },
 {
  "CNKEY": "091099330080",
  "CN": "0910 99 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "091099390080",
  "CN": "0910 99 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Triturado o pulverizado"
 },
 {
  "CNKEY": "091099500080",
  "CN": "0910 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hojas de laurel"
 },
 {
  "CNKEY": "091099910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "091099910080",
  "CN": "0910 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "091099990080",
  "CN": "0910 99 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Trituradas o pulverizadas"
 },
 {
  "CNKEY": "100021000090",
  "CN": "10",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 10 - CEREALES"
 },
 {
  "CNKEY": "100100000080",
  "CN": "1001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trigo y morcajo (tranquillón)"
 },
 {
  "CNKEY": "100111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trigo duro"
 },
 {
  "CNKEY": "100111000080",
  "CN": "1001 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100119000080",
  "CN": "1001 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "100191000080",
  "CN": "1001 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100191100080",
  "CN": "1001 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Escanda"
 },
 {
  "CNKEY": "100191200080",
  "CN": "1001 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trigo blando y morcajo (tranquillón)"
 },
 {
  "CNKEY": "100191900080",
  "CN": "1001 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100199000080",
  "CN": "1001 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100200000080",
  "CN": "1002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Centeno"
 },
 {
  "CNKEY": "100210000080",
  "CN": "1002 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100290000080",
  "CN": "1002 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100300000080",
  "CN": "1003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cebada"
 },
 {
  "CNKEY": "100310000080",
  "CN": "1003 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100390000080",
  "CN": "1003 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "100400000080",
  "CN": "1004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Avena"
 },
 {
  "CNKEY": "100410000080",
  "CN": "1004 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100490000080",
  "CN": "1004 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100500000080",
  "CN": "1005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Maíz"
 },
 {
  "CNKEY": "100510000080",
  "CN": "1005 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100510130010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Híbrido"
 },
 {
  "CNKEY": "100510130080",
  "CN": "1005 10 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Híbrido triple"
 },
 {
  "CNKEY": "100510150080",
  "CN": "1005 10 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Híbrido simple"
 },
 {
  "CNKEY": "100510180080",
  "CN": "1005 10 18",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100510900080",
  "CN": "1005 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100590000080",
  "CN": "1005 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100600000080",
  "CN": "1006",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Arroz"
 },
 {
  "CNKEY": "100610000080",
  "CN": "1006 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arroz con cáscara (arroz paddy)"
 },
 {
  "CNKEY": "100610100080",
  "CN": "1006 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100610300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "100610300080",
  "CN": "1006 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100610500080",
  "CN": "1006 10 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100610710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100610710080",
  "CN": "1006 10 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100610790080",
  "CN": "1006 10 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100620000080",
  "CN": "1006 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arroz descascarillado (arroz cargo o arroz pardo)"
 },
 {
  "CNKEY": "100620110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Escaldado (parboiled)"
 },
 {
  "CNKEY": "100620110080",
  "CN": "1006 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100620130080",
  "CN": "1006 20 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100620150010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100620150080",
  "CN": "1006 20 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100620170080",
  "CN": "1006 20 17",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100620920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "100620920080",
  "CN": "1006 20 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100620940080",
  "CN": "1006 20 94",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100620960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100620960080",
  "CN": "1006 20 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100620980080",
  "CN": "1006 20 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100630000080",
  "CN": "1006 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Arroz semiblanqueado o blanqueado, incluso pulido o glaseado"
 },
 {
  "CNKEY": "100630210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arroz semiblanqueado"
 },
 {
  "CNKEY": "100630210020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Escaldado (parboiled)"
 },
 {
  "CNKEY": "100630210080",
  "CN": "1006 30 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100630230080",
  "CN": "1006 30 23",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100630250010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100630250080",
  "CN": "1006 30 25",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100630270080",
  "CN": "1006 30 27",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100630420010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "100630420080",
  "CN": "1006 30 42",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100630440080",
  "CN": "1006 30 44",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100630460010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100630460080",
  "CN": "1006 30 46",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100630480080",
  "CN": "1006 30 48",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100630610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arroz blanqueado"
 },
 {
  "CNKEY": "100630610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Escaldado (parboiled)"
 },
 {
  "CNKEY": "100630610080",
  "CN": "1006 30 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100630630080",
  "CN": "1006 30 63",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100630650010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100630650080",
  "CN": "1006 30 65",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100630670080",
  "CN": "1006 30 67",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100630920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "100630920080",
  "CN": "1006 30 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano redondo"
 },
 {
  "CNKEY": "100630940080",
  "CN": "1006 30 94",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grano medio"
 },
 {
  "CNKEY": "100630960010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De grano largo"
 },
 {
  "CNKEY": "100630960080",
  "CN": "1006 30 96",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior a 2 pero inferior a 3"
 },
 {
  "CNKEY": "100630980080",
  "CN": "1006 30 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que presente una relación longitud\/anchura superior o igual a 3"
 },
 {
  "CNKEY": "100640000080",
  "CN": "1006 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Arroz partido"
 },
 {
  "CNKEY": "100700000080",
  "CN": "1007",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sorgo de grano (granífero)"
 },
 {
  "CNKEY": "100710000080",
  "CN": "1007 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100710100080",
  "CN": "1007 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Híbrido, para siembra"
 },
 {
  "CNKEY": "100710900080",
  "CN": "1007 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100790000080",
  "CN": "1007 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100800000080",
  "CN": "1008",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alforfón, mijo y alpiste; los demás cereales"
 },
 {
  "CNKEY": "100810000080",
  "CN": "1008 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alforfón"
 },
 {
  "CNKEY": "100821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mijo"
 },
 {
  "CNKEY": "100821000080",
  "CN": "1008 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "100829000080",
  "CN": "1008 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "100830000080",
  "CN": "1008 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alpiste"
 },
 {
  "CNKEY": "100840000080",
  "CN": "1008 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fonio (Digitaria spp.)"
 },
 {
  "CNKEY": "100850000080",
  "CN": "1008 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Quinua (quinoa) (Chenopodium quinoa)"
 },
 {
  "CNKEY": "100860000080",
  "CN": "1008 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triticale"
 },
 {
  "CNKEY": "100890000080",
  "CN": "1008 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás cereales"
 },
 {
  "CNKEY": "110021000090",
  "CN": "11",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 11 - PRODUCTOS DE LA MOLINERÍA; MALTA; ALMIDÓN Y FÉCULA; INULINA; GLUTEN DE TRIGO"
 },
 {
  "CNKEY": "110100000080",
  "CN": "1101 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina de trigo o de morcajo (tranquillón)"
 },
 {
  "CNKEY": "110100110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De trigo"
 },
 {
  "CNKEY": "110100110080",
  "CN": "1101 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De trigo duro"
 },
 {
  "CNKEY": "110100150080",
  "CN": "1101 00 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De trigo blando y de escanda"
 },
 {
  "CNKEY": "110100900080",
  "CN": "1101 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De morcajo (tranquillón)"
 },
 {
  "CNKEY": "110200000080",
  "CN": "1102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina de cereales, excepto de trigo o de morcajo (tranquillón)"
 },
 {
  "CNKEY": "110220000080",
  "CN": "1102 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Harina de maíz"
 },
 {
  "CNKEY": "110220100080",
  "CN": "1102 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "110220900080",
  "CN": "1102 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "110290000080",
  "CN": "1102 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "110290100080",
  "CN": "1102 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cebada"
 },
 {
  "CNKEY": "110290300080",
  "CN": "1102 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De avena"
 },
 {
  "CNKEY": "110290500080",
  "CN": "1102 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De arroz"
 },
 {
  "CNKEY": "110290700080",
  "CN": "1102 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De centeno"
 },
 {
  "CNKEY": "110290900080",
  "CN": "1102 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "110300000080",
  "CN": "1103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grañones, sémola y pellets, de cereales"
 },
 {
  "CNKEY": "110311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grañones y sémola"
 },
 {
  "CNKEY": "110311000080",
  "CN": "1103 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De trigo"
 },
 {
  "CNKEY": "110311100080",
  "CN": "1103 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De trigo duro"
 },
 {
  "CNKEY": "110311900080",
  "CN": "1103 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De trigo blando y de escanda"
 },
 {
  "CNKEY": "110313000080",
  "CN": "1103 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De maíz"
 },
 {
  "CNKEY": "110313100080",
  "CN": "1103 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas inferior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "110313900080",
  "CN": "1103 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110319000080",
  "CN": "1103 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás cereales"
 },
 {
  "CNKEY": "110319200080",
  "CN": "1103 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De centeno o cebada"
 },
 {
  "CNKEY": "110319400080",
  "CN": "1103 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De avena"
 },
 {
  "CNKEY": "110319500080",
  "CN": "1103 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De arroz"
 },
 {
  "CNKEY": "110319900080",
  "CN": "1103 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110320000080",
  "CN": "1103 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pellets"
 },
 {
  "CNKEY": "110320250080",
  "CN": "1103 20 25",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De centeno o cebada"
 },
 {
  "CNKEY": "110320300080",
  "CN": "1103 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De avena"
 },
 {
  "CNKEY": "110320400080",
  "CN": "1103 20 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De maíz"
 },
 {
  "CNKEY": "110320500080",
  "CN": "1103 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De arroz"
 },
 {
  "CNKEY": "110320600080",
  "CN": "1103 20 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De trigo"
 },
 {
  "CNKEY": "110320900080",
  "CN": "1103 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110400000080",
  "CN": "1104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Granos de cereales trabajados de otro modo (por ejemplo: mondados, aplastados, en copos, perlados, troceados o quebrantados), excepto del arroz de la partida 1006; germen de cereales entero, aplastado, en copos o molido"
 },
 {
  "CNKEY": "110412000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Granos aplastados o en copos"
 },
 {
  "CNKEY": "110412000080",
  "CN": "1104 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De avena"
 },
 {
  "CNKEY": "110412100080",
  "CN": "1104 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Granos aplastados"
 },
 {
  "CNKEY": "110412900080",
  "CN": "1104 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copos"
 },
 {
  "CNKEY": "110419000080",
  "CN": "1104 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás cereales"
 },
 {
  "CNKEY": "110419100080",
  "CN": "1104 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De trigo"
 },
 {
  "CNKEY": "110419300080",
  "CN": "1104 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De centeno"
 },
 {
  "CNKEY": "110419500080",
  "CN": "1104 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De maíz"
 },
 {
  "CNKEY": "110419610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De cebada"
 },
 {
  "CNKEY": "110419610080",
  "CN": "1104 19 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Granos aplastados"
 },
 {
  "CNKEY": "110419690080",
  "CN": "1104 19 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Copos"
 },
 {
  "CNKEY": "110419910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "110419910080",
  "CN": "1104 19 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Copos de arroz"
 },
 {
  "CNKEY": "110419990080",
  "CN": "1104 19 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110422000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás granos trabajados (por ejemplo: mondados, perlados, troceados o quebrantados)"
 },
 {
  "CNKEY": "110422000080",
  "CN": "1104 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De avena"
 },
 {
  "CNKEY": "110422400080",
  "CN": "1104 22 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "CNKEY": "110422500080",
  "CN": "1104 22 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perlados"
 },
 {
  "CNKEY": "110422950080",
  "CN": "1104 22 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110423000080",
  "CN": "1104 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De maíz"
 },
 {
  "CNKEY": "110423400080",
  "CN": "1104 23 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mondados (descascarillados o pelados), incluso troceados o quebrantados; perlados"
 },
 {
  "CNKEY": "110423980080",
  "CN": "1104 23 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110429000080",
  "CN": "1104 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás cereales"
 },
 {
  "CNKEY": "110429040010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De cebada"
 },
 {
  "CNKEY": "110429040080",
  "CN": "1104 29 04",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "CNKEY": "110429050080",
  "CN": "1104 29 05",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Perlados"
 },
 {
  "CNKEY": "110429080080",
  "CN": "1104 29 08",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110429170010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "110429170080",
  "CN": "1104 29 17",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mondados (descascarillados o pelados), incluso troceados o quebrantados"
 },
 {
  "CNKEY": "110429300080",
  "CN": "1104 29 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Perlados"
 },
 {
  "CNKEY": "110429510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Solamente quebrantados"
 },
 {
  "CNKEY": "110429510080",
  "CN": "1104 29 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De trigo"
 },
 {
  "CNKEY": "110429550080",
  "CN": "1104 29 55",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De centeno"
 },
 {
  "CNKEY": "110429590080",
  "CN": "1104 29 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110429810010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "110429810080",
  "CN": "1104 29 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De trigo"
 },
 {
  "CNKEY": "110429850080",
  "CN": "1104 29 85",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De centeno"
 },
 {
  "CNKEY": "110429890080",
  "CN": "1104 29 89",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110430000080",
  "CN": "1104 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Germen de cereales entero, aplastado, en copos o molido"
 },
 {
  "CNKEY": "110430100080",
  "CN": "1104 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De trigo"
 },
 {
  "CNKEY": "110430900080",
  "CN": "1104 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110500000080",
  "CN": "1105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina, sémola, polvo, copos, gránulos y pellets, de patata (papa)"
 },
 {
  "CNKEY": "110510000080",
  "CN": "1105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Harina, sémola y polvo"
 },
 {
  "CNKEY": "110520000080",
  "CN": "1105 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copos, gránulos y pellets"
 },
 {
  "CNKEY": "110600000080",
  "CN": "1106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina, sémola y polvo de las hortalizas de la partida 0713, de sagú o de las raíces o tubérculos de la partida 0714 o de los productos del Capítulo 8"
 },
 {
  "CNKEY": "110610000080",
  "CN": "1106 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las hortalizas de la partida 0713"
 },
 {
  "CNKEY": "110620000080",
  "CN": "1106 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De sagú o de las raíces o tubérculos de la partida 0714"
 },
 {
  "CNKEY": "110620100080",
  "CN": "1106 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desnaturalizada"
 },
 {
  "CNKEY": "110620900080",
  "CN": "1106 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "110630000080",
  "CN": "1106 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los productos del Capítulo 8"
 },
 {
  "CNKEY": "110630100080",
  "CN": "1106 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plátanos"
 },
 {
  "CNKEY": "110630900080",
  "CN": "1106 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110700000080",
  "CN": "1107",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Malta (de cebada u otros cereales), incluso tostada"
 },
 {
  "CNKEY": "110710000080",
  "CN": "1107 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin tostar"
 },
 {
  "CNKEY": "110710110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De trigo"
 },
 {
  "CNKEY": "110710110080",
  "CN": "1107 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Harina"
 },
 {
  "CNKEY": "110710190080",
  "CN": "1107 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "110710910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "110710910080",
  "CN": "1107 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Harina"
 },
 {
  "CNKEY": "110710990080",
  "CN": "1107 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "110720000080",
  "CN": "1107 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tostada"
 },
 {
  "CNKEY": "110800000080",
  "CN": "1108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Almidón y fécula; inulina"
 },
 {
  "CNKEY": "110811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Almidón y fécula"
 },
 {
  "CNKEY": "110811000080",
  "CN": "1108 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Almidón de trigo"
 },
 {
  "CNKEY": "110812000080",
  "CN": "1108 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Almidón de maíz"
 },
 {
  "CNKEY": "110813000080",
  "CN": "1108 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fécula de patata (papa)"
 },
 {
  "CNKEY": "110814000080",
  "CN": "1108 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fécula de mandioca (yuca)"
 },
 {
  "CNKEY": "110819000080",
  "CN": "1108 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás almidones y féculas"
 },
 {
  "CNKEY": "110819100080",
  "CN": "1108 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Almidón de arroz"
 },
 {
  "CNKEY": "110819900080",
  "CN": "1108 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "110820000080",
  "CN": "1108 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Inulina"
 },
 {
  "CNKEY": "110900000080",
  "CN": "1109 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Gluten de trigo, incluso seco"
 },
 {
  "CNKEY": "120021000090",
  "CN": "12",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 12 - SEMILLAS Y FRUTOS OLEAGINOSOS; SEMILLAS Y FRUTOS DIVERSOS; PLANTAS INDUSTRIALES O MEDICINALES; PAJA Y FORRAJE"
 },
 {
  "CNKEY": "120100000080",
  "CN": "1201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Habas (porotos, frijoles, fréjoles) de soja (soya), incluso quebrantadas"
 },
 {
  "CNKEY": "120110000080",
  "CN": "1201 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120190000080",
  "CN": "1201 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120200000080",
  "CN": "1202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cacahuates (cacahuetes, maníes) sin tostar ni cocer de otro modo, incluso sin cáscara o quebrantados"
 },
 {
  "CNKEY": "120230000080",
  "CN": "1202 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120241000080",
  "CN": "1202 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cáscara"
 },
 {
  "CNKEY": "120242000080",
  "CN": "1202 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara, incluso quebrantados"
 },
 {
  "CNKEY": "120300000080",
  "CN": "1203 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Copra"
 },
 {
  "CNKEY": "120400000080",
  "CN": "1204 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Semilla de lino, incluso quebrantada"
 },
 {
  "CNKEY": "120400100080",
  "CN": "1204 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120400900080",
  "CN": "1204 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120500000080",
  "CN": "1205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Semillas de nabo (nabina) o de colza, incluso quebrantadas"
 },
 {
  "CNKEY": "120510000080",
  "CN": "1205 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de nabo (nabina) o de colza con bajo contenido de ácido erúcico"
 },
 {
  "CNKEY": "120510100080",
  "CN": "1205 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120510900080",
  "CN": "1205 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120590000080",
  "CN": "1205 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120600000080",
  "CN": "1206 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Semilla de girasol, incluso quebrantada"
 },
 {
  "CNKEY": "120600100080",
  "CN": "1206 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120600910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "120600910080",
  "CN": "1206 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cáscara; con cáscara estriada gris y blanca"
 },
 {
  "CNKEY": "120600990080",
  "CN": "1206 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120700000080",
  "CN": "1207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás semillas y frutos oleaginosos, incluso quebrantados"
 },
 {
  "CNKEY": "120710000080",
  "CN": "1207 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Nueces y almendras de palma"
 },
 {
  "CNKEY": "120721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de algodón"
 },
 {
  "CNKEY": "120721000080",
  "CN": "1207 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120729000080",
  "CN": "1207 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120730000080",
  "CN": "1207 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Semillas de ricino"
 },
 {
  "CNKEY": "120740000080",
  "CN": "1207 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de sésamo (ajonjolí)"
 },
 {
  "CNKEY": "120740100080",
  "CN": "1207 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120740900080",
  "CN": "1207 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120750000080",
  "CN": "1207 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas de mostaza"
 },
 {
  "CNKEY": "120750100080",
  "CN": "1207 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120750900080",
  "CN": "1207 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120760000080",
  "CN": "1207 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Semillas de cártamo (Carthamus tinctorius)"
 },
 {
  "CNKEY": "120770000080",
  "CN": "1207 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Semillas de melón"
 },
 {
  "CNKEY": "120791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120791000080",
  "CN": "1207 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Semilla de amapola (adormidera)"
 },
 {
  "CNKEY": "120791100080",
  "CN": "1207 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120791900080",
  "CN": "1207 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120799000080",
  "CN": "1207 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120799200080",
  "CN": "1207 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para siembra"
 },
 {
  "CNKEY": "120799910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120799910080",
  "CN": "1207 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Semilla de cáñamo"
 },
 {
  "CNKEY": "120799960080",
  "CN": "1207 99 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "120800000080",
  "CN": "1208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina de semillas o de frutos oleaginosos, excepto la harina de mostaza"
 },
 {
  "CNKEY": "120810000080",
  "CN": "1208 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De habas (porotos, frijoles, fréjoles) de soja (soya)"
 },
 {
  "CNKEY": "120890000080",
  "CN": "1208 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120900000080",
  "CN": "1209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Semillas, frutos y esporas, para siembra"
 },
 {
  "CNKEY": "120910000080",
  "CN": "1209 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Semilla de remolacha azucarera"
 },
 {
  "CNKEY": "120921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semillas forrajeras"
 },
 {
  "CNKEY": "120921000080",
  "CN": "1209 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De alfalfa"
 },
 {
  "CNKEY": "120922000080",
  "CN": "1209 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De trébol (Trifolium spp.)"
 },
 {
  "CNKEY": "120922100080",
  "CN": "1209 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trébol violeta o rojo (Trifolium pratense L.)"
 },
 {
  "CNKEY": "120922800080",
  "CN": "1209 22 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "120923000080",
  "CN": "1209 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De festucas"
 },
 {
  "CNKEY": "120923110080",
  "CN": "1209 23 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Festuca de los prados (Festuca pratensis Huds.)"
 },
 {
  "CNKEY": "120923150080",
  "CN": "1209 23 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Festuca roja (Festuca rubra L.)"
 },
 {
  "CNKEY": "120923800080",
  "CN": "1209 23 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120924000080",
  "CN": "1209 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De pasto azul de Kentucky (Poa pratensis L.)"
 },
 {
  "CNKEY": "120925000080",
  "CN": "1209 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ballico (Lolium multiflorum Lam., Lolium perenne L.)"
 },
 {
  "CNKEY": "120925100080",
  "CN": "1209 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ray-grass de Italia (Lolium multiflorum Lam.)"
 },
 {
  "CNKEY": "120925900080",
  "CN": "1209 25 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ray-grass inglés (Lolium perenne L.)"
 },
 {
  "CNKEY": "120929000080",
  "CN": "1209 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "120929450080",
  "CN": "1209 29 45",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas de fleo de los prados (Phleum pratensis); vezas; semillas de la especie Poa palustris L. y Poa trivialis L.; dactilo (Dactylis glomerata L.); agrostis (Agrostides)"
 },
 {
  "CNKEY": "120929500080",
  "CN": "1209 29 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas de altramuz"
 },
 {
  "CNKEY": "120929600080",
  "CN": "1209 29 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas de remolacha forrajera (Beta vulgaris var. alba)"
 },
 {
  "CNKEY": "120929800080",
  "CN": "1209 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120930000080",
  "CN": "1209 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Semillas de plantas herbáceas utilizadas principalmente por sus flores"
 },
 {
  "CNKEY": "120991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120991000080",
  "CN": "1209 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Semillas de hortalizas"
 },
 {
  "CNKEY": "120991300080",
  "CN": "1209 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas de remolacha de ensalada o de mesa (Beta vulgaris var. conditiva)"
 },
 {
  "CNKEY": "120991800080",
  "CN": "1209 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "120999000080",
  "CN": "1209 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "120999100080",
  "CN": "1209 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Semillas forestales"
 },
 {
  "CNKEY": "120999910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "120999910080",
  "CN": "1209 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Semillas de plantas utilizadas principalmente por sus flores (excepto las de la subpartida 120930)"
 },
 {
  "CNKEY": "120999990080",
  "CN": "1209 99 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "121000000080",
  "CN": "1210",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Conos de lúpulo frescos o secos, incluso triturados, molidos o en pellets; lupulino"
 },
 {
  "CNKEY": "121010000080",
  "CN": "1210 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Conos de lúpulo sin triturar ni moler ni en pellets"
 },
 {
  "CNKEY": "121020000080",
  "CN": "1210 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conos de lúpulo triturados, molidos o en pellets; lupulino"
 },
 {
  "CNKEY": "121020100080",
  "CN": "1210 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conos de lúpulo triturados, molidos o en pellets, enriquecidos con lupulino; lupulino"
 },
 {
  "CNKEY": "121020900080",
  "CN": "1210 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "121100000080",
  "CN": "1211",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Plantas, partes de plantas, semillas y frutos de las especies utilizadas principalmente en perfumería, medicina o para usos insecticidas, parasiticidas o similares, frescos, refrigerados, congelados o secos, incluso cortados, quebrantados o pulverizados"
 },
 {
  "CNKEY": "121120000080",
  "CN": "1211 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Raíces de ginseng"
 },
 {
  "CNKEY": "121130000080",
  "CN": "1211 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hojas de coca"
 },
 {
  "CNKEY": "121140000080",
  "CN": "1211 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Paja de adormidera"
 },
 {
  "CNKEY": "121150000080",
  "CN": "1211 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Efedra"
 },
 {
  "CNKEY": "121160000080",
  "CN": "1211 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Corteza de cerezo africano (Prunus africana)"
 },
 {
  "CNKEY": "121190000080",
  "CN": "1211 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "121190300080",
  "CN": "1211 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Habas de sarapia"
 },
 {
  "CNKEY": "121190860080",
  "CN": "1211 90 86",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "121200000080",
  "CN": "1212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Algarrobas, algas, remolacha azucarera y caña de azúcar, frescas, refrigeradas, congeladas o secas, incluso pulverizadas; huesos (carozos) y almendras de frutos y demás productos vegetales (incluidas las raíces de achicoria sin tostar de la variedad Cichorium intybus sativum) empleados principalmente en la alimentación humana, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "121221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Algas"
 },
 {
  "CNKEY": "121221000080",
  "CN": "1212 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aptas para la alimentación humana"
 },
 {
  "CNKEY": "121229000080",
  "CN": "1212 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "121291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "121291000080",
  "CN": "1212 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Remolacha azucarera"
 },
 {
  "CNKEY": "121291200080",
  "CN": "1212 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Seca, incluso pulverizada"
 },
 {
  "CNKEY": "121291800080",
  "CN": "1212 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "121292000080",
  "CN": "1212 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Algarrobas"
 },
 {
  "CNKEY": "121293000080",
  "CN": "1212 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caña de azúcar"
 },
 {
  "CNKEY": "121294000080",
  "CN": "1212 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Raíces de achicoria"
 },
 {
  "CNKEY": "121299000080",
  "CN": "1212 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "121299410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Semillas de algarrobas (garrofín)"
 },
 {
  "CNKEY": "121299410080",
  "CN": "1212 99 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin mondar, quebrantar ni moler"
 },
 {
  "CNKEY": "121299490080",
  "CN": "1212 99 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "121299950080",
  "CN": "1212 99 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "121300000080",
  "CN": "1213 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Paja y cascabillo de cereales, en bruto, incluso picados, molidos, prensados o en «pellets»"
 },
 {
  "CNKEY": "121400000080",
  "CN": "1214",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Nabos forrajeros, remolachas forrajeras, raíces forrajeras, heno, alfalfa, trébol, esparceta, coles forrajeras, altramuces, vezas y productos forrajeros similares, incluso en «pellets»"
 },
 {
  "CNKEY": "121410000080",
  "CN": "1214 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Harina y «pellets» de alfalfa"
 },
 {
  "CNKEY": "121490000080",
  "CN": "1214 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "121490100080",
  "CN": "1214 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Remolachas, nabos y demás raíces forrajeras"
 },
 {
  "CNKEY": "121490900080",
  "CN": "1214 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "130021000090",
  "CN": "13",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 13 - GOMAS, RESINAS Y DEMÁS JUGOS Y EXTRACTOS VEGETALES"
 },
 {
  "CNKEY": "130100000080",
  "CN": "1301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Goma laca; gomas, resinas, gomorresinas y oleorresinas (por ejemplo: bálsamos), naturales"
 },
 {
  "CNKEY": "130120000080",
  "CN": "1301 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Goma arábiga"
 },
 {
  "CNKEY": "130190000080",
  "CN": "1301 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "130200000080",
  "CN": "1302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Jugos y extractos vegetales; materias pécticas, pectinatos y pectatos; agar-agar y demás mucílagos y espesativos derivados de los vegetales, incluso modificados"
 },
 {
  "CNKEY": "130211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugos y extractos vegetales"
 },
 {
  "CNKEY": "130211000080",
  "CN": "1302 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Opio"
 },
 {
  "CNKEY": "130212000080",
  "CN": "1302 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De regaliz"
 },
 {
  "CNKEY": "130213000080",
  "CN": "1302 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De lúpulo"
 },
 {
  "CNKEY": "130214000080",
  "CN": "1302 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De efedra"
 },
 {
  "CNKEY": "130219000080",
  "CN": "1302 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "130219050080",
  "CN": "1302 19 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Oleorresina de vainilla"
 },
 {
  "CNKEY": "130219700080",
  "CN": "1302 19 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "130220000080",
  "CN": "1302 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Materias pécticas, pectinatos y pectatos"
 },
 {
  "CNKEY": "130220100080",
  "CN": "1302 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secos"
 },
 {
  "CNKEY": "130220900080",
  "CN": "1302 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "130231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mucílagos y espesativos derivados de los vegetales, incluso modificados"
 },
 {
  "CNKEY": "130231000080",
  "CN": "1302 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agar-agar"
 },
 {
  "CNKEY": "130232000080",
  "CN": "1302 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mucílagos y espesativos de la algarroba o de su semilla o de las semillas de guar, incluso modificados"
 },
 {
  "CNKEY": "130232100080",
  "CN": "1302 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De algarroba o de la semilla (garrofín)"
 },
 {
  "CNKEY": "130232900080",
  "CN": "1302 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De semillas de guar"
 },
 {
  "CNKEY": "130239000080",
  "CN": "1302 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "140021000090",
  "CN": "14",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 14 - MATERIAS TRENZABLES Y DEMÁS PRODUCTOS DE ORIGEN VEGETAL, NO EXPRESADOS NI COMPRENDIDOS EN OTRA PARTE"
 },
 {
  "CNKEY": "140100000080",
  "CN": "1401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Materias vegetales de las especies utilizadas principalmente en cestería o espartería (por ejemplo: bambú, roten (ratán), caña, junco, mimbre, rafia, paja de cereales limpiada, blanqueada o teñida, corteza de tilo)"
 },
 {
  "CNKEY": "140110000080",
  "CN": "1401 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bambú"
 },
 {
  "CNKEY": "140120000080",
  "CN": "1401 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Roten (ratán)"
 },
 {
  "CNKEY": "140190000080",
  "CN": "1401 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "140400000080",
  "CN": "1404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos vegetales no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "140420000080",
  "CN": "1404 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Línteres de algodón"
 },
 {
  "CNKEY": "140490000080",
  "CN": "1404 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150011000090",
  "CN": "III",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN III - GRASAS Y ACEITES, ANIMALES, VEGETALES O DE ORIGEN MICROBIANO, Y PRODUCTOS DE SU DESDOBLAMIENTO; GRASAS ALIMENTICIAS ELABORADAS;  CERAS DE ORIGEN ANIMAL O VEGETAL"
 },
 {
  "CNKEY": "150021000090",
  "CN": "15",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 15 - GRASAS Y ACEITES, ANIMALES, VEGETALES O DE ORIGEN MICROBIANO, Y PRODUCTOS DE SU DESDOBLAMIENTO; GRASAS ALIMENTICIAS ELABORADAS; CERAS DE ORIGEN ANIMAL O VEGETAL"
 },
 {
  "CNKEY": "150100000080",
  "CN": "1501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasa de cerdo (incluida la manteca de cerdo) y grasa de ave, excepto las de las partidas 0209 o 1503"
 },
 {
  "CNKEY": "150110000080",
  "CN": "1501 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Manteca de cerdo"
 },
 {
  "CNKEY": "150110100080",
  "CN": "1501 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150110900080",
  "CN": "1501 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150120000080",
  "CN": "1501 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás grasas de cerdo"
 },
 {
  "CNKEY": "150120100080",
  "CN": "1501 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150120900080",
  "CN": "1501 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150190000080",
  "CN": "1501 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150200000080",
  "CN": "1502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasa de animales de las especies bovina, ovina o caprina, excepto las de la partida 1503"
 },
 {
  "CNKEY": "150210000080",
  "CN": "1502 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sebo"
 },
 {
  "CNKEY": "150210100080",
  "CN": "1502 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150210900080",
  "CN": "1502 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150290000080",
  "CN": "1502 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "150290100080",
  "CN": "1502 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150290900080",
  "CN": "1502 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150300000080",
  "CN": "1503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Estearina solar, aceite de manteca de cerdo, oleoestearina, oleomargarina y aceite de sebo, sin emulsionar, mezclar ni preparar de otro modo"
 },
 {
  "CNKEY": "150300110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estearina solar y oleoestearina"
 },
 {
  "CNKEY": "150300110080",
  "CN": "1503 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos industriales"
 },
 {
  "CNKEY": "150300190080",
  "CN": "1503 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150300300080",
  "CN": "1503 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceite de sebo que se destine a usos industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150300900080",
  "CN": "1503 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150400000080",
  "CN": "1504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, y sus fracciones, de pescado o de mamíferos marinos, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "150410000080",
  "CN": "1504 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de hígado de pescado y sus fracciones"
 },
 {
  "CNKEY": "150410100080",
  "CN": "1504 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de vitamina A inferior o igual a 2500 unidades internacionales por gramo"
 },
 {
  "CNKEY": "150410910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "150410910080",
  "CN": "1504 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De halibut (fletán)"
 },
 {
  "CNKEY": "150410990080",
  "CN": "1504 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150420000080",
  "CN": "1504 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites de pescado y sus fracciones, excepto los aceites de hígado"
 },
 {
  "CNKEY": "150420100080",
  "CN": "1504 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fracciones sólidas"
 },
 {
  "CNKEY": "150420900080",
  "CN": "1504 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150430000080",
  "CN": "1504 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites de mamíferos marinos y sus fracciones"
 },
 {
  "CNKEY": "150430100080",
  "CN": "1504 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fracciones sólidas"
 },
 {
  "CNKEY": "150430900080",
  "CN": "1504 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150500000080",
  "CN": "1505 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasa de lana y sustancias grasas derivadas, incluida la lanolina"
 },
 {
  "CNKEY": "150500100080",
  "CN": "1505 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Grasa de lana en bruto (suarda o suintina)"
 },
 {
  "CNKEY": "150500900080",
  "CN": "1505 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "150600000080",
  "CN": "1506 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Las demás grasas y aceites animales, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "150700000080",
  "CN": "1507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceite de soja (soya) y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "CNKEY": "150710000080",
  "CN": "1507 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite en bruto, incluso desgomado"
 },
 {
  "CNKEY": "150710100080",
  "CN": "1507 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150710900080",
  "CN": "1507 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150790000080",
  "CN": "1507 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "150790100080",
  "CN": "1507 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150790900080",
  "CN": "1507 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150800000080",
  "CN": "1508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceite de cacahuete (cacahuate, maní) y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "CNKEY": "150810000080",
  "CN": "1508 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "150810100080",
  "CN": "1508 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150810900080",
  "CN": "1508 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150890000080",
  "CN": "1508 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "150890100080",
  "CN": "1508 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "150890900080",
  "CN": "1508 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "150900000080",
  "CN": "1509",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceite de oliva y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "CNKEY": "150920000080",
  "CN": "1509 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceite de oliva virgen extra"
 },
 {
  "CNKEY": "150930000080",
  "CN": "1509 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceite de oliva virgen"
 },
 {
  "CNKEY": "150940000080",
  "CN": "1509 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aceites de oliva vírgenes"
 },
 {
  "CNKEY": "150990000080",
  "CN": "1509 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151000000080",
  "CN": "1510",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás aceites y sus fracciones obtenidos exclusivamente de aceituna, incluso refinados, pero sin modificar químicamente, y mezclas de estos aceites o fracciones con los aceites o fracciones de la partida 1509"
 },
 {
  "CNKEY": "151010000080",
  "CN": "1510 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceite de orujo de oliva en bruto"
 },
 {
  "CNKEY": "151090000080",
  "CN": "1510 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151100000080",
  "CN": "1511",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceite de palma y sus fracciones, incluso refinado, pero sin modificar químicamente"
 },
 {
  "CNKEY": "151110000080",
  "CN": "1511 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151110100080",
  "CN": "1511 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151110900080",
  "CN": "1511 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151190000080",
  "CN": "1511 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151190110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fracciones sólidas"
 },
 {
  "CNKEY": "151190110080",
  "CN": "1511 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151190190080",
  "CN": "1511 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151190910080",
  "CN": "1511 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151190990080",
  "CN": "1511 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151200000080",
  "CN": "1512",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites de girasol, cártamo o algodón, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "151211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de girasol o cártamo, y sus fracciones"
 },
 {
  "CNKEY": "151211000080",
  "CN": "1512 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceites en bruto"
 },
 {
  "CNKEY": "151211100080",
  "CN": "1512 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151211910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151211910080",
  "CN": "1512 11 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De girasol"
 },
 {
  "CNKEY": "151211990080",
  "CN": "1512 11 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De cártamo"
 },
 {
  "CNKEY": "151219000080",
  "CN": "1512 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151219100080",
  "CN": "1512 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151219900080",
  "CN": "1512 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de algodón y sus fracciones"
 },
 {
  "CNKEY": "151221000080",
  "CN": "1512 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite en bruto, incluso sin gosipol"
 },
 {
  "CNKEY": "151221100080",
  "CN": "1512 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151221900080",
  "CN": "1512 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151229000080",
  "CN": "1512 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151229100080",
  "CN": "1512 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151229900080",
  "CN": "1512 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151300000080",
  "CN": "1513",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites de coco (de copra), de almendra de palma (palmiste) o de babasú, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "151311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de coco (de copra) y sus fracciones"
 },
 {
  "CNKEY": "151311000080",
  "CN": "1513 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151311100080",
  "CN": "1513 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151311910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151311910080",
  "CN": "1513 11 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151311990080",
  "CN": "1513 11 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151319000080",
  "CN": "1513 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151319110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Fracciones sólidas"
 },
 {
  "CNKEY": "151319110080",
  "CN": "1513 19 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151319190080",
  "CN": "1513 19 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151319300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151319300080",
  "CN": "1513 19 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151319910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151319910080",
  "CN": "1513 19 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151319990080",
  "CN": "1513 19 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de almendra de palma (palmiste) o de babasú, y sus fracciones"
 },
 {
  "CNKEY": "151321000080",
  "CN": "1513 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceites en bruto"
 },
 {
  "CNKEY": "151321100080",
  "CN": "1513 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151321300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151321300080",
  "CN": "1513 21 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151321900080",
  "CN": "1513 21 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151329000080",
  "CN": "1513 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151329110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Fracciones sólidas"
 },
 {
  "CNKEY": "151329110080",
  "CN": "1513 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151329190080",
  "CN": "1513 29 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151329300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151329300080",
  "CN": "1513 29 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151329500010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151329500080",
  "CN": "1513 29 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151329900080",
  "CN": "1513 29 90",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151400000080",
  "CN": "1514",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites de nabo (de nabina), colza o mostaza, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "151411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de nabo (de nabina) o de colza con bajo contenido de ácido erúcico y sus fracciones"
 },
 {
  "CNKEY": "151411000080",
  "CN": "1514 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceites en bruto"
 },
 {
  "CNKEY": "151411100080",
  "CN": "1514 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151411900080",
  "CN": "1514 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151419000080",
  "CN": "1514 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151419100080",
  "CN": "1514 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151419900080",
  "CN": "1514 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151491000080",
  "CN": "1514 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceites en bruto"
 },
 {
  "CNKEY": "151491100080",
  "CN": "1514 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151491900080",
  "CN": "1514 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151499000080",
  "CN": "1514 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151499100080",
  "CN": "1514 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151499900080",
  "CN": "1514 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151500000080",
  "CN": "1515",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás grasas y aceites, vegetales (incluido el aceite de jojoba) o de origen microbiano, fijos, y sus fracciones, incluso refinados, pero sin modificar químicamente"
 },
 {
  "CNKEY": "151511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de lino (de linaza) y sus fracciones"
 },
 {
  "CNKEY": "151511000080",
  "CN": "1515 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151519000080",
  "CN": "1515 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151519100080",
  "CN": "1515 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151519900080",
  "CN": "1515 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de maíz y sus fracciones"
 },
 {
  "CNKEY": "151521000080",
  "CN": "1515 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151521100080",
  "CN": "1515 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151521900080",
  "CN": "1515 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151529000080",
  "CN": "1515 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151529100080",
  "CN": "1515 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151529900080",
  "CN": "1515 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151530000080",
  "CN": "1515 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de ricino y sus fracciones"
 },
 {
  "CNKEY": "151530100080",
  "CN": "1515 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a la producción de ácido aminoundecanoico que se utilice en fabricación de fibras textiles sintéticas o plásticos artificiales"
 },
 {
  "CNKEY": "151530900080",
  "CN": "1515 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151550000080",
  "CN": "1515 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceite de sésamo (ajonjolí) y sus fracciones"
 },
 {
  "CNKEY": "151550110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151550110080",
  "CN": "1515 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151550190080",
  "CN": "1515 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151550910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151550910080",
  "CN": "1515 50 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151550990080",
  "CN": "1515 50 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151560000080",
  "CN": "1515 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, de origen microbiano, y sus fracciones"
 },
 {
  "CNKEY": "151560110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151560110080",
  "CN": "1515 60 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151560510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151560510080",
  "CN": "1515 60 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151560590080",
  "CN": "1515 60 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "CNKEY": "151560600010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151560600080",
  "CN": "1515 60 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151560910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151560910080",
  "CN": "1515 60 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151560990080",
  "CN": "1515 60 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "CNKEY": "151590000080",
  "CN": "1515 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590110080",
  "CN": "1515 90 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceite de tung; aceites de jojoba y de oiticica; cera de mírica y cera del Japón; sus fracciones"
 },
 {
  "CNKEY": "151590210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceite de semilla de tabaco y sus fracciones"
 },
 {
  "CNKEY": "151590210020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aceite en bruto"
 },
 {
  "CNKEY": "151590210080",
  "CN": "1515 90 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destine a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151590290080",
  "CN": "1515 90 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590310080",
  "CN": "1515 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151590390080",
  "CN": "1515 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590400010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás aceites y sus fracciones"
 },
 {
  "CNKEY": "151590400020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aceites en bruto"
 },
 {
  "CNKEY": "151590400080",
  "CN": "1515 90 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151590510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590510080",
  "CN": "1515 90 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151590590080",
  "CN": "1515 90 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "CNKEY": "151590600010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590600080",
  "CN": "1515 90 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151590910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151590910080",
  "CN": "1515 90 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Concretos, en envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151590990080",
  "CN": "1515 90 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Concretos, que se presenten de otra forma; fluidos"
 },
 {
  "CNKEY": "151600000080",
  "CN": "1516",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, parcial o totalmente hidrogenados, interesterificados, reesterificados o elaidinizados, incluso refinados, pero sin preparar de otro modo"
 },
 {
  "CNKEY": "151610000080",
  "CN": "1516 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, animales, y sus fracciones"
 },
 {
  "CNKEY": "151610100080",
  "CN": "1516 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151610900080",
  "CN": "1516 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151620000080",
  "CN": "1516 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, vegetales, y sus fracciones"
 },
 {
  "CNKEY": "151620100080",
  "CN": "1516 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceite de ricino hidrogenado, llamado opalwax"
 },
 {
  "CNKEY": "151620910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151620910080",
  "CN": "1516 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151620950010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que se presenten de otro modo"
 },
 {
  "CNKEY": "151620950080",
  "CN": "1516 20 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Aceites de nabo (de nabina), de colza, de linaza, de girasol, de ilipé, de karité, de makoré, de tulucuná o de babasú, que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151620960010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151620960080",
  "CN": "1516 20 96",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Aceites de cacahuete (de cacahuate, de maní), de algodón, de soja (de soya) o de girasol; los demás aceites con un contenido en ácidos grasos libres inferior al 50 % en peso [excluidos los aceites de almendra de palma, de ilipé, de coco, de nabo (de nabina), de colza o de copaiba]"
 },
 {
  "CNKEY": "151620980080",
  "CN": "1516 20 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151630000080",
  "CN": "1516 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, de origen microbiano, y sus fracciones"
 },
 {
  "CNKEY": "151630910080",
  "CN": "1516 30 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "151630980080",
  "CN": "1516 30 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151700000080",
  "CN": "1517",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Margarina; mezclas o preparaciones alimenticias de grasas o aceites, animales, vegetales o de origen microbiano, o de fracciones de diferentes grasas o aceites, de este Capítulo, excepto las grasas y aceites, alimenticios o sus fracciones, de la partida 1516"
 },
 {
  "CNKEY": "151710000080",
  "CN": "1517 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Margarina, excepto la margarina líquida"
 },
 {
  "CNKEY": "151710100080",
  "CN": "1517 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas de la leche superior al 10 % pero inferior o igual al 15 % en peso"
 },
 {
  "CNKEY": "151710900080",
  "CN": "1517 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "151790000080",
  "CN": "1517 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "151790100080",
  "CN": "1517 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas de la leche superior al 10 % pero inferior o igual al 15 % en peso"
 },
 {
  "CNKEY": "151790910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "151790910080",
  "CN": "1517 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aceites vegetales fijos, fluidos, mezclados"
 },
 {
  "CNKEY": "151790930080",
  "CN": "1517 90 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mezclas o preparaciones culinarias para desmoldeo"
 },
 {
  "CNKEY": "151790990080",
  "CN": "1517 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "151800000080",
  "CN": "1518 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, cocidos, oxidados, deshidratados, sulfurados, soplados, polimerizados por calor en vacío o atmósfera inerte («estandolizados»), o modificados químicamente de otra forma, excepto los de la partida 1516; mezclas o preparaciones no alimenticias de grasas o de aceites, animales, vegetales o de origen microbiano, o de fracciones de diferentes grasas o aceites, de este Capítulo, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "151800100080",
  "CN": "1518 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Linoxina"
 },
 {
  "CNKEY": "151800310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites vegetales fijos, fluidos, mezclados, que se destinen a usos técnicos o industriales (excepto la fabricación de productos para la alimentación humana)"
 },
 {
  "CNKEY": "151800310080",
  "CN": "1518 00 31",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "151800390080",
  "CN": "1518 00 39",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "151800910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151800910080",
  "CN": "1518 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grasas y aceites, animales, vegetales o de origen microbiano, y sus fracciones, cocidos, oxidados, deshidratados, sulfurados, soplados, polimerizados por calor en vacío o atmósfera inerte («estandolizados»), o modificados químicamente de otra forma, excepto los de la partida 1516"
 },
 {
  "CNKEY": "151800950010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "151800950080",
  "CN": "1518 00 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mezclas y preparaciones no alimenticias de grasas y aceites animales o de grasas y aceites animales, vegetales o de origen microbiano, y sus fracciones"
 },
 {
  "CNKEY": "151800990080",
  "CN": "1518 00 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "152000000080",
  "CN": "1520 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Glicerol en bruto; aguas y lejías glicerinosas"
 },
 {
  "CNKEY": "152100000080",
  "CN": "1521",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ceras vegetales (excepto los triglicéridos), cera de abejas o de otros insectos y esperma de ballena o de otros cetáceos (espermaceti), incluso refinadas o coloreadas"
 },
 {
  "CNKEY": "152110000080",
  "CN": "1521 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ceras vegetales"
 },
 {
  "CNKEY": "152190000080",
  "CN": "1521 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "152190100080",
  "CN": "1521 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Esperma de ballena y de otros cetáceos (espermaceti), incluso refinada o coloreada"
 },
 {
  "CNKEY": "152190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Ceras de abejas o de otros insectos, incluso refinadas o coloreadas"
 },
 {
  "CNKEY": "152190910080",
  "CN": "1521 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "152190990080",
  "CN": "1521 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "152200000080",
  "CN": "1522 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Degrás; residuos procedentes del tratamiento de grasas o ceras, animales o vegetales"
 },
 {
  "CNKEY": "152200100080",
  "CN": "1522 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Degrás"
 },
 {
  "CNKEY": "152200310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Residuos procedentes del tratamiento de grasas o ceras, animales o vegetales"
 },
 {
  "CNKEY": "152200310020",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que contengan aceite con las características del aceite de oliva"
 },
 {
  "CNKEY": "152200310080",
  "CN": "1522 00 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pastas de neutralización soap-stocks"
 },
 {
  "CNKEY": "152200390080",
  "CN": "1522 00 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "152200910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "152200910080",
  "CN": "1522 00 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Borras o heces de aceites, pastas de neutralización soap-stocks"
 },
 {
  "CNKEY": "152200990080",
  "CN": "1522 00 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160011000090",
  "CN": "IV",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN IV - PRODUCTOS DE LAS INDUSTRIAS ALIMENTARIAS; BEBIDAS, LÍQUIDOS ALCOHÓLICOS Y VINAGRE; TABACO Y SUCEDÁNEOS DEL TABACO  ELABORADOS; PRODUCTOS, INCLUSO CON NICOTINA, DESTINADOS  PARA LA INHALACIÓN SIN COMBUSTIÓN;"
 },
 {
  "CNKEY": "160021000090",
  "CN": "16",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 16 - PREPARACIONES DE CARNE, PESCADO, CRUSTÁCEOS, MOLUSCOS O DEMÁS INVERTEBRADOS ACUÁTICOS, O DE INSECTOS"
 },
 {
  "CNKEY": "160100000080",
  "CN": "1601 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Embutidos y productos similares de carne, despojos, sangre o de insectos; preparaciones alimenticias a base de estos productos"
 },
 {
  "CNKEY": "160100100080",
  "CN": "1601 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De hígado"
 },
 {
  "CNKEY": "160100910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160100910080",
  "CN": "1601 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Embutidos, secos o para untar, sin cocer"
 },
 {
  "CNKEY": "160100990080",
  "CN": "1601 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160200000080",
  "CN": "1602",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás preparaciones y conservas de carne, despojos,  sangre o de insectos"
 },
 {
  "CNKEY": "160210000080",
  "CN": "1602 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones homogeneizadas"
 },
 {
  "CNKEY": "160220000080",
  "CN": "1602 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De hígado de cualquier animal"
 },
 {
  "CNKEY": "160220100080",
  "CN": "1602 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De ganso o de pato"
 },
 {
  "CNKEY": "160220900080",
  "CN": "1602 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aves de la partida 0105"
 },
 {
  "CNKEY": "160231000080",
  "CN": "1602 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De pavo (gallipavo)"
 },
 {
  "CNKEY": "160231110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "CNKEY": "160231110080",
  "CN": "1602 31 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que contengan exclusivamente carne de pavo sin cocer"
 },
 {
  "CNKEY": "160231190080",
  "CN": "1602 31 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160231800080",
  "CN": "1602 31 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160232000080",
  "CN": "1602 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De aves de la especie Gallus domesticus"
 },
 {
  "CNKEY": "160232110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "CNKEY": "160232110080",
  "CN": "1602 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin cocer"
 },
 {
  "CNKEY": "160232190080",
  "CN": "1602 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160232300080",
  "CN": "1602 32 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carne o despojos de aves superior o igual al 25 % pero inferior al 57 % en peso"
 },
 {
  "CNKEY": "160232900080",
  "CN": "1602 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160239000080",
  "CN": "1602 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160239210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de carne o despojos de aves superior o igual al 57 % en peso"
 },
 {
  "CNKEY": "160239210080",
  "CN": "1602 39 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin cocer"
 },
 {
  "CNKEY": "160239290080",
  "CN": "1602 39 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160239850080",
  "CN": "1602 39 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie porcina"
 },
 {
  "CNKEY": "160241000080",
  "CN": "1602 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jamones y trozos de jamón"
 },
 {
  "CNKEY": "160241100080",
  "CN": "1602 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "160241900080",
  "CN": "1602 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160242000080",
  "CN": "1602 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Paletas y trozos de paleta"
 },
 {
  "CNKEY": "160242100080",
  "CN": "1602 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "160242900080",
  "CN": "1602 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160249000080",
  "CN": "1602 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, incluidas las mezclas"
 },
 {
  "CNKEY": "160249110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De la especie porcina doméstica"
 },
 {
  "CNKEY": "160249110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de carne o despojos de cualquier clase superior o igual al 80 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "CNKEY": "160249110080",
  "CN": "1602 49 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Chuleteros (excepto los espinazos) y sus trozos, incluidas las mezclas de chuleteros y piernas"
 },
 {
  "CNKEY": "160249130080",
  "CN": "1602 49 13",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Espinazos y sus trozos, incluidas las mezclas de espinazos y paletas"
 },
 {
  "CNKEY": "160249150080",
  "CN": "1602 49 15",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás mezclas que contengan piernas, paletas, chuleteros o espinazos y sus trozos"
 },
 {
  "CNKEY": "160249190080",
  "CN": "1602 49 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160249300080",
  "CN": "1602 49 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carne o despojos de cualquier clase superior o igual al 40 % pero inferior al 80 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "CNKEY": "160249500080",
  "CN": "1602 49 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carne o despojos de cualquier clase inferior al 40 % en peso, incluidos el tocino y la grasa de cualquier naturaleza u origen"
 },
 {
  "CNKEY": "160249900080",
  "CN": "1602 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160250000080",
  "CN": "1602 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De la especie bovina"
 },
 {
  "CNKEY": "160250100080",
  "CN": "1602 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin cocer; mezclas de carnes o despojos cocidos y de carne o despojos sin cocer"
 },
 {
  "CNKEY": "160250310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160250310080",
  "CN": "1602 50 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Corned beef en envases herméticamente cerrados"
 },
 {
  "CNKEY": "160250950080",
  "CN": "1602 50 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290000080",
  "CN": "1602 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, incluidas las preparaciones de sangre de cualquier animal"
 },
 {
  "CNKEY": "160290100080",
  "CN": "1602 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones de sangre de cualquier animal"
 },
 {
  "CNKEY": "160290310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290310080",
  "CN": "1602 90 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De caza o de conejo"
 },
 {
  "CNKEY": "160290510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290510080",
  "CN": "1602 90 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que contengan carne o despojos de la especie porcina doméstica, sin cocer"
 },
 {
  "CNKEY": "160290610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290610020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Que contengan carne o despojos de la especie bovina"
 },
 {
  "CNKEY": "160290610080",
  "CN": "1602 90 61",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin cocer; mezclas de carne o despojos cocidos y de carne o despojos sin cocer"
 },
 {
  "CNKEY": "160290690080",
  "CN": "1602 90 69",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290910010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160290910080",
  "CN": "1602 90 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De ovinos"
 },
 {
  "CNKEY": "160290950080",
  "CN": "1602 90 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De caprinos"
 },
 {
  "CNKEY": "160290990080",
  "CN": "1602 90 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "160300000080",
  "CN": "1603 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Extractos y jugos de carne, pescado o de crustáceos, moluscos o demás invertebrados acuáticos"
 },
 {
  "CNKEY": "160300100080",
  "CN": "1603 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "160300800080",
  "CN": "1603 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160400000080",
  "CN": "1604",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones y conservas de pescado; caviar y sus sucedáneos preparados con huevas de pescado"
 },
 {
  "CNKEY": "160411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pescado entero o en trozos, excepto el pescado picado"
 },
 {
  "CNKEY": "160411000080",
  "CN": "1604 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Salmones"
 },
 {
  "CNKEY": "160412000080",
  "CN": "1604 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arenques"
 },
 {
  "CNKEY": "160412100080",
  "CN": "1604 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Filetes crudos simplemente rebozados con pasta o con pan rallado (empanados), incluso precocinados en aceite, congelados"
 },
 {
  "CNKEY": "160412910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160412910080",
  "CN": "1604 12 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases herméticamente cerrados"
 },
 {
  "CNKEY": "160412990080",
  "CN": "1604 12 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160413000080",
  "CN": "1604 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sardinas, sardinelas y espadines"
 },
 {
  "CNKEY": "160413110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sardinas"
 },
 {
  "CNKEY": "160413110080",
  "CN": "1604 13 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En aceite de oliva"
 },
 {
  "CNKEY": "160413190080",
  "CN": "1604 13 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160413900080",
  "CN": "1604 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414000080",
  "CN": "1604 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Atunes, listados y bonitos (Sarda spp.)"
 },
 {
  "CNKEY": "160414210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Atunes y listados"
 },
 {
  "CNKEY": "160414210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Listados o bonitos de vientre rayado"
 },
 {
  "CNKEY": "160414210080",
  "CN": "1604 14 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En aceite vegetal"
 },
 {
  "CNKEY": "160414260010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414260080",
  "CN": "1604 14 26",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Filetes llamados lomos"
 },
 {
  "CNKEY": "160414280080",
  "CN": "1604 14 28",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Atunes de aleta amarilla (rabiles) (Thunnus albacares)"
 },
 {
  "CNKEY": "160414310080",
  "CN": "1604 14 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En aceite vegetal"
 },
 {
  "CNKEY": "160414360010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414360080",
  "CN": "1604 14 36",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Filetes llamados lomos"
 },
 {
  "CNKEY": "160414380080",
  "CN": "1604 14 38",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414410080",
  "CN": "1604 14 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En aceite vegetal"
 },
 {
  "CNKEY": "160414460010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414460080",
  "CN": "1604 14 46",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Filetes llamados lomos"
 },
 {
  "CNKEY": "160414480080",
  "CN": "1604 14 48",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160414900080",
  "CN": "1604 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bonitos (Sarda spp.)"
 },
 {
  "CNKEY": "160415000080",
  "CN": "1604 15",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Caballas"
 },
 {
  "CNKEY": "160415110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De las especies Scomber scombrus y Scomber japonicus"
 },
 {
  "CNKEY": "160415110080",
  "CN": "1604 15 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Filetes"
 },
 {
  "CNKEY": "160415190080",
  "CN": "1604 15 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160415900080",
  "CN": "1604 15 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De la especie Scomber australasicus"
 },
 {
  "CNKEY": "160416000080",
  "CN": "1604 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anchoas"
 },
 {
  "CNKEY": "160417000080",
  "CN": "1604 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anguilas"
 },
 {
  "CNKEY": "160418000080",
  "CN": "1604 18 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aletas de tiburón"
 },
 {
  "CNKEY": "160419000080",
  "CN": "1604 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160419100080",
  "CN": "1604 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Salmónidos (excepto los salmones)"
 },
 {
  "CNKEY": "160419310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pescados del género Euthynnus [excepto los listados (Katsuwonus pelamis)]"
 },
 {
  "CNKEY": "160419310080",
  "CN": "1604 19 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Filetes llamados lomos"
 },
 {
  "CNKEY": "160419390080",
  "CN": "1604 19 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160419500080",
  "CN": "1604 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pescados de las especies Orcynopsis unicolor"
 },
 {
  "CNKEY": "160419910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160419910080",
  "CN": "1604 19 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Filetes crudos, simplemente rebozados con pasta o con pan rallado (empanados), incluso precocinados en aceite, congelados"
 },
 {
  "CNKEY": "160419920010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "160419920080",
  "CN": "1604 19 92",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Bacalaos (Gadus morhua, Gadus ogac y Gadus macrocephalus)"
 },
 {
  "CNKEY": "160419930080",
  "CN": "1604 19 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Carboneros (Pollachius virens)"
 },
 {
  "CNKEY": "160419940080",
  "CN": "1604 19 94",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Merluza (Merluccius spp., Urophycis spp.)"
 },
 {
  "CNKEY": "160419950080",
  "CN": "1604 19 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Abadejos (Theragra chalcogramma y Pollachius pollachius)"
 },
 {
  "CNKEY": "160419970080",
  "CN": "1604 19 97",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160420000080",
  "CN": "1604 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás preparaciones y conservas de pescado"
 },
 {
  "CNKEY": "160420050080",
  "CN": "1604 20 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones de surimi"
 },
 {
  "CNKEY": "160420100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "160420100080",
  "CN": "1604 20 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De salmones"
 },
 {
  "CNKEY": "160420300080",
  "CN": "1604 20 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De salmónidos (excepto los salmones)"
 },
 {
  "CNKEY": "160420400080",
  "CN": "1604 20 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De anchoas"
 },
 {
  "CNKEY": "160420500080",
  "CN": "1604 20 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De sardinas, de bonito o de caballas de las especies Scomber scombrus y Scomber japonicus y pescados de las especies Orcynopsis unicolor"
 },
 {
  "CNKEY": "160420700080",
  "CN": "1604 20 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De atunes, listados y los demás pescados del género Euthynnus"
 },
 {
  "CNKEY": "160420900080",
  "CN": "1604 20 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De los demás pescados"
 },
 {
  "CNKEY": "160431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caviar y sus sucedáneos"
 },
 {
  "CNKEY": "160431000080",
  "CN": "1604 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caviar"
 },
 {
  "CNKEY": "160432000080",
  "CN": "1604 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sucedáneos del caviar"
 },
 {
  "CNKEY": "160500000080",
  "CN": "1605",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Crustáceos, moluscos y demás invertebrados acuáticos, preparados o conservados"
 },
 {
  "CNKEY": "160510000080",
  "CN": "1605 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cangrejos (excepto macruros)"
 },
 {
  "CNKEY": "160521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Camarones, langostinos y demás decápodos Natantia"
 },
 {
  "CNKEY": "160521000080",
  "CN": "1605 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Presentados en envases no herméticos"
 },
 {
  "CNKEY": "160521100080",
  "CN": "1605 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 2 kg"
 },
 {
  "CNKEY": "160521900080",
  "CN": "1605 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160529000080",
  "CN": "1605 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160530000080",
  "CN": "1605 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bogavantes"
 },
 {
  "CNKEY": "160530100080",
  "CN": "1605 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carne de bogavante cocida, destinada a la industria de la transformación para la fabricación de manteca de bogavante, terrinas, sopas o salsas"
 },
 {
  "CNKEY": "160530900080",
  "CN": "1605 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160540000080",
  "CN": "1605 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás crustáceos"
 },
 {
  "CNKEY": "160551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Moluscos"
 },
 {
  "CNKEY": "160551000080",
  "CN": "1605 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ostras"
 },
 {
  "CNKEY": "160552000080",
  "CN": "1605 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Veneras (vieiras ), volandeiras y demás moluscos"
 },
 {
  "CNKEY": "160553000080",
  "CN": "1605 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mejillones"
 },
 {
  "CNKEY": "160553100080",
  "CN": "1605 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases herméticamente cerrados"
 },
 {
  "CNKEY": "160553900080",
  "CN": "1605 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160554000080",
  "CN": "1605 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Jibias (sepias)*, globitos, calamares y potas"
 },
 {
  "CNKEY": "160555000080",
  "CN": "1605 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pulpos"
 },
 {
  "CNKEY": "160556000080",
  "CN": "1605 56 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Almejas, berberechos y arcas"
 },
 {
  "CNKEY": "160557000080",
  "CN": "1605 57 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abulones u orejas de mar"
 },
 {
  "CNKEY": "160558000080",
  "CN": "1605 58 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caracoles, excepto los de mar"
 },
 {
  "CNKEY": "160559000080",
  "CN": "1605 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "160561000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás invertebrados acuáticos"
 },
 {
  "CNKEY": "160561000080",
  "CN": "1605 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pepinos de mar"
 },
 {
  "CNKEY": "160562000080",
  "CN": "1605 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Erizos de mar"
 },
 {
  "CNKEY": "160563000080",
  "CN": "1605 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Medusas"
 },
 {
  "CNKEY": "160569000080",
  "CN": "1605 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170021000090",
  "CN": "17",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 17 - AZÚCARES Y ARTÍCULOS DE CONFITERÍA"
 },
 {
  "CNKEY": "170100000080",
  "CN": "1701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Azúcar de caña o de remolacha y sacarosa químicamente pura, en estado sólido"
 },
 {
  "CNKEY": "170112000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Azúcar en bruto sin adición de aromatizante ni colorante"
 },
 {
  "CNKEY": "170112000080",
  "CN": "1701 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De remolacha"
 },
 {
  "CNKEY": "170112100080",
  "CN": "1701 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine al refinado"
 },
 {
  "CNKEY": "170112900080",
  "CN": "1701 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170113000080",
  "CN": "1701 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Azúcar de caña mencionado en la Nota 2 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "170113100080",
  "CN": "1701 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine al refinado"
 },
 {
  "CNKEY": "170113900080",
  "CN": "1701 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170114000080",
  "CN": "1701 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás azúcares de caña"
 },
 {
  "CNKEY": "170114100080",
  "CN": "1701 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destine al refinado"
 },
 {
  "CNKEY": "170114900080",
  "CN": "1701 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170191000080",
  "CN": "1701 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con adición de aromatizante o colorante"
 },
 {
  "CNKEY": "170199000080",
  "CN": "1701 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170199100080",
  "CN": "1701 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Azúcar blanco"
 },
 {
  "CNKEY": "170199900080",
  "CN": "1701 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170200000080",
  "CN": "1702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás azúcares, incluidas la lactosa, maltosa, glucosa y fructosa (levulosa) químicamente puras, en estado sólido; jarabe de azúcar sin adición de aromatizante ni colorante; sucedáneos de la miel, incluso mezclados con miel natural; azúcar y melaza caramelizados"
 },
 {
  "CNKEY": "170211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lactosa y jarabe de lactosa"
 },
 {
  "CNKEY": "170211000080",
  "CN": "1702 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de lactosa superior o igual al 99 % en peso, expresado en lactosa anhidra, calculado sobre producto seco"
 },
 {
  "CNKEY": "170219000080",
  "CN": "1702 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170220000080",
  "CN": "1702 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Azúcar y jarabe de arce (maple)"
 },
 {
  "CNKEY": "170220100080",
  "CN": "1702 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Azúcar sólido de arce, con aromatizantes o colorantes añadidos"
 },
 {
  "CNKEY": "170220900080",
  "CN": "1702 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170230000080",
  "CN": "1702 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Glucosa y jarabe de glucosa, sin fructosa o con un contenido de fructosa sobre producto seco, inferior al 20 % en peso"
 },
 {
  "CNKEY": "170230100080",
  "CN": "1702 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isoglucosa"
 },
 {
  "CNKEY": "170230500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170230500080",
  "CN": "1702 30 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En polvo cristalino blanco, incluso aglomerado"
 },
 {
  "CNKEY": "170230900080",
  "CN": "1702 30 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170240000080",
  "CN": "1702 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Glucosa y jarabe de glucosa, con un contenido de fructosa sobre producto seco superior o igual al 20 % pero inferior al 50 % en peso, excepto el azúcar invertido"
 },
 {
  "CNKEY": "170240100080",
  "CN": "1702 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isoglucosa"
 },
 {
  "CNKEY": "170240900080",
  "CN": "1702 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170250000080",
  "CN": "1702 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fructosa químicamente pura"
 },
 {
  "CNKEY": "170260000080",
  "CN": "1702 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás fructosas y jarabe de fructosa, con un contenido de fructosa sobre producto seco superior al 50 % en peso, excepto el azúcar invertido"
 },
 {
  "CNKEY": "170260100080",
  "CN": "1702 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isoglucosa"
 },
 {
  "CNKEY": "170260800080",
  "CN": "1702 60 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Jarabe de inulina"
 },
 {
  "CNKEY": "170260950080",
  "CN": "1702 60 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170290000080",
  "CN": "1702 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluido el azúcar invertido y demás azúcares y jarabes de azúcar, con un contenido de fructosa sobre producto seco de 50 % en peso"
 },
 {
  "CNKEY": "170290100080",
  "CN": "1702 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Maltosa químicamente pura"
 },
 {
  "CNKEY": "170290300080",
  "CN": "1702 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isoglucosa"
 },
 {
  "CNKEY": "170290500080",
  "CN": "1702 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Maltodextrina y jarabe de maltodextrina"
 },
 {
  "CNKEY": "170290710010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Azúcar y melaza, caramelizados"
 },
 {
  "CNKEY": "170290710080",
  "CN": "1702 90 71",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa, en estado seco, superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "170290750010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170290750080",
  "CN": "1702 90 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En polvo, incluso aglomerado"
 },
 {
  "CNKEY": "170290790080",
  "CN": "1702 90 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170290800080",
  "CN": "1702 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Jarabe de inulina"
 },
 {
  "CNKEY": "170290950080",
  "CN": "1702 90 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "170300000080",
  "CN": "1703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Melaza procedente de la extracción o del refinado del azúcar"
 },
 {
  "CNKEY": "170310000080",
  "CN": "1703 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Melaza de caña"
 },
 {
  "CNKEY": "170390000080",
  "CN": "1703 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "170400000080",
  "CN": "1704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de confitería sin cacao (incluido el chocolate blanco)"
 },
 {
  "CNKEY": "170410000080",
  "CN": "1704 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chicles y demás gomas de mascar, incluso recubiertos de azúcar"
 },
 {
  "CNKEY": "170410100080",
  "CN": "1704 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa inferior al 60 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "170410900080",
  "CN": "1704 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa superior o igual al 60 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "170490000080",
  "CN": "1704 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170490100080",
  "CN": "1704 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Extracto de regaliz con un contenido de sacarosa superior al 10 % en peso, sin adición de otras sustancias"
 },
 {
  "CNKEY": "170490300080",
  "CN": "1704 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparación llamada «chocolate blanco»"
 },
 {
  "CNKEY": "170490510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170490510080",
  "CN": "1704 90 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pastas y masas, incluido el mazapán, en envases inmediatos con un contenido neto superior o igual a 1 kg"
 },
 {
  "CNKEY": "170490550080",
  "CN": "1704 90 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pastillas para la garganta y caramelos para la tos"
 },
 {
  "CNKEY": "170490610080",
  "CN": "1704 90 61",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Grageas, peladillas y dulces con recubrimiento similar"
 },
 {
  "CNKEY": "170490650010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170490650080",
  "CN": "1704 90 65",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Gomas y otros artículos de confitería, a base de gelificantes, incluidas las pastas de frutas en forma de artículos de confitería"
 },
 {
  "CNKEY": "170490710080",
  "CN": "1704 90 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Caramelos de azúcar cocido, incluso rellenos"
 },
 {
  "CNKEY": "170490750080",
  "CN": "1704 90 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás caramelos"
 },
 {
  "CNKEY": "170490810010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "170490810080",
  "CN": "1704 90 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Obtenidos por compresión"
 },
 {
  "CNKEY": "170490990080",
  "CN": "1704 90 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "180021000090",
  "CN": "18",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 18 - CACAO Y SUS PREPARACIONES"
 },
 {
  "CNKEY": "180100000080",
  "CN": "1801 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cacao en grano, entero o partido, crudo o tostado"
 },
 {
  "CNKEY": "180200000080",
  "CN": "1802 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cáscara, películas y demás desechos de cacao"
 },
 {
  "CNKEY": "180300000080",
  "CN": "1803",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pasta de cacao, incluso desgrasada"
 },
 {
  "CNKEY": "180310000080",
  "CN": "1803 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin desgrasar"
 },
 {
  "CNKEY": "180320000080",
  "CN": "1803 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desgrasada total o parcialmente"
 },
 {
  "CNKEY": "180400000080",
  "CN": "1804 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Manteca, grasa y aceite de cacao"
 },
 {
  "CNKEY": "180500000080",
  "CN": "1805 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cacao en polvo sin adición de azúcar ni otro edulcorante"
 },
 {
  "CNKEY": "180600000080",
  "CN": "1806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chocolate y demás preparaciones alimenticias que contengan cacao"
 },
 {
  "CNKEY": "180610000080",
  "CN": "1806 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cacao en polvo con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "180610150080",
  "CN": "1806 10 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "180610200080",
  "CN": "1806 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa o isoglucosa superior o igual al 5 % pero inferior al 65 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "180610300080",
  "CN": "1806 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa o isoglucosa superior o igual al 65 % pero inferior al 80 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "180610900080",
  "CN": "1806 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa o isoglucosa superior o igual al 80 % en peso, incluido el azúcar invertido calculado en sacarosa"
 },
 {
  "CNKEY": "180620000080",
  "CN": "1806 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás preparaciones, en bloques, tabletas o barras con peso superior a 2 kg, o en forma líquida, pastosa o en polvo, gránulos o formas similares, en recipientes o en envases inmediatos con un contenido superior a 2 kg"
 },
 {
  "CNKEY": "180620100080",
  "CN": "1806 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de manteca de cacao superior o igual al 31 % en peso, o con un contenido total de manteca de cacao y materias grasas de la leche superior o igual al 31 % en peso"
 },
 {
  "CNKEY": "180620300080",
  "CN": "1806 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido total de manteca de cacao y materias grasas de la leche superior o igual al 25 % pero inferior al 31 % en peso"
 },
 {
  "CNKEY": "180620500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "180620500080",
  "CN": "1806 20 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de manteca de cacao superior o igual al 18 % en peso"
 },
 {
  "CNKEY": "180620700080",
  "CN": "1806 20 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Preparaciones llamadas chocolate milk crumb"
 },
 {
  "CNKEY": "180620800080",
  "CN": "1806 20 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Baño de cacao"
 },
 {
  "CNKEY": "180620950080",
  "CN": "1806 20 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "180631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, en bloques, tabletas o barras"
 },
 {
  "CNKEY": "180631000080",
  "CN": "1806 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rellenos"
 },
 {
  "CNKEY": "180632000080",
  "CN": "1806 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin rellenar"
 },
 {
  "CNKEY": "180632100080",
  "CN": "1806 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con cereales, nueces u otros frutos"
 },
 {
  "CNKEY": "180632900080",
  "CN": "1806 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "180690000080",
  "CN": "1806 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "180690110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Chocolate y artículos de chocolate"
 },
 {
  "CNKEY": "180690110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bombones, incluso rellenos"
 },
 {
  "CNKEY": "180690110080",
  "CN": "1806 90 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con alcohol"
 },
 {
  "CNKEY": "180690190080",
  "CN": "1806 90 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "180690310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "180690310080",
  "CN": "1806 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rellenos"
 },
 {
  "CNKEY": "180690390080",
  "CN": "1806 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin rellenar"
 },
 {
  "CNKEY": "180690500080",
  "CN": "1806 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos de confitería y sucedáneos fabricados con productos sustitutivos del azúcar, que contengan cacao"
 },
 {
  "CNKEY": "180690600080",
  "CN": "1806 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pastas para untar que contengan cacao"
 },
 {
  "CNKEY": "180690700080",
  "CN": "1806 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones para bebidas que contengan cacao"
 },
 {
  "CNKEY": "180690900080",
  "CN": "1806 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190021000090",
  "CN": "19",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 19 - PREPARACIONES A BASE DE CEREALES, HARINA, ALMIDÓN, FÉCULA O LECHE; PRODUCTOS DE PASTELERÍA"
 },
 {
  "CNKEY": "190100000080",
  "CN": "1901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Extracto de malta; preparaciones alimenticias de harina, grañones, sémola, almidón, fécula o extracto de malta, que no contengan cacao o con un contenido de cacao inferior al 40 % en peso calculado sobre una base totalmente desgrasada, no expresadas ni comprendidas en otra parte; preparaciones alimenticias de productos de las partidas 0401 a 0404 que no contengan cacao o con un contenido de cacao inferior al 5 % en peso calculado sobre una base totalmente desgrasada, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "190110000080",
  "CN": "1901 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para la alimentación de lactantes o niños de corta edad, acondicionadas para la venta al por menor"
 },
 {
  "CNKEY": "190120000080",
  "CN": "1901 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mezclas y pastas para la preparación de productos de panadería, pastelería o galletería de la partida 1905"
 },
 {
  "CNKEY": "190190000080",
  "CN": "1901 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190190110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Extracto de malta"
 },
 {
  "CNKEY": "190190110080",
  "CN": "1901 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de extracto seco superior o igual al 90 % en peso"
 },
 {
  "CNKEY": "190190190080",
  "CN": "1901 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190190910080",
  "CN": "1901 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa, incluido el azúcar invertido, o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con menos del 5 % en peso (excepto las preparaciones alimenticias en polvo de productos de las partidas 0401 a 0404)"
 },
 {
  "CNKEY": "190190950080",
  "CN": "1901 90 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Preparaciones alimenticias en polvo, constituidas por una mezcla de leche desnatada o de lactosuero y de grasas o aceites vegetales, con un contenido de grasas o aceites inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "190190990080",
  "CN": "1901 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190200000080",
  "CN": "1902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pastas alimenticias, incluso cocidas o rellenas (de carne u otras sustancias) o preparadas de otra forma, tales como espaguetis, fideos, macarrones, tallarines, lasañas, ñoquis, ravioles, canelones; cuscús, incluso preparado"
 },
 {
  "CNKEY": "190211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pastas alimenticias sin cocer, rellenar ni preparar de otra forma"
 },
 {
  "CNKEY": "190211000080",
  "CN": "1902 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan huevo"
 },
 {
  "CNKEY": "190219000080",
  "CN": "1902 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "190219100080",
  "CN": "1902 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin harina ni sémola de trigo blando"
 },
 {
  "CNKEY": "190219900080",
  "CN": "1902 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190220000080",
  "CN": "1902 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pastas alimenticias rellenas, incluso cocidas o preparadas de otra forma"
 },
 {
  "CNKEY": "190220100080",
  "CN": "1902 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de pescados y crustáceos, moluscos y otros invertebrados acuáticos superior al 20 % en peso"
 },
 {
  "CNKEY": "190220300080",
  "CN": "1902 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de embutidos y similares, de carne y despojos de cualquier clase superior al 20 % en peso, incluida la grasa de cualquier naturaleza u origen"
 },
 {
  "CNKEY": "190220910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "190220910080",
  "CN": "1902 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cocidas"
 },
 {
  "CNKEY": "190220990080",
  "CN": "1902 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "190230000080",
  "CN": "1902 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás pastas alimenticias"
 },
 {
  "CNKEY": "190230100080",
  "CN": "1902 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Secas"
 },
 {
  "CNKEY": "190230900080",
  "CN": "1902 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "190240000080",
  "CN": "1902 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cuscús"
 },
 {
  "CNKEY": "190240100080",
  "CN": "1902 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin preparar"
 },
 {
  "CNKEY": "190240900080",
  "CN": "1902 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190300000080",
  "CN": "1903 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tapioca y sus sucedáneos preparados con fécula, en copos, grumos, granos perlados, cemiduras o formas similares"
 },
 {
  "CNKEY": "190400000080",
  "CN": "1904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos a base de cereales obtenidos por inflado o tostado (por ejemplo: hojuelas o copos de maíz); cereales (excepto el maíz) en grano o en forma de copos u otro grano trabajado (excepto la harina, grañones y sémola), precocidos o preparados de otro modo, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "190410000080",
  "CN": "1904 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos a base de cereales obtenidos por inflado o tostado"
 },
 {
  "CNKEY": "190410100080",
  "CN": "1904 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de maíz"
 },
 {
  "CNKEY": "190410300080",
  "CN": "1904 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de arroz"
 },
 {
  "CNKEY": "190410900080",
  "CN": "1904 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190420000080",
  "CN": "1904 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones alimenticias obtenidas con copos de cereales sin tostar o con mezclas de copos de cereales sin tostar y copos de cereales tostados o cereales inflados"
 },
 {
  "CNKEY": "190420100080",
  "CN": "1904 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones a base de copos de cereales, sin tostar, del tipo Müsli"
 },
 {
  "CNKEY": "190420910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "190420910080",
  "CN": "1904 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de maíz"
 },
 {
  "CNKEY": "190420950080",
  "CN": "1904 20 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de arroz"
 },
 {
  "CNKEY": "190420990080",
  "CN": "1904 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "190430000080",
  "CN": "1904 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trigo bulgur"
 },
 {
  "CNKEY": "190490000080",
  "CN": "1904 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190490100080",
  "CN": "1904 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de arroz"
 },
 {
  "CNKEY": "190490800080",
  "CN": "1904 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190500000080",
  "CN": "1905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos de panadería, pastelería o galletería, incluso con adición de cacao; hostias, sellos vacíos de los tipos utilizados para medicamentos, obleas para sellar, pastas secas de harina, almidón o fécula, en hojas, y productos similares"
 },
 {
  "CNKEY": "190510000080",
  "CN": "1905 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pan crujiente llamado Knäckebrot"
 },
 {
  "CNKEY": "190520000080",
  "CN": "1905 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pan de especias"
 },
 {
  "CNKEY": "190520100080",
  "CN": "1905 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, inferior al 30 % en peso"
 },
 {
  "CNKEY": "190520300080",
  "CN": "1905 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, superior o igual al 30 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "190520900080",
  "CN": "1905 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sacarosa, incluido el azúcar invertido calculado en sacarosa, superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "190531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Galletas dulces (con adición de edulcorante); barquillos y obleas, incluso rellenos (gaufrettes, wafers) y waffles(gaufres)"
 },
 {
  "CNKEY": "190531000080",
  "CN": "1905 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Galletas dulces (con adición de edulcorante)"
 },
 {
  "CNKEY": "190531110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Total o parcialmente recubiertos o revestidos de chocolate o de otras preparaciones que contengan cacao"
 },
 {
  "CNKEY": "190531110080",
  "CN": "1905 31 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido inferior o igual a 85 g"
 },
 {
  "CNKEY": "190531190080",
  "CN": "1905 31 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190531300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190531300080",
  "CN": "1905 31 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de materias grasas de la leche superior o igual al 8 % en peso"
 },
 {
  "CNKEY": "190531910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "190531910080",
  "CN": "1905 31 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Galletas dobles rellenas"
 },
 {
  "CNKEY": "190531990080",
  "CN": "1905 31 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "190532000080",
  "CN": "1905 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Barquillos y obleas, incluso rellenos (gaufrettes, wafers) y waffles(gaufres)"
 },
 {
  "CNKEY": "190532050080",
  "CN": "1905 32 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de agua superior al 10 % en peso"
 },
 {
  "CNKEY": "190532110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190532110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Total o parcialmente recubiertos o revestidos de chocolate o de otras preparaciones que contengan cacao"
 },
 {
  "CNKEY": "190532110080",
  "CN": "1905 32 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido inferior o igual a 85 g"
 },
 {
  "CNKEY": "190532190080",
  "CN": "1905 32 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190532910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190532910080",
  "CN": "1905 32 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Salados, rellenos o sin rellenar"
 },
 {
  "CNKEY": "190532990080",
  "CN": "1905 32 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190540000080",
  "CN": "1905 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pan tostado y productos similares tostados"
 },
 {
  "CNKEY": "190540100080",
  "CN": "1905 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pan a la brasa"
 },
 {
  "CNKEY": "190540900080",
  "CN": "1905 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "190590000080",
  "CN": "1905 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190590100080",
  "CN": "1905 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pan ázimo (mazoth)"
 },
 {
  "CNKEY": "190590200080",
  "CN": "1905 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hostias, sellos vacíos de los tipos utilizados para medicamentos, obleas para sellar, pastas secas de harina, almidón o fécula, en hojas y productos similares"
 },
 {
  "CNKEY": "190590300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190590300080",
  "CN": "1905 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pan sin miel, huevos, queso o frutos, con unos contenidos de azúcares y de materias grasas inferiores o iguales al 5 % en peso calculados sobre materia seca"
 },
 {
  "CNKEY": "190590450080",
  "CN": "1905 90 45",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Galletas"
 },
 {
  "CNKEY": "190590550080",
  "CN": "1905 90 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Productos extrudidos o expandidos, salados o aromatizados"
 },
 {
  "CNKEY": "190590700010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "190590700080",
  "CN": "1905 90 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido en peso de sacarosa, azúcar invertido y\/o isoglucosa igual o superior al 5 %"
 },
 {
  "CNKEY": "190590800080",
  "CN": "1905 90 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200021000090",
  "CN": "20",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 20 - PREPARACIONES DE HORTALIZAS, FRUTAS U OTROS FRUTOS O DEMÁS PARTES DE PLANTAS"
 },
 {
  "CNKEY": "200100000080",
  "CN": "2001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas, frutas u otros frutos y demás partes comestibles de plantas, preparados o conservados en vinagre o en ácido acético"
 },
 {
  "CNKEY": "200110000080",
  "CN": "2001 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg\/net eda",
  "ES": "Pepinos y pepinillos"
 },
 {
  "CNKEY": "200190000080",
  "CN": "2001 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200190100080",
  "CN": "2001 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chutney de mango"
 },
 {
  "CNKEY": "200190200080",
  "CN": "2001 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frutos del género Capsicum (excepto los pimientos dulces)"
 },
 {
  "CNKEY": "200190300080",
  "CN": "2001 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "CNKEY": "200190400080",
  "CN": "2001 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ñames, boniatos y partes comestibles similares de plantas, con un contenido de almidón o de fécula superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "200190500080",
  "CN": "2001 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Setas"
 },
 {
  "CNKEY": "200190650080",
  "CN": "2001 90 65",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceitunas"
 },
 {
  "CNKEY": "200190700080",
  "CN": "2001 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pimientos dulces"
 },
 {
  "CNKEY": "200190920080",
  "CN": "2001 90 92",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales; palmitos"
 },
 {
  "CNKEY": "200190970080",
  "CN": "2001 90 97",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200200000080",
  "CN": "2002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tomates preparados o conservados (excepto en vinagre o en ácido acético)"
 },
 {
  "CNKEY": "200210000080",
  "CN": "2002 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tomates enteros o en trozos"
 },
 {
  "CNKEY": "200210100080",
  "CN": "2002 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pelados"
 },
 {
  "CNKEY": "200210900080",
  "CN": "2002 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200290000080",
  "CN": "2002 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200290110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de materia seca inferior al 12 % en peso"
 },
 {
  "CNKEY": "200290110080",
  "CN": "2002 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200290190080",
  "CN": "2002 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200290310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de materia seca superior o igual al 12 % pero inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200290310080",
  "CN": "2002 90 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200290390080",
  "CN": "2002 90 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200290910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de materia seca superior al 30 % en peso"
 },
 {
  "CNKEY": "200290910080",
  "CN": "2002 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200290990080",
  "CN": "2002 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200300000080",
  "CN": "2003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hongos y trufas, preparados o conservados (excepto en vinagre o en ácido acético)"
 },
 {
  "CNKEY": "200310000080",
  "CN": "2003 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hongos del género Agaricus"
 },
 {
  "CNKEY": "200310200080",
  "CN": "2003 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg\/net eda",
  "ES": "Conservados provisionalmente, cocidos completamente"
 },
 {
  "CNKEY": "200310300080",
  "CN": "2003 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg\/net eda",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200390000080",
  "CN": "2003 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200390100080",
  "CN": "2003 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trufas"
 },
 {
  "CNKEY": "200390900080",
  "CN": "2003 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200400000080",
  "CN": "2004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas preparadas o conservadas (excepto en vinagre o en ácido acético), congeladas, excepto los productos de la partida 2006"
 },
 {
  "CNKEY": "200410000080",
  "CN": "2004 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Patatas (papas)"
 },
 {
  "CNKEY": "200410100080",
  "CN": "2004 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente cocidas"
 },
 {
  "CNKEY": "200410910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200410910080",
  "CN": "2004 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En forma de harinas, sémolas o copos"
 },
 {
  "CNKEY": "200410990080",
  "CN": "2004 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200490000080",
  "CN": "2004 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas y las mezclas de hortalizas"
 },
 {
  "CNKEY": "200490100080",
  "CN": "2004 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "CNKEY": "200490300080",
  "CN": "2004 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Choucroute, alcaparras y aceitunas"
 },
 {
  "CNKEY": "200490500080",
  "CN": "2004 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guisantes (Pisum sativum) y judías verdes"
 },
 {
  "CNKEY": "200490910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, incluidas las mezclas"
 },
 {
  "CNKEY": "200490910080",
  "CN": "2004 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cebollas, simplemente cocidas"
 },
 {
  "CNKEY": "200490980080",
  "CN": "2004 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200500000080",
  "CN": "2005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas preparadas o conservadas (excepto en vinagre o en ácido acético), sin congelar, excepto los productos de la partida 2006"
 },
 {
  "CNKEY": "200510000080",
  "CN": "2005 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hortalizas homogeneizadas"
 },
 {
  "CNKEY": "200520000080",
  "CN": "2005 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Patatas (papas)"
 },
 {
  "CNKEY": "200520100080",
  "CN": "2005 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En forma de harinas, sémolas o copos"
 },
 {
  "CNKEY": "200520200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200520200080",
  "CN": "2005 20 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En rodajas finas, fritas, incluso saladas o aromatizadas, en envases herméticamente cerrados, idóneos para su consumo inmediato"
 },
 {
  "CNKEY": "200520800080",
  "CN": "2005 20 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200540000080",
  "CN": "2005 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guisantes (arvejas, chícharos) (Pisum sativum)"
 },
 {
  "CNKEY": "200551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Judías (porotos, alubias, frijoles, fréjoles) (Vigna spp., Phaseolus spp.)"
 },
 {
  "CNKEY": "200551000080",
  "CN": "2005 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desvainadas"
 },
 {
  "CNKEY": "200559000080",
  "CN": "2005 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200560000080",
  "CN": "2005 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Espárragos"
 },
 {
  "CNKEY": "200570000080",
  "CN": "2005 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg\/net eda",
  "ES": "Aceitunas"
 },
 {
  "CNKEY": "200580000080",
  "CN": "2005 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Maíz dulce (Zea mays var. saccharata)"
 },
 {
  "CNKEY": "200591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hortalizas y las mezclas de hortalizas"
 },
 {
  "CNKEY": "200591000080",
  "CN": "2005 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Brotes de bambú"
 },
 {
  "CNKEY": "200599000080",
  "CN": "2005 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200599100080",
  "CN": "2005 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos del género Capsicum (excepto los pimientos dulces)"
 },
 {
  "CNKEY": "200599200080",
  "CN": "2005 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Alcaparras"
 },
 {
  "CNKEY": "200599300080",
  "CN": "2005 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Alcachofas"
 },
 {
  "CNKEY": "200599500080",
  "CN": "2005 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mezclas de hortalizas"
 },
 {
  "CNKEY": "200599600080",
  "CN": "2005 99 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Choucroute"
 },
 {
  "CNKEY": "200599800080",
  "CN": "2005 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200600000080",
  "CN": "2006 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hortalizas, frutas u otros frutos o sus cortezas y demás partes de plantas, confitados con azúcar (almibarados, glaseados o escarchados)"
 },
 {
  "CNKEY": "200600100080",
  "CN": "2006 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Jengibre"
 },
 {
  "CNKEY": "200600310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200600310020",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200600310080",
  "CN": "2006 00 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cerezas"
 },
 {
  "CNKEY": "200600350080",
  "CN": "2006 00 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "200600380080",
  "CN": "2006 00 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200600910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200600910080",
  "CN": "2006 00 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "200600990080",
  "CN": "2006 00 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200700000080",
  "CN": "2007",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Confituras, jaleas y mermeladas, purés y pastas de frutas u otros frutos, obtenidos por cocción, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "200710000080",
  "CN": "2007 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones homogeneizadas"
 },
 {
  "CNKEY": "200710100080",
  "CN": "2007 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200710910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200710910080",
  "CN": "2007 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De frutos tropicales"
 },
 {
  "CNKEY": "200710990080",
  "CN": "2007 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200791000080",
  "CN": "2007 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De agrios (cítricos)"
 },
 {
  "CNKEY": "200791100080",
  "CN": "2007 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 30 % en peso"
 },
 {
  "CNKEY": "200791300080",
  "CN": "2007 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % pero inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200791900080",
  "CN": "2007 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200799000080",
  "CN": "2007 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200799100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 30 % en peso"
 },
 {
  "CNKEY": "200799100080",
  "CN": "2007 99 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Puré y pasta de ciruelas, en envases inmediatos con un contenido neto superior a 100 kg, que se destinen a una transformación industrial"
 },
 {
  "CNKEY": "200799200080",
  "CN": "2007 99 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Puré y pasta de castañas"
 },
 {
  "CNKEY": "200799310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200799310080",
  "CN": "2007 99 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De cerezas"
 },
 {
  "CNKEY": "200799330080",
  "CN": "2007 99 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De fresas (frutillas)"
 },
 {
  "CNKEY": "200799350080",
  "CN": "2007 99 35",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De frambuesas"
 },
 {
  "CNKEY": "200799390080",
  "CN": "2007 99 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200799500080",
  "CN": "2007 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % pero inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200799930010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200799930080",
  "CN": "2007 99 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De frutos tropicales y nueces tropicales"
 },
 {
  "CNKEY": "200799970080",
  "CN": "2007 99 97",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200800000080",
  "CN": "2008",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Frutas u otros frutos y demás partes comestibles de plantas, preparados o conservados de otro modo, incluso con adición de azúcar u otro edulcorante o alcohol, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "200811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frutos de cáscara, cacahuates (cacahuetes, maníes) y demás semillas, incluso mezclados entre sí"
 },
 {
  "CNKEY": "200811000080",
  "CN": "2008 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cacahuates (cacahuetes, maníes)"
 },
 {
  "CNKEY": "200811100080",
  "CN": "2008 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Manteca de cacahuete (cacahuate, maní)"
 },
 {
  "CNKEY": "200811910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200811910080",
  "CN": "2008 11 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior a 1 kg"
 },
 {
  "CNKEY": "200811960010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200811960080",
  "CN": "2008 11 96",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Tostados"
 },
 {
  "CNKEY": "200811980080",
  "CN": "2008 11 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200819000080",
  "CN": "2008 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las mezclas"
 },
 {
  "CNKEY": "200819120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200819120080",
  "CN": "2008 19 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Nueces tropicales; mezclas con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200819130010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200819130080",
  "CN": "2008 19 13",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Almendras y pistachos, tostados"
 },
 {
  "CNKEY": "200819190080",
  "CN": "2008 19 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200819920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200819920080",
  "CN": "2008 19 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Nueces tropicales; mezclas con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200819930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200819930020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Frutos de cáscara tostados"
 },
 {
  "CNKEY": "200819930080",
  "CN": "2008 19 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Almendras y pistachos"
 },
 {
  "CNKEY": "200819950080",
  "CN": "2008 19 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200819990080",
  "CN": "2008 19 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200820000080",
  "CN": "2008 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Piñas (ananás)"
 },
 {
  "CNKEY": "200820110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200820110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200820110080",
  "CN": "2008 20 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 17 % en peso"
 },
 {
  "CNKEY": "200820190080",
  "CN": "2008 20 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200820310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200820310080",
  "CN": "2008 20 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 19 % en peso"
 },
 {
  "CNKEY": "200820390080",
  "CN": "2008 20 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200820510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200820510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200820510080",
  "CN": "2008 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 17 % en peso"
 },
 {
  "CNKEY": "200820590080",
  "CN": "2008 20 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200820710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200820710080",
  "CN": "2008 20 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 19 % en peso"
 },
 {
  "CNKEY": "200820790080",
  "CN": "2008 20 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200820900080",
  "CN": "2008 20 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200830000080",
  "CN": "2008 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Agrios (cítricos)"
 },
 {
  "CNKEY": "200830110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200830110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200830110080",
  "CN": "2008 30 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200830190080",
  "CN": "2008 30 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200830310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200830310080",
  "CN": "2008 30 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200830390080",
  "CN": "2008 30 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200830510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200830510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200830510080",
  "CN": "2008 30 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Gajos de toronja y de pomelo"
 },
 {
  "CNKEY": "200830550080",
  "CN": "2008 30 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mandarinas, incluidas las tangerinas y satsumas; clementinas, wilkings y demás híbridos similares de agrios"
 },
 {
  "CNKEY": "200830590080",
  "CN": "2008 30 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200830710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200830710080",
  "CN": "2008 30 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Gajos de toronja y de pomelo"
 },
 {
  "CNKEY": "200830750080",
  "CN": "2008 30 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mandarinas, incluidas las tangerinas y satsumas; clementinas, wilkings y demás híbridos similares de agrios"
 },
 {
  "CNKEY": "200830790080",
  "CN": "2008 30 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200830900080",
  "CN": "2008 30 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200840000080",
  "CN": "2008 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peras"
 },
 {
  "CNKEY": "200840110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200840110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200840110030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200840110080",
  "CN": "2008 40 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200840190080",
  "CN": "2008 40 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840210080",
  "CN": "2008 40 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200840290080",
  "CN": "2008 40 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200840310080",
  "CN": "2008 40 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200840390080",
  "CN": "2008 40 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200840510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200840510080",
  "CN": "2008 40 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200840590080",
  "CN": "2008 40 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200840710080",
  "CN": "2008 40 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200840790080",
  "CN": "2008 40 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200840900080",
  "CN": "2008 40 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200850000080",
  "CN": "2008 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Albaricoques (damascos, chabacanos)"
 },
 {
  "CNKEY": "200850110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200850110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200850110030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200850110080",
  "CN": "2008 50 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200850190080",
  "CN": "2008 50 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850310080",
  "CN": "2008 50 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200850390080",
  "CN": "2008 50 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200850510080",
  "CN": "2008 50 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200850590080",
  "CN": "2008 50 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200850610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200850610080",
  "CN": "2008 50 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200850690080",
  "CN": "2008 50 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200850710080",
  "CN": "2008 50 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200850790080",
  "CN": "2008 50 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200850920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200850920080",
  "CN": "2008 50 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 5 kg"
 },
 {
  "CNKEY": "200850980080",
  "CN": "2008 50 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 5 kg"
 },
 {
  "CNKEY": "200860000080",
  "CN": "2008 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cerezas"
 },
 {
  "CNKEY": "200860110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200860110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200860110080",
  "CN": "2008 60 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200860190080",
  "CN": "2008 60 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200860310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200860310080",
  "CN": "2008 60 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200860390080",
  "CN": "2008 60 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200860500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200860500020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200860500080",
  "CN": "2008 60 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior a 1 kg"
 },
 {
  "CNKEY": "200860600080",
  "CN": "2008 60 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200860700010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200860700080",
  "CN": "2008 60 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 4,5 kg"
 },
 {
  "CNKEY": "200860900080",
  "CN": "2008 60 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 4,5 kg"
 },
 {
  "CNKEY": "200870000080",
  "CN": "2008 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Melocotones (duraznos), incluidos los griñones y nectarinas"
 },
 {
  "CNKEY": "200870110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido;"
 },
 {
  "CNKEY": "200870110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200870110030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200870110080",
  "CN": "2008 70 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200870190080",
  "CN": "2008 70 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870310080",
  "CN": "2008 70 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200870390080",
  "CN": "2008 70 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200870510080",
  "CN": "2008 70 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200870590080",
  "CN": "2008 70 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200870610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200870610080",
  "CN": "2008 70 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200870690080",
  "CN": "2008 70 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200870710080",
  "CN": "2008 70 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 15 % en peso"
 },
 {
  "CNKEY": "200870790080",
  "CN": "2008 70 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200870920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200870920080",
  "CN": "2008 70 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 5 kg"
 },
 {
  "CNKEY": "200870980080",
  "CN": "2008 70 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 5 kg"
 },
 {
  "CNKEY": "200880000080",
  "CN": "2008 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fresas (frutillas)"
 },
 {
  "CNKEY": "200880110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200880110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200880110080",
  "CN": "2008 80 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200880190080",
  "CN": "2008 80 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200880310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200880310080",
  "CN": "2008 80 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200880390080",
  "CN": "2008 80 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200880500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200880500080",
  "CN": "2008 80 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200880700080",
  "CN": "2008 80 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200880900080",
  "CN": "2008 80 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las mezclas, excepto las mezclas de la subpartida 200819"
 },
 {
  "CNKEY": "200891000080",
  "CN": "2008 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Palmitos"
 },
 {
  "CNKEY": "200893000080",
  "CN": "2008 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arándanos agrios, trepadores o palustres (Vaccinium macrocarpon, Vaccinium oxycoccos); arándanos rojos o encarnados (Vaccinium vitis-idaea)"
 },
 {
  "CNKEY": "200893110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200893110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200893110080",
  "CN": "2008 93 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200893190080",
  "CN": "2008 93 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200893210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200893210080",
  "CN": "2008 93 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200893290080",
  "CN": "2008 93 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200893910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200893910080",
  "CN": "2008 93 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200893930080",
  "CN": "2008 93 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200893990080",
  "CN": "2008 93 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200897000080",
  "CN": "2008 97",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezclas"
 },
 {
  "CNKEY": "200897030010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De nueces tropicales y frutos tropicales, con un contenido de nueces tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897030080",
  "CN": "2008 97 03",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200897050080",
  "CN": "2008 97 05",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "200897120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897120020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200897120030",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200897120040",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200897120080",
  "CN": "2008 97 12",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897140080",
  "CN": "2008 97 14",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897160010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897160080",
  "CN": "2008 97 16",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897180080",
  "CN": "2008 97 18",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897320010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897320020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200897320080",
  "CN": "2008 97 32",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897340080",
  "CN": "2008 97 34",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897360010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897360080",
  "CN": "2008 97 36",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897380080",
  "CN": "2008 97 38",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200897510020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200897510030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "En envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200897510080",
  "CN": "2008 97 51",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897590080",
  "CN": "2008 97 59",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897720010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897720020",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Mezclas en las que ningún fruto sea superior al 50 % en peso del total de los frutos presentados"
 },
 {
  "CNKEY": "200897720080",
  "CN": "2008 97 72",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897740080",
  "CN": "2008 97 74",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897760010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897760080",
  "CN": "2008 97 76",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897780080",
  "CN": "2008 97 78",
  "CN_LEVEL": 11,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897920010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200897920020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior o igual a 5 kg"
 },
 {
  "CNKEY": "200897920080",
  "CN": "2008 97 92",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897930080",
  "CN": "2008 97 93",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897940010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior o igual a 4,5 kg pero inferior a 5 kg"
 },
 {
  "CNKEY": "200897940080",
  "CN": "2008 97 94",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897960080",
  "CN": "2008 97 96",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200897970010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Inferior a 4,5 kg"
 },
 {
  "CNKEY": "200897970080",
  "CN": "2008 97 97",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "De frutos tropicales, incluidas las mezclas con un contenido de nueces tropicales y frutos tropicales superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "200897980080",
  "CN": "2008 97 98",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200899000080",
  "CN": "2008 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con alcohol añadido"
 },
 {
  "CNKEY": "200899110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Jengibre"
 },
 {
  "CNKEY": "200899110080",
  "CN": "2008 99 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200899190080",
  "CN": "2008 99 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Uvas"
 },
 {
  "CNKEY": "200899210080",
  "CN": "2008 99 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares superior al 13 % en peso"
 },
 {
  "CNKEY": "200899230080",
  "CN": "2008 99 23",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200899240010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899240020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares superior al 9 % en peso"
 },
 {
  "CNKEY": "200899240030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un contenido alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200899240080",
  "CN": "2008 99 24",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899280080",
  "CN": "2008 99 28",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899310010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899310080",
  "CN": "2008 99 31",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899340080",
  "CN": "2008 99 34",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899360010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899360020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un grado alcohólico másico adquirido inferior o igual a 11,85 % mas"
 },
 {
  "CNKEY": "200899360080",
  "CN": "2008 99 36",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899370080",
  "CN": "2008 99 37",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899380010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899380080",
  "CN": "2008 99 38",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899400080",
  "CN": "2008 99 40",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sin alcohol añadido"
 },
 {
  "CNKEY": "200899410020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto superior a 1 kg"
 },
 {
  "CNKEY": "200899410080",
  "CN": "2008 99 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Jengibre"
 },
 {
  "CNKEY": "200899430080",
  "CN": "2008 99 43",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Uvas"
 },
 {
  "CNKEY": "200899450080",
  "CN": "2008 99 45",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Ciruelas"
 },
 {
  "CNKEY": "200899480080",
  "CN": "2008 99 48",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899490080",
  "CN": "2008 99 49",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con azúcar añadido, en envases inmediatos con un contenido neto inferior o igual a 1 kg o menos"
 },
 {
  "CNKEY": "200899510080",
  "CN": "2008 99 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Jengibre"
 },
 {
  "CNKEY": "200899630080",
  "CN": "2008 99 63",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Frutos tropicales"
 },
 {
  "CNKEY": "200899670080",
  "CN": "2008 99 67",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200899720010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200899720020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Ciruelas, en envases inmediatos con un contenido neto"
 },
 {
  "CNKEY": "200899720080",
  "CN": "2008 99 72",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior o igual a 5 kg"
 },
 {
  "CNKEY": "200899780080",
  "CN": "2008 99 78",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Inferior a 5 kg"
 },
 {
  "CNKEY": "200899850080",
  "CN": "2008 99 85",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Maíz [excepto el maíz dulce (Zea mays var. saccharata)]"
 },
 {
  "CNKEY": "200899910080",
  "CN": "2008 99 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Ñames, batatas (boniatos) y partes comestibles similares de plantas con un contenido de almidón o de fécula superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "200899990080",
  "CN": "2008 99 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200900000080",
  "CN": "2009",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Jugos de frutas u otros frutos (incluido el mosto de uva y el agua de coco) o de hortalizas, sin fermentar y sin adición de alcohol, incluso con adición de azúcar u otro edulcorante"
 },
 {
  "CNKEY": "200911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de naranja"
 },
 {
  "CNKEY": "200911000080",
  "CN": "2009 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Congelado"
 },
 {
  "CNKEY": "200911110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200911110080",
  "CN": "2009 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200911190080",
  "CN": "2009 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200911910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 67"
 },
 {
  "CNKEY": "200911910080",
  "CN": "2009 11 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto y un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200911990080",
  "CN": "2009 11 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200912000080",
  "CN": "2009 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin congelar, de valor Brix inferior o igual a 20"
 },
 {
  "CNKEY": "200919000080",
  "CN": "2009 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200919110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200919110080",
  "CN": "2009 19 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200919190080",
  "CN": "2009 19 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200919910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200919910080",
  "CN": "2009 19 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto y con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200919980080",
  "CN": "2009 19 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de toronja; jugo de pomelo"
 },
 {
  "CNKEY": "200921000080",
  "CN": "2009 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De valor Brix inferior o igual a 20"
 },
 {
  "CNKEY": "200929000080",
  "CN": "2009 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200929110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200929110080",
  "CN": "2009 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200929190080",
  "CN": "2009 29 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200929910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200929910080",
  "CN": "2009 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200929990080",
  "CN": "2009 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de cualquier otro agrio (cítrico)"
 },
 {
  "CNKEY": "200931000080",
  "CN": "2009 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 20"
 },
 {
  "CNKEY": "200931110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200931110080",
  "CN": "2009 31 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200931190080",
  "CN": "2009 31 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200931510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200931510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Jugo de limón"
 },
 {
  "CNKEY": "200931510080",
  "CN": "2009 31 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200931590080",
  "CN": "2009 31 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200931910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Jugo de los demás agrios (cítricos)"
 },
 {
  "CNKEY": "200931910080",
  "CN": "2009 31 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200931990080",
  "CN": "2009 31 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200939000080",
  "CN": "2009 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200939110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200939110080",
  "CN": "2009 39 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200939190080",
  "CN": "2009 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200939310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200939310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200939310080",
  "CN": "2009 39 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200939390080",
  "CN": "2009 39 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200939510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200939510020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Jugo de limón"
 },
 {
  "CNKEY": "200939510080",
  "CN": "2009 39 51",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200939550080",
  "CN": "2009 39 55",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200939590080",
  "CN": "2009 39 59",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200939910010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Jugo de los demás agrios (cítricos)"
 },
 {
  "CNKEY": "200939910080",
  "CN": "2009 39 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200939950080",
  "CN": "2009 39 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200939990080",
  "CN": "2009 39 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de piña (ananá)"
 },
 {
  "CNKEY": "200941000080",
  "CN": "2009 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 20"
 },
 {
  "CNKEY": "200941920080",
  "CN": "2009 41 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200941990080",
  "CN": "2009 41 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200949000080",
  "CN": "2009 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200949110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200949110080",
  "CN": "2009 49 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200949190080",
  "CN": "2009 49 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200949300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200949300080",
  "CN": "2009 49 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "CNKEY": "200949910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200949910080",
  "CN": "2009 49 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200949930080",
  "CN": "2009 49 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200949990080",
  "CN": "2009 49 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200950000080",
  "CN": "2009 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de tomate"
 },
 {
  "CNKEY": "200950100080",
  "CN": "2009 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200950900080",
  "CN": "2009 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de uva (incluido el mosto)"
 },
 {
  "CNKEY": "200961000080",
  "CN": "2009 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 30"
 },
 {
  "CNKEY": "200961100080",
  "CN": "2009 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De valor superior a 18 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200961900080",
  "CN": "2009 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 18 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200969000080",
  "CN": "2009 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200969110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200969110080",
  "CN": "2009 69 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200969190080",
  "CN": "2009 69 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200969510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 30 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200969510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor superior a 18 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200969510080",
  "CN": "2009 69 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Concentrados"
 },
 {
  "CNKEY": "200969590080",
  "CN": "2009 69 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200969710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor inferior o igual a 18 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200969710020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200969710080",
  "CN": "2009 69 71",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Concentrado"
 },
 {
  "CNKEY": "200969790080",
  "CN": "2009 69 79",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200969900080",
  "CN": "2009 69 90",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200971000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de manzana"
 },
 {
  "CNKEY": "200971000080",
  "CN": "2009 71",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 20"
 },
 {
  "CNKEY": "200971200080",
  "CN": "2009 71 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200971990080",
  "CN": "2009 71 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200979000080",
  "CN": "2009 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200979110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200979110080",
  "CN": "2009 79 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200979190080",
  "CN": "2009 79 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200979300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 20 pero inferior o igual a 67"
 },
 {
  "CNKEY": "200979300080",
  "CN": "2009 79 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor superior a 18 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "CNKEY": "200979910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200979910080",
  "CN": "2009 79 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200979980080",
  "CN": "2009 79 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200981000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jugo de cualquier otra fruta o fruto u hortaliza"
 },
 {
  "CNKEY": "200981000080",
  "CN": "2009 81",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jugo de arándanos agrios, trepadores o palustres (Vaccinium macrocarpon, Vaccinium oxycoccos); jugo de arándanos rojos o encarnados (Vaccinium vitis-idaea)"
 },
 {
  "CNKEY": "200981110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200981110080",
  "CN": "2009 81 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200981190080",
  "CN": "2009 81 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200981310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 67"
 },
 {
  "CNKEY": "200981310080",
  "CN": "2009 81 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "CNKEY": "200981510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200981510080",
  "CN": "2009 81 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200981590080",
  "CN": "2009 81 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200981950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200981950080",
  "CN": "2009 81 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Jugo de fruta de la especie Vaccinium macrocarpon"
 },
 {
  "CNKEY": "200981990080",
  "CN": "2009 81 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989000080",
  "CN": "2009 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200989110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Jugo de peras"
 },
 {
  "CNKEY": "200989110080",
  "CN": "2009 89 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200989190080",
  "CN": "2009 89 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989340010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989340020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200989340080",
  "CN": "2009 89 34",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989350080",
  "CN": "2009 89 35",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989360010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989360080",
  "CN": "2009 89 36",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989380080",
  "CN": "2009 89 38",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 67"
 },
 {
  "CNKEY": "200989500020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Jugo de peras"
 },
 {
  "CNKEY": "200989500080",
  "CN": "2009 89 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De valor superior a 18 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "CNKEY": "200989610010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989610080",
  "CN": "2009 89 61",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200989630080",
  "CN": "2009 89 63",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200989690080",
  "CN": "2009 89 69",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200989710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989710020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De valor superior a 30 € por 100 kg de peso neto, con azúcar añadido"
 },
 {
  "CNKEY": "200989710080",
  "CN": "2009 89 71",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Jugo de cereza"
 },
 {
  "CNKEY": "200989730080",
  "CN": "2009 89 73",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989790080",
  "CN": "2009 89 79",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989850010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989850020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200989850080",
  "CN": "2009 89 85",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989860080",
  "CN": "2009 89 86",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989880010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200989880080",
  "CN": "2009 89 88",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989890080",
  "CN": "2009 89 89",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200989960010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadidos"
 },
 {
  "CNKEY": "200989960080",
  "CN": "2009 89 96",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Jugo de cereza"
 },
 {
  "CNKEY": "200989970080",
  "CN": "2009 89 97",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Jugo de frutos tropicales"
 },
 {
  "CNKEY": "200989990080",
  "CN": "2009 89 99",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "200990000080",
  "CN": "2009 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mezclas de jugos"
 },
 {
  "CNKEY": "200990110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix superior a 67"
 },
 {
  "CNKEY": "200990110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezclas de jugo de manzana y de pera"
 },
 {
  "CNKEY": "200990110080",
  "CN": "2009 90 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 22 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200990190080",
  "CN": "2009 90 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990210080",
  "CN": "2009 90 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200990290080",
  "CN": "2009 90 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De valor Brix inferior o igual a 67"
 },
 {
  "CNKEY": "200990310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezclas de jugo de manzana y de pera"
 },
 {
  "CNKEY": "200990310080",
  "CN": "2009 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De valor inferior o igual a 18 € por 100 kg de peso neto, con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200990390080",
  "CN": "2009 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990410020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor superior a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200990410030",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Mezclas de jugo de agrios (cítricos) y jugo de piña (ananá)"
 },
 {
  "CNKEY": "200990410080",
  "CN": "2009 90 41",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200990490080",
  "CN": "2009 90 49",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990510010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990510080",
  "CN": "2009 90 51",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con azúcar añadido"
 },
 {
  "CNKEY": "200990590080",
  "CN": "2009 90 59",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De valor inferior o igual a 30 € por 100 kg de peso neto"
 },
 {
  "CNKEY": "200990710020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Mezclas de jugo de agrios (cítricos) y jugo de piña (ananá)"
 },
 {
  "CNKEY": "200990710080",
  "CN": "2009 90 71",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200990730080",
  "CN": "2009 90 73",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200990790080",
  "CN": "2009 90 79",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200990920010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990920020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares añadidos superior al 30 % en peso"
 },
 {
  "CNKEY": "200990920080",
  "CN": "2009 90 92",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Mezclas de jugo de frutos tropicales"
 },
 {
  "CNKEY": "200990940080",
  "CN": "2009 90 94",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990950010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Con un contenido de azúcares añadidos inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "200990950080",
  "CN": "2009 90 95",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Mezclas de jugo de frutos tropicales"
 },
 {
  "CNKEY": "200990960080",
  "CN": "2009 90 96",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "200990970010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Sin azúcar añadido"
 },
 {
  "CNKEY": "200990970080",
  "CN": "2009 90 97",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Mezclas de jugo de frutos tropicales"
 },
 {
  "CNKEY": "200990980080",
  "CN": "2009 90 98",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "210021000090",
  "CN": "21",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 21 - PREPARACIONES ALIMENTICIAS DIVERSAS"
 },
 {
  "CNKEY": "210100000080",
  "CN": "2101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Extractos, esencias y concentrados de café, té o yerba mate y preparaciones a base de estos productos o a base de café, té o yerba mate; achicoria tostada y demás sucedáneos del café tostados y sus extractos, esencias y concentrados"
 },
 {
  "CNKEY": "210111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Extractos, esencias y concentrados de café y preparaciones a base de estos extractos, esencias o concentrados o a base de café"
 },
 {
  "CNKEY": "210111000080",
  "CN": "2101 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Extractos, esencias y concentrados"
 },
 {
  "CNKEY": "210112000080",
  "CN": "2101 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Preparaciones a base de extractos, esencias o concentrados o a base de café"
 },
 {
  "CNKEY": "210112920080",
  "CN": "2101 12 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de extractos, esencias o concentrados de café"
 },
 {
  "CNKEY": "210112980080",
  "CN": "2101 12 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "210120000080",
  "CN": "2101 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Extractos, esencias y concentrados de té o de yerba mate y preparaciones a base de estos extractos, esencias o concentrados o a base de té o de yerba mate"
 },
 {
  "CNKEY": "210120200080",
  "CN": "2101 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Extractos, esencias y concentrados"
 },
 {
  "CNKEY": "210120920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Preparaciones"
 },
 {
  "CNKEY": "210120920080",
  "CN": "2101 20 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de extractos, de esencias o de concentrados de té o yerba mate"
 },
 {
  "CNKEY": "210120980080",
  "CN": "2101 20 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210130000080",
  "CN": "2101 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Achicoria tostada y demás sucedáneos del café tostados y sus extractos, esencias y concentrados"
 },
 {
  "CNKEY": "210130110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Achicoria tostada y demás sucedáneos del café tostados"
 },
 {
  "CNKEY": "210130110080",
  "CN": "2101 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Achicoria tostada"
 },
 {
  "CNKEY": "210130190080",
  "CN": "2101 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210130910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Extractos, esencias y concentrados de achicoria tostada y demás sucedáneos del café tostados"
 },
 {
  "CNKEY": "210130910080",
  "CN": "2101 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De achicoria tostada"
 },
 {
  "CNKEY": "210130990080",
  "CN": "2101 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210200000080",
  "CN": "2102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Levaduras (vivas o muertas); los demás microorganismos monocelulares muertos (excepto las vacunas de la partida 3002); polvos preparados para esponjar masas"
 },
 {
  "CNKEY": "210210000080",
  "CN": "2102 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Levaduras vivas"
 },
 {
  "CNKEY": "210210100080",
  "CN": "2102 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Levaduras madres seleccionadas (levaduras de cultivo)"
 },
 {
  "CNKEY": "210210310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Levaduras para panificación"
 },
 {
  "CNKEY": "210210310080",
  "CN": "2102 10 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Secas"
 },
 {
  "CNKEY": "210210390080",
  "CN": "2102 10 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "210210900080",
  "CN": "2102 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "210220000080",
  "CN": "2102 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Levaduras muertas; los demás microorganismos monocelulares muertos"
 },
 {
  "CNKEY": "210220110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Levaduras muertas"
 },
 {
  "CNKEY": "210220110080",
  "CN": "2102 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En tabletas, cubos o presentaciones similares, o bien, en envases inmediatos con un contenido neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "210220190080",
  "CN": "2102 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "210220900080",
  "CN": "2102 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210230000080",
  "CN": "2102 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvos preparados para esponjar masas"
 },
 {
  "CNKEY": "210300000080",
  "CN": "2103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones para salsas y salsas preparadas; condimentos y sazonadores, compuestos; harina de mostaza y mostaza preparada"
 },
 {
  "CNKEY": "210310000080",
  "CN": "2103 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Salsa de soja (soya)"
 },
 {
  "CNKEY": "210320000080",
  "CN": "2103 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Kétchup y demás salsas de tomate"
 },
 {
  "CNKEY": "210330000080",
  "CN": "2103 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Harina de mostaza y mostaza preparada"
 },
 {
  "CNKEY": "210330100080",
  "CN": "2103 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Harina de mostaza"
 },
 {
  "CNKEY": "210330900080",
  "CN": "2103 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mostaza preparada"
 },
 {
  "CNKEY": "210390000080",
  "CN": "2103 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "210390100080",
  "CN": "2103 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chutney de mango, líquido"
 },
 {
  "CNKEY": "210390300080",
  "CN": "2103 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "l alc. 100%",
  "ES": "Amargos aromáticos de grado alcohólico volumétrico superior o igual a 44,2 % pero inferior o igual al 49,2 % vol, y con un contenido de gencianas, de especias y de ingredientes diversos superior o igual al 1,5 % pero inferior o igual al 6 % en peso, de azúcar superior o igual al 4 % pero inferior o igual al 10 % en peso, y que se presenten en recipientes de capacidad inferior o igual a 0,5 l"
 },
 {
  "CNKEY": "210390900080",
  "CN": "2103 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210400000080",
  "CN": "2104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones para sopas, potajes o caldos; sopas, potajes o caldos, preparados; preparaciones alimenticias compuestas homogeneizadas"
 },
 {
  "CNKEY": "210410000080",
  "CN": "2104 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para sopas, potajes o caldos; sopas, potajes o caldos, preparados"
 },
 {
  "CNKEY": "210420000080",
  "CN": "2104 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones alimenticias compuestas homogeneizadas"
 },
 {
  "CNKEY": "210500000080",
  "CN": "2105 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Helados, incluso con cacao"
 },
 {
  "CNKEY": "210500100080",
  "CN": "2105 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin materias grasas de la leche o con un contenido de materias grasas de la leche inferior al 3 % en peso"
 },
 {
  "CNKEY": "210500910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de materias grasas de la leche"
 },
 {
  "CNKEY": "210500910080",
  "CN": "2105 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Superior o igual al 3 % pero inferior al 7 % en peso"
 },
 {
  "CNKEY": "210500990080",
  "CN": "2105 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Superior o igual al 7 % en peso"
 },
 {
  "CNKEY": "210600000080",
  "CN": "2106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones alimenticias no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "210610000080",
  "CN": "2106 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Concentrados de proteínas y sustancias proteicas texturadas"
 },
 {
  "CNKEY": "210610200080",
  "CN": "2106 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con un contenido inferior al 5 % en peso"
 },
 {
  "CNKEY": "210610800080",
  "CN": "2106 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210690000080",
  "CN": "2106 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "210690200080",
  "CN": "2106 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "l alc. 100%",
  "ES": "Preparaciones alcohólicas compuestas (excepto las preparadas con sustancias aromáticas), del tipo de las utilizadas para la elaboración de bebidas"
 },
 {
  "CNKEY": "210690300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jarabes de azúcar aromatizados o con colorantes añadidos"
 },
 {
  "CNKEY": "210690300080",
  "CN": "2106 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De isoglucosa"
 },
 {
  "CNKEY": "210690510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "210690510080",
  "CN": "2106 90 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De lactosa"
 },
 {
  "CNKEY": "210690550080",
  "CN": "2106 90 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De glucosa o de maltodextrina"
 },
 {
  "CNKEY": "210690590080",
  "CN": "2106 90 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "210690920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "210690920080",
  "CN": "2106 90 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin materias grasas de la leche o con un contenido inferior al 1,5 % en peso, sin sacarosa o isoglucosa o con un contenido inferior al 5 % en peso, sin almidón o fécula o glucosa o con un contenido inferior al 5 % en peso"
 },
 {
  "CNKEY": "210690980080",
  "CN": "2106 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220021000090",
  "CN": "22",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 22 - BEBIDAS, LÍQUIDOS ALCOHÓLICOS Y VINAGRE"
 },
 {
  "CNKEY": "220100000080",
  "CN": "2201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Agua, incluidas el agua mineral natural o artificial y la gaseada, sin adición de azúcar u otro edulcorante ni aromatizada; hielo y nieve"
 },
 {
  "CNKEY": "220110000080",
  "CN": "2201 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Agua mineral y agua gaseada"
 },
 {
  "CNKEY": "220110110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Agua mineral natural"
 },
 {
  "CNKEY": "220110110080",
  "CN": "2201 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Sin dióxido de carbono"
 },
 {
  "CNKEY": "220110190080",
  "CN": "2201 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220110900080",
  "CN": "2201 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220190000080",
  "CN": "2201 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220200000080",
  "CN": "2202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Agua, incluidas el agua mineral y la gaseada, con adición de azúcar u otro edulcorante o aromatizada, y demás bebidas no alcohólicas, excepto los jugos de frutas u otros frutos o de hortalizas de la partida 2009"
 },
 {
  "CNKEY": "220210000080",
  "CN": "2202 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "l",
  "ES": "Agua, incluidas el agua mineral y la gaseada, con adición de azúcar u otro edulcorante o aromatizada"
 },
 {
  "CNKEY": "220291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "220291000080",
  "CN": "2202 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Cerveza sin alcohol"
 },
 {
  "CNKEY": "220299000080",
  "CN": "2202 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "220299110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que no contengan productos de las partidas 0401 a 0404 o materias grasas procedentes de dichos productos"
 },
 {
  "CNKEY": "220299110080",
  "CN": "2202 99 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Bebidas a base de soja con un contenido de proteínas en peso  igual o superior al 2,8 %"
 },
 {
  "CNKEY": "220299150080",
  "CN": "2202 99 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Bebidas a base de soja con un contenido de proteínas en peso inferior al 2,8 %; bebidas a base de frutos de cáscara del Capítulo 08, de cereales del Capítulo 10 o de semillas del Capítulo 12"
 },
 {
  "CNKEY": "220299190080",
  "CN": "2202 99 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220299910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás, con un contenido de materias grasas procedentes de los productos de las partidas 0401 a 0404"
 },
 {
  "CNKEY": "220299910080",
  "CN": "2202 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Inferior al 0,2 % en peso"
 },
 {
  "CNKEY": "220299950080",
  "CN": "2202 99 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Superior o igual al 0,2 % pero inferior al 2 % en peso"
 },
 {
  "CNKEY": "220299990080",
  "CN": "2202 99 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Superior o igual al 2 % en peso"
 },
 {
  "CNKEY": "220300000080",
  "CN": "2203 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cerveza de malta"
 },
 {
  "CNKEY": "220300010010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En recipientes de contenido inferior o igual a 10 l"
 },
 {
  "CNKEY": "220300010080",
  "CN": "2203 00 01",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "En botellas"
 },
 {
  "CNKEY": "220300090080",
  "CN": "2203 00 09",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220300100080",
  "CN": "2203 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "l",
  "ES": "En recipientes de contenido superior a 10 l"
 },
 {
  "CNKEY": "220400000080",
  "CN": "2204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vino de uvas frescas, incluso encabezado; mosto de uva, excepto el de la partida 2009"
 },
 {
  "CNKEY": "220410000080",
  "CN": "2204 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vino espumoso"
 },
 {
  "CNKEY": "220410110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "CNKEY": "220410110080",
  "CN": "2204 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Champán"
 },
 {
  "CNKEY": "220410130080",
  "CN": "2204 10 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Cava"
 },
 {
  "CNKEY": "220410150080",
  "CN": "2204 10 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Prosecco"
 },
 {
  "CNKEY": "220410910080",
  "CN": "2204 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Asti spumante"
 },
 {
  "CNKEY": "220410930080",
  "CN": "2204 10 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220410940080",
  "CN": "2204 10 94",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220410960080",
  "CN": "2204 10 96",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220410980080",
  "CN": "2204 10 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vinos; mosto de uva en el que la fermentación se ha impedido o cortado añadiendo alcohol"
 },
 {
  "CNKEY": "220421000080",
  "CN": "2204 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes con capacidad inferior o igual a 2 l"
 },
 {
  "CNKEY": "220421060010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Vino (excepto el de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "CNKEY": "220421060080",
  "CN": "2204 21 06",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "CNKEY": "220421070080",
  "CN": "2204 21 07",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220421080080",
  "CN": "2204 21 08",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220421090080",
  "CN": "2204 21 09",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Originarios de la Unión Europea"
 },
 {
  "CNKEY": "220421110030",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "CNKEY": "220421110040",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "CNKEY": "220421110050",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421110080",
  "CN": "2204 21 11",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Alsace (Alsacia)"
 },
 {
  "CNKEY": "220421120080",
  "CN": "2204 21 12",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Bordeaux (Burdeos)"
 },
 {
  "CNKEY": "220421130080",
  "CN": "2204 21 13",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Bourgogne (Borgoña)"
 },
 {
  "CNKEY": "220421170080",
  "CN": "2204 21 17",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Val de Loire (Valle del Loira)"
 },
 {
  "CNKEY": "220421180080",
  "CN": "2204 21 18",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Mosel"
 },
 {
  "CNKEY": "220421190080",
  "CN": "2204 21 19",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Pfalz"
 },
 {
  "CNKEY": "220421220080",
  "CN": "2204 21 22",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Rheinhessen"
 },
 {
  "CNKEY": "220421230080",
  "CN": "2204 21 23",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Tokaj"
 },
 {
  "CNKEY": "220421240080",
  "CN": "2204 21 24",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Lazio (Lacio)"
 },
 {
  "CNKEY": "220421260080",
  "CN": "2204 21 26",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Toscana"
 },
 {
  "CNKEY": "220421270080",
  "CN": "2204 21 27",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Trentino, Alto Adige y Friuli"
 },
 {
  "CNKEY": "220421280080",
  "CN": "2204 21 28",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Veneto"
 },
 {
  "CNKEY": "220421310080",
  "CN": "2204 21 31",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Sicilia"
 },
 {
  "CNKEY": "220421320080",
  "CN": "2204 21 32",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Vinho Verde"
 },
 {
  "CNKEY": "220421340080",
  "CN": "2204 21 34",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Penedès"
 },
 {
  "CNKEY": "220421360080",
  "CN": "2204 21 36",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Rioja"
 },
 {
  "CNKEY": "220421370080",
  "CN": "2204 21 37",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Valencia"
 },
 {
  "CNKEY": "220421380080",
  "CN": "2204 21 38",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421420010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421420080",
  "CN": "2204 21 42",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Bordeaux (Burdeos)"
 },
 {
  "CNKEY": "220421430080",
  "CN": "2204 21 43",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Bourgogne (Borgoña)"
 },
 {
  "CNKEY": "220421440080",
  "CN": "2204 21 44",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Beaujolais"
 },
 {
  "CNKEY": "220421460080",
  "CN": "2204 21 46",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "CNKEY": "220421470080",
  "CN": "2204 21 47",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "CNKEY": "220421480080",
  "CN": "2204 21 48",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Val de Loire (Valle del Loira)"
 },
 {
  "CNKEY": "220421610080",
  "CN": "2204 21 61",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Sicilia"
 },
 {
  "CNKEY": "220421620080",
  "CN": "2204 21 62",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Piemonte (Piamonte)"
 },
 {
  "CNKEY": "220421660080",
  "CN": "2204 21 66",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Toscana"
 },
 {
  "CNKEY": "220421670080",
  "CN": "2204 21 67",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Trentino y Alto Adige"
 },
 {
  "CNKEY": "220421680080",
  "CN": "2204 21 68",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Veneto"
 },
 {
  "CNKEY": "220421690080",
  "CN": "2204 21 69",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Dão, Bairrada y Douro (Duero)"
 },
 {
  "CNKEY": "220421710080",
  "CN": "2204 21 71",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Navarra"
 },
 {
  "CNKEY": "220421740080",
  "CN": "2204 21 74",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Penedès"
 },
 {
  "CNKEY": "220421760080",
  "CN": "2204 21 76",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Rioja"
 },
 {
  "CNKEY": "220421770080",
  "CN": "2204 21 77",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Valdepeñas"
 },
 {
  "CNKEY": "220421780080",
  "CN": "2204 21 78",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421790010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220421790080",
  "CN": "2204 21 79",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421800080",
  "CN": "2204 21 80",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421810010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220421810080",
  "CN": "2204 21 81",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421820080",
  "CN": "2204 21 82",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421830010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421830080",
  "CN": "2204 21 83",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421840080",
  "CN": "2204 21 84",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421850010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "CNKEY": "220421850020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220421850080",
  "CN": "2204 21 85",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "CNKEY": "220421860080",
  "CN": "2204 21 86",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Jerez"
 },
 {
  "CNKEY": "220421870080",
  "CN": "2204 21 87",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Marsala"
 },
 {
  "CNKEY": "220421880080",
  "CN": "2204 21 88",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "CNKEY": "220421890080",
  "CN": "2204 21 89",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Oporto"
 },
 {
  "CNKEY": "220421900080",
  "CN": "2204 21 90",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421910080",
  "CN": "2204 21 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421930020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220421930080",
  "CN": "2204 21 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421940080",
  "CN": "2204 21 94",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220421950080",
  "CN": "2204 21 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421960080",
  "CN": "2204 21 96",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421970010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220421970080",
  "CN": "2204 21 97",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220421980080",
  "CN": "2204 21 98",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422000080",
  "CN": "2204 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes con capacidad superior a 2 l pero inferior o igual a 10 l"
 },
 {
  "CNKEY": "220422100080",
  "CN": "2204 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Vino (excepto los de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "CNKEY": "220422220010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422220020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Originarios de la Unión Europea"
 },
 {
  "CNKEY": "220422220030",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "CNKEY": "220422220040",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "CNKEY": "220422220080",
  "CN": "2204 22 22",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Bordeaux (Burdeos)"
 },
 {
  "CNKEY": "220422230080",
  "CN": "2204 22 23",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Bourgogne (Borgoña)"
 },
 {
  "CNKEY": "220422240080",
  "CN": "2204 22 24",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Beaujolais"
 },
 {
  "CNKEY": "220422260080",
  "CN": "2204 22 26",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "CNKEY": "220422270080",
  "CN": "2204 22 27",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "CNKEY": "220422280080",
  "CN": "2204 22 28",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Val de Loire (Valle del Loira)"
 },
 {
  "CNKEY": "220422320080",
  "CN": "2204 22 32",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Piemonte (Piamonte)"
 },
 {
  "CNKEY": "220422330080",
  "CN": "2204 22 33",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Tokaj"
 },
 {
  "CNKEY": "220422380010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422380080",
  "CN": "2204 22 38",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422780080",
  "CN": "2204 22 78",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422790010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220422790080",
  "CN": "2204 22 79",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422800080",
  "CN": "2204 22 80",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422810010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220422810080",
  "CN": "2204 22 81",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422820080",
  "CN": "2204 22 82",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422830010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422830080",
  "CN": "2204 22 83",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422840080",
  "CN": "2204 22 84",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422850010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "CNKEY": "220422850020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220422850080",
  "CN": "2204 22 85",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "CNKEY": "220422860080",
  "CN": "2204 22 86",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Jerez"
 },
 {
  "CNKEY": "220422880080",
  "CN": "2204 22 88",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "CNKEY": "220422900080",
  "CN": "2204 22 90",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422910080",
  "CN": "2204 22 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422930020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220422930080",
  "CN": "2204 22 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422940080",
  "CN": "2204 22 94",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220422950080",
  "CN": "2204 22 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422960080",
  "CN": "2204 22 96",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422970010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220422970080",
  "CN": "2204 22 97",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220422980080",
  "CN": "2204 22 98",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429000080",
  "CN": "2204 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429100080",
  "CN": "2204 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Vino (excepto los de la subpartida 220410), en botellas con tapón en forma de champiñón sujeto por ataduras o ligaduras; vino que se presente de otra forma y tenga a 20 °C una sobrepresión, debida al anhídrido carbónico disuelto, superior o igual a 1 bar pero inferior a 3 bar"
 },
 {
  "CNKEY": "220429220010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429220020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Originarios de la Unión Europea"
 },
 {
  "CNKEY": "220429220030",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido inferior o igual al 15 % vol"
 },
 {
  "CNKEY": "220429220040",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP)"
 },
 {
  "CNKEY": "220429220080",
  "CN": "2204 29 22",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Bordeaux (Burdeos)"
 },
 {
  "CNKEY": "220429230080",
  "CN": "2204 29 23",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Bourgogne (Borgoña)"
 },
 {
  "CNKEY": "220429240080",
  "CN": "2204 29 24",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Beaujolais"
 },
 {
  "CNKEY": "220429260080",
  "CN": "2204 29 26",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vallée du Rhône (Valle del Ródano)"
 },
 {
  "CNKEY": "220429270080",
  "CN": "2204 29 27",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Languedoc-Roussillon (Languedoc-Rosellón)"
 },
 {
  "CNKEY": "220429280080",
  "CN": "2204 29 28",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Val de Loire (Valle del Loira)"
 },
 {
  "CNKEY": "220429320080",
  "CN": "2204 29 32",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Piemonte (Piamonte)"
 },
 {
  "CNKEY": "220429380010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429380080",
  "CN": "2204 29 38",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429780080",
  "CN": "2204 29 78",
  "CN_LEVEL": 11,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429790010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220429790080",
  "CN": "2204 29 79",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429800080",
  "CN": "2204 29 80",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429810010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220429810080",
  "CN": "2204 29 81",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429820080",
  "CN": "2204 29 82",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429830010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429830080",
  "CN": "2204 29 83",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429840080",
  "CN": "2204 29 84",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429850010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De grado alcohólico adquirido superior a 15 % vol"
 },
 {
  "CNKEY": "220429850020",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220429850080",
  "CN": "2204 29 85",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Madeira y moscatel de Setúbal"
 },
 {
  "CNKEY": "220429860080",
  "CN": "2204 29 86",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Jerez"
 },
 {
  "CNKEY": "220429880080",
  "CN": "2204 29 88",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Vino de Samos y moscatel de Lemnos"
 },
 {
  "CNKEY": "220429900080",
  "CN": "2204 29 90",
  "CN_LEVEL": 10,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429910080",
  "CN": "2204 29 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429930020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Vinos con denominación de origen protegida (DOP) o vinos con indicación geográfica protegida (IGP)"
 },
 {
  "CNKEY": "220429930080",
  "CN": "2204 29 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429940080",
  "CN": "2204 29 94",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Otros vinos de variedades"
 },
 {
  "CNKEY": "220429950080",
  "CN": "2204 29 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429960080",
  "CN": "2204 29 96",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429970010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220429970080",
  "CN": "2204 29 97",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Vino blanco"
 },
 {
  "CNKEY": "220429980080",
  "CN": "2204 29 98",
  "CN_LEVEL": 9,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220430000080",
  "CN": "2204 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás mostos de uva"
 },
 {
  "CNKEY": "220430100080",
  "CN": "2204 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Parcialmente fermentados, incluso «apagados», sin utilización del alcohol"
 },
 {
  "CNKEY": "220430920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220430920020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De masa volúmica inferior o igual a 1,33 g\/cm³ a 20 °C y de grado alcohólico adquirido inferior o igual a 1 % vol"
 },
 {
  "CNKEY": "220430920080",
  "CN": "2204 30 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Concentrados"
 },
 {
  "CNKEY": "220430940080",
  "CN": "2204 30 94",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220430960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220430960080",
  "CN": "2204 30 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Concentrados"
 },
 {
  "CNKEY": "220430980080",
  "CN": "2204 30 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220500000080",
  "CN": "2205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vermut y demás vinos de uvas frescas preparados con plantas o sustancias aromáticas"
 },
 {
  "CNKEY": "220510000080",
  "CN": "2205 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En recipientes con capacidad inferior o igual a 2 l"
 },
 {
  "CNKEY": "220510100080",
  "CN": "2205 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "De grado alcohólico adquirido inferior o igual a 18 % vol"
 },
 {
  "CNKEY": "220510900080",
  "CN": "2205 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "De grado alcohólico adquirido superior a 18 % vol"
 },
 {
  "CNKEY": "220590000080",
  "CN": "2205 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220590100080",
  "CN": "2205 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "De grado alcohólico adquirido inferior o igual a 18 % vol"
 },
 {
  "CNKEY": "220590900080",
  "CN": "2205 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "De grado alcohólico adquirido superior a 18 % vol"
 },
 {
  "CNKEY": "220600000080",
  "CN": "2206 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás bebidas fermentadas (por ejemplo: sidra, perada, aguamiel, sake); mezclas de bebidas fermentadas y mezclas de bebidas fermentadas y bebidas no alcohólicas, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "220600100080",
  "CN": "2206 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "l",
  "ES": "Piquetas"
 },
 {
  "CNKEY": "220600310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "220600310020",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Espumosas"
 },
 {
  "CNKEY": "220600310080",
  "CN": "2206 00 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Sidra y perada"
 },
 {
  "CNKEY": "220600390080",
  "CN": "2206 00 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220600510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "No espumosas, en recipientes de contenido"
 },
 {
  "CNKEY": "220600510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220600510080",
  "CN": "2206 00 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Sidra y perada"
 },
 {
  "CNKEY": "220600590080",
  "CN": "2206 00 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220600810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220600810080",
  "CN": "2206 00 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Sidra y perada"
 },
 {
  "CNKEY": "220600890080",
  "CN": "2206 00 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "l",
  "ES": "Las demás"
 },
 {
  "CNKEY": "220700000080",
  "CN": "2207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico superior o igual al 80 % vol.; alcohol etílico y aguardiente desnaturalizados, de cualquier graduación"
 },
 {
  "CNKEY": "220710000080",
  "CN": "2207 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "l",
  "ES": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico superior o igual al 80 % vol"
 },
 {
  "CNKEY": "220720000080",
  "CN": "2207 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "l",
  "ES": "Alcohol etílico y aguardiente desnaturalizados, de cualquier graduación"
 },
 {
  "CNKEY": "220800000080",
  "CN": "2208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alcohol etílico sin desnaturalizar con grado alcohólico volumétrico inferior al 80 % vol.; aguardientes, licores y demás bebidas espirituosas"
 },
 {
  "CNKEY": "220820000080",
  "CN": "2208 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aguardiente de vino o de orujo de uvas"
 },
 {
  "CNKEY": "220820120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "CNKEY": "220820120020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aguardiente de vino"
 },
 {
  "CNKEY": "220820120080",
  "CN": "2208 20 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Cognac"
 },
 {
  "CNKEY": "220820140080",
  "CN": "2208 20 14",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Armagnac"
 },
 {
  "CNKEY": "220820160010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Brandy o Weinbrand"
 },
 {
  "CNKEY": "220820160080",
  "CN": "2208 20 16",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "Brandy de Jerez"
 },
 {
  "CNKEY": "220820180080",
  "CN": "2208 20 18",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220820190080",
  "CN": "2208 20 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220820260010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aguardiente de orujo de uva"
 },
 {
  "CNKEY": "220820260080",
  "CN": "2208 20 26",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Grappa"
 },
 {
  "CNKEY": "220820280080",
  "CN": "2208 20 28",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220820620010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes de contenido superior a 2 l"
 },
 {
  "CNKEY": "220820620020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aguardiente de vino"
 },
 {
  "CNKEY": "220820620080",
  "CN": "2208 20 62",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Cognac"
 },
 {
  "CNKEY": "220820660080",
  "CN": "2208 20 66",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Brandy o Weinbrand"
 },
 {
  "CNKEY": "220820690080",
  "CN": "2208 20 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220820860010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aguardiente de orujo de uva"
 },
 {
  "CNKEY": "220820860080",
  "CN": "2208 20 86",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Grappa"
 },
 {
  "CNKEY": "220820880080",
  "CN": "2208 20 88",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220830000080",
  "CN": "2208 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Whisky"
 },
 {
  "CNKEY": "220830110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Whisky Bourbon, en recipientes de contenido"
 },
 {
  "CNKEY": "220830110080",
  "CN": "2208 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220830190080",
  "CN": "2208 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220830300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Whisky Scotch"
 },
 {
  "CNKEY": "220830300080",
  "CN": "2208 30 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Whisky de malta «single malt»"
 },
 {
  "CNKEY": "220830410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Whisky de malta «blended», en recipientes de contenido"
 },
 {
  "CNKEY": "220830410080",
  "CN": "2208 30 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220830490080",
  "CN": "2208 30 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220830610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Whisky de grano «single grain» y «blended», en recipientes de contenido"
 },
 {
  "CNKEY": "220830610080",
  "CN": "2208 30 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220830690080",
  "CN": "2208 30 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220830710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Whisky «blended» de otro tipo, en recipientes de contenido"
 },
 {
  "CNKEY": "220830710080",
  "CN": "2208 30 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220830790080",
  "CN": "2208 30 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220830820010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, en recipientes de contenido"
 },
 {
  "CNKEY": "220830820080",
  "CN": "2208 30 82",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220830880080",
  "CN": "2208 30 88",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220840000080",
  "CN": "2208 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ron y demás aguardientes procedentes de la destilación, previa fermentación, de productos de la caña de azúcar"
 },
 {
  "CNKEY": "220840110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "CNKEY": "220840110080",
  "CN": "2208 40 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Ron con un contenido en sustancias volátiles distintas del alcohol etílico y del alcohol metílico superior o igual a 225 g por hectolitro de alcohol puro (con una tolerancia del 10 %)"
 },
 {
  "CNKEY": "220840310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220840310080",
  "CN": "2208 40 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "De valor superior a 7,9 € por l de alcohol puro"
 },
 {
  "CNKEY": "220840390080",
  "CN": "2208 40 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220840510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En recipientes de contenido superior a 2 l"
 },
 {
  "CNKEY": "220840510080",
  "CN": "2208 40 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Ron con un contenido en sustancias volátiles distintas del alcohol etílico y del alcohol metílico superior o igual a 225 g por hectolitro de alcohol puro (con una tolerancia del 10 %)"
 },
 {
  "CNKEY": "220840910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220840910080",
  "CN": "2208 40 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "De valor superior a 2 € por l de alcohol puro"
 },
 {
  "CNKEY": "220840990080",
  "CN": "2208 40 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220850000080",
  "CN": "2208 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gin y ginebra"
 },
 {
  "CNKEY": "220850110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Gin, en recipientes de contenido"
 },
 {
  "CNKEY": "220850110080",
  "CN": "2208 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220850190080",
  "CN": "2208 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220850910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Ginebra, en recipientes de contenido"
 },
 {
  "CNKEY": "220850910080",
  "CN": "2208 50 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220850990080",
  "CN": "2208 50 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220860000080",
  "CN": "2208 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vodka"
 },
 {
  "CNKEY": "220860110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con grado alcohólico volumétrico inferior o igual a 45,4 % vol., en recipientes de contenido"
 },
 {
  "CNKEY": "220860110080",
  "CN": "2208 60 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220860190080",
  "CN": "2208 60 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220860910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De grado alcohólico volumétrico superior a 45,4 % vol., en recipientes de contenido"
 },
 {
  "CNKEY": "220860910080",
  "CN": "2208 60 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220860990080",
  "CN": "2208 60 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220870000080",
  "CN": "2208 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Licores"
 },
 {
  "CNKEY": "220870100080",
  "CN": "2208 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "l alc. 100%",
  "ES": "En recipientes de contenido inferior o igual a 2 l"
 },
 {
  "CNKEY": "220870900080",
  "CN": "2208 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "l alc. 100%",
  "ES": "En recipientes de contenido superior a 2 l"
 },
 {
  "CNKEY": "220890000080",
  "CN": "2208 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Arak, en recipientes de contenido"
 },
 {
  "CNKEY": "220890110080",
  "CN": "2208 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220890190080",
  "CN": "2208 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220890330010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aguardientes de ciruelas, de peras o de cerezas, en recipientes de contenido"
 },
 {
  "CNKEY": "220890330080",
  "CN": "2208 90 33",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220890380080",
  "CN": "2208 90 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220890410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás aguardientes y demás bebidas espirituosas, en recipientes de contenido"
 },
 {
  "CNKEY": "220890410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220890410080",
  "CN": "2208 90 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Ouzo"
 },
 {
  "CNKEY": "220890450010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890450020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Aguardientes"
 },
 {
  "CNKEY": "220890450030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "De frutas"
 },
 {
  "CNKEY": "220890450080",
  "CN": "2208 90 45",
  "CN_LEVEL": 10,
  "T_SU_SU": "l alc. 100%",
  "ES": "Calvados"
 },
 {
  "CNKEY": "220890480080",
  "CN": "2208 90 48",
  "CN_LEVEL": 10,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890540010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890540080",
  "CN": "2208 90 54",
  "CN_LEVEL": 10,
  "T_SU_SU": "l alc. 100%",
  "ES": "Tequila"
 },
 {
  "CNKEY": "220890560080",
  "CN": "2208 90 56",
  "CN_LEVEL": 10,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890690080",
  "CN": "2208 90 69",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "Las demás bebidas espirituosas"
 },
 {
  "CNKEY": "220890710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220890710020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Aguardientes"
 },
 {
  "CNKEY": "220890710080",
  "CN": "2208 90 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "De frutas"
 },
 {
  "CNKEY": "220890750080",
  "CN": "2208 90 75",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "Tequila"
 },
 {
  "CNKEY": "220890770080",
  "CN": "2208 90 77",
  "CN_LEVEL": 8,
  "T_SU_SU": "l alc. 100%",
  "ES": "Los demás"
 },
 {
  "CNKEY": "220890780080",
  "CN": "2208 90 78",
  "CN_LEVEL": 7,
  "T_SU_SU": "l alc. 100%",
  "ES": "Otras bebidas espirituosas"
 },
 {
  "CNKEY": "220890910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Alcohol etílico sin desnaturalizar de grado alcohólico volumétrico inferior a 80 % vol., en recipientes de contenido"
 },
 {
  "CNKEY": "220890910080",
  "CN": "2208 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220890990080",
  "CN": "2208 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "l alc. 100%",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220900000080",
  "CN": "2209 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vinagre y sucedáneos del vinagre obtenidos a partir del ácido acético"
 },
 {
  "CNKEY": "220900110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vinagre de vino, en recipientes de contenido"
 },
 {
  "CNKEY": "220900110080",
  "CN": "2209 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220900190080",
  "CN": "2209 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "220900910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, en recipientes de contenido"
 },
 {
  "CNKEY": "220900910080",
  "CN": "2209 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Inferior o igual a 2 l"
 },
 {
  "CNKEY": "220900990080",
  "CN": "2209 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "l",
  "ES": "Superior a 2 l"
 },
 {
  "CNKEY": "230021000090",
  "CN": "23",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 23 - RESIDUOS Y DESPERDICIOS DE LAS INDUSTRIAS ALIMENTARIAS; ALIMENTOS PREPARADOS PARA ANIMALES"
 },
 {
  "CNKEY": "230100000080",
  "CN": "2301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Harina, polvo y pellets, de carne, despojos, pescado o de crustáceos, moluscos o demás invertebrados acuáticos, impropios para la alimentación humana; chicharrones"
 },
 {
  "CNKEY": "230110000080",
  "CN": "2301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Harina, polvo y pellets, de carne o despojos; chicharrones"
 },
 {
  "CNKEY": "230120000080",
  "CN": "2301 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Harina, polvo y pellets, de pescado o de crustáceos, moluscos o demás invertebrados acuáticos"
 },
 {
  "CNKEY": "230200000080",
  "CN": "2302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Salvados, moyuelos y demás residuos del cernido, de la molienda o de otros tratamientos de los cereales o de las leguminosas, incluso en pellets"
 },
 {
  "CNKEY": "230210000080",
  "CN": "2302 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De maíz"
 },
 {
  "CNKEY": "230210100080",
  "CN": "2302 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón inferior o igual al 35 % en peso"
 },
 {
  "CNKEY": "230210900080",
  "CN": "2302 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230230000080",
  "CN": "2302 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De trigo"
 },
 {
  "CNKEY": "230230100080",
  "CN": "2302 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón inferior o igual al 28 % en peso, si la proporción de producto que pase por un tamiz de 0,2 mm de anchura de malla es inferior o igual al 10 % en peso o, en caso contrario, si el producto que pase por el tamiz tiene un contenido de cenizas, calculado sobre materia seca, superior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "230230900080",
  "CN": "2302 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230240000080",
  "CN": "2302 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los demás cereales"
 },
 {
  "CNKEY": "230240020010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De arroz"
 },
 {
  "CNKEY": "230240020080",
  "CN": "2302 40 02",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón inferior o igual al 35 % en peso"
 },
 {
  "CNKEY": "230240080080",
  "CN": "2302 40 08",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230240100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "230240100080",
  "CN": "2302 40 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón inferior o igual al 28 % en peso, si la proporción de producto que pase por un tamiz de 0,2 mm de anchura de malla es inferior o igual al 10 % en peso o, en caso contrario, si el producto que pase por el tamiz tiene un contenido de cenizas, calculado sobre materia seca, superior o igual al 1,5 % en peso"
 },
 {
  "CNKEY": "230240900080",
  "CN": "2302 40 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230250000080",
  "CN": "2302 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De leguminosas"
 },
 {
  "CNKEY": "230300000080",
  "CN": "2303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Residuos de la industria del almidón y residuos similares, pulpa de remolacha, bagazo de caña de azúcar y demás desperdicios de la industria azucarera, heces y desperdicios de cervecería o de destilería, incluso en «pellets»"
 },
 {
  "CNKEY": "230310000080",
  "CN": "2303 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Residuos de la industria del almidón y residuos similares"
 },
 {
  "CNKEY": "230310110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Residuos de la industria del almidón de maíz (excepto los de las aguas de remojo concentradas), con un contenido de proteínas, calculado sobre extracto seco"
 },
 {
  "CNKEY": "230310110080",
  "CN": "2303 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 40 % en peso"
 },
 {
  "CNKEY": "230310190080",
  "CN": "2303 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inferior o igual al 40 % en peso"
 },
 {
  "CNKEY": "230310900080",
  "CN": "2303 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230320000080",
  "CN": "2303 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pulpa de remolacha, bagazo de caña de azúcar y demás desperdicios de la industria azucarera"
 },
 {
  "CNKEY": "230320100080",
  "CN": "2303 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pulpa de remolacha"
 },
 {
  "CNKEY": "230320900080",
  "CN": "2303 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230330000080",
  "CN": "2303 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Heces y desperdicios de cervecería o de destilería"
 },
 {
  "CNKEY": "230400000080",
  "CN": "2304 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tortas y demás residuos sólidos de la extracción del aceite de soja (soya), incluso molidos o en «pellets»"
 },
 {
  "CNKEY": "230500000080",
  "CN": "2305 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tortas y demás residuos sólidos de la extracción del aceite de cacahuate (cacahuete, maní), incluso molidos o en «pellets»"
 },
 {
  "CNKEY": "230600000080",
  "CN": "2306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tortas y demás residuos sólidos de la extracción de grasas o aceites, vegetales o de origen microbiano, incluso molidos o en «pellets», excepto los de las partidas 2304 o 2305"
 },
 {
  "CNKEY": "230610000080",
  "CN": "2306 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De semillas de algodón"
 },
 {
  "CNKEY": "230620000080",
  "CN": "2306 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De semillas de lino"
 },
 {
  "CNKEY": "230630000080",
  "CN": "2306 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De semillas de girasol"
 },
 {
  "CNKEY": "230641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De semillas de nabo (nabina) o de colza"
 },
 {
  "CNKEY": "230641000080",
  "CN": "2306 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con bajo contenido de ácido erúcico"
 },
 {
  "CNKEY": "230649000080",
  "CN": "2306 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230650000080",
  "CN": "2306 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De coco o de copra"
 },
 {
  "CNKEY": "230660000080",
  "CN": "2306 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De nuez o de almendra de palma"
 },
 {
  "CNKEY": "230690000080",
  "CN": "2306 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "230690050080",
  "CN": "2306 90 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De germen de maíz"
 },
 {
  "CNKEY": "230690110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "230690110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Orujo de aceitunas y demás residuos de la extracción del aceite de oliva"
 },
 {
  "CNKEY": "230690110080",
  "CN": "2306 90 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de aceite de oliva inferior o igual al 3 % en peso"
 },
 {
  "CNKEY": "230690190080",
  "CN": "2306 90 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de aceite de oliva superior al 3 % en peso"
 },
 {
  "CNKEY": "230690900080",
  "CN": "2306 90 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230700000080",
  "CN": "2307 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lías o heces de vino; tártaro bruto"
 },
 {
  "CNKEY": "230700110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lías de vino"
 },
 {
  "CNKEY": "230700110080",
  "CN": "2307 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico total inferior o igual al 7,9 % mas y con un contenido de materia seca superior o igual al 25 % en peso"
 },
 {
  "CNKEY": "230700190080",
  "CN": "2307 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230700900080",
  "CN": "2307 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tártaro bruto"
 },
 {
  "CNKEY": "230800000080",
  "CN": "2308 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Materias vegetales y desperdicios vegetales, residuos y subproductos vegetales, incluso en «pellets», de los tipos utilizados para la alimentación de los animales, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "230800110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Orujo de uvas"
 },
 {
  "CNKEY": "230800110080",
  "CN": "2308 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un grado alcohólico total inferior o igual al 4,3 % mas y un contenido de materia seca superior o igual al 40 % en peso"
 },
 {
  "CNKEY": "230800190080",
  "CN": "2308 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230800400080",
  "CN": "2308 00 40",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bellotas y castañas de Indias; orujo de frutos (excepto el de uvas)"
 },
 {
  "CNKEY": "230800900080",
  "CN": "2308 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230900000080",
  "CN": "2309",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones del tipo de las utilizadas para la alimentación de los animales"
 },
 {
  "CNKEY": "230910000080",
  "CN": "2309 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alimentos para perros o gatos, acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "230910110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con almidón, fécula, glucosa o jarabe de glucosa, maltodextrina o jarabe de maltodextrina, de las subpartidas 17023050, 17023090, 17024090, 17029050 y 21069055, o productos lácteos"
 },
 {
  "CNKEY": "230910110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que contengan almidón, fécula, glucosa, maltodextrina o jarabe de glucosa o de maltodextrina"
 },
 {
  "CNKEY": "230910110030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin almidón ni fécula o con un contenido de estas materias inferior o igual al 10 % en peso"
 },
 {
  "CNKEY": "230910110080",
  "CN": "2309 10 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230910130080",
  "CN": "2309 10 13",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230910150080",
  "CN": "2309 10 15",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % pero inferior al 75 % en peso"
 },
 {
  "CNKEY": "230910190080",
  "CN": "2309 10 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 75 % en peso"
 },
 {
  "CNKEY": "230910310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de almidón o de fécula superior al 10 % pero inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "230910310080",
  "CN": "2309 10 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230910330080",
  "CN": "2309 10 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230910390080",
  "CN": "2309 10 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "230910510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con un contenido de almidón o de fécula superior al 30 % en peso"
 },
 {
  "CNKEY": "230910510080",
  "CN": "2309 10 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230910530080",
  "CN": "2309 10 53",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230910590080",
  "CN": "2309 10 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "230910700080",
  "CN": "2309 10 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin almidón, féculas, glucosa o jarabe de glucosa, maltodextrina, ni jarabe de maltodextrina, pero que contengan productos lácteos"
 },
 {
  "CNKEY": "230910900080",
  "CN": "2309 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "230990000080",
  "CN": "2309 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "230990100080",
  "CN": "2309 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Productos llamados «solubles» de pescado o de mamíferos marinos"
 },
 {
  "CNKEY": "230990200080",
  "CN": "2309 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Contemplados en la Nota complementaria 5 del presente Capítulo"
 },
 {
  "CNKEY": "230990310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las premezclas"
 },
 {
  "CNKEY": "230990310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con almidón, fécula, glucosa o jarabe de glucosa, maltodextrina o jarabe de maltodextrina, de las subpartidas 17023050, 17023090, 17024090, 17029050 y 21069055, o productos lácteos"
 },
 {
  "CNKEY": "230990310030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Que contengan almidón, fécula, glucosa, maltodextrina o jarabes de glucosa o maltodextrina"
 },
 {
  "CNKEY": "230990310040",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Sin almidón ni fécula o con un contenido de estas materias inferior o igual al 10 % en peso"
 },
 {
  "CNKEY": "230990310080",
  "CN": "2309 90 31",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230990330080",
  "CN": "2309 90 33",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230990350080",
  "CN": "2309 90 35",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % pero inferior al 75 % en peso"
 },
 {
  "CNKEY": "230990390080",
  "CN": "2309 90 39",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 75 % en peso"
 },
 {
  "CNKEY": "230990410010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de almidón o de fécula superior al 10 % pero inferior o igual al 30 % en peso"
 },
 {
  "CNKEY": "230990410080",
  "CN": "2309 90 41",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230990430080",
  "CN": "2309 90 43",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230990490080",
  "CN": "2309 90 49",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "230990510010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con un contenido de almidón o de fécula superior al 30 % en peso"
 },
 {
  "CNKEY": "230990510080",
  "CN": "2309 90 51",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Sin productos lácteos o con un contenido de estos productos inferior al 10 % en peso"
 },
 {
  "CNKEY": "230990530080",
  "CN": "2309 90 53",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 10 % pero inferior al 50 % en peso"
 },
 {
  "CNKEY": "230990590080",
  "CN": "2309 90 59",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de productos lácteos superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "230990700080",
  "CN": "2309 90 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin almidón, fécula, glucosa o jarabe de glucosa, maltodextrina ni jarabe de maltodextrina, pero que contengan productos lácteos"
 },
 {
  "CNKEY": "230990910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "230990910080",
  "CN": "2309 90 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pulpa de remolacha con melaza añadida"
 },
 {
  "CNKEY": "230990960080",
  "CN": "2309 90 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240021000090",
  "CN": "24",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 24 - TABACO Y SUCEDÁNEOS DEL TABACO ELABORADOS; PRODUCTOS, INCLUSO CON NICOTINA, DESTINADOS PARA LA INHALACIÓN SIN COMBUSTIÓN; OTROS PRODUCTOS QUE CONTENGAN NICOTINA DESTINADOS PARA LA ABSORCIÓN DE NICOTINA EN EL CUERPO HUMANO"
 },
 {
  "CNKEY": "240100000080",
  "CN": "2401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tabaco en rama o sin elaborar; desperdicios de tabaco"
 },
 {
  "CNKEY": "240110000080",
  "CN": "2401 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tabaco sin desvenar o desnervar"
 },
 {
  "CNKEY": "240110350080",
  "CN": "2401 10 35",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco light air-cured"
 },
 {
  "CNKEY": "240110600080",
  "CN": "2401 10 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco sun-cured del tipo oriental"
 },
 {
  "CNKEY": "240110700080",
  "CN": "2401 10 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco dark air-cured"
 },
 {
  "CNKEY": "240110850080",
  "CN": "2401 10 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco flue-cured"
 },
 {
  "CNKEY": "240110950080",
  "CN": "2401 10 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240120000080",
  "CN": "2401 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tabaco total o parcialmente desvenado o desnervado"
 },
 {
  "CNKEY": "240120350080",
  "CN": "2401 20 35",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco light air-cured"
 },
 {
  "CNKEY": "240120600080",
  "CN": "2401 20 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco sun-cured del tipo oriental"
 },
 {
  "CNKEY": "240120700080",
  "CN": "2401 20 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco dark air-cured"
 },
 {
  "CNKEY": "240120850080",
  "CN": "2401 20 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco flue-cured"
 },
 {
  "CNKEY": "240120950080",
  "CN": "2401 20 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240130000080",
  "CN": "2401 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios de tabaco"
 },
 {
  "CNKEY": "240200000080",
  "CN": "2402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cigarros (puros), incluso despuntados, cigarritos (puritos) y cigarrillos, de tabaco o de sucedáneos del tabaco"
 },
 {
  "CNKEY": "240210000080",
  "CN": "2402 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "1000 p\/st",
  "ES": "Cigarros (puros), incluso despuntados, y cigarritos (puritos), que contengan tabaco"
 },
 {
  "CNKEY": "240220000080",
  "CN": "2402 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cigarrillos que contengan tabaco"
 },
 {
  "CNKEY": "240220100080",
  "CN": "2402 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "1000 p\/st",
  "ES": "Que contengan clavo"
 },
 {
  "CNKEY": "240220900080",
  "CN": "2402 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "1000 p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240290000080",
  "CN": "2402 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240300000080",
  "CN": "2403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tabacos y sucedáneos del tabaco, elaborados; tabaco «homogeneizado» o «reconstituido»; extractos y jugos de tabaco"
 },
 {
  "CNKEY": "240311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tabaco para fumar, incluso con sucedáneos de tabaco en cualquier proporción"
 },
 {
  "CNKEY": "240311000080",
  "CN": "2403 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco para pipa de agua mencionado en la Nota 1 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "240319000080",
  "CN": "2403 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "240319100080",
  "CN": "2403 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En envases inmediatos de contenido neto inferior o igual a 500 g"
 },
 {
  "CNKEY": "240319900080",
  "CN": "2403 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "240391000080",
  "CN": "2403 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tabaco «homogeneizado» o «reconstituido»"
 },
 {
  "CNKEY": "240399000080",
  "CN": "2403 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "240399100080",
  "CN": "2403 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tabaco de mascar y rapé (tabaco de uso nasal)"
 },
 {
  "CNKEY": "240399900080",
  "CN": "2403 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240400000080",
  "CN": "2404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos que contengan tabaco, tabaco reconstituido, nicotina o sucedáneos del tabaco o de nicotina, destinados para la inhalación sin combustión; otros productos que contengan nicotina destinados para la absorción de nicotina en el cuerpo humano"
 },
 {
  "CNKEY": "240411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos destinados para la inhalación sin combustión"
 },
 {
  "CNKEY": "240411000080",
  "CN": "2404 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan tabaco o tabaco reconstituido"
 },
 {
  "CNKEY": "240412000080",
  "CN": "2404 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan nicotina"
 },
 {
  "CNKEY": "240419000080",
  "CN": "2404 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "240419100080",
  "CN": "2404 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que contengan sucedáneos de tabaco"
 },
 {
  "CNKEY": "240419900080",
  "CN": "2404 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "240491000080",
  "CN": "2404 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para administrarse por vía oral"
 },
 {
  "CNKEY": "240491100080",
  "CN": "2404 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Productos que contienen nicotina destinados a asistir en el abandono del tabaco"
 },
 {
  "CNKEY": "240491900080",
  "CN": "2404 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "240492000080",
  "CN": "2404 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para administrarse por vía transdérmica"
 },
 {
  "CNKEY": "240499000080",
  "CN": "2404 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "250011000090",
  "CN": "V",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN V - PRODUCTOS MINERALES"
 },
 {
  "CNKEY": "250021000090",
  "CN": "25",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 25 - SAL; AZUFRE; TIERRAS Y PIEDRAS; YESOS, CALES Y CEMENTOS"
 },
 {
  "CNKEY": "250100000080",
  "CN": "2501 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sal (incluidas la de mesa y la desnaturalizada), y cloruro de sodio puro, incluso en disolución acuosa o con adición de antiaglomerantes o de agentes que garanticen una buena fluidez; agua de mar"
 },
 {
  "CNKEY": "250100100080",
  "CN": "2501 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Agua de mar y agua madre de salinas"
 },
 {
  "CNKEY": "250100310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sal, incluidas la de mesa y la desnaturalizada, y cloruro de sodio puro, incluso en disolución acuosa o con adición de antiaglomerantes o de agentes que garanticen una buena fluidez"
 },
 {
  "CNKEY": "250100310080",
  "CN": "2501 00 31",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química (separación de Na y Cl) para la fabricación de otros productos"
 },
 {
  "CNKEY": "250100510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "250100510080",
  "CN": "2501 00 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desnaturalizados o para otros usos industriales, incluido el refinado (excepto la conservación o preparación de productos para la alimentación humana o animal)"
 },
 {
  "CNKEY": "250100910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "250100910080",
  "CN": "2501 00 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sal para la alimentación humana"
 },
 {
  "CNKEY": "250100990080",
  "CN": "2501 00 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "250200000080",
  "CN": "2502 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Piritas de hierro sin tostar"
 },
 {
  "CNKEY": "250300000080",
  "CN": "2503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Azufre de cualquier clase, excepto el sublimado, el precipitado y el coloidal"
 },
 {
  "CNKEY": "250300100080",
  "CN": "2503 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Azufre en bruto y azufre sin refinar"
 },
 {
  "CNKEY": "250300900080",
  "CN": "2503 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "250400000080",
  "CN": "2504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grafito natural"
 },
 {
  "CNKEY": "250410000080",
  "CN": "2504 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En polvo o en escamas"
 },
 {
  "CNKEY": "250490000080",
  "CN": "2504 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "250500000080",
  "CN": "2505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Arenas naturales de cualquier clase, incluso coloreadas, excepto las arenas metalíferas del Capítulo 26"
 },
 {
  "CNKEY": "250510000080",
  "CN": "2505 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Arenas silíceas y arenas cuarzosas"
 },
 {
  "CNKEY": "250590000080",
  "CN": "2505 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "250600000080",
  "CN": "2506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuarzo (excepto las arenas naturales); cuarcita, incluso desbastada o simplemente troceada, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "250610000080",
  "CN": "2506 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuarzo"
 },
 {
  "CNKEY": "250620000080",
  "CN": "2506 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuarcita"
 },
 {
  "CNKEY": "250700000080",
  "CN": "2507 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caolín y demás arcillas caolínicas, incluso calcinados"
 },
 {
  "CNKEY": "250700200080",
  "CN": "2507 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caolín"
 },
 {
  "CNKEY": "250700800080",
  "CN": "2507 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás arcillas caolínicas"
 },
 {
  "CNKEY": "250800000080",
  "CN": "2508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás arcillas (excepto las arcillas dilatadas de la partida 6806), andalucita, cianita y silimanita, incluso calcinadas; mullita; tierras de chamota o de dinas"
 },
 {
  "CNKEY": "250810000080",
  "CN": "2508 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bentonita"
 },
 {
  "CNKEY": "250830000080",
  "CN": "2508 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Arcillas refractarias"
 },
 {
  "CNKEY": "250840000080",
  "CN": "2508 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás arcillas"
 },
 {
  "CNKEY": "250850000080",
  "CN": "2508 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Andalucita, cianita y silimanita"
 },
 {
  "CNKEY": "250860000080",
  "CN": "2508 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mullita"
 },
 {
  "CNKEY": "250870000080",
  "CN": "2508 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tierras de chamota o de dinas"
 },
 {
  "CNKEY": "250900000080",
  "CN": "2509 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Creta"
 },
 {
  "CNKEY": "251000000080",
  "CN": "2510",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fosfatos de calcio naturales, fosfatos aluminocálcicos naturales y cretas fosfatadas"
 },
 {
  "CNKEY": "251010000080",
  "CN": "2510 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin moler"
 },
 {
  "CNKEY": "251020000080",
  "CN": "2510 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Molidos"
 },
 {
  "CNKEY": "251100000080",
  "CN": "2511",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfato de bario natural (baritina); carbonato de bario natural (witherita), incluso calcinado, excepto el óxido de bario de la partida 2816"
 },
 {
  "CNKEY": "251110000080",
  "CN": "2511 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sulfato de bario natural (baritina)"
 },
 {
  "CNKEY": "251120000080",
  "CN": "2511 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonato de bario natural (witherita)"
 },
 {
  "CNKEY": "251200000080",
  "CN": "2512 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Harinas silíceas fósiles (por ejemplo: Kieselguhr, tripolita, diatomita) y demás tierras silíceas análogas, de densidad aparente inferior o igual a 1, incluso calcinadas"
 },
 {
  "CNKEY": "251300000080",
  "CN": "2513",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Piedra pómez; esmeril; corindón natural, granate natural y demás abrasivos naturales, incluso tratados térmicamente"
 },
 {
  "CNKEY": "251310000080",
  "CN": "2513 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piedra pómez"
 },
 {
  "CNKEY": "251320000080",
  "CN": "2513 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Esmeril; corindón natural, granate natural y demás abrasivos naturales"
 },
 {
  "CNKEY": "251400000080",
  "CN": "2514 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Pizarra, incluso desbastada o simplemente troceada, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251500000080",
  "CN": "2515",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mármol, travertinos, ecaussines y demás piedras calizas de talla o de construcción de densidad aparente superior o igual a 2,5, y alabastro, incluso desbastados o simplemente troceados, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mármol y travertinos"
 },
 {
  "CNKEY": "251511000080",
  "CN": "2515 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto o desbastados"
 },
 {
  "CNKEY": "251512000080",
  "CN": "2515 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente troceados, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251520000080",
  "CN": "2515 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ecaussines y demás piedras calizas de talla o de construcción; alabastro"
 },
 {
  "CNKEY": "251600000080",
  "CN": "2516",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Granito, pórfido, basalto, arenisca y demás piedras de talla o de construcción, incluso desbastados o simplemente troceados, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Granito"
 },
 {
  "CNKEY": "251611000080",
  "CN": "2516 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto o desbastado"
 },
 {
  "CNKEY": "251612000080",
  "CN": "2516 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente troceado, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251620000080",
  "CN": "2516 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Arenisca"
 },
 {
  "CNKEY": "251690000080",
  "CN": "2516 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás piedras de talla o de construcción"
 },
 {
  "CNKEY": "251700000080",
  "CN": "2517",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cantos, grava, piedras machacadas, de los tipos generalmente utilizados para hacer hormigón, o para firmes de carreteras, vías férreas u otros balastos, guijarros y pedernal, incluso tratados térmicamente; macadán de escorias o de desechos industriales similares, incluso con materiales comprendidos en la primera parte de la partida; macadán alquitranado; gránulos, tasquiles (fragmentos) y polvo de piedras de las partidas 2515 o 2516, incluso tratados térmicamente"
 },
 {
  "CNKEY": "251710000080",
  "CN": "2517 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cantos, grava, piedras machacadas, de los tipos generalmente utilizados para hacer hormigón, o para firmes de carreteras, vías férreas u otros balastos, guijarros y pedernal, incluso tratados térmicamente"
 },
 {
  "CNKEY": "251710100080",
  "CN": "2517 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cantos, grava, guijarros y pedernal"
 },
 {
  "CNKEY": "251710200080",
  "CN": "2517 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dolomita y piedras para la fabricación de cal, quebrantadas o fragmentadas"
 },
 {
  "CNKEY": "251710800080",
  "CN": "2517 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "251720000080",
  "CN": "2517 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Macadán de escorias o de desechos industriales similares, incluso con materiales citados en la subpartida 251710"
 },
 {
  "CNKEY": "251730000080",
  "CN": "2517 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Macadán alquitranado"
 },
 {
  "CNKEY": "251741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gránulos, tasquiles (fragmentos) y polvo de piedras de las partidas 2515 o 2516, incluso tratados térmicamente"
 },
 {
  "CNKEY": "251741000080",
  "CN": "2517 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De mármol"
 },
 {
  "CNKEY": "251749000080",
  "CN": "2517 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "251800000080",
  "CN": "2518",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Dolomita, incluso sinterizada o calcinada, incluida la dolomita desbastada o simplemente troceada, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares"
 },
 {
  "CNKEY": "251810000080",
  "CN": "2518 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dolomita sin calcinar ni sinterizar, llamada «cruda»"
 },
 {
  "CNKEY": "251820000080",
  "CN": "2518 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dolomita calcinada o sinterizada"
 },
 {
  "CNKEY": "251900000080",
  "CN": "2519",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carbonato de magnesio natural (magnesita); magnesia electrofundida; magnesia calcinada a muerte (sinterizada), incluso con pequeñas cantidades de otros óxidos añadidos antes de la sinterización; otro óxido de magnesio, incluso puro"
 },
 {
  "CNKEY": "251910000080",
  "CN": "2519 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonato de magnesio natural (magnesita)"
 },
 {
  "CNKEY": "251990000080",
  "CN": "2519 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "251990100080",
  "CN": "2519 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxido de magnesio [excepto el carbonato de magnesio (magnesita) calcinado]"
 },
 {
  "CNKEY": "251990300080",
  "CN": "2519 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Magnesita calcinada a muerte (sinterizada)"
 },
 {
  "CNKEY": "251990900080",
  "CN": "2519 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "252000000080",
  "CN": "2520",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Yeso natural; anhidrita; yeso fraguable (consistente en yeso natural calcinado o en sulfato de calcio), incluso coloreado o con pequeñas cantidades de aceleradores o retardadores"
 },
 {
  "CNKEY": "252010000080",
  "CN": "2520 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yeso natural; anhidrita"
 },
 {
  "CNKEY": "252020000080",
  "CN": "2520 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yeso fraguable"
 },
 {
  "CNKEY": "252100000080",
  "CN": "2521 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Castinas; piedras para la fabricación de cal o de cemento"
 },
 {
  "CNKEY": "252200000080",
  "CN": "2522",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cal viva, cal apagada y cal hidráulica, excepto el óxido y el hidróxido de calcio de la partida 2825"
 },
 {
  "CNKEY": "252210000080",
  "CN": "2522 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cal viva"
 },
 {
  "CNKEY": "252220000080",
  "CN": "2522 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cal apagada"
 },
 {
  "CNKEY": "252230000080",
  "CN": "2522 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cal hidráulica"
 },
 {
  "CNKEY": "252300000080",
  "CN": "2523",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cementos hidráulicos (comprendidos los cementos sin pulverizar o clinker), incluso coloreados"
 },
 {
  "CNKEY": "252310000080",
  "CN": "2523 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cementos sin pulverizar o clinker"
 },
 {
  "CNKEY": "252321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cemento Pórtland"
 },
 {
  "CNKEY": "252321000080",
  "CN": "2523 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cemento blanco, incluso coloreado artificialmente"
 },
 {
  "CNKEY": "252329000080",
  "CN": "2523 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "252330000080",
  "CN": "2523 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cementos aluminosos"
 },
 {
  "CNKEY": "252390000080",
  "CN": "2523 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás cementos hidráulicos"
 },
 {
  "CNKEY": "252400000080",
  "CN": "2524",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Amianto (asbesto)"
 },
 {
  "CNKEY": "252410000080",
  "CN": "2524 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Crocidolita"
 },
 {
  "CNKEY": "252490000080",
  "CN": "2524 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "252500000080",
  "CN": "2525",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mica, incluida la exfoliada en laminillas irregulares «splittings»; desperdicios de mica"
 },
 {
  "CNKEY": "252510000080",
  "CN": "2525 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mica en bruto o exfoliada en hojas o en laminillas irregulares «splittings»"
 },
 {
  "CNKEY": "252520000080",
  "CN": "2525 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mica en polvo"
 },
 {
  "CNKEY": "252530000080",
  "CN": "2525 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios de mica"
 },
 {
  "CNKEY": "252600000080",
  "CN": "2526",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Esteatita natural, incluso desbastada o simplemente troceada, por aserrado o de otro modo, en bloques o en placas cuadradas o rectangulares; talco"
 },
 {
  "CNKEY": "252610000080",
  "CN": "2526 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin triturar ni pulverizar"
 },
 {
  "CNKEY": "252620000080",
  "CN": "2526 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triturados o pulverizados"
 },
 {
  "CNKEY": "252800000080",
  "CN": "2528 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Boratos naturales y sus concentrados (incluso calcinados), excepto los boratos extraídos de las salmueras naturales; ácido bórico natural con un contenido de H3BO3 inferior o igual al 85 %, calculado sobre producto seco"
 },
 {
  "CNKEY": "252900000080",
  "CN": "2529",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Feldespato; leucita; nefelina y nefelina sienita; espato flúor"
 },
 {
  "CNKEY": "252910000080",
  "CN": "2529 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Feldespato"
 },
 {
  "CNKEY": "252921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Espato flúor"
 },
 {
  "CNKEY": "252921000080",
  "CN": "2529 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de fluoruro de calcio inferior o igual al 97 % en peso"
 },
 {
  "CNKEY": "252922000080",
  "CN": "2529 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de fluoruro de calcio superior al 97 % en peso"
 },
 {
  "CNKEY": "252930000080",
  "CN": "2529 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Leucita; nefelina y nefelina sienita"
 },
 {
  "CNKEY": "253000000080",
  "CN": "2530",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Materias minerales no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "253010000080",
  "CN": "2530 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Vermiculita, perlita y cloritas, sin dilatar"
 },
 {
  "CNKEY": "253020000080",
  "CN": "2530 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Kieserita, epsomita (sulfatos de magnesio naturales)"
 },
 {
  "CNKEY": "253090000080",
  "CN": "2530 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "260021000090",
  "CN": "26",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 26 - MINERALES METALÍFEROS, ESCORIAS Y CENIZAS"
 },
 {
  "CNKEY": "260100000080",
  "CN": "2601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Minerales de hierro y sus concentrados, incluidas las piritas de hierro tostadas (cenizas de piritas)"
 },
 {
  "CNKEY": "260111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Minerales de hierro y sus concentrados, excepto las piritas de hierro tostadas (cenizas de piritas)"
 },
 {
  "CNKEY": "260111000080",
  "CN": "2601 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin aglomerar"
 },
 {
  "CNKEY": "260112000080",
  "CN": "2601 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aglomerados"
 },
 {
  "CNKEY": "260120000080",
  "CN": "2601 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piritas de hierro tostadas (cenizas de piritas)"
 },
 {
  "CNKEY": "260200000080",
  "CN": "2602 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de manganeso y sus concentrados, incluidos los minerales de manganeso ferruginosos y sus concentrados con un contenido de manganeso superior o igual al 20 % en peso, sobre producto seco"
 },
 {
  "CNKEY": "260300000080",
  "CN": "2603 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de cobre y sus concentrados"
 },
 {
  "CNKEY": "260400000080",
  "CN": "2604 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de níquel y sus concentrados"
 },
 {
  "CNKEY": "260500000080",
  "CN": "2605 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de cobalto y sus concentrados"
 },
 {
  "CNKEY": "260600000080",
  "CN": "2606 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de aluminio y sus concentrados"
 },
 {
  "CNKEY": "260700000080",
  "CN": "2607 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de plomo y sus concentrados"
 },
 {
  "CNKEY": "260800000080",
  "CN": "2608 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de cinc y sus concentrados"
 },
 {
  "CNKEY": "260900000080",
  "CN": "2609 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de estaño y sus concentrados"
 },
 {
  "CNKEY": "261000000080",
  "CN": "2610 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de cromo y sus concentrados"
 },
 {
  "CNKEY": "261100000080",
  "CN": "2611 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de volframio (tungsteno) y sus concentrados"
 },
 {
  "CNKEY": "261200000080",
  "CN": "2612",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Minerales de uranio o torio, y sus concentrados"
 },
 {
  "CNKEY": "261210000080",
  "CN": "2612 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Minerales de uranio y sus concentrados"
 },
 {
  "CNKEY": "261210100080",
  "CN": "2612 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Minerales de uranio y pechblenda, con un contenido de uranio superior al 5 % en peso (Euratom)"
 },
 {
  "CNKEY": "261210900080",
  "CN": "2612 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261220000080",
  "CN": "2612 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Minerales de torio y sus concentrados"
 },
 {
  "CNKEY": "261220100080",
  "CN": "2612 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Monacita; uranotorianita y demás minerales de torio, con un contenido de torio superior al 20 % en peso (Euratom)"
 },
 {
  "CNKEY": "261220900080",
  "CN": "2612 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261300000080",
  "CN": "2613",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Minerales de molibdeno y sus concentrados"
 },
 {
  "CNKEY": "261310000080",
  "CN": "2613 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tostados"
 },
 {
  "CNKEY": "261390000080",
  "CN": "2613 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261400000080",
  "CN": "2614 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Minerales de titanio y sus concentrados"
 },
 {
  "CNKEY": "261500000080",
  "CN": "2615",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Minerales de niobio, tantalio, vanadio o circonio, y sus concentrados"
 },
 {
  "CNKEY": "261510000080",
  "CN": "2615 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Minerales de circonio y sus concentrados"
 },
 {
  "CNKEY": "261590000080",
  "CN": "2615 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261600000080",
  "CN": "2616",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Minerales de los metales preciosos y sus concentrados"
 },
 {
  "CNKEY": "261610000080",
  "CN": "2616 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Minerales de plata y sus concentrados"
 },
 {
  "CNKEY": "261690000080",
  "CN": "2616 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261700000080",
  "CN": "2617",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás minerales y sus concentrados"
 },
 {
  "CNKEY": "261710000080",
  "CN": "2617 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Minerales de antimonio y sus concentrados"
 },
 {
  "CNKEY": "261790000080",
  "CN": "2617 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "261800000080",
  "CN": "2618 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Escorias granuladas (arena de escorias) de la siderurgia"
 },
 {
  "CNKEY": "261900000080",
  "CN": "2619 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Escorias (excepto las granuladas), batiduras y demás desperdicios de la siderurgia"
 },
 {
  "CNKEY": "261900200080",
  "CN": "2619 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios aptos para la recuperación de hierro o de manganeso"
 },
 {
  "CNKEY": "261900900080",
  "CN": "2619 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "262000000080",
  "CN": "2620",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Escorias, cenizas y residuos (excepto los de la siderurgia), que contengan metal, arsénico, o sus compuestos"
 },
 {
  "CNKEY": "262011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan principalmente cinc"
 },
 {
  "CNKEY": "262011000080",
  "CN": "2620 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Matas de galvanización"
 },
 {
  "CNKEY": "262019000080",
  "CN": "2620 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "262021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan principalmente plomo"
 },
 {
  "CNKEY": "262021000080",
  "CN": "2620 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lodos de gasolina con plomo y lodos de compuestos antidetonantes con plomo"
 },
 {
  "CNKEY": "262029000080",
  "CN": "2620 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "262030000080",
  "CN": "2620 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente cobre"
 },
 {
  "CNKEY": "262040000080",
  "CN": "2620 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente aluminio"
 },
 {
  "CNKEY": "262060000080",
  "CN": "2620 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan arsénico, mercurio, talio o sus mezclas, de los tipos utilizados para la extracción de arsénico o de estos metales o para la fabricación de sus compuestos químicos"
 },
 {
  "CNKEY": "262091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "262091000080",
  "CN": "2620 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan antimonio, berilio, cadmio, cromo o sus mezclas"
 },
 {
  "CNKEY": "262099000080",
  "CN": "2620 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "262099100080",
  "CN": "2620 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente níquel"
 },
 {
  "CNKEY": "262099200080",
  "CN": "2620 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente niobio o tántalo"
 },
 {
  "CNKEY": "262099400080",
  "CN": "2620 99 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente estaño"
 },
 {
  "CNKEY": "262099600080",
  "CN": "2620 99 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente titanio"
 },
 {
  "CNKEY": "262099950080",
  "CN": "2620 99 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "262100000080",
  "CN": "2621",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás escorias y cenizas, incluidas las cenizas de algas; cenizas y residuos procedentes de la incineración de desechos municipales"
 },
 {
  "CNKEY": "262110000080",
  "CN": "2621 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cenizas y residuos procedentes de la incineración de desechos  municipales"
 },
 {
  "CNKEY": "262190000080",
  "CN": "2621 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "270021000090",
  "CN": "27",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 27 - COMBUSTIBLES MINERALES, ACEITES MINERALES Y PRODUCTOS DE SU DESTILACIÓN; MATERIAS BITUMINOSAS; CERAS MINERALES"
 },
 {
  "CNKEY": "270100000080",
  "CN": "2701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hullas; briquetas, ovoides y combustibles sólidos similares, obtenidos de la hulla"
 },
 {
  "CNKEY": "270111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hullas, incluso pulverizadas, pero sin aglomerar"
 },
 {
  "CNKEY": "270111000080",
  "CN": "2701 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Antracitas"
 },
 {
  "CNKEY": "270112000080",
  "CN": "2701 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hulla bituminosa"
 },
 {
  "CNKEY": "270112100080",
  "CN": "2701 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hulla coquizable"
 },
 {
  "CNKEY": "270112900080",
  "CN": "2701 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "270119000080",
  "CN": "2701 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás hullas"
 },
 {
  "CNKEY": "270120000080",
  "CN": "2701 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Briquetas, ovoides y combustibles sólidos similares, obtenidos de la hulla"
 },
 {
  "CNKEY": "270200000080",
  "CN": "2702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lignitos, incluso aglomerados, excepto el azabache"
 },
 {
  "CNKEY": "270210000080",
  "CN": "2702 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lignitos, incluso pulverizados, pero sin aglomerar"
 },
 {
  "CNKEY": "270220000080",
  "CN": "2702 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lignitos aglomerados"
 },
 {
  "CNKEY": "270300000080",
  "CN": "2703 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Turba (comprendida la utilizada para cama de animales), incluso aglomerada"
 },
 {
  "CNKEY": "270400000080",
  "CN": "2704 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Coques y semicoques de hulla, lignito o turba, incluso aglomerados; carbón de retorta"
 },
 {
  "CNKEY": "270400100080",
  "CN": "2704 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coque y semicoque de hulla"
 },
 {
  "CNKEY": "270400300080",
  "CN": "2704 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coque y semicoque de lignito"
 },
 {
  "CNKEY": "270400900080",
  "CN": "2704 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "270500000080",
  "CN": "2705 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "1000 m3",
  "ES": "Gas de hulla, gas de agua, gas pobre y gases similares, excepto el gas de petróleo y demás hidrocarburos gaseosos"
 },
 {
  "CNKEY": "270600000080",
  "CN": "2706 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Alquitranes de hulla, lignito o turba y demás alquitranes minerales, aunque estén deshidratados o descabezados, incluidos los alquitranes reconstituidos"
 },
 {
  "CNKEY": "270700000080",
  "CN": "2707",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites y demás productos de la destilación de los alquitranes de hulla de alta temperatura; productos análogos en los que los constituyentes aromáticos predominen en peso sobre los no aromáticos"
 },
 {
  "CNKEY": "270710000080",
  "CN": "2707 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Benzol (benceno)"
 },
 {
  "CNKEY": "270720000080",
  "CN": "2707 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Toluol (tolueno)"
 },
 {
  "CNKEY": "270730000080",
  "CN": "2707 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Xilol (xilenos)"
 },
 {
  "CNKEY": "270740000080",
  "CN": "2707 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Naftaleno"
 },
 {
  "CNKEY": "270750000080",
  "CN": "2707 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás mezclas de hidrocarburos aromáticos que destilen, incluidas las pérdidas, una proporción superior o igual al 65 % en volumen, a 250 °C, según el método ISO 3405  (equivalente al método ASTM D 86)"
 },
 {
  "CNKEY": "270791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "270791000080",
  "CN": "2707 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceites de creosota"
 },
 {
  "CNKEY": "270799000080",
  "CN": "2707 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "270799110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aceites brutos"
 },
 {
  "CNKEY": "270799110080",
  "CN": "2707 99 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Aceites ligeros brutos, que destilen una proporción superior o igual al 90 % en volumen hasta 200 °C"
 },
 {
  "CNKEY": "270799190080",
  "CN": "2707 99 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "270799200080",
  "CN": "2707 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cabezas sulfuradas; antraceno"
 },
 {
  "CNKEY": "270799500080",
  "CN": "2707 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Productos básicos"
 },
 {
  "CNKEY": "270799800080",
  "CN": "2707 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fenoles"
 },
 {
  "CNKEY": "270799910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "270799910080",
  "CN": "2707 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a la fabricación de productos de la partida 2803"
 },
 {
  "CNKEY": "270799990080",
  "CN": "2707 99 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "270800000080",
  "CN": "2708",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Brea y coque de brea de alquitrán de hulla o de otros alquitranes minerales"
 },
 {
  "CNKEY": "270810000080",
  "CN": "2708 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Brea"
 },
 {
  "CNKEY": "270820000080",
  "CN": "2708 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coque de brea"
 },
 {
  "CNKEY": "270900000080",
  "CN": "2709 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites crudos de petróleo o de mineral bituminoso"
 },
 {
  "CNKEY": "270900100080",
  "CN": "2709 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Condensados de gas natural"
 },
 {
  "CNKEY": "270900900080",
  "CN": "2709 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271000000080",
  "CN": "2710",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites de petróleo o de mineral bituminoso, excepto los aceites crudos; preparaciones no expresadas ni comprendidas en otra parte, con un contenido de aceites de petróleo o de mineral bituminoso superior o igual al 70 % en peso, en las que estos aceites constituyan el elemento base; desechos de aceites"
 },
 {
  "CNKEY": "271012000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de petróleo o de mineral bituminoso (excepto los aceites crudos) y  preparaciones no expresadas ni comprendidas en otra parte, con un contenido de aceites de petróleo o de mineral bituminoso superior o igual al 70 % en peso, en las que estos aceites constituyan el elemento base, excepto las que contengan biodiésel y los desechos de aceites"
 },
 {
  "CNKEY": "271012000080",
  "CN": "2710 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aceites ligeros  (livianos) y preparaciones"
 },
 {
  "CNKEY": "271012110080",
  "CN": "2710 12 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271012150080",
  "CN": "2710 12 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos en la subpartida 27101211"
 },
 {
  "CNKEY": "271012210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271012210020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Gasolinas especiales"
 },
 {
  "CNKEY": "271012210080",
  "CN": "2710 12 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "White spirit"
 },
 {
  "CNKEY": "271012250080",
  "CN": "2710 12 25",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271012310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271012310020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Gasolinas para motores"
 },
 {
  "CNKEY": "271012310080",
  "CN": "2710 12 31",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Gasolinas de aviación"
 },
 {
  "CNKEY": "271012410010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Las demás, con un contenido de plomo"
 },
 {
  "CNKEY": "271012410020",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 0,013 g por l"
 },
 {
  "CNKEY": "271012410080",
  "CN": "2710 12 41",
  "CN_LEVEL": 11,
  "T_SU_SU": "m³",
  "ES": "Con un octanaje (RON) inferior a 95"
 },
 {
  "CNKEY": "271012450080",
  "CN": "2710 12 45",
  "CN_LEVEL": 11,
  "T_SU_SU": "m³",
  "ES": "Con un octanaje (RON) superior o igual a 95 pero inferior a 98"
 },
 {
  "CNKEY": "271012490080",
  "CN": "2710 12 49",
  "CN_LEVEL": 11,
  "T_SU_SU": "m³",
  "ES": "Con un octanaje (RON) superior o igual a 98"
 },
 {
  "CNKEY": "271012500080",
  "CN": "2710 12 50",
  "CN_LEVEL": 10,
  "T_SU_SU": "m³",
  "ES": "Superior a 0,013 g por l"
 },
 {
  "CNKEY": "271012700080",
  "CN": "2710 12 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Carburorreactores tipo gasolina"
 },
 {
  "CNKEY": "271012900080",
  "CN": "2710 12 90",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás aceites ligeros (livianos)"
 },
 {
  "CNKEY": "271019000080",
  "CN": "2710 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271019110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aceites medios"
 },
 {
  "CNKEY": "271019110080",
  "CN": "2710 19 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271019150080",
  "CN": "2710 19 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos en la subpartida 27101911"
 },
 {
  "CNKEY": "271019210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271019210020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Petróleo lampante"
 },
 {
  "CNKEY": "271019210080",
  "CN": "2710 19 21",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Carburorreactores"
 },
 {
  "CNKEY": "271019250080",
  "CN": "2710 19 25",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271019290080",
  "CN": "2710 19 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271019310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aceites pesados"
 },
 {
  "CNKEY": "271019310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Gasóleo"
 },
 {
  "CNKEY": "271019310080",
  "CN": "2710 19 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destine a un tratamiento definido"
 },
 {
  "CNKEY": "271019350080",
  "CN": "2710 19 35",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destine a una transformación química mediante un tratamiento distinto de los definidos para la subpartida 27101931"
 },
 {
  "CNKEY": "271019430010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Que se destine a otros usos"
 },
 {
  "CNKEY": "271019430080",
  "CN": "2710 19 43",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre inferior o igual al 0,001 % en peso"
 },
 {
  "CNKEY": "271019460080",
  "CN": "2710 19 46",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,001 % pero inferior o igual al 0,002 % en peso"
 },
 {
  "CNKEY": "271019470080",
  "CN": "2710 19 47",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,002 % pero inferior o igual al 0,1 % en peso"
 },
 {
  "CNKEY": "271019480080",
  "CN": "2710 19 48",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,1 % en peso"
 },
 {
  "CNKEY": "271019510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Fuel"
 },
 {
  "CNKEY": "271019510080",
  "CN": "2710 19 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destine a un tratamiento definido"
 },
 {
  "CNKEY": "271019550080",
  "CN": "2710 19 55",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destine a una transformación química mediante un tratamiento distinto de los definidos para la subpartida 27101951"
 },
 {
  "CNKEY": "271019620010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Que se destine a otros usos"
 },
 {
  "CNKEY": "271019620080",
  "CN": "2710 19 62",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre inferior o igual al 0,1 % en peso"
 },
 {
  "CNKEY": "271019660080",
  "CN": "2710 19 66",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,1 % pero inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "271019670080",
  "CN": "2710 19 67",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,5 % en peso"
 },
 {
  "CNKEY": "271019710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Aceites lubricantes y los demás"
 },
 {
  "CNKEY": "271019710080",
  "CN": "2710 19 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271019750080",
  "CN": "2710 19 75",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos para la subpartida 27101971"
 },
 {
  "CNKEY": "271019810010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271019810080",
  "CN": "2710 19 81",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites para motores, compresores y turbinas"
 },
 {
  "CNKEY": "271019830080",
  "CN": "2710 19 83",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites hidráulicos"
 },
 {
  "CNKEY": "271019850080",
  "CN": "2710 19 85",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites blancos, parafina líquida"
 },
 {
  "CNKEY": "271019870080",
  "CN": "2710 19 87",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites para engranajes"
 },
 {
  "CNKEY": "271019910080",
  "CN": "2710 19 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites para la metalurgia, aceites de desmoldeo, aceites anticorrosivos"
 },
 {
  "CNKEY": "271019930080",
  "CN": "2710 19 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Aceites para aislamiento eléctrico"
 },
 {
  "CNKEY": "271019990080",
  "CN": "2710 19 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás aceites lubricantes y los demás"
 },
 {
  "CNKEY": "271020000080",
  "CN": "2710 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites de petróleo o de mineral bituminoso (excepto los aceites crudos) y  preparaciones no expresadas ni comprendidas en otra parte, con un contenido de aceites de petróleo o de mineral bituminoso superior o igual al 70 % en peso, en las que estos aceites constituyan el elemento base, que contengan biodiésel, excepto los desechos de aceites"
 },
 {
  "CNKEY": "271020110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Gasóleo"
 },
 {
  "CNKEY": "271020110080",
  "CN": "2710 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre inferior o igual al 0,001 % en peso"
 },
 {
  "CNKEY": "271020160080",
  "CN": "2710 20 16",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,001 % pero inferior o igual al 0,1 % en peso"
 },
 {
  "CNKEY": "271020190080",
  "CN": "2710 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,1 % en peso"
 },
 {
  "CNKEY": "271020320010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fuel"
 },
 {
  "CNKEY": "271020320080",
  "CN": "2710 20 32",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "271020380080",
  "CN": "2710 20 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de azufre superior al 0,5 % en peso"
 },
 {
  "CNKEY": "271020900080",
  "CN": "2710 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás aceites"
 },
 {
  "CNKEY": "271091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desechos de aceites"
 },
 {
  "CNKEY": "271091000080",
  "CN": "2710 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan bifenilos policlorados (PCB), terfenilos policlorados (PCT) o bifenilos polibromados (PBB)"
 },
 {
  "CNKEY": "271099000080",
  "CN": "2710 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271100000080",
  "CN": "2711",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Gas de petróleo y demás hidrocarburos gaseosos"
 },
 {
  "CNKEY": "271111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Licuados"
 },
 {
  "CNKEY": "271111000080",
  "CN": "2711 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "TJ",
  "ES": "Gas natural"
 },
 {
  "CNKEY": "271112000080",
  "CN": "2711 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Propano"
 },
 {
  "CNKEY": "271112110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Propano de pureza superior o igual al 99 %"
 },
 {
  "CNKEY": "271112110080",
  "CN": "2711 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destine a ser empleado como carburante o como combustible"
 },
 {
  "CNKEY": "271112190080",
  "CN": "2711 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destine a otros usos"
 },
 {
  "CNKEY": "271112910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271112910080",
  "CN": "2711 12 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271112930080",
  "CN": "2711 12 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos en la subpartida 27111291"
 },
 {
  "CNKEY": "271112940010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271112940080",
  "CN": "2711 12 94",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De pureza superior al 90 % pero inferior al 99 %"
 },
 {
  "CNKEY": "271112970080",
  "CN": "2711 12 97",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271113000080",
  "CN": "2711 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Butanos"
 },
 {
  "CNKEY": "271113100080",
  "CN": "2711 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271113300080",
  "CN": "2711 13 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos para la subpartida 27111310"
 },
 {
  "CNKEY": "271113910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271113910080",
  "CN": "2711 13 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De pureza superior al 90 % pero inferior al 95 %"
 },
 {
  "CNKEY": "271113970080",
  "CN": "2711 13 97",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271114000080",
  "CN": "2711 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etileno, propileno, butileno y butadieno"
 },
 {
  "CNKEY": "271119000080",
  "CN": "2711 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En estado gaseoso"
 },
 {
  "CNKEY": "271121000080",
  "CN": "2711 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "TJ",
  "ES": "Gas natural"
 },
 {
  "CNKEY": "271129000080",
  "CN": "2711 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271200000080",
  "CN": "2712",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vaselina; parafina, cera de petróleo microcristalina, slack wax, ozoquerita, cera de lignito, cera de turba, demás ceras minerales y productos similares obtenidos por síntesis o por otros procedimientos, incluso coloreados"
 },
 {
  "CNKEY": "271210000080",
  "CN": "2712 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vaselina"
 },
 {
  "CNKEY": "271210100080",
  "CN": "2712 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "271210900080",
  "CN": "2712 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "271220000080",
  "CN": "2712 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Parafina con un contenido de aceite inferior al 0,75 % en peso"
 },
 {
  "CNKEY": "271220100080",
  "CN": "2712 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Parafina sintética con un peso molecular superior o igual a 460 pero inferior o igual a 1560"
 },
 {
  "CNKEY": "271220900080",
  "CN": "2712 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "271290000080",
  "CN": "2712 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271290110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Ozoquerita, cera de lignito o de turba (productos naturales)"
 },
 {
  "CNKEY": "271290110080",
  "CN": "2712 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "271290190080",
  "CN": "2712 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271290310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271290310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En bruto"
 },
 {
  "CNKEY": "271290310080",
  "CN": "2712 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a un tratamiento definido"
 },
 {
  "CNKEY": "271290330080",
  "CN": "2712 90 33",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a una transformación química mediante un tratamiento distinto de los definidos para la subpartida 27129031"
 },
 {
  "CNKEY": "271290390080",
  "CN": "2712 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que se destinen a otros usos"
 },
 {
  "CNKEY": "271290910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "271290910080",
  "CN": "2712 90 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mezcla de 1-alquenos con un contenido superior o igual al 80 % de 1-alquenos de longitud de cadena superior o igual a 24 átomos de carbono pero inferior o igual a 28 átomos de carbono"
 },
 {
  "CNKEY": "271290990080",
  "CN": "2712 90 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271300000080",
  "CN": "2713",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Coque de petróleo, betún de petróleo y demás residuos de los aceites de petróleo o de mineral bituminoso"
 },
 {
  "CNKEY": "271311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Coque de petróleo"
 },
 {
  "CNKEY": "271311000080",
  "CN": "2713 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin calcinar"
 },
 {
  "CNKEY": "271312000080",
  "CN": "2713 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Calcinado"
 },
 {
  "CNKEY": "271320000080",
  "CN": "2713 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Betún de petróleo"
 },
 {
  "CNKEY": "271390000080",
  "CN": "2713 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás residuos de los aceites de petróleo o de mineral bituminoso"
 },
 {
  "CNKEY": "271390100080",
  "CN": "2713 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destinen a la fabricación de productos de la partida 2803"
 },
 {
  "CNKEY": "271390900080",
  "CN": "2713 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271400000080",
  "CN": "2714",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Betunes y asfaltos naturales; pizarras y arenas bituminosas; asfaltitas y rocas asfálticas"
 },
 {
  "CNKEY": "271410000080",
  "CN": "2714 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pizarras y arenas bituminosas"
 },
 {
  "CNKEY": "271490000080",
  "CN": "2714 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "271500000080",
  "CN": "2715 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Mezclas bituminosas a base de asfalto o de betún naturales, de betún de petróleo, de alquitrán mineral o de brea de alquitrán mineral (por ejemplo: mástiques bituminosos, cut backs)"
 },
 {
  "CNKEY": "271600000080",
  "CN": "2716 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "1000 kWh",
  "ES": "Energía eléctrica (partida discrecional)"
 },
 {
  "CNKEY": "280011000090",
  "CN": "VI",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN VI - PRODUCTOS DE LAS INDUSTRIAS QUÍMICAS O DE LAS INDUSTRIAS CONEXAS"
 },
 {
  "CNKEY": "280021000090",
  "CN": "28",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 28 - PRODUCTOS QUÍMICOS INORGÁNICOS; COMPUESTOS INORGÁNICOS U ORGÁNICOS DE METAL PRECIOSO, DE ELEMENTOS RADIACTIVOS, DE METALES DE LAS TIERRAS RARAS O DE ISÓTOPOS"
 },
 {
  "CNKEY": "280100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. ELEMENTOS QUÍMICOS"
 },
 {
  "CNKEY": "280100000080",
  "CN": "2801",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Flúor, cloro, bromo y yodo"
 },
 {
  "CNKEY": "280110000080",
  "CN": "2801 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloro"
 },
 {
  "CNKEY": "280120000080",
  "CN": "2801 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yodo"
 },
 {
  "CNKEY": "280130000080",
  "CN": "2801 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Flúor; bromo"
 },
 {
  "CNKEY": "280130100080",
  "CN": "2801 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Flúor"
 },
 {
  "CNKEY": "280130900080",
  "CN": "2801 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bromo"
 },
 {
  "CNKEY": "280200000080",
  "CN": "2802 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Azufre sublimado o precipitado; azufre coloidal"
 },
 {
  "CNKEY": "280300000080",
  "CN": "2803 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Carbono (negros de humo y otras formas de carbono no expresadas ni comprendidas en otra parte)"
 },
 {
  "CNKEY": "280400000080",
  "CN": "2804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidrógeno, gases nobles y demás elementos no metálicos"
 },
 {
  "CNKEY": "280410000080",
  "CN": "2804 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m³",
  "ES": "Hidrógeno"
 },
 {
  "CNKEY": "280421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gases nobles"
 },
 {
  "CNKEY": "280421000080",
  "CN": "2804 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Argón"
 },
 {
  "CNKEY": "280429000080",
  "CN": "2804 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "280429100080",
  "CN": "2804 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Helio"
 },
 {
  "CNKEY": "280429900080",
  "CN": "2804 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280430000080",
  "CN": "2804 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m³",
  "ES": "Nitrógeno"
 },
 {
  "CNKEY": "280440000080",
  "CN": "2804 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m³",
  "ES": "Oxígeno"
 },
 {
  "CNKEY": "280450000080",
  "CN": "2804 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Boro; telurio"
 },
 {
  "CNKEY": "280450100080",
  "CN": "2804 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Boro"
 },
 {
  "CNKEY": "280450900080",
  "CN": "2804 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Telurio"
 },
 {
  "CNKEY": "280461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Silicio"
 },
 {
  "CNKEY": "280461000080",
  "CN": "2804 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de silicio superior o igual al 99,99 % en peso"
 },
 {
  "CNKEY": "280469000080",
  "CN": "2804 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280470000080",
  "CN": "2804 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fósforo"
 },
 {
  "CNKEY": "280470100080",
  "CN": "2804 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fósforo rojo"
 },
 {
  "CNKEY": "280470900080",
  "CN": "2804 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280480000080",
  "CN": "2804 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Arsénico"
 },
 {
  "CNKEY": "280490000080",
  "CN": "2804 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Selenio"
 },
 {
  "CNKEY": "280500000080",
  "CN": "2805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Metales alcalinos o alcalinotérreos; metales de las tierras raras, escandio e itrio, incluso mezclados o aleados entre sí; mercurio"
 },
 {
  "CNKEY": "280511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Metales alcalinos o alcalinotérreos"
 },
 {
  "CNKEY": "280511000080",
  "CN": "2805 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sodio"
 },
 {
  "CNKEY": "280512000080",
  "CN": "2805 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Calcio"
 },
 {
  "CNKEY": "280519000080",
  "CN": "2805 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "280519100080",
  "CN": "2805 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Estroncio y bario"
 },
 {
  "CNKEY": "280519900080",
  "CN": "2805 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280530000080",
  "CN": "2805 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Metales de las tierras raras, escandio e itrio, incluso mezclados o aleados entre sí"
 },
 {
  "CNKEY": "280530100080",
  "CN": "2805 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados o aleados entre sí"
 },
 {
  "CNKEY": "280530200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "280530200020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De pureza en peso superior o igual al 95 %"
 },
 {
  "CNKEY": "280530200080",
  "CN": "2805 30 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cerio, lantano, praseodimio, neodimio y samario"
 },
 {
  "CNKEY": "280530300080",
  "CN": "2805 30 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Europio, gadolinio, terbio, disprosio, holmio, erbio, tulio, iterbio, lutecio e itrio"
 },
 {
  "CNKEY": "280530400080",
  "CN": "2805 30 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Escandio"
 },
 {
  "CNKEY": "280530800080",
  "CN": "2805 30 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280540000080",
  "CN": "2805 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mercurio"
 },
 {
  "CNKEY": "280540100080",
  "CN": "2805 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se presenten en bombonas con un contenido neto de 34,5 kg (peso estándar) y cuyo valor fob sea inferior o igual a 224 € por bombona"
 },
 {
  "CNKEY": "280540900080",
  "CN": "2805 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "280600000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. ÁCIDOS INORGÁNICOS Y COMPUESTOS OXIGENADOS INORGÁNICOS DE LOS ELEMENTOS NO METÁLICOS"
 },
 {
  "CNKEY": "280600000080",
  "CN": "2806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cloruro de hidrógeno (ácido clorhídrico); ácido clorosulfúrico"
 },
 {
  "CNKEY": "280610000080",
  "CN": "2806 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloruro de hidrógeno (ácido clorhídrico)"
 },
 {
  "CNKEY": "280620000080",
  "CN": "2806 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácido clorosulfúrico"
 },
 {
  "CNKEY": "280700000080",
  "CN": "2807 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Ácido sulfúrico; óleum"
 },
 {
  "CNKEY": "280800000080",
  "CN": "2808 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Ácido nítrico; ácidos sulfonítricos"
 },
 {
  "CNKEY": "280900000080",
  "CN": "2809",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pentóxido de difósforo; ácido fosfórico; ácidos polifosfóricos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "280910000080",
  "CN": "2809 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg P2O5",
  "ES": "Pentóxido de difósforo"
 },
 {
  "CNKEY": "280920000080",
  "CN": "2809 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg P2O5",
  "ES": "Ácido fosfórico y ácidos polifosfóricos"
 },
 {
  "CNKEY": "281000000080",
  "CN": "2810 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Óxidos de boro; ácidos bóricos"
 },
 {
  "CNKEY": "281000100080",
  "CN": "2810 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trióxido de diboro"
 },
 {
  "CNKEY": "281000900080",
  "CN": "2810 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281100000080",
  "CN": "2811",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás ácidos inorgánicos y los demás compuestos oxigenados inorgánicos de los elementos no metálicos"
 },
 {
  "CNKEY": "281111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás ácidos inorgánicos"
 },
 {
  "CNKEY": "281111000080",
  "CN": "2811 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fluoruro de hidrógeno (ácido fluorhídrico)"
 },
 {
  "CNKEY": "281112000080",
  "CN": "2811 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cianuro de hidrógeno (ácido cianhídrico)"
 },
 {
  "CNKEY": "281119000080",
  "CN": "2811 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "281119100080",
  "CN": "2811 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bromuro de hidrógeno (ácido bromhídrico)"
 },
 {
  "CNKEY": "281119800080",
  "CN": "2811 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás compuestos oxigenados inorgánicos de los elementos no metálicos"
 },
 {
  "CNKEY": "281121000080",
  "CN": "2811 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dióxido de carbono"
 },
 {
  "CNKEY": "281122000080",
  "CN": "2811 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dióxido de silicio"
 },
 {
  "CNKEY": "281129000080",
  "CN": "2811 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "281129050080",
  "CN": "2811 29 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dióxido de azufre"
 },
 {
  "CNKEY": "281129100080",
  "CN": "2811 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trióxido de azufre (anhídrico sulfúrico); trióxido de diarsénico (anhídrido arsenioso)"
 },
 {
  "CNKEY": "281129300080",
  "CN": "2811 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Óxidos de nitrógeno"
 },
 {
  "CNKEY": "281129900080",
  "CN": "2811 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281200000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "III. DERIVADOS HALOGENADOS, OXIHALOGENADOS O SULFURADOS DE LOS ELEMENTOS NO METÁLICOS"
 },
 {
  "CNKEY": "281200000080",
  "CN": "2812",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Halogenuros y oxihalogenuros de los elementos no metálicos"
 },
 {
  "CNKEY": "281211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cloruros y oxicloruros"
 },
 {
  "CNKEY": "281211000080",
  "CN": "2812 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloruro de carbonilo (fosgeno)"
 },
 {
  "CNKEY": "281212000080",
  "CN": "2812 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Oxicloruro de fósforo"
 },
 {
  "CNKEY": "281213000080",
  "CN": "2812 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tricloruro de fósforo"
 },
 {
  "CNKEY": "281214000080",
  "CN": "2812 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pentacloruro de fósforo"
 },
 {
  "CNKEY": "281215000080",
  "CN": "2812 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Monocloruro de azufre"
 },
 {
  "CNKEY": "281216000080",
  "CN": "2812 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloruro de azufre"
 },
 {
  "CNKEY": "281217000080",
  "CN": "2812 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cloruro de tionilo"
 },
 {
  "CNKEY": "281219000080",
  "CN": "2812 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "281219100080",
  "CN": "2812 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fósforo"
 },
 {
  "CNKEY": "281219900080",
  "CN": "2812 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281290000080",
  "CN": "2812 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281300000080",
  "CN": "2813",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfuros de los elementos no metálicos; trisulfuro de fósforo comercial"
 },
 {
  "CNKEY": "281310000080",
  "CN": "2813 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Disulfuro de carbono"
 },
 {
  "CNKEY": "281390000080",
  "CN": "2813 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "281390100080",
  "CN": "2813 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sulfuros de fósforo, incluido el triosulfuro de fósforo comercial"
 },
 {
  "CNKEY": "281390900080",
  "CN": "2813 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "281400000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "IV. BASES INORGÁNICAS Y ÓXIDOS, HIDRÓXIDOS Y PERÓXIDOS DE METALES"
 },
 {
  "CNKEY": "281400000080",
  "CN": "2814",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Amoníaco anhidro o en disolución acuosa"
 },
 {
  "CNKEY": "281410000080",
  "CN": "2814 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Amoníaco anhidro"
 },
 {
  "CNKEY": "281420000080",
  "CN": "2814 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Amoníaco en disolución acuosa"
 },
 {
  "CNKEY": "281500000080",
  "CN": "2815",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidróxido de sodio (sosa o soda cáustica); hidróxido de potasio (potasa cáustica); peróxidos de sodio o de potasio"
 },
 {
  "CNKEY": "281511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hidróxido de sodio (sosa o soda cáustica)"
 },
 {
  "CNKEY": "281511000080",
  "CN": "2815 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sólido"
 },
 {
  "CNKEY": "281512000080",
  "CN": "2815 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg NaOH",
  "ES": "En disolución acuosa (lejía de sosa o soda cáustica)"
 },
 {
  "CNKEY": "281520000080",
  "CN": "2815 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg KOH",
  "ES": "Hidróxido de potasio (potasa cáustica)"
 },
 {
  "CNKEY": "281530000080",
  "CN": "2815 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Peróxidos de sodio o de potasio"
 },
 {
  "CNKEY": "281600000080",
  "CN": "2816",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidróxido y peróxido de magnesio; óxidos, hidróxidos y peróxidos, de estroncio o de bario"
 },
 {
  "CNKEY": "281610000080",
  "CN": "2816 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidróxido y peróxido de magnesio"
 },
 {
  "CNKEY": "281640000080",
  "CN": "2816 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos, hidróxidos y peróxidos, de estroncio o de bario"
 },
 {
  "CNKEY": "281700000080",
  "CN": "2817 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Óxido de cinc; peróxido de cinc"
 },
 {
  "CNKEY": "281800000080",
  "CN": "2818",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Corindón artificial, aunque no sea de constitución química definida; óxido de aluminio; hidróxido de aluminio"
 },
 {
  "CNKEY": "281810000080",
  "CN": "2818 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Corindón artificial, aunque no sea de constitución química definida"
 },
 {
  "CNKEY": "281810110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de óxido de aluminio superior o igual al 98,5 % en peso"
 },
 {
  "CNKEY": "281810110080",
  "CN": "2818 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de partículas, de tamaño superior a 10 mm, inferior al 50 % del peso total"
 },
 {
  "CNKEY": "281810190080",
  "CN": "2818 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de partículas, de tamaño superior a 10 mm, superior o igual al 50 % del peso total"
 },
 {
  "CNKEY": "281810910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de óxido de aluminio inferior al 98,5 % en peso"
 },
 {
  "CNKEY": "281810910080",
  "CN": "2818 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de partículas, de tamaño superior a 10 mm, inferior al 50 % del peso total"
 },
 {
  "CNKEY": "281810990080",
  "CN": "2818 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de partículas, de tamaño superior a 10 mm, superior o igual al 50 % del peso total"
 },
 {
  "CNKEY": "281820000080",
  "CN": "2818 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxido de aluminio, excepto el corindón artificial"
 },
 {
  "CNKEY": "281830000080",
  "CN": "2818 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidróxido de aluminio"
 },
 {
  "CNKEY": "281900000080",
  "CN": "2819",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Óxidos e hidróxidos de cromo"
 },
 {
  "CNKEY": "281910000080",
  "CN": "2819 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trióxido de cromo"
 },
 {
  "CNKEY": "281990000080",
  "CN": "2819 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "281990100080",
  "CN": "2819 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dióxido de cromo"
 },
 {
  "CNKEY": "281990900080",
  "CN": "2819 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282000000080",
  "CN": "2820",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Óxidos de manganeso"
 },
 {
  "CNKEY": "282010000080",
  "CN": "2820 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dióxido de manganeso"
 },
 {
  "CNKEY": "282090000080",
  "CN": "2820 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282090100080",
  "CN": "2820 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxido de manganeso con un contenido de manganeso superior o igual al 77 % en peso"
 },
 {
  "CNKEY": "282090900080",
  "CN": "2820 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282100000080",
  "CN": "2821",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Óxidos e hidróxidos de hierro; tierras colorantes con un contenido de hierro combinado, expresado en Fe2O3, superior o igual al 70 % en peso"
 },
 {
  "CNKEY": "282110000080",
  "CN": "2821 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de hierro"
 },
 {
  "CNKEY": "282120000080",
  "CN": "2821 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tierras colorantes"
 },
 {
  "CNKEY": "282200000080",
  "CN": "2822 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de cobalto; óxidos de cobalto comerciales"
 },
 {
  "CNKEY": "282300000080",
  "CN": "2823 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Óxidos de titanio"
 },
 {
  "CNKEY": "282400000080",
  "CN": "2824",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Óxidos de plomo; minio y minio anaranjado"
 },
 {
  "CNKEY": "282410000080",
  "CN": "2824 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Monóxido de plomo (litargirio, masicote)"
 },
 {
  "CNKEY": "282490000080",
  "CN": "2824 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282500000080",
  "CN": "2825",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidrazina e hidroxilamina y sus sales inorgánicas; las demás bases inorgánicas; los demás óxidos, hidróxidos y peróxidos de metales"
 },
 {
  "CNKEY": "282510000080",
  "CN": "2825 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidrazina e hidroxilamina y sus sales inorgánicas"
 },
 {
  "CNKEY": "282520000080",
  "CN": "2825 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxido e hidróxido de litio"
 },
 {
  "CNKEY": "282530000080",
  "CN": "2825 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de vanadio"
 },
 {
  "CNKEY": "282540000080",
  "CN": "2825 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de níquel"
 },
 {
  "CNKEY": "282550000080",
  "CN": "2825 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de cobre"
 },
 {
  "CNKEY": "282560000080",
  "CN": "2825 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos de germanio y dióxido de circonio"
 },
 {
  "CNKEY": "282570000080",
  "CN": "2825 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de molibdeno"
 },
 {
  "CNKEY": "282580000080",
  "CN": "2825 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Óxidos de antimonio"
 },
 {
  "CNKEY": "282590000080",
  "CN": "2825 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282590110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Óxido, hidróxido y peróxido de calcio"
 },
 {
  "CNKEY": "282590110080",
  "CN": "2825 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hidróxido de calcio, con una pureza superior o igual al 98 % en peso, calculado sobre producto seco, en forma de partículas, de las cuales: - una cantidad inferior o igual al 1 % en peso, presentan un tamaño de partícula superior a 75 micrómetros y - una cantidad inferior o igual al 4 % en peso, presentan un tamaño de partícula inferior a 1,3 micrómetros"
 },
 {
  "CNKEY": "282590190080",
  "CN": "2825 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282590200080",
  "CN": "2825 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxido e hidróxido de berilio"
 },
 {
  "CNKEY": "282590400080",
  "CN": "2825 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxidos e hidróxidos de volframio (tungsteno)"
 },
 {
  "CNKEY": "282590600080",
  "CN": "2825 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxido de cadmio"
 },
 {
  "CNKEY": "282590850080",
  "CN": "2825 90 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282600000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "V. SALES Y PEROXOSALES METÁLICAS DE LOS ÁCIDOS INORGÁNICOS"
 },
 {
  "CNKEY": "282600000080",
  "CN": "2826",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fluoruros; fluorosilicatos, fluoroaluminatos y demás sales complejas de flúor"
 },
 {
  "CNKEY": "282612000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fluoruros"
 },
 {
  "CNKEY": "282612000080",
  "CN": "2826 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aluminio"
 },
 {
  "CNKEY": "282619000080",
  "CN": "2826 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282619100080",
  "CN": "2826 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De amonio o sodio"
 },
 {
  "CNKEY": "282619900080",
  "CN": "2826 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282630000080",
  "CN": "2826 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hexafluoroaluminato de sodio (criolita sintética)"
 },
 {
  "CNKEY": "282690000080",
  "CN": "2826 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282690100080",
  "CN": "2826 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hexafluorocirconato de dipotasio"
 },
 {
  "CNKEY": "282690800080",
  "CN": "2826 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282700000080",
  "CN": "2827",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cloruros, oxicloruros e hidroxicloruros; bromuros y oxibromuros; yoduros y oxiyoduros"
 },
 {
  "CNKEY": "282710000080",
  "CN": "2827 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloruro de amonio"
 },
 {
  "CNKEY": "282720000080",
  "CN": "2827 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloruro de calcio"
 },
 {
  "CNKEY": "282731000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás cloruros"
 },
 {
  "CNKEY": "282731000080",
  "CN": "2827 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De magnesio"
 },
 {
  "CNKEY": "282732000080",
  "CN": "2827 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aluminio"
 },
 {
  "CNKEY": "282735000080",
  "CN": "2827 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De níquel"
 },
 {
  "CNKEY": "282739000080",
  "CN": "2827 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282739100080",
  "CN": "2827 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De estaño"
 },
 {
  "CNKEY": "282739200080",
  "CN": "2827 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De hierro"
 },
 {
  "CNKEY": "282739300080",
  "CN": "2827 39 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cobalto"
 },
 {
  "CNKEY": "282739850080",
  "CN": "2827 39 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Oxicloruros e hidroxicloruros"
 },
 {
  "CNKEY": "282741000080",
  "CN": "2827 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cobre"
 },
 {
  "CNKEY": "282749000080",
  "CN": "2827 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282749100080",
  "CN": "2827 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plomo"
 },
 {
  "CNKEY": "282749900080",
  "CN": "2827 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bromuros y oxibromuros"
 },
 {
  "CNKEY": "282751000080",
  "CN": "2827 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bromuros de sodio o potasio"
 },
 {
  "CNKEY": "282759000080",
  "CN": "2827 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282760000080",
  "CN": "2827 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yoduros y oxiyoduros"
 },
 {
  "CNKEY": "282800000080",
  "CN": "2828",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hipocloritos; hipoclorito de calcio comercial; cloritos; hipobromitos"
 },
 {
  "CNKEY": "282810000080",
  "CN": "2828 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hipoclorito de calcio comercial y demás hipocloritos de calcio"
 },
 {
  "CNKEY": "282890000080",
  "CN": "2828 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282900000080",
  "CN": "2829",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cloratos y percloratos; bromatos y perbromatos; yodatos y peryodatos"
 },
 {
  "CNKEY": "282911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cloratos"
 },
 {
  "CNKEY": "282911000080",
  "CN": "2829 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De sodio"
 },
 {
  "CNKEY": "282919000080",
  "CN": "2829 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "282990000080",
  "CN": "2829 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "282990100080",
  "CN": "2829 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Percloratos"
 },
 {
  "CNKEY": "282990400080",
  "CN": "2829 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bromatos de potasio o sodio"
 },
 {
  "CNKEY": "282990800080",
  "CN": "2829 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283000000080",
  "CN": "2830",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfuros; polisulfuros, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "283010000080",
  "CN": "2830 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sulfuros de sodio"
 },
 {
  "CNKEY": "283090000080",
  "CN": "2830 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283090110080",
  "CN": "2830 90 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sulfuros de calcio, de antimonio o hierro"
 },
 {
  "CNKEY": "283090850080",
  "CN": "2830 90 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283100000080",
  "CN": "2831",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ditionitos y sulfoxilatos"
 },
 {
  "CNKEY": "283110000080",
  "CN": "2831 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De sodio"
 },
 {
  "CNKEY": "283190000080",
  "CN": "2831 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283200000080",
  "CN": "2832",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfitos; tiosulfatos"
 },
 {
  "CNKEY": "283210000080",
  "CN": "2832 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sulfitos de sodio"
 },
 {
  "CNKEY": "283220000080",
  "CN": "2832 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás sulfitos"
 },
 {
  "CNKEY": "283230000080",
  "CN": "2832 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tiosulfatos"
 },
 {
  "CNKEY": "283300000080",
  "CN": "2833",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfatos; alumbres; peroxosulfatos (persulfatos)"
 },
 {
  "CNKEY": "283311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sulfatos de sodio"
 },
 {
  "CNKEY": "283311000080",
  "CN": "2833 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sulfato de disodio"
 },
 {
  "CNKEY": "283319000080",
  "CN": "2833 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás sulfatos"
 },
 {
  "CNKEY": "283321000080",
  "CN": "2833 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De magnesio"
 },
 {
  "CNKEY": "283322000080",
  "CN": "2833 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aluminio"
 },
 {
  "CNKEY": "283324000080",
  "CN": "2833 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De níquel"
 },
 {
  "CNKEY": "283325000080",
  "CN": "2833 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cobre"
 },
 {
  "CNKEY": "283327000080",
  "CN": "2833 27 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bario"
 },
 {
  "CNKEY": "283329000080",
  "CN": "2833 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283329200080",
  "CN": "2833 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cadmio, cromo o cinc"
 },
 {
  "CNKEY": "283329300080",
  "CN": "2833 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cobalto o titanio"
 },
 {
  "CNKEY": "283329600080",
  "CN": "2833 29 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plomo"
 },
 {
  "CNKEY": "283329800080",
  "CN": "2833 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283330000080",
  "CN": "2833 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alumbres"
 },
 {
  "CNKEY": "283340000080",
  "CN": "2833 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Peroxosulfatos (persulfatos)"
 },
 {
  "CNKEY": "283400000080",
  "CN": "2834",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Nitritos; nitratos"
 },
 {
  "CNKEY": "283410000080",
  "CN": "2834 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Nitritos"
 },
 {
  "CNKEY": "283421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nitratos"
 },
 {
  "CNKEY": "283421000080",
  "CN": "2834 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potasio"
 },
 {
  "CNKEY": "283429000080",
  "CN": "2834 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283429200080",
  "CN": "2834 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De bario, berilio, cadmio, cobalto, plomo o níquel"
 },
 {
  "CNKEY": "283429400080",
  "CN": "2834 29 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cobre"
 },
 {
  "CNKEY": "283429800080",
  "CN": "2834 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283500000080",
  "CN": "2835",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fosfinatos (hipofosfitos), fosfonatos (fosfitos) y fosfatos; polifosfatos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "283510000080",
  "CN": "2835 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fosfinatos (hipofosfitos) y fosfonatos (fosfitos)"
 },
 {
  "CNKEY": "283522000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fosfatos"
 },
 {
  "CNKEY": "283522000080",
  "CN": "2835 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De monosodio o disodio"
 },
 {
  "CNKEY": "283524000080",
  "CN": "2835 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potasio"
 },
 {
  "CNKEY": "283525000080",
  "CN": "2835 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hidrogenoortofosfato de calcio («fosfato dicálcico»)"
 },
 {
  "CNKEY": "283526000080",
  "CN": "2835 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás fosfatos de calcio"
 },
 {
  "CNKEY": "283529000080",
  "CN": "2835 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283529100080",
  "CN": "2835 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De triamonio"
 },
 {
  "CNKEY": "283529300080",
  "CN": "2835 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De trisodio"
 },
 {
  "CNKEY": "283529900080",
  "CN": "2835 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polifosfatos"
 },
 {
  "CNKEY": "283531000080",
  "CN": "2835 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trifosfato de sodio (tripolifosfato de sodio)"
 },
 {
  "CNKEY": "283539000080",
  "CN": "2835 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283600000080",
  "CN": "2836",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carbonatos; peroxocarbonatos (percarbonatos); carbonato de amonio comercial que contenga carbamato de amonio"
 },
 {
  "CNKEY": "283620000080",
  "CN": "2836 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonato de disodio"
 },
 {
  "CNKEY": "283630000080",
  "CN": "2836 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidrogenocarbonato (bicarbonato) de sodio"
 },
 {
  "CNKEY": "283640000080",
  "CN": "2836 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonatos de potasio"
 },
 {
  "CNKEY": "283650000080",
  "CN": "2836 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonato de calcio"
 },
 {
  "CNKEY": "283660000080",
  "CN": "2836 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonato de bario"
 },
 {
  "CNKEY": "283691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283691000080",
  "CN": "2836 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carbonatos de litio"
 },
 {
  "CNKEY": "283692000080",
  "CN": "2836 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carbonato de estroncio"
 },
 {
  "CNKEY": "283699000080",
  "CN": "2836 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "283699110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Carbonatos"
 },
 {
  "CNKEY": "283699110080",
  "CN": "2836 99 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De magnesio o cobre"
 },
 {
  "CNKEY": "283699170080",
  "CN": "2836 99 17",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283699900080",
  "CN": "2836 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Peroxocarbonatos (percarbonatos)"
 },
 {
  "CNKEY": "283700000080",
  "CN": "2837",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cianuros, oxicianuros y cianuros complejos"
 },
 {
  "CNKEY": "283711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cianuros y oxicianuros"
 },
 {
  "CNKEY": "283711000080",
  "CN": "2837 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De sodio"
 },
 {
  "CNKEY": "283719000080",
  "CN": "2837 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283720000080",
  "CN": "2837 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cianuros complejos"
 },
 {
  "CNKEY": "283900000080",
  "CN": "2839",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Silicatos; silicatos comerciales de los metales alcalinos"
 },
 {
  "CNKEY": "283911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De sodio"
 },
 {
  "CNKEY": "283911000080",
  "CN": "2839 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metasilicatos"
 },
 {
  "CNKEY": "283919000080",
  "CN": "2839 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "283990000080",
  "CN": "2839 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284000000080",
  "CN": "2840",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Boratos; peroxoboratos (perboratos)"
 },
 {
  "CNKEY": "284011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tetraborato de disodio (bórax refinado)"
 },
 {
  "CNKEY": "284011000080",
  "CN": "2840 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anhidro"
 },
 {
  "CNKEY": "284019000080",
  "CN": "2840 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284019100080",
  "CN": "2840 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tetraborato disódico pentahidrato"
 },
 {
  "CNKEY": "284019900080",
  "CN": "2840 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284020000080",
  "CN": "2840 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás boratos"
 },
 {
  "CNKEY": "284020100080",
  "CN": "2840 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Boratos de sodio anhidros"
 },
 {
  "CNKEY": "284020900080",
  "CN": "2840 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284030000080",
  "CN": "2840 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Peroxoboratos (perboratos)"
 },
 {
  "CNKEY": "284100000080",
  "CN": "2841",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sales de los ácidos oxometálicos o peroxometálicos"
 },
 {
  "CNKEY": "284130000080",
  "CN": "2841 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dicromato de sodio"
 },
 {
  "CNKEY": "284150000080",
  "CN": "2841 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás cromatos y dicromatos; peroxocromatos"
 },
 {
  "CNKEY": "284161000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Manganitos, manganatos y permanganatos"
 },
 {
  "CNKEY": "284161000080",
  "CN": "2841 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Permanganato de potasio"
 },
 {
  "CNKEY": "284169000080",
  "CN": "2841 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284170000080",
  "CN": "2841 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Molibdatos"
 },
 {
  "CNKEY": "284180000080",
  "CN": "2841 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Volframatos (tungstatos)"
 },
 {
  "CNKEY": "284190000080",
  "CN": "2841 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284190300080",
  "CN": "2841 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cincatos o vanadatos"
 },
 {
  "CNKEY": "284190850080",
  "CN": "2841 90 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284200000080",
  "CN": "2842",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás sales de los ácidos o peroxoácidos inorgánicos (incluidos los aluminosilicatos, aunque no sean de constitución química definida), excepto los aziduros (azidas)"
 },
 {
  "CNKEY": "284210000080",
  "CN": "2842 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Silicatos dobles o complejos, incluidos los aluminosilicatos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "284290000080",
  "CN": "2842 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "284290100080",
  "CN": "2842 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sales simples, dobles o complejas de los ácidos del selenio o teluro"
 },
 {
  "CNKEY": "284290800080",
  "CN": "2842 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "284300000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "VI. VARIOS"
 },
 {
  "CNKEY": "284300000080",
  "CN": "2843",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Metal precioso en estado coloidal; compuestos inorgánicos u orgánicos de metal precioso, aunque no sean de constitución química definida; amalgamas de metal precioso"
 },
 {
  "CNKEY": "284310000080",
  "CN": "2843 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Metal precioso en estado coloidal"
 },
 {
  "CNKEY": "284310100080",
  "CN": "2843 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plata"
 },
 {
  "CNKEY": "284310900080",
  "CN": "2843 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos de plata"
 },
 {
  "CNKEY": "284321000080",
  "CN": "2843 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Nitrato de plata"
 },
 {
  "CNKEY": "284329000080",
  "CN": "2843 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284330000080",
  "CN": "2843 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Compuestos de oro"
 },
 {
  "CNKEY": "284390000080",
  "CN": "2843 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás compuestos; amalgamas"
 },
 {
  "CNKEY": "284390100080",
  "CN": "2843 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Amalgamas"
 },
 {
  "CNKEY": "284390900080",
  "CN": "2843 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284400000080",
  "CN": "2844",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Elementos químicos radiactivos e isótopos radiactivos (incluidos los elementos químicos e isótopos fisionables o fértiles) y sus compuestos; mezclas y residuos que contengan estos productos"
 },
 {
  "CNKEY": "284410000080",
  "CN": "2844 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Uranio natural y sus compuestos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan uranio natural o compuestos de uranio natural"
 },
 {
  "CNKEY": "284410100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Uranio natural"
 },
 {
  "CNKEY": "284410100080",
  "CN": "2844 10 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "kg U",
  "ES": "En bruto; desperdicios y desechos (Euratom)"
 },
 {
  "CNKEY": "284410300080",
  "CN": "2844 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "kg U",
  "ES": "Manufacturado (Euratom)"
 },
 {
  "CNKEY": "284410500080",
  "CN": "2844 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg U",
  "ES": "Ferrouranio"
 },
 {
  "CNKEY": "284410900080",
  "CN": "2844 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg U",
  "ES": "Los demás (Euratom)"
 },
 {
  "CNKEY": "284420000080",
  "CN": "2844 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Uranio enriquecido en U 235 y sus compuestos; plutonio y sus compuestos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan uranio enriquecido en U 235, plutonio o compuestos de estos productos"
 },
 {
  "CNKEY": "284420250010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Uranio enriquecido en U 235 y sus compuestos; aleaciones, dispersiones, incluido el cermet, productos cerámicos y mezclas, que contengan uranio enriquecido en U 235 o compuestos de estos productos"
 },
 {
  "CNKEY": "284420250080",
  "CN": "2844 20 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "gi F\/S",
  "ES": "Ferrouranio"
 },
 {
  "CNKEY": "284420350080",
  "CN": "2844 20 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "gi F\/S",
  "ES": "Los demás (Euratom)"
 },
 {
  "CNKEY": "284420510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Plutonio y sus compuestos; aleaciones, dispersiones, incluido el cermet, productos cerámicos y mezclas que contengan plutonio o compuestos de estos productos"
 },
 {
  "CNKEY": "284420510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezclas de uranio y plutonio"
 },
 {
  "CNKEY": "284420510080",
  "CN": "2844 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "gi F\/S",
  "ES": "Ferrouranio"
 },
 {
  "CNKEY": "284420590080",
  "CN": "2844 20 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "gi F\/S",
  "ES": "Los demás (Euratom)"
 },
 {
  "CNKEY": "284420990080",
  "CN": "2844 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "gi F\/S",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284430000080",
  "CN": "2844 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Uranio empobrecido en U 235 y sus compuestos; torio y sus compuestos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan uranio empobrecido en U 235, torio o compuestos de estos productos"
 },
 {
  "CNKEY": "284430110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Uranio empobrecido en U 235; aleaciones, dispersiones, incluido el cermet, productos cerámicos y mezclas, que contengan uranio empobrecido en U 235 o compuestos de este producto"
 },
 {
  "CNKEY": "284430110080",
  "CN": "2844 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cermet"
 },
 {
  "CNKEY": "284430190080",
  "CN": "2844 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284430510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Torio; aleaciones, dispersiones, incluido el cermet, productos cerámicos y mezclas que contengan torio o compuestos de este producto"
 },
 {
  "CNKEY": "284430510080",
  "CN": "2844 30 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cermet"
 },
 {
  "CNKEY": "284430550010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284430550080",
  "CN": "2844 30 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En bruto; desperdicios y desechos (Euratom)"
 },
 {
  "CNKEY": "284430610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Manufacturado"
 },
 {
  "CNKEY": "284430610080",
  "CN": "2844 30 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Barras, perfiles, alambre, chapas, bandas y hojas (Euratom)"
 },
 {
  "CNKEY": "284430690080",
  "CN": "2844 30 69",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás (Euratom)"
 },
 {
  "CNKEY": "284430910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Compuestos de uranio empobrecido en U 235 y compuestos de torio, incluso mezclados entre sí"
 },
 {
  "CNKEY": "284430910080",
  "CN": "2844 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De uranio empobrecido en U 235, de torio, incluso mezclados entre sí (Euratom) (excepto los de las sales de torio)"
 },
 {
  "CNKEY": "284430990080",
  "CN": "2844 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Elementos e isótopos y compuestos, radiactivos, excepto los de las subpartidas 284410, 284420 o 284430; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan estos elementos, isótopos o compuestos; residuos radiactivos"
 },
 {
  "CNKEY": "284441000080",
  "CN": "2844 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tritio y sus compuestos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan tritio o sus compuestos"
 },
 {
  "CNKEY": "284441100080",
  "CN": "2844 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Isótopo radiactivo artificial (Euratom);compuestos de isótopo radiactivo artificial (Euratom)"
 },
 {
  "CNKEY": "284441900080",
  "CN": "2844 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284442000080",
  "CN": "2844 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Actinio-225, actinio-227, californio-253, curio-240, curio-241, curio-242, curio-243, curio-244, einstenio-253, einstenio-254, gadolinio-148, polonio-208, polonio-209, polonio-210, radio-223, uranio-230 o uranio-232, y sus compuestos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan estos elementos o compuestos"
 },
 {
  "CNKEY": "284442100080",
  "CN": "2844 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Isótopos radiactivos artificiales (Euratom); compuestos de isótopos radiactivos artificiales (Euratom)"
 },
 {
  "CNKEY": "284442900080",
  "CN": "2844 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284443000080",
  "CN": "2844 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás elementos e isótopos y compuestos, radiactivos; aleaciones, dispersiones (incluido el cermet), productos cerámicos y mezclas, que contengan estos elementos, isótopos o compuestos"
 },
 {
  "CNKEY": "284443100080",
  "CN": "2844 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Uranio que contenga U 233 y sus compuestos; aleaciones, dispersiones, incluido el cermet, productos cerámicos y mezclas, que contengan U 233 o compuestos de este producto"
 },
 {
  "CNKEY": "284443200080",
  "CN": "2844 43 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Isótopos radiactivos artificiales (Euratom); compuestos de isótopos radiactivos artificiales (Euratom)"
 },
 {
  "CNKEY": "284443800080",
  "CN": "2844 43 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284444000080",
  "CN": "2844 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Residuos radiactivos"
 },
 {
  "CNKEY": "284450000080",
  "CN": "2844 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "gi F\/S",
  "ES": "Elementos combustibles (cartuchos) agotados (irradiados) de reactores nucleares (Euratom)"
 },
 {
  "CNKEY": "284500000080",
  "CN": "2845",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Isótopos, excepto los de la partida 2844; sus compuestos inorgánicos u orgánicos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "284510000080",
  "CN": "2845 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Agua pesada (óxido de deuterio) (Euratom)"
 },
 {
  "CNKEY": "284520000080",
  "CN": "2845 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Boro enriquecido en boro-10 y sus compuestos"
 },
 {
  "CNKEY": "284530000080",
  "CN": "2845 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Litio enriquecido en litio-6 y sus compuestos"
 },
 {
  "CNKEY": "284540000080",
  "CN": "2845 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Helio-3"
 },
 {
  "CNKEY": "284590000080",
  "CN": "2845 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284590100080",
  "CN": "2845 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Deuterio y compuestos de deuterio; hidrógeno y sus compuestos, enriquecidos en deuterio; mezclas y disoluciones que contengan estos productos (Euratom)"
 },
 {
  "CNKEY": "284590900080",
  "CN": "2845 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "284600000080",
  "CN": "2846",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos inorgánicos u orgánicos, de metales de las tierras raras, del itrio, del escandio o de las mezclas de estos metales"
 },
 {
  "CNKEY": "284610000080",
  "CN": "2846 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Compuestos de cerio"
 },
 {
  "CNKEY": "284690000080",
  "CN": "2846 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284690100080",
  "CN": "2846 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Compuestos de lantano, praseodimio, neodimio o samario"
 },
 {
  "CNKEY": "284690200080",
  "CN": "2846 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Compuestos de europio, gadolinio, terbio, disprosio, holmio, erbio, tulio, iterbio, lutecio o itrio"
 },
 {
  "CNKEY": "284690300080",
  "CN": "2846 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Compuestos de escandio"
 },
 {
  "CNKEY": "284690900080",
  "CN": "2846 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Compuestos de mezclas de metales"
 },
 {
  "CNKEY": "284700000080",
  "CN": "2847 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "kg H2O2",
  "ES": "Peróxido de hidrógeno (agua oxigenada), incluso solidificado con urea"
 },
 {
  "CNKEY": "284900000080",
  "CN": "2849",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carburos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "284910000080",
  "CN": "2849 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De calcio"
 },
 {
  "CNKEY": "284920000080",
  "CN": "2849 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De silicio"
 },
 {
  "CNKEY": "284990000080",
  "CN": "2849 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "284990100080",
  "CN": "2849 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De boro"
 },
 {
  "CNKEY": "284990300080",
  "CN": "2849 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De volframio (tungsteno)"
 },
 {
  "CNKEY": "284990500080",
  "CN": "2849 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aluminio, cromo, molibdeno, vanadio, tántalo o titanio"
 },
 {
  "CNKEY": "284990900080",
  "CN": "2849 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "285000000080",
  "CN": "2850 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidruros, nitruros, aziduros (azidas), siliciuros y boruros, aunque no sean de constitución química definida, excepto los compuestos que consistan igualmente en carburos de la partida 2849"
 },
 {
  "CNKEY": "285000200080",
  "CN": "2850 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidruros y nitruros"
 },
 {
  "CNKEY": "285000600080",
  "CN": "2850 00 60",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aziduros (azidas); siliciuros"
 },
 {
  "CNKEY": "285000900080",
  "CN": "2850 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Boruros"
 },
 {
  "CNKEY": "285200000080",
  "CN": "2852",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos inorgánicos u orgánicos de mercurio, aunque no sean de constitución química definida, excepto las amalgamas"
 },
 {
  "CNKEY": "285210000080",
  "CN": "2852 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De constitución química definida"
 },
 {
  "CNKEY": "285290000080",
  "CN": "2852 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "285300000080",
  "CN": "2853",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fosfuros, aunque no sean de constitución química definida, excepto los ferrofósforos; los demás compuestos inorganicos (incluida el agua destilada, de conductividad o del mismo grado de pureza); aire líquido, aunque se le hayan eliminado los gases nobles; aire comprimido; amalgamas, excepto las de metal precioso"
 },
 {
  "CNKEY": "285310000080",
  "CN": "2853 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloruro de cianógeno («chlorcyan»)"
 },
 {
  "CNKEY": "285390000080",
  "CN": "2853 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "285390100080",
  "CN": "2853 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agua destilada, de conductibilidad o del mismo grado de pureza"
 },
 {
  "CNKEY": "285390300080",
  "CN": "2853 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aire líquido, aunque se le hayan eliminado los gases nobles; aire comprimido"
 },
 {
  "CNKEY": "285390900080",
  "CN": "2853 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290021000090",
  "CN": "29",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 29 - PRODUCTOS QUÍMICOS ORGÁNICOS"
 },
 {
  "CNKEY": "290100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. HIDROCARBUROS Y SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "290100000080",
  "CN": "2901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidrocarburos acíclicos"
 },
 {
  "CNKEY": "290110000080",
  "CN": "2901 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Saturados"
 },
 {
  "CNKEY": "290121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "No saturados"
 },
 {
  "CNKEY": "290121000080",
  "CN": "2901 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etileno"
 },
 {
  "CNKEY": "290122000080",
  "CN": "2901 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Propeno (propileno)"
 },
 {
  "CNKEY": "290123000080",
  "CN": "2901 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Buteno (butileno) y sus isómeros"
 },
 {
  "CNKEY": "290124000080",
  "CN": "2901 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Buta-1,3-dieno e isopreno"
 },
 {
  "CNKEY": "290129000080",
  "CN": "2901 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290200000080",
  "CN": "2902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hidrocarburos cíclicos"
 },
 {
  "CNKEY": "290211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ciclánicos, ciclénicos o cicloterpénicos"
 },
 {
  "CNKEY": "290211000080",
  "CN": "2902 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciclohexano"
 },
 {
  "CNKEY": "290219000080",
  "CN": "2902 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290220000080",
  "CN": "2902 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Benceno"
 },
 {
  "CNKEY": "290230000080",
  "CN": "2902 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tolueno"
 },
 {
  "CNKEY": "290241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Xilenos"
 },
 {
  "CNKEY": "290241000080",
  "CN": "2902 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "o-Xileno"
 },
 {
  "CNKEY": "290242000080",
  "CN": "2902 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "m-Xileno"
 },
 {
  "CNKEY": "290243000080",
  "CN": "2902 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "p-Xileno"
 },
 {
  "CNKEY": "290244000080",
  "CN": "2902 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclas de isómeros del xileno"
 },
 {
  "CNKEY": "290250000080",
  "CN": "2902 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Estireno"
 },
 {
  "CNKEY": "290260000080",
  "CN": "2902 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Etilbenceno"
 },
 {
  "CNKEY": "290270000080",
  "CN": "2902 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cumeno"
 },
 {
  "CNKEY": "290290000080",
  "CN": "2902 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290300000080",
  "CN": "2903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Derivados halogenados de los hidrocarburos"
 },
 {
  "CNKEY": "290311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados clorados de los hidrocarburos acíclicos saturados"
 },
 {
  "CNKEY": "290311000080",
  "CN": "2903 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorometano (cloruro de metilo) y cloroetano (cloruro de etilo)"
 },
 {
  "CNKEY": "290312000080",
  "CN": "2903 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diclorometano (cloruro de metileno)"
 },
 {
  "CNKEY": "290313000080",
  "CN": "2903 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cloroformo (triclorometano)"
 },
 {
  "CNKEY": "290314000080",
  "CN": "2903 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tetracloruro de carbono"
 },
 {
  "CNKEY": "290315000080",
  "CN": "2903 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloruro de etileno (ISO) (1,2-dicloroetano)"
 },
 {
  "CNKEY": "290319000080",
  "CN": "2903 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados clorados de los hidrocarburos acíclicos no saturados"
 },
 {
  "CNKEY": "290321000080",
  "CN": "2903 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cloruro de vinilo (cloroetileno)"
 },
 {
  "CNKEY": "290322000080",
  "CN": "2903 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tricloroetileno"
 },
 {
  "CNKEY": "290323000080",
  "CN": "2903 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tetracloroetileno (percloroetileno)"
 },
 {
  "CNKEY": "290329000080",
  "CN": "2903 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados fluorados de los hidrocarburos acíclicos saturados"
 },
 {
  "CNKEY": "290341000080",
  "CN": "2903 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trifluorometano (HFC-23)"
 },
 {
  "CNKEY": "290342000080",
  "CN": "2903 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Difluorometano (HFC-32)"
 },
 {
  "CNKEY": "290343000080",
  "CN": "2903 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fluorometano (HFC-41), 1,2-difluoroetano (HFC-152) y 1,1-difluoroetano (HFC-152a)"
 },
 {
  "CNKEY": "290344000080",
  "CN": "2903 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pentafluoroetano (HFC-125), 1,1,1-trifluoroetano (HFC-143a) y 1,1,2-trifluoroetano (HFC-143)"
 },
 {
  "CNKEY": "290345000080",
  "CN": "2903 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1,1,1,2-Tetrafluoroetano (HFC-134a) y 1,1,2,2-tetrafluoroetano (HFC-134)"
 },
 {
  "CNKEY": "290346000080",
  "CN": "2903 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1,1,1,2,3,3,3-Heptafluoropropano (HFC-227ea), 1,1,1,2,2,3-hexafluoropropano (HFC-236cb), 1,1,1,2,3,3-hexafluoropropano (HFC-236ea) y 1,1,1,3,3,3-hexafluoropropano (HFC-236fa)"
 },
 {
  "CNKEY": "290347000080",
  "CN": "2903 47 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1,1,1,3,3-Pentafluoropropano (HFC-245fa) y 1,1,2,2,3-pentafluoropropano (HFC-245ca)"
 },
 {
  "CNKEY": "290348000080",
  "CN": "2903 48 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1,1,1,3,3-Pentafluorobutano (HFC-365mfc) y 1,1,1,2,2,3,4,5,5,5-decafluoropentano (HFC-43-10mee)"
 },
 {
  "CNKEY": "290349000080",
  "CN": "2903 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290349100080",
  "CN": "2903 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás pentafluoropropanos, hexafluoropropanos y heptafluoropropanos"
 },
 {
  "CNKEY": "290349300080",
  "CN": "2903 49 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Derivados perfluorados"
 },
 {
  "CNKEY": "290349900080",
  "CN": "2903 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290351000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados fluorados de los hidrocarburos acíclicos no saturados"
 },
 {
  "CNKEY": "290351000080",
  "CN": "2903 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2,3,3,3-Tetrafluoropropeno (HFO-1234yf), 1,3,3,3-tetrafluoropropeno (HFO-1234ze) y (Z)-1,1,1,4,4,4-hexafluoro-2-buteno (HFO-1336mzz)"
 },
 {
  "CNKEY": "290359000080",
  "CN": "2903 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290361000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados bromados o derivados yodados, de los hidrocarburos acíclicos"
 },
 {
  "CNKEY": "290361000080",
  "CN": "2903 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bromuro de metilo (bromometano)"
 },
 {
  "CNKEY": "290362000080",
  "CN": "2903 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dibromuro de etileno (ISO) (1,2-dibromoetano)"
 },
 {
  "CNKEY": "290369000080",
  "CN": "2903 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290369110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bromuros"
 },
 {
  "CNKEY": "290369110080",
  "CN": "2903 69 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Dibromometano"
 },
 {
  "CNKEY": "290369190080",
  "CN": "2903 69 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290369800080",
  "CN": "2903 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Yoduros"
 },
 {
  "CNKEY": "290371000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados halogenados de los hidrocarburos acíclicos con dos halógenos diferentes, por lo menos"
 },
 {
  "CNKEY": "290371000080",
  "CN": "2903 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorodifluorometano (HCFC-22)"
 },
 {
  "CNKEY": "290372000080",
  "CN": "2903 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diclorotrifluoroetanos (HCFC-123)"
 },
 {
  "CNKEY": "290373000080",
  "CN": "2903 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diclorofluoroetanos (HCFC-141, 141b)"
 },
 {
  "CNKEY": "290374000080",
  "CN": "2903 74 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorodifluoroetanos (HCFC-142, 142b)"
 },
 {
  "CNKEY": "290375000080",
  "CN": "2903 75 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloropentafluoropropanos (HCFC-225, 225ca, 225cb)"
 },
 {
  "CNKEY": "290376000080",
  "CN": "2903 76",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bromoclorodifluorometano (Halón-1211), bromotrifluorometano (Halón-1301) y dibromotetrafluoroetanos (Halón-2402)"
 },
 {
  "CNKEY": "290376100080",
  "CN": "2903 76 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bromoclorodifluorometano (Halón-1211)"
 },
 {
  "CNKEY": "290376200080",
  "CN": "2903 76 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bromotrifluorometano (Halón-1301)"
 },
 {
  "CNKEY": "290376900080",
  "CN": "2903 76 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dibromotetrafluoroetanos (Halón-2402)"
 },
 {
  "CNKEY": "290377000080",
  "CN": "2903 77",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, perhalogenados solamente con flúor y cloro"
 },
 {
  "CNKEY": "290377600080",
  "CN": "2903 77 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Triclorofluorometano, diclorodifluorometano, triclorotrifluoroetanos, diclorotetrafluoroetanos y cloropentafluoroetano"
 },
 {
  "CNKEY": "290377900080",
  "CN": "2903 77 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290378000080",
  "CN": "2903 78 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás derivados perhalogenados"
 },
 {
  "CNKEY": "290379000080",
  "CN": "2903 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290379300080",
  "CN": "2903 79 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Únicamente bromados y clorados, fluorados y clorados, o fluorados y bromados"
 },
 {
  "CNKEY": "290379800080",
  "CN": "2903 79 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290381000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados halogenados de los hidrocarburos ciclánicos, ciclénicos o cicloterpénicos"
 },
 {
  "CNKEY": "290381000080",
  "CN": "2903 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1,2,3,4,5,6-Hexaclorociclohexano (HCH (ISO)), incluido el lindano (ISO, DCI)"
 },
 {
  "CNKEY": "290382000080",
  "CN": "2903 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aldrina (ISO), clordano (ISO) y heptacloro (ISO)"
 },
 {
  "CNKEY": "290383000080",
  "CN": "2903 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mirex (ISO)"
 },
 {
  "CNKEY": "290389000080",
  "CN": "2903 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290389100080",
  "CN": "2903 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1,2-Dibromo-4-(1,2-dibromoetil)ciclohexano; tetrabromociclooctanos"
 },
 {
  "CNKEY": "290389800080",
  "CN": "2903 89 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados halogenados de los hidrocarburos aromáticos"
 },
 {
  "CNKEY": "290391000080",
  "CN": "2903 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorobenceno, o-diclorobenceno y p-diclorobenceno"
 },
 {
  "CNKEY": "290392000080",
  "CN": "2903 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hexaclorobenceno (ISO) y DDT (ISO) [clofenotano (DCI), 1,1,1-tricloro-2,2-bis(p-clorofenil)etano]"
 },
 {
  "CNKEY": "290393000080",
  "CN": "2903 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pentaclorobenceno (ISO)"
 },
 {
  "CNKEY": "290394000080",
  "CN": "2903 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hexabromobifenilos"
 },
 {
  "CNKEY": "290399000080",
  "CN": "2903 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290399100080",
  "CN": "2903 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2,3,4,5,6-Pentabromoetilbenceno"
 },
 {
  "CNKEY": "290399800080",
  "CN": "2903 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290400000080",
  "CN": "2904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Derivados sulfonados, nitrados o nitrosados de los hidrocarburos, incluso halogenados"
 },
 {
  "CNKEY": "290410000080",
  "CN": "2904 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Derivados solamente sulfonados, sus sales y sus ésteres etílicos"
 },
 {
  "CNKEY": "290420000080",
  "CN": "2904 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Derivados solamente nitrados o solamente nitrosados"
 },
 {
  "CNKEY": "290431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácido perfluorooctano sulfónico, sus sales y fluoruro perfluorooctano sulfonilo"
 },
 {
  "CNKEY": "290431000080",
  "CN": "2904 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido perfluorooctano sulfónico"
 },
 {
  "CNKEY": "290432000080",
  "CN": "2904 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfluorooctano sulfonato de amonio"
 },
 {
  "CNKEY": "290433000080",
  "CN": "2904 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfluorooctano sulfonato de litio"
 },
 {
  "CNKEY": "290434000080",
  "CN": "2904 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfluorooctano sulfonato de potasio"
 },
 {
  "CNKEY": "290435000080",
  "CN": "2904 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás sales del ácido perfluorooctano sulfónico"
 },
 {
  "CNKEY": "290436000080",
  "CN": "2904 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fluoruro de perfluorooctano sulfonilo"
 },
 {
  "CNKEY": "290491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290491000080",
  "CN": "2904 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tricloronitrometano (cloropicrina)"
 },
 {
  "CNKEY": "290499000080",
  "CN": "2904 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290500000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. ALCOHOLES Y SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "290500000080",
  "CN": "2905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alcoholes acíclicos y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monoalcoholes saturados"
 },
 {
  "CNKEY": "290511000080",
  "CN": "2905 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metanol (alcohol metílico)"
 },
 {
  "CNKEY": "290512000080",
  "CN": "2905 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Propan-1-ol (alcohol propílico) y propan-2-ol (alcohol isopropílico)"
 },
 {
  "CNKEY": "290513000080",
  "CN": "2905 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Butan-1-ol (alcohol n-butílico)"
 },
 {
  "CNKEY": "290514000080",
  "CN": "2905 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás butanoles"
 },
 {
  "CNKEY": "290514100080",
  "CN": "2905 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2-Metilpropano-2-ol (alcohol terc-butílico)"
 },
 {
  "CNKEY": "290514900080",
  "CN": "2905 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290516000080",
  "CN": "2905 16",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Octanol (alcohol octílico) y sus isómeros"
 },
 {
  "CNKEY": "290516200080",
  "CN": "2905 16 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Octano-2-ol"
 },
 {
  "CNKEY": "290516850080",
  "CN": "2905 16 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290517000080",
  "CN": "2905 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dodecan-1-ol (alcohol laurílico), hexadecan-1-ol (alcohol cetílico) y octadecan-1-ol (alcohol estearílico)"
 },
 {
  "CNKEY": "290519000080",
  "CN": "2905 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290522000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monoalcoholes no saturados"
 },
 {
  "CNKEY": "290522000080",
  "CN": "2905 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alcoholes terpénicos acíclicos"
 },
 {
  "CNKEY": "290529000080",
  "CN": "2905 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290529100080",
  "CN": "2905 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Alcohol alílico"
 },
 {
  "CNKEY": "290529900080",
  "CN": "2905 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Dioles"
 },
 {
  "CNKEY": "290531000080",
  "CN": "2905 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etilenglicol (etanodiol)"
 },
 {
  "CNKEY": "290532000080",
  "CN": "2905 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Propilenglicol (propano-1,2-diol)"
 },
 {
  "CNKEY": "290539000080",
  "CN": "2905 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290539200080",
  "CN": "2905 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Butano-1,3-diol"
 },
 {
  "CNKEY": "290539260010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Butano-1,4-diol"
 },
 {
  "CNKEY": "290539260080",
  "CN": "2905 39 26",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Butano-1,4-diol o tetrametilenglicol (1,4-butanodiol) con un contenido de carbono de origen biológico del 100 % en masa"
 },
 {
  "CNKEY": "290539280080",
  "CN": "2905 39 28",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290539300080",
  "CN": "2905 39 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2,4,7,9-Tetrametildec-5-ino-4,7-diol"
 },
 {
  "CNKEY": "290539950080",
  "CN": "2905 39 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás polialcoholes"
 },
 {
  "CNKEY": "290541000080",
  "CN": "2905 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2-Etil-2-(hidroximetil)propano-1,3-diol (trimetilolpropano)"
 },
 {
  "CNKEY": "290542000080",
  "CN": "2905 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pentaeritritol (pentaeritrita)"
 },
 {
  "CNKEY": "290543000080",
  "CN": "2905 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Manitol"
 },
 {
  "CNKEY": "290544000080",
  "CN": "2905 44",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "D-glucitol (sorbitol)"
 },
 {
  "CNKEY": "290544110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En disolución acuosa"
 },
 {
  "CNKEY": "290544110080",
  "CN": "2905 44 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que contenga D-manitol en una proporción inferior o igual al 2 % en peso, calculada sobre el contenido de D-glucitol"
 },
 {
  "CNKEY": "290544190080",
  "CN": "2905 44 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290544910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290544910080",
  "CN": "2905 44 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que contengan D-manitol en una proporción inferior o igual al 2 % en peso, calculada sobre el contenido de D-glucitol"
 },
 {
  "CNKEY": "290544990080",
  "CN": "2905 44 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290545000080",
  "CN": "2905 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Glicerol"
 },
 {
  "CNKEY": "290549000080",
  "CN": "2905 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados halogenados, sulfonados, nitrados o nitrosados de los alcoholes acíclicos"
 },
 {
  "CNKEY": "290551000080",
  "CN": "2905 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etclorvinol (DCI)"
 },
 {
  "CNKEY": "290559000080",
  "CN": "2905 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290559910080",
  "CN": "2905 59 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2,2-Bis(bromometil)propanodiol"
 },
 {
  "CNKEY": "290559980080",
  "CN": "2905 59 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290600000080",
  "CN": "2906",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alcoholes cíclicos y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ciclánicos, ciclénicos o cicloterpénicos"
 },
 {
  "CNKEY": "290611000080",
  "CN": "2906 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mentol"
 },
 {
  "CNKEY": "290612000080",
  "CN": "2906 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciclohexanol, metilciclohexanoles y dimetilciclohexanoles"
 },
 {
  "CNKEY": "290613000080",
  "CN": "2906 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Esteroles e inositoles"
 },
 {
  "CNKEY": "290613100080",
  "CN": "2906 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Esteroles"
 },
 {
  "CNKEY": "290613900080",
  "CN": "2906 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inositoles"
 },
 {
  "CNKEY": "290619000080",
  "CN": "2906 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aromáticos"
 },
 {
  "CNKEY": "290621000080",
  "CN": "2906 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alcohol bencílico"
 },
 {
  "CNKEY": "290629000080",
  "CN": "2906 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290700000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "III. FENOLES Y FENOLES-ALCOHOLES Y SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "290700000080",
  "CN": "2907",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fenoles; fenoles-alcoholes"
 },
 {
  "CNKEY": "290711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monofenoles"
 },
 {
  "CNKEY": "290711000080",
  "CN": "2907 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fenol (hidroxibenceno) y sus sales"
 },
 {
  "CNKEY": "290712000080",
  "CN": "2907 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cresoles y sus sales"
 },
 {
  "CNKEY": "290713000080",
  "CN": "2907 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Octilfenol, nonilfenol y sus isómeros; sales de estos productos"
 },
 {
  "CNKEY": "290715000080",
  "CN": "2907 15",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Naftoles y sus sales"
 },
 {
  "CNKEY": "290715100080",
  "CN": "2907 15 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1-Naftol"
 },
 {
  "CNKEY": "290715900080",
  "CN": "2907 15 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290719000080",
  "CN": "2907 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290719100080",
  "CN": "2907 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Xilenoles y sus sales"
 },
 {
  "CNKEY": "290719900080",
  "CN": "2907 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polifenoles; fenoles-alcoholes"
 },
 {
  "CNKEY": "290721000080",
  "CN": "2907 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Resorcinol y sus sales"
 },
 {
  "CNKEY": "290722000080",
  "CN": "2907 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hidroquinona y sus sales"
 },
 {
  "CNKEY": "290723000080",
  "CN": "2907 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "4,4′-Isopropilidendifenol (bisfenol A, difenilolpropano) y sus sales"
 },
 {
  "CNKEY": "290729000080",
  "CN": "2907 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290800000080",
  "CN": "2908",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Derivados halogenados, sulfonados, nitrados o nitrosados, de los fenoles o de los fenoles-alcoholes"
 },
 {
  "CNKEY": "290811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados solamente halogenados y sus sales"
 },
 {
  "CNKEY": "290811000080",
  "CN": "2908 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pentaclorofenol (ISO)"
 },
 {
  "CNKEY": "290819000080",
  "CN": "2908 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290891000080",
  "CN": "2908 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dinoseb (ISO) y sus sales"
 },
 {
  "CNKEY": "290892000080",
  "CN": "2908 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "4,6-Dinitro-o-cresol [DNOC (ISO)] y sus sales"
 },
 {
  "CNKEY": "290899000080",
  "CN": "2908 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290900000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "IV. ÉTERES, PERÓXIDOS DE ALCOHOLES, PERÓXIDOS DE ÉTERES, PERÓXIDOS DE ACETALES Y DE HEMIACETALES, PERÓXIDOS DE CETONAS, EPÓXIDOS CON TRES ÁTOMOS EN EL CICLO, ACETALES Y SEMIACETALES, Y SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "290900000080",
  "CN": "2909",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Éteres, éteres-alcoholes, éteres-fenoles, éteres-alcoholes-fenoles, peróxidos de alcoholes, peróxidos de éteres, peróxidos de acetales y de semiacetales, peróxidos de cetonas (aunque no sean de constitución química definida), y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Éteres acíclicos y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290911000080",
  "CN": "2909 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Éter dietílico (óxido de dietilo)"
 },
 {
  "CNKEY": "290919000080",
  "CN": "2909 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290919100080",
  "CN": "2909 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Terc-butil etil éter (etil-terc-butil-éter, ETBE)"
 },
 {
  "CNKEY": "290919900080",
  "CN": "2909 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290920000080",
  "CN": "2909 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Éteres ciclánicos, ciclénicos, cicloterpénicos, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290930000080",
  "CN": "2909 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Éteres aromáticos y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290930100080",
  "CN": "2909 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Éter difenílico (óxido de difenilo)"
 },
 {
  "CNKEY": "290930310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Derivados halogenados únicamente con bromo"
 },
 {
  "CNKEY": "290930310080",
  "CN": "2909 30 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Éter de pentabromodifenilo; 1,2,4,5-tetrabromo-3,6-bis(pentabromofenoxi)benceno"
 },
 {
  "CNKEY": "290930350080",
  "CN": "2909 30 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1,2-Bis(2,4,6-tribromofenoxi)etano, destinado a la fabricación de acrilonitrilo-butadieno-estireno (ABS)"
 },
 {
  "CNKEY": "290930380080",
  "CN": "2909 30 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290930900080",
  "CN": "2909 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Éteres-alcoholes y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290941000080",
  "CN": "2909 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2,2′-Oxidietanol (dietilenglicol)"
 },
 {
  "CNKEY": "290943000080",
  "CN": "2909 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Éteres monobutílicos del etilenglicol o del dietilenglicol"
 },
 {
  "CNKEY": "290944000080",
  "CN": "2909 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás éteres monoalquílicos del etilenglicol o del dietilenglicol"
 },
 {
  "CNKEY": "290949000080",
  "CN": "2909 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "290949110080",
  "CN": "2909 49 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2-(2-Cloroetoxi)etanol"
 },
 {
  "CNKEY": "290949800080",
  "CN": "2909 49 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "290950000080",
  "CN": "2909 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Éteres-fenoles, éteres-alcoholes-fenoles, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290960000080",
  "CN": "2909 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peróxidos de alcoholes, peróxidos de éteres, peróxidos de acetales y de semiacetales, peróxidos de cetonas, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "290960100080",
  "CN": "2909 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peróxidos de acetales y de semiacetales"
 },
 {
  "CNKEY": "290960900080",
  "CN": "2909 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291000000080",
  "CN": "2910",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Epóxidos, epoxialcoholes, epoxifenoles y epoxiéteres, con tres átomos en el ciclo, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291010000080",
  "CN": "2910 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Oxirano (óxido de etileno)"
 },
 {
  "CNKEY": "291020000080",
  "CN": "2910 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Metiloxirano (óxido de propileno)"
 },
 {
  "CNKEY": "291030000080",
  "CN": "2910 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "1-Cloro-2,3-epoxipropano (epiclorhidrina)"
 },
 {
  "CNKEY": "291040000080",
  "CN": "2910 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dieldrina (ISO, DCI)"
 },
 {
  "CNKEY": "291050000080",
  "CN": "2910 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Endrina (ISO)"
 },
 {
  "CNKEY": "291090000080",
  "CN": "2910 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291100000080",
  "CN": "2911 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Acetales y semiacetales, incluso con otras funciones oxigenadas, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291200000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "V. COMPUESTOS CON FUNCIÓN ALDEHÍDO"
 },
 {
  "CNKEY": "291200000080",
  "CN": "2912",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aldehídos, incluso con otras funciones oxigenadas; polímeros cíclicos de los aldehídos; paraformaldehído"
 },
 {
  "CNKEY": "291211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aldehídos acíclicos sin otras funciones oxigenadas"
 },
 {
  "CNKEY": "291211000080",
  "CN": "2912 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metanal (formaldehído)"
 },
 {
  "CNKEY": "291212000080",
  "CN": "2912 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etanal (acetaldehído)"
 },
 {
  "CNKEY": "291219000080",
  "CN": "2912 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aldehídos cíclicos sin otras funciones oxigenadas"
 },
 {
  "CNKEY": "291221000080",
  "CN": "2912 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Benzaldehído (aldehído benzoico)"
 },
 {
  "CNKEY": "291229000080",
  "CN": "2912 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aldehídos-alcoholes, aldehídos-éteres, aldehídos-fenoles y aldehídos con otras funciones oxigenadas"
 },
 {
  "CNKEY": "291241000080",
  "CN": "2912 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vainillina (aldehído metilprotocatéquico)"
 },
 {
  "CNKEY": "291242000080",
  "CN": "2912 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etilvainillina (aldehído etilprotocatéquico)"
 },
 {
  "CNKEY": "291249000080",
  "CN": "2912 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291250000080",
  "CN": "2912 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polímeros cíclicos de los aldehídos"
 },
 {
  "CNKEY": "291260000080",
  "CN": "2912 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Paraformaldehído"
 },
 {
  "CNKEY": "291300000080",
  "CN": "2913 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Derivados halogenados, sulfonados, nitrados o nitrosados de los productos de la partida 2912"
 },
 {
  "CNKEY": "291400000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "VI. COMPUESTOS CON FUNCIÓN CETONA O CON FUNCIÓN QUINONA"
 },
 {
  "CNKEY": "291400000080",
  "CN": "2914",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cetonas y quinonas, incluso con otras funciones oxigenadas, y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cetonas acíclicas sin otras funciones oxigenadas"
 },
 {
  "CNKEY": "291411000080",
  "CN": "2914 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acetona"
 },
 {
  "CNKEY": "291412000080",
  "CN": "2914 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Butanona (metiletilcetona)"
 },
 {
  "CNKEY": "291413000080",
  "CN": "2914 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "4-Metilpentan-2-ona (metilisobutilcetona)"
 },
 {
  "CNKEY": "291419000080",
  "CN": "2914 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "291419100080",
  "CN": "2914 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "5-Metilhexan-2-ona"
 },
 {
  "CNKEY": "291419900080",
  "CN": "2914 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291422000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cetonas ciclánicas, ciclénicas o cicloterpénicas, sin otras funciones oxigenadas"
 },
 {
  "CNKEY": "291422000080",
  "CN": "2914 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciclohexanona y metilciclohexanonas"
 },
 {
  "CNKEY": "291423000080",
  "CN": "2914 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Iononas y metiliononas"
 },
 {
  "CNKEY": "291429000080",
  "CN": "2914 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cetonas aromáticas sin otras funciones oxigenadas"
 },
 {
  "CNKEY": "291431000080",
  "CN": "2914 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fenilacetona (fenilpropan-2-ona)"
 },
 {
  "CNKEY": "291439000080",
  "CN": "2914 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291440000080",
  "CN": "2914 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cetonas-alcoholes y cetonas-aldehídos"
 },
 {
  "CNKEY": "291440100080",
  "CN": "2914 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "4-Hidroxi-4-metilpentan-2-ona (diacetona alcohol)"
 },
 {
  "CNKEY": "291440900080",
  "CN": "2914 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291450000080",
  "CN": "2914 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cetonas-fenoles y cetonas con otras funciones oxigenadas"
 },
 {
  "CNKEY": "291461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Quinonas"
 },
 {
  "CNKEY": "291461000080",
  "CN": "2914 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Antraquinona"
 },
 {
  "CNKEY": "291462000080",
  "CN": "2914 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coenzima Q10 (ubidecarenona (DCI))"
 },
 {
  "CNKEY": "291469000080",
  "CN": "2914 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "291469100080",
  "CN": "2914 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1,4-Naftoquinona"
 },
 {
  "CNKEY": "291469800080",
  "CN": "2914 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291471000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291471000080",
  "CN": "2914 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clordecona (ISO)"
 },
 {
  "CNKEY": "291479000080",
  "CN": "2914 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291500000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "VII. ÁCIDOS CARBOXÍLICOS, SUS ANHÍDRIDOS, HALOGENUROS, PERÓXIDOS Y PEROXIÁCIDOS; SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "291500000080",
  "CN": "2915",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos monocarboxílicos acíclicos saturados y sus anhídridos, halogenuros, peróxidos y peroxiácidos; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácido fórmico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291511000080",
  "CN": "2915 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido fórmico"
 },
 {
  "CNKEY": "291512000080",
  "CN": "2915 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sales del ácido fórmico"
 },
 {
  "CNKEY": "291513000080",
  "CN": "2915 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres del ácido fórmico"
 },
 {
  "CNKEY": "291521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácido acético y sus sales; anhídrido acético"
 },
 {
  "CNKEY": "291521000080",
  "CN": "2915 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido acético"
 },
 {
  "CNKEY": "291524000080",
  "CN": "2915 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anhídrido acético"
 },
 {
  "CNKEY": "291529000080",
  "CN": "2915 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "291531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ésteres del ácido acético"
 },
 {
  "CNKEY": "291531000080",
  "CN": "2915 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acetato de etilo"
 },
 {
  "CNKEY": "291532000080",
  "CN": "2915 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acetato de vinilo"
 },
 {
  "CNKEY": "291533000080",
  "CN": "2915 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acetato de n-butilo"
 },
 {
  "CNKEY": "291536000080",
  "CN": "2915 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acetato de dinoseb (ISO)"
 },
 {
  "CNKEY": "291539000080",
  "CN": "2915 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291540000080",
  "CN": "2915 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácidos mono-, di- o tricloroacéticos, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291550000080",
  "CN": "2915 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácido propiónico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291560000080",
  "CN": "2915 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos butanoicos, ácidos pentanoicos, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291560110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Ácidos butanoicos, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291560110080",
  "CN": "2915 60 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Diisobutirato de 1-isopropil-2,2-dimetiltrimetileno"
 },
 {
  "CNKEY": "291560190080",
  "CN": "2915 60 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291560900080",
  "CN": "2915 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácidos pentanoicos, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291570000080",
  "CN": "2915 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácido palmítico, ácido esteárico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291570400080",
  "CN": "2915 70 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido palmítico, sus sales y esteres"
 },
 {
  "CNKEY": "291570500080",
  "CN": "2915 70 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido esteárico, sus sales y esteres"
 },
 {
  "CNKEY": "291590000080",
  "CN": "2915 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291590300080",
  "CN": "2915 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido láurico, sus sales y esteres"
 },
 {
  "CNKEY": "291590700080",
  "CN": "2915 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291600000080",
  "CN": "2916",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos monocarboxílicos acíclicos no saturados y ácidos monocarboxílicos cíclicos, sus anhídridos, halogenuros, peróxidos y peroxiácidos; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos monocarboxílicos acíclicos no saturados, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291611000080",
  "CN": "2916 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido acrílico y sus sales"
 },
 {
  "CNKEY": "291612000080",
  "CN": "2916 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres del ácido acrílico"
 },
 {
  "CNKEY": "291613000080",
  "CN": "2916 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido metacrílico y sus sales"
 },
 {
  "CNKEY": "291614000080",
  "CN": "2916 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres del ácido metacrílico"
 },
 {
  "CNKEY": "291615000080",
  "CN": "2916 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácidos oleico, linoleico o linolénico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291616000080",
  "CN": "2916 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Binapacril (ISO)"
 },
 {
  "CNKEY": "291619000080",
  "CN": "2916 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291619100080",
  "CN": "2916 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácidos undecenoicos, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291619400080",
  "CN": "2916 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido crotónico"
 },
 {
  "CNKEY": "291619950080",
  "CN": "2916 19 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291620000080",
  "CN": "2916 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácidos monocarboxílicos ciclánicos, ciclénicos o cicloterpénicos, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos monocarboxílicos aromáticos, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291631000080",
  "CN": "2916 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido benzoico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291632000080",
  "CN": "2916 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peróxido de benzoilo y cloruro de benzoilo"
 },
 {
  "CNKEY": "291634000080",
  "CN": "2916 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido fenilacético y sus sales"
 },
 {
  "CNKEY": "291639000080",
  "CN": "2916 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291639100080",
  "CN": "2916 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ésteres del ácido fenilacético"
 },
 {
  "CNKEY": "291639900080",
  "CN": "2916 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291700000080",
  "CN": "2917",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos policarboxílicos, sus anhídridos, halogenuros, peróxidos y peroxiácidos; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos policarboxílicos acíclicos, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291711000080",
  "CN": "2917 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido oxálico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291712000080",
  "CN": "2917 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido adípico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291713000080",
  "CN": "2917 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Ácido azelaico, ácido sebácico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291713100080",
  "CN": "2917 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido sebácico"
 },
 {
  "CNKEY": "291713900080",
  "CN": "2917 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291714000080",
  "CN": "2917 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anhídrido maleico"
 },
 {
  "CNKEY": "291719000080",
  "CN": "2917 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291719100080",
  "CN": "2917 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido malónico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291719200080",
  "CN": "2917 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido etano-1,2-dicarboxílico o ácido butanodioico (ácido succínico) con un contenido de carbono de origen biológico del 100 %  en masa"
 },
 {
  "CNKEY": "291719800080",
  "CN": "2917 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291720000080",
  "CN": "2917 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácidos policarboxílicos ciclánicos, ciclénicos o cicloterpénicos, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291732000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos policarboxílicos aromáticos, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291732000080",
  "CN": "2917 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ortoftalatos de dioctilo"
 },
 {
  "CNKEY": "291733000080",
  "CN": "2917 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ortoftalatos de dinonilo o didecilo"
 },
 {
  "CNKEY": "291734000080",
  "CN": "2917 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás ésteres del ácido ortoftálico"
 },
 {
  "CNKEY": "291735000080",
  "CN": "2917 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anhídrido ftálico"
 },
 {
  "CNKEY": "291736000080",
  "CN": "2917 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido tereftálico y sus sales"
 },
 {
  "CNKEY": "291737000080",
  "CN": "2917 37 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tereftalato de dimetilo"
 },
 {
  "CNKEY": "291739000080",
  "CN": "2917 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291739200080",
  "CN": "2917 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Éster o anhídrido del ácido tetrabromoftálico; ácido benceno-1,2,4-tricarboxílico; dicloruro de isoftaloílo, con un contenido de dicloruro de tereftaloilo inferior o igual al 0,8 % en peso; ácido naftaleno-1,4,5,8-tetracarboxílico; anhídrido tetracloroftálico; 3,5-bis(metoxicarbonil)bencensulfonato de sodio"
 },
 {
  "CNKEY": "291739950080",
  "CN": "2917 39 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291800000080",
  "CN": "2918",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos carboxílicos con funciones oxigenadas suplementarias y sus anhídridos, halogenuros, peróxidos y peroxiácidos; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos carboxílicos con función alcohol, pero sin otra función oxigenada, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291811000080",
  "CN": "2918 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido láctico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291812000080",
  "CN": "2918 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido tartárico"
 },
 {
  "CNKEY": "291813000080",
  "CN": "2918 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sales y ésteres del ácido tartárico"
 },
 {
  "CNKEY": "291814000080",
  "CN": "2918 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido cítrico"
 },
 {
  "CNKEY": "291815000080",
  "CN": "2918 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sales y ésteres del ácido cítrico"
 },
 {
  "CNKEY": "291816000080",
  "CN": "2918 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido glucónico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291817000080",
  "CN": "2918 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido 2,2-difenil-2-hidroxiacético (ácido bencílico)"
 },
 {
  "CNKEY": "291818000080",
  "CN": "2918 18 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorobencilato (ISO)"
 },
 {
  "CNKEY": "291819000080",
  "CN": "2918 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291819300080",
  "CN": "2918 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido cólico, ácido 3-α,12-α-dihidroxi-5-β-colan-24-oico (ácido desoxicólico), sus sales y sus ésteres"
 },
 {
  "CNKEY": "291819400080",
  "CN": "2918 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido 2,2-bis(hidroximetil)propiónico"
 },
 {
  "CNKEY": "291819980080",
  "CN": "2918 19 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos carboxílicos con función fenol, pero sin otra función oxigenada, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291821000080",
  "CN": "2918 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido salicílico y sus sales"
 },
 {
  "CNKEY": "291822000080",
  "CN": "2918 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido o-acetilsalicílico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "291823000080",
  "CN": "2918 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás ésteres del ácido salicílico y sus sales"
 },
 {
  "CNKEY": "291829000080",
  "CN": "2918 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291830000080",
  "CN": "2918 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácidos carboxílicos con función aldehído o cetona, pero sin otra función oxigenada, sus anhídridos, halogenuros, peróxidos, peroxiácidos y sus derivados"
 },
 {
  "CNKEY": "291891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291891000080",
  "CN": "2918 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2,4,5-T (ISO) (ácido 2,4,5-triclorofenoxiacético), sus sales y sus ésteres"
 },
 {
  "CNKEY": "291899000080",
  "CN": "2918 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "291899400080",
  "CN": "2918 99 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido 2,6-dimetoxibenzoico; dicamba (ISO); fenoxiacetato de sodio"
 },
 {
  "CNKEY": "291899900080",
  "CN": "2918 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "291900000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "VIII. ÉSTERES DE LOS ÁCIDOS INORGÁNICOS DE LOS NO METALES Y SUS SALES, Y SUS DERIVADOS HALOGENADOS, SULFONADOS, NITRADOS O NITROSADOS"
 },
 {
  "CNKEY": "291900000080",
  "CN": "2919",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ésteres fosfóricos y sus sales, incluidos los lactofosfatos; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "291910000080",
  "CN": "2919 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fosfato de tris(2,3-dibromopropilo)"
 },
 {
  "CNKEY": "291990000080",
  "CN": "2919 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292000000080",
  "CN": "2920",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ésteres de los demás ácidos inorgánicos de los no metales (excepto de los ésteres de halogenuros de hidrógeno) y sus sales; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "292011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ésteres tiofosfóricos (fosforotioatos) y sus sales; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "292011000080",
  "CN": "2920 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Paratión (ISO) y paratión-metilo (ISO) (metil paratión)"
 },
 {
  "CNKEY": "292019000080",
  "CN": "2920 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ésteres de fosfitos y sus sales; sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "292021000080",
  "CN": "2920 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fosfito de dimetilo"
 },
 {
  "CNKEY": "292022000080",
  "CN": "2920 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fosfito de dietilo"
 },
 {
  "CNKEY": "292023000080",
  "CN": "2920 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fosfito de trimetilo"
 },
 {
  "CNKEY": "292024000080",
  "CN": "2920 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fosfito de trietilo"
 },
 {
  "CNKEY": "292029000080",
  "CN": "2920 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292030000080",
  "CN": "2920 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Endosulfán (ISO)"
 },
 {
  "CNKEY": "292090000080",
  "CN": "2920 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292090100080",
  "CN": "2920 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres sulfúricos y ésteres carbónicos; sus sales y sus derivados halogenados, sulfonados, nitrados o nitrosados"
 },
 {
  "CNKEY": "292090700080",
  "CN": "2920 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás productos"
 },
 {
  "CNKEY": "292100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "IX. COMPUESTOS CON FUNCIONES NITROGENADAS"
 },
 {
  "CNKEY": "292100000080",
  "CN": "2921",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos con función amina"
 },
 {
  "CNKEY": "292111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monoaminas acíclicas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292111000080",
  "CN": "2921 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg met.am.",
  "ES": "Mono-, di- o trimetilamina y sus sales"
 },
 {
  "CNKEY": "292112000080",
  "CN": "2921 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorhidrato de 2-cloroetil (N,N-dimetilamina)"
 },
 {
  "CNKEY": "292113000080",
  "CN": "2921 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorhidrato de 2-cloroetil (N,N- dietilamina)"
 },
 {
  "CNKEY": "292114000080",
  "CN": "2921 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clorhidrato de 2-cloroetil (N,N-diisopropilamina)"
 },
 {
  "CNKEY": "292119000080",
  "CN": "2921 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292119400080",
  "CN": "2921 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1,1,3,3-Tetrametilbutilamina"
 },
 {
  "CNKEY": "292119500080",
  "CN": "2921 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dietilamina y sus sales"
 },
 {
  "CNKEY": "292119990080",
  "CN": "2921 19 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poliaminas acíclicas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292121000080",
  "CN": "2921 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etilendiamina y sus sales"
 },
 {
  "CNKEY": "292122000080",
  "CN": "2921 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hexametilendiamina y sus sales"
 },
 {
  "CNKEY": "292129000080",
  "CN": "2921 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292130000080",
  "CN": "2921 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monoaminas y poliaminas, ciclánicas, ciclénicas o cicloterpénicas, y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292130100080",
  "CN": "2921 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciclohexilamina, ciclohexildimetilamina, y sus sales"
 },
 {
  "CNKEY": "292130910080",
  "CN": "2921 30 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ciclohex-1,3-ilendiamina (1,3-diaminociclohexano)"
 },
 {
  "CNKEY": "292130990080",
  "CN": "2921 30 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monoaminas aromáticas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292141000080",
  "CN": "2921 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anilina y sus sales"
 },
 {
  "CNKEY": "292142000080",
  "CN": "2921 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Derivados de la anilina y sus sales"
 },
 {
  "CNKEY": "292143000080",
  "CN": "2921 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Toluidinas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292144000080",
  "CN": "2921 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Difenilamina y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292145000080",
  "CN": "2921 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1-Naftilamina (α-naftilamina),2-naftilamina (β-naftilamina), y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292146000080",
  "CN": "2921 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anfetamina (DCI), benzfetamina (DCI), dexanfetamina (DCI), etilanfetamina (DCI), fencanfamina (DCI), fentermina (DCI), lefetamina (DCI), levanfetamina (DCI) y mefenorex (DCI); sales de estos productos"
 },
 {
  "CNKEY": "292149000080",
  "CN": "2921 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poliaminas aromáticas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292151000080",
  "CN": "2921 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "o-, m- y p-Fenilendiamina, diaminotoluenos, y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292151110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "o-, m- y p-Fenilendiamina, diaminotoluenos y sus derivados halogenados, sulfonados, nitrados y nitrosados; sales de estos productos"
 },
 {
  "CNKEY": "292151110080",
  "CN": "2921 51 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "m-Fenilendiamina, con una pureza superior o igual al 99 % en peso y un contenido de: - agua inferior o igual al 1 % en peso, - o-fenilendiamina inferior o igual a 200 mg\/kg y - p-fenilendiamina inferior o igual a 450 mg\/kg"
 },
 {
  "CNKEY": "292151190080",
  "CN": "2921 51 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292151900080",
  "CN": "2921 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292159000080",
  "CN": "2921 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292159500080",
  "CN": "2921 59 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "m-Fenilenbis(metilamina); 2,2′-dicloro-4,4′-metilendianilina; 4,4′-bi-o-toluidina; 1,8-naftilendiamina"
 },
 {
  "CNKEY": "292159900080",
  "CN": "2921 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292200000080",
  "CN": "2922",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos aminados con funciones oxigenadas"
 },
 {
  "CNKEY": "292211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amino-alcoholes (excepto los que contengan funciones oxigenadas diferentes), sus éteres y sus ésteres; sales de estos productos"
 },
 {
  "CNKEY": "292211000080",
  "CN": "2922 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Monoetanolamina y sus sales"
 },
 {
  "CNKEY": "292212000080",
  "CN": "2922 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dietanolamina y sus sales"
 },
 {
  "CNKEY": "292214000080",
  "CN": "2922 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dextropropoxifeno (DCI) y sus sales"
 },
 {
  "CNKEY": "292215000080",
  "CN": "2922 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Trietanolamina"
 },
 {
  "CNKEY": "292216000080",
  "CN": "2922 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfluorooctano sulfonato de dietanolamonio"
 },
 {
  "CNKEY": "292217000080",
  "CN": "2922 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metildietanolamina y etildietanolamina"
 },
 {
  "CNKEY": "292218000080",
  "CN": "2922 18 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2-(N,N- diisopropilamino) etanol"
 },
 {
  "CNKEY": "292219000080",
  "CN": "2922 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amino-naftoles y demás amino-fenoles (excepto los que contengan funciones oxigenadas diferentes), sus éteres y sus ésteres; sales de estos productos"
 },
 {
  "CNKEY": "292221000080",
  "CN": "2922 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácidos aminohidroxinaftalenosulfónicos y sus sales"
 },
 {
  "CNKEY": "292229000080",
  "CN": "2922 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amino-aldehidos, amino-cetonas y amino-quinonas (excepto los que contengan funciones oxigenadas diferentes); sales de estos productos"
 },
 {
  "CNKEY": "292231000080",
  "CN": "2922 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anfepramona (DCI), metadona (DCI) y normetadona (DCI); sales de estos productos"
 },
 {
  "CNKEY": "292239000080",
  "CN": "2922 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aminoácidos (excepto los que contengan funciones oxigenadas diferentes), y sus ésteres; sales de estos productos"
 },
 {
  "CNKEY": "292241000080",
  "CN": "2922 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lisina y sus ésteres; sales de estos productos"
 },
 {
  "CNKEY": "292242000080",
  "CN": "2922 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido glutámico y sus sales"
 },
 {
  "CNKEY": "292243000080",
  "CN": "2922 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido antranílico y sus sales"
 },
 {
  "CNKEY": "292244000080",
  "CN": "2922 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tilidina (DCI) y sus sales"
 },
 {
  "CNKEY": "292249000080",
  "CN": "2922 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292249200080",
  "CN": "2922 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "ß-Alanina"
 },
 {
  "CNKEY": "292249850080",
  "CN": "2922 49 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292250000080",
  "CN": "2922 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Amino-alcoholes-fenoles, aminoácidos-fenoles y demás compuestos aminados con funciones oxigenadas"
 },
 {
  "CNKEY": "292300000080",
  "CN": "2923",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sales e hidróxidos de amonio cuaternario; lecitinas y demás fosfoaminolípidos, aunque no sean de constitución química definida"
 },
 {
  "CNKEY": "292310000080",
  "CN": "2923 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Colina y sus sales"
 },
 {
  "CNKEY": "292320000080",
  "CN": "2923 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lecitinas y demás fosfoaminolípidos"
 },
 {
  "CNKEY": "292330000080",
  "CN": "2923 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfluorooctano sulfonato de tetraetilamonio"
 },
 {
  "CNKEY": "292340000080",
  "CN": "2923 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perluorooctano sulfonato de didecildimetilamonio"
 },
 {
  "CNKEY": "292390000080",
  "CN": "2923 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292400000080",
  "CN": "2924",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos con función carboxiamida; compuestos con función amida del ácido carbónico"
 },
 {
  "CNKEY": "292411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amidas acíclicas, incluidos los carbamatos, y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292411000080",
  "CN": "2924 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Meprobamato (DCI)"
 },
 {
  "CNKEY": "292412000080",
  "CN": "2924 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fluoroacetamida (ISO), fosfamidón (ISO) y monocrotofós (ISO)"
 },
 {
  "CNKEY": "292419000080",
  "CN": "2924 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Amidas cíclicas, incluidos los carbamatos, y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292421000080",
  "CN": "2924 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ureínas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292423000080",
  "CN": "2924 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido 2-acetamidobenzoico (ácido N-acetilantranílico) y sus sales"
 },
 {
  "CNKEY": "292424000080",
  "CN": "2924 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etinamato (DCI)"
 },
 {
  "CNKEY": "292425000080",
  "CN": "2924 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alaclor (ISO)"
 },
 {
  "CNKEY": "292429000080",
  "CN": "2924 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292429100080",
  "CN": "2924 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Lidocaína (DCI)"
 },
 {
  "CNKEY": "292429700080",
  "CN": "2924 29 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292500000080",
  "CN": "2925",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos con función carboxiimida, incluida la sacarina y sus sales, o con función imina"
 },
 {
  "CNKEY": "292511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Imidas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292511000080",
  "CN": "2925 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sacarina y sus sales"
 },
 {
  "CNKEY": "292512000080",
  "CN": "2925 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Glutetimida (DCI)"
 },
 {
  "CNKEY": "292519000080",
  "CN": "2925 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292519200080",
  "CN": "2925 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "3,3′,4,4′,5,5′,6,6′-Octabromo-N,N′-etilendiftalimida; N,N′-etilenbis(4,5-dibromohexahidro-3,6-metanoftalimida)"
 },
 {
  "CNKEY": "292519950080",
  "CN": "2925 19 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Iminas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "292521000080",
  "CN": "2925 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clordimeformo (ISO)"
 },
 {
  "CNKEY": "292529000080",
  "CN": "2925 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292600000080",
  "CN": "2926",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos con función nitrilo"
 },
 {
  "CNKEY": "292610000080",
  "CN": "2926 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acrilonitrilo"
 },
 {
  "CNKEY": "292620000080",
  "CN": "2926 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "1-Cianoguanidina (diciandiamida)"
 },
 {
  "CNKEY": "292630000080",
  "CN": "2926 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fenproporex (DCI) y sus sales; intermediario de la metadona (DCI) (4-ciano-2-dimetilamino-4,4-difenilbutano)"
 },
 {
  "CNKEY": "292640000080",
  "CN": "2926 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alfa-Fenilacetoacetonitrilo"
 },
 {
  "CNKEY": "292690000080",
  "CN": "2926 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "292690200080",
  "CN": "2926 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isoftalonitrilo"
 },
 {
  "CNKEY": "292690700080",
  "CN": "2926 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292700000080",
  "CN": "2927 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Compuestos diazoicos, azoicos o azoxi"
 },
 {
  "CNKEY": "292800000080",
  "CN": "2928 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Derivados orgánicos de la hidrazina o de la hidroxilamina"
 },
 {
  "CNKEY": "292800100080",
  "CN": "2928 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "N,N-Bis(2-metoxietil)hidroxilamina"
 },
 {
  "CNKEY": "292800900080",
  "CN": "2928 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "292900000080",
  "CN": "2929",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos con otras funciones nitrogenadas"
 },
 {
  "CNKEY": "292910000080",
  "CN": "2929 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Isocianatos"
 },
 {
  "CNKEY": "292990000080",
  "CN": "2929 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293000000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "X. COMPUESTOS ÓRGANO-INORGÁNICOS, COMPUESTOS HETEROCÍCLICOS, ÁCIDOS NUCLEICOS Y SUS SALES, Y SULFONAMIDAS"
 },
 {
  "CNKEY": "293000000080",
  "CN": "2930",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tiocompuestos orgánicos"
 },
 {
  "CNKEY": "293010000080",
  "CN": "2930 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "2-(N,N-Dimetilamino)etanotiol"
 },
 {
  "CNKEY": "293020000080",
  "CN": "2930 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tiocarbamatos y ditiocarbamatos"
 },
 {
  "CNKEY": "293030000080",
  "CN": "2930 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mono-, di- o tetrasulfuros de tiourama"
 },
 {
  "CNKEY": "293040000080",
  "CN": "2930 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Metionina"
 },
 {
  "CNKEY": "293040100080",
  "CN": "2930 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metionina (DCI)"
 },
 {
  "CNKEY": "293040900080",
  "CN": "2930 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293060000080",
  "CN": "2930 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "2-(N,N- dietilamino) etanotiol"
 },
 {
  "CNKEY": "293070000080",
  "CN": "2930 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sulfuro de bis(2-hidroxietilo) (tiodiglicol (DCI))"
 },
 {
  "CNKEY": "293080000080",
  "CN": "2930 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aldicarb (ISO), captafol (ISO) y metamidofos (ISO)"
 },
 {
  "CNKEY": "293090000080",
  "CN": "2930 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293090130080",
  "CN": "2930 90 13",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cisteína y cistina"
 },
 {
  "CNKEY": "293090160080",
  "CN": "2930 90 16",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Derivados de cisteína o cistina"
 },
 {
  "CNKEY": "293090300080",
  "CN": "2930 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido-DL-2-hidroxi-4-(metiltio)butírico"
 },
 {
  "CNKEY": "293090400080",
  "CN": "2930 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bis[3-(3,5-di-terc-butil-4-hidroxifenil)propionato] de 2,2′-tiodietilo"
 },
 {
  "CNKEY": "293090500080",
  "CN": "2930 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezcla de isómeros constituida por 4-metil-2,6-bis(metiltio)-m-fenilendiamina y 2-metil-4,6-bis(metiltio)-m-fenilendiamina"
 },
 {
  "CNKEY": "293090980080",
  "CN": "2930 90 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293100000080",
  "CN": "2931",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás compuestos órgano-inorgánicos"
 },
 {
  "CNKEY": "293110000080",
  "CN": "2931 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tetrametilplomo y tetraetilplomo"
 },
 {
  "CNKEY": "293120000080",
  "CN": "2931 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Compuestos de tributilestaño"
 },
 {
  "CNKEY": "293141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados órgano-fosforados no halogenados"
 },
 {
  "CNKEY": "293141000080",
  "CN": "2931 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metilfosfonato de dimetilo"
 },
 {
  "CNKEY": "293142000080",
  "CN": "2931 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Propilfosfonato de dimetilo"
 },
 {
  "CNKEY": "293143000080",
  "CN": "2931 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Etilfosfonato de dietilo"
 },
 {
  "CNKEY": "293144000080",
  "CN": "2931 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido metilfosfónico"
 },
 {
  "CNKEY": "293145000080",
  "CN": "2931 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sal del ácido metilfosfónico y de (aminoiminometil)urea (1 : 1)"
 },
 {
  "CNKEY": "293146000080",
  "CN": "2931 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2,4,6-Trióxido de 2,4,6-tripropil-1,3,5,2,4,6-trioxatrifosfinano"
 },
 {
  "CNKEY": "293147000080",
  "CN": "2931 47 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metilfosfonato de (5-etil-2-metil-2-óxido-1,3,2-dioxafosfinan-5-il)metil metilo"
 },
 {
  "CNKEY": "293148000080",
  "CN": "2931 48 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "3,9-Dióxido de 3,9-dimetil-2,4,8,10-tetraoxa-3,9-difosfaspiro[5.5]undecano"
 },
 {
  "CNKEY": "293149000080",
  "CN": "2931 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293149100080",
  "CN": "2931 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "3-(trihidroxisilil) propil metilfosfonato de sodio"
 },
 {
  "CNKEY": "293149200080",
  "CN": "2931 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Metilfosfonato de bis[(5-etil-2-metil-2-óxido-1,3,2-dioxafosfinan-5- il) metilo]"
 },
 {
  "CNKEY": "293149300080",
  "CN": "2931 49 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido etidrónico (DCI) (ácido 1-hidroxietano-1,1-difosfónico) y sus sales"
 },
 {
  "CNKEY": "293149400080",
  "CN": "2931 49 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "(Nitrilotrimetanodiil)tris(ácido fosfónico), {etano-1,2-diilbis[nitrilobis(metilen)]}tetrakis(ácido fosfónico), ácido [(bis{2-[bis(fosfonometil)amino]etil}amino)metil]fosfónico, {hexano-1,6-diilbis[nitrilobis(metilen)]}tetrakis(ácido fosfónico), {[(2-hidroxietil)imino]bis(metilen)}bis(ácido fosfónico), y ácido [(bis{6-[bis(fosfonometil)amino]hexil}amino)metil]fosfónico; sales de estos productos"
 },
 {
  "CNKEY": "293149900080",
  "CN": "2931 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Derivados órgano-fosforados halogenados"
 },
 {
  "CNKEY": "293151000080",
  "CN": "2931 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloruro metilfosfónico"
 },
 {
  "CNKEY": "293152000080",
  "CN": "2931 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dicloruro propilfosfónico"
 },
 {
  "CNKEY": "293153000080",
  "CN": "2931 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metilfosfonotionato de O-(3-cloropropil) O-[4-nitro-3-(trifluorometil)fenilo]"
 },
 {
  "CNKEY": "293154000080",
  "CN": "2931 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Triclorfón (ISO)"
 },
 {
  "CNKEY": "293159000080",
  "CN": "2931 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293159100080",
  "CN": "2931 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Difluoruro de metilfosfonoílo (difluoruro metilfosfónico)"
 },
 {
  "CNKEY": "293159900080",
  "CN": "2931 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293190000080",
  "CN": "2931 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293200000080",
  "CN": "2932",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos heterocíclicos con heteroátomo(s) de oxígeno exclusivamente"
 },
 {
  "CNKEY": "293211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos furano (incluso hidrogenado), sin condensar"
 },
 {
  "CNKEY": "293211000080",
  "CN": "2932 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tetrahidrofurano"
 },
 {
  "CNKEY": "293212000080",
  "CN": "2932 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "2-Furaldehído (furfural)"
 },
 {
  "CNKEY": "293213000080",
  "CN": "2932 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alcohol furfurílico y alcohol tetrahidrofurfurílico"
 },
 {
  "CNKEY": "293214000080",
  "CN": "2932 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sucralosa"
 },
 {
  "CNKEY": "293219000080",
  "CN": "2932 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293220000080",
  "CN": "2932 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lactonas"
 },
 {
  "CNKEY": "293220100080",
  "CN": "2932 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fenolftaleína; ácido-1-hidroxi-4-[1-(4-hidroxi-3-metoxicarbonil-1-naftil)-3-oxo-1H,3H-benzo[de]isocromen-1-il]-6-octadeciloxi-2-naftoico; 3′-cloro-6′-ciclohexilaminoespiro[isobenzofurano-1(3H),9′-xanteno]-3-ona; 6′-(N-etil-p-toluidino)-2′-metilespiro[isobenzofurano-1(3H),9′-xanteno]-3-ona; 6-docosiloxi-1-hidroxi-4-[1-(4-hidroxi-3-metil-1-fenantril)-3-oxo-1H,3H-nafto[1,8-cd]piran-1-il]naftalen-2-carboxilato de metilo"
 },
 {
  "CNKEY": "293220200080",
  "CN": "2932 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "gamma-Butirolactona"
 },
 {
  "CNKEY": "293220900080",
  "CN": "2932 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "293291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293291000080",
  "CN": "2932 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Isosafrol"
 },
 {
  "CNKEY": "293292000080",
  "CN": "2932 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "1-(1,3-Benzodioxol-5-il)propan-2-ona"
 },
 {
  "CNKEY": "293293000080",
  "CN": "2932 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Piperonal"
 },
 {
  "CNKEY": "293294000080",
  "CN": "2932 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Safrol"
 },
 {
  "CNKEY": "293295000080",
  "CN": "2932 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tetrahidrocannabinoles (todos los isómeros)"
 },
 {
  "CNKEY": "293296000080",
  "CN": "2932 96 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carbofurano (carbofurán) (ISO)"
 },
 {
  "CNKEY": "293299000080",
  "CN": "2932 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293300000080",
  "CN": "2933",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compuestos heterocíclicos con heteroátomo(s) de nitrógeno exclusivamente"
 },
 {
  "CNKEY": "293311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos pirazol (incluso hidrogenados), sin condensar"
 },
 {
  "CNKEY": "293311000080",
  "CN": "2933 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fenazona (antipirina) y sus derivados"
 },
 {
  "CNKEY": "293311100080",
  "CN": "2933 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Propifenazona (DCI)"
 },
 {
  "CNKEY": "293311900080",
  "CN": "2933 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293319000080",
  "CN": "2933 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293319100080",
  "CN": "2933 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fenilbutazona (DCI)"
 },
 {
  "CNKEY": "293319900080",
  "CN": "2933 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos imidazol (incluso hidrogenados), sin condensar"
 },
 {
  "CNKEY": "293321000080",
  "CN": "2933 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hidantoína y sus derivados"
 },
 {
  "CNKEY": "293329000080",
  "CN": "2933 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293329100080",
  "CN": "2933 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Clorhidrato de nafazolina (DCIM) y nitrato de nafazolina (DCIM), fentol-amina (DCI), clorhidrato de tolazolina (DCIM)"
 },
 {
  "CNKEY": "293329900080",
  "CN": "2933 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos piridina (incluso hidrogenados), sin condensar"
 },
 {
  "CNKEY": "293331000080",
  "CN": "2933 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Piridina y sus sales"
 },
 {
  "CNKEY": "293332000080",
  "CN": "2933 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Piperidina y sus sales"
 },
 {
  "CNKEY": "293333000080",
  "CN": "2933 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alfentanilo (DCI), anileridina (DCI), bezitramida (DCI), bromazepam (DCI), carfentanilo (DCI), cetobemidona (DCI), difenoxilato (DCI), difenoxina (DCI), dipipanona (DCI), fenciclidina (DCI) (PCP), fenoperidina (DCI), fentanilo (DCI), metilfenidato (DCI), pentazocina (DCI), petidina (DCI), intermedio A de la petidina (DCI), pipradrol (DCI), piritramida (DCI), propiram (DCI), remifentanilo (DCI) y trimeperidina (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293334000080",
  "CN": "2933 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás fentanilos y sus derivados"
 },
 {
  "CNKEY": "293335000080",
  "CN": "2933 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Quinuclidin-3-ol"
 },
 {
  "CNKEY": "293336000080",
  "CN": "2933 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "4-Anilino-N-fenetilpiperidina (ANPP)"
 },
 {
  "CNKEY": "293337000080",
  "CN": "2933 37 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "N-Fenetil-4-piperidona (NPP)"
 },
 {
  "CNKEY": "293339000080",
  "CN": "2933 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293339100080",
  "CN": "2933 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Iproniazida (DCI); clorhidrato de cetobemidona (DCIM); bromuro de piridostigmina (DCI)"
 },
 {
  "CNKEY": "293339200080",
  "CN": "2933 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2,3,5,6-Tetracloropiridina"
 },
 {
  "CNKEY": "293339250080",
  "CN": "2933 39 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácido 3,6-dicloropiridina-2-carboxílico"
 },
 {
  "CNKEY": "293339350080",
  "CN": "2933 39 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "3,6-Dicloropiridina-2-carboxílato de 2-hidroxietilamonio"
 },
 {
  "CNKEY": "293339400080",
  "CN": "2933 39 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "3,5,6-Tricloro-2-piridiloxiacetato de 2-butoxietilo"
 },
 {
  "CNKEY": "293339450080",
  "CN": "2933 39 45",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "3,5-Dicloro-2,4,6-trifluoropiridina"
 },
 {
  "CNKEY": "293339500080",
  "CN": "2933 39 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fluroxipir (ISO), éster metílico"
 },
 {
  "CNKEY": "293339550080",
  "CN": "2933 39 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "4-Metilpiridina"
 },
 {
  "CNKEY": "293339990080",
  "CN": "2933 39 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga ciclos quinoleína o isoquinoleína, incluso hidrogenados, sin otras condensaciones"
 },
 {
  "CNKEY": "293341000080",
  "CN": "2933 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Levorfanol (DCI) y sus sales"
 },
 {
  "CNKEY": "293349000080",
  "CN": "2933 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293349100080",
  "CN": "2933 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Derivados halogenados de la quinoleína, derivados de los ácidos quinoleincarboxílicos"
 },
 {
  "CNKEY": "293349300080",
  "CN": "2933 49 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dextrometorfano (DCI) y sus sales"
 },
 {
  "CNKEY": "293349900080",
  "CN": "2933 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293352000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos pirimidina (incluso hidrogenado), o piperazina"
 },
 {
  "CNKEY": "293352000080",
  "CN": "2933 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Malonilurea (ácido barbitúrico) y sus sales"
 },
 {
  "CNKEY": "293353000080",
  "CN": "2933 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Alobarbital (DCI), amobarbital (DCI), barbital (DCI), butalbital (DCI), butobarbital, ciclobarbital (DCI), fenobarbital (DCI), metilfenobarbital (DCI), pentobarbital (DCI), secbutabarbital (DCI), secobarbital (DCI) y vinilbital (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293353100080",
  "CN": "2933 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fenobarbital (DCI), barbital (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293353900080",
  "CN": "2933 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293354000080",
  "CN": "2933 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás derivados de la malonilurea (ácido barbitúrico); sales de estos productos"
 },
 {
  "CNKEY": "293355000080",
  "CN": "2933 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Loprazolam (DCI), meclocualona (DCI), metacualona (DCI) y zipeprol (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293359000080",
  "CN": "2933 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293359100080",
  "CN": "2933 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Diazinon (ISO)"
 },
 {
  "CNKEY": "293359200080",
  "CN": "2933 59 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "1,4-Diazabiciclo[2.2.2]octano (trietilendiamina)"
 },
 {
  "CNKEY": "293359950080",
  "CN": "2933 59 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293361000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga uno o más ciclos triazina (incluso hidrogenado), sin condensar"
 },
 {
  "CNKEY": "293361000080",
  "CN": "2933 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Melamina"
 },
 {
  "CNKEY": "293369000080",
  "CN": "2933 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293369100080",
  "CN": "2933 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Atrazina (ISO); propazina (ISO); simazina (ISO); hexahidro-1,3,5-trinito-1,3,5-triazina (hexógeno, trimetilentrimitramina)"
 },
 {
  "CNKEY": "293369400080",
  "CN": "2933 69 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Metenamina (DCI) (hexametilentetramina); 2,6-di-terc-butil-4-[4,6-bis(octiltio)-1,3,5-triazina-2-ilamino]fenol"
 },
 {
  "CNKEY": "293369800080",
  "CN": "2933 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293371000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lactamas"
 },
 {
  "CNKEY": "293371000080",
  "CN": "2933 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "6-Hexanolactama (épsilon-caprolactama)"
 },
 {
  "CNKEY": "293372000080",
  "CN": "2933 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clobazam (DCI) y metiprilona (DCI)"
 },
 {
  "CNKEY": "293379000080",
  "CN": "2933 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás lactamas"
 },
 {
  "CNKEY": "293391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293391000080",
  "CN": "2933 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Alprazolam (DCI), camazepam (DCI), clonazepam (DCI), clorazepato, clordiazepóxido (DCI), delorazepam (DCI), diazepam (DCI), estazolam (DCI), fludiazepam (DCI), flunitrazepam (DCI), flurazepam (DCI), halazepam (DCI), loflazepato de etilo (DCI), lorazepam (DCI), lormetazepam (DCI), mazindol (DCI), medazepam (DCI), midazolam (DCI), nimetazepam (DCI), nitrazepam (DCI), nordazepam (DCI), oxazepam (DCI), pinazepam (DCI), pirovalerona (DCI), prazepam (DCI), temazepam (DCI), tetrazepam (DCI) y triazolam (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293391100080",
  "CN": "2933 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Clorodiazepóxido (DCI)"
 },
 {
  "CNKEY": "293391900080",
  "CN": "2933 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293392000080",
  "CN": "2933 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Azinfos metil (ISO)"
 },
 {
  "CNKEY": "293399000080",
  "CN": "2933 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293399200080",
  "CN": "2933 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Indol, 3-metilindol (escatol), 6-alil-6,7-dihidro-5H-dibenzo[c,e]azepina (azapetina), fenindamina (DCI); sales de estos productos; clorhidrato de imipramina (DCIM)"
 },
 {
  "CNKEY": "293399500080",
  "CN": "2933 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "2,4-Di-terc-butil-6-(5-clorobenzotriazol-2-il)fenol"
 },
 {
  "CNKEY": "293399800080",
  "CN": "2933 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293400000080",
  "CN": "2934",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos nucleicos y sus sales, aunque no sean de constitución química definida; los demás compuestos heterocíclicos"
 },
 {
  "CNKEY": "293410000080",
  "CN": "2934 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Compuestos cuya estructura contenga uno o más ciclos tiazol (incluso hidrogenado), sin condensar"
 },
 {
  "CNKEY": "293420000080",
  "CN": "2934 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga ciclos benzotiazol, incluso hidrogenados, sin otras condensaciones"
 },
 {
  "CNKEY": "293420200080",
  "CN": "2934 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Disulfuro de di(benzotiazol-2-ilo); benzotiazol-2-tiol (mercaptobenzotiazol) y sus sales"
 },
 {
  "CNKEY": "293420800080",
  "CN": "2934 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293430000080",
  "CN": "2934 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compuestos cuya estructura contenga ciclos fenotiazina, incluso hidrogenados, sin otras condensaciones"
 },
 {
  "CNKEY": "293430100080",
  "CN": "2934 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tietilperazina (DCI); tioridazina (DCI) y sus sales"
 },
 {
  "CNKEY": "293430900080",
  "CN": "2934 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293491000080",
  "CN": "2934 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aminorex (DCI), brotizolam (DCI), clotiazepam (DCI), cloxazolam (DCI), dextromoramida (DCI), fendimetrazina (DCI), fenmetrazina (DCI), haloxazolam (DCI), ketazolam (DCI), mesocarb (DCI), oxazolam (DCI), pemolina (DCI) y sufentanil (DCI); sales de estos productos"
 },
 {
  "CNKEY": "293492000080",
  "CN": "2934 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás fentanilos y sus derivados"
 },
 {
  "CNKEY": "293499000080",
  "CN": "2934 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293499600080",
  "CN": "2934 99 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cloroprotixeno (DCI); tenalidina (DCI), sus tartratos y maleatos; furazolidona (DCI); ácido 7-aminocefalosporanico; sales y ésteres del ácido (6R,7R)-3-acetoximetil-7-[(R)-2-formiloxi-2-fenilacetamido]-8-oxo-5-tia-1-azabiciclo[4.2.0]oct-2-eno-2-carboxílico; bromuro de 1-[2-(1,3-dioxan-2-il)etil]-2-metilpiridinio"
 },
 {
  "CNKEY": "293499900080",
  "CN": "2934 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293500000080",
  "CN": "2935",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sulfonamidas"
 },
 {
  "CNKEY": "293510000080",
  "CN": "2935 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "N-Metilperfluorooctano sulfonamida"
 },
 {
  "CNKEY": "293520000080",
  "CN": "2935 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "N-Etilperfluorooctano sulfonamida"
 },
 {
  "CNKEY": "293530000080",
  "CN": "2935 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "N-Etil-N-(2-hidroxietil) perfluorooctano sulfonamida"
 },
 {
  "CNKEY": "293540000080",
  "CN": "2935 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "N-(2-Hidroxietil)-N-metilperfluorooctano sulfonamida"
 },
 {
  "CNKEY": "293550000080",
  "CN": "2935 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás perfluorooctano sulfonamidas"
 },
 {
  "CNKEY": "293590000080",
  "CN": "2935 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "293590300080",
  "CN": "2935 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "3-{1-[7-(Hexadecilsulfonilamino)-1H-indol-3-il]-3-oxo-1H,3H-nafto[1,8-cd]piran-1-il}-N,N-dimetil-1H-indol-7-sulfonamida; metosulam (ISO)"
 },
 {
  "CNKEY": "293590900080",
  "CN": "2935 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293600000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "XI. PROVITAMINAS, VITAMINAS Y HORMONAS"
 },
 {
  "CNKEY": "293600000080",
  "CN": "2936",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Provitaminas y vitaminas, naturales o reproducidas por síntesis, incluidos los concentrados naturales, y sus derivados utilizados principalmente como vitaminas, mezclados o no entre sí o en disoluciones de cualquier clase"
 },
 {
  "CNKEY": "293621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vitaminas y sus derivados, sin mezclar"
 },
 {
  "CNKEY": "293621000080",
  "CN": "2936 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitaminas A y sus derivados"
 },
 {
  "CNKEY": "293622000080",
  "CN": "2936 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina B1 y sus derivados"
 },
 {
  "CNKEY": "293623000080",
  "CN": "2936 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina B2 y sus derivados"
 },
 {
  "CNKEY": "293624000080",
  "CN": "2936 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido D- o DL-pantoténico (vitamina B5) y sus derivados"
 },
 {
  "CNKEY": "293625000080",
  "CN": "2936 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina B6 y sus derivados"
 },
 {
  "CNKEY": "293626000080",
  "CN": "2936 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina B12 y sus derivados"
 },
 {
  "CNKEY": "293627000080",
  "CN": "2936 27 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina C y sus derivados"
 },
 {
  "CNKEY": "293628000080",
  "CN": "2936 28 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vitamina E y sus derivados"
 },
 {
  "CNKEY": "293629000080",
  "CN": "2936 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás vitaminas y sus derivados"
 },
 {
  "CNKEY": "293690000080",
  "CN": "2936 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos los concentrados naturales"
 },
 {
  "CNKEY": "293700000080",
  "CN": "2937",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hormonas, prostaglandinas, tromboxanos y leucotrienos, naturales o reproducidos por síntesis; sus derivados y análogos estructurales, incluidos los polipéptidos de cadena modificada, utilizados principalmente como hormonas"
 },
 {
  "CNKEY": "293711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hormonas polipeptídicas, hormonas proteicas y hormonas glucoproteicas, sus derivados y análogos estructurales"
 },
 {
  "CNKEY": "293711000080",
  "CN": "2937 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Somatotropina, sus derivados y análogos estructurales"
 },
 {
  "CNKEY": "293712000080",
  "CN": "2937 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Insulina y sus sales"
 },
 {
  "CNKEY": "293719000080",
  "CN": "2937 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hormonas esteroideas, sus derivados y análogos estructurales"
 },
 {
  "CNKEY": "293721000080",
  "CN": "2937 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Cortisona, hidrocortisona, prednisona (dehidrocortisona) y prednisolona (dehidrohidrocortisona)"
 },
 {
  "CNKEY": "293722000080",
  "CN": "2937 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Derivados halogenados de las hormonas corticosteroides"
 },
 {
  "CNKEY": "293723000080",
  "CN": "2937 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Estrógenos y progestógenos"
 },
 {
  "CNKEY": "293729000080",
  "CN": "2937 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293750000080",
  "CN": "2937 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Prostaglandinas, tromboxanos y leucotrienos, sus derivados y análogos estructurales"
 },
 {
  "CNKEY": "293790000080",
  "CN": "2937 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293800000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "XII. HETERÓSIDOS Y ALCALOIDES, NATURALES O REPRODUCIDOS POR SÍNTESIS, SUS SALES, ÉTERES, ÉSTERES Y DEMÁS DERIVADOS"
 },
 {
  "CNKEY": "293800000080",
  "CN": "2938",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Heterósidos, naturales o reproducidos por síntesis, sus sales, éteres, ésteres y demás derivados"
 },
 {
  "CNKEY": "293810000080",
  "CN": "2938 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Rutósido (rutina) y sus derivados"
 },
 {
  "CNKEY": "293890000080",
  "CN": "2938 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293890100080",
  "CN": "2938 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Heterósidos de la digital"
 },
 {
  "CNKEY": "293890300080",
  "CN": "2938 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Glicirricina y glicirrizatos"
 },
 {
  "CNKEY": "293890900080",
  "CN": "2938 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293900000080",
  "CN": "2939",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alcaloides, naturales o reproducidos por síntesis, sus sales, éteres, ésteres y demás derivados"
 },
 {
  "CNKEY": "293911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alcaloides del opio y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "293911000080",
  "CN": "2939 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Concentrados de paja de adormidera; buprenorfina (DCI), codeína, dihidrocodeína (DCI), etilmorfina, etorfina (DCI), folcodina (DCI), heroína, hidrocodona (DCI), hidromorfona (DCI), morfina, nicomorfina (DCI), oxicodona (DCI), oximorfona (DCI), tebacona (DCI) y tebaína; sales de estos productos"
 },
 {
  "CNKEY": "293919000080",
  "CN": "2939 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293920000080",
  "CN": "2939 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alcaloides de la quina (chinchona) y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "293930000080",
  "CN": "2939 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cafeína y sus sales"
 },
 {
  "CNKEY": "293941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alcaloides de la efedra y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "293941000080",
  "CN": "2939 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Efedrina y sus sales"
 },
 {
  "CNKEY": "293942000080",
  "CN": "2939 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Seudoefedrina (DCI) y sus sales"
 },
 {
  "CNKEY": "293943000080",
  "CN": "2939 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Catina (DCI) y sus sales"
 },
 {
  "CNKEY": "293944000080",
  "CN": "2939 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Norefedrina y sus sales"
 },
 {
  "CNKEY": "293945000080",
  "CN": "2939 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Levometanfetamina, metanfetamina (DCI), racemato de metanfetamina, y sus sales"
 },
 {
  "CNKEY": "293949000080",
  "CN": "2939 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "293951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teofilina y aminofilina (teofilina-etilendiamina) y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "293951000080",
  "CN": "2939 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fenetilina (DCI) y sus sales"
 },
 {
  "CNKEY": "293959000080",
  "CN": "2939 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alcaloides del cornezuelo del centeno y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "293961000080",
  "CN": "2939 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ergometrina (DCI) y sus sales"
 },
 {
  "CNKEY": "293962000080",
  "CN": "2939 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ergotamina (DCI) y sus sales"
 },
 {
  "CNKEY": "293963000080",
  "CN": "2939 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido lisérgico y sus sales"
 },
 {
  "CNKEY": "293969000080",
  "CN": "2939 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293972000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de origen vegetal"
 },
 {
  "CNKEY": "293972000080",
  "CN": "2939 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cocaína, ecgonina; sales, ésteres y demás derivados de estos productos"
 },
 {
  "CNKEY": "293979000080",
  "CN": "2939 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "293979100080",
  "CN": "2939 79 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Nicotina y sus sales, éteres, ésteres y demás derivados de estos productos"
 },
 {
  "CNKEY": "293979900080",
  "CN": "2939 79 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "293980000080",
  "CN": "2939 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "294000000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "XIII. LOS DEMÁS COMPUESTOS ORGÁNICOS"
 },
 {
  "CNKEY": "294000000080",
  "CN": "2940 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Azúcares químicamente puros, excepto la sacarosa, lactosa, maltosa, glucosa y fructosa (levulosa); éteres, acetales y ésteres de los azúcares y sus sales (excepto los productos de las partidas 2937, 2938 o 2939)"
 },
 {
  "CNKEY": "294100000080",
  "CN": "2941",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Antibióticos"
 },
 {
  "CNKEY": "294110000080",
  "CN": "2941 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Penicilinas y sus derivados con la estructura del ácido penicilánico; sales de estos productos"
 },
 {
  "CNKEY": "294120000080",
  "CN": "2941 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estreptomicinas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "294120300080",
  "CN": "2941 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dihidroestreptomicina, sus sales, ésteres e hidratos"
 },
 {
  "CNKEY": "294120800080",
  "CN": "2941 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "294130000080",
  "CN": "2941 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tetraciclinas y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "294140000080",
  "CN": "2941 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cloranfenicol y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "294150000080",
  "CN": "2941 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Eritromicina y sus derivados; sales de estos productos"
 },
 {
  "CNKEY": "294190000080",
  "CN": "2941 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "294200000080",
  "CN": "2942 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Los demás compuestos orgánicos"
 },
 {
  "CNKEY": "300021000090",
  "CN": "30",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 30 - PRODUCTOS FARMACÉUTICOS"
 },
 {
  "CNKEY": "300100000080",
  "CN": "3001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Glándulas y demás órganos para usos opoterápicos, desecados, incluso pulverizados; extractos de glándulas o de otros órganos o de sus secreciones, para usos opoterápicos; heparina y sus sales; las demás sustancias humanas o animales preparadas para usos terapéuticos o profilácticos, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "300120000080",
  "CN": "3001 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Extractos de glándulas o de otros órganos o de sus secreciones"
 },
 {
  "CNKEY": "300120100080",
  "CN": "3001 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De origen humano"
 },
 {
  "CNKEY": "300120900080",
  "CN": "3001 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300190000080",
  "CN": "3001 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "300190200080",
  "CN": "3001 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De origen humano"
 },
 {
  "CNKEY": "300190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "300190910080",
  "CN": "3001 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Heparina y sus sales"
 },
 {
  "CNKEY": "300190980080",
  "CN": "3001 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "300200000080",
  "CN": "3002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sangre humana; sangre animal preparada para usos terapéuticos, profilácticos o de diagnóstico; antisueros (sueros con anticuerpos), demás fracciones de la sangre y productos inmunológicos, incluso modificados u obtenidos por procesos biotecnológicos; vacunas, toxinas, cultivos de microorganismos (excepto las levaduras) y productos similares; cultivos de células, incluso modificadas"
 },
 {
  "CNKEY": "300212000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Antisueros (sueros con anticuerpos), demás fracciones de la sangre y productos inmunológicos, incluso modificados u obtenidos por procesos biotecnológicos"
 },
 {
  "CNKEY": "300212000080",
  "CN": "3002 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Antisueros (sueros con anticuerpos) y demás fracciones de la sangre"
 },
 {
  "CNKEY": "300213000080",
  "CN": "3002 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Productos inmunológicos sin mezclar, sin dosificar  ni acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "300214000080",
  "CN": "3002 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Productos inmunológicos mezclados, sin dosificar  ni acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "300215000080",
  "CN": "3002 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Productos inmunológicos dosificados o acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "300241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vacunas, toxinas, cultivos de microorganismos (excepto las levaduras) y productos similares"
 },
 {
  "CNKEY": "300241000080",
  "CN": "3002 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vacunas para uso en medicina humana"
 },
 {
  "CNKEY": "300241100080",
  "CN": "3002 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Vacunas contra los coronavirus relacionados con el SARS (especie SARS-CoV)"
 },
 {
  "CNKEY": "300241900080",
  "CN": "3002 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300242000080",
  "CN": "3002 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vacunas para uso en medicina veterinaria"
 },
 {
  "CNKEY": "300249000080",
  "CN": "3002 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cultivos de células, incluso modificadas"
 },
 {
  "CNKEY": "300251000080",
  "CN": "3002 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Productos de terapia celular"
 },
 {
  "CNKEY": "300259000080",
  "CN": "3002 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300290000080",
  "CN": "3002 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "300290100080",
  "CN": "3002 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sangre humana"
 },
 {
  "CNKEY": "300290300080",
  "CN": "3002 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sangre de animales preparada para usos terapéuticos, profilácticos o de diagnóstico"
 },
 {
  "CNKEY": "300290900080",
  "CN": "3002 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300300000080",
  "CN": "3003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Medicamentos (excepto los productos de las partidas 3002, 3005 o 3006) constituidos por productos mezclados entre sí, preparados para usos terapéuticos o profilácticos, sin dosificar ni acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "300310000080",
  "CN": "3003 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan penicilinas o derivados de estos productos con la estructura del ácido penicilánico, o estreptomicinas o derivados de estos productos"
 },
 {
  "CNKEY": "300320000080",
  "CN": "3003 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan antibióticos"
 },
 {
  "CNKEY": "300331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, que contengan hormonas u otros productos de la partida 2937"
 },
 {
  "CNKEY": "300331000080",
  "CN": "3003 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan insulina"
 },
 {
  "CNKEY": "300339000080",
  "CN": "3003 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, que contengan alcaloides o sus derivados"
 },
 {
  "CNKEY": "300341000080",
  "CN": "3003 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan efedrina o sus sales"
 },
 {
  "CNKEY": "300342000080",
  "CN": "3003 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan seudoefedrina (DCI) o sus sales"
 },
 {
  "CNKEY": "300343000080",
  "CN": "3003 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan norefedrina o sus sales"
 },
 {
  "CNKEY": "300349000080",
  "CN": "3003 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300360000080",
  "CN": "3003 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan los principios activos contra la malaria (paludismo) descritos en la Nota 2 de subpartida del presente Capítulo"
 },
 {
  "CNKEY": "300390000080",
  "CN": "3003 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300400000080",
  "CN": "3004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Medicamentos (excepto los productos de las partidas 3002, 3005 o 3006) constituidos por productos mezclados o sin mezclar, preparados para usos terapéuticos o profilácticos, dosificados (incluidos los administrados por vía transdérmica) o acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "300410000080",
  "CN": "3004 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan penicilinas o derivados de estos productos con la estructura del ácido penicilánico, o estreptomicinas o derivados de estos productos"
 },
 {
  "CNKEY": "300420000080",
  "CN": "3004 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan antibióticos"
 },
 {
  "CNKEY": "300431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, que contengan hormonas u otros productos de la partida 2937"
 },
 {
  "CNKEY": "300431000080",
  "CN": "3004 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan insulina"
 },
 {
  "CNKEY": "300432000080",
  "CN": "3004 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan hormonas corticosteroides, sus derivados o análogos estructurales"
 },
 {
  "CNKEY": "300439000080",
  "CN": "3004 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, que contengan alcaloides o sus derivados"
 },
 {
  "CNKEY": "300441000080",
  "CN": "3004 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan efedrina o sus sales"
 },
 {
  "CNKEY": "300442000080",
  "CN": "3004 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan seudoefedrina (DCI) o sus sales"
 },
 {
  "CNKEY": "300443000080",
  "CN": "3004 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan norefedrina o sus sales"
 },
 {
  "CNKEY": "300449000080",
  "CN": "3004 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300450000080",
  "CN": "3004 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan vitaminas u otros productos de la partida 2936"
 },
 {
  "CNKEY": "300460000080",
  "CN": "3004 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, que contengan los principios activos contra la malaria (paludismo) descritos en la Nota 2 de subpartida del presente Capítulo"
 },
 {
  "CNKEY": "300490000080",
  "CN": "3004 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300500000080",
  "CN": "3005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Guatas, gasas, vendas y artículos análogos (por ejemplo: apósitos, esparadrapos, sinapismos), impregnados o recubiertos de sustancias farmacéuticas o acondicionados para la venta al por menor con fines médicos, quirúrgicos, odontológicos o veterinarios"
 },
 {
  "CNKEY": "300510000080",
  "CN": "3005 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Apósitos y demás artículos, con una capa adhesiva"
 },
 {
  "CNKEY": "300590000080",
  "CN": "3005 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "300590100080",
  "CN": "3005 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guatas y artículos de guata"
 },
 {
  "CNKEY": "300590310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "300590310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De materia textil"
 },
 {
  "CNKEY": "300590310080",
  "CN": "3005 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Gasas y artículos de gasa"
 },
 {
  "CNKEY": "300590500080",
  "CN": "3005 90 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300590990080",
  "CN": "3005 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300600000080",
  "CN": "3006",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones y artículos farmacéuticos a que se refiere la Nota 4 de este Capítulo"
 },
 {
  "CNKEY": "300610000080",
  "CN": "3006 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Catguts estériles y ligaduras estériles similares, para suturas quirúrgicas (incluidos los hilos reabsorbibles estériles para cirugía u odontología) y los adhesivos estériles para tejidos orgánicos utilizados en cirugía para cerrar heridas; laminarias estériles; hemostáticos reabsorbibles estériles para cirugía u odontología ; barreras antiadherencias estériles, para cirugía u odontología, incluso reabsorbibles"
 },
 {
  "CNKEY": "300610100080",
  "CN": "3006 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Catguts estériles"
 },
 {
  "CNKEY": "300610300080",
  "CN": "3006 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Barreras antiadherencias estériles para cirugía u odontología, incluso reabsorbibles"
 },
 {
  "CNKEY": "300610900080",
  "CN": "3006 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "300630000080",
  "CN": "3006 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones opacificantes para exámenes radiológicos; reactivos de diagnóstico concebidos para usar en el paciente"
 },
 {
  "CNKEY": "300640000080",
  "CN": "3006 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cementos y demás productos de obturación dental; cementos para la refección de los huesos"
 },
 {
  "CNKEY": "300650000080",
  "CN": "3006 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Botiquines equipados para primeros auxilios"
 },
 {
  "CNKEY": "300660000080",
  "CN": "3006 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones químicas anticonceptivas a base de hormonas, de otros productos de la partida 2937 o de espermicidas"
 },
 {
  "CNKEY": "300670000080",
  "CN": "3006 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones en forma de gel, concebidas para ser utilizadas en medicina o veterinaria como lubricante para ciertas partes del cuerpo en operaciones quirúrgicas o exámenes médicos o como nexo entre el cuerpo y los instrumentos médicos"
 },
 {
  "CNKEY": "300691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "300691000080",
  "CN": "3006 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos identificables para uso en estomas"
 },
 {
  "CNKEY": "300692000080",
  "CN": "3006 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desechos farmacéuticos"
 },
 {
  "CNKEY": "300693000080",
  "CN": "3006 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Placebos y kits para ensayos clínicos ciegos (o doble ciego), destinados para ensayos clínicos reconocidos, presentados en forma de dosis"
 },
 {
  "CNKEY": "310021000090",
  "CN": "31",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 31 - ABONOS"
 },
 {
  "CNKEY": "310100000080",
  "CN": "3101 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Abonos de origen animal o vegetal, incluso mezclados entre sí o tratados químicamente; abonos procedentes de la mezcla o del tratamiento químico de productos de origen animal o vegetal"
 },
 {
  "CNKEY": "310200000080",
  "CN": "3102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abonos minerales o químicos nitrogenados"
 },
 {
  "CNKEY": "310210000080",
  "CN": "3102 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Urea, incluso en disolución acuosa"
 },
 {
  "CNKEY": "310210100080",
  "CN": "3102 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Urea con un contenido de nitrógeno superior al 45 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310210900080",
  "CN": "3102 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sulfato de amonio; sales dobles y mezclas entre sí de sulfato de amonio y nitrato de amonio"
 },
 {
  "CNKEY": "310221000080",
  "CN": "3102 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Sulfato de amonio"
 },
 {
  "CNKEY": "310229000080",
  "CN": "3102 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Las demás"
 },
 {
  "CNKEY": "310230000080",
  "CN": "3102 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nitrato de amonio, incluso en disolución acuosa"
 },
 {
  "CNKEY": "310230100080",
  "CN": "3102 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "En disolución acuosa"
 },
 {
  "CNKEY": "310230900080",
  "CN": "3102 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310240000080",
  "CN": "3102 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mezclas de nitrato de amonio con carbonato de calcio u otras materias inorgánicas sin poder fertilizante"
 },
 {
  "CNKEY": "310240100080",
  "CN": "3102 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Con un contenido de nitrógeno inferior o igual al 28 % en peso"
 },
 {
  "CNKEY": "310240900080",
  "CN": "3102 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg N",
  "ES": "Con un contenido de nitrógeno superior al 28 % en peso"
 },
 {
  "CNKEY": "310250000080",
  "CN": "3102 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg N",
  "ES": "Nitrato de sodio"
 },
 {
  "CNKEY": "310260000080",
  "CN": "3102 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg N",
  "ES": "Sales dobles y mezclas entre sí de nitrato de calcio y nitrato de amonio"
 },
 {
  "CNKEY": "310280000080",
  "CN": "3102 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg N",
  "ES": "Mezclas de urea con nitrato de amonio en disolución acuosa o amoniacal"
 },
 {
  "CNKEY": "310290000080",
  "CN": "3102 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg N",
  "ES": "Los demás, incluidas las mezclas no comprendidas en las subpartidas precedentes"
 },
 {
  "CNKEY": "310300000080",
  "CN": "3103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abonos minerales o químicos fosfatados"
 },
 {
  "CNKEY": "310311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Superfosfatos"
 },
 {
  "CNKEY": "310311000080",
  "CN": "3103 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg P2O5",
  "ES": "Con un contenido de pentóxido de difósforo (P2O5) superior o igual al 35 % en peso"
 },
 {
  "CNKEY": "310319000080",
  "CN": "3103 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg P2O5",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310390000080",
  "CN": "3103 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg P2O5",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310400000080",
  "CN": "3104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abonos minerales o químicos potásicos"
 },
 {
  "CNKEY": "310420000080",
  "CN": "3104 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cloruro de potasio"
 },
 {
  "CNKEY": "310420100080",
  "CN": "3104 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg K2O",
  "ES": "Con un contenido de potasio expresado en K2O inferior o igual al 40 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310420500080",
  "CN": "3104 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg K2O",
  "ES": "Con un contenido de potasio expresado en K2O superior al 40 % pero inferior o igual al 62 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310420900080",
  "CN": "3104 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg K2O",
  "ES": "Con un contenido de potasio expresado en K2O superior al 62 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310430000080",
  "CN": "3104 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg K2O",
  "ES": "Sulfato de potasio"
 },
 {
  "CNKEY": "310490000080",
  "CN": "3104 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg K2O",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310500000080",
  "CN": "3105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abonos minerales o químicos, con dos o tres de los elementos fertilizantes: nitrógeno, fósforo y potasio; los demás abonos; productos de este Capítulo en tabletas o formas similares o en envases de un peso bruto inferior o igual a 10 kg"
 },
 {
  "CNKEY": "310510000080",
  "CN": "3105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos de este Capítulo en tabletas o formas similares o en envases de un peso bruto inferior o igual a 10 kg"
 },
 {
  "CNKEY": "310520000080",
  "CN": "3105 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Abonos minerales o químicos con los tres elementos fertilizantes: nitrógeno, fósforo y potasio"
 },
 {
  "CNKEY": "310520100080",
  "CN": "3105 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de nitrógeno superior al 10 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310520900080",
  "CN": "3105 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310530000080",
  "CN": "3105 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidrogenoortofosfato de diamonio (fosfato diamónico)"
 },
 {
  "CNKEY": "310540000080",
  "CN": "3105 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dihidrogenoortofosfato de amonio (fosfato monoamónico), incluso mezclado con el hidrogenoortofosfato de diamonio (fosfato diamónico)"
 },
 {
  "CNKEY": "310551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás abonos minerales o químicos con los dos elementos fertilizantes: nitrógeno y fósforo"
 },
 {
  "CNKEY": "310551000080",
  "CN": "3105 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan nitratos y fosfatos"
 },
 {
  "CNKEY": "310559000080",
  "CN": "3105 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "310560000080",
  "CN": "3105 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Abonos minerales o químicos con los dos elementos fertilizantes: fósforo y potasio"
 },
 {
  "CNKEY": "310590000080",
  "CN": "3105 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "310590200080",
  "CN": "3105 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de nitrógeno superior al 10 % en peso del producto anhidro seco"
 },
 {
  "CNKEY": "310590800080",
  "CN": "3105 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320021000090",
  "CN": "32",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 32 - EXTRACTOS CURTIENTES O TINTÓREOS; TANINOS Y SUS DERIVADOS; PIGMENTOS Y DEMÁS MATERIAS COLORANTES; PINTURAS Y BARNICES; MÁSTIQUES; TINTAS"
 },
 {
  "CNKEY": "320100000080",
  "CN": "3201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Extractos curtientes de origen vegetal; taninos y sus sales, éteres, ésteres y demás derivados"
 },
 {
  "CNKEY": "320110000080",
  "CN": "3201 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Extracto de quebracho"
 },
 {
  "CNKEY": "320120000080",
  "CN": "3201 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Extracto de mimosa (acacia)"
 },
 {
  "CNKEY": "320190000080",
  "CN": "3201 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "320190200080",
  "CN": "3201 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Extractos de zumaque, de valonea, de roble o de castaño"
 },
 {
  "CNKEY": "320190900080",
  "CN": "3201 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320200000080",
  "CN": "3202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos curtientes orgánicos sintéticos; productos curtientes inorgánicos; preparaciones curtientes, incluso con productos curtientes naturales; preparaciones enzimáticas para precurtido"
 },
 {
  "CNKEY": "320210000080",
  "CN": "3202 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos curtientes orgánicos sintéticos"
 },
 {
  "CNKEY": "320290000080",
  "CN": "3202 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320300000080",
  "CN": "3203 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Materias colorantes de origen vegetal o animal, incluidos los extractos tintóreos (excepto los negros de origen animal), aunque sean de constitución química definida; preparaciones a que se refiere la Nota 3 de este Capítulo a base de materias de origen vegetal o animal"
 },
 {
  "CNKEY": "320300100080",
  "CN": "3203 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Materias colorantes de origen vegetal y preparaciones a base de estas materias"
 },
 {
  "CNKEY": "320300900080",
  "CN": "3203 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Materias colorantes de origen animal y preparaciones a base de estas materias"
 },
 {
  "CNKEY": "320400000080",
  "CN": "3204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Materias colorantes orgánicas sintéticas, aunque sean de constitución química definida; preparaciones a que se refiere la Nota 3 de este Capítulo a base de materias colorantes orgánicas sintéticas; productos orgánicos sintéticos de los tipos utilizados para el avivado fluorescente o como luminóforos, aunque sean de constitución química definida"
 },
 {
  "CNKEY": "320411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Materias colorantes orgánicas sintéticas y preparaciones a que se refiere la Nota 3 de este Capítulo a base de dichas materias colorantes"
 },
 {
  "CNKEY": "320411000080",
  "CN": "3204 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes dispersos y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320412000080",
  "CN": "3204 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes ácidos, incluso metalizados, y preparaciones a base de estos colorantes; colorantes para mordiente y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320413000080",
  "CN": "3204 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes básicos y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320414000080",
  "CN": "3204 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes directos y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320415000080",
  "CN": "3204 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes a la tina o a la cuba, incluidos los utilizables directamente como colorantes pigmentarios, y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320416000080",
  "CN": "3204 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes reactivos y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320417000080",
  "CN": "3204 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes pigmentarios y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320418000080",
  "CN": "3204 18 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colorantes carotenoides y preparaciones a base de estos colorantes"
 },
 {
  "CNKEY": "320419000080",
  "CN": "3204 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, incluidas las mezclas de materias colorantes de dos o más de las subpartidas 320411 a 320419"
 },
 {
  "CNKEY": "320420000080",
  "CN": "3204 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos orgánicos sintéticos de los tipos utilizados para el avivado fluorescente"
 },
 {
  "CNKEY": "320490000080",
  "CN": "3204 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320500000080",
  "CN": "3205 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Lacas colorantes; preparaciones a que se refiere la Nota 3 de este Capítulo a base de lacas colorantes"
 },
 {
  "CNKEY": "320600000080",
  "CN": "3206",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás materias colorantes; preparaciones a que se refiere la Nota 3 de este Capítulo (excepto las de las partidas 3203, 3204 o 3205); productos inorgánicos de los tipos utilizados como luminóforos, aunque sean de constitución química definida"
 },
 {
  "CNKEY": "320611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pigmentos y preparaciones a base de dióxido de titanio"
 },
 {
  "CNKEY": "320611000080",
  "CN": "3206 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de dióxido de titanio superior o igual al 80 % en peso, calculado sobre materia seca"
 },
 {
  "CNKEY": "320619000080",
  "CN": "3206 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320620000080",
  "CN": "3206 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pigmentos y preparaciones a base de compuestos de cromo"
 },
 {
  "CNKEY": "320641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás materias colorantes y las demás preparaciones"
 },
 {
  "CNKEY": "320641000080",
  "CN": "3206 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ultramar y sus preparaciones"
 },
 {
  "CNKEY": "320642000080",
  "CN": "3206 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Litopón y demás pigmentos y preparaciones a base de sulfuro de cinc"
 },
 {
  "CNKEY": "320649000080",
  "CN": "3206 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "320649100080",
  "CN": "3206 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Magnetita"
 },
 {
  "CNKEY": "320649700080",
  "CN": "3206 49 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "320650000080",
  "CN": "3206 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos inorgánicos de los tipos utilizados como luminóforos"
 },
 {
  "CNKEY": "320700000080",
  "CN": "3207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pigmentos, opacificantes y colores preparados, composiciones vitrificables, engobes, abrillantadores (lustres) líquidos y preparaciones similares, de los tipos utilizados en cerámica, esmaltado o en la industria del vidrio; frita de vidrio y demás vidrios, en polvo, gránulos, copos o escamillas"
 },
 {
  "CNKEY": "320710000080",
  "CN": "3207 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pigmentos, opacificantes y colores preparados y preparaciones similares"
 },
 {
  "CNKEY": "320720000080",
  "CN": "3207 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Composiciones vitrificables, engobes y preparaciones similares"
 },
 {
  "CNKEY": "320720100080",
  "CN": "3207 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Engobes"
 },
 {
  "CNKEY": "320720900080",
  "CN": "3207 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "320730000080",
  "CN": "3207 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Abrillantadores (lustres) líquidos y preparaciones similares"
 },
 {
  "CNKEY": "320740000080",
  "CN": "3207 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frita de vidrio y demás vidrios, en polvo, gránulos, copos o escamillas"
 },
 {
  "CNKEY": "320740400080",
  "CN": "3207 40 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vidrio, en forma de copos, de una longitud superior o igual a 0,1 mm y inferior o igual a 3,5 mm, de espesor superior o igual a 2 micrómetros o inferior o igual a 5 micrómetros; vidrio, en forma de polvo o gránulos, con un contenido de dióxido de silicio superior o igual al 99 % en peso"
 },
 {
  "CNKEY": "320740850080",
  "CN": "3207 40 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "320800000080",
  "CN": "3208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pinturas y barnices a base de polímeros sintéticos o naturales modificados, dispersos o disueltos en un medio no acuoso; disoluciones definidas en la Nota 4 de este Capítulo"
 },
 {
  "CNKEY": "320810000080",
  "CN": "3208 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "A base de poliésteres"
 },
 {
  "CNKEY": "320810100080",
  "CN": "3208 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Disoluciones definidas en la Nota 4 de este Capítulo"
 },
 {
  "CNKEY": "320810900080",
  "CN": "3208 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "320820000080",
  "CN": "3208 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "A base de polímeros acrílicos o vinílicos"
 },
 {
  "CNKEY": "320820100080",
  "CN": "3208 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Disoluciones definidas en la Nota 4 de este Capítulo"
 },
 {
  "CNKEY": "320820900080",
  "CN": "3208 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "320890000080",
  "CN": "3208 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "320890110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Disoluciones definidas en la Nota 4 de este Capítulo"
 },
 {
  "CNKEY": "320890110080",
  "CN": "3208 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poliuretano obtenido de 2,2′-(terc-butilimino)dietanol y de 4,4′-metilendiciclohexildiisocianato, en forma de solución en N,N-dimetilacetamida, con un contenido de polímero superior o igual al 48 % en peso"
 },
 {
  "CNKEY": "320890130080",
  "CN": "3208 90 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copolímero de p-cresol y divinilbenceno, en forma de solución en N,N-dimetilacetamida, con un contenido de polímero superior o igual al 48 % en peso"
 },
 {
  "CNKEY": "320890190080",
  "CN": "3208 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "320890910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "320890910080",
  "CN": "3208 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de polímeros sintéticos"
 },
 {
  "CNKEY": "320890990080",
  "CN": "3208 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de polímeros naturales modificados"
 },
 {
  "CNKEY": "320900000080",
  "CN": "3209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pinturas y barnices a base de polímeros sintéticos o naturales modificados, dispersos o disueltos en un medio acuoso"
 },
 {
  "CNKEY": "320910000080",
  "CN": "3209 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "A base de polímeros acrílicos o vinílicos"
 },
 {
  "CNKEY": "320990000080",
  "CN": "3209 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "321000000080",
  "CN": "3210 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás pinturas y barnices; pigmentos al agua preparados de los tipos utilizados para el acabado del cuero"
 },
 {
  "CNKEY": "321000100080",
  "CN": "3210 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pinturas y barnices al aceite"
 },
 {
  "CNKEY": "321000900080",
  "CN": "3210 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "321100000080",
  "CN": "3211 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Secativos preparados"
 },
 {
  "CNKEY": "321200000080",
  "CN": "3212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pigmentos, incluidos el polvo y escamillas metálicos, dispersos en medios no acuosos, líquidos o en pasta, de los tipos utilizados para la fabricación de pinturas; hojas para el marcado a fuego; tintes y demás materias colorantes presentados en formas o envases para la venta al por menor"
 },
 {
  "CNKEY": "321210000080",
  "CN": "3212 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hojas para el marcado a fuego"
 },
 {
  "CNKEY": "321290000080",
  "CN": "3212 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "321300000080",
  "CN": "3213",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Colores para la pintura artística, la enseñanza, la pintura de carteles, para matizar o para entretenimiento y colores similares, en pastillas, tubos, botes, frascos o en formas o envases similares"
 },
 {
  "CNKEY": "321310000080",
  "CN": "3213 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Colores en surtidos"
 },
 {
  "CNKEY": "321390000080",
  "CN": "3213 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "321400000080",
  "CN": "3214",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Masilla, cementos de resina y demás mástiques; plastes (enduidos) utilizados en pintura; plastes (enduidos) no refractarios de los tipos utilizados en albañilería"
 },
 {
  "CNKEY": "321410000080",
  "CN": "3214 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Masilla, cementos de resina y demás mástiques; plastes (enduidos) utilizados en pintura"
 },
 {
  "CNKEY": "321410100080",
  "CN": "3214 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Masilla, cementos de resina y demás mástiques"
 },
 {
  "CNKEY": "321410900080",
  "CN": "3214 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plastes (enduidos) utilizados en pintura"
 },
 {
  "CNKEY": "321490000080",
  "CN": "3214 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "321500000080",
  "CN": "3215",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tintas de imprimir, tintas de escribir o de dibujar y demás tintas, incluso concentradas o sólidas"
 },
 {
  "CNKEY": "321511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tintas de imprimir"
 },
 {
  "CNKEY": "321511000080",
  "CN": "3215 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Negras"
 },
 {
  "CNKEY": "321519000080",
  "CN": "3215 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "321590000080",
  "CN": "3215 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "321590200080",
  "CN": "3215 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cartuchos de tinta (sin cabezal impresor integrado) que incorporan componentes mecánicos o eléctricos para instalar en aparatos comprendidos en las subpartidas 844331, 844332 u 844339; tinta sólida diseñada a medida para instalar en aparatos comprendidos en las subpartidas 844331, 844332 u 844339"
 },
 {
  "CNKEY": "321590700080",
  "CN": "3215 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330021000090",
  "CN": "33",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 33 - ACEITES ESENCIALES Y RESINOIDES; PREPARACIONES DE PERFUMERÍA, DE TOCADOR O DE COSMÉTICA"
 },
 {
  "CNKEY": "330100000080",
  "CN": "3301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceites esenciales (desterpenados o no), incluidos los «concretos» o «absolutos»; resinoides; oleorresinas de extracción; disoluciones concentradas de aceites esenciales en grasas, aceites fijos, ceras o materias análogas, obtenidas por enflorado o maceración; subproductos terpénicos residuales de la desterpenación de los aceites esenciales; destilados acuosos aromáticos y disoluciones acuosas de aceites esenciales"
 },
 {
  "CNKEY": "330112000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites esenciales de agrios (cítricos)"
 },
 {
  "CNKEY": "330112000080",
  "CN": "3301 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De naranja"
 },
 {
  "CNKEY": "330112100080",
  "CN": "3301 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330112900080",
  "CN": "3301 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330113000080",
  "CN": "3301 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De limón"
 },
 {
  "CNKEY": "330113100080",
  "CN": "3301 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330113900080",
  "CN": "3301 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330119000080",
  "CN": "3301 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "330119200080",
  "CN": "3301 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330119800080",
  "CN": "3301 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330124000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aceites esenciales [excepto los de agrios (cítricos)]"
 },
 {
  "CNKEY": "330124000080",
  "CN": "3301 24",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De menta piperita (Mentha piperita)"
 },
 {
  "CNKEY": "330124100080",
  "CN": "3301 24 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330124900080",
  "CN": "3301 24 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330125000080",
  "CN": "3301 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás mentas"
 },
 {
  "CNKEY": "330125100080",
  "CN": "3301 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330125900080",
  "CN": "3301 25 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330129000080",
  "CN": "3301 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "330129110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De clavo, de niauli, de ilang-ilang"
 },
 {
  "CNKEY": "330129110080",
  "CN": "3301 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330129310080",
  "CN": "3301 29 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330129420010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "330129420020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin desterpenar"
 },
 {
  "CNKEY": "330129420080",
  "CN": "3301 29 42",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De rosa"
 },
 {
  "CNKEY": "330129490080",
  "CN": "3301 29 49",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "330129710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Desterpenados"
 },
 {
  "CNKEY": "330129710080",
  "CN": "3301 29 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De geranio; de jazmín; de espicanardo (vetiver)"
 },
 {
  "CNKEY": "330129790080",
  "CN": "3301 29 79",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De lavanda (espliego) o de lavandín"
 },
 {
  "CNKEY": "330129910080",
  "CN": "3301 29 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330130000080",
  "CN": "3301 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinoides"
 },
 {
  "CNKEY": "330190000080",
  "CN": "3301 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "330190100080",
  "CN": "3301 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Subproductos terpénicos residuales de la desterpenación de los aceites esenciales"
 },
 {
  "CNKEY": "330190210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Oleorresinas de extracción"
 },
 {
  "CNKEY": "330190210080",
  "CN": "3301 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De regaliz y de lúpulo"
 },
 {
  "CNKEY": "330190300080",
  "CN": "3301 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330190900080",
  "CN": "3301 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "330200000080",
  "CN": "3302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mezclas de sustancias odoríferas y mezclas, incluidas las disoluciones alcohólicas, a base de una o varias de estas sustancias, de los tipos utilizados como materias básicas para la industria; las demás preparaciones a base de sustancias odoríferas, de los tipos utilizados para la elaboración de bebidas"
 },
 {
  "CNKEY": "330210000080",
  "CN": "3302 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los tipos utilizados en las industrias alimentarias o de bebidas"
 },
 {
  "CNKEY": "330210100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los tipos utilizados en las industrias de bebidas"
 },
 {
  "CNKEY": "330210100020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Preparaciones que contienen todos los agentes aromatizantes que caracterizan a una bebida"
 },
 {
  "CNKEY": "330210100080",
  "CN": "3302 10 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De grado alcohólico adquirido superior al 0,5 % vol"
 },
 {
  "CNKEY": "330210210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "330210210080",
  "CN": "3302 10 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin materias grasas de leche, sacarosa, isoglucosa, glucosa, almidón o fécula o con un contenido de materias grasas de leche inferior al 1,5 % en peso, de sacarosa o isoglucosa inferior al 5 % en peso o de glucosa o almidón o fécula inferior al 5 % en peso"
 },
 {
  "CNKEY": "330210290080",
  "CN": "3302 10 29",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330210400080",
  "CN": "3302 10 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330210900080",
  "CN": "3302 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en las industrias alimentarias"
 },
 {
  "CNKEY": "330290000080",
  "CN": "3302 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "330290100080",
  "CN": "3302 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Disoluciones alcohólicas"
 },
 {
  "CNKEY": "330290900080",
  "CN": "3302 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330300000080",
  "CN": "3303 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Perfumes y aguas de tocador"
 },
 {
  "CNKEY": "330300100080",
  "CN": "3303 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfumes"
 },
 {
  "CNKEY": "330300900080",
  "CN": "3303 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aguas de tocador"
 },
 {
  "CNKEY": "330400000080",
  "CN": "3304",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones de belleza, maquillaje y para el cuidado de la piel (excepto los medicamentos), incluidas las preparaciones antisolares y las bronceadoras; preparaciones para manicuras o pedicuras"
 },
 {
  "CNKEY": "330410000080",
  "CN": "3304 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para el maquillaje de los labios"
 },
 {
  "CNKEY": "330420000080",
  "CN": "3304 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para el maquillaje de los ojos"
 },
 {
  "CNKEY": "330430000080",
  "CN": "3304 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para manicuras o pedicuras"
 },
 {
  "CNKEY": "330491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "330491000080",
  "CN": "3304 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Polvos, incluidos los compactos"
 },
 {
  "CNKEY": "330499000080",
  "CN": "3304 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330500000080",
  "CN": "3305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones capilares"
 },
 {
  "CNKEY": "330510000080",
  "CN": "3305 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Champúes"
 },
 {
  "CNKEY": "330520000080",
  "CN": "3305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para ondulación o desrizado permanentes"
 },
 {
  "CNKEY": "330530000080",
  "CN": "3305 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lacas para el cabello"
 },
 {
  "CNKEY": "330590000080",
  "CN": "3305 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330600000080",
  "CN": "3306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones para higiene bucal o dental, incluidos los polvos y cremas para la adherencia de las dentaduras; hilo utilizado para limpieza de los espacios interdentales (hilo dental), en envases individuales para la venta al por menor"
 },
 {
  "CNKEY": "330610000080",
  "CN": "3306 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Dentífricos"
 },
 {
  "CNKEY": "330620000080",
  "CN": "3306 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilo utilizado para limpieza de los espacios interdentales (hilo dental)"
 },
 {
  "CNKEY": "330690000080",
  "CN": "3306 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "330700000080",
  "CN": "3307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones para afeitar o para antes o después del afeitado, desodorantes corporales, preparaciones para el baño, depilatorios y demás preparaciones de perfumería, de tocador o de cosmética, no expresadas ni comprendidas en otra parte; preparaciones desodorantes de locales, incluso sin perfumar, aunque tengan propiedades desinfectantes"
 },
 {
  "CNKEY": "330710000080",
  "CN": "3307 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para afeitar o para antes o después del afeitado"
 },
 {
  "CNKEY": "330720000080",
  "CN": "3307 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desodorantes corporales y antitranspirantes"
 },
 {
  "CNKEY": "330730000080",
  "CN": "3307 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sales perfumadas y demás preparaciones para el baño"
 },
 {
  "CNKEY": "330741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones para perfumar o desodorizar locales, incluidas las preparaciones odoríferas para ceremonias religiosas"
 },
 {
  "CNKEY": "330741000080",
  "CN": "3307 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agarbatti y demás preparaciones odoríferas que actúen por combustión"
 },
 {
  "CNKEY": "330749000080",
  "CN": "3307 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "330790000080",
  "CN": "3307 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340021000090",
  "CN": "34",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 34 - JABÓN, AGENTES DE SUPERFICIE ORGÁNICOS, PREPARACIONES PARA LAVAR, PREPARACIONES LUBRICANTES, CERAS ARTIFICIALES, CERAS PREPARADAS, PRODUCTOS DE LIMPIEZA, VELAS Y ARTÍCULOS SIMILARES, PASTAS PARA MODELAR, «CERAS PARA ODONTOLOGÍA» Y PREPARACIONES PARA ODONTOLOGÍA A BASE DE YESO FRAGUABLE"
 },
 {
  "CNKEY": "340100000080",
  "CN": "3401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Jabón; productos y preparaciones orgánicos tensoactivos utilizados como jabón, en barras, panes, trozos o piezas troqueladas o moldeadas, aunque contengan jabón; productos y preparaciones orgánicos tensoactivos para el lavado de la piel, líquidos o en crema, acondicionados para la venta al por menor, aunque contengan jabón; papel, guata, fieltro y tela sin tejer, impregnados, recubiertos o revestidos de jabón o de detergentes"
 },
 {
  "CNKEY": "340111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jabón, productos y preparaciones orgánicos tensoactivos, en barras, panes, trozos o piezas troqueladas o moldeadas, y papel, guata, fieltro y tela sin tejer, impregnados, recubiertos o revestidos de jabón o de detergentes"
 },
 {
  "CNKEY": "340111000080",
  "CN": "3401 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De tocador, incluso los medicinales"
 },
 {
  "CNKEY": "340119000080",
  "CN": "3401 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340120000080",
  "CN": "3401 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jabón en otras formas"
 },
 {
  "CNKEY": "340120100080",
  "CN": "3401 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copos, gránulos o polvo"
 },
 {
  "CNKEY": "340120900080",
  "CN": "3401 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340130000080",
  "CN": "3401 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos y preparaciones orgánicos tensoactivos para el lavado de la piel, líquidos o en crema, acondicionados para la venta al por menor, aunque contengan jabón"
 },
 {
  "CNKEY": "340200000080",
  "CN": "3402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Agentes de superficie orgánicos (excepto el jabón); preparaciones tensoactivas, preparaciones para lavar, incluidas las preparaciones auxiliares de lavado, y preparaciones de limpieza, aunque contengan jabón (excepto las de la partida 3401)"
 },
 {
  "CNKEY": "340231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Agentes de superficie orgánicos aniónicos, incluso acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "340231000080",
  "CN": "3402 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácidos alquilbenceno sulfónicos lineales y sus sales"
 },
 {
  "CNKEY": "340239000080",
  "CN": "3402 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "340239100080",
  "CN": "3402 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Solución acuosa con un contenido de alquil[oxidi(bencenosulfonato)] de disodio superior o igual al 30 % pero inferior o igual al 50 % en peso"
 },
 {
  "CNKEY": "340239900080",
  "CN": "3402 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás agentes de superficie orgánicos, incluso acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "340241000080",
  "CN": "3402 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Catiónicos"
 },
 {
  "CNKEY": "340242000080",
  "CN": "3402 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "No iónicos"
 },
 {
  "CNKEY": "340249000080",
  "CN": "3402 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340250000080",
  "CN": "3402 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones acondicionadas para la venta al por menor"
 },
 {
  "CNKEY": "340250100080",
  "CN": "3402 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones tensoactivas"
 },
 {
  "CNKEY": "340250900080",
  "CN": "3402 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones para lavar y preparaciones de limpieza"
 },
 {
  "CNKEY": "340290000080",
  "CN": "3402 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "340290100080",
  "CN": "3402 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones tensoactivas"
 },
 {
  "CNKEY": "340290900080",
  "CN": "3402 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones para lavar y preparaciones de limpieza"
 },
 {
  "CNKEY": "340300000080",
  "CN": "3403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones lubricantes, incluidos los aceites de corte, las preparaciones para aflojar tuercas, las preparaciones antiherrumbre o anticorrosión y las preparaciones para el desmoldeo, a base de lubricantes y preparaciones de los tipos utilizados para el ensimado de materias textiles o el aceitado o engrasado de cueros y pieles, peletería u otras materias (excepto aquellas que contengan como componente básico una proporción de aceites de petróleo o de mineral bituminoso superior o igual al 70 % en peso)"
 },
 {
  "CNKEY": "340311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan aceites de petróleo o de mineral bituminoso"
 },
 {
  "CNKEY": "340311000080",
  "CN": "3403 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones para el tratamiento de materias textiles, cueros y pieles, peletería u otras materias"
 },
 {
  "CNKEY": "340319000080",
  "CN": "3403 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "340319100080",
  "CN": "3403 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de aceites de petróleo o de mineral bituminoso superior o igual al 70 % en peso, pero que no sean los componentes básicos"
 },
 {
  "CNKEY": "340319200080",
  "CN": "3403 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Lubricantes con un contenido de carbono de origen biológico de al menos el 25 % en masa y que sean biodegradables a un nivel de al menos el 60 %"
 },
 {
  "CNKEY": "340319800080",
  "CN": "3403 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "340391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "340391000080",
  "CN": "3403 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparaciones para el tratamiento de materias textiles, cueros y pieles, peletería u otras materias"
 },
 {
  "CNKEY": "340399000080",
  "CN": "3403 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "340400000080",
  "CN": "3404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ceras artificiales y ceras preparadas"
 },
 {
  "CNKEY": "340420000080",
  "CN": "3404 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De poli(oxietileno) (polietilenglicol)"
 },
 {
  "CNKEY": "340490000080",
  "CN": "3404 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "340500000080",
  "CN": "3405",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Betunes y cremas para el calzado, encáusticos, abrillantadores (lustres) para carrocerías, vidrio o metal, pastas y polvos para fregar y preparaciones similares, incluso papel, guata, fieltro, tela sin tejer, plástico o caucho celulares, impregnados, recubiertos o revestidos de estas preparaciones (excepto las ceras de la partida 3404)"
 },
 {
  "CNKEY": "340510000080",
  "CN": "3405 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Betunes, cremas y preparaciones similares para el calzado o para cueros y pieles"
 },
 {
  "CNKEY": "340520000080",
  "CN": "3405 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Encáusticos y preparaciones similares para la conservación de muebles de madera, parqués u otras manufacturas de madera"
 },
 {
  "CNKEY": "340530000080",
  "CN": "3405 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Abrillantadores (lustres) y preparaciones similares para carrocerías (excepto las preparaciones para lustrar metal)"
 },
 {
  "CNKEY": "340540000080",
  "CN": "3405 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pastas, polvos y demás preparaciones para fregar"
 },
 {
  "CNKEY": "340590000080",
  "CN": "3405 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "340590100080",
  "CN": "3405 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Abrillantadores para metal"
 },
 {
  "CNKEY": "340590900080",
  "CN": "3405 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "340600000080",
  "CN": "3406 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Velas, cirios y artículos similares"
 },
 {
  "CNKEY": "340700000080",
  "CN": "3407 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Pastas para modelar, incluidas las presentadas para entretenimiento de los niños; preparaciones llamadas «ceras para odontología» o «compuestos para impresión dental», presentadas en juegos o surtidos, en envases para la venta al por menor o en plaquitas, herraduras, barritas o formas similares; las demás preparaciones para odontología a base de yeso fraguable"
 },
 {
  "CNKEY": "350021000090",
  "CN": "35",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 35 - MATERIAS ALBUMINOIDEAS; PRODUCTOS A BASE DE ALMIDÓN O DE FÉCULA MODIFICADOS; COLAS; ENZIMAS"
 },
 {
  "CNKEY": "350100000080",
  "CN": "3501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caseína, caseinatos y demás derivados de la caseína; colas de caseína"
 },
 {
  "CNKEY": "350110000080",
  "CN": "3501 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caseína"
 },
 {
  "CNKEY": "350110100080",
  "CN": "3501 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destine a la fabricación de fibras textiles artificiales"
 },
 {
  "CNKEY": "350110500080",
  "CN": "3501 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que se destine a usos industriales distintos de la fabricación de productos alimenticios o forrajeros"
 },
 {
  "CNKEY": "350110900080",
  "CN": "3501 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350190000080",
  "CN": "3501 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "350190100080",
  "CN": "3501 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colas de caseína"
 },
 {
  "CNKEY": "350190900080",
  "CN": "3501 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "350200000080",
  "CN": "3502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Albúminas (incluidos los concentrados de varias proteínas del lactosuero, con un contenido de proteínas del lactosuero superior al 80 % en peso, calculado sobre materia seca) albuminatos y demás derivados de las albúminas"
 },
 {
  "CNKEY": "350211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ovoalbúmina"
 },
 {
  "CNKEY": "350211000080",
  "CN": "3502 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Seca"
 },
 {
  "CNKEY": "350211100080",
  "CN": "3502 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropia o hecha impropia para la alimentación humana"
 },
 {
  "CNKEY": "350211900080",
  "CN": "3502 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350219000080",
  "CN": "3502 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "350219100080",
  "CN": "3502 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropia o hecha impropia para la alimentación humana"
 },
 {
  "CNKEY": "350219900080",
  "CN": "3502 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350220000080",
  "CN": "3502 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lactoalbúmina, incluidos los concentrados de dos o más proteínas del lactosuero"
 },
 {
  "CNKEY": "350220100080",
  "CN": "3502 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Impropia o hecha impropia para la alimentación humana"
 },
 {
  "CNKEY": "350220910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "350220910080",
  "CN": "3502 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Seca (por ejemplo: en hojas, escamas, cristales, polvo)"
 },
 {
  "CNKEY": "350220990080",
  "CN": "3502 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350290000080",
  "CN": "3502 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "350290200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Albúminas (excepto la ovoalbúmina)"
 },
 {
  "CNKEY": "350290200080",
  "CN": "3502 90 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Impropias o hechas impropias para la alimentación humana"
 },
 {
  "CNKEY": "350290700080",
  "CN": "3502 90 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350290900080",
  "CN": "3502 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Albuminatos y otros derivados de las albúminas"
 },
 {
  "CNKEY": "350300000080",
  "CN": "3503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Gelatinas (aunque se presenten en hojas cuadradas o rectangulares, incluso trabajadas en la superficie o coloreadas) y sus derivados; ictiocola; las demás colas de origen animal (excepto las colas de caseína de la partida 3501)"
 },
 {
  "CNKEY": "350300100080",
  "CN": "3503 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Gelatinas y sus derivados"
 },
 {
  "CNKEY": "350300800080",
  "CN": "3503 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350400000080",
  "CN": "3504 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Peptonas y sus derivados; las demás materias proteínicas y sus derivados, no expresados ni comprendidos en otra parte; polvo de cueros y pieles, incluso tratado al cromo"
 },
 {
  "CNKEY": "350400100080",
  "CN": "3504 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Concentrados de proteínas de leche a que se refiere la Nota complementaria 1 de este Capítulo"
 },
 {
  "CNKEY": "350400900080",
  "CN": "3504 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "350500000080",
  "CN": "3505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Dextrina y demás almidones y féculas modificados (por ejemplo: almidones y féculas pregelatinizados o esterificados); colas a base de almidón, fécula, dextrina o demás almidones o féculas modificados"
 },
 {
  "CNKEY": "350510000080",
  "CN": "3505 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Dextrina y demás almidones y féculas modificados"
 },
 {
  "CNKEY": "350510100080",
  "CN": "3505 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dextrina"
 },
 {
  "CNKEY": "350510500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás almidones y féculas modificados"
 },
 {
  "CNKEY": "350510500080",
  "CN": "3505 10 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Almidones y féculas esterificados o eterificados"
 },
 {
  "CNKEY": "350510900080",
  "CN": "3505 10 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "350520000080",
  "CN": "3505 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Colas"
 },
 {
  "CNKEY": "350520100080",
  "CN": "3505 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón o de fécula, de dextrina u otros almidones y féculas modificados, inferior al 25 % en peso"
 },
 {
  "CNKEY": "350520300080",
  "CN": "3505 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón o de fécula, de dextrina u otros almidones y féculas modificados, superior o igual al 25 % pero inferior al 55 % en peso"
 },
 {
  "CNKEY": "350520500080",
  "CN": "3505 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón o de fécula, de dextrina u otros almidones y féculas modificados, superior o igual al 55 % pero inferior al 80 % en peso"
 },
 {
  "CNKEY": "350520900080",
  "CN": "3505 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de almidón o de fécula, de dextrina u otros almidones y féculas modificados, superior o igual al 80 % en peso"
 },
 {
  "CNKEY": "350600000080",
  "CN": "3506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Colas y demás adhesivos preparados, no expresados ni comprendidos en otra parte; productos de cualquier clase utilizados como colas o adhesivos, acondicionados para la venta al por menor como colas o adhesivos, de peso neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "350610000080",
  "CN": "3506 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos de cualquier clase utilizados como colas o adhesivos, acondicionados para la venta al por menor como colas o adhesivos, de peso neto inferior o igual a 1 kg"
 },
 {
  "CNKEY": "350691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "350691000080",
  "CN": "3506 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Adhesivos a base de polímeros de las partidas 3901 a 3913 o de caucho"
 },
 {
  "CNKEY": "350691100080",
  "CN": "3506 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Adhesivos transparentes en forma de película sin soporte y adhesivos líquidos transparentes endurecibles de los tipos utilizados, exclusiva o principalmente, en la fabricación de dispositivos de visualización de pantalla plana o en paneles de pantallas táctiles"
 },
 {
  "CNKEY": "350691900080",
  "CN": "3506 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "350699000080",
  "CN": "3506 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "350700000080",
  "CN": "3507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Enzimas; preparaciones enzimáticas no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "350710000080",
  "CN": "3507 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuajo y sus concentrados"
 },
 {
  "CNKEY": "350790000080",
  "CN": "3507 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "350790300080",
  "CN": "3507 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lipoproteína lipasa; proteasa alcalina de Aspergillus"
 },
 {
  "CNKEY": "350790900080",
  "CN": "3507 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "360021000090",
  "CN": "36",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 36 - PÓLVORA Y EXPLOSIVOS; ARTÍCULOS DE PIROTECNIA; FÓSFOROS (CERILLAS); ALEACIONES PIROFÓRICAS; MATERIAS INFLAMABLES"
 },
 {
  "CNKEY": "360100000080",
  "CN": "3601 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Pólvora"
 },
 {
  "CNKEY": "360200000080",
  "CN": "3602 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Explosivos preparados (excepto la pólvora)"
 },
 {
  "CNKEY": "360300000080",
  "CN": "3603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mechas de seguridad; cordones detonantes; cebos y cápsulas, fulminantes; inflamadores; detonadores eléctricos"
 },
 {
  "CNKEY": "360310000080",
  "CN": "3603 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mechas de seguridad"
 },
 {
  "CNKEY": "360320000080",
  "CN": "3603 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cordones detonantes"
 },
 {
  "CNKEY": "360330000080",
  "CN": "3603 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cebos fulminantes"
 },
 {
  "CNKEY": "360340000080",
  "CN": "3603 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cápsulas fulminantes"
 },
 {
  "CNKEY": "360350000080",
  "CN": "3603 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Inflamadores"
 },
 {
  "CNKEY": "360360000080",
  "CN": "3603 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Detonadores eléctricos"
 },
 {
  "CNKEY": "360400000080",
  "CN": "3604",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos para fuegos artificiales, cohetes de señales o granífugos y similares, petardos y demás artículos de pirotecnia"
 },
 {
  "CNKEY": "360410000080",
  "CN": "3604 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos para fuegos artificiales"
 },
 {
  "CNKEY": "360490000080",
  "CN": "3604 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "360500000080",
  "CN": "3605 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Fósforos (cerillas) (excepto los artículos de pirotecnia de la partida 3604)"
 },
 {
  "CNKEY": "360600000080",
  "CN": "3606",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ferrocerio y demás aleaciones pirofóricas en cualquier forma; artículos de materias inflamables a que se refiere la Nota 2 de este Capítulo"
 },
 {
  "CNKEY": "360610000080",
  "CN": "3606 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Combustibles líquidos y gases combustibles licuados en recipientes de los tipos utilizados para cargar o recargar encendedores o mecheros, de capacidad inferior o igual a 300 cm³"
 },
 {
  "CNKEY": "360690000080",
  "CN": "3606 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "360690100080",
  "CN": "3606 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ferrocerio y demás aleaciones piróforicas en cualquier forma"
 },
 {
  "CNKEY": "360690900080",
  "CN": "3606 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "370021000090",
  "CN": "37",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 37 - PRODUCTOS FOTOGRÁFICOS O CINEMATOGRÁFICOS"
 },
 {
  "CNKEY": "370100000080",
  "CN": "3701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas y películas planas, fotográficas, sensibilizadas, sin impresionar, excepto las de papel, cartón o textiles; películas fotográficas planas autorrevelables, sensibilizadas, sin impresionar, incluso en cargadores"
 },
 {
  "CNKEY": "370110000080",
  "CN": "3701 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Para rayos X"
 },
 {
  "CNKEY": "370120000080",
  "CN": "3701 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Películas autorrevelables"
 },
 {
  "CNKEY": "370130000080",
  "CN": "3701 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Las demás placas y películas planas en las que por lo menos un lado sea superior a 255 mm"
 },
 {
  "CNKEY": "370191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "370191000080",
  "CN": "3701 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para fotografía en colores (policroma)"
 },
 {
  "CNKEY": "370199000080",
  "CN": "3701 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370200000080",
  "CN": "3702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Películas fotográficas en rollos, sensibilizadas, sin impresionar, excepto las de papel, cartón o textiles; películas fotográficas autorrevelables en rollos, sensibilizadas, sin impresionar"
 },
 {
  "CNKEY": "370210000080",
  "CN": "3702 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Para rayos X"
 },
 {
  "CNKEY": "370231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás películas, sin perforar, de anchura inferior o igual a 105 mm"
 },
 {
  "CNKEY": "370231000080",
  "CN": "3702 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para fotografía en colores (policroma)"
 },
 {
  "CNKEY": "370231910080",
  "CN": "3702 31 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Película negativa de color: - de anchura superior o igual a 75 mm pero inferior o igual a 105 mm, y - de longitud superior o igual a 100 m;destinada a la fabricación de películas de fotografía instantánea"
 },
 {
  "CNKEY": "370231970080",
  "CN": "3702 31 97",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370232000080",
  "CN": "3702 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, con emulsión de halogenuros de plata"
 },
 {
  "CNKEY": "370232100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De anchura inferior o igual a 35 mm"
 },
 {
  "CNKEY": "370232100080",
  "CN": "3702 32 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Microfilmes; películas para las artes gráficas"
 },
 {
  "CNKEY": "370232200080",
  "CN": "3702 32 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370232850080",
  "CN": "3702 32 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De anchura superior a 35 mm"
 },
 {
  "CNKEY": "370239000080",
  "CN": "3702 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás películas, sin perforar, de anchura superior a 105 mm"
 },
 {
  "CNKEY": "370241000080",
  "CN": "3702 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 610 mm y longitud superior a 200 m, para fotografía en colores (policroma)"
 },
 {
  "CNKEY": "370242000080",
  "CN": "3702 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 610 mm y longitud superior a 200 m (excepto para fotografía en colores)"
 },
 {
  "CNKEY": "370243000080",
  "CN": "3702 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 610 mm y longitud inferior o igual a 200 m"
 },
 {
  "CNKEY": "370244000080",
  "CN": "3702 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 105 mm pero inferior o igual a 610 mm"
 },
 {
  "CNKEY": "370252000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás películas para fotografía en colores (policroma)"
 },
 {
  "CNKEY": "370252000080",
  "CN": "3702 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De anchura inferior o igual a 16 mm"
 },
 {
  "CNKEY": "370253000080",
  "CN": "3702 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De anchura superior a 16 mm pero inferior o igual a 35 mm y longitud inferior o igual a 30 m, para diapositivas"
 },
 {
  "CNKEY": "370254000080",
  "CN": "3702 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De anchura superior a 16 mm pero inferior o igual a 35 mm y longitud inferior o igual a 30 m (excepto para diapositivas)"
 },
 {
  "CNKEY": "370255000080",
  "CN": "3702 55 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "De anchura superior a 16 mm pero inferior o igual a 35 mm y longitud superior a 30 m"
 },
 {
  "CNKEY": "370256000080",
  "CN": "3702 56 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De anchura superior a 35 mm"
 },
 {
  "CNKEY": "370296000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "370296000080",
  "CN": "3702 96",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De anchura inferior o igual a 35 mm y longitud inferior o igual a 30 m"
 },
 {
  "CNKEY": "370296100080",
  "CN": "3702 96 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Microfilmes; películas para las artes gráficas"
 },
 {
  "CNKEY": "370296900080",
  "CN": "3702 96 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370297000080",
  "CN": "3702 97",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De anchura inferior o igual a 35 mm y longitud superior a 30 m"
 },
 {
  "CNKEY": "370297100080",
  "CN": "3702 97 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Microfilmes; películas para las artes gráficas"
 },
 {
  "CNKEY": "370297900080",
  "CN": "3702 97 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370298000080",
  "CN": "3702 98 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De anchura superior a 35 mm"
 },
 {
  "CNKEY": "370300000080",
  "CN": "3703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel, cartón y textiles, fotográficos, sensibilizados, sin impresionar"
 },
 {
  "CNKEY": "370310000080",
  "CN": "3703 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En rollos de anchura superior a 610 mm"
 },
 {
  "CNKEY": "370320000080",
  "CN": "3703 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, para fotografía en colores (policroma)"
 },
 {
  "CNKEY": "370390000080",
  "CN": "3703 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "370400000080",
  "CN": "3704 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas, películas, papel, cartón y textiles, fotográficos, impresionados pero sin revelar"
 },
 {
  "CNKEY": "370400100080",
  "CN": "3704 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Placas y películas"
 },
 {
  "CNKEY": "370400900080",
  "CN": "3704 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "370500000080",
  "CN": "3705 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas y películas, fotográficas, impresionadas y reveladas, excepto las cinematográficas (filmes)"
 },
 {
  "CNKEY": "370500100080",
  "CN": "3705 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para la reproducción offset"
 },
 {
  "CNKEY": "370500900080",
  "CN": "3705 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "370600000080",
  "CN": "3706",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Películas cinematográficas (filmes), impresionadas y reveladas, con registro de sonido o sin él, o con registro de sonido solamente"
 },
 {
  "CNKEY": "370610000080",
  "CN": "3706 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De anchura superior o igual a 35 mm"
 },
 {
  "CNKEY": "370610200080",
  "CN": "3706 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Con registro de sonido solamente; negativas; positivas intermedias de trabajo"
 },
 {
  "CNKEY": "370610990080",
  "CN": "3706 10 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Las demás positivas"
 },
 {
  "CNKEY": "370690000080",
  "CN": "3706 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "370690520080",
  "CN": "3706 90 52",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Con registro de sonido solamente; negativas; positivas intermedias de trabajo; noticiarios"
 },
 {
  "CNKEY": "370690910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, de anchura"
 },
 {
  "CNKEY": "370690910080",
  "CN": "3706 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Inferior a 10 mm"
 },
 {
  "CNKEY": "370690990080",
  "CN": "3706 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Superior o igual a 10 mm"
 },
 {
  "CNKEY": "370700000080",
  "CN": "3707",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones químicas para uso fotográfico (excepto los barnices, colas, adhesivos y preparaciones similares); productos sin mezclar para uso fotográfico, dosificados o acondicionados para la venta al por menor listos para su empleo"
 },
 {
  "CNKEY": "370710000080",
  "CN": "3707 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Emulsiones para sensibilizar superficies"
 },
 {
  "CNKEY": "370790000080",
  "CN": "3707 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "370790200080",
  "CN": "3707 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Reveladores y fijadores"
 },
 {
  "CNKEY": "370790900080",
  "CN": "3707 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380021000090",
  "CN": "38",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 38 - PRODUCTOS DIVERSOS DE LAS INDUSTRIAS QUÍMICAS"
 },
 {
  "CNKEY": "380100000080",
  "CN": "3801",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grafito artificial; grafito coloidal o semicoloidal; preparaciones a base de grafito u otros carbonos, en pasta, bloques, plaquitas u otras semimanufacturas"
 },
 {
  "CNKEY": "380110000080",
  "CN": "3801 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Grafito artificial"
 },
 {
  "CNKEY": "380120000080",
  "CN": "3801 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grafito coloidal o semicoloidal"
 },
 {
  "CNKEY": "380120100080",
  "CN": "3801 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grafito coloidal en suspensión en aceite; grafito semicoloidal"
 },
 {
  "CNKEY": "380120900080",
  "CN": "3801 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380130000080",
  "CN": "3801 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pastas carbonosas para electrodos y pastas similares para el revestimiento interior de hornos"
 },
 {
  "CNKEY": "380190000080",
  "CN": "3801 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "380200000080",
  "CN": "3802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carbón activado; materias minerales naturales activadas; negro de origen animal, incluido el agotado"
 },
 {
  "CNKEY": "380210000080",
  "CN": "3802 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbón activado"
 },
 {
  "CNKEY": "380290000080",
  "CN": "3802 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380300000080",
  "CN": "3803 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tall oil, incluso refinado"
 },
 {
  "CNKEY": "380300100080",
  "CN": "3803 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "380300900080",
  "CN": "3803 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380400000080",
  "CN": "3804 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Lejías residuales de la fabricación de pastas de celulosa, aunque estén concentradas, desazucaradas o tratadas químicamente, incluidos los lignosulfonatos (excepto el tall oil de la partida 3803)"
 },
 {
  "CNKEY": "380500000080",
  "CN": "3805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Esencias de trementina, de madera de pino o de pasta celulósica al sulfato (sulfato de trementina) y demás esencias terpénicas procedentes de la destilación o de otros tratamientos de la madera de coníferas; dipenteno en bruto; esencia de pasta celulósica al bisulfito (bisulfito de trementina) y demás paracimenos en bruto; aceite de pino con alfa-terpineol como componente principal"
 },
 {
  "CNKEY": "380510000080",
  "CN": "3805 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esencias de trementina, de madera de pino o de pasta celulósica al sulfato (sulfato de trementina)"
 },
 {
  "CNKEY": "380510100080",
  "CN": "3805 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Esencia de trementina"
 },
 {
  "CNKEY": "380510300080",
  "CN": "3805 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Esencia de madera de pino"
 },
 {
  "CNKEY": "380510900080",
  "CN": "3805 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Esencia de pasta celulósica al sulfato (sulfato de trementina)"
 },
 {
  "CNKEY": "380590000080",
  "CN": "3805 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "380590100080",
  "CN": "3805 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aceite de pino"
 },
 {
  "CNKEY": "380590900080",
  "CN": "3805 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380600000080",
  "CN": "3806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Colofonias y ácidos resínicos, y sus derivados; esencia y aceites de colofonia; gomas fundidas"
 },
 {
  "CNKEY": "380610000080",
  "CN": "3806 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Colofonias y ácidos resínicos"
 },
 {
  "CNKEY": "380620000080",
  "CN": "3806 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sales de colofonias, de ácidos resínicos o de derivados de colofonias o de ácidos resínicos (excepto las sales de aductos de colofonias)"
 },
 {
  "CNKEY": "380630000080",
  "CN": "3806 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Gomas éster"
 },
 {
  "CNKEY": "380690000080",
  "CN": "3806 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380700000080",
  "CN": "3807 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alquitranes de madera; aceites de alquitrán de madera; creosota de madera; metileno (nafta de madera); pez vegetal; pez de cervecería y preparaciones similares a base de colofonia, de ácidos resínicos o de pez vegetal"
 },
 {
  "CNKEY": "380700100080",
  "CN": "3807 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alquitranes de madera"
 },
 {
  "CNKEY": "380700900080",
  "CN": "3807 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380800000080",
  "CN": "3808",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Insecticidas, raticidas y demás antirroedores, fungicidas, herbicidas, inhibidores de germinación y reguladores del crecimiento de las plantas, desinfectantes y productos similares, presentados en formas o en envases para la venta al por menor, o como preparaciones o artículos tales como cintas, mechas y velas, azufradas, y papeles matamoscas"
 },
 {
  "CNKEY": "380852000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos mencionados en la Nota 1 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "380852000080",
  "CN": "3808 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "DDT (ISO) [clofenotano (DCI)], acondicionado en envases con un contenido en peso neto inferior o igual a 300 g"
 },
 {
  "CNKEY": "380859000080",
  "CN": "3808 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380861000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos mencionados en la Nota 2 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "380861000080",
  "CN": "3808 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados en envases con un contenido en peso neto inferior o igual a 300 g"
 },
 {
  "CNKEY": "380862000080",
  "CN": "3808 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados en envases con un contenido en peso neto superior a 300 g pero inferior o igual a 7,5 kg"
 },
 {
  "CNKEY": "380869000080",
  "CN": "3808 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "380891000080",
  "CN": "3808 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Insecticidas"
 },
 {
  "CNKEY": "380891100080",
  "CN": "3808 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de piretrinoides"
 },
 {
  "CNKEY": "380891200080",
  "CN": "3808 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de hidrocarburos clorados"
 },
 {
  "CNKEY": "380891300080",
  "CN": "3808 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de carbamatos"
 },
 {
  "CNKEY": "380891400080",
  "CN": "3808 91 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de compuestos organofosforados"
 },
 {
  "CNKEY": "380891900080",
  "CN": "3808 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380892000080",
  "CN": "3808 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fungicidas"
 },
 {
  "CNKEY": "380892100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inorgánicos"
 },
 {
  "CNKEY": "380892100080",
  "CN": "3808 92 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Preparaciones cúpricas"
 },
 {
  "CNKEY": "380892200080",
  "CN": "3808 92 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380892300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "380892300080",
  "CN": "3808 92 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de ditiocarbamatos"
 },
 {
  "CNKEY": "380892400080",
  "CN": "3808 92 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de bencimidazoles"
 },
 {
  "CNKEY": "380892500080",
  "CN": "3808 92 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de diazoles o triazoles"
 },
 {
  "CNKEY": "380892600080",
  "CN": "3808 92 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de diacinas o morfolinas"
 },
 {
  "CNKEY": "380892900080",
  "CN": "3808 92 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380893000080",
  "CN": "3808 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Herbicidas, inhibidores de germinación y reguladores del crecimiento de las plantas"
 },
 {
  "CNKEY": "380893110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Herbicidas"
 },
 {
  "CNKEY": "380893110080",
  "CN": "3808 93 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de fenoxifitohormonas"
 },
 {
  "CNKEY": "380893130080",
  "CN": "3808 93 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de triacinas"
 },
 {
  "CNKEY": "380893150080",
  "CN": "3808 93 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de amidas"
 },
 {
  "CNKEY": "380893170080",
  "CN": "3808 93 17",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de carbamatos"
 },
 {
  "CNKEY": "380893210080",
  "CN": "3808 93 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de derivados de dinitroanilinas"
 },
 {
  "CNKEY": "380893230080",
  "CN": "3808 93 23",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "A base de derivados de urea, de uracilos o de sulfonilureas"
 },
 {
  "CNKEY": "380893270080",
  "CN": "3808 93 27",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380893300080",
  "CN": "3808 93 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inhibidores de germinación"
 },
 {
  "CNKEY": "380893900080",
  "CN": "3808 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Reguladores del crecimiento de las plantas"
 },
 {
  "CNKEY": "380894000080",
  "CN": "3808 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Desinfectantes"
 },
 {
  "CNKEY": "380894100080",
  "CN": "3808 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de sales de amonio cuaternario"
 },
 {
  "CNKEY": "380894200080",
  "CN": "3808 94 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de compuestos halogenados"
 },
 {
  "CNKEY": "380894900080",
  "CN": "3808 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380899000080",
  "CN": "3808 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "380899100080",
  "CN": "3808 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Raticidas y demás antirroedores"
 },
 {
  "CNKEY": "380899900080",
  "CN": "3808 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "380900000080",
  "CN": "3809",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aprestos y productos de acabado, aceleradores de tintura o de fijación de materias colorantes y demás productos y preparaciones (por ejemplo: aprestos y mordientes), de los tipos utilizados en la industria textil, del papel, del cuero o industrias similares, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "380910000080",
  "CN": "3809 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "A base de materias amiláceas"
 },
 {
  "CNKEY": "380910100080",
  "CN": "3809 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de estas materias inferior al 55 % en peso"
 },
 {
  "CNKEY": "380910300080",
  "CN": "3809 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de estas materias superior o igual al 55 % pero inferior al 70 % en peso"
 },
 {
  "CNKEY": "380910500080",
  "CN": "3809 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de estas materias superior o igual al 70 % pero inferior al 83 % en peso"
 },
 {
  "CNKEY": "380910900080",
  "CN": "3809 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de estas materias superior o igual al 83 % en peso"
 },
 {
  "CNKEY": "380991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "380991000080",
  "CN": "3809 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en la industria textil o industrias similares"
 },
 {
  "CNKEY": "380992000080",
  "CN": "3809 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en la industria del papel o industrias similares"
 },
 {
  "CNKEY": "380993000080",
  "CN": "3809 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en la industria del cuero o industrias similares"
 },
 {
  "CNKEY": "381000000080",
  "CN": "3810",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones para el decapado de metal; flujos y demás preparaciones auxiliares para soldar metal; pastas y polvos para soldar, constituidos por metal y otros productos; preparaciones de los tipos utilizados para recubrir o rellenar electrodos o varillas de soldadura"
 },
 {
  "CNKEY": "381010000080",
  "CN": "3810 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones para el decapado de metal; pastas y polvos para soldar, constituidos por metal y otros productos"
 },
 {
  "CNKEY": "381090000080",
  "CN": "3810 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "381090100080",
  "CN": "3810 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Preparados para recubrir o rellenar varillas de soldadura"
 },
 {
  "CNKEY": "381090900080",
  "CN": "3810 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381100000080",
  "CN": "3811",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones antidetonantes, inhibidores de oxidación, aditivos peptizantes, mejoradores de viscosidad, anticorrosivos y demás aditivos preparados para aceites minerales, incluida la gasolina, u otros líquidos utilizados para los mismos fines que los aceites minerales"
 },
 {
  "CNKEY": "381111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones antidetonantes"
 },
 {
  "CNKEY": "381111000080",
  "CN": "3811 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "A base de compuestos de plomo"
 },
 {
  "CNKEY": "381111100080",
  "CN": "3811 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de tetraetilplomo"
 },
 {
  "CNKEY": "381111900080",
  "CN": "3811 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "381119000080",
  "CN": "3811 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "381121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aditivos para aceites lubricantes"
 },
 {
  "CNKEY": "381121000080",
  "CN": "3811 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan aceites de petróleo o de mineral bituminoso"
 },
 {
  "CNKEY": "381129000080",
  "CN": "3811 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381190000080",
  "CN": "3811 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381200000080",
  "CN": "3812",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aceleradores de vulcanización preparados; plastificantes compuestos para caucho o plástico, no expresados ni comprendidos en otra parte; preparaciones antioxidantes y demás estabilizantes compuestos para caucho o plástico"
 },
 {
  "CNKEY": "381210000080",
  "CN": "3812 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceleradores de vulcanización preparados"
 },
 {
  "CNKEY": "381220000080",
  "CN": "3812 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Plastificantes compuestos para caucho o plástico"
 },
 {
  "CNKEY": "381220100080",
  "CN": "3812 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezcla de reacción que contenga ftalato de bencilo y 3-isobutiriloxi-1-isopropil-2,2-dimetilpropilo y ftalato de bencilo y 3-isobutiriloxi-2,2,4-trimetilpentilo"
 },
 {
  "CNKEY": "381220900080",
  "CN": "3812 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "381231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Preparaciones antioxidantes y demás estabilizantes compuestos para caucho o plástico"
 },
 {
  "CNKEY": "381231000080",
  "CN": "3812 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclas de oligómeros de 2,2,4-trimetil-1,2-dihidroquinolina (TMQ)"
 },
 {
  "CNKEY": "381239000080",
  "CN": "3812 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "381239100080",
  "CN": "3812 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Preparaciones antioxidantes"
 },
 {
  "CNKEY": "381239900080",
  "CN": "3812 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "381300000080",
  "CN": "3813 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Preparaciones y cargas para aparatos extintores; granadas y bombas extintoras"
 },
 {
  "CNKEY": "381400000080",
  "CN": "3814 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Disolventes y diluyentes orgánicos compuestos, no expresados ni comprendidos en otra parte; preparaciones para quitar pinturas o barnices"
 },
 {
  "CNKEY": "381400100080",
  "CN": "3814 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "A base de acetato de butilo"
 },
 {
  "CNKEY": "381400900080",
  "CN": "3814 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381500000080",
  "CN": "3815",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Iniciadores y aceleradores de reacción y preparaciones catalíticas, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "381511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Catalizadores sobre soporte"
 },
 {
  "CNKEY": "381511000080",
  "CN": "3815 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con níquel o sus compuestos como sustancia activa"
 },
 {
  "CNKEY": "381512000080",
  "CN": "3815 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con metal precioso o sus compuestos como sustancia activa"
 },
 {
  "CNKEY": "381519000080",
  "CN": "3815 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "381519100080",
  "CN": "3815 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Catalizadores, en forma de granos, de los cuales una cantidad superior o igual al 90 % en peso presentan un tamaño de partícula inferior o igual a 10 micrómetros, compuestos de una mezcla de óxidos sobre un soporte de silicato de magnesio, con un contenido de: - cobre superior o igual al 20 % pero inferior o igual al 35 % en peso, y - bismuto superior o igual al 2 % pero inferior o igual al 3 % en peso,y de densidad aparente superior o igual a 0,2 pero inferior o igual a 1,0"
 },
 {
  "CNKEY": "381519900080",
  "CN": "3815 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381590000080",
  "CN": "3815 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "381590100080",
  "CN": "3815 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Catalizadores compuestos de acetato de etiltrifenilfosfonio, en forma de solución en metanol"
 },
 {
  "CNKEY": "381590900080",
  "CN": "3815 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381600000080",
  "CN": "3816 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cementos, morteros, hormigones y preparaciones similares, refractarios, incluido el aglomerado de dolomita, excepto los productos de la partida 3801"
 },
 {
  "CNKEY": "381600100080",
  "CN": "3816 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aglomerado de dolomita"
 },
 {
  "CNKEY": "381600900080",
  "CN": "3816 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381700000080",
  "CN": "3817 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mezclas de alquilbencenos y mezclas de alquilnaftalenos (excepto las de las partidas 2707 o 2902)"
 },
 {
  "CNKEY": "381700500080",
  "CN": "3817 00 50",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alquilbenceno lineal"
 },
 {
  "CNKEY": "381700800080",
  "CN": "3817 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "381800000080",
  "CN": "3818 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Elementos químicos dopados para uso en electrónica, en discos, obleas(wafers) o formas análogas; compuestos químicos dopados para uso en electrónica"
 },
 {
  "CNKEY": "381800100080",
  "CN": "3818 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Silicio dopado"
 },
 {
  "CNKEY": "381800900080",
  "CN": "3818 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "381900000080",
  "CN": "3819 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Líquidos para frenos hidráulicos y demás líquidos preparados para transmisiones hidráulicas, sin aceites de petróleo ni de mineral bituminoso o con un contenido inferior al 70 % en peso de dichos aceites"
 },
 {
  "CNKEY": "382000000080",
  "CN": "3820 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Preparaciones anticongelantes y líquidos preparados para descongelar"
 },
 {
  "CNKEY": "382100000080",
  "CN": "3821 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Medios de cultivo preparados para el desarrollo o mantenimiento de microorganismos (incluidos los virus y organismos similares) o de células vegetales, humanas o animales"
 },
 {
  "CNKEY": "382200000080",
  "CN": "3822",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Reactivos de diagnóstico o de laboratorio sobre cualquier soporte y reactivos de diagnóstico o de laboratorio preparados, incluso sobre soporte, incluso presentados en kits, excepto los de la partida 3006; materiales de referencia certificados"
 },
 {
  "CNKEY": "382211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Reactivos de diagnóstico o de laboratorio sobre cualquier soporte y reactivos de diagnóstico o de laboratorio preparados, incluso sobre soporte, incluso presentados en kits"
 },
 {
  "CNKEY": "382211000080",
  "CN": "3822 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para la malaria (paludismo)"
 },
 {
  "CNKEY": "382212000080",
  "CN": "3822 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para el Zika y demás enfermedades transmitidas por mosquitos del género Aedes"
 },
 {
  "CNKEY": "382213000080",
  "CN": "3822 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para la determinación de los grupos o de los factores sanguíneos"
 },
 {
  "CNKEY": "382219000080",
  "CN": "3822 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382290000080",
  "CN": "3822 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382300000080",
  "CN": "3823",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ácidos grasos monocarboxílicos industriales; aceites ácidos del refinado; alcoholes grasos industriales"
 },
 {
  "CNKEY": "382311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ácidos grasos monocarboxílicos industriales; aceites ácidos del refinado"
 },
 {
  "CNKEY": "382311000080",
  "CN": "3823 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido esteárico"
 },
 {
  "CNKEY": "382312000080",
  "CN": "3823 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácido oleico"
 },
 {
  "CNKEY": "382313000080",
  "CN": "3823 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ácidos grasos del tall oil"
 },
 {
  "CNKEY": "382319000080",
  "CN": "3823 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382319100080",
  "CN": "3823 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácidos grasos destilados"
 },
 {
  "CNKEY": "382319300080",
  "CN": "3823 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destilado de ácido graso"
 },
 {
  "CNKEY": "382319900080",
  "CN": "3823 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382370000080",
  "CN": "3823 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alcoholes grasos industriales"
 },
 {
  "CNKEY": "382400000080",
  "CN": "3824",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Preparaciones aglutinantes para moldes o núcleos de fundición; productos químicos y preparaciones de la industria química o de las industrias conexas, incluidas las mezclas de productos naturales, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "382410000080",
  "CN": "3824 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preparaciones aglutinantes para moldes o núcleos de fundición"
 },
 {
  "CNKEY": "382430000080",
  "CN": "3824 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carburos metálicos sin aglomerar mezclados entre sí o con aglutinantes metálicos"
 },
 {
  "CNKEY": "382440000080",
  "CN": "3824 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aditivos preparados para cementos, morteros u hormigones"
 },
 {
  "CNKEY": "382450000080",
  "CN": "3824 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Morteros y hormigones, no refractarios"
 },
 {
  "CNKEY": "382450100080",
  "CN": "3824 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hormigón dispuesto para moldeo o colada"
 },
 {
  "CNKEY": "382450900080",
  "CN": "3824 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382460000080",
  "CN": "3824 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sorbitol (excepto el de la subpartida 290544)"
 },
 {
  "CNKEY": "382460110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En disolución acuosa"
 },
 {
  "CNKEY": "382460110080",
  "CN": "3824 60 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con D-manitol en proporción inferior o igual al 2 % en peso calculado sobre el contenido de D-glucitol"
 },
 {
  "CNKEY": "382460190080",
  "CN": "3824 60 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382460910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382460910080",
  "CN": "3824 60 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con D-manitol en proporción inferior o igual al 2 % en peso calculado sobre el contenido de D-glucitol"
 },
 {
  "CNKEY": "382460990080",
  "CN": "3824 60 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382481000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos mencionados en la Nota 3 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "382481000080",
  "CN": "3824 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan oxirano (óxido de etileno)"
 },
 {
  "CNKEY": "382482000080",
  "CN": "3824 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan bifenilos polibromados (PBB), bifenilos policlorados (PCB) o terfenilos policlorados (PCT)"
 },
 {
  "CNKEY": "382483000080",
  "CN": "3824 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan fosfato de tris(2,3-dibromopropilo)"
 },
 {
  "CNKEY": "382484000080",
  "CN": "3824 84 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan aldrina (ISO), canfecloro (ISO) (toxafeno), clordano (ISO), clordecona (ISO), DDT (ISO) (clofenotano (DCI), 1,1,1-tricloro-2,2-bis(p-clorofenil)etano), dieldrina (ISO, DCI), endosulfán (ISO), endrina (ISO), heptacloro (ISO) o mirex (ISO)"
 },
 {
  "CNKEY": "382485000080",
  "CN": "3824 85 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan 1,2,3,4,5,6-hexaclorociclohexano (HCH (ISO)), incluido el lindano (ISO, DCI)"
 },
 {
  "CNKEY": "382486000080",
  "CN": "3824 86 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan pentaclorobenceno (ISO) o hexaclorobenceno (ISO)"
 },
 {
  "CNKEY": "382487000080",
  "CN": "3824 87 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan ácido perfluorooctano sulfónico o sus sales, perfluorooctano sulfonamidas, o fluoruro de perfluorooctano sulfonilo"
 },
 {
  "CNKEY": "382488000080",
  "CN": "3824 88 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan éteres tetra-, penta-, hexa-, hepta- u octabromodifenílicos"
 },
 {
  "CNKEY": "382489000080",
  "CN": "3824 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan parafinas cloradas de cadena corta"
 },
 {
  "CNKEY": "382491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382491000080",
  "CN": "3824 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclas y preparaciones constituidas esencialmente de metisfosfonato de (5-etil-2-metil-2-óxido-1,3,2-dioxafosfinan-5-il)metil metilo y metilfosfonato de bis[(5-etil-2-metil-2-óxido-1,3,2-dioxafosfinan-5-il)metilo]"
 },
 {
  "CNKEY": "382492000080",
  "CN": "3824 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres de poliglicol del ácido metilfosfónico"
 },
 {
  "CNKEY": "382499000080",
  "CN": "3824 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382499100080",
  "CN": "3824 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sulfonatos de petróleo (excepto los de metales alcalinos, de amonio o de etanolaminas); ácidos sulfónicos tioenados de aceites minerales bituminosos y sus sales"
 },
 {
  "CNKEY": "382499150080",
  "CN": "3824 99 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Intercambiadores de iones"
 },
 {
  "CNKEY": "382499200080",
  "CN": "3824 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Compuestos absorbentes para perfeccionar el vacío en las válvulas o tubos eléctricos"
 },
 {
  "CNKEY": "382499250080",
  "CN": "3824 99 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pirolignitos (por ejemplo: de calcio); tartrato de calcio bruto, citrato de calcio bruto"
 },
 {
  "CNKEY": "382499300080",
  "CN": "3824 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ácidos nafténicos, sus sales insolubles en agua y sus ésteres"
 },
 {
  "CNKEY": "382499450010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382499450080",
  "CN": "3824 99 45",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Preparaciones desincrustantes y similares"
 },
 {
  "CNKEY": "382499500080",
  "CN": "3824 99 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Preparaciones para galvanoplastia"
 },
 {
  "CNKEY": "382499550080",
  "CN": "3824 99 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mezclas de mono-, di-y triésteres de ácidos grasos de glicerina (emulsionantes de grasas)"
 },
 {
  "CNKEY": "382499610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Productos y preparaciones para usos farmacéuticos o quirúrgicos"
 },
 {
  "CNKEY": "382499610080",
  "CN": "3824 99 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Productos intermedios del proceso de fabricación de antibióticos, obtenidos por fermentación de Streptomyces tenebrarius, secados o no, destinados a la fabricación de medicamentos de la partida 3004 para la medicina humana"
 },
 {
  "CNKEY": "382499620080",
  "CN": "3824 99 62",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Productos intermedios de la fabricación de sales de monensina"
 },
 {
  "CNKEY": "382499640080",
  "CN": "3824 99 64",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382499650080",
  "CN": "3824 99 65",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Productos auxiliares del tipo de los utilizados en fundición (excepto los comprendidos en la subpartida  38241000)"
 },
 {
  "CNKEY": "382499700080",
  "CN": "3824 99 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Preparaciones ignífugas, hidrófugas y otras, utilizadas para la protección de construcciones"
 },
 {
  "CNKEY": "382499750010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382499750080",
  "CN": "3824 99 75",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Placas de niobato de litio, no impregnadas"
 },
 {
  "CNKEY": "382499800080",
  "CN": "3824 99 80",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mezcla de aminas obtenidas de ácidos grasos dimerizados, con un peso molecular medio superior o igual a 520 pero inferior o igual a 550"
 },
 {
  "CNKEY": "382499850080",
  "CN": "3824 99 85",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "3-(1-Etil-1-metilpropil)isoxazol-5-ilamina, en forma de solución en tolueno"
 },
 {
  "CNKEY": "382499860080",
  "CN": "3824 99 86",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Mezclas formadas principalmente por metilfosfonato de dimetilo, oxirano y pentóxido de difósforo"
 },
 {
  "CNKEY": "382499920010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Productos o preparaciones químicas constituidos predominantemente por  compuestos orgánicos, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "382499920080",
  "CN": "3824 99 92",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "En forma líquida a 20 °C"
 },
 {
  "CNKEY": "382499930080",
  "CN": "3824 99 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382499960080",
  "CN": "3824 99 96",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382500000080",
  "CN": "3825",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos residuales de la industria química o de las industrias conexas, no expresados ni comprendidos en otra parte; desechos municipales; lodos de depuración; los demás desechos citados en la Nota 6 del presente Capítulo"
 },
 {
  "CNKEY": "382510000080",
  "CN": "3825 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desechos municipales"
 },
 {
  "CNKEY": "382520000080",
  "CN": "3825 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lodos de depuración"
 },
 {
  "CNKEY": "382530000080",
  "CN": "3825 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desechos clínicos"
 },
 {
  "CNKEY": "382541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desechos de disolventes orgánicos"
 },
 {
  "CNKEY": "382541000080",
  "CN": "3825 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Halogenados"
 },
 {
  "CNKEY": "382549000080",
  "CN": "3825 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382550000080",
  "CN": "3825 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desechos de soluciones decapantes, fluidos hidráulicos, líquidos para frenos y líquidos anticongelantes"
 },
 {
  "CNKEY": "382561000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás desechos de la industria química o de las industrias conexas"
 },
 {
  "CNKEY": "382561000080",
  "CN": "3825 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan principalmente componentes orgánicos"
 },
 {
  "CNKEY": "382569000080",
  "CN": "3825 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382590000080",
  "CN": "3825 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "382590100080",
  "CN": "3825 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Óxidos de hierro alcalinizados para la depuración de los gases"
 },
 {
  "CNKEY": "382590900080",
  "CN": "3825 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382600000080",
  "CN": "3826 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Biodiésel y sus mezclas, sin aceites de petróleo o de mineral bituminoso o con un contenido inferior al 70 % en peso"
 },
 {
  "CNKEY": "382600100080",
  "CN": "3826 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ésteres monoalquílicos de ácidos grasos (FAMAE) con un contenido de ésteres igual o superior al 96,5 % en peso"
 },
 {
  "CNKEY": "382600900080",
  "CN": "3826 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "382700000080",
  "CN": "3827",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mezclas que contengan derivados halogenados de metano, etano o propano, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "382711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan clorofluorocarburos (CFC), incluso con hidroclorofluorocarburos (HCFC), perfluorocarburos (PFC) o hidrofluorocarburos (HFC); que contengan hidrobromofluorocarburos (HBFC); que contengan tetracloruro de carbono; que contengan 1,1,1-tricloroetano (metilcloroformo)"
 },
 {
  "CNKEY": "382711000080",
  "CN": "3827 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan clorofluorocarburos (CFC), incluso con hidroclorofluorocarburos (HCFC), perfluorocarburos (PFC) o hidrofluorocarburos (HFC)"
 },
 {
  "CNKEY": "382712000080",
  "CN": "3827 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan hidrobromofluorocarburos (HBFC)"
 },
 {
  "CNKEY": "382713000080",
  "CN": "3827 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan tetracloruro de carbono"
 },
 {
  "CNKEY": "382714000080",
  "CN": "3827 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan 1,1,1-tricloroetano (metilcloroformo)"
 },
 {
  "CNKEY": "382720000080",
  "CN": "3827 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan bromoclorodifluorometano (Halón-1211), bromotrifluorometano (Halón-1301) o dibromotetrafluoroetanos (Halón-2402)"
 },
 {
  "CNKEY": "382731000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan hidroclorofluorocarburos (HCFC), incluso con perfluorocarburos (PFC) o hidrofluorocarburos (HFC), pero que no contengan clorofluorocarburos (CFC)"
 },
 {
  "CNKEY": "382731000080",
  "CN": "3827 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan sustancias de las subpartidas290341 a 290348"
 },
 {
  "CNKEY": "382732000080",
  "CN": "3827 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, que contengan sustancias de las subpartidas 290371 a 290375"
 },
 {
  "CNKEY": "382739000080",
  "CN": "3827 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "382740000080",
  "CN": "3827 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan bromuro de metilo (bromometano) o bromoclorometano"
 },
 {
  "CNKEY": "382751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan trifluorometano (HFC-23) o perfluorocarburos (PFC), pero que no contengan clorofluorocarburos (CFC) o hidroclorofluorocarburos (HCFC)"
 },
 {
  "CNKEY": "382751000080",
  "CN": "3827 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan trifluorometano (HFC-23)"
 },
 {
  "CNKEY": "382759000080",
  "CN": "3827 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "382761000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que contengan los demás hidrofluorocarburos (HFC), pero que no contengan clorofluorocarburos (CFC) o hidroclorofluorocarburos (HCFC)"
 },
 {
  "CNKEY": "382761000080",
  "CN": "3827 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de 1,1,1-trifluoroetano (HFC-143a), superior o igual al 15 % en masa"
 },
 {
  "CNKEY": "382762000080",
  "CN": "3827 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, no comprendidas en subpartidas anteriores, con un contenido de pentafluoroetano (HFC-125) superior o igual al 55 % en masa, pero que no contengan derivados fluorados de los hidrocarburos acíclicos no saturados (HFO)"
 },
 {
  "CNKEY": "382763000080",
  "CN": "3827 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, no comprendidas en subpartidas anteriores, con un contenido de pentafluoroetano (HFC-125) superior o igual al 40 % en masa"
 },
 {
  "CNKEY": "382764000080",
  "CN": "3827 64 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, no comprendidas en subpartidas anteriores, con un contenido de 1,1,1,2-tetrafluoroetano (HFC-134a) superior o igual al 30 % en masa, pero que no contengan derivados fluorados de los hidrocarburos acíclicos no saturados (HFO)"
 },
 {
  "CNKEY": "382765000080",
  "CN": "3827 65 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, no comprendidas en subpartidas anteriores, con un contenido de difluorometano (HFC-32) superior o igual al 20 % en masa y de pentafluoroetano (HFC-125) superior o igual al 20 % en masa"
 },
 {
  "CNKEY": "382768000080",
  "CN": "3827 68 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, no comprendidas en subpartidas anteriores, que contengan sustancias de las subpartidas 290341 a 290348"
 },
 {
  "CNKEY": "382769000080",
  "CN": "3827 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "382790000080",
  "CN": "3827 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "390011000090",
  "CN": "VII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN VII - PLÁSTICO Y SUS MANUFACTURAS; CAUCHO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "390021000090",
  "CN": "39",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 39 - PLÁSTICO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "390100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. FORMAS PRIMARIAS"
 },
 {
  "CNKEY": "390100000080",
  "CN": "3901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros de etileno en formas primarias"
 },
 {
  "CNKEY": "390110000080",
  "CN": "3901 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polietileno de densidad inferior a 0,94"
 },
 {
  "CNKEY": "390110100080",
  "CN": "3901 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Polietileno lineal"
 },
 {
  "CNKEY": "390110900080",
  "CN": "3901 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390120000080",
  "CN": "3901 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polietileno de densidad superior o igual a 0,94"
 },
 {
  "CNKEY": "390120100080",
  "CN": "3901 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Polietileno, en una de las formas señaladas en la Nota 6 b) de este Capítulo, de densidad superior o igual a 0,958 a 23 °C, y con un contenido de: - aluminio inferior o igual a 50 mg\/kg, - calcio inferior o igual a 2 mg\/kg, - cromo inferior o igual a 2 mg\/kg, - hierro inferior o igual a 2 mg\/kg, - níquel inferior o igual a 2 mg\/kg, - titanio inferior o igual a 2 mg\/kg, y - vanadio inferior o igual a 8 mg\/kg,destinado a la fabricación de polietileno clorosulfonado"
 },
 {
  "CNKEY": "390120900080",
  "CN": "3901 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390130000080",
  "CN": "3901 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de etileno y acetato de vinilo"
 },
 {
  "CNKEY": "390140000080",
  "CN": "3901 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de etileno y alfa-olefina de densidad inferior a 0,94"
 },
 {
  "CNKEY": "390190000080",
  "CN": "3901 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390190300080",
  "CN": "3901 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Resina de ionómero compuesta por la sal de un terpolímero de etileno, acrilato de isobutilo y ácido metacrílico; copolímero en bloque del tipo A-B-A de poliestireno, de copolímero de etilenobutileno y de poliestireno, con un contenido de estireno inferior o igual al 35 % en peso, en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "390190800080",
  "CN": "3901 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390200000080",
  "CN": "3902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros de propileno o de otras olefinas, en formas primarias"
 },
 {
  "CNKEY": "390210000080",
  "CN": "3902 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polipropileno"
 },
 {
  "CNKEY": "390220000080",
  "CN": "3902 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poliisobutileno"
 },
 {
  "CNKEY": "390230000080",
  "CN": "3902 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de propileno"
 },
 {
  "CNKEY": "390290000080",
  "CN": "3902 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390290100080",
  "CN": "3902 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímero en bloque del tipo A-B-A de poliestireno, de copolímero de etilenobutileno y de poliestireno, con un contenido de estireno inferior o igual al 35 % en peso, en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "390290200080",
  "CN": "3902 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Polibuteno-1, copolímeros de buteno-1 y etileno con un contenido de etileno inferior o igual al 10 % en peso, o mezclas de polibuteno-1, polietileno, o polipropileno con un contenido de polietileno inferior o igual al 10 % en peso, de polipropileno inferior o igual al 25 % en peso, en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "390290900080",
  "CN": "3902 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390300000080",
  "CN": "3903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros de estireno en formas primarias"
 },
 {
  "CNKEY": "390311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poliestireno"
 },
 {
  "CNKEY": "390311000080",
  "CN": "3903 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Expandible"
 },
 {
  "CNKEY": "390319000080",
  "CN": "3903 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390320000080",
  "CN": "3903 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de estireno-acrilonitrilo (SAN)"
 },
 {
  "CNKEY": "390330000080",
  "CN": "3903 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de acrilonitrilo-butadieno-estireno (ABS)"
 },
 {
  "CNKEY": "390390000080",
  "CN": "3903 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390390100080",
  "CN": "3903 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímeros exclusivamente de estireno y alcohol alílico, con un índice de acetilo superior o igual a 175"
 },
 {
  "CNKEY": "390390200080",
  "CN": "3903 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Poliestireno bromado con un contenido de bromo superior o igual al 58 % pero inferior o igual al 71 % en peso, en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "390390900080",
  "CN": "3903 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390400000080",
  "CN": "3904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros de cloruro de vinilo o de otras olefinas halogenadas, en formas primarias"
 },
 {
  "CNKEY": "390410000080",
  "CN": "3904 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poli(cloruro de vinilo) sin mezclar con otras sustancias"
 },
 {
  "CNKEY": "390421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás poli(cloruros de vinilo)"
 },
 {
  "CNKEY": "390421000080",
  "CN": "3904 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin plastificar"
 },
 {
  "CNKEY": "390422000080",
  "CN": "3904 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plastificados"
 },
 {
  "CNKEY": "390430000080",
  "CN": "3904 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Copolímeros de cloruro de vinilo y acetato de vinilo"
 },
 {
  "CNKEY": "390440000080",
  "CN": "3904 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás copolímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "390450000080",
  "CN": "3904 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polímeros de cloruro de vinilideno"
 },
 {
  "CNKEY": "390450100080",
  "CN": "3904 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímeros de cloruro de vinilideno y acrilonitrilo, en forma de gránulos expandibles de diámetro superior o igual a 4 micrómetros pero inferior o igual a 20 micrómetros"
 },
 {
  "CNKEY": "390450900080",
  "CN": "3904 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polímeros fluorados"
 },
 {
  "CNKEY": "390461000080",
  "CN": "3904 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Politetrafluoroetileno"
 },
 {
  "CNKEY": "390469000080",
  "CN": "3904 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390469100080",
  "CN": "3904 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poli(fluoruro de vinilo), en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "390469200080",
  "CN": "3904 69 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fluoroelastómeros FKM"
 },
 {
  "CNKEY": "390469800080",
  "CN": "3904 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390490000080",
  "CN": "3904 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390500000080",
  "CN": "3905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros de acetato de vinilo o de otros ésteres vinílicos, en formas primarias; los demás polímeros vinílicos en formas primarias"
 },
 {
  "CNKEY": "390512000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poli(acetato de vinilo)"
 },
 {
  "CNKEY": "390512000080",
  "CN": "3905 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En dispersión acuosa"
 },
 {
  "CNKEY": "390519000080",
  "CN": "3905 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Copolímeros de acetato de vinilo"
 },
 {
  "CNKEY": "390521000080",
  "CN": "3905 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En dispersión acuosa"
 },
 {
  "CNKEY": "390529000080",
  "CN": "3905 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390530000080",
  "CN": "3905 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poli(alcohol vinílico), incluso con grupos acetato sin hidrolizar"
 },
 {
  "CNKEY": "390591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390591000080",
  "CN": "3905 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímeros"
 },
 {
  "CNKEY": "390599000080",
  "CN": "3905 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390599100080",
  "CN": "3905 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poli(formal de vinilo), en una de las formas señaladas en la Nota 6 b) de este Capítulo, con un peso molecular superior o igual a 10000 pero inferior o igual a 40000, y con contenido de: - grupos acetilo, expresados en acetados de vinilo superior o igual al 9,5 % pero inferior o igual al 13 % en peso, y - grupos hidroxi, expresados en alcohol vinílico, superior o igual al 5 % pero inferior o igual al 6,5 % en peso"
 },
 {
  "CNKEY": "390599900080",
  "CN": "3905 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390600000080",
  "CN": "3906",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros acrílicos en formas primarias"
 },
 {
  "CNKEY": "390610000080",
  "CN": "3906 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poli(metacrilato de metilo)"
 },
 {
  "CNKEY": "390690000080",
  "CN": "3906 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390690100080",
  "CN": "3906 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Poli[N-(3-hidroxiimino-1,1-dimetilbutil)acrilamida]"
 },
 {
  "CNKEY": "390690200080",
  "CN": "3906 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímero de 2-diisopropilaminoetilmetacrilato y decilmetacrilato, en forma de solución en N,N-dimetilacetamida, con un contenido de copolímero superior o igual al 55 % en peso"
 },
 {
  "CNKEY": "390690300080",
  "CN": "3906 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímero del ácido acrílico y del acrilato de 2-etilhexilo, con un contenido de acrilato de 2-etilhexilo superior o igual al 10 % pero inferior o igual al 11 % en peso"
 },
 {
  "CNKEY": "390690400080",
  "CN": "3906 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímero de acrilonitrilo y acrilato de metilo, modificado con polibutadienacrilonitrilo (NBR)"
 },
 {
  "CNKEY": "390690500080",
  "CN": "3906 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Producto de polimerización del ácido acrílico con metacrilato de alquilo y pequeñas cantidades de otros monómeros, destinado a utilizarse como espesantes en la fabricación de pastas de estampación para textiles"
 },
 {
  "CNKEY": "390690600080",
  "CN": "3906 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Copolímero de acrilato de metilo, etileno y un monómero que contenga un grupo carboxilo no terminal como sustituyente, con un contenido de acrilato de metilo superior o igual al 50 % en peso, mezclado o no con sílice"
 },
 {
  "CNKEY": "390690900080",
  "CN": "3906 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390700000080",
  "CN": "3907",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Poliacetales, los demás poliéteres y resinas epoxi, en formas primarias; policarbonatos, resinas alcídicas, poliésteres alílicos y demás poliésteres, en formas primarias"
 },
 {
  "CNKEY": "390710000080",
  "CN": "3907 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poliacetales"
 },
 {
  "CNKEY": "390721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás poliéteres"
 },
 {
  "CNKEY": "390721000080",
  "CN": "3907 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metilfosfonato de bis(polioxietileno)"
 },
 {
  "CNKEY": "390729000080",
  "CN": "3907 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390729110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Poliéter-alcoholes"
 },
 {
  "CNKEY": "390729110080",
  "CN": "3907 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Polietilenglicol"
 },
 {
  "CNKEY": "390729200080",
  "CN": "3907 29 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390729910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390729910080",
  "CN": "3907 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Copolímero de 1-cloro-2,3-epoxipropano con óxido de etileno"
 },
 {
  "CNKEY": "390729990080",
  "CN": "3907 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390730000080",
  "CN": "3907 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas epoxi"
 },
 {
  "CNKEY": "390740000080",
  "CN": "3907 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Policarbonatos"
 },
 {
  "CNKEY": "390750000080",
  "CN": "3907 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas alcídicas"
 },
 {
  "CNKEY": "390761000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poli(tereftalato de etileno)"
 },
 {
  "CNKEY": "390761000080",
  "CN": "3907 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un índice de viscosidad igual o superior a 78 ml\/g"
 },
 {
  "CNKEY": "390769000080",
  "CN": "3907 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390770000080",
  "CN": "3907 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poli(ácido láctico)"
 },
 {
  "CNKEY": "390791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás poliésteres"
 },
 {
  "CNKEY": "390791000080",
  "CN": "3907 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "No saturados"
 },
 {
  "CNKEY": "390791100080",
  "CN": "3907 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Líquidos"
 },
 {
  "CNKEY": "390791900080",
  "CN": "3907 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390799000080",
  "CN": "3907 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "390799050080",
  "CN": "3907 99 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copolímeros termoplásticos de poliésteres aromáticos de cristal líquido"
 },
 {
  "CNKEY": "390799100080",
  "CN": "3907 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poli(etilenonaftaleno-2,6-dicarboxilato)"
 },
 {
  "CNKEY": "390799800080",
  "CN": "3907 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "390800000080",
  "CN": "3908",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Poliamidas en formas primarias"
 },
 {
  "CNKEY": "390810000080",
  "CN": "3908 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poliamidas-6, -11, -12, -6,6, -6,9, -6,10 o -6,12"
 },
 {
  "CNKEY": "390890000080",
  "CN": "3908 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "390900000080",
  "CN": "3909",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Resinas amínicas, resinas fenólicas y poliuretanos, en formas primarias"
 },
 {
  "CNKEY": "390910000080",
  "CN": "3909 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas ureicas; resinas de tiourea"
 },
 {
  "CNKEY": "390920000080",
  "CN": "3909 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas melamínicas"
 },
 {
  "CNKEY": "390931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás resinas amínicas"
 },
 {
  "CNKEY": "390931000080",
  "CN": "3909 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Poli(metilenfenilisocianato) (MDI bruto, MDI polimérico)"
 },
 {
  "CNKEY": "390939000080",
  "CN": "3909 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "390940000080",
  "CN": "3909 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas fenólicas"
 },
 {
  "CNKEY": "390950000080",
  "CN": "3909 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Poliuretanos"
 },
 {
  "CNKEY": "390950100080",
  "CN": "3909 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Poliuretano obtenido de 2,2′-(terc-butilimino)dietanol y de 4,4′-metilendiciclohexildiisocianato, en forma de solución en N,N-dimetilacetamida, con un contenido de polímero superior o igual al 50 % en peso"
 },
 {
  "CNKEY": "390950900080",
  "CN": "3909 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391000000080",
  "CN": "3910 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Siliconas en formas primarias"
 },
 {
  "CNKEY": "391100000080",
  "CN": "3911",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Resinas de petróleo, resinas de cumarona-indeno, politerpenos, polisulfuros, polisulfonas y demás productos previstos en la Nota 3 de este Capítulo, no expresados ni comprendidos en otra parte, en formas primarias"
 },
 {
  "CNKEY": "391110000080",
  "CN": "3911 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resinas de petróleo, resinas de cumarona, resinas de indeno, resinas de cumarona-indeno y politerpenos"
 },
 {
  "CNKEY": "391120000080",
  "CN": "3911 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Poli(1,3-fenilen metilfosfonato)"
 },
 {
  "CNKEY": "391190000080",
  "CN": "3911 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "391190110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Productos de polimerización de reorganización o de condensación, incluso modificados químicamente"
 },
 {
  "CNKEY": "391190110080",
  "CN": "3911 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poli(oxi-1,4-fenilensulfonil-1,4-fenilenoxi-1,4-fenilenisopropiliden-1,4-fenileno) en una de las formas señaladas en la Nota 6 b) de este Capítulo"
 },
 {
  "CNKEY": "391190130080",
  "CN": "3911 90 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Poli(tio-1,4-fenileno)"
 },
 {
  "CNKEY": "391190190080",
  "CN": "3911 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391190920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "391190920080",
  "CN": "3911 90 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copolímeros de p-cresol y divinilbenceno, en forma de solución en N,N-dimetilacetamida, con un contenido de copolímero superior o igual al 50 % en peso; copolímero de viniltolueno y alfa-metilestireno, hidrogenado"
 },
 {
  "CNKEY": "391190990080",
  "CN": "3911 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391200000080",
  "CN": "3912",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Celulosa y sus derivados químicos, no expresados ni comprendidos en otra parte, en formas primarias"
 },
 {
  "CNKEY": "391211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Acetatos de celulosa"
 },
 {
  "CNKEY": "391211000080",
  "CN": "3912 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin plastificar"
 },
 {
  "CNKEY": "391212000080",
  "CN": "3912 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plastificados"
 },
 {
  "CNKEY": "391220000080",
  "CN": "3912 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Nitratos de celulosa, incluidos los colodiones"
 },
 {
  "CNKEY": "391220110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin plastificar"
 },
 {
  "CNKEY": "391220110080",
  "CN": "3912 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colodiones y celoidina"
 },
 {
  "CNKEY": "391220190080",
  "CN": "3912 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391220900080",
  "CN": "3912 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plastificados"
 },
 {
  "CNKEY": "391231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Éteres de celulosa"
 },
 {
  "CNKEY": "391231000080",
  "CN": "3912 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carboximetilcelulosa y sus sales"
 },
 {
  "CNKEY": "391239000080",
  "CN": "3912 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "391239200080",
  "CN": "3912 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hidroxipropilcelulosa"
 },
 {
  "CNKEY": "391239850080",
  "CN": "3912 39 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391290000080",
  "CN": "3912 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "391290100080",
  "CN": "3912 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ésteres de celulosa"
 },
 {
  "CNKEY": "391290900080",
  "CN": "3912 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391300000080",
  "CN": "3913",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polímeros naturales (por ejemplo: ácido algínico) y polímeros naturales modificados (por ejemplo: proteínas endurecidas, derivados químicos del caucho natural), no expresados ni comprendidos en otra parte, en formas primarias"
 },
 {
  "CNKEY": "391310000080",
  "CN": "3913 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ácido algínico, sus sales y sus ésteres"
 },
 {
  "CNKEY": "391390000080",
  "CN": "3913 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391400000080",
  "CN": "3914 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Intercambiadores de iones a base de polímeros de las partidas 3901 a 3913, en formas primarias"
 },
 {
  "CNKEY": "391500000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. DESECHOS, DESPERDICIOS Y RECORTES; SEMIMANUFACTURAS; MANUFACTURAS"
 },
 {
  "CNKEY": "391500000080",
  "CN": "3915",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desechos, desperdicios y recortes, de plástico"
 },
 {
  "CNKEY": "391510000080",
  "CN": "3915 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de etileno"
 },
 {
  "CNKEY": "391520000080",
  "CN": "3915 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de estireno"
 },
 {
  "CNKEY": "391530000080",
  "CN": "3915 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "391590000080",
  "CN": "3915 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "391590110080",
  "CN": "3915 90 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polímeros de propileno"
 },
 {
  "CNKEY": "391590800080",
  "CN": "3915 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391600000080",
  "CN": "3916",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monofilamentos cuya mayor dimensión de la sección transversal sea superior a 1 mm, barras, varillas y perfiles, incluso trabajados en la superficie pero sin otra labor, de plástico"
 },
 {
  "CNKEY": "391610000080",
  "CN": "3916 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de etileno"
 },
 {
  "CNKEY": "391620000080",
  "CN": "3916 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "391690000080",
  "CN": "3916 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "391690100080",
  "CN": "3916 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De productos de polimerización de reorganización o de condensación, incluso modificados químicamente"
 },
 {
  "CNKEY": "391690500080",
  "CN": "3916 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De productos de polimerización de adición"
 },
 {
  "CNKEY": "391690900080",
  "CN": "3916 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391700000080",
  "CN": "3917",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos y accesorios de tubería [por ejemplo: juntas, codos, empalmes (racores)], de plástico"
 },
 {
  "CNKEY": "391710000080",
  "CN": "3917 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tripas artificiales de proteínas endurecidas o de plásticos celulósicos"
 },
 {
  "CNKEY": "391710100080",
  "CN": "3917 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De proteínas endurecidas"
 },
 {
  "CNKEY": "391710900080",
  "CN": "3917 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plásticos celulósicos"
 },
 {
  "CNKEY": "391721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos rígidos"
 },
 {
  "CNKEY": "391721000080",
  "CN": "3917 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De polímeros de etileno"
 },
 {
  "CNKEY": "391721100080",
  "CN": "3917 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin soldadura y con longitud superior a la mayor dimensión del corte transversal, incluso trabajados en la superficie, pero sin otra labor"
 },
 {
  "CNKEY": "391721900080",
  "CN": "3917 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391722000080",
  "CN": "3917 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De polímeros de propileno"
 },
 {
  "CNKEY": "391722100080",
  "CN": "3917 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin soldadura y con longitud superior a la mayor dimensión del corte transversal, incluso trabajados en la superficie, pero sin otra labor"
 },
 {
  "CNKEY": "391722900080",
  "CN": "3917 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391723000080",
  "CN": "3917 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "391723100080",
  "CN": "3917 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sin soldadura y con longitud superior a la mayor dimensión del corte transversal, incluso trabajados en la superficie, pero sin otra labor"
 },
 {
  "CNKEY": "391723900080",
  "CN": "3917 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391729000080",
  "CN": "3917 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "391731000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tubos"
 },
 {
  "CNKEY": "391731000080",
  "CN": "3917 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tubos flexibles para una presión superior o igual a 27,6 MPa"
 },
 {
  "CNKEY": "391732000080",
  "CN": "3917 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, sin reforzar ni combinar con otras materias, sin accesorios"
 },
 {
  "CNKEY": "391733000080",
  "CN": "3917 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, sin reforzar ni combinar con otras materias, con accesorios"
 },
 {
  "CNKEY": "391739000080",
  "CN": "3917 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391740000080",
  "CN": "3917 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Accesorios"
 },
 {
  "CNKEY": "391800000080",
  "CN": "3918",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Revestimientos de plástico para suelos, incluso autoadhesivos, en rollos o losetas; revestimientos de plástico para paredes o techos, definidos en la Nota 9 de este Capítulo"
 },
 {
  "CNKEY": "391810000080",
  "CN": "3918 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "391810100080",
  "CN": "3918 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Que consistan en un soporte impregnado, recubierto o revestido de poli(cloruro de vinilo)"
 },
 {
  "CNKEY": "391810900080",
  "CN": "3918 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "391890000080",
  "CN": "3918 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "391900000080",
  "CN": "3919",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas, láminas, hojas, cintas, tiras y demás formas planas, autoadhesivas, de plástico, incluso en rollos"
 },
 {
  "CNKEY": "391910000080",
  "CN": "3919 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En rollos de anchura inferior o igual a 20 cm"
 },
 {
  "CNKEY": "391910120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cintas con baño de caucho natural o sintético, sin vulcanizar"
 },
 {
  "CNKEY": "391910120080",
  "CN": "3919 10 12",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De poli(cloruro de vinilo) o de polietileno"
 },
 {
  "CNKEY": "391910150080",
  "CN": "3919 10 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "391910190080",
  "CN": "3919 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "391910800080",
  "CN": "3919 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "391990000080",
  "CN": "3919 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "391990200080",
  "CN": "3919 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Almohadillas de pulido autoadhesivas en soportes circulares del tipo utilizado para la fabricación de obleas semiconductoras"
 },
 {
  "CNKEY": "391990800080",
  "CN": "3919 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392000000080",
  "CN": "3920",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás placas, láminas, películas, hojas y tiras, de plástico no celular y sin refuerzo, estratificación ni soporte o combinación similar con otras materias"
 },
 {
  "CNKEY": "392010000080",
  "CN": "3920 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polímeros de etileno"
 },
 {
  "CNKEY": "392010230010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior o igual a 0,125 mm"
 },
 {
  "CNKEY": "392010230020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De polietileno de densidad"
 },
 {
  "CNKEY": "392010230030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior a 0,94"
 },
 {
  "CNKEY": "392010230080",
  "CN": "3920 10 23",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Hojas de polietileno, de espesor superior o igual a 20 micrómetros pero inferior o igual a 40 micrómetros, destinadas a la fabricación de películas fotorresistentes para la producción de semiconductores o de circuitos impresos"
 },
 {
  "CNKEY": "392010240080",
  "CN": "3920 10 24",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Láminas estirables, sin imprimir"
 },
 {
  "CNKEY": "392010250080",
  "CN": "3920 10 25",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392010280080",
  "CN": "3920 10 28",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 0,94"
 },
 {
  "CNKEY": "392010400080",
  "CN": "3920 10 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392010810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 0,125 mm"
 },
 {
  "CNKEY": "392010810080",
  "CN": "3920 10 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pasta de papel sintética, en forma de hojas húmedas fabricadas con fibras de polietileno inconexas y finamente ramificadas, mezcladas o no con fibras de celulosa en cantidad inferior o igual al 15 %, conteniendo poli(alcohol vinílico) disuelto en agua como agente humectante"
 },
 {
  "CNKEY": "392010890080",
  "CN": "3920 10 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392020000080",
  "CN": "3920 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polímeros de propileno"
 },
 {
  "CNKEY": "392020210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior o igual a 0,10 mm"
 },
 {
  "CNKEY": "392020210080",
  "CN": "3920 20 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De orientación biaxial"
 },
 {
  "CNKEY": "392020290080",
  "CN": "3920 20 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392020800080",
  "CN": "3920 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior a 0,10 mm"
 },
 {
  "CNKEY": "392030000080",
  "CN": "3920 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polímeros de estireno"
 },
 {
  "CNKEY": "392043000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "392043000080",
  "CN": "3920 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de plastificantes superior o igual al 6 % en peso"
 },
 {
  "CNKEY": "392043100080",
  "CN": "3920 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor inferior o igual a 1 mm"
 },
 {
  "CNKEY": "392043900080",
  "CN": "3920 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior a 1 mm"
 },
 {
  "CNKEY": "392049000080",
  "CN": "3920 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "392049100080",
  "CN": "3920 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor inferior o igual a 1 mm"
 },
 {
  "CNKEY": "392049900080",
  "CN": "3920 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior a 1 mm"
 },
 {
  "CNKEY": "392051000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polímeros acrílicos"
 },
 {
  "CNKEY": "392051000080",
  "CN": "3920 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poli(metacrilato de metilo)"
 },
 {
  "CNKEY": "392059000080",
  "CN": "3920 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "392059100080",
  "CN": "3920 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copolímero de los ésteres acrílico y metacrílico, en forma de película, de espesor inferior o igual a 150 micrómetros"
 },
 {
  "CNKEY": "392059900080",
  "CN": "3920 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392061000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De policarbonatos, resinas alcídicas, poliésteres alílicos o demás poliésteres"
 },
 {
  "CNKEY": "392061000080",
  "CN": "3920 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De policarbonatos"
 },
 {
  "CNKEY": "392062000080",
  "CN": "3920 62",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De poli(tereftalato de etileno)"
 },
 {
  "CNKEY": "392062120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De espesor inferior o igual a 0,35 mm"
 },
 {
  "CNKEY": "392062120080",
  "CN": "3920 62 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cintas de poli(tereftalato de etileno), de espesor superior o igual a 72 micrómetros pero inferior o igual a 79 micrómetros, destinada a la fabricación de discos magnéticos flexibles; hojas de poli(tereftalato de etileno), de espesor superior o igual a 100 micrómetros pero inferior o igual a 150 micrómetros, destinadas a la fabricación de placas de impresión de fotopolímeros"
 },
 {
  "CNKEY": "392062190080",
  "CN": "3920 62 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392062900080",
  "CN": "3920 62 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior a 0,35 mm"
 },
 {
  "CNKEY": "392063000080",
  "CN": "3920 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliésteres no saturados"
 },
 {
  "CNKEY": "392069000080",
  "CN": "3920 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los demás poliésteres"
 },
 {
  "CNKEY": "392071000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De celulosa o de sus derivados químicos"
 },
 {
  "CNKEY": "392071000080",
  "CN": "3920 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De celulosa regenerada"
 },
 {
  "CNKEY": "392073000080",
  "CN": "3920 73",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De acetato de celulosa"
 },
 {
  "CNKEY": "392073100080",
  "CN": "3920 73 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cintas en rollos o bandas, para cinematografía o fotografía"
 },
 {
  "CNKEY": "392073800080",
  "CN": "3920 73 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392079000080",
  "CN": "3920 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás derivados de la celulosa"
 },
 {
  "CNKEY": "392079100080",
  "CN": "3920 79 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fibra vulcanizada"
 },
 {
  "CNKEY": "392079900080",
  "CN": "3920 79 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "392091000080",
  "CN": "3920 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poli(butiral de vinilo)"
 },
 {
  "CNKEY": "392092000080",
  "CN": "3920 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliamidas"
 },
 {
  "CNKEY": "392093000080",
  "CN": "3920 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De resinas amínicas"
 },
 {
  "CNKEY": "392094000080",
  "CN": "3920 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De resinas fenólicas"
 },
 {
  "CNKEY": "392099000080",
  "CN": "3920 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "392099210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De productos de polimeración de reorganización o de condensación, incluso modificados químicamente"
 },
 {
  "CNKEY": "392099210080",
  "CN": "3920 99 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hojas y láminas de poliimida, no recubiertas o recubiertas exclusivamente de plástico"
 },
 {
  "CNKEY": "392099280080",
  "CN": "3920 99 28",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392099520010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De productos de polimerización de adición"
 },
 {
  "CNKEY": "392099520080",
  "CN": "3920 99 52",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hojas de poli(fluoro de vinilo); hojas de poli(alcohol vinílico) de orientación biaxial, de espesor inferior o igual a 1 mm, no recubiertas, con un contenido de poli(alcohol vinílico) superior o igual al 97 % en peso"
 },
 {
  "CNKEY": "392099530080",
  "CN": "3920 99 53",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Membranas intercambiadoras de iones en material plástico fluorado, destinadas a utilizarse en las células electrolíticas cloro-alcalinas"
 },
 {
  "CNKEY": "392099590080",
  "CN": "3920 99 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392099900080",
  "CN": "3920 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392100000080",
  "CN": "3921",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás placas, láminas, películas, hojas y tiras, de plástico"
 },
 {
  "CNKEY": "392111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos celulares"
 },
 {
  "CNKEY": "392111000080",
  "CN": "3921 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polímeros de estireno"
 },
 {
  "CNKEY": "392112000080",
  "CN": "3921 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polímeros de cloruro de vinilo"
 },
 {
  "CNKEY": "392113000080",
  "CN": "3921 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De poliuretanos"
 },
 {
  "CNKEY": "392113100080",
  "CN": "3921 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espuma flexible"
 },
 {
  "CNKEY": "392113900080",
  "CN": "3921 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392114000080",
  "CN": "3921 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De celulosa regenerada"
 },
 {
  "CNKEY": "392119000080",
  "CN": "3921 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "392190000080",
  "CN": "3921 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "392190100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De productos de polimerización de reorganización o de condensación, incluso modificados químicamente"
 },
 {
  "CNKEY": "392190100080",
  "CN": "3921 90 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "392190300080",
  "CN": "3921 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De resinas fenólicas"
 },
 {
  "CNKEY": "392190410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De resinas amínicas"
 },
 {
  "CNKEY": "392190410020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Estratificadas"
 },
 {
  "CNKEY": "392190410080",
  "CN": "3921 90 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "A alta presión, con capa decorativa sobre una o las dos caras"
 },
 {
  "CNKEY": "392190430080",
  "CN": "3921 90 43",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392190490080",
  "CN": "3921 90 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392190550080",
  "CN": "3921 90 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392190600080",
  "CN": "3921 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De productos de polimerización de adición"
 },
 {
  "CNKEY": "392190900080",
  "CN": "3921 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "392200000080",
  "CN": "3922",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bañeras, duchas, fregaderos (piletas de lavar), lavabos, bidés, inodoros y sus asientos y tapas, cisternas (depósitos de agua) para inodoros y artículos sanitarios o higiénicos similares, de plástico"
 },
 {
  "CNKEY": "392210000080",
  "CN": "3922 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bañeras, duchas, fregaderos (piletas de lavar) y lavabos"
 },
 {
  "CNKEY": "392220000080",
  "CN": "3922 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Asientos y tapas de inodoros"
 },
 {
  "CNKEY": "392290000080",
  "CN": "3922 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392300000080",
  "CN": "3923",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos para el transporte o envasado, de plástico; tapones, tapas, cápsulas y demás dispositivos de cierre, de plástico"
 },
 {
  "CNKEY": "392310000080",
  "CN": "3923 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cajas, cajones, jaulas y artículos similares"
 },
 {
  "CNKEY": "392310100080",
  "CN": "3923 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cajas, cajones, jaulas y artículos similares, de plástico, especialmente diseñados o adecuados para el transporte o envasado de máscaras, retículas y obleas semiconductoras"
 },
 {
  "CNKEY": "392310900080",
  "CN": "3923 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sacos (bolsas), bolsitas y cucuruchos"
 },
 {
  "CNKEY": "392321000080",
  "CN": "3923 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polímeros de etileno"
 },
 {
  "CNKEY": "392329000080",
  "CN": "3923 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás plásticos"
 },
 {
  "CNKEY": "392329100080",
  "CN": "3923 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De poli(cloruro de vinilo)"
 },
 {
  "CNKEY": "392329900080",
  "CN": "3923 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392330000080",
  "CN": "3923 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bombonas (damajuanas), botellas, frascos y artículos similares"
 },
 {
  "CNKEY": "392330100080",
  "CN": "3923 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad inferior o igual a 2 l"
 },
 {
  "CNKEY": "392330900080",
  "CN": "3923 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad superior a 2 l"
 },
 {
  "CNKEY": "392340000080",
  "CN": "3923 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bobinas, carretes, canillas y soportes similares"
 },
 {
  "CNKEY": "392340100080",
  "CN": "3923 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bobinas y soportes similares para enrollar los filmes y películas fotográficas y cinematográficas o de cintas, filmes y demás, de la partida 8523"
 },
 {
  "CNKEY": "392340900080",
  "CN": "3923 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392350000080",
  "CN": "3923 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tapones, tapas, cápsulas y demás dispositivos de cierre"
 },
 {
  "CNKEY": "392350100080",
  "CN": "3923 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cápsulas de cierre"
 },
 {
  "CNKEY": "392350900080",
  "CN": "3923 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392390000080",
  "CN": "3923 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392400000080",
  "CN": "3924",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vajilla, artículos de cocina o de uso doméstico y artículos de higiene o tocador, de plástico"
 },
 {
  "CNKEY": "392410000080",
  "CN": "3924 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Vajilla y demás artículos para el servicio de mesa o de cocina"
 },
 {
  "CNKEY": "392490000080",
  "CN": "3924 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392500000080",
  "CN": "3925",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos para la construcción, de plástico, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "392510000080",
  "CN": "3925 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Depósitos, cisternas, cubas y recipientes análogos, de capacidad superior a 300 l"
 },
 {
  "CNKEY": "392520000080",
  "CN": "3925 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Puertas, ventanas, y sus marcos, contramarcos y umbrales"
 },
 {
  "CNKEY": "392530000080",
  "CN": "3925 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Contraventanas, persianas, incluidas las venecianas, y artículos similares, y sus partes"
 },
 {
  "CNKEY": "392590000080",
  "CN": "3925 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "392590100080",
  "CN": "3925 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Accesorios y guarniciones para fijar permanentemente en puertas, ventanas, escaleras, paredes y otras partes de edificios"
 },
 {
  "CNKEY": "392590200080",
  "CN": "3925 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles y conductos de cables para canalizaciones eléctricas"
 },
 {
  "CNKEY": "392590800080",
  "CN": "3925 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "392600000080",
  "CN": "3926",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de plástico y manufacturas de las demás materias de las partidas 3901 a 3914"
 },
 {
  "CNKEY": "392610000080",
  "CN": "3926 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos de oficina y artículos escolares"
 },
 {
  "CNKEY": "392620000080",
  "CN": "3926 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Prendas y complementos (accesorios), de vestir, incluidos los guantes, mitones y manoplas"
 },
 {
  "CNKEY": "392630000080",
  "CN": "3926 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guarniciones para muebles, carrocerías o similares"
 },
 {
  "CNKEY": "392640000080",
  "CN": "3926 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Estatuillas y demás artículos de adorno"
 },
 {
  "CNKEY": "392690000080",
  "CN": "3926 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "392690500080",
  "CN": "3926 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rejillas y artículos similares para filtrar el agua a la entrada de las alcantarillas"
 },
 {
  "CNKEY": "392690600080",
  "CN": "3926 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pantallas y viseras de protección facial"
 },
 {
  "CNKEY": "392690970080",
  "CN": "3926 90 97",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "400021000090",
  "CN": "40",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 40 - CAUCHO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "400100000080",
  "CN": "4001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caucho natural, balata, gutapercha, guayule, chicle y gomas naturales análogas, en formas primarias o en placas, hojas o tiras"
 },
 {
  "CNKEY": "400110000080",
  "CN": "4001 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Látex de caucho natural, incluso prevulcanizado"
 },
 {
  "CNKEY": "400121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caucho natural en otras formas"
 },
 {
  "CNKEY": "400121000080",
  "CN": "4001 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojas ahumadas"
 },
 {
  "CNKEY": "400122000080",
  "CN": "4001 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cauchos técnicamente especificados (TSNR)"
 },
 {
  "CNKEY": "400129000080",
  "CN": "4001 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400130000080",
  "CN": "4001 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Balata, gutapercha, guayule, chicle y gomas naturales análogas"
 },
 {
  "CNKEY": "400200000080",
  "CN": "4002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caucho sintético y caucho facticio derivado de los aceites, en formas primarias o en placas, hojas o tiras; mezclas de productos de la partida 4001 con los de esta partida, en formas primarias o en placas, hojas o tiras"
 },
 {
  "CNKEY": "400211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caucho estireno-butadieno (SBR); caucho estireno-butadieno carboxilado (XSBR)"
 },
 {
  "CNKEY": "400211000080",
  "CN": "4002 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Látex"
 },
 {
  "CNKEY": "400219000080",
  "CN": "4002 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "400219100080",
  "CN": "4002 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Caucho estireno-butadieno producido por polimerización en emulsión (E-SBR), en balas"
 },
 {
  "CNKEY": "400219200080",
  "CN": "4002 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Copolímeros en bloque de estireno-butadieno-estireno producido por polimerización en solución (SBS, elastómeros termoplásticos), en gránulos, migas o en polvo"
 },
 {
  "CNKEY": "400219300080",
  "CN": "4002 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Caucho estireno-butadieno producido por polimerización en solución (S-SBR) en balas"
 },
 {
  "CNKEY": "400219900080",
  "CN": "4002 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400220000080",
  "CN": "4002 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caucho butadieno (BR)"
 },
 {
  "CNKEY": "400231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caucho isobuteno-isopreno (butilo) (IIR); caucho isobuteno-isopreno halogenado (CIIR o BIIR)"
 },
 {
  "CNKEY": "400231000080",
  "CN": "4002 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Caucho isobuteno-isopreno (butilo) (IIR)"
 },
 {
  "CNKEY": "400239000080",
  "CN": "4002 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caucho cloropreno (clorobutadieno) (CR)"
 },
 {
  "CNKEY": "400241000080",
  "CN": "4002 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Látex"
 },
 {
  "CNKEY": "400249000080",
  "CN": "4002 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Caucho acrilonitrilo-butadieno (NBR)"
 },
 {
  "CNKEY": "400251000080",
  "CN": "4002 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Látex"
 },
 {
  "CNKEY": "400259000080",
  "CN": "4002 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400260000080",
  "CN": "4002 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caucho isopreno (IR)"
 },
 {
  "CNKEY": "400270000080",
  "CN": "4002 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caucho etileno-propileno-dieno no conjugado (EPDM)"
 },
 {
  "CNKEY": "400280000080",
  "CN": "4002 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mezclas de los productos de la partida 4001 con los de esta partida"
 },
 {
  "CNKEY": "400291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "400291000080",
  "CN": "4002 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Látex"
 },
 {
  "CNKEY": "400299000080",
  "CN": "4002 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "400299100080",
  "CN": "4002 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Productos modificados por la incorporación de plástico"
 },
 {
  "CNKEY": "400299900080",
  "CN": "4002 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400300000080",
  "CN": "4003 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Caucho regenerado en formas primarias o en placas, hojas o tiras"
 },
 {
  "CNKEY": "400400000080",
  "CN": "4004 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desechos, desperdicios y recortes, de caucho sin endurecer, incluso en polvo o gránulos"
 },
 {
  "CNKEY": "400500000080",
  "CN": "4005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Caucho mezclado sin vulcanizar, en formas primarias o en placas, hojas o tiras"
 },
 {
  "CNKEY": "400510000080",
  "CN": "4005 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Caucho con adición de negro de humo o de sílice"
 },
 {
  "CNKEY": "400520000080",
  "CN": "4005 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Disoluciones; dispersiones (excepto las de la subpartida 400510)"
 },
 {
  "CNKEY": "400591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "400591000080",
  "CN": "4005 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Placas, hojas y tiras"
 },
 {
  "CNKEY": "400599000080",
  "CN": "4005 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400600000080",
  "CN": "4006",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás formas (por ejemplo: varillas, tubos, perfiles) y artículos (por ejemplo: discos, arandelas), de caucho sin vulcanizar"
 },
 {
  "CNKEY": "400610000080",
  "CN": "4006 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfiles para recauchutar"
 },
 {
  "CNKEY": "400690000080",
  "CN": "4006 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400700000080",
  "CN": "4007 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Hilos y cuerdas, de caucho vulcanizado"
 },
 {
  "CNKEY": "400800000080",
  "CN": "4008",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas, hojas, tiras, varillas y perfiles, de caucho vulcanizado sin endurecer"
 },
 {
  "CNKEY": "400811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De caucho celular"
 },
 {
  "CNKEY": "400811000080",
  "CN": "4008 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Placas, hojas y tiras"
 },
 {
  "CNKEY": "400819000080",
  "CN": "4008 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De caucho no celular"
 },
 {
  "CNKEY": "400821000080",
  "CN": "4008 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Placas, hojas y tiras"
 },
 {
  "CNKEY": "400821100080",
  "CN": "4008 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Revestimientos de suelos y alfombras"
 },
 {
  "CNKEY": "400821900080",
  "CN": "4008 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400829000080",
  "CN": "4008 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "400900000080",
  "CN": "4009",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos de caucho vulcanizado sin endurecer, incluso con sus accesorios [por ejemplo: juntas, codos, empalmes (racores)]"
 },
 {
  "CNKEY": "400911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin reforzar ni combinar de otro modo con otras materias"
 },
 {
  "CNKEY": "400911000080",
  "CN": "4009 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin accesorios"
 },
 {
  "CNKEY": "400912000080",
  "CN": "4009 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con accesorios"
 },
 {
  "CNKEY": "400921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Reforzados o combinados de otro modo solamente con metal"
 },
 {
  "CNKEY": "400921000080",
  "CN": "4009 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin accesorios"
 },
 {
  "CNKEY": "400922000080",
  "CN": "4009 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con accesorios"
 },
 {
  "CNKEY": "400931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Reforzados o combinados de otro modo solamente con materia textil"
 },
 {
  "CNKEY": "400931000080",
  "CN": "4009 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin accesorios"
 },
 {
  "CNKEY": "400932000080",
  "CN": "4009 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con accesorios"
 },
 {
  "CNKEY": "400941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Reforzados o combinados de otro modo con otras materias"
 },
 {
  "CNKEY": "400941000080",
  "CN": "4009 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin accesorios"
 },
 {
  "CNKEY": "400942000080",
  "CN": "4009 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con accesorios"
 },
 {
  "CNKEY": "401000000080",
  "CN": "4010",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Correas transportadoras o de transmisión, de caucho vulcanizado"
 },
 {
  "CNKEY": "401011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Correas transportadoras"
 },
 {
  "CNKEY": "401011000080",
  "CN": "4010 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Reforzadas solamente con metal"
 },
 {
  "CNKEY": "401012000080",
  "CN": "4010 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Reforzadas solamente con materia textil"
 },
 {
  "CNKEY": "401019000080",
  "CN": "4010 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "401031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Correas de transmisión"
 },
 {
  "CNKEY": "401031000080",
  "CN": "4010 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, estriadas, de sección trapezoidal, de circunferencia exterior superior a 60 cm pero inferior o igual a 180 cm"
 },
 {
  "CNKEY": "401032000080",
  "CN": "4010 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, sin estriar, de sección trapezoidal, de circunferencia exterior superior a 60 cm pero inferior o igual a 180 cm"
 },
 {
  "CNKEY": "401033000080",
  "CN": "4010 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, estriadas, de sección trapezoidal, de circunferencia exterior superior a 180 cm pero inferior o igual a 240 cm"
 },
 {
  "CNKEY": "401034000080",
  "CN": "4010 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, sin estriar, de sección trapezoidal, de circunferencia exterior superior a 180 cm pero inferior o igual a 240 cm"
 },
 {
  "CNKEY": "401035000080",
  "CN": "4010 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, con muescas (sincrónicas), de circunferencia exterior superior a 60 cm pero inferior o igual a 150 cm"
 },
 {
  "CNKEY": "401036000080",
  "CN": "4010 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas de transmisión, sin fin, con muescas (sincrónicas), de circunferencia exterior superior a 150 cm pero inferior o igual a 198 cm"
 },
 {
  "CNKEY": "401039000080",
  "CN": "4010 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "401100000080",
  "CN": "4011",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Neumáticos (llantas neumáticas) nuevos de caucho"
 },
 {
  "CNKEY": "401110000080",
  "CN": "4011 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en automóviles de turismo, incluidos los del tipo familiar (break o station wagon) y los de carreras"
 },
 {
  "CNKEY": "401120000080",
  "CN": "4011 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los tipos utilizados en autobuses o camiones"
 },
 {
  "CNKEY": "401120100080",
  "CN": "4011 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un índice de carga inferior o igual a 121"
 },
 {
  "CNKEY": "401120900080",
  "CN": "4011 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un índice de carga superior a 121"
 },
 {
  "CNKEY": "401130000080",
  "CN": "4011 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en aeronaves"
 },
 {
  "CNKEY": "401140000080",
  "CN": "4011 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en motocicletas"
 },
 {
  "CNKEY": "401150000080",
  "CN": "4011 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en bicicletas"
 },
 {
  "CNKEY": "401170000080",
  "CN": "4011 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en vehículos y máquinas agrícolas o forestales"
 },
 {
  "CNKEY": "401180000080",
  "CN": "4011 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en vehículos y máquinas para la construcción,  minería o mantenimiento industrial"
 },
 {
  "CNKEY": "401190000080",
  "CN": "4011 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "401200000080",
  "CN": "4012",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Neumáticos (llantas neumáticas) recauchutados o usados, de caucho; bandajes (llantas macizas o huecas), bandas de rodadura para neumáticos (llantas neumáticas) y protectores (flaps), de caucho"
 },
 {
  "CNKEY": "401211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Neumáticos (llantas neumáticas) recauchutados"
 },
 {
  "CNKEY": "401211000080",
  "CN": "4012 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en automóviles de turismo, incluidos los del tipo familiar (break o station wagon) y los de carreras"
 },
 {
  "CNKEY": "401212000080",
  "CN": "4012 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en autobuses o camiones"
 },
 {
  "CNKEY": "401213000080",
  "CN": "4012 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en aeronaves"
 },
 {
  "CNKEY": "401219000080",
  "CN": "4012 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "401220000080",
  "CN": "4012 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Neumáticos (llantas neumáticas) usados"
 },
 {
  "CNKEY": "401290000080",
  "CN": "4012 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "401290200080",
  "CN": "4012 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bandajes, macizos o huecos (semimacizos)"
 },
 {
  "CNKEY": "401290300080",
  "CN": "4012 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bandas de rodadura para neumáticos"
 },
 {
  "CNKEY": "401290900080",
  "CN": "4012 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Flaps"
 },
 {
  "CNKEY": "401300000080",
  "CN": "4013",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cámaras de caucho para neumáticos (llantas neumáticas)"
 },
 {
  "CNKEY": "401310000080",
  "CN": "4013 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en automóviles de turismo, incluidos los del tipo familiar (break o station wagon) y los de carreras, en autobuses o camiones"
 },
 {
  "CNKEY": "401320000080",
  "CN": "4013 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en bicicletas"
 },
 {
  "CNKEY": "401390000080",
  "CN": "4013 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "401400000080",
  "CN": "4014",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de higiene o de farmacia, comprendidas las tetinas, de caucho vulcanizado sin endurecer, incluso con partes de caucho endurecido"
 },
 {
  "CNKEY": "401410000080",
  "CN": "4014 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Preservativos"
 },
 {
  "CNKEY": "401490000080",
  "CN": "4014 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "401500000080",
  "CN": "4015",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas de vestir, guantes, mitones y manoplas y demás complementos (accesorios), de vestir, para cualquier uso, de caucho vulcanizado sin endurecer"
 },
 {
  "CNKEY": "401512000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guantes, mitones y manoplas"
 },
 {
  "CNKEY": "401512000080",
  "CN": "4015 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De los tipos utilizados con fines médicos, quirúrgicos, odontológicos o veterinarios"
 },
 {
  "CNKEY": "401519000080",
  "CN": "4015 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "401590000080",
  "CN": "4015 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "401600000080",
  "CN": "4016",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de caucho vulcanizado sin endurecer"
 },
 {
  "CNKEY": "401610000080",
  "CN": "4016 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De caucho celular"
 },
 {
  "CNKEY": "401691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "401691000080",
  "CN": "4016 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Revestimientos para el suelo y alfombras"
 },
 {
  "CNKEY": "401692000080",
  "CN": "4016 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Gomas de borrar"
 },
 {
  "CNKEY": "401693000080",
  "CN": "4016 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Juntas o empaquetaduras"
 },
 {
  "CNKEY": "401694000080",
  "CN": "4016 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Defensas, incluso inflables, para el atraque de los barcos"
 },
 {
  "CNKEY": "401695000080",
  "CN": "4016 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás artículos inflables"
 },
 {
  "CNKEY": "401699000080",
  "CN": "4016 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "401699520010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Para vehículos automóviles de las partidas 8701 a 8705"
 },
 {
  "CNKEY": "401699520080",
  "CN": "4016 99 52",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Piezas de caucho-metal"
 },
 {
  "CNKEY": "401699570080",
  "CN": "4016 99 57",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "401699910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "401699910080",
  "CN": "4016 99 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Piezas de caucho-metal"
 },
 {
  "CNKEY": "401699970080",
  "CN": "4016 99 97",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "401700000080",
  "CN": "4017 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Caucho endurecido (por ejemplo: ebonita) en cualquier forma, incluidos los desechos y desperdicios; manufacturas de caucho endurecido"
 },
 {
  "CNKEY": "410011000090",
  "CN": "VIII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN VIII - PIELES, CUEROS, PELETERÍA Y MANUFACTURAS DE ESTAS MATERIAS; ARTÍCULOS DE TALABARTERÍA O GUARNICIONERÍA; ARTÍCULOS DE VIAJE, BOLSOS DE MANO (CARTERAS) Y CONTINENTES SIMILARES; MANUFACTURAS DE TRIPA"
 },
 {
  "CNKEY": "410021000090",
  "CN": "41",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 41 - PIELES (EXCEPTO LA PELETERÍA) Y CUEROS"
 },
 {
  "CNKEY": "410100000080",
  "CN": "4101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros y pieles en bruto, de bovino, incluido el búfalo, o de equino (frescos o salados, secos, encalados, piquelados o conservados de otro modo, pero sin curtir, apergaminar ni preparar de otra forma), incluso depilados o divididos"
 },
 {
  "CNKEY": "410120000080",
  "CN": "4101 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros, sin dividir, de peso unitario inferior o igual a 8 kg para los secos, a 10 kg para los salados secos y a 16 kg para los frescos, salados verdes (húmedos) o conservados de otro modo"
 },
 {
  "CNKEY": "410120100080",
  "CN": "4101 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Frescos"
 },
 {
  "CNKEY": "410120300080",
  "CN": "4101 20 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Salados verdes (húmedos)"
 },
 {
  "CNKEY": "410120500080",
  "CN": "4101 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Secos o salados secos"
 },
 {
  "CNKEY": "410120800080",
  "CN": "4101 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410150000080",
  "CN": "4101 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros, de peso unitario superior a 16 kg"
 },
 {
  "CNKEY": "410150100080",
  "CN": "4101 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Frescos"
 },
 {
  "CNKEY": "410150300080",
  "CN": "4101 50 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Salados verdes (húmedos)"
 },
 {
  "CNKEY": "410150500080",
  "CN": "4101 50 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Secos o salados secos"
 },
 {
  "CNKEY": "410150900080",
  "CN": "4101 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410190000080",
  "CN": "4101 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos los crupones, medios crupones y faldas"
 },
 {
  "CNKEY": "410200000080",
  "CN": "4102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros y pieles en bruto, de ovino (frescos o salados, secos, encalados, piquelados o conservados de otro modo, pero sin curtir, apergaminar ni preparar de otra forma), incluso depilados o divididos, excepto los excluidos por la Nota 1 c) de este Capítulo"
 },
 {
  "CNKEY": "410210000080",
  "CN": "4102 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con lana"
 },
 {
  "CNKEY": "410210100080",
  "CN": "4102 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cordero"
 },
 {
  "CNKEY": "410210900080",
  "CN": "4102 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De otros ovinos"
 },
 {
  "CNKEY": "410221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin lana (depilados)"
 },
 {
  "CNKEY": "410221000080",
  "CN": "4102 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Piquelados"
 },
 {
  "CNKEY": "410229000080",
  "CN": "4102 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410300000080",
  "CN": "4103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás cueros y pieles, en bruto (frescos o salados, secos, encalados, piquelados o conservados de otro modo, pero sin curtir, apergaminar ni preparar de otra forma), incluso depilados o divididos, excepto los excluidos por las Notas 1 b) o 1 c) de este Capítulo"
 },
 {
  "CNKEY": "410320000080",
  "CN": "4103 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De reptil"
 },
 {
  "CNKEY": "410330000080",
  "CN": "4103 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De porcino"
 },
 {
  "CNKEY": "410390000080",
  "CN": "4103 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410400000080",
  "CN": "4104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros y pieles curtidos o crust, de bovino, incluido el búfalo, o de equino, depilados, incluso divididos pero sin otra preparación"
 },
 {
  "CNKEY": "410411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En estado húmedo, incluido el wet-blue"
 },
 {
  "CNKEY": "410411000080",
  "CN": "4104 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Plena flor sin dividir; divididos con la flor"
 },
 {
  "CNKEY": "410411100080",
  "CN": "4104 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410411510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410411510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410411510080",
  "CN": "4104 11 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros, con una superficie por unidad superior a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410411590080",
  "CN": "4104 11 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410411900080",
  "CN": "4104 11 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410419000080",
  "CN": "4104 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410419100080",
  "CN": "4104 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410419510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410419510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410419510080",
  "CN": "4104 19 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros, con una superficie por unidad superior a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410419590080",
  "CN": "4104 19 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410419900080",
  "CN": "4104 19 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En estado seco (crust)"
 },
 {
  "CNKEY": "410441000080",
  "CN": "4104 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Plena flor, sin dividir; divididos con la flor"
 },
 {
  "CNKEY": "410441110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410441110080",
  "CN": "4104 41 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De vaquillas de la India (kips), enteros o incluso sin la cabeza ni las patas, de peso unitario inferior o igual a 4,5 kg curtidos solamente con sustancias vegetales, aunque se hayan sometido a otras operaciones, pero manifiestamente inutilizables todavía para la fabricación de manufacturas de cuero"
 },
 {
  "CNKEY": "410441190080",
  "CN": "4104 41 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410441510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410441510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410441510080",
  "CN": "4104 41 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros, con una superficie por unidad superior a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410441590080",
  "CN": "4104 41 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410441900080",
  "CN": "4104 41 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410449000080",
  "CN": "4104 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410449110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410449110080",
  "CN": "4104 49 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De vaquillas de la India (kips), enteros o incluso sin la cabeza ni las patas, de peso unitario inferior o igual a 4,5 kg curtidos solamente con sustancias vegetales, aunque se hayan sometido a otras operaciones, pero manifiestamente inutilizables todavía para la fabricación de manufacturas de cuero"
 },
 {
  "CNKEY": "410449190080",
  "CN": "4104 49 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410449510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410449510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410449510080",
  "CN": "4104 49 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Cueros y pieles enteros, con una superficie por unidad superior a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410449590080",
  "CN": "4104 49 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410449900080",
  "CN": "4104 49 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410500000080",
  "CN": "4105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pieles curtidas o crust, de ovino, depiladas, incluso divididas pero sin otra preparación"
 },
 {
  "CNKEY": "410510000080",
  "CN": "4105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "En estado húmedo, incluido el wet-blue"
 },
 {
  "CNKEY": "410530000080",
  "CN": "4105 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "En estado seco (crust)"
 },
 {
  "CNKEY": "410530100080",
  "CN": "4105 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De mestizos de la India, con precurtido vegetal, aunque se hayan sometido a ciertas operaciones, pero manifiestamente inutilizables para la fabricación de manufacturas de piel"
 },
 {
  "CNKEY": "410530900080",
  "CN": "4105 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "410600000080",
  "CN": "4106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros y pieles depilados de los demás animales y pieles de animales sin pelo, curtidos o crust, incluso divididos pero sin otra preparación"
 },
 {
  "CNKEY": "410621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De caprino"
 },
 {
  "CNKEY": "410621000080",
  "CN": "4106 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "En estado húmedo, incluido el wet-blue"
 },
 {
  "CNKEY": "410622000080",
  "CN": "4106 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En estado seco (crust)"
 },
 {
  "CNKEY": "410622100080",
  "CN": "4106 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cabras de la India, con precurtido vegetal, aunque se hayan sometido a ciertas operaciones, pero manifiestamente inutilizables todavía para la fabricación de manufacturas de piel"
 },
 {
  "CNKEY": "410622900080",
  "CN": "4106 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De porcino"
 },
 {
  "CNKEY": "410631000080",
  "CN": "4106 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "En estado húmedo, incluido el wet-blue"
 },
 {
  "CNKEY": "410632000080",
  "CN": "4106 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "En estado seco (crust)"
 },
 {
  "CNKEY": "410640000080",
  "CN": "4106 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De reptil"
 },
 {
  "CNKEY": "410640100080",
  "CN": "4106 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con precurtido vegetal"
 },
 {
  "CNKEY": "410640900080",
  "CN": "4106 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410691000080",
  "CN": "4106 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En estado húmedo, incluido el wet-blue"
 },
 {
  "CNKEY": "410692000080",
  "CN": "4106 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "En estado seco (crust)"
 },
 {
  "CNKEY": "410700000080",
  "CN": "4107",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros preparados después del curtido o del secado y cueros y pieles apergaminados, de bovino (incluido el búfalo) o equino, depilados, incluso divididos excepto los de la partida 4114"
 },
 {
  "CNKEY": "410711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros"
 },
 {
  "CNKEY": "410711000080",
  "CN": "4107 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Plena flor sin dividir"
 },
 {
  "CNKEY": "410711110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410711110080",
  "CN": "4107 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Box-calf"
 },
 {
  "CNKEY": "410711190080",
  "CN": "4107 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410711900080",
  "CN": "4107 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410712000080",
  "CN": "4107 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Divididos con la flor"
 },
 {
  "CNKEY": "410712110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410712110080",
  "CN": "4107 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Box-calf"
 },
 {
  "CNKEY": "410712190080",
  "CN": "4107 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410712910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410712910080",
  "CN": "4107 12 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410712990080",
  "CN": "4107 12 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De equino"
 },
 {
  "CNKEY": "410719000080",
  "CN": "4107 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410719100080",
  "CN": "4107 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Cueros y pieles enteros de bovino, incluido el búfalo, con una superficie por unidad inferior o igual a 2,6 m² (28 pies cuadrados)"
 },
 {
  "CNKEY": "410719900080",
  "CN": "4107 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las hojas"
 },
 {
  "CNKEY": "410791000080",
  "CN": "4107 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Plena flor sin dividir"
 },
 {
  "CNKEY": "410791100080",
  "CN": "4107 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para suelas"
 },
 {
  "CNKEY": "410791900080",
  "CN": "4107 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "410792000080",
  "CN": "4107 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Divididos con la flor"
 },
 {
  "CNKEY": "410792100080",
  "CN": "4107 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410792900080",
  "CN": "4107 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De equino"
 },
 {
  "CNKEY": "410799000080",
  "CN": "4107 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "410799100080",
  "CN": "4107 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De bovino, incluido el búfalo"
 },
 {
  "CNKEY": "410799900080",
  "CN": "4107 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De equino"
 },
 {
  "CNKEY": "411200000080",
  "CN": "4112 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "m²",
  "ES": "Cueros preparados después del curtido o secado y cueros y pieles apergaminados, de ovino, depilados, incluso divididos excepto los de la partida 4114"
 },
 {
  "CNKEY": "411300000080",
  "CN": "4113",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros preparados después del curtido o secado y cueros y pieles apergaminados, de los demás animales, depilados y cueros preparados después del curtido y cueros y pieles apergaminados, de animales sin pelo, incluso divididos excepto los de la partida 4114"
 },
 {
  "CNKEY": "411310000080",
  "CN": "4113 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De caprino"
 },
 {
  "CNKEY": "411320000080",
  "CN": "4113 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De porcino"
 },
 {
  "CNKEY": "411330000080",
  "CN": "4113 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De reptil"
 },
 {
  "CNKEY": "411390000080",
  "CN": "4113 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "411400000080",
  "CN": "4114",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cueros y pieles agamuzados, incluido el agamuzado combinado al aceite; cueros y pieles charolados y sus imitaciones de cueros o pieles chapados; cueros y pieles metalizados"
 },
 {
  "CNKEY": "411410000080",
  "CN": "4114 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cueros y pieles agamuzados, incluido el agamuzado combinado al aceite"
 },
 {
  "CNKEY": "411410100080",
  "CN": "4114 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De ovino"
 },
 {
  "CNKEY": "411410900080",
  "CN": "4114 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los demás animales"
 },
 {
  "CNKEY": "411420000080",
  "CN": "4114 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Cueros y pieles charolados y sus imitaciones de cueros o pieles chapados; cueros y pieles metalizados"
 },
 {
  "CNKEY": "411500000080",
  "CN": "4115",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuero regenerado a base de cuero o fibras de cuero, en placas, hojas o tiras, incluso enrolladas; recortes y demás desperdicios de cuero o piel, preparados, o de cuero regenerado, inutilizables para la fabricación de manufacturas de cuero; aserrín, polvo y harina de cuero"
 },
 {
  "CNKEY": "411510000080",
  "CN": "4115 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuero regenerado a base de cuero o fibras de cuero, en placas, hojas o tiras, incluso enrolladas"
 },
 {
  "CNKEY": "411520000080",
  "CN": "4115 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Recortes y demás desperdicios de cuero o piel, preparados, o de cuero regenerado, no inutilizables para la fabricación de manufacturas de cuero; aserrín, polvo y harina de cuero"
 },
 {
  "CNKEY": "420021000090",
  "CN": "42",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 42 - MANUFACTURAS DE CUERO; ARTÍCULOS DE TALABARTERÍA O GUARNICIONERÍA; ARTÍCULOS DE VIAJE, BOLSOS DE MANO (CARTERAS) Y CONTINENTES SIMILARES; MANUFACTURAS DE TRIPA"
 },
 {
  "CNKEY": "420100000080",
  "CN": "4201 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Artículos de talabartería o guarnicionería para todos los animales, incluidos los tiros, traíllas, rodilleras, bozales, sudaderos, alforjas, abrigos para perros y artículos similares, de cualquier materia"
 },
 {
  "CNKEY": "420200000080",
  "CN": "4202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Baúles, maletas (valijas), maletines, incluidos los de aseo y los portadocumentos, portafolios (carteras de mano), cartapacios, fundas y estuches para gafas (anteojos), binoculares, cámaras fotográficas o cinematográficas, instrumentos musicales o armas y continentes similares; sacos de viaje, sacos (bolsas) aislantes para alimentos y bebidas, bolsas de aseo, mochilas, bolsos de mano (carteras), bolsas para la compra, billeteras, portamonedas, portamapas, petacas, pitilleras y bolsas para tabaco, bolsas para herramientas y para artículos de deporte, estuches para frascos y botellas, estuches para joyas, polveras, estuches para orfebrería y continentes similares, de cuero natural o regenerado, hojas de plástico, materia textil, fibra vulcanizada o cartón, o recubiertos totalmente o en su mayor parte con estas materias o papel"
 },
 {
  "CNKEY": "420211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Baúles, maletas (valijas) y maletines, incluidos los de aseo y los portadocumentos, portafolios (carteras de mano), cartapacios y continentes similares"
 },
 {
  "CNKEY": "420211000080",
  "CN": "4202 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420211100080",
  "CN": "4202 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Maletines portadocumentos, portafolios (carteras de mano), cartapacios y continentes similares"
 },
 {
  "CNKEY": "420211900080",
  "CN": "4202 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420212000080",
  "CN": "4202 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de plástico o de materia textil"
 },
 {
  "CNKEY": "420212110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De hojas de plástico"
 },
 {
  "CNKEY": "420212110080",
  "CN": "4202 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Maletines portadocumentos, portafolios (carteras de mano), cartapacios y continentes similares"
 },
 {
  "CNKEY": "420212190080",
  "CN": "4202 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420212500080",
  "CN": "4202 12 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico moldeado"
 },
 {
  "CNKEY": "420212910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De las demás materias, incluida la fibra vulcanizada"
 },
 {
  "CNKEY": "420212910080",
  "CN": "4202 12 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Maletines portadocumentos, portafolios (carteras de mano), cartapacios y continentes similares"
 },
 {
  "CNKEY": "420212990080",
  "CN": "4202 12 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420219000080",
  "CN": "4202 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "420219100080",
  "CN": "4202 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De aluminio"
 },
 {
  "CNKEY": "420219900080",
  "CN": "4202 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "420221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bolsos de mano (carteras), incluso con bandolera o sin asas"
 },
 {
  "CNKEY": "420221000080",
  "CN": "4202 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con la superficie exterior de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420222000080",
  "CN": "4202 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de hojas de plástico o de materia textil"
 },
 {
  "CNKEY": "420222100080",
  "CN": "4202 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De hojas de plástico"
 },
 {
  "CNKEY": "420222900080",
  "CN": "4202 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De materia textil"
 },
 {
  "CNKEY": "420229000080",
  "CN": "4202 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos de bolsillo o de bolso de mano (carteras)"
 },
 {
  "CNKEY": "420231000080",
  "CN": "4202 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con la superficie exterior de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420232000080",
  "CN": "4202 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de hojas de plástico o de materia textil"
 },
 {
  "CNKEY": "420232100080",
  "CN": "4202 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De hojas de plástico"
 },
 {
  "CNKEY": "420232900080",
  "CN": "4202 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De materia textil"
 },
 {
  "CNKEY": "420239000080",
  "CN": "4202 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "420291000080",
  "CN": "4202 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420291100080",
  "CN": "4202 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sacos de viaje, bolsas de aseo, mochilas y bolsas para artículos de deporte"
 },
 {
  "CNKEY": "420291800080",
  "CN": "4202 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420292000080",
  "CN": "4202 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con la superficie exterior de hojas de plástico o de materia textil"
 },
 {
  "CNKEY": "420292110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De hojas de plástico"
 },
 {
  "CNKEY": "420292110080",
  "CN": "4202 92 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sacos de viaje, bolsas de aseo, mochilas y bolsas para artículos de deporte"
 },
 {
  "CNKEY": "420292150080",
  "CN": "4202 92 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Estuches para instrumentos musicales"
 },
 {
  "CNKEY": "420292190080",
  "CN": "4202 92 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420292910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De materia textil"
 },
 {
  "CNKEY": "420292910080",
  "CN": "4202 92 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Sacos de viaje, bolsas de aseo, mochilas y bolsas para artículos de deporte"
 },
 {
  "CNKEY": "420292980080",
  "CN": "4202 92 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420299000080",
  "CN": "4202 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420300000080",
  "CN": "4203",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas y complementos (accesorios), de vestir, de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420310000080",
  "CN": "4203 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Prendas de vestir"
 },
 {
  "CNKEY": "420321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guantes, mitones y manoplas"
 },
 {
  "CNKEY": "420321000080",
  "CN": "4203 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Diseñados especialmente para la práctica del deporte"
 },
 {
  "CNKEY": "420329000080",
  "CN": "4203 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "420329100080",
  "CN": "4203 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "De protección para cualquier oficio"
 },
 {
  "CNKEY": "420329900080",
  "CN": "4203 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420330000080",
  "CN": "4203 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cintos, cinturones y bandoleras"
 },
 {
  "CNKEY": "420340000080",
  "CN": "4203 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás complementos (accesorios) de vestir"
 },
 {
  "CNKEY": "420500000080",
  "CN": "4205 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de cuero natural o cuero regenerado"
 },
 {
  "CNKEY": "420500110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para usos técnicos"
 },
 {
  "CNKEY": "420500110080",
  "CN": "4205 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Correas transportadoras o de transmisión"
 },
 {
  "CNKEY": "420500190080",
  "CN": "4205 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420500900080",
  "CN": "4205 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "420600000080",
  "CN": "4206 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Manufacturas de tripa, vejigas o tendones"
 },
 {
  "CNKEY": "430021000090",
  "CN": "43",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 43 - PELETERÍA Y CONFECCIONES DE PELETERÍA; PELETERÍA FACTICIA O ARTIFICIAL"
 },
 {
  "CNKEY": "430100000080",
  "CN": "4301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Peletería en bruto, incluidas las cabezas, colas, patas y demás trozos utilizables en peletería (excepto las pieles en bruto de las partidas 4101, 4102 o 4103)"
 },
 {
  "CNKEY": "430110000080",
  "CN": "4301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De visón, enteras, incluso sin la cabeza, cola o patas"
 },
 {
  "CNKEY": "430130000080",
  "CN": "4301 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De cordero llamadas astracán, «Breitschwanz», «caracul», «persa» o similares, de cordero de Indias, de China, de Mongolia o del Tíbet, enteras, incluso sin la cabeza, cola o patas"
 },
 {
  "CNKEY": "430160000080",
  "CN": "4301 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De zorro, enteras, incluso sin la cabeza, cola o patas"
 },
 {
  "CNKEY": "430180000080",
  "CN": "4301 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás pieles, enteras, incluso sin la cabeza, cola o patas"
 },
 {
  "CNKEY": "430190000080",
  "CN": "4301 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cabezas, colas, patas y demás trozos utilizables en peletería"
 },
 {
  "CNKEY": "430200000080",
  "CN": "4302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Peletería curtida o adobada, incluidas las cabezas, colas, patas y demás trozos, desechos y recortes, incluso ensamblada (sin otras materias) (excepto la de la partida 4303)"
 },
 {
  "CNKEY": "430211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pieles enteras, incluso sin la cabeza, cola o patas, sin ensamblar"
 },
 {
  "CNKEY": "430211000080",
  "CN": "4302 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De visón"
 },
 {
  "CNKEY": "430219000080",
  "CN": "4302 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "430219150080",
  "CN": "4302 19 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De castor, rata almizclera o zorro"
 },
 {
  "CNKEY": "430219350080",
  "CN": "4302 19 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De conejo o liebre"
 },
 {
  "CNKEY": "430219410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De foca u otaria"
 },
 {
  "CNKEY": "430219410080",
  "CN": "4302 19 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De crías de foca rayada («de capa blanca») o de crías de foca de capucha («de capa azul»)"
 },
 {
  "CNKEY": "430219490080",
  "CN": "4302 19 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "430219750010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ovino"
 },
 {
  "CNKEY": "430219750080",
  "CN": "4302 19 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De cordero llamadas astracán, «Breitschwanz», «caracul», «persa» o similares, de cordero de Indias, de China, de Mongolia o del Tíbet"
 },
 {
  "CNKEY": "430219800080",
  "CN": "4302 19 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "430219990080",
  "CN": "4302 19 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "430220000080",
  "CN": "4302 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cabezas, colas, patas y demás trozos, desechos y recortes, sin ensamblar"
 },
 {
  "CNKEY": "430230000080",
  "CN": "4302 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pieles enteras, trozos y recortes de pieles, ensamblados"
 },
 {
  "CNKEY": "430230100080",
  "CN": "4302 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pieles llamadas «alargadas»"
 },
 {
  "CNKEY": "430230250010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "430230250080",
  "CN": "4302 30 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De conejo o liebre"
 },
 {
  "CNKEY": "430230510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De foca u otaria"
 },
 {
  "CNKEY": "430230510080",
  "CN": "4302 30 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De crías de foca rayada («de capa blanca») o de crías de foca de capucha («de capa azul»)"
 },
 {
  "CNKEY": "430230550080",
  "CN": "4302 30 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "430230990080",
  "CN": "4302 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "430300000080",
  "CN": "4303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas y complementos (accesorios), de vestir, y demás artículos de peletería"
 },
 {
  "CNKEY": "430310000080",
  "CN": "4303 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Prendas y complementos (accesorios), de vestir"
 },
 {
  "CNKEY": "430310100080",
  "CN": "4303 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peletería de crías de foca rayada («de capa blanca») o de crías de foca de capucha («de capa azul»)"
 },
 {
  "CNKEY": "430310900080",
  "CN": "4303 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "430390000080",
  "CN": "4303 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "430400000080",
  "CN": "4304 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Peletería facticia o artificial y artículos de peletería facticia o artificial"
 },
 {
  "CNKEY": "440011000090",
  "CN": "IX",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN IX - MADERA, CARBÓN VEGETAL Y MANUFACTURAS DE MADERA; CORCHO Y SUS MANUFACTURAS; MANUFACTURAS DE ESPARTERÍA O CESTERÍA"
 },
 {
  "CNKEY": "440021000090",
  "CN": "44",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 44 - MADERA, CARBÓN VEGETAL Y MANUFACTURAS DE MADERA"
 },
 {
  "CNKEY": "440100000080",
  "CN": "4401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Leña; madera en plaquitas o partículas; aserrín, desperdicios y desechos, de madera, incluso aglomerados en leños, briquetas, «pellets» o formas similares"
 },
 {
  "CNKEY": "440111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Leña"
 },
 {
  "CNKEY": "440111000080",
  "CN": "4401 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440112000080",
  "CN": "4401 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "440121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Madera en plaquitas o partículas"
 },
 {
  "CNKEY": "440121000080",
  "CN": "4401 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440122000080",
  "CN": "4401 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "440122100080",
  "CN": "4401 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De eucalipto (Eucalyptus spp.)"
 },
 {
  "CNKEY": "440122900080",
  "CN": "4401 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aserrín, desperdicios y desechos, de madera, aglomerados en leños, briquetas, «pellets» o formas similares"
 },
 {
  "CNKEY": "440131000080",
  "CN": "4401 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "«Pellets» de madera"
 },
 {
  "CNKEY": "440132000080",
  "CN": "4401 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Briquetas de madera"
 },
 {
  "CNKEY": "440139000080",
  "CN": "4401 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "440141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aserrín, desperdicios y desechos, de madera, sin aglomerar"
 },
 {
  "CNKEY": "440141000080",
  "CN": "4401 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aserrín"
 },
 {
  "CNKEY": "440149000080",
  "CN": "4401 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "440200000080",
  "CN": "4402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carbón vegetal, comprendido el de cáscaras o de huesos (carozos) de frutos, incluso aglomerado"
 },
 {
  "CNKEY": "440210000080",
  "CN": "4402 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "440220000080",
  "CN": "4402 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cáscaras o de huesos (carozos) de frutos"
 },
 {
  "CNKEY": "440290000080",
  "CN": "4402 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "440300000080",
  "CN": "4403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Madera en bruto, incluso descortezada, desalburada o escuadrada"
 },
 {
  "CNKEY": "440311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tratada con pintura, creosota u otros agentes de conservación"
 },
 {
  "CNKEY": "440311000080",
  "CN": "4403 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440312000080",
  "CN": "4403 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "440321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, de coníferas"
 },
 {
  "CNKEY": "440321000080",
  "CN": "4403 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De pino (Pinus spp.), cuya menor dimensión de la sección transversal sea superior o igual a 15 cm"
 },
 {
  "CNKEY": "440321100080",
  "CN": "4403 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Madera en rollo para serrar"
 },
 {
  "CNKEY": "440321900080",
  "CN": "4403 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440322000080",
  "CN": "4403 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, de pino (Pinus spp.)"
 },
 {
  "CNKEY": "440323000080",
  "CN": "4403 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De abeto (Abies spp.) y de pícea (Picea spp.), cuya menor dimensión de la sección transversal sea superior o igual a 15 cm"
 },
 {
  "CNKEY": "440323100080",
  "CN": "4403 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Madera en rollo para serrar"
 },
 {
  "CNKEY": "440323900080",
  "CN": "4403 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440324000080",
  "CN": "4403 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, de abeto (Abies spp.) y de pícea (Picea spp.)"
 },
 {
  "CNKEY": "440325000080",
  "CN": "4403 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, cuya menor dimensión de la sección transversal sea superior o igual a 15 cm"
 },
 {
  "CNKEY": "440325100080",
  "CN": "4403 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Madera en rollo para serrar"
 },
 {
  "CNKEY": "440325900080",
  "CN": "4403 25 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440326000080",
  "CN": "4403 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, de maderas tropicales"
 },
 {
  "CNKEY": "440341000080",
  "CN": "4403 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Dark red meranti, light red meranti y meranti bakau"
 },
 {
  "CNKEY": "440342000080",
  "CN": "4403 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Teca"
 },
 {
  "CNKEY": "440349000080",
  "CN": "4403 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440349100080",
  "CN": "4403 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Acajou de África, Iroko e sapelli"
 },
 {
  "CNKEY": "440349350080",
  "CN": "4403 49 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Okoumé e sipo"
 },
 {
  "CNKEY": "440349850080",
  "CN": "4403 49 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440391000080",
  "CN": "4403 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De encina, roble, alcornoque y demás belloteros (Quercus spp.)"
 },
 {
  "CNKEY": "440393000080",
  "CN": "4403 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De haya (Fagus spp.), cuya menor dimensión de la sección transversal sea superior o igual a 15 cm"
 },
 {
  "CNKEY": "440394000080",
  "CN": "4403 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, de haya (Fagus spp.)"
 },
 {
  "CNKEY": "440395000080",
  "CN": "4403 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De abedul (Betula spp.), cuya menor dimensión de la sección transversal sea superior o igual a 15 cm"
 },
 {
  "CNKEY": "440395100080",
  "CN": "4403 95 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Madera en rollo para serrar"
 },
 {
  "CNKEY": "440395900080",
  "CN": "4403 95 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440396000080",
  "CN": "4403 96 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, de abedul (Betula spp.)"
 },
 {
  "CNKEY": "440397000080",
  "CN": "4403 97 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De álamo (Populus spp.)"
 },
 {
  "CNKEY": "440398000080",
  "CN": "4403 98 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De eucalipto (Eucalyptus spp.)"
 },
 {
  "CNKEY": "440399000080",
  "CN": "4403 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440400000080",
  "CN": "4404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Flejes de madera; rodrigones hendidos; estacas y estaquillas de madera, apuntadas, sin aserrar longitudinalmente; madera simplemente desbastada o redondeada, pero sin tornear, curvar ni trabajar de otro modo, para bastones, paraguas, mangos de herramientas o similares; madera en tablillas, láminas, cintas o similares"
 },
 {
  "CNKEY": "440410000080",
  "CN": "4404 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440420000080",
  "CN": "4404 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "440500000080",
  "CN": "4405 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Lana de madera; harina de madera"
 },
 {
  "CNKEY": "440600000080",
  "CN": "4406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Traviesas (durmientes) de madera para vías férreas o similares"
 },
 {
  "CNKEY": "440611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin impregnar"
 },
 {
  "CNKEY": "440611000080",
  "CN": "4406 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440612000080",
  "CN": "4406 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Distintas de la de coníferas"
 },
 {
  "CNKEY": "440691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440691000080",
  "CN": "4406 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440692000080",
  "CN": "4406 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Distintas de la de coníferas"
 },
 {
  "CNKEY": "440700000080",
  "CN": "4407",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Madera aserrada o desbastada longitudinalmente, cortada o desenrollada, incluso cepillada, lijada o unida por los extremos, de espesor superior a 6 mm"
 },
 {
  "CNKEY": "440711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440711000080",
  "CN": "4407 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De pino (Pinus spp.)"
 },
 {
  "CNKEY": "440711100080",
  "CN": "4407 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440711200080",
  "CN": "4407 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440711900080",
  "CN": "4407 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440712000080",
  "CN": "4407 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De abeto (Abies spp.) y de pícea (Picea spp.)"
 },
 {
  "CNKEY": "440712100080",
  "CN": "4407 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440712200080",
  "CN": "4407 12 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440712900080",
  "CN": "4407 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440713000080",
  "CN": "4407 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De P-P-A («S-P-F») (pícea (Picea spp.), pino (Pinus spp.) y abeto (Abies spp.))"
 },
 {
  "CNKEY": "440714000080",
  "CN": "4407 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De «Hem-fir» (tsuga-abeto) (hemlock occidental (tsuga del pacífico) (Tsuga heterophylla) y abeto (Abies spp.))"
 },
 {
  "CNKEY": "440719000080",
  "CN": "4407 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440719100080",
  "CN": "4407 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440719200080",
  "CN": "4407 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440719900080",
  "CN": "4407 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "440721000080",
  "CN": "4407 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mahogany (Swietenia spp.)"
 },
 {
  "CNKEY": "440721100080",
  "CN": "4407 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440721910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440721910080",
  "CN": "4407 21 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440721990080",
  "CN": "4407 21 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440722000080",
  "CN": "4407 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Virola, imbuya y balsa"
 },
 {
  "CNKEY": "440722100080",
  "CN": "4407 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440722910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440722910080",
  "CN": "4407 22 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440722990080",
  "CN": "4407 22 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440723000080",
  "CN": "4407 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Teca"
 },
 {
  "CNKEY": "440723100080",
  "CN": "4407 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440723200080",
  "CN": "4407 23 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440723900080",
  "CN": "4407 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440725000080",
  "CN": "4407 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Dark red meranti, light red meranti y meranti bakau"
 },
 {
  "CNKEY": "440725100080",
  "CN": "4407 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440725300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440725300080",
  "CN": "4407 25 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440725500080",
  "CN": "4407 25 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440725900080",
  "CN": "4407 25 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440726000080",
  "CN": "4407 26",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "White lauan, white meranti, white seraya, yellow meranti y alan"
 },
 {
  "CNKEY": "440726100080",
  "CN": "4407 26 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440726300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440726300080",
  "CN": "4407 26 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440726500080",
  "CN": "4407 26 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440726900080",
  "CN": "4407 26 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440727000080",
  "CN": "4407 27",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sapelli"
 },
 {
  "CNKEY": "440727100080",
  "CN": "4407 27 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440727910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440727910080",
  "CN": "4407 27 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440727990080",
  "CN": "4407 27 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440728000080",
  "CN": "4407 28",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Iroko"
 },
 {
  "CNKEY": "440728100080",
  "CN": "4407 28 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440728910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440728910080",
  "CN": "4407 28 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440728990080",
  "CN": "4407 28 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729000080",
  "CN": "4407 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729150010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Abura, acajou de África, afrormosia, ako, andiroba, aningré, avodiré, azobé, balau, bossé clair, bossé foncé, cativo, cedro, dabema, dibétou, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, ipé, jaboty, jelutong, jequitiba, jongkong, kapur, kempas, keruing, kosipo, kotibé, koto, limba, louro, maçaranduba, makoré, mandioqueira, mansonia, mengkulang, merawan, merbau, merpauh, mersawa, moabi, niangon, nyatoh, obeche, okoumé, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, palissandre de Para, palissandre de Río, palissandre de Rose, pau Amarelo, pau marfim, pulai, punah, quaruba, ramin, saqui-saqui, sepetir, sipo, sucupira, suren, tauari, tiama, tola"
 },
 {
  "CNKEY": "440729150080",
  "CN": "4407 29 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440729200010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729200080",
  "CN": "4407 29 20",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Palissandre de Para, palissandre de Río y palissandre de Rose, cepillada"
 },
 {
  "CNKEY": "440729830010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729830080",
  "CN": "4407 29 83",
  "CN_LEVEL": 9,
  "T_SU_SU": "m³",
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440729850080",
  "CN": "4407 29 85",
  "CN_LEVEL": 9,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440729950080",
  "CN": "4407 29 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De las demás maderas tropicales"
 },
 {
  "CNKEY": "440729960080",
  "CN": "4407 29 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440729970010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440729970080",
  "CN": "4407 29 97",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440729980080",
  "CN": "4407 29 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440791000080",
  "CN": "4407 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De encina, roble, alcornoque y demás belloteros (Quercus spp.)"
 },
 {
  "CNKEY": "440791150080",
  "CN": "4407 91 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Lijada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440791310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440791310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Cepillada"
 },
 {
  "CNKEY": "440791310080",
  "CN": "4407 91 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "m²",
  "ES": "Tablas y frisos para parqués, sin ensamblar"
 },
 {
  "CNKEY": "440791390080",
  "CN": "4407 91 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440791900080",
  "CN": "4407 91 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440792000080",
  "CN": "4407 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De haya (Fagus spp.)"
 },
 {
  "CNKEY": "440793000080",
  "CN": "4407 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De arce (Acer spp.)"
 },
 {
  "CNKEY": "440793100080",
  "CN": "4407 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440793910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440793910080",
  "CN": "4407 93 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440793990080",
  "CN": "4407 93 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440794000080",
  "CN": "4407 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cerezo (Prunus spp.)"
 },
 {
  "CNKEY": "440794100080",
  "CN": "4407 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440794910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440794910080",
  "CN": "4407 94 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440794990080",
  "CN": "4407 94 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440795000080",
  "CN": "4407 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fresno (Fraxinus spp.)"
 },
 {
  "CNKEY": "440795100080",
  "CN": "4407 95 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440795910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440795910080",
  "CN": "4407 95 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440795990080",
  "CN": "4407 95 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440796000080",
  "CN": "4407 96",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De abedul (Betula spp.)"
 },
 {
  "CNKEY": "440796100080",
  "CN": "4407 96 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440796910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440796910080",
  "CN": "4407 96 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440796990080",
  "CN": "4407 96 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440797000080",
  "CN": "4407 97",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De álamo (Populus spp.)"
 },
 {
  "CNKEY": "440797100080",
  "CN": "4407 97 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440797910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440797910080",
  "CN": "4407 97 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440797990080",
  "CN": "4407 97 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440799000080",
  "CN": "4407 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440799270080",
  "CN": "4407 99 27",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Cepillada; unida por los extremos, incluso cepillada o lijada"
 },
 {
  "CNKEY": "440799400010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440799400080",
  "CN": "4407 99 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijada"
 },
 {
  "CNKEY": "440799900080",
  "CN": "4407 99 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440800000080",
  "CN": "4408",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hojas para chapado (incluidas las obtenidas por cortado de madera estratificada), para contrachapado o para maderas estratificadas similares y demás maderas aserradas longitudinalmente, cortadas o desenrolladas, incluso cepilladas, lijadas, unidas longitudinalmente o por los extremos, de espesor inferior o igual a 6 mm"
 },
 {
  "CNKEY": "440810000080",
  "CN": "4408 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440810150080",
  "CN": "4408 10 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Cepilladas; lijadas; unidas por los extremos, incluso cepilladas o lijadas"
 },
 {
  "CNKEY": "440810910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440810910080",
  "CN": "4408 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Tablillas para la fabricación de lápices"
 },
 {
  "CNKEY": "440810980080",
  "CN": "4408 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "440831000080",
  "CN": "4408 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Dark red meranti, light red meranti y meranti bakau"
 },
 {
  "CNKEY": "440831110080",
  "CN": "4408 31 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Unidas por los extremos, incluso cepilladas o lijadas"
 },
 {
  "CNKEY": "440831210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440831210080",
  "CN": "4408 31 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepilladas"
 },
 {
  "CNKEY": "440831250080",
  "CN": "4408 31 25",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijadas"
 },
 {
  "CNKEY": "440831300080",
  "CN": "4408 31 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839000080",
  "CN": "4408 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839150010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Acajou de África, limba, mahogany (Swietenia spp.), okumé, obeche, sapelli, sipo, palissandre de Para, palissandre de Río, palissandre de Rose, virola y white lauan"
 },
 {
  "CNKEY": "440839150080",
  "CN": "4408 39 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Lijadas; unidas por los extremos, incluso cepilladas o lijadas"
 },
 {
  "CNKEY": "440839210010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839210080",
  "CN": "4408 39 21",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Cepilladas"
 },
 {
  "CNKEY": "440839300080",
  "CN": "4408 39 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839550010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839550080",
  "CN": "4408 39 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Cepilladas; lijadas; unidas por los extremos, incluso cepilladas o lijadas"
 },
 {
  "CNKEY": "440839700010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839700080",
  "CN": "4408 39 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "m³",
  "ES": "Tablillas para la fabricación de lápices"
 },
 {
  "CNKEY": "440839850010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440839850080",
  "CN": "4408 39 85",
  "CN_LEVEL": 9,
  "T_SU_SU": "m³",
  "ES": "De espesor inferior o igual a 1 mm"
 },
 {
  "CNKEY": "440839950080",
  "CN": "4408 39 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "m³",
  "ES": "De espesor superior a 1 mm"
 },
 {
  "CNKEY": "440890000080",
  "CN": "4408 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440890150080",
  "CN": "4408 90 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Cepilladas; lijadas; unidas por los extremos, incluso cepilladas o lijadas"
 },
 {
  "CNKEY": "440890350010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440890350080",
  "CN": "4408 90 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Tablillas para la fabricación de lápices"
 },
 {
  "CNKEY": "440890850010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440890850080",
  "CN": "4408 90 85",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De espesor inferior o igual a 1 mm"
 },
 {
  "CNKEY": "440890950080",
  "CN": "4408 90 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De espesor superior a 1 mm"
 },
 {
  "CNKEY": "440900000080",
  "CN": "4409",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Madera (incluidas las tablillas y frisos para parqués, sin ensamblar) perfilada longitudinalmente (con lengüetas, ranuras, rebajes, acanalados, biselados, con juntas en V, moldurados, redondeados o similares) en una o varias caras, cantos o extremos, incluso cepillada, lijada o unida por los extremos"
 },
 {
  "CNKEY": "440910000080",
  "CN": "4409 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De coníferas"
 },
 {
  "CNKEY": "440910110080",
  "CN": "4409 10 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Varillas y molduras de madera, para marcos de cuadros, fotografías, espejos u objetos similares"
 },
 {
  "CNKEY": "440910180080",
  "CN": "4409 10 18",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "440921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "440921000080",
  "CN": "4409 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "440922000080",
  "CN": "4409 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "440929000080",
  "CN": "4409 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440929100080",
  "CN": "4409 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m",
  "ES": "Varillas y molduras de madera, para marcos de cuadros, fotografías, espejos u objetos similares"
 },
 {
  "CNKEY": "440929910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "440929910080",
  "CN": "4409 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Tablillas y frisos para parqués, sin ensamblar"
 },
 {
  "CNKEY": "440929990080",
  "CN": "4409 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441000000080",
  "CN": "4410",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tableros de partículas, tableros llamados «oriented strand board» (OSB) y tableros similares (por ejemplo: los llamados «waferboard»), de madera u otras materias leñosas, incluso aglomeradas con resinas o demás aglutinantes orgánicos"
 },
 {
  "CNKEY": "441011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De madera"
 },
 {
  "CNKEY": "441011000080",
  "CN": "4410 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tableros de partículas"
 },
 {
  "CNKEY": "441011100080",
  "CN": "4410 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "En bruto o simplemente lijados (tableros en bruto)"
 },
 {
  "CNKEY": "441011300080",
  "CN": "4410 11 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Recubiertos en la superficie con papel impregnado de melamina"
 },
 {
  "CNKEY": "441011500080",
  "CN": "4410 11 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Recubiertos en la superficie con placas u hojas decorativas estratificadas de plástico"
 },
 {
  "CNKEY": "441011900080",
  "CN": "4410 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441012000080",
  "CN": "4410 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tableros llamados «oriented strand board» (OSB)"
 },
 {
  "CNKEY": "441012100080",
  "CN": "4410 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "En bruto o simplemente lijados (tableros en bruto)"
 },
 {
  "CNKEY": "441012900080",
  "CN": "4410 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441019000080",
  "CN": "4410 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441090000080",
  "CN": "4410 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441100000080",
  "CN": "4411",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tableros de fibra de madera u otras materias leñosas, incluso aglomeradas con resinas o demás aglutinantes orgánicos"
 },
 {
  "CNKEY": "441112000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tableros de fibra de densidad media (llamados «MDF»)"
 },
 {
  "CNKEY": "441112000080",
  "CN": "4411 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior o igual a 5 mm"
 },
 {
  "CNKEY": "441112100080",
  "CN": "4411 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin trabajo mecánico ni recubrimiento de superficie (tableros en bruto)"
 },
 {
  "CNKEY": "441112920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441112920080",
  "CN": "4411 12 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad superior a 0,8 g\/cm³ (HDF)"
 },
 {
  "CNKEY": "441112940080",
  "CN": "4411 12 94",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad inferior o igual a 0,8 g\/cm³"
 },
 {
  "CNKEY": "441113000080",
  "CN": "4411 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 5 mm pero inferior o igual a 9 mm"
 },
 {
  "CNKEY": "441113100080",
  "CN": "4411 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin trabajo mecánico ni recubrimiento de superficie (tableros en bruto)"
 },
 {
  "CNKEY": "441113920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441113920080",
  "CN": "4411 13 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad superior a 0,8 g\/cm³ (HDF)"
 },
 {
  "CNKEY": "441113940080",
  "CN": "4411 13 94",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad inferior o igual a 0,8 g\/cm³"
 },
 {
  "CNKEY": "441114000080",
  "CN": "4411 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 9 mm"
 },
 {
  "CNKEY": "441114100080",
  "CN": "4411 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin trabajo mecánico ni recubrimiento de superficie (tableros en bruto)"
 },
 {
  "CNKEY": "441114920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441114920080",
  "CN": "4411 14 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad superior a 0,8 g\/cm³ (HDF)"
 },
 {
  "CNKEY": "441114950080",
  "CN": "4411 14 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad superior a 0,5 g\/cm³ pero inferior o igual a 0,8 g\/cm³"
 },
 {
  "CNKEY": "441114970080",
  "CN": "4411 14 97",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "De densidad inferior o igual a 0,5 g\/cm³"
 },
 {
  "CNKEY": "441192000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441192000080",
  "CN": "4411 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De densidad superior a 0,8 g\/cm³"
 },
 {
  "CNKEY": "441192100080",
  "CN": "4411 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin trabajo mecánico ni recubrimiento de superficie (tableros en bruto)"
 },
 {
  "CNKEY": "441192900080",
  "CN": "4411 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441193000080",
  "CN": "4411 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "De densidad superior a 0,5 g\/cm³ pero inferior o igual a 0,8 g\/cm³"
 },
 {
  "CNKEY": "441194000080",
  "CN": "4411 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De densidad inferior o igual a 0,5 g\/cm³"
 },
 {
  "CNKEY": "441194100080",
  "CN": "4411 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin trabajo mecánico ni recubrimiento de superficie (tableros en bruto)"
 },
 {
  "CNKEY": "441194900080",
  "CN": "4411 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441200000080",
  "CN": "4412",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Madera contrachapada, madera chapada y madera estratificada similar"
 },
 {
  "CNKEY": "441210000080",
  "CN": "4412 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m³",
  "ES": "De bambú"
 },
 {
  "CNKEY": "441231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás maderas contrachapadas, constituidas exclusivamente por hojas de madera (exepto de bambú) de espesor unitario inferior o igual a 6 mm"
 },
 {
  "CNKEY": "441231000080",
  "CN": "4412 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que tengan, por lo menos, una hoja externa de maderas tropicales"
 },
 {
  "CNKEY": "441231100080",
  "CN": "4412 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Acajou de África, dark red meranti, light red meranti, limba, mahogany (Swietenia spp.), obeche, okoumé, sapelli, sipo, palissandre de Para, palissandre de Río, palissandre de Rose, virola o white lauan"
 },
 {
  "CNKEY": "441231900080",
  "CN": "4412 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441233000080",
  "CN": "4412 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, que tengan, por lo menos, una hoja externa de madera, distinta de la de coníferas de las especies aliso (Alnus spp.), fresno (Fraxinus spp.), haya (Fagus spp.), abedul (Betula spp.), cerezo (Prunus spp.), castaño (Castanea spp.), olmo (Ulmus spp.), eucalipto (Eucalyptus spp.), caria o pacana (Carya spp.), castaño de Indias (Aesculus spp.), tilo (Tilia spp.), arce (Acer spp.), roble (Quercus spp.), plátano (Platanus spp.), álamo (Populus spp.), algarrobo negro (Robinia spp.), árbol de tulipán (Liriodendron spp.) o nogal (Juglans spp.)"
 },
 {
  "CNKEY": "441233100080",
  "CN": "4412 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Con al menos una hoja externa de abedul (Betula spp.)"
 },
 {
  "CNKEY": "441233200080",
  "CN": "4412 33 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin hoja externa de abedul, pero con al menos una hoja externa de álamo o chopo (Populus spp.)"
 },
 {
  "CNKEY": "441233300080",
  "CN": "4412 33 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Sin hoja externa de abedul, álamo o chopo (Populus spp.), pero con al menos una hoja externa de eucalipto (Eucalyptus spp.)"
 },
 {
  "CNKEY": "441233900080",
  "CN": "4412 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441234000080",
  "CN": "4412 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas, no especificadas en la subpartida 441233"
 },
 {
  "CNKEY": "441239000080",
  "CN": "4412 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, con las dos hojas externas de madera de coníferas"
 },
 {
  "CNKEY": "441241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Madera chapada estratificada (llamada «LVL»)"
 },
 {
  "CNKEY": "441241000080",
  "CN": "4412 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que tenga, por lo menos, una hoja externa de maderas tropicales"
 },
 {
  "CNKEY": "441241910080",
  "CN": "4412 41 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441241990080",
  "CN": "4412 41 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441242000080",
  "CN": "4412 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441249000080",
  "CN": "4412 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Las demás, con las dos hojas externas de madera de coníferas"
 },
 {
  "CNKEY": "441251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tableros denominados «blockboard», «laminboard» y «battenboard»"
 },
 {
  "CNKEY": "441251000080",
  "CN": "4412 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que tengan, por lo menos, una hoja externa de maderas tropicales"
 },
 {
  "CNKEY": "441251100080",
  "CN": "4412 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441251900080",
  "CN": "4412 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441252000080",
  "CN": "4412 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Los demás, que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441259000080",
  "CN": "4412 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Los demás, con las dos hojas externas de madera de coníferas"
 },
 {
  "CNKEY": "441291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "441291000080",
  "CN": "4412 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que tengan, por lo menos, una hoja externa de maderas tropicales"
 },
 {
  "CNKEY": "441291100080",
  "CN": "4412 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Con un tablero de partículas, por lo menos"
 },
 {
  "CNKEY": "441291910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "441291910080",
  "CN": "4412 91 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441291990080",
  "CN": "4412 91 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441292000080",
  "CN": "4412 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, que tengan, por lo menos, una hoja externa de madera distinta de la de coníferas"
 },
 {
  "CNKEY": "441292100080",
  "CN": "4412 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Con un tablero de partículas, por lo menos"
 },
 {
  "CNKEY": "441292900080",
  "CN": "4412 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441299000080",
  "CN": "4412 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, con las dos hojas externas de madera de coníferas"
 },
 {
  "CNKEY": "441299100080",
  "CN": "4412 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Con un tablero de partículas, por lo menos"
 },
 {
  "CNKEY": "441299900080",
  "CN": "4412 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m³",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441300000080",
  "CN": "4413 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "m³",
  "ES": "Madera densificada en bloques, tablas, tiras o perfiles"
 },
 {
  "CNKEY": "441400000080",
  "CN": "4414",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Marcos de madera para cuadros, fotografías, espejos u objetos similares"
 },
 {
  "CNKEY": "441410000080",
  "CN": "4414 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "441410100080",
  "CN": "4414 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las maderas tropicales citadas en la Nota complementaria 2 de este Capítulo"
 },
 {
  "CNKEY": "441410900080",
  "CN": "4414 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441490000080",
  "CN": "4414 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441500000080",
  "CN": "4415",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajones, cajas, jaulas, tambores y envases similares, de madera; carretes para cables, de madera; paletas, paletas caja y demás plataformas para carga, de madera; collarines para paletas, de madera"
 },
 {
  "CNKEY": "441510000080",
  "CN": "4415 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cajones, cajas, jaulas, tambores y envases similares; carretes para cables"
 },
 {
  "CNKEY": "441510100080",
  "CN": "4415 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cajones, cajas, jaulas, tambores (cilindros) y envases similares"
 },
 {
  "CNKEY": "441510900080",
  "CN": "4415 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carretes para cables"
 },
 {
  "CNKEY": "441520000080",
  "CN": "4415 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Paletas, paletas caja y demás plataformas para carga; collarines para paletas"
 },
 {
  "CNKEY": "441520200080",
  "CN": "4415 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Paletas simples; collarines para paletas"
 },
 {
  "CNKEY": "441520900080",
  "CN": "4415 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441600000080",
  "CN": "4416 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Barriles, cubas, tinas y demás manufacturas de tonelería y sus partes, de madera, incluidas las duelas"
 },
 {
  "CNKEY": "441700000080",
  "CN": "4417 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Herramientas, monturas y mangos de herramientas, monturas y mangos de cepillos, brochas o escobas, de madera; hormas, ensanchadores y tensores para el calzado, de madera"
 },
 {
  "CNKEY": "441800000080",
  "CN": "4418",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Obras y piezas de carpintería para construcciones, incluidos los tableros celulares, los tableros ensamblados para revestimiento de suelo y tablillas para cubierta de tejados o fachadas («singles» y «shakes»), de madera"
 },
 {
  "CNKEY": "441811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ventanas, puertas vidriera, y sus marcos y contramarcos"
 },
 {
  "CNKEY": "441811000080",
  "CN": "4418 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "441819000080",
  "CN": "4418 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441819500080",
  "CN": "4418 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De madera de coníferas"
 },
 {
  "CNKEY": "441819900080",
  "CN": "4418 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Puertas y sus marcos, contramarcos y umbrales"
 },
 {
  "CNKEY": "441821000080",
  "CN": "4418 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "441821100080",
  "CN": "4418 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las maderas tropicales citadas en la Nota complementaria 2 de este Capítulo"
 },
 {
  "CNKEY": "441821900080",
  "CN": "4418 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441829000080",
  "CN": "4418 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441829500080",
  "CN": "4418 29 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De madera de coníferas"
 },
 {
  "CNKEY": "441829800080",
  "CN": "4418 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441830000080",
  "CN": "4418 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Postes y vigas, distintos de los productos de las subpartidas  441881 a 441889"
 },
 {
  "CNKEY": "441840000080",
  "CN": "4418 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Encofrados para hormigón"
 },
 {
  "CNKEY": "441850000080",
  "CN": "4418 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tablillas para cubierta de tejados o fachadas («singles» y «shakes»)"
 },
 {
  "CNKEY": "441873000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tableros ensamblados para revestimiento de suelo"
 },
 {
  "CNKEY": "441873000080",
  "CN": "4418 73",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De bambú o que tengan, por lo menos, la capa superior de bambú"
 },
 {
  "CNKEY": "441873100080",
  "CN": "4418 73 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Para suelos en mosaico"
 },
 {
  "CNKEY": "441873900080",
  "CN": "4418 73 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás, multicapas"
 },
 {
  "CNKEY": "441874000080",
  "CN": "4418 74 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás, para suelos en mosaico"
 },
 {
  "CNKEY": "441875000080",
  "CN": "4418 75 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás, multicapas"
 },
 {
  "CNKEY": "441879000080",
  "CN": "4418 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441881000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Productos de madera de ingeniería estructural"
 },
 {
  "CNKEY": "441881000080",
  "CN": "4418 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Madera laminada-encolada (llamada «glulam»)"
 },
 {
  "CNKEY": "441882000080",
  "CN": "4418 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Madera laminada cruzada (contralaminada) (llamada «CLT» o «X-lam»)"
 },
 {
  "CNKEY": "441883000080",
  "CN": "4418 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vigas en I"
 },
 {
  "CNKEY": "441889000080",
  "CN": "4418 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "441891000080",
  "CN": "4418 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "441892000080",
  "CN": "4418 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tableros celulares de madera"
 },
 {
  "CNKEY": "441899000080",
  "CN": "4418 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441900000080",
  "CN": "4419",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de mesa o de cocina, de madera"
 },
 {
  "CNKEY": "441911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De bambú"
 },
 {
  "CNKEY": "441911000080",
  "CN": "4419 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tablas para pan, tablas para cortar y artículos similares"
 },
 {
  "CNKEY": "441912000080",
  "CN": "4419 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Palillos"
 },
 {
  "CNKEY": "441919000080",
  "CN": "4419 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "441920000080",
  "CN": "4419 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "441920100080",
  "CN": "4419 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las maderas tropicales citadas en la Nota complementaria 2 de este Capítulo"
 },
 {
  "CNKEY": "441920900080",
  "CN": "4419 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "441990000080",
  "CN": "4419 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "442000000080",
  "CN": "4420",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Marquetería y taracea; cofrecillos y estuches para joyería u orfebrería y manufacturas similares, de madera; estatuillas y demás objetos de adorno, de madera; artículos de mobiliario, de madera, no comprendidos en el Capítulo 94"
 },
 {
  "CNKEY": "442011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estatuillas y demás objetos de adorno"
 },
 {
  "CNKEY": "442011000080",
  "CN": "4420 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De maderas tropicales"
 },
 {
  "CNKEY": "442011100080",
  "CN": "4420 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las maderas tropicales citadas en la Nota complementaria 2 de este Capítulo"
 },
 {
  "CNKEY": "442011900080",
  "CN": "4420 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "442019000080",
  "CN": "4420 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "442090000080",
  "CN": "4420 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "442090100080",
  "CN": "4420 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m³",
  "ES": "Marquetería y taracea"
 },
 {
  "CNKEY": "442090910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "442090910080",
  "CN": "4420 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las maderas tropicales citadas en la Nota complementaria 2 de este Capítulo"
 },
 {
  "CNKEY": "442090990080",
  "CN": "4420 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "442100000080",
  "CN": "4421",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de madera"
 },
 {
  "CNKEY": "442110000080",
  "CN": "4421 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Perchas para prendas de vestir"
 },
 {
  "CNKEY": "442120000080",
  "CN": "4421 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Ataúdes"
 },
 {
  "CNKEY": "442191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "442191000080",
  "CN": "4421 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "442199000080",
  "CN": "4421 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "442199100080",
  "CN": "4421 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De tableros de fibras"
 },
 {
  "CNKEY": "442199990080",
  "CN": "4421 99 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "450021000090",
  "CN": "45",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 45 - CORCHO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "450100000080",
  "CN": "4501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Corcho natural en bruto o simplemente preparado; desperdicios de corcho; corcho triturado, granulado o pulverizado"
 },
 {
  "CNKEY": "450110000080",
  "CN": "4501 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Corcho natural en bruto o simplemente preparado"
 },
 {
  "CNKEY": "450190000080",
  "CN": "4501 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "450200000080",
  "CN": "4502 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Corcho natural, descortezado o simplemente escuadrado o en bloques, placas, hojas o tiras, cuadradas o rectangulares, incluidos los esbozos con aristas vivas para tapones"
 },
 {
  "CNKEY": "450300000080",
  "CN": "4503",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de corcho natural"
 },
 {
  "CNKEY": "450310000080",
  "CN": "4503 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tapones"
 },
 {
  "CNKEY": "450310100080",
  "CN": "4503 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cilíndricos"
 },
 {
  "CNKEY": "450310900080",
  "CN": "4503 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "450390000080",
  "CN": "4503 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "450400000080",
  "CN": "4504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Corcho aglomerado, incluso con aglutinante, y manufacturas de corcho aglomerado"
 },
 {
  "CNKEY": "450410000080",
  "CN": "4504 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bloques, placas, hojas y tiras; baldosas y revestimientos similares de pared, de cualquier forma; cilindros macizos, incluidos los discos"
 },
 {
  "CNKEY": "450410110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tapones"
 },
 {
  "CNKEY": "450410110080",
  "CN": "4504 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para vinos espumosos, incluso con discos de corcho natural"
 },
 {
  "CNKEY": "450410190080",
  "CN": "4504 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "450410910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "450410910080",
  "CN": "4504 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con aglutinante"
 },
 {
  "CNKEY": "450410990080",
  "CN": "4504 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "450490000080",
  "CN": "4504 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "450490200080",
  "CN": "4504 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tapones"
 },
 {
  "CNKEY": "450490800080",
  "CN": "4504 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460021000090",
  "CN": "46",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 46 - MANUFACTURAS DE ESPARTERÍA O CESTERÍA"
 },
 {
  "CNKEY": "460100000080",
  "CN": "4601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trenzas y artículos similares, de materia trenzable, incluso ensamblados en tiras; materia trenzable, trenzas y artículos similares de materia trenzable, tejidos o paralelizados, en forma plana, incluso terminados (por ejemplo: esterillas, esteras, cañizos)"
 },
 {
  "CNKEY": "460121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esterillas, esteras y cañizos, de materia vegetal"
 },
 {
  "CNKEY": "460121000080",
  "CN": "4601 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De bambú"
 },
 {
  "CNKEY": "460121100080",
  "CN": "4601 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460121900080",
  "CN": "4601 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460122000080",
  "CN": "4601 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De roten (ratán)"
 },
 {
  "CNKEY": "460122100080",
  "CN": "4601 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460122900080",
  "CN": "4601 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460129000080",
  "CN": "4601 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460129100080",
  "CN": "4601 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460129900080",
  "CN": "4601 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460192000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460192000080",
  "CN": "4601 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De bambú"
 },
 {
  "CNKEY": "460192050080",
  "CN": "4601 92 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trenzas y artículos similares, de materia trenzable, incluso ensamblados en tiras"
 },
 {
  "CNKEY": "460192100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460192100080",
  "CN": "4601 92 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460192900080",
  "CN": "4601 92 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460193000080",
  "CN": "4601 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De roten (ratán)"
 },
 {
  "CNKEY": "460193050080",
  "CN": "4601 93 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trenzas y artículos similares, de materia trenzable, incluso ensamblados en tiras"
 },
 {
  "CNKEY": "460193100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460193100080",
  "CN": "4601 93 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460193900080",
  "CN": "4601 93 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460194000080",
  "CN": "4601 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias vegetales"
 },
 {
  "CNKEY": "460194050080",
  "CN": "4601 94 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trenzas y artículos similares, de materia trenzable, incluso ensamblados en tiras"
 },
 {
  "CNKEY": "460194100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460194100080",
  "CN": "4601 94 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460194900080",
  "CN": "4601 94 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460199000080",
  "CN": "4601 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460199050080",
  "CN": "4601 99 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Trenzas y artículos similares, de materia trenzable, incluso ensamblados en tiras"
 },
 {
  "CNKEY": "460199100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460199100080",
  "CN": "4601 99 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Confeccionados con trenzas y artículos similares de materia trenzable"
 },
 {
  "CNKEY": "460199900080",
  "CN": "4601 99 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460200000080",
  "CN": "4602",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de cestería obtenidos directamente en su forma con materia trenzable o confeccionados con artículos de la partida 4601; manufacturas de esponja vegetal (paste o lufa)"
 },
 {
  "CNKEY": "460211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De materia vegetal"
 },
 {
  "CNKEY": "460211000080",
  "CN": "4602 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "460212000080",
  "CN": "4602 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De roten (ratán)"
 },
 {
  "CNKEY": "460219000080",
  "CN": "4602 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "460219100080",
  "CN": "4602 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fundas de paja para botellas de embalaje o de protección"
 },
 {
  "CNKEY": "460219900080",
  "CN": "4602 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "460290000080",
  "CN": "4602 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "470011000090",
  "CN": "X",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN X - PASTA DE MADERA O DE LAS DEMÁS MATERIAS FIBROSAS CELULÓSICAS; PAPEL O CARTÓN PARA RECICLAR (DESPERDICIOS Y DESECHOS); PAPEL O CARTÓN Y SUS APLICACIONES"
 },
 {
  "CNKEY": "470021000090",
  "CN": "47",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 47 - PASTA DE MADERA O DE LAS DEMÁS MATERIAS FIBROSAS CELULÓSICAS; PAPEL O CARTÓN PARA RECICLAR (DESPERDICIOS Y DESECHOS)"
 },
 {
  "CNKEY": "470100000080",
  "CN": "4701 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pasta mecánica de madera"
 },
 {
  "CNKEY": "470100100080",
  "CN": "4701 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Pasta termomecánica de madera"
 },
 {
  "CNKEY": "470100900080",
  "CN": "4701 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Las demás"
 },
 {
  "CNKEY": "470200000080",
  "CN": "4702 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Pasta química de madera para disolver"
 },
 {
  "CNKEY": "470300000080",
  "CN": "4703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pasta química de madera a la sosa (soda) o al sulfato (excepto la pasta para disolver)"
 },
 {
  "CNKEY": "470311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cruda"
 },
 {
  "CNKEY": "470311000080",
  "CN": "4703 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "470319000080",
  "CN": "4703 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "470321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semiblanqueada o blanqueada"
 },
 {
  "CNKEY": "470321000080",
  "CN": "4703 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "470329000080",
  "CN": "4703 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "470400000080",
  "CN": "4704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pasta química de madera al sulfito (excepto la pasta para disolver)"
 },
 {
  "CNKEY": "470411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cruda"
 },
 {
  "CNKEY": "470411000080",
  "CN": "4704 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "470419000080",
  "CN": "4704 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "470421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Semiblanqueada o blanqueada"
 },
 {
  "CNKEY": "470421000080",
  "CN": "4704 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "De coníferas"
 },
 {
  "CNKEY": "470429000080",
  "CN": "4704 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Distinta de la de coníferas"
 },
 {
  "CNKEY": "470500000080",
  "CN": "4705 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Pasta de madera obtenida por la combinación de tratamientos mecánico y químico"
 },
 {
  "CNKEY": "470600000080",
  "CN": "4706",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pasta de fibras obtenidas de papel o cartón reciclado (desperdicios y desechos) o de las demás materias fibrosas celulósicas"
 },
 {
  "CNKEY": "470610000080",
  "CN": "4706 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pasta de línter de algodón"
 },
 {
  "CNKEY": "470620000080",
  "CN": "4706 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Pasta de fibras obtenidas de papel o cartón reciclado (desperdicios y desechos)"
 },
 {
  "CNKEY": "470630000080",
  "CN": "4706 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Las demás, de bambú"
 },
 {
  "CNKEY": "470691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "470691000080",
  "CN": "4706 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Mecánicas"
 },
 {
  "CNKEY": "470692000080",
  "CN": "4706 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Químicas"
 },
 {
  "CNKEY": "470693000080",
  "CN": "4706 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "kg 90% sdt",
  "ES": "Obtenidas por la combinación de procedimientos mecánico y químico"
 },
 {
  "CNKEY": "470700000080",
  "CN": "4707",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel o cartón para reciclar (desperdicios y desechos)"
 },
 {
  "CNKEY": "470710000080",
  "CN": "4707 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel o cartón Kraft crudo o papel o cartón corrugado"
 },
 {
  "CNKEY": "470720000080",
  "CN": "4707 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás papeles o cartones obtenidos principalmente a partir de pasta química blanqueada sin colorear en la masa"
 },
 {
  "CNKEY": "470730000080",
  "CN": "4707 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel o cartón obtenido principalmente a partir de pasta mecánica (por ejemplo: diarios, periódicos e impresos similares)"
 },
 {
  "CNKEY": "470730100080",
  "CN": "4707 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Periódicos y revistas atrasados o sin vender, guías telefónicas, folletos e impresos publicitarios"
 },
 {
  "CNKEY": "470730900080",
  "CN": "4707 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "470790000080",
  "CN": "4707 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidos los desperdicios y desechos sin clasificar"
 },
 {
  "CNKEY": "470790100080",
  "CN": "4707 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin clasificar"
 },
 {
  "CNKEY": "470790900080",
  "CN": "4707 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Clasificados"
 },
 {
  "CNKEY": "480021000090",
  "CN": "48",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 48 - PAPEL Y CARTÓN; MANUFACTURAS DE PASTA DE CELULOSA, DE PAPEL O CARTÓN"
 },
 {
  "CNKEY": "480100000080",
  "CN": "4801 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Papel prensa en bobinas (rollos) o en hojas"
 },
 {
  "CNKEY": "480200000080",
  "CN": "4802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón, sin estucar ni recubrir, de los tipos utilizados para escribir, imprimir u otros fines gráficos y papel y cartón para tarjetas o cintas para perforar (sin perforar), en bobinas (rollos) o en hojas de forma cuadrada o rectangular, de cualquier tamaño, excepto el papel de las partidas 4801 o 4803; papel y cartón hechos a mano (hoja a hoja)"
 },
 {
  "CNKEY": "480210000080",
  "CN": "4802 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón hechos a mano (hoja a hoja)"
 },
 {
  "CNKEY": "480220000080",
  "CN": "4802 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón soporte para papel y cartón fotosensibles, termosensibles o electrosensibles"
 },
 {
  "CNKEY": "480240000080",
  "CN": "4802 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel soporte para papeles de decorar paredes"
 },
 {
  "CNKEY": "480240100080",
  "CN": "4802 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin fibras obtenidas por procedimiento mecánico o con un contenido total de estas fibras inferior o igual al 10 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480240900080",
  "CN": "4802 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480254000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones, sin fibras obtenidas por procedimiento mecánico o químico-mecánico o con un contenido total de estas fibras inferior o igual al 10 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480254000080",
  "CN": "4802 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peso inferior a 40 g\/m²"
 },
 {
  "CNKEY": "480255000080",
  "CN": "4802 55",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior o igual a 40 g\/m² pero inferior o igual a 150 g\/m², en bobinas (rollos)"
 },
 {
  "CNKEY": "480255150080",
  "CN": "4802 55 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 40 g\/m² pero inferior a 60 g\/m²"
 },
 {
  "CNKEY": "480255250080",
  "CN": "4802 55 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 60 g\/m² pero inferior a 75 g\/m²"
 },
 {
  "CNKEY": "480255300080",
  "CN": "4802 55 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 75 g\/m² pero inferior a 80 g\/m²"
 },
 {
  "CNKEY": "480255900080",
  "CN": "4802 55 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 80 g\/m²"
 },
 {
  "CNKEY": "480256000080",
  "CN": "4802 56",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior o igual a 40 g\/m² pero inferior o igual a 150 g\/m², en hojas en las que un lado sea inferior o igual a 435 mm y el otro sea inferior o igual a 297 mm, medidos sin plegar"
 },
 {
  "CNKEY": "480256200080",
  "CN": "4802 56 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En las que un lado mida 297 mm y el otro mida 210 mm (formato A4)"
 },
 {
  "CNKEY": "480256800080",
  "CN": "4802 56 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480257000080",
  "CN": "4802 57 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de peso superior o igual a 40 g\/m² pero inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "480258000080",
  "CN": "4802 58",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "480258100080",
  "CN": "4802 58 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos)"
 },
 {
  "CNKEY": "480258900080",
  "CN": "4802 58 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480261000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones, con un contenido total de fibras obtenidas por procedimiento mecánico o químico-mecánico superior al 10 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480261000080",
  "CN": "4802 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En bobinas (rollos)"
 },
 {
  "CNKEY": "480261150080",
  "CN": "4802 61 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De peso inferior a 72 g\/m², con un contenido total de fibras obtenidas por procedimiento mecánico superior al 50 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480261800080",
  "CN": "4802 61 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480262000080",
  "CN": "4802 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En hojas en las que un lado sea inferior o igual a 435 mm y el otro sea inferior o igual a 297 mm, medidos sin plegar"
 },
 {
  "CNKEY": "480269000080",
  "CN": "4802 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480300000080",
  "CN": "4803 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel del tipo utilizado para papel higiénico, toallitas para desmaquillar, toallas, servilletas o papeles similares de uso doméstico, de higiene o tocador, guata de celulosa y napa de fibras de celulosa, incluso rizados (crepés), plisados, gofrados, estampados, perforados, coloreados o decorados en la superficie o impresos, en bobinas (rollos) o en hojas"
 },
 {
  "CNKEY": "480300100080",
  "CN": "4803 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Guata de celulosa"
 },
 {
  "CNKEY": "480300310010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel rizado y napas de fibra de celulosa llamado tissue, de un peso por capa"
 },
 {
  "CNKEY": "480300310080",
  "CN": "4803 00 31",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Inferior o igual a 25 g\/m²"
 },
 {
  "CNKEY": "480300390080",
  "CN": "4803 00 39",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Superior a 25 g\/m²"
 },
 {
  "CNKEY": "480300900080",
  "CN": "4803 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480400000080",
  "CN": "4804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón Kraft, sin estucar ni recubrir, en bobinas (rollos) o en hojas (excepto el de las partidas 4802 o 4803)"
 },
 {
  "CNKEY": "480411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón para caras (cubiertas) (Kraftliner)"
 },
 {
  "CNKEY": "480411000080",
  "CN": "4804 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "480411110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480411110080",
  "CN": "4804 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De peso inferior a 150 g\/m²"
 },
 {
  "CNKEY": "480411150080",
  "CN": "4804 11 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 150 g\/m² pero inferior a 175 g\/m²"
 },
 {
  "CNKEY": "480411190080",
  "CN": "4804 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 175 g\/m²"
 },
 {
  "CNKEY": "480411900080",
  "CN": "4804 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480419000080",
  "CN": "4804 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480419120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480419120020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Compuestos de una o varias hojas crudas y una capa exterior blanqueada, semiblanqueada o coloreada en la masa, de peso"
 },
 {
  "CNKEY": "480419120080",
  "CN": "4804 19 12",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior a 175 g\/m²"
 },
 {
  "CNKEY": "480419190080",
  "CN": "4804 19 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 175 g\/m²"
 },
 {
  "CNKEY": "480419300080",
  "CN": "4804 19 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480419900080",
  "CN": "4804 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel Kraft para sacos (bolsas)"
 },
 {
  "CNKEY": "480421000080",
  "CN": "4804 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudo"
 },
 {
  "CNKEY": "480421100080",
  "CN": "4804 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480421900080",
  "CN": "4804 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480429000080",
  "CN": "4804 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480429100080",
  "CN": "4804 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480429900080",
  "CN": "4804 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones Kraft, de peso inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "480431000080",
  "CN": "4804 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "480431510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480431510080",
  "CN": "4804 31 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Que sirvan de aislantes para usos electrotécnicos"
 },
 {
  "CNKEY": "480431580080",
  "CN": "4804 31 58",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480431800080",
  "CN": "4804 31 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480439000080",
  "CN": "4804 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480439510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480439510080",
  "CN": "4804 39 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Blanqueados uniformemente en la masa"
 },
 {
  "CNKEY": "480439580080",
  "CN": "4804 39 58",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480439800080",
  "CN": "4804 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones Kraft, de peso superior a 150 g\/m² pero inferior a 225 g\/m²"
 },
 {
  "CNKEY": "480441000080",
  "CN": "4804 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "480441910080",
  "CN": "4804 41 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Papeles y cartones llamados saturating kraft"
 },
 {
  "CNKEY": "480441980080",
  "CN": "4804 41 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480442000080",
  "CN": "4804 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Blanqueados uniformemente en la masa y con un contenido de fibras de madera obtenidas por procedimiento químico superior al 95 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480449000080",
  "CN": "4804 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones Kraft, de peso superior o igual a 225 g\/m²"
 },
 {
  "CNKEY": "480451000080",
  "CN": "4804 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "480452000080",
  "CN": "4804 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Blanqueados uniformemente en la masa y con un contenido de fibras de madera obtenidas por procedimiento químico superior al 95 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480459000080",
  "CN": "4804 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480459100080",
  "CN": "4804 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de fibras de coníferas obtenidas por procedimiento químico al sulfato o a la sosa (soda) superior o igual al 80 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "480459900080",
  "CN": "4804 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480500000080",
  "CN": "4805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones, sin estucar ni recubrir, en bobinas (rollos) o en hojas, que no hayan sido sometidos a trabajos complementarios o tratamientos distintos de los especificados en la Nota 3 de este Capítulo"
 },
 {
  "CNKEY": "480511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel para acanalar"
 },
 {
  "CNKEY": "480511000080",
  "CN": "4805 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papel semiquímico para acanalar"
 },
 {
  "CNKEY": "480512000080",
  "CN": "4805 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papel paja para acanalar"
 },
 {
  "CNKEY": "480519000080",
  "CN": "4805 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480519100080",
  "CN": "4805 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Wellenstoff"
 },
 {
  "CNKEY": "480519900080",
  "CN": "4805 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480524000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Testliner"
 },
 {
  "CNKEY": "480524000080",
  "CN": "4805 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peso inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "480525000080",
  "CN": "4805 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "480530000080",
  "CN": "4805 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel sulfito para envolver"
 },
 {
  "CNKEY": "480540000080",
  "CN": "4805 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón filtro"
 },
 {
  "CNKEY": "480550000080",
  "CN": "4805 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón fieltro; papel y cartón lana"
 },
 {
  "CNKEY": "480591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "480591000080",
  "CN": "4805 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peso inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "480592000080",
  "CN": "4805 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De peso superior a 150 g\/m² pero inferior a 225 g\/m²"
 },
 {
  "CNKEY": "480593000080",
  "CN": "4805 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior o igual a 225 g\/m²"
 },
 {
  "CNKEY": "480593200080",
  "CN": "4805 93 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "A base de papel reciclado"
 },
 {
  "CNKEY": "480593800080",
  "CN": "4805 93 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480600000080",
  "CN": "4806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón sulfurizados, papel resistente a las grasas, papel vegetal, papel cristal y demás papeles calandrados transparentes o traslúcidos, en bobinas (rollos) o en hojas"
 },
 {
  "CNKEY": "480610000080",
  "CN": "4806 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón sulfurizados (pergamino vegetal)"
 },
 {
  "CNKEY": "480620000080",
  "CN": "4806 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel resistente a las grasas (greaseproof)"
 },
 {
  "CNKEY": "480630000080",
  "CN": "4806 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel vegetal (papel calco)"
 },
 {
  "CNKEY": "480640000080",
  "CN": "4806 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel cristal y demás papeles calandrados transparentes o traslúcidos"
 },
 {
  "CNKEY": "480640100080",
  "CN": "4806 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papel cristal"
 },
 {
  "CNKEY": "480640900080",
  "CN": "4806 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480700000080",
  "CN": "4807 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón obtenidos por pegado de hojas planas, sin estucar ni recubrir en la superficie y sin impregnar, incluso reforzados interiormente, en bobinas (rollos) o en hojas"
 },
 {
  "CNKEY": "480700300080",
  "CN": "4807 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "A base de papel reciclado, incluso revestidos con papel"
 },
 {
  "CNKEY": "480700800080",
  "CN": "4807 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480800000080",
  "CN": "4808",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón corrugados, incluso revestidos por encolado, rizados (crepés), plisados, gofrados, estampados o perforados, en bobinas (rollos) o en hojas (excepto el papel de los tipos descritos en el texto de la partida 4803)"
 },
 {
  "CNKEY": "480810000080",
  "CN": "4808 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón corrugados, incluso perforados"
 },
 {
  "CNKEY": "480840000080",
  "CN": "4808 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel Kraft rizado («crepé») o plisado, incluso gofrado, estampado o perforado"
 },
 {
  "CNKEY": "480890000080",
  "CN": "4808 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "480900000080",
  "CN": "4809",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel carbón (carbónico), papel autocopia y demás papeles para copiar o transferir, incluido el estucado o cuché, recubierto o impregnado, para clisés de mimeógrafo (stencils) o para planchas offset, incluso impresos, en bobinas (rollos) o en hojas"
 },
 {
  "CNKEY": "480920000080",
  "CN": "4809 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel autocopia"
 },
 {
  "CNKEY": "480990000080",
  "CN": "4809 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481000000080",
  "CN": "4810",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel y cartón estucados por una o las dos caras con caolín u otras sustancias inorgánicas, con aglutinante o sin él, con exclusión de cualquier otro estucado o recubrimiento, incluso coloreados o decorados en la superficie o impresos, en bobinas (rollos) o en hojas de forma cuadrada o rectangular, de cualquier tamaño"
 },
 {
  "CNKEY": "481013000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón de los tipos utilizados para escribir, imprimir u otros fines gráficos, sin fibras obtenidas por procedimiento mecánico o químico-mecánico o con un contenido total de estas fibras inferior o igual al 10 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "481013000080",
  "CN": "4810 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos)"
 },
 {
  "CNKEY": "481014000080",
  "CN": "4810 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En hojas en las que un lado sea inferior o igual a 435 mm y el otro sea inferior o igual a 297 mm, medidos sin plegar"
 },
 {
  "CNKEY": "481019000080",
  "CN": "4810 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481022000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón de los tipos utilizados para escribir, imprimir u otros fines gráficos, con un contenido total de fibras obtenidas por procedimiento mecánico o químico-mecánico superior al 10 % en peso del contenido total de fibra"
 },
 {
  "CNKEY": "481022000080",
  "CN": "4810 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papel estucado o cuché ligero (liviano) (L.W.C.)"
 },
 {
  "CNKEY": "481029000080",
  "CN": "4810 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "481029300080",
  "CN": "4810 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos)"
 },
 {
  "CNKEY": "481029800080",
  "CN": "4810 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón Kraft (excepto los de los tipos utilizados para escribir, imprimir u otros fines gráficos)"
 },
 {
  "CNKEY": "481031000080",
  "CN": "4810 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Blanqueados uniformemente en la masa y con un contenido de fibras de madera obtenidas por procedimiento químico superior al 95 % en peso del contenido total de fibra, de peso inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "481032000080",
  "CN": "4810 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Blanqueados uniformemente en la masa y con un contenido de fibras de madera obtenidas por procedimiento químico superior al 95 % en peso del contenido total de fibra, de peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "481032100080",
  "CN": "4810 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Estucado o recubierto de caolín"
 },
 {
  "CNKEY": "481032900080",
  "CN": "4810 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481039000080",
  "CN": "4810 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481092000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás papeles y cartones"
 },
 {
  "CNKEY": "481092000080",
  "CN": "4810 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Multicapas"
 },
 {
  "CNKEY": "481092100080",
  "CN": "4810 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con todas las capas blanqueadas"
 },
 {
  "CNKEY": "481092300080",
  "CN": "4810 92 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con una sola capa exterior blanqueada"
 },
 {
  "CNKEY": "481092900080",
  "CN": "4810 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481099000080",
  "CN": "4810 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "481099100080",
  "CN": "4810 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De pasta blanqueada, estucados o recubiertos de caolín"
 },
 {
  "CNKEY": "481099800080",
  "CN": "4810 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481100000080",
  "CN": "4811",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel, cartón, guata de celulosa y napa de fibras de celulosa, estucados, recubiertos, impregnados o revestidos, coloreados o decorados en la superficie o impresos, en bobinas (rollos) o en hojas de forma cuadrada o rectangular, de cualquier tamaño, excepto los productos de los tipos descritos en el texto de las partidas 4803, 4809 o 4810"
 },
 {
  "CNKEY": "481110000080",
  "CN": "4811 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón alquitranados, embetunados o asfaltados"
 },
 {
  "CNKEY": "481141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón engomados o adhesivos"
 },
 {
  "CNKEY": "481141000080",
  "CN": "4811 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Autoadhesivos"
 },
 {
  "CNKEY": "481141200080",
  "CN": "4811 41 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De anchura inferior o igual a 10 cm, con recubrimiento de caucho natural o sintético sin vulcanizar"
 },
 {
  "CNKEY": "481141900080",
  "CN": "4811 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481149000080",
  "CN": "4811 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel y cartón recubiertos, impregnados o revestidos de plástico (excepto los adhesivos)"
 },
 {
  "CNKEY": "481151000080",
  "CN": "4811 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Blanqueados, de peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "481159000080",
  "CN": "4811 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481160000080",
  "CN": "4811 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón recubiertos, impregnados o revestidos de cera, parafina, estearina, aceite o glicerol"
 },
 {
  "CNKEY": "481190000080",
  "CN": "4811 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás papeles, cartones, guata de celulosa y napa de fibras de celulosa"
 },
 {
  "CNKEY": "481200000080",
  "CN": "4812 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Bloques y placas, filtrantes, de pasta de papel"
 },
 {
  "CNKEY": "481300000080",
  "CN": "4813",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel de fumar, incluso cortado al tamaño adecuado, en librillos o en tubos"
 },
 {
  "CNKEY": "481310000080",
  "CN": "4813 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En librillos o en tubos"
 },
 {
  "CNKEY": "481320000080",
  "CN": "4813 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos) de anchura inferior o igual a 5 cm"
 },
 {
  "CNKEY": "481390000080",
  "CN": "4813 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "481390100080",
  "CN": "4813 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos) de anchura superior a 5 cm pero inferior o igual a 15 cm"
 },
 {
  "CNKEY": "481390900080",
  "CN": "4813 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481400000080",
  "CN": "4814",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel para decorar y revestimientos similares de paredes; papel para vidrieras"
 },
 {
  "CNKEY": "481420000080",
  "CN": "4814 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel para decorar y revestimientos similares de paredes, constituidos por papel recubierto o revestido, en la cara vista, con una capa de plástico graneada, gofrada, coloreada, impresa con motivos o decorada de otro modo"
 },
 {
  "CNKEY": "481490000080",
  "CN": "4814 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "481490100080",
  "CN": "4814 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papel para decorar y revestimientos similares de paredes constituidos por papel graneado, gofrado, coloreado en la superficie, impreso con motivos o decorado de otro modo, en la superficie, y recubierto o revestido de plástico protector transparente"
 },
 {
  "CNKEY": "481490700080",
  "CN": "4814 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481600000080",
  "CN": "4816",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel carbón (carbónico), papel autocopia y demás papeles para copiar o transferir (excepto los de la partida 4809), clisés de mimeógrafo («stencils») completos y planchas offset, de papel, incluso acondicionados en cajas"
 },
 {
  "CNKEY": "481620000080",
  "CN": "4816 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel autocopia"
 },
 {
  "CNKEY": "481690000080",
  "CN": "4816 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481700000080",
  "CN": "4817",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sobres, sobres carta, tarjetas postales sin ilustrar y tarjetas para correspondencia, de papel o cartón; cajas, bolsas y presentaciones similares, de papel o cartón, con un surtido de artículos de correspondencia"
 },
 {
  "CNKEY": "481710000080",
  "CN": "4817 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sobres"
 },
 {
  "CNKEY": "481720000080",
  "CN": "4817 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sobres carta, tarjetas postales sin ilustrar y tarjetas para correspondencia"
 },
 {
  "CNKEY": "481730000080",
  "CN": "4817 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas, bolsas y presentaciones similares de papel o cartón, con un surtido de artículos de correspondencia"
 },
 {
  "CNKEY": "481800000080",
  "CN": "4818",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Papel del tipo utilizado para papel higiénico y papeles similares, guata de celulosa o napa de fibras de celulosa, de los tipos utilizados para fines domésticos o sanitarios, en bobinas (rollos) de una anchura inferior o igual a 36 cm o cortados en formato; pañuelos, toallitas de desmaquillar, toallas, manteles, servilletas, sábanas y artículos similares para uso doméstico, de tocador, higiénico o de hospital, prendas y complementos (accesorios), de vestir, de pasta de papel, papel, guata de celulosa o napa de fibras de celulosa"
 },
 {
  "CNKEY": "481810000080",
  "CN": "4818 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Papel higiénico"
 },
 {
  "CNKEY": "481810100080",
  "CN": "4818 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De un peso por capa inferior o igual a 25 g\/m²"
 },
 {
  "CNKEY": "481810900080",
  "CN": "4818 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De un peso por capa superior a 25 g\/m²"
 },
 {
  "CNKEY": "481820000080",
  "CN": "4818 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pañuelos, toallitas de desmaquillar y toallas"
 },
 {
  "CNKEY": "481820100080",
  "CN": "4818 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pañuelos y toallitas de desmaquillaje"
 },
 {
  "CNKEY": "481820910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Toallas"
 },
 {
  "CNKEY": "481820910080",
  "CN": "4818 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos)"
 },
 {
  "CNKEY": "481820990080",
  "CN": "4818 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "481830000080",
  "CN": "4818 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Manteles y servilletas"
 },
 {
  "CNKEY": "481850000080",
  "CN": "4818 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Prendas y complementos (accesorios), de vestir"
 },
 {
  "CNKEY": "481890000080",
  "CN": "4818 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "481890100080",
  "CN": "4818 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos para uso quirúrgico, médico o higiénico, sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "481890900080",
  "CN": "4818 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "481900000080",
  "CN": "4819",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas, sacos (bolsas), bolsitas, cucuruchos y demás envases de papel, cartón, guata de celulosa o napa de fibras de celulosa; cartonajes de oficina, tienda o similares"
 },
 {
  "CNKEY": "481910000080",
  "CN": "4819 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas de papel o cartón corrugado"
 },
 {
  "CNKEY": "481920000080",
  "CN": "4819 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas y cartonajes, plegables, de papel o cartón, sin corrugar"
 },
 {
  "CNKEY": "481930000080",
  "CN": "4819 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sacos (bolsas) con una anchura en la base superior o igual a 40 cm"
 },
 {
  "CNKEY": "481940000080",
  "CN": "4819 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás sacos (bolsas); bolsitas y cucuruchos"
 },
 {
  "CNKEY": "481950000080",
  "CN": "4819 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás envases, incluidas las fundas para discos"
 },
 {
  "CNKEY": "481960000080",
  "CN": "4819 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cartonajes de oficina, tienda o similares"
 },
 {
  "CNKEY": "482000000080",
  "CN": "4820",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Libros registro, libros de contabilidad, talonarios (de notas, pedidos o recibos), agendas, bloques, memorandos, bloques de papel de cartas y artículos similares, cuadernos, carpetas de mesa, clasificadores, encuadernaciones (de hojas móviles u otras), carpetas y cubiertas para documentos y demás artículos escolares, de oficina o de papelería, incluso los formularios en paquetes o plegados («manifold»), aunque lleven papel carbón (carbónico), de papel o cartón; álbumes para muestras o para colecciones y cubiertas para libros, de papel o cartón"
 },
 {
  "CNKEY": "482010000080",
  "CN": "4820 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Libros registro, libros de contabilidad, talonarios (de notas, pedidos o recibos), bloques memorandos, bloques de papel de cartas, agendas y artículos similares"
 },
 {
  "CNKEY": "482010100080",
  "CN": "4820 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Libros registro, libros de contabilidad, talonarios de pedidos o de recibos"
 },
 {
  "CNKEY": "482010300080",
  "CN": "4820 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Talonarios de notas, bloques de papel de cartas y bloques memorandos"
 },
 {
  "CNKEY": "482010500080",
  "CN": "4820 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agendas"
 },
 {
  "CNKEY": "482010900080",
  "CN": "4820 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "482020000080",
  "CN": "4820 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuadernos"
 },
 {
  "CNKEY": "482030000080",
  "CN": "4820 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Clasificadores, encuadernaciones (excepto las cubiertas para libros), carpetas y cubiertas para documentos"
 },
 {
  "CNKEY": "482040000080",
  "CN": "4820 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Formularios en paquetes o plegados («manifold»), aunque lleven papel carbón (carbónico)"
 },
 {
  "CNKEY": "482050000080",
  "CN": "4820 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Álbumes para muestras o para colecciones"
 },
 {
  "CNKEY": "482090000080",
  "CN": "4820 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "482100000080",
  "CN": "4821",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Etiquetas de todas clases, de papel o cartón, incluso impresas"
 },
 {
  "CNKEY": "482110000080",
  "CN": "4821 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Impresas"
 },
 {
  "CNKEY": "482110100080",
  "CN": "4821 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Autoadhesivas"
 },
 {
  "CNKEY": "482110900080",
  "CN": "4821 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "482190000080",
  "CN": "4821 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "482190100080",
  "CN": "4821 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Autoadhesivas"
 },
 {
  "CNKEY": "482190900080",
  "CN": "4821 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "482200000080",
  "CN": "4822",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carretes, bobinas, canillas y soportes similares, de pasta de papel, papel o cartón, incluso perforados o endurecidos"
 },
 {
  "CNKEY": "482210000080",
  "CN": "4822 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados para el bobinado de hilados textiles"
 },
 {
  "CNKEY": "482290000080",
  "CN": "4822 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "482300000080",
  "CN": "4823",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás papeles, cartones, guata de celulosa y napa de fibras de celulosa, cortados en formato; los demás artículos de pasta de papel, papel, cartón, guata de celulosa o napa de fibras de celulosa"
 },
 {
  "CNKEY": "482320000080",
  "CN": "4823 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel y cartón filtro"
 },
 {
  "CNKEY": "482340000080",
  "CN": "4823 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Papel diagrama para aparatos registradores, en bobinas (rollos), hojas o discos"
 },
 {
  "CNKEY": "482361000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bandejas, fuentes, platos, tazas, vasos y artículos similares, de papel o cartón"
 },
 {
  "CNKEY": "482361000080",
  "CN": "4823 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "482369000080",
  "CN": "4823 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "482369100080",
  "CN": "4823 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Bandejas, fuentes y platos"
 },
 {
  "CNKEY": "482369900080",
  "CN": "4823 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "482370000080",
  "CN": "4823 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos moldeados o prensados, de pasta de papel"
 },
 {
  "CNKEY": "482370100080",
  "CN": "4823 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Envases alveolares para huevos"
 },
 {
  "CNKEY": "482370900080",
  "CN": "4823 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "482390000080",
  "CN": "4823 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "482390400080",
  "CN": "4823 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Papeles y cartones de los tipos utilizados en la escritura, impresión u otros fines gráficos"
 },
 {
  "CNKEY": "482390850080",
  "CN": "4823 90 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "490021000090",
  "CN": "49",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 49 - PRODUCTOS EDITORIALES, DE LA PRENSA Y DE LAS DEMÁS INDUSTRIAS GRÁFICAS; TEXTOS MANUSCRITOS O MECANOGRAFIADOS Y PLANOS"
 },
 {
  "CNKEY": "490100000080",
  "CN": "4901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Libros, folletos e impresos similares, incluso en hojas sueltas"
 },
 {
  "CNKEY": "490110000080",
  "CN": "4901 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En hojas sueltas, incluso plegadas"
 },
 {
  "CNKEY": "490191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "490191000080",
  "CN": "4901 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diccionarios y enciclopedias, incluso en fascículos"
 },
 {
  "CNKEY": "490199000080",
  "CN": "4901 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "490200000080",
  "CN": "4902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Diarios y publicaciones periódicas, impresos, incluso ilustrados o con publicidad"
 },
 {
  "CNKEY": "490210000080",
  "CN": "4902 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que se publiquen cuatro veces por semana como mínimo"
 },
 {
  "CNKEY": "490290000080",
  "CN": "4902 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "490300000080",
  "CN": "4903 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Álbumes o libros de estampas y cuadernos para dibujar o colorear, para niños"
 },
 {
  "CNKEY": "490400000080",
  "CN": "4904 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Música manuscrita o impresa, incluso con ilustraciones o encuadernada"
 },
 {
  "CNKEY": "490500000080",
  "CN": "4905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas cartográficas de todas clases, incluidos los mapas murales, planos topográficos y esferas, impresos"
 },
 {
  "CNKEY": "490520000080",
  "CN": "4905 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En forma de libros o folletos"
 },
 {
  "CNKEY": "490590000080",
  "CN": "4905 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "490600000080",
  "CN": "4906 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Planos y dibujos originales hechos a mano, de arquitectura, ingeniería, industriales, comerciales, topográficos o similares; textos manuscritos; reproducciones fotográficas sobre papel sensibilizado y copias con papel carbón (carbónico), de los planos, dibujos o textos antes mencionados"
 },
 {
  "CNKEY": "490700000080",
  "CN": "4907 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sellos (estampillas) de correos, timbres fiscales y análogos, sin obliterar, que tengan o estén destinados a tener curso legal en el país en el que su valor facial sea reconocido; papel timbrado; billetes de banco; cheques; títulos de acciones u obligaciones y títulos similares"
 },
 {
  "CNKEY": "490700100080",
  "CN": "4907 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sellos (estampillas) de correos, timbres fiscales y análogos"
 },
 {
  "CNKEY": "490700300080",
  "CN": "4907 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Billetes de banco"
 },
 {
  "CNKEY": "490700900080",
  "CN": "4907 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "490800000080",
  "CN": "4908",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calcomanías de cualquier clase"
 },
 {
  "CNKEY": "490810000080",
  "CN": "4908 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Calcomanías vitrificables"
 },
 {
  "CNKEY": "490890000080",
  "CN": "4908 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "490900000080",
  "CN": "4909 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tarjetas postales impresas o ilustradas; tarjetas impresas con felicitaciones o comunicaciones personales, incluso con ilustraciones, adornos o aplicaciones, o con sobres"
 },
 {
  "CNKEY": "491000000080",
  "CN": "4910 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Calendarios de cualquier clase impresos, incluidos los tacos de calendario"
 },
 {
  "CNKEY": "491100000080",
  "CN": "4911",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás impresos, incluidas las estampas, grabados y fotografías"
 },
 {
  "CNKEY": "491110000080",
  "CN": "4911 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Impresos publicitarios, catálogos comerciales y similares"
 },
 {
  "CNKEY": "491110100080",
  "CN": "4911 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Catálogos comerciales"
 },
 {
  "CNKEY": "491110900080",
  "CN": "4911 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "491191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "491191000080",
  "CN": "4911 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampas, grabados y fotografías"
 },
 {
  "CNKEY": "491199000080",
  "CN": "4911 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500011000090",
  "CN": "XI",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XI - MATERIAS TEXTILES Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "500021000090",
  "CN": "50",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 50 - SEDA"
 },
 {
  "CNKEY": "500100000080",
  "CN": "5001 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Capullos de seda aptos para el devanado"
 },
 {
  "CNKEY": "500200000080",
  "CN": "5002 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Seda cruda (sin torcer)"
 },
 {
  "CNKEY": "500300000080",
  "CN": "5003 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desperdicios de seda, incluidos los capullos no aptos para el devanado, desperdicios de hilados e hilachas"
 },
 {
  "CNKEY": "500400000080",
  "CN": "5004 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de seda (excepto los hilados de desperdicios de seda) sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "500400100080",
  "CN": "5004 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Crudos, descrudados o blanqueados"
 },
 {
  "CNKEY": "500400900080",
  "CN": "5004 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500500000080",
  "CN": "5005 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de desperdicios de seda sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "500500100080",
  "CN": "5005 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Crudos, descrudados o blanqueados"
 },
 {
  "CNKEY": "500500900080",
  "CN": "5005 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500600000080",
  "CN": "5006 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de seda o de desperdicios de seda, acondicionados para la venta al por menor; «pelo de Mesina» («crin de Florencia»)"
 },
 {
  "CNKEY": "500600100080",
  "CN": "5006 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados de seda"
 },
 {
  "CNKEY": "500600900080",
  "CN": "5006 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados de desperdicios de seda; «pelo de Mesina» («crin de Florencia»)"
 },
 {
  "CNKEY": "500700000080",
  "CN": "5007",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de seda o de desperdicios de seda"
 },
 {
  "CNKEY": "500710000080",
  "CN": "5007 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos de borrilla"
 },
 {
  "CNKEY": "500720000080",
  "CN": "5007 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de seda o de desperdicios de seda, distintos de la borrilla, superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "500720110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crespones"
 },
 {
  "CNKEY": "500720110080",
  "CN": "5007 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos, descrudados o blanqueados"
 },
 {
  "CNKEY": "500720190080",
  "CN": "5007 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pongé, habutaí, honan, shangtung, corah y tejidos similares de Extremo Oriente, de seda pura (sin mezclar con borra de seda, borrilla ni demás materias textiles)"
 },
 {
  "CNKEY": "500720210080",
  "CN": "5007 20 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De ligamento de tafetán, crudos o simplemente descrudados"
 },
 {
  "CNKEY": "500720310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720310080",
  "CN": "5007 20 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "500720390080",
  "CN": "5007 20 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720410080",
  "CN": "5007 20 41",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Tejidos diáfanos (no densos)"
 },
 {
  "CNKEY": "500720510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720510080",
  "CN": "5007 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Crudos, descrudados o blanqueados"
 },
 {
  "CNKEY": "500720590080",
  "CN": "5007 20 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "500720610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Fabricados con hilos de distintos colores"
 },
 {
  "CNKEY": "500720610080",
  "CN": "5007 20 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 57 cm pero inferior o igual a 75 cm"
 },
 {
  "CNKEY": "500720690080",
  "CN": "5007 20 69",
  "CN_LEVEL": 8,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "500720710080",
  "CN": "5007 20 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "500790000080",
  "CN": "5007 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "500790100080",
  "CN": "5007 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos, descrudados o blanqueados"
 },
 {
  "CNKEY": "500790300080",
  "CN": "5007 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "500790500080",
  "CN": "5007 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Fabricados con hilos de distintos colores"
 },
 {
  "CNKEY": "500790900080",
  "CN": "5007 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "510021000090",
  "CN": "51",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 51 - LANA Y PELO FINO U ORDINARIO; HILADOS Y TEJIDOS DE CRIN"
 },
 {
  "CNKEY": "510100000080",
  "CN": "5101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lana sin cardar ni peinar"
 },
 {
  "CNKEY": "510111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lana sucia, incluida la lavada en vivo"
 },
 {
  "CNKEY": "510111000080",
  "CN": "5101 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lana esquilada"
 },
 {
  "CNKEY": "510119000080",
  "CN": "5101 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "510121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desgrasada, sin carbonizar"
 },
 {
  "CNKEY": "510121000080",
  "CN": "5101 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lana esquilada"
 },
 {
  "CNKEY": "510129000080",
  "CN": "5101 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "510130000080",
  "CN": "5101 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carbonizada"
 },
 {
  "CNKEY": "510200000080",
  "CN": "5102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pelo fino u ordinario, sin cardar ni peinar"
 },
 {
  "CNKEY": "510211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pelo fino"
 },
 {
  "CNKEY": "510211000080",
  "CN": "5102 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cabra de Cachemira"
 },
 {
  "CNKEY": "510219000080",
  "CN": "5102 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "510219100080",
  "CN": "5102 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De conejo de Angora"
 },
 {
  "CNKEY": "510219300080",
  "CN": "5102 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De alpaca, de llama, de vicuña"
 },
 {
  "CNKEY": "510219400080",
  "CN": "5102 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De camello, de dromedario, de yac, de cabra de Angora («mohair»), de cabra del Tíbet y de cabras similares"
 },
 {
  "CNKEY": "510219900080",
  "CN": "5102 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De conejo distinto del de Angora, de liebre, de castor, de coipo y de rata almizclera"
 },
 {
  "CNKEY": "510220000080",
  "CN": "5102 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pelo ordinario"
 },
 {
  "CNKEY": "510300000080",
  "CN": "5103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios de lana o de pelo fino u ordinario, incluidos los desperdicios de hilados (excepto las hilachas)"
 },
 {
  "CNKEY": "510310000080",
  "CN": "5103 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Borras del peinado de lana o pelo fino"
 },
 {
  "CNKEY": "510310100080",
  "CN": "5103 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin carbonizar"
 },
 {
  "CNKEY": "510310900080",
  "CN": "5103 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carbonizadas"
 },
 {
  "CNKEY": "510320000080",
  "CN": "5103 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás desperdicios de lana o pelo fino"
 },
 {
  "CNKEY": "510330000080",
  "CN": "5103 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios de pelo ordinario"
 },
 {
  "CNKEY": "510400000080",
  "CN": "5104 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Hilachas de lana o pelo fino u ordinario"
 },
 {
  "CNKEY": "510500000080",
  "CN": "5105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lana y pelo fino u ordinario, cardados o peinados (incluida la «lana peinada a granel»)"
 },
 {
  "CNKEY": "510510000080",
  "CN": "5105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lana cardada"
 },
 {
  "CNKEY": "510521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lana peinada"
 },
 {
  "CNKEY": "510521000080",
  "CN": "5105 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "«Lana peinada a granel»"
 },
 {
  "CNKEY": "510529000080",
  "CN": "5105 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "510531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pelo fino cardado o peinado"
 },
 {
  "CNKEY": "510531000080",
  "CN": "5105 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cabra de Cachemira"
 },
 {
  "CNKEY": "510539000080",
  "CN": "5105 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510540000080",
  "CN": "5105 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pelo ordinario cardado o peinado"
 },
 {
  "CNKEY": "510600000080",
  "CN": "5106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de lana cardada sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "510610000080",
  "CN": "5106 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "510610100080",
  "CN": "5106 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510610900080",
  "CN": "5106 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510620000080",
  "CN": "5106 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana inferior al 85 % en peso"
 },
 {
  "CNKEY": "510620100080",
  "CN": "5106 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de lana y pelo fino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "510620910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "510620910080",
  "CN": "5106 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510620990080",
  "CN": "5106 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510700000080",
  "CN": "5107",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de lana peinada sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "510710000080",
  "CN": "5107 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "510710100080",
  "CN": "5107 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510710900080",
  "CN": "5107 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510720000080",
  "CN": "5107 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana inferior al 85 % en peso"
 },
 {
  "CNKEY": "510720100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana y pelo fino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "510720100080",
  "CN": "5107 20 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510720300080",
  "CN": "5107 20 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510720510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "510720510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezclados única o principalmente con fibras sintéticas discontinuas"
 },
 {
  "CNKEY": "510720510080",
  "CN": "5107 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510720590080",
  "CN": "5107 20 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510720910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "510720910080",
  "CN": "5107 20 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510720990080",
  "CN": "5107 20 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510800000080",
  "CN": "5108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de pelo fino cardado o peinado, sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "510810000080",
  "CN": "5108 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cardado"
 },
 {
  "CNKEY": "510810100080",
  "CN": "5108 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510810900080",
  "CN": "5108 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510820000080",
  "CN": "5108 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peinado"
 },
 {
  "CNKEY": "510820100080",
  "CN": "5108 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos"
 },
 {
  "CNKEY": "510820900080",
  "CN": "5108 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510900000080",
  "CN": "5109",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de lana o pelo fino, acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "510910000080",
  "CN": "5109 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana o pelo fino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "510910100080",
  "CN": "5109 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bolas, ovillos, madejas o madejitas, con un peso superior a 125 g pero inferior o igual a 500 g"
 },
 {
  "CNKEY": "510910900080",
  "CN": "5109 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "510990000080",
  "CN": "5109 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "511000000080",
  "CN": "5110 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Hilados de pelo ordinario o de crin (incluidos los hilados de crin entorchados), aunque estén acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "511100000080",
  "CN": "5111",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de lana cardada o pelo fino cardado"
 },
 {
  "CNKEY": "511111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana o pelo fino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "511111000080",
  "CN": "5111 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 300 g\/m²"
 },
 {
  "CNKEY": "511119000080",
  "CN": "5111 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "511120000080",
  "CN": "5111 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás, mezclados exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "511130000080",
  "CN": "5111 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, mezclados exclusiva o principalmente con fibras sintéticas o artificiales discontinuas"
 },
 {
  "CNKEY": "511130100080",
  "CN": "5111 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 300 g\/m²"
 },
 {
  "CNKEY": "511130800080",
  "CN": "5111 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso superior a 300 g\/m²"
 },
 {
  "CNKEY": "511190000080",
  "CN": "5111 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "511190100080",
  "CN": "5111 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un contenido total de materias textiles del Capítulo 50 superior al 10 % en peso"
 },
 {
  "CNKEY": "511190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "511190910080",
  "CN": "5111 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 300 g\/m²"
 },
 {
  "CNKEY": "511190980080",
  "CN": "5111 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De peso superior a 300 g\/m²"
 },
 {
  "CNKEY": "511200000080",
  "CN": "5112",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de lana peinada o pelo fino peinado"
 },
 {
  "CNKEY": "511211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lana o pelo fino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "511211000080",
  "CN": "5112 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "511219000080",
  "CN": "5112 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "511220000080",
  "CN": "5112 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás, mezclados exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "511230000080",
  "CN": "5112 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, mezclados exclusiva o principalmente con fibras sintéticas o artificiales discontinuas"
 },
 {
  "CNKEY": "511230100080",
  "CN": "5112 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "511230800080",
  "CN": "5112 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De peso superior a 200 g\/m²"
 },
 {
  "CNKEY": "511290000080",
  "CN": "5112 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "511290100080",
  "CN": "5112 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un contenido total de materias textiles del Capítulo 50 superior al 10 % en peso"
 },
 {
  "CNKEY": "511290910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "511290910080",
  "CN": "5112 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "511290980080",
  "CN": "5112 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De peso superior a 200 g\/m²"
 },
 {
  "CNKEY": "511300000080",
  "CN": "5113 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "m²",
  "ES": "Tejidos de pelo ordinario o de crin"
 },
 {
  "CNKEY": "520021000090",
  "CN": "52",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 52 - ALGODÓN"
 },
 {
  "CNKEY": "520100000080",
  "CN": "5201 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Algodón sin cardar ni peinar"
 },
 {
  "CNKEY": "520100100080",
  "CN": "5201 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hidrófilo o blanqueado"
 },
 {
  "CNKEY": "520100900080",
  "CN": "5201 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520200000080",
  "CN": "5202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios de algodón (incluidos los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "520210000080",
  "CN": "5202 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios de hilados"
 },
 {
  "CNKEY": "520291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "520291000080",
  "CN": "5202 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hilachas"
 },
 {
  "CNKEY": "520299000080",
  "CN": "5202 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520300000080",
  "CN": "5203 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Algodón cardado o peinado"
 },
 {
  "CNKEY": "520400000080",
  "CN": "5204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilo de coser de algodón, incluso acondicionado para la venta al por menor"
 },
 {
  "CNKEY": "520411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "520411000080",
  "CN": "5204 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de algodón superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "520419000080",
  "CN": "5204 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520420000080",
  "CN": "5204 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acondicionado para la venta al por menor"
 },
 {
  "CNKEY": "520500000080",
  "CN": "5205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de algodón (excepto el hilo de coser) con un contenido de algodón superior o igual al 85 % en peso, sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "520511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados sencillos de fibras sin peinar"
 },
 {
  "CNKEY": "520511000080",
  "CN": "5205 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex (inferior o igual al número métrico 14)"
 },
 {
  "CNKEY": "520512000080",
  "CN": "5205 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex (superior al número métrico 14 pero inferior o igual al número métrico 43)"
 },
 {
  "CNKEY": "520513000080",
  "CN": "5205 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex (superior al número métrico 43 pero inferior o igual al número métrico 52)"
 },
 {
  "CNKEY": "520514000080",
  "CN": "5205 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex (superior al número métrico 52 pero inferior o igual al número métrico 80)"
 },
 {
  "CNKEY": "520515000080",
  "CN": "5205 15",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De título inferior a 125 decitex (superior al número métrico 80)"
 },
 {
  "CNKEY": "520515100080",
  "CN": "5205 15 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex pero superior o igual a 83,33 decitex (superior al número métrico 80 pero inferior o igual al número métrico 120)"
 },
 {
  "CNKEY": "520515900080",
  "CN": "5205 15 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior a 83,33 decitex (superior al número métrico 120)"
 },
 {
  "CNKEY": "520521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados sencillos de fibras peinadas"
 },
 {
  "CNKEY": "520521000080",
  "CN": "5205 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex (inferior o igual al número métrico 14)"
 },
 {
  "CNKEY": "520522000080",
  "CN": "5205 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex (superior al número métrico 14 pero inferior o igual al número métrico 43)"
 },
 {
  "CNKEY": "520523000080",
  "CN": "5205 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex (superior al número métrico 43 pero inferior o igual al número métrico 52)"
 },
 {
  "CNKEY": "520524000080",
  "CN": "5205 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex (superior al número métrico 52 pero inferior o igual al número métrico 80)"
 },
 {
  "CNKEY": "520526000080",
  "CN": "5205 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex pero superior o igual a 106,38 decitex (superior al número métrico 80 pero inferior o igual al número métrico 94)"
 },
 {
  "CNKEY": "520527000080",
  "CN": "5205 27 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 106,38 decitex pero superior o igual a 83,33 decitex (superior al número métrico 94 pero inferior o igual al número métrico 120)"
 },
 {
  "CNKEY": "520528000080",
  "CN": "5205 28 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 83,33 decitex (superior al número métrico 120)"
 },
 {
  "CNKEY": "520531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados retorcidos o cableados, de fibras sin peinar"
 },
 {
  "CNKEY": "520531000080",
  "CN": "5205 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex por hilo sencillo (inferior o igual al número métrico 14 por hilo sencillo)"
 },
 {
  "CNKEY": "520532000080",
  "CN": "5205 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex, por hilo sencillo (superior al número métrico 14 pero inferior o igual al número métrico 43, por hilo sencillo)"
 },
 {
  "CNKEY": "520533000080",
  "CN": "5205 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex, por hilo sencillo (superior al número métrico 43 pero inferior o igual al número métrico 52, por hilo sencillo)"
 },
 {
  "CNKEY": "520534000080",
  "CN": "5205 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex, por hilo sencillo (superior al número métrico 52 pero inferior o igual al número métrico 80, por hilo sencillo)"
 },
 {
  "CNKEY": "520535000080",
  "CN": "5205 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex, por hilo sencillo (superior al número métrico 80 por hilo sencillo)"
 },
 {
  "CNKEY": "520541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados retorcidos o cableados, de fibras peinadas"
 },
 {
  "CNKEY": "520541000080",
  "CN": "5205 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex por hilo sencillo (inferior o igual al número métrico 14 por hilo sencillo)"
 },
 {
  "CNKEY": "520542000080",
  "CN": "5205 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex, por hilo sencillo (superior al número métrico 14 pero inferior o igual al número métrico 43, por hilo sencillo)"
 },
 {
  "CNKEY": "520543000080",
  "CN": "5205 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex, por hilo sencillo (superior al número métrico 43 pero inferior o igual al número métrico 52, por hilo sencillo)"
 },
 {
  "CNKEY": "520544000080",
  "CN": "5205 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex, por hilo sencillo (superior al número métrico 52 pero inferior o igual al número métrico 80, por hilo sencillo)"
 },
 {
  "CNKEY": "520546000080",
  "CN": "5205 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex pero superior o igual a 106,38 decitex, por hilo sencillo (superior al número métrico 80 pero inferior o igual al número métrico 94, por hilo sencillo)"
 },
 {
  "CNKEY": "520547000080",
  "CN": "5205 47 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 106,38 decitex pero superior o igual a 83,33 decitex, por hilo sencillo (superior al número métrico 94 pero inferior o igual al número métrico 120, por hilo sencillo)"
 },
 {
  "CNKEY": "520548000080",
  "CN": "5205 48 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 83,33 decitex por hilo sencillo (superior al número métrico 120 por hilo sencillo)"
 },
 {
  "CNKEY": "520600000080",
  "CN": "5206",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de algodón (excepto el hilo de coser) con un contenido de algodón inferior al 85 % en peso, sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "520611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados sencillos de fibras sin peinar"
 },
 {
  "CNKEY": "520611000080",
  "CN": "5206 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex (inferior o igual al número métrico 14)"
 },
 {
  "CNKEY": "520612000080",
  "CN": "5206 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex (superior al número métrico 14 pero inferior o igual al número métrico 43)"
 },
 {
  "CNKEY": "520613000080",
  "CN": "5206 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex (superior al número métrico 43 pero inferior o igual al número métrico 52)"
 },
 {
  "CNKEY": "520614000080",
  "CN": "5206 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex (superior al número métrico 52 pero inferior o igual al número métrico 80)"
 },
 {
  "CNKEY": "520615000080",
  "CN": "5206 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex (superior al número métrico 80)"
 },
 {
  "CNKEY": "520621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados sencillos de fibras peinadas"
 },
 {
  "CNKEY": "520621000080",
  "CN": "5206 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex (inferior o igual al número métrico 14)"
 },
 {
  "CNKEY": "520622000080",
  "CN": "5206 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex (superior al número métrico 14 pero inferior o igual al número métrico 43)"
 },
 {
  "CNKEY": "520623000080",
  "CN": "5206 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex (superior al número métrico 43 pero inferior o igual al número métrico 52)"
 },
 {
  "CNKEY": "520624000080",
  "CN": "5206 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex (superior al número métrico 52 pero inferior o igual al número métrico 80)"
 },
 {
  "CNKEY": "520625000080",
  "CN": "5206 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex (superior al número métrico 80)"
 },
 {
  "CNKEY": "520631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados retorcidos o cableados, de fibras sin peinar"
 },
 {
  "CNKEY": "520631000080",
  "CN": "5206 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex por hilo sencillo (inferior o igual al número métrico 14 por hilo sencillo)"
 },
 {
  "CNKEY": "520632000080",
  "CN": "5206 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex, por hilo sencillo (superior al número métrico 14 pero inferior o igual al número métrico 43, por hilo sencillo)"
 },
 {
  "CNKEY": "520633000080",
  "CN": "5206 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex, por hilo sencillo (superior al número métrico 43 pero inferior o igual al número métrico 52, por hilo sencillo)"
 },
 {
  "CNKEY": "520634000080",
  "CN": "5206 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex, por hilo sencillo (superior al número métrico 52 pero inferior o igual al número métrico 80, por hilo sencillo)"
 },
 {
  "CNKEY": "520635000080",
  "CN": "5206 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex por hilo sencillo (superior al número métrico 80 por hilo sencillo)"
 },
 {
  "CNKEY": "520641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados retorcidos o cableados, de fibras peinadas"
 },
 {
  "CNKEY": "520641000080",
  "CN": "5206 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 714,29 decitex por hilo sencillo (inferior o igual al número métrico 14 por hilo sencillo)"
 },
 {
  "CNKEY": "520642000080",
  "CN": "5206 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 714,29 decitex pero superior o igual a 232,56 decitex, por hilo sencillo (superior al número métrico 14 pero inferior o igual al número métrico 43, por hilo sencillo)"
 },
 {
  "CNKEY": "520643000080",
  "CN": "5206 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 232,56 decitex pero superior o igual a 192,31 decitex, por hilo sencillo (superior al número métrico 43 pero inferior o igual al número métrico 52, por hilo sencillo)"
 },
 {
  "CNKEY": "520644000080",
  "CN": "5206 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 192,31 decitex pero superior o igual a 125 decitex, por hilo sencillo (superior al número métrico 52 pero inferior o igual al número métrico 80, por hilo sencillo)"
 },
 {
  "CNKEY": "520645000080",
  "CN": "5206 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De título inferior a 125 decitex por hilo sencillo (superior al número métrico 80 por hilo sencillo)"
 },
 {
  "CNKEY": "520700000080",
  "CN": "5207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de algodón (excepto el hilo de coser) acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "520710000080",
  "CN": "5207 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de algodón superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "520790000080",
  "CN": "5207 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520800000080",
  "CN": "5208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de algodón con un contenido de algodón superior o igual al 85 % en peso, de peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "520811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "520811000080",
  "CN": "5208 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso inferior o igual a 100 g\/m²"
 },
 {
  "CNKEY": "520811100080",
  "CN": "5208 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Tejidos para la fabricación de vendas, apósitos y gasas para apósitos"
 },
 {
  "CNKEY": "520811900080",
  "CN": "5208 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520812000080",
  "CN": "5208 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m²"
 },
 {
  "CNKEY": "520812160010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m² pero inferior o igual a 130 g\/m², de anchura"
 },
 {
  "CNKEY": "520812160080",
  "CN": "5208 12 16",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520812190080",
  "CN": "5208 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520812960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 130 g\/m², de anchura"
 },
 {
  "CNKEY": "520812960080",
  "CN": "5208 12 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520812990080",
  "CN": "5208 12 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520813000080",
  "CN": "5208 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520819000080",
  "CN": "5208 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "520821000080",
  "CN": "5208 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso inferior o igual a 100 g\/m²"
 },
 {
  "CNKEY": "520821100080",
  "CN": "5208 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Tejidos para la fabricación de vendas, apósitos y gasas para apósitos"
 },
 {
  "CNKEY": "520821900080",
  "CN": "5208 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520822000080",
  "CN": "5208 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m²"
 },
 {
  "CNKEY": "520822160010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m² pero inferior o igual a 130 g\/m², de anchura"
 },
 {
  "CNKEY": "520822160080",
  "CN": "5208 22 16",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520822190080",
  "CN": "5208 22 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520822960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 130 g\/m², de anchura"
 },
 {
  "CNKEY": "520822960080",
  "CN": "5208 22 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520822990080",
  "CN": "5208 22 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520823000080",
  "CN": "5208 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520829000080",
  "CN": "5208 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "520831000080",
  "CN": "5208 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán, de peso inferior o igual a 100 g\/m²"
 },
 {
  "CNKEY": "520832000080",
  "CN": "5208 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m²"
 },
 {
  "CNKEY": "520832160010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m² pero inferior o igual a 130 g\/m²"
 },
 {
  "CNKEY": "520832160080",
  "CN": "5208 32 16",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520832190080",
  "CN": "5208 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520832960010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De ligamento tafetán, de peso superior a 130 g\/m², de anchura"
 },
 {
  "CNKEY": "520832960080",
  "CN": "5208 32 96",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 165 cm"
 },
 {
  "CNKEY": "520832990080",
  "CN": "5208 32 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Superior a 165 cm"
 },
 {
  "CNKEY": "520833000080",
  "CN": "5208 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520839000080",
  "CN": "5208 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520841000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "520841000080",
  "CN": "5208 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán, de peso inferior o igual a 100 g\/m²"
 },
 {
  "CNKEY": "520842000080",
  "CN": "5208 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m²"
 },
 {
  "CNKEY": "520843000080",
  "CN": "5208 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520849000080",
  "CN": "5208 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520851000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "520851000080",
  "CN": "5208 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán, de peso inferior o igual a 100 g\/m²"
 },
 {
  "CNKEY": "520852000080",
  "CN": "5208 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán, de peso superior a 100 g\/m²"
 },
 {
  "CNKEY": "520859000080",
  "CN": "5208 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520859100080",
  "CN": "5208 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520859900080",
  "CN": "5208 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "520900000080",
  "CN": "5209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de algodón con un contenido de algodón superior o igual al 85 % en peso, de peso superior a 200 g\/m²"
 },
 {
  "CNKEY": "520911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "520911000080",
  "CN": "5209 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "520912000080",
  "CN": "5209 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520919000080",
  "CN": "5209 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "520921000080",
  "CN": "5209 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "520922000080",
  "CN": "5209 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520929000080",
  "CN": "5209 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "520931000080",
  "CN": "5209 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "520932000080",
  "CN": "5209 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520939000080",
  "CN": "5209 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "520941000080",
  "CN": "5209 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "520942000080",
  "CN": "5209 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Tejidos de mezclilla («denim»)"
 },
 {
  "CNKEY": "520943000080",
  "CN": "5209 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520949000080",
  "CN": "5209 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "520951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "520951000080",
  "CN": "5209 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "520952000080",
  "CN": "5209 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "520959000080",
  "CN": "5209 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521000000080",
  "CN": "5210",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de algodón con un contenido de algodón inferior al 85 % en peso, mezclado exclusiva o principalmente con fibras sintéticas o artificiales, de peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "521011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "521011000080",
  "CN": "5210 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521019000080",
  "CN": "5210 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "521021000080",
  "CN": "5210 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521029000080",
  "CN": "5210 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "521031000080",
  "CN": "5210 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521032000080",
  "CN": "5210 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "521039000080",
  "CN": "5210 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521041000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "521041000080",
  "CN": "5210 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521049000080",
  "CN": "5210 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521051000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "521051000080",
  "CN": "5210 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521059000080",
  "CN": "5210 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521100000080",
  "CN": "5211",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de algodón con un contenido de algodón inferior al 85 % en peso, mezclado exclusiva o principalmente con fibras sintéticas o artificiales, de peso superior a 200 g\/m²"
 },
 {
  "CNKEY": "521111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "521111000080",
  "CN": "5211 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521112000080",
  "CN": "5211 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "521119000080",
  "CN": "5211 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521120000080",
  "CN": "5211 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "521131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "521131000080",
  "CN": "5211 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521132000080",
  "CN": "5211 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "521139000080",
  "CN": "5211 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "521141000080",
  "CN": "5211 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521142000080",
  "CN": "5211 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Tejidos de mezclilla («denim»)"
 },
 {
  "CNKEY": "521143000080",
  "CN": "5211 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "521149000080",
  "CN": "5211 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521149100080",
  "CN": "5211 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Tejidos Jacquard"
 },
 {
  "CNKEY": "521149900080",
  "CN": "5211 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "521151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "521151000080",
  "CN": "5211 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento tafetán"
 },
 {
  "CNKEY": "521152000080",
  "CN": "5211 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "521159000080",
  "CN": "5211 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "521200000080",
  "CN": "5212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tejidos de algodón"
 },
 {
  "CNKEY": "521211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De peso inferior o igual a 200 g\/m²"
 },
 {
  "CNKEY": "521211000080",
  "CN": "5212 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "521211100080",
  "CN": "5212 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521211900080",
  "CN": "5212 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521212000080",
  "CN": "5212 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "521212100080",
  "CN": "5212 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521212900080",
  "CN": "5212 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521213000080",
  "CN": "5212 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "521213100080",
  "CN": "5212 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521213900080",
  "CN": "5212 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521214000080",
  "CN": "5212 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "521214100080",
  "CN": "5212 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521214900080",
  "CN": "5212 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521215000080",
  "CN": "5212 15",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "521215100080",
  "CN": "5212 15 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521215900080",
  "CN": "5212 15 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De peso superior a 200 g\/m²"
 },
 {
  "CNKEY": "521221000080",
  "CN": "5212 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "521221100080",
  "CN": "5212 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521221900080",
  "CN": "5212 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521222000080",
  "CN": "5212 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "521222100080",
  "CN": "5212 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521222900080",
  "CN": "5212 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521223000080",
  "CN": "5212 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "521223100080",
  "CN": "5212 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521223900080",
  "CN": "5212 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521224000080",
  "CN": "5212 24",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "521224100080",
  "CN": "5212 24 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521224900080",
  "CN": "5212 24 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "521225000080",
  "CN": "5212 25",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "521225100080",
  "CN": "5212 25 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados principal o únicamente con lino"
 },
 {
  "CNKEY": "521225900080",
  "CN": "5212 25 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Mezclados de otro modo"
 },
 {
  "CNKEY": "530021000090",
  "CN": "53",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 53 - LAS DEMÁS FIBRAS TEXTILES VEGETALES; HILADOS DE PAPEL Y TEJIDOS DE HILADOS DE PAPEL"
 },
 {
  "CNKEY": "530100000080",
  "CN": "5301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lino en bruto o trabajado, pero sin hilar; estopas y desperdicios de lino (incluidos los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "530110000080",
  "CN": "5301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lino en bruto o enriado"
 },
 {
  "CNKEY": "530121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lino agramado, espadado, peinado o trabajado de otro modo, pero sin hilar"
 },
 {
  "CNKEY": "530121000080",
  "CN": "5301 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agramado o espadado"
 },
 {
  "CNKEY": "530129000080",
  "CN": "5301 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "530130000080",
  "CN": "5301 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Estopas y desperdicios de lino"
 },
 {
  "CNKEY": "530200000080",
  "CN": "5302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cáñamo (Cannabis sativa L.) en bruto o trabajado, pero sin hilar; estopas y desperdicios de cáñamo (incluidos los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "530210000080",
  "CN": "5302 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cáñamo en bruto o enriado"
 },
 {
  "CNKEY": "530290000080",
  "CN": "5302 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "530300000080",
  "CN": "5303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Yute y demás fibras textiles del líber (excepto el lino, cáñamo y ramio), en bruto o trabajados, pero sin hilar; estopas y desperdicios de estas fibras (incluidos los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "530310000080",
  "CN": "5303 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Yute y demás fibras textiles del líber, en bruto o enriados"
 },
 {
  "CNKEY": "530390000080",
  "CN": "5303 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "530500000080",
  "CN": "5305 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Coco, abacá [cáñamo de Manila (Musa textilisNee)], ramio y demás fibras textiles vegetales no expresadas ni comprendidas en otra parte, en bruto o trabajadas, pero sin hilar; estopas y desperdicios de estas fibras (incluidos los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "530600000080",
  "CN": "5306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de lino"
 },
 {
  "CNKEY": "530610000080",
  "CN": "5306 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sencillos"
 },
 {
  "CNKEY": "530610100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "530610100080",
  "CN": "5306 10 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 833,3 decitex (inferior o igual al número métrico 12)"
 },
 {
  "CNKEY": "530610300080",
  "CN": "5306 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior a 833,3 decitex pero superior o igual a 277,8 decitex (superior al número métrico 12 pero inferior o igual al número métrico 36)"
 },
 {
  "CNKEY": "530610500080",
  "CN": "5306 10 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior a 277,8 decitex (superior al número métrico 36)"
 },
 {
  "CNKEY": "530610900080",
  "CN": "5306 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "530620000080",
  "CN": "5306 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "530620100080",
  "CN": "5306 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "530620900080",
  "CN": "5306 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "530700000080",
  "CN": "5307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de yute o demás fibras textiles del líber de la partida 5303"
 },
 {
  "CNKEY": "530710000080",
  "CN": "5307 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "530720000080",
  "CN": "5307 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "530800000080",
  "CN": "5308",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de las demás fibras textiles vegetales; hilados de papel"
 },
 {
  "CNKEY": "530810000080",
  "CN": "5308 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados de coco"
 },
 {
  "CNKEY": "530820000080",
  "CN": "5308 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados de cáñamo"
 },
 {
  "CNKEY": "530820100080",
  "CN": "5308 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "530820900080",
  "CN": "5308 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "530890000080",
  "CN": "5308 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "530890120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hilados de ramio"
 },
 {
  "CNKEY": "530890120080",
  "CN": "5308 90 12",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título superior o igual a 277,8 decitex (inferior o igual al número métrico 36)"
 },
 {
  "CNKEY": "530890190080",
  "CN": "5308 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior a 277,8 decitex (superior al número métrico 36)"
 },
 {
  "CNKEY": "530890500080",
  "CN": "5308 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hilados de papel"
 },
 {
  "CNKEY": "530890900080",
  "CN": "5308 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "530900000080",
  "CN": "5309",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de lino"
 },
 {
  "CNKEY": "530911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lino superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "530911000080",
  "CN": "5309 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "530911100080",
  "CN": "5309 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos"
 },
 {
  "CNKEY": "530911900080",
  "CN": "5309 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Blanqueados"
 },
 {
  "CNKEY": "530919000080",
  "CN": "5309 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "530921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de lino inferior al 85 % en peso"
 },
 {
  "CNKEY": "530921000080",
  "CN": "5309 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "530929000080",
  "CN": "5309 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "531000000080",
  "CN": "5310",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de yute o demás fibras textiles del líber de la partida 5303"
 },
 {
  "CNKEY": "531010000080",
  "CN": "5310 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos"
 },
 {
  "CNKEY": "531010100080",
  "CN": "5310 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura inferior o igual a 150 cm"
 },
 {
  "CNKEY": "531010900080",
  "CN": "5310 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 150 cm"
 },
 {
  "CNKEY": "531090000080",
  "CN": "5310 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "531100000080",
  "CN": "5311 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de las demás fibras textiles vegetales; tejidos de hilados de papel"
 },
 {
  "CNKEY": "531100100080",
  "CN": "5311 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos de ramio"
 },
 {
  "CNKEY": "531100900080",
  "CN": "5311 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540021000090",
  "CN": "54",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 54 - FILAMENTOS SINTÉTICOS O ARTIFICIALES; TIRAS Y FORMAS SIMILARES DE MATERIA TEXTIL SINTÉTICA O ARTIFICIAL"
 },
 {
  "CNKEY": "540100000080",
  "CN": "5401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilo de coser de filamentos sintéticos o artificiales, incluso acondicionado para la venta al por menor"
 },
 {
  "CNKEY": "540110000080",
  "CN": "5401 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De filamentos sintéticos"
 },
 {
  "CNKEY": "540110120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "540110120020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Hilos con núcleo llamados «core yarn»"
 },
 {
  "CNKEY": "540110120080",
  "CN": "5401 10 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Filamentos de poliéster recubiertos de fibras de algodón"
 },
 {
  "CNKEY": "540110140080",
  "CN": "5401 10 14",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540110160010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "540110160080",
  "CN": "5401 10 16",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hilados texturados"
 },
 {
  "CNKEY": "540110180080",
  "CN": "5401 10 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540110900080",
  "CN": "5401 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "540120000080",
  "CN": "5401 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De filamentos artificiales"
 },
 {
  "CNKEY": "540120100080",
  "CN": "5401 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "540120900080",
  "CN": "5401 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "540200000080",
  "CN": "5402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de filamentos sintéticos (excepto el hilo de coser) sin acondicionar para la venta al por menor, incluidos los monofilamentos sintéticos de título inferior a 67 decitex"
 },
 {
  "CNKEY": "540211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados de alta tenacidad de nailon o demás poliamidas, incluso texturados"
 },
 {
  "CNKEY": "540211000080",
  "CN": "5402 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aramidas"
 },
 {
  "CNKEY": "540219000080",
  "CN": "5402 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540220000080",
  "CN": "5402 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados de alta tenacidad de poliésteres, incluso texturados"
 },
 {
  "CNKEY": "540231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hilados texturados"
 },
 {
  "CNKEY": "540231000080",
  "CN": "5402 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas, de título inferior o igual a 50 tex por hilo sencillo"
 },
 {
  "CNKEY": "540232000080",
  "CN": "5402 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas, de título superior a 50 tex por hilo sencillo"
 },
 {
  "CNKEY": "540233000080",
  "CN": "5402 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "540234000080",
  "CN": "5402 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "540239000080",
  "CN": "5402 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540244000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados sencillos sin torsión o con una torsión inferior o igual a 50 vueltas por metro"
 },
 {
  "CNKEY": "540244000080",
  "CN": "5402 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De elastómeros"
 },
 {
  "CNKEY": "540245000080",
  "CN": "5402 45 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de nailon o demás poliamidas"
 },
 {
  "CNKEY": "540246000080",
  "CN": "5402 46 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de poliésteres, parcialmente orientados"
 },
 {
  "CNKEY": "540247000080",
  "CN": "5402 47 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de poliésteres"
 },
 {
  "CNKEY": "540248000080",
  "CN": "5402 48 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de polipropileno"
 },
 {
  "CNKEY": "540249000080",
  "CN": "5402 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados sencillos con una torsión superior a 50 vueltas por metro"
 },
 {
  "CNKEY": "540251000080",
  "CN": "5402 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "540252000080",
  "CN": "5402 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "540253000080",
  "CN": "5402 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "540259000080",
  "CN": "5402 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540261000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados retorcidos o cableados"
 },
 {
  "CNKEY": "540261000080",
  "CN": "5402 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "540262000080",
  "CN": "5402 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "540263000080",
  "CN": "5402 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "540269000080",
  "CN": "5402 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540300000080",
  "CN": "5403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de filamentos artificiales (excepto el hilo de coser) sin acondicionar para la venta al por menor, incluidos los monofilamentos artificiales de título inferior a 67 decitex"
 },
 {
  "CNKEY": "540310000080",
  "CN": "5403 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados de alta tenacidad de rayón viscosa"
 },
 {
  "CNKEY": "540331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados sencillos"
 },
 {
  "CNKEY": "540331000080",
  "CN": "5403 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De rayón viscosa, sin torsión o con una torsión inferior o igual a 120 vueltas por metro"
 },
 {
  "CNKEY": "540332000080",
  "CN": "5403 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De rayón viscosa, con una torsión superior a 120 vueltas por metro"
 },
 {
  "CNKEY": "540333000080",
  "CN": "5403 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acetato de celulosa"
 },
 {
  "CNKEY": "540339000080",
  "CN": "5403 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados retorcidos o cableados"
 },
 {
  "CNKEY": "540341000080",
  "CN": "5403 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De rayón viscosa"
 },
 {
  "CNKEY": "540342000080",
  "CN": "5403 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acetato de celulosa"
 },
 {
  "CNKEY": "540349000080",
  "CN": "5403 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540400000080",
  "CN": "5404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monofilamentos sintéticos de título superior o igual a 67 decitex y cuya mayor dimensión de la sección transversal sea inferior o igual a 1 mm; tiras y formas similares (por ejemplo: paja artificial) de materia textil sintética, de anchura aparente inferior o igual a 5 mm"
 },
 {
  "CNKEY": "540411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monofilamentos"
 },
 {
  "CNKEY": "540411000080",
  "CN": "5404 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De elastómeros"
 },
 {
  "CNKEY": "540412000080",
  "CN": "5404 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de polipropileno"
 },
 {
  "CNKEY": "540419000080",
  "CN": "5404 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540490000080",
  "CN": "5404 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "540490100080",
  "CN": "5404 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "540490900080",
  "CN": "5404 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540500000080",
  "CN": "5405 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Monofilamentos artificiales de título superior o igual a 67 decitex y cuya mayor dimensión de la sección transversal sea inferior o igual a 1 mm; tiras y formas similares (por ejemplo: paja artificial) de materia textil artificial, de anchura aparente inferior o igual a 5 mm"
 },
 {
  "CNKEY": "540600000080",
  "CN": "5406 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Hilados de filamentos sintéticos o artificiales (excepto el hilo de coser), acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "540700000080",
  "CN": "5407",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de hilados de filamentos sintéticos, incluidos los tejidos fabricados con los productos de la partida 5404"
 },
 {
  "CNKEY": "540710000080",
  "CN": "5407 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos fabricados con hilados de alta tenacidad de nailon o demás poliamidas o de poliésteres"
 },
 {
  "CNKEY": "540720000080",
  "CN": "5407 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tejidos fabricados con tiras o formas similares"
 },
 {
  "CNKEY": "540720110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De polietileno o de polipropileno, de anchura"
 },
 {
  "CNKEY": "540720110080",
  "CN": "5407 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Inferior a 3 m"
 },
 {
  "CNKEY": "540720190080",
  "CN": "5407 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Superior o igual a 3 m"
 },
 {
  "CNKEY": "540720900080",
  "CN": "5407 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540730000080",
  "CN": "5407 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Productos citados en la Nota 9 de la Sección XI"
 },
 {
  "CNKEY": "540741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos de nailon o demás poliamidas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540741000080",
  "CN": "5407 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540742000080",
  "CN": "5407 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540743000080",
  "CN": "5407 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540744000080",
  "CN": "5407 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos de poliéster texturados superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540751000080",
  "CN": "5407 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540752000080",
  "CN": "5407 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540753000080",
  "CN": "5407 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540754000080",
  "CN": "5407 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540761000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos de poliéster superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540761000080",
  "CN": "5407 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de filamentos de poliéster sin texturar superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540761100080",
  "CN": "5407 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540761300080",
  "CN": "5407 61 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540761500080",
  "CN": "5407 61 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540761900080",
  "CN": "5407 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540769000080",
  "CN": "5407 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "540769100080",
  "CN": "5407 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540769900080",
  "CN": "5407 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540771000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos sintéticos superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540771000080",
  "CN": "5407 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540772000080",
  "CN": "5407 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540773000080",
  "CN": "5407 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540774000080",
  "CN": "5407 74 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540781000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos sintéticos inferior al 85 % en peso, mezclados exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "540781000080",
  "CN": "5407 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540782000080",
  "CN": "5407 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540783000080",
  "CN": "5407 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540784000080",
  "CN": "5407 84 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "540791000080",
  "CN": "5407 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540792000080",
  "CN": "5407 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540793000080",
  "CN": "5407 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540794000080",
  "CN": "5407 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540800000080",
  "CN": "5408",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de hilados de filamentos artificiales, incluidos los fabricados con productos de la partida 5405"
 },
 {
  "CNKEY": "540810000080",
  "CN": "5408 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos fabricados con hilados de alta tenacidad de rayón viscosa"
 },
 {
  "CNKEY": "540821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos con un contenido de filamentos o de tiras o formas similares, artificiales, superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "540821000080",
  "CN": "5408 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540822000080",
  "CN": "5408 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540822100080",
  "CN": "5408 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 135 cm pero inferior o igual a 155 cm, de ligamento tafetán, sarga, cruzado o satén"
 },
 {
  "CNKEY": "540822900080",
  "CN": "5408 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "540823000080",
  "CN": "5408 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540824000080",
  "CN": "5408 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "540831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "540831000080",
  "CN": "5408 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "540832000080",
  "CN": "5408 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "540833000080",
  "CN": "5408 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "540834000080",
  "CN": "5408 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "550021000090",
  "CN": "55",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 55 - FIBRAS SINTÉTICAS O ARTIFICIALES DISCONTINUAS"
 },
 {
  "CNKEY": "550100000080",
  "CN": "5501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cables de filamentos sintéticos"
 },
 {
  "CNKEY": "550111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "550111000080",
  "CN": "5501 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aramidas"
 },
 {
  "CNKEY": "550119000080",
  "CN": "5501 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550120000080",
  "CN": "5501 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "550130000080",
  "CN": "5501 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acrílicos o modacrílicos"
 },
 {
  "CNKEY": "550140000080",
  "CN": "5501 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "550190000080",
  "CN": "5501 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550200000080",
  "CN": "5502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cables de filamentos artificiales"
 },
 {
  "CNKEY": "550210000080",
  "CN": "5502 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acetato de celulosa"
 },
 {
  "CNKEY": "550290000080",
  "CN": "5502 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550300000080",
  "CN": "5503",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fibras sintéticas discontinuas, sin cardar, peinar ni transformar de otro modo para la hilatura"
 },
 {
  "CNKEY": "550311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "550311000080",
  "CN": "5503 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aramidas"
 },
 {
  "CNKEY": "550319000080",
  "CN": "5503 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "550320000080",
  "CN": "5503 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "550330000080",
  "CN": "5503 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acrílicas o modacrílicas"
 },
 {
  "CNKEY": "550340000080",
  "CN": "5503 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "550390000080",
  "CN": "5503 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "550400000080",
  "CN": "5504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fibras artificiales discontinuas, sin cardar, peinar ni transformar de otro modo para la hilatura"
 },
 {
  "CNKEY": "550410000080",
  "CN": "5504 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De rayón viscosa"
 },
 {
  "CNKEY": "550490000080",
  "CN": "5504 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "550500000080",
  "CN": "5505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios de fibras sintéticas o artificiales (incluidas las borras, los desperdicios de hilados y las hilachas)"
 },
 {
  "CNKEY": "550510000080",
  "CN": "5505 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "550510100080",
  "CN": "5505 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "550510300080",
  "CN": "5505 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "550510500080",
  "CN": "5505 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acrílicas o modacrílicas"
 },
 {
  "CNKEY": "550510700080",
  "CN": "5505 10 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "550510900080",
  "CN": "5505 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550520000080",
  "CN": "5505 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "550600000080",
  "CN": "5506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fibras sintéticas discontinuas, cardadas, peinadas o transformadas de otro modo para la hilatura"
 },
 {
  "CNKEY": "550610000080",
  "CN": "5506 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "550620000080",
  "CN": "5506 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "550630000080",
  "CN": "5506 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acrílicas o modacrílicas"
 },
 {
  "CNKEY": "550640000080",
  "CN": "5506 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "550690000080",
  "CN": "5506 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "550700000080",
  "CN": "5507 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Fibras artificiales discontinuas, cardadas, peinadas o transformadas de otro modo para la hilatura"
 },
 {
  "CNKEY": "550800000080",
  "CN": "5508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilo de coser de fibras sintéticas o artificiales, discontinuas, incluso acondicionado para la venta al por menor"
 },
 {
  "CNKEY": "550810000080",
  "CN": "5508 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas discontinuas"
 },
 {
  "CNKEY": "550810100080",
  "CN": "5508 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "550810900080",
  "CN": "5508 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "550820000080",
  "CN": "5508 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras artificiales discontinuas"
 },
 {
  "CNKEY": "550820100080",
  "CN": "5508 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "550820900080",
  "CN": "5508 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "550900000080",
  "CN": "5509",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de fibras sintéticas discontinuas (excepto el hilo de coser) sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "550911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras discontinuas de nailon o demás poliamidas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "550911000080",
  "CN": "5509 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "550912000080",
  "CN": "5509 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "550921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras discontinuas de poliéster superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "550921000080",
  "CN": "5509 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "550922000080",
  "CN": "5509 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "550931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras discontinuas acrílicas o modacrílicas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "550931000080",
  "CN": "5509 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "550932000080",
  "CN": "5509 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "550941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados con un contenido de fibras sintéticas discontinuas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "550941000080",
  "CN": "5509 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "550942000080",
  "CN": "5509 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "550951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "550951000080",
  "CN": "5509 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con fibras artificiales discontinuas"
 },
 {
  "CNKEY": "550952000080",
  "CN": "5509 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "550953000080",
  "CN": "5509 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "550959000080",
  "CN": "5509 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados de fibras discontinuas acrílicas o modacrílicas"
 },
 {
  "CNKEY": "550961000080",
  "CN": "5509 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "550962000080",
  "CN": "5509 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "550969000080",
  "CN": "5509 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "550991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hilados"
 },
 {
  "CNKEY": "550991000080",
  "CN": "5509 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "550992000080",
  "CN": "5509 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mezclados exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "550999000080",
  "CN": "5509 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551000000080",
  "CN": "5510",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de fibras artificiales discontinuas (excepto el hilo de coser) sin acondicionar para la venta al por menor"
 },
 {
  "CNKEY": "551011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras artificiales discontinuas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551011000080",
  "CN": "5510 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sencillos"
 },
 {
  "CNKEY": "551012000080",
  "CN": "5510 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Retorcidos o cableados"
 },
 {
  "CNKEY": "551020000080",
  "CN": "5510 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás hilados mezclados exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "551030000080",
  "CN": "5510 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás hilados mezclados exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "551090000080",
  "CN": "5510 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás hilados"
 },
 {
  "CNKEY": "551100000080",
  "CN": "5511",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados de fibras sintéticas o artificiales, discontinuas (excepto el hilo de coser) acondicionados para la venta al por menor"
 },
 {
  "CNKEY": "551110000080",
  "CN": "5511 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas discontinuas con un contenido de estas fibras superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551120000080",
  "CN": "5511 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas discontinuas con un contenido de estas fibras inferior al 85 % en peso"
 },
 {
  "CNKEY": "551130000080",
  "CN": "5511 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras artificiales discontinuas"
 },
 {
  "CNKEY": "551200000080",
  "CN": "5512",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de fibras sintéticas discontinuas con un contenido de fibras sintéticas discontinuas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras discontinuas de poliéster superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551211000080",
  "CN": "5512 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551219000080",
  "CN": "5512 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551219100080",
  "CN": "5512 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551219900080",
  "CN": "5512 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras discontinuas acrílicas o modacrílicas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551221000080",
  "CN": "5512 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551229000080",
  "CN": "5512 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551229100080",
  "CN": "5512 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551229900080",
  "CN": "5512 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551291000080",
  "CN": "5512 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551299000080",
  "CN": "5512 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551299100080",
  "CN": "5512 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551299900080",
  "CN": "5512 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551300000080",
  "CN": "5513",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de fibras sintéticas discontinuas con un contenido de estas fibras inferior al 85 % en peso, mezcladas exclusiva o principalmente con algodón, de peso inferior o igual a 170 g\/m²"
 },
 {
  "CNKEY": "551311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551311000080",
  "CN": "5513 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551311200080",
  "CN": "5513 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De anchura inferior o igual a 165 cm"
 },
 {
  "CNKEY": "551311900080",
  "CN": "5513 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De anchura superior a 165 cm"
 },
 {
  "CNKEY": "551312000080",
  "CN": "5513 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551313000080",
  "CN": "5513 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551319000080",
  "CN": "5513 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551321000080",
  "CN": "5513 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551323000080",
  "CN": "5513 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tejidos de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551323100080",
  "CN": "5513 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551323900080",
  "CN": "5513 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551329000080",
  "CN": "5513 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551331000080",
  "CN": "5513 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551339000080",
  "CN": "5513 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "551341000080",
  "CN": "5513 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551349000080",
  "CN": "5513 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551400000080",
  "CN": "5514",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de fibras sintéticas discontinuas con un contenido de estas fibras inferior al 85 % en peso, mezcladas exclusiva o principalmente con algodón, de peso superior a 170 g\/m²"
 },
 {
  "CNKEY": "551411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551411000080",
  "CN": "5514 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551412000080",
  "CN": "5514 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551419000080",
  "CN": "5514 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551419100080",
  "CN": "5514 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551419900080",
  "CN": "5514 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551421000080",
  "CN": "5514 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551422000080",
  "CN": "5514 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551423000080",
  "CN": "5514 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551429000080",
  "CN": "5514 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551430000080",
  "CN": "5514 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551430100080",
  "CN": "5514 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551430300080",
  "CN": "5514 30 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551430500080",
  "CN": "5514 30 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551430900080",
  "CN": "5514 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "551441000080",
  "CN": "5514 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento tafetán"
 },
 {
  "CNKEY": "551442000080",
  "CN": "5514 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras discontinuas de poliéster, de ligamento sarga, incluido el cruzado, de curso inferior o igual a 4"
 },
 {
  "CNKEY": "551443000080",
  "CN": "5514 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos de fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551449000080",
  "CN": "5514 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551500000080",
  "CN": "5515",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tejidos de fibras sintéticas discontinuas"
 },
 {
  "CNKEY": "551511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras discontinuas de poliéster"
 },
 {
  "CNKEY": "551511000080",
  "CN": "5515 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con fibras discontinuas de rayón viscosa"
 },
 {
  "CNKEY": "551511100080",
  "CN": "5515 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551511300080",
  "CN": "5515 11 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551511900080",
  "CN": "5515 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551512000080",
  "CN": "5515 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "551512100080",
  "CN": "5515 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551512300080",
  "CN": "5515 12 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551512900080",
  "CN": "5515 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551513000080",
  "CN": "5515 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "551513110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino, cardados"
 },
 {
  "CNKEY": "551513110080",
  "CN": "5515 13 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551513190080",
  "CN": "5515 13 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551513910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino, peinados"
 },
 {
  "CNKEY": "551513910080",
  "CN": "5515 13 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551513990080",
  "CN": "5515 13 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551519000080",
  "CN": "5515 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551519100080",
  "CN": "5515 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551519300080",
  "CN": "5515 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551519900080",
  "CN": "5515 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras discontinuas acrílicas o modacrílicas"
 },
 {
  "CNKEY": "551521000080",
  "CN": "5515 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "551521100080",
  "CN": "5515 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551521300080",
  "CN": "5515 21 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551521900080",
  "CN": "5515 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551522000080",
  "CN": "5515 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "551522110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino, cardados"
 },
 {
  "CNKEY": "551522110080",
  "CN": "5515 22 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551522190080",
  "CN": "5515 22 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551522910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con lana o pelo fino, peinados"
 },
 {
  "CNKEY": "551522910080",
  "CN": "5515 22 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551522990080",
  "CN": "5515 22 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551529000080",
  "CN": "5515 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tejidos"
 },
 {
  "CNKEY": "551591000080",
  "CN": "5515 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mezcladas exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "551591100080",
  "CN": "5515 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551591300080",
  "CN": "5515 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551591900080",
  "CN": "5515 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551599000080",
  "CN": "5515 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551599200080",
  "CN": "5515 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551599400080",
  "CN": "5515 99 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551599800080",
  "CN": "5515 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551600000080",
  "CN": "5516",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de fibras artificiales discontinuas"
 },
 {
  "CNKEY": "551611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras artificiales discontinuas superior o igual al 85 % en peso"
 },
 {
  "CNKEY": "551611000080",
  "CN": "5516 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551612000080",
  "CN": "5516 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551613000080",
  "CN": "5516 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551614000080",
  "CN": "5516 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras artificiales discontinuas inferior al 85 % en peso, mezcladas exclusiva o principalmente con filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "551621000080",
  "CN": "5516 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551622000080",
  "CN": "5516 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551623000080",
  "CN": "5516 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551623100080",
  "CN": "5516 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Tejidos Jacquard de anchura superior o igual a 140 cm (cuti para colchones)"
 },
 {
  "CNKEY": "551623900080",
  "CN": "5516 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "551624000080",
  "CN": "5516 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras artificiales discontinuas inferior al 85 % en peso, mezcladas exclusiva o principalmente con lana o pelo fino"
 },
 {
  "CNKEY": "551631000080",
  "CN": "5516 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551632000080",
  "CN": "5516 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551633000080",
  "CN": "5516 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551634000080",
  "CN": "5516 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de fibras artificiales discontinuas inferior al 85 % en peso, mezcladas exclusiva o principalmente con algodón"
 },
 {
  "CNKEY": "551641000080",
  "CN": "5516 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551642000080",
  "CN": "5516 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551643000080",
  "CN": "5516 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551644000080",
  "CN": "5516 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "551691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "551691000080",
  "CN": "5516 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "551692000080",
  "CN": "5516 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "551693000080",
  "CN": "5516 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "551694000080",
  "CN": "5516 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Estampados"
 },
 {
  "CNKEY": "560021000090",
  "CN": "56",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 56 - GUATA, FIELTRO Y TELA SIN TEJER; HILADOS ESPECIALES; CORDELES, CUERDAS Y CORDAJES; ARTÍCULOS DE CORDELERÍA"
 },
 {
  "CNKEY": "560100000080",
  "CN": "5601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Guata de materia textil y artículos de esta guata; fibras textiles de longitud inferior o igual a 5 mm (tundizno), nudos y motas de materia textil"
 },
 {
  "CNKEY": "560121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guata de materia textil y artículos de esta guata"
 },
 {
  "CNKEY": "560121000080",
  "CN": "5601 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "560121100080",
  "CN": "5601 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hidrófilo"
 },
 {
  "CNKEY": "560121900080",
  "CN": "5601 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560122000080",
  "CN": "5601 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "560122100080",
  "CN": "5601 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rollos de diámetro inferior o igual a 8 mm"
 },
 {
  "CNKEY": "560122900080",
  "CN": "5601 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560129000080",
  "CN": "5601 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560130000080",
  "CN": "5601 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tundizno, nudos y motas de materia textil"
 },
 {
  "CNKEY": "560200000080",
  "CN": "5602",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fieltro, incluso impregnado, recubierto, revestido o estratificado"
 },
 {
  "CNKEY": "560210000080",
  "CN": "5602 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fieltro punzonado y productos obtenidos mediante costura por cadeneta"
 },
 {
  "CNKEY": "560210110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin impregnar, recubrir, revestir ni estratificar"
 },
 {
  "CNKEY": "560210110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Fieltro punzonado"
 },
 {
  "CNKEY": "560210110080",
  "CN": "5602 10 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De yute o de otras fibras textiles del líber de la partida 5303"
 },
 {
  "CNKEY": "560210190080",
  "CN": "5602 10 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "560210310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Productos obtenidos mediante costura por cadeneta"
 },
 {
  "CNKEY": "560210310080",
  "CN": "5602 10 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "560210380080",
  "CN": "5602 10 38",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "560210900080",
  "CN": "5602 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Impregnados, recubiertos, revestidos o estratificados"
 },
 {
  "CNKEY": "560221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás fieltros sin impregnar, recubrir, revestir ni estratificar"
 },
 {
  "CNKEY": "560221000080",
  "CN": "5602 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "560229000080",
  "CN": "5602 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "560290000080",
  "CN": "5602 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560300000080",
  "CN": "5603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tela sin tejer, incluso impregnada, recubierta, revestida o estratificada"
 },
 {
  "CNKEY": "560311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De filamentos sintéticos o artificiales"
 },
 {
  "CNKEY": "560311000080",
  "CN": "5603 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso inferior o igual a 25 g\/m²"
 },
 {
  "CNKEY": "560311100080",
  "CN": "5603 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560311900080",
  "CN": "5603 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560312000080",
  "CN": "5603 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 25 g\/m² pero inferior o igual a 70 g\/m²"
 },
 {
  "CNKEY": "560312100080",
  "CN": "5603 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560312900080",
  "CN": "5603 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560313000080",
  "CN": "5603 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 70 g\/m² pero inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "560313100080",
  "CN": "5603 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560313900080",
  "CN": "5603 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560314000080",
  "CN": "5603 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "560314100080",
  "CN": "5603 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560314900080",
  "CN": "5603 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "560391000080",
  "CN": "5603 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso inferior o igual a 25 g\/m²"
 },
 {
  "CNKEY": "560391100080",
  "CN": "5603 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560391900080",
  "CN": "5603 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560392000080",
  "CN": "5603 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 25 g\/m² pero inferior o igual a 70 g\/m²"
 },
 {
  "CNKEY": "560392100080",
  "CN": "5603 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560392900080",
  "CN": "5603 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560393000080",
  "CN": "5603 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 70 g\/m² pero inferior o igual a 150 g\/m²"
 },
 {
  "CNKEY": "560393100080",
  "CN": "5603 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560393900080",
  "CN": "5603 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560394000080",
  "CN": "5603 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior a 150 g\/m²"
 },
 {
  "CNKEY": "560394100080",
  "CN": "5603 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Recubiertas o revestidas"
 },
 {
  "CNKEY": "560394900080",
  "CN": "5603 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560400000080",
  "CN": "5604",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilos y cuerdas de caucho revestidos de textiles; hilados de textiles, tiras y formas similares de las partidas 5404 o 5405, impregnados, recubiertos, revestidos o enfundados con caucho o plástico"
 },
 {
  "CNKEY": "560410000080",
  "CN": "5604 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilos y cuerdas de caucho revestidos de textiles"
 },
 {
  "CNKEY": "560490000080",
  "CN": "5604 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "560490100080",
  "CN": "5604 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hilados de alta tenacidad de poliésteres, de nailon o demás poliamidas o de rayón viscosa, impregnados o recubiertos"
 },
 {
  "CNKEY": "560490900080",
  "CN": "5604 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560500000080",
  "CN": "5605 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Hilados metálicos e hilados metalizados, incluso entorchados, constituidos por hilados textiles, tiras o formas similares de las partidas 5404 o 5405, combinados con metal en forma de hilos, tiras o polvo, o revestidos de metal"
 },
 {
  "CNKEY": "560600000080",
  "CN": "5606 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilados entorchados, tiras y formas similares de las partidas 5404 o 5405, entorchadas (excepto los de la partida 5605 y los hilados de crin entorchados); hilados de chenilla; hilados «de cadeneta»"
 },
 {
  "CNKEY": "560600100080",
  "CN": "5606 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hilados «de cadeneta»"
 },
 {
  "CNKEY": "560600910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "560600910080",
  "CN": "5606 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hilados entorchados"
 },
 {
  "CNKEY": "560600990080",
  "CN": "5606 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560700000080",
  "CN": "5607",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cordeles, cuerdas y cordajes, estén o no trenzados, incluso impregnados, recubiertos, revestidos o enfundados con caucho o plástico"
 },
 {
  "CNKEY": "560721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De sisal o demás fibras textiles del género Agave"
 },
 {
  "CNKEY": "560721000080",
  "CN": "5607 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cordeles para atar o engavillar"
 },
 {
  "CNKEY": "560729000080",
  "CN": "5607 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560741000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De polietileno o polipropileno"
 },
 {
  "CNKEY": "560741000080",
  "CN": "5607 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cordeles para atar o engavillar"
 },
 {
  "CNKEY": "560749000080",
  "CN": "5607 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "560749110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De título superior a 50000 decitex (5 gramos por metro)"
 },
 {
  "CNKEY": "560749110080",
  "CN": "5607 49 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Trenzados"
 },
 {
  "CNKEY": "560749190080",
  "CN": "5607 49 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560749900080",
  "CN": "5607 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior o igual a 50000 decitex (5 gramos por metro)"
 },
 {
  "CNKEY": "560750000080",
  "CN": "5607 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás fibras sintéticas"
 },
 {
  "CNKEY": "560750110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De nailon o de otras poliamidas, o de poliésteres"
 },
 {
  "CNKEY": "560750110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De título superior a 50000 decitex (5 gramos por metro)"
 },
 {
  "CNKEY": "560750110080",
  "CN": "5607 50 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Trenzados"
 },
 {
  "CNKEY": "560750190080",
  "CN": "5607 50 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560750300080",
  "CN": "5607 50 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De título inferior o igual a 50000 decitex (5 gramos por metro)"
 },
 {
  "CNKEY": "560750900080",
  "CN": "5607 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás fibras sintéticas"
 },
 {
  "CNKEY": "560790000080",
  "CN": "5607 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "560790200080",
  "CN": "5607 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De abacá (cáñamo de Manila o Musa textilisNee) o demás fibras duras de hojas; de yute o demás fibras textiles del líber de la partida 5303"
 },
 {
  "CNKEY": "560790900080",
  "CN": "5607 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "560800000080",
  "CN": "5608",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Redes de mallas anudadas, en paño o en pieza, fabricadas con cordeles, cuerdas o cordajes; redes confeccionadas para la pesca y demás redes confeccionadas, de materia textil"
 },
 {
  "CNKEY": "560811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De materia textil sintética o artificial"
 },
 {
  "CNKEY": "560811000080",
  "CN": "5608 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Redes confeccionadas para la pesca"
 },
 {
  "CNKEY": "560811200080",
  "CN": "5608 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cordeles, cuerdas o cordajes"
 },
 {
  "CNKEY": "560811800080",
  "CN": "5608 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560819000080",
  "CN": "5608 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "560819110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Redes confeccionadas"
 },
 {
  "CNKEY": "560819110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De nailon o de otras poliamidas"
 },
 {
  "CNKEY": "560819110080",
  "CN": "5608 19 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De cordeles, cuerdas o cordajes"
 },
 {
  "CNKEY": "560819190080",
  "CN": "5608 19 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560819300080",
  "CN": "5608 19 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560819900080",
  "CN": "5608 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560890000080",
  "CN": "5608 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "560900000080",
  "CN": "5609 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Artículos de hilados, tiras o formas similares de las partidas 5404 o 5405, cordeles, cuerdas o cordajes, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "570021000090",
  "CN": "57",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 57 - ALFOMBRAS Y DEMÁS REVESTIMIENTOS PARA EL SUELO, DE MATERIA TEXTIL"
 },
 {
  "CNKEY": "570100000080",
  "CN": "5701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alfombras de nudo de materia textil, incluso confeccionadas"
 },
 {
  "CNKEY": "570110000080",
  "CN": "5701 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570110100080",
  "CN": "5701 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un contenido de seda o de borra de seda (schappe) superior al 10 % en peso total"
 },
 {
  "CNKEY": "570110900080",
  "CN": "5701 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "570190000080",
  "CN": "5701 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570190100080",
  "CN": "5701 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De seda, borra de seda (schappe), fibras textiles sintéticas, hilados de la partida 5605 o de materia textil con hilos de metal incorporados"
 },
 {
  "CNKEY": "570190900080",
  "CN": "5701 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570200000080",
  "CN": "5702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alfombras y demás revestimientos para el suelo, de materia textil, tejidos (excepto los de mechón insertado y los flocados), aunque estén confeccionados, incluidas las alfombras llamadas «Kelim» o «Kilim», «Schumacks» o «Soumak», «Karamanie» y alfombras similares tejidas a mano"
 },
 {
  "CNKEY": "570210000080",
  "CN": "5702 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Alfombras llamadas «Kelim» o «Kilim», «Schumacks» o «Soumak», «Karamanie» y alfombras similares tejidas a mano"
 },
 {
  "CNKEY": "570220000080",
  "CN": "5702 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Revestimientos para el suelo de fibras de coco"
 },
 {
  "CNKEY": "570231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, aterciopelados, sin confeccionar"
 },
 {
  "CNKEY": "570231000080",
  "CN": "5702 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570231100080",
  "CN": "5702 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Alfombras Axminster"
 },
 {
  "CNKEY": "570231800080",
  "CN": "5702 31 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570232000080",
  "CN": "5702 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De materia textil sintética o artificial"
 },
 {
  "CNKEY": "570239000080",
  "CN": "5702 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, aterciopelados, confeccionados"
 },
 {
  "CNKEY": "570241000080",
  "CN": "5702 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570241100080",
  "CN": "5702 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Alfombras Axminster"
 },
 {
  "CNKEY": "570241900080",
  "CN": "5702 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570242000080",
  "CN": "5702 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De materia textil sintética o artificial"
 },
 {
  "CNKEY": "570249000080",
  "CN": "5702 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570250000080",
  "CN": "5702 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, sin aterciopelar ni confeccionar"
 },
 {
  "CNKEY": "570250100080",
  "CN": "5702 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570250310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De materia textil sintética o artificial"
 },
 {
  "CNKEY": "570250310080",
  "CN": "5702 50 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "570250390080",
  "CN": "5702 50 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570250900080",
  "CN": "5702 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, sin aterciopelar, confeccionados"
 },
 {
  "CNKEY": "570291000080",
  "CN": "5702 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570292000080",
  "CN": "5702 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De materia textil sintética o artificial"
 },
 {
  "CNKEY": "570292100080",
  "CN": "5702 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "570292900080",
  "CN": "5702 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570299000080",
  "CN": "5702 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570300000080",
  "CN": "5703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alfombras y demás revestimientos para el suelo (incluido el césped), de materia textil, con mechón insertado, incluso confeccionados"
 },
 {
  "CNKEY": "570310000080",
  "CN": "5703 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "570321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "570321000080",
  "CN": "5703 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Césped"
 },
 {
  "CNKEY": "570329000080",
  "CN": "5703 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "570329100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Estampados"
 },
 {
  "CNKEY": "570329100080",
  "CN": "5703 29 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570329190080",
  "CN": "5703 29 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570329910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "570329910080",
  "CN": "5703 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570329990080",
  "CN": "5703 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles sintéticas o de materias textil artificiales"
 },
 {
  "CNKEY": "570331000080",
  "CN": "5703 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Césped"
 },
 {
  "CNKEY": "570339000080",
  "CN": "5703 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "570339100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De polipropileno"
 },
 {
  "CNKEY": "570339100080",
  "CN": "5703 39 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570339190080",
  "CN": "5703 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570339910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "570339910080",
  "CN": "5703 39 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570339990080",
  "CN": "5703 39 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570390000080",
  "CN": "5703 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "570390200080",
  "CN": "5703 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570390800080",
  "CN": "5703 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570400000080",
  "CN": "5704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alfombras y demás revestimientos para el suelo, de fieltro (excepto los de mechón insertado y los flocados), incluso confeccionados"
 },
 {
  "CNKEY": "570410000080",
  "CN": "5704 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De superficie inferior o igual a 0,3 m²"
 },
 {
  "CNKEY": "570420000080",
  "CN": "5704 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De superficie superior a 0,3 m² pero inferior o igual a 1 m²"
 },
 {
  "CNKEY": "570490000080",
  "CN": "5704 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "570500000080",
  "CN": "5705 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás alfombras y revestimientos para el suelo, de materia textil, incluso confeccionados"
 },
 {
  "CNKEY": "570500300080",
  "CN": "5705 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De materia textil sintetica o artificial"
 },
 {
  "CNKEY": "570500800080",
  "CN": "5705 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "580021000090",
  "CN": "58",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 58 - TEJIDOS ESPECIALES; SUPERFICIES TEXTILES CON MECHÓN INSERTADO; ENCAJES; TAPICERÍA; PASAMANERÍA; BORDADOS"
 },
 {
  "CNKEY": "580100000080",
  "CN": "5801",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Terciopelo y felpa (excepto los de punto), y tejidos de chenilla (excepto los productos de las partidas 5802 o 5806)"
 },
 {
  "CNKEY": "580110000080",
  "CN": "5801 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "580121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "580121000080",
  "CN": "5801 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por trama, sin cortar"
 },
 {
  "CNKEY": "580122000080",
  "CN": "5801 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por trama, cortados, rayados (pana rayada, corduroy)"
 },
 {
  "CNKEY": "580123000080",
  "CN": "5801 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás terciopelos y felpas por trama"
 },
 {
  "CNKEY": "580126000080",
  "CN": "5801 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Tejidos de chenilla"
 },
 {
  "CNKEY": "580127000080",
  "CN": "5801 27 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por urdimbre"
 },
 {
  "CNKEY": "580131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "580131000080",
  "CN": "5801 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por trama, sin cortar"
 },
 {
  "CNKEY": "580132000080",
  "CN": "5801 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por trama, cortados, rayados (pana rayada, corduroy)"
 },
 {
  "CNKEY": "580133000080",
  "CN": "5801 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás terciopelos y felpas por trama"
 },
 {
  "CNKEY": "580136000080",
  "CN": "5801 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Tejidos de chenilla"
 },
 {
  "CNKEY": "580137000080",
  "CN": "5801 37 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Terciopelo y felpa por urdimbre"
 },
 {
  "CNKEY": "580190000080",
  "CN": "5801 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "580190100080",
  "CN": "5801 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De lino"
 },
 {
  "CNKEY": "580190900080",
  "CN": "5801 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580200000080",
  "CN": "5802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos con bucles del tipo toalla (excepto los productos de la partida 5806); superficies textiles con mechón insertado (excepto los productos de la partida 5703)"
 },
 {
  "CNKEY": "580210000080",
  "CN": "5802 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos con bucles del tipo toalla, de algodón"
 },
 {
  "CNKEY": "580220000080",
  "CN": "5802 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Tejidos con bucles del tipo toalla, de las demás materias textiles"
 },
 {
  "CNKEY": "580230000080",
  "CN": "5802 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Superficies textiles con mechón insertado"
 },
 {
  "CNKEY": "580300000080",
  "CN": "5803 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de gasa de vuelta (excepto los productos de la partida 5806)"
 },
 {
  "CNKEY": "580300100080",
  "CN": "5803 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De algodón"
 },
 {
  "CNKEY": "580300300080",
  "CN": "5803 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "De seda o de desperdicios de seda"
 },
 {
  "CNKEY": "580300900080",
  "CN": "5803 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580400000080",
  "CN": "5804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tul, tul-bobinot y tejidos de mallas anudadas; encajes en pieza, en tiras o en aplicaciones (excepto los productos de las partidas 6002 a 6006)"
 },
 {
  "CNKEY": "580410000080",
  "CN": "5804 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tul, tul-bobinot y tejidos de mallas anudadas"
 },
 {
  "CNKEY": "580410100080",
  "CN": "5804 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lisos"
 },
 {
  "CNKEY": "580410900080",
  "CN": "5804 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Encajes fabricados a máquina"
 },
 {
  "CNKEY": "580421000080",
  "CN": "5804 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "580429000080",
  "CN": "5804 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "580430000080",
  "CN": "5804 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Encajes hechos a mano"
 },
 {
  "CNKEY": "580500000080",
  "CN": "5805 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tapicería tejida a mano (gobelinos, Flandes, Aubusson, Beauvais y similares) y tapicería de aguja (por ejemplo: de petit point, de punto de cruz), incluso confeccionadas"
 },
 {
  "CNKEY": "580600000080",
  "CN": "5806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cintas (excepto los artículos de la partida 5807); cintas sin trama, de hilados o fibras paralelizados y aglutinados"
 },
 {
  "CNKEY": "580610000080",
  "CN": "5806 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cintas de terciopelo, de felpa, de tejidos de chenilla o de tejidos con bucles del tipo toalla"
 },
 {
  "CNKEY": "580620000080",
  "CN": "5806 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás cintas, con un contenido de hilos de elastómeros o de hilos de caucho superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "580631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás cintas"
 },
 {
  "CNKEY": "580631000080",
  "CN": "5806 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "580632000080",
  "CN": "5806 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "580632100080",
  "CN": "5806 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con orillos verdaderos"
 },
 {
  "CNKEY": "580632900080",
  "CN": "5806 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "580639000080",
  "CN": "5806 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "580640000080",
  "CN": "5806 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cintas sin trama, de hilados o fibras paralelizados y aglutinados"
 },
 {
  "CNKEY": "580700000080",
  "CN": "5807",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Etiquetas, escudos y artículos similares, de materia textil, en pieza, en cintas o recortados, sin bordar"
 },
 {
  "CNKEY": "580710000080",
  "CN": "5807 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tejidos"
 },
 {
  "CNKEY": "580710100080",
  "CN": "5807 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con inscripciones o motivos, tejidos"
 },
 {
  "CNKEY": "580710900080",
  "CN": "5807 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580790000080",
  "CN": "5807 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "580790100080",
  "CN": "5807 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fieltro o de tela sin tejer"
 },
 {
  "CNKEY": "580790900080",
  "CN": "5807 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580800000080",
  "CN": "5808",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trenzas en pieza; artículos de pasamanería y artículos ornamentales análogos, en pieza, sin bordar (excepto los de punto); bellotas, madroños, pompones, borlas y artículos similares"
 },
 {
  "CNKEY": "580810000080",
  "CN": "5808 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trenzas en pieza"
 },
 {
  "CNKEY": "580890000080",
  "CN": "5808 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "580900000080",
  "CN": "5809 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tejidos de hilos de metal y tejidos de hilados metálicos o de hilados textiles metalizados de la partida 5605, de los tipos utilizados en prendas de vestir, tapicería o usos similares, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "581000000080",
  "CN": "5810",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bordados en pieza, en tiras o en aplicaciones"
 },
 {
  "CNKEY": "581010000080",
  "CN": "5810 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bordados químicos o aéreos y bordados con fondo recortado"
 },
 {
  "CNKEY": "581010100080",
  "CN": "5810 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De valor superior a 35 € por kg de peso neto"
 },
 {
  "CNKEY": "581010900080",
  "CN": "5810 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "581091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás bordados"
 },
 {
  "CNKEY": "581091000080",
  "CN": "5810 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "581091100080",
  "CN": "5810 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De valor superior a 17,50 € por kg de peso neto"
 },
 {
  "CNKEY": "581091900080",
  "CN": "5810 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "581092000080",
  "CN": "5810 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "581092100080",
  "CN": "5810 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De valor superior a 17,50 € por kg de peso neto"
 },
 {
  "CNKEY": "581092900080",
  "CN": "5810 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "581099000080",
  "CN": "5810 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "581099100080",
  "CN": "5810 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De valor superior a 17,50 € por kg de peso neto"
 },
 {
  "CNKEY": "581099900080",
  "CN": "5810 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "581100000080",
  "CN": "5811 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "m²",
  "ES": "Productos textiles acolchados en pieza, constituidos por una o varias capas de materia textil combinadas con una materia de relleno y mantenidas mediante puntadas u otra forma de sujeción (excepto los bordados de la partida 5810)"
 },
 {
  "CNKEY": "590021000090",
  "CN": "59",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 59 - TELAS IMPREGNADAS, RECUBIERTAS, REVESTIDAS O ESTRATIFICADAS; ARTÍCULOS TÉCNICOS DE MATERIA TEXTIL"
 },
 {
  "CNKEY": "590100000080",
  "CN": "5901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Telas recubiertas de cola o materias amiláceas, de los tipos utilizados para encuadernación, cartonaje, estuchería o usos similares; transparentes textiles para calcar o dibujar; lienzos preparados para pintar; bucarán y telas rígidas similares de los tipos utilizados en sombrerería"
 },
 {
  "CNKEY": "590110000080",
  "CN": "5901 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Telas recubiertas de cola o materias amiláceas, de los tipos utilizados para encuadernación, cartonaje, estuchería o usos similares"
 },
 {
  "CNKEY": "590190000080",
  "CN": "5901 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "590200000080",
  "CN": "5902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Napas tramadas para neumáticos fabricadas con hilados de alta tenacidad de nailon o demás poliamidas, de poliésteres o de rayón viscosa"
 },
 {
  "CNKEY": "590210000080",
  "CN": "5902 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De nailon o demás poliamidas"
 },
 {
  "CNKEY": "590210100080",
  "CN": "5902 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas de caucho"
 },
 {
  "CNKEY": "590210900080",
  "CN": "5902 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "590220000080",
  "CN": "5902 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De poliésteres"
 },
 {
  "CNKEY": "590220100080",
  "CN": "5902 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas de caucho"
 },
 {
  "CNKEY": "590220900080",
  "CN": "5902 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "590290000080",
  "CN": "5902 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "590290100080",
  "CN": "5902 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas de caucho"
 },
 {
  "CNKEY": "590290900080",
  "CN": "5902 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "590300000080",
  "CN": "5903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Telas impregnadas, recubiertas, revestidas o estratificadas con plástico (excepto las de la partida 5902)"
 },
 {
  "CNKEY": "590310000080",
  "CN": "5903 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con poli(cloruro de vinilo)"
 },
 {
  "CNKEY": "590310100080",
  "CN": "5903 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas"
 },
 {
  "CNKEY": "590310900080",
  "CN": "5903 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Recubiertas, revestidas o estratificadas"
 },
 {
  "CNKEY": "590320000080",
  "CN": "5903 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con poliuretano"
 },
 {
  "CNKEY": "590320100080",
  "CN": "5903 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas"
 },
 {
  "CNKEY": "590320900080",
  "CN": "5903 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Recubiertas, revestidas o estratificadas"
 },
 {
  "CNKEY": "590390000080",
  "CN": "5903 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "590390100080",
  "CN": "5903 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Impregnadas"
 },
 {
  "CNKEY": "590390910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Recubiertas, revestidas o estratificadas"
 },
 {
  "CNKEY": "590390910080",
  "CN": "5903 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Con derivados de la celulosa u otros plásticos, constituyendo la tela la derecha (cara vista)"
 },
 {
  "CNKEY": "590390990080",
  "CN": "5903 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "590400000080",
  "CN": "5904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Linóleo, incluso cortado; revestimientos para el suelo formados por un recubrimiento o revestimiento aplicado sobre un soporte textil, incluso cortados"
 },
 {
  "CNKEY": "590410000080",
  "CN": "5904 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Linóleo"
 },
 {
  "CNKEY": "590490000080",
  "CN": "5904 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "590500000080",
  "CN": "5905 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Revestimientos de materia textil para paredes"
 },
 {
  "CNKEY": "590500100080",
  "CN": "5905 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con hilos dispuestos paralelamente en un soporte"
 },
 {
  "CNKEY": "590500300010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "590500300080",
  "CN": "5905 00 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De lino"
 },
 {
  "CNKEY": "590500500080",
  "CN": "5905 00 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De yute"
 },
 {
  "CNKEY": "590500700080",
  "CN": "5905 00 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "590500900080",
  "CN": "5905 00 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "590600000080",
  "CN": "5906",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Telas cauchutadas (excepto las de la partida 5902)"
 },
 {
  "CNKEY": "590610000080",
  "CN": "5906 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cintas adhesivas de anchura inferior o igual a 20 cm"
 },
 {
  "CNKEY": "590691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "590691000080",
  "CN": "5906 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "590699000080",
  "CN": "5906 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "590699100080",
  "CN": "5906 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Napas contempladas en la Nota 5 c) de este Capítulo"
 },
 {
  "CNKEY": "590699900080",
  "CN": "5906 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "590700000080",
  "CN": "5907 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "m²",
  "ES": "Las demás telas impregnadas, recubiertas o revestidas; lienzos pintados para decoraciones de teatro, fondos de estudio o usos análogos"
 },
 {
  "CNKEY": "590800000080",
  "CN": "5908 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Mechas de materia textil tejida, trenzada o de punto, para lámparas, hornillos, mecheros, velas o similares; manguitos de incandescencia y tejidos de punto tubulares utilizados para su fabricación, incluso impregnados"
 },
 {
  "CNKEY": "590900000080",
  "CN": "5909 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mangueras para bombas y tubos similares, de materia textil, incluso con armadura o accesorios de otras materias"
 },
 {
  "CNKEY": "590900100080",
  "CN": "5909 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "590900900080",
  "CN": "5909 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "591000000080",
  "CN": "5910 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Correas transportadoras o de transmisión, de materia textil, incluso impregnadas, recubiertas, revestidas o estratificadas con plástico o reforzadas con metal u otra materia"
 },
 {
  "CNKEY": "591100000080",
  "CN": "5911",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos y artículos textiles para usos técnicos mencionados en la Nota 8 de este Capítulo"
 },
 {
  "CNKEY": "591110000080",
  "CN": "5911 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Telas, fieltro y tejidos forrados de fieltro, combinados con una o varias capas de caucho, cuero u otra materia, de los tipos utilizados para la fabricación de guarniciones de cardas y productos análogos para otros usos técnicos, incluidas las cintas de terciopelo impregnadas de caucho para forrar enjulios"
 },
 {
  "CNKEY": "591120000080",
  "CN": "5911 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Gasas y telas para cerner, incluso confeccionadas"
 },
 {
  "CNKEY": "591131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Telas y fieltros sin fin o con dispositivos de unión, de los tipos utilizados en las máquinas de fabricar papel o en máquinas similares (por ejemplo: para pasta, para amiantocemento)"
 },
 {
  "CNKEY": "591131000080",
  "CN": "5911 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso inferior a 650 g\/m²"
 },
 {
  "CNKEY": "591131110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De seda, de fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "591131110080",
  "CN": "5911 31 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Tejidos de los tipos utilizados en las máquinas de fabricar papel (por ejemplo: formando telas)"
 },
 {
  "CNKEY": "591131190080",
  "CN": "5911 31 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "591131900080",
  "CN": "5911 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "591132000080",
  "CN": "5911 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso superior o igual a 650 g\/m²"
 },
 {
  "CNKEY": "591132110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De seda, de fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "591132110080",
  "CN": "5911 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Tejidos reforzados con capas, de los tipos utilizados en las máquinas de fabricar papel(por ejemplo: fieltro prensado)"
 },
 {
  "CNKEY": "591132190080",
  "CN": "5911 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "591132900080",
  "CN": "5911 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "591140000080",
  "CN": "5911 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Telas filtrantes, tejidos gruesos y capachos de los tipos utilizados en las prensas de aceite o para usos técnicos análogos, incluidos los de cabello"
 },
 {
  "CNKEY": "591190000080",
  "CN": "5911 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "591190100080",
  "CN": "5911 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fieltro"
 },
 {
  "CNKEY": "591190910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "591190910080",
  "CN": "5911 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Almohadillas para pulido circulares autoadherentes del tipo utilizado en la fabricación de obleas semiconductoras"
 },
 {
  "CNKEY": "591190990080",
  "CN": "5911 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600021000090",
  "CN": "60",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 60 - TEJIDOS DE PUNTO"
 },
 {
  "CNKEY": "600100000080",
  "CN": "6001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Terciopelo, felpa (incluidos los tejidos de punto «de pelo largo») y tejidos con bucles, de punto"
 },
 {
  "CNKEY": "600110000080",
  "CN": "6001 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tejidos «de pelo largo»"
 },
 {
  "CNKEY": "600121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tejidos con bucles"
 },
 {
  "CNKEY": "600121000080",
  "CN": "6001 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "600122000080",
  "CN": "6001 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "600129000080",
  "CN": "6001 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "600191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "600191000080",
  "CN": "6001 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "600192000080",
  "CN": "6001 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "600199000080",
  "CN": "6001 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "600200000080",
  "CN": "6002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de punto de anchura inferior o igual a 30 cm, con un contenido de hilados de elastómeros o de hilos de caucho superior o igual al 5 % en peso (excepto los de la partida 6001)"
 },
 {
  "CNKEY": "600240000080",
  "CN": "6002 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de hilados de elástomeros superior o igual al 5 % en peso, sin hilos de caucho"
 },
 {
  "CNKEY": "600290000080",
  "CN": "6002 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600300000080",
  "CN": "6003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de punto de anchura inferior o igual a 30 cm, (excepto los de las partidas 6001 o 6002)"
 },
 {
  "CNKEY": "600310000080",
  "CN": "6003 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "600320000080",
  "CN": "6003 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "600330000080",
  "CN": "6003 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "600330100080",
  "CN": "6003 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Encajes Raschel"
 },
 {
  "CNKEY": "600330900080",
  "CN": "6003 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600340000080",
  "CN": "6003 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "600390000080",
  "CN": "6003 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600400000080",
  "CN": "6004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de punto de anchura superior a 30 cm, con un contenido de hilados de elastómeros o de hilos de caucho superior o igual al 5 % en peso (excepto los de la partida 6001)"
 },
 {
  "CNKEY": "600410000080",
  "CN": "6004 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de hilados de elastómeros superior o igual al 5 % en peso, sin hilos de caucho"
 },
 {
  "CNKEY": "600490000080",
  "CN": "6004 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600500000080",
  "CN": "6005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejidos de punto por urdimbre (incluidos los obtenidos en telares de pasamanería), excepto los de las partidas 6001 a 6004"
 },
 {
  "CNKEY": "600521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "600521000080",
  "CN": "6005 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "600522000080",
  "CN": "6005 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "600523000080",
  "CN": "6005 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "600524000080",
  "CN": "6005 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampados"
 },
 {
  "CNKEY": "600535000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "600535000080",
  "CN": "6005 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos mencionados en la Nota 1 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "600536000080",
  "CN": "6005 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, crudos o blanqueados"
 },
 {
  "CNKEY": "600537000080",
  "CN": "6005 37 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, teñidos"
 },
 {
  "CNKEY": "600538000080",
  "CN": "6005 38 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, con hilados de distintos colores"
 },
 {
  "CNKEY": "600539000080",
  "CN": "6005 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, estampados"
 },
 {
  "CNKEY": "600541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "600541000080",
  "CN": "6005 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "600542000080",
  "CN": "6005 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "600543000080",
  "CN": "6005 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "600544000080",
  "CN": "6005 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampados"
 },
 {
  "CNKEY": "600590000080",
  "CN": "6005 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "600590100080",
  "CN": "6005 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "600590900080",
  "CN": "6005 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "600600000080",
  "CN": "6006",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tejidos de punto"
 },
 {
  "CNKEY": "600610000080",
  "CN": "6006 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "600621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "600621000080",
  "CN": "6006 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "600622000080",
  "CN": "6006 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "600623000080",
  "CN": "6006 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "600624000080",
  "CN": "6006 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampados"
 },
 {
  "CNKEY": "600631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "600631000080",
  "CN": "6006 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "600632000080",
  "CN": "6006 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "600633000080",
  "CN": "6006 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "600634000080",
  "CN": "6006 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampados"
 },
 {
  "CNKEY": "600641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "600641000080",
  "CN": "6006 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crudos o blanqueados"
 },
 {
  "CNKEY": "600642000080",
  "CN": "6006 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Teñidos"
 },
 {
  "CNKEY": "600643000080",
  "CN": "6006 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con hilados de distintos colores"
 },
 {
  "CNKEY": "600644000080",
  "CN": "6006 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estampados"
 },
 {
  "CNKEY": "600690000080",
  "CN": "6006 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "610021000090",
  "CN": "61",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 61 - PRENDAS Y COMPLEMENTOS (ACCESORIOS), DE VESTIR, DE PUNTO"
 },
 {
  "CNKEY": "610100000080",
  "CN": "6101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abrigos, chaquetones, capas, anoraks, cazadoras y artículos similares, de punto, para hombres o niños (excepto los artículos de la partida 6103)"
 },
 {
  "CNKEY": "610120000080",
  "CN": "6101 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "610120100080",
  "CN": "6101 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610120900080",
  "CN": "6101 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610130000080",
  "CN": "6101 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610130100080",
  "CN": "6101 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610130900080",
  "CN": "6101 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610190000080",
  "CN": "6101 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610190200080",
  "CN": "6101 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610190800080",
  "CN": "6101 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610200000080",
  "CN": "6102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abrigos, chaquetones, capas, anoraks, cazadoras y artículos similares, de punto, para mujeres o niñas (excepto los artículos de la partida 6104)"
 },
 {
  "CNKEY": "610210000080",
  "CN": "6102 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610210100080",
  "CN": "6102 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610210900080",
  "CN": "6102 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610220000080",
  "CN": "6102 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "610220100080",
  "CN": "6102 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610220900080",
  "CN": "6102 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610230000080",
  "CN": "6102 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610230100080",
  "CN": "6102 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610230900080",
  "CN": "6102 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610290000080",
  "CN": "6102 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610290100080",
  "CN": "6102 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abrigos, chaquetones, capas y artículos similares"
 },
 {
  "CNKEY": "610290900080",
  "CN": "6102 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Anoraks, cazadoras y artículos similares"
 },
 {
  "CNKEY": "610300000080",
  "CN": "6103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trajes (ambos o ternos), conjuntos, chaquetas (sacos), pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts (excepto de baño), de punto, para hombres o niños"
 },
 {
  "CNKEY": "610310000080",
  "CN": "6103 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trajes (ambos o ternos)"
 },
 {
  "CNKEY": "610310100080",
  "CN": "6103 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610310900080",
  "CN": "6103 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610322000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conjuntos"
 },
 {
  "CNKEY": "610322000080",
  "CN": "6103 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610323000080",
  "CN": "6103 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610329000080",
  "CN": "6103 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chaquetas (sacos)"
 },
 {
  "CNKEY": "610331000080",
  "CN": "6103 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610332000080",
  "CN": "6103 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610333000080",
  "CN": "6103 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610339000080",
  "CN": "6103 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts"
 },
 {
  "CNKEY": "610341000080",
  "CN": "6103 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610342000080",
  "CN": "6103 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610343000080",
  "CN": "6103 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610349000080",
  "CN": "6103 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610400000080",
  "CN": "6104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trajes sastre, conjuntos, chaquetas (sacos), vestidos, faldas, faldas pantalón, pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts (excepto de baño), de punto, para mujeres o niñas"
 },
 {
  "CNKEY": "610413000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trajes sastre"
 },
 {
  "CNKEY": "610413000080",
  "CN": "6104 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610419000080",
  "CN": "6104 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610419200080",
  "CN": "6104 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610419900080",
  "CN": "6104 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610422000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conjuntos"
 },
 {
  "CNKEY": "610422000080",
  "CN": "6104 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610423000080",
  "CN": "6104 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610429000080",
  "CN": "6104 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610429100080",
  "CN": "6104 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610429900080",
  "CN": "6104 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chaquetas (sacos)"
 },
 {
  "CNKEY": "610431000080",
  "CN": "6104 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610432000080",
  "CN": "6104 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610433000080",
  "CN": "6104 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610439000080",
  "CN": "6104 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vestidos"
 },
 {
  "CNKEY": "610441000080",
  "CN": "6104 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610442000080",
  "CN": "6104 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610443000080",
  "CN": "6104 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610444000080",
  "CN": "6104 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "610449000080",
  "CN": "6104 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Faldas y faldas pantalón"
 },
 {
  "CNKEY": "610451000080",
  "CN": "6104 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610452000080",
  "CN": "6104 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610453000080",
  "CN": "6104 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610459000080",
  "CN": "6104 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts"
 },
 {
  "CNKEY": "610461000080",
  "CN": "6104 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610462000080",
  "CN": "6104 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610463000080",
  "CN": "6104 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610469000080",
  "CN": "6104 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610500000080",
  "CN": "6105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisas de punto para hombres o niños"
 },
 {
  "CNKEY": "610510000080",
  "CN": "6105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610520000080",
  "CN": "6105 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610520100080",
  "CN": "6105 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "610520900080",
  "CN": "6105 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "610590000080",
  "CN": "6105 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610590100080",
  "CN": "6105 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610590900080",
  "CN": "6105 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610600000080",
  "CN": "6106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisas, blusas y blusas camiseras, de punto, para mujeres o niñas"
 },
 {
  "CNKEY": "610610000080",
  "CN": "6106 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610620000080",
  "CN": "6106 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610690000080",
  "CN": "6106 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610690100080",
  "CN": "6106 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "610690300080",
  "CN": "6106 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De seda o desperdicios de seda"
 },
 {
  "CNKEY": "610690500080",
  "CN": "6106 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lino o de ramio"
 },
 {
  "CNKEY": "610690900080",
  "CN": "6106 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610700000080",
  "CN": "6107",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calzoncillos (incluidos los largos y los slips), camisones, pijamas, albornoces de baño, batas de casa y artículos similares, de punto, para hombres o niños"
 },
 {
  "CNKEY": "610711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzoncillos (incluidos los largos y los slips)"
 },
 {
  "CNKEY": "610711000080",
  "CN": "6107 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610712000080",
  "CN": "6107 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610719000080",
  "CN": "6107 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Camisones y pijamas"
 },
 {
  "CNKEY": "610721000080",
  "CN": "6107 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610722000080",
  "CN": "6107 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610729000080",
  "CN": "6107 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "610791000080",
  "CN": "6107 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610799000080",
  "CN": "6107 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610800000080",
  "CN": "6108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Combinaciones, enaguas, bragas (bombachas, calzones), incluso las que no llegan hasta la cintura, camisones, pijamas, saltos de cama, albornoces de baño, batas de casa y artículos similares, de punto, para mujeres o niñas"
 },
 {
  "CNKEY": "610811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Combinaciones y enaguas"
 },
 {
  "CNKEY": "610811000080",
  "CN": "6108 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610819000080",
  "CN": "6108 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bragas (bombachas, calzones), incluso las que no llegan hasta la cintura"
 },
 {
  "CNKEY": "610821000080",
  "CN": "6108 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610822000080",
  "CN": "6108 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610829000080",
  "CN": "6108 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Camisones y pijamas"
 },
 {
  "CNKEY": "610831000080",
  "CN": "6108 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610832000080",
  "CN": "6108 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610839000080",
  "CN": "6108 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "610891000080",
  "CN": "6108 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610892000080",
  "CN": "6108 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610899000080",
  "CN": "6108 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610900000080",
  "CN": "6109",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "T-shirts y camisetas, de punto"
 },
 {
  "CNKEY": "610910000080",
  "CN": "6109 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "610990000080",
  "CN": "6109 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "610990200080",
  "CN": "6109 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino o fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "610990900080",
  "CN": "6109 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611000000080",
  "CN": "6110",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Suéteres (jerseys), pulóveres, cardigan, chalecos y artículos similares, de punto"
 },
 {
  "CNKEY": "611011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "611011000080",
  "CN": "6110 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana"
 },
 {
  "CNKEY": "611011100080",
  "CN": "6110 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Suéteres (jerseys) y pulóveres con un contenido de lana superior o igual al 50 % en peso y con un peso superior o igual a 600 g"
 },
 {
  "CNKEY": "611011300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611011300080",
  "CN": "6110 11 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "611011900080",
  "CN": "6110 11 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "611012000080",
  "CN": "6110 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cabra de Cachemira"
 },
 {
  "CNKEY": "611012100080",
  "CN": "6110 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "611012900080",
  "CN": "6110 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "611019000080",
  "CN": "6110 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611019100080",
  "CN": "6110 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "611019900080",
  "CN": "6110 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "611020000080",
  "CN": "6110 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "611020100080",
  "CN": "6110 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Prendas de cuello de cisne"
 },
 {
  "CNKEY": "611020910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611020910080",
  "CN": "6110 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "611020990080",
  "CN": "6110 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "611030000080",
  "CN": "6110 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "611030100080",
  "CN": "6110 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Prendas de cuello de cisne"
 },
 {
  "CNKEY": "611030910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611030910080",
  "CN": "6110 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "611030990080",
  "CN": "6110 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "611090000080",
  "CN": "6110 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611090100080",
  "CN": "6110 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lino o de ramio"
 },
 {
  "CNKEY": "611090900080",
  "CN": "6110 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611100000080",
  "CN": "6111",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas y complementos (accesorios), de vestir, de punto, para bebés"
 },
 {
  "CNKEY": "611120000080",
  "CN": "6111 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "611120100080",
  "CN": "6111 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Guantes"
 },
 {
  "CNKEY": "611120900080",
  "CN": "6111 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611130000080",
  "CN": "6111 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611130100080",
  "CN": "6111 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Guantes"
 },
 {
  "CNKEY": "611130900080",
  "CN": "6111 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611190000080",
  "CN": "6111 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611190110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "611190110080",
  "CN": "6111 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Guantes"
 },
 {
  "CNKEY": "611190190080",
  "CN": "6111 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611190900080",
  "CN": "6111 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611200000080",
  "CN": "6112",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), monos (overoles) y conjuntos de esquí y bañadores, de punto"
 },
 {
  "CNKEY": "611211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales)"
 },
 {
  "CNKEY": "611211000080",
  "CN": "6112 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "611212000080",
  "CN": "6112 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611219000080",
  "CN": "6112 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611220000080",
  "CN": "6112 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Monos (overoles) y conjuntos de esquí"
 },
 {
  "CNKEY": "611231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bañadores para hombres o niños"
 },
 {
  "CNKEY": "611231000080",
  "CN": "6112 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611231100080",
  "CN": "6112 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con un contenido de hilos de caucho superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "611231900080",
  "CN": "6112 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611239000080",
  "CN": "6112 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611239100080",
  "CN": "6112 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con un contenido de hilos de caucho superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "611239900080",
  "CN": "6112 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bañadores para mujeres o niñas"
 },
 {
  "CNKEY": "611241000080",
  "CN": "6112 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611241100080",
  "CN": "6112 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con un contenido de hilos de caucho superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "611241900080",
  "CN": "6112 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611249000080",
  "CN": "6112 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611249100080",
  "CN": "6112 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con un contenido de hilos de caucho superior o igual al 5 % en peso"
 },
 {
  "CNKEY": "611249900080",
  "CN": "6112 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611300000080",
  "CN": "6113 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas de vestir confeccionadas con tejidos de punto de las partidas 5903, 5906 o 5907"
 },
 {
  "CNKEY": "611300100080",
  "CN": "6113 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con tejidos de punto de la partida 5906"
 },
 {
  "CNKEY": "611300900080",
  "CN": "6113 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611400000080",
  "CN": "6114",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás prendas de vestir, de punto"
 },
 {
  "CNKEY": "611420000080",
  "CN": "6114 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "611430000080",
  "CN": "6114 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "611490000080",
  "CN": "6114 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611500000080",
  "CN": "6115",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calzas, panty-medias, leotardos, medias, calcetines y demás artículos de calcetería, incluso de compresión progresiva (por ejemplo, medias para varices), de punto"
 },
 {
  "CNKEY": "611510000080",
  "CN": "6115 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzas, panty-medias, leotardos y medias de compresión progresiva (por ejemplo, medias para varices)"
 },
 {
  "CNKEY": "611510100080",
  "CN": "6115 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611510900080",
  "CN": "6115 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "611521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás calzas, panty-medias y leotardos"
 },
 {
  "CNKEY": "611521000080",
  "CN": "6115 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas, de título inferior a 67 decitex por hilo sencillo"
 },
 {
  "CNKEY": "611522000080",
  "CN": "6115 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas, de título superior o igual a 67 decitex por hilo sencillo"
 },
 {
  "CNKEY": "611529000080",
  "CN": "6115 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611530000080",
  "CN": "6115 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás medias de mujer, de título inferior a 67 decitex por hilo sencillo"
 },
 {
  "CNKEY": "611530110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611530110080",
  "CN": "6115 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Que cubren hasta la rodilla"
 },
 {
  "CNKEY": "611530190080",
  "CN": "6115 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Las demás"
 },
 {
  "CNKEY": "611530900080",
  "CN": "6115 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611594000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611594000080",
  "CN": "6115 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "611595000080",
  "CN": "6115 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De algodón"
 },
 {
  "CNKEY": "611596000080",
  "CN": "6115 96",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611596100080",
  "CN": "6115 96 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Calcetines"
 },
 {
  "CNKEY": "611596910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611596910080",
  "CN": "6115 96 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Medias para mujeres"
 },
 {
  "CNKEY": "611596990080",
  "CN": "6115 96 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611599000080",
  "CN": "6115 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611600000080",
  "CN": "6116",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Guantes, mitones y manoplas, de punto"
 },
 {
  "CNKEY": "611610000080",
  "CN": "6116 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Impregnados, recubiertos, revestidos o estratificados, con plástico o caucho"
 },
 {
  "CNKEY": "611610200080",
  "CN": "6116 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Guantes impregnados, recubiertos o revestidos con caucho"
 },
 {
  "CNKEY": "611610800080",
  "CN": "6116 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "611691000080",
  "CN": "6116 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "611692000080",
  "CN": "6116 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De algodón"
 },
 {
  "CNKEY": "611693000080",
  "CN": "6116 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "611699000080",
  "CN": "6116 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "611700000080",
  "CN": "6117",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás complementos (accesorios) de vestir confeccionados, de punto; partes de prendas o de complementos (accesorios), de vestir, de punto"
 },
 {
  "CNKEY": "611710000080",
  "CN": "6117 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Chales, pañuelos de cuello, bufandas, mantillas, velos y artículos similares"
 },
 {
  "CNKEY": "611780000080",
  "CN": "6117 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás complementos (accesorios) de vestir"
 },
 {
  "CNKEY": "611780100080",
  "CN": "6117 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De punto, elásticos o cauchutados"
 },
 {
  "CNKEY": "611780800080",
  "CN": "6117 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "611790000080",
  "CN": "6117 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "620021000090",
  "CN": "62",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 62 - PRENDAS Y COMPLEMENTOS (ACCESORIOS), DE VESTIR, EXCEPTO LOS DE PUNTO"
 },
 {
  "CNKEY": "620100000080",
  "CN": "6201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abrigos, chaquetones, capas, anoraks, cazadoras y artículos similares, para hombres o niños, excepto los artículos de la partida 6203"
 },
 {
  "CNKEY": "620120000080",
  "CN": "6201 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620130000080",
  "CN": "6201 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620130100080",
  "CN": "6201 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, inferior o igual a 1 kg"
 },
 {
  "CNKEY": "620130900080",
  "CN": "6201 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, superior a 1 kg"
 },
 {
  "CNKEY": "620140000080",
  "CN": "6201 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620140100080",
  "CN": "6201 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, inferior o igual a 1 kg"
 },
 {
  "CNKEY": "620140900080",
  "CN": "6201 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, superior a 1 kg"
 },
 {
  "CNKEY": "620190000080",
  "CN": "6201 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620200000080",
  "CN": "6202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abrigos, chaquetones, capas, anoraks, cazadoras y artículos similares, para mujeres o niñas, excepto los artículos de la partida 6204"
 },
 {
  "CNKEY": "620220000080",
  "CN": "6202 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620230000080",
  "CN": "6202 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620230100080",
  "CN": "6202 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, inferior o igual a 1 kg"
 },
 {
  "CNKEY": "620230900080",
  "CN": "6202 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, superior a 1 kg"
 },
 {
  "CNKEY": "620240000080",
  "CN": "6202 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620240100080",
  "CN": "6202 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, inferior o igual a 1 kg"
 },
 {
  "CNKEY": "620240900080",
  "CN": "6202 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso por unidad, superior a 1 kg"
 },
 {
  "CNKEY": "620290000080",
  "CN": "6202 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620300000080",
  "CN": "6203",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trajes (ambos o ternos), conjuntos, chaquetas (sacos), pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts (excepto de baño), para hombres o niños"
 },
 {
  "CNKEY": "620311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trajes (ambos o ternos)"
 },
 {
  "CNKEY": "620311000080",
  "CN": "6203 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620312000080",
  "CN": "6203 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620319000080",
  "CN": "6203 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620319100080",
  "CN": "6203 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620319300080",
  "CN": "6203 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620319900080",
  "CN": "6203 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620322000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conjuntos"
 },
 {
  "CNKEY": "620322000080",
  "CN": "6203 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620322100080",
  "CN": "6203 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620322800080",
  "CN": "6203 22 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620323000080",
  "CN": "6203 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620323100080",
  "CN": "6203 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620323800080",
  "CN": "6203 23 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620329000080",
  "CN": "6203 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620329110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620329110080",
  "CN": "6203 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620329180080",
  "CN": "6203 29 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620329300080",
  "CN": "6203 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620329900080",
  "CN": "6203 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chaquetas (sacos)"
 },
 {
  "CNKEY": "620331000080",
  "CN": "6203 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620332000080",
  "CN": "6203 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620332100080",
  "CN": "6203 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620332900080",
  "CN": "6203 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620333000080",
  "CN": "6203 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620333100080",
  "CN": "6203 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620333900080",
  "CN": "6203 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620339000080",
  "CN": "6203 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620339110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620339110080",
  "CN": "6203 39 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620339190080",
  "CN": "6203 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620339900080",
  "CN": "6203 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts"
 },
 {
  "CNKEY": "620341000080",
  "CN": "6203 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620341100080",
  "CN": "6203 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620341300080",
  "CN": "6203 41 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620341900080",
  "CN": "6203 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620342000080",
  "CN": "6203 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620342110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620342110080",
  "CN": "6203 42 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620342310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "620342310080",
  "CN": "6203 42 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De tejidos llamados «mezclilla (denim)»"
 },
 {
  "CNKEY": "620342330080",
  "CN": "6203 42 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De terciopelo o felpa por trama, cortados o rayados"
 },
 {
  "CNKEY": "620342350080",
  "CN": "6203 42 35",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620342510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620342510080",
  "CN": "6203 42 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620342590080",
  "CN": "6203 42 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620342900080",
  "CN": "6203 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620343000080",
  "CN": "6203 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620343110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620343110080",
  "CN": "6203 43 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620343190080",
  "CN": "6203 43 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620343310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620343310080",
  "CN": "6203 43 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620343390080",
  "CN": "6203 43 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620343900080",
  "CN": "6203 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620349000080",
  "CN": "6203 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620349110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620349110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620349110080",
  "CN": "6203 49 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620349190080",
  "CN": "6203 49 19",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620349310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620349310080",
  "CN": "6203 49 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620349390080",
  "CN": "6203 49 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620349500080",
  "CN": "6203 49 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620349900080",
  "CN": "6203 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620400000080",
  "CN": "6204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trajes sastre, conjuntos, chaquetas (sacos), vestidos, faldas, faldas pantalón, pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts (excepto de baño), para mujeres o niñas"
 },
 {
  "CNKEY": "620411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trajes sastre"
 },
 {
  "CNKEY": "620411000080",
  "CN": "6204 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620412000080",
  "CN": "6204 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620413000080",
  "CN": "6204 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620419000080",
  "CN": "6204 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620419100080",
  "CN": "6204 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620419900080",
  "CN": "6204 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Conjuntos"
 },
 {
  "CNKEY": "620421000080",
  "CN": "6204 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620422000080",
  "CN": "6204 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620422100080",
  "CN": "6204 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620422800080",
  "CN": "6204 22 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620423000080",
  "CN": "6204 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620423100080",
  "CN": "6204 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620423800080",
  "CN": "6204 23 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620429000080",
  "CN": "6204 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620429110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620429110080",
  "CN": "6204 29 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620429180080",
  "CN": "6204 29 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620429900080",
  "CN": "6204 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chaquetas (sacos)"
 },
 {
  "CNKEY": "620431000080",
  "CN": "6204 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620432000080",
  "CN": "6204 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620432100080",
  "CN": "6204 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620432900080",
  "CN": "6204 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620433000080",
  "CN": "6204 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620433100080",
  "CN": "6204 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620433900080",
  "CN": "6204 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620439000080",
  "CN": "6204 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620439110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620439110080",
  "CN": "6204 39 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620439190080",
  "CN": "6204 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "620439900080",
  "CN": "6204 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vestidos"
 },
 {
  "CNKEY": "620441000080",
  "CN": "6204 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620442000080",
  "CN": "6204 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620443000080",
  "CN": "6204 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620444000080",
  "CN": "6204 44 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620449000080",
  "CN": "6204 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620449100080",
  "CN": "6204 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De seda o desperdicios de seda"
 },
 {
  "CNKEY": "620449900080",
  "CN": "6204 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Faldas y faldas pantalón"
 },
 {
  "CNKEY": "620451000080",
  "CN": "6204 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620452000080",
  "CN": "6204 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620453000080",
  "CN": "6204 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620459000080",
  "CN": "6204 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620459100080",
  "CN": "6204 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620459900080",
  "CN": "6204 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620461000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pantalones largos, pantalones con peto, pantalones cortos (calzones) y shorts"
 },
 {
  "CNKEY": "620461000080",
  "CN": "6204 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620461100080",
  "CN": "6204 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620461850080",
  "CN": "6204 61 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620462000080",
  "CN": "6204 62",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "620462110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620462110080",
  "CN": "6204 62 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620462310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "620462310080",
  "CN": "6204 62 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De tejidos llamados «mezclilla (denim)»"
 },
 {
  "CNKEY": "620462330080",
  "CN": "6204 62 33",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De terciopelo o felpa por trama, cortados o rayados"
 },
 {
  "CNKEY": "620462390080",
  "CN": "6204 62 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620462510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620462510080",
  "CN": "6204 62 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620462590080",
  "CN": "6204 62 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620462900080",
  "CN": "6204 62 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620463000080",
  "CN": "6204 63",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620463110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620463110080",
  "CN": "6204 63 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620463180080",
  "CN": "6204 63 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620463310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620463310080",
  "CN": "6204 63 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620463390080",
  "CN": "6204 63 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620463900080",
  "CN": "6204 63 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620469000080",
  "CN": "6204 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620469110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "620469110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pantalones largos y pantalones cortos (calzones)"
 },
 {
  "CNKEY": "620469110080",
  "CN": "6204 69 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620469180080",
  "CN": "6204 69 18",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620469310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Pantalones con peto"
 },
 {
  "CNKEY": "620469310080",
  "CN": "6204 69 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De trabajo"
 },
 {
  "CNKEY": "620469390080",
  "CN": "6204 69 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620469500080",
  "CN": "6204 69 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "620469900080",
  "CN": "6204 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620500000080",
  "CN": "6205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisas para hombres o niños"
 },
 {
  "CNKEY": "620520000080",
  "CN": "6205 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620530000080",
  "CN": "6205 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620590000080",
  "CN": "6205 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620590100080",
  "CN": "6205 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lino o de ramio"
 },
 {
  "CNKEY": "620590800080",
  "CN": "6205 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620600000080",
  "CN": "6206",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisas, blusas y blusas camiseras, para mujeres o niñas"
 },
 {
  "CNKEY": "620610000080",
  "CN": "6206 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De seda o desperdicios de seda"
 },
 {
  "CNKEY": "620620000080",
  "CN": "6206 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620630000080",
  "CN": "6206 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620640000080",
  "CN": "6206 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620690000080",
  "CN": "6206 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620690100080",
  "CN": "6206 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De lino o ramio"
 },
 {
  "CNKEY": "620690900080",
  "CN": "6206 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620700000080",
  "CN": "6207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisetas, calzoncillos (incluidos los largos y los slip), camisones, pijamas, albornoces de baño, batas de casa y artículos similares, para hombres o niños"
 },
 {
  "CNKEY": "620711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzoncillos (incluidos los largos y los slip)"
 },
 {
  "CNKEY": "620711000080",
  "CN": "6207 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620719000080",
  "CN": "6207 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Camisones y pijamas"
 },
 {
  "CNKEY": "620721000080",
  "CN": "6207 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620722000080",
  "CN": "6207 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620729000080",
  "CN": "6207 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "620791000080",
  "CN": "6207 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620799000080",
  "CN": "6207 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620799100080",
  "CN": "6207 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620799900080",
  "CN": "6207 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620800000080",
  "CN": "6208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Camisetas, combinaciones, enaguas, bragas (bombachas, calzones), incluso las que no llegan hasta la cintura, camisones, pijamas, saltos de cama, albornoces de baño, batas de casa y artículos similares, para mujeres o niñas"
 },
 {
  "CNKEY": "620811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Combinaciones y enaguas"
 },
 {
  "CNKEY": "620811000080",
  "CN": "6208 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620819000080",
  "CN": "6208 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Camisones y pijamas"
 },
 {
  "CNKEY": "620821000080",
  "CN": "6208 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620822000080",
  "CN": "6208 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620829000080",
  "CN": "6208 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "620891000080",
  "CN": "6208 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620892000080",
  "CN": "6208 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "620899000080",
  "CN": "6208 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620900000080",
  "CN": "6209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas y complementos (accesorios), de vestir, para bebés"
 },
 {
  "CNKEY": "620920000080",
  "CN": "6209 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "620930000080",
  "CN": "6209 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "620990000080",
  "CN": "6209 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "620990100080",
  "CN": "6209 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "620990900080",
  "CN": "6209 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621000000080",
  "CN": "6210",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prendas de vestir confeccionadas con productos de las partidas 5602, 5603, 5903, 5906 o 5907"
 },
 {
  "CNKEY": "621010000080",
  "CN": "6210 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con productos de las partidas 5602 o 5603"
 },
 {
  "CNKEY": "621010100080",
  "CN": "6210 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con productos de la partida 5602"
 },
 {
  "CNKEY": "621010920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con productos de la partida 5603"
 },
 {
  "CNKEY": "621010920080",
  "CN": "6210 10 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Batas de un solo uso, del tipo utilizado por pacientes o cirujanos en operaciones quirúrgicas"
 },
 {
  "CNKEY": "621010980080",
  "CN": "6210 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "621020000080",
  "CN": "6210 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás prendas de vestir de los tipos citados en la partida 6201"
 },
 {
  "CNKEY": "621030000080",
  "CN": "6210 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás prendas de vestir de los tipos citados en la partida 6202"
 },
 {
  "CNKEY": "621040000080",
  "CN": "6210 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás prendas de vestir para hombres o niños"
 },
 {
  "CNKEY": "621050000080",
  "CN": "6210 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás prendas de vestir para mujeres o niñas"
 },
 {
  "CNKEY": "621100000080",
  "CN": "6211",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), monos (overoles) y conjuntos de esquí y bañadores; las demás prendas de vestir"
 },
 {
  "CNKEY": "621111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bañadores"
 },
 {
  "CNKEY": "621111000080",
  "CN": "6211 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para hombres o niños"
 },
 {
  "CNKEY": "621112000080",
  "CN": "6211 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para mujeres o niñas"
 },
 {
  "CNKEY": "621120000080",
  "CN": "6211 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Monos (overoles) y conjuntos de esquí"
 },
 {
  "CNKEY": "621132000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás prendas de vestir para hombres o niños"
 },
 {
  "CNKEY": "621132000080",
  "CN": "6211 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "621132100080",
  "CN": "6211 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Prendas de trabajo"
 },
 {
  "CNKEY": "621132310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), con forro"
 },
 {
  "CNKEY": "621132310080",
  "CN": "6211 32 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cuyo exterior esté realizado con un único tejido"
 },
 {
  "CNKEY": "621132410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "621132410080",
  "CN": "6211 32 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes superiores"
 },
 {
  "CNKEY": "621132420080",
  "CN": "6211 32 42",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes inferiores"
 },
 {
  "CNKEY": "621132900080",
  "CN": "6211 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "621133000080",
  "CN": "6211 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "621133100080",
  "CN": "6211 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Prendas de trabajo"
 },
 {
  "CNKEY": "621133310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), con forro"
 },
 {
  "CNKEY": "621133310080",
  "CN": "6211 33 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cuyo exterior esté realizado con un único tejido"
 },
 {
  "CNKEY": "621133410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "621133410080",
  "CN": "6211 33 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes superiores"
 },
 {
  "CNKEY": "621133420080",
  "CN": "6211 33 42",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes inferiores"
 },
 {
  "CNKEY": "621133900080",
  "CN": "6211 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "621139000080",
  "CN": "6211 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621142000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás prendas de vestir para mujeres o niñas"
 },
 {
  "CNKEY": "621142000080",
  "CN": "6211 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De algodón"
 },
 {
  "CNKEY": "621142100080",
  "CN": "6211 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Delantales, batas y las demás prendas de trabajo"
 },
 {
  "CNKEY": "621142310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), con forro"
 },
 {
  "CNKEY": "621142310080",
  "CN": "6211 42 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cuyo exterior esté realizado con un único tejido"
 },
 {
  "CNKEY": "621142410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "621142410080",
  "CN": "6211 42 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes superiores"
 },
 {
  "CNKEY": "621142420080",
  "CN": "6211 42 42",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes inferiores"
 },
 {
  "CNKEY": "621142900080",
  "CN": "6211 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "621143000080",
  "CN": "6211 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "621143100080",
  "CN": "6211 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Delantales, batas y las demás prendas de trabajo"
 },
 {
  "CNKEY": "621143310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Conjuntos de abrigo para entrenamiento o deporte (chándales), con forro"
 },
 {
  "CNKEY": "621143310080",
  "CN": "6211 43 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cuyo exterior esté realizado con un único tejido"
 },
 {
  "CNKEY": "621143410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "621143410080",
  "CN": "6211 43 41",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes superiores"
 },
 {
  "CNKEY": "621143420080",
  "CN": "6211 43 42",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Partes inferiores"
 },
 {
  "CNKEY": "621143900080",
  "CN": "6211 43 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "621149000080",
  "CN": "6211 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621200000080",
  "CN": "6212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sostenes (corpiños), fajas, corsés, tirantes (tiradores), ligas y artículos similares, y sus partes, incluso de punto"
 },
 {
  "CNKEY": "621210000080",
  "CN": "6212 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sostenes (corpiños)"
 },
 {
  "CNKEY": "621210100080",
  "CN": "6212 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Presentados en conjuntos acondicionados para la venta al por menor conteniendo un sostén (corpiño) y una braga (bombacha)"
 },
 {
  "CNKEY": "621210900080",
  "CN": "6212 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "621220000080",
  "CN": "6212 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Fajas y fajas braga (fajas bombacha)"
 },
 {
  "CNKEY": "621230000080",
  "CN": "6212 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Fajas sostén (fajas corpiño)"
 },
 {
  "CNKEY": "621290000080",
  "CN": "6212 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "621300000080",
  "CN": "6213",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pañuelos de bolsillo"
 },
 {
  "CNKEY": "621320000080",
  "CN": "6213 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "621390000080",
  "CN": "6213 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621400000080",
  "CN": "6214",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chales, pañuelos de cuello, bufandas, mantillas, velos y artículos similares"
 },
 {
  "CNKEY": "621410000080",
  "CN": "6214 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De seda o desperdicios de seda"
 },
 {
  "CNKEY": "621420000080",
  "CN": "6214 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De lana o pelo fino"
 },
 {
  "CNKEY": "621430000080",
  "CN": "6214 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "621440000080",
  "CN": "6214 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras artificiales"
 },
 {
  "CNKEY": "621490000080",
  "CN": "6214 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621500000080",
  "CN": "6215",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Corbatas y lazos similares"
 },
 {
  "CNKEY": "621510000080",
  "CN": "6215 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De seda o desperdicios de seda"
 },
 {
  "CNKEY": "621520000080",
  "CN": "6215 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "621590000080",
  "CN": "6215 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "621600000080",
  "CN": "6216 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "pa",
  "ES": "Guantes, mitones y manoplas"
 },
 {
  "CNKEY": "621700000080",
  "CN": "6217",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás complementos (accesorios) de vestir confeccionados; partes de prendas o de complementos (accesorios), de vestir (excepto las de la partida 6212)"
 },
 {
  "CNKEY": "621710000080",
  "CN": "6217 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Complementos (accesorios) de vestir"
 },
 {
  "CNKEY": "621790000080",
  "CN": "6217 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "630021000090",
  "CN": "63",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 63 - LOS DEMÁS ARTÍCULOS TEXTILES CONFECCIONADOS; JUEGOS; PRENDERÍA Y TRAPOS"
 },
 {
  "CNKEY": "630100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. LOS DEMÁS ARTÍCULOS TEXTILES CONFECCIONADOS"
 },
 {
  "CNKEY": "630100000080",
  "CN": "6301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mantas"
 },
 {
  "CNKEY": "630110000080",
  "CN": "6301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Mantas eléctricas"
 },
 {
  "CNKEY": "630120000080",
  "CN": "6301 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mantas de lana o pelo fino (excepto las eléctricas)"
 },
 {
  "CNKEY": "630120100080",
  "CN": "6301 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De punto"
 },
 {
  "CNKEY": "630120900080",
  "CN": "6301 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630130000080",
  "CN": "6301 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mantas de algodón (excepto las eléctricas)"
 },
 {
  "CNKEY": "630130100080",
  "CN": "6301 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De punto"
 },
 {
  "CNKEY": "630130900080",
  "CN": "6301 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630140000080",
  "CN": "6301 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mantas de fibras sintéticas (excepto las eléctricas)"
 },
 {
  "CNKEY": "630140100080",
  "CN": "6301 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De punto"
 },
 {
  "CNKEY": "630140900080",
  "CN": "6301 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630190000080",
  "CN": "6301 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás mantas"
 },
 {
  "CNKEY": "630190100080",
  "CN": "6301 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De punto"
 },
 {
  "CNKEY": "630190900080",
  "CN": "6301 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630200000080",
  "CN": "6302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ropa de cama, de mesa, de tocador o cocina"
 },
 {
  "CNKEY": "630210000080",
  "CN": "6302 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ropa de cama, de punto"
 },
 {
  "CNKEY": "630221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás ropas de cama, estampadas"
 },
 {
  "CNKEY": "630221000080",
  "CN": "6302 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630222000080",
  "CN": "6302 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "630222100080",
  "CN": "6302 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630222900080",
  "CN": "6302 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630229000080",
  "CN": "6302 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630229100080",
  "CN": "6302 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De lino o de ramio"
 },
 {
  "CNKEY": "630229900080",
  "CN": "6302 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás ropas de cama"
 },
 {
  "CNKEY": "630231000080",
  "CN": "6302 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630232000080",
  "CN": "6302 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "630232100080",
  "CN": "6302 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630232900080",
  "CN": "6302 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630239000080",
  "CN": "6302 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630239200080",
  "CN": "6302 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De lino o de ramio"
 },
 {
  "CNKEY": "630239900080",
  "CN": "6302 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630240000080",
  "CN": "6302 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ropa de mesa, de punto"
 },
 {
  "CNKEY": "630251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás ropas de mesa"
 },
 {
  "CNKEY": "630251000080",
  "CN": "6302 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630253000080",
  "CN": "6302 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "630253100080",
  "CN": "6302 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630253900080",
  "CN": "6302 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630259000080",
  "CN": "6302 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630259100080",
  "CN": "6302 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De lino"
 },
 {
  "CNKEY": "630259900080",
  "CN": "6302 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630260000080",
  "CN": "6302 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ropa de tocador o cocina, de tejido con bucles, del tipo toalla, de algodón"
 },
 {
  "CNKEY": "630291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "630291000080",
  "CN": "6302 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630293000080",
  "CN": "6302 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas o artificiales"
 },
 {
  "CNKEY": "630293100080",
  "CN": "6302 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630293900080",
  "CN": "6302 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630299000080",
  "CN": "6302 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630299100080",
  "CN": "6302 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De lino"
 },
 {
  "CNKEY": "630299900080",
  "CN": "6302 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630300000080",
  "CN": "6303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Visillos y cortinas; guardamalletas y rodapiés de cama"
 },
 {
  "CNKEY": "630312000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De punto"
 },
 {
  "CNKEY": "630312000080",
  "CN": "6303 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "630319000080",
  "CN": "6303 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "630391000080",
  "CN": "6303 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630392000080",
  "CN": "6303 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "630392100080",
  "CN": "6303 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630392900080",
  "CN": "6303 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630399000080",
  "CN": "6303 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630399100080",
  "CN": "6303 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630399900080",
  "CN": "6303 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630400000080",
  "CN": "6304",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás artículos de tapicería (excepto los de la partida 9404)"
 },
 {
  "CNKEY": "630411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Colchas"
 },
 {
  "CNKEY": "630411000080",
  "CN": "6304 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De punto"
 },
 {
  "CNKEY": "630419000080",
  "CN": "6304 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "630419100080",
  "CN": "6304 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630419300080",
  "CN": "6304 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De lino o ramio"
 },
 {
  "CNKEY": "630419900080",
  "CN": "6304 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630420000080",
  "CN": "6304 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Mosquiteros para camas especificados en la nota 1 de subpartida n.º 1 de este capítulo"
 },
 {
  "CNKEY": "630491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "630491000080",
  "CN": "6304 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "630492000080",
  "CN": "6304 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De algodón (excepto de punto)"
 },
 {
  "CNKEY": "630493000080",
  "CN": "6304 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas (excepto de punto)"
 },
 {
  "CNKEY": "630499000080",
  "CN": "6304 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles (excepto de punto)"
 },
 {
  "CNKEY": "630500000080",
  "CN": "6305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sacos (bolsas) y talegas, para envasar"
 },
 {
  "CNKEY": "630510000080",
  "CN": "6305 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De yute o demás fibras textiles del líber de la partida 5303"
 },
 {
  "CNKEY": "630510100080",
  "CN": "6305 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Usados"
 },
 {
  "CNKEY": "630510900080",
  "CN": "6305 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630520000080",
  "CN": "6305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De algodón"
 },
 {
  "CNKEY": "630532000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De materias textiles sintéticas o artificiales"
 },
 {
  "CNKEY": "630532000080",
  "CN": "6305 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Continentes intermedios flexibles para productos a granel"
 },
 {
  "CNKEY": "630532110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De tiras o formas similares, de polietileno o polipropileno"
 },
 {
  "CNKEY": "630532110080",
  "CN": "6305 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "630532190080",
  "CN": "6305 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630532900080",
  "CN": "6305 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630533000080",
  "CN": "6305 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de tiras o formas similares, de polietileno o polipropileno"
 },
 {
  "CNKEY": "630533100080",
  "CN": "6305 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "630533900080",
  "CN": "6305 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630539000080",
  "CN": "6305 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630590000080",
  "CN": "6305 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630600000080",
  "CN": "6306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Toldos de cualquier clase; tiendas (carpas, incluidos los pabellones («gazebos», templetes)* temporales y artículos similares); velas para embarcaciones, deslizadores o vehículos terrestres; artículos de acampar"
 },
 {
  "CNKEY": "630612000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Toldos de cualquier clase"
 },
 {
  "CNKEY": "630612000080",
  "CN": "6306 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "630619000080",
  "CN": "6306 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630622000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tiendas (carpas, incluidos los pabellones («gazebos», templetes)* temporales y artículos similares)"
 },
 {
  "CNKEY": "630622000080",
  "CN": "6306 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas"
 },
 {
  "CNKEY": "630629000080",
  "CN": "6306 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias textiles"
 },
 {
  "CNKEY": "630630000080",
  "CN": "6306 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Velas"
 },
 {
  "CNKEY": "630640000080",
  "CN": "6306 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Colchones neumáticos"
 },
 {
  "CNKEY": "630690000080",
  "CN": "6306 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630700000080",
  "CN": "6307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás artículos confeccionados, incluidos los patrones para prendas de vestir"
 },
 {
  "CNKEY": "630710000080",
  "CN": "6307 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Paños para fregar o lavar (bayetas, paños rejilla), franelas y artículos similares para limpieza"
 },
 {
  "CNKEY": "630710100080",
  "CN": "6307 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "630710300080",
  "CN": "6307 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De telas sin tejer"
 },
 {
  "CNKEY": "630710900080",
  "CN": "6307 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630720000080",
  "CN": "6307 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cinturones y chalecos salvavidas"
 },
 {
  "CNKEY": "630790000080",
  "CN": "6307 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "630790100080",
  "CN": "6307 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De punto"
 },
 {
  "CNKEY": "630790910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "630790910080",
  "CN": "6307 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fieltro"
 },
 {
  "CNKEY": "630790920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "630790920080",
  "CN": "6307 90 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Paños de un solo uso, confeccionados con tejidos de la partida 5603, del tipo utilizado en operaciones quirúrgicas"
 },
 {
  "CNKEY": "630790930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Mascarillas de protección facial"
 },
 {
  "CNKEY": "630790930080",
  "CN": "6307 90 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Mascarillas autofiltrantes (FFP) conformes con la norma EN149; las demás mascarillas que se ajusten a una norma similar, como los dispositivos de protección respiratoria contra partículas"
 },
 {
  "CNKEY": "630790950080",
  "CN": "6307 90 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "630790980080",
  "CN": "6307 90 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "630800000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. JUEGOS"
 },
 {
  "CNKEY": "630800000080",
  "CN": "6308 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Juegos constituidos por piezas de tejido e hilados, incluso con accesorios, para la confección de alfombras, tapicería, manteles o servilletas bordados o de artículos textiles similares, en envases para la venta al por menor"
 },
 {
  "CNKEY": "630900000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "III. PRENDERÍA Y TRAPOS"
 },
 {
  "CNKEY": "630900000080",
  "CN": "6309 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Artículos de prendería"
 },
 {
  "CNKEY": "631000000080",
  "CN": "6310",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Trapos, cordeles, cuerdas y cordajes, de materia textil, en desperdicios o en artículos inservibles"
 },
 {
  "CNKEY": "631010000080",
  "CN": "6310 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Clasificados"
 },
 {
  "CNKEY": "631090000080",
  "CN": "6310 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640011000090",
  "CN": "XII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XII - CALZADO, SOMBREROS Y DEMÁS TOCADOS, PARAGUAS, QUITASOLES, BASTONES, LÁTIGOS, FUSTAS Y SUS PARTES; PLUMAS PREPARADAS Y ARTÍCULOS DE PLUMAS; FLORES ARTIFICIALES; MANUFACTURAS DE CABELLO"
 },
 {
  "CNKEY": "640021000090",
  "CN": "64",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 64 - CALZADO, POLAINAS Y ARTÍCULOS ANÁLOGOS; PARTES DE ESTOS ARTÍCULOS"
 },
 {
  "CNKEY": "640100000080",
  "CN": "6401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calzado impermeable con suela y parte superior de caucho o plástico, cuya parte superior no se haya unido a la suela por costura o por medio de remaches, clavos, tornillos, espigas o dispositivos similares, ni se haya formado con diferentes partes unidas de la misma manera"
 },
 {
  "CNKEY": "640110000080",
  "CN": "6401 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "pa",
  "ES": "Calzado con puntera metálica de protección"
 },
 {
  "CNKEY": "640192000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás calzados"
 },
 {
  "CNKEY": "640192000080",
  "CN": "6401 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo sin cubrir la rodilla"
 },
 {
  "CNKEY": "640192100080",
  "CN": "6401 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con la parte superior de caucho"
 },
 {
  "CNKEY": "640192900080",
  "CN": "6401 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con la parte superior de plástico"
 },
 {
  "CNKEY": "640199000080",
  "CN": "6401 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640200000080",
  "CN": "6402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás calzados con suela y parte superior de caucho o plástico"
 },
 {
  "CNKEY": "640212000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzado de deporte"
 },
 {
  "CNKEY": "640212000080",
  "CN": "6402 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Calzado de esquí y calzado para la práctica de snowboard (tabla para nieve)"
 },
 {
  "CNKEY": "640212100080",
  "CN": "6402 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Calzado de esquí"
 },
 {
  "CNKEY": "640212900080",
  "CN": "6402 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Calzado para la práctica de snowboard (tabla para nieve)"
 },
 {
  "CNKEY": "640219000080",
  "CN": "6402 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640220000080",
  "CN": "6402 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "pa",
  "ES": "Calzado con la parte superior de tiras o bridas, fijadas a la suela por tetones (espigas)"
 },
 {
  "CNKEY": "640291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás calzados"
 },
 {
  "CNKEY": "640291000080",
  "CN": "6402 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo"
 },
 {
  "CNKEY": "640291100080",
  "CN": "6402 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con puntera metálica de protección"
 },
 {
  "CNKEY": "640291900080",
  "CN": "6402 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640299000080",
  "CN": "6402 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640299050080",
  "CN": "6402 99 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con puntera metálica de protección"
 },
 {
  "CNKEY": "640299100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640299100080",
  "CN": "6402 99 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Con la parte superior de caucho"
 },
 {
  "CNKEY": "640299310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con la parte superior de plástico"
 },
 {
  "CNKEY": "640299310020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Calzado constituido por tiras o con una o varias hendiduras"
 },
 {
  "CNKEY": "640299310080",
  "CN": "6402 99 31",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Con tacón de altura superior a 3 cm, incluida la tapa"
 },
 {
  "CNKEY": "640299390080",
  "CN": "6402 99 39",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640299500080",
  "CN": "6402 99 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640299910010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás, con plantillas de longitud"
 },
 {
  "CNKEY": "640299910080",
  "CN": "6402 99 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640299930010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640299930080",
  "CN": "6402 99 93",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Calzado que no sea identificable como calzado para hombres o para mujeres"
 },
 {
  "CNKEY": "640299960010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640299960080",
  "CN": "6402 99 96",
  "CN_LEVEL": 11,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640299980080",
  "CN": "6402 99 98",
  "CN_LEVEL": 11,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640300000080",
  "CN": "6403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calzado con suela de caucho, plástico, cuero natural o regenerado y parte superior de cuero natural"
 },
 {
  "CNKEY": "640312000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzado de deporte"
 },
 {
  "CNKEY": "640312000080",
  "CN": "6403 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Calzado de esquí y calzado para la práctica de snowboard (tabla para nieve)"
 },
 {
  "CNKEY": "640319000080",
  "CN": "6403 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640320000080",
  "CN": "6403 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "pa",
  "ES": "Calzado con suela de cuero natural y parte superior de tiras de cuero natural que pasan por el empeine y rodean el dedo gordo"
 },
 {
  "CNKEY": "640340000080",
  "CN": "6403 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "pa",
  "ES": "Los demás calzados, con puntera metálica de protección"
 },
 {
  "CNKEY": "640351000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás calzados, con suela de cuero natural"
 },
 {
  "CNKEY": "640351000080",
  "CN": "6403 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo"
 },
 {
  "CNKEY": "640351050080",
  "CN": "6403 51 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con palmilla o plataforma de madera, sin plantillas"
 },
 {
  "CNKEY": "640351110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640351110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo, pero no la pantorrilla, con plantilla de longitud"
 },
 {
  "CNKEY": "640351110080",
  "CN": "6403 51 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640351150010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640351150080",
  "CN": "6403 51 15",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640351190080",
  "CN": "6403 51 19",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640351910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640351910080",
  "CN": "6403 51 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640351950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640351950080",
  "CN": "6403 51 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640351990080",
  "CN": "6403 51 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640359000080",
  "CN": "6403 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640359050080",
  "CN": "6403 59 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con palmilla o plataforma de madera, sin plantillas"
 },
 {
  "CNKEY": "640359110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640359110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Calzado constituido por tiras o con una o varias hendiduras"
 },
 {
  "CNKEY": "640359110080",
  "CN": "6403 59 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Con tacón de altura superior a 3 cm, incluida la tapa"
 },
 {
  "CNKEY": "640359310010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640359310080",
  "CN": "6403 59 31",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640359350010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640359350080",
  "CN": "6403 59 35",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640359390080",
  "CN": "6403 59 39",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640359500080",
  "CN": "6403 59 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640359910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640359910080",
  "CN": "6403 59 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640359950010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640359950080",
  "CN": "6403 59 95",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640359990080",
  "CN": "6403 59 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás calzados"
 },
 {
  "CNKEY": "640391000080",
  "CN": "6403 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo"
 },
 {
  "CNKEY": "640391050080",
  "CN": "6403 91 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con palmilla o plataforma de madera, sin plantillas"
 },
 {
  "CNKEY": "640391110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640391110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Que cubran el tobillo, pero no la pantorrilla, con plantilla de longitud"
 },
 {
  "CNKEY": "640391110080",
  "CN": "6403 91 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640391130010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640391130080",
  "CN": "6403 91 13",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Calzado que no sea identificable como calzado para hombres o para mujeres"
 },
 {
  "CNKEY": "640391160010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640391160080",
  "CN": "6403 91 16",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640391180080",
  "CN": "6403 91 18",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640391910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640391910080",
  "CN": "6403 91 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640391930010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640391930080",
  "CN": "6403 91 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Calzado que no sea identificable como calzado para hombres o para mujeres"
 },
 {
  "CNKEY": "640391960010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640391960080",
  "CN": "6403 91 96",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640391980080",
  "CN": "6403 91 98",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640399000080",
  "CN": "6403 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640399050080",
  "CN": "6403 99 05",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Con palmilla o plataforma de madera, sin plantillas"
 },
 {
  "CNKEY": "640399110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640399110020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Calzado constituido por tiras o con una o varias hendiduras"
 },
 {
  "CNKEY": "640399110080",
  "CN": "6403 99 11",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Con tacón de altura superior a 3 cm, incluida la tapa"
 },
 {
  "CNKEY": "640399310010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640399310080",
  "CN": "6403 99 31",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640399330010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640399330080",
  "CN": "6403 99 33",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Calzado que no sea identificable como calzado para hombres o para mujeres"
 },
 {
  "CNKEY": "640399360010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640399360080",
  "CN": "6403 99 36",
  "CN_LEVEL": 11,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640399380080",
  "CN": "6403 99 38",
  "CN_LEVEL": 11,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640399500080",
  "CN": "6403 99 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640399910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con plantilla de longitud"
 },
 {
  "CNKEY": "640399910080",
  "CN": "6403 99 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "pa",
  "ES": "Inferior a 24 cm"
 },
 {
  "CNKEY": "640399930010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Superior o igual a 24 cm"
 },
 {
  "CNKEY": "640399930080",
  "CN": "6403 99 93",
  "CN_LEVEL": 9,
  "T_SU_SU": "pa",
  "ES": "Calzado que no sea identificable como calzado para hombres o para mujeres"
 },
 {
  "CNKEY": "640399960010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640399960080",
  "CN": "6403 99 96",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para hombres"
 },
 {
  "CNKEY": "640399980080",
  "CN": "6403 99 98",
  "CN_LEVEL": 10,
  "T_SU_SU": "pa",
  "ES": "Para mujeres"
 },
 {
  "CNKEY": "640400000080",
  "CN": "6404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calzado con suela de caucho, plástico, cuero natural o regenerado y parte superior de materia textil"
 },
 {
  "CNKEY": "640411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzado con suela de caucho o plástico"
 },
 {
  "CNKEY": "640411000080",
  "CN": "6404 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Calzado de deporte; calzado de tenis, baloncesto, gimnasia, entrenamiento y calzados similares"
 },
 {
  "CNKEY": "640419000080",
  "CN": "6404 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640419100080",
  "CN": "6404 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640419900080",
  "CN": "6404 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640420000080",
  "CN": "6404 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calzado con suela de cuero natural o regenerado"
 },
 {
  "CNKEY": "640420100080",
  "CN": "6404 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640420900080",
  "CN": "6404 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640500000080",
  "CN": "6405",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás calzados"
 },
 {
  "CNKEY": "640510000080",
  "CN": "6405 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "pa",
  "ES": "Con la parte superior de cuero natural o regenerado"
 },
 {
  "CNKEY": "640520000080",
  "CN": "6405 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con la parte superior de materia textil"
 },
 {
  "CNKEY": "640520100080",
  "CN": "6405 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Con suela de madera o de corcho"
 },
 {
  "CNKEY": "640520910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con suela de otras materias"
 },
 {
  "CNKEY": "640520910080",
  "CN": "6405 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Pantuflas y demás calzado de casa"
 },
 {
  "CNKEY": "640520990080",
  "CN": "6405 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Los demás"
 },
 {
  "CNKEY": "640590000080",
  "CN": "6405 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640590100080",
  "CN": "6405 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Con suela de caucho, de plástico, de cuero natural o regenerado"
 },
 {
  "CNKEY": "640590900080",
  "CN": "6405 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Con suela de otras materias"
 },
 {
  "CNKEY": "640600000080",
  "CN": "6406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes de calzado (incluidas las partes superiores fijadas a las palmillas distintas de la suela); plantillas, taloneras y artículos similares, amovibles; polainas y artículos similares, y sus partes"
 },
 {
  "CNKEY": "640610000080",
  "CN": "6406 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes superiores de calzado y sus partes (excepto los contrafuertes y punteras duras)"
 },
 {
  "CNKEY": "640610100080",
  "CN": "6406 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cuero natural"
 },
 {
  "CNKEY": "640610900080",
  "CN": "6406 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De otras materias"
 },
 {
  "CNKEY": "640620000080",
  "CN": "6406 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Suelas y tacones (tacos), de caucho o plástico"
 },
 {
  "CNKEY": "640620100080",
  "CN": "6406 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De caucho"
 },
 {
  "CNKEY": "640620900080",
  "CN": "6406 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "640690000080",
  "CN": "6406 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "640690300080",
  "CN": "6406 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Conjunto formado por la parte superior del calzado fijo a la plantilla o a otras partes inferiores, pero sin suela"
 },
 {
  "CNKEY": "640690500080",
  "CN": "6406 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plantillas y demás accesorios amovibles"
 },
 {
  "CNKEY": "640690600080",
  "CN": "6406 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Suelas de cuero natural o regenerado"
 },
 {
  "CNKEY": "640690900080",
  "CN": "6406 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "650021000090",
  "CN": "65",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 65 - SOMBREROS, DEMÁS TOCADOS, Y SUS PARTES"
 },
 {
  "CNKEY": "650100000080",
  "CN": "6501 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Cascos sin ahormado ni perfilado del ala, platos (discos) y cilindros, aunque estén cortados en el sentido de la altura, de fieltro, para sombreros"
 },
 {
  "CNKEY": "650200000080",
  "CN": "6502 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Cascos para sombreros, trenzados o fabricados por unión de tiras de cualquier materia, sin ahormado ni perfilado del ala y sin guarnecer"
 },
 {
  "CNKEY": "650400000080",
  "CN": "6504 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Sombreros y demás tocados, trenzados o fabricados por unión de tiras de cualquier materia, incluso guarnecidos"
 },
 {
  "CNKEY": "650500000080",
  "CN": "6505 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sombreros y demás tocados, de punto o confeccionados con encaje, fieltro u otro producto textil, en pieza (pero no en tiras), incluso guarnecidos; redecillas para el cabello, de cualquier materia, incluso guarnecidas"
 },
 {
  "CNKEY": "650500100080",
  "CN": "6505 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fieltro de pelo o de fieltro de lana y pelo fabricados con cascos o platos de la partida 65010000"
 },
 {
  "CNKEY": "650500300010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "650500300080",
  "CN": "6505 00 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Gorras, quepis y similares con visera"
 },
 {
  "CNKEY": "650500900080",
  "CN": "6505 00 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "650600000080",
  "CN": "6506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás sombreros y tocados, incluso guarnecidos"
 },
 {
  "CNKEY": "650610000080",
  "CN": "6506 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cascos de seguridad"
 },
 {
  "CNKEY": "650610100080",
  "CN": "6506 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De plástico"
 },
 {
  "CNKEY": "650610800080",
  "CN": "6506 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "650691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "650691000080",
  "CN": "6506 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De caucho o plástico"
 },
 {
  "CNKEY": "650699000080",
  "CN": "6506 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "650699100080",
  "CN": "6506 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fieltro de pelo o de fieltro de lana y pelo fabricados con cascos o platos de la partida 65010000"
 },
 {
  "CNKEY": "650699900080",
  "CN": "6506 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "650700000080",
  "CN": "6507 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desudadores, forros, fundas, armaduras, viseras y barboquejos (barbijos), para sombreros y demás tocados"
 },
 {
  "CNKEY": "660021000090",
  "CN": "66",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 66 - PARAGUAS, SOMBRILLAS, QUITASOLES, BASTONES, BASTONES ASIENTO, LÁTIGOS, FUSTAS, Y SUS PARTES"
 },
 {
  "CNKEY": "660100000080",
  "CN": "6601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Paraguas, sombrillas y quitasoles (incluidos los paraguas bastón, los quitasoles toldo y artículos similares)"
 },
 {
  "CNKEY": "660110000080",
  "CN": "6601 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Quitasoles toldo y artículos similares"
 },
 {
  "CNKEY": "660191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "660191000080",
  "CN": "6601 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con astil o mango telescópico"
 },
 {
  "CNKEY": "660199000080",
  "CN": "6601 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "660199200080",
  "CN": "6601 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con cubierta de tejidos de materia textil"
 },
 {
  "CNKEY": "660199900080",
  "CN": "6601 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "660200000080",
  "CN": "6602 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Bastones, bastones asiento, látigos, fustas y artículos similares"
 },
 {
  "CNKEY": "660300000080",
  "CN": "6603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes, guarniciones y accesorios para los artículos de las partidas 6601 o 6602"
 },
 {
  "CNKEY": "660320000080",
  "CN": "6603 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Monturas ensambladas, incluso con el astil o mango, para paraguas, sombrillas o quitasoles"
 },
 {
  "CNKEY": "660390000080",
  "CN": "6603 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "660390100080",
  "CN": "6603 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Puños y pomos"
 },
 {
  "CNKEY": "660390900080",
  "CN": "6603 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "670021000090",
  "CN": "67",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 67 - PLUMAS Y PLUMÓN PREPARADOS Y ARTÍCULOS DE PLUMAS O PLUMÓN; FLORES ARTIFICIALES; MANUFACTURAS DE CABELLO"
 },
 {
  "CNKEY": "670100000080",
  "CN": "6701 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Pieles y demás partes de ave con sus plumas o plumón; plumas, partes de plumas, plumón y artículos de estas materias (excepto los productos de la partida 0505 y los cañones y astiles de plumas, trabajados)"
 },
 {
  "CNKEY": "670200000080",
  "CN": "6702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Flores, follaje y frutos, artificiales, y sus partes; artículos confeccionados con flores, follaje o frutos, artificiales"
 },
 {
  "CNKEY": "670210000080",
  "CN": "6702 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "670290000080",
  "CN": "6702 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "670300000080",
  "CN": "6703 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cabello peinado, afinado, blanqueado o preparado de otra forma; lana, pelo u otra materia textil, preparados para la fabricación de pelucas o artículos similares"
 },
 {
  "CNKEY": "670400000080",
  "CN": "6704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pelucas, barbas, cejas, pestañas, mechones y artículos análogos, de cabello, pelo o materia textil; manufacturas de cabello no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "670411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De materia textil sintética"
 },
 {
  "CNKEY": "670411000080",
  "CN": "6704 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pelucas que cubran toda la cabeza"
 },
 {
  "CNKEY": "670419000080",
  "CN": "6704 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "670420000080",
  "CN": "6704 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cabello"
 },
 {
  "CNKEY": "670490000080",
  "CN": "6704 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "680011000090",
  "CN": "XIII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XIII - MANUFACTURAS DE PIEDRA, YESO FRAGUABLE, CEMENTO, AMIANTO (ASBESTO), MICA O MATERIAS ANÁLOGAS; PRODUCTOS CERÁMICOS; VIDRIO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "680021000090",
  "CN": "68",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 68 - MANUFACTURAS DE PIEDRA, YESO FRAGUABLE, CEMENTO, AMIANTO (ASBESTO), MICA O MATERIAS ANÁLOGAS"
 },
 {
  "CNKEY": "680100000080",
  "CN": "6801 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Adoquines, encintado (bordillos) y losas para pavimentos, de piedra natural (excepto la pizarra)"
 },
 {
  "CNKEY": "680200000080",
  "CN": "6802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Piedras de talla o de construcción trabajadas (excluida la pizarra) y sus manufacturas, excepto de la partida 6801; cubos, dados y artículos similares para mosaicos, de piedra natural (incluida la pizarra), aunque estén sobre soporte; gránulos, tasquiles (fragmentos) y polvo de piedra natural (incluida la pizarra), coloreados artificialmente"
 },
 {
  "CNKEY": "680210000080",
  "CN": "6802 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Losetas, cubos, dados y artículos similares, incluso de forma distinta a la cuadrada o rectangular, en los que la superficie mayor pueda inscribirse en un cuadrado de lado inferior a 7 cm; gránulos, tasquiles (fragmentos) y polvo, coloreados artificialmente"
 },
 {
  "CNKEY": "680221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás piedras de talla o de construcción y sus manufacturas, simplemente talladas o aserradas, con superficie plana o lisa"
 },
 {
  "CNKEY": "680221000080",
  "CN": "6802 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mármol, travertinos y alabastro"
 },
 {
  "CNKEY": "680223000080",
  "CN": "6802 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Granito"
 },
 {
  "CNKEY": "680229000080",
  "CN": "6802 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás piedras"
 },
 {
  "CNKEY": "680291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "680291000080",
  "CN": "6802 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mármol, travertinos y alabastro"
 },
 {
  "CNKEY": "680292000080",
  "CN": "6802 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás piedras calizas"
 },
 {
  "CNKEY": "680293000080",
  "CN": "6802 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Granito"
 },
 {
  "CNKEY": "680293100080",
  "CN": "6802 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pulimentado, decorado o trabajado de otro modo, pero sin esculpir, de peso neto superior o igual a 10 kg"
 },
 {
  "CNKEY": "680293900080",
  "CN": "6802 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "680299000080",
  "CN": "6802 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás piedras"
 },
 {
  "CNKEY": "680299100080",
  "CN": "6802 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pulimentadas, decoradas o trabajadas de otro modo, pero sin esculpir, de peso neto superior o igual a 10 kg"
 },
 {
  "CNKEY": "680299900080",
  "CN": "6802 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "680300000080",
  "CN": "6803 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pizarra natural trabajada y manufacturas de pizarra natural o aglomerada"
 },
 {
  "CNKEY": "680300100080",
  "CN": "6803 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pizarras para tejados y fachadas"
 },
 {
  "CNKEY": "680300900080",
  "CN": "6803 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "680400000080",
  "CN": "6804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Muelas y artículos similares, sin bastidor, para moler, desfibrar, triturar, afilar, pulir, rectificar, cortar o trocear, piedras de afilar o pulir a mano, y sus partes, de piedra natural, de abrasivos naturales o artificiales aglomerados o de cerámica, incluso con partes de las demás materias"
 },
 {
  "CNKEY": "680410000080",
  "CN": "6804 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Muelas para moler o desfibrar"
 },
 {
  "CNKEY": "680421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás muelas y artículos similares"
 },
 {
  "CNKEY": "680421000080",
  "CN": "6804 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De diamante natural o sintético, aglomerado"
 },
 {
  "CNKEY": "680422000080",
  "CN": "6804 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De los demás abrasivos aglomerados o de cerámica"
 },
 {
  "CNKEY": "680422120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De abrasivos artificiales con aglomerante"
 },
 {
  "CNKEY": "680422120020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De resinas sintéticas o artificiales"
 },
 {
  "CNKEY": "680422120080",
  "CN": "6804 22 12",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin reforzar"
 },
 {
  "CNKEY": "680422180080",
  "CN": "6804 22 18",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Reforzadas"
 },
 {
  "CNKEY": "680422300080",
  "CN": "6804 22 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De cerámica o de silicatos"
 },
 {
  "CNKEY": "680422500080",
  "CN": "6804 22 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "680422900080",
  "CN": "6804 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "680423000080",
  "CN": "6804 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De piedras naturales"
 },
 {
  "CNKEY": "680430000080",
  "CN": "6804 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piedras de afilar o pulir a mano"
 },
 {
  "CNKEY": "680500000080",
  "CN": "6805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Abrasivos naturales o artificiales en polvo o gránulos con soporte de materia textil, papel, cartón u otras materias, incluso recortados, cosidos o unidos de otra forma"
 },
 {
  "CNKEY": "680510000080",
  "CN": "6805 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con soporte constituido solamente por tejido de materia textil"
 },
 {
  "CNKEY": "680520000080",
  "CN": "6805 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con soporte constituido solamente por papel o cartón"
 },
 {
  "CNKEY": "680530000080",
  "CN": "6805 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con soporte de las demás materias"
 },
 {
  "CNKEY": "680600000080",
  "CN": "6806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lana de escoria, de roca y lanas minerales similares; vermiculita dilatada, arcilla dilatada, espuma de escoria y productos minerales similares dilatados; mezclas y manufacturas de materias minerales para aislamiento térmico o acústico o para la absorción del sonido (excepto las de las partidas 6811 o 6812 o del Capítulo 69)"
 },
 {
  "CNKEY": "680610000080",
  "CN": "6806 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lana de escoria, de roca y lanas minerales similares, incluso mezcladas entre sí, en masa, hojas o enrolladas"
 },
 {
  "CNKEY": "680620000080",
  "CN": "6806 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vermiculita dilatada, arcilla dilatada, espuma de escoria y productos minerales similares dilatados, incluso mezclados entre sí"
 },
 {
  "CNKEY": "680620100080",
  "CN": "6806 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arcilla dilatada"
 },
 {
  "CNKEY": "680620900080",
  "CN": "6806 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "680690000080",
  "CN": "6806 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "680700000080",
  "CN": "6807",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de asfalto o de productos similares (por ejemplo: pez de petróleo, brea)"
 },
 {
  "CNKEY": "680710000080",
  "CN": "6807 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "En rollos"
 },
 {
  "CNKEY": "680790000080",
  "CN": "6807 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "680800000080",
  "CN": "6808 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Paneles, placas, losetas, bloques y artículos similares, de fibra vegetal, paja o viruta, de plaquitas o partículas, o de aserrín o demás desperdicios de madera, aglomerados con cemento, yeso fraguable o demás aglutinantes minerales"
 },
 {
  "CNKEY": "680900000080",
  "CN": "6809",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de yeso fraguable o de preparaciones a base de yeso fraguable"
 },
 {
  "CNKEY": "680911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Placas, hojas, paneles, losetas y artículos similares, sin adornos"
 },
 {
  "CNKEY": "680911000080",
  "CN": "6809 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Revestidos o reforzados exclusivamente con papel o cartón"
 },
 {
  "CNKEY": "680919000080",
  "CN": "6809 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "680990000080",
  "CN": "6809 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás manufacturas"
 },
 {
  "CNKEY": "681000000080",
  "CN": "6810",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de cemento, hormigón o piedra artificial, incluso armadas"
 },
 {
  "CNKEY": "681011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tejas, losetas, losas, ladrillos y artículos similares"
 },
 {
  "CNKEY": "681011000080",
  "CN": "6810 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bloques y ladrillos para la construcción"
 },
 {
  "CNKEY": "681011100080",
  "CN": "6810 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De hormigón ligero (por ejemplo: a base de piedra pómez, de escorias granuladas)"
 },
 {
  "CNKEY": "681011900080",
  "CN": "6810 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "681019000080",
  "CN": "6810 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "681091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas"
 },
 {
  "CNKEY": "681091000080",
  "CN": "6810 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Elementos prefabricados para la construcción o ingeniería civil"
 },
 {
  "CNKEY": "681099000080",
  "CN": "6810 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681100000080",
  "CN": "6811",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de amiantocemento, celulosacemento o similares"
 },
 {
  "CNKEY": "681140000080",
  "CN": "6811 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan amianto (asbesto)"
 },
 {
  "CNKEY": "681181000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que no contengan amianto (asbesto)"
 },
 {
  "CNKEY": "681181000080",
  "CN": "6811 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Placas onduladas"
 },
 {
  "CNKEY": "681182000080",
  "CN": "6811 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás placas, paneles, losetas, tejas y artículos similares"
 },
 {
  "CNKEY": "681189000080",
  "CN": "6811 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás manufacturas"
 },
 {
  "CNKEY": "681200000080",
  "CN": "6812",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Amianto (asbesto) en fibras trabajado; mezclas a base de amianto o a base de amianto y carbonato de magnesio; manufacturas de estas mezclas o de amianto (por ejemplo: hilados, tejidos, prendas de vestir, sombreros y demás tocados, calzado, juntas), incluso armadas (excepto las de las partidas 6811 o 6813)"
 },
 {
  "CNKEY": "681280000080",
  "CN": "6812 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De crocidolita"
 },
 {
  "CNKEY": "681280100080",
  "CN": "6812 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En fibras trabajado; mezclas a base de amianto o a base de amianto y carbonato de magnesio"
 },
 {
  "CNKEY": "681280900080",
  "CN": "6812 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "681291000080",
  "CN": "6812 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Prendas y complementos (accesorios), de vestir, calzado y sombreros y demás tocados"
 },
 {
  "CNKEY": "681299000080",
  "CN": "6812 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "681299100080",
  "CN": "6812 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Amianto (asbesto) en fibras trabajado; mezclas a base de amianto o a base de amianto y carbonato de magnesio"
 },
 {
  "CNKEY": "681299900080",
  "CN": "6812 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681300000080",
  "CN": "6813",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Guarniciones de fricción (por ejemplo: hojas, rollos, tiras, segmentos, discos, arandelas, plaquitas) sin montar, para frenos, embragues o cualquier órgano de frotamiento, a base de amianto (asbesto), de otras sustancias minerales o de celulosa, incluso combinados con textiles o demás materias"
 },
 {
  "CNKEY": "681320000080",
  "CN": "6813 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que contengan amianto (asbesto)"
 },
 {
  "CNKEY": "681381000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que no contengan amianto (asbesto)"
 },
 {
  "CNKEY": "681381000080",
  "CN": "6813 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guarniciones para frenos"
 },
 {
  "CNKEY": "681389000080",
  "CN": "6813 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681400000080",
  "CN": "6814",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mica trabajada y manufacturas de mica, incluida la aglomerada o reconstituida, incluso con soporte de papel, cartón o demás materias"
 },
 {
  "CNKEY": "681410000080",
  "CN": "6814 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Placas, hojas y tiras de mica aglomerada o reconstituida, incluso con soporte"
 },
 {
  "CNKEY": "681490000080",
  "CN": "6814 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681500000080",
  "CN": "6815",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de piedra o demás materias minerales (incluidas las fibras de carbono y sus manufacturas y las manufacturas de turba), no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "681511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fibras de carbono; manufacturas de fibras de carbono para usos distintos de los eléctricos; las demás manufacturas de grafito u otros carbonos, para usos distintos de los eléctricos"
 },
 {
  "CNKEY": "681511000080",
  "CN": "6815 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fibras de carbono"
 },
 {
  "CNKEY": "681512000080",
  "CN": "6815 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Textiles de fibras de carbono"
 },
 {
  "CNKEY": "681513000080",
  "CN": "6815 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás manufacturas de fibras de carbono"
 },
 {
  "CNKEY": "681519000080",
  "CN": "6815 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "681520000080",
  "CN": "6815 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Manufacturas de turba"
 },
 {
  "CNKEY": "681591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas"
 },
 {
  "CNKEY": "681591000080",
  "CN": "6815 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan magnesita, magnesia en forma de periclasa, dolomita incluida la cal dolomítica, o cromita"
 },
 {
  "CNKEY": "681599000080",
  "CN": "6815 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "690021000090",
  "CN": "69",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 69 - PRODUCTOS CERÁMICOS"
 },
 {
  "CNKEY": "690100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. PRODUCTOS DE HARINAS SILÍCEAS FÓSILES O DE TIERRAS SÍLICEAS ANÁLOGAS Y PRODUCTOS REFRACTARIOS"
 },
 {
  "CNKEY": "690100000080",
  "CN": "6901 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Ladrillos, placas, baldosas y demás piezas cerámicas de harinas silíceas fósiles (por ejemplo: «kieselguhr», tripolita, diatomita) o de tierras silíceas análogas"
 },
 {
  "CNKEY": "690200000080",
  "CN": "6902",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ladrillos, placas, baldosas y demás piezas cerámicas análogas de construcción, refractarios (excepto los de harinas silíceas fósiles o de tierras silíceas análogas)"
 },
 {
  "CNKEY": "690210000080",
  "CN": "6902 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de los elementos Mg (magnesio), Ca (calcio) o Cr (cromo), considerados aislada o conjuntamente, superior al 50 % en peso, expresados en MgO (óxido de magnesio), CaO (óxido de calcio) o Cr2O3 (óxido crómico)"
 },
 {
  "CNKEY": "690220000080",
  "CN": "6902 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de alúmina (Al2O3), de sílice (SiO2) o de una mezcla o combinación de estos productos, superior al 50 % en peso"
 },
 {
  "CNKEY": "690220100080",
  "CN": "6902 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de sílice (SiO2) superior o igual al 93 % en peso"
 },
 {
  "CNKEY": "690220910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "690220910080",
  "CN": "6902 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de alúmina (Al2O3) superior al 7 % pero inferior al 45 % en peso"
 },
 {
  "CNKEY": "690220990080",
  "CN": "6902 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690290000080",
  "CN": "6902 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690300000080",
  "CN": "6903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás artículos cerámicos refractarios (por ejemplo: retortas, crisoles, muflas, toberas, tapones, soportes, copelas, tubos, fundas, varillas, compuertas deslizantes), excepto los de harinas silíceas fósiles o de tierras silíceas análogas"
 },
 {
  "CNKEY": "690310000080",
  "CN": "6903 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono libre, superior al 50 % en peso"
 },
 {
  "CNKEY": "690320000080",
  "CN": "6903 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de alúmina (Al2O3) o de una mezcla o combinación de alúmina y de sílice (SiO2), superior al 50 % en peso"
 },
 {
  "CNKEY": "690320100080",
  "CN": "6903 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de alúmina (Al2O3) inferior al 45 % en peso"
 },
 {
  "CNKEY": "690320900080",
  "CN": "6903 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de alúmina (Al2O3) superior o igual al 45 % en peso"
 },
 {
  "CNKEY": "690390000080",
  "CN": "6903 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "690390100080",
  "CN": "6903 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de grafito u otro carbono o de una mezcla de estos productos superior al 25 % pero inferior o igual al 50 % en peso"
 },
 {
  "CNKEY": "690390900080",
  "CN": "6903 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690400000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. LOS DEMÁS PRODUCTOS CERÁMICOS"
 },
 {
  "CNKEY": "690400000080",
  "CN": "6904",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ladrillos de construcción, bovedillas, cubrevigas y artículos similares, de cerámica"
 },
 {
  "CNKEY": "690410000080",
  "CN": "6904 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Ladrillos de construcción"
 },
 {
  "CNKEY": "690490000080",
  "CN": "6904 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690500000080",
  "CN": "6905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tejas, elementos de chimenea, conductos de humo, ornamentos arquitectónicos y demás artículos cerámicos de construcción"
 },
 {
  "CNKEY": "690510000080",
  "CN": "6905 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tejas"
 },
 {
  "CNKEY": "690590000080",
  "CN": "6905 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690600000080",
  "CN": "6906 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tubos, canalones y accesorios de tubería, de cerámica"
 },
 {
  "CNKEY": "690700000080",
  "CN": "6907",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Placas y baldosas, de cerámica, para pavimentación o revestimiento; cubos, dados y artículos similares, de cerámica, para mosaicos, incluso con soporte; piezas de acabado, de cerámica"
 },
 {
  "CNKEY": "690721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Placas y baldosas, para pavimentación o revestimiento, excepto las  de las subpartidas 690730 y 690740"
 },
 {
  "CNKEY": "690721000080",
  "CN": "6907 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un coeficiente de absorción de agua inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "690722000080",
  "CN": "6907 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un coeficiente de absorción de agua superior al 0,5 % pero inferior o igual al 10 %, en peso"
 },
 {
  "CNKEY": "690723000080",
  "CN": "6907 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con un coeficiente de absorción de agua superior al 10 % en peso"
 },
 {
  "CNKEY": "690730000080",
  "CN": "6907 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Cubos, dados y artículos similares para mosaicos, excepto los de la subpartida 690740"
 },
 {
  "CNKEY": "690740000080",
  "CN": "6907 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Piezas de acabado"
 },
 {
  "CNKEY": "690900000080",
  "CN": "6909",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos y artículos, de cerámica, para usos químicos o demás usos técnicos; abrevaderos, pilas y recipientes similares, de cerámica, para uso rural; cántaros y recipientes similares, de cerámica, para transporte o envasado"
 },
 {
  "CNKEY": "690911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos y artículos para usos químicos o demás usos técnicos"
 },
 {
  "CNKEY": "690911000080",
  "CN": "6909 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De porcelana"
 },
 {
  "CNKEY": "690912000080",
  "CN": "6909 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos con una dureza igual o superior a 9 en la escala de Mohs"
 },
 {
  "CNKEY": "690919000080",
  "CN": "6909 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "690990000080",
  "CN": "6909 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691000000080",
  "CN": "6910",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fregaderos (piletas de lavar), lavabos, pedestales de lavabo, bañeras, bidés, inodoros, cisternas (depósitos de agua) para inodoros, urinarios y aparatos fijos similares, de cerámica, para usos sanitarios"
 },
 {
  "CNKEY": "691010000080",
  "CN": "6910 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De porcelana"
 },
 {
  "CNKEY": "691090000080",
  "CN": "6910 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691100000080",
  "CN": "6911",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vajilla y demás artículos de uso doméstico, higiene o tocador, de porcelana"
 },
 {
  "CNKEY": "691110000080",
  "CN": "6911 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos para el servicio de mesa o cocina"
 },
 {
  "CNKEY": "691190000080",
  "CN": "6911 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691200000080",
  "CN": "6912 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vajilla y demás artículos de uso doméstico, higiene o tocador, de cerámica (excepto porcelana)"
 },
 {
  "CNKEY": "691200210010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos para el servicio de mesa o cocina"
 },
 {
  "CNKEY": "691200210080",
  "CN": "6912 00 21",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De barro ordinario"
 },
 {
  "CNKEY": "691200230080",
  "CN": "6912 00 23",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De gres"
 },
 {
  "CNKEY": "691200250080",
  "CN": "6912 00 25",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De loza o de barro fino"
 },
 {
  "CNKEY": "691200290080",
  "CN": "6912 00 29",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691200810010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "691200810080",
  "CN": "6912 00 81",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De barro ordinario"
 },
 {
  "CNKEY": "691200830080",
  "CN": "6912 00 83",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De gres"
 },
 {
  "CNKEY": "691200850080",
  "CN": "6912 00 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De loza o de barro fino"
 },
 {
  "CNKEY": "691200890080",
  "CN": "6912 00 89",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691300000080",
  "CN": "6913",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Estatuillas y demás artículos para adorno, de cerámica"
 },
 {
  "CNKEY": "691310000080",
  "CN": "6913 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De porcelana"
 },
 {
  "CNKEY": "691390000080",
  "CN": "6913 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "691390100080",
  "CN": "6913 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De barro ordinario"
 },
 {
  "CNKEY": "691390930010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "691390930080",
  "CN": "6913 90 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De loza o de barro fino"
 },
 {
  "CNKEY": "691390980080",
  "CN": "6913 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "691400000080",
  "CN": "6914",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de cerámica"
 },
 {
  "CNKEY": "691410000080",
  "CN": "6914 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De porcelana"
 },
 {
  "CNKEY": "691490000080",
  "CN": "6914 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "700021000090",
  "CN": "70",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 70 - VIDRIO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "700100000080",
  "CN": "7001 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calcín y demás desperdicios y desechos de vidrio, excepto el vidrio de tubos de rayos catódicos y demás vidrios activados de la partida 8549; vidrio en masa"
 },
 {
  "CNKEY": "700100100080",
  "CN": "7001 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos de vidrio"
 },
 {
  "CNKEY": "700100910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vidrio en masa"
 },
 {
  "CNKEY": "700100910080",
  "CN": "7001 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Vidrio óptico"
 },
 {
  "CNKEY": "700100990080",
  "CN": "7001 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700200000080",
  "CN": "7002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio en bolas (excepto las microesferas de la partida 7018), barras, varillas o tubos, sin trabajar"
 },
 {
  "CNKEY": "700210000080",
  "CN": "7002 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bolas"
 },
 {
  "CNKEY": "700220000080",
  "CN": "7002 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras o varillas"
 },
 {
  "CNKEY": "700220100080",
  "CN": "7002 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De vidrio óptico"
 },
 {
  "CNKEY": "700220900080",
  "CN": "7002 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos"
 },
 {
  "CNKEY": "700231000080",
  "CN": "7002 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cuarzo o demás sílices fundidos"
 },
 {
  "CNKEY": "700232000080",
  "CN": "7002 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De otro vidrio con un coeficiente de dilatación lineal inferior o igual a 5 × 10–6 por Kelvin, entre 0 °C y 300 °C"
 },
 {
  "CNKEY": "700239000080",
  "CN": "7002 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700300000080",
  "CN": "7003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio colado o laminado, en placas, hojas o perfiles, incluso con capa absorbente, reflectante o antirreflectante, pero sin trabajar de otro modo"
 },
 {
  "CNKEY": "700312000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Placas y hojas, sin armar"
 },
 {
  "CNKEY": "700312000080",
  "CN": "7003 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Coloreadas en la masa, opacificadas, chapadas o con capa absorbente, reflectante o antirreflectante"
 },
 {
  "CNKEY": "700312100080",
  "CN": "7003 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De vidrio óptico"
 },
 {
  "CNKEY": "700312910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "700312910080",
  "CN": "7003 12 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Con capa antirreflectante"
 },
 {
  "CNKEY": "700312990080",
  "CN": "7003 12 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "700319000080",
  "CN": "7003 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "700319100080",
  "CN": "7003 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De vidrio óptico"
 },
 {
  "CNKEY": "700319900080",
  "CN": "7003 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "700320000080",
  "CN": "7003 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Placas y hojas, armadas"
 },
 {
  "CNKEY": "700330000080",
  "CN": "7003 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfiles"
 },
 {
  "CNKEY": "700400000080",
  "CN": "7004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio estirado o soplado, en hojas, incluso con capa absorbente, reflectante o antirreflectante, pero sin trabajar de otro modo"
 },
 {
  "CNKEY": "700420000080",
  "CN": "7004 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vidrio coloreado en la masa, opacificado, chapado o con capa absorbente, reflectante o antirreflectante"
 },
 {
  "CNKEY": "700420100080",
  "CN": "7004 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Vidrio óptico"
 },
 {
  "CNKEY": "700420910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "700420910080",
  "CN": "7004 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Con capa antirreflectante"
 },
 {
  "CNKEY": "700420990080",
  "CN": "7004 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700490000080",
  "CN": "7004 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vidrios"
 },
 {
  "CNKEY": "700490100080",
  "CN": "7004 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Vidrio óptico"
 },
 {
  "CNKEY": "700490800080",
  "CN": "7004 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700500000080",
  "CN": "7005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio flotado y vidrio desbastado o pulido por una o las dos caras, en placas u hojas, incluso con capa absorbente, reflectante o antirreflectante, pero sin trabajar de otro modo"
 },
 {
  "CNKEY": "700510000080",
  "CN": "7005 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vidrio sin armar con capa absorbente, reflectante o antirreflectante"
 },
 {
  "CNKEY": "700510050080",
  "CN": "7005 10 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Con capa antirreflectante"
 },
 {
  "CNKEY": "700510250010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de espesor"
 },
 {
  "CNKEY": "700510250080",
  "CN": "7005 10 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Inferior o igual a 3,5 mm"
 },
 {
  "CNKEY": "700510300080",
  "CN": "7005 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Superior a 3,5 mm pero inferior o igual a 4,5 mm"
 },
 {
  "CNKEY": "700510800080",
  "CN": "7005 10 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Superior a 4,5 mm"
 },
 {
  "CNKEY": "700521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vidrios sin armar"
 },
 {
  "CNKEY": "700521000080",
  "CN": "7005 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Coloreados en la masa, opacificados, chapados o simplemente desbastados"
 },
 {
  "CNKEY": "700521250080",
  "CN": "7005 21 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor inferior o igual a 3,5 mm"
 },
 {
  "CNKEY": "700521300080",
  "CN": "7005 21 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor superior a 3,5 mm pero inferior o igual a 4,5 mm"
 },
 {
  "CNKEY": "700521800080",
  "CN": "7005 21 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor superior a 4,5 mm"
 },
 {
  "CNKEY": "700529000080",
  "CN": "7005 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "700529250080",
  "CN": "7005 29 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor inferior o igual a 3,5 mm"
 },
 {
  "CNKEY": "700529350080",
  "CN": "7005 29 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor superior a 3,5 mm pero inferior o igual a 4,5 mm"
 },
 {
  "CNKEY": "700529800080",
  "CN": "7005 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "De espesor superior a 4,5 mm"
 },
 {
  "CNKEY": "700530000080",
  "CN": "7005 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Vidrio armado"
 },
 {
  "CNKEY": "700600000080",
  "CN": "7006 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio de las partidas 7003, 7004 o 7005, curvado, biselado, grabado, taladrado, esmaltado o trabajado de otro modo, pero sin enmarcar ni combinar con otras materias"
 },
 {
  "CNKEY": "700600100080",
  "CN": "7006 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Vidrio óptico"
 },
 {
  "CNKEY": "700600900080",
  "CN": "7006 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700700000080",
  "CN": "7007",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrio de seguridad constituido por vidrio templado o contrachapado"
 },
 {
  "CNKEY": "700711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vidrio templado"
 },
 {
  "CNKEY": "700711000080",
  "CN": "7007 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De dimensiones y formatos que permitan su empleo en automóviles, aeronaves, barcos u otros vehículos"
 },
 {
  "CNKEY": "700711100080",
  "CN": "7007 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De dimensiones y formatos que permitan su empleo en automóviles y tractores"
 },
 {
  "CNKEY": "700711900080",
  "CN": "7007 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700719000080",
  "CN": "7007 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "700719100080",
  "CN": "7007 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Esmaltados"
 },
 {
  "CNKEY": "700719200080",
  "CN": "7007 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Coloreados en la masa, opacificados, chapados o con capa absorbente o reflectante"
 },
 {
  "CNKEY": "700719800080",
  "CN": "7007 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vidrio contrachapado"
 },
 {
  "CNKEY": "700721000080",
  "CN": "7007 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De dimensiones y formatos que permitan su empleo en automóviles, aeronaves, barcos u otros vehículos"
 },
 {
  "CNKEY": "700721200080",
  "CN": "7007 21 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De dimensiones y formatos que permitan su empleo en automóviles y tractores"
 },
 {
  "CNKEY": "700721800080",
  "CN": "7007 21 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700729000080",
  "CN": "7007 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Los demás"
 },
 {
  "CNKEY": "700800000080",
  "CN": "7008 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vidrieras aislantes de paredes múltiples"
 },
 {
  "CNKEY": "700800200080",
  "CN": "7008 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "m²",
  "ES": "Coloreadas en la masa, opacificadas, chapados o con una capa absorbente o reflectante"
 },
 {
  "CNKEY": "700800810010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "700800810080",
  "CN": "7008 00 81",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Formadas por dos placas de vidrio selladas en su contorno por una junta hermética y separadas por capa de aire, de otro gas o por vacío"
 },
 {
  "CNKEY": "700800890080",
  "CN": "7008 00 89",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Las demás"
 },
 {
  "CNKEY": "700900000080",
  "CN": "7009",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Espejos de vidrio, enmarcados o no, incluidos los espejos retrovisores"
 },
 {
  "CNKEY": "700910000080",
  "CN": "7009 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Espejos retrovisores para vehículos"
 },
 {
  "CNKEY": "700991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "700991000080",
  "CN": "7009 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin enmarcar"
 },
 {
  "CNKEY": "700992000080",
  "CN": "7009 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Enmarcados"
 },
 {
  "CNKEY": "701000000080",
  "CN": "7010",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bombonas (damajuanas), botellas, frascos, bocales, tarros, envases tubulares, ampollas y demás recipientes para el transporte o envasado, de vidrio; bocales para conservas, de vidrio; tapones, tapas y demás dispositivos de cierre, de vidrio"
 },
 {
  "CNKEY": "701010000080",
  "CN": "7010 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Ampollas"
 },
 {
  "CNKEY": "701020000080",
  "CN": "7010 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tapones, tapas y demás dispositivos de cierre"
 },
 {
  "CNKEY": "701090000080",
  "CN": "7010 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701090100080",
  "CN": "7010 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tarros para esterilizar"
 },
 {
  "CNKEY": "701090210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701090210080",
  "CN": "7010 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Obtenidos de un tubo de vidrio"
 },
 {
  "CNKEY": "701090310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, de capacidad nominal"
 },
 {
  "CNKEY": "701090310080",
  "CN": "7010 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 2,5 l"
 },
 {
  "CNKEY": "701090410010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior a 2,5 l"
 },
 {
  "CNKEY": "701090410020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Para productos alimenticios y bebidas"
 },
 {
  "CNKEY": "701090410030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Botellas y frascos"
 },
 {
  "CNKEY": "701090410040",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "De vidrio sin colorear, de capacidad nominal"
 },
 {
  "CNKEY": "701090410080",
  "CN": "7010 90 41",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 1 l"
 },
 {
  "CNKEY": "701090430080",
  "CN": "7010 90 43",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 0,33 l pero inferior a 1 l"
 },
 {
  "CNKEY": "701090450080",
  "CN": "7010 90 45",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 0,15 l pero inferior o igual a 0,33 l"
 },
 {
  "CNKEY": "701090470080",
  "CN": "7010 90 47",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Inferior a 0,15 l"
 },
 {
  "CNKEY": "701090510010",
  "CN": null,
  "CN_LEVEL": 10,
  "T_SU_SU": null,
  "ES": "De vidrio coloreado, de capacidad nominal"
 },
 {
  "CNKEY": "701090510080",
  "CN": "7010 90 51",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 1 l"
 },
 {
  "CNKEY": "701090530080",
  "CN": "7010 90 53",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 0,33 l pero inferior a 1 l"
 },
 {
  "CNKEY": "701090550080",
  "CN": "7010 90 55",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 0,15 l pero inferior o igual a 0,33 l"
 },
 {
  "CNKEY": "701090570080",
  "CN": "7010 90 57",
  "CN_LEVEL": 11,
  "T_SU_SU": "p\/st",
  "ES": "Inferior a 0,15 l"
 },
 {
  "CNKEY": "701090610010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás, de capacidad nominal"
 },
 {
  "CNKEY": "701090610080",
  "CN": "7010 90 61",
  "CN_LEVEL": 10,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 0,25 l"
 },
 {
  "CNKEY": "701090670080",
  "CN": "7010 90 67",
  "CN_LEVEL": 10,
  "T_SU_SU": "p\/st",
  "ES": "Inferior a 0,25 l"
 },
 {
  "CNKEY": "701090710010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Para productos farmaceúticos de capacidad nominal"
 },
 {
  "CNKEY": "701090710080",
  "CN": "7010 90 71",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 0,055 l"
 },
 {
  "CNKEY": "701090790080",
  "CN": "7010 90 79",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 0,055 l"
 },
 {
  "CNKEY": "701090910010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Para otros productos"
 },
 {
  "CNKEY": "701090910080",
  "CN": "7010 90 91",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "De vidrio sin colorear"
 },
 {
  "CNKEY": "701090990080",
  "CN": "7010 90 99",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "De vidrio coloreado"
 },
 {
  "CNKEY": "701100000080",
  "CN": "7011",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ampollas y envolturas tubulares, abiertas, y sus partes, de vidrio, sin guarniciones, para lámparas y fuentes luminosas, eléctricas, tubos de rayos catódicos o similares"
 },
 {
  "CNKEY": "701110000080",
  "CN": "7011 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para alumbrado eléctrico"
 },
 {
  "CNKEY": "701120000080",
  "CN": "7011 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para tubos de rayos catódicos"
 },
 {
  "CNKEY": "701190000080",
  "CN": "7011 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "701300000080",
  "CN": "7013",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de vidrio para servicio de mesa, cocina, tocador, baño, oficina, adorno de interiores o usos similares (excepto los de las partidas 7010 o 7018)"
 },
 {
  "CNKEY": "701310000080",
  "CN": "7013 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Artículos de vitrocerámica"
 },
 {
  "CNKEY": "701322000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Recipientes con pie para beber, excepto los de vitrocerámica"
 },
 {
  "CNKEY": "701322000080",
  "CN": "7013 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cristal al plomo"
 },
 {
  "CNKEY": "701322100080",
  "CN": "7013 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701322900080",
  "CN": "7013 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701328000080",
  "CN": "7013 28",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701328100080",
  "CN": "7013 28 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701328900080",
  "CN": "7013 28 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701333000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás recipientes para beber, excepto los de vitrocerámica"
 },
 {
  "CNKEY": "701333000080",
  "CN": "7013 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cristal al plomo"
 },
 {
  "CNKEY": "701333110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701333110080",
  "CN": "7013 33 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Tallados o decorados de otro modo"
 },
 {
  "CNKEY": "701333190080",
  "CN": "7013 33 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701333910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701333910080",
  "CN": "7013 33 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Tallados o decorados de otro modo"
 },
 {
  "CNKEY": "701333990080",
  "CN": "7013 33 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701337000080",
  "CN": "7013 37",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701337100080",
  "CN": "7013 37 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De vidrio templado"
 },
 {
  "CNKEY": "701337510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701337510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701337510080",
  "CN": "7013 37 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Tallados o decorados de otro modo"
 },
 {
  "CNKEY": "701337590080",
  "CN": "7013 37 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701337910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701337910080",
  "CN": "7013 37 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Tallados o decorados de otro modo"
 },
 {
  "CNKEY": "701337990080",
  "CN": "7013 37 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos para servicio de mesa (excluidos los recipientes para beber) o cocina (excepto los de vitrocerámica)"
 },
 {
  "CNKEY": "701341000080",
  "CN": "7013 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cristal al plomo"
 },
 {
  "CNKEY": "701341100080",
  "CN": "7013 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701341900080",
  "CN": "7013 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701342000080",
  "CN": "7013 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De vidrio con un coeficiente de dilatación lineal inferior o igual a 5 × 10–6 por Kelvin, entre 0 °C y 300 °C"
 },
 {
  "CNKEY": "701349000080",
  "CN": "7013 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701349100080",
  "CN": "7013 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De vidrio templado"
 },
 {
  "CNKEY": "701349910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701349910080",
  "CN": "7013 49 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701349990080",
  "CN": "7013 49 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás artículos"
 },
 {
  "CNKEY": "701391000080",
  "CN": "7013 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cristal al plomo"
 },
 {
  "CNKEY": "701391100080",
  "CN": "7013 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Hechos a mano"
 },
 {
  "CNKEY": "701391900080",
  "CN": "7013 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Fabricados mecánicamente"
 },
 {
  "CNKEY": "701399000080",
  "CN": "7013 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701400000080",
  "CN": "7014 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Vidrio para señalización y elementos de óptica de vidrio (excepto los de la partida 7015), sin trabajar ópticamente"
 },
 {
  "CNKEY": "701500000080",
  "CN": "7015",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cristales para relojes y cristales análogos, cristales para gafas (anteojos), incluso correctores, abombados, curvados, ahuecados o similares, sin trabajar ópticamente; esferas huecas y sus segmentos (casquetes esféricos), de vidrio, para la fabricación de estos cristales"
 },
 {
  "CNKEY": "701510000080",
  "CN": "7015 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cristales correctores para gafas (anteojos)"
 },
 {
  "CNKEY": "701590000080",
  "CN": "7015 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701600000080",
  "CN": "7016",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Adoquines, boldosas, ladrillos, placas, tejas y demás artículos, de vidrio prensado o moldeado, incluso armado, para la construcción; cubos, dados y demás artículos similares, de vidrio, incluso con soporte, para mosaicos o decoraciones similares; vidrieras artísticas (vitrales, incluso de vidrios incoloros); vidrio multicelular o vidrio «espuma», en bloques, paneles, placas, coquillas o formas similares"
 },
 {
  "CNKEY": "701610000080",
  "CN": "7016 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cubos, dados y demás artículos similares, de vidrio, incluso con soporte, para mosaicos o decoraciones similares"
 },
 {
  "CNKEY": "701690000080",
  "CN": "7016 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701690100080",
  "CN": "7016 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "m²",
  "ES": "Vidrieras artísticas (vitrales, incluso de vidrios incoloros)"
 },
 {
  "CNKEY": "701690400080",
  "CN": "7016 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Adoquines y ladrillos para la construcción"
 },
 {
  "CNKEY": "701690700080",
  "CN": "7016 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701700000080",
  "CN": "7017",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de vidrio para laboratorio, higiene o farmacia, incluso graduados o calibrados"
 },
 {
  "CNKEY": "701710000080",
  "CN": "7017 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cuarzo o demás sílices fundidos"
 },
 {
  "CNKEY": "701720000080",
  "CN": "7017 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De otro vidrio con un coeficiente de dilatación lineal inferior o igual a 5 × 10–6 por Kelvin, entre 0 °C y 300 °C"
 },
 {
  "CNKEY": "701790000080",
  "CN": "7017 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701800000080",
  "CN": "7018",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuentas de vidrio, imitaciones de perlas, de piedras preciosas o semipreciosas y artículos similares de abalorio, y sus manufacturas (excepto la bisutería); ojos de vidrio (excepto los de prótesis); estatuillas y demás artículos de adorno, de vidrio trabajado al soplete (vidrio ahilado) (excepto la bisutería); microesferas de vidrio con un diámetro inferior o igual a 1 mm"
 },
 {
  "CNKEY": "701810000080",
  "CN": "7018 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cuentas de vidrio, imitaciones de perlas, de piedras preciosas o semipreciosas y artículos similares de abalorio"
 },
 {
  "CNKEY": "701810110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cuentas de vidrio"
 },
 {
  "CNKEY": "701810110080",
  "CN": "7018 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Talladas y pulidas mecánicamente"
 },
 {
  "CNKEY": "701810190080",
  "CN": "7018 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "701810300080",
  "CN": "7018 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Imitaciones de perlas"
 },
 {
  "CNKEY": "701810510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Imitaciones de piedras preciosas y semipreciosas"
 },
 {
  "CNKEY": "701810510080",
  "CN": "7018 10 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Talladas y pulidas mecánicamente"
 },
 {
  "CNKEY": "701810590080",
  "CN": "7018 10 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "701810900080",
  "CN": "7018 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701820000080",
  "CN": "7018 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Microesferas de vidrio con un diámetro inferior o igual a 1 mm"
 },
 {
  "CNKEY": "701890000080",
  "CN": "7018 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "701890100080",
  "CN": "7018 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ojos de vidrio; objetos de abalorio"
 },
 {
  "CNKEY": "701890900080",
  "CN": "7018 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701900000080",
  "CN": "7019",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fibra de vidrio (incluida la lana de vidrio) y manufacturas de esta materia (por ejemplo: hilados, «rovings», tejidos)"
 },
 {
  "CNKEY": "701911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mechas, «rovings», hilados, aunque estén cortados y «mats» de estas materias"
 },
 {
  "CNKEY": "701911000080",
  "CN": "7019 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hilados cortados (chopped strands), de longitud inferior o igual a 50 mm"
 },
 {
  "CNKEY": "701912000080",
  "CN": "7019 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rovings"
 },
 {
  "CNKEY": "701913000080",
  "CN": "7019 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás hilados, mechas"
 },
 {
  "CNKEY": "701914000080",
  "CN": "7019 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "«Mats» unidos mecánicamente"
 },
 {
  "CNKEY": "701915000080",
  "CN": "7019 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "«Mats» unidos químicamente"
 },
 {
  "CNKEY": "701919000080",
  "CN": "7019 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "701961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Telas unidas mecánicamente"
 },
 {
  "CNKEY": "701961000080",
  "CN": "7019 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos planos de «rovings» de malla cerrada"
 },
 {
  "CNKEY": "701962000080",
  "CN": "7019 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás telas de «rovings» de malla cerrada"
 },
 {
  "CNKEY": "701963000080",
  "CN": "7019 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos de hilados de malla cerrada, de ligamento tafetán, sin recubrir ni estratificar"
 },
 {
  "CNKEY": "701964000080",
  "CN": "7019 64 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos de hilados de malla cerrada, de ligamento tafetán, recubiertos o estratificados"
 },
 {
  "CNKEY": "701965000080",
  "CN": "7019 65 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos de malla abierta, de anchura inferior o igual a 30 cm"
 },
 {
  "CNKEY": "701966000080",
  "CN": "7019 66 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tejidos de malla abierta, de anchura superior a 30 cm"
 },
 {
  "CNKEY": "701969000080",
  "CN": "7019 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "701969100080",
  "CN": "7019 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tejidos tricotados y tejidos cosidos con aguja"
 },
 {
  "CNKEY": "701969900080",
  "CN": "7019 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "701971000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Telas unidas químicamente"
 },
 {
  "CNKEY": "701971000080",
  "CN": "7019 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Velos (capas delgadas)"
 },
 {
  "CNKEY": "701972000080",
  "CN": "7019 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás telas de malla cerrada"
 },
 {
  "CNKEY": "701973000080",
  "CN": "7019 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás telas de malla abierta"
 },
 {
  "CNKEY": "701980000080",
  "CN": "7019 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lana de vidrio y sus manufacturas"
 },
 {
  "CNKEY": "701980100080",
  "CN": "7019 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Paneles, colchones y productos similares"
 },
 {
  "CNKEY": "701980900080",
  "CN": "7019 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "701990000080",
  "CN": "7019 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "702000000080",
  "CN": "7020 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de vidrio"
 },
 {
  "CNKEY": "702000050080",
  "CN": "7020 00 05",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tubos y soportes de reactores de cuarzo destinados a hornos de difusión y de oxidación para la producción de materiales semiconductores"
 },
 {
  "CNKEY": "702000070010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ampollas de vidrio para termos o demás recipientes isotérmicos aislados por vacío"
 },
 {
  "CNKEY": "702000070080",
  "CN": "7020 00 07",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin terminar"
 },
 {
  "CNKEY": "702000080080",
  "CN": "7020 00 08",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Terminadas"
 },
 {
  "CNKEY": "702000100010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "702000100080",
  "CN": "7020 00 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cuarzo o de otras sílices, fundidos"
 },
 {
  "CNKEY": "702000300080",
  "CN": "7020 00 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De vidrio con un coeficiente de dilatación lineal inferior o igual a 5 × 10–6 por Kelvin entre 0 °C y 300 °C"
 },
 {
  "CNKEY": "702000800080",
  "CN": "7020 00 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "710011000090",
  "CN": "XIV",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XIV - PERLAS FINAS (NATURALES) O CULTIVADAS, PIEDRAS PRECIOSAS O SEMIPRECIOSAS, METALES PRECIOSOS, CHAPADOS DE METAL PRECIOSO (PLAQUÉ) Y MANUFACTURAS DE ESTAS MATERIAS; BISUTERÍA; MONEDAS"
 },
 {
  "CNKEY": "710021000090",
  "CN": "71",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 71 - PERLAS FINAS (NATURALES) O CULTIVADAS, PIEDRAS PRECIOSAS O SEMIPRECIOSAS, METALES PRECIOSOS, CHAPADOS DE METAL PRECIOSO (PLAQUÉ) Y MANUFACTURAS DE ESTAS MATERIAS; BISUTERÍA; MONEDAS"
 },
 {
  "CNKEY": "710100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. PERLAS FINAS (NATURALES) O CULTIVADAS, PIEDRAS PRECIOSAS O SEMIPRECIOSAS"
 },
 {
  "CNKEY": "710100000080",
  "CN": "7101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Perlas finas (naturales) o cultivadas, incluso trabajadas o clasificadas, pero sin ensartar, montar ni engarzar; perlas finas (naturales) o cultivadas, ensartadas temporalmente para facilitar el transporte"
 },
 {
  "CNKEY": "710110000080",
  "CN": "7101 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Perlas finas (naturales)"
 },
 {
  "CNKEY": "710121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perlas cultivadas"
 },
 {
  "CNKEY": "710121000080",
  "CN": "7101 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto"
 },
 {
  "CNKEY": "710122000080",
  "CN": "7101 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Trabajadas"
 },
 {
  "CNKEY": "710200000080",
  "CN": "7102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Diamantes, incluso trabajados, sin montar ni engarzar"
 },
 {
  "CNKEY": "710210000080",
  "CN": "7102 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "c\/k",
  "ES": "Sin clasificar"
 },
 {
  "CNKEY": "710221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Industriales"
 },
 {
  "CNKEY": "710221000080",
  "CN": "7102 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "c\/k",
  "ES": "En bruto o simplemente aserrados, exfoliados o desbastados"
 },
 {
  "CNKEY": "710229000080",
  "CN": "7102 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "c\/k",
  "ES": "Los demás"
 },
 {
  "CNKEY": "710231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "No industriales"
 },
 {
  "CNKEY": "710231000080",
  "CN": "7102 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "c\/k",
  "ES": "En bruto o simplemente aserrados, exfoliados o desbastados"
 },
 {
  "CNKEY": "710239000080",
  "CN": "7102 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "c\/k",
  "ES": "Los demás"
 },
 {
  "CNKEY": "710300000080",
  "CN": "7103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Piedras preciosas (excepto los diamantes) o semipreciosas, naturales, incluso trabajadas o clasificadas, sin ensartar, montar ni engarzar; piedras preciosas (excepto los diamantes) o semipreciosas, naturales, sin clasificar, ensartadas temporalmente para facilitar el transporte"
 },
 {
  "CNKEY": "710310000080",
  "CN": "7103 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En bruto o simplemente aserradas o desbastadas"
 },
 {
  "CNKEY": "710391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Trabajadas de otro modo"
 },
 {
  "CNKEY": "710391000080",
  "CN": "7103 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Rubíes, zafiros y esmeraldas"
 },
 {
  "CNKEY": "710399000080",
  "CN": "7103 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "710400000080",
  "CN": "7104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Piedras preciosas o semipreciosas, sintéticas o reconstituidas, incluso trabajadas o clasificadas, sin ensartar, montar ni engarzar; piedras preciosas o semipreciosas, sintéticas o reconstituidas, sin clasificar, ensartadas temporalmente para facilitar el transporte"
 },
 {
  "CNKEY": "710410000080",
  "CN": "7104 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Cuarzo piezoeléctrico"
 },
 {
  "CNKEY": "710421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, en bruto o simplemente aserradas o desbastadas"
 },
 {
  "CNKEY": "710421000080",
  "CN": "7104 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Diamantes"
 },
 {
  "CNKEY": "710429000080",
  "CN": "7104 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "710491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "710491000080",
  "CN": "7104 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Diamantes"
 },
 {
  "CNKEY": "710499000080",
  "CN": "7104 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "710500000080",
  "CN": "7105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polvo de piedras preciosas o semipreciosas, naturales o sintéticas"
 },
 {
  "CNKEY": "710510000080",
  "CN": "7105 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "De diamante"
 },
 {
  "CNKEY": "710590000080",
  "CN": "7105 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "710600000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. METALES PRECIOSOS Y CHAPADOS DE METAL PRECIOSO (PLAQUÉ)"
 },
 {
  "CNKEY": "710600000080",
  "CN": "7106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Plata (incluida la plata dorada y la platinada) en bruto, semilabrada o en polvo"
 },
 {
  "CNKEY": "710610000080",
  "CN": "7106 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Polvo"
 },
 {
  "CNKEY": "710691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "710691000080",
  "CN": "7106 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto"
 },
 {
  "CNKEY": "710692000080",
  "CN": "7106 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Semilabrada"
 },
 {
  "CNKEY": "710700000080",
  "CN": "7107 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Chapado (plaqué) de plata sobre metal común, en bruto o semilabrado"
 },
 {
  "CNKEY": "710800000080",
  "CN": "7108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Oro (incluido el oro platinado) en bruto, semilabrado o en polvo"
 },
 {
  "CNKEY": "710811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para uso no monetario"
 },
 {
  "CNKEY": "710811000080",
  "CN": "7108 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Polvo"
 },
 {
  "CNKEY": "710812000080",
  "CN": "7108 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Las demás formas en bruto"
 },
 {
  "CNKEY": "710813000080",
  "CN": "7108 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás formas semilabradas"
 },
 {
  "CNKEY": "710813100080",
  "CN": "7108 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "g",
  "ES": "Barras, alambres y perfiles de sección maciza; planchas, hojas y bandas de espesor superior a 0,15 mm, sin incluir el soporte"
 },
 {
  "CNKEY": "710813800080",
  "CN": "7108 13 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "710820000080",
  "CN": "7108 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Para uso monetario"
 },
 {
  "CNKEY": "710900000080",
  "CN": "7109 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Chapado (plaqué) de oro sobre metal común o sobre plata, en bruto o semilabrado"
 },
 {
  "CNKEY": "711000000080",
  "CN": "7110",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Platino en bruto, semilabrado o en polvo"
 },
 {
  "CNKEY": "711011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Platino"
 },
 {
  "CNKEY": "711011000080",
  "CN": "7110 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto o en polvo"
 },
 {
  "CNKEY": "711019000080",
  "CN": "7110 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "711019100080",
  "CN": "7110 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "g",
  "ES": "Barras, alambres y perfiles de sección maciza; planchas, hojas y bandas de espesor superior a 0,15 mm, sin incluir el soporte"
 },
 {
  "CNKEY": "711019800080",
  "CN": "7110 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "711021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Paladio"
 },
 {
  "CNKEY": "711021000080",
  "CN": "7110 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto o en polvo"
 },
 {
  "CNKEY": "711029000080",
  "CN": "7110 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "711031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Rodio"
 },
 {
  "CNKEY": "711031000080",
  "CN": "7110 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto o en polvo"
 },
 {
  "CNKEY": "711039000080",
  "CN": "7110 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "711041000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Iridio, osmio y rutenio"
 },
 {
  "CNKEY": "711041000080",
  "CN": "7110 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "En bruto o en polvo"
 },
 {
  "CNKEY": "711049000080",
  "CN": "7110 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Los demás"
 },
 {
  "CNKEY": "711100000080",
  "CN": "7111 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Chapado (plaqué) de platino sobre metal común, plata u oro, en bruto o semilabrado"
 },
 {
  "CNKEY": "711200000080",
  "CN": "7112",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de metal precioso o de chapado de metal precioso (plaqué); demás desperdicios y desechos que contengan metal precioso o compuestos de metal precioso, de los tipos utilizados principalmente para la recuperación del metal precioso, distintos de los productos de la partida 8549"
 },
 {
  "CNKEY": "711230000080",
  "CN": "7112 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cenizas que contengan metal precioso o compuestos de metal precioso"
 },
 {
  "CNKEY": "711291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "711291000080",
  "CN": "7112 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De oro o de chapado (plaqué) de oro (excepto las barreduras que contengan otro metal precioso)"
 },
 {
  "CNKEY": "711292000080",
  "CN": "7112 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De platino o de chapado (plaqué) de platino (excepto las barreduras que contengan otro metal precioso)"
 },
 {
  "CNKEY": "711299000080",
  "CN": "7112 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "711300000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "III. JOYERÍA Y DEMÁS MANUFACTURAS"
 },
 {
  "CNKEY": "711300000080",
  "CN": "7113",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de joyería y sus partes, de metal precioso o de chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De metal precioso, incluso revestido o chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711311000080",
  "CN": "7113 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "De plata, incluso revestida o chapada de otro metal precioso (plaqué)"
 },
 {
  "CNKEY": "711319000080",
  "CN": "7113 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "De los demás metales preciosos, incluso revestidos o chapados de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711320000080",
  "CN": "7113 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De chapado de metal precioso (plaqué) sobre metal común"
 },
 {
  "CNKEY": "711400000080",
  "CN": "7114",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de orfebrería y sus partes, de metal precioso o de chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De metal precioso, incluso revestido o chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711411000080",
  "CN": "7114 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "De plata, incluso revestida o chapada de otro metal precioso (plaqué)"
 },
 {
  "CNKEY": "711419000080",
  "CN": "7114 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "De los demás metales preciosos, incluso revestidos o chapados de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711420000080",
  "CN": "7114 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De chapado de metal precioso (plaqué) sobre metal común"
 },
 {
  "CNKEY": "711500000080",
  "CN": "7115",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de metal precioso o de chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "711510000080",
  "CN": "7115 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Catalizadores de platino en forma de tela o enrejado"
 },
 {
  "CNKEY": "711590000080",
  "CN": "7115 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "711600000080",
  "CN": "7116",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manufacturas de perlas finas (naturales) o cultivadas, de piedras preciosas o semipreciosas (naturales, sintéticas o reconstituidas)"
 },
 {
  "CNKEY": "711610000080",
  "CN": "7116 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "De perlas finas (naturales) o cultivadas"
 },
 {
  "CNKEY": "711620000080",
  "CN": "7116 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De piedras preciosas o semipreciosas (naturales, sintéticas o reconstituidas)"
 },
 {
  "CNKEY": "711620110080",
  "CN": "7116 20 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Collares, pulseras y otras manufacturas exclusivamente de piedras preciosas o semipreciosas, simplemente ensartadas, sin dispositivos de cierre ni otros accesorios"
 },
 {
  "CNKEY": "711620800080",
  "CN": "7116 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "711700000080",
  "CN": "7117",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bisutería"
 },
 {
  "CNKEY": "711711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De metal común, incluso plateado, dorado o platinado"
 },
 {
  "CNKEY": "711711000080",
  "CN": "7117 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Gemelos y pasadores similares"
 },
 {
  "CNKEY": "711719000080",
  "CN": "7117 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "711790000080",
  "CN": "7117 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "711800000080",
  "CN": "7118",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monedas"
 },
 {
  "CNKEY": "711810000080",
  "CN": "7118 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Monedas sin curso legal (excepto las de oro)"
 },
 {
  "CNKEY": "711890000080",
  "CN": "7118 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "g",
  "ES": "Las demás"
 },
 {
  "CNKEY": "720011000090",
  "CN": "XV",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XV - METALES COMUNES Y MANUFACTURAS DE ESTOS METALES"
 },
 {
  "CNKEY": "720021000090",
  "CN": "72",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 72 - FUNDICIÓN, HIERRO Y ACERO"
 },
 {
  "CNKEY": "720100000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "I. PRODUCTOS BÁSICOS; GRANALLAS Y POLVO"
 },
 {
  "CNKEY": "720100000080",
  "CN": "7201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fundición en bruto y fundición especular, en lingotes, bloques o demás formas primarias"
 },
 {
  "CNKEY": "720110000080",
  "CN": "7201 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fundición en bruto sin alear con un contenido de fósforo inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "720110110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de manganeso superior o igual al 0,4 % en peso"
 },
 {
  "CNKEY": "720110110080",
  "CN": "7201 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de silicio inferior o igual al 1 % en peso"
 },
 {
  "CNKEY": "720110190080",
  "CN": "7201 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de silicio superior al 1 % en peso"
 },
 {
  "CNKEY": "720110300080",
  "CN": "7201 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de manganeso superior o igual al 0,1 % pero inferior al 0,4 % en peso"
 },
 {
  "CNKEY": "720110900080",
  "CN": "7201 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de manganeso inferior al 0,1 % en peso"
 },
 {
  "CNKEY": "720120000080",
  "CN": "7201 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fundición en bruto sin alear con un contenido de fósforo superior al 0,5 % en peso"
 },
 {
  "CNKEY": "720150000080",
  "CN": "7201 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fundición en bruto aleada; fundición especular"
 },
 {
  "CNKEY": "720150100080",
  "CN": "7201 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fundición en bruto aleada con unos contenidos de titanio superior o igual al 0,3 % pero inferior o igual al 1 % en peso y de vanadio superior o igual al 0,5 % pero inferior o igual al 1 % en peso"
 },
 {
  "CNKEY": "720150900080",
  "CN": "7201 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "720200000080",
  "CN": "7202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Ferroaleaciones"
 },
 {
  "CNKEY": "720211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ferromanganeso"
 },
 {
  "CNKEY": "720211000080",
  "CN": "7202 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono superior al 2 % en peso"
 },
 {
  "CNKEY": "720211200080",
  "CN": "7202 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De granulometría inferior o igual a 5 mm y con un contenido de manganeso superior al 65 % en peso"
 },
 {
  "CNKEY": "720211800080",
  "CN": "7202 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720219000080",
  "CN": "7202 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ferrosilicio"
 },
 {
  "CNKEY": "720221000080",
  "CN": "7202 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de silicio superior al 55 % en peso"
 },
 {
  "CNKEY": "720229000080",
  "CN": "7202 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720229100080",
  "CN": "7202 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de magnesio superior o igual al 4 % pero inferior o igual al 10 % en peso"
 },
 {
  "CNKEY": "720229900080",
  "CN": "7202 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720230000080",
  "CN": "7202 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ferro-sílico-manganeso"
 },
 {
  "CNKEY": "720241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ferrocromo"
 },
 {
  "CNKEY": "720241000080",
  "CN": "7202 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono superior al 4 % en peso"
 },
 {
  "CNKEY": "720241100080",
  "CN": "7202 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 4 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "720241900080",
  "CN": "7202 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior al 6 % en peso"
 },
 {
  "CNKEY": "720249000080",
  "CN": "7202 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720249100080",
  "CN": "7202 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono inferior o igual al 0,05 % en peso"
 },
 {
  "CNKEY": "720249500080",
  "CN": "7202 49 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior al 0,05 % pero inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "720249900080",
  "CN": "7202 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior al 0,5 % pero inferior o igual al 4 % en peso"
 },
 {
  "CNKEY": "720250000080",
  "CN": "7202 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ferro-sílico-cromo"
 },
 {
  "CNKEY": "720260000080",
  "CN": "7202 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ferroníquel"
 },
 {
  "CNKEY": "720270000080",
  "CN": "7202 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ferromolibdeno"
 },
 {
  "CNKEY": "720280000080",
  "CN": "7202 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ferrovolframio y ferro-sílico-volframio"
 },
 {
  "CNKEY": "720291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "720291000080",
  "CN": "7202 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ferrotitanio y ferro-sílico-titanio"
 },
 {
  "CNKEY": "720292000080",
  "CN": "7202 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ferrovanadio"
 },
 {
  "CNKEY": "720293000080",
  "CN": "7202 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ferroniobio"
 },
 {
  "CNKEY": "720299000080",
  "CN": "7202 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "720299100080",
  "CN": "7202 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ferrofósforo"
 },
 {
  "CNKEY": "720299300080",
  "CN": "7202 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ferro-sílico-magnesio"
 },
 {
  "CNKEY": "720299800080",
  "CN": "7202 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "720300000080",
  "CN": "7203",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos férreos obtenidos por reducción directa de minerales de hierro y demás productos férreos esponjosos, en trozos, pellets o formas similares; hierro con una pureza superior o igual al 99,94 % en peso, en trozos, pellets o formas similares"
 },
 {
  "CNKEY": "720310000080",
  "CN": "7203 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Productos férreos obtenidos por reducción directa de minerales de hierro"
 },
 {
  "CNKEY": "720390000080",
  "CN": "7203 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720400000080",
  "CN": "7204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos (chatarra), de fundición, hierro o acero; lingotes de chatarra de hierro o acero"
 },
 {
  "CNKEY": "720410000080",
  "CN": "7204 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos, de fundición"
 },
 {
  "CNKEY": "720421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de aceros aleados"
 },
 {
  "CNKEY": "720421000080",
  "CN": "7204 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "720421100080",
  "CN": "7204 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 8 % en peso"
 },
 {
  "CNKEY": "720421900080",
  "CN": "7204 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720429000080",
  "CN": "7204 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720430000080",
  "CN": "7204 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos, de hierro o acero estañados"
 },
 {
  "CNKEY": "720441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás desperdicios y desechos"
 },
 {
  "CNKEY": "720441000080",
  "CN": "7204 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Torneaduras, virutas, esquirlas, limaduras (de amolado, aserrado, limado) y recortes de estampado o de corte, incluso en paquetes"
 },
 {
  "CNKEY": "720441100080",
  "CN": "7204 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Torneaduras, virutas, esquirlas, limaduras (de amolado, aserrado, limado)"
 },
 {
  "CNKEY": "720441910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Recortes de estampado o de corte"
 },
 {
  "CNKEY": "720441910080",
  "CN": "7204 41 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En paquetes"
 },
 {
  "CNKEY": "720441990080",
  "CN": "7204 41 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720449000080",
  "CN": "7204 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720449100080",
  "CN": "7204 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Otros recortes"
 },
 {
  "CNKEY": "720449300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720449300080",
  "CN": "7204 49 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En paquetes"
 },
 {
  "CNKEY": "720449900080",
  "CN": "7204 49 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720450000080",
  "CN": "7204 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lingotes de chatarra"
 },
 {
  "CNKEY": "720500000080",
  "CN": "7205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Granallas y polvo, de fundición en bruto, de fundición especular, de hierro o acero"
 },
 {
  "CNKEY": "720510000080",
  "CN": "7205 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Granallas"
 },
 {
  "CNKEY": "720521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polvo"
 },
 {
  "CNKEY": "720521000080",
  "CN": "7205 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aceros aleados"
 },
 {
  "CNKEY": "720529000080",
  "CN": "7205 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720600000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "II. HIERRO Y ACERO SIN ALEAR"
 },
 {
  "CNKEY": "720600000080",
  "CN": "7206",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hierro y acero sin alear, en lingotes o demás formas primarias (excepto el hierro de la partida 7203)"
 },
 {
  "CNKEY": "720610000080",
  "CN": "7206 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lingotes"
 },
 {
  "CNKEY": "720690000080",
  "CN": "7206 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720700000080",
  "CN": "7207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos intermedios de hierro o acero sin alear"
 },
 {
  "CNKEY": "720711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "720711000080",
  "CN": "7207 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección transversal cuadrada o rectangular, cuya anchura sea inferior al doble del espesor"
 },
 {
  "CNKEY": "720711110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "720711110080",
  "CN": "7207 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero de fácil mecanización"
 },
 {
  "CNKEY": "720711140010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720711140080",
  "CN": "7207 11 14",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De espesor inferior o igual a 130 mm"
 },
 {
  "CNKEY": "720711160080",
  "CN": "7207 11 16",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De espesor superior a 130 mm"
 },
 {
  "CNKEY": "720711900080",
  "CN": "7207 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720712000080",
  "CN": "7207 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de sección transversal rectangular"
 },
 {
  "CNKEY": "720712100080",
  "CN": "7207 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "720712900080",
  "CN": "7207 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720719000080",
  "CN": "7207 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720719120010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De sección transversal circular o poligonal"
 },
 {
  "CNKEY": "720719120080",
  "CN": "7207 19 12",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por coladas continuas"
 },
 {
  "CNKEY": "720719190080",
  "CN": "7207 19 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720719800080",
  "CN": "7207 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720720000080",
  "CN": "7207 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono superior o igual al 0,25 % en peso"
 },
 {
  "CNKEY": "720720110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección transversal cuadrada o rectangular, cuya anchura sea inferior al doble del espesor"
 },
 {
  "CNKEY": "720720110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "720720110080",
  "CN": "7207 20 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero de fácil mecanización"
 },
 {
  "CNKEY": "720720150010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, con un contenido"
 },
 {
  "CNKEY": "720720150080",
  "CN": "7207 20 15",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De carbono superior o igual al 0,25 % pero inferior al 0,6 % en peso"
 },
 {
  "CNKEY": "720720170080",
  "CN": "7207 20 17",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De carbono superior o igual al 0,6 % en peso"
 },
 {
  "CNKEY": "720720190080",
  "CN": "7207 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720720320010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de sección transversal rectangular"
 },
 {
  "CNKEY": "720720320080",
  "CN": "7207 20 32",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "720720390080",
  "CN": "7207 20 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720720520010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección transversal circular o poligonal"
 },
 {
  "CNKEY": "720720520080",
  "CN": "7207 20 52",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "720720590080",
  "CN": "7207 20 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "720720800080",
  "CN": "7207 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720800000080",
  "CN": "7208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de hierro o acero sin alear, de anchura superior o igual a 600 mm, laminados en caliente, sin chapar ni revestir"
 },
 {
  "CNKEY": "720810000080",
  "CN": "7208 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Enrollados, simplemente laminados en caliente, con motivos en relieve"
 },
 {
  "CNKEY": "720825000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, enrollados, simplemente laminados en caliente, decapados"
 },
 {
  "CNKEY": "720825000080",
  "CN": "7208 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm"
 },
 {
  "CNKEY": "720826000080",
  "CN": "7208 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "720827000080",
  "CN": "7208 27 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 3 mm"
 },
 {
  "CNKEY": "720836000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, enrollados, simplemente laminados en caliente"
 },
 {
  "CNKEY": "720836000080",
  "CN": "7208 36 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior a 10 mm"
 },
 {
  "CNKEY": "720837000080",
  "CN": "7208 37 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm pero inferior o igual a 10 mm"
 },
 {
  "CNKEY": "720838000080",
  "CN": "7208 38 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "720839000080",
  "CN": "7208 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 3 mm"
 },
 {
  "CNKEY": "720840000080",
  "CN": "7208 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sin enrollar, simplemente laminados en caliente, con motivos en relieve"
 },
 {
  "CNKEY": "720851000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, sin enrollar, simplemente laminados en caliente"
 },
 {
  "CNKEY": "720851000080",
  "CN": "7208 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 10 mm"
 },
 {
  "CNKEY": "720851200080",
  "CN": "7208 51 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior a 15 mm"
 },
 {
  "CNKEY": "720851910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De espesor superior a 10 mm pero inferior o igual a 15 mm, de anchura"
 },
 {
  "CNKEY": "720851910080",
  "CN": "7208 51 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 2050 mm"
 },
 {
  "CNKEY": "720851980080",
  "CN": "7208 51 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 2050 mm"
 },
 {
  "CNKEY": "720852000080",
  "CN": "7208 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 4,75 mm pero inferior o igual a 10 mm"
 },
 {
  "CNKEY": "720852100080",
  "CN": "7208 52 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados en las cuatro caras o en acanaladuras cerradas, de anchura inferior o igual a 1250 mm"
 },
 {
  "CNKEY": "720852910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, de anchura"
 },
 {
  "CNKEY": "720852910080",
  "CN": "7208 52 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 2050 mm"
 },
 {
  "CNKEY": "720852990080",
  "CN": "7208 52 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 2050 mm"
 },
 {
  "CNKEY": "720853000080",
  "CN": "7208 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "720853100080",
  "CN": "7208 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados en las cuatro caras o en acanaladuras cerradas, de anchura inferior o igual a 1250 mm y de espesor superior o igual a 4 mm"
 },
 {
  "CNKEY": "720853900080",
  "CN": "7208 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720854000080",
  "CN": "7208 54 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 3 mm"
 },
 {
  "CNKEY": "720890000080",
  "CN": "7208 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720890200080",
  "CN": "7208 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perforados"
 },
 {
  "CNKEY": "720890800080",
  "CN": "7208 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720900000080",
  "CN": "7209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de hierro o acero sin alear, de anchura superior o igual a 600 mm, laminados en frío, sin chapar ni revestir"
 },
 {
  "CNKEY": "720915000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Enrollados, simplemente laminados en frío"
 },
 {
  "CNKEY": "720915000080",
  "CN": "7209 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 3 mm"
 },
 {
  "CNKEY": "720916000080",
  "CN": "7209 16",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 1 mm pero inferior a 3 mm"
 },
 {
  "CNKEY": "720916100080",
  "CN": "7209 16 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720916900080",
  "CN": "7209 16 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720917000080",
  "CN": "7209 17",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 0,5 mm pero inferior o igual a 1 mm"
 },
 {
  "CNKEY": "720917100080",
  "CN": "7209 17 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720917900080",
  "CN": "7209 17 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720918000080",
  "CN": "7209 18",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 0,5 mm"
 },
 {
  "CNKEY": "720918100080",
  "CN": "7209 18 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720918910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720918910080",
  "CN": "7209 18 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 0,35 mm pero inferior a 0,5 mm"
 },
 {
  "CNKEY": "720918990080",
  "CN": "7209 18 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 0,35 mm"
 },
 {
  "CNKEY": "720925000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin enrollar, simplemente laminados en frío"
 },
 {
  "CNKEY": "720925000080",
  "CN": "7209 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 3 mm"
 },
 {
  "CNKEY": "720926000080",
  "CN": "7209 26",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 1 mm pero inferior a 3 mm"
 },
 {
  "CNKEY": "720926100080",
  "CN": "7209 26 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720926900080",
  "CN": "7209 26 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720927000080",
  "CN": "7209 27",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 0,5 mm pero inferior o igual a 1 mm"
 },
 {
  "CNKEY": "720927100080",
  "CN": "7209 27 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720927900080",
  "CN": "7209 27 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720928000080",
  "CN": "7209 28",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 0,5 mm"
 },
 {
  "CNKEY": "720928100080",
  "CN": "7209 28 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "720928900080",
  "CN": "7209 28 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "720990000080",
  "CN": "7209 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "720990200080",
  "CN": "7209 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perforados"
 },
 {
  "CNKEY": "720990800080",
  "CN": "7209 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721000000080",
  "CN": "7210",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de hierro o acero sin alear, de anchura superior o igual a 600 mm, chapados o revestidos"
 },
 {
  "CNKEY": "721011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estañados"
 },
 {
  "CNKEY": "721011000080",
  "CN": "7210 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 0,5 mm"
 },
 {
  "CNKEY": "721012000080",
  "CN": "7210 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 0,5 mm"
 },
 {
  "CNKEY": "721012200080",
  "CN": "7210 12 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Hojalata"
 },
 {
  "CNKEY": "721012800080",
  "CN": "7210 12 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721020000080",
  "CN": "7210 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Emplomados, incluidos los revestidos con una aleación de plomo y estaño"
 },
 {
  "CNKEY": "721030000080",
  "CN": "7210 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cincados electrolíticamente"
 },
 {
  "CNKEY": "721041000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cincados de otro modo"
 },
 {
  "CNKEY": "721041000080",
  "CN": "7210 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ondulados"
 },
 {
  "CNKEY": "721049000080",
  "CN": "7210 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721050000080",
  "CN": "7210 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Revestidos de óxidos de cromo o de cromo y óxidos de cromo"
 },
 {
  "CNKEY": "721061000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Revestidos de aluminio"
 },
 {
  "CNKEY": "721061000080",
  "CN": "7210 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Revestidos de aleaciones de aluminio y cinc"
 },
 {
  "CNKEY": "721069000080",
  "CN": "7210 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721070000080",
  "CN": "7210 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pintados, barnizados o revestidos de plástico"
 },
 {
  "CNKEY": "721070100080",
  "CN": "7210 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojalata, barnizada; productos revestidos de óxidos de cromo o de cromo y óxidos de cromo, barnizados"
 },
 {
  "CNKEY": "721070800080",
  "CN": "7210 70 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721090000080",
  "CN": "7210 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721090300080",
  "CN": "7210 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chapados"
 },
 {
  "CNKEY": "721090400080",
  "CN": "7210 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estañados o impresos"
 },
 {
  "CNKEY": "721090800080",
  "CN": "7210 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721100000080",
  "CN": "7211",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de hierro o acero sin alear, de anchura inferior a 600 mm, sin chapar ni revestir"
 },
 {
  "CNKEY": "721113000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en caliente"
 },
 {
  "CNKEY": "721113000080",
  "CN": "7211 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Laminados en las cuatro caras o en acanaladuras cerradas, de anchura superior a 150 mm y espesor superior o igual a 4 mm, sin enrollar y sin motivos en relieve"
 },
 {
  "CNKEY": "721114000080",
  "CN": "7211 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de espesor superior o igual a 4,75 mm"
 },
 {
  "CNKEY": "721119000080",
  "CN": "7211 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721123000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en frío"
 },
 {
  "CNKEY": "721123000080",
  "CN": "7211 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721123200080",
  "CN": "7211 23 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llamados «magnéticos»"
 },
 {
  "CNKEY": "721123300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721123300080",
  "CN": "7211 23 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 0,35 mm"
 },
 {
  "CNKEY": "721123800080",
  "CN": "7211 23 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 0,35 mm"
 },
 {
  "CNKEY": "721129000080",
  "CN": "7211 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721190000080",
  "CN": "7211 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721190200080",
  "CN": "7211 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perforados"
 },
 {
  "CNKEY": "721190800080",
  "CN": "7211 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721200000080",
  "CN": "7212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de hierro o acero sin alear, de anchura inferior a 600 mm, chapados o revestidos"
 },
 {
  "CNKEY": "721210000080",
  "CN": "7212 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estañados"
 },
 {
  "CNKEY": "721210100080",
  "CN": "7212 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojalata simplemente tratada en la superficie"
 },
 {
  "CNKEY": "721210900080",
  "CN": "7212 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721220000080",
  "CN": "7212 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cincados electrolíticamente"
 },
 {
  "CNKEY": "721230000080",
  "CN": "7212 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cincados de otro modo"
 },
 {
  "CNKEY": "721240000080",
  "CN": "7212 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pintados, barnizados o revestidos de plástico"
 },
 {
  "CNKEY": "721240200080",
  "CN": "7212 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojalata simplemente barnizada; productos revestidos de óxidos de cromo o de cromo y óxidos de cromo, barnizados"
 },
 {
  "CNKEY": "721240800080",
  "CN": "7212 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721250000080",
  "CN": "7212 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Revestidos de otro modo"
 },
 {
  "CNKEY": "721250200080",
  "CN": "7212 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Revestidos de óxidos de cromo o de cromo y óxidos de cromo"
 },
 {
  "CNKEY": "721250300080",
  "CN": "7212 50 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cromados o niquelados"
 },
 {
  "CNKEY": "721250400080",
  "CN": "7212 50 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cobreados"
 },
 {
  "CNKEY": "721250610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Revestidos de aluminio"
 },
 {
  "CNKEY": "721250610080",
  "CN": "7212 50 61",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Revestidos de aleaciones aluminio y cinc"
 },
 {
  "CNKEY": "721250690080",
  "CN": "7212 50 69",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721250900080",
  "CN": "7212 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721260000080",
  "CN": "7212 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Chapados"
 },
 {
  "CNKEY": "721300000080",
  "CN": "7213",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambrón de hierro o acero sin alear"
 },
 {
  "CNKEY": "721310000080",
  "CN": "7213 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con muescas, cordones, surcos o relieves, producidos en el laminado"
 },
 {
  "CNKEY": "721320000080",
  "CN": "7213 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, de acero de fácil mecanización"
 },
 {
  "CNKEY": "721391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721391000080",
  "CN": "7213 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección circular con diámetro inferior a 14 mm"
 },
 {
  "CNKEY": "721391100080",
  "CN": "7213 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Del tipo utilizado como armadura del hormigón"
 },
 {
  "CNKEY": "721391200080",
  "CN": "7213 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Del tipo utilizado como refuerzo de neumáticos"
 },
 {
  "CNKEY": "721391410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721391410080",
  "CN": "7213 91 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono inferior o igual al 0,06 % en peso"
 },
 {
  "CNKEY": "721391490080",
  "CN": "7213 91 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior al 0,06 % pero inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721391700080",
  "CN": "7213 91 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % pero inferior o igual al 0,75 % en peso"
 },
 {
  "CNKEY": "721391900080",
  "CN": "7213 91 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior al 0,75 % en peso"
 },
 {
  "CNKEY": "721399000080",
  "CN": "7213 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721399100080",
  "CN": "7213 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721399900080",
  "CN": "7213 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % en peso"
 },
 {
  "CNKEY": "721400000080",
  "CN": "7214",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras de hierro o acero sin alear, simplemente forjadas, laminadas o extrudidas, en caliente, así como las sometidas a torsión después del laminado"
 },
 {
  "CNKEY": "721410000080",
  "CN": "7214 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Forjadas"
 },
 {
  "CNKEY": "721420000080",
  "CN": "7214 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con muescas, cordones, surcos o relieves, producidos en el laminado o sometidas a torsión después del laminado"
 },
 {
  "CNKEY": "721430000080",
  "CN": "7214 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás, de acero de fácil mecanización"
 },
 {
  "CNKEY": "721491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "721491000080",
  "CN": "7214 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección transversal rectangular"
 },
 {
  "CNKEY": "721491100080",
  "CN": "7214 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721491900080",
  "CN": "7214 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % en peso"
 },
 {
  "CNKEY": "721499000080",
  "CN": "7214 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "721499100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721499100080",
  "CN": "7214 99 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados como armadura del hormigón"
 },
 {
  "CNKEY": "721499310010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás, de sección circular y diámetro"
 },
 {
  "CNKEY": "721499310080",
  "CN": "7214 99 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 80 mm"
 },
 {
  "CNKEY": "721499390080",
  "CN": "7214 99 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior a 80 mm"
 },
 {
  "CNKEY": "721499500080",
  "CN": "7214 99 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "721499710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono superior o igual al 0,25 % en peso"
 },
 {
  "CNKEY": "721499710020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De sección circular y diámetro"
 },
 {
  "CNKEY": "721499710080",
  "CN": "7214 99 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 80 mm"
 },
 {
  "CNKEY": "721499790080",
  "CN": "7214 99 79",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior a 80 mm"
 },
 {
  "CNKEY": "721499950080",
  "CN": "7214 99 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "721500000080",
  "CN": "7215",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás barras de hierro o acero sin alear"
 },
 {
  "CNKEY": "721510000080",
  "CN": "7215 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acero de fácil mecanización, simplemente obtenidas o acabadas en frío"
 },
 {
  "CNKEY": "721550000080",
  "CN": "7215 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, simplemente obtenidas o acabadas en frío"
 },
 {
  "CNKEY": "721550110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721550110080",
  "CN": "7215 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De sección rectangular"
 },
 {
  "CNKEY": "721550190080",
  "CN": "7215 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "721550800080",
  "CN": "7215 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % en peso"
 },
 {
  "CNKEY": "721590000080",
  "CN": "7215 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "721600000080",
  "CN": "7216",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Perfiles de hierro o acero sin alear"
 },
 {
  "CNKEY": "721610000080",
  "CN": "7216 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfiles en U, en I o en H, simplemente laminados o extrudidos en caliente, de altura inferior a 80 mm"
 },
 {
  "CNKEY": "721621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles en L o en T, simplemente laminados o extrudidos en caliente, de altura inferior a 80 mm"
 },
 {
  "CNKEY": "721621000080",
  "CN": "7216 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles en L"
 },
 {
  "CNKEY": "721622000080",
  "CN": "7216 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles en T"
 },
 {
  "CNKEY": "721631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles en U, en I o en H, simplemente laminados o extrudidos en caliente, de altura superior o igual a 80 mm"
 },
 {
  "CNKEY": "721631000080",
  "CN": "7216 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Perfiles en U"
 },
 {
  "CNKEY": "721631100080",
  "CN": "7216 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De altura superior o igual a 80 mm pero inferior o igual a 220 mm"
 },
 {
  "CNKEY": "721631900080",
  "CN": "7216 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De altura superior a 220 mm"
 },
 {
  "CNKEY": "721632000080",
  "CN": "7216 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Perfiles en I"
 },
 {
  "CNKEY": "721632110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De altura superior o igual a 80 mm pero inferior o igual a 220 mm"
 },
 {
  "CNKEY": "721632110080",
  "CN": "7216 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De alas con caras paralelas"
 },
 {
  "CNKEY": "721632190080",
  "CN": "7216 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721632910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De altura superior a 220 mm"
 },
 {
  "CNKEY": "721632910080",
  "CN": "7216 32 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De alas con caras paralelas"
 },
 {
  "CNKEY": "721632990080",
  "CN": "7216 32 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721633000080",
  "CN": "7216 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Perfiles en H"
 },
 {
  "CNKEY": "721633100080",
  "CN": "7216 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De altura superior o igual a 80 mm pero inferior o igual a 180 mm"
 },
 {
  "CNKEY": "721633900080",
  "CN": "7216 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De altura superior a 180 mm"
 },
 {
  "CNKEY": "721640000080",
  "CN": "7216 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles en L o en T, simplemente laminados o extrudidos en caliente, de altura superior o igual a 80 mm"
 },
 {
  "CNKEY": "721640100080",
  "CN": "7216 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles en L"
 },
 {
  "CNKEY": "721640900080",
  "CN": "7216 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles en T"
 },
 {
  "CNKEY": "721650000080",
  "CN": "7216 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás perfiles, simplemente laminados o extrudidos en caliente"
 },
 {
  "CNKEY": "721650100080",
  "CN": "7216 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De sección transversal inscribible en un cuadrado de lado inferior o igual a 80 mm"
 },
 {
  "CNKEY": "721650910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721650910080",
  "CN": "7216 50 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Lisos con rebordes o pestañas"
 },
 {
  "CNKEY": "721650990080",
  "CN": "7216 50 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721661000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles simplemente obtenidos o acabados en frío"
 },
 {
  "CNKEY": "721661000080",
  "CN": "7216 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Obtenidos a partir de productos laminados planos"
 },
 {
  "CNKEY": "721661100080",
  "CN": "7216 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perfiles en C, L, U, Z, omega o en tubo abierto"
 },
 {
  "CNKEY": "721661900080",
  "CN": "7216 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721669000080",
  "CN": "7216 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721691000080",
  "CN": "7216 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Obtenidos o acabados en frío, a partir de productos laminados planos"
 },
 {
  "CNKEY": "721691100080",
  "CN": "7216 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Chapas con nervaduras"
 },
 {
  "CNKEY": "721691800080",
  "CN": "7216 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721699000080",
  "CN": "7216 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721700000080",
  "CN": "7217",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambre de hierro o acero sin alear"
 },
 {
  "CNKEY": "721710000080",
  "CN": "7217 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin revestir, incluso pulido"
 },
 {
  "CNKEY": "721710100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721710100080",
  "CN": "7217 10 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión del corte transversal inferior a 0,8 mm"
 },
 {
  "CNKEY": "721710310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con la mayor dimensión del corte transversal superior o igual a 0,8 mm"
 },
 {
  "CNKEY": "721710310080",
  "CN": "7217 10 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con muescas, cordones, surcos o relieves producidos en el laminado"
 },
 {
  "CNKEY": "721710390080",
  "CN": "7217 10 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721710500080",
  "CN": "7217 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % pero inferior al 0,6 % en peso"
 },
 {
  "CNKEY": "721710900080",
  "CN": "7217 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,6 % en peso"
 },
 {
  "CNKEY": "721720000080",
  "CN": "7217 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cincado"
 },
 {
  "CNKEY": "721720100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721720100080",
  "CN": "7217 20 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión del corte transversal inferior a 0,8 mm"
 },
 {
  "CNKEY": "721720300080",
  "CN": "7217 20 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión del corte transversal superior o igual a 0,8 mm"
 },
 {
  "CNKEY": "721720500080",
  "CN": "7217 20 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % pero inferior al 0,6 % en peso"
 },
 {
  "CNKEY": "721720900080",
  "CN": "7217 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,6 % en peso"
 },
 {
  "CNKEY": "721730000080",
  "CN": "7217 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Revestido de otro metal común"
 },
 {
  "CNKEY": "721730410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721730410080",
  "CN": "7217 30 41",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cobreados"
 },
 {
  "CNKEY": "721730490080",
  "CN": "7217 30 49",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "721730500080",
  "CN": "7217 30 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % pero inferior al 0,6 % en peso"
 },
 {
  "CNKEY": "721730900080",
  "CN": "7217 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,6 % en peso"
 },
 {
  "CNKEY": "721790000080",
  "CN": "7217 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721790200080",
  "CN": "7217 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono inferior al 0,25 % en peso"
 },
 {
  "CNKEY": "721790500080",
  "CN": "7217 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,25 % pero inferior al 0,6 % en peso"
 },
 {
  "CNKEY": "721790900080",
  "CN": "7217 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de carbono superior o igual al 0,6 % en peso"
 },
 {
  "CNKEY": "721800000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "III. ACERO INOXIDABLE"
 },
 {
  "CNKEY": "721800000080",
  "CN": "7218",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Acero inoxidable en lingotes o demás formas primarias; productos intermedios de acero inoxidable"
 },
 {
  "CNKEY": "721810000080",
  "CN": "7218 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lingotes o demás formas primarias"
 },
 {
  "CNKEY": "721891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721891000080",
  "CN": "7218 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección transversal rectangular"
 },
 {
  "CNKEY": "721891100080",
  "CN": "7218 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721891800080",
  "CN": "7218 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721899000080",
  "CN": "7218 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721899110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De sección transversal cuadrada"
 },
 {
  "CNKEY": "721899110080",
  "CN": "7218 99 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "721899190080",
  "CN": "7218 99 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "721899200010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721899200080",
  "CN": "7218 99 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Laminados u obtenidos por colada continua"
 },
 {
  "CNKEY": "721899800080",
  "CN": "7218 99 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "721900000080",
  "CN": "7219",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de acero inoxidable, de anchura superior o igual a 600 mm"
 },
 {
  "CNKEY": "721911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en caliente, enrollados"
 },
 {
  "CNKEY": "721911000080",
  "CN": "7219 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior a 10 mm"
 },
 {
  "CNKEY": "721912000080",
  "CN": "7219 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 4,75 mm pero inferior o igual a 10 mm"
 },
 {
  "CNKEY": "721912100080",
  "CN": "7219 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721912900080",
  "CN": "7219 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721913000080",
  "CN": "7219 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "721913100080",
  "CN": "7219 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721913900080",
  "CN": "7219 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721914000080",
  "CN": "7219 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 3 mm"
 },
 {
  "CNKEY": "721914100080",
  "CN": "7219 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721914900080",
  "CN": "7219 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en caliente, sin enrollar"
 },
 {
  "CNKEY": "721921000080",
  "CN": "7219 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 10 mm"
 },
 {
  "CNKEY": "721921100080",
  "CN": "7219 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721921900080",
  "CN": "7219 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721922000080",
  "CN": "7219 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 4,75 mm pero inferior o igual a 10 mm"
 },
 {
  "CNKEY": "721922100080",
  "CN": "7219 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721922900080",
  "CN": "7219 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721923000080",
  "CN": "7219 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "721924000080",
  "CN": "7219 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 3 mm"
 },
 {
  "CNKEY": "721931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en frío"
 },
 {
  "CNKEY": "721931000080",
  "CN": "7219 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm"
 },
 {
  "CNKEY": "721932000080",
  "CN": "7219 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 3 mm pero inferior a 4,75 mm"
 },
 {
  "CNKEY": "721932100080",
  "CN": "7219 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721932900080",
  "CN": "7219 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721933000080",
  "CN": "7219 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 1 mm pero inferior a 3 mm"
 },
 {
  "CNKEY": "721933100080",
  "CN": "7219 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721933900080",
  "CN": "7219 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721934000080",
  "CN": "7219 34",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 0,5 mm pero inferior o igual a 1 mm"
 },
 {
  "CNKEY": "721934100080",
  "CN": "7219 34 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721934900080",
  "CN": "7219 34 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721935000080",
  "CN": "7219 35",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 0,5 mm"
 },
 {
  "CNKEY": "721935100080",
  "CN": "7219 35 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "721935900080",
  "CN": "7219 35 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "721990000080",
  "CN": "7219 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "721990200080",
  "CN": "7219 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perforados"
 },
 {
  "CNKEY": "721990800080",
  "CN": "7219 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722000000080",
  "CN": "7220",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de acero inoxidable, de anchura inferior a 600 mm"
 },
 {
  "CNKEY": "722011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en caliente"
 },
 {
  "CNKEY": "722011000080",
  "CN": "7220 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm"
 },
 {
  "CNKEY": "722012000080",
  "CN": "7220 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 4,75 mm"
 },
 {
  "CNKEY": "722020000080",
  "CN": "7220 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en frío"
 },
 {
  "CNKEY": "722020210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior o igual a 3 mm, con un contenido"
 },
 {
  "CNKEY": "722020210080",
  "CN": "7220 20 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722020290080",
  "CN": "7220 20 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722020410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor superior a 0,35 mm pero inferior a 3 mm, con un contenido"
 },
 {
  "CNKEY": "722020410080",
  "CN": "7220 20 41",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722020490080",
  "CN": "7220 20 49",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722020810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor inferior o igual a 0,35 mm, con un contenido"
 },
 {
  "CNKEY": "722020810080",
  "CN": "7220 20 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722020890080",
  "CN": "7220 20 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722090000080",
  "CN": "7220 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722090200080",
  "CN": "7220 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perforados"
 },
 {
  "CNKEY": "722090800080",
  "CN": "7220 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722100000080",
  "CN": "7221 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambrón de acero inoxidable"
 },
 {
  "CNKEY": "722100100080",
  "CN": "7221 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722100900080",
  "CN": "7221 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722200000080",
  "CN": "7222",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras y perfiles, de acero inoxidable"
 },
 {
  "CNKEY": "722211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras simplemente laminadas o extrudidas en caliente"
 },
 {
  "CNKEY": "722211000080",
  "CN": "7222 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección circular"
 },
 {
  "CNKEY": "722211110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con diámetro superior o igual a 80 mm, con un contenido"
 },
 {
  "CNKEY": "722211110080",
  "CN": "7222 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722211190080",
  "CN": "7222 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722211810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con diámetro inferior a 80 mm, con un contenido"
 },
 {
  "CNKEY": "722211810080",
  "CN": "7222 11 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722211890080",
  "CN": "7222 11 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722219000080",
  "CN": "7222 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "722219100080",
  "CN": "7222 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722219900080",
  "CN": "7222 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722220000080",
  "CN": "7222 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras simplemente obtenidas o acabadas en frío"
 },
 {
  "CNKEY": "722220110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección circular"
 },
 {
  "CNKEY": "722220110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con diámetro superior o igual a 80 mm, con un contenido"
 },
 {
  "CNKEY": "722220110080",
  "CN": "7222 20 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722220190080",
  "CN": "7222 20 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722220210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con diámetro superior o igual a 25 mm pero inferior a 80 mm, con un contenido"
 },
 {
  "CNKEY": "722220210080",
  "CN": "7222 20 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722220290080",
  "CN": "7222 20 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722220310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con diámetro inferior a 25 mm, con un contenido"
 },
 {
  "CNKEY": "722220310080",
  "CN": "7222 20 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722220390080",
  "CN": "7222 20 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722220810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, con un contenido"
 },
 {
  "CNKEY": "722220810080",
  "CN": "7222 20 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722220890080",
  "CN": "7222 20 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722230000080",
  "CN": "7222 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás barras"
 },
 {
  "CNKEY": "722230510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Forjadas, con un contenido"
 },
 {
  "CNKEY": "722230510080",
  "CN": "7222 30 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722230910080",
  "CN": "7222 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722230970080",
  "CN": "7222 30 97",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722240000080",
  "CN": "7222 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles"
 },
 {
  "CNKEY": "722240100080",
  "CN": "7222 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente laminados en caliente"
 },
 {
  "CNKEY": "722240500080",
  "CN": "7222 40 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente obtenidos o acabados en frío"
 },
 {
  "CNKEY": "722240900080",
  "CN": "7222 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722300000080",
  "CN": "7223 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambre de acero inoxidable"
 },
 {
  "CNKEY": "722300110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de níquel superior o igual al 2,5 % en peso"
 },
 {
  "CNKEY": "722300110080",
  "CN": "7223 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de níquel superior o igual al 28 % pero inferior o igual al 31 % en peso y de cromo superior o igual al 20 % pero inferior o igual al 22 % en peso"
 },
 {
  "CNKEY": "722300190080",
  "CN": "7223 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722300910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de níquel inferior al 2,5 % en peso"
 },
 {
  "CNKEY": "722300910080",
  "CN": "7223 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de cromo superior o igual al 13 % pero inferior o igual al 25 % en peso y de aluminio superior o igual al 3,5 % pero inferior o igual al 6 % en peso"
 },
 {
  "CNKEY": "722300990080",
  "CN": "7223 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722400000010",
  "CN": null,
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "IV. LOS DEMÁS ACEROS ALEADOS; BARRAS HUECAS PARA PERFORACIÓN, DE ACERO ALEADO O SIN ALEAR"
 },
 {
  "CNKEY": "722400000080",
  "CN": "7224",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás aceros aleados en lingotes o demás formas primarias; productos intermedios de los demás aceros aleados"
 },
 {
  "CNKEY": "722410000080",
  "CN": "7224 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lingotes o demás formas primarias"
 },
 {
  "CNKEY": "722410100080",
  "CN": "7224 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722410900080",
  "CN": "7224 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722490000080",
  "CN": "7224 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490020080",
  "CN": "7224 90 02",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722490030010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490030020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De sección transversal cuadrada o rectangular"
 },
 {
  "CNKEY": "722490030030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Laminados en caliente u obtenidos por colada continua"
 },
 {
  "CNKEY": "722490030040",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De anchura inferior al doble del espesor"
 },
 {
  "CNKEY": "722490030080",
  "CN": "7224 90 03",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722490050080",
  "CN": "7224 90 05",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de carbono inferior o igual al 0,7 % en peso, de manganeso superior o igual al 0,5 % pero inferior o igual al 1,2 % en peso y de silicio superior o igual al 0,6 % pero inferior o igual al 2,3 % en peso; con un contenido de boro superior o igual al 0,0008 % en peso, sin que ningún otro elemento alcance el contenido mínimo de la Nota 1 f) de este Capítulo"
 },
 {
  "CNKEY": "722490070080",
  "CN": "7224 90 07",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490140080",
  "CN": "7224 90 14",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490180080",
  "CN": "7224 90 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "722490310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Laminados en caliente u obtenidos por colada continua"
 },
 {
  "CNKEY": "722490310080",
  "CN": "7224 90 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "722490380080",
  "CN": "7224 90 38",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722490900080",
  "CN": "7224 90 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Forjados"
 },
 {
  "CNKEY": "722500000080",
  "CN": "7225",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de los demás aceros aleados, de anchura superior o igual a 600 mm"
 },
 {
  "CNKEY": "722511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De acero al silicio llamado «magnético» (acero magnético al silicio)"
 },
 {
  "CNKEY": "722511000080",
  "CN": "7225 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De grano orientado"
 },
 {
  "CNKEY": "722519000080",
  "CN": "7225 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722519100080",
  "CN": "7225 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados en caliente"
 },
 {
  "CNKEY": "722519900080",
  "CN": "7225 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminados en frío"
 },
 {
  "CNKEY": "722530000080",
  "CN": "7225 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, simplemente laminados en caliente, enrollados"
 },
 {
  "CNKEY": "722530100080",
  "CN": "7225 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722530300080",
  "CN": "7225 30 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722530900080",
  "CN": "7225 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722540000080",
  "CN": "7225 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, simplemente laminados en caliente, sin enrollar"
 },
 {
  "CNKEY": "722540120080",
  "CN": "7225 40 12",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722540150080",
  "CN": "7225 40 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722540400010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722540400080",
  "CN": "7225 40 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior a 10 mm"
 },
 {
  "CNKEY": "722540600080",
  "CN": "7225 40 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm pero inferior o igual a 10 mm"
 },
 {
  "CNKEY": "722540900080",
  "CN": "7225 40 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 4,75 mm"
 },
 {
  "CNKEY": "722550000080",
  "CN": "7225 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, simplemente laminados en frío"
 },
 {
  "CNKEY": "722550200080",
  "CN": "7225 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722550800080",
  "CN": "7225 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722591000080",
  "CN": "7225 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cincados electrolíticamente"
 },
 {
  "CNKEY": "722592000080",
  "CN": "7225 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cincados de otro modo"
 },
 {
  "CNKEY": "722599000080",
  "CN": "7225 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722600000080",
  "CN": "7226",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Productos laminados planos de los demás aceros aleados, de anchura inferior a 600 mm"
 },
 {
  "CNKEY": "722611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De acero al silicio llamado «magnético» (acero magnético al silicio)"
 },
 {
  "CNKEY": "722611000080",
  "CN": "7226 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De grano orientado"
 },
 {
  "CNKEY": "722619000080",
  "CN": "7226 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722619100080",
  "CN": "7226 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Simplemente laminados en caliente"
 },
 {
  "CNKEY": "722619800080",
  "CN": "7226 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722620000080",
  "CN": "7226 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722691000080",
  "CN": "7226 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Simplemente laminados en caliente"
 },
 {
  "CNKEY": "722691200080",
  "CN": "7226 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722691910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722691910080",
  "CN": "7226 91 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 4,75 mm"
 },
 {
  "CNKEY": "722691990080",
  "CN": "7226 91 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 4,75 mm"
 },
 {
  "CNKEY": "722692000080",
  "CN": "7226 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente laminados en frío"
 },
 {
  "CNKEY": "722699000080",
  "CN": "7226 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722699100080",
  "CN": "7226 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cincados electrolíticamente"
 },
 {
  "CNKEY": "722699300080",
  "CN": "7226 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cincados de otro modo"
 },
 {
  "CNKEY": "722699700080",
  "CN": "7226 99 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722700000080",
  "CN": "7227",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambrón de los demás aceros aleados"
 },
 {
  "CNKEY": "722710000080",
  "CN": "7227 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722720000080",
  "CN": "7227 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acero silicomanganoso"
 },
 {
  "CNKEY": "722790000080",
  "CN": "7227 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722790100080",
  "CN": "7227 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de boro superior o igual al 0,0008 % en peso, sin que ningún otro elemento alcance el contenido mínimo de la Nota 1 f) de este Capítulo"
 },
 {
  "CNKEY": "722790500080",
  "CN": "7227 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso, y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "722790950080",
  "CN": "7227 90 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722800000080",
  "CN": "7228",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras y perfiles, de los demás aceros aleados; barras huecas para perforación, de aceros aleados o sin alear"
 },
 {
  "CNKEY": "722810000080",
  "CN": "7228 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras de acero rápido"
 },
 {
  "CNKEY": "722810200080",
  "CN": "7228 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente laminadas o extrudidas en caliente; laminadas o extrudidas en caliente, simplemente chapadas"
 },
 {
  "CNKEY": "722810500080",
  "CN": "7228 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Forjadas"
 },
 {
  "CNKEY": "722810900080",
  "CN": "7228 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722820000080",
  "CN": "7228 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras de acero silicomanganoso"
 },
 {
  "CNKEY": "722820100080",
  "CN": "7228 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De sección rectangular, laminadas en caliente en las cuatro caras"
 },
 {
  "CNKEY": "722820910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "722820910080",
  "CN": "7228 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Simplemente laminadas o extrudidas en caliente; laminadas o extrudidas en caliente, simplemente chapadas"
 },
 {
  "CNKEY": "722820990080",
  "CN": "7228 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722830000080",
  "CN": "7228 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás barras, simplemente laminadas o extrudidas en caliente"
 },
 {
  "CNKEY": "722830200080",
  "CN": "7228 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722830410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "722830410080",
  "CN": "7228 30 41",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De sección circular, con diámetro superior o igual a 80 mm"
 },
 {
  "CNKEY": "722830490080",
  "CN": "7228 30 49",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722830610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "722830610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De sección circular, con diámetro"
 },
 {
  "CNKEY": "722830610080",
  "CN": "7228 30 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 80 mm"
 },
 {
  "CNKEY": "722830690080",
  "CN": "7228 30 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 80 mm"
 },
 {
  "CNKEY": "722830700080",
  "CN": "7228 30 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De sección rectangular, laminadas en caliente en las cuatro caras"
 },
 {
  "CNKEY": "722830890080",
  "CN": "7228 30 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722840000080",
  "CN": "7228 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás barras, simplemente forjadas"
 },
 {
  "CNKEY": "722840100080",
  "CN": "7228 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722840900080",
  "CN": "7228 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722850000080",
  "CN": "7228 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás barras, simplemente obtenidas o acabadas en frío"
 },
 {
  "CNKEY": "722850200080",
  "CN": "7228 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722850400080",
  "CN": "7228 50 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "722850610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "722850610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De sección circular, con diámetro"
 },
 {
  "CNKEY": "722850610080",
  "CN": "7228 50 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 80 mm"
 },
 {
  "CNKEY": "722850690080",
  "CN": "7228 50 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 80 mm"
 },
 {
  "CNKEY": "722850800080",
  "CN": "7228 50 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722860000080",
  "CN": "7228 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás barras"
 },
 {
  "CNKEY": "722860200080",
  "CN": "7228 60 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero para herramientas"
 },
 {
  "CNKEY": "722860800080",
  "CN": "7228 60 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "722870000080",
  "CN": "7228 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Perfiles"
 },
 {
  "CNKEY": "722870100080",
  "CN": "7228 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simplemente laminados o extrudidos en caliente"
 },
 {
  "CNKEY": "722870900080",
  "CN": "7228 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "722880000080",
  "CN": "7228 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Barras huecas para perforación"
 },
 {
  "CNKEY": "722900000080",
  "CN": "7229",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambre de los demás aceros aleados"
 },
 {
  "CNKEY": "722920000080",
  "CN": "7229 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De acero silicomanganoso"
 },
 {
  "CNKEY": "722990000080",
  "CN": "7229 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "722990200080",
  "CN": "7229 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "722990500080",
  "CN": "7229 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "722990900080",
  "CN": "7229 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730021000090",
  "CN": "73",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 73 - MANUFACTURAS DE FUNDICIÓN, DE HIERRO O ACERO"
 },
 {
  "CNKEY": "730100000080",
  "CN": "7301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tablestacas de hierro o acero, incluso perforadas o hechas con elementos ensamblados; perfiles de hierro o acero obtenidos por soldadura"
 },
 {
  "CNKEY": "730110000080",
  "CN": "7301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tablestacas"
 },
 {
  "CNKEY": "730120000080",
  "CN": "7301 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Perfiles"
 },
 {
  "CNKEY": "730200000080",
  "CN": "7302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Elementos para vías férreas, de fundición, hierro o acero: carriles (rieles), contracarriles (contrarrieles) y cremalleras, agujas, puntas de corazón, varillas para mando de agujas y otros elementos para cruce o cambio de vías, traviesas (durmientes), bridas, cojinetes, cuñas, placas de asiento, placas de unión, placas y tirantes de separación y demás piezas diseñadas especialmente para la colocación, unión o fijación de carriles (rieles)"
 },
 {
  "CNKEY": "730210000080",
  "CN": "7302 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carriles (rieles)"
 },
 {
  "CNKEY": "730210100080",
  "CN": "7302 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conductores de corriente, con parte de metal no férreo"
 },
 {
  "CNKEY": "730210220010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730210220020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Nuevos"
 },
 {
  "CNKEY": "730210220030",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Carriles (rieles) del tipo «vignole»"
 },
 {
  "CNKEY": "730210220080",
  "CN": "7302 10 22",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De peso superior o igual a 36 kg por metro lineal"
 },
 {
  "CNKEY": "730210280080",
  "CN": "7302 10 28",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De peso inferior a 36 kg por metro lineal"
 },
 {
  "CNKEY": "730210400080",
  "CN": "7302 10 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Carriles (rieles) de garganta"
 },
 {
  "CNKEY": "730210500080",
  "CN": "7302 10 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730210900080",
  "CN": "7302 10 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Usados"
 },
 {
  "CNKEY": "730230000080",
  "CN": "7302 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Agujas, puntas de corazón, varillas para mando de agujas y otros elementos para cruce o cambio de vías"
 },
 {
  "CNKEY": "730240000080",
  "CN": "7302 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bridas y placas de asiento"
 },
 {
  "CNKEY": "730290000080",
  "CN": "7302 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730300000080",
  "CN": "7303 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos y perfiles huecos, de fundición"
 },
 {
  "CNKEY": "730300100080",
  "CN": "7303 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tubos de los tipos utilizados para canalizaciones a presión"
 },
 {
  "CNKEY": "730300900080",
  "CN": "7303 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730400000080",
  "CN": "7304",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos y perfiles huecos, sin soldadura (sin costura), de hierro o acero"
 },
 {
  "CNKEY": "730411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de los tipos utilizados en oleoductos o gasoductos"
 },
 {
  "CNKEY": "730411000080",
  "CN": "7304 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "730419000080",
  "CN": "7304 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730419100080",
  "CN": "7304 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730419300080",
  "CN": "7304 19 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 168,3 mm pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730419900080",
  "CN": "7304 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 406,4 mm"
 },
 {
  "CNKEY": "730422000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de entubación («casing») o de producción («tubing») y tubos de perforación, de los tipos utilizados para la extracción de petróleo o gas"
 },
 {
  "CNKEY": "730422000080",
  "CN": "7304 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tubos de perforación de acero inoxidable"
 },
 {
  "CNKEY": "730423000080",
  "CN": "7304 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás tubos de perforación"
 },
 {
  "CNKEY": "730424000080",
  "CN": "7304 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de acero inoxidable"
 },
 {
  "CNKEY": "730429000080",
  "CN": "7304 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730429100080",
  "CN": "7304 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730429300080",
  "CN": "7304 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 168,3 mm pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730429900080",
  "CN": "7304 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 406,4 mm"
 },
 {
  "CNKEY": "730431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de sección circular, de hierro o acero sin alear"
 },
 {
  "CNKEY": "730431000080",
  "CN": "7304 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730431200080",
  "CN": "7304 31 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De precisión"
 },
 {
  "CNKEY": "730431800080",
  "CN": "7304 31 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730439000080",
  "CN": "7304 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730439500080",
  "CN": "7304 39 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tubos roscados o roscables llamados «gas»"
 },
 {
  "CNKEY": "730439820010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, de diámetro exterior"
 },
 {
  "CNKEY": "730439820080",
  "CN": "7304 39 82",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730439830080",
  "CN": "7304 39 83",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior a 168,3 mm pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730439880080",
  "CN": "7304 39 88",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior a 406,4 mm"
 },
 {
  "CNKEY": "730441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de sección circular, de acero inoxidable"
 },
 {
  "CNKEY": "730441000080",
  "CN": "7304 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730449000080",
  "CN": "7304 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730449830080",
  "CN": "7304 49 83",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730449850080",
  "CN": "7304 49 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 168,3 mm, pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730449890080",
  "CN": "7304 49 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 406,4 mm"
 },
 {
  "CNKEY": "730451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de sección circular, de los demás aceros aleados"
 },
 {
  "CNKEY": "730451000080",
  "CN": "7304 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730451100080",
  "CN": "7304 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rectos y con pared de espesor uniforme, de acero aleado, con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "730451810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730451810080",
  "CN": "7304 51 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De precisión"
 },
 {
  "CNKEY": "730451890080",
  "CN": "7304 51 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730459000080",
  "CN": "7304 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730459300080",
  "CN": "7304 59 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rectos y con pared de espesor uniforme, de acero aleado, con unos contenidos de carbono superior o igual al 0,9 % pero inferior o igual al 1,15 % en peso y de cromo superior o igual al 0,5 % pero inferior o igual al 2 % en peso y, eventualmente, de molibdeno inferior o igual al 0,5 % en peso"
 },
 {
  "CNKEY": "730459820010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730459820080",
  "CN": "7304 59 82",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De diámetro exterior inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730459830080",
  "CN": "7304 59 83",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 168,3 mm pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730459890080",
  "CN": "7304 59 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De diámetro exterior superior a 406,4 mm"
 },
 {
  "CNKEY": "730490000080",
  "CN": "7304 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730500000080",
  "CN": "7305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tubos (por ejemplo: soldados o remachados) de sección circular con diámetro exterior superior a 406,4 mm, de hierro o acero"
 },
 {
  "CNKEY": "730511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de los tipos utilizados en oleoductos o gasoductos"
 },
 {
  "CNKEY": "730511000080",
  "CN": "7305 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldados longitudinalmente con arco sumergido"
 },
 {
  "CNKEY": "730512000080",
  "CN": "7305 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, soldados longitudinalmente"
 },
 {
  "CNKEY": "730519000080",
  "CN": "7305 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730520000080",
  "CN": "7305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tubos de entubación (casing) de los tipos utilizados para la extracción de petróleo o gas"
 },
 {
  "CNKEY": "730531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, soldados"
 },
 {
  "CNKEY": "730531000080",
  "CN": "7305 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldados longitudinalmente"
 },
 {
  "CNKEY": "730539000080",
  "CN": "7305 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730590000080",
  "CN": "7305 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730600000080",
  "CN": "7306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás tubos y perfiles huecos (por ejemplo: soldados, remachados, grapados o con los bordes simplemente aproximados), de hierro o acero"
 },
 {
  "CNKEY": "730611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de los tipos utilizados en oleoductos y gasoductos"
 },
 {
  "CNKEY": "730611000080",
  "CN": "7306 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldados, de acero inoxidable"
 },
 {
  "CNKEY": "730619000080",
  "CN": "7306 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de entubación («casing») o de producción («tubing»), de los tipos utilizados para la extracción de petróleo o gas"
 },
 {
  "CNKEY": "730621000080",
  "CN": "7306 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldados, de acero inoxidable"
 },
 {
  "CNKEY": "730629000080",
  "CN": "7306 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730630000080",
  "CN": "7306 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, soldados, de sección circular, de hierro o acero sin alear"
 },
 {
  "CNKEY": "730630120010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De precisión"
 },
 {
  "CNKEY": "730630120080",
  "CN": "7306 30 12",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730630180080",
  "CN": "7306 30 18",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730630410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730630410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Tubos roscados o roscables llamados «gas»"
 },
 {
  "CNKEY": "730630410080",
  "CN": "7306 30 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Cincados"
 },
 {
  "CNKEY": "730630490080",
  "CN": "7306 30 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730630720010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, de diámetro exterior"
 },
 {
  "CNKEY": "730630720020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 168,3 mm"
 },
 {
  "CNKEY": "730630720080",
  "CN": "7306 30 72",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Cincados"
 },
 {
  "CNKEY": "730630770080",
  "CN": "7306 30 77",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730630800080",
  "CN": "7306 30 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior a 168,3 mm pero inferior o igual a 406,4 mm"
 },
 {
  "CNKEY": "730640000080",
  "CN": "7306 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, soldados, de sección circular, de acero inoxidable"
 },
 {
  "CNKEY": "730640200080",
  "CN": "7306 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730640800080",
  "CN": "7306 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730650000080",
  "CN": "7306 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, soldados, de sección circular, de los demás aceros aleados"
 },
 {
  "CNKEY": "730650210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De precisión"
 },
 {
  "CNKEY": "730650210080",
  "CN": "7306 50 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Estirados o laminados en frío"
 },
 {
  "CNKEY": "730650290080",
  "CN": "7306 50 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730650800080",
  "CN": "7306 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730661000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, soldados, excepto los de sección circular"
 },
 {
  "CNKEY": "730661000080",
  "CN": "7306 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sección cuadrada o rectangular"
 },
 {
  "CNKEY": "730661100080",
  "CN": "7306 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "730661920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730661920080",
  "CN": "7306 61 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con pared de espesor inferior o igual a 2 mm"
 },
 {
  "CNKEY": "730661990080",
  "CN": "7306 61 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Con pared de espesor superior a 2 mm"
 },
 {
  "CNKEY": "730669000080",
  "CN": "7306 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730669100080",
  "CN": "7306 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "730669900080",
  "CN": "7306 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730690000080",
  "CN": "7306 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730700000080",
  "CN": "7307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Accesorios de tubería [por ejemplo: empalmes (rácores), codos, manguitos], de fundición, de hierro o acero"
 },
 {
  "CNKEY": "730711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Moldeados"
 },
 {
  "CNKEY": "730711000080",
  "CN": "7307 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De fundición no maleable"
 },
 {
  "CNKEY": "730711100080",
  "CN": "7307 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para tubos de los tipos utilizados para canalizaciones a presión"
 },
 {
  "CNKEY": "730711900080",
  "CN": "7307 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730719000080",
  "CN": "7307 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730719100080",
  "CN": "7307 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "730719900080",
  "CN": "7307 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de acero inoxidable"
 },
 {
  "CNKEY": "730721000080",
  "CN": "7307 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bridas"
 },
 {
  "CNKEY": "730722000080",
  "CN": "7307 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Codos, curvas y manguitos, roscados"
 },
 {
  "CNKEY": "730722100080",
  "CN": "7307 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Manguitos"
 },
 {
  "CNKEY": "730722900080",
  "CN": "7307 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Codos y curvas"
 },
 {
  "CNKEY": "730723000080",
  "CN": "7307 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Accesorios para soldar a tope"
 },
 {
  "CNKEY": "730723100080",
  "CN": "7307 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Codos y curvas"
 },
 {
  "CNKEY": "730723900080",
  "CN": "7307 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730729000080",
  "CN": "7307 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730729100080",
  "CN": "7307 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Roscados"
 },
 {
  "CNKEY": "730729800080",
  "CN": "7307 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730791000080",
  "CN": "7307 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bridas"
 },
 {
  "CNKEY": "730792000080",
  "CN": "7307 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Codos, curvas y manguitos, roscados"
 },
 {
  "CNKEY": "730792100080",
  "CN": "7307 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Manguitos"
 },
 {
  "CNKEY": "730792900080",
  "CN": "7307 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Codos y curvas"
 },
 {
  "CNKEY": "730793000080",
  "CN": "7307 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Accesorios para soldar a tope"
 },
 {
  "CNKEY": "730793110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cuyo mayor diámetro exterior no sea superior a 609,6 mm"
 },
 {
  "CNKEY": "730793110080",
  "CN": "7307 93 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Codos y curvas"
 },
 {
  "CNKEY": "730793190080",
  "CN": "7307 93 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730793910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cuyo mayor diámetro exterior sea superior a 609,6 mm"
 },
 {
  "CNKEY": "730793910080",
  "CN": "7307 93 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Codos y curvas"
 },
 {
  "CNKEY": "730793990080",
  "CN": "7307 93 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730799000080",
  "CN": "7307 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730799100080",
  "CN": "7307 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Roscados"
 },
 {
  "CNKEY": "730799800080",
  "CN": "7307 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730800000080",
  "CN": "7308",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Construcciones y sus partes (por ejemplo: puentes y sus partes, compuertas de esclusas, torres, castilletes, pilares, columnas, armazones para techumbre, techados, puertas y ventanas y sus marcos, contramarcos y umbrales, cortinas de cierre, barandillas), de fundición, hierro o acero (excepto las construcciones prefabricadas de la partida 9406); chapas, barras, perfiles, tubos y similares, de fundición, hierro o acero, preparados para la construcción"
 },
 {
  "CNKEY": "730810000080",
  "CN": "7308 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Puentes y sus partes"
 },
 {
  "CNKEY": "730820000080",
  "CN": "7308 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Torres y castilletes"
 },
 {
  "CNKEY": "730830000080",
  "CN": "7308 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Puertas y ventanas y sus marcos, contramarcos y umbrales"
 },
 {
  "CNKEY": "730840000080",
  "CN": "7308 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Material de andamiaje, encofrado, apeo o apuntalamiento"
 },
 {
  "CNKEY": "730890000080",
  "CN": "7308 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "730890510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Única o principalmente de chapa"
 },
 {
  "CNKEY": "730890510080",
  "CN": "7308 90 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Paneles múltiples constituidos por dos paramentos de chapa con nervaduras y un alma aislante"
 },
 {
  "CNKEY": "730890590080",
  "CN": "7308 90 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730890980080",
  "CN": "7308 90 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "730900000080",
  "CN": "7309 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Depósitos, cisternas, cubas y recipientes similares para cualquier materia (excepto gas comprimido o licuado), de fundición, hierro o acero, de capacidad superior a 300 l, sin dispositivos mecánicos ni térmicos, incluso con revestimiento interior o calorífugo"
 },
 {
  "CNKEY": "730900100080",
  "CN": "7309 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para gas (excepto gas comprimido o licuado)"
 },
 {
  "CNKEY": "730900300010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para líquidos"
 },
 {
  "CNKEY": "730900300080",
  "CN": "7309 00 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con revestimiento interior o calorífugo"
 },
 {
  "CNKEY": "730900510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de capacidad"
 },
 {
  "CNKEY": "730900510080",
  "CN": "7309 00 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Superior a 100000 l"
 },
 {
  "CNKEY": "730900590080",
  "CN": "7309 00 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Inferior o igual a 100000 l"
 },
 {
  "CNKEY": "730900900080",
  "CN": "7309 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para sólidos"
 },
 {
  "CNKEY": "731000000080",
  "CN": "7310",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Depósitos, barriles, tambores, bidones, latas o botes, cajas y recipientes similares, para cualquier materia (excepto gas comprimido o licuado), de fundición, hierro o acero, de capacidad inferior o igual a 300 l, sin dispositivos mecánicos ni térmicos, incluso con revestimiento interior o calorífugo"
 },
 {
  "CNKEY": "731010000080",
  "CN": "7310 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De capacidad superior o igual a 50 l"
 },
 {
  "CNKEY": "731021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De capacidad inferior a 50 l"
 },
 {
  "CNKEY": "731021000080",
  "CN": "7310 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Latas o botes para ser cerrados por soldadura o rebordeado"
 },
 {
  "CNKEY": "731021110080",
  "CN": "7310 21 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Latas de conserva de los tipos utilizados para artículos alimenticios"
 },
 {
  "CNKEY": "731021190080",
  "CN": "7310 21 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Latas de conserva de los tipos utilizados para bebidas"
 },
 {
  "CNKEY": "731021910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con pared de espesor"
 },
 {
  "CNKEY": "731021910080",
  "CN": "7310 21 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Inferior a 0,5 mm"
 },
 {
  "CNKEY": "731021990080",
  "CN": "7310 21 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Superior o igual a 0,5 mm"
 },
 {
  "CNKEY": "731029000080",
  "CN": "7310 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "731029100080",
  "CN": "7310 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con pared de espesor inferior a 0,5 mm"
 },
 {
  "CNKEY": "731029900080",
  "CN": "7310 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con pared de espesor superior o igual a 0,5 mm"
 },
 {
  "CNKEY": "731100000080",
  "CN": "7311 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Recipientes para gas comprimido o licuado, de fundición, hierro o acero"
 },
 {
  "CNKEY": "731100110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin soldadura"
 },
 {
  "CNKEY": "731100110020",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para una presión superior o igual a 165 bares, de capacidad"
 },
 {
  "CNKEY": "731100110080",
  "CN": "7311 00 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Inferior a 20 l"
 },
 {
  "CNKEY": "731100130080",
  "CN": "7311 00 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Superior o igual a 20 l pero inferior o igual a 50 l"
 },
 {
  "CNKEY": "731100190080",
  "CN": "7311 00 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 50 l"
 },
 {
  "CNKEY": "731100300080",
  "CN": "7311 00 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731100910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, de capacidad"
 },
 {
  "CNKEY": "731100910080",
  "CN": "7311 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Inferior a 1000 l"
 },
 {
  "CNKEY": "731100990080",
  "CN": "7311 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Superior o igual a 1000 l"
 },
 {
  "CNKEY": "731200000080",
  "CN": "7312",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cables, trenzas, eslingas y artículos similares, de hierro o acero, sin aislar para electricidad"
 },
 {
  "CNKEY": "731210000080",
  "CN": "7312 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cables"
 },
 {
  "CNKEY": "731210200080",
  "CN": "7312 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731210410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás cuyo corte transversal en su mayor dimensión sea"
 },
 {
  "CNKEY": "731210410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 3 mm"
 },
 {
  "CNKEY": "731210410080",
  "CN": "7312 10 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Revestidos de aleaciones a base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "731210490080",
  "CN": "7312 10 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731210610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 3 mm"
 },
 {
  "CNKEY": "731210610020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Cables obtenidos solo por torsión («torones»)"
 },
 {
  "CNKEY": "731210610080",
  "CN": "7312 10 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Sin revestimiento"
 },
 {
  "CNKEY": "731210650010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Revestidos"
 },
 {
  "CNKEY": "731210650080",
  "CN": "7312 10 65",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Cincados"
 },
 {
  "CNKEY": "731210690080",
  "CN": "7312 10 69",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731210810010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Cables, incluidos los cables cerrados"
 },
 {
  "CNKEY": "731210810020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Sin revestimiento o simplemente cincados cuyo corte transversal en su mayor dimensión sea"
 },
 {
  "CNKEY": "731210810080",
  "CN": "7312 10 81",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior a 3 mm pero inferior o igual a 12 mm"
 },
 {
  "CNKEY": "731210830080",
  "CN": "7312 10 83",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior a 12 mm pero inferior o igual a 24 mm"
 },
 {
  "CNKEY": "731210850080",
  "CN": "7312 10 85",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior a 24 mm pero inferior o igual a 48 mm"
 },
 {
  "CNKEY": "731210890080",
  "CN": "7312 10 89",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior a 48 mm"
 },
 {
  "CNKEY": "731210980080",
  "CN": "7312 10 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731290000080",
  "CN": "7312 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731300000080",
  "CN": "7313 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Alambre de púas, de hierro o acero; alambre (simple o doble) y fleje, torcidos, incluso con púas, de hierro o acero, de los tipos utilizados para cercar"
 },
 {
  "CNKEY": "731400000080",
  "CN": "7314",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Telas metálicas (incluidas las continuas o sin fin), redes y rejas, de alambre de hierro o acero; chapas y tiras, extendidas (desplegadas), de hierro o acero"
 },
 {
  "CNKEY": "731412000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Telas metálicas tejidas"
 },
 {
  "CNKEY": "731412000080",
  "CN": "7314 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Telas metálicas continuas o sin fin, de acero inoxidable, para máquinas"
 },
 {
  "CNKEY": "731414000080",
  "CN": "7314 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás telas metálicas tejidas, de acero inoxidable"
 },
 {
  "CNKEY": "731419000080",
  "CN": "7314 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731420000080",
  "CN": "7314 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Redes y rejas, soldadas en los puntos de cruce, de alambre cuya mayor dimensión de la sección transversal sea superior o igual a 3 mm y con malla de superficie superior o igual a 100 cm²"
 },
 {
  "CNKEY": "731420100080",
  "CN": "7314 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De alambre corrugado"
 },
 {
  "CNKEY": "731420900080",
  "CN": "7314 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás redes y rejas, soldadas en los puntos de cruce"
 },
 {
  "CNKEY": "731431000080",
  "CN": "7314 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cincadas"
 },
 {
  "CNKEY": "731439000080",
  "CN": "7314 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás telas metálicas, redes y rejas"
 },
 {
  "CNKEY": "731441000080",
  "CN": "7314 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cincadas"
 },
 {
  "CNKEY": "731442000080",
  "CN": "7314 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Revestidas de plástico"
 },
 {
  "CNKEY": "731449000080",
  "CN": "7314 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731450000080",
  "CN": "7314 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Chapas y tiras, extendidas (desplegadas)"
 },
 {
  "CNKEY": "731500000080",
  "CN": "7315",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cadenas y sus partes, de fundición, hierro o acero"
 },
 {
  "CNKEY": "731511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cadenas de eslabones articulados y sus partes"
 },
 {
  "CNKEY": "731511000080",
  "CN": "7315 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cadenas de rodillos"
 },
 {
  "CNKEY": "731511100080",
  "CN": "7315 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados para bicicletas y motocicletas"
 },
 {
  "CNKEY": "731511900080",
  "CN": "7315 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731512000080",
  "CN": "7315 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás cadenas"
 },
 {
  "CNKEY": "731519000080",
  "CN": "7315 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "731520000080",
  "CN": "7315 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cadenas antideslizantes"
 },
 {
  "CNKEY": "731581000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás cadenas"
 },
 {
  "CNKEY": "731581000080",
  "CN": "7315 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cadenas de eslabones con contrete (travesaño)"
 },
 {
  "CNKEY": "731582000080",
  "CN": "7315 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás cadenas, de eslabones soldados"
 },
 {
  "CNKEY": "731589000080",
  "CN": "7315 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731590000080",
  "CN": "7315 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás partes"
 },
 {
  "CNKEY": "731600000080",
  "CN": "7316 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Anclas, rezones y sus partes, de fundición, hierro o acero"
 },
 {
  "CNKEY": "731700000080",
  "CN": "7317 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Puntas, clavos, chinchetas (chinches), grapas apuntadas, onduladas o biseladas, y artículos similares, de fundición, hierro o acero, incluso con cabeza de otras materias (excepto de cabeza de cobre)"
 },
 {
  "CNKEY": "731700200010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De trefilería"
 },
 {
  "CNKEY": "731700200080",
  "CN": "7317 00 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Puntas en batería, en bandas o en rollos"
 },
 {
  "CNKEY": "731700600080",
  "CN": "7317 00 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731700800080",
  "CN": "7317 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731800000080",
  "CN": "7318",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tornillos, pernos, tuercas, tirafondos, escarpias roscadas, remaches, pasadores, chavetas, arandelas [incluidas las arandelas de muelle (resorte)] y artículos similares, de fundición, hierro o acero"
 },
 {
  "CNKEY": "731811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos roscados"
 },
 {
  "CNKEY": "731811000080",
  "CN": "7318 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tirafondos"
 },
 {
  "CNKEY": "731812000080",
  "CN": "7318 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tornillos para madera"
 },
 {
  "CNKEY": "731812100080",
  "CN": "7318 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731812900080",
  "CN": "7318 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731813000080",
  "CN": "7318 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Escarpias y armellas, roscadas"
 },
 {
  "CNKEY": "731814000080",
  "CN": "7318 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tornillos taladradores"
 },
 {
  "CNKEY": "731814100080",
  "CN": "7318 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731814910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "731814910080",
  "CN": "7318 14 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Tornillos para chapa"
 },
 {
  "CNKEY": "731814990080",
  "CN": "7318 14 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731815000080",
  "CN": "7318 15",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás tornillos y pernos, incluso con sus tuercas y arandelas"
 },
 {
  "CNKEY": "731815200080",
  "CN": "7318 15 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para fijación de elementos de vías férreas"
 },
 {
  "CNKEY": "731815350010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "731815350020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Sin cabeza"
 },
 {
  "CNKEY": "731815350080",
  "CN": "7318 15 35",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731815420010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Los demás, con una resistencia a la tracción"
 },
 {
  "CNKEY": "731815420080",
  "CN": "7318 15 42",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Inferior a 800 MPa"
 },
 {
  "CNKEY": "731815480080",
  "CN": "7318 15 48",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Superior o igual a 800 MPa"
 },
 {
  "CNKEY": "731815520010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con cabeza"
 },
 {
  "CNKEY": "731815520020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Con ranura recta o en cruz"
 },
 {
  "CNKEY": "731815520080",
  "CN": "7318 15 52",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731815580080",
  "CN": "7318 15 58",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731815620010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "De hueco de seis caras"
 },
 {
  "CNKEY": "731815620080",
  "CN": "7318 15 62",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731815680080",
  "CN": "7318 15 68",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731815750010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Hexagonal"
 },
 {
  "CNKEY": "731815750080",
  "CN": "7318 15 75",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731815820010",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "Los demás, con una resistencia a la tracción"
 },
 {
  "CNKEY": "731815820080",
  "CN": "7318 15 82",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Inferior a 800 MPa"
 },
 {
  "CNKEY": "731815880080",
  "CN": "7318 15 88",
  "CN_LEVEL": 10,
  "T_SU_SU": "",
  "ES": "Superior o igual a 800 MPa"
 },
 {
  "CNKEY": "731815950080",
  "CN": "7318 15 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731816000080",
  "CN": "7318 16",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Tuercas"
 },
 {
  "CNKEY": "731816310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "731816310080",
  "CN": "7318 16 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Tuercas de remache ciego"
 },
 {
  "CNKEY": "731816390080",
  "CN": "7318 16 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "731816400010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "731816400080",
  "CN": "7318 16 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Tuercas de remache ciego"
 },
 {
  "CNKEY": "731816600080",
  "CN": "7318 16 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De seguridad"
 },
 {
  "CNKEY": "731816920010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás, de diámetro interior"
 },
 {
  "CNKEY": "731816920080",
  "CN": "7318 16 92",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior o igual a 12 mm"
 },
 {
  "CNKEY": "731816990080",
  "CN": "7318 16 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior a 12 mm"
 },
 {
  "CNKEY": "731819000080",
  "CN": "7318 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos sin rosca"
 },
 {
  "CNKEY": "731821000080",
  "CN": "7318 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arandelas de muelle (resorte) y las demás de seguridad"
 },
 {
  "CNKEY": "731822000080",
  "CN": "7318 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás arandelas"
 },
 {
  "CNKEY": "731823000080",
  "CN": "7318 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Remaches"
 },
 {
  "CNKEY": "731824000080",
  "CN": "7318 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pasadores y chavetas"
 },
 {
  "CNKEY": "731829000080",
  "CN": "7318 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "731900000080",
  "CN": "7319",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Agujas de coser, de tejer, pasacintas, agujas de ganchillo (croché), punzones para bordar y artículos similares, de uso manual, de hierro o acero; alfileres de gancho (imperdibles) y demás alfileres de hierro o acero, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "731940000080",
  "CN": "7319 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alfileres de gancho (imperdibles) y demás alfileres"
 },
 {
  "CNKEY": "731990000080",
  "CN": "7319 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "731990100080",
  "CN": "7319 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Agujas de coser, zurcir o bordar"
 },
 {
  "CNKEY": "731990900080",
  "CN": "7319 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732000000080",
  "CN": "7320",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Muelles (resortes), ballestas y sus hojas, de hierro o acero"
 },
 {
  "CNKEY": "732010000080",
  "CN": "7320 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ballestas y sus hojas"
 },
 {
  "CNKEY": "732010110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Conformadas en caliente"
 },
 {
  "CNKEY": "732010110080",
  "CN": "7320 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Muelles parabólicos y sus hojas"
 },
 {
  "CNKEY": "732010190080",
  "CN": "7320 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "732010900080",
  "CN": "7320 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "732020000080",
  "CN": "7320 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muelles (resortes) helicoidales"
 },
 {
  "CNKEY": "732020200080",
  "CN": "7320 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conformados en caliente"
 },
 {
  "CNKEY": "732020810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "732020810080",
  "CN": "7320 20 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Resortes de compresión"
 },
 {
  "CNKEY": "732020850080",
  "CN": "7320 20 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Resortes de tracción"
 },
 {
  "CNKEY": "732020890080",
  "CN": "7320 20 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732090000080",
  "CN": "7320 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "732090100080",
  "CN": "7320 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Resortes espirales planos"
 },
 {
  "CNKEY": "732090300080",
  "CN": "7320 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muelles con forma de disco"
 },
 {
  "CNKEY": "732090900080",
  "CN": "7320 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732100000080",
  "CN": "7321",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Estufas, calderas con hogar, cocinas (incluidas las que puedan utilizarse accesoriamente para calefacción central), barbacoas (parrillas), braseros, hornillos de gas, calientaplatos y aparatos no eléctricos similares, de uso doméstico, y sus partes, de fundición, hierro o acero"
 },
 {
  "CNKEY": "732111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de cocción y calientaplatos"
 },
 {
  "CNKEY": "732111000080",
  "CN": "7321 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De combustibles gaseosos, o de gas y otros combustibles"
 },
 {
  "CNKEY": "732111100080",
  "CN": "7321 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con horno, incluidos los hornos separados"
 },
 {
  "CNKEY": "732111900080",
  "CN": "7321 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732112000080",
  "CN": "7321 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De combustibles líquidos"
 },
 {
  "CNKEY": "732119000080",
  "CN": "7321 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás, incluidos los aparatos de combustibles sólidos"
 },
 {
  "CNKEY": "732181000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "732181000080",
  "CN": "7321 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De combustibles gaseosos, o de gas y otros combustibles"
 },
 {
  "CNKEY": "732182000080",
  "CN": "7321 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De combustibles líquidos"
 },
 {
  "CNKEY": "732189000080",
  "CN": "7321 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás, incluidos los aparatos de combustibles sólidos"
 },
 {
  "CNKEY": "732190000080",
  "CN": "7321 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "732200000080",
  "CN": "7322",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Radiadores para la calefacción central, de calentamiento no eléctrico, y sus partes, de fundición, hierro o acero; generadores y distribuidores de aire caliente (incluidos los distribuidores que puedan funcionar también como distribuidores de aire fresco o acondicionado), de calentamiento no eléctrico, que lleven un ventilador o un soplador con motor, y sus partes, de fundición, hierro o acero"
 },
 {
  "CNKEY": "732211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Radiadores y sus partes"
 },
 {
  "CNKEY": "732211000080",
  "CN": "7322 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "732219000080",
  "CN": "7322 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732290000080",
  "CN": "7322 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732300000080",
  "CN": "7323",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de uso doméstico y sus partes, de fundición, hierro o acero; lana de hierro o acero; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos, de hierro o acero"
 },
 {
  "CNKEY": "732310000080",
  "CN": "7323 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lana de hierro o acero; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos"
 },
 {
  "CNKEY": "732391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "732391000080",
  "CN": "7323 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición, sin esmaltar"
 },
 {
  "CNKEY": "732392000080",
  "CN": "7323 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición, esmaltados"
 },
 {
  "CNKEY": "732393000080",
  "CN": "7323 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "732394000080",
  "CN": "7323 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De hierro o acero, esmaltados"
 },
 {
  "CNKEY": "732399000080",
  "CN": "7323 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "732400000080",
  "CN": "7324",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de higiene o tocador, y sus partes, de fundición, hierro o acero"
 },
 {
  "CNKEY": "732410000080",
  "CN": "7324 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fregaderos (piletas de lavar) y lavabos, de acero inoxidable"
 },
 {
  "CNKEY": "732421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bañeras"
 },
 {
  "CNKEY": "732421000080",
  "CN": "7324 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De fundición, incluso esmaltadas"
 },
 {
  "CNKEY": "732429000080",
  "CN": "7324 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "732490000080",
  "CN": "7324 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "732500000080",
  "CN": "7325",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas moldeadas de fundición, hierro o acero"
 },
 {
  "CNKEY": "732510000080",
  "CN": "7325 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De fundición no maleable"
 },
 {
  "CNKEY": "732591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "732591000080",
  "CN": "7325 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bolas y artículos similares para molinos"
 },
 {
  "CNKEY": "732599000080",
  "CN": "7325 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "732599100080",
  "CN": "7325 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "732599900080",
  "CN": "7325 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "732600000080",
  "CN": "7326",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de hierro o acero"
 },
 {
  "CNKEY": "732611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Forjadas o estampadas pero sin trabajar de otro modo"
 },
 {
  "CNKEY": "732611000080",
  "CN": "7326 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bolas y artículos similares para molinos"
 },
 {
  "CNKEY": "732619000080",
  "CN": "7326 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "732619100080",
  "CN": "7326 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjadas"
 },
 {
  "CNKEY": "732619900080",
  "CN": "7326 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "732620000080",
  "CN": "7326 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Manufacturas de alambre de hierro o acero"
 },
 {
  "CNKEY": "732690000080",
  "CN": "7326 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "732690300080",
  "CN": "7326 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Escaleras y escabeles"
 },
 {
  "CNKEY": "732690400080",
  "CN": "7326 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Paletas y plataformas análogas, para la manipulación de mercancías"
 },
 {
  "CNKEY": "732690500080",
  "CN": "7326 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bobinas para cables, tuberías y artículos similares"
 },
 {
  "CNKEY": "732690600080",
  "CN": "7326 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ventiladores no mecánicos, canalones, ganchos y artículos similares usados en construcción"
 },
 {
  "CNKEY": "732690920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de hierro o acero"
 },
 {
  "CNKEY": "732690920080",
  "CN": "7326 90 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Forjadas"
 },
 {
  "CNKEY": "732690940080",
  "CN": "7326 90 94",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Estampadas"
 },
 {
  "CNKEY": "732690960080",
  "CN": "7326 90 96",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sinterizadas"
 },
 {
  "CNKEY": "732690980080",
  "CN": "7326 90 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "740021000090",
  "CN": "74",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 74 - COBRE Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "740100000080",
  "CN": "7401 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Matas de cobre; cobre de cementación (cobre precipitado)"
 },
 {
  "CNKEY": "740200000080",
  "CN": "7402 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cobre sin refinar; ánodos de cobre para refinado electrolítico"
 },
 {
  "CNKEY": "740300000080",
  "CN": "7403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cobre refinado y aleaciones de cobre, en bruto"
 },
 {
  "CNKEY": "740311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cobre refinado"
 },
 {
  "CNKEY": "740311000080",
  "CN": "7403 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cátodos y secciones de cátodos"
 },
 {
  "CNKEY": "740312000080",
  "CN": "7403 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Barras para alambrón (wire-bars)"
 },
 {
  "CNKEY": "740313000080",
  "CN": "7403 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tochos"
 },
 {
  "CNKEY": "740319000080",
  "CN": "7403 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "740321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aleaciones de cobre"
 },
 {
  "CNKEY": "740321000080",
  "CN": "7403 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "740322000080",
  "CN": "7403 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-estaño (bronce)"
 },
 {
  "CNKEY": "740329000080",
  "CN": "7403 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás aleaciones de cobre (excepto las aleaciones madre de la partida 7405)"
 },
 {
  "CNKEY": "740400000080",
  "CN": "7404 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de cobre"
 },
 {
  "CNKEY": "740400100080",
  "CN": "7404 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "740400910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "740400910080",
  "CN": "7404 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "740400990080",
  "CN": "7404 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "740500000080",
  "CN": "7405 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Aleaciones madre de cobre"
 },
 {
  "CNKEY": "740600000080",
  "CN": "7406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polvo y escamillas, de cobre"
 },
 {
  "CNKEY": "740610000080",
  "CN": "7406 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo de estructura no laminar"
 },
 {
  "CNKEY": "740620000080",
  "CN": "7406 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo de estructura laminar; escamillas"
 },
 {
  "CNKEY": "740700000080",
  "CN": "7407",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras y perfiles, de cobre"
 },
 {
  "CNKEY": "740710000080",
  "CN": "7407 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "740721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "740721000080",
  "CN": "7407 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "A base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "740721100080",
  "CN": "7407 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Barras"
 },
 {
  "CNKEY": "740721900080",
  "CN": "7407 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perfiles"
 },
 {
  "CNKEY": "740729000080",
  "CN": "7407 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "740800000080",
  "CN": "7408",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambre de cobre"
 },
 {
  "CNKEY": "740811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "740811000080",
  "CN": "7408 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión de la sección transversal superior a 6 mm"
 },
 {
  "CNKEY": "740819000080",
  "CN": "7408 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "740819100080",
  "CN": "7408 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión de la sección transversal superior a 0,5 mm"
 },
 {
  "CNKEY": "740819900080",
  "CN": "7408 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión de la sección transversal inferior o igual a 0,5 mm"
 },
 {
  "CNKEY": "740821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "740821000080",
  "CN": "7408 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "740822000080",
  "CN": "7408 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-níquel (cuproníquel) o de cobre-níquel-cinc (alpaca)"
 },
 {
  "CNKEY": "740829000080",
  "CN": "7408 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "740900000080",
  "CN": "7409",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chapas y tiras, de cobre, de espesor superior a 0,15 mm"
 },
 {
  "CNKEY": "740911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "740911000080",
  "CN": "7409 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Enrolladas"
 },
 {
  "CNKEY": "740919000080",
  "CN": "7409 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "740921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones a base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "740921000080",
  "CN": "7409 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Enrolladas"
 },
 {
  "CNKEY": "740929000080",
  "CN": "7409 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "740931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones a base de cobre-estaño (bronce)"
 },
 {
  "CNKEY": "740931000080",
  "CN": "7409 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Enrolladas"
 },
 {
  "CNKEY": "740939000080",
  "CN": "7409 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "740940000080",
  "CN": "7409 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De aleaciones a base de cobre-níquel (cuproníquel) o de cobre-níquel-cinc (alpaca)"
 },
 {
  "CNKEY": "740990000080",
  "CN": "7409 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De las demás aleaciones de cobre"
 },
 {
  "CNKEY": "741000000080",
  "CN": "7410",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hojas y tiras, delgadas, de cobre, incluso impresas o fijadas sobre papel, cartón, plástico o soportes similares, de espesor inferior o igual a 0,15 mm (sin incluir el soporte)"
 },
 {
  "CNKEY": "741011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin soporte"
 },
 {
  "CNKEY": "741011000080",
  "CN": "7410 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "741012000080",
  "CN": "7410 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "741021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con soporte"
 },
 {
  "CNKEY": "741021000080",
  "CN": "7410 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "741022000080",
  "CN": "7410 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "741100000080",
  "CN": "7411",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos de cobre"
 },
 {
  "CNKEY": "741110000080",
  "CN": "7411 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "741110100080",
  "CN": "7411 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rectos"
 },
 {
  "CNKEY": "741110900080",
  "CN": "7411 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "741121000080",
  "CN": "7411 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "A base de cobre-cinc (latón)"
 },
 {
  "CNKEY": "741121100080",
  "CN": "7411 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rectos"
 },
 {
  "CNKEY": "741121900080",
  "CN": "7411 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741122000080",
  "CN": "7411 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "A base de cobre-níquel (cuproníquel) o de cobre-níquel-cinc (alpaca)"
 },
 {
  "CNKEY": "741129000080",
  "CN": "7411 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741200000080",
  "CN": "7412",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Accesorios de tubería [por ejemplo: empalmes (rácores), codos o manguitos] de cobre"
 },
 {
  "CNKEY": "741210000080",
  "CN": "7412 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cobre refinado"
 },
 {
  "CNKEY": "741220000080",
  "CN": "7412 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De aleaciones de cobre"
 },
 {
  "CNKEY": "741300000080",
  "CN": "7413 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Cables, trenzas y artículos similares, de cobre, sin aislar para electricidad"
 },
 {
  "CNKEY": "741500000080",
  "CN": "7415",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Puntas, clavos, chinchetas (chinches), grapas apuntadas y artículos similares, de cobre, o con espiga de hierro o acero y cabeza de cobre; tornillos, pernos, tuercas, escarpias roscadas, remaches, pasadores,  chavetas y arandelas [incluidas las arandelas de muelle (resorte)], y artículos similares, de cobre"
 },
 {
  "CNKEY": "741510000080",
  "CN": "7415 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Puntas, clavos, chinchetas (chinches), grapas apuntadas y artículos similares"
 },
 {
  "CNKEY": "741521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás artículos sin rosca"
 },
 {
  "CNKEY": "741521000080",
  "CN": "7415 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Arandelas (incluidas las arandelas de muelle (resorte))"
 },
 {
  "CNKEY": "741529000080",
  "CN": "7415 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741533000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás artículos roscados"
 },
 {
  "CNKEY": "741533000080",
  "CN": "7415 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tornillos; pernos y tuercas"
 },
 {
  "CNKEY": "741539000080",
  "CN": "7415 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741800000080",
  "CN": "7418",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de uso doméstico, higiene o tocador, y sus partes, de cobre; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos, de cobre"
 },
 {
  "CNKEY": "741810000080",
  "CN": "7418 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos de uso doméstico y sus partes; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos"
 },
 {
  "CNKEY": "741810100080",
  "CN": "7418 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos no eléctricos de cocción o de calefacción, de uso doméstico, y sus partes"
 },
 {
  "CNKEY": "741810900080",
  "CN": "7418 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "741820000080",
  "CN": "7418 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos de higiene o tocador, y sus partes"
 },
 {
  "CNKEY": "741900000080",
  "CN": "7419",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de cobre"
 },
 {
  "CNKEY": "741920000080",
  "CN": "7419 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Coladas, moldeadas, estampadas o forjadas, pero sin trabajar de otro modo"
 },
 {
  "CNKEY": "741980000080",
  "CN": "7419 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "741980100080",
  "CN": "7419 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Telas metálicas (incluidas las continuas o sin fin), redes y rejas, de alambre con una sección transversal no superior a 6 mm; chapas y tiras, extendidas (desplegadas)"
 },
 {
  "CNKEY": "741980300080",
  "CN": "7419 80 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muelles (resortes)"
 },
 {
  "CNKEY": "741980900080",
  "CN": "7419 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "750021000090",
  "CN": "75",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 75 - NÍQUEL Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "750100000080",
  "CN": "7501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Matas de níquel, sinters de óxidos de níquel y demás productos intermedios de la metalurgia del níquel"
 },
 {
  "CNKEY": "750110000080",
  "CN": "7501 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Matas de níquel"
 },
 {
  "CNKEY": "750120000080",
  "CN": "7501 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sinters de óxidos de níquel y demás productos intermedios de la metalurgia del níquel"
 },
 {
  "CNKEY": "750200000080",
  "CN": "7502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Níquel en bruto"
 },
 {
  "CNKEY": "750210000080",
  "CN": "7502 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Níquel sin alear"
 },
 {
  "CNKEY": "750220000080",
  "CN": "7502 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aleaciones de níquel"
 },
 {
  "CNKEY": "750300000080",
  "CN": "7503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de níquel"
 },
 {
  "CNKEY": "750300100080",
  "CN": "7503 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De níquel sin alear"
 },
 {
  "CNKEY": "750300900080",
  "CN": "7503 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De aleaciones de níquel"
 },
 {
  "CNKEY": "750400000080",
  "CN": "7504 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Polvo y escamillas, de níquel"
 },
 {
  "CNKEY": "750500000080",
  "CN": "7505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras, perfiles y alambre, de níquel"
 },
 {
  "CNKEY": "750511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barras y perfiles"
 },
 {
  "CNKEY": "750511000080",
  "CN": "7505 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De níquel sin alear"
 },
 {
  "CNKEY": "750512000080",
  "CN": "7505 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de níquel"
 },
 {
  "CNKEY": "750521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alambre"
 },
 {
  "CNKEY": "750521000080",
  "CN": "7505 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De níquel sin alear"
 },
 {
  "CNKEY": "750522000080",
  "CN": "7505 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de níquel"
 },
 {
  "CNKEY": "750600000080",
  "CN": "7506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chapas, hojas y tiras, de níquel"
 },
 {
  "CNKEY": "750610000080",
  "CN": "7506 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De níquel sin alear"
 },
 {
  "CNKEY": "750620000080",
  "CN": "7506 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De aleaciones de níquel"
 },
 {
  "CNKEY": "750700000080",
  "CN": "7507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos y accesorios de tubería [por ejemplo: empalmes (rácores), codos, manguitos], de níquel"
 },
 {
  "CNKEY": "750711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos"
 },
 {
  "CNKEY": "750711000080",
  "CN": "7507 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De níquel sin alear"
 },
 {
  "CNKEY": "750712000080",
  "CN": "7507 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de níquel"
 },
 {
  "CNKEY": "750720000080",
  "CN": "7507 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Accesorios de tubería"
 },
 {
  "CNKEY": "750800000080",
  "CN": "7508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de níquel"
 },
 {
  "CNKEY": "750810000080",
  "CN": "7508 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Telas metálicas, redes y rejas, de alambre de níquel"
 },
 {
  "CNKEY": "750890000080",
  "CN": "7508 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "760021000090",
  "CN": "76",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 76 - ALUMINIO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "760100000080",
  "CN": "7601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aluminio en bruto"
 },
 {
  "CNKEY": "760110000080",
  "CN": "7601 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aluminio sin alear"
 },
 {
  "CNKEY": "760120000080",
  "CN": "7601 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aleaciones de aluminio"
 },
 {
  "CNKEY": "760120200080",
  "CN": "7601 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desbastes planos y palaquillas"
 },
 {
  "CNKEY": "760120800080",
  "CN": "7601 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "760200000080",
  "CN": "7602 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de aluminio"
 },
 {
  "CNKEY": "760200110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desperdicios"
 },
 {
  "CNKEY": "760200110080",
  "CN": "7602 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Torneaduras, virutas, esquirlas, limaduras (de amolado, aserrado, limado); desperdicios de hojas y tiras delgadas, coloreadas, revestidas o pegadas, de espesor inferior o igual a 0,2 mm (sin incluir el soporte)"
 },
 {
  "CNKEY": "760200190080",
  "CN": "7602 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos los rechazos de fabricación"
 },
 {
  "CNKEY": "760200900080",
  "CN": "7602 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desechos"
 },
 {
  "CNKEY": "760300000080",
  "CN": "7603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polvo y escamillas, de aluminio"
 },
 {
  "CNKEY": "760310000080",
  "CN": "7603 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo de estructura no laminar"
 },
 {
  "CNKEY": "760320000080",
  "CN": "7603 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo de estructura laminar; escamillas"
 },
 {
  "CNKEY": "760400000080",
  "CN": "7604",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barras y perfiles, de aluminio"
 },
 {
  "CNKEY": "760410000080",
  "CN": "7604 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aluminio sin alear"
 },
 {
  "CNKEY": "760410100080",
  "CN": "7604 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Barras"
 },
 {
  "CNKEY": "760410900080",
  "CN": "7604 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles"
 },
 {
  "CNKEY": "760421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de aluminio"
 },
 {
  "CNKEY": "760421000080",
  "CN": "7604 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Perfiles huecos"
 },
 {
  "CNKEY": "760429000080",
  "CN": "7604 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "760429100080",
  "CN": "7604 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Barras"
 },
 {
  "CNKEY": "760429900080",
  "CN": "7604 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Perfiles"
 },
 {
  "CNKEY": "760500000080",
  "CN": "7605",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambre de aluminio"
 },
 {
  "CNKEY": "760511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aluminio sin alear"
 },
 {
  "CNKEY": "760511000080",
  "CN": "7605 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión de la sección transversal superior a 7 mm"
 },
 {
  "CNKEY": "760519000080",
  "CN": "7605 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "760521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de aluminio"
 },
 {
  "CNKEY": "760521000080",
  "CN": "7605 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con la mayor dimensión de la sección transversal superior a 7 mm"
 },
 {
  "CNKEY": "760529000080",
  "CN": "7605 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "760600000080",
  "CN": "7606",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chapas y tiras, de aluminio, de espesor superior a 0,2 mm"
 },
 {
  "CNKEY": "760611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cuadradas o rectangulares"
 },
 {
  "CNKEY": "760611000080",
  "CN": "7606 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De aluminio sin alear"
 },
 {
  "CNKEY": "760611300080",
  "CN": "7606 11 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Lámina de aluminio compuesta"
 },
 {
  "CNKEY": "760611500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "760611500080",
  "CN": "7606 11 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pintadas, barnizadas o revestidas de plástico"
 },
 {
  "CNKEY": "760611910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás, de espesor"
 },
 {
  "CNKEY": "760611910080",
  "CN": "7606 11 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior a 3 mm"
 },
 {
  "CNKEY": "760611930080",
  "CN": "7606 11 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 3 mm pero inferior a 6 mm"
 },
 {
  "CNKEY": "760611990080",
  "CN": "7606 11 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 6 mm"
 },
 {
  "CNKEY": "760612000080",
  "CN": "7606 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De aleaciones de aluminio"
 },
 {
  "CNKEY": "760612110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Material para cuerpos, fondos, tapas y anillas de latas de bebida"
 },
 {
  "CNKEY": "760612110080",
  "CN": "7606 12 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Material para cuerpos de latas de bebida"
 },
 {
  "CNKEY": "760612190080",
  "CN": "7606 12 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Material para fondos, tapas y anillas de latas de bebida"
 },
 {
  "CNKEY": "760612300080",
  "CN": "7606 12 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Lámina de aluminio compuesta"
 },
 {
  "CNKEY": "760612500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "760612500080",
  "CN": "7606 12 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Pintadas, barnizadas o revestidas de plástico"
 },
 {
  "CNKEY": "760612920010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás, de espesor"
 },
 {
  "CNKEY": "760612920080",
  "CN": "7606 12 92",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Inferior a 3 mm"
 },
 {
  "CNKEY": "760612930080",
  "CN": "7606 12 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 3 mm pero inferior a 6 mm"
 },
 {
  "CNKEY": "760612990080",
  "CN": "7606 12 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Superior o igual a 6 mm"
 },
 {
  "CNKEY": "760691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "760691000080",
  "CN": "7606 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aluminio sin alear"
 },
 {
  "CNKEY": "760692000080",
  "CN": "7606 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aleaciones de aluminio"
 },
 {
  "CNKEY": "760700000080",
  "CN": "7607",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hojas y tiras, delgadas, de aluminio, incluso impresas o fijadas sobre papel, cartón, plástico o soportes similares, de espesor inferior o igual a 0,2 mm (sin incluir el soporte)"
 },
 {
  "CNKEY": "760711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin soporte"
 },
 {
  "CNKEY": "760711000080",
  "CN": "7607 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Simplemente laminadas"
 },
 {
  "CNKEY": "760711110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De espesor inferior a 0,021 mm"
 },
 {
  "CNKEY": "760711110080",
  "CN": "7607 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "En bobinas (rollos) de peso inferior o igual a 10 kg"
 },
 {
  "CNKEY": "760711190080",
  "CN": "7607 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "760711900080",
  "CN": "7607 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 0,021 mm pero inferior o igual a 0,2 mm"
 },
 {
  "CNKEY": "760719000080",
  "CN": "7607 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "760719100080",
  "CN": "7607 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor inferior a 0,021 mm"
 },
 {
  "CNKEY": "760719900080",
  "CN": "7607 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De espesor superior o igual a 0,021 mm pero inferior o igual a 0,2 mm"
 },
 {
  "CNKEY": "760720000080",
  "CN": "7607 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con soporte"
 },
 {
  "CNKEY": "760720100080",
  "CN": "7607 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De espesor (sin incluir el soporte) inferior a 0,021 mm"
 },
 {
  "CNKEY": "760720910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De espesor (sin incluir el soporte) superior o igual a 0,021 mm pero inferior o igual a 0,2 mm"
 },
 {
  "CNKEY": "760720910080",
  "CN": "7607 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "m²",
  "ES": "Lámina de aluminio compuesta"
 },
 {
  "CNKEY": "760720990080",
  "CN": "7607 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "760800000080",
  "CN": "7608",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos de aluminio"
 },
 {
  "CNKEY": "760810000080",
  "CN": "7608 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De aluminio sin alear"
 },
 {
  "CNKEY": "760820000080",
  "CN": "7608 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De aleaciones de aluminio"
 },
 {
  "CNKEY": "760820200080",
  "CN": "7608 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldados"
 },
 {
  "CNKEY": "760820810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "760820810080",
  "CN": "7608 20 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Simplemente extrudidos en caliente"
 },
 {
  "CNKEY": "760820890080",
  "CN": "7608 20 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "760900000080",
  "CN": "7609 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Accesorios de tuberías [por ejemplo: empalmes (rácores), codos, manguitos], de aluminio"
 },
 {
  "CNKEY": "761000000080",
  "CN": "7610",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Construcciones y sus partes (por ejemplo: puentes y sus partes, torres, castilletes, pilares, columnas, armazones para techumbre, techados, puertas y ventanas y sus marcos, contramarcos y umbrales, barandillas), de aluminio (excepto las construcciones prefabricadas de la partida 9406); chapas, barras, perfiles, tubos y similares, de aluminio, preparados para la construcción"
 },
 {
  "CNKEY": "761010000080",
  "CN": "7610 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Puertas y ventanas y sus marcos, contramarcos y umbrales"
 },
 {
  "CNKEY": "761090000080",
  "CN": "7610 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "761090100080",
  "CN": "7610 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Puentes y sus partes, torres y castilletes"
 },
 {
  "CNKEY": "761090900080",
  "CN": "7610 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "761100000080",
  "CN": "7611 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Depósitos, cisternas, cubas y recipientes similares para cualquier materia (excepto gas comprimido o licuado), de aluminio, de capacidad superior a 300 l, sin dispositivos mecánicos ni térmicos, incluso con revestimiento interior o calorífugo"
 },
 {
  "CNKEY": "761200000080",
  "CN": "7612",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Depósitos, barriles, tambores, bidones, botes, cajas y recipientes similares, de aluminio (incluidos los envases tubulares rígidos o flexibles), para cualquier materia (excepto gas comprimido o licuado), de capacidad inferior o igual a 300 l, sin dispositivos mecánicos ni térmicos, incluso con revestimiento interior o calorífugo"
 },
 {
  "CNKEY": "761210000080",
  "CN": "7612 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Envases tubulares flexibles"
 },
 {
  "CNKEY": "761290000080",
  "CN": "7612 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "761290200080",
  "CN": "7612 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Recipientes de los tipos utilizados para aerosoles"
 },
 {
  "CNKEY": "761290300080",
  "CN": "7612 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fabricados a partir de hojas y tiras delgadas de espesor inferior o igual a 0,2 mm"
 },
 {
  "CNKEY": "761290800080",
  "CN": "7612 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "761300000080",
  "CN": "7613 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Recipientes para gas comprimido o licuado, de aluminio"
 },
 {
  "CNKEY": "761400000080",
  "CN": "7614",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cables, trenzas y artículos similares, de aluminio, sin aislar para electricidad"
 },
 {
  "CNKEY": "761410000080",
  "CN": "7614 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Con alma de acero"
 },
 {
  "CNKEY": "761490000080",
  "CN": "7614 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "761500000080",
  "CN": "7615",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de uso doméstico, higiene o tocador y sus partes, de aluminio; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos, de aluminio"
 },
 {
  "CNKEY": "761510000080",
  "CN": "7615 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos de uso doméstico y sus partes; esponjas, estropajos, guantes y artículos similares para fregar, lustrar o usos análogos"
 },
 {
  "CNKEY": "761510100080",
  "CN": "7615 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Colados o moldeados"
 },
 {
  "CNKEY": "761510300080",
  "CN": "7615 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fabricados a partir de hojas y tiras delgadas de espesor inferior o igual a 0,2 mm"
 },
 {
  "CNKEY": "761510800080",
  "CN": "7615 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "761520000080",
  "CN": "7615 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos de higiene o tocador, y sus partes"
 },
 {
  "CNKEY": "761600000080",
  "CN": "7616",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de aluminio"
 },
 {
  "CNKEY": "761610000080",
  "CN": "7616 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Puntas, clavos, grapas apuntadas, tornillos, pernos, tuercas, escarpias roscadas, remaches, pasadores, chavetas, arandelas y artículos similares"
 },
 {
  "CNKEY": "761691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "761691000080",
  "CN": "7616 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Telas metálicas, redes y rejas, de alambre de aluminio"
 },
 {
  "CNKEY": "761699000080",
  "CN": "7616 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "761699100080",
  "CN": "7616 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas"
 },
 {
  "CNKEY": "761699900080",
  "CN": "7616 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "780021000090",
  "CN": "78",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 78 - PLOMO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "780100000080",
  "CN": "7801",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Plomo en bruto"
 },
 {
  "CNKEY": "780110000080",
  "CN": "7801 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Plomo refinado"
 },
 {
  "CNKEY": "780191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "780191000080",
  "CN": "7801 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con antimonio como el otro elemento predominante en peso"
 },
 {
  "CNKEY": "780199000080",
  "CN": "7801 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "780199100080",
  "CN": "7801 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de plata superior o igual al 0,02 % en peso y que se destine al afino (plomo de obra)"
 },
 {
  "CNKEY": "780199900080",
  "CN": "7801 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "780200000080",
  "CN": "7802 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos, de plomo"
 },
 {
  "CNKEY": "780400000080",
  "CN": "7804",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chapas, hojas y tiras, de plomo; polvo y escamillas, de plomo"
 },
 {
  "CNKEY": "780411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chapas, hojas y tiras"
 },
 {
  "CNKEY": "780411000080",
  "CN": "7804 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chapas y tiras, de espesor inferior o igual a 0,2 mm (sin incluir el soporte)"
 },
 {
  "CNKEY": "780419000080",
  "CN": "7804 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "780420000080",
  "CN": "7804 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo y escamillas"
 },
 {
  "CNKEY": "780600000080",
  "CN": "7806 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de plomo"
 },
 {
  "CNKEY": "780600100080",
  "CN": "7806 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Envases con blindaje de plomo de protección contra las radiaciones, para transportar o almacenar materias radiactivas (Euratom)"
 },
 {
  "CNKEY": "780600800080",
  "CN": "7806 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "790021000090",
  "CN": "79",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 79 - CINC Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "790100000080",
  "CN": "7901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cinc en bruto"
 },
 {
  "CNKEY": "790111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cinc sin alear"
 },
 {
  "CNKEY": "790111000080",
  "CN": "7901 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de cinc superior o igual al 99,99 % en peso"
 },
 {
  "CNKEY": "790112000080",
  "CN": "7901 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un contenido de cinc inferior al 99,99 % en peso"
 },
 {
  "CNKEY": "790112100080",
  "CN": "7901 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de cinc superior o igual al 99,95 % pero inferior al 99,99 % en peso"
 },
 {
  "CNKEY": "790112300080",
  "CN": "7901 12 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de cinc superior o igual al 98,5 % pero inferior al 99,95 % en peso"
 },
 {
  "CNKEY": "790112900080",
  "CN": "7901 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con un contenido de cinc superior o igual al 97,5 % pero inferior al 98,5 % en peso"
 },
 {
  "CNKEY": "790120000080",
  "CN": "7901 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aleaciones de cinc"
 },
 {
  "CNKEY": "790200000080",
  "CN": "7902 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos, de cinc"
 },
 {
  "CNKEY": "790300000080",
  "CN": "7903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polvo y escamillas, de cinc"
 },
 {
  "CNKEY": "790310000080",
  "CN": "7903 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo de condensación"
 },
 {
  "CNKEY": "790390000080",
  "CN": "7903 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "790400000080",
  "CN": "7904 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Barras, perfiles y alambre, de cinc"
 },
 {
  "CNKEY": "790500000080",
  "CN": "7905 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Chapas, hojas y tiras, de cinc"
 },
 {
  "CNKEY": "790700000080",
  "CN": "7907 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Las demás manufacturas de cinc"
 },
 {
  "CNKEY": "800021000090",
  "CN": "80",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 80 - ESTAÑO Y SUS MANUFACTURAS"
 },
 {
  "CNKEY": "800100000080",
  "CN": "8001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Estaño en bruto"
 },
 {
  "CNKEY": "800110000080",
  "CN": "8001 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Estaño sin alear"
 },
 {
  "CNKEY": "800120000080",
  "CN": "8001 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aleaciones de estaño"
 },
 {
  "CNKEY": "800200000080",
  "CN": "8002 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos, de estaño"
 },
 {
  "CNKEY": "800300000080",
  "CN": "8003 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Barras, perfiles y alambre, de estaño"
 },
 {
  "CNKEY": "800700000080",
  "CN": "8007 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás manufacturas de estaño"
 },
 {
  "CNKEY": "800700100080",
  "CN": "8007 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Chapas, hojas y tiras, de espesor superior a 0,2 mm"
 },
 {
  "CNKEY": "800700800080",
  "CN": "8007 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "810021000090",
  "CN": "81",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 81 - LOS DEMÁS METALES COMUNES; CERMETS; MANUFACTURAS DE ESTAS MATERIAS"
 },
 {
  "CNKEY": "810100000080",
  "CN": "8101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Volframio (tungsteno) y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810110000080",
  "CN": "8101 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo"
 },
 {
  "CNKEY": "810194000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810194000080",
  "CN": "8101 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Volframio (tungsteno) en bruto, incluidas las barras simplemente obtenidas por sinterizado"
 },
 {
  "CNKEY": "810196000080",
  "CN": "8101 96 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alambre"
 },
 {
  "CNKEY": "810197000080",
  "CN": "8101 97 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810199000080",
  "CN": "8101 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810199100080",
  "CN": "8101 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Barras (excepto simplemente las obtenidas por sinterizado), perfiles, chapas, hojas y tiras"
 },
 {
  "CNKEY": "810199900080",
  "CN": "8101 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810200000080",
  "CN": "8102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Molibdeno y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810210000080",
  "CN": "8102 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Polvo"
 },
 {
  "CNKEY": "810294000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810294000080",
  "CN": "8102 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Molibdeno en bruto, incluidas las barras simplemente obtenidas por sinterizado"
 },
 {
  "CNKEY": "810295000080",
  "CN": "8102 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Barras (excepto las simplemente obtenidas por sinterizado), perfiles, chapas, hojas y tiras"
 },
 {
  "CNKEY": "810296000080",
  "CN": "8102 96 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Alambre"
 },
 {
  "CNKEY": "810297000080",
  "CN": "8102 97 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810299000080",
  "CN": "8102 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810300000080",
  "CN": "8103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tantalio y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810320000080",
  "CN": "8103 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tantalio en bruto, incluidas las barras simplemente obtenidas por sinterizado; polvo"
 },
 {
  "CNKEY": "810330000080",
  "CN": "8103 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810391000080",
  "CN": "8103 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Crisoles"
 },
 {
  "CNKEY": "810399000080",
  "CN": "8103 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810399100080",
  "CN": "8103 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Barras (excepto las simplemente obtenidas por sinterizado), perfiles, alambre, chapas, hojas y tiras"
 },
 {
  "CNKEY": "810399900080",
  "CN": "8103 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810400000080",
  "CN": "8104",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Magnesio y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Magnesio en bruto"
 },
 {
  "CNKEY": "810411000080",
  "CN": "8104 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido de magnesio superior o igual al 99,8 % en peso"
 },
 {
  "CNKEY": "810419000080",
  "CN": "8104 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810420000080",
  "CN": "8104 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810430000080",
  "CN": "8104 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Virutas, torneaduras y gránulos calibrados; polvo"
 },
 {
  "CNKEY": "810490000080",
  "CN": "8104 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810500000080",
  "CN": "8105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Matas de cobalto y demás productos intermedios de la metalurgia del cobalto; cobalto y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810520000080",
  "CN": "8105 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Matas de cobalto y demás productos intermedios de la metalurgia del cobalto; cobalto en bruto; polvo"
 },
 {
  "CNKEY": "810530000080",
  "CN": "8105 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810590000080",
  "CN": "8105 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810600000080",
  "CN": "8106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bismuto y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810610000080",
  "CN": "8106 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con un contenido de bismuto superior al 99,99 % en peso"
 },
 {
  "CNKEY": "810610100080",
  "CN": "8106 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bismuto en bruto; desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "810610900080",
  "CN": "8106 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810690000080",
  "CN": "8106 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810690100080",
  "CN": "8106 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bismuto en bruto; desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "810690900080",
  "CN": "8106 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810800000080",
  "CN": "8108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Titanio y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810820000080",
  "CN": "8108 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Titanio en bruto; polvo"
 },
 {
  "CNKEY": "810830000080",
  "CN": "8108 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810890000080",
  "CN": "8108 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810890300080",
  "CN": "8108 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Barras, perfiles y alambre"
 },
 {
  "CNKEY": "810890500080",
  "CN": "8108 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chapas, hojas y tiras"
 },
 {
  "CNKEY": "810890600080",
  "CN": "8108 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tubos"
 },
 {
  "CNKEY": "810890900080",
  "CN": "8108 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810900000080",
  "CN": "8109",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Circonio y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "810921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Circonio en bruto; polvo"
 },
 {
  "CNKEY": "810921000080",
  "CN": "8109 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido inferior a 1 parte de hafnio (celtio) por 500 partes de circonio, en peso"
 },
 {
  "CNKEY": "810929000080",
  "CN": "8109 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "810931000080",
  "CN": "8109 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido inferior a 1 parte de hafnio (celtio) por 500 partes de circonio, en peso"
 },
 {
  "CNKEY": "810939000080",
  "CN": "8109 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "810991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "810991000080",
  "CN": "8109 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con un contenido inferior a 1 parte de hafnio (celtio) por 500 partes de circonio, en peso"
 },
 {
  "CNKEY": "810999000080",
  "CN": "8109 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811000000080",
  "CN": "8110",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Antimonio y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "811010000080",
  "CN": "8110 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Antimonio en bruto; polvo"
 },
 {
  "CNKEY": "811020000080",
  "CN": "8110 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811090000080",
  "CN": "8110 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811100000080",
  "CN": "8111 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Manganeso y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "811100110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Manganeso en bruto, desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "811100110080",
  "CN": "8111 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Manganeso en bruto; polvo"
 },
 {
  "CNKEY": "811100190080",
  "CN": "8111 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811100900080",
  "CN": "8111 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811200000080",
  "CN": "8112",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Berilio, cadmio, cromo, germanio, vanadio, galio, hafnio (celtio), indio, niobio (colombio), renio y talio, asi como las manufacturas de estos metales, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "811212000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Berilio"
 },
 {
  "CNKEY": "811212000080",
  "CN": "8112 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto; polvo"
 },
 {
  "CNKEY": "811213000080",
  "CN": "8112 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811219000080",
  "CN": "8112 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cromo"
 },
 {
  "CNKEY": "811221000080",
  "CN": "8112 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En bruto; polvo"
 },
 {
  "CNKEY": "811221100080",
  "CN": "8112 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aleaciones de cromo con un contenido de níquel superior al 10 % en peso"
 },
 {
  "CNKEY": "811221900080",
  "CN": "8112 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811222000080",
  "CN": "8112 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811229000080",
  "CN": "8112 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hafnio (celtio)"
 },
 {
  "CNKEY": "811231000080",
  "CN": "8112 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto; desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "811239000080",
  "CN": "8112 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Renio"
 },
 {
  "CNKEY": "811241000080",
  "CN": "8112 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En bruto; desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "811241100080",
  "CN": "8112 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811241900080",
  "CN": "8112 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811249000080",
  "CN": "8112 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Talio"
 },
 {
  "CNKEY": "811251000080",
  "CN": "8112 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "En bruto; polvo"
 },
 {
  "CNKEY": "811252000080",
  "CN": "8112 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desechos y desperdicios"
 },
 {
  "CNKEY": "811259000080",
  "CN": "8112 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811261000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cadmio"
 },
 {
  "CNKEY": "811261000080",
  "CN": "8112 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811269000080",
  "CN": "8112 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "811269100080",
  "CN": "8112 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cadmio en bruto; polvo"
 },
 {
  "CNKEY": "811269900080",
  "CN": "8112 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "811292000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "811292000080",
  "CN": "8112 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "En bruto; desperdicios y desechos; polvo"
 },
 {
  "CNKEY": "811292210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Niobio (colombio), galio, indio, vanadio, germanio"
 },
 {
  "CNKEY": "811292210080",
  "CN": "8112 92 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811292400010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "811292400080",
  "CN": "8112 92 40",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Niobio (colombio)"
 },
 {
  "CNKEY": "811292810080",
  "CN": "8112 92 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Indio"
 },
 {
  "CNKEY": "811292890080",
  "CN": "8112 92 89",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Galio"
 },
 {
  "CNKEY": "811292910080",
  "CN": "8112 92 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Vanadio"
 },
 {
  "CNKEY": "811292950080",
  "CN": "8112 92 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Germanio"
 },
 {
  "CNKEY": "811299000080",
  "CN": "8112 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "811299400080",
  "CN": "8112 99 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Germanio"
 },
 {
  "CNKEY": "811299500080",
  "CN": "8112 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Niobio (colombio)"
 },
 {
  "CNKEY": "811299700080",
  "CN": "8112 99 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Galio, indio, vanadio"
 },
 {
  "CNKEY": "811300000080",
  "CN": "8113 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cermet y sus manufacturas, incluidos los desperdicios y desechos"
 },
 {
  "CNKEY": "811300200080",
  "CN": "8113 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "En bruto"
 },
 {
  "CNKEY": "811300400080",
  "CN": "8113 00 40",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos"
 },
 {
  "CNKEY": "811300900080",
  "CN": "8113 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820021000090",
  "CN": "82",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 82 - HERRAMIENTAS Y ÚTILES, ARTÍCULOS DE CUCHILLERÍA Y CUBIERTOS DE MESA, DE METAL COMÚN; PARTES DE ESTOS ARTÍCULOS, DE METAL COMÚN"
 },
 {
  "CNKEY": "820100000080",
  "CN": "8201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Layas, palas, azadas, picos, binaderas, horcas de labranza, rastrillos y raederas; hachas, hocinos y herramientas similares con filo; tijeras de podar de cualquier tipo, hoces y guadañas; cuchillos para heno o para paja, cizallas para setos, cuñas y demás herramientas de mano, agrícolas, hortícolas o forestales"
 },
 {
  "CNKEY": "820110000080",
  "CN": "8201 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Layas y palas"
 },
 {
  "CNKEY": "820130000080",
  "CN": "8201 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Azadas, picos, binaderas, rastrillos y raederas"
 },
 {
  "CNKEY": "820140000080",
  "CN": "8201 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hachas, hocinos y herramientas similares con filo"
 },
 {
  "CNKEY": "820150000080",
  "CN": "8201 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tijeras de podar (incluidas las de trinchar aves), para usar con una sola mano"
 },
 {
  "CNKEY": "820160000080",
  "CN": "8201 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cizallas para setos, tijeras de podar y herramientas similares, para usar con las dos manos"
 },
 {
  "CNKEY": "820190000080",
  "CN": "8201 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás herramientas de mano, agrícolas, hortícolas o forestales"
 },
 {
  "CNKEY": "820200000080",
  "CN": "8202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sierras de mano; hojas de sierra de cualquier clase (incluidas las fresas sierra y las hojas sin dentar)"
 },
 {
  "CNKEY": "820210000080",
  "CN": "8202 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sierras de mano"
 },
 {
  "CNKEY": "820220000080",
  "CN": "8202 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hojas de sierra de cinta"
 },
 {
  "CNKEY": "820231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hojas de sierra circulares (incluidas las fresas sierra)"
 },
 {
  "CNKEY": "820231000080",
  "CN": "8202 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de acero"
 },
 {
  "CNKEY": "820239000080",
  "CN": "8202 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás, incluidas las partes"
 },
 {
  "CNKEY": "820240000080",
  "CN": "8202 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cadenas cortantes"
 },
 {
  "CNKEY": "820291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás hojas de sierra"
 },
 {
  "CNKEY": "820291000080",
  "CN": "8202 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojas de sierra rectas para trabajar metal"
 },
 {
  "CNKEY": "820299000080",
  "CN": "8202 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "820299200080",
  "CN": "8202 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para trabajar metal"
 },
 {
  "CNKEY": "820299800080",
  "CN": "8202 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para trabajar las demás materias"
 },
 {
  "CNKEY": "820300000080",
  "CN": "8203",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Limas, escofinas, alicates, incluso cortantes, tenazas, pinzas, cizallas para metales, cortatubos, cortapernos, sacabocados y herramientas similares, de mano"
 },
 {
  "CNKEY": "820310000080",
  "CN": "8203 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Limas, escofinas y herramientas similares"
 },
 {
  "CNKEY": "820320000080",
  "CN": "8203 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alicates, incluso cortantes, tenazas, pinzas y herramientas similares"
 },
 {
  "CNKEY": "820330000080",
  "CN": "8203 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cizallas para metales y herramientas similares"
 },
 {
  "CNKEY": "820340000080",
  "CN": "8203 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cortatubos, cortapernos, sacabocados y herramientas similares"
 },
 {
  "CNKEY": "820400000080",
  "CN": "8204",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Llaves de ajuste de mano (incluidas las llaves dinamométricas); cubos (vasos) de ajuste intercambiables, incluso con mango"
 },
 {
  "CNKEY": "820411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Llaves de ajuste de mano"
 },
 {
  "CNKEY": "820411000080",
  "CN": "8204 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "No ajustables"
 },
 {
  "CNKEY": "820412000080",
  "CN": "8204 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ajustables"
 },
 {
  "CNKEY": "820420000080",
  "CN": "8204 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cubos (vasos) de ajuste intercambiables, incluso con mango"
 },
 {
  "CNKEY": "820500000080",
  "CN": "8205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Herramientas de mano (incluidos los diamantes de vidriero), no expresadas ni comprendidas en otra parte; lámparas de soldar y similares; tornillos de banco, prensas de carpintero y similares, excepto los que sean accesorios o partes de máquinas herramienta o de máquinas para cortar por chorro de agua; yunques; fraguas portátiles; muelas de mano o pedal, con bastidor"
 },
 {
  "CNKEY": "820510000080",
  "CN": "8205 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Herramientas de taladrar o roscar (incluidas las terrajas)"
 },
 {
  "CNKEY": "820520000080",
  "CN": "8205 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Martillos y mazas"
 },
 {
  "CNKEY": "820530000080",
  "CN": "8205 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cepillos, formones, gubias y herramientas cortantes similares para trabajar madera"
 },
 {
  "CNKEY": "820540000080",
  "CN": "8205 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Destornilladores"
 },
 {
  "CNKEY": "820551000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás herramientas de mano (incluidos los diamantes de vidriero)"
 },
 {
  "CNKEY": "820551000080",
  "CN": "8205 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De uso doméstico"
 },
 {
  "CNKEY": "820559000080",
  "CN": "8205 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "820559100080",
  "CN": "8205 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Herramientas para albañiles, modeladores, cementeros, escayolistas y pintores"
 },
 {
  "CNKEY": "820559800080",
  "CN": "8205 59 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "820560000080",
  "CN": "8205 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lámparas de soldar y similares"
 },
 {
  "CNKEY": "820570000080",
  "CN": "8205 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tornillos de banco, prensas de carpintero y similares"
 },
 {
  "CNKEY": "820590000080",
  "CN": "8205 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidos juegos de artículos de dos o más de las subpartidas de esta partida"
 },
 {
  "CNKEY": "820590100080",
  "CN": "8205 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Yunques; fraguas portátiles; muelas de mano o pedal, con bastidor"
 },
 {
  "CNKEY": "820590900080",
  "CN": "8205 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Juegos de artículos de dos o más de las subpartidas de esta partida"
 },
 {
  "CNKEY": "820600000080",
  "CN": "8206 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Herramientas de dos o más de las partidas 8202 a 8205, acondicionadas en juegos para la venta al por menor"
 },
 {
  "CNKEY": "820700000080",
  "CN": "8207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Útiles intercambiables para herramientas de mano, incluso mecánicas, o para máquinas herramienta (por ejemplo: de embutir, estampar, punzonar, roscar (incluso aterrajar), taladrar, escariar, brochar, fresar, tornear, atornillar), incluidas las hileras de extrudir o de estirar (trefilar) metal, así como los útiles de perforación o sondeo"
 },
 {
  "CNKEY": "820713000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de perforación o sondeo"
 },
 {
  "CNKEY": "820713000080",
  "CN": "8207 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de cermet"
 },
 {
  "CNKEY": "820719000080",
  "CN": "8207 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "820719100080",
  "CN": "8207 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con parte operante de diamante o de aglomerados de diamante"
 },
 {
  "CNKEY": "820719900080",
  "CN": "8207 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820720000080",
  "CN": "8207 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hileras de extrudir o de estirar (trefilar) metal"
 },
 {
  "CNKEY": "820720100080",
  "CN": "8207 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de diamante o de aglomerados de diamante"
 },
 {
  "CNKEY": "820720900080",
  "CN": "8207 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de las demás materias"
 },
 {
  "CNKEY": "820730000080",
  "CN": "8207 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de embutir, estampar o punzonar"
 },
 {
  "CNKEY": "820730100080",
  "CN": "8207 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para trabajar metal"
 },
 {
  "CNKEY": "820730900080",
  "CN": "8207 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820740000080",
  "CN": "8207 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de roscar, incluso aterrajar"
 },
 {
  "CNKEY": "820740100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para trabajar metal"
 },
 {
  "CNKEY": "820740100080",
  "CN": "8207 40 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Útiles para aterrajar"
 },
 {
  "CNKEY": "820740300080",
  "CN": "8207 40 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Útiles para roscar, incluso aterrajar, exteriormente"
 },
 {
  "CNKEY": "820740900080",
  "CN": "8207 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820750000080",
  "CN": "8207 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de taladrar"
 },
 {
  "CNKEY": "820750100080",
  "CN": "8207 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de diamante o de aglomerados de diamante"
 },
 {
  "CNKEY": "820750300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con parte operante de las demás materias"
 },
 {
  "CNKEY": "820750300080",
  "CN": "8207 50 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Brocas para albañilería"
 },
 {
  "CNKEY": "820750500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "820750500020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Para mecanizar metal, con parte operante"
 },
 {
  "CNKEY": "820750500080",
  "CN": "8207 50 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De cermet"
 },
 {
  "CNKEY": "820750600080",
  "CN": "8207 50 60",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero rápido"
 },
 {
  "CNKEY": "820750700080",
  "CN": "8207 50 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "820750900080",
  "CN": "8207 50 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820760000080",
  "CN": "8207 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de escariar o brochar"
 },
 {
  "CNKEY": "820760100080",
  "CN": "8207 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de diamantes o de aglomerados de diamante"
 },
 {
  "CNKEY": "820760300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con parte operante de las demás materias"
 },
 {
  "CNKEY": "820760300020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Útiles de escariar"
 },
 {
  "CNKEY": "820760300080",
  "CN": "8207 60 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Para mecanizar metal"
 },
 {
  "CNKEY": "820760500080",
  "CN": "8207 60 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820760700010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Útiles de brochar"
 },
 {
  "CNKEY": "820760700080",
  "CN": "8207 60 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Para mecanizar metal"
 },
 {
  "CNKEY": "820760900080",
  "CN": "8207 60 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820770000080",
  "CN": "8207 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de fresar"
 },
 {
  "CNKEY": "820770100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para trabajar metal, con parte operante"
 },
 {
  "CNKEY": "820770100080",
  "CN": "8207 70 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cermet"
 },
 {
  "CNKEY": "820770310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "820770310080",
  "CN": "8207 70 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Fresas con mango"
 },
 {
  "CNKEY": "820770370080",
  "CN": "8207 70 37",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820770900080",
  "CN": "8207 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820780000080",
  "CN": "8207 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Útiles de tornear"
 },
 {
  "CNKEY": "820780110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para mecanizar metal, con parte operante"
 },
 {
  "CNKEY": "820780110080",
  "CN": "8207 80 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cermet"
 },
 {
  "CNKEY": "820780190080",
  "CN": "8207 80 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "820780900080",
  "CN": "8207 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820790000080",
  "CN": "8207 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás útiles intercambiables"
 },
 {
  "CNKEY": "820790100080",
  "CN": "8207 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con parte operante de diamante o de aglomerados de diamante"
 },
 {
  "CNKEY": "820790300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con parte operante de las demás materias"
 },
 {
  "CNKEY": "820790300080",
  "CN": "8207 90 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Puntas de destornillador"
 },
 {
  "CNKEY": "820790500080",
  "CN": "8207 90 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Útiles para tallar engranajes"
 },
 {
  "CNKEY": "820790710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, con parte operante"
 },
 {
  "CNKEY": "820790710020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De cermet"
 },
 {
  "CNKEY": "820790710080",
  "CN": "8207 90 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Para mecanizar metal"
 },
 {
  "CNKEY": "820790780080",
  "CN": "8207 90 78",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820790910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "820790910080",
  "CN": "8207 90 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Para mecanizar metal"
 },
 {
  "CNKEY": "820790990080",
  "CN": "8207 90 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "820800000080",
  "CN": "8208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuchillas y hojas cortantes, para máquinas o aparatos mecánicos"
 },
 {
  "CNKEY": "820810000080",
  "CN": "8208 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para trabajar metal"
 },
 {
  "CNKEY": "820820000080",
  "CN": "8208 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para trabajar madera"
 },
 {
  "CNKEY": "820830000080",
  "CN": "8208 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para aparatos de cocina o máquinas de la industria alimentaria"
 },
 {
  "CNKEY": "820840000080",
  "CN": "8208 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para máquinas agrícolas, hortícolas o forestales"
 },
 {
  "CNKEY": "820890000080",
  "CN": "8208 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "820900000080",
  "CN": "8209 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Plaquitas, varillas, puntas y artículos similares para útiles, sin montar, de cermet"
 },
 {
  "CNKEY": "820900200080",
  "CN": "8209 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Plaquitas intercambiables"
 },
 {
  "CNKEY": "820900800080",
  "CN": "8209 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "821000000080",
  "CN": "8210 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Aparatos mecánicos accionados a mano, de peso inferior o igual a 10 kg, utilizados para preparar, acondicionar o servir alimentos o bebidas"
 },
 {
  "CNKEY": "821100000080",
  "CN": "8211",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuchillos con hoja cortante o dentada, incluidas las navajas de podar, y sus hojas (excepto los artículos de la partida 8208)"
 },
 {
  "CNKEY": "821110000080",
  "CN": "8211 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Surtidos"
 },
 {
  "CNKEY": "821191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "821191000080",
  "CN": "8211 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuchillos de mesa de hoja fija"
 },
 {
  "CNKEY": "821192000080",
  "CN": "8211 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás cuchillos de hoja fija"
 },
 {
  "CNKEY": "821193000080",
  "CN": "8211 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuchillos (excepto los de hoja fija), incluidas las navajas de podar"
 },
 {
  "CNKEY": "821194000080",
  "CN": "8211 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojas"
 },
 {
  "CNKEY": "821195000080",
  "CN": "8211 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mangos de metal común"
 },
 {
  "CNKEY": "821200000080",
  "CN": "8212",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Navajas y maquinillas de afeitar y sus hojas (incluidos los esbozos en fleje)"
 },
 {
  "CNKEY": "821210000080",
  "CN": "8212 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Navajas y maquinillas de afeitar"
 },
 {
  "CNKEY": "821210100080",
  "CN": "8212 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Maquinillas de afeitar de hoja no reemplazable"
 },
 {
  "CNKEY": "821210900080",
  "CN": "8212 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "821220000080",
  "CN": "8212 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "1000 p\/st",
  "ES": "Hojas para maquinillas de afeitar, incluidos los esbozos en fleje"
 },
 {
  "CNKEY": "821290000080",
  "CN": "8212 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás partes"
 },
 {
  "CNKEY": "821300000080",
  "CN": "8213 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tijeras y sus hojas"
 },
 {
  "CNKEY": "821400000080",
  "CN": "8214",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás artículos de cuchillería (por ejemplo: máquinas de cortar el pelo o de esquilar, cuchillas de picar carne, tajaderas de carnicería o cocina y cortapapeles); herramientas y juegos de herramientas de manicura o de pedicura (incluidas las limas para uñas)"
 },
 {
  "CNKEY": "821410000080",
  "CN": "8214 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cortapapeles, abrecartas, raspadores, sacapuntas y sus cuchillas"
 },
 {
  "CNKEY": "821420000080",
  "CN": "8214 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Herramientas y juegos de herramientas de manicura o pedicura (incluidas las limas para uñas)"
 },
 {
  "CNKEY": "821490000080",
  "CN": "8214 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "821500000080",
  "CN": "8215",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cucharas, tenedores, cucharones, espumaderas, palas para tarta, cuchillos para pescado o mantequilla (manteca), pinzas para azúcar y artículos similares"
 },
 {
  "CNKEY": "821510000080",
  "CN": "8215 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Surtidos que contengan por lo menos un objeto plateado, dorado o platinado"
 },
 {
  "CNKEY": "821510200080",
  "CN": "8215 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan únicamente objetos plateados, dorados o platinados"
 },
 {
  "CNKEY": "821510300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "821510300080",
  "CN": "8215 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "821510800080",
  "CN": "8215 10 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "821520000080",
  "CN": "8215 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás surtidos"
 },
 {
  "CNKEY": "821520100080",
  "CN": "8215 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "821520900080",
  "CN": "8215 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "821591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "821591000080",
  "CN": "8215 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plateados, dorados o platinados"
 },
 {
  "CNKEY": "821599000080",
  "CN": "8215 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "821599100080",
  "CN": "8215 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero inoxidable"
 },
 {
  "CNKEY": "821599900080",
  "CN": "8215 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "830021000090",
  "CN": "83",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 83 - MANUFACTURAS DIVERSAS DE METAL COMÚN"
 },
 {
  "CNKEY": "830100000080",
  "CN": "8301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Candados, cerraduras y cerrojos (de llave, de combinación o eléctricos), de metal común; cierres y monturas cierre, con cerradura incorporada, de metal común; llaves de metal común para estos artículos"
 },
 {
  "CNKEY": "830110000080",
  "CN": "8301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Candados"
 },
 {
  "CNKEY": "830120000080",
  "CN": "8301 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cerraduras de los tipos utilizados en vehículos automóviles"
 },
 {
  "CNKEY": "830130000080",
  "CN": "8301 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cerraduras de los tipos utilizados en muebles"
 },
 {
  "CNKEY": "830140000080",
  "CN": "8301 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás cerraduras; cerrojos"
 },
 {
  "CNKEY": "830140110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cerraduras de los tipos utilizados para puertas de edificios"
 },
 {
  "CNKEY": "830140110080",
  "CN": "8301 40 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cerraduras de cilindro"
 },
 {
  "CNKEY": "830140190080",
  "CN": "8301 40 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "830140900080",
  "CN": "8301 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás cerraduras; cerrojos"
 },
 {
  "CNKEY": "830150000080",
  "CN": "8301 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cierres y monturas cierre, con cerradura incorporada"
 },
 {
  "CNKEY": "830160000080",
  "CN": "8301 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "830170000080",
  "CN": "8301 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Llaves presentadas aisladamente"
 },
 {
  "CNKEY": "830200000080",
  "CN": "8302",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Guarniciones, herrajes y artículos similares, de metal común, para muebles, puertas, escaleras, ventanas, persianas, carrocerías, artículos de guarnicionería, baúles, arcas, cofres y demás manufacturas de esta clase; colgadores, perchas, soportes y artículos similares, de metal común; ruedas con montura de metal común; cierrapuertas automáticos de metal común"
 },
 {
  "CNKEY": "830210000080",
  "CN": "8302 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bisagras de cualquier clase (incluidos los pernios y demás goznes)"
 },
 {
  "CNKEY": "830220000080",
  "CN": "8302 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ruedas"
 },
 {
  "CNKEY": "830230000080",
  "CN": "8302 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás guarniciones, herrajes y artículos similares, para vehículos automóviles"
 },
 {
  "CNKEY": "830241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás guarniciones, herrajes y artículos similares"
 },
 {
  "CNKEY": "830241000080",
  "CN": "8302 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para edificios"
 },
 {
  "CNKEY": "830241100080",
  "CN": "8302 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para puertas"
 },
 {
  "CNKEY": "830241500080",
  "CN": "8302 41 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para ventanas y puertas vidrieras"
 },
 {
  "CNKEY": "830241900080",
  "CN": "8302 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "830242000080",
  "CN": "8302 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, para muebles"
 },
 {
  "CNKEY": "830249000080",
  "CN": "8302 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "830250000080",
  "CN": "8302 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Colgadores, perchas, soportes y artículos similares"
 },
 {
  "CNKEY": "830260000080",
  "CN": "8302 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cierrapuertas automáticos"
 },
 {
  "CNKEY": "830300000080",
  "CN": "8303 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas de caudales, puertas blindadas y compartimientos para cámaras acorazadas, cofres y cajas de seguridad y artículos similares, de metal común"
 },
 {
  "CNKEY": "830300400080",
  "CN": "8303 00 40",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas de caudales, puertas blindadas y compartimientos para cámaras acorazadas"
 },
 {
  "CNKEY": "830300900080",
  "CN": "8303 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cofres y cajas de seguridad y artículos similares"
 },
 {
  "CNKEY": "830400000080",
  "CN": "8304 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Clasificadores, ficheros, cajas de clasificación, bandejas de correspondencia, plumeros (vasos o cajas para plumas de escribir), portasellos y material similar de oficina, de metal común (excepto los muebles de oficina de la partida 9403)"
 },
 {
  "CNKEY": "830500000080",
  "CN": "8305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mecanismos para encuadernación de hojas intercambiables o para clasificadores, sujetadores, cantoneras, clips, índices de señal y artículos similares de oficina, de metal común; grapas en tiras (por ejemplo: de oficina, tapicería o envase) de metal común"
 },
 {
  "CNKEY": "830510000080",
  "CN": "8305 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Mecanismos para encuadernación de hojas intercambiables o para clasificadores"
 },
 {
  "CNKEY": "830520000080",
  "CN": "8305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Grapas en tiras"
 },
 {
  "CNKEY": "830590000080",
  "CN": "8305 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "830600000080",
  "CN": "8306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Campanas, campanillas, gongos y artículos similares, que no sean eléctricos, de metal común; estatuillas y demás artículos de adorno, de metal común; marcos para fotografías, grabados o similares, de metal común; espejos de metal común"
 },
 {
  "CNKEY": "830610000080",
  "CN": "8306 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Campanas, campanillas, gongos y artículos similares"
 },
 {
  "CNKEY": "830621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Estatuillas y demás artículos de adorno"
 },
 {
  "CNKEY": "830621000080",
  "CN": "8306 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plateados, dorados o platinados"
 },
 {
  "CNKEY": "830629000080",
  "CN": "8306 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "830630000080",
  "CN": "8306 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Marcos para fotografías, grabados o similares; espejos"
 },
 {
  "CNKEY": "830700000080",
  "CN": "8307",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tubos flexibles de metal común, incluso con sus accesorios"
 },
 {
  "CNKEY": "830710000080",
  "CN": "8307 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De hierro o acero"
 },
 {
  "CNKEY": "830790000080",
  "CN": "8307 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De los demás metales comunes"
 },
 {
  "CNKEY": "830800000080",
  "CN": "8308",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cierres, monturas cierre, hebillas, hebillas cierre, corchetes, ganchos, anillos para ojetes y artículos similares, de metal común, de los tipos utilizados para prendas de vestir o sus complementos (accesorios), calzado, bisutería, relojes de pulsera, libros, toldos, marroquinería, talabartería, artículos de viaje o demás artículos confeccionados; remaches tubulares o con espiga hendida, de metal común; cuentas y lentejuelas, de metal común"
 },
 {
  "CNKEY": "830810000080",
  "CN": "8308 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Corchetes, ganchos y anillos para ojetes"
 },
 {
  "CNKEY": "830820000080",
  "CN": "8308 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Remaches tubulares o con espiga hendida"
 },
 {
  "CNKEY": "830890000080",
  "CN": "8308 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "830900000080",
  "CN": "8309",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tapones y tapas (incluidas las tapas corona, las tapas roscadas y los tapones vertedores), cápsulas para botellas, tapones roscados, sobretapas, precintos y demás accesorios para envases, de metal común"
 },
 {
  "CNKEY": "830910000080",
  "CN": "8309 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tapas corona"
 },
 {
  "CNKEY": "830990000080",
  "CN": "8309 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "830990100080",
  "CN": "8309 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cápsulas para taponar o para sobretaponar de plomo; cápsulas para taponar o para sobretaponar de aluminio con un diámetro superior a 21 mm"
 },
 {
  "CNKEY": "830990900080",
  "CN": "8309 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "831000000080",
  "CN": "8310 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Placas indicadoras, placas rótulo, placas de direcciones y placas similares, cifras, letras y signos diversos, de metal común (excepto los de la partida 9405)"
 },
 {
  "CNKEY": "831100000080",
  "CN": "8311",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Alambres, varillas, tubos, placas, electrodos y artículos similares, de metal común o de carburo metálico, recubiertos o rellenos de decapantes o de fundentes, para soldadura o depósito de metal o de carburo metálico; alambres y varillas, de polvo de metal común aglomerado, para la metalización por proyección"
 },
 {
  "CNKEY": "831110000080",
  "CN": "8311 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Electrodos recubiertos para soldadura de arco, de metal común"
 },
 {
  "CNKEY": "831120000080",
  "CN": "8311 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alambre «relleno» para soldadura de arco, de metal común"
 },
 {
  "CNKEY": "831130000080",
  "CN": "8311 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Varillas recubiertas y alambre «relleno» para soldar al soplete, de metal común"
 },
 {
  "CNKEY": "831190000080",
  "CN": "8311 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840011000090",
  "CN": "XVI",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XVI - MÁQUINAS Y APARATOS, MATERIAL ELÉCTRICO Y SUS PARTES; APARATOS DE GRABACIÓN O REPRODUCCIÓN DE SONIDO, APARATOS DE GRABACIÓN O REPRODUCCIÓN DE IMÁGENES Y SONIDO EN TELEVISIÓN, Y LAS PARTES Y ACCESORIOS DE ESTOS APARATOS"
 },
 {
  "CNKEY": "840021000090",
  "CN": "84",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 84 - REACTORES NUCLEARES, CALDERAS, MÁQUINAS, APARATOS Y ARTEFACTOS MECÁNICOS; PARTES DE ESTAS MÁQUINAS O APARATOS"
 },
 {
  "CNKEY": "840100000080",
  "CN": "8401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Reactores nucleares; elementos combustibles (cartuchos) sin irradiar para reactores nucleares; máquinas y aparatos para la separación isotópica"
 },
 {
  "CNKEY": "840110000080",
  "CN": "8401 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Reactores nucleares (Euratom)"
 },
 {
  "CNKEY": "840120000080",
  "CN": "8401 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la separación isotópica, y sus partes (Euratom)"
 },
 {
  "CNKEY": "840130000080",
  "CN": "8401 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "gi F\/S",
  "ES": "Elementos combustibles (cartuchos) sin irradiar (Euratom)"
 },
 {
  "CNKEY": "840140000080",
  "CN": "8401 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes de reactores nucleares (Euratom)"
 },
 {
  "CNKEY": "840200000080",
  "CN": "8402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calderas de vapor (generadores de vapor) (excepto las de calefacción central diseñadas para producir agua caliente y también vapor a baja presión); calderas denominadas «de agua sobrecalentada»"
 },
 {
  "CNKEY": "840211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calderas de vapor"
 },
 {
  "CNKEY": "840211000080",
  "CN": "8402 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Calderas acuotubulares con una producción de vapor superior a 45 t por hora"
 },
 {
  "CNKEY": "840212000080",
  "CN": "8402 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Calderas acuotubulares con una producción de vapor inferior o igual a 45 t por hora"
 },
 {
  "CNKEY": "840219000080",
  "CN": "8402 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás calderas de vapor, incluidas las calderas mixtas"
 },
 {
  "CNKEY": "840219100080",
  "CN": "8402 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Calderas de tubos de humo"
 },
 {
  "CNKEY": "840219900080",
  "CN": "8402 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "840220000080",
  "CN": "8402 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Calderas denominadas «de agua sobrecalentada»"
 },
 {
  "CNKEY": "840290000080",
  "CN": "8402 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "840300000080",
  "CN": "8403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calderas para calefacción central (excepto las de la partida 8402)"
 },
 {
  "CNKEY": "840310000080",
  "CN": "8403 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calderas"
 },
 {
  "CNKEY": "840310100080",
  "CN": "8403 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "840310900080",
  "CN": "8403 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "840390000080",
  "CN": "8403 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "840390100080",
  "CN": "8403 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "840390900080",
  "CN": "8403 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "840400000080",
  "CN": "8404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos auxiliares para las calderas de las partidas 8402 u 8403 (por ejemplo: economizadores, recalentadores, deshollinadores o recuperadores de gas); condensadores para máquinas de vapor"
 },
 {
  "CNKEY": "840410000080",
  "CN": "8404 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos auxiliares para las calderas de las partidas 8402 u 8403"
 },
 {
  "CNKEY": "840420000080",
  "CN": "8404 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Condensadores para máquinas de vapor"
 },
 {
  "CNKEY": "840490000080",
  "CN": "8404 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "840500000080",
  "CN": "8405",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Generadores de gas pobre (gas de aire) o de gas de agua, incluso con sus depuradores; generadores de acetileno y generadores similares de gases, por vía húmeda, incluso con sus depuradores"
 },
 {
  "CNKEY": "840510000080",
  "CN": "8405 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Generadores de gas pobre (gas de aire) o de gas de agua, incluso con sus depuradores; generadores de acetileno y generadores similares de gases, por vía húmeda, incluso con sus depuradores"
 },
 {
  "CNKEY": "840590000080",
  "CN": "8405 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "840600000080",
  "CN": "8406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Turbinas de vapor"
 },
 {
  "CNKEY": "840610000080",
  "CN": "8406 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Turbinas para la propulsión de barcos"
 },
 {
  "CNKEY": "840681000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás turbinas"
 },
 {
  "CNKEY": "840681000080",
  "CN": "8406 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia superior a 40 MW"
 },
 {
  "CNKEY": "840682000080",
  "CN": "8406 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 40 MW"
 },
 {
  "CNKEY": "840690000080",
  "CN": "8406 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "840690100080",
  "CN": "8406 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Álabes, paletas y rotores"
 },
 {
  "CNKEY": "840690900080",
  "CN": "8406 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840700000080",
  "CN": "8407",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Motores de émbolo (pistón) alternativo y motores rotativos, de encendido por chispa (motores de explosión)"
 },
 {
  "CNKEY": "840710000080",
  "CN": "8407 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Motores de aviación"
 },
 {
  "CNKEY": "840721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores para la propulsión de barcos"
 },
 {
  "CNKEY": "840721000080",
  "CN": "8407 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Del tipo fueraborda"
 },
 {
  "CNKEY": "840721100080",
  "CN": "8407 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada inferior o igual a 325 cm³"
 },
 {
  "CNKEY": "840721910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 325 cm³"
 },
 {
  "CNKEY": "840721910080",
  "CN": "8407 21 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 30 kW"
 },
 {
  "CNKEY": "840721990080",
  "CN": "8407 21 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 30 kW"
 },
 {
  "CNKEY": "840729000080",
  "CN": "8407 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840731000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores de émbolo (pistón) alternativo de los tipos utilizados para la propulsión de vehículos del capítulo 87"
 },
 {
  "CNKEY": "840731000080",
  "CN": "8407 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada inferior o igual a 50 cm³"
 },
 {
  "CNKEY": "840732000080",
  "CN": "8407 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 50 cm³ pero inferior o igual a 250 cm³"
 },
 {
  "CNKEY": "840732100080",
  "CN": "8407 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 50 cm³ pero inferior o igual a 125 cm³"
 },
 {
  "CNKEY": "840732900080",
  "CN": "8407 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 125 cm³ pero inferior o igual a 250 cm³"
 },
 {
  "CNKEY": "840733000080",
  "CN": "8407 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 250 cm³ pero inferior o igual a 1000 cm³"
 },
 {
  "CNKEY": "840733200080",
  "CN": "8407 33 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 250 cm³ pero inferior o igual a 500 cm³"
 },
 {
  "CNKEY": "840733800080",
  "CN": "8407 33 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 500 cm³ pero inferior o igual a 1 000 cm³"
 },
 {
  "CNKEY": "840734000080",
  "CN": "8407 34",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 1000 cm³"
 },
 {
  "CNKEY": "840734100080",
  "CN": "8407 34 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a la industria de montaje: de motocultores de la subpartida 870110, de vehículos automóviles de la partida 8703, de vehículos automóviles de la partida 8704 con motor de cilindrada inferior a 2800 cm³ o de vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "840734300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "840734300080",
  "CN": "8407 34 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "840734910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Nuevos, de cilindrada"
 },
 {
  "CNKEY": "840734910080",
  "CN": "8407 34 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 1500 cm³"
 },
 {
  "CNKEY": "840734990080",
  "CN": "8407 34 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 1500 cm³"
 },
 {
  "CNKEY": "840790000080",
  "CN": "8407 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás motores"
 },
 {
  "CNKEY": "840790100080",
  "CN": "8407 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada inferior o igual a 250 cm³"
 },
 {
  "CNKEY": "840790500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 250 cm³"
 },
 {
  "CNKEY": "840790500080",
  "CN": "8407 90 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a la industria de montaje: de motocultores de la subpartida 870110, de vehículos automóviles de la partida 8703, de vehículos automóviles de la partida 8704 con motor de cilindrada inferior a 2800 cm³ o de vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "840790800010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "840790800080",
  "CN": "8407 90 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 10 kW"
 },
 {
  "CNKEY": "840790900080",
  "CN": "8407 90 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 10 kW"
 },
 {
  "CNKEY": "840800000080",
  "CN": "8408",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Motores de émbolo (pistón) de encendido por compresión (motores diésel o semi-diésel)"
 },
 {
  "CNKEY": "840810000080",
  "CN": "8408 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores para la propulsión de barcos"
 },
 {
  "CNKEY": "840810110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Usados"
 },
 {
  "CNKEY": "840810110080",
  "CN": "8408 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810190080",
  "CN": "8408 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810230010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Nuevos, de potencia"
 },
 {
  "CNKEY": "840810230020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 50 kW"
 },
 {
  "CNKEY": "840810230080",
  "CN": "8408 10 23",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810270080",
  "CN": "8408 10 27",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 50 kW pero inferior o igual a 100 kW"
 },
 {
  "CNKEY": "840810310080",
  "CN": "8408 10 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810390080",
  "CN": "8408 10 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 100 kW pero inferior o igual a 200 kW"
 },
 {
  "CNKEY": "840810410080",
  "CN": "8408 10 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810490080",
  "CN": "8408 10 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 200 kW pero inferior o igual a 300 kW"
 },
 {
  "CNKEY": "840810510080",
  "CN": "8408 10 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810590080",
  "CN": "8408 10 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 300 kW pero inferior o igual a 500 kW"
 },
 {
  "CNKEY": "840810610080",
  "CN": "8408 10 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810690080",
  "CN": "8408 10 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810710010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 500 kW pero inferior o igual a 1000 kW"
 },
 {
  "CNKEY": "840810710080",
  "CN": "8408 10 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810790080",
  "CN": "8408 10 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 1000 kW pero inferior o igual a 5000 kW"
 },
 {
  "CNKEY": "840810810080",
  "CN": "8408 10 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810890080",
  "CN": "8408 10 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840810910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 5000 kW"
 },
 {
  "CNKEY": "840810910080",
  "CN": "8408 10 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a embarcaciones para la navegación marítima de las partidas 8901 a 8906, a los remolcadores de la subpartida 89040010 y a los barcos de guerra de la subpartida 89061000"
 },
 {
  "CNKEY": "840810990080",
  "CN": "8408 10 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "840820000080",
  "CN": "8408 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores de los tipos utilizados para la propulsión de vehículos del capítulo 87"
 },
 {
  "CNKEY": "840820100080",
  "CN": "8408 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a la industria de montaje: de motocultores de la subpartida 870110, de vehículos automóviles de la partida 8703, de vehículos automóviles de la partida 8704 con motor de cilindrada inferior a 2500 cm³ o de vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "840820310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "840820310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Para tractores agrícolas o forestales, de ruedas, de potencia"
 },
 {
  "CNKEY": "840820310080",
  "CN": "8408 20 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 50 kW"
 },
 {
  "CNKEY": "840820350080",
  "CN": "8408 20 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 50 kW pero inferior o igual a 100 kW"
 },
 {
  "CNKEY": "840820370080",
  "CN": "8408 20 37",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 100 kW"
 },
 {
  "CNKEY": "840820510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Para los demás vehículos del capítulo 87, de potencia"
 },
 {
  "CNKEY": "840820510080",
  "CN": "8408 20 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 50 kW"
 },
 {
  "CNKEY": "840820550080",
  "CN": "8408 20 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 50 kW pero inferior o igual a 100 kW"
 },
 {
  "CNKEY": "840820570080",
  "CN": "8408 20 57",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 100 kW pero inferior o igual a 200 kW"
 },
 {
  "CNKEY": "840820990080",
  "CN": "8408 20 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 200 kW"
 },
 {
  "CNKEY": "840890000080",
  "CN": "8408 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás motores"
 },
 {
  "CNKEY": "840890210080",
  "CN": "8408 90 21",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la propulsión de vehículos ferroviarios"
 },
 {
  "CNKEY": "840890270010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "840890270080",
  "CN": "8408 90 27",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "840890410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Nuevos, de potencia"
 },
 {
  "CNKEY": "840890410080",
  "CN": "8408 90 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 15 kW"
 },
 {
  "CNKEY": "840890430080",
  "CN": "8408 90 43",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 15 kW pero inferior o igual a 30 kW"
 },
 {
  "CNKEY": "840890450080",
  "CN": "8408 90 45",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 30 kW pero inferior o igual a 50 kW"
 },
 {
  "CNKEY": "840890470080",
  "CN": "8408 90 47",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 50 kW pero inferior o igual a 100 kW"
 },
 {
  "CNKEY": "840890610080",
  "CN": "8408 90 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 100 kW pero inferior o igual a 200 kW"
 },
 {
  "CNKEY": "840890650080",
  "CN": "8408 90 65",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 200 kW pero inferior o igual a 300 kW"
 },
 {
  "CNKEY": "840890670080",
  "CN": "8408 90 67",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 300 kW pero inferior o igual a 500 kW"
 },
 {
  "CNKEY": "840890810080",
  "CN": "8408 90 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 500 kW pero inferior o igual a 1000 kW"
 },
 {
  "CNKEY": "840890850080",
  "CN": "8408 90 85",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 1000 kW pero inferior o igual a 5000 kW"
 },
 {
  "CNKEY": "840890890080",
  "CN": "8408 90 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 5000 kW"
 },
 {
  "CNKEY": "840900000080",
  "CN": "8409",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes identificables como destinadas, exclusiva o principalmente, a los motores de las partidas 8407 u 8408"
 },
 {
  "CNKEY": "840910000080",
  "CN": "8409 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De motores de aviación"
 },
 {
  "CNKEY": "840991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "840991000080",
  "CN": "8409 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Identificables como destinadas, exclusiva o principalmente, a los motores de émbolo (pistón) de encendido por chispa"
 },
 {
  "CNKEY": "840999000080",
  "CN": "8409 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841000000080",
  "CN": "8410",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Turbinas hidráulicas, ruedas hidráulicas y sus reguladores"
 },
 {
  "CNKEY": "841011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Turbinas y ruedas hidráulicas"
 },
 {
  "CNKEY": "841011000080",
  "CN": "8410 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 1000 kW"
 },
 {
  "CNKEY": "841012000080",
  "CN": "8410 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia superior a 1000 kW pero inferior o igual a 10000 kW"
 },
 {
  "CNKEY": "841013000080",
  "CN": "8410 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia superior a 10000 kW"
 },
 {
  "CNKEY": "841090000080",
  "CN": "8410 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes, incluidos los reguladores"
 },
 {
  "CNKEY": "841100000080",
  "CN": "8411",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Turborreactores, turbopropulsores y demás turbinas de gas"
 },
 {
  "CNKEY": "841111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Turborreactores"
 },
 {
  "CNKEY": "841111000080",
  "CN": "8411 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De empuje inferior o igual a 25 kN"
 },
 {
  "CNKEY": "841112000080",
  "CN": "8411 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De empuje superior a 25 kN"
 },
 {
  "CNKEY": "841112100080",
  "CN": "8411 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De empuje superior a 25 kN pero inferior o igual a 44 kN"
 },
 {
  "CNKEY": "841112300080",
  "CN": "8411 12 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De empuje superior a 44 kN pero inferior o igual a 132 kN"
 },
 {
  "CNKEY": "841112800080",
  "CN": "8411 12 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De empuje superior a 132 kN"
 },
 {
  "CNKEY": "841121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Turbopropulsores"
 },
 {
  "CNKEY": "841121000080",
  "CN": "8411 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 1100 kW"
 },
 {
  "CNKEY": "841122000080",
  "CN": "8411 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 1100 kW"
 },
 {
  "CNKEY": "841122200080",
  "CN": "8411 22 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 1100 kW pero inferior o igual a 3730 kW"
 },
 {
  "CNKEY": "841122800080",
  "CN": "8411 22 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 3730 kW"
 },
 {
  "CNKEY": "841181000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás turbinas de gas"
 },
 {
  "CNKEY": "841181000080",
  "CN": "8411 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 5000 kW"
 },
 {
  "CNKEY": "841182000080",
  "CN": "8411 82",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 5000 kW"
 },
 {
  "CNKEY": "841182200080",
  "CN": "8411 82 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 5000 kW pero inferior o igual a 20000 kW"
 },
 {
  "CNKEY": "841182600080",
  "CN": "8411 82 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 20000 kW pero inferior o igual a 50000 kW"
 },
 {
  "CNKEY": "841182800080",
  "CN": "8411 82 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 50000 kW"
 },
 {
  "CNKEY": "841191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "841191000080",
  "CN": "8411 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De turborreactores o de turbopropulsores"
 },
 {
  "CNKEY": "841199000080",
  "CN": "8411 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841200000080",
  "CN": "8412",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás motores y máquinas motrices"
 },
 {
  "CNKEY": "841210000080",
  "CN": "8412 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Propulsores a reacción (excepto los turborreactores)"
 },
 {
  "CNKEY": "841221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores hidráulicos"
 },
 {
  "CNKEY": "841221000080",
  "CN": "8412 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con movimiento rectilíneo (cilindros)"
 },
 {
  "CNKEY": "841221200080",
  "CN": "8412 21 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sistemas hidráulicos"
 },
 {
  "CNKEY": "841221800080",
  "CN": "8412 21 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841229000080",
  "CN": "8412 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841229200080",
  "CN": "8412 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Sistemas hidráulicos"
 },
 {
  "CNKEY": "841229810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841229810080",
  "CN": "8412 29 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Motores oleohidráulicos"
 },
 {
  "CNKEY": "841229890080",
  "CN": "8412 29 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores neumáticos"
 },
 {
  "CNKEY": "841231000080",
  "CN": "8412 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con movimiento rectilíneo (cilindros)"
 },
 {
  "CNKEY": "841239000080",
  "CN": "8412 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841280000080",
  "CN": "8412 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841280100080",
  "CN": "8412 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de vapor de agua o de otros vapores"
 },
 {
  "CNKEY": "841280800080",
  "CN": "8412 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841290000080",
  "CN": "8412 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "841290200080",
  "CN": "8412 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De propulsores a reacción distintos de los turborreactores"
 },
 {
  "CNKEY": "841290400080",
  "CN": "8412 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De motores hidráulicos"
 },
 {
  "CNKEY": "841290800080",
  "CN": "8412 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841300000080",
  "CN": "8413",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bombas para líquidos, incluso con dispositivo medidor incorporado; elevadores de líquidos"
 },
 {
  "CNKEY": "841311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bombas equipadas o diseñadas para equiparlas con dispositivo medidor"
 },
 {
  "CNKEY": "841311000080",
  "CN": "8413 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bombas para distribución de carburantes o lubricantes, de los tipos utilizados en gasolineras, estaciones de servicio o garajes"
 },
 {
  "CNKEY": "841319000080",
  "CN": "8413 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841320000080",
  "CN": "8413 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Bombas manuales (excepto las de las subpartidas 841311 u 841319)"
 },
 {
  "CNKEY": "841330000080",
  "CN": "8413 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bombas de carburante, aceite o refrigerante, para motores de encendido por chispa o compresión"
 },
 {
  "CNKEY": "841330200080",
  "CN": "8413 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bombas de inyección"
 },
 {
  "CNKEY": "841330800080",
  "CN": "8413 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841340000080",
  "CN": "8413 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Bombas para hormigón"
 },
 {
  "CNKEY": "841350000080",
  "CN": "8413 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás bombas volumétricas alternativas"
 },
 {
  "CNKEY": "841350200080",
  "CN": "8413 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conjuntos hidráulicos"
 },
 {
  "CNKEY": "841350400080",
  "CN": "8413 50 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bombas dosificadoras"
 },
 {
  "CNKEY": "841350610010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "841350610020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bombas de émbolo"
 },
 {
  "CNKEY": "841350610080",
  "CN": "8413 50 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Bombas oleohidráulicas"
 },
 {
  "CNKEY": "841350690080",
  "CN": "8413 50 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841350800080",
  "CN": "8413 50 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841360000080",
  "CN": "8413 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás bombas volumétricas rotativas"
 },
 {
  "CNKEY": "841360200080",
  "CN": "8413 60 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conjuntos hidráulicos"
 },
 {
  "CNKEY": "841360310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "841360310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bombas de engranajes"
 },
 {
  "CNKEY": "841360310080",
  "CN": "8413 60 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Bombas oleohidráulicas"
 },
 {
  "CNKEY": "841360390080",
  "CN": "8413 60 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841360610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Bombas de paletas conducidas"
 },
 {
  "CNKEY": "841360610080",
  "CN": "8413 60 61",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Bombas oleohidráulicas"
 },
 {
  "CNKEY": "841360690080",
  "CN": "8413 60 69",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841360700080",
  "CN": "8413 60 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De tornillo helicoidal"
 },
 {
  "CNKEY": "841360800080",
  "CN": "8413 60 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841370000080",
  "CN": "8413 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás bombas centrífugas"
 },
 {
  "CNKEY": "841370210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sumergibles"
 },
 {
  "CNKEY": "841370210080",
  "CN": "8413 70 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Monocelulares"
 },
 {
  "CNKEY": "841370290080",
  "CN": "8413 70 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Multicelulares"
 },
 {
  "CNKEY": "841370300080",
  "CN": "8413 70 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para calefacción central y de agua caliente"
 },
 {
  "CNKEY": "841370350010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, con tubería de impulsión de diámetro"
 },
 {
  "CNKEY": "841370350080",
  "CN": "8413 70 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 15 mm"
 },
 {
  "CNKEY": "841370450010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 15 mm"
 },
 {
  "CNKEY": "841370450080",
  "CN": "8413 70 45",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De rodetes acanalados y de canal lateral"
 },
 {
  "CNKEY": "841370510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "De rueda radial"
 },
 {
  "CNKEY": "841370510020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Monocelulares"
 },
 {
  "CNKEY": "841370510030",
  "CN": null,
  "CN_LEVEL": 9,
  "T_SU_SU": null,
  "ES": "De flujo sencillo"
 },
 {
  "CNKEY": "841370510080",
  "CN": "8413 70 51",
  "CN_LEVEL": 10,
  "T_SU_SU": "p\/st",
  "ES": "Monobloques"
 },
 {
  "CNKEY": "841370590080",
  "CN": "8413 70 59",
  "CN_LEVEL": 10,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841370650080",
  "CN": "8413 70 65",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "De flujo múltiple"
 },
 {
  "CNKEY": "841370750080",
  "CN": "8413 70 75",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Multicelulares"
 },
 {
  "CNKEY": "841370810010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás bombas centrífugas"
 },
 {
  "CNKEY": "841370810080",
  "CN": "8413 70 81",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Monocelulares"
 },
 {
  "CNKEY": "841370890080",
  "CN": "8413 70 89",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Multicelulares"
 },
 {
  "CNKEY": "841381000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás bombas; elevadores de líquidos"
 },
 {
  "CNKEY": "841381000080",
  "CN": "8413 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bombas"
 },
 {
  "CNKEY": "841382000080",
  "CN": "8413 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Elevadores de líquidos"
 },
 {
  "CNKEY": "841391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "841391000080",
  "CN": "8413 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bombas"
 },
 {
  "CNKEY": "841392000080",
  "CN": "8413 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De elevadores de líquidos"
 },
 {
  "CNKEY": "841400000080",
  "CN": "8414",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bombas de aire o de vacío, compresores de aire u otros gases y ventiladores; campanas aspirantes para extracción o reciclado, con ventilador incorporado, incluso con filtro; recintos de seguridad biológica herméticos a gases, incluso con filtro"
 },
 {
  "CNKEY": "841410000080",
  "CN": "8414 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bombas de vacío"
 },
 {
  "CNKEY": "841410150080",
  "CN": "8414 10 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en la fabricación de semiconductores o, exclusiva o principalmente, en la fabricación de dispositivos de visualización de pantalla plana"
 },
 {
  "CNKEY": "841410250010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "841410250080",
  "CN": "8414 10 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De pistón rotativo, de paletas, moleculares y bombas «Roots»"
 },
 {
  "CNKEY": "841410810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "841410810080",
  "CN": "8414 10 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De difusión, criostáticas y de absorción"
 },
 {
  "CNKEY": "841410890080",
  "CN": "8414 10 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841420000080",
  "CN": "8414 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bombas de aire, de mano o pedal"
 },
 {
  "CNKEY": "841420200080",
  "CN": "8414 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bombas de mano para bicicletas"
 },
 {
  "CNKEY": "841420800080",
  "CN": "8414 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841430000080",
  "CN": "8414 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compresores de los tipos utilizados en los equipos frigoríficos"
 },
 {
  "CNKEY": "841430200080",
  "CN": "8414 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 0,4 kW"
 },
 {
  "CNKEY": "841430810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 0,4 kW"
 },
 {
  "CNKEY": "841430810080",
  "CN": "8414 30 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Herméticos o semiherméticos"
 },
 {
  "CNKEY": "841430890080",
  "CN": "8414 30 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841440000080",
  "CN": "8414 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compresores de aire montados en chasis remolcable de ruedas"
 },
 {
  "CNKEY": "841440100080",
  "CN": "8414 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De un caudal por minuto inferior o igual a 2 m³"
 },
 {
  "CNKEY": "841440900080",
  "CN": "8414 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De un caudal por minuto superior a 2 m³"
 },
 {
  "CNKEY": "841451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ventiladores"
 },
 {
  "CNKEY": "841451000080",
  "CN": "8414 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Ventiladores de mesa, suelo, pared, cielo raso, techo o ventana, con motor eléctrico incorporado de potencia inferior o igual a 125 W"
 },
 {
  "CNKEY": "841459000080",
  "CN": "8414 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841459150080",
  "CN": "8414 59 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Ventiladores de los tipos utilizados, exclusiva o principalmente, para enfriar microprocesadores, aparatos de telecomunicación, o máquinas automáticas para tratamiento o procesamiento de datos y sus unidades"
 },
 {
  "CNKEY": "841459250010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841459250080",
  "CN": "8414 59 25",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Ventiladores axiales"
 },
 {
  "CNKEY": "841459350080",
  "CN": "8414 59 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Ventiladores centrífugos"
 },
 {
  "CNKEY": "841459950080",
  "CN": "8414 59 95",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841460000080",
  "CN": "8414 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Campanas aspirantes en las que el mayor lado horizontal sea inferior o igual a 120 cm"
 },
 {
  "CNKEY": "841470000080",
  "CN": "8414 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Recintos de seguridad biológica herméticos a gases"
 },
 {
  "CNKEY": "841480000080",
  "CN": "8414 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841480110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Turbocompresores"
 },
 {
  "CNKEY": "841480110080",
  "CN": "8414 80 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Monocelulares"
 },
 {
  "CNKEY": "841480190080",
  "CN": "8414 80 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Multicelulares"
 },
 {
  "CNKEY": "841480220010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Compresores volumétricos alternativos que puedan suministrar una sobrepresión"
 },
 {
  "CNKEY": "841480220020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 15 bar, con un caudal por hora"
 },
 {
  "CNKEY": "841480220080",
  "CN": "8414 80 22",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 60 m³"
 },
 {
  "CNKEY": "841480280080",
  "CN": "8414 80 28",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 60 m³"
 },
 {
  "CNKEY": "841480510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Superior a 15 bar, con un caudal por hora"
 },
 {
  "CNKEY": "841480510080",
  "CN": "8414 80 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 120 m³"
 },
 {
  "CNKEY": "841480590080",
  "CN": "8414 80 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 120 m³"
 },
 {
  "CNKEY": "841480730010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Compresores volumétricos rotativos"
 },
 {
  "CNKEY": "841480730080",
  "CN": "8414 80 73",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De un solo eje"
 },
 {
  "CNKEY": "841480750010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De varios ejes"
 },
 {
  "CNKEY": "841480750080",
  "CN": "8414 80 75",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De tornillo"
 },
 {
  "CNKEY": "841480780080",
  "CN": "8414 80 78",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841480800080",
  "CN": "8414 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841490000080",
  "CN": "8414 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "841500000080",
  "CN": "8415",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para acondicionamiento de aire que comprenden un ventilador con motor y los dispositivos adecuados para modificar la temperatura y la humedad, aunque no regulen separadamente el grado higrométrico"
 },
 {
  "CNKEY": "841510000080",
  "CN": "8415 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los tipos diseñados para ser montados sobre una ventana, pared, techo o suelo, formando un solo cuerpo o del tipo sistema de elementos separados («split-system»)"
 },
 {
  "CNKEY": "841510100080",
  "CN": "8415 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Formando un solo cuerpo"
 },
 {
  "CNKEY": "841510900080",
  "CN": "8415 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Del tipo sistema de elementos separados («split-system»)"
 },
 {
  "CNKEY": "841520000080",
  "CN": "8415 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en vehículos automóviles para sus ocupantes"
 },
 {
  "CNKEY": "841581000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841581000080",
  "CN": "8415 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con equipo de enfriamiento y válvula de inversión del ciclo térmico (bombas de calor reversibles)"
 },
 {
  "CNKEY": "841582000080",
  "CN": "8415 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, con equipo de enfriamiento"
 },
 {
  "CNKEY": "841583000080",
  "CN": "8415 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin equipo de enfriamiento"
 },
 {
  "CNKEY": "841590000080",
  "CN": "8415 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "841600000080",
  "CN": "8416",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Quemadores para la alimentación de hogares, de combustibles líquidos o sólidos pulverizados o de gases; alimentadores mecánicos de hogares, parrillas mecánicas, descargadores mecánicos de cenizas y demás dispositivos mecánicos auxiliares empleados en hogares"
 },
 {
  "CNKEY": "841610000080",
  "CN": "8416 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Quemadores de combustibles líquidos"
 },
 {
  "CNKEY": "841610100080",
  "CN": "8416 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con dispositivo automático de control, montado"
 },
 {
  "CNKEY": "841610900080",
  "CN": "8416 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841620000080",
  "CN": "8416 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás quemadores, incluidos los mixtos"
 },
 {
  "CNKEY": "841620100080",
  "CN": "8416 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De gas exclusivamente, monobloques, con ventilador incorporado y dispositivo de control"
 },
 {
  "CNKEY": "841620200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841620200080",
  "CN": "8416 20 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Quemadores mixtos"
 },
 {
  "CNKEY": "841620800080",
  "CN": "8416 20 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841630000080",
  "CN": "8416 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Alimentadores mecánicos de hogares, parrillas mecánicas, descargadores mecánicos de cenizas y demás dispositivos mecánicos auxiliares empleados en hogares"
 },
 {
  "CNKEY": "841690000080",
  "CN": "8416 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "841700000080",
  "CN": "8417",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hornos industriales o de laboratorio, incluidos los incineradores, que no sean eléctricos"
 },
 {
  "CNKEY": "841710000080",
  "CN": "8417 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hornos para tostación, fusión u otros tratamientos térmicos de los minerales metalíferos (incluidas las piritas) o de los metales"
 },
 {
  "CNKEY": "841720000080",
  "CN": "8417 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hornos de panadería, pastelería o galletería"
 },
 {
  "CNKEY": "841720100080",
  "CN": "8417 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hornos de túnel"
 },
 {
  "CNKEY": "841720900080",
  "CN": "8417 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841780000080",
  "CN": "8417 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841780300080",
  "CN": "8417 80 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Hornos para la cocción de productos cerámicos"
 },
 {
  "CNKEY": "841780500080",
  "CN": "8417 80 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Hornos para la cocción de cemento, vidrio o productos químicos"
 },
 {
  "CNKEY": "841780700080",
  "CN": "8417 80 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841790000080",
  "CN": "8417 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "841800000080",
  "CN": "8418",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Refrigeradores, congeladores y demás material, máquinas y aparatos para producción de frío, aunque no sean eléctricos; bombas de calor (excepto las máquinas y aparatos para acondicionamiento de aire de la partida 8415)"
 },
 {
  "CNKEY": "841810000080",
  "CN": "8418 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Combinaciones de refrigerador y congelador con puertas o cajones exteriores separados, o de combinaciones de estos elementos"
 },
 {
  "CNKEY": "841810200080",
  "CN": "8418 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad superior a 340 l"
 },
 {
  "CNKEY": "841810800080",
  "CN": "8418 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Refrigeradores domésticos"
 },
 {
  "CNKEY": "841821000080",
  "CN": "8418 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De compresión"
 },
 {
  "CNKEY": "841821100080",
  "CN": "8418 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad superior a 340 l"
 },
 {
  "CNKEY": "841821510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841821510080",
  "CN": "8418 21 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Modelos de mesa"
 },
 {
  "CNKEY": "841821590080",
  "CN": "8418 21 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De empotrar"
 },
 {
  "CNKEY": "841821910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás, de capacidad"
 },
 {
  "CNKEY": "841821910080",
  "CN": "8418 21 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 250 l"
 },
 {
  "CNKEY": "841821990080",
  "CN": "8418 21 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 250 l pero inferior o igual a 340 l"
 },
 {
  "CNKEY": "841829000080",
  "CN": "8418 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841830000080",
  "CN": "8418 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Congeladores horizontales del tipo arcón (cofre), de capacidad inferior o igual a 800 l"
 },
 {
  "CNKEY": "841830200080",
  "CN": "8418 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad inferior o igual a 400 l"
 },
 {
  "CNKEY": "841830800080",
  "CN": "8418 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad superior a 400 l pero inferior o igual a 800 l"
 },
 {
  "CNKEY": "841840000080",
  "CN": "8418 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Congeladores verticales del tipo armario, de capacidad inferior o igual a 900 l"
 },
 {
  "CNKEY": "841840200080",
  "CN": "8418 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad inferior o igual a 250 l"
 },
 {
  "CNKEY": "841840800080",
  "CN": "8418 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad superior a 250 l pero inferior o igual a 900 l"
 },
 {
  "CNKEY": "841850000080",
  "CN": "8418 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás muebles [armarios, arcones (cofres), vitrinas, mostradores y similares] para la conservación y exposición de los productos, que incorporen un equipo para producción de frío"
 },
 {
  "CNKEY": "841850110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Muebles vitrina y muebles mostrador frigorífico (con grupo frigorífico o evaporador incorporado)"
 },
 {
  "CNKEY": "841850110080",
  "CN": "8418 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para productos congelados"
 },
 {
  "CNKEY": "841850190080",
  "CN": "8418 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841850900080",
  "CN": "8418 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás muebles frigoríficos"
 },
 {
  "CNKEY": "841861000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás materiales, máquinas y aparatos para producción de frío; bombas de calor"
 },
 {
  "CNKEY": "841861000080",
  "CN": "8418 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bombas de calor, excepto las máquinas y aparatos para acondicionamiento de aire de la partida 8415"
 },
 {
  "CNKEY": "841869000080",
  "CN": "8418 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "841891000080",
  "CN": "8418 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muebles diseñados para incorporarles un equipo para producción de frío"
 },
 {
  "CNKEY": "841899000080",
  "CN": "8418 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "841899100080",
  "CN": "8418 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Evaporadores y condensadores (excepto los de aparatos para uso doméstico)"
 },
 {
  "CNKEY": "841899900080",
  "CN": "8418 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "841900000080",
  "CN": "8419",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos, dispositivos o equipos de laboratorio, aunque se calienten eléctricamente (excepto los hornos y demás aparatos de la partida 8514), para el tratamiento de materias mediante operaciones que impliquen un cambio de temperatura, tales como calentamiento, cocción, torrefacción, destilación, rectificación, esterilización, pasteurización, baño de vapor de agua, secado, evaporación, vaporización, condensación o enfriamiento (excepto los aparatos domésticos); calentadores de agua de calentamiento instantáneo o de acumulación (excepto los eléctricos)"
 },
 {
  "CNKEY": "841911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calentadores de agua de calentamiento instantáneo o de acumulación (excepto los eléctricos)"
 },
 {
  "CNKEY": "841911000080",
  "CN": "8419 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De calentamiento instantáneo, de gas"
 },
 {
  "CNKEY": "841912000080",
  "CN": "8419 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Calentadores solares de agua"
 },
 {
  "CNKEY": "841919000080",
  "CN": "8419 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841920000080",
  "CN": "8419 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Esterilizadores médicos, quirúrgicos o de laboratorio"
 },
 {
  "CNKEY": "841933000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Secadores"
 },
 {
  "CNKEY": "841933000080",
  "CN": "8419 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de liofilización, de criodesecación y secadores de pulverización"
 },
 {
  "CNKEY": "841934000080",
  "CN": "8419 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, para productos agrícolas"
 },
 {
  "CNKEY": "841935000080",
  "CN": "8419 35 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, para madera, pasta para papel, papel o cartón"
 },
 {
  "CNKEY": "841939000080",
  "CN": "8419 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841940000080",
  "CN": "8419 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos de destilación o rectificación"
 },
 {
  "CNKEY": "841950000080",
  "CN": "8419 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Intercambiadores de calor"
 },
 {
  "CNKEY": "841950200080",
  "CN": "8419 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Intercambiadores de calor fabricados con polímeros fluorados, con orificios para los tubos de entrada y de salida de un diámetro interior inferior o igual a 3 cm"
 },
 {
  "CNKEY": "841950800080",
  "CN": "8419 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841960000080",
  "CN": "8419 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos y dispositivos para licuefacción de aire u otros gases"
 },
 {
  "CNKEY": "841981000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos y dispositivos"
 },
 {
  "CNKEY": "841981000080",
  "CN": "8419 81",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para la preparación de bebidas calientes o la cocción o calentamiento de alimentos"
 },
 {
  "CNKEY": "841981200080",
  "CN": "8419 81 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cafeteras y demás aparatos para la preparación de café y de otras bebidas calientes"
 },
 {
  "CNKEY": "841981800080",
  "CN": "8419 81 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841989000080",
  "CN": "8419 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "841989100080",
  "CN": "8419 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos y dispositivos de enfriamiento por retorno de agua, en los que el intercambio térmico no se realice a través de una pared"
 },
 {
  "CNKEY": "841989300080",
  "CN": "8419 89 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos y dispositivos para el metalizado al vacío"
 },
 {
  "CNKEY": "841989980080",
  "CN": "8419 89 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "841990000080",
  "CN": "8419 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "841990150080",
  "CN": "8419 90 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De esterilizadores de la subpartida 84192000"
 },
 {
  "CNKEY": "841990850080",
  "CN": "8419 90 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842000000080",
  "CN": "8420",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calandrias y laminadores (excepto para metal o vidrio), y cilindros para estas máquinas"
 },
 {
  "CNKEY": "842010000080",
  "CN": "8420 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calandrias y laminadores"
 },
 {
  "CNKEY": "842010100080",
  "CN": "8420 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en la industria textil"
 },
 {
  "CNKEY": "842010300080",
  "CN": "8420 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en la industria del papel"
 },
 {
  "CNKEY": "842010810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842010810080",
  "CN": "8420 10 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminadores de rodillos de los tipos utilizados, exclusiva o principalmente, en la fabricación de sustratos de circuitos impresos o circuitos impresos"
 },
 {
  "CNKEY": "842010890080",
  "CN": "8420 10 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "842091000080",
  "CN": "8420 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cilindros"
 },
 {
  "CNKEY": "842091100080",
  "CN": "8420 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "842091800080",
  "CN": "8420 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842099000080",
  "CN": "8420 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842100000080",
  "CN": "8421",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Centrifugadoras, incluidas las secadoras centrífugas; aparatos para filtrar o depurar líquidos o gases"
 },
 {
  "CNKEY": "842111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Centrifugadoras, incluidas las secadoras centrífugas"
 },
 {
  "CNKEY": "842111000080",
  "CN": "8421 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Desnatadoras (descremadoras)"
 },
 {
  "CNKEY": "842112000080",
  "CN": "8421 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Secadoras de ropa"
 },
 {
  "CNKEY": "842119000080",
  "CN": "8421 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "842119200080",
  "CN": "8421 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Centrifugadoras del tipo de las utilizadas en los laboratorios"
 },
 {
  "CNKEY": "842119700080",
  "CN": "8421 19 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos para filtrar o depurar líquidos"
 },
 {
  "CNKEY": "842121000080",
  "CN": "8421 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para filtrar o depurar agua"
 },
 {
  "CNKEY": "842122000080",
  "CN": "8421 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para filtrar o depurar las demás bebidas"
 },
 {
  "CNKEY": "842123000080",
  "CN": "8421 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para filtrar lubricantes o carburantes en los motores de encendido por chispa o compresión"
 },
 {
  "CNKEY": "842129000080",
  "CN": "8421 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842129200080",
  "CN": "8421 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Fabricados con polímeros fluorados y dotados de una membrana para filtrar o depurar de espesor inferior o igual a 140 micras"
 },
 {
  "CNKEY": "842129800080",
  "CN": "8421 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos para filtrar o depurar gases"
 },
 {
  "CNKEY": "842131000080",
  "CN": "8421 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Filtros de entrada de aire para motores de encendido por chispa o compresión"
 },
 {
  "CNKEY": "842132000080",
  "CN": "8421 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Convertidores catalíticos y filtros de partículas, incluso combinados, para purificar o filtrar gases de escape de motores de encendido por chispa o compresión"
 },
 {
  "CNKEY": "842139000080",
  "CN": "8421 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842139150080",
  "CN": "8421 39 15",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Con carcasa de acero inoxidable y orificios para los tubos de entrada y de salida de un diámetro interior inferior o igual a 1,3 cm"
 },
 {
  "CNKEY": "842139250010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842139250080",
  "CN": "8421 39 25",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Aparatos para filtrar o depurar aire"
 },
 {
  "CNKEY": "842139350010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Aparatos para filtrar o depurar otros gases"
 },
 {
  "CNKEY": "842139350080",
  "CN": "8421 39 35",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Por procedimiento catalítico"
 },
 {
  "CNKEY": "842139850080",
  "CN": "8421 39 85",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "842191000080",
  "CN": "8421 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De centrifugadoras, incluidas las de secadoras centrífugas"
 },
 {
  "CNKEY": "842199000080",
  "CN": "8421 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "842199100080",
  "CN": "8421 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes de maquinaria y aparatos de las  subpartidas 84212920 o 84213915"
 },
 {
  "CNKEY": "842199900080",
  "CN": "8421 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842200000080",
  "CN": "8422",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para lavar vajilla; máquinas y aparatos para limpiar o secar botellas o demás recipientes; máquinas y aparatos para llenar, cerrar, tapar, taponar o etiquetar botellas, botes o latas, cajas, sacos (bolsas) o demás continentes; máquinas y aparatos de capsular botellas, tarros, tubos y continentes análogos; las demás máquinas y aparatos para empaquetar o envolver mercancías (incluidas las de envolver con película termorretráctil); máquinas y aparatos para gasear bebidas"
 },
 {
  "CNKEY": "842211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas para lavar vajilla"
 },
 {
  "CNKEY": "842211000080",
  "CN": "8422 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De tipo doméstico"
 },
 {
  "CNKEY": "842219000080",
  "CN": "8422 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842220000080",
  "CN": "8422 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para limpiar o secar botellas o demás recipientes"
 },
 {
  "CNKEY": "842230000080",
  "CN": "8422 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para llenar, cerrar, tapar, taponar o etiquetar botellas, botes o latas, cajas, sacos (bolsas) o demás continentes; máquinas y aparatos de capsular botellas, tarros, tubos y continentes análogos; máquinas y aparatos para gasear bebidas"
 },
 {
  "CNKEY": "842240000080",
  "CN": "8422 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos para empaquetar o envolver mercancías (incluidas las de envolver con película termorretráctil)"
 },
 {
  "CNKEY": "842290000080",
  "CN": "8422 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "842290100080",
  "CN": "8422 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De máquinas para lavar vajilla"
 },
 {
  "CNKEY": "842290900080",
  "CN": "8422 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842300000080",
  "CN": "8423",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos e instrumentos de pesar, incluidas las básculas y balanzas para comprobar o contar piezas fabricadas (excepto las balanzas sensibles a un peso inferior o igual a 5 cg); pesas para toda clase de básculas o balanzas"
 },
 {
  "CNKEY": "842310000080",
  "CN": "8423 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para pesar personas, incluidos los pesabebés; balanzas domésticas"
 },
 {
  "CNKEY": "842310100080",
  "CN": "8423 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Balanzas domésticas"
 },
 {
  "CNKEY": "842310900080",
  "CN": "8423 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842320000080",
  "CN": "8423 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Básculas y balanzas para pesada continua sobre transportador"
 },
 {
  "CNKEY": "842320100080",
  "CN": "8423 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que determinen el peso por medios electrónicos"
 },
 {
  "CNKEY": "842320900080",
  "CN": "8423 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842330000080",
  "CN": "8423 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Básculas y balanzas para pesada constante, incluidas las de descargar pesos determinados en sacos (bolsas) u otros recipientes, así como las dosificadoras de tolva"
 },
 {
  "CNKEY": "842330100080",
  "CN": "8423 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que determinen el peso por medios electrónicos"
 },
 {
  "CNKEY": "842330900080",
  "CN": "8423 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842381000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos de pesar"
 },
 {
  "CNKEY": "842381000080",
  "CN": "8423 81",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con capacidad inferior o igual a 30 kg"
 },
 {
  "CNKEY": "842381210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Que determinen el peso por medios electrónicos"
 },
 {
  "CNKEY": "842381210080",
  "CN": "8423 81 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos de control por referencia a un peso predeterminado, de funcionamiento automático, incluidas las clasificadoras ponderales"
 },
 {
  "CNKEY": "842381230080",
  "CN": "8423 81 23",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos y aparatos de pesar y etiquetar productos preenvasados"
 },
 {
  "CNKEY": "842381250080",
  "CN": "8423 81 25",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Balanzas de tienda"
 },
 {
  "CNKEY": "842381290080",
  "CN": "8423 81 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842381800080",
  "CN": "8423 81 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842382000080",
  "CN": "8423 82",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con capacidad superior a 30 kg pero inferior o igual a 5000 kg"
 },
 {
  "CNKEY": "842382200080",
  "CN": "8423 82 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Que determinen el peso por medios electrónicos, excepto las máquinas para pesar vehículos automóviles"
 },
 {
  "CNKEY": "842382810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842382810080",
  "CN": "8423 82 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos de control por referencia a un peso determinado, de funcionamiento automático, incluidas las clasificadoras ponderales"
 },
 {
  "CNKEY": "842382890080",
  "CN": "8423 82 89",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842389000080",
  "CN": "8423 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842389200080",
  "CN": "8423 89 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Que determinen el peso por medios electrónicos"
 },
 {
  "CNKEY": "842389800080",
  "CN": "8423 89 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842390000080",
  "CN": "8423 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pesas para toda clase de básculas o balanzas; partes de aparatos o instrumentos de pesar"
 },
 {
  "CNKEY": "842390100080",
  "CN": "8423 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes de aparatos o instrumentos de pesar de las subpartidas 84232010, 84233010, 84238121, 84238123, 84238125, 84238129, 84238220 o 84238920"
 },
 {
  "CNKEY": "842390900080",
  "CN": "8423 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842400000080",
  "CN": "8424",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos mecánicos, incluso manuales, para proyectar, dispersar o pulverizar materias líquidas o en polvo; extintores, incluso cargados; pistolas aerográficas y aparatos similares; máquinas y aparatos de chorro de arena o de vapor y aparatos de chorro similares"
 },
 {
  "CNKEY": "842410000080",
  "CN": "8424 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Extintores, incluso cargados"
 },
 {
  "CNKEY": "842420000080",
  "CN": "8424 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pistolas aerográficas y aparatos similares"
 },
 {
  "CNKEY": "842430000080",
  "CN": "8424 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos de chorro de arena o de vapor y aparatos de chorro similares"
 },
 {
  "CNKEY": "842430010010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aparatos para limpieza con agua, con motor incorporado"
 },
 {
  "CNKEY": "842430010080",
  "CN": "8424 30 01",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con dispositivos de calentamiento"
 },
 {
  "CNKEY": "842430080080",
  "CN": "8424 30 08",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842430100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "842430100080",
  "CN": "8424 30 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De aire comprimido"
 },
 {
  "CNKEY": "842430900080",
  "CN": "8424 30 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pulverizadores para agricultura u horticultura"
 },
 {
  "CNKEY": "842441000080",
  "CN": "8424 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pulverizadores portátiles"
 },
 {
  "CNKEY": "842449000080",
  "CN": "8424 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842449100080",
  "CN": "8424 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pulverizadores y espolvoreadores concebidos para que los lleve o arrastre un tractor"
 },
 {
  "CNKEY": "842449900080",
  "CN": "8424 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842482000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "842482000080",
  "CN": "8424 82",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para agricultura u horticultura"
 },
 {
  "CNKEY": "842482100080",
  "CN": "8424 82 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos de riego"
 },
 {
  "CNKEY": "842482900080",
  "CN": "8424 82 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842489000080",
  "CN": "8424 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842489400080",
  "CN": "8424 89 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos mecánicos para proyectar, dispersar o pulverizar de los tipos utilizados, exclusiva o principalmente, para la fabricación de circuitos impresos o estructuras de circuitos impresos"
 },
 {
  "CNKEY": "842489700080",
  "CN": "8424 89 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842490000080",
  "CN": "8424 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "842490200080",
  "CN": "8424 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aparatos mecánicos de la subpartida 84248940"
 },
 {
  "CNKEY": "842490800080",
  "CN": "8424 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842500000080",
  "CN": "8425",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Polipastos; tornos y cabrestantes; gatos"
 },
 {
  "CNKEY": "842511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Polipastos"
 },
 {
  "CNKEY": "842511000080",
  "CN": "8425 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con motor eléctrico"
 },
 {
  "CNKEY": "842519000080",
  "CN": "8425 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tornos; cabrestantes"
 },
 {
  "CNKEY": "842531000080",
  "CN": "8425 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con motor eléctrico"
 },
 {
  "CNKEY": "842539000080",
  "CN": "8425 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gatos"
 },
 {
  "CNKEY": "842541000080",
  "CN": "8425 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Elevadores fijos para vehículos, de los tipos utilizados en talleres"
 },
 {
  "CNKEY": "842542000080",
  "CN": "8425 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás gatos hidráulicos"
 },
 {
  "CNKEY": "842549000080",
  "CN": "8425 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842600000080",
  "CN": "8426",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grúas y aparatos de elevación sobre cable aéreo; puentes rodantes, pórticos de descarga o manipulación, puentes grúa, carretillas puente y carretillas grúa"
 },
 {
  "CNKEY": "842611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Puentes (incluidas las vigas) rodantes, pórticos puentes grúa y carretillas puente"
 },
 {
  "CNKEY": "842611000080",
  "CN": "8426 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Puentes (incluidas las vigas) rodantes, sobre soporte fijo"
 },
 {
  "CNKEY": "842612000080",
  "CN": "8426 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pórticos móviles sobre neumáticos y carretillas puente"
 },
 {
  "CNKEY": "842619000080",
  "CN": "8426 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842620000080",
  "CN": "8426 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Grúas de torre"
 },
 {
  "CNKEY": "842630000080",
  "CN": "8426 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Grúas de pórtico"
 },
 {
  "CNKEY": "842641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos, autopropulsados"
 },
 {
  "CNKEY": "842641000080",
  "CN": "8426 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sobre neumáticos"
 },
 {
  "CNKEY": "842649000080",
  "CN": "8426 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "842691000080",
  "CN": "8426 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Diseñados para montarlos sobre vehículos de carretera"
 },
 {
  "CNKEY": "842691100080",
  "CN": "8426 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Grúas hidráulicas para la carga o descarga del vehículo"
 },
 {
  "CNKEY": "842691900080",
  "CN": "8426 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842699000080",
  "CN": "8426 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842700000080",
  "CN": "8427",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carretillas apiladoras; las demás carretillas de manipulación con dispositivo de elevación incorporado"
 },
 {
  "CNKEY": "842710000080",
  "CN": "8427 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carretillas autopropulsadas con motor eléctrico"
 },
 {
  "CNKEY": "842710100080",
  "CN": "8427 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que eleven a una altura superior o igual a 1 m"
 },
 {
  "CNKEY": "842710900080",
  "CN": "8427 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842720000080",
  "CN": "8427 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás carretillas autopropulsadas"
 },
 {
  "CNKEY": "842720110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que eleven a una altura superior o igual a 1 m"
 },
 {
  "CNKEY": "842720110080",
  "CN": "8427 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Carretillas elevadoras todo terreno"
 },
 {
  "CNKEY": "842720190080",
  "CN": "8427 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842720900080",
  "CN": "8427 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842790000080",
  "CN": "8427 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás carretillas"
 },
 {
  "CNKEY": "842800000080",
  "CN": "8428",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos de elevación, carga, descarga o manipulación (por ejemplo: ascensores, escaleras mecánicas, transportadores, teleféricos)"
 },
 {
  "CNKEY": "842810000080",
  "CN": "8428 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ascensores y montacargas"
 },
 {
  "CNKEY": "842810200080",
  "CN": "8428 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "842810800080",
  "CN": "8428 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842820000080",
  "CN": "8428 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos elevadores o transportadores, neumáticos"
 },
 {
  "CNKEY": "842820200080",
  "CN": "8428 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para productos a granel"
 },
 {
  "CNKEY": "842820800080",
  "CN": "8428 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos elevadores o transportadores, de acción continua, para mercancías"
 },
 {
  "CNKEY": "842831000080",
  "CN": "8428 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Especialmente diseñados para el interior de minas u otros trabajos subterráneos"
 },
 {
  "CNKEY": "842832000080",
  "CN": "8428 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de cangilones"
 },
 {
  "CNKEY": "842833000080",
  "CN": "8428 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, de banda o correa"
 },
 {
  "CNKEY": "842839000080",
  "CN": "8428 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "842839200080",
  "CN": "8428 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Transportadores de rodillos o cilindros"
 },
 {
  "CNKEY": "842839900080",
  "CN": "8428 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842840000080",
  "CN": "8428 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Escaleras mecánicas y pasillos móviles"
 },
 {
  "CNKEY": "842860000080",
  "CN": "8428 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Teleféricos (incluidos las telesillas y los telesquís); mecanismos de tracción para funiculares"
 },
 {
  "CNKEY": "842870000080",
  "CN": "8428 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Robots industriales"
 },
 {
  "CNKEY": "842890000080",
  "CN": "8428 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "842890710010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cargadores especialmente concebidos para explotaciones agrícolas"
 },
 {
  "CNKEY": "842890710080",
  "CN": "8428 90 71",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Concebidos para montar en tractores agrícolas"
 },
 {
  "CNKEY": "842890790080",
  "CN": "8428 90 79",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842890900080",
  "CN": "8428 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842900000080",
  "CN": "8429",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Topadoras frontales (bulldozers), topadoras angulares (angledozers), niveladoras, traíllas (scrapers), palas mecánicas, excavadoras, cargadoras, palas cargadoras, compactadoras y apisonadoras (aplanadoras), autopropulsadas"
 },
 {
  "CNKEY": "842911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Topadoras frontales (bulldozers) y topadoras angulares (angledozers)"
 },
 {
  "CNKEY": "842911000080",
  "CN": "8429 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De orugas"
 },
 {
  "CNKEY": "842919000080",
  "CN": "8429 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842920000080",
  "CN": "8429 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Niveladoras"
 },
 {
  "CNKEY": "842930000080",
  "CN": "8429 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Traíllas (scrapers)"
 },
 {
  "CNKEY": "842940000080",
  "CN": "8429 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Compactadoras y apisonadoras (aplanadoras)"
 },
 {
  "CNKEY": "842940100010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Apisonadoras (aplanadoras)"
 },
 {
  "CNKEY": "842940100080",
  "CN": "8429 40 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Apisonadoras (aplanadoras) vibrantes"
 },
 {
  "CNKEY": "842940300080",
  "CN": "8429 40 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "842940900080",
  "CN": "8429 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Compactadoras"
 },
 {
  "CNKEY": "842951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Palas mecánicas, excavadoras, cargadoras y palas cargadoras"
 },
 {
  "CNKEY": "842951000080",
  "CN": "8429 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cargadoras y palas cargadoras de carga frontal"
 },
 {
  "CNKEY": "842951100080",
  "CN": "8429 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cargadoras especialmente concebidas para el interior de minas u otros trabajos subterráneos"
 },
 {
  "CNKEY": "842951910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "842951910080",
  "CN": "8429 51 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cargadoras de orugas"
 },
 {
  "CNKEY": "842951990080",
  "CN": "8429 51 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842952000080",
  "CN": "8429 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas cuya superestructura pueda girar 360o"
 },
 {
  "CNKEY": "842952100080",
  "CN": "8429 52 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Excavadoras de orugas"
 },
 {
  "CNKEY": "842952900080",
  "CN": "8429 52 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "842959000080",
  "CN": "8429 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843000000080",
  "CN": "8430",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos para explanar, nivelar, traillar (scraping), excavar, compactar, apisonar (aplanar), extraer o perforar tierra o minerales; martinetes y máquinas para arrancar pilotes, estacas o similares; quitanieves"
 },
 {
  "CNKEY": "843010000080",
  "CN": "8430 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Martinetes y máquinas para arrancar pilotes, estacas o similares"
 },
 {
  "CNKEY": "843020000080",
  "CN": "8430 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Quitanieves"
 },
 {
  "CNKEY": "843031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cortadoras y arrancadoras, de carbón o rocas, y máquinas para hacer túneles o galerías"
 },
 {
  "CNKEY": "843031000080",
  "CN": "8430 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Autopropulsadas"
 },
 {
  "CNKEY": "843039000080",
  "CN": "8430 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843041000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas para sondeo o perforación"
 },
 {
  "CNKEY": "843041000080",
  "CN": "8430 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Autopropulsadas"
 },
 {
  "CNKEY": "843049000080",
  "CN": "8430 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843050000080",
  "CN": "8430 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos, autopropulsados"
 },
 {
  "CNKEY": "843061000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos, sin propulsión"
 },
 {
  "CNKEY": "843061000080",
  "CN": "8430 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para compactar o apisonar (aplanar)"
 },
 {
  "CNKEY": "843069000080",
  "CN": "8430 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "843100000080",
  "CN": "8431",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes identificables como destinadas, exclusiva o principalmente, a las máquinas o aparatos de las partidas 8425 a 8430"
 },
 {
  "CNKEY": "843110000080",
  "CN": "8431 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De máquinas o aparatos de la partida 8425"
 },
 {
  "CNKEY": "843120000080",
  "CN": "8431 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De máquinas o aparatos de la partida 8427"
 },
 {
  "CNKEY": "843131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De máquinas o aparatos de la partida 8428"
 },
 {
  "CNKEY": "843131000080",
  "CN": "8431 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De ascensores, montacargas o escaleras mecánicas"
 },
 {
  "CNKEY": "843139000080",
  "CN": "8431 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De máquinas o aparatos de las partidas 8426, 8429 u 8430"
 },
 {
  "CNKEY": "843141000080",
  "CN": "8431 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cangilones, cucharas, cucharas de almeja, palas y garras o pinzas"
 },
 {
  "CNKEY": "843142000080",
  "CN": "8431 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hojas de topadoras frontales (bulldozers) o de topadoras angulares (angledozers)"
 },
 {
  "CNKEY": "843143000080",
  "CN": "8431 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De máquinas de sondeo o perforación de las subpartidas 843041 u 843049"
 },
 {
  "CNKEY": "843149000080",
  "CN": "8431 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843149200080",
  "CN": "8431 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "843149800080",
  "CN": "8431 49 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843200000080",
  "CN": "8432",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas, aparatos y artefactos agrícolas, hortícolas o silvícolas, para la preparación o el trabajo del suelo o para el cultivo; rodillos para césped o terrenos de deporte"
 },
 {
  "CNKEY": "843210000080",
  "CN": "8432 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Arados"
 },
 {
  "CNKEY": "843221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gradas (rastras), escarificadores, cultivadores, extirpadores, azadas, rotativas (rotocultores), escardadoras y binadoras"
 },
 {
  "CNKEY": "843221000080",
  "CN": "8432 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Gradas (rastras) de discos"
 },
 {
  "CNKEY": "843229000080",
  "CN": "8432 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "843229100080",
  "CN": "8432 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Escarificadores y cultivadores"
 },
 {
  "CNKEY": "843229300080",
  "CN": "8432 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Gradas (rastras)"
 },
 {
  "CNKEY": "843229500080",
  "CN": "8432 29 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Motobinadoras"
 },
 {
  "CNKEY": "843229900080",
  "CN": "8432 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "843231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sembradoras, plantadoras y trasplantadoras"
 },
 {
  "CNKEY": "843231000080",
  "CN": "8432 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sembradoras, plantadoras y trasplantadoras, para siembra directa"
 },
 {
  "CNKEY": "843239000080",
  "CN": "8432 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843239110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Sembradoras"
 },
 {
  "CNKEY": "843239110080",
  "CN": "8432 39 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De precisión, con mando central"
 },
 {
  "CNKEY": "843239190080",
  "CN": "8432 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843239900080",
  "CN": "8432 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Plantadoras y trasplantadoras"
 },
 {
  "CNKEY": "843241000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esparcidores de estiércol y distribuidores de abonos"
 },
 {
  "CNKEY": "843241000080",
  "CN": "8432 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Esparcidores de estiércol"
 },
 {
  "CNKEY": "843242000080",
  "CN": "8432 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Distribuidores de abonos"
 },
 {
  "CNKEY": "843280000080",
  "CN": "8432 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas, aparatos y artefactos"
 },
 {
  "CNKEY": "843290000080",
  "CN": "8432 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843300000080",
  "CN": "8433",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas, aparatos y artefactos de cosechar o trillar, incluidas las prensas para paja o forraje; cortadoras de césped y guadañadoras; máquinas para limpieza o clasificación de huevos, frutos o demás productos agrícolas (excepto las de la partida 8437)"
 },
 {
  "CNKEY": "843311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cortadoras de césped"
 },
 {
  "CNKEY": "843311000080",
  "CN": "8433 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con motor, en las que el dispositivo de corte gire en un plano horizontal"
 },
 {
  "CNKEY": "843311100080",
  "CN": "8433 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Eléctrico"
 },
 {
  "CNKEY": "843311510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843311510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Autopropulsadas"
 },
 {
  "CNKEY": "843311510080",
  "CN": "8433 11 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Con asiento"
 },
 {
  "CNKEY": "843311590080",
  "CN": "8433 11 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843311900080",
  "CN": "8433 11 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843319000080",
  "CN": "8433 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843319100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Con motor"
 },
 {
  "CNKEY": "843319100080",
  "CN": "8433 19 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Eléctrico"
 },
 {
  "CNKEY": "843319510010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843319510020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Autopropulsadas"
 },
 {
  "CNKEY": "843319510080",
  "CN": "8433 19 51",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Con asiento"
 },
 {
  "CNKEY": "843319590080",
  "CN": "8433 19 59",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843319700080",
  "CN": "8433 19 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843319900080",
  "CN": "8433 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Sin motor"
 },
 {
  "CNKEY": "843320000080",
  "CN": "8433 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guadañadoras, incluidas las barras de corte para montar sobre un tractor"
 },
 {
  "CNKEY": "843320100080",
  "CN": "8433 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con motor"
 },
 {
  "CNKEY": "843320500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "843320500080",
  "CN": "8433 20 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Concebidas para ser arrastradas o montadas sobre un tractor"
 },
 {
  "CNKEY": "843320900080",
  "CN": "8433 20 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843330000080",
  "CN": "8433 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas y aparatos de henificar"
 },
 {
  "CNKEY": "843340000080",
  "CN": "8433 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Prensas para paja o forraje, incluidas las prensas recogedoras"
 },
 {
  "CNKEY": "843351000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos de cosechar; máquinas y aparatos de trillar"
 },
 {
  "CNKEY": "843351000080",
  "CN": "8433 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Cosechadoras-trilladoras"
 },
 {
  "CNKEY": "843352000080",
  "CN": "8433 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas y aparatos de trillar"
 },
 {
  "CNKEY": "843353000080",
  "CN": "8433 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de cosechar raíces o tubérculos"
 },
 {
  "CNKEY": "843353100080",
  "CN": "8433 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Recolectoras de patatas (papas)"
 },
 {
  "CNKEY": "843353300080",
  "CN": "8433 53 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Descoronadoras y máquinas de cosechar remolacha"
 },
 {
  "CNKEY": "843353900080",
  "CN": "8433 53 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843359000080",
  "CN": "8433 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "843359110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Recogedoras-picadoras"
 },
 {
  "CNKEY": "843359110080",
  "CN": "8433 59 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Autopropulsadas"
 },
 {
  "CNKEY": "843359190080",
  "CN": "8433 59 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843359850080",
  "CN": "8433 59 85",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843360000080",
  "CN": "8433 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para limpieza o clasificación de huevos, frutos o demás productos agrícolas"
 },
 {
  "CNKEY": "843390000080",
  "CN": "8433 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843400000080",
  "CN": "8434",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas de ordeñar y máquinas y aparatos para la industria lechera"
 },
 {
  "CNKEY": "843410000080",
  "CN": "8434 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas de ordeñar"
 },
 {
  "CNKEY": "843420000080",
  "CN": "8434 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la industria lechera"
 },
 {
  "CNKEY": "843490000080",
  "CN": "8434 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843500000080",
  "CN": "8435",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Prensas, estrujadoras y máquinas y aparatos análogos para la producción de vino, sidra, jugos de frutos o bebidas similares"
 },
 {
  "CNKEY": "843510000080",
  "CN": "8435 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos"
 },
 {
  "CNKEY": "843590000080",
  "CN": "8435 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843600000080",
  "CN": "8436",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos para la agricultura, horticultura, silvicultura, avicultura o apicultura, incluidos los germinadores con dispositivos mecánicos o térmicos incorporados y las incubadoras y criadoras avícolas"
 },
 {
  "CNKEY": "843610000080",
  "CN": "8436 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para preparar alimentos o piensos para animales"
 },
 {
  "CNKEY": "843621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para la avicultura, incluidas las incubadoras y criadoras"
 },
 {
  "CNKEY": "843621000080",
  "CN": "8436 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Incubadoras y criadoras"
 },
 {
  "CNKEY": "843629000080",
  "CN": "8436 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "843680000080",
  "CN": "8436 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "843680100080",
  "CN": "8436 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la silvicultura"
 },
 {
  "CNKEY": "843680900080",
  "CN": "8436 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "843691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "843691000080",
  "CN": "8436 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De máquinas o aparatos para la avicultura"
 },
 {
  "CNKEY": "843699000080",
  "CN": "8436 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "843700000080",
  "CN": "8437",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para limpieza, clasificación o cribado de semillas, granos u hortalizas de vaina secas; máquinas y aparatos para molienda o tratamiento de cereales u hortalizas de vaina secas (excepto las de tipo rural)"
 },
 {
  "CNKEY": "843710000080",
  "CN": "8437 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para limpieza, clasificación o cribado de semillas, granos u hortalizas de vaina secas"
 },
 {
  "CNKEY": "843780000080",
  "CN": "8437 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "843790000080",
  "CN": "8437 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843800000080",
  "CN": "8438",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos, no expresados ni comprendidos en otra parte de este Capítulo, para la preparación o fabricación industrial de alimentos o bebidas, excepto las máquinas y aparatos para extracción o preparación de aceites o grasas, vegetales o de origen microbiano, fijos o animales"
 },
 {
  "CNKEY": "843810000080",
  "CN": "8438 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para panadería, pastelería, galletería o la fabricación de pastas alimenticias"
 },
 {
  "CNKEY": "843810100080",
  "CN": "8438 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para panadería, pastelería y galletería"
 },
 {
  "CNKEY": "843810900080",
  "CN": "8438 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para la fabricación de pastas alimenticias"
 },
 {
  "CNKEY": "843820000080",
  "CN": "8438 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para confitería, elaboración de cacao o la fabricación de chocolate"
 },
 {
  "CNKEY": "843830000080",
  "CN": "8438 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la industria azucarera"
 },
 {
  "CNKEY": "843840000080",
  "CN": "8438 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la industria cervecera"
 },
 {
  "CNKEY": "843850000080",
  "CN": "8438 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la preparación de carne"
 },
 {
  "CNKEY": "843860000080",
  "CN": "8438 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la preparación de frutos u hortalizas"
 },
 {
  "CNKEY": "843880000080",
  "CN": "8438 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "843880100080",
  "CN": "8438 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para tratamiento y preparación de café o té"
 },
 {
  "CNKEY": "843880910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "843880910080",
  "CN": "8438 80 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para la preparación o fabricación de bebidas"
 },
 {
  "CNKEY": "843880990080",
  "CN": "8438 80 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "843890000080",
  "CN": "8438 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "843900000080",
  "CN": "8439",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para la fabricación de pasta de materias fibrosas celulósicas o para la fabricación o acabado de papel o cartón"
 },
 {
  "CNKEY": "843910000080",
  "CN": "8439 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación de pasta de materias fibrosas celulósicas"
 },
 {
  "CNKEY": "843920000080",
  "CN": "8439 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación de papel o cartón"
 },
 {
  "CNKEY": "843930000080",
  "CN": "8439 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para el acabado de papel o cartón"
 },
 {
  "CNKEY": "843991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "843991000080",
  "CN": "8439 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De máquinas o aparatos para la fabricación de pasta de materias fibrosas celulósicas"
 },
 {
  "CNKEY": "843999000080",
  "CN": "8439 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844000000080",
  "CN": "8440",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para encuadernación, incluidas las máquinas para coser pliegos"
 },
 {
  "CNKEY": "844010000080",
  "CN": "8440 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos"
 },
 {
  "CNKEY": "844010100080",
  "CN": "8440 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plegadoras"
 },
 {
  "CNKEY": "844010200080",
  "CN": "8440 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ensambladoras"
 },
 {
  "CNKEY": "844010300080",
  "CN": "8440 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cosedoras o grapadoras"
 },
 {
  "CNKEY": "844010400080",
  "CN": "8440 10 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de encuadernar por pegado"
 },
 {
  "CNKEY": "844010900080",
  "CN": "8440 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844090000080",
  "CN": "8440 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "844100000080",
  "CN": "8441",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos para el trabajo de la pasta de papel, del papel o cartón, incluidas las cortadoras de cualquier tipo"
 },
 {
  "CNKEY": "844110000080",
  "CN": "8441 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cortadoras"
 },
 {
  "CNKEY": "844110100080",
  "CN": "8441 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cortadoras-bobinadoras"
 },
 {
  "CNKEY": "844110200080",
  "CN": "8441 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cortadoras longitudinales o transversales"
 },
 {
  "CNKEY": "844110300080",
  "CN": "8441 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guillotinas de una sola hoja"
 },
 {
  "CNKEY": "844110700080",
  "CN": "8441 10 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844120000080",
  "CN": "8441 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para la fabricación de sacos (bolsas), bolsitas o sobres"
 },
 {
  "CNKEY": "844130000080",
  "CN": "8441 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para la fabricación de cajas, tubos, tambores o continentes similares (excepto por moldeado)"
 },
 {
  "CNKEY": "844140000080",
  "CN": "8441 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para moldear artículos de pasta de papel, de papel o cartón"
 },
 {
  "CNKEY": "844180000080",
  "CN": "8441 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "844190000080",
  "CN": "8441 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "844190100080",
  "CN": "8441 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cortadoras"
 },
 {
  "CNKEY": "844190900080",
  "CN": "8441 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844200000080",
  "CN": "8442",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas, aparatos y material (excepto las máquinas de las partidas 8456 a 8465) para preparar o fabricar clisés, planchas, cilindros o demás elementos impresores; clisés, planchas, cilindros y demás elementos impresores; piedras litográficas, planchas, placas y cilindros, preparados para la impresión (por ejemplo: aplanados, graneados, pulidos)"
 },
 {
  "CNKEY": "844230000080",
  "CN": "8442 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas, aparatos y material"
 },
 {
  "CNKEY": "844240000080",
  "CN": "8442 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes de estas máquinas, aparatos o material"
 },
 {
  "CNKEY": "844250000080",
  "CN": "8442 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Clisés, planchas, cilindros y demás elementos impresores; piedras litográficas, planchas, placas y cilindros, preparados para la impresión (por ejemplo: aplanados, graneados, pulidos)"
 },
 {
  "CNKEY": "844300000080",
  "CN": "8443",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para imprimir mediante planchas, cilindros y demás elementos impresores de la partida 8442; las demás máquinas impresoras, copiadoras y de fax, incluso combinadas entre sí; partes y accesorios"
 },
 {
  "CNKEY": "844311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para imprimir mediante planchas, cilindros y demás elementos impresores de la partida 8442"
 },
 {
  "CNKEY": "844311000080",
  "CN": "8443 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para imprimir, offset, alimentados con bobinas"
 },
 {
  "CNKEY": "844312000080",
  "CN": "8443 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos de oficina para imprimir, offset, alimentados con hojas en las que un lado sea inferior o igual a 22 cm y el otro sea inferior o igual a 36 cm, medidas sin plegar"
 },
 {
  "CNKEY": "844313000080",
  "CN": "8443 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos para imprimir, offset"
 },
 {
  "CNKEY": "844313100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Alimentados con hojas"
 },
 {
  "CNKEY": "844313100080",
  "CN": "8443 13 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "844313320010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Nuevos, con hojas de formato"
 },
 {
  "CNKEY": "844313320080",
  "CN": "8443 13 32",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 53 × 75 cm"
 },
 {
  "CNKEY": "844313340080",
  "CN": "8443 13 34",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 53 × 75 cm pero inferior o igual a 75 × 107 cm"
 },
 {
  "CNKEY": "844313380080",
  "CN": "8443 13 38",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 75 × 107 cm"
 },
 {
  "CNKEY": "844313900080",
  "CN": "8443 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844314000080",
  "CN": "8443 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para imprimir, tipográficos, alimentados con bobinas, excepto las máquinas y aparatos flexográficos"
 },
 {
  "CNKEY": "844315000080",
  "CN": "8443 15 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para imprimir, tipográficos, distintos de los alimentados con bobinas, excepto las máquinas y aparatos flexográficos"
 },
 {
  "CNKEY": "844316000080",
  "CN": "8443 16 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para imprimir, flexográficos"
 },
 {
  "CNKEY": "844317000080",
  "CN": "8443 17 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y aparatos para imprimir, heliográficos (huecograbado)"
 },
 {
  "CNKEY": "844319000080",
  "CN": "8443 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "844319200080",
  "CN": "8443 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para estampar o imprimir materias textiles"
 },
 {
  "CNKEY": "844319400080",
  "CN": "8443 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para ser utilizadas en la producción de material semiconductor"
 },
 {
  "CNKEY": "844319700080",
  "CN": "8443 19 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas impresoras, copiadoras y de fax, incluso combinadas entre sí"
 },
 {
  "CNKEY": "844331000080",
  "CN": "8443 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas que efectúen dos o más de las siguientes funciones : impresión, copia o fax, aptas para ser conectadas a una máquina automática para tratamiento o procesamiento de datos o a una red"
 },
 {
  "CNKEY": "844332000080",
  "CN": "8443 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás, aptas para ser conectadas a una máquina automática para tratamiento o procesamiento de datos o a una red"
 },
 {
  "CNKEY": "844332100080",
  "CN": "8443 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Impresoras"
 },
 {
  "CNKEY": "844332800080",
  "CN": "8443 32 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844339000080",
  "CN": "8443 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "844391000080",
  "CN": "8443 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de máquinas y aparatos para imprimir por medio de planchas, cilindros y demás elementos impresores de la partida 8442"
 },
 {
  "CNKEY": "844391100080",
  "CN": "8443 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De aparatos de la subpartida  84431940"
 },
 {
  "CNKEY": "844391910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "844391910080",
  "CN": "8443 91 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "844391990080",
  "CN": "8443 91 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844399000080",
  "CN": "8443 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "844399100080",
  "CN": "8443 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "844399900080",
  "CN": "8443 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844400000080",
  "CN": "8444 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para extrudir, estirar, texturar o cortar materia textil sintética o artificial"
 },
 {
  "CNKEY": "844400100080",
  "CN": "8444 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para extrudir"
 },
 {
  "CNKEY": "844400900080",
  "CN": "8444 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844500000080",
  "CN": "8445",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para la preparación de materia textil; máquinas para hilar, doblar o retorcer materia textil y demás máquinas y aparatos para la fabricación de hilados textiles; máquinas para bobinar (incluidas las canilleras), o devanar materia textil y máquinas para la preparación de hilados textiles para su utilización en las máquinas de las partidas 8446 u 8447"
 },
 {
  "CNKEY": "844511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas para la preparación de materia textil"
 },
 {
  "CNKEY": "844511000080",
  "CN": "8445 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Cardas"
 },
 {
  "CNKEY": "844512000080",
  "CN": "8445 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Peinadoras"
 },
 {
  "CNKEY": "844513000080",
  "CN": "8445 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Mecheras"
 },
 {
  "CNKEY": "844519000080",
  "CN": "8445 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844520000080",
  "CN": "8445 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para hilar materia textil"
 },
 {
  "CNKEY": "844530000080",
  "CN": "8445 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para doblar o retorcer materia textil"
 },
 {
  "CNKEY": "844540000080",
  "CN": "8445 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para bobinar (incluidas las canilleras) o devanar materia textil"
 },
 {
  "CNKEY": "844590000080",
  "CN": "8445 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844600000080",
  "CN": "8446",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Telares"
 },
 {
  "CNKEY": "844610000080",
  "CN": "8446 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Para tejidos de anchura inferior o igual a 30 cm"
 },
 {
  "CNKEY": "844621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para tejidos de anchura superior a 30 cm, de lanzadera"
 },
 {
  "CNKEY": "844621000080",
  "CN": "8446 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De motor"
 },
 {
  "CNKEY": "844629000080",
  "CN": "8446 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844630000080",
  "CN": "8446 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Para tejidos de anchura superior a 30 cm, sin lanzadera"
 },
 {
  "CNKEY": "844700000080",
  "CN": "8447",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas de tricotar, de coser por cadeneta, de entorchar, de fabricar tul, encaje, bordados, pasamanería, trenzas, redes o de insertar mechones"
 },
 {
  "CNKEY": "844711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas circulares de tricotar"
 },
 {
  "CNKEY": "844711000080",
  "CN": "8447 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con cilindro de diámetro inferior o igual a 165 mm"
 },
 {
  "CNKEY": "844712000080",
  "CN": "8447 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con cilindro de diámetro superior a 165 mm"
 },
 {
  "CNKEY": "844720000080",
  "CN": "8447 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas rectilíneas de tricotar; máquinas de coser por cadeneta"
 },
 {
  "CNKEY": "844720200080",
  "CN": "8447 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Telares de urdimbres, incluidos los Raschel; máquinas de coser por cadeneta"
 },
 {
  "CNKEY": "844720800080",
  "CN": "8447 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844790000080",
  "CN": "8447 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "844800000080",
  "CN": "8448",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos auxiliares para las máquinas de las partidas 8444, 8445, 8446 u 8447 (por ejemplo: maquinitas para lizos, mecanismos Jacquard, paraurdidumbres y paratramas, mecanismos de cambio de lanzadera); partes y accesorios identificables como destinados, exclusiva o principalmente, a las máquinas de esta partida o de las partidas 8444, 8445, 8446 u 8447 (por ejemplo: husos, aletas, guarniciones de cardas, peines, barretas, hileras, lanzaderas, lizos y cuadros de lizos, agujas, platinas, ganchos)"
 },
 {
  "CNKEY": "844811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos auxiliares para las máquinas de las partidas 8444, 8445, 8446 u 8447"
 },
 {
  "CNKEY": "844811000080",
  "CN": "8448 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Maquinitas para lizos mecanismos Jacquard; reductoras, perforadoras y copiadoras de cartones; máquinas para unir los cartones después de perforados"
 },
 {
  "CNKEY": "844819000080",
  "CN": "8448 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844820000080",
  "CN": "8448 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de las máquinas de la partida 8444 o de sus máquinas o aparatos auxiliares"
 },
 {
  "CNKEY": "844831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de las máquinas de la partida 8445 o de sus máquinas o aparatos auxiliares"
 },
 {
  "CNKEY": "844831000080",
  "CN": "8448 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Guarniciones de cardas"
 },
 {
  "CNKEY": "844832000080",
  "CN": "8448 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De máquinas para la preparación de materia textil"
 },
 {
  "CNKEY": "844833000080",
  "CN": "8448 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Husos y sus aletas, anillos y cursores"
 },
 {
  "CNKEY": "844839000080",
  "CN": "8448 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844842000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de telares o de sus máquinas o aparatos auxiliares"
 },
 {
  "CNKEY": "844842000080",
  "CN": "8448 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Peines, lizos y cuadros de lizos"
 },
 {
  "CNKEY": "844849000080",
  "CN": "8448 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844851000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de máquinas o aparatos de la partida 8447 o de sus máquinas o aparatos auxiliares"
 },
 {
  "CNKEY": "844851000080",
  "CN": "8448 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Platinas, agujas y demás artículos que participen en la formación de mallas"
 },
 {
  "CNKEY": "844851100080",
  "CN": "8448 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Platinas"
 },
 {
  "CNKEY": "844851900080",
  "CN": "8448 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844859000080",
  "CN": "8448 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "844900000080",
  "CN": "8449 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación o acabado del fieltro o tela sin tejer, en pieza o con forma, incluidas las máquinas y aparatos para la fabricación de sombreros de fieltro; hormas de sombrerería"
 },
 {
  "CNKEY": "845000000080",
  "CN": "8450",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para lavar ropa, incluso con dispositivo de secado"
 },
 {
  "CNKEY": "845011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de capacidad unitaria, expresada en peso de ropa seca, inferior o igual a 10 kg"
 },
 {
  "CNKEY": "845011000080",
  "CN": "8450 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas totalmente automáticas"
 },
 {
  "CNKEY": "845011110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De capacidad unitaria, expresada en peso de ropa seca, inferior o igual a 6 kg"
 },
 {
  "CNKEY": "845011110080",
  "CN": "8450 11 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De carga frontal"
 },
 {
  "CNKEY": "845011190080",
  "CN": "8450 11 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De carga superior"
 },
 {
  "CNKEY": "845011900080",
  "CN": "8450 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De capacidad unitaria, expresada en peso de ropa seca, superior a 6 kg pero inferior o igual a 10 kg"
 },
 {
  "CNKEY": "845012000080",
  "CN": "8450 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas, con secadora centrífuga incorporada"
 },
 {
  "CNKEY": "845019000080",
  "CN": "8450 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845020000080",
  "CN": "8450 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de capacidad unitaria, expresada en peso de ropa seca, superior a 10 kg"
 },
 {
  "CNKEY": "845090000080",
  "CN": "8450 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "845100000080",
  "CN": "8451",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos (excepto las máquinas de la partida 8450) para lavar, limpiar, escurrir, secar, planchar, prensar (incluidas las prensas de fijar), blanquear, teñir, aprestar, acabar, recubrir o impregnar hilados, telas o manufacturas textiles y máquinas para el revestimiento de telas u otros soportes utilizados en la fabricación de cubresuelos, tales como linóleo; máquinas para enrollar, desenrollar, plegar, cortar o dentar telas"
 },
 {
  "CNKEY": "845110000080",
  "CN": "8451 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para limpieza en seco"
 },
 {
  "CNKEY": "845121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas para secar"
 },
 {
  "CNKEY": "845121000080",
  "CN": "8451 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De capacidad unitaria, expresada en peso de ropa seca, inferior o igual a 10 kg"
 },
 {
  "CNKEY": "845129000080",
  "CN": "8451 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845130000080",
  "CN": "8451 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas y prensas para planchar, incluidas las prensas para fijar"
 },
 {
  "CNKEY": "845140000080",
  "CN": "8451 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para lavar, blanquear o teñir"
 },
 {
  "CNKEY": "845150000080",
  "CN": "8451 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para enrollar, desenrollar, plegar, cortar o dentar telas"
 },
 {
  "CNKEY": "845180000080",
  "CN": "8451 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "845180100080",
  "CN": "8451 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas para fabricar linóleos u otros cubresuelos, aplicando pasta sobre telas u otros soportes"
 },
 {
  "CNKEY": "845180300080",
  "CN": "8451 80 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas para aprestar o acabar"
 },
 {
  "CNKEY": "845180800080",
  "CN": "8451 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845190000080",
  "CN": "8451 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "845200000080",
  "CN": "8452",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas de coser (excepto las de coser pliegos de la partida 8440); muebles, basamentos y tapas o cubiertas especialmente diseñados para máquinas de coser; agujas para máquinas de coser"
 },
 {
  "CNKEY": "845210000080",
  "CN": "8452 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de coser domésticas"
 },
 {
  "CNKEY": "845210110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de coser que hagan solo pespunte, con un cabezal de peso inferior o igual a 16 kg sin motor o a 17 kg con motor; cabezales de máquinas de coser que hagan solo pespunte y con un peso inferior o igual a 16 kg sin motor o a 17 kg con motor"
 },
 {
  "CNKEY": "845210110080",
  "CN": "8452 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de coser de valor unitario (excluidos las armazones, mesas o muebles) superior a 65 €"
 },
 {
  "CNKEY": "845210190080",
  "CN": "8452 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845210900080",
  "CN": "8452 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas de coser y los demás cabezales para máquinas de coser"
 },
 {
  "CNKEY": "845221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas de coser"
 },
 {
  "CNKEY": "845221000080",
  "CN": "8452 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Unidades automáticas"
 },
 {
  "CNKEY": "845229000080",
  "CN": "8452 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845230000080",
  "CN": "8452 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "1000 p\/st",
  "ES": "Agujas para máquinas de coser"
 },
 {
  "CNKEY": "845290000080",
  "CN": "8452 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Muebles, basamentos y tapas o cubiertas para máquinas de coser, y sus partes; las demás partes para máquinas de coser"
 },
 {
  "CNKEY": "845300000080",
  "CN": "8453",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para la preparación, curtido o trabajo de cuero o piel o para la fabricación o reparación de calzado u otras manufacturas de cuero o piel (excepto las máquinas de coser)"
 },
 {
  "CNKEY": "845310000080",
  "CN": "8453 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la preparación, curtido o trabajo de cuero o piel"
 },
 {
  "CNKEY": "845320000080",
  "CN": "8453 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación o reparación de calzado"
 },
 {
  "CNKEY": "845380000080",
  "CN": "8453 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "845390000080",
  "CN": "8453 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "845400000080",
  "CN": "8454",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Convertidores, cucharas de colada, lingoteras y máquinas de colar (moldear), para metalurgia, acerías o fundiciones"
 },
 {
  "CNKEY": "845410000080",
  "CN": "8454 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Convertidores"
 },
 {
  "CNKEY": "845420000080",
  "CN": "8454 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lingoteras y cucharas de colada"
 },
 {
  "CNKEY": "845430000080",
  "CN": "8454 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de colar (moldear)"
 },
 {
  "CNKEY": "845430100080",
  "CN": "8454 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de colar (moldear) a presión"
 },
 {
  "CNKEY": "845430900080",
  "CN": "8454 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845490000080",
  "CN": "8454 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "845500000080",
  "CN": "8455",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Laminadores para metal y sus cilindros"
 },
 {
  "CNKEY": "845510000080",
  "CN": "8455 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Laminadores de tubos"
 },
 {
  "CNKEY": "845521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás laminadores"
 },
 {
  "CNKEY": "845521000080",
  "CN": "8455 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para laminar en caliente o combinados para laminar en caliente y en frío"
 },
 {
  "CNKEY": "845522000080",
  "CN": "8455 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para laminar en frío"
 },
 {
  "CNKEY": "845530000080",
  "CN": "8455 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cilindros de laminadores"
 },
 {
  "CNKEY": "845530100080",
  "CN": "8455 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "845530310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De acero forjado"
 },
 {
  "CNKEY": "845530310080",
  "CN": "8455 30 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cilindros de trabajo en caliente; cilindros de apoyo en caliente y en frío"
 },
 {
  "CNKEY": "845530390080",
  "CN": "8455 30 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cilindros de trabajo en frío"
 },
 {
  "CNKEY": "845530900080",
  "CN": "8455 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845590000080",
  "CN": "8455 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás partes"
 },
 {
  "CNKEY": "845600000080",
  "CN": "8456",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta que trabajen por arranque de cualquier materia mediante láser u otros haces de luz o de fotones, por ultrasonido, electroerosión, procesos electroquímicos, haces de electrones, haces iónicos o chorro de plasma; máquinas para cortar por chorro de agua"
 },
 {
  "CNKEY": "845611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que operen mediante láser u otros haces de luz o de fotones"
 },
 {
  "CNKEY": "845611000080",
  "CN": "8456 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que operen mediante láser"
 },
 {
  "CNKEY": "845611100080",
  "CN": "8456 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Exclusiva o principalmente, para la fabricación de circuitos impresos, estructuras de circuitos impresos, partes de los artículos de la partida 8517 o partes de máquinas automáticas para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "845611900080",
  "CN": "8456 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845612000080",
  "CN": "8456 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Que operen mediante otros haces de luz o de fotones"
 },
 {
  "CNKEY": "845612100080",
  "CN": "8456 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Exclusiva o principalmente, para la fabricación de circuitos impresos, estructuras de circuitos impresos, partes de los artículos de la partida 8517 o partes de máquinas automáticas para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "845612900080",
  "CN": "8456 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845620000080",
  "CN": "8456 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Que operen por ultrasonido"
 },
 {
  "CNKEY": "845630000080",
  "CN": "8456 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que operen por electroerosión"
 },
 {
  "CNKEY": "845630110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845630110080",
  "CN": "8456 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De corte por hilo"
 },
 {
  "CNKEY": "845630190080",
  "CN": "8456 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845630900080",
  "CN": "8456 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845640000080",
  "CN": "8456 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Que operen mediante chorro de plasma"
 },
 {
  "CNKEY": "845650000080",
  "CN": "8456 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para cortar por chorro de agua"
 },
 {
  "CNKEY": "845690000080",
  "CN": "8456 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845700000080",
  "CN": "8457",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Centros de mecanizado, máquinas de puesto fijo y máquinas de puestos múltiples, para trabajar metal"
 },
 {
  "CNKEY": "845710000080",
  "CN": "8457 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Centros de mecanizado"
 },
 {
  "CNKEY": "845710100080",
  "CN": "8457 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Horizontales"
 },
 {
  "CNKEY": "845710900080",
  "CN": "8457 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845720000080",
  "CN": "8457 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de puesto fijo"
 },
 {
  "CNKEY": "845730000080",
  "CN": "8457 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de puestos múltiples"
 },
 {
  "CNKEY": "845730100080",
  "CN": "8457 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845730900080",
  "CN": "8457 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845800000080",
  "CN": "8458",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tornos (incluidos los centros de torneado) que trabajen por arranque de metal"
 },
 {
  "CNKEY": "845811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tornos horizontales"
 },
 {
  "CNKEY": "845811000080",
  "CN": "8458 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845811200080",
  "CN": "8458 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Centros de torneado"
 },
 {
  "CNKEY": "845811410010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Tornos automáticos"
 },
 {
  "CNKEY": "845811410080",
  "CN": "8458 11 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Monohusillo"
 },
 {
  "CNKEY": "845811490080",
  "CN": "8458 11 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Multihusillos"
 },
 {
  "CNKEY": "845811800080",
  "CN": "8458 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845819000080",
  "CN": "8458 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás tornos"
 },
 {
  "CNKEY": "845891000080",
  "CN": "8458 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845891200080",
  "CN": "8458 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Centros de torneado"
 },
 {
  "CNKEY": "845891800080",
  "CN": "8458 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845899000080",
  "CN": "8458 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "845900000080",
  "CN": "8459",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta (incluidas las unidades de mecanizado de correderas) de taladrar, escariar, fresar o roscar (incluso aterrajar), metal por arranque de material, excepto los tornos (incluidos los centros de torneado) de la partida 8458)"
 },
 {
  "CNKEY": "845910000080",
  "CN": "8459 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Unidades de mecanizado de correderas"
 },
 {
  "CNKEY": "845921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas de taladrar"
 },
 {
  "CNKEY": "845921000080",
  "CN": "8459 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845929000080",
  "CN": "8459 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás escariadoras-fresadoras"
 },
 {
  "CNKEY": "845931000080",
  "CN": "8459 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845939000080",
  "CN": "8459 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás escariadoras"
 },
 {
  "CNKEY": "845941000080",
  "CN": "8459 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845949000080",
  "CN": "8459 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de fresar de consola"
 },
 {
  "CNKEY": "845951000080",
  "CN": "8459 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845959000080",
  "CN": "8459 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845961000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas de fresar"
 },
 {
  "CNKEY": "845961000080",
  "CN": "8459 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De control numérico"
 },
 {
  "CNKEY": "845961100080",
  "CN": "8459 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fresar útiles"
 },
 {
  "CNKEY": "845961900080",
  "CN": "8459 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845969000080",
  "CN": "8459 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "845969100080",
  "CN": "8459 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De fresar útiles"
 },
 {
  "CNKEY": "845969900080",
  "CN": "8459 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "845970000080",
  "CN": "8459 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas de roscar, incluso aterrajar"
 },
 {
  "CNKEY": "846000000080",
  "CN": "8460",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta de desbarbar, afilar, amolar, rectificar, lapear (bruñir), pulir o hacer otras operaciones de acabado, para metal o cermet, mediante muelas, abrasivos o productos para pulir (excepto las máquinas para tallar o acabar engranajes de la partida 8461)"
 },
 {
  "CNKEY": "846012000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de rectificar superficies planas"
 },
 {
  "CNKEY": "846012000080",
  "CN": "8460 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846019000080",
  "CN": "8460 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846022000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas de rectificar"
 },
 {
  "CNKEY": "846022000080",
  "CN": "8460 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de rectificar sin centro, de control numérico"
 },
 {
  "CNKEY": "846023000080",
  "CN": "8460 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas de rectificar superficies cilíndricas, de control numérico"
 },
 {
  "CNKEY": "846024000080",
  "CN": "8460 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás, de control numérico"
 },
 {
  "CNKEY": "846029000080",
  "CN": "8460 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846029100080",
  "CN": "8460 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para superficies cilíndricas"
 },
 {
  "CNKEY": "846029900080",
  "CN": "8460 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de afilar"
 },
 {
  "CNKEY": "846031000080",
  "CN": "8460 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846039000080",
  "CN": "8460 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846040000080",
  "CN": "8460 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de lapear (bruñir)"
 },
 {
  "CNKEY": "846040100080",
  "CN": "8460 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846040900080",
  "CN": "8460 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846090000080",
  "CN": "8460 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846100000080",
  "CN": "8461",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta de cepillar, limar, mortajar, brochar, tallar o acabar engranajes, aserrar, trocear y demás máquinas herramienta que trabajen por arranque de metal o cermet, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "846120000080",
  "CN": "8461 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de limar o mortajar"
 },
 {
  "CNKEY": "846130000080",
  "CN": "8461 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de brochar"
 },
 {
  "CNKEY": "846130100080",
  "CN": "8461 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846130900080",
  "CN": "8461 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846140000080",
  "CN": "8461 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de tallar o acabar engranajes"
 },
 {
  "CNKEY": "846140110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de tallar engranajes"
 },
 {
  "CNKEY": "846140110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De tallar engranajes cilíndricos"
 },
 {
  "CNKEY": "846140110080",
  "CN": "8461 40 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846140190080",
  "CN": "8461 40 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846140310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De tallar otros engranajes"
 },
 {
  "CNKEY": "846140310080",
  "CN": "8461 40 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846140390080",
  "CN": "8461 40 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846140710010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de acabar engranajes"
 },
 {
  "CNKEY": "846140710020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "En las que la pieza pueda regularse en uno de los ejes con una precisión superior o igual a 0,01 mm"
 },
 {
  "CNKEY": "846140710080",
  "CN": "8461 40 71",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846140790080",
  "CN": "8461 40 79",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846140900080",
  "CN": "8461 40 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846150000080",
  "CN": "8461 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de aserrar o trocear"
 },
 {
  "CNKEY": "846150110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de aserrar"
 },
 {
  "CNKEY": "846150110080",
  "CN": "8461 50 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Circulares"
 },
 {
  "CNKEY": "846150190080",
  "CN": "8461 50 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846150900080",
  "CN": "8461 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de trocear"
 },
 {
  "CNKEY": "846190000080",
  "CN": "8461 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846200000080",
  "CN": "8462",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta (incluidas las prensas) de forjar o estampar, martillos pilón y otras máquinas de martillar, para trabajar metal (excepto los laminadores); máquinas herramienta (incluidas las prensas, las líneas de hendido y las líneas de corte longitudinal) de enrollar, curvar, plegar, enderezar, aplanar, cizallar, punzonar, entallar o mordiscar, metal (excepto los bancos de estirar); prensas para trabajar metal o carburos metálicos, no expresadas anteriormente"
 },
 {
  "CNKEY": "846211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas (incluidas las prensas), para trabajar en caliente, de forjar con matrices o forjado libre, o estampar, martillos pilón y otras máquinas de martillar"
 },
 {
  "CNKEY": "846211000080",
  "CN": "8462 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de forjar con matriz cerrada"
 },
 {
  "CNKEY": "846211100080",
  "CN": "8462 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846211900080",
  "CN": "8462 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846219000080",
  "CN": "8462 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846219100080",
  "CN": "8462 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846219900080",
  "CN": "8462 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846222000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas (incluidas las prensas) de enrollar, curvar, plegar, enderezar o aplanar, para productos planos"
 },
 {
  "CNKEY": "846222000080",
  "CN": "8462 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de conformar perfiles"
 },
 {
  "CNKEY": "846222100080",
  "CN": "8462 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846222900080",
  "CN": "8462 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846223000080",
  "CN": "8462 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Prensas plegadoras, de control numérico"
 },
 {
  "CNKEY": "846224000080",
  "CN": "8462 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Prensas para paneles, de control numérico"
 },
 {
  "CNKEY": "846225000080",
  "CN": "8462 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de perfilar rodillos, de control numérico"
 },
 {
  "CNKEY": "846226000080",
  "CN": "8462 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas de enrollar, curvar, plegar, enderezar o aplanar, de control numérico"
 },
 {
  "CNKEY": "846229000080",
  "CN": "8462 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846229100080",
  "CN": "8462 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846229900080",
  "CN": "8462 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846232000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Líneas de hendido, lineas de corte longitudinal y demás máquinas (excepto las prensas) de cizallar, para productos planos, excepto las combinadas de cizallar y punzonar"
 },
 {
  "CNKEY": "846232000080",
  "CN": "8462 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Líneas de hendido y líneas de corte longitudinal"
 },
 {
  "CNKEY": "846232100080",
  "CN": "8462 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846232900080",
  "CN": "8462 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846233000080",
  "CN": "8462 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de cizallar, de control numérico"
 },
 {
  "CNKEY": "846239000080",
  "CN": "8462 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846242000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas (excepto las prensas) de punzonar, entallar o mordiscar, para productos planos, incluso las combinadas de cizallar y punzonar"
 },
 {
  "CNKEY": "846242000080",
  "CN": "8462 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846249000080",
  "CN": "8462 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846251000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas para trabajar tubos, tubería, perfiles huecos, perfiles y barras (excepto las prensas)"
 },
 {
  "CNKEY": "846251000080",
  "CN": "8462 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846259000080",
  "CN": "8462 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846261000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Prensas de metal en frío"
 },
 {
  "CNKEY": "846261000080",
  "CN": "8462 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Prensas hidráulicas"
 },
 {
  "CNKEY": "846261100080",
  "CN": "8462 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846261900080",
  "CN": "8462 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846262000080",
  "CN": "8462 62",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Prensas mecánicas"
 },
 {
  "CNKEY": "846262100080",
  "CN": "8462 62 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846262900080",
  "CN": "8462 62 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846263000080",
  "CN": "8462 63",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Servoprensas"
 },
 {
  "CNKEY": "846263100080",
  "CN": "8462 63 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846263900080",
  "CN": "8462 63 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846269000080",
  "CN": "8462 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846269100080",
  "CN": "8462 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846269900080",
  "CN": "8462 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846290000080",
  "CN": "8462 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846290100080",
  "CN": "8462 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De control numérico"
 },
 {
  "CNKEY": "846290900080",
  "CN": "8462 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846300000080",
  "CN": "8463",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas herramienta para trabajar metal o cermet, que no trabajen por arranque de materia"
 },
 {
  "CNKEY": "846310000080",
  "CN": "8463 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bancos de estirar barras, tubos, perfiles, alambres o similares"
 },
 {
  "CNKEY": "846310100080",
  "CN": "8463 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bancos de estirar alambres"
 },
 {
  "CNKEY": "846310900080",
  "CN": "8463 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846320000080",
  "CN": "8463 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas laminadoras de hacer roscas"
 },
 {
  "CNKEY": "846330000080",
  "CN": "8463 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para trabajar alambre"
 },
 {
  "CNKEY": "846390000080",
  "CN": "8463 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846400000080",
  "CN": "8464",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta para trabajar piedra, cerámica, hormigón, amiantocemento o materias minerales similares, o para trabajar el vidrio en frío"
 },
 {
  "CNKEY": "846410000080",
  "CN": "8464 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de aserrar"
 },
 {
  "CNKEY": "846420000080",
  "CN": "8464 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas de amolar o pulir"
 },
 {
  "CNKEY": "846420110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para trabajar vidrio"
 },
 {
  "CNKEY": "846420110080",
  "CN": "8464 20 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cristales de óptica"
 },
 {
  "CNKEY": "846420190080",
  "CN": "8464 20 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846420800080",
  "CN": "8464 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846490000080",
  "CN": "8464 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846500000080",
  "CN": "8465",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas herramienta (incluidas las de clavar, grapar, encolar o ensamblar de otro modo) para trabajar madera, corcho, hueso, caucho endurecido, plástico rígido o materias duras similares"
 },
 {
  "CNKEY": "846510000080",
  "CN": "8465 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas que efectúen distintas operaciones de mecanizado sin cambio de útil entre dichas operaciones"
 },
 {
  "CNKEY": "846510100080",
  "CN": "8465 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con colocación manual de la pieza entre cada operación"
 },
 {
  "CNKEY": "846510900080",
  "CN": "8465 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sin colocación manual de la pieza entre cada operación"
 },
 {
  "CNKEY": "846520000080",
  "CN": "8465 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Centros de mecanizado"
 },
 {
  "CNKEY": "846591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846591000080",
  "CN": "8465 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas de aserrar"
 },
 {
  "CNKEY": "846591100080",
  "CN": "8465 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De cinta"
 },
 {
  "CNKEY": "846591200080",
  "CN": "8465 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Circulares"
 },
 {
  "CNKEY": "846591900080",
  "CN": "8465 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846592000080",
  "CN": "8465 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de cepillar; máquinas de fresar o moldurar"
 },
 {
  "CNKEY": "846593000080",
  "CN": "8465 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de amolar, lijar o pulir"
 },
 {
  "CNKEY": "846594000080",
  "CN": "8465 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de curvar o ensamblar"
 },
 {
  "CNKEY": "846595000080",
  "CN": "8465 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de taladrar o mortajar"
 },
 {
  "CNKEY": "846596000080",
  "CN": "8465 96 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de hendir, rebanar o desenrollar"
 },
 {
  "CNKEY": "846599000080",
  "CN": "8465 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846600000080",
  "CN": "8466",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios identificables como destinados, exclusiva o principalmente, a las máquinas de las partidas 8456 a 8465, incluidos los portapiezas y portaútiles, dispositivos de roscar de apertura automática, divisores y demás dispositivos especiales para montar en estas máquinas; portaútiles para herramientas de mano de cualquier tipo"
 },
 {
  "CNKEY": "846610000080",
  "CN": "8466 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Portaútiles y dispositivos de roscar de apertura automática"
 },
 {
  "CNKEY": "846610200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Portaútiles"
 },
 {
  "CNKEY": "846610200080",
  "CN": "8466 10 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mandriles, pinzas y manguitos"
 },
 {
  "CNKEY": "846610310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "846610310080",
  "CN": "8466 10 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Para tornos"
 },
 {
  "CNKEY": "846610380080",
  "CN": "8466 10 38",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846610800080",
  "CN": "8466 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos de roscar de apertura automática"
 },
 {
  "CNKEY": "846620000080",
  "CN": "8466 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Portapiezas"
 },
 {
  "CNKEY": "846620200080",
  "CN": "8466 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Montajes de mecanizado y sus conjuntos de componentes estándar"
 },
 {
  "CNKEY": "846620910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "846620910080",
  "CN": "8466 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para tornos"
 },
 {
  "CNKEY": "846620980080",
  "CN": "8466 20 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846630000080",
  "CN": "8466 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Divisores y demás dispositivos especiales para ser montados en las máquinas"
 },
 {
  "CNKEY": "846691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "846691000080",
  "CN": "8466 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para máquinas de la partida 8464"
 },
 {
  "CNKEY": "846691200080",
  "CN": "8466 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colados o moldeados, de fundición, hierro o acero"
 },
 {
  "CNKEY": "846691950080",
  "CN": "8466 91 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846692000080",
  "CN": "8466 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para máquinas de la partida 8465"
 },
 {
  "CNKEY": "846692200080",
  "CN": "8466 92 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colados o moldeados, de fundición, hierro o acero"
 },
 {
  "CNKEY": "846692800080",
  "CN": "8466 92 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846693000080",
  "CN": "8466 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para máquinas de las partidas 8456 a 8461"
 },
 {
  "CNKEY": "846693400080",
  "CN": "8466 93 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de las máquinas de las subpartidas 84561110, 84561210, 845620, 845630, 845710, 845891, 84592100, 845961 o 846150 de los tipos utilizados exclusiva o principalmente para la fabricación de circuitos impresos, placas de circuitos impresos, partes de la partida 8517 o partes de máquinas automáticas para procesamiento de datos"
 },
 {
  "CNKEY": "846693500010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "846693500080",
  "CN": "8466 93 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Para máquinas de la subpartida  84565000"
 },
 {
  "CNKEY": "846693600080",
  "CN": "8466 93 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846694000080",
  "CN": "8466 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para máquinas de las partidas 8462 u 8463"
 },
 {
  "CNKEY": "846700000080",
  "CN": "8467",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Herramientas neumáticas, hidráulicas o con motor incorporado, incluso eléctrico, de uso manual"
 },
 {
  "CNKEY": "846711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Neumáticas"
 },
 {
  "CNKEY": "846711000080",
  "CN": "8467 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Rotativas, incluso de percusión"
 },
 {
  "CNKEY": "846711100080",
  "CN": "8467 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para el trabajo de metal"
 },
 {
  "CNKEY": "846711900080",
  "CN": "8467 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846719000080",
  "CN": "8467 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con motor eléctrico incorporado"
 },
 {
  "CNKEY": "846721000080",
  "CN": "8467 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Taladros de toda clase, incluidas las perforadoras rotativas"
 },
 {
  "CNKEY": "846721100080",
  "CN": "8467 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Que funcionen sin fuente de energía exterior"
 },
 {
  "CNKEY": "846721910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "846721910080",
  "CN": "8467 21 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Electroneumáticos"
 },
 {
  "CNKEY": "846721990080",
  "CN": "8467 21 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "846722000080",
  "CN": "8467 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sierras, incluidas las tronzadoras"
 },
 {
  "CNKEY": "846722100080",
  "CN": "8467 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tronzadoras"
 },
 {
  "CNKEY": "846722300080",
  "CN": "8467 22 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Sierras circulares"
 },
 {
  "CNKEY": "846722900080",
  "CN": "8467 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846729000080",
  "CN": "8467 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846729200080",
  "CN": "8467 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Que funcionen sin fuente de energía exterior"
 },
 {
  "CNKEY": "846729510010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "846729510020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Amoladoras y lijadoras"
 },
 {
  "CNKEY": "846729510080",
  "CN": "8467 29 51",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Amoladoras angulares"
 },
 {
  "CNKEY": "846729530080",
  "CN": "8467 29 53",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Lijadoras de banda"
 },
 {
  "CNKEY": "846729590080",
  "CN": "8467 29 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846729700080",
  "CN": "8467 29 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cepillos"
 },
 {
  "CNKEY": "846729800080",
  "CN": "8467 29 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Cizallas para cortar setos, cizallas para césped y desherbadoras"
 },
 {
  "CNKEY": "846729850080",
  "CN": "8467 29 85",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846781000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás herramientas"
 },
 {
  "CNKEY": "846781000080",
  "CN": "8467 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sierras o tronzadoras, de cadena"
 },
 {
  "CNKEY": "846789000080",
  "CN": "8467 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "846791000080",
  "CN": "8467 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De sierras o tronzadoras, de cadena"
 },
 {
  "CNKEY": "846792000080",
  "CN": "8467 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De herramientas neumáticas"
 },
 {
  "CNKEY": "846799000080",
  "CN": "8467 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "846800000080",
  "CN": "8468",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para soldar, aunque puedan cortar (excepto los de la partida 8515); máquinas y aparatos de gas para temple superficial"
 },
 {
  "CNKEY": "846810000080",
  "CN": "8468 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sopletes manuales"
 },
 {
  "CNKEY": "846820000080",
  "CN": "8468 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos de gas"
 },
 {
  "CNKEY": "846880000080",
  "CN": "8468 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "846890000080",
  "CN": "8468 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "847000000080",
  "CN": "8470",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas de calcular y máquinas de bolsillo registradoras, reproductoras y visualizadoras de datos, con función de cálculo; máquinas de contabilidad, de franquear, expedir boletos (tiques) y máquinas similares, con dispositivo de cálculo incorporado; cajas registradoras"
 },
 {
  "CNKEY": "847010000080",
  "CN": "8470 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Calculadoras electrónicas que puedan funcionar sin fuente de energía eléctrica exterior y máquinas de bolsillo registradoras, reproductoras y visualizadoras de datos, con función de cálculo"
 },
 {
  "CNKEY": "847021000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas de calcular electrónicas"
 },
 {
  "CNKEY": "847021000080",
  "CN": "8470 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con dispositivo de impresión incorporado"
 },
 {
  "CNKEY": "847029000080",
  "CN": "8470 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847030000080",
  "CN": "8470 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás máquinas de calcular"
 },
 {
  "CNKEY": "847050000080",
  "CN": "8470 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cajas registradoras"
 },
 {
  "CNKEY": "847090000080",
  "CN": "8470 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847100000080",
  "CN": "8471",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas automáticas para tratamiento o procesamiento de datos y sus unidades; lectores magnéticos u ópticos, máquinas para registro de datos sobre soporte en forma codificada y máquinas para tratamiento o procesamiento de estos datos, no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "847130000080",
  "CN": "8471 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas automáticas para tratamiento o procesamiento de datos, portátiles, de peso inferior o igual a 10 kg, que estén constituidas, al menos, por una unidad central de proceso, un teclado y un visualizador"
 },
 {
  "CNKEY": "847141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas automáticas para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "847141000080",
  "CN": "8471 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que incluyan en la misma envoltura, al menos, una unidad central de proceso y, aunque estén combinadas, una unidad de entrada y una de salida"
 },
 {
  "CNKEY": "847149000080",
  "CN": "8471 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás presentadas en forma de sistemas"
 },
 {
  "CNKEY": "847150000080",
  "CN": "8471 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Unidades de proceso, excepto las de las subpartidas 847141 u 847149, aunque incluyan en la misma envoltura uno o dos de los tipos siguientes de unidades: unidad de memoria, unidad de entrada y unidad de salida"
 },
 {
  "CNKEY": "847160000080",
  "CN": "8471 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Unidades de entrada o salida, aunque incluyan unidades de memoria en la misma envoltura"
 },
 {
  "CNKEY": "847160600080",
  "CN": "8471 60 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Teclados"
 },
 {
  "CNKEY": "847160700080",
  "CN": "8471 60 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847170000080",
  "CN": "8471 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Unidades de memoria"
 },
 {
  "CNKEY": "847170200080",
  "CN": "8471 70 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Unidades de memoria central"
 },
 {
  "CNKEY": "847170300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847170300020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Unidades de memoria de disco"
 },
 {
  "CNKEY": "847170300080",
  "CN": "8471 70 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Ópticas, incluidas las magneto-ópticas"
 },
 {
  "CNKEY": "847170500010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847170500080",
  "CN": "8471 70 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Unidades de memoria de disco duro"
 },
 {
  "CNKEY": "847170700080",
  "CN": "8471 70 70",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847170800080",
  "CN": "8471 70 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Unidades de memoria de cinta"
 },
 {
  "CNKEY": "847170980080",
  "CN": "8471 70 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847180000080",
  "CN": "8471 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás unidades de máquinas automáticas para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "847190000080",
  "CN": "8471 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847200000080",
  "CN": "8472",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos de oficina (por ejemplo: copiadoras hectográficas, mimeógrafos, máquinas de imprimir direcciones, distribuidores automáticos de billetes de banco, máquinas de clasificar, contar o encartuchar monedas, sacapuntas, perforadoras, grapadoras)"
 },
 {
  "CNKEY": "847210000080",
  "CN": "8472 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Copiadoras, incluidos los mimeógrafos"
 },
 {
  "CNKEY": "847230000080",
  "CN": "8472 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de clasificar, plegar, meter en sobres o colocar en fajas correspondencia, máquinas de abrir, cerrar o precintar correspondencia y máquinas de colocar u obliterar los sellos (estampillas)"
 },
 {
  "CNKEY": "847290000080",
  "CN": "8472 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "847290100080",
  "CN": "8472 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de clasificar, contar y encartuchar monedas"
 },
 {
  "CNKEY": "847290800080",
  "CN": "8472 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847300000080",
  "CN": "8473",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios (excepto los estuches, fundas y similares) identificables como destinados, exclusiva o principalmente, a las máquinas o aparatos de las partidas 8470 a 8472"
 },
 {
  "CNKEY": "847321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de máquinas de la partida 8470"
 },
 {
  "CNKEY": "847321000080",
  "CN": "8473 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De máquinas de calcular electrónicas de las subpartidas 847010, 847021 u 847029"
 },
 {
  "CNKEY": "847321100080",
  "CN": "8473 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "847321900080",
  "CN": "8473 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847329000080",
  "CN": "8473 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "847329100080",
  "CN": "8473 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "847329900080",
  "CN": "8473 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847330000080",
  "CN": "8473 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de máquinas de la partida 8471"
 },
 {
  "CNKEY": "847330200080",
  "CN": "8473 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "847330800080",
  "CN": "8473 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847340000080",
  "CN": "8473 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de máquinas de la partida 8472"
 },
 {
  "CNKEY": "847340100080",
  "CN": "8473 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "847340800080",
  "CN": "8473 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847350000080",
  "CN": "8473 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios que puedan utilizarse indistintamente con máquinas o aparatos de varias de las partidas 8470 a 8472"
 },
 {
  "CNKEY": "847350200080",
  "CN": "8473 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "847350800080",
  "CN": "8473 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847400000080",
  "CN": "8474",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos de clasificar, cribar, separar, lavar, quebrantar, triturar, pulverizar, mezclar, amasar o sobar tierra, piedra u otra materia mineral sólida (incluido el polvo y la pasta); máquinas de aglomerar, formar o moldear combustibles minerales sólidos, pastas cerámicas, cemento, yeso o demás materias minerales en polvo o pasta; máquinas de hacer moldes de arena para fundición"
 },
 {
  "CNKEY": "847410000080",
  "CN": "8474 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos de clasificar, cribar, separar o lavar"
 },
 {
  "CNKEY": "847420000080",
  "CN": "8474 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos de quebrantar, triturar o pulverizar"
 },
 {
  "CNKEY": "847431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos de mezclar, amasar o sobar"
 },
 {
  "CNKEY": "847431000080",
  "CN": "8474 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hormigoneras y aparatos de amasar mortero"
 },
 {
  "CNKEY": "847432000080",
  "CN": "8474 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de mezclar materia mineral con asfalto"
 },
 {
  "CNKEY": "847439000080",
  "CN": "8474 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847480000080",
  "CN": "8474 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "847480100080",
  "CN": "8474 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de aglomerar, formar o moldear pastas cerámicas"
 },
 {
  "CNKEY": "847480900080",
  "CN": "8474 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847490000080",
  "CN": "8474 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "847490100080",
  "CN": "8474 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "847490900080",
  "CN": "8474 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847500000080",
  "CN": "8475",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para montar lámparas, tubos o válvulas eléctricos o electrónicos o lámparas de destello, que tengan envoltura de vidrio; máquinas para fabricar o trabajar en caliente el vidrio o sus manufacturas"
 },
 {
  "CNKEY": "847510000080",
  "CN": "8475 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para montar lámparas, tubos o válvulas eléctricos o electrónicos o lámparas de destello, que tengan envoltura de vidrio"
 },
 {
  "CNKEY": "847521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas para fabricar o trabajar en caliente el vidrio o sus manufacturas"
 },
 {
  "CNKEY": "847521000080",
  "CN": "8475 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas para fabricar fibras ópticas y sus esbozos"
 },
 {
  "CNKEY": "847529000080",
  "CN": "8475 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847590000080",
  "CN": "8475 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "847590100080",
  "CN": "8475 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para máquinas de la subpartida  84752100"
 },
 {
  "CNKEY": "847590900080",
  "CN": "8475 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847600000080",
  "CN": "8476",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas automáticas para la venta de productos [por ejemplo: sellos (estampillas), cigarrillos, alimentos, bebidas], incluidas las máquinas de cambiar moneda"
 },
 {
  "CNKEY": "847621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas automáticas para venta de bebidas"
 },
 {
  "CNKEY": "847621000080",
  "CN": "8476 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con dispositivo de calentamiento o refrigeración, incorporado"
 },
 {
  "CNKEY": "847629000080",
  "CN": "8476 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847681000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847681000080",
  "CN": "8476 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con dispositivo de calentamiento o refrigeración, incorporado"
 },
 {
  "CNKEY": "847689000080",
  "CN": "8476 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847689100080",
  "CN": "8476 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para cambiar moneda"
 },
 {
  "CNKEY": "847689900080",
  "CN": "8476 89 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847690000080",
  "CN": "8476 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "847690100080",
  "CN": "8476 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes de máquinas para cambiar moneda"
 },
 {
  "CNKEY": "847690900080",
  "CN": "8476 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847700000080",
  "CN": "8477",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para trabajar caucho o plástico o para fabricar productos de estas materias, no expresados ni comprendidos en otra parte de este capítulo"
 },
 {
  "CNKEY": "847710000080",
  "CN": "8477 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de moldear por inyección"
 },
 {
  "CNKEY": "847720000080",
  "CN": "8477 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Extrusoras"
 },
 {
  "CNKEY": "847730000080",
  "CN": "8477 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de moldear por soplado"
 },
 {
  "CNKEY": "847740000080",
  "CN": "8477 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de moldear en vacío y demás máquinas para termoformado"
 },
 {
  "CNKEY": "847751000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos de moldear o formar"
 },
 {
  "CNKEY": "847751000080",
  "CN": "8477 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De moldear o recauchutar neumáticos (llantas neumáticos) o moldear o formar cámaras para neumáticos"
 },
 {
  "CNKEY": "847759000080",
  "CN": "8477 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "847759100080",
  "CN": "8477 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Prensas"
 },
 {
  "CNKEY": "847759800080",
  "CN": "8477 59 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847780000080",
  "CN": "8477 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "847780110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Máquinas para la fabricación de productos esponjosos o celulares"
 },
 {
  "CNKEY": "847780110080",
  "CN": "8477 80 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para la transformación de resinas reactivas"
 },
 {
  "CNKEY": "847780190080",
  "CN": "8477 80 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847780910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847780910080",
  "CN": "8477 80 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas para fragmentar"
 },
 {
  "CNKEY": "847780930080",
  "CN": "8477 80 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Mezcladores, malaxadores y agitadores"
 },
 {
  "CNKEY": "847780950080",
  "CN": "8477 80 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cortadoras y peladoras"
 },
 {
  "CNKEY": "847780990080",
  "CN": "8477 80 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847790000080",
  "CN": "8477 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "847790100080",
  "CN": "8477 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "847790800080",
  "CN": "8477 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847800000080",
  "CN": "8478",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para preparar o elaborar tabaco, no expresados ni comprendidos en otra parte de este capítulo"
 },
 {
  "CNKEY": "847810000080",
  "CN": "8478 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos"
 },
 {
  "CNKEY": "847890000080",
  "CN": "8478 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "847900000080",
  "CN": "8479",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos mecánicos con función propia, no expresados ni comprendidos en otra parte de este capítulo"
 },
 {
  "CNKEY": "847910000080",
  "CN": "8479 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para obras públicas, la construcción o trabajos análogos"
 },
 {
  "CNKEY": "847920000080",
  "CN": "8479 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para extracción o preparación de grasas o aceites, vegetales o de origen microbiano, fijos o animales"
 },
 {
  "CNKEY": "847930000080",
  "CN": "8479 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Prensas para fabricar tableros de partículas, fibra de madera u otras materias leñosas y demás máquinas y aparatos para el tratamiento de la madera o el corcho"
 },
 {
  "CNKEY": "847930100080",
  "CN": "8479 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Prensas"
 },
 {
  "CNKEY": "847930900080",
  "CN": "8479 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847940000080",
  "CN": "8479 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de cordelería o cablería"
 },
 {
  "CNKEY": "847950000080",
  "CN": "8479 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Robots industriales, no expresados ni comprendidos en otra parte"
 },
 {
  "CNKEY": "847960000080",
  "CN": "8479 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos de evaporación para refrigerar el aire"
 },
 {
  "CNKEY": "847971000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pasarelas de embarque para pasajeros"
 },
 {
  "CNKEY": "847971000080",
  "CN": "8479 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en aeropuertos"
 },
 {
  "CNKEY": "847979000080",
  "CN": "8479 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "847981000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "847981000080",
  "CN": "8479 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para el tratamiento del metal, incluidas las bobinadoras de hilos eléctricos"
 },
 {
  "CNKEY": "847982000080",
  "CN": "8479 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para mezclar, amasar o sobar, quebrantar, triturar, pulverizar, cribar, tamizar, homogeneizar, emulsionar o agitar"
 },
 {
  "CNKEY": "847983000080",
  "CN": "8479 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Prensas isostáticas en frío"
 },
 {
  "CNKEY": "847989000080",
  "CN": "8479 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "847989300080",
  "CN": "8479 89 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Entibados móviles hidráulicos para minas"
 },
 {
  "CNKEY": "847989600080",
  "CN": "8479 89 60",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dispositivos llamados «de engrase centralizado»"
 },
 {
  "CNKEY": "847989700080",
  "CN": "8479 89 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Máquinas automáticas de colocación de componentes electrónicos de los tipos utilizados, exclusiva o principalmente, para la fabricación de estructuras de circuitos impresos"
 },
 {
  "CNKEY": "847989970080",
  "CN": "8479 89 97",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "847990000080",
  "CN": "8479 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "847990150080",
  "CN": "8479 90 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes de máquinas de la subpartida 84798970"
 },
 {
  "CNKEY": "847990200010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "847990200080",
  "CN": "8479 90 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "847990700080",
  "CN": "8479 90 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848000000080",
  "CN": "8480",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas de fundición; placas de fondo para moldes; modelos para moldes; moldes para metal (excepto las lingoteras), carburos metálicos, vidrio, materia mineral, caucho o plástico"
 },
 {
  "CNKEY": "848010000080",
  "CN": "8480 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas de fundición"
 },
 {
  "CNKEY": "848020000080",
  "CN": "8480 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Placas de fondo para moldes"
 },
 {
  "CNKEY": "848030000080",
  "CN": "8480 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Modelos para moldes"
 },
 {
  "CNKEY": "848030100080",
  "CN": "8480 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "848030900080",
  "CN": "8480 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848041000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Moldes para metal o carburos metálicos"
 },
 {
  "CNKEY": "848041000080",
  "CN": "8480 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para moldeo por inyección o compresión"
 },
 {
  "CNKEY": "848049000080",
  "CN": "8480 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848050000080",
  "CN": "8480 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Moldes para vidrio"
 },
 {
  "CNKEY": "848060000080",
  "CN": "8480 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Moldes para materia mineral"
 },
 {
  "CNKEY": "848071000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Moldes para caucho o plástico"
 },
 {
  "CNKEY": "848071000080",
  "CN": "8480 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para moldeo por inyección o compresión"
 },
 {
  "CNKEY": "848079000080",
  "CN": "8480 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848100000080",
  "CN": "8481",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos de grifería y órganos similares para tuberías, calderas, depósitos, cubas o continentes similares, incluidas las válvulas reductoras de presión y las válvulas termostáticas"
 },
 {
  "CNKEY": "848110000080",
  "CN": "8481 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Válvulas reductoras de presión"
 },
 {
  "CNKEY": "848110050080",
  "CN": "8481 10 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Combinadas con filtros o engrasadores"
 },
 {
  "CNKEY": "848110190010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "848110190080",
  "CN": "8481 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De fundición o de acero"
 },
 {
  "CNKEY": "848110990080",
  "CN": "8481 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848120000080",
  "CN": "8481 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Válvulas para transmisiones oleohidráulicas o neumáticas"
 },
 {
  "CNKEY": "848120100080",
  "CN": "8481 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Válvulas para transmisiones oleohidráulicas"
 },
 {
  "CNKEY": "848120900080",
  "CN": "8481 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Válvulas para transmisiones neumáticas"
 },
 {
  "CNKEY": "848130000080",
  "CN": "8481 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Válvulas de retención"
 },
 {
  "CNKEY": "848130910080",
  "CN": "8481 30 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición o de acero"
 },
 {
  "CNKEY": "848130990080",
  "CN": "8481 30 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848140000080",
  "CN": "8481 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Válvulas de alivio o seguridad"
 },
 {
  "CNKEY": "848140100080",
  "CN": "8481 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición o de acero"
 },
 {
  "CNKEY": "848140900080",
  "CN": "8481 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848180000080",
  "CN": "8481 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás artículos de grifería y órganos similares"
 },
 {
  "CNKEY": "848180110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Grifería sanitaria"
 },
 {
  "CNKEY": "848180110080",
  "CN": "8481 80 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mezcladores, reguladores de agua caliente"
 },
 {
  "CNKEY": "848180190080",
  "CN": "8481 80 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848180310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Grifería para radiadores de calefacción central"
 },
 {
  "CNKEY": "848180310080",
  "CN": "8481 80 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Llaves termostáticas"
 },
 {
  "CNKEY": "848180390080",
  "CN": "8481 80 39",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848180400080",
  "CN": "8481 80 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Válvulas para neumáticos y cámaras de aire"
 },
 {
  "CNKEY": "848180510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "848180510020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Válvulas de regulación"
 },
 {
  "CNKEY": "848180510080",
  "CN": "8481 80 51",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De temperatura"
 },
 {
  "CNKEY": "848180590080",
  "CN": "8481 80 59",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848180610010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "848180610020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Llaves, grifos y válvulas de paso directo"
 },
 {
  "CNKEY": "848180610080",
  "CN": "8481 80 61",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "848180630080",
  "CN": "8481 80 63",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero"
 },
 {
  "CNKEY": "848180690080",
  "CN": "8481 80 69",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848180710010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Válvulas de asiento"
 },
 {
  "CNKEY": "848180710080",
  "CN": "8481 80 71",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "848180730080",
  "CN": "8481 80 73",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero"
 },
 {
  "CNKEY": "848180790080",
  "CN": "8481 80 79",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848180810080",
  "CN": "8481 80 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Válvulas con obturador esférico, cónico o cilíndrico"
 },
 {
  "CNKEY": "848180850080",
  "CN": "8481 80 85",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Válvulas de mariposa"
 },
 {
  "CNKEY": "848180870080",
  "CN": "8481 80 87",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Válvulas de membrana"
 },
 {
  "CNKEY": "848180990080",
  "CN": "8481 80 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848190000080",
  "CN": "8481 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "848200000080",
  "CN": "8482",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Rodamientos de bolas, de rodillos o de agujas"
 },
 {
  "CNKEY": "848210000080",
  "CN": "8482 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Rodamientos de bolas"
 },
 {
  "CNKEY": "848210100080",
  "CN": "8482 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cuyo mayor diámetro exterior sea inferior o igual a 30 mm"
 },
 {
  "CNKEY": "848210900080",
  "CN": "8482 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848220000080",
  "CN": "8482 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Rodamientos de rodillos cónicos, incluidos los ensamblajes de conos y rodillos cónicos"
 },
 {
  "CNKEY": "848230000080",
  "CN": "8482 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Rodamientos de rodillos en forma de tonel"
 },
 {
  "CNKEY": "848240000080",
  "CN": "8482 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Rodamientos de agujas, incluidos los ensamblajes de jaulas y rodillos de agujas"
 },
 {
  "CNKEY": "848250000080",
  "CN": "8482 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás rodamientos de rodillos cilíndricos, incluidos los ensamblajes de jaulas y rodillos"
 },
 {
  "CNKEY": "848280000080",
  "CN": "8482 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos los rodamientos combinados"
 },
 {
  "CNKEY": "848291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "848291000080",
  "CN": "8482 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bolas, rodillos y agujas"
 },
 {
  "CNKEY": "848291100080",
  "CN": "8482 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Rodillos cónicos"
 },
 {
  "CNKEY": "848291900080",
  "CN": "8482 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848299000080",
  "CN": "8482 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848300000080",
  "CN": "8483",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Árboles de transmisión (incluidos los de levas y los cigüeñales) y manivelas; cajas de cojinetes y cojinetes; engranajes y ruedas de fricción; husillos fileteados de bolas o rodillos; reductores, multiplicadores y variadores de velocidad, incluidos los convertidores de par; volantes y poleas, incluidos los motones; embragues y órganos de acoplamiento, incluidas las juntas de articulación"
 },
 {
  "CNKEY": "848310000080",
  "CN": "8483 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Árboles de transmisión (incluidos los de levas y los cigüeñales) y manivelas"
 },
 {
  "CNKEY": "848310210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Manivelas y cigüeñales"
 },
 {
  "CNKEY": "848310210080",
  "CN": "8483 10 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "848310250080",
  "CN": "8483 10 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero forjado"
 },
 {
  "CNKEY": "848310290080",
  "CN": "8483 10 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848310500080",
  "CN": "8483 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Árboles articulados"
 },
 {
  "CNKEY": "848310950080",
  "CN": "8483 10 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848320000080",
  "CN": "8483 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas de cojinetes con rodamientos incorporados"
 },
 {
  "CNKEY": "848330000080",
  "CN": "8483 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cajas de cojinetes sin rodamientos incorporados; cojinetes"
 },
 {
  "CNKEY": "848330320010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cajas de cojinetes"
 },
 {
  "CNKEY": "848330320080",
  "CN": "8483 30 32",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para rodamientos de cualquier clase"
 },
 {
  "CNKEY": "848330380080",
  "CN": "8483 30 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848330800080",
  "CN": "8483 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cojinetes"
 },
 {
  "CNKEY": "848340000080",
  "CN": "8483 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Engranajes y ruedas de fricción (excepto las ruedas dentadas y demás órganos elementales de transmisión presentados aisladamente); husillos fileteados de bolas o rodillos; reductores, multiplicadores y variadores de velocidad, incluidos los convertidores de par"
 },
 {
  "CNKEY": "848340210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Engranajes"
 },
 {
  "CNKEY": "848340210080",
  "CN": "8483 40 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cilíndricos"
 },
 {
  "CNKEY": "848340230080",
  "CN": "8483 40 23",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cónicos y cilindro-cónicos"
 },
 {
  "CNKEY": "848340250080",
  "CN": "8483 40 25",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De tornillos sin fin"
 },
 {
  "CNKEY": "848340290080",
  "CN": "8483 40 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848340300080",
  "CN": "8483 40 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Husillos fileteados de bolas o rodillos"
 },
 {
  "CNKEY": "848340510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Reductores, multiplicadores y variadores de velocidad"
 },
 {
  "CNKEY": "848340510080",
  "CN": "8483 40 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Reductores, multiplicadores y cajas de cambio"
 },
 {
  "CNKEY": "848340590080",
  "CN": "8483 40 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848340900080",
  "CN": "8483 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848350000080",
  "CN": "8483 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Volantes y poleas, incluidos los motones"
 },
 {
  "CNKEY": "848350200080",
  "CN": "8483 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "848350800080",
  "CN": "8483 50 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848360000080",
  "CN": "8483 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Embragues y órganos de acoplamiento, incluidas las juntas de articulación"
 },
 {
  "CNKEY": "848360200080",
  "CN": "8483 60 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "848360800080",
  "CN": "8483 60 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848390000080",
  "CN": "8483 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ruedas dentadas y demás órganos elementales de transmisión presentados aisladamente; partes"
 },
 {
  "CNKEY": "848390200080",
  "CN": "8483 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes de cajas de cojinetes para rodamientos de cualquier clase"
 },
 {
  "CNKEY": "848390810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "848390810080",
  "CN": "8483 90 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "848390890080",
  "CN": "8483 90 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848400000080",
  "CN": "8484",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Juntas metaloplásticas; surtidos de juntas o empaquetaduras de distinta composición presentados en bolsitas, sobres o envases análogos; juntas mecánicas de estanqueidad"
 },
 {
  "CNKEY": "848410000080",
  "CN": "8484 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Juntas metaloplásticas"
 },
 {
  "CNKEY": "848420000080",
  "CN": "8484 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Juntas mecánicas de estanqueidad"
 },
 {
  "CNKEY": "848490000080",
  "CN": "8484 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "848500000080",
  "CN": "8485",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas para fabricación aditiva"
 },
 {
  "CNKEY": "848510000080",
  "CN": "8485 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Por depósito de metal"
 },
 {
  "CNKEY": "848520000080",
  "CN": "8485 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Por depósito de plástico o caucho"
 },
 {
  "CNKEY": "848530000080",
  "CN": "8485 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Por depósito de yeso, cemento, cerámica o vidrio"
 },
 {
  "CNKEY": "848530100080",
  "CN": "8485 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Por depósito de yeso, cemento o cerámica"
 },
 {
  "CNKEY": "848530900080",
  "CN": "8485 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848580000080",
  "CN": "8485 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848590000080",
  "CN": "8485 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "848590100080",
  "CN": "8485 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes de máquinas de la subpartida 84853010"
 },
 {
  "CNKEY": "848590900080",
  "CN": "8485 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848600000080",
  "CN": "8486",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos utilizados, exclusiva o principalmente, para la fabricación de semiconductores en forma de monocristales periformes u obleas («wafers»), dispositivos semiconductores, circuitos electrónicos integrados o dispositivos de visualización («display») de pantalla plana; máquinas y aparatos descritos en la Nota 11 C) de este Capítulo; partes y accesorios"
 },
 {
  "CNKEY": "848610000080",
  "CN": "8486 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación de semiconductores en forma de monocristales periformes u obleas («wafers»)"
 },
 {
  "CNKEY": "848620000080",
  "CN": "8486 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación de dispositivos semiconductores o circuitos electrónicos integrados"
 },
 {
  "CNKEY": "848630000080",
  "CN": "8486 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos para la fabricación de dispositivos de visualización («display») de pantalla plana"
 },
 {
  "CNKEY": "848640000080",
  "CN": "8486 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas y aparatos descritos en la Nota 11 C) de este Capítulo"
 },
 {
  "CNKEY": "848690000080",
  "CN": "8486 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "848700000080",
  "CN": "8487",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes de máquinas o aparatos, no expresadas ni comprendidas en otra parte de este capítulo, sin conexiones eléctricas, partes aisladas eléctricamente, bobinados, contactos ni otras características eléctricas"
 },
 {
  "CNKEY": "848710000080",
  "CN": "8487 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hélices para barcos y sus paletas"
 },
 {
  "CNKEY": "848710100080",
  "CN": "8487 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De bronce"
 },
 {
  "CNKEY": "848710900080",
  "CN": "8487 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De otras materias"
 },
 {
  "CNKEY": "848790000080",
  "CN": "8487 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "848790400080",
  "CN": "8487 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fundición"
 },
 {
  "CNKEY": "848790510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De hierro o acero"
 },
 {
  "CNKEY": "848790510080",
  "CN": "8487 90 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De acero colado o moldeado"
 },
 {
  "CNKEY": "848790570080",
  "CN": "8487 90 57",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De hierro o acero forjados o estampados"
 },
 {
  "CNKEY": "848790590080",
  "CN": "8487 90 59",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "848790900080",
  "CN": "8487 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De otras materias"
 },
 {
  "CNKEY": "850021000090",
  "CN": "85",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 85 - MÁQUINAS, APARATOS Y MATERIAL ELÉCTRICO, Y SUS PARTES; APARATOS DE GRABACIÓN O REPRODUCCIÓN DE SONIDO, APARATOS DE GRABACIÓN O REPRODUCCIÓN DE IMAGEN Y SONIDO EN TELEVISIÓN, Y LAS PARTES Y ACCESORIOS DE ESTOS APARATOS"
 },
 {
  "CNKEY": "850100000080",
  "CN": "8501",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Motores y generadores, eléctricos (excepto los grupos electrógenos)"
 },
 {
  "CNKEY": "850110000080",
  "CN": "8501 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Motores de potencia inferior o igual a 37,5 W"
 },
 {
  "CNKEY": "850110100080",
  "CN": "8501 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Motores síncronos de potencia inferior o igual a 18 W"
 },
 {
  "CNKEY": "850110910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "850110910080",
  "CN": "8501 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Motores universales"
 },
 {
  "CNKEY": "850110930080",
  "CN": "8501 10 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Motores de corriente alterna"
 },
 {
  "CNKEY": "850110990080",
  "CN": "8501 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Motores de corriente continua"
 },
 {
  "CNKEY": "850120000080",
  "CN": "8501 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Motores universales de potencia superior a 37,5 W"
 },
 {
  "CNKEY": "850131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás motores de corriente continua; generadores de corriente continua, excepto los generadores fotovoltaicos"
 },
 {
  "CNKEY": "850131000080",
  "CN": "8501 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 750 W"
 },
 {
  "CNKEY": "850132000080",
  "CN": "8501 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 W pero inferior o igual a 75 kW"
 },
 {
  "CNKEY": "850133000080",
  "CN": "8501 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 75 kW pero inferior o igual a 375 kW"
 },
 {
  "CNKEY": "850134000080",
  "CN": "8501 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 375 kW"
 },
 {
  "CNKEY": "850140000080",
  "CN": "8501 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás motores de corriente alterna, monofásicos"
 },
 {
  "CNKEY": "850140200080",
  "CN": "8501 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 750 W"
 },
 {
  "CNKEY": "850140800080",
  "CN": "8501 40 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 W"
 },
 {
  "CNKEY": "850151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás motores de corriente alterna, polifásicos"
 },
 {
  "CNKEY": "850151000080",
  "CN": "8501 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 750 W"
 },
 {
  "CNKEY": "850152000080",
  "CN": "8501 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 750 W pero inferior o igual a 75 kW"
 },
 {
  "CNKEY": "850152200080",
  "CN": "8501 52 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 W pero inferior o igual a 7,5 kW"
 },
 {
  "CNKEY": "850152300080",
  "CN": "8501 52 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 7,5 kW pero inferior o igual a 37 kW"
 },
 {
  "CNKEY": "850152900080",
  "CN": "8501 52 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 37 kW pero inferior o igual a 75 kW"
 },
 {
  "CNKEY": "850153000080",
  "CN": "8501 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 75 kW"
 },
 {
  "CNKEY": "850153500080",
  "CN": "8501 53 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Motores de tracción"
 },
 {
  "CNKEY": "850153810010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, de potencia"
 },
 {
  "CNKEY": "850153810080",
  "CN": "8501 53 81",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 75 kW pero inferior o igual a 375 kW"
 },
 {
  "CNKEY": "850153940080",
  "CN": "8501 53 94",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 375 kW pero inferior o igual a 750 kW"
 },
 {
  "CNKEY": "850153990080",
  "CN": "8501 53 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 750 kW"
 },
 {
  "CNKEY": "850161000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Generadores de corriente alterna (alternadores), excepto los generadores fotovoltaicos"
 },
 {
  "CNKEY": "850161000080",
  "CN": "8501 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia inferior o igual a 75 kVA"
 },
 {
  "CNKEY": "850161200080",
  "CN": "8501 61 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 7,5 kVA"
 },
 {
  "CNKEY": "850161800080",
  "CN": "8501 61 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 7,5 kVA pero inferior o igual a 75 kVA"
 },
 {
  "CNKEY": "850162000080",
  "CN": "8501 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 75 kVA pero inferior o igual a 375 kVA"
 },
 {
  "CNKEY": "850163000080",
  "CN": "8501 63 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 375 kVA pero inferior o igual a 750 kVA"
 },
 {
  "CNKEY": "850164000080",
  "CN": "8501 64 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 kVA"
 },
 {
  "CNKEY": "850171000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Generadores fotovoltaicos de corriente continua"
 },
 {
  "CNKEY": "850171000080",
  "CN": "8501 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 50 W"
 },
 {
  "CNKEY": "850172000080",
  "CN": "8501 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 50 W"
 },
 {
  "CNKEY": "850180000080",
  "CN": "8501 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Generadores fotovoltaicos de corriente alterna"
 },
 {
  "CNKEY": "850200000080",
  "CN": "8502",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grupos electrógenos y convertidores rotativos eléctricos"
 },
 {
  "CNKEY": "850211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grupos electrógenos con motor de émbolo (pistón) de encendido por compresión (motores diésel o semi-diésel)"
 },
 {
  "CNKEY": "850211000080",
  "CN": "8502 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia inferior o igual a 75 kVA"
 },
 {
  "CNKEY": "850211200080",
  "CN": "8502 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 7,5 kVA"
 },
 {
  "CNKEY": "850211800080",
  "CN": "8502 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 7,5 kVA pero inferior o igual a 75 kVA"
 },
 {
  "CNKEY": "850212000080",
  "CN": "8502 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 75 kVA pero inferior o igual a 375 kVA"
 },
 {
  "CNKEY": "850213000080",
  "CN": "8502 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 375 kVA"
 },
 {
  "CNKEY": "850213200080",
  "CN": "8502 13 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 375 kVA pero inferior o igual a 750 kVA"
 },
 {
  "CNKEY": "850213400080",
  "CN": "8502 13 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 kVA pero inferior o igual a 2000 kVA"
 },
 {
  "CNKEY": "850213800080",
  "CN": "8502 13 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 2000 kVA"
 },
 {
  "CNKEY": "850220000080",
  "CN": "8502 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Grupos electrógenos con motor de émbolo (pistón) de encendido por chispa (motor de explosión)"
 },
 {
  "CNKEY": "850220200080",
  "CN": "8502 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 7,5 kVA"
 },
 {
  "CNKEY": "850220400080",
  "CN": "8502 20 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 7,5 kVA pero inferior o igual a 375 kVA"
 },
 {
  "CNKEY": "850220600080",
  "CN": "8502 20 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 375 kVA pero inferior o igual a 750 kVA"
 },
 {
  "CNKEY": "850220800080",
  "CN": "8502 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 750 kVA"
 },
 {
  "CNKEY": "850231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás grupos electrógenos"
 },
 {
  "CNKEY": "850231000080",
  "CN": "8502 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De energía eólica"
 },
 {
  "CNKEY": "850239000080",
  "CN": "8502 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "850239200080",
  "CN": "8502 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Turbogeneradores"
 },
 {
  "CNKEY": "850239800080",
  "CN": "8502 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850240000080",
  "CN": "8502 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Convertidores rotativos eléctricos"
 },
 {
  "CNKEY": "850300000080",
  "CN": "8503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes identificables como destinadas, exclusiva o principalmente, a las máquinas de las partidas 8501 u 8502"
 },
 {
  "CNKEY": "850300100080",
  "CN": "8503 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Zunchos no magnéticos"
 },
 {
  "CNKEY": "850300910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "850300910080",
  "CN": "8503 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Coladas o moldeadas, de fundición, hierro o acero"
 },
 {
  "CNKEY": "850300990080",
  "CN": "8503 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850400000080",
  "CN": "8504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Transformadores eléctricos, convertidores eléctricos estáticos (por ejemplo: rectificadores) y bobinas de reactancia (autoinducción)"
 },
 {
  "CNKEY": "850410000080",
  "CN": "8504 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Balastos (reactancias) para lámparas o tubos de descarga"
 },
 {
  "CNKEY": "850410200080",
  "CN": "8504 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bobinas de reactancia, incluso con condensador acoplado"
 },
 {
  "CNKEY": "850410800080",
  "CN": "8504 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Transformadores de dieléctrico líquido"
 },
 {
  "CNKEY": "850421000080",
  "CN": "8504 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 650 kVA"
 },
 {
  "CNKEY": "850422000080",
  "CN": "8504 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia superior a 650 kVA pero inferior o igual a 10000 kVA"
 },
 {
  "CNKEY": "850422100080",
  "CN": "8504 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 650 kVA pero inferior o igual a 1600 kVA"
 },
 {
  "CNKEY": "850422900080",
  "CN": "8504 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 1600 kVA pero inferior o igual a 10000 kVA"
 },
 {
  "CNKEY": "850423000080",
  "CN": "8504 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 10000 kVA"
 },
 {
  "CNKEY": "850431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás transformadores"
 },
 {
  "CNKEY": "850431000080",
  "CN": "8504 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De potencia inferior o igual a 1 kVA"
 },
 {
  "CNKEY": "850431210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Transformadores de medida"
 },
 {
  "CNKEY": "850431210080",
  "CN": "8504 31 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Para medir tensiones"
 },
 {
  "CNKEY": "850431290080",
  "CN": "8504 31 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850431800080",
  "CN": "8504 31 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850432000080",
  "CN": "8504 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 1 kVA pero inferior o igual a 16 kVA"
 },
 {
  "CNKEY": "850433000080",
  "CN": "8504 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 16 kVA pero inferior o igual a 500 kVA"
 },
 {
  "CNKEY": "850434000080",
  "CN": "8504 34 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia superior a 500 kVA"
 },
 {
  "CNKEY": "850440000080",
  "CN": "8504 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Convertidores estáticos"
 },
 {
  "CNKEY": "850440300080",
  "CN": "8504 40 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados con los aparatos de telecomunicación, las máquinas automáticas para tratamiento o procesamiento de datos y sus unidades"
 },
 {
  "CNKEY": "850440550010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "850440550080",
  "CN": "8504 40 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cargadores de acumuladores"
 },
 {
  "CNKEY": "850440820010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "850440820080",
  "CN": "8504 40 82",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rectificadores"
 },
 {
  "CNKEY": "850440840010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Onduladores"
 },
 {
  "CNKEY": "850440840080",
  "CN": "8504 40 84",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 7,5 kVA"
 },
 {
  "CNKEY": "850440880080",
  "CN": "8504 40 88",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De potencia superior a 7,5 kVA"
 },
 {
  "CNKEY": "850440900080",
  "CN": "8504 40 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850450000080",
  "CN": "8504 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás bobinas de reactancia (autoinducción)"
 },
 {
  "CNKEY": "850490000080",
  "CN": "8504 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "850490110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De transformadores o de bobinas de reactancia (autoinducción)"
 },
 {
  "CNKEY": "850490110080",
  "CN": "8504 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Núcleos de ferrita"
 },
 {
  "CNKEY": "850490130080",
  "CN": "8504 90 13",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Laminaciones y núcleos de acero, estén apilados o enrollados o no"
 },
 {
  "CNKEY": "850490170080",
  "CN": "8504 90 17",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850490900080",
  "CN": "8504 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De convertidores estáticos"
 },
 {
  "CNKEY": "850500000080",
  "CN": "8505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Electroimanes; imanes permanentes y artículos destinados a ser imantados permanentemente; platos, mandriles y dispositivos magnéticos o electromagnéticos similares, de sujeción; acoplamientos, embragues, variadores de velocidad y frenos, electromagnéticos; cabezas elevadoras electromagnéticas"
 },
 {
  "CNKEY": "850511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Imanes permanentes y artículos destinados a ser imantados permanentemente"
 },
 {
  "CNKEY": "850511000080",
  "CN": "8505 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De metal"
 },
 {
  "CNKEY": "850519000080",
  "CN": "8505 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "850519100080",
  "CN": "8505 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Imanes permanentes de ferrita aglomerada"
 },
 {
  "CNKEY": "850519900080",
  "CN": "8505 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850520000080",
  "CN": "8505 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Acoplamientos, embragues, variadores de velocidad y frenos, electromagnéticos"
 },
 {
  "CNKEY": "850590000080",
  "CN": "8505 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "850590210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Electroimanes; platos, mandriles y dispositivos magnéticos o electromagnéticos similares, de sujeción"
 },
 {
  "CNKEY": "850590210080",
  "CN": "8505 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Electroimanes de los tipos utilizados, exclusiva o principalmente, para aparatos de diagnóstico de visualización por resonancia magnética distintos de los electroimanes de la partida 9018"
 },
 {
  "CNKEY": "850590290080",
  "CN": "8505 90 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850590500080",
  "CN": "8505 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cabezas elevadoras electromagnéticas"
 },
 {
  "CNKEY": "850590900080",
  "CN": "8505 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "850600000080",
  "CN": "8506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pilas y baterías de pilas, eléctricas"
 },
 {
  "CNKEY": "850610000080",
  "CN": "8506 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De dióxido de manganeso"
 },
 {
  "CNKEY": "850610110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Alcalinas"
 },
 {
  "CNKEY": "850610110080",
  "CN": "8506 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas cilíndricas"
 },
 {
  "CNKEY": "850610180080",
  "CN": "8506 10 18",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850610910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "850610910080",
  "CN": "8506 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas cilíndricas"
 },
 {
  "CNKEY": "850610980080",
  "CN": "8506 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850630000080",
  "CN": "8506 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De óxido de mercurio"
 },
 {
  "CNKEY": "850640000080",
  "CN": "8506 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De óxido de plata"
 },
 {
  "CNKEY": "850650000080",
  "CN": "8506 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De litio"
 },
 {
  "CNKEY": "850650100080",
  "CN": "8506 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pilas cilíndricas"
 },
 {
  "CNKEY": "850650300080",
  "CN": "8506 50 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pilas de botón"
 },
 {
  "CNKEY": "850650900080",
  "CN": "8506 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850660000080",
  "CN": "8506 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De aire-cinc"
 },
 {
  "CNKEY": "850680000080",
  "CN": "8506 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás pilas y baterías de pilas"
 },
 {
  "CNKEY": "850680050080",
  "CN": "8506 80 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Baterías secas de cinc-carbón, de una tensión superior o igual a 5,5 V pero inferior o igual a 6,5 V"
 },
 {
  "CNKEY": "850680800080",
  "CN": "8506 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850690000080",
  "CN": "8506 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "850700000080",
  "CN": "8507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Acumuladores eléctricos, incluidos sus separadores, aunque sean cuadrados o rectangulares"
 },
 {
  "CNKEY": "850710000080",
  "CN": "8507 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De plomo, de los tipos utilizados para arranque de motores de émbolo (pistón)"
 },
 {
  "CNKEY": "850710200080",
  "CN": "8507 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que funcionen con electrólito líquido"
 },
 {
  "CNKEY": "850710800080",
  "CN": "8507 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850720000080",
  "CN": "8507 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás acumuladores de plomo"
 },
 {
  "CNKEY": "850720200080",
  "CN": "8507 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "ce\/el",
  "ES": "Que funcionen con electrólito líquido"
 },
 {
  "CNKEY": "850720800080",
  "CN": "8507 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "ce\/el",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850730000080",
  "CN": "8507 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De níquel-cadmio"
 },
 {
  "CNKEY": "850730200080",
  "CN": "8507 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Herméticamente cerrados"
 },
 {
  "CNKEY": "850730800080",
  "CN": "8507 30 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "ce\/el",
  "ES": "Los demás"
 },
 {
  "CNKEY": "850750000080",
  "CN": "8507 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De níquel-hidruro metálico"
 },
 {
  "CNKEY": "850760000080",
  "CN": "8507 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De iones de litio"
 },
 {
  "CNKEY": "850780000080",
  "CN": "8507 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás acumuladores"
 },
 {
  "CNKEY": "850790000080",
  "CN": "8507 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "850790300080",
  "CN": "8507 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Separadores"
 },
 {
  "CNKEY": "850790800080",
  "CN": "8507 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850800000080",
  "CN": "8508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aspiradoras"
 },
 {
  "CNKEY": "850811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con motor eléctrico incorporado"
 },
 {
  "CNKEY": "850811000080",
  "CN": "8508 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De potencia inferior o igual a 1500 W y de capacidad del depósito o bolsa para el polvo inferior o igual a 20 l"
 },
 {
  "CNKEY": "850819000080",
  "CN": "8508 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "850860000080",
  "CN": "8508 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás aspiradoras"
 },
 {
  "CNKEY": "850870000080",
  "CN": "8508 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "850900000080",
  "CN": "8509",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos electromecánicos con motor eléctrico incorporado, de uso doméstico, excepto las aspiradoras de la partida 8508"
 },
 {
  "CNKEY": "850940000080",
  "CN": "8509 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Trituradoras y mezcladoras de alimentos; extractoras de jugo de frutos u hortalizas"
 },
 {
  "CNKEY": "850980000080",
  "CN": "8509 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "850990000080",
  "CN": "8509 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851000000080",
  "CN": "8510",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Afeitadoras, máquinas de cortar el pelo o esquilar y aparatos de depilar, con motor eléctrico incorporado"
 },
 {
  "CNKEY": "851010000080",
  "CN": "8510 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Afeitadoras"
 },
 {
  "CNKEY": "851020000080",
  "CN": "8510 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máquinas de cortar el pelo o esquilar"
 },
 {
  "CNKEY": "851030000080",
  "CN": "8510 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos de depilar"
 },
 {
  "CNKEY": "851090000080",
  "CN": "8510 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851100000080",
  "CN": "8511",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos y dispositivos eléctricos de encendido o de arranque, para motores de encendido por chispa o por compresión (por ejemplo: magnetos, dinamomagnetos, bobinas de encendido, bujías de encendido o calentamiento, motores de arranque); generadores (por ejemplo: dínamos, alternadores) y reguladores disyuntores utilizados con estos motores"
 },
 {
  "CNKEY": "851110000080",
  "CN": "8511 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bujías de encendido"
 },
 {
  "CNKEY": "851120000080",
  "CN": "8511 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Magnetos; dinamomagnetos; volantes magnéticos"
 },
 {
  "CNKEY": "851130000080",
  "CN": "8511 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Distribuidores; bobinas de encendido"
 },
 {
  "CNKEY": "851140000080",
  "CN": "8511 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Motores de arranque, aunque funcionen también como generadores"
 },
 {
  "CNKEY": "851150000080",
  "CN": "8511 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás generadores"
 },
 {
  "CNKEY": "851180000080",
  "CN": "8511 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aparatos y dispositivos"
 },
 {
  "CNKEY": "851190000080",
  "CN": "8511 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851200000080",
  "CN": "8512",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos eléctricos de alumbrado o señalización (excepto los artículos de la partida 8539), limpiaparabrisas, eliminadores de escarcha o vaho, eléctricos, de los tipos utilizados en velocípedos o vehículos automóviles"
 },
 {
  "CNKEY": "851210000080",
  "CN": "8512 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos de alumbrado o señalización visual de los tipos utilizados en bicicletas"
 },
 {
  "CNKEY": "851220000080",
  "CN": "8512 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aparatos de alumbrado o señalización visual"
 },
 {
  "CNKEY": "851230000080",
  "CN": "8512 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de señalización acústica"
 },
 {
  "CNKEY": "851230100080",
  "CN": "8512 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Alarmas antirrobo de los tipos utilizados para vehículos automóviles"
 },
 {
  "CNKEY": "851230900080",
  "CN": "8512 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851240000080",
  "CN": "8512 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Limpiaparabrisas y eliminadores de escarcha o vaho"
 },
 {
  "CNKEY": "851290000080",
  "CN": "8512 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "851290100080",
  "CN": "8512 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De aparatos de la subpartida 85123010"
 },
 {
  "CNKEY": "851290900080",
  "CN": "8512 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "851300000080",
  "CN": "8513",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lámparas eléctricas portátiles concebidas para funcionar con su propia fuente de energía (por ejemplo: de pilas, acumuladores, electromagnéticas) (excepto los aparatos de alumbrado de la partida 8512)"
 },
 {
  "CNKEY": "851310000080",
  "CN": "8513 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Lámparas"
 },
 {
  "CNKEY": "851390000080",
  "CN": "8513 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851400000080",
  "CN": "8514",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hornos eléctricos industriales o de laboratorio, incluidos los que funcionen por inducción o pérdidas dieléctricas; los demás aparatos industriales o de laboratorio para tratamiento térmico de materias por inducción o pérdidas dieléctricas"
 },
 {
  "CNKEY": "851411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hornos de resistencia (de calentamiento indirecto)"
 },
 {
  "CNKEY": "851411000080",
  "CN": "8514 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Prensas isostáticas en caliente"
 },
 {
  "CNKEY": "851419000080",
  "CN": "8514 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851419100080",
  "CN": "8514 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Hornos de panadería, pastelería o galletería"
 },
 {
  "CNKEY": "851419800080",
  "CN": "8514 19 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás hornos"
 },
 {
  "CNKEY": "851420000080",
  "CN": "8514 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Hornos que funcionen por inducción o pérdidas dieléctricas"
 },
 {
  "CNKEY": "851420100080",
  "CN": "8514 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que funcionen por inducción"
 },
 {
  "CNKEY": "851420800080",
  "CN": "8514 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que funcionen por pérdidas dieléctricas"
 },
 {
  "CNKEY": "851431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hornos"
 },
 {
  "CNKEY": "851431000080",
  "CN": "8514 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hornos de haces de electrones"
 },
 {
  "CNKEY": "851431100080",
  "CN": "8514 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados, exclusiva o principalmente, para la fabricación de circuitos impresos o estructuras de circuitos impresos"
 },
 {
  "CNKEY": "851431900080",
  "CN": "8514 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851432000080",
  "CN": "8514 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Hornos de plasma y hornos de arco al vacío"
 },
 {
  "CNKEY": "851432100080",
  "CN": "8514 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados, exclusiva o principalmente, para la fabricación de circuitos impresos o estructuras de circuitos impresos"
 },
 {
  "CNKEY": "851432900080",
  "CN": "8514 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851439000080",
  "CN": "8514 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851439100080",
  "CN": "8514 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados, exclusiva o principalmente, para la fabricación de circuitos impresos o estructuras de circuitos impresos"
 },
 {
  "CNKEY": "851439900080",
  "CN": "8514 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851440000080",
  "CN": "8514 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás aparatos para tratamiento térmico de materias por inducción o pérdidas dieléctricas"
 },
 {
  "CNKEY": "851490000080",
  "CN": "8514 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "851490300080",
  "CN": "8514 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De hornos de la subpartidas 85143110, 85143210 u 85143910"
 },
 {
  "CNKEY": "851490700080",
  "CN": "8514 90 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851500000080",
  "CN": "8515",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para soldar (aunque puedan cortar), eléctricos (incluidos los de gas calentado eléctricamente), de láser u otros haces de luz o de fotones, ultrasonido, haces de electrones, impulsos magnéticos o chorro de plasma; máquinas y aparatos eléctricos para proyectar en caliente metal o cermet"
 },
 {
  "CNKEY": "851511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para soldadura fuerte o para soldadura blanda"
 },
 {
  "CNKEY": "851511000080",
  "CN": "8515 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Soldadores y pistolas para soldar"
 },
 {
  "CNKEY": "851519000080",
  "CN": "8515 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851519100080",
  "CN": "8515 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Máquinas de soldadura por ola de los tipos utilizados, exclusiva o principalmente, para la fabricación de estructuras de circuitos impresos"
 },
 {
  "CNKEY": "851519900080",
  "CN": "8515 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para soldar metal por resistencia"
 },
 {
  "CNKEY": "851521000080",
  "CN": "8515 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Total o parcialmente automáticos"
 },
 {
  "CNKEY": "851529000080",
  "CN": "8515 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para soldar metal, de arco o chorro de plasma"
 },
 {
  "CNKEY": "851531000080",
  "CN": "8515 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Total o parcialmente automáticos"
 },
 {
  "CNKEY": "851539000080",
  "CN": "8515 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851539130010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Manuales, para electrodos recubiertos, constituidos por los dispositivos de soldadura y"
 },
 {
  "CNKEY": "851539130080",
  "CN": "8515 39 13",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Un transformador"
 },
 {
  "CNKEY": "851539180080",
  "CN": "8515 39 18",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Un generador o un convertidor rotativo o un convertidor estático"
 },
 {
  "CNKEY": "851539900080",
  "CN": "8515 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851580000080",
  "CN": "8515 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "851580100080",
  "CN": "8515 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para tratamiento de metales"
 },
 {
  "CNKEY": "851580900080",
  "CN": "8515 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851590000080",
  "CN": "8515 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "851590200080",
  "CN": "8515 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las máquinas de soldadura por ola de la subpartida 85151910"
 },
 {
  "CNKEY": "851590800080",
  "CN": "8515 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851600000080",
  "CN": "8516",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Calentadores eléctricos de agua de calentamiento instantáneo o acumulación y calentadores eléctricos de inmersión; aparatos eléctricos para calefacción de espacios o suelos; aparatos electrotérmicos para el cuidado del cabello (por ejemplo: secadores, rizadores, calientatenacillas) o para secar las manos; planchas eléctricas; los demás aparatos electrotérmicos de uso doméstico; resistencias calentadoras (excepto las de la partida 8545)"
 },
 {
  "CNKEY": "851610000080",
  "CN": "8516 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Calentadores eléctricos de agua de calentamiento instantáneo o acumulación y calentadores eléctricos de inmersión"
 },
 {
  "CNKEY": "851610110080",
  "CN": "8516 10 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De calentamiento instantáneo"
 },
 {
  "CNKEY": "851610800080",
  "CN": "8516 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos eléctricos para calefacción de espacios o suelos"
 },
 {
  "CNKEY": "851621000080",
  "CN": "8516 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Radiadores de acumulación"
 },
 {
  "CNKEY": "851629000080",
  "CN": "8516 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851629100080",
  "CN": "8516 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Radiadores de circulación de líquido"
 },
 {
  "CNKEY": "851629500080",
  "CN": "8516 29 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Radiadores por convección"
 },
 {
  "CNKEY": "851629910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851629910080",
  "CN": "8516 29 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Con ventilador incorporado"
 },
 {
  "CNKEY": "851629990080",
  "CN": "8516 29 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos electrotérmicos para el cuidado del cabello o para secar las manos"
 },
 {
  "CNKEY": "851631000080",
  "CN": "8516 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Secadores para el cabello"
 },
 {
  "CNKEY": "851632000080",
  "CN": "8516 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás aparatos para el cuidado del cabello"
 },
 {
  "CNKEY": "851633000080",
  "CN": "8516 33 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos para secar las manos"
 },
 {
  "CNKEY": "851640000080",
  "CN": "8516 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Planchas eléctricas"
 },
 {
  "CNKEY": "851650000080",
  "CN": "8516 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Hornos de microondas"
 },
 {
  "CNKEY": "851660000080",
  "CN": "8516 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás hornos; cocinas, hornillos (incluidas las mesas de cocción), parrillas y asadores"
 },
 {
  "CNKEY": "851660100080",
  "CN": "8516 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Cocinas"
 },
 {
  "CNKEY": "851660500080",
  "CN": "8516 60 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Hornillos (incluidas las mesas de cocción)"
 },
 {
  "CNKEY": "851660700080",
  "CN": "8516 60 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Parrillas y asadores"
 },
 {
  "CNKEY": "851660800080",
  "CN": "8516 60 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Hornos para empotrar"
 },
 {
  "CNKEY": "851660900080",
  "CN": "8516 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851671000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos electrotérmicos"
 },
 {
  "CNKEY": "851671000080",
  "CN": "8516 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos para la preparación de café o té"
 },
 {
  "CNKEY": "851672000080",
  "CN": "8516 72 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tostadoras de pan"
 },
 {
  "CNKEY": "851679000080",
  "CN": "8516 79",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851679200080",
  "CN": "8516 79 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Freidoras"
 },
 {
  "CNKEY": "851679700080",
  "CN": "8516 79 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851680000080",
  "CN": "8516 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Resistencias calentadoras"
 },
 {
  "CNKEY": "851680200080",
  "CN": "8516 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Montadas sobre un soporte de materia aislante"
 },
 {
  "CNKEY": "851680800080",
  "CN": "8516 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "851690000080",
  "CN": "8516 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851700000080",
  "CN": "8517",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Teléfonos, incluidos los teléfonos inteligentes y demás teléfonos móviles (celulares) y los de otras redes inalámbricas; los demás aparatos de emisión, transmisión o recepción de voz, imagen u otros datos, incluidos los de comunicación en red con o sin cable [tales como redes locales (LAN) o extendidas (WAN)], distintos de los aparatos de emisión, transmisión o recepción de las partidas 8443, 8525, 8527 u 8528"
 },
 {
  "CNKEY": "851711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Teléfonos, incluidos los teléfonos inteligentes y demás teléfonos móviles (celulares) y los de otras redes inalámbricas"
 },
 {
  "CNKEY": "851711000080",
  "CN": "8517 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Teléfonos de auricular inalámbrico combinado con micrófono"
 },
 {
  "CNKEY": "851713000080",
  "CN": "8517 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Teléfonos inteligentes"
 },
 {
  "CNKEY": "851714000080",
  "CN": "8517 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás teléfonos móviles (celulares) y los de otras redes inalámbricas"
 },
 {
  "CNKEY": "851718000080",
  "CN": "8517 18 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851761000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos de transmisión o recepción de voz, imagen u otros datos, incluidos los de comunicación en red con o sin cable [tales como redes locales (LAN) o extendidas (WAN)]"
 },
 {
  "CNKEY": "851761000080",
  "CN": "8517 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Estaciones base"
 },
 {
  "CNKEY": "851762000080",
  "CN": "8517 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos para la recepción, conversión, emisión y transmisión o regeneración de voz, imagen u otros datos, incluidos los de conmutación y encaminamiento (switching and routing apparatus)"
 },
 {
  "CNKEY": "851769000080",
  "CN": "8517 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851769100080",
  "CN": "8517 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Videófonos"
 },
 {
  "CNKEY": "851769200080",
  "CN": "8517 69 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Interfonos"
 },
 {
  "CNKEY": "851769300080",
  "CN": "8517 69 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos receptores de radiotelefonía o radiotelegrafía"
 },
 {
  "CNKEY": "851769900080",
  "CN": "8517 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851771000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "851771000080",
  "CN": "8517 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Antenas y reflectores de antena de cualquier tipo; partes identificables para ser utilizadas con dichos artículos"
 },
 {
  "CNKEY": "851779000080",
  "CN": "8517 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "851800000080",
  "CN": "8518",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Micrófonos y sus soportes; altavoces (altoparlantes), incluso montados en sus cajas; auriculares, incluidos los de casco, estén o no combinados con micrófono, y juegos o conjuntos constituidos por un micrófono y uno o varios altavoces (altoparlantes); amplificadores eléctricos de audiofrecuencia; equipos eléctricos para amplificación de sonido"
 },
 {
  "CNKEY": "851810000080",
  "CN": "8518 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Micrófonos y sus soportes"
 },
 {
  "CNKEY": "851821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Altavoces (altoparlantes), incluso montados en sus cajas"
 },
 {
  "CNKEY": "851821000080",
  "CN": "8518 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Un altavoz (altoparlante) montado en su caja"
 },
 {
  "CNKEY": "851822000080",
  "CN": "8518 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Varios altavoces (altoparlantes) montados en una misma caja"
 },
 {
  "CNKEY": "851829000080",
  "CN": "8518 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851830000080",
  "CN": "8518 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Auriculares, incluidos los de casco, estén o no combinados con micrófono, y juegos o conjuntos constituidos por un micrófono y uno o varios altavoces (altoparlantes)"
 },
 {
  "CNKEY": "851840000080",
  "CN": "8518 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Amplificadores eléctricos de audiofrecuencia"
 },
 {
  "CNKEY": "851850000080",
  "CN": "8518 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Equipos eléctricos para amplificación de sonido"
 },
 {
  "CNKEY": "851890000080",
  "CN": "8518 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "851900000080",
  "CN": "8519",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de grabación de sonido; aparatos de reproducción de sonido; aparatos de grabación y reproducción de sonido"
 },
 {
  "CNKEY": "851920000080",
  "CN": "8519 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos activados con monedas, billetes, tarjetas, fichas o cualquier otro medio de pago"
 },
 {
  "CNKEY": "851920100080",
  "CN": "8519 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tocadiscos que funcionen por ficha o moneda"
 },
 {
  "CNKEY": "851920910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "851920910080",
  "CN": "8519 20 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De sistema de lectura por rayo láser"
 },
 {
  "CNKEY": "851920990080",
  "CN": "8519 20 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "851930000080",
  "CN": "8519 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Giradiscos"
 },
 {
  "CNKEY": "851981000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "851981000080",
  "CN": "8519 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Que utilizan un soporte magnético, óptico o semiconductor"
 },
 {
  "CNKEY": "851989000080",
  "CN": "8519 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852100000080",
  "CN": "8521",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de grabación o reproducción de imagen y sonido (vídeos), incluso con receptor de señales de imagen y sonido incorporado"
 },
 {
  "CNKEY": "852110000080",
  "CN": "8521 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De cinta magnética"
 },
 {
  "CNKEY": "852110200080",
  "CN": "8521 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De anchura inferior o igual a 1,3 cm y que permitan la grabación o la reproducción a velocidad de avance inferior o igual a 50 mm por segundo"
 },
 {
  "CNKEY": "852110950080",
  "CN": "8521 10 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852190000080",
  "CN": "8521 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852200000080",
  "CN": "8522",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios identificables como destinados, exclusiva o principalmente, a los aparatos de la partida 8519 u 8521"
 },
 {
  "CNKEY": "852210000080",
  "CN": "8522 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cápsulas fonocaptoras"
 },
 {
  "CNKEY": "852290000080",
  "CN": "8522 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852300000080",
  "CN": "8523",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Discos, cintas, dispositivos de almacenamiento permanente de datos a base de semiconductores, tarjetas inteligentes (smart cards) y demás soportes para grabar sonido o grabaciones análogas, grabados o no, incluso las matrices y moldes galvánicos para fabricación de discos, excepto los productos del Capítulo 37"
 },
 {
  "CNKEY": "852321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Soportes magnéticos"
 },
 {
  "CNKEY": "852321000080",
  "CN": "8523 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tarjetas con banda magnética incorporada"
 },
 {
  "CNKEY": "852329000080",
  "CN": "8523 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852329150010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Cintas magnéticas; discos magnéticos"
 },
 {
  "CNKEY": "852329150080",
  "CN": "8523 29 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Sin grabar"
 },
 {
  "CNKEY": "852329190080",
  "CN": "8523 29 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "852329900080",
  "CN": "8523 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852341000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Soportes ópticos"
 },
 {
  "CNKEY": "852341000080",
  "CN": "8523 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin grabar"
 },
 {
  "CNKEY": "852341100080",
  "CN": "8523 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Discos para sistemas de lectura por rayos láser con una capacidad de grabación inferior o igual a 900 megaoctetos, que no se puedan borrar"
 },
 {
  "CNKEY": "852341300080",
  "CN": "8523 41 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Discos para sistemas de lectura por rayos láser con una capacidad de grabación superior a 900 megaoctetos pero inferior o igual a 18 gigaoctetos, que no se puedan borrar"
 },
 {
  "CNKEY": "852341900080",
  "CN": "8523 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852349000080",
  "CN": "8523 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852349100010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Discos para sistemas de lectura por rayos láser"
 },
 {
  "CNKEY": "852349100080",
  "CN": "8523 49 10",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Discos versátiles digitales (DVD)"
 },
 {
  "CNKEY": "852349200080",
  "CN": "8523 49 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852349900080",
  "CN": "8523 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852351000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Soportes semiconductores"
 },
 {
  "CNKEY": "852351000080",
  "CN": "8523 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Dispositivos de almacenamiento permanente de datos a base de semiconductores"
 },
 {
  "CNKEY": "852351100080",
  "CN": "8523 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Sin grabar"
 },
 {
  "CNKEY": "852351900080",
  "CN": "8523 51 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852352000080",
  "CN": "8523 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tarjetas inteligentes (smart cards)"
 },
 {
  "CNKEY": "852359000080",
  "CN": "8523 59",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852359100080",
  "CN": "8523 59 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Sin grabar"
 },
 {
  "CNKEY": "852359900080",
  "CN": "8523 59 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852380000080",
  "CN": "8523 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852380100080",
  "CN": "8523 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sin grabar"
 },
 {
  "CNKEY": "852380900080",
  "CN": "8523 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852400000080",
  "CN": "8524",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Módulos de visualización («display») de pantalla plana, incluso que incorporen pantallas táctiles"
 },
 {
  "CNKEY": "852411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sin controladores («drivers») ni circuitos de control"
 },
 {
  "CNKEY": "852411000080",
  "CN": "8524 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cristal líquido"
 },
 {
  "CNKEY": "852412000080",
  "CN": "8524 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De diodos emisores de luz orgánicos (OLED)"
 },
 {
  "CNKEY": "852419000080",
  "CN": "8524 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852491000080",
  "CN": "8524 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cristal líquido"
 },
 {
  "CNKEY": "852492000080",
  "CN": "8524 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De diodos emisores de luz orgánicos (OLED)"
 },
 {
  "CNKEY": "852499000080",
  "CN": "8524 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852500000080",
  "CN": "8525",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos emisores de radiodifusión o televisión, incluso con aparato receptor o de grabación o reproducción de sonido incorporado; cámaras de televisión, cámaras fotográficas digitales y videocámaras"
 },
 {
  "CNKEY": "852550000080",
  "CN": "8525 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos emisores"
 },
 {
  "CNKEY": "852560000080",
  "CN": "8525 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos emisores con aparato receptor incorporado"
 },
 {
  "CNKEY": "852581000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cámaras de televisión, cámaras digitales y videocámaras"
 },
 {
  "CNKEY": "852581000080",
  "CN": "8525 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Ultrarrápidas, especificadas en la Nota 1 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "852582000080",
  "CN": "8525 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás, resistentes a radiaciones, especificadas en la Nota 2 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "852583000080",
  "CN": "8525 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás, de visión nocturna, especificadas en la Nota 3 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "852589000080",
  "CN": "8525 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "852600000080",
  "CN": "8526",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de radar, radionavegación o radiotelemando"
 },
 {
  "CNKEY": "852610000080",
  "CN": "8526 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos de radar"
 },
 {
  "CNKEY": "852691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852691000080",
  "CN": "8526 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aparatos de radionavegación"
 },
 {
  "CNKEY": "852691200080",
  "CN": "8526 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Receptores de radionavegación"
 },
 {
  "CNKEY": "852691800080",
  "CN": "8526 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852692000080",
  "CN": "8526 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de radiotelemando"
 },
 {
  "CNKEY": "852700000080",
  "CN": "8527",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos receptores de radiodifusión, incluso combinados en la misma envoltura con grabador o reproductor de sonido o con reloj"
 },
 {
  "CNKEY": "852712000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos receptores de radiodifusión que puedan funcionar sin fuente de energía exterior"
 },
 {
  "CNKEY": "852712000080",
  "CN": "8527 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Radiocasetes de bolsillo"
 },
 {
  "CNKEY": "852713000080",
  "CN": "8527 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás aparatos combinados con grabador o reproductor de sonido"
 },
 {
  "CNKEY": "852719000080",
  "CN": "8527 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos receptores de radiodifusión que solo funcionen con fuente de energía exterior, de los tipos utilizados en vehículos automóviles"
 },
 {
  "CNKEY": "852721000080",
  "CN": "8527 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Combinados con grabador o reproductor de sonido"
 },
 {
  "CNKEY": "852721200010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Aptos para recibir y descifrar señales RDS (sistema de desciframiento de informaciones de carreteras)"
 },
 {
  "CNKEY": "852721200080",
  "CN": "8527 21 20",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De sistema de lectura por rayos láser"
 },
 {
  "CNKEY": "852721520010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852721520080",
  "CN": "8527 21 52",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De casetes con sistema de lectura analógico y digital"
 },
 {
  "CNKEY": "852721590080",
  "CN": "8527 21 59",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852721700010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852721700080",
  "CN": "8527 21 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "De sistema de lectura por rayos láser"
 },
 {
  "CNKEY": "852721920010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852721920080",
  "CN": "8527 21 92",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "De casetes con sistema de lectura analógico y digital"
 },
 {
  "CNKEY": "852721980080",
  "CN": "8527 21 98",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852729000080",
  "CN": "8527 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852791000080",
  "CN": "8527 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Combinados grabador o reproductor de sonido"
 },
 {
  "CNKEY": "852792000080",
  "CN": "8527 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sin combinar con grabador o reproductor de sonido, pero combinados con reloj"
 },
 {
  "CNKEY": "852799000080",
  "CN": "8527 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852800000080",
  "CN": "8528",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monitores y proyectores, que no incorporen aparato receptor de televisión; aparatos receptores de televisión, incluso con aparato receptor de radiodifusión o grabación o reproducción de sonido o imagen incorporado"
 },
 {
  "CNKEY": "852842000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monitores con tubo de rayos catódicos"
 },
 {
  "CNKEY": "852842000080",
  "CN": "8528 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aptos para ser conectados directamente  y diseñados para ser utilizados con una máquina automática para tratamiento o procesamiento de datos de la partida 8471"
 },
 {
  "CNKEY": "852849000080",
  "CN": "8528 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852852000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás monitores"
 },
 {
  "CNKEY": "852852000080",
  "CN": "8528 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Aptos para ser conectados directamente y diseñados para ser utilizados con una máquina automática para tratamiento o procesamiento de datos de la partida 8471"
 },
 {
  "CNKEY": "852852100080",
  "CN": "8528 52 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados exclusiva o principalmente en un sistema automático para tratamiento o procesamiento de datos de la partida 8471"
 },
 {
  "CNKEY": "852852910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852852910080",
  "CN": "8528 52 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Con pantalla de cristal líquido (LCD)"
 },
 {
  "CNKEY": "852852990080",
  "CN": "8528 52 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852859000080",
  "CN": "8528 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852862000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Proyectores"
 },
 {
  "CNKEY": "852862000080",
  "CN": "8528 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aptos para ser conectados directamente y diseñados para ser utilizados con una máquina automática para tratamiento o procesamiento de datos de la partida 8471"
 },
 {
  "CNKEY": "852869000080",
  "CN": "8528 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852869200080",
  "CN": "8528 69 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Monocromos"
 },
 {
  "CNKEY": "852869800080",
  "CN": "8528 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852871000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos receptores de televisión, incluso con aparato receptor de radiodifusión o de grabación o reproducción de sonido o imagen incorporado"
 },
 {
  "CNKEY": "852871000080",
  "CN": "8528 71",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "No concebidos para incorporar un dispositivo de visualización («display») o pantalla de vídeo"
 },
 {
  "CNKEY": "852871110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Receptores de señales de imagen y sonido (sintonizadores)"
 },
 {
  "CNKEY": "852871110080",
  "CN": "8528 71 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Conjuntos electrónicos montados, destinados a ser incorporados a una máquina automática para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "852871150080",
  "CN": "8528 71 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos con un dispositivo de microprocesador con un módem incorporado para acceder a internet y con una función de intercambio de información interactivo con capacidad para captar señales televisivas [denominados «adaptadores multimedia que desempeñan una función de comunicación», set-top boxes (STB), incluidos los que tienen incorporado un dispositivo que desempeña una función de grabación o reproducción, siempre y cuando mantengan el carácter esencial de un adaptador multimedia que desempeña una función de comunicación]"
 },
 {
  "CNKEY": "852871190080",
  "CN": "8528 71 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852871910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852871910080",
  "CN": "8528 71 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos con un dispositivo de microprocesador con un módem incorporado para acceder a internet y con una función de intercambio de información interactivo con capacidad para captar señales televisivas [denominados «adaptadores multimedia que desempeñan una función de comunicación», set-top boxes (STB), incluidos los que tienen incorporado un dispositivo que desempeña una función de grabación o reproducción, siempre y cuando mantengan el carácter esencial de un adaptador multimedia que desempeña una función de comunicación]"
 },
 {
  "CNKEY": "852871990080",
  "CN": "8528 71 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852872000080",
  "CN": "8528 72",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, en colores"
 },
 {
  "CNKEY": "852872100080",
  "CN": "8528 72 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Teleproyectores"
 },
 {
  "CNKEY": "852872200080",
  "CN": "8528 72 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con aparato de grabación o reproducción de imagen y sonido incorporado"
 },
 {
  "CNKEY": "852872300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852872300080",
  "CN": "8528 72 30",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Con tubo de imagen incorporado"
 },
 {
  "CNKEY": "852872400080",
  "CN": "8528 72 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Con pantalla de cristal líquido (LCD)"
 },
 {
  "CNKEY": "852872600080",
  "CN": "8528 72 60",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Con pantalla de plasma (PDP)"
 },
 {
  "CNKEY": "852872800080",
  "CN": "8528 72 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852873000080",
  "CN": "8528 73 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás, monocromos"
 },
 {
  "CNKEY": "852900000080",
  "CN": "8529",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes identificables como destinadas, exclusiva o principalmente, a los aparatos de las partidas 8524 a 8528"
 },
 {
  "CNKEY": "852910000080",
  "CN": "8529 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Antenas y reflectores de antena de cualquier tipo; partes identificables para ser utilizadas con dichos artículos"
 },
 {
  "CNKEY": "852910110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Antenas"
 },
 {
  "CNKEY": "852910110080",
  "CN": "8529 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Antenas telescópicas y antenas de látigo para aparatos portátiles o para aparatos que se instalan en los vehículos automóviles"
 },
 {
  "CNKEY": "852910300080",
  "CN": "8529 10 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Antenas de exterior para receptores de radio y televisión"
 },
 {
  "CNKEY": "852910650080",
  "CN": "8529 10 65",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Antenas de interior para receptores de radio y televisión, incluidas las que se incorporan a estos"
 },
 {
  "CNKEY": "852910690080",
  "CN": "8529 10 69",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "852910800080",
  "CN": "8529 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Filtros y separadores de antena"
 },
 {
  "CNKEY": "852910950080",
  "CN": "8529 10 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "852990000080",
  "CN": "8529 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "852990150080",
  "CN": "8529 90 15",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Módulos y paneles de diodos orgánicos emisores de luz para los aparatos de las subpartidas 852872 u 852873"
 },
 {
  "CNKEY": "852990180010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "852990180080",
  "CN": "8529 90 18",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De artículos de las subpartidas 85241100 y 85249100"
 },
 {
  "CNKEY": "852990200080",
  "CN": "8529 90 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De cámaras fotográficas digitales de las subpartidas 85258100, 85258200, 85258300 y 85258900; de aparatos de las subpartidas 85256000, 85284200, 85285210 y 85286200"
 },
 {
  "CNKEY": "852990400010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "852990400080",
  "CN": "8529 90 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Muebles y envolturas"
 },
 {
  "CNKEY": "852990650080",
  "CN": "8529 90 65",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "852990910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "852990910080",
  "CN": "8529 90 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Módulos de unidades de retroiluminación por diodos luminiscentes (LED), que son fuentes de iluminación que constan de uno o más LED y uno o más conectores montados en un circuito impreso u otro soporte similar, y otros componentes pasivos, incluso combinados con componentes ópticos o diodos de protección, y que se utilizan como retroiluminación para dispositivos de visualización de cristal líquido (LCD)"
 },
 {
  "CNKEY": "852990920010",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "852990920080",
  "CN": "8529 90 92",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Para las cámaras de televisión de las subpartidas 852581, 852582, 852583 y 852589, y aparatos de las partidas 8527 y 8528"
 },
 {
  "CNKEY": "852990970080",
  "CN": "8529 90 97",
  "CN_LEVEL": 9,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "853000000080",
  "CN": "8530",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos eléctricos de señalización (excepto los de transmisión de mensajes), seguridad, control o mando, para vías férreas o similares, carreteras, vías fluviales, áreas o parques de estacionamiento, instalaciones portuarias o aeropuertos (excepto los de la partida 8608)"
 },
 {
  "CNKEY": "853010000080",
  "CN": "8530 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos para vías férreas o similares"
 },
 {
  "CNKEY": "853080000080",
  "CN": "8530 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "853090000080",
  "CN": "8530 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "853100000080",
  "CN": "8531",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos eléctricos de señalización acústica o visual (por ejemplo: timbres, sirenas, tableros indicadores, avisadores de protección contra robo o incendio) (excepto los de las partidas 8512 u 8530)"
 },
 {
  "CNKEY": "853110000080",
  "CN": "8531 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Avisadores eléctricos de protección contra robo o incendio y aparatos similares"
 },
 {
  "CNKEY": "853110300080",
  "CN": "8531 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados para edificios"
 },
 {
  "CNKEY": "853110950080",
  "CN": "8531 10 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853120000080",
  "CN": "8531 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tableros indicadores con dispositivos de cristal líquido (LCD) o diodos emisores de luz (LED), incorporados"
 },
 {
  "CNKEY": "853120200080",
  "CN": "8531 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "853120400010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con dispositivos de cristal líquido (LCD)"
 },
 {
  "CNKEY": "853120400080",
  "CN": "8531 20 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De matriz activa"
 },
 {
  "CNKEY": "853120950080",
  "CN": "8531 20 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853180000080",
  "CN": "8531 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "853180400080",
  "CN": "8531 80 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Timbres de puertas, carillones, vibradores y similares"
 },
 {
  "CNKEY": "853180700080",
  "CN": "8531 80 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853190000080",
  "CN": "8531 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "853200000080",
  "CN": "8532",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Condensadores eléctricos fijos, variables o ajustables"
 },
 {
  "CNKEY": "853210000080",
  "CN": "8532 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Condensadores fijos concebidos para redes eléctricas de 50\/60 Hz, para una potencia reactiva superior o igual a 0,5 kvar (condensadores de potencia)"
 },
 {
  "CNKEY": "853221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás condensadores fijos"
 },
 {
  "CNKEY": "853221000080",
  "CN": "8532 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De tantalio"
 },
 {
  "CNKEY": "853222000080",
  "CN": "8532 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Electrolíticos de aluminio"
 },
 {
  "CNKEY": "853223000080",
  "CN": "8532 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con dieléctrico de cerámica de una sola capa"
 },
 {
  "CNKEY": "853224000080",
  "CN": "8532 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con dieléctrico de cerámica, multicapas"
 },
 {
  "CNKEY": "853225000080",
  "CN": "8532 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con dieléctrico de papel o plástico"
 },
 {
  "CNKEY": "853229000080",
  "CN": "8532 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853230000080",
  "CN": "8532 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Condensadores variables o ajustables"
 },
 {
  "CNKEY": "853290000080",
  "CN": "8532 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "853300000080",
  "CN": "8533",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Resistencias eléctricas, excepto las de calentamiento (incluidos reóstatos y potenciómetros)"
 },
 {
  "CNKEY": "853310000080",
  "CN": "8533 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Resistencias fijas de carbono, aglomeradas o de capa"
 },
 {
  "CNKEY": "853321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás resistencias fijas"
 },
 {
  "CNKEY": "853321000080",
  "CN": "8533 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 20 W"
 },
 {
  "CNKEY": "853329000080",
  "CN": "8533 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "853331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Resistencias variables bobinadas (incluidos reóstatos y potenciómetros)"
 },
 {
  "CNKEY": "853331000080",
  "CN": "8533 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 20 W"
 },
 {
  "CNKEY": "853339000080",
  "CN": "8533 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "853340000080",
  "CN": "8533 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás resistencias variables (incluidos reóstatos y potenciómetros)"
 },
 {
  "CNKEY": "853340100080",
  "CN": "8533 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De potencia inferior o igual a 20 W"
 },
 {
  "CNKEY": "853340900080",
  "CN": "8533 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "853390000080",
  "CN": "8533 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "853400000080",
  "CN": "8534 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Circuitos impresos"
 },
 {
  "CNKEY": "853400110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Que solo lleven elementos conductores y contactos"
 },
 {
  "CNKEY": "853400110080",
  "CN": "8534 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Circuitos multicapas"
 },
 {
  "CNKEY": "853400190080",
  "CN": "8534 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853400900080",
  "CN": "8534 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Que lleven otros elementos pasivos"
 },
 {
  "CNKEY": "853500000080",
  "CN": "8535",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos para corte, seccionamiento, protección, derivación, empalme o conexión de circuitos eléctricos (por ejemplo: interruptores, conmutadores, cortacircuitos, pararrayos, limitadores de tensión, supresores de sobretensión transitoria, tomas de corriente y demás conectores, cajas de empalme), para una tensión superior a 1000 V"
 },
 {
  "CNKEY": "853510000080",
  "CN": "8535 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Fusibles y cortacircuitos de fusible"
 },
 {
  "CNKEY": "853521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Disyuntores"
 },
 {
  "CNKEY": "853521000080",
  "CN": "8535 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para una tensión inferior a 72,5 kV"
 },
 {
  "CNKEY": "853529000080",
  "CN": "8535 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853530000080",
  "CN": "8535 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Seccionadores e interruptores"
 },
 {
  "CNKEY": "853530100080",
  "CN": "8535 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para una tensión inferior a 72,5 kV"
 },
 {
  "CNKEY": "853530900080",
  "CN": "8535 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853540000080",
  "CN": "8535 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pararrayos, limitadores de tensión y supresores de sobretensión transitoria"
 },
 {
  "CNKEY": "853590000080",
  "CN": "8535 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853600000080",
  "CN": "8536",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos para corte, seccionamiento, protección, derivación, empalme o conexión de circuitos eléctricos [por ejemplo: interruptores, conmutadores, relés, cortacircuitos, supresores de sobretensión transitoria, clavijas y tomas de corriente (enchufes), portalámparas y demás conectores, cajas de empalme], para una tensión inferior o igual a 1000 V; conectores de fibras ópticas, haces o cables de fibras ópticas"
 },
 {
  "CNKEY": "853610000080",
  "CN": "8536 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fusibles y cortacircuitos de fusible"
 },
 {
  "CNKEY": "853610100080",
  "CN": "8536 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades inferiores o iguales a 10 A"
 },
 {
  "CNKEY": "853610500080",
  "CN": "8536 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 10 A pero inferiores o iguales a 63 A"
 },
 {
  "CNKEY": "853610900080",
  "CN": "8536 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 63 A"
 },
 {
  "CNKEY": "853620000080",
  "CN": "8536 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Disyuntores"
 },
 {
  "CNKEY": "853620100080",
  "CN": "8536 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades inferiores o iguales a 63 A"
 },
 {
  "CNKEY": "853620900080",
  "CN": "8536 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 63 A"
 },
 {
  "CNKEY": "853630000080",
  "CN": "8536 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos para protección de circuitos eléctricos"
 },
 {
  "CNKEY": "853630100080",
  "CN": "8536 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades inferiores o iguales a 16 A"
 },
 {
  "CNKEY": "853630300080",
  "CN": "8536 30 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 16 A pero inferiores o iguales a 125 A"
 },
 {
  "CNKEY": "853630900080",
  "CN": "8536 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 125 A"
 },
 {
  "CNKEY": "853641000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Relés"
 },
 {
  "CNKEY": "853641000080",
  "CN": "8536 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para una tensión inferior o igual a 60 V"
 },
 {
  "CNKEY": "853641100080",
  "CN": "8536 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para intensidades inferiores o iguales a 2 A"
 },
 {
  "CNKEY": "853641900080",
  "CN": "8536 41 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para intensidades superiores a 2 A"
 },
 {
  "CNKEY": "853649000080",
  "CN": "8536 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853650000080",
  "CN": "8536 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás interruptores, seccionadores y conmutadores"
 },
 {
  "CNKEY": "853650030080",
  "CN": "8536 50 03",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Interruptores electrónicos de CA que consistan en circuitos de entrada y salida de acoplamiento óptico (interruptores CA de tiristor aislado)"
 },
 {
  "CNKEY": "853650050080",
  "CN": "8536 50 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Interruptores electrónicos de protección térmica, compuestos por un transistor y una microplaquita lógica (tecnología chip-on-chip)"
 },
 {
  "CNKEY": "853650070080",
  "CN": "8536 50 07",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Interruptores electromecánicos de acción rápida para una corriente inferior o igual a 11 A"
 },
 {
  "CNKEY": "853650110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853650110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Para una tensión inferior o igual a 60 V"
 },
 {
  "CNKEY": "853650110080",
  "CN": "8536 50 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De botón o pulsador"
 },
 {
  "CNKEY": "853650150080",
  "CN": "8536 50 15",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Rotativos"
 },
 {
  "CNKEY": "853650190080",
  "CN": "8536 50 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853650800080",
  "CN": "8536 50 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853661000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Portalámparas, clavijas y tomas de corriente (enchufes)"
 },
 {
  "CNKEY": "853661000080",
  "CN": "8536 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Portalámparas"
 },
 {
  "CNKEY": "853661100080",
  "CN": "8536 61 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Portalámparas Edison"
 },
 {
  "CNKEY": "853661900080",
  "CN": "8536 61 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853669000080",
  "CN": "8536 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853669100080",
  "CN": "8536 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para cables coaxiales"
 },
 {
  "CNKEY": "853669300080",
  "CN": "8536 69 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para circuitos impresos"
 },
 {
  "CNKEY": "853669900080",
  "CN": "8536 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853670000080",
  "CN": "8536 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Conectores de fibras ópticas, haces o cables de fibras ópticas"
 },
 {
  "CNKEY": "853690000080",
  "CN": "8536 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás aparatos"
 },
 {
  "CNKEY": "853690010080",
  "CN": "8536 90 01",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Elementos prefabricados para canalizaciones eléctricas"
 },
 {
  "CNKEY": "853690100080",
  "CN": "8536 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Conexiones y elementos de contacto para hilos y cables"
 },
 {
  "CNKEY": "853690400080",
  "CN": "8536 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pinzas de batería del tipo utilizado para vehículos automóviles de las partidas 8702, 8703, 8704 u 8711"
 },
 {
  "CNKEY": "853690950080",
  "CN": "8536 90 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853700000080",
  "CN": "8537",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cuadros, paneles, consolas, armarios y demás soportes equipados con varios aparatos de las partidas 8535 u 8536, para control o distribución de electricidad, incluidos los que incorporen instrumentos o aparatos del Capítulo 90, así como los aparatos de control numérico (excepto los aparatos de conmutación de la partida 8517)"
 },
 {
  "CNKEY": "853710000080",
  "CN": "8537 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para una tensión inferior o igual a 1000 V"
 },
 {
  "CNKEY": "853710100080",
  "CN": "8537 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Controles numéricos que incorporen una máquina automática para tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "853710910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853710910080",
  "CN": "8537 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos de mando con memoria programable"
 },
 {
  "CNKEY": "853710950080",
  "CN": "8537 10 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Dispositivos táctiles de entrada de datos (pantallas táctiles) sin función de visualización para su incorporación en aparatos que disponen de una pantalla de visualización, que funcionan mediante la detección de un contacto con la superficie de la pantalla"
 },
 {
  "CNKEY": "853710980080",
  "CN": "8537 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853720000080",
  "CN": "8537 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para una tensión superior a 1000 V"
 },
 {
  "CNKEY": "853720910080",
  "CN": "8537 20 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para una tensión superior a 1000 V pero inferior o igual a 72,5 kV"
 },
 {
  "CNKEY": "853720990080",
  "CN": "8537 20 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para una tensión superior a 72,5 kV"
 },
 {
  "CNKEY": "853800000080",
  "CN": "8538",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes identificables como destinadas, exclusiva o principalmente, a los aparatos de las partidas 8535, 8536 u 8537"
 },
 {
  "CNKEY": "853810000080",
  "CN": "8538 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuadros, paneles, consolas, armarios y demás soportes de la partida 8537, sin sus aparatos"
 },
 {
  "CNKEY": "853890000080",
  "CN": "8538 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "853890110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De sondas de discos (wafers) de material semiconductor"
 },
 {
  "CNKEY": "853890110080",
  "CN": "8538 90 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "853890190080",
  "CN": "8538 90 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853890910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853890910080",
  "CN": "8538 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Conjuntos electrónicos montados"
 },
 {
  "CNKEY": "853890990080",
  "CN": "8538 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853900000080",
  "CN": "8539",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lámparas y tubos eléctricos de incandescencia o de descarga, incluidos los faros o unidades «sellados» y las lámparas y tubos de rayos ultravioletas o infrarrojos; lámparas de arco; fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "853910000080",
  "CN": "8539 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Faros o unidades «sellados»"
 },
 {
  "CNKEY": "853921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás lámparas y tubos de incandescencia (excepto las de rayos ultravioletas o infrarrojos)"
 },
 {
  "CNKEY": "853921000080",
  "CN": "8539 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Halógenos, de volframio (tungsteno)"
 },
 {
  "CNKEY": "853921300080",
  "CN": "8539 21 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados en motocicletas y otros vehículos automóviles"
 },
 {
  "CNKEY": "853921920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, para una tensión"
 },
 {
  "CNKEY": "853921920080",
  "CN": "8539 21 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 100 V"
 },
 {
  "CNKEY": "853921980080",
  "CN": "8539 21 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 100 V"
 },
 {
  "CNKEY": "853922000080",
  "CN": "8539 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de potencia inferior o igual a 200 W y para una tensión superior a 100 V"
 },
 {
  "CNKEY": "853922100080",
  "CN": "8539 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Reflectores"
 },
 {
  "CNKEY": "853922900080",
  "CN": "8539 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853929000080",
  "CN": "8539 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853929300080",
  "CN": "8539 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De los tipos utilizados para motocicletas y otros vehículos automóviles"
 },
 {
  "CNKEY": "853929920010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás, para una tensión"
 },
 {
  "CNKEY": "853929920080",
  "CN": "8539 29 92",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 100 V"
 },
 {
  "CNKEY": "853929980080",
  "CN": "8539 29 98",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Inferior o igual a 100 V"
 },
 {
  "CNKEY": "853931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lámparas y tubos de descarga (excepto los de rayos ultravioletas)"
 },
 {
  "CNKEY": "853931000080",
  "CN": "8539 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fluorescentes, de cátodo caliente"
 },
 {
  "CNKEY": "853931100080",
  "CN": "8539 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De dos casquillos"
 },
 {
  "CNKEY": "853931900080",
  "CN": "8539 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853932000080",
  "CN": "8539 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Lámparas de vapor de mercurio o sodio; lámparas de halogenuro metálico"
 },
 {
  "CNKEY": "853932200080",
  "CN": "8539 32 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De vapor de mercurio o sodio"
 },
 {
  "CNKEY": "853932900080",
  "CN": "8539 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De halogenuro metálico"
 },
 {
  "CNKEY": "853939000080",
  "CN": "8539 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "853939200080",
  "CN": "8539 39 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Lámparas fluorescentes de cátodo frío para la retroiluminación de dispositivos de visualización de pantalla plana"
 },
 {
  "CNKEY": "853939800080",
  "CN": "8539 39 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853941000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lámparas y tubos de rayos ultravioletas o infrarrojos; lámparas de arco"
 },
 {
  "CNKEY": "853941000080",
  "CN": "8539 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Lámparas de arco"
 },
 {
  "CNKEY": "853949000080",
  "CN": "8539 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "853951000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "853951000080",
  "CN": "8539 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Módulos de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "853952000080",
  "CN": "8539 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Lámparas y tubos de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "853990000080",
  "CN": "8539 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "853990100080",
  "CN": "8539 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Casquillos"
 },
 {
  "CNKEY": "853990900080",
  "CN": "8539 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "854000000080",
  "CN": "8540",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lámparas, tubos y válvulas electrónicos, de cátodo caliente, cátodo frío o fotocátodo (por ejemplo: lámparas, tubos y válvulas, de vacío, de vapor o gas, tubos rectificadores de vapor de mercurio, tubos de rayos catódicos, tubos y válvulas para cámaras de televisión) (excepto los de la partida 8539)"
 },
 {
  "CNKEY": "854011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos de rayos catódicos para aparatos receptores de televisión, incluso para videomonitores"
 },
 {
  "CNKEY": "854011000080",
  "CN": "8540 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "En colores"
 },
 {
  "CNKEY": "854012000080",
  "CN": "8540 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Monocromos"
 },
 {
  "CNKEY": "854020000080",
  "CN": "8540 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos para cámaras de televisión; tubos convertidores o intensificadores de imagen; los demás tubos de fotocátodo"
 },
 {
  "CNKEY": "854020100080",
  "CN": "8540 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tubos para cámaras de televisión"
 },
 {
  "CNKEY": "854020800080",
  "CN": "8540 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854040000080",
  "CN": "8540 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tubos para visualizar datos gráficos monocromos; tubos para visualizar datos gráficos en colores, con pantalla fosfórica de separación de puntos inferior a 0,4 mm"
 },
 {
  "CNKEY": "854060000080",
  "CN": "8540 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás tubos de rayos catódicos"
 },
 {
  "CNKEY": "854071000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tubos para hiperfrecuencias (por ejemplo: magnetrones, klistrones, tubos de ondas progresivas, carcinotrones) (excepto los controlados por rejillas)"
 },
 {
  "CNKEY": "854071000080",
  "CN": "8540 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Magnetrones"
 },
 {
  "CNKEY": "854079000080",
  "CN": "8540 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854081000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás lámparas, tubos y válvulas"
 },
 {
  "CNKEY": "854081000080",
  "CN": "8540 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Tubos receptores o amplificadores"
 },
 {
  "CNKEY": "854089000080",
  "CN": "8540 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854091000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "854091000080",
  "CN": "8540 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De tubos de rayos catódicos"
 },
 {
  "CNKEY": "854099000080",
  "CN": "8540 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "854100000080",
  "CN": "8541",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Dispositivos semiconductores (por ejemplo: diodos, transistores, transductores basados en semiconductores); dispositivos semiconductores fotosensibles, incluidas las células fotovoltaicas, aunque estén ensambladas en módulos o paneles; diodos emisores de luz (LED), incluso ensamblados con otros diodos emisores de luz (LED); cristales piezoeléctricos montados"
 },
 {
  "CNKEY": "854110000080",
  "CN": "8541 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Diodos, excepto los fotodiodos y los diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "854121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Transistores (excepto los fototransistores)"
 },
 {
  "CNKEY": "854121000080",
  "CN": "8541 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con una capacidad de disipación inferior a 1 W"
 },
 {
  "CNKEY": "854129000080",
  "CN": "8541 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854130000080",
  "CN": "8541 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tiristores, diacs y triacs (excepto los diapositivos fotosensibles)"
 },
 {
  "CNKEY": "854141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Dispositivos semiconductores fotosensibles, incluidas las células fotovoltaicas, aunque estén ensambladas en módulos o paneles; diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "854141000080",
  "CN": "8541 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "854142000080",
  "CN": "8541 42 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Células fotovoltaicas sin ensamblar en módulos ni paneles"
 },
 {
  "CNKEY": "854143000080",
  "CN": "8541 43 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Células fotovoltaicas ensambladas en módulos o paneles"
 },
 {
  "CNKEY": "854149000080",
  "CN": "8541 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854151000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás dispositivos semiconductores"
 },
 {
  "CNKEY": "854151000080",
  "CN": "8541 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Transductores basados en semiconductores"
 },
 {
  "CNKEY": "854159000080",
  "CN": "8541 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854160000080",
  "CN": "8541 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cristales piezoeléctricos montados"
 },
 {
  "CNKEY": "854190000080",
  "CN": "8541 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "854200000080",
  "CN": "8542",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Circuitos electrónicos integrados"
 },
 {
  "CNKEY": "854231000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Circuitos electrónicos integrados"
 },
 {
  "CNKEY": "854231000080",
  "CN": "8542 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Procesadores y controladores, incluso combinados con memorias, convertidores, circuitos lógicos, amplificadores, relojes y circuitos de sincronización, u otros circuitos"
 },
 {
  "CNKEY": "854231110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las mercancías especificadas en la Nota 12 b) 3) y 4) de este Capítulo"
 },
 {
  "CNKEY": "854231110080",
  "CN": "8542 31 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Circuitos integrados de componentes múltiples (MCO)"
 },
 {
  "CNKEY": "854231190080",
  "CN": "8542 31 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854231900080",
  "CN": "8542 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854232000080",
  "CN": "8542 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Memorias"
 },
 {
  "CNKEY": "854232110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las mercancías especificadas en la Nota 12 b) 3) y 4) de este Capítulo"
 },
 {
  "CNKEY": "854232110080",
  "CN": "8542 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Circuitos integrados de componentes múltiples (MCO)"
 },
 {
  "CNKEY": "854232190080",
  "CN": "8542 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854232310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "854232310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Memoria dinámica de lectura-escritura de acceso aleatorio (D-RAMs)"
 },
 {
  "CNKEY": "854232310080",
  "CN": "8542 32 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad de almacenamiento inferior o igual a 512 Mbits"
 },
 {
  "CNKEY": "854232390080",
  "CN": "8542 32 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad de almacenamiento superior a 512 Mbits"
 },
 {
  "CNKEY": "854232450080",
  "CN": "8542 32 45",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Memoria estática de lectura-escritura de acceso aleatorio (S-RAMs), incluida la memoria-cache de lectura-escritura de acceso aleatorio (cache-RAMs)"
 },
 {
  "CNKEY": "854232550080",
  "CN": "8542 32 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Memoria exclusivamente de lectura, programable, que se pueda borrar mediante rayos ultravioletas (EPROMs)"
 },
 {
  "CNKEY": "854232610010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Memoria exclusivamente de lectura, programable, que se pueda borrar eléctricamente (E²PROMs), incluidas las flash E²PROMs"
 },
 {
  "CNKEY": "854232610020",
  "CN": null,
  "CN_LEVEL": 8,
  "T_SU_SU": null,
  "ES": "Flash E²PROMs"
 },
 {
  "CNKEY": "854232610080",
  "CN": "8542 32 61",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad de almacenamiento inferior o igual a 512 Mbits"
 },
 {
  "CNKEY": "854232690080",
  "CN": "8542 32 69",
  "CN_LEVEL": 9,
  "T_SU_SU": "p\/st",
  "ES": "Con una capacidad de almacenamiento superior a 512 Mbits"
 },
 {
  "CNKEY": "854232750080",
  "CN": "8542 32 75",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "854232900080",
  "CN": "8542 32 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás memorias"
 },
 {
  "CNKEY": "854233000080",
  "CN": "8542 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Amplificadores"
 },
 {
  "CNKEY": "854233100080",
  "CN": "8542 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Circuitos integrados de componentes múltiples (MCO)"
 },
 {
  "CNKEY": "854233900080",
  "CN": "8542 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854239000080",
  "CN": "8542 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854239110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Las mercancías especificadas en la Nota 12 b) 3) y 4) de este Capítulo"
 },
 {
  "CNKEY": "854239110080",
  "CN": "8542 39 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Circuitos integrados de componentes múltiples (MCO)"
 },
 {
  "CNKEY": "854239190080",
  "CN": "8542 39 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854239900080",
  "CN": "8542 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854290000080",
  "CN": "8542 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "854300000080",
  "CN": "8543",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos eléctricos con función propia, no expresados ni comprendidos en otra parte de este Capítulo"
 },
 {
  "CNKEY": "854310000080",
  "CN": "8543 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aceleradores de partículas"
 },
 {
  "CNKEY": "854320000080",
  "CN": "8543 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Generadores de señales"
 },
 {
  "CNKEY": "854330000080",
  "CN": "8543 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos de galvanoplastia, electrólisis o electroforesis"
 },
 {
  "CNKEY": "854330400080",
  "CN": "8543 30 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de galvanoplastia o electrólisis del tipo utilizado, exclusiva o principalmente, para la fabricación de circuitos impresos"
 },
 {
  "CNKEY": "854330700080",
  "CN": "8543 30 70",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854340000080",
  "CN": "8543 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cigarrillos electrónicos y dispositivos personales de vaporización eléctricos similares"
 },
 {
  "CNKEY": "854370000080",
  "CN": "8543 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "854370010080",
  "CN": "8543 70 01",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos específicamente concebidos para su conexión a telégrafos, teléfonos, instrumentos similares o redes de telégrafos o teléfonos"
 },
 {
  "CNKEY": "854370020080",
  "CN": "8543 70 02",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Amplificadores de microondas"
 },
 {
  "CNKEY": "854370030080",
  "CN": "8543 70 03",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos inalámbricos de control remoto por infrarrojos para consolas de videojuegos"
 },
 {
  "CNKEY": "854370040080",
  "CN": "8543 70 04",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Grabadores digitales de datos de vuelo"
 },
 {
  "CNKEY": "854370050080",
  "CN": "8543 70 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Lectores electrónicos portátiles alimentados con batería para la grabación y reproducción de archivos de texto, imagen fija o audio"
 },
 {
  "CNKEY": "854370060080",
  "CN": "8543 70 06",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de procesamiento de señales digitales aptos para ser conectados a redes con o sin cables y empleados para mezclar sonido"
 },
 {
  "CNKEY": "854370070080",
  "CN": "8543 70 07",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos de enseñanza portátiles, electrónicos e interactivos especialmente diseñados para los niños"
 },
 {
  "CNKEY": "854370080080",
  "CN": "8543 70 08",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas de limpieza por plasma que eliminan contaminantes orgánicos de muestras y portamuestras de microscopia electrónica"
 },
 {
  "CNKEY": "854370090080",
  "CN": "8543 70 09",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos táctiles de entrada de datos (pantallas táctiles) sin función de visualización para su incorporación en aparatos que disponen de una pantalla de visualización, que funcionan mediante la detección de un contacto con la superficie de la pantalla"
 },
 {
  "CNKEY": "854370100080",
  "CN": "8543 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Máquinas eléctricas con funciones de traducción o de diccionario"
 },
 {
  "CNKEY": "854370300080",
  "CN": "8543 70 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Amplificadores de antena"
 },
 {
  "CNKEY": "854370500080",
  "CN": "8543 70 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bancos y techos solares, y aparatos similares para el bronceado"
 },
 {
  "CNKEY": "854370600080",
  "CN": "8543 70 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Electrificadores de cercas"
 },
 {
  "CNKEY": "854370900080",
  "CN": "8543 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854390000080",
  "CN": "8543 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "854400000080",
  "CN": "8544",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Hilos, cables (incluidos los coaxiales) y demás conductores aislados para electricidad, aunque estén laqueados, anodizados o provistos de piezas de conexión; cables de fibras ópticas constituidos por fibras enfundadas individualmente, incluso con conductores eléctricos o provistos de piezas de conexión"
 },
 {
  "CNKEY": "854411000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Alambre para bobinar"
 },
 {
  "CNKEY": "854411000080",
  "CN": "8544 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cobre"
 },
 {
  "CNKEY": "854411100080",
  "CN": "8544 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Esmaltado o laqueado"
 },
 {
  "CNKEY": "854411900080",
  "CN": "8544 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854419000080",
  "CN": "8544 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854420000080",
  "CN": "8544 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cables y demás conductores eléctricos, coaxiales"
 },
 {
  "CNKEY": "854430000080",
  "CN": "8544 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Juegos de cables para bujías de encendido y demás juegos de cables de los tipos utilizados en los medios de transporte"
 },
 {
  "CNKEY": "854442000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás conductores eléctricos para una tensión inferior o igual a 1000 V"
 },
 {
  "CNKEY": "854442000080",
  "CN": "8544 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Provistos de piezas de conexión"
 },
 {
  "CNKEY": "854442100080",
  "CN": "8544 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en telecomunicación"
 },
 {
  "CNKEY": "854442900080",
  "CN": "8544 42 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854449000080",
  "CN": "8544 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854449200080",
  "CN": "8544 49 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en telecomunicación para una tensión inferior o igual a 80 V"
 },
 {
  "CNKEY": "854449910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854449910080",
  "CN": "8544 49 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Hilos y cables, con cabos de diámetro superior a 0,51 mm"
 },
 {
  "CNKEY": "854449930010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854449930080",
  "CN": "8544 49 93",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "para una tensión inferior o igual a 80 V"
 },
 {
  "CNKEY": "854449950080",
  "CN": "8544 49 95",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "para una tensión superior a 80 V pero inferior a 1000 V"
 },
 {
  "CNKEY": "854449990080",
  "CN": "8544 49 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "para una tensión de 1000 V"
 },
 {
  "CNKEY": "854460000080",
  "CN": "8544 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás conductores eléctricos para una tensión superior a 1000 V"
 },
 {
  "CNKEY": "854460100080",
  "CN": "8544 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con conductores de cobre"
 },
 {
  "CNKEY": "854460900080",
  "CN": "8544 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con otros conductores"
 },
 {
  "CNKEY": "854470000080",
  "CN": "8544 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cables de fibras ópticas"
 },
 {
  "CNKEY": "854500000080",
  "CN": "8545",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Electrodos y escobillas de carbón, carbón para lámparas o pilas y demás artículos de grafito u otros carbonos, incluso con metal, para usos eléctricos"
 },
 {
  "CNKEY": "854511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Electrodos"
 },
 {
  "CNKEY": "854511000080",
  "CN": "8545 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados en hornos"
 },
 {
  "CNKEY": "854519000080",
  "CN": "8545 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854520000080",
  "CN": "8545 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Escobillas"
 },
 {
  "CNKEY": "854590000080",
  "CN": "8545 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854590100080",
  "CN": "8545 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Resistencias calentadoras"
 },
 {
  "CNKEY": "854590900080",
  "CN": "8545 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854600000080",
  "CN": "8546",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aisladores eléctricos de cualquier materia"
 },
 {
  "CNKEY": "854610000080",
  "CN": "8546 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "854620000080",
  "CN": "8546 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De cerámica"
 },
 {
  "CNKEY": "854690000080",
  "CN": "8546 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854690100080",
  "CN": "8546 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "854690900080",
  "CN": "8546 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854700000080",
  "CN": "8547",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Piezas aislantes totalmente de materia aislante o con simples piezas metálicas de ensamblado (por ejemplo: casquillos roscados) embutidas en la masa, para máquinas, aparatos o instalaciones eléctricas (excepto los aisladores de la partida 8546); tubos aisladores y sus piezas de unión, de metal común, aislados interiormente"
 },
 {
  "CNKEY": "854710000080",
  "CN": "8547 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piezas aislantes de cerámica"
 },
 {
  "CNKEY": "854720000080",
  "CN": "8547 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Piezas aislantes de plástico"
 },
 {
  "CNKEY": "854790000080",
  "CN": "8547 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854800000080",
  "CN": "8548 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes eléctricas de máquinas o aparatos, no expresadas ni comprendidas en otra parte de este Capítulo"
 },
 {
  "CNKEY": "854800200080",
  "CN": "8548 00 20",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Memorias dinámicas de lectura-escritura de acceso aleatorio (D-RAMs) combinadas de diversas formas, por ejemplo: apiladas, montadas en módulos"
 },
 {
  "CNKEY": "854800300080",
  "CN": "8548 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Módulos de unidades de retroiluminación por diodos luminiscentes (LED), que son fuentes de iluminación que constan de uno o más LED y uno o más conectores montados en un circuito impreso u otro soporte similar, y otros componentes pasivos, incluso combinados con componentes ópticos o diodos de protección, y se utilizan como retroiluminación para dispositivos de visualización de cristal líquido (LCD)"
 },
 {
  "CNKEY": "854800900080",
  "CN": "8548 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854900000080",
  "CN": "8549",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, eléctricos y electrónicos"
 },
 {
  "CNKEY": "854911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos, de pilas, baterías de pilas o acumuladores eléctricos; pilas, baterías de pilas o acumuladores eléctricos, inservibles"
 },
 {
  "CNKEY": "854911000080",
  "CN": "8549 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Desperdicios y desechos de acumuladores de plomo y ácido; acumuladores de plomo y ácido inservibles"
 },
 {
  "CNKEY": "854911100080",
  "CN": "8549 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acumuladores de plomo y ácido inservibles"
 },
 {
  "CNKEY": "854911900080",
  "CN": "8549 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Desperdicios y desechos de acumuladores de plomo y ácido"
 },
 {
  "CNKEY": "854912000080",
  "CN": "8549 12",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, que contengan plomo, cadmio o mercurio"
 },
 {
  "CNKEY": "854912100080",
  "CN": "8549 12 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas y baterías de pilas eléctricas inservibles"
 },
 {
  "CNKEY": "854912200080",
  "CN": "8549 12 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acumuladores eléctricos inservibles"
 },
 {
  "CNKEY": "854912900080",
  "CN": "8549 12 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854913000080",
  "CN": "8549 13",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Clasificados por tipo de componente químico, que no contengan plomo, cadmio o mercurio"
 },
 {
  "CNKEY": "854913100080",
  "CN": "8549 13 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas y baterías de pilas eléctricas inservibles"
 },
 {
  "CNKEY": "854913200080",
  "CN": "8549 13 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acumuladores eléctricos inservibles"
 },
 {
  "CNKEY": "854913900080",
  "CN": "8549 13 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854914000080",
  "CN": "8549 14",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Sin clasificar, que no contengan plomo, cadmio o mercurio"
 },
 {
  "CNKEY": "854914100080",
  "CN": "8549 14 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas y baterías de pilas eléctricas inservibles"
 },
 {
  "CNKEY": "854914200080",
  "CN": "8549 14 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acumuladores eléctricos inservibles"
 },
 {
  "CNKEY": "854914900080",
  "CN": "8549 14 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854919000080",
  "CN": "8549 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854919100080",
  "CN": "8549 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Pilas y baterías de pilas eléctricas inservibles"
 },
 {
  "CNKEY": "854919200080",
  "CN": "8549 19 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Acumuladores eléctricos inservibles"
 },
 {
  "CNKEY": "854919900080",
  "CN": "8549 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854921000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De los tipos utilizados principalmente para la recuperación de metales preciosos"
 },
 {
  "CNKEY": "854921000080",
  "CN": "8549 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan pilas, baterías de pilas, acumuladores eléctricos, interruptores de mercurio, vidrio de tubos de rayos catódicos u otros vidrios activados, o componentes eléctricos o electrónicos que contengan cadmio, mercurio, plomo o bifenilos policlorados (PCB)"
 },
 {
  "CNKEY": "854929000080",
  "CN": "8549 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854931000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás ensamblajes eléctricos y electrónicos, y tarjetas de circuitos impresos"
 },
 {
  "CNKEY": "854931000080",
  "CN": "8549 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan pilas, baterías de pilas, acumuladores eléctricos, interruptores de mercurio, vidrio de tubos de rayos catódicos u otros vidrios activados, o componentes eléctricos o electrónicos que contengan cadmio, mercurio, plomo o bifenilos policlorados (PCB)"
 },
 {
  "CNKEY": "854939000080",
  "CN": "8549 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "854991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "854991000080",
  "CN": "8549 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Que contengan pilas, baterías de pilas, acumuladores eléctricos, interruptores de mercurio, vidrio de tubos de rayos catódicos u otros vidrios activados, o componentes eléctricos o electrónicos que contengan cadmio, mercurio, plomo o bifenilos policlorados (PCB)"
 },
 {
  "CNKEY": "854999000080",
  "CN": "8549 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860011000090",
  "CN": "XVII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XVII - MATERIAL DE TRANSPORTE"
 },
 {
  "CNKEY": "860021000090",
  "CN": "86",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 86 - VEHÍCULOS Y MATERIAL PARA VÍAS FÉRREAS O SIMILARES, Y SUS PARTES; APARATOS MECÁNICOS, INCLUSO ELECTROMECÁNICOS, DE SEÑALIZACIÓN PARA VÍAS DE COMUNICACIÓN"
 },
 {
  "CNKEY": "860100000080",
  "CN": "8601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Locomotoras y locotractores, de fuente externa de electricidad o acumuladores eléctricos"
 },
 {
  "CNKEY": "860110000080",
  "CN": "8601 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fuente externa de electricidad"
 },
 {
  "CNKEY": "860120000080",
  "CN": "8601 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De acumuladores eléctricos"
 },
 {
  "CNKEY": "860200000080",
  "CN": "8602",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás locomotoras y locotractores; ténderes"
 },
 {
  "CNKEY": "860210000080",
  "CN": "8602 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Locomotoras diésel-eléctricas"
 },
 {
  "CNKEY": "860290000080",
  "CN": "8602 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860300000080",
  "CN": "8603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Automotores para vías férreas y tranvías autopropulsados, excepto los de la partida 8604"
 },
 {
  "CNKEY": "860310000080",
  "CN": "8603 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "De fuente externa de electricidad"
 },
 {
  "CNKEY": "860390000080",
  "CN": "8603 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860400000080",
  "CN": "8604 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Vehículos para mantenimiento o servicio de vías férreas o similares, incluso autopropulsados (por ejemplo: vagones taller, vagones grúa, vagones equipados para apisonar balasto, alinear vías, coches para ensayos y vagonetas de inspección de vías)"
 },
 {
  "CNKEY": "860500000080",
  "CN": "8605 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Coches de viajeros, furgones de equipajes, coches correo y demás coches especiales, para vías férreas o similares (excepto los coches de la partida 8604)"
 },
 {
  "CNKEY": "860600000080",
  "CN": "8606",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vagones para transporte de mercancías sobre carriles (rieles)"
 },
 {
  "CNKEY": "860610000080",
  "CN": "8606 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Vagones cisterna y similares"
 },
 {
  "CNKEY": "860630000080",
  "CN": "8606 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Vagones de descarga automática, excepto los de la subpartida 860610"
 },
 {
  "CNKEY": "860691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "860691000080",
  "CN": "8606 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cubiertos y cerrados"
 },
 {
  "CNKEY": "860691100080",
  "CN": "8606 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transporte de productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "860691800080",
  "CN": "8606 91 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860692000080",
  "CN": "8606 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Abiertos, con pared fija de altura superior a 60 cm"
 },
 {
  "CNKEY": "860699000080",
  "CN": "8606 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860700000080",
  "CN": "8607",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes de vehículos para vías férreas o similares"
 },
 {
  "CNKEY": "860711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bojes, «bissels», ejes y ruedas, y sus partes"
 },
 {
  "CNKEY": "860711000080",
  "CN": "8607 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bojes y «bissels», de tracción"
 },
 {
  "CNKEY": "860712000080",
  "CN": "8607 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás bojes y «bissels»"
 },
 {
  "CNKEY": "860719000080",
  "CN": "8607 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las partes"
 },
 {
  "CNKEY": "860719100080",
  "CN": "8607 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ejes montados o sin montar; ruedas y sus partes"
 },
 {
  "CNKEY": "860719900080",
  "CN": "8607 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes de bojes, «bissels» y similares"
 },
 {
  "CNKEY": "860721000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frenos y sus partes"
 },
 {
  "CNKEY": "860721000080",
  "CN": "8607 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Frenos de aire comprimido y sus partes"
 },
 {
  "CNKEY": "860721100080",
  "CN": "8607 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Colados o moldeados, de fundición, hierro o acero"
 },
 {
  "CNKEY": "860721900080",
  "CN": "8607 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860729000080",
  "CN": "8607 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "860730000080",
  "CN": "8607 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Ganchos y demás sistemas de enganche, topes, y sus partes"
 },
 {
  "CNKEY": "860791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "860791000080",
  "CN": "8607 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De locomotoras o locotractores"
 },
 {
  "CNKEY": "860791100080",
  "CN": "8607 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cajas de engrase y sus partes"
 },
 {
  "CNKEY": "860791900080",
  "CN": "8607 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "860799000080",
  "CN": "8607 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "860799100080",
  "CN": "8607 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cajas de engrase y sus partes"
 },
 {
  "CNKEY": "860799800080",
  "CN": "8607 99 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "860800000080",
  "CN": "8608 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Material fijo de vías férreas o similares; aparatos mecánicos (incluso electromecánicos) de señalización, seguridad, control o mando para vías férreas o similares, carreteras o vías fluviales, áreas o parques de estacionamiento, instalaciones portuarias o aeropuertos; sus partes"
 },
 {
  "CNKEY": "860900000080",
  "CN": "8609 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Contenedores (incluidos los contenedores cisterna y los contenedores depósito) especialmente concebidos y equipados para uno o varios medios de transporte"
 },
 {
  "CNKEY": "860900100080",
  "CN": "8609 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Contenedores con blindaje protector de plomo contra las radiaciones, para el transporte de materias radioactivas (Euratom)"
 },
 {
  "CNKEY": "860900900080",
  "CN": "8609 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870021000090",
  "CN": "87",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 87 - VEHÍCULOS AUTOMÓVILES, TRACTORES, VELOCÍPEDOS Y DEMÁS VEHÍCULOS TERRESTRES, SUS PARTES Y ACCESORIOS"
 },
 {
  "CNKEY": "870100000080",
  "CN": "8701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Tractores (excepto las carretillas tractor de la partida 8709)"
 },
 {
  "CNKEY": "870110000080",
  "CN": "8701 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tractores de un solo eje"
 },
 {
  "CNKEY": "870121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Tractores de carretera para semirremolques"
 },
 {
  "CNKEY": "870121000080",
  "CN": "8701 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Únicamente con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel)"
 },
 {
  "CNKEY": "870121100080",
  "CN": "8701 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870121900080",
  "CN": "8701 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870122000080",
  "CN": "8701 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Equipados para la propulsión con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel) y con motor eléctrico"
 },
 {
  "CNKEY": "870122100080",
  "CN": "8701 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870122900080",
  "CN": "8701 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870123000080",
  "CN": "8701 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Equipados para la propulsión con motor de émbolo (pistón), de encendido por chispa y con motor eléctrico"
 },
 {
  "CNKEY": "870123100080",
  "CN": "8701 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870123900080",
  "CN": "8701 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870124000080",
  "CN": "8701 24",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Únicamente propulsados con motor eléctrico"
 },
 {
  "CNKEY": "870124100080",
  "CN": "8701 24 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870124900080",
  "CN": "8701 24 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870129000080",
  "CN": "8701 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870130000080",
  "CN": "8701 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tractores de orugas"
 },
 {
  "CNKEY": "870191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, con motor de potencia"
 },
 {
  "CNKEY": "870191000080",
  "CN": "8701 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Inferior o igual a 18 kW"
 },
 {
  "CNKEY": "870191100080",
  "CN": "8701 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tractores agrícolas y tractores forestales, de ruedas"
 },
 {
  "CNKEY": "870191900080",
  "CN": "8701 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870192000080",
  "CN": "8701 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior a 18 kW pero inferior o igual a 37 kW"
 },
 {
  "CNKEY": "870192100080",
  "CN": "8701 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tractores agrícolas y tractores forestales, de ruedas"
 },
 {
  "CNKEY": "870192900080",
  "CN": "8701 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870193000080",
  "CN": "8701 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior a 37 kW pero inferior o igual a 75 kW"
 },
 {
  "CNKEY": "870193100080",
  "CN": "8701 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tractores agrícolas y tractores forestales, de ruedas"
 },
 {
  "CNKEY": "870193900080",
  "CN": "8701 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870194000080",
  "CN": "8701 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior a 75 kW but pero inferior o igual a 130 kW"
 },
 {
  "CNKEY": "870194100080",
  "CN": "8701 94 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tractores agrícolas y tractores forestales, de ruedas"
 },
 {
  "CNKEY": "870194900080",
  "CN": "8701 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870195000080",
  "CN": "8701 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Superior a 130 kW"
 },
 {
  "CNKEY": "870195100080",
  "CN": "8701 95 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Tractores agrícolas y tractores forestales, de ruedas"
 },
 {
  "CNKEY": "870195900080",
  "CN": "8701 95 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870200000080",
  "CN": "8702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vehículos automóviles para transporte de diez o más personas, incluido el conductor"
 },
 {
  "CNKEY": "870210000080",
  "CN": "8702 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Únicamente con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel)"
 },
 {
  "CNKEY": "870210110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 2500 cm³"
 },
 {
  "CNKEY": "870210110080",
  "CN": "8702 10 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870210190080",
  "CN": "8702 10 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870210910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada inferior o igual a 2500 cm³"
 },
 {
  "CNKEY": "870210910080",
  "CN": "8702 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870210990080",
  "CN": "8702 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870220000080",
  "CN": "8702 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Equipados para la propulsión con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel) y con motor eléctrico"
 },
 {
  "CNKEY": "870220100080",
  "CN": "8702 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 2500 cm³"
 },
 {
  "CNKEY": "870220900080",
  "CN": "8702 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada inferior o igual a 2500 cm³"
 },
 {
  "CNKEY": "870230000080",
  "CN": "8702 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Equipados para la propulsión con motor de émbolo (pistón), de encendido por chispa y con motor eléctrico"
 },
 {
  "CNKEY": "870230100080",
  "CN": "8702 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 2800 cm³"
 },
 {
  "CNKEY": "870230900080",
  "CN": "8702 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada inferior o igual a 2800 cm³"
 },
 {
  "CNKEY": "870240000080",
  "CN": "8702 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Únicamente propulsados con motor eléctrico"
 },
 {
  "CNKEY": "870290000080",
  "CN": "8702 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870290110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con motor de émbolo (pistón), de encendido por chispa"
 },
 {
  "CNKEY": "870290110020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 2800 cm³"
 },
 {
  "CNKEY": "870290110080",
  "CN": "8702 90 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870290190080",
  "CN": "8702 90 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870290310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De cilindrada inferior o igual a 2800 cm³"
 },
 {
  "CNKEY": "870290310080",
  "CN": "8702 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870290390080",
  "CN": "8702 90 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870290900080",
  "CN": "8702 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870300000080",
  "CN": "8703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Automóviles de turismo y demás vehículos automóviles diseñados principalmente para transporte de personas (excepto los de la partida 8702), incluidos los del tipo familiar (break o station wagon) y los de carreras"
 },
 {
  "CNKEY": "870310000080",
  "CN": "8703 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vehículos especialmente diseñados para desplazarse sobre nieve; vehículos especiales para transporte de personas en campos de golf y vehículos similares"
 },
 {
  "CNKEY": "870310110080",
  "CN": "8703 10 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Vehículos especialmente concebidos para desplazarse sobre la nieve, con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel) o con motor de émbolo (pistón), de encendido por chispa"
 },
 {
  "CNKEY": "870310180080",
  "CN": "8703 10 18",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vehículos, únicamente con motor de émbolo (pistón), de encendido por chispa"
 },
 {
  "CNKEY": "870321000080",
  "CN": "8703 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada inferior o igual a 1000 cm³"
 },
 {
  "CNKEY": "870321100080",
  "CN": "8703 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870321900080",
  "CN": "8703 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870322000080",
  "CN": "8703 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 1000 cm³ pero inferior o igual a 1500 cm³"
 },
 {
  "CNKEY": "870322100080",
  "CN": "8703 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870322900080",
  "CN": "8703 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870323000080",
  "CN": "8703 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 1500 cm³ pero inferior o igual a 3000 cm³"
 },
 {
  "CNKEY": "870323110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870323110080",
  "CN": "8703 23 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Auto-caravanas"
 },
 {
  "CNKEY": "870323190080",
  "CN": "8703 23 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870323900080",
  "CN": "8703 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870324000080",
  "CN": "8703 24",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 3000 cm³"
 },
 {
  "CNKEY": "870324100080",
  "CN": "8703 24 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870324900080",
  "CN": "8703 24 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vehículos ùnicamente de motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel)"
 },
 {
  "CNKEY": "870331000080",
  "CN": "8703 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada inferior o igual a 1500 cm³"
 },
 {
  "CNKEY": "870331100080",
  "CN": "8703 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870331900080",
  "CN": "8703 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870332000080",
  "CN": "8703 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 1500 cm³ pero inferior o igual a 2500 cm³"
 },
 {
  "CNKEY": "870332110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870332110080",
  "CN": "8703 32 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Auto-caravanas"
 },
 {
  "CNKEY": "870332190080",
  "CN": "8703 32 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870332900080",
  "CN": "8703 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870333000080",
  "CN": "8703 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De cilindrada superior a 2500 cm³"
 },
 {
  "CNKEY": "870333110010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870333110080",
  "CN": "8703 33 11",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Auto-caravanas"
 },
 {
  "CNKEY": "870333190080",
  "CN": "8703 33 19",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870333900080",
  "CN": "8703 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870340000080",
  "CN": "8703 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vehículos, equipados para la propulsión con motor de émbolo (pistón), de encendido por chispa y con motor eléctrico, excepto los que se puedan cargar mediante conexión a una fuente externa de alimentación eléctrica"
 },
 {
  "CNKEY": "870340100080",
  "CN": "8703 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870340900080",
  "CN": "8703 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870350000080",
  "CN": "8703 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás vehículos, equipados para la propulsión con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel) y con motor eléctrico, excepto los que se puedan cargar mediante conexión a una fuente externa de alimentación eléctrica"
 },
 {
  "CNKEY": "870360000080",
  "CN": "8703 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vehículos, equipados para la propulsión con motor de émbolo (pistón), de encendido por chispa y con motor eléctrico, que se puedan cargar mediante conexión a una fuente externa de alimentación eléctrica"
 },
 {
  "CNKEY": "870360100080",
  "CN": "8703 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870360900080",
  "CN": "8703 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870370000080",
  "CN": "8703 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás vehículos, equipados para la propulsión con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel) y con motor eléctrico, que se puedan cargar mediante conexión a una fuente externa de alimentación eléctrica"
 },
 {
  "CNKEY": "870380000080",
  "CN": "8703 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás vehículos,  propulsados únicamente con motor eléctrico"
 },
 {
  "CNKEY": "870380100080",
  "CN": "8703 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870380900080",
  "CN": "8703 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870390000080",
  "CN": "8703 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870400000080",
  "CN": "8704",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vehículos automóviles para transporte de mercancías"
 },
 {
  "CNKEY": "870410000080",
  "CN": "8704 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Volquetes automotores diseñados para utilizarlos fuera de la red de carreteras"
 },
 {
  "CNKEY": "870410100080",
  "CN": "8704 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel) o con motor de émbolo (pistón), de encendido por chispa"
 },
 {
  "CNKEY": "870410900080",
  "CN": "8704 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, únicamente con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel)"
 },
 {
  "CNKEY": "870421000080",
  "CN": "8704 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima inferior o igual a 5 t"
 },
 {
  "CNKEY": "870421100080",
  "CN": "8704 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870421310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870421310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada superior a 2500 cm³"
 },
 {
  "CNKEY": "870421310080",
  "CN": "8704 21 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870421390080",
  "CN": "8704 21 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870421910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada inferior o igual a 2500 cm³"
 },
 {
  "CNKEY": "870421910080",
  "CN": "8704 21 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870421990080",
  "CN": "8704 21 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870422000080",
  "CN": "8704 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 5 t pero inferior o igual a 20 t"
 },
 {
  "CNKEY": "870422100080",
  "CN": "8704 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870422910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870422910080",
  "CN": "8704 22 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870422990080",
  "CN": "8704 22 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870423000080",
  "CN": "8704 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 20 t"
 },
 {
  "CNKEY": "870423100080",
  "CN": "8704 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870423910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870423910080",
  "CN": "8704 23 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870423990080",
  "CN": "8704 23 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870431000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, únicamente con motor de émbolo (pistón), de encendido por chispa"
 },
 {
  "CNKEY": "870431000080",
  "CN": "8704 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima inferior o igual a 5 t"
 },
 {
  "CNKEY": "870431100080",
  "CN": "8704 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870431310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870431310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada superior a 2800 cm³"
 },
 {
  "CNKEY": "870431310080",
  "CN": "8704 31 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870431390080",
  "CN": "8704 31 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870431910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada inferior o igual a 2800 cm³"
 },
 {
  "CNKEY": "870431910080",
  "CN": "8704 31 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870431990080",
  "CN": "8704 31 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870432000080",
  "CN": "8704 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 5 t"
 },
 {
  "CNKEY": "870432100080",
  "CN": "8704 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870432910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870432910080",
  "CN": "8704 32 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870432990080",
  "CN": "8704 32 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870441000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, equipados para la propulsión con motor de émbolo (pistón), de encendido por compresión (diésel o semi-diésel) y con motor eléctrico"
 },
 {
  "CNKEY": "870441000080",
  "CN": "8704 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima inferior o igual a 5 t"
 },
 {
  "CNKEY": "870441100080",
  "CN": "8704 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870441310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870441310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada superior a 2500 cm³"
 },
 {
  "CNKEY": "870441310080",
  "CN": "8704 41 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870441390080",
  "CN": "8704 41 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870441910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada inferior o igual a 2500 cm³"
 },
 {
  "CNKEY": "870441910080",
  "CN": "8704 41 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870441990080",
  "CN": "8704 41 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870442000080",
  "CN": "8704 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 5 t pero inferior o igual a 20 t"
 },
 {
  "CNKEY": "870442100080",
  "CN": "8704 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870442910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870442910080",
  "CN": "8704 42 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870442990080",
  "CN": "8704 42 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870443000080",
  "CN": "8704 43",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 20 t"
 },
 {
  "CNKEY": "870443100080",
  "CN": "8704 43 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870443910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870443910080",
  "CN": "8704 43 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870443990080",
  "CN": "8704 43 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870451000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, equipados para la propulsión con motor de émbolo (pistón), de encendido por chispa y con motor eléctrico"
 },
 {
  "CNKEY": "870451000080",
  "CN": "8704 51",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima inferior o igual a 5 t"
 },
 {
  "CNKEY": "870451100080",
  "CN": "8704 51 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870451310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870451310020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada superior a 2800 cm³"
 },
 {
  "CNKEY": "870451310080",
  "CN": "8704 51 31",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870451390080",
  "CN": "8704 51 39",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870451910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Con motor de cilindrada inferior o igual a 2800 cm³"
 },
 {
  "CNKEY": "870451910080",
  "CN": "8704 51 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870451990080",
  "CN": "8704 51 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870452000080",
  "CN": "8704 52",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De peso total con carga máxima superior a 5 t"
 },
 {
  "CNKEY": "870452100080",
  "CN": "8704 52 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870452910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870452910080",
  "CN": "8704 52 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "870452990080",
  "CN": "8704 52 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "870460000080",
  "CN": "8704 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás, únicamente propulsados con motor eléctrico"
 },
 {
  "CNKEY": "870490000080",
  "CN": "8704 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870500000080",
  "CN": "8705",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Vehículos automóviles para usos especiales, excepto los diseñados principalmente para transporte de personas o mercancías (por ejemplo: coches para reparaciones (auxilio mecánico), camiones grúa, camiones de bomberos, camiones hormigonera, coches barredera, coches esparcidores, coches taller, coches radiológicos)"
 },
 {
  "CNKEY": "870510000080",
  "CN": "8705 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Camiones grúa"
 },
 {
  "CNKEY": "870520000080",
  "CN": "8705 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Camiones automóviles para sondeo o perforación"
 },
 {
  "CNKEY": "870530000080",
  "CN": "8705 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Camiones de bomberos"
 },
 {
  "CNKEY": "870540000080",
  "CN": "8705 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Camiones hormigonera"
 },
 {
  "CNKEY": "870590000080",
  "CN": "8705 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870590300080",
  "CN": "8705 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Vehículos para bomba de hormigón"
 },
 {
  "CNKEY": "870590800080",
  "CN": "8705 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870600000080",
  "CN": "8706 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Chasis de vehículos automóviles de las partidas 8701 a 8705, equipados con su motor"
 },
 {
  "CNKEY": "870600110010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Chasis de tractores de la partida 8701; chasis de vehículos automóviles de las partidas 8702, 8703 y 8704, con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada superior a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada superior a 2800 cm³"
 },
 {
  "CNKEY": "870600110080",
  "CN": "8706 00 11",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De vehículos automóviles de la partida 8702 o de vehículos automóviles de la partida 8704"
 },
 {
  "CNKEY": "870600190080",
  "CN": "8706 00 19",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870600910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870600910080",
  "CN": "8706 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De vehículos automóviles de la partida 8703"
 },
 {
  "CNKEY": "870600990080",
  "CN": "8706 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870700000080",
  "CN": "8707",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carrocerías de vehículos automóviles de las partidas 8701 a 8705, incluidas las cabinas"
 },
 {
  "CNKEY": "870710000080",
  "CN": "8707 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De vehículos de la partida 8703"
 },
 {
  "CNKEY": "870710100080",
  "CN": "8707 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Destinadas a la industria de montaje"
 },
 {
  "CNKEY": "870710900080",
  "CN": "8707 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "870790000080",
  "CN": "8707 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "870790100080",
  "CN": "8707 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Destinadas a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870790900080",
  "CN": "8707 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870800000080",
  "CN": "8708",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de vehículos automóviles de las partidas 8701 a 8705"
 },
 {
  "CNKEY": "870810000080",
  "CN": "8708 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Parachoques (paragolpes, defensas) y sus partes"
 },
 {
  "CNKEY": "870810100080",
  "CN": "8708 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870810900080",
  "CN": "8708 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás partes y accesorios de carrocería (incluidas las de cabina)"
 },
 {
  "CNKEY": "870821000080",
  "CN": "8708 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cinturones de seguridad"
 },
 {
  "CNKEY": "870821100080",
  "CN": "8708 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a la industria del montaje:de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870821900080",
  "CN": "8708 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870822000080",
  "CN": "8708 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Parabrisas, vidrios traseros (lunetas) y demás ventanillas especificados en la Nota 1 de subpartida de este Capítulo"
 },
 {
  "CNKEY": "870822100080",
  "CN": "8708 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870822900080",
  "CN": "8708 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870829000080",
  "CN": "8708 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870829100080",
  "CN": "8708 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870829900080",
  "CN": "8708 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870830000080",
  "CN": "8708 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Frenos y servofrenos; sus partes"
 },
 {
  "CNKEY": "870830100080",
  "CN": "8708 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870830910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870830910080",
  "CN": "8708 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para frenos de disco"
 },
 {
  "CNKEY": "870830990080",
  "CN": "8708 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870840000080",
  "CN": "8708 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cajas de cambio y sus partes"
 },
 {
  "CNKEY": "870840200080",
  "CN": "8708 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinadas a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870840500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870840500080",
  "CN": "8708 40 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cajas de cambio"
 },
 {
  "CNKEY": "870840910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "870840910080",
  "CN": "8708 40 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870840990080",
  "CN": "8708 40 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870850000080",
  "CN": "8708 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ejes con diferencial, incluso provistos con otros órganos de transmisión, y ejes portadores; sus partes"
 },
 {
  "CNKEY": "870850200080",
  "CN": "8708 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870850350010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870850350080",
  "CN": "8708 50 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ejes con diferencial, incluso provistos con otros órganos de transmisión, y ejes portadores"
 },
 {
  "CNKEY": "870850550010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "870850550080",
  "CN": "8708 50 55",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870850910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870850910080",
  "CN": "8708 50 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Para ejes portadores"
 },
 {
  "CNKEY": "870850990080",
  "CN": "8708 50 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870870000080",
  "CN": "8708 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Ruedas, sus partes y accesorios"
 },
 {
  "CNKEY": "870870100080",
  "CN": "8708 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870870500010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870870500080",
  "CN": "8708 70 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Ruedas de aluminio; partes y accesorios de ruedas, de aluminio"
 },
 {
  "CNKEY": "870870910080",
  "CN": "8708 70 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes de ruedas coladas en una sola pieza en forma de estrella, de fundición, hierro o acero"
 },
 {
  "CNKEY": "870870990080",
  "CN": "8708 70 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870880000080",
  "CN": "8708 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Sistemas de suspensión y sus partes (incluidos los amortiguadores)"
 },
 {
  "CNKEY": "870880200080",
  "CN": "8708 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870880350010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870880350080",
  "CN": "8708 80 35",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Amortiguadores de suspensión"
 },
 {
  "CNKEY": "870880550080",
  "CN": "8708 80 55",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Barras estabilizadoras; Barras de torsión"
 },
 {
  "CNKEY": "870880910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870880910080",
  "CN": "8708 80 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870880990080",
  "CN": "8708 80 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás partes y accesorios"
 },
 {
  "CNKEY": "870891000080",
  "CN": "8708 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Radiadores y sus partes"
 },
 {
  "CNKEY": "870891200080",
  "CN": "8708 91 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870891350010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870891350080",
  "CN": "8708 91 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Radiadores"
 },
 {
  "CNKEY": "870891910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "870891910080",
  "CN": "8708 91 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870891990080",
  "CN": "8708 91 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870892000080",
  "CN": "8708 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Silenciadores y tubos (caños) de escape; sus partes"
 },
 {
  "CNKEY": "870892200080",
  "CN": "8708 92 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870892350010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870892350080",
  "CN": "8708 92 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Silenciadores y tubos (caños) de escape"
 },
 {
  "CNKEY": "870892910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "870892910080",
  "CN": "8708 92 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870892990080",
  "CN": "8708 92 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870893000080",
  "CN": "8708 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Embragues y sus partes"
 },
 {
  "CNKEY": "870893100080",
  "CN": "8708 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870893900080",
  "CN": "8708 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870894000080",
  "CN": "8708 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Volantes, columnas y cajas de dirección; sus partes"
 },
 {
  "CNKEY": "870894200080",
  "CN": "8708 94 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870894350010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870894350080",
  "CN": "8708 94 35",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Volantes, columnas y cajas de dirección"
 },
 {
  "CNKEY": "870894910010",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "870894910080",
  "CN": "8708 94 91",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870894990080",
  "CN": "8708 94 99",
  "CN_LEVEL": 8,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870895000080",
  "CN": "8708 95",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Bolsas inflables de seguridad con sistema de inflado (airbag); sus partes"
 },
 {
  "CNKEY": "870895100080",
  "CN": "8708 95 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870895910010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870895910080",
  "CN": "8708 95 91",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870895990080",
  "CN": "8708 95 99",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870899000080",
  "CN": "8708 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870899100080",
  "CN": "8708 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Destinados a la industria del montaje:de los motocultores de la subpartida 870110,de los vehículos automóviles de la partida 8703,de los vehículos automóviles de la partida 8704 con motor de émbolo (pistón), de encendido por compresión (diésel o semidiésel), de cilindrada inferior o igual a 2500 cm³, o con motor de émbolo (pistón), de encendido por chispa, de cilindrada inferior o igual a 2800 cm³,de los vehículos automóviles de la partida 8705"
 },
 {
  "CNKEY": "870899930010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "870899930080",
  "CN": "8708 99 93",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De acero estampado"
 },
 {
  "CNKEY": "870899970080",
  "CN": "8708 99 97",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "870900000080",
  "CN": "8709",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Carretillas automóvil sin dispositivo de elevación de los tipos utilizados en fábricas, almacenes, puertos o aeropuertos, para transporte de mercancías a corta distancia; carretillas tractor de los tipos utilizados en estaciones ferroviarias; sus partes"
 },
 {
  "CNKEY": "870911000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Carretillas"
 },
 {
  "CNKEY": "870911000080",
  "CN": "8709 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Eléctricas"
 },
 {
  "CNKEY": "870911100080",
  "CN": "8709 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente diseñadas para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870911900080",
  "CN": "8709 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "870919000080",
  "CN": "8709 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "870919100080",
  "CN": "8709 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente diseñadas para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "870919900080",
  "CN": "8709 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "870990000080",
  "CN": "8709 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "871000000080",
  "CN": "8710 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tanques y demás vehículos automóviles blindados de combate, incluso con su armamento; sus partes"
 },
 {
  "CNKEY": "871100000080",
  "CN": "8711",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Motocicletas (incluidos los ciclomotores) y velocípedos equipados con motor auxiliar, con sidecar o sin él; sidecares"
 },
 {
  "CNKEY": "871110000080",
  "CN": "8711 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Con motor de émbolo (pistón), de cilindrada inferior o igual a 50 cm³"
 },
 {
  "CNKEY": "871120000080",
  "CN": "8711 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con motor de émbolo (pistón), de cilindrada superior a 50 cm³ pero inferior o igual a 250 cm³"
 },
 {
  "CNKEY": "871120100080",
  "CN": "8711 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Scooters"
 },
 {
  "CNKEY": "871120920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, de cilindrada"
 },
 {
  "CNKEY": "871120920080",
  "CN": "8711 20 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 50 cm³ pero inferior o igual a 125 cm³"
 },
 {
  "CNKEY": "871120980080",
  "CN": "8711 20 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Superior a 125 cm³ pero inferior o igual a 250 cm³"
 },
 {
  "CNKEY": "871130000080",
  "CN": "8711 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Con motor de émbolo (pistón) de cilindrada superior a 250 cm³ pero inferior o igual a 500 cm³"
 },
 {
  "CNKEY": "871130100080",
  "CN": "8711 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 250 cm³ pero inferior o igual a 380 cm³"
 },
 {
  "CNKEY": "871130900080",
  "CN": "8711 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De cilindrada superior a 380 cm³ pero inferior o igual a 500 cm³"
 },
 {
  "CNKEY": "871140000080",
  "CN": "8711 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Con motor de émbolo (pistón), de cilindrada superior a 500 cm³ pero inferior o igual a 800 cm³"
 },
 {
  "CNKEY": "871150000080",
  "CN": "8711 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Con motor de émbolo (pistón), de cilindrada superior a 800 cm³"
 },
 {
  "CNKEY": "871160000080",
  "CN": "8711 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Propulsados con motor eléctrico"
 },
 {
  "CNKEY": "871160100080",
  "CN": "8711 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Bicicletas, triciclos y cuadriciclos, con pedaleo asistido, con un motor eléctrico auxiliar de potencia nominal continua no superior a 250 vatios"
 },
 {
  "CNKEY": "871160900080",
  "CN": "8711 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871190000080",
  "CN": "8711 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871200000080",
  "CN": "8712 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bicicletas y demás velocípedos (incluidos los triciclos de reparto) sin motor"
 },
 {
  "CNKEY": "871200300080",
  "CN": "8712 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Bicicletas con cojinetes de bolas"
 },
 {
  "CNKEY": "871200700080",
  "CN": "8712 00 70",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871300000080",
  "CN": "8713",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Sillones de ruedas y demás vehículos para inválidos, incluso con motor u otro mecanismo de propulsión"
 },
 {
  "CNKEY": "871310000080",
  "CN": "8713 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Sin mecanismo de propulsión"
 },
 {
  "CNKEY": "871390000080",
  "CN": "8713 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871400000080",
  "CN": "8714",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de vehículos de las partidas 8711 a 8713"
 },
 {
  "CNKEY": "871410000080",
  "CN": "8714 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De motocicletas (incluidos los ciclomotores)"
 },
 {
  "CNKEY": "871410100080",
  "CN": "8714 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Frenos y sus partes"
 },
 {
  "CNKEY": "871410200080",
  "CN": "8714 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cajas de cambio y sus partes"
 },
 {
  "CNKEY": "871410300080",
  "CN": "8714 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ruedas, sus partes y accesorios"
 },
 {
  "CNKEY": "871410400080",
  "CN": "8714 10 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Silenciadores y tubos (caños) de escape; sus partes"
 },
 {
  "CNKEY": "871410500080",
  "CN": "8714 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Embragues y sus partes"
 },
 {
  "CNKEY": "871410900080",
  "CN": "8714 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871420000080",
  "CN": "8714 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De sillones de ruedas y demás vehículos para inválidos"
 },
 {
  "CNKEY": "871491000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "871491000080",
  "CN": "8714 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Cuadros y horquillas, y sus partes"
 },
 {
  "CNKEY": "871491100080",
  "CN": "8714 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cuadros"
 },
 {
  "CNKEY": "871491300080",
  "CN": "8714 91 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Horquillas delanteras"
 },
 {
  "CNKEY": "871491900080",
  "CN": "8714 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "871492000080",
  "CN": "8714 92",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Llantas y radios"
 },
 {
  "CNKEY": "871492100080",
  "CN": "8714 92 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Llantas"
 },
 {
  "CNKEY": "871492900080",
  "CN": "8714 92 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Radios"
 },
 {
  "CNKEY": "871493000080",
  "CN": "8714 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Bujes sin freno y piñones libres"
 },
 {
  "CNKEY": "871494000080",
  "CN": "8714 94",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Frenos, incluidos los bujes con freno, y sus partes"
 },
 {
  "CNKEY": "871494200080",
  "CN": "8714 94 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Frenos"
 },
 {
  "CNKEY": "871494900080",
  "CN": "8714 94 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "871495000080",
  "CN": "8714 95 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Sillines (asientos)"
 },
 {
  "CNKEY": "871496000080",
  "CN": "8714 96",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pedales y mecanismos de pedal, y sus partes"
 },
 {
  "CNKEY": "871496100080",
  "CN": "8714 96 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Pedales"
 },
 {
  "CNKEY": "871496300080",
  "CN": "8714 96 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mecanismos de pedal"
 },
 {
  "CNKEY": "871496900080",
  "CN": "8714 96 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "871499000080",
  "CN": "8714 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "871499100080",
  "CN": "8714 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Manillares"
 },
 {
  "CNKEY": "871499300080",
  "CN": "8714 99 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Portaequipajes"
 },
 {
  "CNKEY": "871499500080",
  "CN": "8714 99 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cambios de marcha"
 },
 {
  "CNKEY": "871499900080",
  "CN": "8714 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871500000080",
  "CN": "8715 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Coches, sillas y vehículos similares para transporte de niños, y sus partes"
 },
 {
  "CNKEY": "871500100080",
  "CN": "8715 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Coches, sillas y vehículos similares"
 },
 {
  "CNKEY": "871500900080",
  "CN": "8715 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "871600000080",
  "CN": "8716",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Remolques y semirremolques para cualquier vehículo; los demás vehículos no automóviles; sus partes"
 },
 {
  "CNKEY": "871610000080",
  "CN": "8716 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Remolques y semirremolques para vivienda o acampada, del tipo caravana"
 },
 {
  "CNKEY": "871610920080",
  "CN": "8716 10 92",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso inferior o igual a 1600 kg"
 },
 {
  "CNKEY": "871610980080",
  "CN": "8716 10 98",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso superior a 1600 kg"
 },
 {
  "CNKEY": "871620000080",
  "CN": "8716 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Remolques y semirremolques, autocargadores o autodescargadores, para uso agrícola"
 },
 {
  "CNKEY": "871631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás remolques y semirremolques para transporte de mercancías"
 },
 {
  "CNKEY": "871631000080",
  "CN": "8716 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Cisternas"
 },
 {
  "CNKEY": "871639000080",
  "CN": "8716 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "871639100080",
  "CN": "8716 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Especialmente concebidos para transportar productos muy radiactivos (Euratom)"
 },
 {
  "CNKEY": "871639300010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "871639300020",
  "CN": null,
  "CN_LEVEL": 7,
  "T_SU_SU": null,
  "ES": "Nuevos"
 },
 {
  "CNKEY": "871639300080",
  "CN": "8716 39 30",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Semirremolques"
 },
 {
  "CNKEY": "871639500080",
  "CN": "8716 39 50",
  "CN_LEVEL": 8,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "871639800080",
  "CN": "8716 39 80",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "871640000080",
  "CN": "8716 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás remolques y semirremolques"
 },
 {
  "CNKEY": "871680000080",
  "CN": "8716 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás vehículos"
 },
 {
  "CNKEY": "871690000080",
  "CN": "8716 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "871690100080",
  "CN": "8716 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Chasis"
 },
 {
  "CNKEY": "871690300080",
  "CN": "8716 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carrocerías"
 },
 {
  "CNKEY": "871690500080",
  "CN": "8716 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ejes"
 },
 {
  "CNKEY": "871690900080",
  "CN": "8716 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "880021000090",
  "CN": "88",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 88 - AERONAVES, VEHÍCULOS ESPACIALES, Y SUS PARTES"
 },
 {
  "CNKEY": "880100000080",
  "CN": "8801 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Globos y dirigibles; planeadores, alas planeadoras y demás aeronaves no propulsados con motor"
 },
 {
  "CNKEY": "880100100080",
  "CN": "8801 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Globos y dirigibles; planeadores y alas planeadoras"
 },
 {
  "CNKEY": "880100900080",
  "CN": "8801 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "880200000080",
  "CN": "8802",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás aeronaves (por ejemplo: helicópteros, aviones), excepto las aeronaves no tripuladas de la partida 8806; vehículos espaciales (incluidos los satélites) y sus vehículos de lanzamiento y vehículos suborbitales"
 },
 {
  "CNKEY": "880211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Helicópteros"
 },
 {
  "CNKEY": "880211000080",
  "CN": "8802 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío inferior o igual a 2000 kg"
 },
 {
  "CNKEY": "880212000080",
  "CN": "8802 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío superior a 2000 kg"
 },
 {
  "CNKEY": "880220000080",
  "CN": "8802 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aviones y demás aeronaves, de peso en vacío inferior o igual a 2000 kg"
 },
 {
  "CNKEY": "880230000080",
  "CN": "8802 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aviones y demás aeronaves, de peso en vacío superior a 2000 kg pero inferior o igual a 15000 kg"
 },
 {
  "CNKEY": "880240000080",
  "CN": "8802 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Aviones y demás aeronaves, de peso en vacío superior a 15000 kg"
 },
 {
  "CNKEY": "880260000080",
  "CN": "8802 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Vehículos espaciales (incluidos los satélites) y sus vehículos de lanzamiento y vehículos suborbitales"
 },
 {
  "CNKEY": "880260110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Vehículos espaciales (incluidos los satélites)"
 },
 {
  "CNKEY": "880260110080",
  "CN": "8802 60 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Satélites de telecomunicación"
 },
 {
  "CNKEY": "880260190080",
  "CN": "8802 60 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "880260900080",
  "CN": "8802 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Vehículos de lanzamiento y vehículos suborbitales"
 },
 {
  "CNKEY": "880400000080",
  "CN": "8804 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Paracaídas, incluidos los dirigibles, planeadores (parapentes) o de aspas giratorias; sus partes y accesorios"
 },
 {
  "CNKEY": "880500000080",
  "CN": "8805",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos y dispositivos para lanzamiento de aeronaves; aparatos y dispositivos para aterrizaje en portaaviones y aparatos y dispositivos similares; aparatos de entrenamiento de vuelo en tierra; sus partes"
 },
 {
  "CNKEY": "880510000080",
  "CN": "8805 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos y dispositivos para lanzamiento de aeronaves y sus partes; aparatos y dispositivos para aterrizaje en portaaviones y aparatos y dispositivos similares, y sus partes"
 },
 {
  "CNKEY": "880510100080",
  "CN": "8805 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos y dispositivos para lanzamiento de aeronaves y sus partes"
 },
 {
  "CNKEY": "880510900080",
  "CN": "8805 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "880521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de entrenamiento de vuelo en tierra y sus partes"
 },
 {
  "CNKEY": "880521000080",
  "CN": "8805 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simuladores de combate aéreo y sus partes"
 },
 {
  "CNKEY": "880529000080",
  "CN": "8805 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "880600000080",
  "CN": "8806",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aeronaves no tripuladas"
 },
 {
  "CNKEY": "880610000080",
  "CN": "8806 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Diseñadas para el transporte de pasajeros"
 },
 {
  "CNKEY": "880610100080",
  "CN": "8806 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío inferior o igual a 2000 kg"
 },
 {
  "CNKEY": "880610900080",
  "CN": "8806 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío superior a 2000 kg"
 },
 {
  "CNKEY": "880621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás, únicamente diseñadas para ser teledirigidas"
 },
 {
  "CNKEY": "880621000080",
  "CN": "8806 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un peso máximo de despegue inferior o igual a 250 g"
 },
 {
  "CNKEY": "880621100080",
  "CN": "8806 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Multirrotores, equipados con aparatos integrados permanentemente de la subpartida 852589 para la captura y grabación de imágenes fijas y de vídeo"
 },
 {
  "CNKEY": "880621900080",
  "CN": "8806 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "880622000080",
  "CN": "8806 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Con un peso máximo de despegue superior a 250 g pero inferior o igual a 7 kg"
 },
 {
  "CNKEY": "880622100080",
  "CN": "8806 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Multirrotores, equipados con aparatos integrados permanentemente de la subpartida 852589 para la captura y grabación de imágenes fijas y de vídeo"
 },
 {
  "CNKEY": "880622900080",
  "CN": "8806 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "880623000080",
  "CN": "8806 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue superior a 7 kg pero inferior o igual a 25 kg"
 },
 {
  "CNKEY": "880624000080",
  "CN": "8806 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue superior a 25 kg pero inferior o igual a 150 kg"
 },
 {
  "CNKEY": "880629000080",
  "CN": "8806 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "880629100080",
  "CN": "8806 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío inferior o igual a 2000 kg"
 },
 {
  "CNKEY": "880629200080",
  "CN": "8806 29 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío superior a 2000 kg"
 },
 {
  "CNKEY": "880691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "880691000080",
  "CN": "8806 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue inferior o igual a 250 g"
 },
 {
  "CNKEY": "880692000080",
  "CN": "8806 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue superior a 250 g pero inferior o igual a 7 kg"
 },
 {
  "CNKEY": "880693000080",
  "CN": "8806 93 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue superior a 7 kg pero inferior o igual a 25 kg"
 },
 {
  "CNKEY": "880694000080",
  "CN": "8806 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un peso máximo de despegue superior a 25 kg pero inferior o igual a 150 kg"
 },
 {
  "CNKEY": "880699000080",
  "CN": "8806 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "880699100080",
  "CN": "8806 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío inferior o igual a 2000 kg"
 },
 {
  "CNKEY": "880699200080",
  "CN": "8806 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso en vacío superior a 2000 kg"
 },
 {
  "CNKEY": "880700000080",
  "CN": "8807",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes de los aparatos de las partidas8801, 8802 u 8806"
 },
 {
  "CNKEY": "880710000080",
  "CN": "8807 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hélices y rotores, y sus partes"
 },
 {
  "CNKEY": "880720000080",
  "CN": "8807 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trenes de aterrizaje y sus partes"
 },
 {
  "CNKEY": "880730000080",
  "CN": "8807 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás partes de aviones, helicópteros o aeronaves no tripuladas"
 },
 {
  "CNKEY": "880790000080",
  "CN": "8807 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "880790100080",
  "CN": "8807 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cometas"
 },
 {
  "CNKEY": "880790210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De vehículos espaciales (incluidos los satélites)"
 },
 {
  "CNKEY": "880790210080",
  "CN": "8807 90 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De satélites de comunicación"
 },
 {
  "CNKEY": "880790290080",
  "CN": "8807 90 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "880790300080",
  "CN": "8807 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De vehículos de lanzamiento y vehículos suborbitales"
 },
 {
  "CNKEY": "880790900080",
  "CN": "8807 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "890021000090",
  "CN": "89",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 89 - BARCOS Y DEMÁS ARTEFACTOS FLOTANTES"
 },
 {
  "CNKEY": "890100000080",
  "CN": "8901",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Transatlánticos, barcos para excursiones (de cruceros), transbordadores, cargueros, gabarras (barcazas) y barcos similares para transporte de personas o mercancías"
 },
 {
  "CNKEY": "890110000080",
  "CN": "8901 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Transatlánticos, barcos para excursiones (de cruceros) y barcos similares concebidos principalmente para transporte de personas; transbordadores"
 },
 {
  "CNKEY": "890110100080",
  "CN": "8901 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890110900080",
  "CN": "8901 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890120000080",
  "CN": "8901 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barcos cisterna"
 },
 {
  "CNKEY": "890120100080",
  "CN": "8901 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890120900080",
  "CN": "8901 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "ct\/l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890130000080",
  "CN": "8901 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barcos frigorífico, excepto los de la subpartida 890120"
 },
 {
  "CNKEY": "890130100080",
  "CN": "8901 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890130900080",
  "CN": "8901 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "ct\/l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890190000080",
  "CN": "8901 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás barcos para transporte de mercancías y los demás barcos concebidos para transporte mixto de personas y mercancías"
 },
 {
  "CNKEY": "890190100080",
  "CN": "8901 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890190900080",
  "CN": "8901 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "ct\/l",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890200000080",
  "CN": "8902 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barcos de pesca; barcos factoría y demás barcos para la preparación o la conservación de los productos de la pesca"
 },
 {
  "CNKEY": "890200100080",
  "CN": "8902 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890200900080",
  "CN": "8902 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890300000080",
  "CN": "8903",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Yates y demás barcos y embarcaciones de recreo o deporte; barcas (botes) de remo y canoas"
 },
 {
  "CNKEY": "890311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Embarcaciones inflables, incluso con casco rígido"
 },
 {
  "CNKEY": "890311000080",
  "CN": "8903 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Equipadas o diseñadas para equiparlas con motor, de peso en vacío, sin motor, inferior o igual a 100 kg"
 },
 {
  "CNKEY": "890312000080",
  "CN": "8903 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "No diseñadas para ser utilizadas con motor y de peso en vacío inferior o igual a 100 kg"
 },
 {
  "CNKEY": "890319000080",
  "CN": "8903 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "890321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barcos de vela, distintos de los inflables, incluso con motor auxiliar"
 },
 {
  "CNKEY": "890321000080",
  "CN": "8903 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De longitud inferior o igual a 7,5 m"
 },
 {
  "CNKEY": "890322000080",
  "CN": "8903 22",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De longitud superior a 7,5 m pero inferior o igual a 24 m"
 },
 {
  "CNKEY": "890322100080",
  "CN": "8903 22 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890322900080",
  "CN": "8903 22 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890323000080",
  "CN": "8903 23",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De longitud superior a 24 m"
 },
 {
  "CNKEY": "890323100080",
  "CN": "8903 23 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890323900080",
  "CN": "8903 23 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890331000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barcos de motor, distintos de los inflables, excepto los de motor fueraborda"
 },
 {
  "CNKEY": "890331000080",
  "CN": "8903 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De longitud inferior o igual a 7,5 m"
 },
 {
  "CNKEY": "890332000080",
  "CN": "8903 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De longitud superior a 7,5 m pero inferior o igual a 24 m"
 },
 {
  "CNKEY": "890332100080",
  "CN": "8903 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890332900080",
  "CN": "8903 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890333000080",
  "CN": "8903 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De longitud superior a 24 m"
 },
 {
  "CNKEY": "890333100080",
  "CN": "8903 33 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890333900080",
  "CN": "8903 33 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890393000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "890393000080",
  "CN": "8903 93",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De longitud inferior o igual a 7,5 m"
 },
 {
  "CNKEY": "890393100080",
  "CN": "8903 93 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso unitario inferior o igual a 100 kg"
 },
 {
  "CNKEY": "890393900080",
  "CN": "8903 93 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890399000080",
  "CN": "8903 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "890399100080",
  "CN": "8903 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso unitario inferior o igual a 100 kg"
 },
 {
  "CNKEY": "890399990080",
  "CN": "8903 99 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890400000080",
  "CN": "8904 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Remolcadores y barcos empujadores"
 },
 {
  "CNKEY": "890400100080",
  "CN": "8904 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Remolcadores"
 },
 {
  "CNKEY": "890400910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Barcos empujadores"
 },
 {
  "CNKEY": "890400910080",
  "CN": "8904 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890400990080",
  "CN": "8904 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890500000080",
  "CN": "8905",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Barcos faro, barcos bomba, dragas, pontones grúa y demás barcos en los que la navegación sea accesoria en relación con la función principal; diques flotantes; plataformas de perforación o explotación, flotantes o sumergibles"
 },
 {
  "CNKEY": "890510000080",
  "CN": "8905 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Dragas"
 },
 {
  "CNKEY": "890510100080",
  "CN": "8905 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890510900080",
  "CN": "8905 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "890520000080",
  "CN": "8905 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Plataformas de perforación o explotación, flotantes o sumergibles"
 },
 {
  "CNKEY": "890590000080",
  "CN": "8905 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "890590100080",
  "CN": "8905 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890590900080",
  "CN": "8905 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890600000080",
  "CN": "8906",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás barcos, incluidos los navíos de guerra y barcos de salvamento excepto los de remo"
 },
 {
  "CNKEY": "890610000080",
  "CN": "8906 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Navíos de guerra"
 },
 {
  "CNKEY": "890690000080",
  "CN": "8906 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "890690100080",
  "CN": "8906 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para la navegación marítima"
 },
 {
  "CNKEY": "890690910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "890690910080",
  "CN": "8906 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De peso unitario inferior o igual a 100 kg"
 },
 {
  "CNKEY": "890690990080",
  "CN": "8906 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890700000080",
  "CN": "8907",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás artefactos flotantes (por ejemplo: balsas, depósitos, cajones, incluso de amarre, boyas y balizas)"
 },
 {
  "CNKEY": "890710000080",
  "CN": "8907 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Balsas inflables"
 },
 {
  "CNKEY": "890790000080",
  "CN": "8907 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "890800000080",
  "CN": "8908 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Barcos y demás artefactos flotantes para desguace"
 },
 {
  "CNKEY": "900011000090",
  "CN": "XVIII",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XVIII - INSTRUMENTOS Y APARATOS DE ÓPTICA, FOTOGRAFÍA O CINEMATOGRAFÍA, DE MEDIDA, CONTROL O PRECISIÓN; INSTRUMENTOS Y APARATOS MEDICOQUIRÚRGICOS; APARATOS DE RELOJERÍA; INSTRUMENTOS MUSICALES; PARTES Y ACCESORIOS DE ESTOS INSTRUMENTOS O APARATOS"
 },
 {
  "CNKEY": "900021000090",
  "CN": "90",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 90 - INSTRUMENTOS Y APARATOS DE ÓPTICA, FOTOGRAFÍA O CINEMATOGRAFÍA, DE MEDIDA, CONTROL O PRECISIÓN; INSTRUMENTOS Y APARATOS MEDICOQUIRÚRGICOS; PARTES Y ACCESORIOS DE ESTOS INSTRUMENTOS O APARATOS"
 },
 {
  "CNKEY": "900100000080",
  "CN": "9001",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Fibras ópticas y haces de fibras ópticas; cables de fibras ópticas, excepto los de la partida 8544; hojas y placas de materia polarizante; lentes, incluso de contacto, prismas, espejos y demás elementos de óptica de cualquier materia, sin montar, excepto los de vidrio sin trabajar ópticamente"
 },
 {
  "CNKEY": "900110000080",
  "CN": "9001 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Fibras ópticas, haces y cables de fibras ópticas"
 },
 {
  "CNKEY": "900110100080",
  "CN": "9001 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Cables conductores de imágenes"
 },
 {
  "CNKEY": "900110900080",
  "CN": "9001 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900120000080",
  "CN": "9001 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Hojas y placas de materia polarizante"
 },
 {
  "CNKEY": "900130000080",
  "CN": "9001 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Lentes de contacto"
 },
 {
  "CNKEY": "900140000080",
  "CN": "9001 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lentes de vidrio para gafas (anteojos)"
 },
 {
  "CNKEY": "900140200080",
  "CN": "9001 40 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "No correctoras"
 },
 {
  "CNKEY": "900140410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Correctoras"
 },
 {
  "CNKEY": "900140410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Completamente trabajadas en las dos caras"
 },
 {
  "CNKEY": "900140410080",
  "CN": "9001 40 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Monofocales"
 },
 {
  "CNKEY": "900140490080",
  "CN": "9001 40 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900140800080",
  "CN": "9001 40 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900150000080",
  "CN": "9001 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lentes de otras materias para gafas (anteojos)"
 },
 {
  "CNKEY": "900150200080",
  "CN": "9001 50 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "No correctoras"
 },
 {
  "CNKEY": "900150410010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Correctoras"
 },
 {
  "CNKEY": "900150410020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Completamente trabajadas en las dos caras"
 },
 {
  "CNKEY": "900150410080",
  "CN": "9001 50 41",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Monofocales"
 },
 {
  "CNKEY": "900150490080",
  "CN": "9001 50 49",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900150800080",
  "CN": "9001 50 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900190000080",
  "CN": "9001 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900200000080",
  "CN": "9002",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lentes, prismas, espejos y demás elementos de óptica de cualquier materia, montados, para instrumentos o aparatos, excepto los de vidrio sin trabajar ópticamente"
 },
 {
  "CNKEY": "900211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Objetivos"
 },
 {
  "CNKEY": "900211000080",
  "CN": "9002 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para cámaras, proyectores o aparatos fotográficos o cinematográficos de ampliación o reducción"
 },
 {
  "CNKEY": "900219000080",
  "CN": "9002 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900220000080",
  "CN": "9002 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Filtros"
 },
 {
  "CNKEY": "900290000080",
  "CN": "9002 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900300000080",
  "CN": "9003",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monturas (armazones) de gafas (anteojos) o de artículos similares y sus partes"
 },
 {
  "CNKEY": "900311000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Monturas (armazones)"
 },
 {
  "CNKEY": "900311000080",
  "CN": "9003 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De plástico"
 },
 {
  "CNKEY": "900319000080",
  "CN": "9003 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De otras materias"
 },
 {
  "CNKEY": "900390000080",
  "CN": "9003 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "900400000080",
  "CN": "9004",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Gafas (anteojos) correctoras, protectoras u otras, y artículos similares"
 },
 {
  "CNKEY": "900410000080",
  "CN": "9004 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Gafas (anteojos) de sol"
 },
 {
  "CNKEY": "900410100080",
  "CN": "9004 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con cristales trabajados ópticamente"
 },
 {
  "CNKEY": "900410910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "900410910080",
  "CN": "9004 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con cristales de plástico"
 },
 {
  "CNKEY": "900410990080",
  "CN": "9004 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900490000080",
  "CN": "9004 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "900490100080",
  "CN": "9004 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con cristales de plástico"
 },
 {
  "CNKEY": "900490900080",
  "CN": "9004 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900500000080",
  "CN": "9005",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Binoculares (incluidos los prismáticos), catalejos, anteojos astronómicos, telescopios ópticos y sus armazones; los demás instrumentos de astronomía y sus armazones, excepto los aparatos de radioastronomía"
 },
 {
  "CNKEY": "900510000080",
  "CN": "9005 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Binoculares (incluidos los prismáticos)"
 },
 {
  "CNKEY": "900580000080",
  "CN": "9005 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás instrumentos"
 },
 {
  "CNKEY": "900590000080",
  "CN": "9005 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios (incluidos los armazones)"
 },
 {
  "CNKEY": "900600000080",
  "CN": "9006",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cámaras fotográficas; aparatos y dispositivos, incluidos las lámparas y tubos, para la producción de destellos en fotografía, excepto las lámparas y tubos de descarga de la partida 8539"
 },
 {
  "CNKEY": "900630000080",
  "CN": "9006 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cámaras especiales para fotografía submarina o aérea, examen médico de órganos internos o para laboratorios de medicina legal o identificación judicial"
 },
 {
  "CNKEY": "900640000080",
  "CN": "9006 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cámaras fotográficas con autorrevelado"
 },
 {
  "CNKEY": "900653000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás cámaras fotográficas"
 },
 {
  "CNKEY": "900653000080",
  "CN": "9006 53",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para películas en rollo de anchura igual a 35 mm"
 },
 {
  "CNKEY": "900653100080",
  "CN": "9006 53 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cámaras fotográficas desechables"
 },
 {
  "CNKEY": "900653800080",
  "CN": "9006 53 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900659000080",
  "CN": "9006 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "900661000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos y dispositivos, incluidos lámparas y tubos, para producir destellos para fotografía"
 },
 {
  "CNKEY": "900661000080",
  "CN": "9006 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos de tubo de descarga para producir destellos (flashes electrónicos)"
 },
 {
  "CNKEY": "900669000080",
  "CN": "9006 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "900691000080",
  "CN": "9006 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cámaras fotográficas"
 },
 {
  "CNKEY": "900699000080",
  "CN": "9006 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "900700000080",
  "CN": "9007",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cámaras y proyectores cinematográficos, incluso con grabador o reproductor de sonido incorporados"
 },
 {
  "CNKEY": "900710000080",
  "CN": "9007 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cámaras"
 },
 {
  "CNKEY": "900720000080",
  "CN": "9007 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Proyectores"
 },
 {
  "CNKEY": "900791000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "900791000080",
  "CN": "9007 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De cámaras"
 },
 {
  "CNKEY": "900792000080",
  "CN": "9007 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De proyectores"
 },
 {
  "CNKEY": "900800000080",
  "CN": "9008",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Proyectores de imagen fija; ampliadoras o reductoras, fotográficas"
 },
 {
  "CNKEY": "900850000080",
  "CN": "9008 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Proyectores, ampliadoras o reductoras"
 },
 {
  "CNKEY": "900890000080",
  "CN": "9008 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901000000080",
  "CN": "9010",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos y material para laboratorios fotográficos o cinematográficos, no expresados ni comprendidos en otra parte de este Capítulo; negatoscopios; pantallas de proyección"
 },
 {
  "CNKEY": "901010000080",
  "CN": "9010 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos y material para revelado automático de película fotográfica, película cinematográfica (filme) o papel fotográfico en rollo o para impresión automática de películas reveladas en rollos de papel fotográfico"
 },
 {
  "CNKEY": "901050000080",
  "CN": "9010 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás aparatos y material para laboratorios fotográfico o cinematográfico; negatoscopios"
 },
 {
  "CNKEY": "901060000080",
  "CN": "9010 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Pantallas de proyección"
 },
 {
  "CNKEY": "901090000080",
  "CN": "9010 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901090200080",
  "CN": "9010 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De los aparatos y material de las subpartidas 9010 50 00 o 9010 60 00"
 },
 {
  "CNKEY": "901090800080",
  "CN": "9010 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901100000080",
  "CN": "9011",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Microscopios ópticos, incluso para fotomicrografía, cinefotomicrografía o microproyección"
 },
 {
  "CNKEY": "901110000080",
  "CN": "9011 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Microscopios estereoscópicos"
 },
 {
  "CNKEY": "901120000080",
  "CN": "9011 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás microscopios para fotomicrografía, cinefotomicrografía o microproyección"
 },
 {
  "CNKEY": "901120100080",
  "CN": "9011 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Microscopios para fotomicrografía que dispongan de equipo específicamente concebido para la manipulación y transporte de discos (wafers) de material semiconductor o de retículas"
 },
 {
  "CNKEY": "901120900080",
  "CN": "9011 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901180000080",
  "CN": "9011 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás microscopios"
 },
 {
  "CNKEY": "901190000080",
  "CN": "9011 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901200000080",
  "CN": "9012",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Microscopios, excepto los ópticos; difractógrafos"
 },
 {
  "CNKEY": "901210000080",
  "CN": "9012 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Microscopios, excepto los ópticos; difractógrafos"
 },
 {
  "CNKEY": "901290000080",
  "CN": "9012 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901300000080",
  "CN": "9013",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Láseres, excepto los diodos láser; los demás aparatos e instrumentos de óptica, no expresados ni comprendidos en otra parte de este Capítulo"
 },
 {
  "CNKEY": "901310000080",
  "CN": "9013 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Miras telescópicas para armas; periscopios; visores para máquinas, aparatos o instrumentos de este Capítulo o de la Sección XVI"
 },
 {
  "CNKEY": "901310100080",
  "CN": "9013 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Visores para máquinas, aparatos o instrumentos de este Capítulo o de la Sección XVI"
 },
 {
  "CNKEY": "901310900080",
  "CN": "9013 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901320000080",
  "CN": "9013 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Láseres, excepto los diodos láser"
 },
 {
  "CNKEY": "901380000080",
  "CN": "9013 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás dispositivos, aparatos e instrumentos"
 },
 {
  "CNKEY": "901390000080",
  "CN": "9013 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901390050080",
  "CN": "9013 90 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De miras telescópicas para armas o periscopios"
 },
 {
  "CNKEY": "901390800080",
  "CN": "9013 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901400000080",
  "CN": "9014",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Brújulas, incluidos los compases de navegación; los demás instrumentos y aparatos de navegación"
 },
 {
  "CNKEY": "901410000080",
  "CN": "9014 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Brújulas, incluidos los compases de navegación"
 },
 {
  "CNKEY": "901420000080",
  "CN": "9014 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos para navegación aérea o espacial (excepto las brújulas)"
 },
 {
  "CNKEY": "901420200080",
  "CN": "9014 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sistemas de navegación inercial"
 },
 {
  "CNKEY": "901420800080",
  "CN": "9014 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901480000080",
  "CN": "9014 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "901490000080",
  "CN": "9014 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901500000080",
  "CN": "9015",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos de geodesia, topografía, agrimensura, nivelación, fotogrametría, hidrografía, oceanografía, hidrología, meteorología o geofísica (excepto las brújulas); telémetros"
 },
 {
  "CNKEY": "901510000080",
  "CN": "9015 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Telémetros"
 },
 {
  "CNKEY": "901520000080",
  "CN": "9015 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Teodolitos y taquímetros"
 },
 {
  "CNKEY": "901530000080",
  "CN": "9015 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Niveles"
 },
 {
  "CNKEY": "901530100080",
  "CN": "9015 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "901530900080",
  "CN": "9015 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901540000080",
  "CN": "9015 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Instrumentos y aparatos de fotogrametría"
 },
 {
  "CNKEY": "901580000080",
  "CN": "9015 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "901580200080",
  "CN": "9015 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De meteorología, hidrología y geofísica"
 },
 {
  "CNKEY": "901580400080",
  "CN": "9015 80 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De geodesia, topografía, agrimensura, nivelación e hidrografía"
 },
 {
  "CNKEY": "901580800080",
  "CN": "9015 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901590000080",
  "CN": "9015 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901600000080",
  "CN": "9016 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Balanzas sensibles a un peso inferior o igual a 5 cg, incluso con pesas"
 },
 {
  "CNKEY": "901600100080",
  "CN": "9016 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Balanzas"
 },
 {
  "CNKEY": "901600900080",
  "CN": "9016 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901700000080",
  "CN": "9017",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos de dibujo, trazado o cálculo (por ejemplo: máquinas de dibujar, pantógrafos, transportadores, estuches de dibujo, reglas y círculos, de cálculo); instrumentos manuales de medida de longitud (por ejemplo: metros, micrómetros, calibradores), no expresados ni comprendidos en otra parte de este Capítulo"
 },
 {
  "CNKEY": "901710000080",
  "CN": "9017 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Mesas y máquinas de dibujar, incluso automáticas"
 },
 {
  "CNKEY": "901710100080",
  "CN": "9017 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Trazadores (plotters)"
 },
 {
  "CNKEY": "901710900080",
  "CN": "9017 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "901720000080",
  "CN": "9017 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos de dibujo, trazado o cálculo"
 },
 {
  "CNKEY": "901720050080",
  "CN": "9017 20 05",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Trazadores"
 },
 {
  "CNKEY": "901720100080",
  "CN": "9017 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás instrumentos de dibujo"
 },
 {
  "CNKEY": "901720390080",
  "CN": "9017 20 39",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos de trazado"
 },
 {
  "CNKEY": "901720900080",
  "CN": "9017 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos de cálculo"
 },
 {
  "CNKEY": "901730000080",
  "CN": "9017 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Micrómetros, pies de rey, calibradores y galgas"
 },
 {
  "CNKEY": "901780000080",
  "CN": "9017 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos"
 },
 {
  "CNKEY": "901780100080",
  "CN": "9017 80 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Metros y reglas divididas"
 },
 {
  "CNKEY": "901780900080",
  "CN": "9017 80 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901790000080",
  "CN": "9017 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "901800000080",
  "CN": "9018",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos de medicina, cirugía, odontología o veterinaria, incluidos los de centellografía y demás aparatos electromédicos, así como los aparatos para pruebas visuales"
 },
 {
  "CNKEY": "901811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de electrodiagnóstico (incluidos los aparatos de exploración funcional o de vigilancia de parámetros fisiológicos)"
 },
 {
  "CNKEY": "901811000080",
  "CN": "9018 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Electrocardiógrafos"
 },
 {
  "CNKEY": "901812000080",
  "CN": "9018 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de diagnóstico por exploración ultrasónica"
 },
 {
  "CNKEY": "901813000080",
  "CN": "9018 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de diagnóstico de visualización por resonancia magnética"
 },
 {
  "CNKEY": "901814000080",
  "CN": "9018 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de centellografía"
 },
 {
  "CNKEY": "901819000080",
  "CN": "9018 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "901819100080",
  "CN": "9018 19 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos para vigilancia simultánea de dos o más parámetros fisiológicos"
 },
 {
  "CNKEY": "901819900080",
  "CN": "9018 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901820000080",
  "CN": "9018 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Aparatos de rayos ultravioletas o infrarrojos"
 },
 {
  "CNKEY": "901831000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Jeringas, agujas, catéteres, cánulas e instrumentos similares"
 },
 {
  "CNKEY": "901831000080",
  "CN": "9018 31",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Jeringas, incluso con aguja"
 },
 {
  "CNKEY": "901831100080",
  "CN": "9018 31 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "901831900080",
  "CN": "9018 31 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "901832000080",
  "CN": "9018 32",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Agujas tubulares de metal y agujas de sutura"
 },
 {
  "CNKEY": "901832100080",
  "CN": "9018 32 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Agujas tubulares de metal"
 },
 {
  "CNKEY": "901832900080",
  "CN": "9018 32 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Agujas de sutura"
 },
 {
  "CNKEY": "901839000080",
  "CN": "9018 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901841000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos de odontología"
 },
 {
  "CNKEY": "901841000080",
  "CN": "9018 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Tornos dentales, incluso combinados con otros equipos dentales sobre basamento común"
 },
 {
  "CNKEY": "901849000080",
  "CN": "9018 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "901849100080",
  "CN": "9018 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Muelas, discos, fresas y cepillos, para tornos de odontología"
 },
 {
  "CNKEY": "901849900080",
  "CN": "9018 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901850000080",
  "CN": "9018 50",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos de oftalmología"
 },
 {
  "CNKEY": "901850100080",
  "CN": "9018 50 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "No ópticos"
 },
 {
  "CNKEY": "901850900080",
  "CN": "9018 50 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Ópticos"
 },
 {
  "CNKEY": "901890000080",
  "CN": "9018 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "901890100080",
  "CN": "9018 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Instrumentos y aparatos para medir la presión arterial"
 },
 {
  "CNKEY": "901890200080",
  "CN": "9018 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Endoscopios"
 },
 {
  "CNKEY": "901890300080",
  "CN": "9018 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Riñones artificiales"
 },
 {
  "CNKEY": "901890400080",
  "CN": "9018 90 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de diatermia"
 },
 {
  "CNKEY": "901890500080",
  "CN": "9018 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos de transfusión y perfusión"
 },
 {
  "CNKEY": "901890600080",
  "CN": "9018 90 60",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Instrumentos y aparatos para anestesia"
 },
 {
  "CNKEY": "901890750080",
  "CN": "9018 90 75",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos para la estimulación neural"
 },
 {
  "CNKEY": "901890840080",
  "CN": "9018 90 84",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901900000080",
  "CN": "9019",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de mecanoterapia; aparatos para masajes; aparatos de sicotecnia; aparatos de ozonoterapia, oxigenoterapia o aerosolterapia, aparatos respiratorios de reanimación y demás aparatos de terapia respiratoria"
 },
 {
  "CNKEY": "901910000080",
  "CN": "9019 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de mecanoterapia; aparatos para masajes; aparatos de sicotecnia"
 },
 {
  "CNKEY": "901910100080",
  "CN": "9019 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Aparatos eléctricos de vibromasaje"
 },
 {
  "CNKEY": "901910900080",
  "CN": "9019 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "901920000080",
  "CN": "9019 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de ozonoterapia, oxigenoterapia o aerosolterapia, aparatos respiratorios de reanimación y demás aparatos de terapia respiratoria"
 },
 {
  "CNKEY": "901920100080",
  "CN": "9019 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Dispositivos de ventilación mecánica, aptos para la ventilación invasiva"
 },
 {
  "CNKEY": "901920200080",
  "CN": "9019 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Dispositivos de ventilación mecánica, no invasivos"
 },
 {
  "CNKEY": "901920900080",
  "CN": "9019 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos sus partes y accesorios"
 },
 {
  "CNKEY": "902000000080",
  "CN": "9020 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás aparatos respiratorios y máscaras antigás, excepto las máscaras de protección sin mecanismo ni elemento filtrante amovible"
 },
 {
  "CNKEY": "902000100080",
  "CN": "9020 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Máscaras antigás"
 },
 {
  "CNKEY": "902000900080",
  "CN": "9020 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás, incluidos sus partes y accesorios"
 },
 {
  "CNKEY": "902100000080",
  "CN": "9021",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos y aparatos de ortopedia, incluidas las fajas y vendajes medicoquirúrgicos y las muletas; tablillas, férulas u otros artículos y aparatos para fracturas; artículos y aparatos de prótesis; audífonos y demás aparatos que lleve la propia persona o se le implanten para compensar un defecto o incapacidad"
 },
 {
  "CNKEY": "902110000080",
  "CN": "9021 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos y aparatos de ortopedia o para fracturas"
 },
 {
  "CNKEY": "902110100080",
  "CN": "9021 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos y aparatos de ortopedia"
 },
 {
  "CNKEY": "902110900080",
  "CN": "9021 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Artículos y aparatos para fracturas"
 },
 {
  "CNKEY": "902121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos y aparatos de prótesis dental"
 },
 {
  "CNKEY": "902121000080",
  "CN": "9021 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Dientes artificiales"
 },
 {
  "CNKEY": "902121100080",
  "CN": "9021 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "100 p\/st",
  "ES": "De plástico"
 },
 {
  "CNKEY": "902121900080",
  "CN": "9021 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "100 p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902129000080",
  "CN": "9021 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás artículos y aparatos de prótesis"
 },
 {
  "CNKEY": "902131000080",
  "CN": "9021 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Prótesis articulares"
 },
 {
  "CNKEY": "902139000080",
  "CN": "9021 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902139100080",
  "CN": "9021 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Prótesis oculares"
 },
 {
  "CNKEY": "902139900080",
  "CN": "9021 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902140000080",
  "CN": "9021 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Audífonos, excepto sus partes y accesorios"
 },
 {
  "CNKEY": "902150000080",
  "CN": "9021 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Estimuladores cardíacos, excepto sus partes y accesorios"
 },
 {
  "CNKEY": "902190000080",
  "CN": "9021 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902190100080",
  "CN": "9021 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de audífonos"
 },
 {
  "CNKEY": "902190900080",
  "CN": "9021 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902200000080",
  "CN": "9022",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de rayos X y aparatos que utilicen radiaciones alfa, beta gamma o demás radiaciones ionizantes, incluso para uso médico, quirúrgico, odontológico o veterinario, incluidos los aparatos de radiografía o radioterapia, tubos de rayos X y demás dispositivos generadores de rayos X, generadores de tensión, consolas de mando, pantallas, mesas, sillones y soportes similares para examen o tratamiento"
 },
 {
  "CNKEY": "902212000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos de rayos X, incluso para uso médico, quirúrgico, odontológico o veterinario, incluidos los aparatos de radiografía o radioterapia"
 },
 {
  "CNKEY": "902212000080",
  "CN": "9022 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Aparatos de tomografía regidos por una máquina automática de tratamiento o procesamiento de datos"
 },
 {
  "CNKEY": "902213000080",
  "CN": "9022 13 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás para uso odontológico"
 },
 {
  "CNKEY": "902214000080",
  "CN": "9022 14 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás, para uso médico, quirúrgico o veterinario"
 },
 {
  "CNKEY": "902219000080",
  "CN": "9022 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para otros usos"
 },
 {
  "CNKEY": "902221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Aparatos que utilicen radiaciones alfa, beta gamma o demás radiaciones ionizantes, incluso para uso médico, quirúrgico, odontológico o veterinario, incluidos los aparatos de radiografía o radioterapia"
 },
 {
  "CNKEY": "902221000080",
  "CN": "9022 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para uso médico, quirúrgico, odontológico o veterinario"
 },
 {
  "CNKEY": "902229000080",
  "CN": "9022 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Para otros usos"
 },
 {
  "CNKEY": "902230000080",
  "CN": "9022 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Tubos de rayos X"
 },
 {
  "CNKEY": "902290000080",
  "CN": "9022 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás, incluidas las partes y accesorios"
 },
 {
  "CNKEY": "902290200080",
  "CN": "9022 90 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de aparatos de rayos X"
 },
 {
  "CNKEY": "902290800080",
  "CN": "9022 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902300000080",
  "CN": "9023 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos, aparatos y modelos, concebidos para demostraciones (por ejemplo: en la enseñanza o exposiciones), no susceptibles de otros usos"
 },
 {
  "CNKEY": "902300100080",
  "CN": "9023 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Para la enseñanza de la física, de la química o de asignaturas técnicas"
 },
 {
  "CNKEY": "902300800080",
  "CN": "9023 00 80",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902400000080",
  "CN": "9024",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para ensayos de dureza, tracción, compresión, elasticidad u otras propiedades mecánicas de materiales (por ejemplo: metal, madera, textil, papel, plástico)"
 },
 {
  "CNKEY": "902410000080",
  "CN": "9024 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Máquinas y aparatos para ensayo de metal"
 },
 {
  "CNKEY": "902410200080",
  "CN": "9024 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Universales y para ensayos de tracción"
 },
 {
  "CNKEY": "902410400080",
  "CN": "9024 10 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para ensayos de dureza"
 },
 {
  "CNKEY": "902410800080",
  "CN": "9024 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902480000080",
  "CN": "9024 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás máquinas y aparatos"
 },
 {
  "CNKEY": "902490000080",
  "CN": "9024 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "902500000080",
  "CN": "9025",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Densímetros, areómetros, pesalíquidos e instrumentos flotantes similares, termómetros, pirómetros, barómetros, higrómetros y sicrómetros, incluso registradores o combinados entre sí"
 },
 {
  "CNKEY": "902511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Termómetros y pirómetros, sin combinar con otros instrumentos"
 },
 {
  "CNKEY": "902511000080",
  "CN": "9025 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De líquido, con lectura directa"
 },
 {
  "CNKEY": "902511200080",
  "CN": "9025 11 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Médicos o veterinarios"
 },
 {
  "CNKEY": "902511800080",
  "CN": "9025 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902519000080",
  "CN": "9025 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902580000080",
  "CN": "9025 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos"
 },
 {
  "CNKEY": "902580200080",
  "CN": "9025 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Barómetros sin combinar con otros instrumentos"
 },
 {
  "CNKEY": "902580400010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902580400080",
  "CN": "9025 80 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "902580800080",
  "CN": "9025 80 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902590000080",
  "CN": "9025 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "902600000080",
  "CN": "9026",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos para medida o control del caudal, nivel, presión u otras características variables de líquidos o gases (por ejemplo: caudalímetros, indicadores de nivel, manómetros, contadores de calor), excepto los instrumentos y aparatos de las partidas 9014, 9015, 9028 o 9032"
 },
 {
  "CNKEY": "902610000080",
  "CN": "9026 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para medida o control del caudal o nivel de líquidos"
 },
 {
  "CNKEY": "902610210010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "902610210080",
  "CN": "9026 10 21",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Caudalímetros"
 },
 {
  "CNKEY": "902610290080",
  "CN": "9026 10 29",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902610810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902610810080",
  "CN": "9026 10 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Caudalímetros"
 },
 {
  "CNKEY": "902610890080",
  "CN": "9026 10 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902620000080",
  "CN": "9026 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Para medida o control de presión"
 },
 {
  "CNKEY": "902620200080",
  "CN": "9026 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "902620400010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902620400080",
  "CN": "9026 20 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Manómetros de espira o membrana manométrica metálica"
 },
 {
  "CNKEY": "902620800080",
  "CN": "9026 20 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902680000080",
  "CN": "9026 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "902680200080",
  "CN": "9026 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "902680800080",
  "CN": "9026 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902690000080",
  "CN": "9026 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "902700000080",
  "CN": "9027",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos para análisis físicos o químicos (por ejemplo: polarímetros, refractómetros, espectrómetros, analizadores de gases o de humos); instrumentos y aparatos para ensayos de viscosidad, porosidad, dilatación, tensión superficial o similares o para medidas calorimétricas, acústicas o fotométricas (incluidos los exposímetros); micrótomos"
 },
 {
  "CNKEY": "902710000080",
  "CN": "9027 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Analizadores de gases o humos"
 },
 {
  "CNKEY": "902710100080",
  "CN": "9027 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "902710900080",
  "CN": "9027 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902720000080",
  "CN": "9027 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cromatógrafos e instrumentos de electroforesis"
 },
 {
  "CNKEY": "902730000080",
  "CN": "9027 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Espectrómetros, espectrofotómetros y espectrógrafos que utilicen radiaciones ópticas (UV, visibles, IR)"
 },
 {
  "CNKEY": "902750000080",
  "CN": "9027 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás instrumentos y aparatos que utilicen radiaciones ópticas (UV, visibles, IR)"
 },
 {
  "CNKEY": "902781000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "902781000080",
  "CN": "9027 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Espectrómetros de masa"
 },
 {
  "CNKEY": "902789000080",
  "CN": "9027 89",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "902789100080",
  "CN": "9027 89 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Exposímetros"
 },
 {
  "CNKEY": "902789300080",
  "CN": "9027 89 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Peachímetros, erreachímetros y demás aparatos para medir la conductividad"
 },
 {
  "CNKEY": "902789900080",
  "CN": "9027 89 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902790000080",
  "CN": "9027 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Micrótomos; partes y accesorios"
 },
 {
  "CNKEY": "902800000080",
  "CN": "9028",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Contadores de gas, líquido o electricidad, incluidos los de calibración"
 },
 {
  "CNKEY": "902810000080",
  "CN": "9028 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Contadores de gas"
 },
 {
  "CNKEY": "902820000080",
  "CN": "9028 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Contadores de líquido"
 },
 {
  "CNKEY": "902830000080",
  "CN": "9028 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Contadores de electricidad"
 },
 {
  "CNKEY": "902830110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Para corriente alterna"
 },
 {
  "CNKEY": "902830110080",
  "CN": "9028 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Monofásica"
 },
 {
  "CNKEY": "902830190080",
  "CN": "9028 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Polifásica"
 },
 {
  "CNKEY": "902830900080",
  "CN": "9028 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902890000080",
  "CN": "9028 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "902890100080",
  "CN": "9028 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De contadores de electricidad"
 },
 {
  "CNKEY": "902890900080",
  "CN": "9028 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902900000080",
  "CN": "9029",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás contadores (por ejemplo: cuentarrevoluciones, contadores de producción, taxímetros, cuentakilómetros, podómetros); velocímetros y tacómetros, excepto los de las partidas 9014 o 9015; estroboscopios"
 },
 {
  "CNKEY": "902910000080",
  "CN": "9029 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuentarrevoluciones, contadores de producción, taxímetros, cuentakilómetros, podómetros y contadores similares"
 },
 {
  "CNKEY": "902920000080",
  "CN": "9029 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Velocímetros y tacómetros; estroboscopios"
 },
 {
  "CNKEY": "902920310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Velocímetros y tacómetros"
 },
 {
  "CNKEY": "902920310080",
  "CN": "9029 20 31",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Velocímetros para vehículos terrestres"
 },
 {
  "CNKEY": "902920380080",
  "CN": "9029 20 38",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "902920900080",
  "CN": "9029 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Estroboscopios"
 },
 {
  "CNKEY": "902990000080",
  "CN": "9029 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "903000000080",
  "CN": "9030",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Osciloscopios, analizadores de espectro y demás instrumentos y aparatos para medida o control de magnitudes eléctricas; instrumentos y aparatos para medida o detección de radiaciones alfa, beta, gamma, X, cósmicas o demás radiaciones ionizantes"
 },
 {
  "CNKEY": "903010000080",
  "CN": "9030 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Instrumentos y aparatos para medida o detección de radiaciones ionizantes"
 },
 {
  "CNKEY": "903020000080",
  "CN": "9030 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Osciloscopios y oscilógrafos"
 },
 {
  "CNKEY": "903031000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos para medida o control de tensión, intensidad, resistencia o potencia (distintos de los utilizados para medida o control de obleas («wafers») o dispositivos semiconductores)"
 },
 {
  "CNKEY": "903031000080",
  "CN": "9030 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Multímetros, sin dispositivo registrador"
 },
 {
  "CNKEY": "903032000080",
  "CN": "9030 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Multímetros, con dispositivo registrador"
 },
 {
  "CNKEY": "903033000080",
  "CN": "9030 33",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, sin dispositivo registrador"
 },
 {
  "CNKEY": "903033200080",
  "CN": "9030 33 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Instrumentos para medidas de resistencia"
 },
 {
  "CNKEY": "903033700080",
  "CN": "9030 33 70",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903039000080",
  "CN": "9030 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, con dispositivo registrador"
 },
 {
  "CNKEY": "903040000080",
  "CN": "9030 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás instrumentos y aparatos, especialmente concebidos para técnicas de telecomunicación (por ejemplo: hipsómetros, kerdómetros, distorsiómetros, sofómetros)"
 },
 {
  "CNKEY": "903082000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "903082000080",
  "CN": "9030 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para medida o control de obleas («wafers») o dispositivos semiconductores (incluidos los circuitos integrados)"
 },
 {
  "CNKEY": "903084000080",
  "CN": "9030 84 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás, con dispositivo registrador"
 },
 {
  "CNKEY": "903089000080",
  "CN": "9030 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903090000080",
  "CN": "9030 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "903100000080",
  "CN": "9031",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos, máquinas y aparatos para medida o control, no expresados ni comprendidos en otra parte de este Capítulo; proyectores de perfiles"
 },
 {
  "CNKEY": "903110000080",
  "CN": "9031 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Máquinas para equilibrar piezas mecánicas"
 },
 {
  "CNKEY": "903120000080",
  "CN": "9031 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Bancos de pruebas"
 },
 {
  "CNKEY": "903141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos, ópticos"
 },
 {
  "CNKEY": "903141000080",
  "CN": "9031 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para control de obleas («wafers») o dispositivos semiconductores (incluidos los circuitos integrados), o para control de máscaras fotográficas o retículas utilizadas en la fabricación de dispositivos semiconductores (incluidos los circuitos integrados)"
 },
 {
  "CNKEY": "903149000080",
  "CN": "9031 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "903149100080",
  "CN": "9031 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Proyectores de perfiles"
 },
 {
  "CNKEY": "903149900080",
  "CN": "9031 49 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903180000080",
  "CN": "9031 80",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos, aparatos y máquinas"
 },
 {
  "CNKEY": "903180200080",
  "CN": "9031 80 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para medida o control de magnitudes geométricas"
 },
 {
  "CNKEY": "903180800080",
  "CN": "9031 80 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903190000080",
  "CN": "9031 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "903200000080",
  "CN": "9032",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos y aparatos automáticos para regulación o control automáticos"
 },
 {
  "CNKEY": "903210000080",
  "CN": "9032 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Termostatos"
 },
 {
  "CNKEY": "903210200080",
  "CN": "9032 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Electrónicos"
 },
 {
  "CNKEY": "903210800080",
  "CN": "9032 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903220000080",
  "CN": "9032 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Manostatos (presostatos)"
 },
 {
  "CNKEY": "903281000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos y aparatos"
 },
 {
  "CNKEY": "903281000080",
  "CN": "9032 81 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Hidráulicos o neumáticos"
 },
 {
  "CNKEY": "903289000080",
  "CN": "9032 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "903290000080",
  "CN": "9032 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "903300000080",
  "CN": "9033 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios, no expresados ni comprendidos en otra parte de este Capítulo, para máquinas, aparatos, instrumentos o artículos del Capítulo 90"
 },
 {
  "CNKEY": "903300100080",
  "CN": "9033 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Módulos de unidades de retroiluminación por diodos luminiscentes (LED), que son fuentes de iluminación que constan de uno o más LED y uno o más conectores montados en un circuito impreso u otro soporte similar, y otros componentes pasivos, incluso combinados con componentes ópticos o diodos de protección, y se utilizan como retroiluminación para dispositivos de visualización de cristal líquido (LCD)"
 },
 {
  "CNKEY": "903300900080",
  "CN": "9033 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910021000090",
  "CN": "91",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 91 - APARATOS DE RELOJERÍA Y SUS PARTES"
 },
 {
  "CNKEY": "910100000080",
  "CN": "9101",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Relojes de pulsera, bolsillo y similares (incluidos los contadores de tiempo de los mismos tipos), con caja de metal precioso o chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "910111000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Relojes de pulsera, eléctricos, incluso con contador de tiempo incorporado"
 },
 {
  "CNKEY": "910111000080",
  "CN": "9101 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con indicador mecánico solamente"
 },
 {
  "CNKEY": "910119000080",
  "CN": "9101 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás relojes de pulsera, incluso con contador de tiempo incorporado"
 },
 {
  "CNKEY": "910121000080",
  "CN": "9101 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Automáticos"
 },
 {
  "CNKEY": "910129000080",
  "CN": "9101 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "910191000080",
  "CN": "9101 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910199000080",
  "CN": "9101 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910200000080",
  "CN": "9102",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Relojes de pulsera, bolsillo y similares (incluidos los contadores de tiempo de los mismos tipos), excepto los de la partida 9101"
 },
 {
  "CNKEY": "910211000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Relojes de pulsera, eléctricos, incluso con contador de tiempo incorporado"
 },
 {
  "CNKEY": "910211000080",
  "CN": "9102 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con indicador mecánico solamente"
 },
 {
  "CNKEY": "910212000080",
  "CN": "9102 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con indicador optoelectrónico solamente"
 },
 {
  "CNKEY": "910219000080",
  "CN": "9102 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910221000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás relojes de pulsera, incluso con contador de tiempo incorporado"
 },
 {
  "CNKEY": "910221000080",
  "CN": "9102 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Automáticos"
 },
 {
  "CNKEY": "910229000080",
  "CN": "9102 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910291000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "910291000080",
  "CN": "9102 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910299000080",
  "CN": "9102 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910300000080",
  "CN": "9103",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Despertadores y demás relojes de pequeño mecanismo de relojería"
 },
 {
  "CNKEY": "910310000080",
  "CN": "9103 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910390000080",
  "CN": "9103 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910400000080",
  "CN": "9104 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Relojes de tablero de instrumentos y relojes similares, para automóviles, aeronaves, barcos o demás vehículos"
 },
 {
  "CNKEY": "910500000080",
  "CN": "9105",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás relojes"
 },
 {
  "CNKEY": "910511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Despertadores"
 },
 {
  "CNKEY": "910511000080",
  "CN": "9105 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910519000080",
  "CN": "9105 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Relojes de pared"
 },
 {
  "CNKEY": "910521000080",
  "CN": "9105 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910529000080",
  "CN": "9105 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "910591000080",
  "CN": "9105 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910599000080",
  "CN": "9105 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910600000080",
  "CN": "9106",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Aparatos de control de tiempo y contadores de tiempo, con mecanismo de relojería o motor sincrónico (por ejemplo: registradores de asistencia, registradores fechadores, registradores contadores)"
 },
 {
  "CNKEY": "910610000080",
  "CN": "9106 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Registradores de asistencia; registradores fechadores y registradores contadores"
 },
 {
  "CNKEY": "910690000080",
  "CN": "9106 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910700000080",
  "CN": "9107 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Interruptores horarios y demás aparatos que permitan accionar un dispositivo en un momento dado, con mecanismo de relojería o motor sincrónico"
 },
 {
  "CNKEY": "910800000080",
  "CN": "9108",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pequeños mecanismos de relojería completos y montados"
 },
 {
  "CNKEY": "910811000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910811000080",
  "CN": "9108 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con indicador mecánico solamente o con dispositivo que permita incorporarlo"
 },
 {
  "CNKEY": "910812000080",
  "CN": "9108 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con indicador optoelectrónico solamente"
 },
 {
  "CNKEY": "910819000080",
  "CN": "9108 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910820000080",
  "CN": "9108 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Automáticos"
 },
 {
  "CNKEY": "910890000080",
  "CN": "9108 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "910900000080",
  "CN": "9109",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás mecanismos de relojería completos y montados"
 },
 {
  "CNKEY": "910910000080",
  "CN": "9109 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Eléctricos"
 },
 {
  "CNKEY": "910990000080",
  "CN": "9109 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "911000000080",
  "CN": "9110",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mecanismos de relojería completos, sin montar o parcialmente montados («chablons»); mecanismos de relojería incompletos, montados; mecanismos de relojería «en blanco» («ébauches»)"
 },
 {
  "CNKEY": "911011000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pequeños mecanismos"
 },
 {
  "CNKEY": "911011000080",
  "CN": "9110 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Mecanismos completos, sin montar o parcialmente montados («chablons»)"
 },
 {
  "CNKEY": "911011100080",
  "CN": "9110 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "De volante y espiral"
 },
 {
  "CNKEY": "911011900080",
  "CN": "9110 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "911012000080",
  "CN": "9110 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mecanismos incompletos, montados"
 },
 {
  "CNKEY": "911019000080",
  "CN": "9110 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mecanismos «en blanco» («ébauches»)"
 },
 {
  "CNKEY": "911090000080",
  "CN": "9110 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "911100000080",
  "CN": "9111",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas de los relojes de las partidas 9101 o 9102, y sus partes"
 },
 {
  "CNKEY": "911110000080",
  "CN": "9111 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cajas de metal precioso o chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "911120000080",
  "CN": "9111 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cajas de metal común, incluso dorado o plateado"
 },
 {
  "CNKEY": "911180000080",
  "CN": "9111 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás cajas"
 },
 {
  "CNKEY": "911190000080",
  "CN": "9111 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "911200000080",
  "CN": "9112",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas y envolturas similares para los demás aparatos de relojería y sus partes"
 },
 {
  "CNKEY": "911220000080",
  "CN": "9112 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cajas y envolturas similares"
 },
 {
  "CNKEY": "911290000080",
  "CN": "9112 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "911300000080",
  "CN": "9113",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pulseras para reloj y sus partes"
 },
 {
  "CNKEY": "911310000080",
  "CN": "9113 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De metal precioso o chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "911310100080",
  "CN": "9113 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De metal precioso"
 },
 {
  "CNKEY": "911310900080",
  "CN": "9113 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De chapado de metal precioso (plaqué)"
 },
 {
  "CNKEY": "911320000080",
  "CN": "9113 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De metal común, incluso dorado o plateado"
 },
 {
  "CNKEY": "911390000080",
  "CN": "9113 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "911400000080",
  "CN": "9114",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás partes de aparatos de relojería"
 },
 {
  "CNKEY": "911430000080",
  "CN": "9114 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Esferas o cuadrantes"
 },
 {
  "CNKEY": "911440000080",
  "CN": "9114 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Platinas y puentes"
 },
 {
  "CNKEY": "911490000080",
  "CN": "9114 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "911490100080",
  "CN": "9114 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muelles (resortes), incluidas las espirales"
 },
 {
  "CNKEY": "911490900080",
  "CN": "9114 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "920021000090",
  "CN": "92",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 92 - INSTRUMENTOS MUSICALES; SUS PARTES Y ACCESORIOS"
 },
 {
  "CNKEY": "920100000080",
  "CN": "9201",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pianos, incluso automáticos; clavecines y demás instrumentos de cuerda con teclado"
 },
 {
  "CNKEY": "920110000080",
  "CN": "9201 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pianos verticales"
 },
 {
  "CNKEY": "920110100080",
  "CN": "9201 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Nuevos"
 },
 {
  "CNKEY": "920110900080",
  "CN": "9201 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Usados"
 },
 {
  "CNKEY": "920120000080",
  "CN": "9201 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Pianos de cola"
 },
 {
  "CNKEY": "920190000080",
  "CN": "9201 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920200000080",
  "CN": "9202",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás instrumentos musicales de cuerda (por ejemplo: guitarras, violines, arpas)"
 },
 {
  "CNKEY": "920210000080",
  "CN": "9202 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De arco"
 },
 {
  "CNKEY": "920210100080",
  "CN": "9202 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Violines"
 },
 {
  "CNKEY": "920210900080",
  "CN": "9202 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920290000080",
  "CN": "9202 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920290300080",
  "CN": "9202 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Guitarras"
 },
 {
  "CNKEY": "920290800080",
  "CN": "9202 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920500000080",
  "CN": "9205",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos musicales de viento (por ejemplo: órganos de tubos y teclado, acordeones, clarinetes, trompetas, gaitas), excepto los orquestriones y los organillos"
 },
 {
  "CNKEY": "920510000080",
  "CN": "9205 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Instrumentos llamados «metales»"
 },
 {
  "CNKEY": "920590000080",
  "CN": "9205 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920590100080",
  "CN": "9205 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Acordeones e instrumentos similares"
 },
 {
  "CNKEY": "920590300080",
  "CN": "9205 90 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Armónicas"
 },
 {
  "CNKEY": "920590500080",
  "CN": "9205 90 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Órganos de tubos y teclado; armonios e instrumentos similares de teclado y lengüetas metálicas libres"
 },
 {
  "CNKEY": "920590900080",
  "CN": "9205 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920600000080",
  "CN": "9206 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Instrumentos musicales de percusión (por ejemplo: tambores, cajas, xilófonos, platillos, castañuelas, maracas)"
 },
 {
  "CNKEY": "920700000080",
  "CN": "9207",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Instrumentos musicales en los que el sonido se produzca o tenga que amplificarse eléctricamente (por ejemplo: órganos, guitarras, acordeones)"
 },
 {
  "CNKEY": "920710000080",
  "CN": "9207 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Instrumentos de teclado, excepto los acordeones"
 },
 {
  "CNKEY": "920710100080",
  "CN": "9207 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Órganos"
 },
 {
  "CNKEY": "920710300080",
  "CN": "9207 10 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pianos digitales"
 },
 {
  "CNKEY": "920710500080",
  "CN": "9207 10 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Sintetizadores"
 },
 {
  "CNKEY": "920710800080",
  "CN": "9207 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920790000080",
  "CN": "9207 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920790100080",
  "CN": "9207 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Guitarras"
 },
 {
  "CNKEY": "920790900080",
  "CN": "9207 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920800000080",
  "CN": "9208",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cajas de música, orquestriones, organillos, pájaros cantores, sierras musicales y demás instrumentos musicales no comprendidos en otra partida de este Capítulo; reclamos de cualquier clase; silbatos, cuernos y demás instrumentos de boca, de llamada o aviso"
 },
 {
  "CNKEY": "920810000080",
  "CN": "9208 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cajas de música"
 },
 {
  "CNKEY": "920890000080",
  "CN": "9208 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "920900000080",
  "CN": "9209",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes (por ejemplo: mecanismos de cajas de música) y accesorios (por ejemplo: tarjetas, discos y rollos para aparatos mecánicos) de instrumentos musicales; metrónomos y diapasones de cualquier tipo"
 },
 {
  "CNKEY": "920930000080",
  "CN": "9209 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cuerdas armónicas"
 },
 {
  "CNKEY": "920991000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920991000080",
  "CN": "9209 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de pianos"
 },
 {
  "CNKEY": "920992000080",
  "CN": "9209 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de instrumentos musicales de la partida 9202"
 },
 {
  "CNKEY": "920994000080",
  "CN": "9209 94 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de instrumentos musicales de la partida 9207"
 },
 {
  "CNKEY": "920999000080",
  "CN": "9209 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920999200080",
  "CN": "9209 99 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes y accesorios de instrumentos musicales de la partida 9205"
 },
 {
  "CNKEY": "920999400010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "920999400080",
  "CN": "9209 99 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Metrónomos y diapasones"
 },
 {
  "CNKEY": "920999500080",
  "CN": "9209 99 50",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Mecanismos de cajas de música"
 },
 {
  "CNKEY": "920999700080",
  "CN": "9209 99 70",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "930011000090",
  "CN": "XIX",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XIX - ARMAS, MUNICIONES, Y SUS PARTES Y ACCESORIOS"
 },
 {
  "CNKEY": "930021000090",
  "CN": "93",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 93 - ARMAS, MUNICIONES, Y SUS PARTES Y ACCESORIOS"
 },
 {
  "CNKEY": "930100000080",
  "CN": "9301",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Armas de guerra, excepto los revólveres, pistolas y armas blancas"
 },
 {
  "CNKEY": "930110000080",
  "CN": "9301 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Piezas de artillería (por ejemplo: cañones, obuses y morteros)"
 },
 {
  "CNKEY": "930120000080",
  "CN": "9301 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Lanzacohetes; lanzallamas; lanzagranadas; lanzatorpedos y lanzadores similares"
 },
 {
  "CNKEY": "930190000080",
  "CN": "9301 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "930200000080",
  "CN": "9302 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Revólveres y pistolas, excepto los de las partidas 9303 o 9304"
 },
 {
  "CNKEY": "930300000080",
  "CN": "9303",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Las demás armas de fuego y artefactos similares que utilicen la deflagración de pólvora (por ejemplo: armas de caza, armas de avancarga, pistolas lanzacohete y demás artefactos concebidos únicamente para lanzar cohetes de señal, pistolas y revólveres de fogueo, pistolas de matarife, cañones lanzacabos)"
 },
 {
  "CNKEY": "930310000080",
  "CN": "9303 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Armas de avancarga"
 },
 {
  "CNKEY": "930320000080",
  "CN": "9303 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás armas largas de caza o tiro deportivo que tengan, por lo menos, un cañón de ánima lisa"
 },
 {
  "CNKEY": "930320100080",
  "CN": "9303 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Con un cañón de ánima lisa"
 },
 {
  "CNKEY": "930320950080",
  "CN": "9303 20 95",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "930330000080",
  "CN": "9303 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás armas largas de caza o tiro deportivo"
 },
 {
  "CNKEY": "930390000080",
  "CN": "9303 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Las demás"
 },
 {
  "CNKEY": "930400000080",
  "CN": "9304 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "p\/st",
  "ES": "Las demás armas [por ejemplo: armas largas y pistolas de muelle (resorte), aire comprimido o gas, porras], excepto las de la partida 9307"
 },
 {
  "CNKEY": "930500000080",
  "CN": "9305",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Partes y accesorios de los artículos de las partidas 9301 a 9304"
 },
 {
  "CNKEY": "930510000080",
  "CN": "9305 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De revólveres o pistolas"
 },
 {
  "CNKEY": "930520000080",
  "CN": "9305 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De armas largas de la partida 9303"
 },
 {
  "CNKEY": "930591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "930591000080",
  "CN": "9305 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De armas de guerra de la partida 9301"
 },
 {
  "CNKEY": "930599000080",
  "CN": "9305 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "930600000080",
  "CN": "9306",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bombas, granadas, torpedos, minas, misiles, cartuchos y demás municiones y proyectiles, y sus partes, incluidas las postas, perdigones y tacos para cartuchos"
 },
 {
  "CNKEY": "930621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cartuchos para armas largas con cañón de ánima lisa y sus partes; balines para armas de aire comprimido"
 },
 {
  "CNKEY": "930621000080",
  "CN": "9306 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "1000 p\/st",
  "ES": "Cartuchos"
 },
 {
  "CNKEY": "930629000080",
  "CN": "9306 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "930630000080",
  "CN": "9306 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás cartuchos y sus partes"
 },
 {
  "CNKEY": "930630100080",
  "CN": "9306 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Para revólveres y pistolas de la partida 9302 y para pistolas ametralladoras de la partida 9301"
 },
 {
  "CNKEY": "930630300010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "930630300080",
  "CN": "9306 30 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Para armas de guerra"
 },
 {
  "CNKEY": "930630900080",
  "CN": "9306 30 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "930690000080",
  "CN": "9306 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "930690100080",
  "CN": "9306 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De guerra"
 },
 {
  "CNKEY": "930690900080",
  "CN": "9306 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "930700000080",
  "CN": "9307 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Sables, espadas, bayonetas, lanzas y demás armas blancas, sus partes y fundas"
 },
 {
  "CNKEY": "940011000090",
  "CN": "XX",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XX - MERCANCÍAS Y PRODUCTOS DIVERSOS"
 },
 {
  "CNKEY": "940021000090",
  "CN": "94",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 94 - MUEBLES; MOBILIARIO MEDICOQUIRÚRGICO; ARTÍCULOS DE CAMA Y SIMILARES; LUMINARIAS Y APARATOS DE ALUMBRADO NO EXPRESADOS NI COMPRENDIDOS EN OTRA PARTE; ANUNCIOS, LETREROS Y PLACAS INDICADORAS LUMINOSOS Y ARTÍCULOS SIMILARES; CONSTRUCCIONES PREFABRICADAS"
 },
 {
  "CNKEY": "940100000080",
  "CN": "9401",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Asientos (excepto los de la partida 9402), incluso los transformables en cama, y sus partes"
 },
 {
  "CNKEY": "940110000080",
  "CN": "9401 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Asientos de los tipos utilizados en aeronaves"
 },
 {
  "CNKEY": "940120000080",
  "CN": "9401 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Asientos de los tipos utilizados en vehículos automóviles"
 },
 {
  "CNKEY": "940131000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Asientos giratorios de altura ajustable"
 },
 {
  "CNKEY": "940131000080",
  "CN": "9401 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "940139000080",
  "CN": "9401 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940141000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Asientos transformables en cama, excepto el material de acampar o de jardín"
 },
 {
  "CNKEY": "940141000080",
  "CN": "9401 41 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "940149000080",
  "CN": "9401 49 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940152000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Asientos de roten (ratán), mimbre, bambú o materias similares"
 },
 {
  "CNKEY": "940152000080",
  "CN": "9401 52 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "940153000080",
  "CN": "9401 53 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De roten (ratán)"
 },
 {
  "CNKEY": "940159000080",
  "CN": "9401 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940161000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás asientos, con armazón de madera"
 },
 {
  "CNKEY": "940161000080",
  "CN": "9401 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con relleno"
 },
 {
  "CNKEY": "940169000080",
  "CN": "9401 69 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940171000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás asientos, con armazón de metal"
 },
 {
  "CNKEY": "940171000080",
  "CN": "9401 71 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con relleno"
 },
 {
  "CNKEY": "940179000080",
  "CN": "9401 79 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940180000080",
  "CN": "9401 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás asientos"
 },
 {
  "CNKEY": "940191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "940191000080",
  "CN": "9401 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De madera"
 },
 {
  "CNKEY": "940191100080",
  "CN": "9401 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De asientos de los tipos utilizados en aeronaves"
 },
 {
  "CNKEY": "940191900080",
  "CN": "9401 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940199000080",
  "CN": "9401 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940199100080",
  "CN": "9401 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De asientos de los tipos utilizados en aeronaves"
 },
 {
  "CNKEY": "940199900080",
  "CN": "9401 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940200000080",
  "CN": "9402",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Mobiliario para medicina, cirugía, odontología o veterinaria (por ejemplo: mesas de operaciones o de reconocimiento, camas con mecanismo para uso clínico, sillones de dentista); sillones de peluquería y sillones similares, con dispositivos de orientación y elevación; partes de estos artículos"
 },
 {
  "CNKEY": "940210000080",
  "CN": "9402 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Sillones de dentista, de peluquería y sillones similares, y sus partes"
 },
 {
  "CNKEY": "940290000080",
  "CN": "9402 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940300000080",
  "CN": "9403",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Los demás muebles y sus partes"
 },
 {
  "CNKEY": "940310000080",
  "CN": "9403 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muebles de metal de los tipos utilizados en oficinas"
 },
 {
  "CNKEY": "940310510010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De altura inferior o igual a 80 cm"
 },
 {
  "CNKEY": "940310510080",
  "CN": "9403 10 51",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mesas"
 },
 {
  "CNKEY": "940310580080",
  "CN": "9403 10 58",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940310910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De altura superior a 80 cm"
 },
 {
  "CNKEY": "940310910080",
  "CN": "9403 10 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Armarios con puertas, persianas o trampillas"
 },
 {
  "CNKEY": "940310930080",
  "CN": "9403 10 93",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Armarios con cajones, clasificadores y ficheros"
 },
 {
  "CNKEY": "940310980080",
  "CN": "9403 10 98",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940320000080",
  "CN": "9403 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás muebles de metal"
 },
 {
  "CNKEY": "940320200080",
  "CN": "9403 20 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Camas"
 },
 {
  "CNKEY": "940320800080",
  "CN": "9403 20 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940330000080",
  "CN": "9403 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muebles de madera de los tipos utilizados en oficinas"
 },
 {
  "CNKEY": "940330110010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De altura inferior o igual a 80 cm"
 },
 {
  "CNKEY": "940330110080",
  "CN": "9403 30 11",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Mesas"
 },
 {
  "CNKEY": "940330190080",
  "CN": "9403 30 19",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940330910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De altura superior a 80 cm"
 },
 {
  "CNKEY": "940330910080",
  "CN": "9403 30 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Armarios, clasificadores y ficheros"
 },
 {
  "CNKEY": "940330990080",
  "CN": "9403 30 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940340000080",
  "CN": "9403 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muebles de madera de los tipos utilizados en cocinas"
 },
 {
  "CNKEY": "940340100080",
  "CN": "9403 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Elementos de cocinas"
 },
 {
  "CNKEY": "940340900080",
  "CN": "9403 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940350000080",
  "CN": "9403 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Muebles de madera de los tipos utilizados en dormitorios"
 },
 {
  "CNKEY": "940360000080",
  "CN": "9403 60",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás muebles de madera"
 },
 {
  "CNKEY": "940360100080",
  "CN": "9403 60 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muebles de madera de los tipos utilizados en comedores y cuartos de estar"
 },
 {
  "CNKEY": "940360300080",
  "CN": "9403 60 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muebles de madera de los tipos utilizados en tiendas y almacenes"
 },
 {
  "CNKEY": "940360900080",
  "CN": "9403 60 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás muebles de madera"
 },
 {
  "CNKEY": "940370000080",
  "CN": "9403 70 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Muebles de plástico"
 },
 {
  "CNKEY": "940382000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muebles de otras materias, incluidos el roten (ratán), mimbre, bambú o materias similares"
 },
 {
  "CNKEY": "940382000080",
  "CN": "9403 82 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De bambú"
 },
 {
  "CNKEY": "940383000080",
  "CN": "9403 83 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De roten (ratán)"
 },
 {
  "CNKEY": "940389000080",
  "CN": "9403 89 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940391000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "940391000080",
  "CN": "9403 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "940399000080",
  "CN": "9403 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940399100080",
  "CN": "9403 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De metal"
 },
 {
  "CNKEY": "940399900080",
  "CN": "9403 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940400000080",
  "CN": "9404",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Somieres; artículos de cama y artículos similares (por ejemplo: colchones, cubrepiés, edredones, cojines, pufs, almohadas), bien con muelles (resortes), bien rellenos o guarnecidos interiormente con cualquier materia, incluidos los de caucho o plástico celulares, recubiertos o no"
 },
 {
  "CNKEY": "940410000080",
  "CN": "9404 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Somieres"
 },
 {
  "CNKEY": "940421000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Colchones"
 },
 {
  "CNKEY": "940421000080",
  "CN": "9404 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De caucho o plástico celulares, recubiertos o no"
 },
 {
  "CNKEY": "940421100080",
  "CN": "9404 21 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De caucho"
 },
 {
  "CNKEY": "940421900080",
  "CN": "9404 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico celular"
 },
 {
  "CNKEY": "940429000080",
  "CN": "9404 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De otras materias"
 },
 {
  "CNKEY": "940429100080",
  "CN": "9404 29 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De muelles metálicos"
 },
 {
  "CNKEY": "940429900080",
  "CN": "9404 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940430000080",
  "CN": "9404 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Sacos (bolsas) de dormir"
 },
 {
  "CNKEY": "940440000080",
  "CN": "9404 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cubrepiés, colchas, edredones y cobertores"
 },
 {
  "CNKEY": "940440100080",
  "CN": "9404 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rellenos de plumas o plumón"
 },
 {
  "CNKEY": "940440900080",
  "CN": "9404 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940490000080",
  "CN": "9404 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940490100080",
  "CN": "9404 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rellenos de plumas o plumón"
 },
 {
  "CNKEY": "940490900080",
  "CN": "9404 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940500000080",
  "CN": "9405",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Luminarias y aparatos de alumbrado (incluidos los proyectores), y sus partes, no expresados ni comprendidos en otra parte; anuncios, letreros y placas indicadoras luminosos y artículos similares, con fuente de luz inseparable, y sus partes no expresadas ni comprendidas en otra parte"
 },
 {
  "CNKEY": "940511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lámparas y demás luminarias, eléctricas, para colgar o fijar al techo o a la pared, excepto las de los tipos utilizados para el alumbrado de espacios o vías públicos"
 },
 {
  "CNKEY": "940511000080",
  "CN": "9405 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Diseñadas para ser utilizadas únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940511400080",
  "CN": "9405 11 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico o de cerámica"
 },
 {
  "CNKEY": "940511500080",
  "CN": "9405 11 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "940511900080",
  "CN": "9405 11 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940519000080",
  "CN": "9405 19",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940519400080",
  "CN": "9405 19 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico o de cerámica"
 },
 {
  "CNKEY": "940519500080",
  "CN": "9405 19 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "940519900080",
  "CN": "9405 19 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lámparas eléctricas de mesa, oficina, cabecera o de pie"
 },
 {
  "CNKEY": "940521000080",
  "CN": "9405 21",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Diseñadas para ser utilizadas únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940521400080",
  "CN": "9405 21 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico o de cerámica"
 },
 {
  "CNKEY": "940521500080",
  "CN": "9405 21 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "940521900080",
  "CN": "9405 21 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940529000080",
  "CN": "9405 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940529400080",
  "CN": "9405 29 40",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico o de cerámica"
 },
 {
  "CNKEY": "940529500080",
  "CN": "9405 29 50",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "940529900080",
  "CN": "9405 29 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Guirnaldas eléctricas de los tipos utilizados en árboles de Navidad"
 },
 {
  "CNKEY": "940531000080",
  "CN": "9405 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Diseñadas para ser utilizadas únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940539000080",
  "CN": "9405 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "940541000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás luminarias y aparatos de alumbrado, eléctricos"
 },
 {
  "CNKEY": "940541000080",
  "CN": "9405 41",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Fotovoltaicos, diseñados para ser utilizados únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940541100080",
  "CN": "9405 41 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Proyectores"
 },
 {
  "CNKEY": "940541310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940541310080",
  "CN": "9405 41 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940541390080",
  "CN": "9405 41 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940542000080",
  "CN": "9405 42",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás, diseñados para ser utilizados únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940542100080",
  "CN": "9405 42 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Proyectores"
 },
 {
  "CNKEY": "940542310010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940542310080",
  "CN": "9405 42 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940542390080",
  "CN": "9405 42 39",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940549000080",
  "CN": "9405 49",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940549100080",
  "CN": "9405 49 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Proyectores"
 },
 {
  "CNKEY": "940549400010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940549400080",
  "CN": "9405 49 40",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940549900080",
  "CN": "9405 49 90",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940550000080",
  "CN": "9405 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Luminarias y aparatos de alumbrado, no eléctricos"
 },
 {
  "CNKEY": "940561000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Anuncios, letreros y placas indicadoras luminosos y artículos similares"
 },
 {
  "CNKEY": "940561000080",
  "CN": "9405 61",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Diseñados para ser utilizados únicamente con fuentes luminosas de diodos emisores de luz (LED)"
 },
 {
  "CNKEY": "940561200080",
  "CN": "9405 61 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940561800080",
  "CN": "9405 61 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940569000080",
  "CN": "9405 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "940569200080",
  "CN": "9405 69 20",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940569800080",
  "CN": "9405 69 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "940591000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "940591000080",
  "CN": "9405 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "De vidrio"
 },
 {
  "CNKEY": "940591100080",
  "CN": "9405 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Artículos para equipar los aparatos eléctricos de iluminación (excepto los proyectores)"
 },
 {
  "CNKEY": "940591900080",
  "CN": "9405 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "940592000080",
  "CN": "9405 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "940599000080",
  "CN": "9405 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "940600000080",
  "CN": "9406",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Construcciones prefabricadas"
 },
 {
  "CNKEY": "940610000080",
  "CN": "9406 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "940620000080",
  "CN": "9406 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Unidades de construcción modular, de acero"
 },
 {
  "CNKEY": "940690000080",
  "CN": "9406 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940690100080",
  "CN": "9406 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Casas móviles"
 },
 {
  "CNKEY": "940690310010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Las demás"
 },
 {
  "CNKEY": "940690310020",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "De hierro o acero"
 },
 {
  "CNKEY": "940690310080",
  "CN": "9406 90 31",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Invernaderos"
 },
 {
  "CNKEY": "940690380080",
  "CN": "9406 90 38",
  "CN_LEVEL": 7,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "940690900080",
  "CN": "9406 90 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "950021000090",
  "CN": "95",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 95 - JUGUETES, JUEGOS Y ARTÍCULOS PARA RECREO O DEPORTE; SUS PARTES Y ACCESORIOS"
 },
 {
  "CNKEY": "950300000080",
  "CN": "9503 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Triciclos, patinetes, coches de pedal y juguetes similares con ruedas; coches y sillas de ruedas para muñecas o muñecos; muñecas o muñecos; los demás juguetes; modelos reducidos y modelos similares, para entretenimiento, incluso animados; rompecabezas de cualquier clase"
 },
 {
  "CNKEY": "950300100080",
  "CN": "9503 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Triciclos, patinetes, coches de pedal y juguetes similares con ruedas; coches y sillas de ruedas para muñecas o muñecos"
 },
 {
  "CNKEY": "950300210010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Muñecas y muñecos que representen solamente seres humanos y partes y accesorios"
 },
 {
  "CNKEY": "950300210080",
  "CN": "9503 00 21",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Muñecas y muñecos"
 },
 {
  "CNKEY": "950300290080",
  "CN": "9503 00 29",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "950300300080",
  "CN": "9503 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Trenes eléctricos, incluidos los carriles (rieles), señales y demás accesorios y modelos reducidos para ensamblar, incluso animados"
 },
 {
  "CNKEY": "950300350010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás juegos o surtidos y juguetes de construcción"
 },
 {
  "CNKEY": "950300350080",
  "CN": "9503 00 35",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "950300390080",
  "CN": "9503 00 39",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "950300410010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Juguetes que representen animales o seres no humanos"
 },
 {
  "CNKEY": "950300410080",
  "CN": "9503 00 41",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Rellenos"
 },
 {
  "CNKEY": "950300490080",
  "CN": "9503 00 49",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950300550080",
  "CN": "9503 00 55",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Instrumentos y aparatos musicales, de juguete"
 },
 {
  "CNKEY": "950300610010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Rompecabezas"
 },
 {
  "CNKEY": "950300610080",
  "CN": "9503 00 61",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De madera"
 },
 {
  "CNKEY": "950300690080",
  "CN": "9503 00 69",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950300700080",
  "CN": "9503 00 70",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás juguetes presentados en juegos o surtidos o en panoplias"
 },
 {
  "CNKEY": "950300750010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás juguetes y modelos, con motor"
 },
 {
  "CNKEY": "950300750080",
  "CN": "9503 00 75",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "950300790080",
  "CN": "9503 00 79",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "950300810010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950300810080",
  "CN": "9503 00 81",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Armas de juguete"
 },
 {
  "CNKEY": "950300850080",
  "CN": "9503 00 85",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Modelos en miniatura de metal, obtenidos por moldeo"
 },
 {
  "CNKEY": "950300870080",
  "CN": "9503 00 87",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Dispositivos de enseñanza portátiles, electrónicos e interactivos especialmente diseñados para los niños"
 },
 {
  "CNKEY": "950300950010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950300950080",
  "CN": "9503 00 95",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "950300990080",
  "CN": "9503 00 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950400000080",
  "CN": "9504",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Videoconsolas y máquinas de videojuego, juegos de sociedad, incluidos los juegos con motor o mecanismo, billares, mesas especiales para juegos de casino y juegos de bolos automáticos («bowlings»), juegos activados con monedas, billetes de banco, tarjetas bancarias, fichas o por cualquier otro medio de pago"
 },
 {
  "CNKEY": "950420000080",
  "CN": "9504 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Billares de cualquier clase y sus accesorios"
 },
 {
  "CNKEY": "950430000080",
  "CN": "9504 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás juegos activados con monedas, billetes de banco, tarjetas bancarias, fichas o por cualquier otro medio de pago, excepto los juegos de bolos automáticos («bowling»)"
 },
 {
  "CNKEY": "950430100080",
  "CN": "9504 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Juegos con pantalla"
 },
 {
  "CNKEY": "950430200080",
  "CN": "9504 30 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Los demás juegos"
 },
 {
  "CNKEY": "950430900080",
  "CN": "9504 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "950440000080",
  "CN": "9504 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Naipes"
 },
 {
  "CNKEY": "950450000080",
  "CN": "9504 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Videoconsolas y máquinas  de videojuego, excepto las de la subpartida 950430"
 },
 {
  "CNKEY": "950490000080",
  "CN": "9504 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950490100080",
  "CN": "9504 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Circuitos eléctricos de coches con características de juegos de competición"
 },
 {
  "CNKEY": "950490800080",
  "CN": "9504 90 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950500000080",
  "CN": "9505",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos para fiestas, carnaval u otras diversiones, incluidos los de magia y artículos sorpresa"
 },
 {
  "CNKEY": "950510000080",
  "CN": "9505 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Artículos para fiestas de Navidad"
 },
 {
  "CNKEY": "950510100080",
  "CN": "9505 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De vidrio"
 },
 {
  "CNKEY": "950510900080",
  "CN": "9505 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De las demás materias"
 },
 {
  "CNKEY": "950590000080",
  "CN": "9505 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950600000080",
  "CN": "9506",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Artículos y material para cultura física, gimnasia, atletismo, demás deportes (incluido el tenis de mesa), o para juegos al aire libre, no expresados ni comprendidos en otra parte de este Capítulo; piscinas, incluso infantiles"
 },
 {
  "CNKEY": "950611000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esquís para nieve y demás artículos para práctica del esquí de nieve"
 },
 {
  "CNKEY": "950611000080",
  "CN": "9506 11",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Esquís"
 },
 {
  "CNKEY": "950611100080",
  "CN": "9506 11 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Esquís de fondo"
 },
 {
  "CNKEY": "950611210010",
  "CN": null,
  "CN_LEVEL": 6,
  "T_SU_SU": null,
  "ES": "Esquís alpinos"
 },
 {
  "CNKEY": "950611210080",
  "CN": "9506 11 21",
  "CN_LEVEL": 7,
  "T_SU_SU": "p\/st",
  "ES": "Monoesquís y «snowboard» (tabla para nieve)"
 },
 {
  "CNKEY": "950611290080",
  "CN": "9506 11 29",
  "CN_LEVEL": 7,
  "T_SU_SU": "pa",
  "ES": "Otros"
 },
 {
  "CNKEY": "950611800080",
  "CN": "9506 11 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "pa",
  "ES": "Otros esquís"
 },
 {
  "CNKEY": "950612000080",
  "CN": "9506 12 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Fijadores de esquí"
 },
 {
  "CNKEY": "950619000080",
  "CN": "9506 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Esquís acuáticos, tablas, deslizadores de vela y demás artículos para práctica de deportes acuáticos"
 },
 {
  "CNKEY": "950621000080",
  "CN": "9506 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Deslizadores de vela"
 },
 {
  "CNKEY": "950629000080",
  "CN": "9506 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950631000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Palos de golf («clubs») y demás artículos para golf"
 },
 {
  "CNKEY": "950631000080",
  "CN": "9506 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Palos de golf («clubs») completos"
 },
 {
  "CNKEY": "950632000080",
  "CN": "9506 32 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pelotas"
 },
 {
  "CNKEY": "950639000080",
  "CN": "9506 39",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950639100080",
  "CN": "9506 39 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Partes de palos («clubs»)"
 },
 {
  "CNKEY": "950639900080",
  "CN": "9506 39 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950640000080",
  "CN": "9506 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Artículos y material para tenis de mesa"
 },
 {
  "CNKEY": "950651000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Raquetas de tenis, bádminton o similares, incluso sin cordaje"
 },
 {
  "CNKEY": "950651000080",
  "CN": "9506 51 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Raquetas de tenis, incluso sin cordaje"
 },
 {
  "CNKEY": "950659000080",
  "CN": "9506 59 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "950661000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Balones y pelotas, excepto las de golf o tenis de mesa"
 },
 {
  "CNKEY": "950661000080",
  "CN": "9506 61 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pelotas de tenis"
 },
 {
  "CNKEY": "950662000080",
  "CN": "9506 62 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Inflables"
 },
 {
  "CNKEY": "950669000080",
  "CN": "9506 69",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950669100080",
  "CN": "9506 69 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pelotas de «cricket» o de polo"
 },
 {
  "CNKEY": "950669900080",
  "CN": "9506 69 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950670000080",
  "CN": "9506 70",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Patines para hielo y patines de ruedas, incluido el calzado con patines fijos"
 },
 {
  "CNKEY": "950670100080",
  "CN": "9506 70 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Patines para hielo"
 },
 {
  "CNKEY": "950670300080",
  "CN": "9506 70 30",
  "CN_LEVEL": 5,
  "T_SU_SU": "pa",
  "ES": "Patines de ruedas"
 },
 {
  "CNKEY": "950670900080",
  "CN": "9506 70 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Partes y accesorios"
 },
 {
  "CNKEY": "950691000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950691000080",
  "CN": "9506 91",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Artículos y material para cultura física, gimnasia o atletismo"
 },
 {
  "CNKEY": "950691100080",
  "CN": "9506 91 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Aparatos de gimnasia con sistema de esfuerzo regulable"
 },
 {
  "CNKEY": "950691900080",
  "CN": "9506 91 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950699000080",
  "CN": "9506 99",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "950699100080",
  "CN": "9506 99 10",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Artículos de «cricket» o de polo, excepto las pelotas"
 },
 {
  "CNKEY": "950699900080",
  "CN": "9506 99 90",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950700000080",
  "CN": "9507",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cañas de pescar, anzuelos y demás artículos para la pesca con caña; salabardos, cazamariposas y redes similares; señuelos (excepto los de las partidas 9208 o 9705) y artículos de caza similares"
 },
 {
  "CNKEY": "950710000080",
  "CN": "9507 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Cañas de pescar"
 },
 {
  "CNKEY": "950720000080",
  "CN": "9507 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Anzuelos, incluso montados en sedal (tanza)"
 },
 {
  "CNKEY": "950720100080",
  "CN": "9507 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Anzuelos sin brazolada"
 },
 {
  "CNKEY": "950720900080",
  "CN": "9507 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950730000080",
  "CN": "9507 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Carretes de pesca"
 },
 {
  "CNKEY": "950790000080",
  "CN": "9507 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "950800000080",
  "CN": "9508",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Circos y zoológicos, ambulantes; atracciones para parques de diversiones y atracciones de parques acuáticos; atracciones de feria, incluidas las casetas de tiro; teatros ambulantes"
 },
 {
  "CNKEY": "950810000080",
  "CN": "9508 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Circos y zoológicos ambulantes"
 },
 {
  "CNKEY": "950821000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Atracciones para parques de diversiones y atracciones de parques acuáticos"
 },
 {
  "CNKEY": "950821000080",
  "CN": "9508 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Montañas rusas"
 },
 {
  "CNKEY": "950822000080",
  "CN": "9508 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Carruseles, columpios y tiovivos"
 },
 {
  "CNKEY": "950823000080",
  "CN": "9508 23 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Autos de choque"
 },
 {
  "CNKEY": "950824000080",
  "CN": "9508 24 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Simuladores de movimiento y cines dinámicos"
 },
 {
  "CNKEY": "950825000080",
  "CN": "9508 25 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Paseos acuáticos"
 },
 {
  "CNKEY": "950826000080",
  "CN": "9508 26 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Atracciones de parques acuáticos"
 },
 {
  "CNKEY": "950829000080",
  "CN": "9508 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "950830000080",
  "CN": "9508 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Atracciones de feria"
 },
 {
  "CNKEY": "950840000080",
  "CN": "9508 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Teatros ambulantes"
 },
 {
  "CNKEY": "960021000090",
  "CN": "96",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 96 - MANUFACTURAS DIVERSAS"
 },
 {
  "CNKEY": "960100000080",
  "CN": "9601",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Marfil, hueso, concha (caparazón) de tortuga, cuerno, asta, coral, nácar y demás materias animales para tallar, trabajadas, y manufacturas de estas materias, incluso las obtenidas por moldeo"
 },
 {
  "CNKEY": "960110000080",
  "CN": "9601 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Marfil trabajado y sus manufacturas"
 },
 {
  "CNKEY": "960190000080",
  "CN": "9601 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960200000080",
  "CN": "9602 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Materias vegetales o minerales para tallar, trabajadas, y manufacturas de estas materias; manufacturas moldeadas o talladas de cera, parafina, estearina, gomas o resinas naturales o pasta para modelar y demás manufacturas moldeadas o talladas no expresadas ni comprendidas en otra parte; gelatina sin endurecer trabajada, excepto la de la partida 3503, y manufacturas de gelatina sin endurecer"
 },
 {
  "CNKEY": "960300000080",
  "CN": "9603",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Escobas y escobillas, cepillos, brochas y pinceles (incluso si son partes de máquinas, aparatos o vehículos), escobas mecánicas, sin motor, de uso manual, fregona o mopas y plumeros; cabezas preparadas para artículos de cepillería; almohadillas y muñequillas y rodillos, para pintar; rasquetas de caucho o materia flexible análoga"
 },
 {
  "CNKEY": "960310000080",
  "CN": "9603 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Escobas y escobillas de ramitas u otra materia vegetal atada en haces, incluso con mango"
 },
 {
  "CNKEY": "960321000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cepillos de dientes, brochas de afeitar, cepillos para cabello, pestañas o uñas y demás cepillos para aseo personal, incluidos los que sean partes de aparatos"
 },
 {
  "CNKEY": "960321000080",
  "CN": "9603 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Cepillos de dientes, incluidos los cepillos para dentaduras postizas"
 },
 {
  "CNKEY": "960329000080",
  "CN": "9603 29",
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960329300080",
  "CN": "9603 29 30",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Cepillos para cabello"
 },
 {
  "CNKEY": "960329800080",
  "CN": "9603 29 80",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960330000080",
  "CN": "9603 30",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pinceles y brochas para pintura artística, pinceles para escribir y pinceles similares para aplicación de cosméticos"
 },
 {
  "CNKEY": "960330100080",
  "CN": "9603 30 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pinceles y brochas para pintura artística, pinceles para escribir"
 },
 {
  "CNKEY": "960330900080",
  "CN": "9603 30 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pinceles para aplicación de cosméticos"
 },
 {
  "CNKEY": "960340000080",
  "CN": "9603 40",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pinceles y brochas para pintar, enlucir, barnizar o similares (excepto los de la subpartida 960330); almohadillas o muñequillas y rodillos, para pintar"
 },
 {
  "CNKEY": "960340100080",
  "CN": "9603 40 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Pinceles y brochas para pintar, enlucir, barnizar o similares"
 },
 {
  "CNKEY": "960340900080",
  "CN": "9603 40 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Almohadillas o muñequillas y rodillos, para pintar"
 },
 {
  "CNKEY": "960350000080",
  "CN": "9603 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás cepillos que constituyan partes de máquinas, aparatos o vehículos"
 },
 {
  "CNKEY": "960390000080",
  "CN": "9603 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960390100080",
  "CN": "9603 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "Escobas mecánicas de uso manual (excepto las de motor)"
 },
 {
  "CNKEY": "960390910010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960390910080",
  "CN": "9603 90 91",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Cepillos y cepillos escoba para limpieza de superficies y para la casa, incluidos los cepillos para prendas de vestir o para calzado; artículos de cepillería para el aseo de los animales"
 },
 {
  "CNKEY": "960390990080",
  "CN": "9603 90 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960400000080",
  "CN": "9604 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Tamices, cedazos y cribas, de mano"
 },
 {
  "CNKEY": "960500000080",
  "CN": "9605 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Juegos o surtidos de viaje para aseo personal, costura o limpieza del calzado o de prendas de vestir"
 },
 {
  "CNKEY": "960600000080",
  "CN": "9606",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Botones y botones de presión; formas para botones y demás partes de botones o de botones de presión; esbozos de botones"
 },
 {
  "CNKEY": "960610000080",
  "CN": "9606 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Botones de presión y sus partes"
 },
 {
  "CNKEY": "960621000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Botones"
 },
 {
  "CNKEY": "960621000080",
  "CN": "9606 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico, sin forrar con materia textil"
 },
 {
  "CNKEY": "960622000080",
  "CN": "9606 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De metal común, sin forrar con materia textil"
 },
 {
  "CNKEY": "960629000080",
  "CN": "9606 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960630000080",
  "CN": "9606 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Formas para botones y demás partes de botones; esbozos de botones"
 },
 {
  "CNKEY": "960700000080",
  "CN": "9607",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cierres de cremallera (cierres relámpago) y sus partes"
 },
 {
  "CNKEY": "960711000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cierres de cremallera (cierres relámpago)"
 },
 {
  "CNKEY": "960711000080",
  "CN": "9607 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Con dientes de metal común"
 },
 {
  "CNKEY": "960719000080",
  "CN": "9607 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "m",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960720000080",
  "CN": "9607 20",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Partes"
 },
 {
  "CNKEY": "960720100080",
  "CN": "9607 20 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De metal común, incluidas las cintas con grapas de metal común"
 },
 {
  "CNKEY": "960720900080",
  "CN": "9607 20 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "960800000080",
  "CN": "9608",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Bolígrafos; rotuladores y marcadores con punta de fieltro u otra punta porosa; estilográficas y demás plumas; estiletes o punzones para clisés de mimeógrafo («stencils»); portaminas; portaplumas, portalápices y artículos similares; partes de estos artículos (incluidos los capuchones y sujetadores), excepto las de la partida 9609"
 },
 {
  "CNKEY": "960810000080",
  "CN": "9608 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Bolígrafos"
 },
 {
  "CNKEY": "960810100080",
  "CN": "9608 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "p\/st",
  "ES": "De tinta líquida"
 },
 {
  "CNKEY": "960810920010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960810920080",
  "CN": "9608 10 92",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Con cartucho recambiable"
 },
 {
  "CNKEY": "960810990080",
  "CN": "9608 10 99",
  "CN_LEVEL": 6,
  "T_SU_SU": "p\/st",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960820000080",
  "CN": "9608 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Rotuladores y marcadores con punta de fieltro u otra punta porosa"
 },
 {
  "CNKEY": "960830000080",
  "CN": "9608 30 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Estilográficas y demás plumas"
 },
 {
  "CNKEY": "960840000080",
  "CN": "9608 40 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Portaminas"
 },
 {
  "CNKEY": "960850000080",
  "CN": "9608 50 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Juegos de artículos pertenecientes, por lo menos, a dos de las subpartidas anteriores"
 },
 {
  "CNKEY": "960860000080",
  "CN": "9608 60 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Cartuchos de repuesto con su punta para bolígrafo"
 },
 {
  "CNKEY": "960891000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960891000080",
  "CN": "9608 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Plumillas y puntos para plumillas"
 },
 {
  "CNKEY": "960899000080",
  "CN": "9608 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960900000080",
  "CN": "9609",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Lápices, minas, pasteles, carboncillos, tizas para escribir o dibujar y jaboncillos (tizas) de sastre"
 },
 {
  "CNKEY": "960910000080",
  "CN": "9609 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Lápices"
 },
 {
  "CNKEY": "960910100080",
  "CN": "9609 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Con mina de grafito"
 },
 {
  "CNKEY": "960910900080",
  "CN": "9609 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "960920000080",
  "CN": "9609 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Minas para lápices o portaminas"
 },
 {
  "CNKEY": "960990000080",
  "CN": "9609 90",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "960990100080",
  "CN": "9609 90 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pasteles y carboncillos"
 },
 {
  "CNKEY": "960990900080",
  "CN": "9609 90 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "961000000080",
  "CN": "9610 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Pizarras y tableros para escribir o dibujar, incluso enmarcados"
 },
 {
  "CNKEY": "961100000080",
  "CN": "9611 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Fechadores, sellos, numeradores, timbradores y artículos similares (incluidos los aparatos para imprimir etiquetas), de mano; componedores e imprentillas con componedor, de mano"
 },
 {
  "CNKEY": "961200000080",
  "CN": "9612",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Cintas para máquinas de escribir y cintas similares, entintadas o preparadas de otro modo para imprimir, incluso en carretes o cartuchos; tampones, incluso impregnados o con caja"
 },
 {
  "CNKEY": "961210000080",
  "CN": "9612 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Cintas"
 },
 {
  "CNKEY": "961210100080",
  "CN": "9612 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico"
 },
 {
  "CNKEY": "961210200080",
  "CN": "9612 10 20",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De fibras sintéticas o artificiales, con una anchura inferior a 30 mm, contenidas permanentemente en cartuchos de plástico o metal, de los tipos utilizados en las máquinas de escribir automáticas, equipos de tratamiento automático de la información y otras máquinas"
 },
 {
  "CNKEY": "961210800080",
  "CN": "9612 10 80",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "961220000080",
  "CN": "9612 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Tampones"
 },
 {
  "CNKEY": "961300000080",
  "CN": "9613",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Encendedores y mecheros, incluso mecánicos o eléctricos, y sus partes, excepto las piedras y mechas"
 },
 {
  "CNKEY": "961310000080",
  "CN": "9613 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Encendedores de gas no recargables, de bolsillo"
 },
 {
  "CNKEY": "961320000080",
  "CN": "9613 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "p\/st",
  "ES": "Encendedores de gas recargables, de bolsillo"
 },
 {
  "CNKEY": "961380000080",
  "CN": "9613 80 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás encendedores y mecheros"
 },
 {
  "CNKEY": "961390000080",
  "CN": "9613 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Partes"
 },
 {
  "CNKEY": "961400000080",
  "CN": "9614 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pipas (incluidas las cazoletas), boquillas para cigarros (puros) o cigarrillos, y sus partes"
 },
 {
  "CNKEY": "961400100080",
  "CN": "9614 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Escalabornes de madera o de raíces"
 },
 {
  "CNKEY": "961400900080",
  "CN": "9614 00 90",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "961500000080",
  "CN": "9615",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Peines, peinetas, pasadores y artículos similares; horquillas; rizadores, bigudíes y artículos similares para el peinado, excepto los de la partida 8516, y sus partes"
 },
 {
  "CNKEY": "961511000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Peines, peinetas, pasadores y artículos similares"
 },
 {
  "CNKEY": "961511000080",
  "CN": "9615 11 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De caucho endurecido o plástico"
 },
 {
  "CNKEY": "961519000080",
  "CN": "9615 19 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "961590000080",
  "CN": "9615 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "961600000080",
  "CN": "9616",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pulverizadores de tocador, sus monturas y cabezas de monturas; borlas y similares para aplicación de polvos, otros cosméticos o productos de tocador"
 },
 {
  "CNKEY": "961610000080",
  "CN": "9616 10",
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Pulverizadores de tocador, sus monturas y cabezas de monturas"
 },
 {
  "CNKEY": "961610100080",
  "CN": "9616 10 10",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pulverizadores de tocador"
 },
 {
  "CNKEY": "961610900080",
  "CN": "9616 10 90",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Monturas y cabezas de monturas"
 },
 {
  "CNKEY": "961620000080",
  "CN": "9616 20 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Borlas y similares para aplicación de polvos, otros cosméticos o productos de tocador"
 },
 {
  "CNKEY": "961700000080",
  "CN": "9617 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Termos y demás recipientes isotérmicos, montados y aislados por vacío, así como sus partes (excepto las ampollas de vidrio)"
 },
 {
  "CNKEY": "961800000080",
  "CN": "9618 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Maniquíes y artículos similares; autómatas y escenas animadas para escaparates"
 },
 {
  "CNKEY": "961900000080",
  "CN": "9619 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Compresas y tampones higiénicos, pañales y artículos similares, de cualquier materia"
 },
 {
  "CNKEY": "961900300080",
  "CN": "9619 00 30",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De guata de materias textiles"
 },
 {
  "CNKEY": "961900400010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De otras materias textiles"
 },
 {
  "CNKEY": "961900400080",
  "CN": "9619 00 40",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Compresas higiénicas, tampones higiénicos y artículos similares"
 },
 {
  "CNKEY": "961900500080",
  "CN": "9619 00 50",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pañales para bebés y artículos similares"
 },
 {
  "CNKEY": "961900710010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De otras materias"
 },
 {
  "CNKEY": "961900710020",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Compresas higiénicas, tampones higiénicos y artículos similares"
 },
 {
  "CNKEY": "961900710080",
  "CN": "9619 00 71",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Compresas higiénicas"
 },
 {
  "CNKEY": "961900750080",
  "CN": "9619 00 75",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Tampones higiénicos"
 },
 {
  "CNKEY": "961900790080",
  "CN": "9619 00 79",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "961900810010",
  "CN": null,
  "CN_LEVEL": 5,
  "T_SU_SU": null,
  "ES": "Pañales para bebés y artículos similares"
 },
 {
  "CNKEY": "961900810080",
  "CN": "9619 00 81",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Pañales para bebés"
 },
 {
  "CNKEY": "961900890080",
  "CN": "9619 00 89",
  "CN_LEVEL": 6,
  "T_SU_SU": "",
  "ES": "Los demás (por ejemplo: artículos para la incontinencia)"
 },
 {
  "CNKEY": "962000000080",
  "CN": "9620 00",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Monopies, bípodes, trípodes y artículos similares"
 },
 {
  "CNKEY": "962000100080",
  "CN": "9620 00 10",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De los tipos utilizados para las cámaras digitales, fotográficas o de vídeo, cámaras y proyectores cinematográficos, o de los tipos usados para otros aparatos del capítulo 90"
 },
 {
  "CNKEY": "962000910010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "962000910080",
  "CN": "9620 00 91",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De plástico o de aluminio"
 },
 {
  "CNKEY": "962000990080",
  "CN": "9620 00 99",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "970011000090",
  "CN": "XXI",
  "CN_LEVEL": 1,
  "T_SU_SU": null,
  "ES": "SECCIÓN XXI - OBJETOS DE ARTE O COLECCIÓN Y ANTIGÜEDADES"
 },
 {
  "CNKEY": "970021000090",
  "CN": "97",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 97 - OBJETOS DE ARTE O COLECCIÓN Y ANTIGÜEDADES"
 },
 {
  "CNKEY": "970100000080",
  "CN": "9701",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Pinturas y dibujos, hechos totalmente a mano, excepto los dibujos de la partida 4906 y artículos manufacturados decorados a mano; collages, mosaicos y cuadros similares"
 },
 {
  "CNKEY": "970121000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "De más de 100 años"
 },
 {
  "CNKEY": "970121000080",
  "CN": "9701 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pinturas y dibujos"
 },
 {
  "CNKEY": "970122000080",
  "CN": "9701 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mosaicos"
 },
 {
  "CNKEY": "970129000080",
  "CN": "9701 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "970191000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Los demás"
 },
 {
  "CNKEY": "970191000080",
  "CN": "9701 91 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Pinturas y dibujos"
 },
 {
  "CNKEY": "970192000080",
  "CN": "9701 92 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Mosaicos"
 },
 {
  "CNKEY": "970199000080",
  "CN": "9701 99 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "970200000080",
  "CN": "9702",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Grabados, estampas y litografías, originales"
 },
 {
  "CNKEY": "970210000080",
  "CN": "9702 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De más de 100 años"
 },
 {
  "CNKEY": "970290000080",
  "CN": "9702 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Los demás"
 },
 {
  "CNKEY": "970300000080",
  "CN": "9703",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Obras originales de estatuaria o escultura, de cualquier materia"
 },
 {
  "CNKEY": "970310000080",
  "CN": "9703 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De más de 100 años"
 },
 {
  "CNKEY": "970390000080",
  "CN": "9703 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "970400000080",
  "CN": "9704 00 00",
  "CN_LEVEL": 3,
  "T_SU_SU": "",
  "ES": "Sellos (estampillas) de correo, timbres fiscales, marcas postales, sobres primer día, enteros postales, demás artículos franqueados y análogos, incluso obliterados, excepto los artículos de la partida 4907"
 },
 {
  "CNKEY": "970500000080",
  "CN": "9705",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Colecciones y piezas de colección que tengan un interés arqueológico, etnográfico, histórico, zoológico, botánico, mineralógico, anatómico, paleontológico o numismático"
 },
 {
  "CNKEY": "970510000080",
  "CN": "9705 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Colecciones y piezas de colección que tengan un interés arqueológico, etnográfico o histórico"
 },
 {
  "CNKEY": "970521000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Colecciones y piezas de colección que tengan un interés zoológico, botánico, mineralógico, anatómico o paleontológico"
 },
 {
  "CNKEY": "970521000080",
  "CN": "9705 21 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Especímenes humanos y sus partes"
 },
 {
  "CNKEY": "970522000080",
  "CN": "9705 22 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Especies extintas o en peligro de extinción, y sus partes"
 },
 {
  "CNKEY": "970529000080",
  "CN": "9705 29 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "970531000010",
  "CN": null,
  "CN_LEVEL": 4,
  "T_SU_SU": null,
  "ES": "Colecciones y piezas de colección que tengan un interés numismático"
 },
 {
  "CNKEY": "970531000080",
  "CN": "9705 31 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "De más de 100 años"
 },
 {
  "CNKEY": "970539000080",
  "CN": "9705 39 00",
  "CN_LEVEL": 5,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "970600000080",
  "CN": "9706",
  "CN_LEVEL": 3,
  "T_SU_SU": null,
  "ES": "Antigüedades de más de cien años"
 },
 {
  "CNKEY": "970610000080",
  "CN": "9706 10 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "De más de 250 años"
 },
 {
  "CNKEY": "970690000080",
  "CN": "9706 90 00",
  "CN_LEVEL": 4,
  "T_SU_SU": "",
  "ES": "Las demás"
 },
 {
  "CNKEY": "980021000090",
  "CN": "98",
  "CN_LEVEL": 2,
  "T_SU_SU": null,
  "ES": "CAPÍTULO 98 - CONJUNTOS INDUSTRIALES"
 }]*/