import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {checkSession} from "../../network/ApiAxios";

const AuthRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={(props) => (
            !(localStorage.getItem("token") && localStorage.getItem("user"))
                ? <Component {...props} />
                : (props.location.search.indexOf("code") == -1) ? <Redirect to='/admin/index'/> : <Component {...props} />
        )}/>
    )
}

export default AuthRoute;