import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import ModalProduccionAgroquimico from "./ModalExtend/ModalProduccionAgroquimicoView";
import { row_fertilizer_production } from "network/ApiAxios";

export default class ProduccionAgroquimicoView extends React.Component {
    state={
    dataFertilizer:[],
  }
async componentDidMount(){
      if(this.props.paramsCode==undefined || this.props.paramsCode==null){
          var codigoTraza = 'nocode';
        }else{
          var codigoTraza = this.props.paramsCode;
        }
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var dataFertilizer = await row_fertilizer_production(jsondata._id,codigoTraza,codigoTrazaTwo,'production_fertilizer')
        this.setState({dataFertilizer:dataFertilizer.trazaData})
}
  render(){

  return (
    <>
        <ModalProduccionAgroquimico sendInfoProps={this.state} />
    </>
  );
 }
}

//export default Elements;
