import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_finalized, data_list_processes } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import { get_permission } from "network/ApiAxios";
import jQuery from 'jquery';
import {UserContext} from "layouts/store";

export default class FinalizedTrace extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    data_traza_meat:[],
    company_id:null,
    setinfoState:false,
    trazac:'show',
    trazafv:'show',
    trazanamag:'show'
  }
 async componentDidMount(){
   this.context.getAffiliates()
  // var userdata = localStorage.getItem('user');
  // var jsondata = JSON.parse(userdata);

  // var gerParam = new URLSearchParams(this.props.location.search).get("company");
  //   var idcompany = null;
  //   if (jsondata.userType==2) {
  //     if (gerParam!=null) {
  //       idcompany = gerParam
  //     }
  //   }
  //   var dataPermission = await get_permission(jsondata._id,idcompany)

  }
 setInfoInit = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area===-1) {
          this.setState({get_permission:false})
          return;
        }
  if (dataPermission.type_traza==null) {
    this.setState({trazac:'none'})
    this.setState({trazafv:'none'})
    this.setState({trazanamag:'none'})
    // jQuery('#trazac').remove()
    // jQuery('#trazafv').remove()
    // jQuery('#trazanamag').remove()
    //return
  }else{
    if (dataPermission.type_traza.indexOf("VF")===-1) {
      this.setState({trazac:'none'})
      //jQuery('#trazac').remove()
    }
    if (dataPermission.type_traza.indexOf("M")===-1) {
      this.setState({trazafv:'none'})
      //jQuery('#trazafv').remove()
    }
    if (dataPermission.type_traza.indexOf("G")===-1) {
      this.setState({trazanamag:'none'})
      //jQuery('#trazanamag').remove()
    }
  }
  jQuery("#contaivfm").hide()
  if (dataPermission.role==2) {
    jQuery("#contaivfm").show()
    if (dataPermission.type_traza.indexOf('VF')===-1) {
      this.setState({trazac:'none'})
      //jQuery("#trazac").remove()
    }
    if (dataPermission.type_traza.indexOf("G")===-1) {
      this.setState({trazanamag:'none'})
      //jQuery('#trazanamag').remove()
    }
  }else{
    jQuery("#contaivfm").show()
  }
  var exten = this
// data_list_finalized(jsondata._id,dataPermission.user_id_connection,dataPermission.type).then((result)=>{
//     exten.setState({
//       company_id:gerParam,
//       data_traza:result.trazabilidad,
//       data_traza_meat:result.trazabilidad_meat,
//     })
//   })
  var data = await data_list_finalized(jsondata._id,dataPermission.user_id_connection,dataPermission.type,'th',jsondata.userType)
  //var data = await data_list_processes(jsondata._id,dataPermission.user_id_connection,dataPermission.type,'fruit_vegetable*th')

  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
    data_traza:data.trazabilidad[1],
    data_traza_th:data.trazabilidad[0],
    data_traza_g:data.traza_grain[1],
    data_traza_g_th:data.traza_grain[0],
    trazabilidad_meat:data.trazabilidad_meat,
  })
      }
  setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data.length!=0) {
    var content=[]
      data.map((a,b)=>{
         var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/vista-trace-admin-grain-nama"
              }
              if (val[1]?.split('-')[0]=='FCTGT') {
                url_selected = "/admin/vista_trace_admin_grain_traza"
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/vista-trace-admin"
              }
            }
 var content_two = <>
  {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td><ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:url_selected,
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver en vista trace
                     </ComponentLink><br />
                    <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-qr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}>
                     Ver Código Qr
                     </ComponentLink>
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <tr>{content_one}</tr>
      })
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      // Object.keys(this.state.data_traza[0]).map((val,ind)=>{
      //   Object.values(data).map((a,b)=>{
      //     if (val==a.split('*')[1]) {
      //       console.log(a.split('*')[0])
      //     }
      //   })
      // })
      Object.values(data).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }
render(){
  this.setInfoInit()
  return (
    <>
      <SimpleHeader name="Historial" parentName="Historial" section="fuits_vegetable" section_two="Tabla" section_table_form="table_history" />
        <Container className="mt--6" fluid id="contaivfm">

        <Card id="trazafv" style={{display: this.state.trazafv}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Trazabilidad Frutas y vegetales</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                {this.setContentTh(this.state.data_traza_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {this.setContent(this.state.data_traza)}
{/*            {this.state.data_traza.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.producto}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.codigo_arancelario}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.codigo}
                    </span>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-trace-admin",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}>
                     Ver Esta Trazabilidad Detallada
                     </ComponentLink><br />
                    <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-qr",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}>
                     Ver Código Qr
                     </ComponentLink>
                   </td>
                 </tr>)
            })
          }*/}

            </tbody>
          </Table>
        </Card>
        
        <Card id="trazac" style={{display: this.state.trazac}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Trazabilidad Carnicos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Especie</th>
                <th>Creado</th>
                <th>codigo Trazabilidad</th>
                <th>Nombre de la trazabilidad</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {this.state.data_traza_meat.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     {value.id}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.especie}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.codigo}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {value.nombre_traza}
                    </span>
                   </td>
                   <td>
                     <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-trace-meat-admin",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}>
                     Ver Esta Trazabilidad Detallada
                     </ComponentLink><br />
                    <ComponentLink
                      className="font-weight-bold"
                      to={{
                        pathname:"/admin/vista-qr",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}>
                     Ver Código Qr
                     </ComponentLink>
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>
        <Card id="tg" style={{display:(this.state.data_traza_g==undefined)?('none'):((this.state.data_traza_g[0]==undefined)?('none'):('block'))}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Grano: Trazabilidad arroz</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_traza_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_traza_g)}
            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

//export default Elements;
