import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

import {register_colab,data_list_addcoll, get_permission} from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import jQuery from 'jquery';
import { NavLink as ComponentLink } from "react-router-dom";
//import {UserContext} from "layouts/store";
//function Elements() {
class SellUser extends React.Component {
state = {
  autorization:'0',
  trazabilidad:'0',
  guide_m:'0',
  guide_establishment_m:'',
  guide_incription_m:'',
  entidad:'',
  nombre:'',
  email:'',
  password:'',
  mess:'',
  notificationAlertRef: React.createRef(),
  btndisab:false,
  check_nama_data:[],
  data_traza:[],
  data_traza_m:[],
  data_production_user:[],
  data_traza_g:[],
  setAreawork:"",
  select_mrv:0
}
async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    const data = await data_list_addcoll(jsondata._id)
    //this.setState({data_traza:data.trazabilidad,data_traza_m:data.trazabilidad_meat,data_production_user:data.data_production,data_traza_g:data.traza_grain})
}
render(){
  const saveColab = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var globlalthis = this
            this.setState({btndisab: true})
    if ( this.state.setAreawork=='' || this.state.entidad=='' || this.state.nombre=='' || this.state.email=='') {
            this.setState({mess: 'Todos los campos son requeridos'})
            this.setState({btndisab: false})
    }else{
    if (this.state.setAreawork.indexOf('T')!=-1 ) {
      if (this.state.autorization=='0' || (this.state.trazabilidad=='0' || this.state.trazabilidad=='')) {
        this.setState({mess: 'Seleccione una Trazabilidad y una Autorización.'})
        this.setState({btndisab: false})
        return;
      }
    }
        var getValue = [];
      if (this.state.data_traza.length!=0) {
        var documentTag = document.querySelectorAll(".lote_check_send:checked");
        if (this.state.autorization=='view' && (this.state.trazabilidad.indexOf('VF')!=-1)) {
          if (documentTag.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en frutas y vegetales')
          return
        }else{
          for (var i = 0; i < documentTag.length; i++) {
              getValue[i] = documentTag[i].value
            }
          }
        }
      }
        var getValueM = [];
      if (this.state.data_traza_m.length!=0) {
        var documentTagM = document.querySelectorAll(".lote_check_m_send:checked");
        if (this.state.autorization=='view' && (this.state.trazabilidad.indexOf('M')!=-1) ) {
          if (documentTagM.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en carnicos')
          return
        }else{
          for (var i = 0; i < documentTagM.length; i++) {
              getValueM[i] = documentTagM[i].value
            }
          }
        }
      }

        var getValueG = [];
      if (this.state.data_traza_g.length!=0) {
        var documentTagGrain = document.querySelectorAll(".check_traza_g_send:checked");
        if (this.state.autorization=='view' && (this.state.trazabilidad.indexOf('G')!=-1)) {
          if (documentTagGrain.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione una trazabilidad en granos')
          return
        }else{
          for (var i = 0; i < documentTagGrain.length; i++) {
              getValueG[i] = documentTagGrain[i].value
            }
          }
        }
      }

        var getValueP = [];
      if (this.state.data_production_user.length!=0) {
        var documentTagP = document.querySelectorAll(".check_prod_send:checked");
        if (this.state.autorization=='view' && (this.state.setAreawork.indexOf('P')!=-1)) {
          if (documentTagP.length==0) {
          globlalthis.setState({btndisab: false})
          alert('Seleccione un registro para producción')
          return
        }else{
          for (var i = 0; i < documentTagP.length; i++) {
              getValueP[i] = documentTagP[i].value
            }
          }
        }
      }
        this.setState({btndisab: false})

         const response = await register_colab(this.state.nombre, this.state.email, this.state.entidad, this.state.password, this.state.autorization, this.state.setAreawork+'|'+this.state.trazabilidad, this.state.guide_m, this.state.guide_establishment_m, this.state.guide_incription_m,this.state.select_mrv, 2, jsondata._id, JSON.stringify(this.state.check_nama_data), getValue.filter(Boolean), getValueM.filter(Boolean), getValueG.filter(Boolean), getValueP.filter(Boolean))
         .catch(function (message){
          if (message.response.status==500) {
           globlalthis.setState({mess: 'Ha ocurrido un error, intente mas tarde.'})
          }
          if (message.response.status==401) {
           globlalthis.setState({mess: message.response.data.errors.email[0]})
          }
          if (message.response.status==422) {
            globlalthis.setState({mess: 'No puedes registrar este correo.' })
          }
          if (message.response.status==403) {
            globlalthis.setState({mess: message.response.data.error })
          }
           globlalthis.setState({btndisab: false})
         })
           if (response==undefined) {
           return;
         }else{
           const {data} = response;
           if (!data.success) {
           globlalthis.setState({btndisab: false})
           this.setState({mess: data.message})
               return;
           }
         }
         notify("success", "¡Registrado exitosamente!", "Ahora inicia sesión con correo y contraseña.");
         this.props.history.push('/admin/users')
    }
    //setTimeout(function(){history.push('/home/login')}, 3000);

  }
  const logistSwitch = () => {
        if (this.state.setAreawork.indexOf("P")!=-1) {
          var contentViewsProd = []
        if (this.state.autorization=="view") {
          if (this.state.data_production_user.length!=0) {
            contentViewsProd[0]= sectionTableP(this.state.data_production_user)
          }
        }
        if (this.state.autorization=="options") {
             contentViewsProd[1]= contentG()
        }
          return contentViewsProd
        }
      if (this.state.setAreawork.indexOf("T")!=-1) {
        var contentViews = []
        if (this.state.autorization=="view") {
          if (this.state.trazabilidad.indexOf("VF")!=-1) {
            if (this.state.data_traza.length!=0) {
              contentViews[0]= sectionTableVF(this.state.data_traza)
            }
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            if (this.state.data_traza_m.length!=0) {
              contentViews[1]= sectionTableM(this.state.data_traza_m)
            }
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            if (this.state.data_traza_g.length!=0) {
              contentViews[1]= sectionTableG(this.state.data_traza_g)
            }
          }
          
          // if (this.state.trazabilidad.indexOf("G")!=-1) {
          //   if (this.state.data_production_user.length!=0) {
          //     contentViews[2]= sectionTableP(this.state.data_production_user)
          //   }
          // }
        return contentViews
        }

        var contentOptions = []
        if (this.state.autorization=="options") {
          if (this.state.trazabilidad.indexOf("VF")!=-1) {
            contentOptions[0]= contentVF()
          }
          if (this.state.trazabilidad.indexOf("M")!=-1) {
            contentOptions[1]= contentM()
          }
          if (this.state.trazabilidad.indexOf("G")!=-1) {
            contentOptions[1]= contentTG()
          }
          // if (this.state.trazabilidad.indexOf("G")!=-1) {
          //   contentOptions[2]= contentG()
          // }
        return contentOptions
        }
      }

  }
  const logistGuides = () => {
      var component=null;
      if (this.state.trazabilidad=="0") {
        return;
      }
      if (this.state.trazabilidad.indexOf("M")==-1) {
        return;
      }
      if ((this.state.trazabilidad.indexOf("M")!=-1) && (this.state.autorization=="view" || this.state.autorization=="0")) {
        return;
      }
    component =<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Asignar Guias de propiedad y transito
                  </label>
                  <br />
                <input
                  onChange={(e)=>{this.setState({guide_m:e.target.value})}}
                  id="guide_pt" name="guide_pt" type="checkbox" value={(this.state.guide_m=="0")?("1"):("0")}/>
                </FormGroup>
              </Col></>
              return component
  }
  const sectionTableVF = (data) => {
    var componenteData = []
    componenteData = <>
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista frutas y vegetales
                        </span>
              </Col>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Foodchain ID</th>
                        <th>producto</th>
                        <th>Creado</th>
                        <th>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.producto}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td>
                                                      <Col md="1">
                                                        <Input style={{width: '100%',margin: 0}} className="lote_check_send" name="lote_check_send" type="checkbox" value={value.codigo}/>
                                                      </Col>
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </Table></>
          return componenteData
  }
  const sectionTableM = (data) => {
    var componenteData = []
    componenteData = <>
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista carnicos
                        </span>
              </Col>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Foodchain ID</th>
                        <th>Nombre</th>
                        <th>Creado</th>
                        <th>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.nombre_traza}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td>
                                                      <Col md="1">
                                                        <Input style={{width: '100%',margin: 0}} className="lote_check_m_send" name="lote_check_m_send" type="checkbox" value={value.codigo}/>
                                                      </Col>
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </Table></>
          return componenteData
  }
  const sectionTableP = (data) => {
    var componenteData = []
    componenteData = <>
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista producción
                        </span>
              </Col>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Foodchain ID</th>
                        <th>producto</th>
                        <th>Creado</th>
                        <th>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.producto}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td>
                                                      <Col md="1">
                                                        <Input style={{width: '100%',margin: 0}} className="check_prod_send" name="check_prod_send" type="checkbox" value={value.codigo}/>
                                                      </Col>
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </Table></>
          return componenteData
  }
  const sectionTableG = (data) => {
    var componenteData = []
    componenteData = <>
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Lista granos arroz
                        </span>
              </Col>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Foodchain ID</th>
                        <th>Creado</th>
                        <th>Selección</th>
                      </tr>
                    </thead>
                    <tbody>
    {                    data.map((value,index)=>{
                         return(<tr key={index}>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.codigo}
                                                     </span>
                                                    </td>
                                                    <td>
                                                     <span className="text-muted">
                                                      {value.created_at}
                                                     </span>
                                                    </td>
                                                    <td>
                                                      <Col md="1">
                                                        <Input style={{width: '100%',margin: 0}} className="check_traza_g_send" name="check_traza_g_send" type="checkbox" value={value.codigo}/>
                                                      </Col>
                                                    </td>
                                                  </tr>)
                        })
    }
                    </tbody>
                  </Table></>
          return componenteData
  }

  const contentVF = () => {
const data = [
{title:'Trazabilidad',attr:'trazabilidad',stateVal:'trazabilidad'},
{title:'Producción',attr:'produccion',stateVal:'produccion'},
{title:'Cosecha',attr:'cosecha',stateVal:'cosecha'},
{title:'Planta',attr:'planta',stateVal:'planta'},
{title:'Logistíca y Transporte',attr:'logistica',stateVal:'logistica'},
{title:'Comercialización',attr:'comercializa',stateVal:'logistica'},
{title:'Documentos',attr:'documento',stateVal:'documento'},
]
return(<Row>
                                      <Col md="12">
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Vegetales Y frutas
                                                </span>
                                      </Col>
                                      {getDataState(data)}
                                    </Row>)
  }
const contentM = () => {
const data = [

{title:'Trazabilidad',attr:'trazabilidad_m',stateVal:'trazabilidad_m'},
{title:'Lote',attr:'lote_m',stateVal:'lote_m'},
{title:'Logistíca',attr:'logist_m',stateVal:'logistica_m'},
{title:'Compartimento',attr:'compart_m',stateVal:'compartimento_m'},
{title:'Frigorífico',attr:'fridge_m',stateVal:'frigorifico_m'},
{title:'Documentos',attr:'documento_m',stateVal:'documentos_m'},
]
return(<Row className="pt-2">
                                      <Col md="12">
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Carnicos
                                                </span>
                                      </Col>
                                      {getDataState(data)}
                                    </Row>)
  }

const contentTG = () => {
const data = [
{title:'Información General',attr:'information_grain_t',stateVal:'information_grain_t'},
{title:'Producción',attr:'production_grain_t',stateVal:'production_grain_t'},
{title:'Cosecha',attr:'harvest_grain_t',stateVal:'harvest_grain_t'},
{title:'Documento',attr:'document_grain_t',stateVal:'document_grain_t'},
]
return(<Row className="pt-2">
                                      <Col md="12">
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Opciones de Arroz
                                                </span>
                                      </Col>
                                      {getDataState(data)}
                                    </Row>)
  }
const contentG = () => {

                            return(<Row className="pt-2">
                                      <Col md="12">
                                                <span
                                                  className="form-control-label"
                                                  htmlFor="exampleFormControlSelect1">
                                                  Selección de Producción
                                                </span>
                                      </Col>
                                      {setContentP()}
{/*                                      <Col md="12">
                                        <Row>
                                          <Col md="6">
                                            <Button style={{boxShadow: 'none', backgroundColor: 'white', border: 'none'}} onClick={()=>{this.setState({setTypeOption:'trazabilidad'})}}>Trazabilidad de arroz</Button>
                                          </Col>
                                          <Col md="6">
                                            <Button style={{boxShadow: 'none', backgroundColor: 'white', border: 'none'}} onClick={()=>{this.setState({setTypeOption:'nama'})}}>NAMA de arroz</Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                      {contentPSelect()}*/}
                                   </Row>)
  }
  const contentPSelect = (setTypeOption) => {
    if (this.state.setTypeOption=='nama') {
      return setContentP()
    }
    if (this.state.setTypeOption=='trazabilidad') {
      return null
    }

  }
  const setContentP = () => {
const data = [
{title:'Información General',attr:'information_nama_g',stateVal:'information_nama_g'},
{title:'Producción',attr:'prodction_nama_g',stateVal:'production_nama_g'},
{title:'Manejo Fitosanitario',attr:'hading_nama_g',stateVal:'hading_nama_g'},
{title:'Cosecha',attr:'harvest_nama_g',stateVal:'harvest_nama_g'},
{title:'Documento',attr:'documento_n_g',stateVal:'document_nama_g'},
]
return(
        <Col md="12">
          <Row className="pt-2">
              <Col md="12">
                        <span
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1">
                          Opciones de Produccion
                        </span>
              </Col>
                {getDataState(data)}
            </Row>
          </Col>
        )
  }
  const getDataState = (data) => {
    var content = []
      data.map((val,ind)=>{
      content[ind] = <>
            <Col key={ind} md="6" sm="6" className="pt-2">
              <Row>
                <div className="col-md-6 col-6">
                      <span
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        {val.title}
                      </span>
                </div>
                <div className="col-md-2 col-2">
                    <Input
                    onChange={(e)=>{this.setState({check_nama_data: {...this.state.check_nama_data,[val.stateVal]:(e.target.checked)?('1'):('0')}})}}
                     id={val.attr} name={val.attr} type="checkbox" value={this.state.check_nama_data[val.stateVal]}/>
                </div>
              </Row>
            </Col>
            </>
          })
    return content
  }
      const contentTraza = () => {
        var get_data = this.props.dataUser.type_traza.split('|')[1]
        if (get_data!=undefined) {
        return(<>
          {(get_data=='VF')?(<>
                    <option value="VF">Vegetales y frutas</option>
            </>):(null)}
          {(get_data=='M')?(<>
                    <option value="M">Carnicos</option>
            </>):(null)}
          {(get_data=='G')?(<>
                    <option value="G">Granos</option>
            </>):(null)}
          {(get_data=='VF-M')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="M">Carnicos</option>
                    <option value="VF-M">Vegetales y frutas - Carnicos</option>
            </>):(null)}
          {(get_data=='VF-G')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="G">Granos</option>
                    <option value="VF-G">Vegetales y frutas - Granos</option>
            </>):(null)}
          {(get_data=='M-G')?(<>
                    <option value="M">Carnicos</option>
                    <option value="G">Granos</option>
                    <option value="M-G">Carnicos - Granos</option>
            </>):(null)}
          {(get_data=='VF-M-G')?(<>
                    <option value="VF">Vegetales y frutas</option>
                    <option value="M">Carnicos</option>
                    <option value="G">Granos</option>
                    <option value="VF-M">Vegetales y frutas - Carnicos</option>
                    <option value="M-G">Carnicos - Granos</option>
                    <option value="VF-G">Vegetales y frutas - Granos</option>
                    <option value="VF-M-G">Vegetales y frutas - Carnicos - Granos</option>
            </>):(null)}
               </>)
        }
    }
      const contentAreaWork = () => {
        var get_data = this.props.dataUser.type_traza.split('|')[0]
        if (get_data!=undefined) {
        return(<>
          {(get_data.indexOf('P')!=-1)?(<>
                  <span>Producción: </span><input type="radio" name="work_area" value="P" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('T')!=-1)?(<>
                  <span>Trazabilidad: </span><input type="radio" name="work_area" value="T" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
          {(get_data.indexOf('C')!=-1)?(<>
                  <span>Comercialización: </span><input type="radio" name="work_area" value="C" onChange={(e)=>{changeAreaWork(e.target)}}/>{' '}
            </>):(null)}
               </>)
        }
    }
    const changeAreaWork = (val) => {
      // console.log(val)
      // var data = ''
      // if (val.checked) {
      //   data = this.state.setAreawork+val.value
      //   //this.state.setAreawork
      // }else{
      //   data = this.state.setAreawork.replace(val.value,'')
      // }
      this.setState({setAreawork:val.value})
    }
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <Container className="mt--1" fluid>

            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nombre y apellido
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({nombre:e.target.value})}}
                    placeholder="Escribe un nombre"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Entidad/Empresa
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({entidad:e.target.value})}}
                    placeholder="Escribe un entidad"
                    type="text"
                    autoComplete="none"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Área de Trabajo
                  </label>
                  <br/>
                    {contentAreaWork()}
                </FormGroup>
              </Col>
              {(this.state.setAreawork.indexOf('T')!=-1)?(
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Trazabilidad
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({trazabilidad:e.target.value})}}
                   id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    {/*<option value="VFM">Todos</option>*/}
                    {contentTraza()}
                  </Input>
                </FormGroup>
              </Col>
                ):(null)}
              {(this.state.setAreawork.indexOf('T')!=-1 || this.state.setAreawork.indexOf('P')!=-1)?(
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Autorización
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({autorization:e.target.value})}}
                    id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      <option value="admin">Agregar administrador</option>
                      <option value="view">Agregar Colaborador para que vea el proceso solamente</option>
                      <option value="options">Agregar Colaborador para editar solo secciones</option>
{/*                      {(this.state.trazabilidad=="VF")?(<>
                        <option value="prod_invt">Invitar usuario </option>
                        </>):(null)}*/}

                    </Input>
                  </FormGroup>
                </Col>
                ):(null)}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Correo electrónico
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({email:e.target.value})}}
                    placeholder="Escribe un correo"
                    type="email"
                  />
                </FormGroup>
              </Col>
              {logistGuides()}
{(this.state.guide_m=='1')?(<><Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Establecimiento
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({guide_establishment_m:e.target.value})}}
                  id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="P">Propietario</option>
                    <option value="C">Consignatario</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Número de inscripción del establecimiento o empresa
                  </label>
                  <Input
                  onChange={(e)=>{this.setState({guide_incription_m:e.target.value})}}
                    type="numer"
                  />
                </FormGroup>
              </Col></>):(null)}
            </Row>
            {logistSwitch()}
            <hr /><br />
                   {this.state.mess ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.mess}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button onClick={()=>{saveColab()}} disabled={this.state.btndisab} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Invitar</span>
                </Button>
              </Col>
          </Row>
      </Container>
    </>
  );
 }
}
class ModalInviteUser extends React.Component {
    //static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false,
    dataUser:[]
  }
 async componentDidMount(){
    this.props.contexto.getAffiliates()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    //var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.props.contexto.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.props.contexto.valueGlobal
    this.setState({dataUser:dataPermission})
    if (dataPermission.role==1) {
      this.setState({get_permission:true})
    }else{
      this.setState({get_permission:false})
    }
      }  
render(){
  this.setInfoInit()  
  return (
    <>
  <Modal isOpen={this.props.sendOpenModal} size="xl">
           <CardHeader>
            <Row>
                <Col md={11}>
                    <h3 className="mb-0">Invitar Usuario</h3>
                </Col>
                <Col md={1}>
                    <a href="#" onClick={()=> {this.props.setInfoInvoice(null,false)} }>
                    <h3 class="mb-0">X</h3>
                    </a>
                </Col>
            </Row>
          </CardHeader>
        <ModalBody>
            <CardBody>          
                    {(this.state.get_permission=='load')?(
                    <h1 className="text-center">Cargando...</h1>
                    ):(
                    (this.state.get_permission)?(
                        <SellUser {...this.props} dataUser={this.state.dataUser} />
                        ):(
                        <ViewPermision />
                        )
                    )}
                </CardBody>
            </ModalBody>
        <ModalFooter>
        <Button onClick={()=> {this.props.setInfoInvoice(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
 }
}

export default ModalInviteUser;
