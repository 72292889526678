import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { logistic_create_trasport, logistic_create_export, data_logstic_transport,data_logstic_export, deleteTransExport, deleteTransFrom } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import "../StyleCss.css";
 class ModalListconstancyTwo extends React.Component {
  state={
  }
    async componentDidMount(){
        
  }
  
  render(){

       const setInfoTransFrom = async (id_set,verify) =>{
    }

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  
  const sectionListRow =()=> {
    var content_data =[]
    for (var i = 0; i < getDataSession('resultSum'); i++) {
                  content_data[i] = <Col md={4}>
                  <div className="form-control-label" style={{display:'flex'}}>
                    <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '30px',fontWeight: '500',marginRight: '0px'}}>
                      {i+1})
                    </div>
                    <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: 'auto',fontWeight: '500',marginRight: '0px'}}>
                      0000000000000000
                    </div>
                  </div>                                   
                </Col>
    }
    return content_data
  }
  const dateVigent =()=> {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear()
  }
    const getDataSessionOne = ()=> {
      var getDataValid = JSON.parse(localStorage.getItem('dataItem'))
      return (getDataValid==null)?(null):(getDataValid.numberguia)
    }

  const getDataSession = (value)=> {
    var getDataValid = JSON.parse(localStorage.getItem('dataItemTwo'))
    switch (value) {
      case 'fecha_emision_guia':
            return (getDataValid==null)?(null):(getDataValid.fecha_emision_guia)
        break;
      case 'numberguia':
            return (getDataValid==null)?(null):(getDataValid.numberguia)
        break;
      case 'codigo_autorizacion':
            return (getDataValid==null)?(null):(getDataValid.codigo_autorizacion)
        break;
      case 'establecimiento_a':
            return (getDataValid==null)?(null):(getDataValid.establecimiento_a)
        break;
      case 'razon_social_a':
            return (getDataValid==null)?(null):(getDataValid.razon_social_a)
        break;
      case 'establecimiento_b':
            return (getDataValid==null)?(null):(getDataValid.establecimiento_b)
        break; 
      case 'razon_social_b':
            return (getDataValid==null)?(null):(getDataValid.razon_social_b)
        break; 
      case 'establecimiento_c':
            return (getDataValid==null)?(null):(getDataValid.establecimiento_c)
        break; 
      case 'rezon_social_c':
            return (getDataValid==null)?(null):(getDataValid.rezon_social_c)
        break;
      case 'establecimiento_d':
            return (getDataValid==null)?(null):(getDataValid.establecimiento_d)
        break;
      case 'rezon_social_d':
            return (getDataValid==null)?(null):(getDataValid.rezon_social_d)
        break;
      case 'propiedad':
            return (getDataValid==null)?(null):((getDataValid.propiedad=='Sin Cambio de Propiedad 2')?('NO'):((getDataValid.propiedad=='Con Cambio de Propiedad 1')?('SI'):(null)))
        break;
      case 'resultSum':
            return (getDataValid==null)?(null):(getDataValid.resultSum)
        break;        
      default:
      return null
        break;
    }

  }

  return (
    <>
    <Modal isOpen={this.props.sendOpenModalFromListTwo} size="xl">
    <ModalBody>
            <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Guía 4</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {this.props.setInfoTransFromList(1,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          <CardBody>

            <Col md={12}>
                  <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '500',marginRight: '0px',textDecoration: 'underline'}}>
                    Sistema Nacional de información Ganadera
                  </div>
                  <div className="form-control-label" style={{color: '#000000',fontSize: '13px',width: '300px',fontWeight: '700',marginRight: '0px'}}>
                    CONSTANCIA DE PROPIEDAD Y TRANSITO DE ANIMALES REGISTRADOS
                  </div>   
            </Col>

            <hr style={{border: 'solid 1px black',marginBottom: '20px'}} />                         
            <Col md={12}>
                  <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '400px',fontWeight: '500',marginRight: '0px'}}>
                    FECHA: <b>{getDataSession('fecha_emision_guia')}</b><br />
                    CORRESPONDIENTE A LA GUÍA DE PROPIEDAD Y TRANSITO: <b>Z{getDataSession('numberguia')}</b><br />
                    NÚMERO DE AUTORIZACIÓN: <b>{getDataSession('codigo_autorizacion')}</b><br />
                  </div><br />
                  <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '500',marginRight: '0px'}}>
                    <Row>
                      <Col md={7}>
                        DIOCOSE A: <b>{getDataSession('establecimiento_a')}</b>
                      </Col>
                      <Col md={5}>
                      <label style={{width:'200px',margin: '0'}}>                      
                        {getDataSession('razon_social_a')}
                      </label>
                      </Col>                                        
                    </Row>

                    <Row>
                      <Col md={7}>
                        DIOCOSE B: <b>{getDataSession('establecimiento_b')}</b>
                      </Col>
                      <Col md={5}>
                      <label style={{width:'200px',margin: '0'}}>
                        {getDataSession('razon_social_b')}
                      </label>
                      </Col>                                        
                    </Row>

                    <Row>
                      <Col md={7}>
                        DIOCOSE C: <b>{getDataSession('establecimiento_c')}</b>
                      </Col>
                      <Col md={5}>
                      <label style={{width:'200px',margin: '0'}}>
                        {getDataSession('rezon_social_c')}
                      </label>                      
                      </Col>                                        
                    </Row>

                    <Row>
                      <Col md={7}>
                        DIOCOSE D: <b>{getDataSession('establecimiento_d')}</b>
                      </Col>
                      <Col md={5}>
                      <label style={{width:'200px',margin: '0'}}>
                        {getDataSession('rezon_social_d')}
                      </label>                      
                      </Col>                                        
                    </Row>
                  </div>
                  <br />
                  <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '500',marginRight: '0px'}}>
                  CAMBIO DE PROPIEDAD: {getDataSession('propiedad')}
                  </div> 
            </Col>

            <Col md={12}>
                  <hr style={{border: 'solid 1px black',marginBottom: '10px',marginTop: '20px'}} />
                  <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '700',marginRight: '0px'}}>
                    NÚMERO DE CARAVANAS:
                  </div>
                  <hr style={{border: 'solid 1px black',marginBottom: '20px',marginTop: '10px'}} />
            </Col>

            <Col md={12}>
              <Row>
                {sectionListRow()}
              </Row>
            </Col>
            <br/>
            <Col md={12}>
              <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '700',marginRight: '0px'}}>
                      TITULAR QUE EMITE LA CONSTANCIA
              </div>
              <hr style={{border: 'solid 1px black',marginBottom: '10px',marginTop: '10px'}} />
              <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '500',marginRight: '0px'}}>
                      NOMBRE: <b>{getDataSession('razon_social_a')}</b><br />
                      ADVERTENCIA: <b>NINGUNA</b>
              </div>              
              <hr style={{border: 'solid 1px black',marginBottom: '10px',marginTop: '10px'}} />
              <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '700',marginRight: '0px'}}>
                      GUÍAS DE ORIGEN
              </div>
              <hr style={{border: 'solid 1px black',marginBottom: '10px',marginTop: '10px'}} />              
              <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: '300px',fontWeight: '500',marginRight: '0px'}}>
                      1) Z{getDataSessionOne()}
              </div>
              <hr style={{border: 'solid 1px black',marginBottom: '10px',marginTop: '10px'}} />              

            </Col>

            <Col md={12}>
              <div className="form-control-label" style={{color: '#000000',fontSize: '11px',width: 'auto',fontWeight: '700',marginRight: '0px'}}>
                Sr. Productor:<br />
                Verifique que los datos en esta constancia sean correctos y coincidan con la Guía de Propiedad y Tránsito a la que corresponden.<br />
                Conserve este listado junto a su copia de la Guía de Propiedad y Tránsito, el mismo le podrá ser exigido por el MGAP.
              </div>
            </Col>


          </CardBody>
          </CardHeader>
        </Card>

        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.props.setInfoTransFromList(1,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
 }
}

export default ModalListconstancyTwo;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px',
  top: '4px',
  position: 'relative',
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: '10px',
    border: 'solid 2px rgb(201, 237, 253)',
  }
}