import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label
} from "reactstrap";
import { row_origen_prop_list, delete_row_origin } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';

import "../StyleCss.css";
 class DetailOrigin extends React.Component {
  state={
    sumField:0,
    dataTatic:[],
    dataField:[],
  }
  async componentDidMount(){
    this.setData()
  }
  setData = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var data = await row_origen_prop_list(jsondata._id, this.props.validSwith.set_id)

     if (data.origin.length!=0) {
         this.setState({
           sumField:data.origin.length,
           dataField:data.origin,
           dataTatic:data.origin
         })
     }
  }

  render(){

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const sumField = () => {
    this.setState({sumField:this.state.sumField+1})
    this.setState({dataTatic:[...this.state.dataTatic,{id:'idNone',field_serie:null,field_guide:null,field_head:null,field_troop:null}]})
  }
const restField = async (index,id_origin) => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (id_origin!="idNone") {
         var result = await delete_row_origin(id_origin,jsondata._id)
     if (result.message=="exito") {
       this.setState({dataTatic:[]})
       this.setData()
     }
  }else{
  //document.querySelector("."+index).remove()
        var globalSelector = jQuery('.SectionSpan').children().last()
        var selectorEspecific = jQuery("."+index)

        var dataFieldSerie = globalSelector.find('.dataFieldSerie').val();
        var dataFieldGuide = globalSelector.find('.dataFieldGuide').val();
        var dataFieldHead = globalSelector.find('.dataFieldHead').val();
        var dataFieldTroop = globalSelector.find('.dataFieldTroop').val();

        selectorEspecific.find('.dataFieldSerie').val(dataFieldSerie)
        selectorEspecific.find('.dataFieldGuide').val(dataFieldGuide)
        selectorEspecific.find('.dataFieldHead').val(dataFieldHead)
        selectorEspecific.find('.dataFieldTroop').val(dataFieldTroop)

        var dataArray = this.state.dataTatic
        const newArray = dataArray.filter((person,indexa) => indexa !== parseInt(index.replace('fielSection','')) );
        this.setState({dataTatic:newArray})
  }
}  
    const rowSerieContent = () => {
      var content = []
      for (var i = 0; i < this.state.dataTatic.length; i++) {

              content[i] = <Row style={{margin: 'auto'}} key={i} className={"fielSection"+i}>
                      <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px', paddingLeft: '3px', paddingRight: '3px'}}>
                        <div style={{margin:'auto'}}>
                          <div style={{display:'flex'}}>
                          <Input
                          style={{textAlign: 'center',fontSize: '21px',height: '41px',borderRadius: '0px',width: '40px',color: 'black',letterSpacing: '0px'}}                    
                           //value={(this.props.validSwith.field_serie==null)?(null):(this.props.validSwith.field_serie[i])}
                           //onChange={(e)=>{this.props.setValFieldOrigin('field_serie',e.target.value,e.target.getAttribute('data-id'))}}
                           onLoad={loadInfo(this.state.dataField[i],i)}
                           id={i}
                           className="dataFieldSerie"
                           data-id={(this.state.dataTatic[i]==undefined)?('idNone'):(this.state.dataTatic[i].id)}
                           type="text"
                          />
                          </div>
                        </div>
                      </div>
                      
                      <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px', paddingLeft: '3px', paddingRight: '3px'}}>
                        <div style={{margin:'auto'}}>
                          <div style={{display:'flex'}}>
                          <Input
                          style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '120px',color: 'black',letterSpacing: '3px'}}                    
                           //value={(this.props.validSwith.field_guide==null)?(null):(this.props.validSwith.field_guide[i])}
                           //onChange={(e)=>{this.props.setValFieldOrigin('field_guide',e.target.value,e.target.getAttribute('data-id'))}}
                           className="dataFieldGuide"
                           data-id={i}
                           type="text"
                          />
                          </div>
                        </div>
                      </div>

                      <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px', paddingLeft: '3px', paddingRight: '3px'}}>
                        <div style={{margin:'auto'}}>
                          <div style={{display:'flex'}}>
                          <Input
                          style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '120px',color: 'black',letterSpacing: '3px'}}
                           //value={(this.props.validSwith.field_head==null)?(null):(this.props.validSwith.field_head[i])}
                           //onChange={(e)=>{this.props.setValFieldOrigin('field_head',e.target.value,e.target.getAttribute('data-id'))}}
                           className="dataFieldHead"
                           data-id={i}
                           type="text"
                          />
                          </div>
                        </div>
                      </div>

                      <div style={{alignSelf:'center',textAlign: 'center',marginTop: '5px', paddingLeft: '3px', paddingRight: '3px'}}>
                        <div style={{margin:'auto'}}>
                          <div style={{display:'flex'}}>
                          <Input
                          style={{textAlign: 'center',fontSize: '22px',height: '41px',borderRadius: '0px',width: '110px',color: 'black',letterSpacing: '3px'}}
                           //onChange={(e)=>{this.props.setValFieldOrigin('field_troop',e.target.value,e.target.getAttribute('data-id'))}}
                           //value={(this.props.validSwith.field_troop==null)?(null):(this.props.validSwith.field_troop[i])}
                           className="dataFieldTroop"
                           data-id={i}
                           type="text"
                          />
                          </div>
                        </div>
                      </div>
                        <Col md={1}>
                          <Button style={styles.titleBtnPlus} id={"fielSection"+i} data-id={(this.state.dataField[i]==undefined)?('idNone'):(this.state.dataField[i].id)} onClick={(e) => {restField(e.target.id,e.target.getAttribute('data-id'))} }>-</Button>
                        </Col>                      
                      </Row>
      }
      return content
  };
 const loadInfo = async (result,section) => {  
    var contentSection = await document.querySelectorAll('.SectionSpan')
    var contentSerie = document.querySelectorAll('.dataFieldSerie')[section]
    var contentGuide = document.querySelectorAll('.dataFieldGuide')[section]
    var contentHead = document.querySelectorAll('.dataFieldHead')[section]
    var contentTroop = document.querySelectorAll('.dataFieldTroop')[section]

  if (contentSerie!=null) {
    if (result!=undefined) {
    contentSerie.value=(result.serie==undefined)?(contentSerie.value):(result.serie)
    }
  }
  if (contentGuide!=null) {
    if (result!=undefined) {
    contentGuide.value=(result.numb_guia==undefined)?(contentGuide.value):(result.numb_guia)
    }
  }

  if (contentHead!=null) {
    if (result!=undefined) {
    contentHead.value=(result.cabeza==undefined)?(contentHead.value):(result.cabeza)
    }
  }

  if (contentTroop!=null) {
    if (result!=undefined) {
    contentTroop.value=(result.tropa==undefined)?(contentTroop.value):(result.tropa)
    }
  }    

}  
  //     const rowGuideContent = () => {
  //     var content = []
  //     for (var i = 0; i < 6; i++) {
  //             content[i] = 
  //     }
  //     return content
  // };

  //   const rowHeadContent = () => {
  //     var content = []
  //     for (var i = 0; i < 6; i++) {
  //             content[i] = 
  //     }
  //     return content
  // };

  //   const rowTroopContent = () => {
  //     var content = []
  //     for (var i = 0; i < 6; i++) {
  //             content[i] = 
  //     }
  //     return content
  // };
  return (
    <>
              <Col md="6" className="pb-2">
              <Col md="12">
                <label className="form-control-label" style={styles.labelStyleTree}>
                  4- ORIGEN DE LA PROP. DEL GANADO NO CONTRAMARCADO 
                </label>
              </Col>              
                <div style={styles.cardStyleSectionTwo}>
                <label className="form-control-label" style={{color: '#000000',fontSize: '15px',fontWeight: '700',margin: 0,width: '100%', textAlign:'center'}}>
                  CONSIGNACIÓN - REMATE - EXPORTACIÓN - FAENA
                </label>                
                  <div className="form-control-label text-justify" style={{color: '#000000',fontSize: '13px',width: '100%',fontWeight: '400',marginRight: '0px'}}>
                    INDICAR: N<sup>os</sup> de guías por las cuales recibió ese ganado y cantidades de cabezas en esta remesa correspondientes a cada guia de origen y de tropa cuando va a la faena.
                  </div>                
                <Row>
                <Col md={12}>
                <Row style={{margin: 'auto'}}>

                  <Col md={2}>
                      <div className="form-control-label text-center" style={{color: '#000000',fontSize: '13px',width: '100%',fontWeight: '400',marginRight: '0px'}}>
                        SERIE
                      </div> 
                    </Col>

                  <Col md={3} style={{padding: '0px'}}>
                      <div className="form-control-label" style={{color: '#000000',fontSize: '13px',width: '100%',fontWeight: '400',marginRight: '0px'}}>
                        N<sup>o</sup> DE GUÍA
                      </div> 
                      {/*rowGuideContent()*/}
                    </Col>

                  <Col md={3}>
                      <div className="form-control-label" style={{color: '#000000',fontSize: '13px',width: '100%',fontWeight: '400',marginRight: '0px'}}>
                        CABEZA
                      </div> 
                      {/*rowHeadContent()*/}
                    </Col>
                  <Col md={3}>
                      <div className="form-control-label" style={{color: '#000000',fontSize: '13px',width: '100%',fontWeight: '400',marginRight: '0px'}}>
                        TROPA
                      </div> 
                      {/*rowTroopContent()*/}
                    </Col> 

                   </Row>
                    <Col md={12}>
                      <span className="SectionSpan">{rowSerieContent()}</span>
                    </Col>
                    <Button style={styles.titleBtnPlus} onClick={() => {sumField()}}>+</Button>
                  </Col>
                </Row>
                </div>
              </Col>

    </>
  );
 }
}

export default DetailOrigin;

const styles = {
  hrStyle: {
    width: '91%',
    alignSelf: 'center',
    height: '1px',
    background: 'black',
    marginBottom: 0
  },
  titleBtnPlus:{
    boxShadow:'none',
    background: 'transparent',
    border: '0'
  },  
  labelStyle:{color: '#000000',
  fontSize: '13px',
  width: '170px',
  fontWeight: '700',
  margin: 0,
  },
  labelStyleTree:{
    color: '#000000',
    fontSize: '12px',
    fontWeight: '700',
    margin: 0,
    width: 'auto'
  },
  labelStyleFour:{
    color: '#000000',
    fontSize: '13px',
    fontWeight: '700',
    margin: 0,
    width: 'auto',
    paddingLeft: '5px'
  },
  labelStyleTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '180px',
  fontWeight: '600',
  marginRight: '25px'
  },
  labelStyleTwoNext:{
  color: '#000000',
  fontSize: '11px',
  width: '170px',
  fontWeight: '600',
  marginRight: '0px'
  },  
  labelStyleTwoNextMessage:{
  color: '#000000',
  fontSize: '11px',
  width: '300px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageReson:{
  color: '#000000',
  fontSize: '11px',
  width: '70px',
  fontWeight: '500',
  marginRight: '0px'
  },    
  labelStyleTwoNextMessageResonTwo:{
  color: '#000000',
  fontSize: '11px',
  width: '121px',
  fontWeight: '500',
  marginRight: '0px'
  },      
  cardStyleSection:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  },
  cardStyleSectionTwo:{
    backgroundColor: '#c9edfd',
    borderRadius: '6px',
    padding: '10px',
  }
}