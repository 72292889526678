import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";

export default class SectionFridge extends React.Component {
  state = {
    cross_transport:['cargando'],
    openColapse:[false],
    openColapseChild:[false],
    openColapseNextChild:[false]
  }
  async componentDidMount(){
  }
  render(){
         const opened = (isChange,ident) =>{
      if (isChange==undefined) {
      this.setState({openColapse:{[ident]: true }});
      }else{
      this.setState({openColapse:{[ident]: !isChange }});
      }
    }
    const openedChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseChild:{[ident]: true }});
        }else{
        this.setState({openColapseChild:{[ident]: !isChange }});
        }
      }
      const openedNextChild = (isChange,ident) =>{
      if (isChange==undefined) {
        this.setState({openColapseNextChild:{[ident]: true }});
        }else{
        this.setState({openColapseNextChild:{[ident]: !isChange }});
        }
      }

  return (
    <>
                    <div id="fridge-tab" className="clearfix eael-tab-content-item inactive" data-title-link="fridge-tab">
                                  <div data-elementor-type="page" data-elementor-id="5541" className="elementor elementor-5541">
                  <section className="elementor-section elementor-top-section elementor-element elementor-element-8903bf3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8903bf3" data-element_type="section"><div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8ec3272" data-id="8ec3272" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-eed6dbe elementor-widget elementor-widget-spacer" data-id="eed6dbe" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-28d08b1 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="28d08b1" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
      <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.fridge_info_fridge[0] == undefined)?('#f84d43'):((this.props.DataRow.fridge_info_fridge[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="far fa-newspaper" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Datos Frigorífico</h3></div></div>   </div>
        </div>
        <div className="elementor-element elementor-element-42682be elementor-widget elementor-widget-toggle" data-id="42682be" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">
{ 
                  (this.props.DataRow.fridge_info_fridge[0] == undefined)
                  ?
                  'Sin datos'
                  :
  this.props.DataRow.fridge_info_fridge.map((value,index)=>{
                                return (
                                  <div className="elementor-toggle-item" key={index}>
                                      <h6 onClick={()=> {openedChild(this.state.openColapseChild['datadp'+index],'datadp'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datall"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                                          <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                                          <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                                        </span>
                                        <div className="elementor-toggle-title">{(value.nombre_frigorifico=='null')?('Sin número de guía'):('Nombre Frigrotifico: '+value.nombre_frigorifico)}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['datadp'+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datall"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                                      <li key={index}>
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['datap'+index],'datap'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"transporte"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">Información de Frigorífico</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['datap'+index]} style={{width: '280%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"transporte"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
                                                                              <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.nombre_frigorifico==null)?(null):((valuetwo.nombre_frigorifico=='null')?(null):((valuetwo.nombre_frigorifico=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre del Frigorifico:</label><br /> {valuetwo.nombre_frigorifico}</Col>)))}
                                                     {(valuetwo.registro_numero==null)?(null):((valuetwo.registro_numero=='null')?(null):((valuetwo.registro_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Registro Numero:</label><br /> {valuetwo.registro_numero}</Col>)))}
                                                     {(valuetwo.ciudad==null)?(null):((valuetwo.ciudad=='null')?(null):((valuetwo.ciudad=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Pais:</label><br /> {valuetwo.ciudad}</Col>)))}
                                                     {(valuetwo.ciudad==null)?(null):((valuetwo.ciudad=='null')?(null):((valuetwo.ciudad=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Ciudad:</label><br /> {valuetwo.ciudad}</Col>)))}
                                                     {(valuetwo.estado==null)?(null):((valuetwo.estado=='null')?(null):((valuetwo.estado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Estado:</label><br /> {valuetwo.estado}</Col>)))}
                                                     {(valuetwo.georeferencia==null)?(null):((valuetwo.georeferencia=='null')?(null):((valuetwo.georeferencia=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Georeferencia:</label><br /> {valuetwo.georeferencia}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                              {/*{Object.values(value).map((valuetwo,indextwo)=>{
                                             return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={indextwo}>{valuetwo}</li>))))
                                            })}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>

                                       <li>
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['datasp'+index],'datasp'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"s"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">Información del Producto Recibido</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['datasp'+index]} style={{width: '280%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"s"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
<Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(this.props.DataRow.fridge_produc_received[index]==null)?(null):(this.props.DataRow.fridge_produc_received[index].status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      (this.props.DataRow.fridge_produc_received[index]==null)
                                                      ?
                                                      'Sin datos'
                                                      :
                                                      [this.props.DataRow.fridge_produc_received[index]].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.nombre_finca==null)?(null):((valuetwo.nombre_finca=='null')?(null):((valuetwo.nombre_finca=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nombre de la finca:</label><br /> {valuetwo.nombre_finca}</Col>)))}
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.guia_numero==null)?(null):((valuetwo.guia_numero=='null')?(null):((valuetwo.guia_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Guía Numero:</label><br /> {valuetwo.guia_numero}</Col>)))}
                                                     {(valuetwo.precinto_numero==null)?(null):((valuetwo.precinto_numero=='null')?(null):((valuetwo.precinto_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Precinto Numero:</label><br /> {valuetwo.precinto_numero}</Col>)))}
                                                     {(valuetwo.cert_inspeccion_nro==null)?(null):((valuetwo.cert_inspeccion_nro=='null')?(null):((valuetwo.cert_inspeccion_nro=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cert. Inspeccion Nro:</label><br /> {valuetwo.cert_inspeccion_nro}</Col>)))}
                                                     {(valuetwo.fecha_recibido==null)?(null):((valuetwo.fecha_recibido=='null')?(null):((valuetwo.fecha_recibido=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Recibido:</label><br /> {valuetwo.fecha_recibido}</Col>)))}
                                                     {(valuetwo.hora_recibido==null)?(null):((valuetwo.hora_recibido=='null')?(null):((valuetwo.hora_recibido=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de Recibido:</label><br /> {valuetwo.hora_recibido}</Col>)))}
                                                     {(valuetwo.cantidad_animales==null)?(null):((valuetwo.cantidad_animales=='null')?(null):((valuetwo.cantidad_animales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de Animales:</label><br /> {valuetwo.cantidad_animales}</Col>)))}
                                                     {(valuetwo.peso_total==null)?(null):((valuetwo.peso_total=='null')?(null):((valuetwo.peso_total=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Peso Total Est.:</label><br /> {valuetwo.peso_total+' '+valuetwo.peso_unidad}</Col>)))}
                                                     {(valuetwo.lavado_desinfeccion==null)?(null):((valuetwo.lavado_desinfeccion=='null')?(null):((valuetwo.lavado_desinfeccion=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lavado y desinfeccion:</label><br /> {valuetwo.lavado_desinfeccion}</Col>)))}
                                                     {(valuetwo.tratamiento_aplicado==null)?(null):((valuetwo.tratamiento_aplicado=='null')?(null):((valuetwo.tratamiento_aplicado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Tratamiento aplicado:</label><br /> {valuetwo.tratamiento_aplicado}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
{/*                                              {(this.props.DataRow.fridge_produc_received[index]==undefined)?(<li key={index}>Sin datos</li>):((this.props.DataRow.fridge_produc_received[index].lote_numero=='0')?(<li key={index}>Sin datos</li>):(Object.values(this.props.DataRow.fridge_produc_received[index]).map((value,index)=>{
                                                                                                                                         return ((value==null)?(null):((value=='null')?(null):((value=='undefined')?(null):(<li key={index}>{value}</li>))))
                                                                                                                                        })))}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>

                                       <li >
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['dataw'+index],'dataw'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"p"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">Información de la Faena</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['dataw'+index]} style={{width: '350%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"p"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
<Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(this.props.DataRow.fridge_produc_task[index]==null)?(null):(this.props.DataRow.fridge_produc_task[index].status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      (this.props.DataRow.fridge_produc_task[index]==null)
                                                      ?
                                                      'Sin datos'
                                                      :
                                                      [this.props.DataRow.fridge_produc_task[index]].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.fecha_faena==null)?(null):((valuetwo.fecha_faena=='null')?(null):((valuetwo.fecha_faena=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de la Faena:</label><br /> {valuetwo.fecha_faena}</Col>)))}
                                                     {(valuetwo.hora_faena==null)?(null):((valuetwo.hora_faena=='null')?(null):((valuetwo.hora_faena=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de inicio:</label><br /> {valuetwo.hora_faena}</Col>)))}
                                                     {(valuetwo.hora_final==null)?(null):((valuetwo.hora_final=='null')?(null):((valuetwo.hora_final=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora final:</label><br /> {valuetwo.hora_final}</Col>)))}
                                                     {(valuetwo.cantidad_animales==null)?(null):((valuetwo.cantidad_animales=='null')?(null):((valuetwo.cantidad_animales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de Animales:</label><br /> {valuetwo.cantidad_animales}</Col>)))}
                                                     {(valuetwo.lavado_faena==null)?(null):((valuetwo.lavado_faena=='null')?(null):((valuetwo.lavado_faena=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lavado Pos Faena:</label><br /> {valuetwo.lavado_faena}</Col>)))}
                                                     {(valuetwo.peso_pie==null)?(null):((valuetwo.peso_pie=='null')?(null):((valuetwo.peso_pie=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lavado Pos Faena:</label><br /> {valuetwo.peso_pie+' '+valuetwo.peso_unidad_pie}</Col>)))}
                                                     {(valuetwo.peso_canales==null)?(null):((valuetwo.peso_canales=='null')?(null):((valuetwo.peso_canales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lavado Pos Faena:</label><br /> {valuetwo.peso_canales+' '+valuetwo.peso_unidad_canales}</Col>)))}
                                                     {(valuetwo.tratamiento_aplicado==null)?(null):((valuetwo.tratamiento_aplicado=='null')?(null):((valuetwo.tratamiento_aplicado=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Tratamiento aplicado:</label><br /> {valuetwo.tratamiento_aplicado}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
{/*                                              {(this.props.DataRow.fridge_produc_task[index]==undefined)?(<li key={index}>Sin datos</li>):(Object.values(this.props.DataRow.fridge_produc_task[index]).map((valuevehicuo,index)=>{
                                                                                           return ((valuevehicuo==null)?(null):((valuevehicuo=='null')?(null):((valuevehicuo=='undefined')?(null):(<li key={index}>{valuevehicuo}</li>))))
                                                                                          }))}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>

                                        {/*Object.values(value).map((valuetwo,indextwo)=>{
                                       return ((valuetwo==null)?(null):((valuetwo=='null')?(null):(<li key={index}>{valuetwo}</li>)))
                                      })*/}
                                    </ul>
                                    </Collapse>
                                  </div>
                                )
                })
              }
                </div>
        </div>
        </div>

          </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fead0bd" data-id="fead0bd" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7c3b6fa" data-id="7c3b6fa" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-7c54668 elementor-widget elementor-widget-spacer" data-id="7c54668" data-element_type="widget" data-widget_type="spacer.default">
        <div className="elementor-widget-container">
          <div className="elementor-spacer">
      <div className="elementor-spacer-inner"></div>
    </div>
        </div>
        </div>
        <div className="elementor-element elementor-element-d816072 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="d816072" data-element_type="widget" data-widget_type="image-box.default">
        <div className="elementor-widget-container">
      <div className="elementor-image-box-wrapper">
            <figure className="elementor-image-box-img">
      <div style={{width:45,height:45,backgroundColor:(this.props.DataRow.slaughterhouse_exploded_record[0]==undefined)?('#f84d43'):((this.props.DataRow.slaughterhouse_exploded_record[0].status=='finalizado')?'#58d999':'#f8d014'),borderRadius: '100px', textAlign: 'end'}}>
      <span className="fa fa-home" style={{color:'#204d74',fontSize: '35px',position: 'relative',bottom: '5px',left: '5px'}}></span>
      </div>
      {/*<img width="100" height="94" src="https://www.food-chain.site/wp-content/uploads/2021/09/5.-1.png" className="attachment-full size-full" alt="" loading="lazy" />*/}
      </figure>
      <div className="elementor-image-box-content"><h3 className="elementor-image-box-title">Matadero</h3></div></div>   </div>
        </div>
        <div className="elementor-element elementor-element-5247922 elementor-widget elementor-widget-toggle" data-id="5247922" data-element_type="widget" data-widget_type="toggle.default">
        <div className="elementor-widget-container">
          <div className="elementor-toggle" role="tablist">

                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=> {openedChild(this.state.openColapseChild['mataderecord'],'mataderecord')}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datallem"} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                                          <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                                          <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                                        </span>
                                        <div className="elementor-toggle-title">{'Registro de Despiece '}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['mataderecord']} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datallem"} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
{(this.props.DataRow.slaughterhouse_exploded_record[0]==undefined)
                  ?
                  'Sin datos'
                  :
  this.props.DataRow.slaughterhouse_exploded_record.map((value,index)=>{
                                return (
                                      <li key={index}>
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['datap'+index],'datap'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"transporte"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">{(value.lote_numero==null)?('Sin Numero de lote'):((value.lote_numero=='null')?('Sin Numero de lote'):('Lote: '+value.lote_numero))}</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['datap'+index]} style={{width: '265%',position: 'relative',right: '100%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"transporte"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
                                            <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.fecha_despiece==null)?(null):((valuetwo.fecha_despiece=='null')?(null):((valuetwo.fecha_despiece=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Despiece:</label><br /> {valuetwo.fecha_despiece}</Col>)))}
                                                     {(valuetwo.hora_inicio==null)?(null):((valuetwo.hora_inicio=='null')?(null):((valuetwo.hora_inicio=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora de Inicio:</label><br /> {valuetwo.hora_inicio}</Col>)))}
                                                     {(valuetwo.hora_final==null)?(null):((valuetwo.hora_final=='null')?(null):((valuetwo.hora_final=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora Final:</label><br /> {valuetwo.hora_final}</Col>)))}
                                                     {(valuetwo.nro_canales==null)?(null):((valuetwo.nro_canales=='null')?(null):((valuetwo.nro_canales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro de Canales:</label><br /> {valuetwo.nro_canales}</Col>)))}
                                                     {(valuetwo.nro_medias_canales==null)?(null):((valuetwo.nro_medias_canales=='null')?(null):((valuetwo.nro_medias_canales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro de Medias Canales:</label><br /> {valuetwo.nro_medias_canales}</Col>)))}
                                                     {(valuetwo.nro_cuartos_canales==null)?(null):((valuetwo.nro_cuartos_canales=='null')?(null):((valuetwo.nro_cuartos_canales=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Nro de Cuartos Canales:</label><br /> {valuetwo.nro_cuartos_canales}</Col>)))}
                                                     {(valuetwo.carcasa_entera==null)?(null):((valuetwo.carcasa_entera=='null')?(null):((valuetwo.carcasa_entera=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Carcasa Entera:</label><br /> {valuetwo.carcasa_entera}</Col>)))}
                                                     {(valuetwo.pierna==null)?(null):((valuetwo.pierna=='null')?(null):((valuetwo.pierna=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Pierna:</label><br /> {valuetwo.pierna}</Col>)))}
                                                     {(valuetwo.paleta==null)?(null):((valuetwo.paleta=='null')?(null):((valuetwo.paleta=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Paleta:</label><br /> {valuetwo.paleta}</Col>)))}
                                                     {(valuetwo.costillar==null)?(null):((valuetwo.costillar=='null')?(null):((valuetwo.costillar=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Costillar:</label><br /> {valuetwo.costillar}</Col>)))}
                                                     {/*{(valuetwo.otro_corte==null)?(null):((valuetwo.otro_corte=='null')?(null):((valuetwo.otro_corte=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Otro Corte:</label><br /> {valuetwo.otro_corte}</Col>)))}
                                                     {(valuetwo.cantidad==null)?(null):((valuetwo.cantidad=='null')?(null):((valuetwo.cantidad=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad:</label><br /> {valuetwo.cantidad}</Col>)))}*/}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                              {/*{Object.values(value).map((valuetwo,indextwo)=>{
                                             return ((valuetwo==null)?(null):((valuetwo=='null')?(null):((valuetwo=='undefined')?(null):(<li key={indextwo}>{valuetwo}</li>))))
                                            })}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>

                                )})}
                                    </ul>
                                    </Collapse>
                                  </div>

                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=> {openedChild(this.state.openColapseChild['matadepack'],'matadepack')}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datall"} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                                          <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                                          <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                                        </span>
                                        <div className="elementor-toggle-title">{'Empaque'}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['matadepack']} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datall"} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
{(this.props.DataRow.slaughterhouse_packing[0]==undefined)
                  ?
                  'Sin datos'
                  :
  this.props.DataRow.slaughterhouse_packing.map((value,index)=>{
                                return (
                                       <li key={index}>
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['empaq'+index],'empaq'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"s"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">{(this.props.DataRow.slaughterhouse_packing[index]==undefined)?('Sin Numero de lote'):((this.props.DataRow.slaughterhouse_packing[index].lote_numero=='null')?('Sin Numero de lote'):('Lote: '+this.props.DataRow.slaughterhouse_packing[index].lote_numero))}</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['empaq'+index]} style={{width: '360%',position: 'relative',right: '200%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"s"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
                                            <Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.fecha==null)?(null):((valuetwo.fecha=='null')?(null):((valuetwo.fecha=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha:</label><br /> {valuetwo.fecha}</Col>)))}
                                                     {(valuetwo.producto==null)?(null):((valuetwo.producto=='null')?(null):((valuetwo.producto=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Corte:</label><br /> {valuetwo.producto}</Col>)))}
                                                     {(valuetwo.piezas_caja==null)?(null):((valuetwo.piezas_caja=='null')?(null):((valuetwo.piezas_caja=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Piezas por caja:</label><br /> {valuetwo.piezas_caja}</Col>)))}
                                                     {(valuetwo.cantidad_cajas==null)?(null):((valuetwo.cantidad_cajas=='null')?(null):((valuetwo.cantidad_cajas=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de cajas:</label><br /> {valuetwo.cantidad_cajas}</Col>)))}
                                                     {(valuetwo.piezas_caja==null)?(null):((valuetwo.piezas_caja=='null')?(null):((valuetwo.piezas_caja=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad total de Piezas:</label><br /> {parseInt(valuetwo.piezas_caja)+parseInt(valuetwo.cantidad_cajas)}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
{/*                                              {(this.props.DataRow.slaughterhouse_packing[index]==undefined)?(<li key={index}>Sin datos</li>):(Object.values(this.props.DataRow.slaughterhouse_packing[index]).map((value,index)=>{
                                                                                           return ((value==null)?(null):((value=='null')?(null):((value=='undefined')?(null):(<li key={index}>{value}</li>))))
                                                                                          }))}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>
                                )})}
                                    </ul>
                                    </Collapse>
                                  </div>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=> {openedChild(this.state.openColapseChild['matadefree'],'matadefree')}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"datall"} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <span className="elementor-toggle-icon elementor-toggle-icon-left" aria-hidden="true">
                                          <span className="elementor-toggle-icon-closed"><i className="fas fa-caret-right"></i></span>
                                          <span className="elementor-toggle-icon-opened"><i className="elementor-toggle-icon-opened fas fa-caret-up"></i></span>
                                        </span>
                                        <div className="elementor-toggle-title">{'Camara de Frio'}</div>
                                      </h6>
                                    <Collapse isOpen={this.state.openColapseChild['matadefree']} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"datall"} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
{(this.props.DataRow.slaughterhouse_freezing[0]==undefined)
                  ?
                  'Sin datos'
                  :
  this.props.DataRow.slaughterhouse_freezing.map((value,index)=>{
                                return (

                                       <li key={index}>
                                        <div className="elementor-toggle-item">
                                            <h6 onClick={()=> {openedNextChild(this.state.openColapseNextChild['cmdf'+index],'cmdf'+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={"p"+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                              <div className="elementor-toggle-title">{(this.props.DataRow.slaughterhouse_freezing[index]==undefined)?('Sin Numero de lote'):((this.props.DataRow.slaughterhouse_freezing[index].lote_numero=='null')?('Sin Numero de lote'):('Lote: '+this.props.DataRow.slaughterhouse_freezing[index].lote_numero+' '+this.props.DataRow.slaughterhouse_freezing[index].producto))}</div>
                                            </h6>
                                          <Collapse isOpen={this.state.openColapseNextChild['cmdf'+index]} style={{width: '265%',position: 'relative',right: '100%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={"p"+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                          <ul>
<Col md="12" style={{margin: 'auto'}}>
                                                  <Card>
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(157, 14, 13)'}}>
                                                    <Row>
                                                      <Col md={9}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                      </Col>
                                                      <Col md={3}>
                                                        <h4 className="mb-0" style={{color: '#ffffff'}}>{(value.status=='desarrollo')?('Trazabilidad en proceso'):(value.status)}</h4>
                                                      </Col>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                    {
                                                      [value].map((valuetwo,index)=>{
                                                     return (<>
                                                     {(valuetwo.lote_numero==null)?(null):((valuetwo.lote_numero=='null')?(null):((valuetwo.lote_numero=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Lote Numero:</label><br /> {valuetwo.lote_numero}</Col>)))}
                                                     {(valuetwo.producto==null)?(null):((valuetwo.producto=='null')?(null):((valuetwo.producto=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Corte:</label><br /> {valuetwo.producto}</Col>)))}
                                                     {(valuetwo.cantidad_producto==null)?(null):((valuetwo.cantidad_producto=='null')?(null):((valuetwo.cantidad_producto=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de cajas:</label><br /> {valuetwo.cantidad_producto}</Col>)))}
                                                     {(valuetwo.cantidad_paleta==null)?(null):((valuetwo.cantidad_paleta=='null')?(null):((valuetwo.cantidad_paleta=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Cantidad de Paleta:</label><br /> {valuetwo.cantidad_paleta}</Col>)))}
                                                     {(valuetwo.fecha_ingreso==null)?(null):((valuetwo.fecha_ingreso=='null')?(null):((valuetwo.fecha_ingreso=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Fecha de Ingreso:</label><br /> {valuetwo.fecha_ingreso}</Col>)))}
                                                     {(valuetwo.hora_ingreso==null)?(null):((valuetwo.hora_ingreso=='null')?(null):((valuetwo.hora_ingreso=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Hora:</label><br /> {valuetwo.hora_ingreso}</Col>)))}
                                                     {(valuetwo.aplico_preenfriamiento==null)?(null):((valuetwo.aplico_preenfriamiento=='null')?(null):((valuetwo.aplico_preenfriamiento=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Se Aplico Preenfriamiento:</label><br /> {valuetwo.aplico_preenfriamiento}</Col>)))}
                                                     {(valuetwo.temperatura_inicial==null)?(null):((valuetwo.temperatura_inicial=='null')?(null):((valuetwo.temperatura_inicial=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Temperatura Inicial:</label><br /> {valuetwo.temperatura_inicial}</Col>)))}
                                                     {(valuetwo.temperatura_final==null)?(null):((valuetwo.temperatura_final=='null')?(null):((valuetwo.temperatura_final=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Temperatura Final:</label><br /> {valuetwo.temperatura_final}</Col>)))}
                                                     {(valuetwo.informacion_adicional==null)?(null):((valuetwo.informacion_adicional=='null')?(null):((valuetwo.informacion_adicional=='undefined')?(null):(<Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Información adicional:</label><br /> {valuetwo.informacion_adicional}</Col>)))}
                                                      </>)
                                                    })}
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
{/*                                              {(this.props.DataRow.slaughterhouse_freezing[index]==undefined)?(<li key={index}>Sin datos</li>):(Object.values(this.props.DataRow.slaughterhouse_freezing[index]).map((valuevehicuo,index)=>{
                                                                                           return ((valuevehicuo==null)?(null):((valuevehicuo=='null')?(null):((valuevehicuo=='undefined')?(null):(<li key={index}>{valuevehicuo}</li>))))
                                                                                          }))}*/}
                                          </ul>
                                          </Collapse>
                                        </div>
                                      </li>
                                )})}
                                    </ul>
                                    </Collapse>
                                  </div>
                </div>
        </div>
        </div>

          </div>
    </div>

              </div>
    </section><section className="elementor-section elementor-top-section elementor-element elementor-element-db5b2a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="db5b2a8" data-element_type="section"><div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0c62df" data-id="e0c62df" data-element_type="column">
      <div className="elementor-widget-wrap">
                  </div>
    </div>
              </div>
    </section></div>
                                        </div>
    </>
  );
}
}

//export default Elements;
