import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  NavLink,
  Row,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { NavLink as ComponentLink } from "react-router-dom";

import { save_meat, data_trazabilidad_meat } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import config from "config";

export default class StartMeats extends React.Component {

  state={
    mess:'',
    btnd:false,
    codigoAran: [],
    notificationAlertRef: React.createRef(),
    myVarTime:null,
    codigo_arancelario:'',
    counter:0
  }
  async componentDidMount(){
    this.initData()
  }
    initData = async ()=>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        this.state.myVarTime = setInterval(() => this.setDataCode(), 500);
        var gerParam = this.props.paramsCode;
        var codigoTrazaTwo =  new URLSearchParams(window.location.search).get("up")
        var dataTrazabilidadvf = await data_trazabilidad_meat(jsondata._id,gerParam,codigoTrazaTwo)
        this.props.getData(dataTrazabilidadvf)
        if (dataTrazabilidadvf==null) {
          var date = new Date()
          var completeDate = date.toLocaleDateString("en-CA")
          this.setState({
              meat_id:'noind',
              fecha:completeDate,
              nombre_producto:'',
              nombre_finca:'',
              tamano_finca:'',
              area:'',
              georeferencia:'',
              pais_origen:'',
              ciudad:'',
              provincia:'',
              especie:'0',
              otra_especie:'',
              raza:'',
              proposito:'0',
              otro_uso:'',
              codigo_arancelario:'0',
              informacion_adicional:'',
          })
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }

    setDataCampos = (data)=>{
      this.setState({
          meat_id:data.id,
          fecha:data.fecha,
          nombre_producto:data.nombre_producto,
          nombre_finca:data.nombre_finca,
          tamano_finca:data.tamano_finca,
          area:data.area,
          georeferencia:data.georeferencia,
          pais_origen:data.pais_origen,
          ciudad:data.ciudad,
          provincia:data.provincia,
          especie:data.especie,
          otra_especie:data.otra_especie,
          raza:data.raza,
          proposito:data.proposito,
          otro_uso:data.otro_uso,
          codigo_arancelario:data.codigo_arancelario,
          informacion_adicional:data.informacion_adicional,
          file_document:data.archivo,
      })
    }

  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranM})
      }
      this.setState({counter:this.state.counter+1})
  }
  render(){
  const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)
  return (
    <>
   {/*<SimpleHeader name="Iniciar trazabilidad" parentName="Carnicos" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Iniciar trazabilidad: Carnicos</h3>
          </CardHeader>
          <CardBody>
            Acceso denegado. Contáctanos para más información.
          </CardBody>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}


                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                        >
                          Fecha de Registro
                        </label>
                        <br /><span>
                          {this.state.fecha}
                          </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Nombre del Producto
                        </label>
                        <br /><span>
                          {this.state.nombre_producto}
                          </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Nombre de la finca
                        </label>
                        <br /><span>
                          {this.state.nombre_finca}
                          </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Tamaño de la finca
                        </label>
                        <br /><span>
                          {this.state.tamano_finca}
                          </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Georeferencia
                        </label>
                        <br /><span>
                          {this.state.georeferencia}
                        </span>
                      </FormGroup>
                    </Col>
                    {/*<Col md="4">
                                          <FormGroup>
                                            <label
                                              className="form-control-label"
                                              htmlFor="example3cols2Input"
                                            >
                                              Área
                                            </label>
                                            <br /><span>
                                              
                                              {this.state.fecha}
                                              id="example3cols2Input"
                                              placeholder="Escribe un área"
                                              type="text"
                                            />
                                          </FormGroup>
                                        </Col>*/}
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          País de origen
                        </label>
                        <br /><span> 
                        {this.state.pais_origen}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Ciudad
                        </label>
                        <br /><span>
                          
                          {this.state.ciudad}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Provincia
                        </label>
                        <br /><span>
                          
                          {this.state.provincia}
                            </span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Especie
                        </label>
                        <br /><span>
                          {this.state.especie}
                          </span>
                      </FormGroup>
                    </Col>
                   <Col md="4" sm="6" style={{ display: (this.state.especie=='otro')?('block'):('none') }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Especificar Otra Especie
                        </label>
                        <br /><span>
                          
                          {this.state.otra_especie}
                            </span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Raza
                        </label>
                        <br /><span>
                          
                          {this.state.raza}
                            </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Propósito
                        </label>
                        <br /><span>
                          {this.state.proposito}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6" style={{ display: (this.state.proposito=='otro')?('block'):('none') }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Otro Proposito
                        </label>
                        <br /><span>
                          
                          {this.state.otro_uso}
                            </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Código arancelario
                        </label>
                        <br /><span>{this.state.codigo_arancelario}</span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Información adicional
                        </label>
                        <br /><span>
                          {this.state.informacion_adicional}
                          </span>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="6">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Documento/Imagen
                      </label><br />
                    {(this.state.file_document==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('/').pop()}</a>)}
                    </Col>              
                  </Row>

    </>
  );
 }
}

//export default Elements;
