import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
//filter: hue-rotate(28deg);
import config from "config";
import {PutContentFile} from './InfoLogistic'

export default class SectionDocuments extends React.Component {
  async componentDidMount(){
  }
  render(){
  return (
    <>
    <div id="documentos-tab" className="clearfix eael-tab-content-item active" data-title-link="documentos-tab">
    <div data-elementor-type="section" data-elementor-id="5570" className="elementor elementor-5570">
    <div className="elementor-section-wrap">
    <section className="elementor-section elementor-top-section elementor-element elementor-element-1fac86c8 elementor-section-content-middle animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-visible" data-id="1fac86c8" data-element_type="section" id="services" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-497571a6" data-id="497571a6" data-element_type="column">
    <div className="elementor-widget-wrap elementor-element-populated">
    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- TRAZABILIDAD: Vegetales y frutas frescas </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*    <div className="col-md-3" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Vegetales y frutas frescas</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="fruit_vegetable"
                  />
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>
    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PRODUCCIÓN: Datos </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    {/*<div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
{/*    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">*/}
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="production_create"
                  />
{/*    </div>
    </div>
    </div>*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PRODUCCIÓN: Fertilizante/Agroquímico </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Fertilizante/Agroquímico</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="production_fertilizer"
                  />
{/*                    {
                     (this.props.DataRow.DataRowProdcutionFertilizer==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.DataRowProdcutionFertilizer.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}
  */}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Datos </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
    {/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- COSECHA</span></Col>
    <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
{/*    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">*/}
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_cosecha"
                  />
{/*                    {   (this.props.DataRow.create_cosecha==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.create_cosecha].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
                  )))
                })}*/}
{/*    </div>
    </div>
    </div>*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Transporte Campo </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*        <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Transporte Campo</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="transport_cosecha"
                  />
{/*                    {
                     (this.props.DataRow.transport_cosecha==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.transport_cosecha.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COSECHA: Vehiculo </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/* <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Vehiculo</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="transport_vehicule_cosecha"
                  />
{/*            this.props.DataRow.transport_vehicule_cosecha{
                     (this.props.DataRow.transport_vehicule_cosecha==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.transport_vehicule_cosecha.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: producto </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- PLANTA</span></Col>
            <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos planta: Ingreso del producto</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_product"
                  />
{/*                    { (this.props.DataRow.create_plant_product==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.create_plant_product.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Muestra de calidad </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*    <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos planta: Muestra de calidad</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_sample"
                  />
{/*                    { (this.props.DataRow.create_plant_sample==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.create_plant_sample.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Peso del producto </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*        <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos planta: Peso del producto</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_weight_product"
                  />
{/*                    { (this.props.DataRow.create_plant_weight_product==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.create_plant_weight_product.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Tratamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

        {/*<section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Datos planta: Tratamiento</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_treatment"
                  />
{/*                    { (this.props.DataRow.create_plant_treatment==null)
                      ?
                      'Sin datos'
                      :
                      this.props.DataRow.create_plant_treatment.map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Información de empaque </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*    <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Información de empaque</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_information_empaque"
                  />
{/*                    {   (this.props.DataRow.create_plant_information_empaque==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.create_plant_information_empaque].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Pre-enfriamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

        {/*<section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Pre-enfriamiento</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_pre_cold"
                  />
   {/*                 {   (this.props.DataRow.create_plant_pre_cold==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.create_plant_pre_cold].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- PLANTA: Enfriamiento </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*            <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Enfriamiento</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="create_plant_cold"
                  />
{/*                    {   (this.props.DataRow.create_plant_cold==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.create_plant_cold].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Embarque </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- LOGISTICA Y TRANSPORTE</span></Col>
<section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Embarque</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="logistics_transport_shipment"
                  />
{/*                    {   (this.props.DataRow.logistics_transport_shipment==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.logistics_transport_shipment].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- LOGISTICA Y TRANSPORTE: Transporte </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*    <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Transporte</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="logistics_transport_transporte"
                  />
{/*                    {   (this.props.DataRow.logistics_transport_transporte==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.logistics_transport_transporte].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>
 

    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMERCIALIZACIÓN: Mercado nacional </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">

{/*    
    <Col md={12}><span className="elementor-heading-title" style={{fontWeight: '600'}}>- COMERCIALIZACIÓN</span></Col>
<section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Mercado nacional</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="commercialization_market"
                  />
  {/*                  {   (this.props.DataRow.commercialization_market==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.commercialization_market].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>


    <Card style={{width: '100%'}}>
    <CardHeader md={12} style={{backgroundColor: '#7ac142',padding: '5px'}}><span className="elementor-heading-title" style={{fontWeight: '600',color: 'white'}}>- COMERCIALIZACIÓN: Exportación </span></CardHeader>
    <Col md={12}>
    <CardBody className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="row">
{/*
    <section className="" data-id="635b709" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-container elementor-column-gap-narrow">
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-d199dc8" data-id="d199dc8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c0fc3dc elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c0fc3dc" data-element_type="widget" data-widget_type="heading.default">
    <div className="elementor-widget-container">
    <h2 className="elementor-heading-title elementor-size-default">Exportación</h2>    
    </div>
    </div>
    </div>
    </div>*/}
    <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-62de2ae" data-id="62de2ae" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <PutContentFile
                  data_function={this.props.DataRow} 
                  type_function="commercialization_export"
                  />
{/*                    {   (this.props.DataRow.commercialization_export==null)
                        ?
                        'Sin datos'
                        :
                      [this.props.DataRow.commercialization_export].map((value,index)=>{
                      if(value==null){
                      var name_file = "Sin archivo";
                      }else{
                      if (value.archivo==null) {
                      var name_file = "-";
                      }else{
                      var name_file = String(value.archivo).split('/').pop();
                      }
                      
                      }
                 return ((value==null)?('-'):((value.archivo==null)?('-'):(
    <div className="elementor-widget-wrap elementor-element-populated">
    <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                        <span className="elementor-icon elementor-animation-">
                        <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                        </span>
                        </div>
                        <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo} className="elementor-icon-box-content">
                        <h3 className="elementor-icon-box-title">
                        <span>
                        {name_file}         
                        </span>
                        </h3>
                        </a>
                      </div>
    </div>
    </div>
    </div>
                  )))
                })}*/}
    </div>
    </div>
    </CardBody>
    </Col>
    </Card>

    </div>
    </div>
    </div>
    </section>
    </div>
    </div>
    </div>
    </>
  );
}
}

//export default Elements;
