import React, {Component} from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import { row_documents_meats_list, printPdf } from "network/ApiAxios";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
//import print from 'print-js'
import config from "config";
import {UserContext} from "layouts/store";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";

class DocumentsMeat extends Component {
state={
  data:[],
  create_traceability_meat:[],
  compartment_receipt_animal:[],
  compartment_exit_animal:[],
  fridge_info_fridge:[],
  fridge_produc_received:[],
  fridge_produc_task:[],
  logistics_transport_export:[],
  logistics_transport_from:[],
  create_animal_meat:[],
  slaughterhouse_exploded_record:[],
  slaughterhouse_freezing:[],
  slaughterhouse_packing:[],
}

async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.location.search).get("code");
  var data = await row_documents_meats_list(jsondata._id,gerParam)
  if (data=="Sin codigo") {
  this.setState({create_traceability_meat:[]})
  this.setState({compartment_receipt_animal:[]})
  this.setState({compartment_exit_animal:[]})
  this.setState({fridge_info_fridge:[]})
  this.setState({fridge_produc_received:[]})
  this.setState({fridge_produc_task:[]})
  this.setState({logistics_transport_export:[]})
  this.setState({logistics_transport_from:[]})
  this.setState({create_animal_meat:[]})
  this.setState({slaughterhouse_exploded_record:[]})
  this.setState({slaughterhouse_freezing:[]})
  this.setState({slaughterhouse_packing:[]})
  }else{
  this.setState({create_traceability_meat:data.create_traceability_meat})
  this.setState({compartment_receipt_animal:data.compartment_receipt_animal})
  this.setState({compartment_exit_animal:data.compartment_exit_animal})
  this.setState({fridge_info_fridge:data.fridge_info_fridge})
  this.setState({fridge_produc_received:data.fridge_produc_received})
  this.setState({fridge_produc_task:data.fridge_produc_task})
  this.setState({logistics_transport_export:data.logistics_transport_export})
  this.setState({logistics_transport_from:data.logistics_transport_from})
  this.setState({create_animal_meat:data.create_animal_meat})
  this.setState({slaughterhouse_exploded_record:data.slaughterhouse_exploded_record})
  this.setState({slaughterhouse_freezing:data.slaughterhouse_freezing})
  this.setState({slaughterhouse_packing:data.slaughterhouse_packing})
  }
  this.setState({data:data})

}
  render(){
const printFile = (urlFile) => {
       //var Pagelink = urlFile;
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(ImagetoPrint(urlFile));
        pwa.document.close();
  };
  const ImagetoPrint = (source)=>{
           return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
  }
  const convertBase64ToFile = (base64String, fileName) => {
     let arr = base64String.split(',');
     let mime = arr[0].match(/:(.*?);/)[1];
     let bstr = atob(arr[1]);
     let n = bstr.length;
     let uint8Array = new Uint8Array(n);
     while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
     }
     let file = new File([uint8Array], fileName, { type: mime });
     return file;
}
const printer_pdf = async (url,fileExtend) => {
   var printer = await printPdf(url)
  if (printer) {
    if (url.indexOf(".pdf") == -1) {
      printFile('data:image/'+fileExtend+';base64,'+printer)
    }else{
    console.log('hola')

        let byteCharacters = atob(printer);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], {type: 'application/pdf;base64'});
    let fileURL = URL.createObjectURL(file);
    const newWindow = window.open(fileURL);
setTimeout(function() {
    newWindow.print();
}, 500)
    //newWindow.close();
     }
  }
}

  const download_file = async (url,fileName)=>{
   var printer = await printPdf(url)
   if (printer) {
    if (fileName.indexOf(".pdf") == -1) {
      var baseUrl = 'data:image/'+fileName+';base64,'+printer;
    }else{
      var baseUrl = 'data:application/'+fileName+';base64,'+printer;
  }

   var file = convertBase64ToFile(baseUrl, fileName);
   saveAs(file, fileName);
   
   }

  }
  const optionsIles = (URL_PRODUCTION,archivo,index,position)=>{
    var content = []
    if (archivo.indexOf('.pdf') != -1 || archivo.indexOf('.jp') != -1 || archivo.indexOf('.png') != -1) {
                          content[position] = <>
                                      <a
                                         target="_black"
                                         className="font-weight-bold"
                                         href={URL_PRODUCTION+"/storage/file_documents/"+archivo}
                                       >
                                         Ver
                                       </a><br />
                                       <a
                                         className="font-weight-bold"
                                         href="javascript:;"
                                         onClick={()=>{download_file("/storage/file_documents/"+archivo, String(archivo).split('/').pop() )}}
                                         >
                                         Descargar
                                       </a><br />
                                       <a
                                         className="font-weight-bold"
                                         href="javascript:;"
                                         onClick={()=>{printer_pdf("/storage/file_documents/"+archivo, String(archivo).split('.').pop() )}}
                                       >
                                         Imprimir
                                       </a>
                                       </>
    }else{

                                      content[position] = <>
                                       <a
                                         className="font-weight-bold"
                                         href="javascript:;"
                                         onClick={()=>{download_file("/storage/file_documents/"+archivo, String(archivo).split('/').pop() )}}
                                         >
                                         Descargar
                                       </a>
                                       </>
    }

                                       return content
  }
  return (
    <>
    <MeatsNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Documentos" parentName="Operaciones" />
      <Container className="mt--6" fluid>

        <Card style={{display:(this.state.create_traceability_meat.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Carnico Trazabilidad</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.create_traceability_meat.length==0 ? null :

        this.state.create_traceability_meat.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                  {optionsIles(config.URL_PRODUCTION,value.archivo,index,0)}
                </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
<Card style={{display:(this.state.create_animal_meat.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Información Animal</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.create_animal_meat.length==0 ? null :

        this.state.create_animal_meat.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,1)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>

                <Card style={{display:(this.state.compartment_receipt_animal.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Compartimento</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.compartment_receipt_animal.length==0 ? null :

        this.state.compartment_receipt_animal.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,2)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>

                <Card style={{display:(this.state.compartment_exit_animal.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Compartimento: Salida de Animales</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.compartment_exit_animal.length==0 ? null :

        this.state.compartment_exit_animal.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,3)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.fridge_info_fridge.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Frigorifico</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.fridge_info_fridge.length==0 ? null :

        this.state.fridge_info_fridge.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,4)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.fridge_produc_received.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Frigirifico Recibido</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.fridge_produc_received.length==0 ? null :

        this.state.fridge_produc_received.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,5)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.fridge_produc_task.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Frigorifico Faena</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.fridge_produc_task.length==0 ? null :

        this.state.fridge_produc_task.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,6)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
<Card style={{display:(this.state.slaughterhouse_exploded_record.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Matadero: Registro de Despiece</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.slaughterhouse_exploded_record.length==0 ? null :

        this.state.slaughterhouse_exploded_record.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,7)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
         <Card style={{display:(this.state.slaughterhouse_packing.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Matadero: Empaque</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.slaughterhouse_packing.length==0 ? null :

        this.state.slaughterhouse_packing.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,8)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
         <Card style={{display:(this.state.slaughterhouse_freezing.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Matadero: Camara de Frio</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.slaughterhouse_freezing.length==0 ? null :

        this.state.slaughterhouse_freezing.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,9)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.logistics_transport_export.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Transporte Exportación</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.logistics_transport_export.length==0 ? null :

        this.state.logistics_transport_export.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,10)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.logistics_transport_from.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Logistica Transporte</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.logistics_transport_from.length==0 ? null :

        this.state.logistics_transport_from.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      {optionsIles(config.URL_PRODUCTION,value.archivo,index,11)}
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                
 
      </Container>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
      this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)

  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
if (dataPermission.type_traza=='VF' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
                if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.documentos_m==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }

render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <DocumentsMeat {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
