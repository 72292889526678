import React, {Component} from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import { row_documents_list, printPdf, get_permission } from "network/ApiAxios";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
//import print from 'print-js'
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import PropTypes from 'prop-types';
class Documents extends Component {
state={
  data:[],
  create_plant_product:[],
  create_plant_sample:[],
  create_plant_treatment:[],
  create_plant_weight_product:[],
  production_fertilizer:[],
  transport_cosecha:[],
  transport_vehicule_cosecha:[],
  set_content:[]
}
async componentDidMount(){
 var dataArr = 
[  {'Trazabilidad':{'fruit_vegetable':'Vegetales y frutas frescas'}},
   {'Producción': {'production_create':'Datos Producción','production_fertilizer':'Datos Fertilizantes / Agroquímicos'}},
   {'Cosecha': {'create_cosecha':'Datos Cosecha','transport_cosecha':'Datos Transporte de Campo','transport_vehicule_cosecha':'Vehiculo'}},
   {'Planta': {'create_plant_product':'Ingreso del Producto','create_plant_sample':'Muestra de Calidad','create_plant_weight_product':'Peso del Producto','create_plant_treatment':'Tratamiento','create_plant_information_empaque':'Información de Empaque','create_plant_pre_cold':'Pre-enfriamiento','create_plant_cold':'Enfriamiento'}},
   {'Logística y Transporte': {'logistics_transport_shipment':'Embarque','logistics_transport_transporte':'Transporte'}},
   {'Comercialización': {'commercialization_market':'Mercado Nacional','commercialization_export':'Exportacion'}},
];
this.setState({set_content:dataArr})
//dataArr.map((a,b)=>{console.log(a)})
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.location.search).get("code");
  var data = await row_documents_list(jsondata._id,gerParam)
  // if (data=="Sin codigo") {
  // this.setState({create_plant_product:[]})
  // this.setState({create_plant_sample:[]})
  // this.setState({create_plant_treatment:[]})
  // this.setState({create_plant_weight_product:[]})
  // this.setState({production_fertilizer:[]})
  // this.setState({transport_cosecha:[]})
  // this.setState({transport_vehicule_cosecha:[]})
  // this.setState({logistics_transport_shipment:[]})
  // this.setState({logistics_transport_transporte:[]})
  // }else{
  // this.setState({create_plant_product:data.create_plant_product})
  // this.setState({create_plant_sample:data.create_plant_sample})
  // this.setState({create_plant_treatment:data.create_plant_treatment})
  // this.setState({create_plant_weight_product:data.create_plant_weight_product})
  // this.setState({production_fertilizer:data.production_fertilizer})
  // this.setState({transport_cosecha:data.transport_cosecha})
  // this.setState({transport_vehicule_cosecha:data.transport_vehicule_cosecha})
  // this.setState({logistics_transport_shipment:data.logistics_transport_shipment})
  // this.setState({logistics_transport_transporte:data.logistics_transport_transporte})
  // }
   this.setState({data_doc:data})

}
getContentInfo = (table_name,data) =>{
  var content=[]
  data.map((a,b)=>{
    content[b] = <tr>
                <td>
                  <span className="text-muted">
                    Create
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {/*{value==null? null : String(value.archivo).split('/').pop()}*/}
                  {table_name}
                  </span>
                </td>                
                <td>
                  <span className="text-muted">
                  {/*{value==null? null : String(value.archivo).split('/').pop()}*/}
                  {(a=='')?('Sin archivo'):(a)}
                  </span>
                </td>
               

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+a}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{this.download_file("/storage/file_documents/"+a,String(a).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{this.printer_pdf("/storage/file_documents/"+a,String(a).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>
  })
  return content
}

getInfo = (info_content) =>{
  if (this.state.data_doc!=undefined) {
    return Object.keys(this.state.data_doc).map((a,b)=>{

      if (info_content[0][a]!=undefined) {
         var data_logi=[]
         // if (this.state.data_doc[a].indexOf('|')!=-1) {
         //   data_logi=this.state.data_doc[a].split('|')
         // }else{
         //   data_logi = this.state.data_doc[a]
         // }
         if (typeof this.state.data_doc[a] == 'string') {
          // if (typeof this.state.data_doc[a] == 'string') {
          // }
           data_logi=this.state.data_doc[a].split('|')
         }else{
          //console.log(Object.values(this.state.data_doc[a]).join("|").split("|"))
           //data_logi = Object.values(this.state.data_doc[a]).join("|").split("|").filter((a,b)=>a!='|')
           data_logi = this.state.data_doc[a]
         }
        //console.log(data_logi,info_content[0][a])
         return this.getContentInfo(info_content[0][a],data_logi)
      }
    })
  }
}
setContent = (content) => {
  var put_content = []
  //style={{display:(this.state.production_fertilizer.length==0) ? ('none') : ('flex')}}
  for (var i = 0; i < content.length; i++) {
        put_content[i] = <Card key={i}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
              
                <h3 className="mb-0">{Object.keys(content[i])}</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Formulario</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            {this.getInfo(Object.values(content[i]))}
<tbody>
{/*            
     {  this.state.production_fertilizer.length==0 ? null :

        this.state.production_fertilizer.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
          */}
            </tbody>
          </Table>
        </Card>
  }
  return put_content
}

 printFile = (urlFile) => {
       //var Pagelink = urlFile;
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(this.ImagetoPrint(urlFile));
        pwa.document.close();
  };
   ImagetoPrint = (source)=>{
           return "<html><head><scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<img src='" + source + "' /></body></html>";
  }
   convertBase64ToFile = (base64String, fileName) => {
     let arr = base64String.split(',');
     let mime = arr[0].match(/:(.*?);/)[1];
     let bstr = atob(arr[1]);
     let n = bstr.length;
     let uint8Array = new Uint8Array(n);
     while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
     }
     let file = new File([uint8Array], fileName, { type: mime });
     return file;
}
 printer_pdf = async (url,fileExtend) => {
   var printer = await printPdf(url)
  if (printer) {
    if (url.indexOf(".pdf") == -1) {
      this.printFile('data:image/'+fileExtend+';base64,'+printer)
    }else{

        let byteCharacters = atob(printer);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], {type: 'application/pdf;base64'});
    let fileURL = URL.createObjectURL(file);
    const newWindow = window.open(fileURL);
    newWindow.print();
    //newWindow.close();
     }
  }
}

   download_file = async (url,fileName)=>{
   var printer = await printPdf(url)
   if (printer) {
    if (fileName.indexOf(".pdf") == -1) {
      var baseUrl = 'data:image/'+fileName+';base64,'+printer;
    }else{
      var baseUrl = 'data:application/'+fileName+';base64,'+printer;
  }

   var file = this.convertBase64ToFile(baseUrl, fileName);
   saveAs(file, fileName);
   
   }

  }
  render(){
  return (
    <>
      <VegetablesNavBar getParams={this.props.location.search}/>  
      <SimpleHeader name="Documentos" parentName="Documentos" section="fuits_vegetable" section_two="Documentos" section_table_form="null" />
      <Container className="mt--6" fluid>
      {this.setContent(this.state.set_content)}
      
{/*        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Vegetales y frutas frescas</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.fruit_vegetable==undefined?'Sin Archivo':this.state.data.fruit_vegetable.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.fruit_vegetable==undefined? null : String(this.state.data.fruit_vegetable.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.fruit_vegetable==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.fruit_vegetable.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.fruit_vegetable.archivo,String(this.state.data.fruit_vegetable.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.fruit_vegetable.archivo,String(this.state.data.fruit_vegetable.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>
        
        <Card style={{display:(this.state.data.production_create==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Producción: Datos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.production_create==undefined?'Sin Archivo':this.state.data.production_create.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.production_create==undefined? null : String(this.state.data.production_create.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.production_create==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.production_create.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.production_create.archivo,String(this.state.data.production_create.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.production_create.archivo,String(this.state.data.production_create.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

        <Card style={{display:(this.state.production_fertilizer.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Producción: Agroquímicos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.production_fertilizer.length==0 ? null :

        this.state.production_fertilizer.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
        <Card style={{display:(this.state.data.create_cosecha==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Cosecha: Datos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.create_cosecha==undefined?'Sin Archivo':this.state.data.create_cosecha.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.create_cosecha==undefined? null : String(this.state.data.create_cosecha.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.create_cosecha==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.create_cosecha.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.create_cosecha.archivo,String(this.state.data.create_cosecha.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.create_cosecha.archivo,String(this.state.data.create_cosecha.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>
        <Card style={{display:(this.state.transport_cosecha.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Cosecha: Transporte</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.transport_cosecha.length==0 ? null :

        this.state.transport_cosecha.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.transport_vehicule_cosecha.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Cosecha: Vehiculo</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.transport_vehicule_cosecha.length==0 ? null :

        this.state.transport_vehicule_cosecha.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
        <Card style={{display:(this.state.create_plant_product.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Planta: Producto</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     { 
       this.state.create_plant_product.length==0 ? null :

        this.state.create_plant_product.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.create_plant_sample.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Planta: Muestra Calidad</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.create_plant_sample.length==0 ? null :

        this.state.create_plant_sample.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                <Card style={{display:(this.state.create_plant_weight_product.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Producción: Peso del Producto</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.create_plant_weight_product.length==0 ? null :

        this.state.create_plant_weight_product.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>
                        <Card style={{display:(this.state.create_plant_treatment.length==0) ? ('none') : ('flex')}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Producción: Tratamiento</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
     {  this.state.create_plant_treatment.length==0 ? null :

        this.state.create_plant_treatment.map((value,index)=>{

return(
              <tr>
                <td>
                  <span className="text-muted">
                    {value==null?'Sin Archivo':value.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {value==null? null : String(value.archivo).split('/').pop()}
                  </span>
                </td>

                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+value.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+value.archivo,String(value.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+value.archivo,String(value.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>

              </tr>)
                })
                   }
            </tbody>
          </Table>
        </Card>

        <Card style={{display:(this.state.data.create_plant_information_empaque==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Planta: Empaque</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.create_plant_information_empaque==undefined?'Sin Archivo':this.state.data.create_plant_information_empaque.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.create_plant_information_empaque==undefined? null : String(this.state.data.create_plant_information_empaque.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.create_plant_information_empaque==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.create_plant_information_empaque.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.create_plant_information_empaque.archivo,String(this.state.data.create_plant_information_empaque.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.create_plant_information_empaque.archivo,String(this.state.data.create_plant_information_empaque.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>
        <Card style={{display:(this.state.data.create_plant_pre_cold==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Planta, camara de frio: Pre-enfriamiento</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.create_plant_pre_cold==undefined?'Sin Archivo':this.state.data.create_plant_pre_cold.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.create_plant_pre_cold==undefined? null : String(this.state.data.create_plant_pre_cold.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.create_plant_pre_cold==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.create_plant_pre_cold.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.create_plant_pre_cold.archivo,String(this.state.data.create_plant_pre_cold.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.create_plant_pre_cold.archivo,String(this.state.data.create_plant_pre_cold.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

                <Card style={{display:(this.state.data.create_plant_cold==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Planta, camara de frio: Enfriamiento</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.create_plant_cold==undefined?'Sin Archivo':this.state.data.create_plant_cold.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.create_plant_cold==undefined? null : String(this.state.data.create_plant_cold.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.create_plant_cold==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.create_plant_cold.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.create_plant_cold.archivo,String(this.state.data.create_plant_cold.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.create_plant_cold.archivo,String(this.state.data.create_plant_cold.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

<Card style={{display:(this.state.data.logistics_transport_shipment==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Logistica y Transporte: Embarque</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.logistics_transport_shipment==undefined?'Sin Archivo':this.state.data.logistics_transport_shipment.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.logistics_transport_shipment==undefined? null : String(this.state.data.logistics_transport_shipment.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.logistics_transport_shipment==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.logistics_transport_shipment.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.logistics_transport_shipment.archivo,String(this.state.data.logistics_transport_shipment.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.logistics_transport_shipment.archivo,String(this.state.data.logistics_transport_shipment.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

        <Card style={{display:(this.state.data.logistics_transport_transporte==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Logistica y Transporte: Embarque</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.logistics_transport_transporte==undefined?'Sin Archivo':this.state.data.logistics_transport_transporte.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.logistics_transport_transporte==undefined? null : String(this.state.data.logistics_transport_transporte.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.logistics_transport_transporte==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.logistics_transport_transporte.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.logistics_transport_transporte.archivo,String(this.state.data.logistics_transport_transporte.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.logistics_transport_transporte.archivo,String(this.state.data.logistics_transport_transporte.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

        <Card style={{display:(this.state.data.commercialization_market==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Comercialización: Mercado nacional</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.commercialization_market==undefined?'Sin Archivo':this.state.data.commercialization_market.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.commercialization_market==undefined? null : String(this.state.data.commercialization_market.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.commercialization_market==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.commercialization_market.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.commercialization_market.archivo,String(this.state.data.commercialization_market.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.commercialization_market.archivo,String(this.state.data.commercialization_market.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>

                <Card style={{display:(this.state.data.commercialization_export==undefined)?'none':'flex'}}>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Comercialización: Exportación</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Creación</th>
                <th>Documento</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted">
                    {this.state.data.commercialization_export==undefined?'Sin Archivo':this.state.data.commercialization_export.created_at}
                  </span>
                </td>
                <td>
                  <span className="text-muted">
                  {this.state.data.commercialization_export==undefined? null : String(this.state.data.commercialization_export.archivo).split('/').pop()}
                  </span>
                </td>

     { this.state.data.commercialization_export==undefined? null :
                <td className="text-center">
                      <a
                         target="_black"
                         className="font-weight-bold"
                         href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.data.commercialization_export.archivo}
                       >
                         Ver
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{download_file("/storage/file_documents/"+this.state.data.commercialization_export.archivo,String(this.state.data.commercialization_export.archivo).split('/').pop() )}}
                         >
                         Descargar
                       </a><br />
                       <a
                         className="font-weight-bold"
                         href="javascript:;"
                         onClick={()=>{printer_pdf("/storage/file_documents/"+this.state.data.commercialization_export.archivo,String(this.state.data.commercialization_export.archivo).split('.').pop())}}
                       >
                         Imprimir
                       </a>
                     </td>
                   }

              </tr>
            </tbody>
          </Table>
        </Card>*/}
      </Container>
    </>
  );
 }
}
Documents.propTypes = {
  name: PropTypes.string
};
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
      this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)

  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
if (dataPermission.type_traza=='M' || dataPermission.type_traza==null) {
  this.setState({get_permission:false})
  return;
}
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
                if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            if (dataPermission.documento==1) {
              this.setState({get_permission:true})
            }else{
              this.setState({get_permission:false})
            }
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }

render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Documents {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;

//export default Documents;
