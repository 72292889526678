import React, {createRef} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  NavLink,
  Row,
  Col,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import MeatsNavBar from "views/pages/components/MeatsNavBar.js";
import { NavLink as ComponentLink } from "react-router-dom";

import { save_meat, data_trazabilidad_meat,get_permission } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { stateData } from "network/DataState";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import config from "config";
import jQuery from "jquery";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";

export class StartMeats extends React.Component {

  state={
    mess:'',
    btnd:false,
    codigoAran: [],
    notificationAlertRef: React.createRef(),
    myVarTime:null,
    codigo_arancelario:'',
    counter:0,
    list_category:[],
    bool_category:false,
    userPermission:false,
    thisdropdown:false,
    dataStateList:[]
  }
  async componentDidMount(){
    this.initData()
  }
    initData = async ()=>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        if (jsondata._id=="8" || jsondata._id=="6" || jsondata._id=="20" || jsondata._id=="21" || jsondata._id=="19") {
          this.setState({userPermission:true})
        }
        this.state.myVarTime = setInterval(() => this.setDataCode(), 500);
        var gerParam = new URLSearchParams(this.props.location.search).get("code");
        var dataTrazabilidadvf = await data_trazabilidad_meat(jsondata._id,gerParam)
        if (dataTrazabilidadvf==null) {
          var date = new Date()
          var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
          this.setState({
              meat_id:'noind',
              nombre_trazabilidad:'',
              fecha:completeDate,
              nombre_producto:'',
              nombre_finca:'',
              tamano_finca:'',
              area:'',
              georeferencia:'',
              pais_origen:'',
              ciudad:'',
              provincia:'',
              especie:'0',
              otra_especie:'',
              category:'No',
              raza:'',
              proposito:'0',
              otro_uso:'',
              codigo_arancelario:'0',
              unidadsup:'0',
              code_country:'',
              informacion_adicional:'',
              file_document:undefined,
          })
        }else{
          this.setDataCampos(dataTrazabilidadvf)
        }
  }

    setDataCampos = (data)=>{
      if (data.provincia!=null) {
        var thisData = data.provincia.split('-')
        if (thisData.length > 1) {
         this.changeState(thisData[1])
        } 
      }
      this.changeEspecies(data.especie)

      this.setState({
          meat_id:data.id,
          nombre_trazabilidad:data.nombre_traza,
          fecha:data.fecha,
          nombre_producto:data.nombre_producto,
          nombre_finca:data.nombre_finca,
          tamano_finca:data.tamano_finca,
          area:data.area,
          georeferencia:data.georeferencia,
          pais_origen:data.pais_origen,
          ciudad:data.ciudad,
          provincia:data.provincia,
          especie:data.especie,
          otra_especie:data.otra_especie,
          category:data.categoria,
          raza:data.raza,
          proposito:data.proposito,
          otro_uso:data.otro_uso,
          codigo_arancelario:data.codigo_arancelario,
          unidadsup:data.unidad,
          informacion_adicional:data.informacion_adicional,
          file_document:data.archivo,
      })
    }
    changeState = async (data) => {
      // if (typeof data == 'string') {
      // }else{
      // var setDataStateOptionTwo = data.options[data.options.selectedIndex].id
      // var dataState = await stateData.filter((a,b) => a.alpha2Code==setDataStateOptionTwo )
      // this.setState({dataStateList:dataState[0].states})
      // }
      //var setDataStateOptionOne = String(data.replace('Estado','')).trim() 
      var dataState = await stateData.filter((a,b) => a.alpha2Code==data )
      this.setState({dataStateList:dataState[0].states})
      this.setState({code_country:data})


    };
  componentWillUnmount(){
              clearInterval(this.state.myVarTime);
              this.setState({codigoAran:[]})
  }
  setDataCode = ()=>{
      if (this.state.counter==1) {
        clearInterval(this.state.myVarTime);
        this.setState({codigoAran:CodigoAranM})
      }
      this.setState({counter:this.state.counter+1})
  }
    changeEspecies = (valor) => {
     this.setState({especie:valor})
     var array_list=[]
     var type=false
     if (valor=='Bovino/Vacuno') {
       array_list = ['Toros','Vacas','Novillos + de 3 años y Bueyes','Novillos 2 a 3 años','Novillos 1 a 2 años','Vaquillonas + de 2 años','Vaquillonas 1 a 2 años','Terneros/as']
       type = true
     }
     if (valor=='Ovinos') {
       array_list = ['Carneros','Ovejas','Capones','Borregas 2-4D','Corderas DL','Corderos DL','Corderos/as Mamones']
       type = true
     }
       this.setState({list_category:array_list,bool_category:type})
    }  
  render(){
  const saveInfo = async () =>{

    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({mess:'Enviando...'})
    if (this.state.nombre_finca=="") {
      this.setState({mess:'El nombre de la finca necesarios.'})
      this.setState({btnd:false})
    }else{
      //if (this.state.file_document==undefined) {
      //this.setState({mess:'Necesita subir un archivo.'})
      //this.setState({btnd:false})
      //}else{
        const var_meat = await save_meat(this.state,jsondata._id);

        if (var_meat.data=="exito") {
          this.setState({mess:'Se guardo exitosamente.'})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.setState({btnd:false})
          this.initData()
          //setTimeout(function(){window.location.reload()}, 3000);
        }
      //}
    }
  }
const geolocacion = () => {
      this.setState({loadlocali:'Cargando...'})
       if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(VerPosicion,noPermitir);
       }else{
         this.setState({loadlocali:null})
       }
    };
    function VerPosicion(posicion){
    var lat = posicion.coords.latitude;
    var lon = posicion.coords.longitude;
    var locapi = "https://api.opencagedata.com/geocode/v1/json?q="+lat+","+lon+"&key=6bd6d4c53f9c44c69c0dcd2caaf70b6f";
    fetch(locapi)
    .then(response => response.json()
        .then(data => {
            if (data) {
              setinfolocation(lat,lon,data)
            }

        })
        .catch(err => console.log(err.message)));
    }
        const setinfolocation = (lat,lon,data) => {
              this.setState({loadlocali:null})
              this.setState({pais_origen: String(data.results[0].components.country) })
              this.setState({ciudad:String(data.results[0].components.city)})
              this.changeState(data.results[0].components.country_code.toUpperCase())
              var dataClean = data.results[0].components.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('Estado','').trim()+'-'+data.results[0].components.country_code.toUpperCase()
              this.setState({provincia:dataClean})
              this.setState({georeferencia:lat+','+lon+'-'+data.results[0].formatted})
    }

    function noPermitir(argument) {
      this.setState({loadlocali:null})
    // $('.cargadores').hide();
    }

    const changeCountryState = async (value,data) => {
      this.changeState(data.options[data.options.selectedIndex].id)
      this.setState({pais_origen:value})
    };

  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  const listaArray =(a,b)=>{
  if ( a.Code < b.Code ){
    return -1;
  }
  if ( a.Code > b.Code ){
    return 1;
  }
  return 0;
    }
    const listCountryCode = CountrysData.sort(listaArray)
  return (
    <>
{/*   {(!this.state.userPermission)?(<><SimpleHeader name="Iniciar trazabilidad" parentName="Carnicos" />
            <Container className="mt--6" fluid>
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">Iniciar trazabilidad: Carnicos</h3>
                </CardHeader>
                <CardBody>
                  Acceso denegado. Contáctanos para más información.
                </CardBody>
              </Card>
            </Container>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>)
         :(*/}<><div className="rna-wrapper">
                          <NotificationAlert ref={this.state.notificationAlertRef} />
                        </div>
                        <MeatsNavBar getParams={this.props.location.search} />
                        <SimpleHeader name="Iniciar trazabilidad" parentName="Cárnicos" />
                        <Container className="mt--6" fluid>
                          <Card className="mb-4">
                            <CardHeader>
                        {
                          (this.props.location.search=='')
                          ?
                          null
                          :
                          (<NavLink href="#" onClick={()=>{window.location.href = "/admin/start-trace/vegetables-fruits"}}>
                                        <h5 className="mb-0"></h5>
                                        </NavLink>)
                        }
                              <h3 className="mb-0">Iniciar trazabilidad: cárnicos</h3>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example-date-input"
                                    >
                                      Fecha de Registro
                                    </label>
                                    <Input
                                      value={this.state.fecha}
                                      onChange={(e)=>{this.setState({fecha:e.target.value})}}
                                      defaultValue={this.state.fecha}
                                      id="example-date-input"
                                      type="date"
                                    />
                                  </FormGroup>
                                </Col>
            {/*                    <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Nombre del Producto
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({nombre_producto:e.target.value})}}
                                      value={this.state.nombre_producto}
                                      id="example3cols2Input"
                                      placeholder="Escribe un producto"
                                      type="text"
                                    />
                                  </FormGroup>
                                </Col>*/}
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Nombre alias de esta trazabilidad
                                    </label>
                                    <Input
                                    autoComplete='none'
                                      onChange={(e)=>{this.setState({nombre_trazabilidad:e.target.value})}}
                                      value={this.state.nombre_trazabilidad}
                                      id="example3cols2Input"
                                      placeholder="Escribe un nombre alias de esta trazabilidad"
                                      type="text"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Nombre de la finca
                                    </label>
                                    <Input
                                    autoComplete='none'
                                      onChange={(e)=>{this.setState({nombre_finca:e.target.value})}}
                                      value={this.state.nombre_finca}
                                      id="example3cols2Input"
                                      placeholder="Escribe un nombre de la finca"
                                      type="text"
                                    />
                                  </FormGroup>
                                </Col>
{/*                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Tamaño de la finca
                                    </label>
                                    <Input
                                    autoComplete='none'
                                      onChange={(e)=>{this.setState({tamano_finca:e.target.value})}}
                                      value={this.state.tamano_finca}
                                      id="example3cols2Input"
                                      placeholder="Escribe el tamaño de la finca"
                                      type="text"
                                    />
                                  </FormGroup>
                                </Col>*/}
                                <Col md="4">
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Tamaño de la finca
                                    </label>
                                 <InputGroup>
                                    <Input
                                      autoComplete='none'
                                        onChange={(e)=>{this.setState({tamano_finca:e.target.value})}}
                                        value={this.state.tamano_finca}
                                        id="example3cols2Input"
                                        placeholder="Escribe el tamaño de la finca"
                                        type="text"
                                      />
                                    <Input
                                      value={this.state.unidadsup}
                                      onChange={(e)=>{ this.setState({unidadsup:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="m2">m2</option>
                                      <option value="manzanas">Manzanas</option>
                                      <option value="Ha (Hectareas)">Ha (Hectareas)</option>
                                      <option value="Metros">Metros</option>
                                      <option value="Acre">Acre</option>
                                    </Input>
{/*                                    <ButtonDropdown
                                      toggle={() => { this.setState({thisdropdown:!this.state.thisdropdown}) }}
                                      isOpen={this.state.thisdropdown}
                                    >
                                      <DropdownToggle caret>
                                        Seleccione
                                      </DropdownToggle>
                                      <DropdownToggle>
                                        m<sup>2</sup>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          Header
                                        </DropdownItem>
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>*/}
                                  </InputGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                      >
                                        Georeferencia
                                      </label>
                                      <Row>
                                        <Col md="10">
                                        <Input
                                          value={this.state.georeferencia}
                                          id="example3cols2Input"
                                          className="ubicacionex"
                                          placeholder="Escribe la referencia"
                                          type="textarea"
                                          onChange={(e) => this.setState({georeferencia:e.target.value})}
                                        />
                                          {this.state.loadlocali}
                                        </Col>  
                                        <Col md="2">
                                        <a href="javascript:void(0)" style={{color: '#002a5c'}} onClick={()=> {geolocacion()}}>
                                          <span style={{fontSize: '35px'}} className="fa fa-map-marker"></span>
                                        </a>
                                        </Col>  
                                      </Row>
                                      </FormGroup>
                                  </Col> 
                                {/*<Col md="4">
                                                      <FormGroup>
                                                        <label
                                                          className="form-control-label"
                                                          htmlFor="example3cols2Input"
                                                        >
                                                          Área
                                                        </label>
                                                        <Input
                                                          onChange={(e)=>{this.setState({area:e.target.value})}}
                                                          value={this.state.fecha}
                                                          id="example3cols2Input"
                                                          placeholder="Escribe un área"
                                                          type="text"
                                                          autoComplete='none'
                                                        />
                                                      </FormGroup>
                                                    </Col>*/}
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      País de origen
                                    </label>
                                    <Input 
                                    value={this.state.pais_origen} 
                                    id="exampleFormControlSelect1" 
                                    type="select" 
                                    onChange={(e) => changeCountryState(e.target.value,e.target) }>
                                      <option value="0">Seleccione...</option>
                                      {  listCountryCode.map((value,index)=>{
                                         return <option id={value.Code} value={value.Name} key={index}>{value.Name}</option>
                                        })
                                      }
            
                                    </Input>
                                  </FormGroup>
                                </Col>

                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Provincia/Estado
                                    </label>
                                    <Input 
                                    value={this.state.provincia} 
                                    id="exampleFormControlSelect1" 
                                    type="select" 
                                    onChange={(e) => { this.setState({provincia:e.target.value}) } }>
                                      <option value="0">Seleccione...</option>
                                      {  this.state.dataStateList.map((value,index)=>{
                                         return <option value={value+'-'+this.state.code_country} key={index}>{value}</option>
                                        })
                                      }
            
                                    </Input>
                                  </FormGroup>
                                </Col>
{/*                                <Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Provincia/Estado
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({provincia:e.target.value})}}
                                      value={this.state.provincia}
                                      id="example3cols2Input"
                                      placeholder="Escribe una provincia"
                                      type="text"
                                      autoComplete='none'
                                    />
                                  </FormGroup>
                                </Col>*/}
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Ciudad
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({ciudad:e.target.value})}}
                                      value={this.state.ciudad}
                                      id="example3cols2Input"
                                      placeholder="Escribe una ciudad"
                                      type="text"
                                      autoComplete='none'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Especie
                                    </label>
                                    <Input
                                      value={this.state.especie}
                                      onChange={(e)=>{ this.changeEspecies(e.target.value) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="Ave">Ave</option>
                                      <option value="Bovino/Vacuno">Bovino/Vacuno</option>
                                      <option value="Ovinos">Ovinos</option>
                                      <option value="Porcino">Porcino</option>
                                      <option value="Caprino">Caprino</option>
                                      <option value="otro">Otro</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                {(this.state.bool_category)?(<Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Categoria
                                    </label>
                                    <Input
                                      value={this.state.category}
                                      onChange={(e)=>{ this.setState({category:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="No">Seleccione...</option>
                                      {this.state.list_category.map((val,ind)=>{
                                        return (<option value={val}>{val}</option>)
                                      })}
                                    </Input>
                                  </FormGroup>
                                </Col>):(null)}
                               <Col md="4" sm="6" style={{ display: (this.state.especie=='otro')?('block'):('none') }}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="example3cols2Input"
                                  >
                                    Especificar Otra Especie
                                  </label>
                                  <Input
                                    onChange={(e)=>{this.setState({otra_especie:e.target.value})}}
                                    value={this.state.otra_especie}
                                    id="example3cols2Input"
                                    placeholder="Escribe una especie"
                                    type="text"
                                    autoComplete='none'
                                  />
                                </FormGroup>
                               </Col>
                                <Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Raza
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({raza:e.target.value})}}
                                      value={this.state.raza}
                                      id="example3cols2Input"
                                      placeholder="Escribe una raza"
                                      type="text"
                                      autoComplete='none'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Propósito
                                    </label>
                                    <Input
                                      value={this.state.proposito}
                                      onChange={(e)=>{this.setState({proposito:e.target.value})}} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="Carne">Carne</option>
                                      <option value="Lechero">Lechero</option>
                                      <option value="Leche y carne">Leche y carne</option>
                                      <option value="Piel">Piel</option>
                                      <option value="otro">Otro</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col md="4" sm="6" style={{ display: (this.state.proposito=='otro')?('block'):('none') }}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Otro Proposito
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({otro_uso:e.target.value})}}
                                      value={this.state.otro_uso}
                                      id="example3cols2Input"
                                      placeholder="Escribe otro proposito"
                                      type="text"
                                      autoComplete='none'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect1"
                                    >
                                      Código arancelario
                                    </label>
                              { 
                    (this.state.codigo_arancelario=='')?
                    null
                    :
                  <Select 
                  defaultInputValue={(this.state.codigo_arancelario=='0')?(null):(this.state.codigo_arancelario)}
                  options={this.state.codigoAran} 
                  getOptionLabel={(option)=> option.Codigo+'-'+option.Titulo }
                  getOptionValue={(option)=> option.Codigo}
                  onChange={(e) => this.setState({codigo_arancelario:e})}
                   />
                  }
                                  </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlTextarea1"
                                    >
                                      Información adicional
                                    </label>
                                    <Input
                                      onChange={(e)=>{this.setState({informacion_adicional:e.target.value})}}
                                      value={this.state.informacion_adicional}
                                      id="exampleFormControlTextarea1"
                                      rows="1"
                                      type="textarea"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                  <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlTextarea1"
                                  >
                                    Documento/Imagen
                                  </label>
                                  <Form>
                                    <div className="custom-file">
                                      <input
                                        onChange={(e)=>{this.setState({file_document:e.target.files[0] })}}
                                        className="custom-file-input"
                                        id="customFileLang"
                                        lang="es"
                                        type="file"
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="customFileLang"
                                      >
                                        Elegir archivo
                                      </label>
                                    </div>
                                  </Form>
                                {(this.state.file_document==null) ? "Sin archivo": ((typeof this.state.file_document!='string')?(this.state.file_document.name):(<NavLink target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document}>{String(this.state.file_document).split('_').pop()}</NavLink>))}
                                </Col>              
                              </Row>
                              {this.state.mess ? (
                                <div className="text-muted font-italic">
                                  <small>
                                    <span className="text-red font-weight-700">
                                      {this.state.mess}
                                    </span>
                                  </small>
                                </div>
                              ) : null}
                              <Row>
                                <Col md="3">
                            <Button 
                            className="btn-icon" 
                            color="traza-blue" 
                            type="button"
                            disabled={this.state.btnd}
                            onClick={saveInfo}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-check-circle" />
                              </span>
                              <span className="btn-inner--text">Guardar</span>
                            </Button>
                                </Col>
                            </Row>
                            </CardBody>
                          </Card>
                        </Container>      
                        <br/><br/></>{/*)}*/}
    </>
  );
 }
}
class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    setinfoState:false
  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('T')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
    if (dataPermission.type_traza=='VF' || dataPermission.type_traza==null) {
      this.setState({get_permission:false})
      return;
    }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='trazability' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return
         }
        if (dataPermission.trazabilidad_m==1) {
          if (dataPermission.role==1) {
            this.setState({get_permission:true})
          }else{
              if (dataPermission.type=='admin') {
                this.setState({get_permission:true})
              }
            if (dataPermission.type=='options') {
            }
            if (dataPermission.type=='view') {
              this.setState({get_permission:false})
            }
          }
        }else{
          this.setState({get_permission:false})
        }
      }
render(){
  {this.setInfoInit()}
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <StartMeats {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
