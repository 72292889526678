import React,{useContext,useEffect} from "react"
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {UserContext} from "layouts/store";
import { NavLink as ComponentLink } from "react-router-dom";
import jQuery from 'jquery'
function CardsHeader({ name, parentName }) {
  const data_tp = useContext(UserContext).valuePanel
  useEffect(()=>{

  })
  const contentPutCard = () => {
    var content = []
    // #7ac142
    // #9d0e0d
    // #0998df
    if (data_tp!=undefined) {
    if (data_tp.length!=0) {
        Object.entries(data_tp[0]).map((val,indx)=>{
          var type_trz = null
          var type_color = null
          var type_icon = null
          var total_trz = 0
         if (val[0].indexOf("vf")!=-1) {
          type_trz = 'Frutas y Vegetales'
          type_color = '#7ac142'
          type_icon = 'fas fa-carrot'
          total_trz = val[1]
         }
         if (val[0].indexOf("m")!=-1) {
          type_trz = 'Carnicos'
          type_color = '#9d0e0d'
          type_icon = 'fas fa-drumstick-bite'
          total_trz = val[1]
         }
         if (val[0].indexOf("g")!=-1) {
          type_trz = 'Granos'
          type_color = '#0998df'
          type_icon = 'fas fa-seedling'
          total_trz = val[1]
         }

content[indx]= <><Col md="6" xl="4">
                 <Card className="card-stats">
                   <CardBody>
                     <Row>
                       <div className="col">
                         <CardTitle
                           tag="h5"
                           className="text-uppercase text-muted mb-0"
                         >
                           Total Trazabilidad {type_trz}
                         </CardTitle>
                         <span className="h2 font-weight-bold mb-0">
                           {total_trz}
                         </span>
                       </div>
                       <Col className="col-auto">
                         <div className="icon icon-shape text-white rounded-circle shadow" style={{backgroundColor:type_color}}>
                           <i className={type_icon} />
                         </div>
                       </Col>
                     </Row>

                   </CardBody>
                 </Card>
               </Col></>
        })
    }
  }
    return content
  }
  return (
    <>
      <div className="header">
        <Container fluid>
          <div className="header-body">
            <Row>
              {contentPutCard()}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
