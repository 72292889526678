import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { get_users_colab, get_permission, delete_user_colab, view_data_register } from "network/ApiAxios";
import ModalEditUserPermission from "./Modal/ModalEditUserPermission";
import ModalRegisterActivity from "./Modal/ModalRegisterActivity";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import NotificationAlert from "react-notification-alert";
import * as firedatabase from 'firebase/database';
import {db} from 'network/ConfigFirebase';
// const pagination = paginationFactory({
//   page: 1,
//   alwaysShowAllBtns: true,
//   showTotal: true,
//   withFirstAndLast: false,
//   sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
//     <div className="dataTables_length" id="datatable-basic_length">
//       <label>
//         Mostrar{" "}
//         {
//           <select
//             name="datatable-basic_length"
//             aria-controls="datatable-basic"
//             className="form-control form-control-sm"
//             onChange={(e) => onSizePerPageChange(e.target.value)}
//           >
//             <option value="10">10</option>
//             <option value="25">25</option>
//             <option value="50">50</option>
//             <option value="100">100</option>
//           </select>
//         }{" "}
//         entradas.
//       </label>
//     </div>
//   ),
// });

// const { SearchBar } = Search;

class ReactBSTables extends React.Component {
  // const [alert, setAlert] = React.useState(null);
  // const [users, setUsers] = React.useState([]);
  state = {
    setUsers:[],
    openModal:false,
    openModalRegister:false,
    openModalDelete:false,
    dataUser:null,
    notificationAlertRef: React.createRef(),
    dataUserActivity:[],
    dataActivitySession:[],
    emailDelete:null,
    idUserDelate:null,
    putContentInfo:null
  }
async componentDidMount() {
    this.getAllUsers();
}

  getAllUsers = async () => {
      var userdata = localStorage.getItem('user');
      var jsondata = JSON.parse(userdata);
      const response = await get_users_colab(jsondata._id);
      const data = response;

      data.forEach(el => {
        el.created_at = new Date(el.created_at).toLocaleString();

        if (el.type=='admin') {
          el.type_other = 'Admin';
        }
        if (el.type=='options') {
          el.type_other='Colaborador para editar solo secciones'
        }
        if (el.type=='view') {
          el.type_other='Colaborador para que vea el proceso solamente'
        }
      });
      this.setState({setUsers:data})
      // setUsers(data);
    }
getDataFireBase = async (data_register,idUser)=>{
        const productsCollection = firedatabase.ref(db,'user/'+idUser)
        var dataUserActivity = []
        var dataActivitySession = []
        const myPromise = new Promise((resolve, reject) => {
            firedatabase.onValue(productsCollection, (snapshot) => {
              if (snapshot.exists()) {
                var data = snapshot.val();
                var getData = Object.values(data)
                resolve({dataUserActivity:data_register?.dataService,allDataUser:data_register?.dataUser,dataActivitySession:getData.sort().reverse()})
                  // dataUserActivity = data_register?.dataService
                  // dataActivitySession = getData.sort().reverse()
              }else{
                resolve({dataUserActivity:data_register?.dataService,allDataUser:data_register?.dataUser,dataActivitySession:['Sin actividades']})
              }
          });
        });
      return await myPromise
}
closeSectionsContent=(typeC)=>{
  this.setState({dataUserActivity:[],dataActivitySession:[],putContentInfo:null})
}
render(){
  const viewActivitys = async (type,email,idUser) =>{
    this.setState({openModalRegister:type,dataUserActivity:[],dataActivitySession:[],putContentInfo:null})
    var data_register = await view_data_register(email,idUser)
    var forThis = this;
    var getInformation = await this.getDataFireBase(data_register,idUser)
      this.setState({putContentInfo: <ModalRegisterActivity 
        setDataUserRegister={getInformation.dataUserActivity} 
        returnResponseRegister={viewActivitys} 
        dataSession={getInformation.dataActivitySession}
        allDataUser={getInformation.allDataUser}
        closeSections={this.closeSectionsContent} /> })
  }
  const optionModal = (type,dataSelect) =>{
    this.setState({openModal:type,
                  dataUser:dataSelect
                  })
  }
  const deleteUser = async (motive) =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    if (this.state.idUserDelate==null) {
      return
    }
      if (window.confirm("Deseas eliminar este usuario?")) {
       var result = await delete_user_colab(jsondata._id,this.state.emailDelete,this.state.idUserDelate,motive)
       if (result=='exito') {
         this.getAllUsers();
         notify("success", "Cambios realizados exitosamente!", "Cuenta Eliminada exitosamente.");
       }else{
         notify("danger", "No se pudo Eliminar", "Vuelva a intentar para eliminar el usuario.");
       }
      }
  }


  const responseDelete = (type,email,idUserColab) =>{
    this.setState({openModalDelete:type,emailDelete:email,idUserDelate:idUserColab})
  }  
    const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <ModalEditUserPermission 
      sendOpenModal={this.state.openModal}
      setDataUser={this.state.dataUser}
      returnResponse={optionModal} 
      refreshData={this.getAllUsers} />

      <ModalDeleteUser
      sendOpenModalDelete={this.state.openModalDelete}
      returnResponseDelete={responseDelete}
      confirmDelete={deleteUser} />
      {(this.props.userCompany==undefined)?(<SimpleHeader name="Usuarios autorizados" parentName="Administración" section="user_aut" section_two="none" section_table_form="null" />):(null)}
      <Container className={(this.props.userCompany==undefined)?("mt--6"):(null)} fluid>
        <Row>
          <div className="col-md-4">
            <Card>
              <CardHeader style={{padding: '1px',backgroundColor: '#7ac142'}}>
                <h3 className="mb-0" style={{color:'white'}}>Usuarios autorizados</h3>
              </CardHeader>
              <div className="responsive">
<table className="align-items-center table-flush" style={{fontSize: '12px',textAlign: 'center',border: 'solid 1px',width: '100%'}}>
            <thead className="thead-light">
              <tr>
                <th style={{border: 'solid 1px'}}>Correo</th>
                <th style={{border: 'solid 1px'}}>Rol</th>
                <th style={{border: 'solid 1px'}}>Ingreso</th>
                <th style={{border: 'solid 1px'}}>Opciones</th>
              </tr>
            </thead>
            <tbody>
            {this.state.setUsers.map((value,index)=>{
              return(
                <tr>
                   <td style={{border: 'solid 1px'}}>
                    <span className="text-muted">
                     {value.email}
                    </span>
                   </td>
                   <td style={{border: 'solid 1px'}}>
                    <span className="text-muted">
                    <a className="font-weight-bold" title={value.type_other} href="javascript:;" onClick={()=>{optionModal(true,value)}} >
                    <i class="fas fa-edit" style={{color: 'black'}}></i>
                    </a>         
                    </span>
                   </td>
                   <td style={{border: 'solid 1px'}}>
                    <span className="text-muted">
                     {value.created_at}
                    </span>
                   </td>
                   <td style={{border: 'solid 1px'}}>
                    <span className="text-muted">
                    {(value.user_email==0)?(<a className="font-weight-bold" title="Eliminar Usuario" href="javascript:;" onClick={()=>{responseDelete(true,value.email,value.user_id)}} >
                                                                     <i class="fas fa-eraser" style={{color: 'black'}}></i>
                                                                    </a>):('Eliminado')
                  }        
                    </span>
                    <span className="text-muted">
                    <br />
                    {<a className="font-weight-bold" title="Ver actividades" href="javascript:;" onClick={()=>{
                      viewActivitys(true,value.email,value.user_id)
                      }} >
                                                                     <i class="fas fa-eye" style={{color: 'black'}}></i>
                                                                    </a>}
                    </span>                    
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </table>
          </div>
            </Card>
          </div>
          <div className="col-md-12">
            {this.state.putContentInfo}
          </div>
        </Row>
      </Container>
    </>
  );
 }
}
export class ModalDeleteUser extends React.Component {
  state={
    motive:'',
  }
render(){
  const setModal = (type) => {
    this.props.returnResponseDelete(type)
  }  
  const confirmDeleteInside = () =>{
    setModal(false)
    this.props.confirmDelete(this.state.motive)
  }
  return (
  <Modal isOpen={this.props.sendOpenModalDelete} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
            <Row>
            <Col md={11}>
            <h3 className="mb-0">Motivo de la eliminación</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setModal(false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>          
          </CardHeader>
          <CardBody>
            <textarea className="form-control" onChange={(e)=>{this.setState({motive:e.target.value})}}>
              {this.state.motive}
            </textarea>

            <br />
            <Row>
              <Col md="3">
                <Button onClick={()=>{confirmDeleteInside()}} className="btn-icon" color="traza-blue" type="button">
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-erase" />
                  </span>
                  <span className="btn-inner--text">Eliminar usuario</span>
                </Button>
              </Col>
          </Row>            
          </CardBody>
        </Card>
    </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setModal(false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>    
  );
 }
}
class SwiftComponent extends React.Component {
  state={
    get_permission:'load'
  }
 async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
        var gerParam = new URLSearchParams(this.props.location.search).get("company");
    var idcompany = null;
    // if (jsondata.userType==1) {
    //   var dataPermission = await get_permission(jsondata._id,null)
    // }
    if (jsondata.userType==2) {
      if (gerParam!=null) {
        idcompany = gerParam
      }
    }
    var dataPermission = await get_permission(jsondata._id,idcompany)
    if (dataPermission[0].role==1) {
      this.setState({get_permission:true})
    }else{
      this.setState({get_permission:false})
    }
  }
render(){
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <ReactBSTables {...this.props} />
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;