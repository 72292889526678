import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { stateData } from "network/DataState";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, {createRef} from "react";
import { create_production, data_trazabilidad, main_content,main_content_filter, data_fertilizer_production, add_transport_plant, fertilizer_production, row_transport_plant, delete_trans_vehicule, delete_fertilizer } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import { CodigoAranF } from "network/CodigoArancelario";
import { CodigoAranM } from "network/CodigoArancelarioAnimal";
import VegetablesNavBar from "views/pages/components/VegetablesNavBar.js";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SelectSearch from 'react-select-search';
import {useHistory} from "react-router-dom";
import config from "config";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import {UserContext} from "layouts/store";
import { Link } from "react-router-dom";
import "react-select-search/style.css";
import axios from 'axios';
import FormPlantProduct from "./Subs/FormPlant/FormPlantProduct";
import FormPlantSample from "./Subs/FormPlant/FormPlantSample";
import FormPlantTreatment from "./Subs/FormPlant/FormPlantTreatment";
import FormPlantWeight from "./Subs/FormPlant/FormPlantWeight";
import InfoPlantTransport from "./Subs/InfoPlantTransport/InfoPlantTransport";
import InfoPlantVehicule from "./Subs/InfoPlantTransport/InfoPlantVehicule";
import jQuery from 'jquery';

export default class ModalPlant extends React.Component {
  state={
    notificationAlertRef: React.createRef(),
    sendOpenModal:false,
    link_id:null,
    sendOpenModalTransport:false,
    dataTransporte:[],
    link_id_transport:null,
    return_breack:1,
    return_breack_p:1,
    return_breack_s:1,
    return_breack_w:1,
    return_breack_t:1,
    data_spread:[],
    data_cross_plant:[],
    delete_register:null,
    is_change:'no',
    get_content:[],
    get_first_info:'',
    userTypeH:1
  }
  async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var getData = await main_content('transport_cosecha')
    this.setState({field_content:getData,userTypeH:jsondata.userType})
  }
   async componentDidUpdate(prevProps, prevState, snapshot) {
    var get_data_plant = this.props.sendInfoProps.dataPlant
       if (get_data_plant.length!=0) {
        if (this.state.get_content.length==0) {
           var getDataPlant = await main_content_filter('create_plant_product',get_data_plant)
           getDataPlant.map((a,b)=>{
             if (a.include_info==1) {
               this.setState({get_first_info:a.name_field})
             }
           })
           this.setState({get_content:getDataPlant})
        }
       }
      }
  initData = async (id_set,verify)=>{
    this.setState({sendOpenModal:verify,link_id:id_set})
    this.setInfoTransport(id_set,true)
  }
deleteTransVehi = async (id) => {
   var userdata = localStorage.getItem('user');
   var jsondata = JSON.parse(userdata);
   var gerParam = new URLSearchParams(window.location.search).get("code");
   if (window.confirm("Deseas eliminar este registro?")) {
   var dataReturn = await delete_trans_vehicule(id,jsondata._id,gerParam,this.state.vehicule_id)
           if (dataReturn=="exito") {
           this.setState({mess:'Se eliminó exitosamente.'})
           this.notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
           //setTimeout(function(){window.location.reload()}, 3000);
           this.props.refreshData()
         }else{
           this.notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
           this.setState({btnd:false})
         }
   }
 }
  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  putIdGlobal = (inset_id) => {
    this.setState({link_id:inset_id})
    this.props.refreshData()
  }

    setInfoTransport = async (id_set,isOpen) => {
    var data_select = []
    if (this.state.field_content.length>0) {
      for (var i = 0; i < this.state.field_content.length; i++) {
        if (this.state.field_content[i].link!=null) {
          var get_csc=this.state.field_content[i].link.split('.')[1]
          if (get_csc=='vgp' || get_csc=='vge' || get_csc=='vgc') {
            data_select[i]={field:this.state.field_content[i].name_field}
          }else{
            data_select[i]={field:this.state.field_content[i].name_field,link:this.state.field_content[i].link}
          }
        }else{
          data_select[i]={field:this.state.field_content[i].name_field}
        }
      }
    }
       var userdata = localStorage.getItem('user');
       var jsondata = JSON.parse(userdata);
       var gerParam = new URLSearchParams(window.location.search).get("code");
       //var data = await data_fertilizer_production()
       var data = await row_transport_plant(id_set,'transport_cosecha',JSON.stringify(data_select),gerParam)
       // var dataCosecha = await data_cosecha(jsondata.user_id_origin,gerParam)
       // this.setState({peso_unidad:(dataCosecha==null)?(null):(dataCosecha.peso_unidad=="null")?(null):(dataCosecha.peso_unidad),})
       var plant_data = data.filter((data,index)=>{return data.verifyCheck==1})
       if (plant_data[0]!=undefined) {
                             setTimeout(function(){
                       data.map((value,index)=>{
                       const verifyCheck = value.verifyCheck && value.id_set==id_set;
                       if (document.getElementsByClassName("thisValueTransport")[index]!=undefined) {
                         document.getElementsByClassName("thisValueTransport")[index].checked=verifyCheck
                       }
                       })
                     }, 500);
        // if (id_set==null) {
        // }
         this.putValueTransport(plant_data[0].cosecha_id,true,'no')
       }
       this.setState({
         sendOpenModalTransport:isOpen,
         dataTransporte:data,
         plant_product_id:id_set
       })
     }
     putValueTransport = async (changeValue,checked,ich) =>{
      this.setState({is_change:ich})
      this.setState({idCosechaTransport:[[changeValue,checked]]})
      this.setState({link_id_transport:changeValue})
      this.changeBackClk('glo')
     }
    changeBackClk = (type,data,resp) =>{
      if (type=='glo') {
      this.setState({return_breack:0})
        this.setState({return_breack_p:0})
        this.setState({return_breack_s:0})
        this.setState({return_breack_t:0})
        this.setState({return_breack_w:0})
      }

      if (data!=undefined) {
        this.setState({data_spread:data})
      }
      if (resp=='ipt') {
        this.setState({return_breack:type})
        this.setState({return_breack_p:0})
        this.setState({return_breack_s:0})
        this.setState({return_breack_t:0})
        this.setState({return_breack_w:0})
      }
      if (resp=='fpp') {
        this.setState({return_breack_p:type})
      }
      if (resp=='fpt') {
        this.setState({return_breack_t:type})
      }
      if (resp=='fps') {
        this.setState({return_breack_s:type})
      }
      if (resp=='fpw') {
        this.setState({return_breack_w:type})
      }
     }
deleteRegister = async (id_set) => {
  jQuery('#id_register_prod').val(id_set)
  await this.initData(id_set,true)
   if (window.confirm("Deseas eliminar este registro?")) {
    this.setState({delete_register:'delete_register'})
  }else{
    this.initData(null,false)
  }
  jQuery('.modal').hide()
 }  
  deleteRegisterListe = async (id_set,user_id_origin,field_data,field_data_name,gerParam,field_name,label_name) => {
    var get_id_register_prod=jQuery('#id_register_prod').val()
    this.setState({delete_register:null})
    this.initData(null,false)
  }     
  render(){
    const insertTransport = async (plant_id) => {
       //this.setState({sendOpenModalTransport:false})

          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(window.location.search).get("code");
if (this.state.idCosechaTransport!=undefined) {
          var resultData = await add_transport_plant(this.state.idCosechaTransport.filter(Boolean),plant_id,jsondata._id,gerParam)
}
         
         //messageReturn(resultData,'mess1','Transporte Guardado')
    }
    const insertTransportData = async (getData) => {
      this.setState({data_cross_plant:getData})
    }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <input type='hidden' id='id_register_prod' value='0' />
    <Modal isOpen={this.state.sendOpenModal} size="xl">
        <ModalBody>
           <ModalTransport 
           isOpenTrans={this.state.sendOpenModalTransport} 
           putValueTransport={this.putValueTransport} 
           dataTransport={this.state.dataTransporte}
           idPlant={this.state.plant_product_id} 
           getDataTransport={insertTransport} 
           />
              <InfoPlantTransport return_breack={this.state.return_breack} changeBack={this.changeBackClk} field_content={this.state.field_content} link_id_transport={this.state.link_id_transport} putLinkId={this.state.link_id}/>
              <InfoPlantVehicule return_breack={this.state.return_breack} changeBack={this.changeBackClk} link_id_transport={this.state.link_id_transport} putLinkId={this.state.link_id}/>
              
               <hr />

        <FormPlantProduct notify={this.notify} get_content={this.state.get_content} is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  insertTransportAfter={insertTransport} {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_p={this.state.return_breack_p} putLinkId={this.state.link_id} getLinkId={this.putIdGlobal}/>
        <FormPlantSample notify={this.notify} is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_s={this.state.return_breack_s} putLinkId={this.state.link_id}/>
        <FormPlantTreatment notify={this.notify} is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_t={this.state.return_breack_t} putLinkId={this.state.link_id}/>
        <FormPlantWeight notify={this.notify} is_change={this.state.is_change} deleteRegisterListen={this.deleteRegisterListe} for_delete={this.state.delete_register}  {...this.props} changeBack={this.changeBackClk} data_spread={this.state.data_spread} return_breack_w={this.state.return_breack_w} putLinkId={this.state.link_id}/>
        
        
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.initData(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
             <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Ingresar datos de planta
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ this.initData(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
             {
              (this.props.sendInfoProps.dataPlant.length==0)?(null):
                   this.props.sendInfoProps.dataPlant?.create_plant_product.map((value,index)=>{
                             return (
                                      <Col md="4" className="text-center">
                                      <Card md="12" className="pb-3">
                                        <Col md="12">
                                         <Row>
                                          <Col md="6">
                                            <label
                                              className="form-control-label"
                                              htmlFor="exampleFormControlSelect1"
                                            >
                                             {Object.entries(value).map((a,b)=>{
                                              if (a[0]==this.state.get_first_info) {
                                                return a[1]
                                              }
                                             })}
                                            </label>
                                          </Col>
                                           <Col md="6">
                                            <label
                                              className="form-control-label"
                                              htmlFor="exampleFormControlSelect1"
                                            >
                                              {value.created_at}
                                            </label>
                                          </Col>
                                        </Row>
                                        </Col>

                                       <Col md="12">
                                          <Row>
                                          <Col md="4">
                                          <Button 
                                            className="btn-icon" 
                                            type="button"
                                            onClick={() => this.initData(value.id,true) }
                                            >
                                              <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-edit" />
                                              </span>
                                            </Button>
                                          </Col>
                         
 {/*                                        <Col md="4">
                                          <Button 
                                            className="btn-icon" 
                                            type="button"
                                            onClick={() => setInfoTransport(value.id,true) }
                                            >
                                              <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-truck" />
                                              </span>
                                            </Button>
                                          </Col>*/}
                                          {(this.state.userTypeH==1)?(
                                              <Col md="4">
                                              <Button 
                                                className="btn-icon" 
                                                type="button"
                                                onClick={() => this.deleteRegister(value.id)}>
                                                  <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-trash" />
                                                  </span>
                                                </Button>
                                              </Col>
                                            ):(null)}
                                        
                                          </Row>
                                        </Col>
                                     </Card>
                                      </Col>
                                     ) 
                      })
            }
            </Row>
         </CardBody>
    </>
  );
 }
}
export function ModalTransport(props){
  //render(){
  /*const insertTransportData = async () =>{
    var getValue = [];
    var documentTag = document.querySelectorAll(".thisValueTransport");
    for (var i = 0; i < documentTag.length; i++) {
        getValue[i] = [documentTag[i].value,documentTag[i].checked]
      }
    props.getDataTransport(getValue.filter(Boolean))  
  }*/
  const checkedSet = async (type,index)=>{
   var checkvery = document.getElementsByClassName("thisValueTransport")[index].checked
    document.getElementsByClassName("thisValueTransport")[index].checked = checkvery
     props.putValueTransport(type.target.value,type.target.checked,'si')
  }
  /*useEffect(()=>{
                    setTimeout(function(){
                      props.dataTransport.map((value,index)=>{
                      const verifyCheck = value.verifyCheck && value.id_plant==props.idPlant;
                      if (document.getElementsByClassName("thisValueTransport")[index]!=undefined) {
                        document.getElementsByClassName("thisValueTransport")[index].checked=verifyCheck
                      }
                      })
                    }, 500);
  });*/
  return (

      <Col md="12">
        <Row>
          {
            props.dataTransport.map((value,index)=>{
              return (
                <>
                  <Col md="3">
                    <span className="btn-inner--text thisValueTransportwhis"><Input type="radio" className="thisValueTransport" name="thisValueTransport" onChange={(e)=>{checkedSet(e,index)}} value={value.cosecha_id} />{(value.numero_de_guia==null) ? ("Sin número de guía") : ((value.numero_de_guia=="null") ? ("Sin número de guía") :('Guía: '+value.numero_de_guia+' - Finca: '+value.nombre_de_la_finca+'\n'+value.fecha_salida+'-'+value.hora_salida) ) }</span>
                  </Col>
                </>
                )
            })
          }
          </Row>
        </Col>

    )
  //}
 }
