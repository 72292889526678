import {node} from './blockchain/IPFS/config';
import buffer from 'buffer';
import {ABI_NFT} from './blockchain/ABI/ABI_NFT_foodchain';
import Web3 from 'web3';
import jQuery from 'jquery';
import { saveInfoBlockchainMeats, generate_trazabilidad_meat } from "network/ApiAxios";

//import MetaMaskOnboarding from '@metamask/onboarding';
//import { ethers } from 'ethers';
export async function generateChainMeats(iduser,code,redirect,idTraza,typeUp,walletAddress){
var component = document.querySelector(".contentBlockChain").innerHTML
  ipfsRequest (buffer.Buffer.from(String(component+= '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous">'), 'utf-8')).then((data) => {
    let uri = (data.path);
    var resul = conexionWallet(uri,iduser,code,redirect,idTraza,typeUp,walletAddress);
    return resul
  }, function (reason) {
    return 'volverintentar'
  })
  //purchaseService();
}

export async function ipfsRequest (data) {
const projectId = '2Q9QL4YlpwehliW1NOyiII4XX15';
const projectSecret = 'f053e5dec9a91cf0a48906e52ecd1f35';
const auth = 'Basic ' + buffer.Buffer.from(projectId + ':' + projectSecret).toString('base64');
  var ipfs = window.IpfsHttpClient.create({
    host: 'ipfs.infura.io',
    port: '5001',
    protocol: 'https',
    headers: {
          authorization: auth,
          },
  })
      var cid =  await ipfs.add(data)
      return cid
 //  var ipfs = window.IpfsHttpClient(node[node.default].address, node[node.default].port, {protocol: node[node.default].protocol}) //router to the IPFS network without any local node
 //  var file_send =
 //  [
 //   {
 //     path: file_name,
 //     content: data
 //   }
 // ]
 //  return new Promise((resolve, reject) => {
 //      ipfs.add(file_send, function (err, json) {
 //        if (err) {
 //          alert(err);
 //          reject (0)
 //        } else {
 //          resolve (json)
 //        }
 //      })
 //  })
}

var provider = window.ethereum
var selectedAccount;
var url_nft;
export async function conexionWallet(URI_IPSF,iduser,trazabilidad_code,redirect,idTraza,typeUp,walletAddress){
    //eth_requestAccounts
    //wallet_addEthereumChain


  // if (typeof provider != 'undefined') {
  //   const confirma = await provider.request({method: 'eth_chainId'})
  //   console.log(confirma)
  //  if (confirma=='0x38') {
  //     provider.request({method: 'eth_requestAccounts'}).then((result) => {
  //                   console.log(result)
  //                 selectedAccount= result[0]
  //                }).catch((error) => {
  //                 console.log(error)
  //                 //return 'Vuelva a Intentar'
  //               });
  //                window.ethereum.on('accountsChanged',function (accounts){
  //                 console.log(accounts)
  //                 selectedAccount= accounts[0]
  //                })
  //   }else{
  //       var paramsBnb =   [{
  //                           chainId:'0x38',
  //                           chainName:'BINANCE SMART',
  //                           nativeCurrency: {
  //                             name:'Binance Coin',
  //                             symbol:'BNB',
  //                             decimals:18
  //                           },
  //                           //rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  //                           rpcUrls: ['https://bsc-dataseed.binance.org/'],
  //                           //blockExplorerUrls: ['https://testnet.bscscan.com/'],
  //                           blockExplorerUrls: ['https://bscscan.com'],
  //                             }];
                              
  //              var retuender = await provider.request({method: 'wallet_addEthereumChain', params:paramsBnb})
  //              if (retuender==null) {conexionWallet(URI_IPSF)}else{console.log('error')}
  //       }
  //   }else{
  //     alert("Por favor es necesario tener instalado metamask.")
  //     jQuery("#loadingupload").hide();
  //     jQuery(".btnend").show()
  //   }
 
    try{
        //console.log(provider)
        //purchaseService(selectedAccount,provider)
        //purchaseService(web3,selectedAccount,URI_IPSF)
    //var provideUrl = process.env.PROVIDER_URL || 'http://localhost:8545'
        //purchaseService(URI_IPSF,selectedAccount)
    //var provider = window.ethereum
    //var meAccount = web3.eth
    //console.log(meAccount)
    //var price1 = await nftTokenSet.methods.balanceOf(selectedAccount).call()
    //console.log(meAccount)
      var web3 = new Web3('https://bsc-dataseed.binance.org/')
      const nonce = await web3.eth.getTransactionCount(walletAddress.address, 'latest'); //get latest nonce
      const nftToken = "0xD805c1FBc75A1D152ee6385285B79b3d48e61Dd2";
      var nftTokenSet = new web3.eth.Contract(ABI_NFT,nftToken)
      var URI = 'https://ipfs.io/ipfs/'+URI_IPSF;
      var uriString = (URI);
      var gasPrice = await web3.eth.getGasPrice()
      var minValue = web3.utils.toWei('0.0001', 'ether')
      var getBalance = await web3.eth.getBalance(walletAddress.address);
       const tx = {
         'from': walletAddress.address,
         'to': nftToken,
         'nonce': nonce,
         'value': minValue,
         'gas':300000,
         'data': nftTokenSet.methods.mintTokenNFT(uriString).encodeABI()
       };
         const signPromise = web3.eth.accounts.signTransaction(tx, walletAddress.privateKey)
         signPromise.then((signedTx) => {
            web3.eth.sendSignedTransaction(signedTx.rawTransaction)
            .once('sending', function(payload){ console.log('Sending : '+payload); })
            .once('sent', function(payload){ console.log('Sent : '+payload) })
            .once('transactionHash', function(hash){ console.log(hash) })
            .once('receipt', function(result){ console.log(result) })
            .on('confirmation', function(result){ console.log(result) })
            .on('error', function(result){ console.log(result)
                                           jQuery("#loadingupload").hide();
                                           jQuery(".btnend").show()
                                          })
            .then(function(result){ console.log(result)
            saveInfoDb(result.blockHash,result.from,result.transactionHash,iduser,URI,trazabilidad_code,redirect,idTraza,typeUp)
            });
         }).catch((err) => {
               jQuery(".loadingupload"+idTraza).hide()
               jQuery(".saveBtnAnimalList"+idTraza).show()
           console.log("Promise failed:", err);
         });
  //   const nftToken = "0xD805c1FBc75A1D152ee6385285B79b3d48e61Dd2";
  //   var web3 = new Web3(provider)
  //   var WalletWeb3 = await web3.eth.net.getId()
  //   var nftTokenSet = new web3.eth.Contract(ABI_NFT,nftToken)
  //   var URI = 'https://ipfs.io/ipfs/'+URI_IPSF;
  //   var uriString = (URI);
  //   if (selectedAccount==undefined) {
  //     jQuery("#loadingupload").hide();
  //     jQuery(".btnend").show()
  //     return;
  //   }
		// nftTokenSet.methods.mintTokenNFT(uriString)
		// .send({from: selectedAccount , value: 0})
	 //  .once('sending', function(payload){ console.log('Sending : '+payload); })
		// .once('sent', function(payload){ console.log('Sent : '+payload) })
		// .once('transactionHash', function(hash){ 
		// console.log(hash)
  //   })
  //   .once('receipt', function(result){
  //     console.log(result)
  //   })
  //   .on('confirmation', function(result){
  //     console.log(result)
  //   })
  //   .on('error', function(result){
  //     console.log(result)
  //     jQuery("#loadingupload").hide();
  //     jQuery(".btnend").show()
  //   })
  //   .then(function(result){
  //     saveInfoDb(result.blockHash,result.from,result.transactionHash,iduser,URI,trazabilidad_code,redirect,idTraza,typeUp)
  //   })

    }catch(e){
      console.log(e)
    return 'Vuelva a Intentar'
    }
}

export async function saveInfoDb(blockHash,from,transactionHash,iduser,ipfsdata,trazabilidad_code,redirect,idTraza,typeUp){
  var result = await saveInfoBlockchainMeats(blockHash,from,transactionHash,iduser,ipfsdata,trazabilidad_code)
  if (result=='exito') {
    if (typeUp!='uploadblock') {
      await generate_trazabilidad_meat(iduser,idTraza,trazabilidad_code);
    }
    jQuery("#loadingupload").show()
    jQuery(".btnend").hide()
    jQuery("#loadingupload").html('<div style="color: green;font-weight: 400;font-size: 20px;">¡¡Guardado Exitosamente!! </div><span>Se redirigirá en 3 segundos</span>')
    setTimeout(function(){redirect.push('/admin/vista-blockchain')}, 4000);
  }
}