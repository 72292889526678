import { Container, Row, Col } from "reactstrap";
import {GuideMessage} from "views/pages/components/GuideMessage";

function ProfileHeader(props) {
  const user = JSON.parse(localStorage.getItem("user")).email.split("@")[0];

  return (
    <>
      <div
        className="header pb-8 d-flex align-items-center"
        style={{
          minHeight: "335px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-traza-green opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-3 text-white">¡HOLA, {user.toUpperCase()}!</h1>
              <p className="text-white mt-0 mb-5">
                Esta es la página de tu perfil. Aquí puedes revisar y editar tu información personal.
                {(props.check_guide)?(
              <Col md={3}>
                <GuideMessage section="profile" section_two="none" section_table_form="null" />
              </Col>
                  ):(null)}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
