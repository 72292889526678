import React, {createRef} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  InputGroup
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {create_slaugh_freezing, create_slaugh_explode, create_slaugh_packing, data_slaught_explode, data_slaught_packing, data_slaught_freezing, deleteExplodeFunct, deletePackingFunct, deleteFreezingFunct, data_trazabilidad_meat, row_traza_lote } from "network/ApiAxios";
import NotificationAlert from "react-notification-alert";
import config from "config";

 class ModalSlaughterhouse extends React.Component {
      state={
    verifyModal:false,
    btnd:false,
    notificationAlertRef: React.createRef(),
    sendOpenModalExplode:false,
    sendOpenModalPacking:false,
    sendOpenModalFreezing:false,
    data_lote_explode:[],
    data_lote_packing:[],
    data_lote_freezing:[],
    dsableBtn:false,
    dsableBtnTrans:false,
    dsableBtnTask:false,
    data_trnsport:[],
    displayOne:'none',
    displayTwo:'none',
    displayTree:'none',
    urlLoteCode:'0',
    urlParams:'0'

  }
    async componentDidMount(){
        
  }
          setDataCamposExplode = (data,dataLote,getParamLote)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];
                 if (data!=null) {
                  this.setState({
                          data_lote_explode:dataLote.trazaDataLote,
                          explode_id:(data.id=="null")?(null):(data.id),
                          lote_numero_explode:(data.lote_numero=="null")?(null):(data.lote_numero),
                          fecha_despiece:(data.fecha_despiece=="null")?(completeDate):(data.fecha_despiece),
                          hora_inicio:(data.hora_inicio=="null")?(timeString):(data.hora_inicio),
                          hora_final:(data.hora_final=="null")?(timeString):(data.hora_final),
                          nro_canales:( data.nro_canales=="null")?(null):( data.nro_canales),
                          nro_medias_canales:(data.nro_medias_canales=="null")?(null):(data.nro_medias_canales),
                          nro_cuartos_canales:(data.nro_cuartos_canales=="null")?(null):(data.nro_cuartos_canales),
                          carcasa_entera:(data.carcasa_entera=="null")?(null):(data.carcasa_entera),
                          pierna:(data.pierna=="null")?(null):(data.pierna),
                          paleta:(data.paleta=="null")?(null):(data.paleta),
                          costillar:(data.costillar=="null")?(null):(data.costillar),
                          otro_corte:(data.otro_corte=="null")?(null):(data.otro_corte),
                          cantidad:(data.cantidad=="null")?(null):(data.cantidad),
                          informacion_adicional_explode:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                          file_document_explode:(data.archivo=="null")?(null):(data.archivo),
                  })
                }else{
                        this.setState({
                          data_lote_explode:dataLote.trazaDataLote,
                          explode_id:'noind',
                          lote_numero_explode:'',
                          fecha_despiece:completeDate,
                          hora_inicio:timeString,
                          hora_final:timeString,
                          nro_canales:'',
                          nro_medias_canales:'',
                          nro_cuartos_canales:'',
                          carcasa_entera:'',
                          pierna:'',
                          paleta:'',
                          costillar:'',
                          otro_corte:'',
                          cantidad:'',
                          informacion_adicional_explode:'',
                          file_document_explode:undefined,
                      })
                        this.onChangeLoteValueExplode(getParamLote)
                }
          }
          setDataCamposPacking = (data,dataLote,getParamLote)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

      if (data!=null) {
        this.setState({
                data_lote_packing:dataLote.trazaDataLote,
                packing_id:(data.id=="null")?(null):(data.id),
                lote_numero_packing:(data.lote_numero=="null")?(null):(data.lote_numero),
                fecha_packing:(data.fecha=="null")?(completeDate):(data.fecha),
                corte:(data.producto=="null")?(null):(data.producto),
                piezas_caja:(data.piezas_caja=="null")?(null):(data.piezas_caja),
                cantidad_cajas:(data.cantidad_cajas=="null")?(null):(data.cantidad_cajas),
                informacion_adicional_packing:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                file_document_packing:(data.archivo=="null")?(null):(data.archivo),
        })
      }else{
            this.setState({
                data_lote_packing:dataLote.trazaDataLote,
                packing_id:'noind',
                lote_numero_packing:'0',
                fecha_packing:completeDate,
                corte:'',
                piezas_caja:'',
                cantidad_cajas:'',
                informacion_adicional_packing:'',
                file_document_packing:undefined,
            })
            this.onChangeLoteValuePacking(getParamLote)
      }
    }
          setDataCamposFreezing = (data,dataLote,getParamLote)=>{
              var date = new Date();
              var completeDate = date.toLocaleDateString("en-CA",{timeZone:'America/Panama'})
              var timeString = String(date).split(":")[0].split(" ")[4]+':'+String(date).split(":")[1];

            if (data!=undefined) {
        this.setState({
                data_lote_freezing:dataLote.trazaDataLote,
                freezing_id:(data.id=="null")?(null):(data.id),
                lote_numero_freezing:(data.lote_numero=="null")?(null):(data.lote_numero),
                cantidad_cajas_producto:(data.cantidad_producto=="null")?(null):(data.cantidad_producto),
                cantidad_paleta:(data.cantidad_paleta=="null")?(null):(data.cantidad_paleta),
                corte_freezing:(data.producto=="null")?(null):(data.producto),
                fecha_ingreso:(data.fecha_ingreso=="null")?(completeDate):(data.fecha_ingreso),
                hora_ingreso:(data.hora_ingreso=="null")?(null):(data.hora_ingreso),
                aplico_preenfriamiento:(data.aplico_preenfriamiento=="null")?(null):(data.aplico_preenfriamiento),
                temperatura_inicial:(data.temperatura_inicial=="null")?(null):(data.temperatura_inicial),
                temperatura_final:(data.temperatura_final=="null")?(null):(data.temperatura_final),
                unidad_temperatura_inicial:(data.unidad_temperatura_inicial=="null")?('°C'):(data.unidad_temperatura_inicial),
                unidad_temperatura_final:(data.unidad_temperatura_final=="null")?('°C'):(data.unidad_temperatura_final),
                remanente:(data.remanente=="null")?(null):(data.remanente),
                informacion_adicional_freezing:(data.informacion_adicional=="null")?(null):(data.informacion_adicional),
                file_document_freezing:(data.archivo=="null")?(null):(data.archivo),
        })
      }else{
            this.setState({
                data_lote_freezing:dataLote.trazaDataLote,
                freezing_id:'noind',
                lote_numero_freezing:'',
                corte_freezing:'',
                cantidad_cajas_producto:'',
                cantidad_paleta:'',
                fecha_ingreso:completeDate,
                hora_ingreso:timeString,
                aplico_preenfriamiento:'',
                temperatura_inicial:'',
                temperatura_final:'',
                unidad_temperatura_inicial:'°C',
                unidad_temperatura_final:'°C',
                informacion_adicional_freezing:'',
                file_document_freezing:undefined,
            })
            this.setState({remanente:0})
            this.onChangeLoteValuefreezing(getParamLote)
      }


    }
onChangeLoteValueExplode = async (id) => {
  this.setState({lote_numero_explode:id})
  if (id=="0") {
    this.setState({
      displayTree:'none'
    })
  }else{
    this.setState({
      displayTree:'block'
    })
  }
}
onChangeLoteValuePacking = async (id) => {
  this.setState({lote_numero_packing:id})
  if (id=="0") {
    this.setState({
      displayTwo:'none'
    })
  }else{
    this.setState({
      displayTwo:'block'
    })
  }
}
onChangeLoteValuefreezing = async (id) => {
  this.setState({lote_numero_freezing:id})
  if (id=="0") {
    this.setState({
      displayOne:'none'
    })
  }else{
    this.setState({
      displayOne:'block'
    })
  }
}
  render(){
  const saveInfoExplode = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messex:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    

      if (this.state.nro_canales=="") {
      this.setState({messex:'El Nro de Canales es necesario.'})
      this.setState({btnd:false})
      }else{
        const var_request_explode = await create_slaugh_explode(this.state,jsondata._id,gerParam);
        if (var_request_explode.message=="exito") {
          this.setState({messex:'Se guardo exitosamente.'})
          this.setState({messex:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.setState({btnd:false})
            this.props.refreshData()
            this.setState({explode_id:var_request_explode.explode_id})
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_explode.message);
          this.setState({btnd:false})
        }
      }
  }
   const saveInfoPacking = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messp:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
      if (this.state.lote_numero_packing=="0") {
      this.setState({messp:'El Lote Numero es necesario.'})
      this.setState({btnd:false})
      }else{
        const var_request_packing = await create_slaugh_packing(this.state,jsondata._id,gerParam);
        if (var_request_packing.message=="exito") {
          this.setState({messp:'Se guardo exitosamente.'})
          this.setState({messp:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          this.setState({btnd:false})
            this.props.refreshData()
            this.setState({packing_id:var_request_packing.packing_id})
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_packing.message);
          this.setState({btnd:false})
        }
      }
  }
  const saveInfoFreezing = async () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    this.setState({btnd:true})
    this.setState({messf:'Enviando...'})
    var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    

      if (this.state.temperatura_inicial=="") {
      this.setState({messf:'La Temperatura Inicial es necesario.'})
      this.setState({btnd:false})
      }else{
        //const var_request_explode = await create_slaugh_explode(this.state,jsondata._id,gerParam);
        //const var_request_packing = await create_slaugh_packing(this.state,jsondata._id,var_request_explode.fridge_id,gerParam);
        const var_request_freezing = await create_slaugh_freezing(this.state,jsondata._id,gerParam)
        if (var_request_freezing.message=="exito") {
          this.setState({messf:'Se guardo exitosamente.'})
          this.setState({messf:''})
          notify("success", "¡Registrado exitosamente!", 'Se guardo exitosamente.');
          /*if (this.props.setParamsUrl==null) {
            setTimeout(function(){window.location.reload()}, 3000);
          }*/
          this.setState({btnd:false})
            this.props.refreshData()
            //this.setState({explode_id:var_request_explode.explode_id})
            //this.setState({packing_id:var_request_packing.packing_id})
            this.setState({freezing_id:var_request_freezing.freezing_id})
            //setInfoSlaughterhouseExplode(this.props.sendInfoProps.dataTransportAll,true)
        }else{
          notify("danger", "¡Registrado Fallido!", var_request_freezing.message);
          this.setState({btnd:false})
        }
      }
  }
       const setInfoSlaughterhouseExplode = async (id_set,verify) =>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          this.setState({urlParams:gerParam})
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
              var dataExplode = await data_slaught_explode(id_set)
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
            this.setDataCamposExplode(dataExplode,dataLote,getParamLote)
          this.setState({
        sendOpenModalExplode:verify,
      })
  }
  const setInfoSlaughterhousePacking= async (id_set,verify) =>{ 
              var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          this.setState({urlParams:gerParam})
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
              var dataTransExportLogisc = await data_slaught_packing(id_set)
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
            this.setDataCamposPacking(dataTransExportLogisc,dataLote,getParamLote)
          this.setState({
        sendOpenModalPacking:verify,
      })
  }
  const setInfoSlaughterhouseFreezing = async (id_set,verify) =>{
              var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
          this.setState({urlParams:gerParam})
          var getParamLote = new URLSearchParams(this.props.setParamsUrl).get("lote");
          this.setState({urlLoteCode:getParamLote})
              var dataTransExportLogisc = await data_slaught_freezing(id_set)
              var dataLote = await row_traza_lote(jsondata._id,gerParam)
            this.setDataCamposFreezing(dataTransExportLogisc,dataLote,getParamLote)
          this.setState({
        sendOpenModalFreezing:verify,
      })
  }
const deleteExplode = async (id) => {
    var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteExplodeFunct(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
const deletePacking = async (id) => {
    var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deletePackingFunct(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}
const deleteFreezing = async (id) => {
    var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var gerParam = new URLSearchParams(this.props.setParamsUrl).get("code");
    if (window.confirm("Deseas eliminar este registro?")) {
    var dataReturn = await deleteFreezingFunct(id,jsondata._id,gerParam)
          if (dataReturn=="exito") {
            notify("success", "Eliminado exitosamente!", 'Se eliminó exitosamente.');
            this.props.refreshData()
          }else{
            notify("danger", "¡Registrado Fallido!", '!!No se pudo eliminar.!!');
            this.setState({btnd:false})
          }
  }
}

const changeCutFreezing = (value,type) => {

  var getInfoBox = this.props.sendInfoProps.dataSlaughterhousePacking
  var getInfoBoxFreezing = this.props.sendInfoProps.dataSlaughterhouseFreezing
  if (type==null) {
      var getInfoBoxFilter = getInfoBox.filter((a,b)=> a.producto==value )
      if (getInfoBoxFilter.length>0) {
        var getInfoBoxFilterFreezing = getInfoBoxFreezing.filter((a,b)=> a.producto==value )
        if (getInfoBoxFilterFreezing.length>0) {
          this.setState({cantidad_cajas_producto:getInfoBoxFilterFreezing[getInfoBoxFilterFreezing.length-1].remanente})
        }else{
          this.setState({cantidad_cajas_producto:getInfoBoxFilter[0].cantidad_cajas})
        }
          var mathEcu = getInfoBoxFilter[0].cantidad_cajas/40;
          var mathEcuRed = Math.ceil(mathEcu)
          this.setState({cantidad_paleta:mathEcuRed})
      }else{
        this.setState({cantidad_paleta:0})
        this.setState({cantidad_cajas_producto:0})
      }
        this.setState({remanente:0})

      this.setState({corte_freezing:value})
  }else{
        var getInfoBoxFilter = getInfoBox.filter((a,b)=> a.producto==type )
      if (getInfoBoxFilter.length>0) {
        if (parseInt(value)>parseInt(getInfoBoxFilter[0].cantidad_cajas) || parseInt(value)<0) {
          alert('La cantidad de cajas no puede ser mayor o menor al establecido en el Empaque.')
        }else{

          var getInfoBoxFilterFreezing = getInfoBoxFreezing.filter((a,b)=> a.producto==type)
            if (getInfoBoxFilterFreezing.length>0) {
              if (this.state.freezing_id=='noind') {
                if (parseInt(value)>parseInt(getInfoBoxFilterFreezing[getInfoBoxFilterFreezing.length-1].remanente)) {
                  alert('La cantidad de cajas no puede ser mayor a lo disponible.')
                  return
                }
              }else{
                var getInfoBoxFilterFreezingTwo = getInfoBoxFreezing.filter((a,b)=> a.id == this.state.freezing_id)
                    if ( parseInt(value)>parseInt(parseInt(getInfoBoxFilterFreezingTwo[0].remanente)+parseInt(getInfoBoxFilterFreezingTwo[0].cantidad_producto)) ) {
                      alert('La cantidad de cajas no puede ser mayor a lo disponible.')
                      return
                  }
              }
            }
          this.setState({cantidad_cajas_producto:value})
          var mathEcu = parseInt(value)/40;
          var mathEcuRed = Math.ceil(mathEcu)
          this.setState({cantidad_paleta:mathEcuRed})
          if (getInfoBoxFilterFreezing.length>0) {
            var getInfoBoxFilterFreezingTwo = getInfoBoxFreezing.filter((a,b)=> a.id == this.state.freezing_id)
            
               if (this.state.freezing_id=='noind') {
                 this.setState({remanente:parseInt(getInfoBoxFilterFreezing[getInfoBoxFilterFreezing.length-1].remanente)-parseInt(value)})
               }else{
                if (getInfoBoxFilterFreezingTwo[0].remanente==null) {
                 this.setState({remanente:parseInt(0+parseInt(getInfoBoxFilterFreezingTwo[0].cantidad_producto))-parseInt(value)})
                }else{
                 this.setState({remanente:parseInt(parseInt(getInfoBoxFilterFreezingTwo[0].remanente)+parseInt(getInfoBoxFilterFreezingTwo[0].cantidad_producto))-parseInt(value)})
                }
              //console.log(parseInt(getInfoBoxFilterFreezingTwo[0].cantidad_producto),parseInt(getInfoBoxFilterFreezingTwo[0].remanente))
              //   if (getInfoBoxFilterFreezing.length>=2) {
              //     console.log('hol1')
              //     this.setState({remanente:parseInt(getInfoBoxFilter[0].cantidad_cajas)-parseInt(value)})
              //   }else{
              //     console.log('hol2')
              //     this.setState({remanente:parseInt(parseInt(getInfoBoxFilterFreezing[getInfoBoxFilterFreezing.length-1].remanente)+parseInt(getInfoBoxFilterFreezing[getInfoBoxFilterFreezing.length-1].cantidad_cajas))-parseInt(value)})
              // }
                 }
          }else{
            this.setState({remanente:parseInt(getInfoBoxFilter[0].cantidad_cajas)-parseInt(value)})
          }
        }
      }else{
        alert('Sin registro.')
      }

  }

}
const logicsValid = (value) => {
     var getInfoBox = this.props.sendInfoProps.dataSlaughterhousePacking
     var getInfoBoxFilter = getInfoBox.filter((a,b)=> a.producto==value )
     if (getInfoBoxFilter.length>0) {
      return true
     }else{
      return false
     }
}

const sectionOptionCut = () => {
  console.log()
                       var dataRender = null
                       dataRender = <><option value="0">Seleccione...</option>
                                      <option disabled={logicsValid('Canales')} value="Canales">Canales</option>
                                      <option disabled={logicsValid('Medias Canales')} value="Medias Canales">Medias Canales</option>
                                      <option disabled={logicsValid('Cuartos Canales')} value="Cuartos Canales">Cuartos Canales</option>
                                      <option disabled={logicsValid('Pierna')} value="Pierna">Pierna</option>
                                      <option disabled={logicsValid('Paleta')} value="Paleta">Paleta</option>
                                      <option disabled={logicsValid('Costillar')} value="Costillar">Costillar</option></>;

                       return dataRender
}
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 2,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>

    <Modal isOpen={this.state.sendOpenModalExplode} size="xl">
    <ModalBody>
        <Card className="mb-4">
          <CardHeader>
          <Row>
            <Col md={11}>
              <h3 className="mb-0">Registro de Despiece</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoSlaughterhouseExplode(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValueExplode(e.target.value)}}
                    value={this.state.lote_numero_explode} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {/*{this.state.data_lote_explode.map((value,index)=>{
                        return (<option value={value.lote_numero}>{value.lote_numero}</option>)
                      })}*/}
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                    <a style={{display:this.state.displayTree}} href={"/admin/meats/job-lote?code="+this.state.urlParams+"&lote="+this.state.urlLoteCode} target="_blank">Ver el lote</a>
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Despiece
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_despiece:e.target.value})}}
                    defaultValue={(this.state.fecha_despiece=="undefined")?(null):(this.state.fecha_despiece)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora de Inicio
                  </label>
                  <Input
                    value={(this.state.hora_inicio=="undefined")?(null):(this.state.hora_inicio)}
                    onChange={(e)=>{this.setState({hora_inicio:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora Final
                  </label>
                  <Input
                    value={(this.state.hora_final=="undefined")?(null):(this.state.hora_final)}
                    onChange={(e)=>{this.setState({hora_final:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nro de Canales
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({nro_canales:e.target.value})}}
                    defaultValue={(this.state.nro_canales=="undefined")?(null):(this.state.nro_canales)}
                    id="example-date-input"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Nro de Medias Canales
                  </label>
                  <Input
                    value={(this.state.nro_medias_canales=="undefined")?(null):(this.state.nro_medias_canales)}
                    onChange={(e)=>{this.setState({nro_medias_canales:e.target.value})}} id="example-date-input" type="text" />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Nro de Cuartos Canales
                  </label>
                  <Input
                    value={(this.state.nro_cuartos_canales=="undefined")?(null):(this.state.nro_cuartos_canales)}
                    onChange={(e)=>{this.setState({nro_cuartos_canales:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un Nro"
                    type="text"
                  />
                </FormGroup>
              </Col>
{/*              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Carcasa Entera

                  </label>
                  <Input
                    value={(this.state.carcasa_entera=="undefined")?(null):(this.state.carcasa_entera)}
                    onChange={(e)=>{this.setState({carcasa_entera:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe una carcasa"
                    type="text"
                  />
                </FormGroup>
              </Col>*/}
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                   Pierna
                  </label>
                  <Input
                    value={(this.state.pierna=="undefined")?(null):(this.state.pierna)}
                    onChange={(e)=>{this.setState({pierna:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un pierna"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Paleta
                  </label>
                  <Input
                    value={(this.state.paleta=="undefined")?(null):(this.state.paleta)}
                    onChange={(e)=>{this.setState({paleta:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe una paleta"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Costillar
                  </label>
                  <Input
                    value={(this.state.costillar=="undefined")?(null):(this.state.costillar)}
                    onChange={(e)=>{this.setState({costillar:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe una costillar"
                    type="text"
                  />
                </FormGroup>
              </Col>
{/*              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Otro Corte

                  </label>
                  <Input
                    value={(this.state.otro_corte=="undefined")?(null):(this.state.otro_corte)}
                    onChange={(e)=>{this.setState({otro_corte:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un corte"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Cantidad
                  </label>
                  <Input
                    value={(this.state.cantidad=="undefined")?(null):(this.state.cantidad)}
                    onChange={(e)=>{this.setState({cantidad:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe una cantidad"
                    type="text"
                  />
                </FormGroup>
              </Col>
            */}
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_explode=="undefined")?(null):(this.state.informacion_adicional_explode)}
                    onChange={(e)=>{this.setState({informacion_adicional_explode:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_explode:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_explode==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_explode}>{String(this.state.file_document_explode).split('_').pop()}</a>)}

              </Col>
            </Row>
          </CardBody>
        </Card>
                   {this.state.messex ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messex}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoExplode}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoSlaughterhouseExplode(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

    <Modal isOpen={this.state.sendOpenModalPacking} size="xl">
    <ModalBody>
      <Card>
          <CardHeader>
           <Row>
            <Col md={11}>
              <h3 className="mb-0">Empaque</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoSlaughterhousePacking(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
              <Row>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValuePacking(e.target.value)}}
                    value={this.state.lote_numero_packing} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {/*{this.state.data_lote_packing.map((value,index)=>{
                        return (<option value={value.lote_numero}>{value.lote_numero}</option>)
                      })}*/}
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                    <a style={{display:this.state.displayTwo}} href={"/admin/meats/job-lote?code="+this.state.urlParams+"&lote="+this.state.urlLoteCode} target="_blank">Ver el lote</a>
                  </FormGroup>
                </Col>

                
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_packing:e.target.value})}}
                    defaultValue={(this.state.fecha_packing=="undefined")?(null):(this.state.fecha_packing)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Corte
                  </label>
                                    <Input
                                      value={(this.state.corte=="undefined")?(null):(this.state.corte)}
                                      onChange={(e)=>{ this.setState({corte:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      {sectionOptionCut()}
                                    </Input>
{/*                  <Input
                    value={(this.state.corte=="undefined")?(null):(this.state.corte)}
                    onChange={(e)=>{this.setState({corte:e.target.value})}} id="example-date-input" type="text" />*/}
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Piezas por caja

                    </label>
                    <Input
                    onChange={(e)=>{this.setState({piezas_caja:e.target.value})}}
                    value={this.state.piezas_caja}
                      id="example3cols2Input"
                      placeholder="Escribe las piezas por caja"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Cajas
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({cantidad_cajas:e.target.value})}}
                    value={this.state.cantidad_cajas}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de cajas"
                      type="number"               
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad total de piezas
                    </label>
                    <Input
                    //onChange={(e)=>{this.setState({cantidad_cajas:e.target.value})}}
                    value={parseInt(this.state.piezas_caja)*parseInt(this.state.cantidad_cajas)}
                      id="example3cols2Input"
                      placeholder="Cantidad total de cajas"
                      type="number" 
                      disabled              
                    />
                  </FormGroup>
                </Col>

                <Col md="4" sm="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea1"
                    >
                      Información adicional
                    </label>
                    <Input
                    onChange={(e)=>{this.setState({informacion_adicional_packing:e.target.value})}}
                    value={this.state.informacion_adicional_packing}
                      id="exampleFormControlTextarea1"
                      rows="1"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="6">
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Subir Documento
                  </label>
                  <Form>
                    <div className="custom-file">
                      <input
                      onChange={(e)=>{this.setState({file_document_packing:e.target.files[0]})}}
                        className="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        type="file"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        Elegir archivo
                      </label>
                    </div>
                  </Form>
                {(this.state.file_document_packing==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_packing}>{String(this.state.file_document_packing).split('_').pop()}</a>)}

                </Col>
              </Row>
            </CardBody>
          </Card>
                   {this.state.messp ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messp}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoPacking}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoSlaughterhousePacking(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

    <Modal isOpen={this.state.sendOpenModalFreezing} size="xl">
    <ModalBody>

        <Card className="mb-4">
          <CardHeader>
           <Row>
            <Col md={11}>
              <h3 className="mb-0">Camara de Frio</h3>
            </Col>
            <Col md={1}>
            <a href="#" onClick={()=> {setInfoSlaughterhouseFreezing(null,false)} }>
              <h3 class="mb-0">X</h3>
            </a>
            </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
            <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Lote Número
                    </label>
                    <Input
                    onChange={(e)=>{this.onChangeLoteValuefreezing(e.target.value)}}
                    value={this.state.lote_numero_freezing} id="exampleFormControlSelect1" type="select">
                      <option value="0">Seleccione...</option>
                      {/*{this.state.data_lote_freezing.map((value,index)=>{
                        return (<option value={value.lote_numero}>{value.lote_numero}</option>)
                      })}*/}
                      <option value={this.state.urlLoteCode}>{this.state.urlLoteCode}</option>
                    </Input>
                    <a style={{display:this.state.displayOne}} href={"/admin/meats/job-lote?code="+this.state.urlParams+"&lote="+this.state.urlLoteCode} target="_blank">Ver el lote</a>
                  </FormGroup>
                </Col>
<Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Corte
                  </label>
                                    <Input
                                      value={(this.state.corte_freezing=="undefined")?(null):(this.state.corte_freezing)}
                                      onChange={(e)=>{ changeCutFreezing(e.target.value,null) }} id="exampleFormControlSelect1" type="select">
                                      <option value="0">Seleccione...</option>
                                      <option value="Canales">Canales</option>
                                      <option value="Medias Canales">Medias Canales</option>
                                      <option value="Cuartos Canales">Cuartos Canales</option>
                                      <option value="Pierna">Pierna</option>
                                      <option value="Paleta">Paleta</option>
                                      <option value="Costillar">Costillar</option>
                                    </Input>
{/*                  <Input
                    value={(this.state.corte_freezing=="undefined")?(null):(this.state.corte_freezing)}
                    onChange={(e)=>{this.setState({corte_freezing:e.target.value})}} id="example-date-input" type="text" />*/}
                </FormGroup>
              </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Cantidad de Cajas
                    </label>
                    <Input
                    onChange={(e)=>{
                      changeCutFreezing(e.target.value,this.state.corte_freezing)
                      //this.setState({cantidad_cajas_producto:e.target.value})
                    }}
                    value={this.state.cantidad_cajas_producto}
                    disabled={this.state.dsableBtnTask}
                      id="example3cols2Input"
                      placeholder="Escribe la cantidad de producto"
                      type="number"
                    />
                  </FormGroup>
                </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Cantidad de Paleta
                  </label>
                  <Input
                    value={(this.state.cantidad_paleta=="undefined")?(null):(this.state.cantidad_paleta)}
                    onChange={(e)=>{this.setState({cantidad_paleta:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Cantidad de paleta"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col> 

              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Remanente
                  </label>
                  <Input
                    value={(this.state.remanente==undefined)?(0):(this.state.remanente)}
                    id="example3cols2Input"
                    placeholder="Cantidad de paleta"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col> 

              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Fecha de Ingreso
                  </label>
                  <Input
                    onChange={(e)=>{this.setState({fecha_ingreso:e.target.value})}}
                    defaultValue={(this.state.fecha_ingreso=="undefined")?(null):(this.state.fecha_ingreso)}
                    id="example-date-input"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                  >
                    Hora

                  </label>
                  <Input
                    value={(this.state.hora_ingreso=="undefined")?(null):(this.state.hora_ingreso)}
                    onChange={(e)=>{this.setState({hora_ingreso:e.target.value})}} id="example-date-input" type="time" />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                 Se Aplico Preenfriamiento
                  </label>
                  <Input
                    value={(this.state.aplico_preenfriamiento=="undefined")?(null):(this.state.aplico_preenfriamiento)}
                    onChange={(e)=>{this.setState({aplico_preenfriamiento:e.target.value})}} id="exampleFormControlSelect1" type="select">
                    <option value="0">Seleccione...</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Temperatura Inicial
                  </label>              
                              <InputGroup>
                                      <Input
                                        value={(this.state.temperatura_inicial=="undefined")?(null):(this.state.temperatura_inicial)}
                                        onChange={(e)=>{this.setState({temperatura_inicial:e.target.value})}}
                                        id="example3cols2Input"
                                        placeholder="Escribe la temperatura inicial"
                                        type="number"
                                      />
                                    <Input
                                      value={this.state.unidad_temperatura_inicial}
                                      onChange={(e)=>{ this.setState({unidad_temperatura_inicial:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="°C">°C</option>
                                      <option value="°F">°F</option>
                                      <option value="K">K</option>
                                    </Input>
                                  </InputGroup>
{/*                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Temperatura Inicial
                  </label>
                  <Input
                    value={(this.state.temperatura_inicial=="undefined")?(null):(this.state.temperatura_inicial)}
                    onChange={(e)=>{this.setState({temperatura_inicial:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un tratamiento"
                    type="text"
                  />
                </FormGroup>*/}
              </Col>
              <Col md="4">
                              <label
                                className="form-control-label"
                                htmlFor="example3cols2Input"
                              >
                                Temperatura Final
                              </label>
                              <InputGroup>
                                    <Input
                                      value={(this.state.temperatura_final=="undefined")?(null):(this.state.temperatura_final)}
                                      onChange={(e)=>{this.setState({temperatura_final:e.target.value})}}
                                      id="example3cols2Input"
                                      placeholder="Escribe un tratamiento"
                                      type="number"
                                    />
                                    <Input
                                      value={this.state.unidad_temperatura_final}
                                      onChange={(e)=>{ this.setState({unidad_temperatura_final:e.target.value}) }} id="exampleFormControlSelect1" type="select">
                                      <option value="°C">°C</option>
                                      <option value="°F">°F</option>
                                      <option value="K">K</option>
                                    </Input>
                                  </InputGroup>
{/*                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Temperatura Final
                  </label>
                  <Input
                    value={(this.state.temperatura_final=="undefined")?(null):(this.state.temperatura_final)}
                    onChange={(e)=>{this.setState({temperatura_final:e.target.value})}}
                    id="example3cols2Input"
                    placeholder="Escribe un tratamiento"
                    type="text"
                  />
                </FormGroup>*/}
              </Col>
              <Col md="4" sm="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Información adicional
                  </label>
                  <Input
                    value={(this.state.informacion_adicional_freezing=="undefined")?(null):(this.state.informacion_adicional_freezing)}
                    onChange={(e)=>{this.setState({informacion_adicional_freezing:e.target.value})}}
                    id="exampleFormControlTextarea1"
                    rows="1"
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="6">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Subir Documento
                </label>
                <Form>
                  <div className="custom-file">
                    <input
                      onChange={(e)=>{this.setState({file_document_freezing:e.target.files[0]})}}
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Elegir archivo
                    </label>
                  </div>
                </Form>
                {(this.state.file_document_freezing==null) ? "Sin archivo": (<a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+this.state.file_document_freezing}>{String(this.state.file_document_freezing).split('_').pop()}</a>)}

              </Col>
            </Row>
                   {this.state.messf ? (
                    <div className="text-muted font-italic">
                      <small>
                        <span className="text-red font-weight-700">
                          {this.state.messf}
                        </span>
                      </small>
                    </div>
                  ) : null}
            <Row>
              <Col md="3">
                <Button 
                className="btn-icon" 
                color="traza-blue" 
                type="button" 
                disabled={this.state.btnd}
                onClick={saveInfoFreezing}>
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-check-circle" />
                  </span>
                  <span className="btn-inner--text">Guardar</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {setInfoSlaughterhouseFreezing(null,false)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Registro de Despiece
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoSlaughterhouseExplode(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhouseExplode.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.lote_numero=="null") ? ("Sin número") : (value.lote_numero)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoSlaughterhouseExplode(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteExplode(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Empaque
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoSlaughterhousePacking(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhousePacking.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.producto=="null") ? ("Sin nombre") : (value.producto)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoSlaughterhousePacking(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deletePacking(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
        <Row>
        <Card className="mb-4" md="4">
        <CardHeader style={{borderRadius: '20px'}}>
        <Row>
            <h3 className="mb-0">
             Camara de Frio
            </h3>
            <Button className="btn-icon ml-3" color="traza-blue" type="button" onClick={()=>{ setInfoSlaughterhouseFreezing(null,true) }}>
              <span className="btn-inner--text">+</span>
            </Button>
        </Row>
          </CardHeader>
        </Card>
        </Row>
          <CardBody>
            <Row>
            {
                  this.props.sendInfoProps.dataSlaughterhouseFreezing.map((value,index)=>{
                            return (
                                     <Col md="4" className="text-center">
                                     <Card md="12" className="pb-3">
                                       <Col md="12">
                                        <Row>
                                         <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {(value.producto=="null") ? ("Sin número") : (value.producto)}
                                           </label>
                                         </Col>
                                          <Col md="6">
                                           <label
                                             className="form-control-label"
                                             htmlFor="exampleFormControlSelect1"
                                           >
                                             {value.created_at}
                                           </label>
                                         </Col>
                                       </Row>
                                       </Col>

                                      <Col md="12">
                                         <Row>
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => setInfoSlaughterhouseFreezing(value.id,true) }
                                           >
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-edit" />
                                             </span>
                                           </Button>
                                         </Col>
                         
                                         <Col md="6">
                                         <Button 
                                           className="btn-icon" 
                                           type="button"
                                           onClick={() => deleteFreezing(value.id)}>
                                             <span className="btn-inner--icon mr-1">
                                               <i className="fas fa-trash" />
                                             </span>
                                           </Button>
                                         </Col>
                                         </Row>
                                       </Col>
                                    </Card>
                                     </Col>
                                    ) 
                     })
           }
            </Row>
         </CardBody>
    </>
  );
 }
}

 export default ModalSlaughterhouse;
