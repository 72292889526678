import React, {createRef} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import { get_permission, get_users_colab } from "network/ApiAxios";
import { CountrysData } from "network/DataCountry";
import NotificationAlert from "react-notification-alert";
import config from "config";
import jQuery from 'jquery';
import Web3 from 'web3';
import "./StyleCompany.css";
import Subscription from "./Subscriptions.js";
import usersList from "../tables/AuthUsers.js";
import {UserContext} from "layouts/store";
var web3 = new Web3('https://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/')
var web3Socket = new Web3('wss://damp-wild-violet.bsc-testnet.discover.quiknode.pro/7fd1cd8287bda8760edcef60f46ba7ff28aae4d2/');
//var web3Socket = new Web3("wss://bsc-dataseed.binance.org/");
class ProfileCompany extends React.Component {
  static contextType = UserContext
state = {
  dataPermission:null,
  nameCompany:null,
  nameCompanySimple:null,
  colabCount:null,
  contentSections:Subscription,
  setinfoState:false
}
async componentDidMount(){
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var dataColabCount = await get_users_colab(jsondata._id)
     this.setState({
       colabCount:dataColabCount.length
     })
}


 setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    console.log(dataPermission)
    var getNameSimple = dataPermission.empresa.toLocaleUpperCase().split(' ')
    var getNameSimpleFirst = getNameSimple[0]
    var getNameSimpleSecond = getNameSimple[1]
    if (getNameSimpleSecond==undefined) {
        var nameSimpletitulo = getNameSimpleFirst[0]
    }else{
        var nameSimpletitulo = getNameSimpleSecond[0]+getNameSimpleSecond[0]
    }
    var strName = dataPermission.empresa.charAt(0).toLocaleUpperCase() + dataPermission.empresa.slice(1);
     this.setState({
       dataPermission:dataPermission,
       nameCompany:strName,
       nameCompanySimple:nameSimpletitulo
     })
      }
async componentWillUnmount(){
}

render(){
const selectSection = (index,content) =>{
  this.setState({contentSections:content})
  jQuery('[class*="selectBtn"]').removeClass('active')
  jQuery('.selectBtn'+index).addClass('active')
}

  return (<>
   <div>
   {this.setinfoInit()}
       <Col md={12}>
       <Card>
           <CardBody>
           {(this.state.dataPermission==null)?(<span>Cargando...</span>):(<>
             <div className="go-avatar editable">
               <div className="go-avatar-circle flex bg-primary">
               <div className="go-avatar-cover"></div>
               <span>{this.state.nameCompanySimple}</span>
               </div>
               <h1 style={{fontWeight: "800"}}>{this.state.nameCompany}</h1>
                 <img alt={String(this.state.dataPermission.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.dataPermission.pais).toLocaleLowerCase()+".png 2x"} />
                 <span style={{color: "gray", paddingLeft: "10px", fontSize: "17px"}}>{this.state.dataPermission.pais}</span>
             </div>
             <div className="company-stat">
               <i aria-hidden="true" role="presentation" className="fas fa-users q-icon notranslate" style={{paddingRight: '11px'}}> </i>
               <span>
                {this.state.colabCount} member(s)
              </span>
            </div>
             </>)}

           </CardBody>
         </Card>
       </Col>
{(this.state.dataPermission==null)?(null):(
       <Col md={12}>
       <Card>
         <CardBody>
            <div className="go-tabs q-my-md">
              <div className="active selectBtn0" id="0" onClick={(e)=>{selectSection(0,Subscription)}}>
                <i aria-hidden="true" role="presentation" className="fas fa-file-invoice-dollar q-icon notranslate"></i>
                <span>Planes</span>
              </div>
              <div className="selectBtn1" onClick={(e)=>{selectSection(1,usersList)}}>
                <i aria-hidden="true" role="presentation" className="fas fa-users q-icon notranslate"></i>
                <span>Colaboradores</span>
              </div>
             </div>
 
<this.state.contentSections userCompany={true}/>
 {/*<div className="pricing-area">
  <div className="container">
    <div className="row">
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price">
          <div className="deal-top">
            <h3>Basico</h3>
            <h4> 30 <span className="sup">$</span> </h4> 
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>5 colaboradores</li>
              <li>50 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
     <div className="btn-area">
              <a href="#">Ingresar</a>       
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price">
          <div className="deal-top">
            <h3>Standard</h3>
            <h4> 60 <span className="sup">$</span> </h4>
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>50 colaboradores</li>
              <li>100 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
            <div className="btn-area">
              <a href="#">Ingresar</a>       
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="single-price">
          <div className="deal-top">
            <h3>Pro</h3>
            <h4> 80 <span className="sup">$</span> </h4> 
          </div>
          <div className="deal-bottom">
            <ul className="deal-item">
              <li>100 colaboradores</li>
              <li>1000 trazabilidades</li>
              <li>24 hour Support </li>
            </ul>
            <div className="btn-area">
              <a href="#">Ingresar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>       
</div>*/}

           </CardBody>
         </Card>
       </Col>
 )}

   </div>   
  </>);
 }
}
export default ProfileCompany;
