import {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Table, Button, Card, CardBody, Container, Row, Col, Input } from "reactstrap";
import logo from "assets/img/brand/favicon.png";
import { data_traza_verify } from 'network/ApiAxios';
import { NavLink as ComponentLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import './StylePagination.css';
function InventoryTraza() {
const [dataResult, setData] = useState([]);
const [valueInput, setValueInput] = useState([]);
const [trazabilidad, setTrazabilidad] = useState(null);
const [pageCount, setpageCount] = useState(0);
const [pageInitial, setpageInitial] = useState(0);
  useEffect(async () => {
    var data = await data_traza_verify(valueInput,trazabilidad)
    if (data.indexOf('none')!= -1) {
      return;
    }
    //var pageCount = Math.ceil(items.length / 10);
    setpageCount(data[0].forcounter / 10)
    setData(data)
  }, []);
const changeInfoTraza = async (valueInput,trazabilidad) => {
  setData([{name:'Cargando...'}])
  setpageCount(0)
  setTrazabilidad(trazabilidad)
  setpageInitial(0)
  var data = await data_traza_verify(valueInput,trazabilidad,0)
  if (data== '') {
      setData([{name:'Sin trazabilidades.'}])
      return;
  }
if (data.indexOf('none')!= -1) {
      setData([{name:'Sin trazabilidades.'}])
      return;
  }  
  setpageCount(data[0].forcounter / 10)
  setData(data)
}
const changeInfoValue = async (valueInput,trazabilidad) => {
  setData([{name:'Cargando...'}])
  setpageCount(0)
  setValueInput(valueInput)
  setpageInitial(0)
  var data = await data_traza_verify(valueInput,trazabilidad,0)
  if (data== '') {
      setData([{name:'Sin trazabilidades.'}])
      return;
  }
if (data.indexOf('none')!= -1) {
      setData([{name:'Sin trazabilidades.'}])
      return;
  }
  setpageCount(data[0].forcounter / 10)
  setData(data)
}
  const handlePageClick = async (event) => {
    setData([{name:'Cargando...'}])
    setpageInitial(event.selected)
    if (dataResult.length == 0) {
      return;
    }
    const newOffset = (event.selected * 10) % dataResult[0].forcounter;
    var data = await data_traza_verify(valueInput,trazabilidad,Math.ceil(newOffset))
    setData(data)
  };
  return (
    <>
      <div className="header bg-traza-blue pt-8 pb-7">
        <Container>
          <div className="header-body">
            <Row className="align-items-center">
              <Col md="12">
                <div className="pr-5">
                  <h1 className="display-2 text-white font-weight-bold mb-0">
                    Verificador de trazabilidad
                  </h1>
                  <div className="mt-5">
                  </div>
                </div>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="12">
                    <Card>
                      <CardBody>  
                      <Row>
                        <Col md="6">
                          <input className="form-control" value={valueInput} placeholder="Buscar" onChange={(e)=>{changeInfoValue(e.target.value,trazabilidad)}} />
                        </Col>
                        <Col md="6">
                          <Input 
                          type="select" 
                          value={trazabilidad} 
                          onChange={(e) => {changeInfoTraza(valueInput,e.target.value)} }>
                            <option value="null">Trazabilidad</option>
                            <option value="VF">Vegetales y frutas</option>
                            <option value="M">Carnicos</option>
                          </Input>
                        </Col>                        
                      </Row>
<ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        containerClassName="pagination"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        forcePage={pageInitial}
        disableInitialCallback={false}
        renderOnZeroPageCount={null}
      />
                      <br />
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Usuario</th>
                <th>Trazabilidad</th>
                <th>Nombre de la Trazabilidad</th>
                <th>Blockchain</th>
              </tr>
            </thead>
            <tbody>
            {dataResult.map((value,index)=>{
              return(
                <tr>
                     <td>
                      <span className="text-muted">
                       {value.name} ({value.email})
                      </span>
                     </td>
                   <td>
                    <span className="text-muted">
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:(value.codigo?.indexOf('FCTM') == -1)?("/home/vista-trace"):("/home/vista-trace-meat"),
                        search: "?code="+value.codigo,
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                      {value.codigo}
                     </ComponentLink>
                    </span>
                   </td>
                     <td>
                      <span className="text-muted">
                       {value.nombre_traza}
                      </span>
                     </td>
                   <td>
                    <span className="text-muted">
                     {(value.code==null)?('Sin publicar'):(<a href={value.ipfs} target="_blank">{value.ipfs}</a>)}
                    </span>
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-traza-grayblue" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default InventoryTraza;
