import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Collapse,
  InputGroup
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_production, add_favorite_mrv, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import MRVProd from "views/pages/forms/Production/MRV/MRVProViewTable";

export class FavProductionMVR extends React.Component {
  state={
    data_users_prod:[],
    company_id:null,
    collapse:[]
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tg').remove()
    return
  }else{
    if (this.props.sendDataCompany.type_traza=="G") {
      jQuery('#tg').remove()
    }
  }
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
  })
  }
  get_db = (codigo,data) => {
    var get_data = data.filter((a,b)=>a.btnides==codigo)
    return 'db_traza_'+get_data[0].user_id
  }
  addDataFavorite = async (code_prod,user_id_db,check) =>{
    await add_favorite_mrv(code_prod,user_id_db,check)
  }
  setContent = (data,data_ind) => {
    if (data==undefined) {
      return
    }
    var contentth = []

    if (data.length!=0) {
    var content=[]
    var getdataiter = []
    data.map((a,b)=>{
      getdataiter.push(...a)
    })
    if (getdataiter.length==0) {
        content = <><tr><td>Sin Resultado</td></tr></>
      return content
    }
      getdataiter.map((a,b)=>{
        var get_codigo = null
         var content_one = Object.entries(a).map((val,ind)=>{
            if (val[0]=='btnides') {
              get_codigo=val[1]
            }
         data_ind.map((valind,indind)=>{
             if (valind.split('*')[1].indexOf(val[0])!=-1) {
               contentth[ind+1]='<th>'+valind.split('*')[0]+'</th>'
             }
           })

 var content_two = <>
  {(val[0]!='btnides' && val[0]!='user_id')?(<td ><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='btnides')?(<>
                          <td style={{textAlign: 'center'}}><input type="checkbox" onClick={(e)=>{this.addDataFavorite(val[1],this.get_db(val[1],getdataiter),e.target.checked)}} /></td>
                          <td>
{/*                    <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.toggleFunction(b,this.state.collapse[val[1]],val[1])}}>
                      Desplegar para ver mas detalle
                    </button>*/}

                  <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"detail_monitoring",
                        search: "?db_code="+this.get_db(val[1],getdataiter)+"&code="+val[1],
                        state:{
                          db_codigo:this.get_db(val[1],getdataiter)
                        }
                        }}
                      >
                      + Información de {val[1]}
                     </ComponentLink>
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <>
                    <tr>
                    {content_one}
                    </tr>
{/*                        <tr>
                          <td colspan="3">
                          <Collapse isOpen={this.state.collapse[get_codigo]}>
                          <div>
                            <MRVProd code={this.state.codeTTV}/>
                          </div>
                          </Collapse>
                          </td>
                        </tr>*/}
                    </>
      })
    }
    contentth[contentth.length] = '<th>Seleccionar Favorito</th>'
    contentth[contentth.length] = '<th>Opciones</th>'
    jQuery('.putContentTh').html(contentth)
    return content
  }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }
// setContentTh = (data) => {
//   var content = []
//     if (data!=undefined) {
//       Object.values(data).map((a,b)=>{
//         content[b]=<th>{a.split('*')[0]}</th>
//       })
//     }
//     return content
//   }
 setContentTh = (data,data_ind) => {
   var content = []
      if (data!=undefined) {
      data_ind.map((valind,indind)=>{
        data.map((a,b)=>{
            Object.entries(a).map((val,ind)=>{
                if (valind.split('*')[1].indexOf(val[0])!=-1) {
                 content[ind]=<th>{valind.split('*')[0]}</th>
                }
                })
            })
        })
      }
      return content
   }
   putInfoProd = async () => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var get_value = jQuery('#input_search').val()
    if (get_value=='') {
      alert('No puede estar vacio el campo')
      return
    }
    var data = await data_list_production(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',get_value)
    this.setState({data_users_prod:data.data_productions })
   } 
render(){
  return (
    <>
      <SimpleHeader name="Lista de Productores" parentName="Lista de Productores" section="mrv" section_two="Tabla" section_table_form="table_traza_proccess" />
        <Container className="mt--6" fluid>

        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col md="6">
                <h3 className="mb-0">Tabla Lista de Favoritos</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr className={"putContentTh"}>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_users_prod[2],this.state.data_users_prod[1])}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()

  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }

    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('ON')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
        this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
          if (this.context.valueDataMain[0]==undefined) {
            this.setState({get_permission:false})
          }
        }else{
          if (dataPermission.role==4) {
            this.setState({get_permission:true})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <FavProductionMVR {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default BuildingProduction;
