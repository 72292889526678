import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_blockchain, get_permission, api_debug, save_ipfs_debug } from "network/ApiAxios";
import { globalUrlList } from "network/gateways";
import { NavLink as ComponentLink } from "react-router-dom";
import { generateChain } from "network/GenerateTokenChain";
import jQuery from 'jquery';
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import NotificationAlert from "react-notification-alert";
import {UserContext} from "layouts/store";
import './Style.css';

export class Elements extends React.Component {
  state={
    data_blockchain:[],
    data_blockchain_meat:[],
    list_ipsf:globalUrlList,
    notificationAlertRef: React.createRef(),
    processmsj:null,
    putcode:null,
    company_id:null
  }
 async componentDidMount(){
  this.initData()
  }
  initData = async () => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
          if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tm').remove()
    jQuery('#tvf').remove()
    return
  }else{
    if (this.props.sendDataCompany.type_traza=="VF") {
      jQuery('#tm').remove()
    }
    if (this.props.sendDataCompany.type_traza=="M") {
      jQuery('#tvf').remove()
    }
  }
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    var data = await data_list_blockchain(jsondata._id,gerParam,this.props.sendDataCompany.type)
    console.log(data)
    this.setState({
      company_id:gerParam,
      data_blockchain:data.productionData,
    })
  }
  chanspan = (codigo) => {
    console.log(codigo)
    jQuery("[class*='spanmsj']").hide()
    jQuery(".spanmsj"+codigo).show()
  }
render(){
    const saveInfoTrazaQr =  async (codigo,idTrazabilidad) =>{
      this.props.history.push('/admin/grains-nama-end?code='+codigo+'&up=uploadblock')
  }

  const depuraripfs = (ipfsurl,codigo)=>{
    this.setState({processmsj:'Depurando...',putcode:codigo})
      var codeipfs = ipfsurl.split('/')[ipfsurl.split('/').length-1]
      var codeipfslist = globalUrlList.join().replaceAll(':hash',codeipfs)
      var arrayipfs = codeipfslist.split(',');
      this.setState({list_ipsf:arrayipfs})
      // this.chanspan(codigo)
      responseipsf(arrayipfs[0],codigo)
   }
   const responseipsf = async (ipfsurlcheck,codigo)=>{
      var result = await api_debug(ipfsurlcheck)
      if (result==200) {
      this.setState({processmsj:'Guardando información...'})
       var result_return = await save_ipfs_debug(ipfsurlcheck,codigo)
       if (result_return==true) {
         this.setState({processmsj:null, putcode:null})
         this.initData()
         notify("success", "¡Depurado exitosamente!", 'Se guardo exitosamente el NFT.');
       }else{
         this.setState({processmsj:null, putcode:null})
         notify("danger", "¡Error en guardar la información!", 'No se pudo guardar la información, intente de nuevo.');
       }
      }else{
        changeipfs(codigo)
      }
   }
   const changeipfs = async (codigo)=>{
     var gth = this
     setTimeout(function(){
     var changearray = gth.state.list_ipsf.shift()
       //console.log(gth.state.list_ipsf[0],changearray)
       responseipsf(gth.state.list_ipsf[0],codigo)
     }, 100);
     
   }
  const notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    this.state.notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={this.state.notificationAlertRef} />
      </div>
      <SimpleHeader name="Transacción Blockchain" parentName="Transacción Blockchain" section="fuits_vegetable" section_two="Tabla" section_table_form="table_blockchain" />
        <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Blockchain Producción</h3>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Código de la trazabilidad</th>
                <th>Transaction Hash</th>
                <th>NFT</th>
                <th>
                  Subir a la Blockchain <Col md={12} style={{textAlign: 'center'}}>
                  <div className="tooltip"><i className="fas fa-question"></i>
                    <span className="tooltiptext">Si tarda mucho en cargar la información IPFS use el botón "Actualizar IPFS" para poder depurar los datos y actualizar la url.</span>
                  </div>
                </Col> 
                </th>
              </tr>
            </thead>
            <tbody>
            {this.state.data_blockchain.map((value,index)=>{
              return(
                <tr>
                   <td>
                    <span className="text-muted">
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/vista-trace-admin-grain-nama",
                        search: (this.state.company_id==null)?("?code="+value.codigo):("?company="+this.state.company_id+"&code="+value.codigo),
                        state:{
                          codigoTraza:value.codigo
                        }
                        }}
                      >
                     {value.codigo}
                     </ComponentLink>
                    </span>
                   </td>
{/*                   <td>
                    <span className="text-muted">
                     {(value.codigo==null)?('Sin publicar'):(<a href={'https://mumbai.polygonscan.com/'+value.block_hash} target="_blank">{value.block_hash}</a>)}
                    </span>
                   </td>*/}
                   <td>
                    <span className="text-muted">
                     {(value.code==null)?('Sin publicar'):(<a href={'https://bscscan.com/tx/'+value.transaction_hash} target="_blank">{value.transaction_hash}</a>)}
                    </span>
                   </td>
                   <td>
                    <span className="text-muted">
                     {(value.code==null)?('Sin publicar'):(<a href={value.ipfs} target="_blank">{value.ipfs}</a>)}
                    </span>
                   </td>
                   <td className="text-center">
                   {(value.code==null)?(<>
                    <span className={"text-center loadingupload"+value.idTraza} id='loadingupload' style={{display: 'none'}}>
                      Subiendo...
                    </span>
                    <a href="javascript:;" style={{boxShadow: 'none'}} onClick={()=>{saveInfoTrazaQr(value.codigo,value.idTraza)} } className={"font-weight-bold saveBtnAnimalList"+value.idTraza}>Subir al Blockchain</a>
                    </>):(<><span className="fa fa-check"></span><br />{(this.state.processmsj==null)?(<a href="javascript:;" style={{boxShadow: 'none'}} onClick={()=>{depuraripfs(value.ipfs,value.codigo)} } className={"font-weight-bold saveBtnAnimalList"+value.idTraza}>Actualizar IPFS</a>):( (this.state.putcode==null)?(<span className={"spanmsj"+value.codigo}>{this.state.processmsj}</span>):((this.state.putcode==value.codigo)?(<span className={"spanmsj"+value.codigo}>{this.state.processmsj}</span>):(null)) )}</>)}
                   </td>
                 </tr>)
            })
          }

            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false

  }
 async componentDidMount(){
  this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    //         var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
    var dataPermission = this.context.valueGlobal
    var data_type_area = dataPermission.type_traza.indexOf('P')
        if (data_type_area==-1) {
          this.setState({get_permission:false})
          return;
        }
    this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }    
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <Elements {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default Elements;
