import React, {createRef, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import QRCode from "react-qr-code";

export class BuildingProduction extends React.Component {
  state={
    data_nama_g:[],
    data_nama_g_th:[],
    company_id:null,
    jsondataBase:null
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  if (this.props.sendDataCompany.type_traza==null) {
    jQuery('#tg').remove()
    return
  }else{
    if (this.props.sendDataCompany.type_traza.indexOf("P")==-1) {
      jQuery('#tg').remove()
    }
  }
  var data = await data_list_processes(jsondata._id,this.props.sendDataCompany.user_id_connection,this.props.sendDataCompany.type,'ttp',jsondata.userType)
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
    data_nama_g:data.nama_grain[1],
    data_nama_g_th:data.nama_grain[0],
    jsondataBase:jsondata.database
  })
  }
  setContent = (data) => {
    var routeParamName = 'info_crops_g'
    if (this.props.sendDataCompany.role==2) {
      switch (1) {
        case this.props.sendDataCompany.information_nama_g:
          routeParamName='info_crops_g';
          break;
        case this.props.sendDataCompany.production_nama_g:
          routeParamName='production_land_preparation_g';
          break;
        case this.props.sendDataCompany.hading_nama_g:
          routeParamName='hading_info_weeds';
          break;
        case this.props.sendDataCompany.harvest_nama_g:
          routeParamName='harvest_info_harvest';
          break;
        case parseInt(this.props.sendDataCompany.document_nama_g):
          routeParamName='grains-nama-documents';
          break;
        default:
          routeParamName='info_crops_g';
      }
    }
    if (data==undefined) {
      return
    }
    if (data!=undefined) {
      if (data.length!=0) {
      var content=[]
        data.map((a,b)=>{
           var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/"+routeParamName
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/fruits-vegetables/production"
              }
            }
   var content_two = <>
    
    {(val[0]!='codigo')?(<td><span className="text-muted">{val[1]}</span></td>):(null)}
    {(val[0]=='codigo')?(<><td><span className="text-muted">{val[1]}</span></td><td>
                  <ComponentLink
                   className="font-weight-bold"
                                         to={{
                     pathname:url_selected,
                     search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                     state:{
                       codigoTraza:val[1]
                     }
                     }}
                   >
                  Ver Este Proceso
                  </ComponentLink>
                </td>
                <td>
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/prouction-observations-mvr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver Todas las Observaciones
                     </ComponentLink>
                </td>
                <td><QRCode style={{ width: "65px", height: 'auto' }} value={"http://foodchaintrazabilidad.com/home/vista-trace-grain-nama?code="+val[1]+"&db="+this.state.jsondataBase}  /></td>
                </>):(null)}
                        </>
                        return content_two
          })
          content[b] = <tr>{content_one}</tr>
        })
      }
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]=<th>{a.split('*')[0]}</th>
      })
    }
    return content
  }

render(){
  return (
    <>
      <SimpleHeader name="Producción en proceso" parentName="Producción en proceso" section="fuits_vegetable" section_two="Tabla" section_table_form="table_traza_proccess" />
        <Container className="mt--6" fluid>

        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col xs="12">
              <Row>
                <h3 className="mb-0">Tabla Producción en Proceso - </h3>{' '}
                <Button style={{background: 'none', boxShadow: 'none', border: 'none', padding:0}} onClick={()=>{window.location.href = "/admin/info_producer_g"}}>
                  <h3 className="mb-0"> Crear Nueva Producción</h3>
               </Button>
              </Row>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>id</th>
              {this.setContentTh(this.state.data_nama_g_th)}
                <th>Creado</th>
                <th>Código</th>
                <th>Opciones</th>
                <th>Observaciones</th>
                <th>QR</th>
              </tr>
            </thead>
            <tbody>
                {this.setContent(this.state.data_nama_g)}
            </tbody>
          </Table>
        </Card>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false
  }
 async componentDidMount(){
    this.context.getAffiliates()
    // var userdata = localStorage.getItem('user');
    // var jsondata = JSON.parse(userdata);
    // var gerParam = new URLSearchParams(this.props.location.search).get("company");
    // var idcompany = null;
    // if (jsondata.userType==2) {
    //   if (gerParam!=null) {
    //     idcompany = gerParam
    //   }
    // }
    // var dataPermission = await get_permission(jsondata._id,idcompany)

  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
        var getTypeAreaWork = localStorage.getItem('type_area_work')
         if (getTypeAreaWork!='production' && getTypeAreaWork!=null) {
          this.setState({get_permission:false})
          return 
         }
    var dataPermission = this.context.valueGlobal
          var data_type_area = dataPermission.type_traza.indexOf('P')
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
          this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
        }else{
            if (dataPermission.type=='admin') {
              this.setState({get_permission:true})
            }
          if (dataPermission.type=='options') {
            this.setState({get_permission:true})
          }
          if (dataPermission.type=='view') {
            this.setState({get_permission:false})
          }
        }
      }  
render(){
  this.setInfoInit()
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <BuildingProduction {...this.props} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default BuildingProduction;
