import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import React from "react";
import IndexNavbar from "components/Navbars/NavbarTrace.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import IndexFooter from "components/Footers/IndexFooter.js";
import { get_data_row_main, get_data_row_plant } from "network/ApiAxios";
import SectionProduction from "./SectionsViewTrace/SectionProductionView";
import SectionCosecha from "./SectionsViewTrace/SectionCosechaView";
//import SectionTransport from "./SectionsViewTrace/SectionTransportView";
import SectionPlanta from "./SectionsViewTrace/SectionPlantaView";
import SectionEmbarque from "./SectionsViewTrace/SectionLogisticView";
import SectionCommerce from "./SectionsViewTrace/SectionCommerceView";
import SectionDocuments from "./SectionsViewTrace/SectionDocumentsView";
import SectionTraza from "views/pages/examples/Trazability/SectionsViewTrace/SectionTrazaView";

import { NavLink as ComponentLink } from "react-router-dom";
import {GuideMessage} from "views/pages/components/GuideMessage";

import "./StyleCss.css";
export default class VistaTrace extends React.Component {
	state ={
		statusClass:[],
		statusColor:[],
		allDataTrza:null,
		DataRowProdcutionCreate:['Cargando...'],
		DataRowProdcutionFertilizer:['Cargando...'],
		commercialization_export:['Cargando...'],
		commercialization_market:['Cargando...'],
		create_cosecha:['Cargando...'],
		create_plant_cold:['Cargando...'],
		create_plant_information_empaque:['Cargando...'],
		create_plant_pre_cold:['Cargando...'],
		create_plant_product:['Cargando...'],
		create_plant_sample:['Cargando...'],
		create_plant_treatment:['Cargando...'],
		create_plant_weight_product:['Cargando...'],
		logistics_transport_shipment:['Cargando...'],
		logistics_transport_transporte:['Cargando...'],
		transport_cosecha:['Cargando...'],
		'fruit_vegetable':['Cargando...'],
		'transport_vehicule_cosecha':['Cargando...'],
		code_traza:'Cargando...',
		data_user:null,
		components:SectionTraza,
	}
	async componentDidMount(){
		var gerParam = new URLSearchParams(this.props.location.search).get("code");
		if (gerParam==null) {
			this.setState({code_traza:'Sin Trazabilidad'});
			this.setState({create_plant_information_empaque:null});
			this.setState({DataRowProdcutionCreate:null});
			this.setState({DataRowProdcutionFertilizer:null});
			this.setState({commercialization_export:null});
			this.setState({commercialization_market:null});
			this.setState({create_cosecha:null});
			this.setState({create_plant_cold:null});
			this.setState({create_plant_pre_cold:null});
			this.setState({create_plant_product:null});
			this.setState({create_plant_sample:null});
			this.setState({create_plant_treatment:null});
			this.setState({create_plant_weight_product:null});
			this.setState({logistics_transport_shipment:null});
			this.setState({logistics_transport_transporte:null});
			this.setState({transport_cosecha:null});
			this.setState({fruit_vegetable:null});
			this.setState({transport_vehicule_cosecha:null});
		}else{
		this.setNameDB(gerParam)
		}
	}
	configAllDataStatus = (dataRow) => {
//['create_plant_cold','create_plant_information_empaque','create_plant_pre_cold','create_plant_product','create_plant_sample','create_plant_treatment','create_plant_weight_product','fruit_vegetable','production_create','production_fertilizer','transport_cosecha','transport_vehicule_cosecha','commercialization_export','commercialization_market','logistics_transport_shipment','logistics_transport_transporte','create_cosecha'];
if (dataRow==undefined) {
	var data_array_get = []
}else{
	var data_array_get = Object.keys(dataRow)
}
data_array_get.map((a,b)=>{
 			 if (dataRow[a]['data_array'].length==0) {
 			 	this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
 				this.setState({statusColor:{...this.state.statusColor,[a]:'#7A7A7A'}});
 			 }else{
 			 	  dataRow[a]['data_array'].map((value,index)=>{
				 	if (value.status=='finalizado') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-check-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#58d999'}});
				 	}
				 	if (value.status=='desarrollo') {
		 				this.setState({statusClass:{...this.state.statusClass,[a]:'fas fa-minus-circle'}});
		 				this.setState({statusColor:{...this.state.statusColor,[a]:'#f8d014'}});
					}
 			 	  })
 			 }
 })

	}
		setNameDB = async(data) =>{
		this.setState({code_traza:data+':'});
		var dataRow = await get_data_row_main(data)
		this.configAllDataStatus(dataRow.data.data_traza_row)
		this.setState({allDataTrza: dataRow.data.data_traza_row})
		this.setState({data_user:dataRow.data.data_user});
		//console.log(dataRow.data['fruit_vegetable']['data_array'][0])
		if(dataRow.data.fruit_vegetable==null){
			this.setState({statusTrazaClass:'fas fa-minus-circle'});
			this.setState({statusTrazaColor:'#7A7A7A'});
			this.setState({fruit_vegetable:null});
		}else{
			this.setState({data_user:dataRow.data.data_user});
				 	if (dataRow.data.fruit_vegetable.status=='finalizado') {
		 				this.setState({statusTrazaClass:'fas fa-check-circle'});
		 				this.setState({statusTrazaColor:'#58d999'});
				 	}
				 	if (dataRow.data.fruit_vegetable.status=='desarrollo') {
		 				this.setState({statusTrazaClass:'fas fa-minus-circle'});
		 				this.setState({statusTrazaColor:'#f8d014'});
					}
			this.setState({fruit_vegetable:dataRow.data.fruit_vegetable});
		}
		if(dataRow.data.production_create==null){
			this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
			this.setState({statusProdcutionFertilizerColor:'#7A7A7A'});
			this.setState({DataRowProdcutionCreate:null});
		}else{
			if (dataRow.data.production_fertilizer[0]!=null) {
				 	if (dataRow.data.production_create.status=='finalizado') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-check-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#58d999'});
				 	}
				 	if (dataRow.data.production_create.status=='desarrollo') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#f8d014'});
					}
			}else{
					if (dataRow.data.production_create.status=='finalizado') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#58d999'});
				 	}
				 	if (dataRow.data.production_create.status=='desarrollo') {
		 				this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		 				this.setState({statusProdcutionFertilizerColor:'#f8d014'});
					}
			}
			this.setState({DataRowProdcutionCreate:dataRow.data.production_create});
		}
		if (dataRow.data=='Sin trazabilidad') {
			return
		}
		// if(dataRow.data.production_fertilizer[0]==null){
		// 	this.setState({DataRowProdcutionFertilizer:null});
		// }else{
		// 	this.setState({DataRowProdcutionFertilizer:dataRow.data.production_fertilizer});
		// }
		////////////////////////////////////////STATUS OPTIONS//////////////////////////////////////////////////
		// if ((dataRow.data.production_create!=null && dataRow.data.production_fertilizer[0]==null) || (dataRow.data.production_create==null && dataRow.data.production_fertilizer[0]!=null)) {
		// 	this.setState({statusProdcutionFertilizerClass:'fas fa-minus-circle'});
		// 	if (dataRow.data.production_create.status=='finalizado') {
		// 		this.setState({statusProdcutionFertilizerColor:'#58d999'});
		// 	}else{
		// 		this.setState({statusProdcutionFertilizerColor:'#f8d014'});
		// 	}
		// }
		//////////////////////////////////////////////////////////////////////////////////////////
		// if(dataRow.data.create_cosecha==null){
		// 	this.setState({statusCosechaClass:'fas fa-minus-circle'});
		// 	this.setState({statusCosechaColor:'#7A7A7A'});
		// 	this.setState({create_cosecha:null});
		// }else{
		// 	if (dataRow.data.transport_cosecha[0]!=null) {
		// 		 	if (dataRow.data.create_cosecha.status=='finalizado') {
		//  				this.setState({statusCosechaClass:'fas fa-check-circle'});
		//  				this.setState({statusCosechaColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.create_cosecha.status=='desarrollo') {
		//  				this.setState({statusCosechaClass:'fas fa-minus-circle'});
		//  				this.setState({statusCosechaColor:'#f8d014'});
		// 			}
		// 	}else{
		// 			if (dataRow.data.create_cosecha.status=='finalizado') {
		//  				this.setState({statusCosechaClass:'fas fa-minus-circle'});
		//  				this.setState({statusCosechaColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.create_cosecha.status=='desarrollo') {
		//  				this.setState({statusCosechaClass:'fas fa-minus-circle'});
		//  				this.setState({statusCosechaColor:'#f8d014'});
		// 			}
		// 	}
		// 	this.setState({create_cosecha:dataRow.data.create_cosecha});
		// }
		// if(dataRow.data.transport_cosecha[0]==null){
		// 	this.setState({transport_cosecha:null});
		// }else{
		// 	this.setState({transport_cosecha:dataRow.data.transport_cosecha});
		// }

		// if(dataRow.data.logistics_transport_shipment==null){
		// 	this.setState({statusLoisticClass:'fas fa-minus-circle'});
		// 	this.setState({statusLogisticColor:'#7A7A7A'});
		// 	this.setState({logistics_transport_shipment:null});
		// }else{
		// 	if (dataRow.data.logistics_transport_transporte!=null) {
		// 		 	if (dataRow.data.logistics_transport_shipment.status=='finalizado') {
		//  				this.setState({statusLoisticClass:'fas fa-check-circle'});
		//  				this.setState({statusLogisticColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.logistics_transport_shipment.status=='desarrollo') {
		//  				this.setState({statusLoisticClass:'fas fa-minus-circle'});
		//  				this.setState({statusLogisticColor:'#f8d014'});
		// 			}
		// 	}else{
		// 			if (dataRow.data.logistics_transport_shipment.status=='finalizado') {
		//  				this.setState({statusLoisticClass:'fas fa-minus-circle'});
		//  				this.setState({statusLogisticColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.logistics_transport_shipment.status=='desarrollo') {
		//  				this.setState({statusLoisticClass:'fas fa-minus-circle'});
		//  				this.setState({statusLogisticColor:'#f8d014'});
		// 			}
		// 	}

		// 	this.setState({logistics_transport_shipment:dataRow.data.logistics_transport_shipment});
		// }
		// if(dataRow.data.logistics_transport_transporte==null){
		// 	this.setState({logistics_transport_transporte:null});
		// }else{
		// 	this.setState({logistics_transport_transporte:dataRow.data.logistics_transport_transporte});
		// }
		// if(dataRow.data.transport_vehicule_cosecha[0]==null){
		// 	this.setState({transport_vehicule_cosecha:null});
		// }else{
		// 	this.setState({transport_vehicule_cosecha:dataRow.data.transport_vehicule_cosecha});
		// }
		// //console.log(dataRow.data.transport_vehicule_cosecha[0])
		// if(dataRow.data.commercialization_market==null){
		// 	this.setState({statusMarketClass:'fas fa-minus-circle'});
		// 	this.setState({statusMarketColor:'#7A7A7A'});
		// 	this.setState({commercialization_market:null});
		// }else{
		// 	if (dataRow.data.commercialization_export!=null) {
		// 		 	if (dataRow.data.commercialization_market.status=='finalizado') {
		//  				this.setState({statusMarketClass:'fas fa-check-circle'});
		//  				this.setState({statusMarketColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.commercialization_market.status=='desarrollo') {
		//  				this.setState({statusMarketClass:'fas fa-minus-circle'});
		//  				this.setState({statusMarketColor:'#f8d014'});
		// 			}
		// 	}else{
		// 			if (dataRow.data.commercialization_market.status=='finalizado') {
		//  				this.setState({statusMarketClass:'fas fa-minus-circle'});
		//  				this.setState({statusMarketColor:'#58d999'});
		// 		 	}
		// 		 	if (dataRow.data.commercialization_market.status=='desarrollo') {
		//  				this.setState({statusMarketClass:'fas fa-minus-circle'});
		//  				this.setState({statusMarketColor:'#f8d014'});
		// 			}
		// 	}
		// 	this.setState({commercialization_market:dataRow.data.commercialization_market});
		// }
		// if(dataRow.data.commercialization_export==null){
		// 	this.setState({commercialization_export:null});
		// }else{
		// 	this.setState({commercialization_export:dataRow.data.commercialization_export});
		// }
		// var data_row_plant = await get_data_row_plant(data)
		// if(data_row_plant.data.create_plant_cold==null){
		// 	this.setState({create_plant_cold:null});
		// }else{
		// 	this.setState({create_plant_cold:data_row_plant.data.create_plant_cold});
		// }

		// if (data_row_plant.data.create_plant_information_empaque==null) {
		// 	this.setState({create_plant_information_empaque:null});
		// }else{
		// 	this.setState({create_plant_information_empaque:data_row_plant.data.create_plant_information_empaque});
		// }
		// if(data_row_plant.data.create_plant_pre_cold==null){
		// 	this.setState({create_plant_pre_cold:null});
		// }else{
		// 	this.setState({create_plant_pre_cold:data_row_plant.data.create_plant_pre_cold});
		// }
		// if(data_row_plant.data.create_plant_product[0]==undefined){
		// 	this.setState({create_plant_product:null});
		// }else{
		// 	this.setState({create_plant_product:data_row_plant.data.create_plant_product});
		// }

		// this.setState({create_plant_sample:data_row_plant.data.create_plant_sample});
		// this.setState({create_plant_treatment:data_row_plant.data.create_plant_treatment});
		// this.setState({create_plant_weight_product:data_row_plant.data.create_plant_weight_product});
		
		// ////////////////////////////////////////STATUS OPTIONS PLANT//////////////////////////////////////////////////
		// if (data_row_plant.data.create_plant_product[0]==null) {
		// 	this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 	this.setState({statusPlantColor:'#7A7A7A'});
		// }else{
		// 			if ( data_row_plant.data.create_plant_information_empaque!=null && data_row_plant.data.create_plant_pre_cold!=null && data_row_plant.data.create_plant_cold!=null ) {
		// 				if (data_row_plant.data.create_plant_product[0].status=='finalizado') {
		// 					this.setState({statusPlantClass:'fas fa-check-circle'});
		// 					this.setState({statusPlantColor:'#58d999'});
		// 				}
		// 				if (data_row_plant.data.create_plant_product[0].status=='desarrollo') {
		// 					this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 					this.setState({statusPlantColor:'#f8d014'});
		// 				}
		// 			}else{
		// 					this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 				if (data_row_plant.data.create_plant_product[0].status=='finalizado') {
		// 					this.setState({statusPlantColor:'#58d999'});
		// 				}
		// 				if (data_row_plant.data.create_plant_product[0].status=='desarrollo') {
		// 					this.setState({statusPlantColor:'#f8d014'});
		// 				}
		// 			}
		// }

		// if ((data_row_plant.data.create_plant_product[0]!=null && data_row_plant.data.create_plant_information_empaque==null) || (data_row_plant.data.create_plant_product[0]==null && data_row_plant.data.create_plant_information_empaque!=null)) {
		// 	this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 	if (data_row_plant.data.create_plant_product[0].status=='finalizado') {
		// 		this.setState({statusPlantColor:'#58d999'});
		// 	}
		// 	if (data_row_plant.data.create_plant_product[0].status=='desarrollo') {
		// 		this.setState({statusPlantColor:'#f8d014'});
		// 	}
		// }
		// if ((data_row_plant.data.create_plant_product[0]!=null && data_row_plant.data.create_plant_pre_cold==null) || (data_row_plant.data.create_plant_product[0]==null && data_row_plant.data.create_plant_pre_cold!=null)) {
		// 	this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 	if (data_row_plant.data.create_plant_product[0].status=='finalizado') {
		// 		this.setState({statusPlantColor:'#58d999'});
		// 	}
		// 	if (data_row_plant.data.create_plant_product[0].status=='desarrollo') {
		// 		this.setState({statusPlantColor:'#f8d014'});
		// 	}
		// }
		// if ((data_row_plant.data.create_plant_product[0]!=null && data_row_plant.data.create_plant_cold==null) || (data_row_plant.data.create_plant_product[0]==null && data_row_plant.data.create_plant_cold!=null)) {
		// 	this.setState({statusPlantClass:'fas fa-minus-circle'});
		// 	if (data_row_plant.data.create_plant_product[0].status=='finalizado') {
		// 		this.setState({statusPlantColor:'#58d999'});
		// 	}
		// 	if (data_row_plant.data.create_plant_product[0].status=='desarrollo') {
		// 		this.setState({statusPlantColor:'#f8d014'});
		// 	}
		// }
		//////////////////////////////////////////////////////////////////////////////////////////
		/*this.setState({
			DataRowProdcutionCreate:dataRow.data.production_create,
			DataRowProdcutionFertilizer:dataRow.data.production_fertilizer,
			commercialization_export:dataRow.data.commercialization_export,
			commercialization_market:dataRow.data.commercialization_market,
			create_cosecha:dataRow.data.create_cosecha,
			create_plant_cold:dataRow.data.create_plant_cold,
			create_plant_information_empaque:dataRow.data.create_plant_information_empaque,
			create_plant_pre_cold:dataRow.data.create_plant_pre_cold,
			create_plant_product:dataRow.data.create_plant_product,
			create_plant_sample:dataRow.data.create_plant_sample,
			create_plant_treatment:dataRow.data.create_plant_treatment,
			create_plant_weight_product:dataRow.data.create_plant_weight_product,
			logistics_transport_shipment:dataRow.data.logistics_transport_shipment,
			logistics_transport_transporte:dataRow.data.logistics_transport_transporte,
			transport_cosecha:dataRow.data.transport_cosecha,
			fruit_vegetable:dataRow.data.fruit_vegetable,
			transport_vehicule_cosecha:dataRow.data.transport_vehicule_cosecha,
		});*/
		}
		pushSection = (components) => {
			this.setState({components:components})
			
		}
	render(){
  return (
    <>
      <div className="main-content pt-1" style={{backgroundColor: 'white'}}>
          	<Col md="10" style={{margin: 'auto'}}>
              <Card>
                <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                  <h3 className="mb-0" style={{color:'#ffffff'}}>Trazabilidad Frutas y Vegetales</h3>
                  <Col md={1}>
             	  	<GuideMessage section="fuits_vegetable" section_two="Trace" section_table_form="null" />
                  </Col>
                </CardHeader>
                <CardBody>
                <Row>
                	<Col md={11}>
                	<Row>
                		<h1 style={{margin:0}}>{this.state.code_traza}</h1>
                		<p style={{margin:0, paddingLeft: '8px',fontSize: '13px',fontWeight: 600}}>{(this.state.data_user==null)?(null):(' '+this.state.data_user.name+', '+this.state.data_user.email)}<br /> {(this.state.data_user==null)?(null):(' '+this.state.data_user.pais)}</p>
                	</Row>
                	</Col>
                	<Col md={1} style={{padding:0}}>
                	{(this.state.data_user==null)?(null):(<img alt={String(this.state.data_user.pais).toLocaleLowerCase()+" icon"} srcset={"https://img.icons8.com/office/2x/"+String(this.state.data_user.pais).toLocaleLowerCase()+".png 2x"} />)}
                	</Col>
                </Row>
                </CardBody>
              </Card>
            </Col>
		<section className="elementor-section elementor-top-section elementor-element elementor-element-ddc63bc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddc63bc" data-element_type="section">
		  <div className="elementor-container elementor-column-gap-default">
		    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70ec96d" data-id="70ec96d" data-element_type="column">
		      <div className="elementor-widget-wrap">
		  	  </div>
		    </div>
		  </div>
	    </section>
    			<section style={{backgroundColor: 'white'}} className="elementor-section elementor-top-section elementor-element elementor-element-9f9c7a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f9c7a0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"><div className="elementor-container elementor-column-gap-extended">
          		<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4d21c55" data-id="4d21c55" data-element_type="column">
      			<div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-1a59632 elementor-widget elementor-widget-eael-adv-tabs" data-id="1a59632" data-element_type="widget" data-widget_type="eael-adv-tabs.default">
        		<div className="elementor-widget-container">
              	<div id="eael-advance-tabs-1a59632" className="eael-advance-tabs eael-tabs-horizontal" data-tabid="1a59632">
            	<div className="eael-tabs-nav">
                <ul className="eael-tab-top-icon">
                <Col id="vegefru" aria-selected="false" data-tab="1" role="tab" aria-controls="vegefru-tab" aria-expanded="false" className='text-center'>
{/*	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionTraza)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-carrot" ></i>
	                </li>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionTraza)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-carrot" ></i>
	                </li>
{/*	                <Col>
	                	<i style={{fontSize: '24px',color: (this.state.statusColor['fruit_vegetable']==undefined)?('#7A7A7A'):(this.state.statusColor['fruit_vegetable'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <span className="eael-tab-title title-after-icon">Trazabilidad</span>
                </Col>

                <Col id="produccion" aria-selected="false" data-tab="2" role="tab"  aria-controls="produccion-tab" aria-expanded="false" className='text-center'>
{/*	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionProduction)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-seedling"></i>                                                            
	                </li>

	                <Col>
	                <i style={{fontSize: '24px',color: (this.state.statusColor['production_fertilizer']==undefined)?('#7A7A7A'):(this.state.statusColor['production_fertilizer'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionProduction)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-seedling" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Producción</span>
                </Col>

                <Col id="cosecha" aria-selected="false" data-tab="3" role="tab"  aria-controls="cosecha-tab" aria-expanded="false" className='text-center'>
{/*	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionCosecha)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-apple-alt"></i>                                                            
	                </li>
	                <Col>
	                	<i style={{fontSize: '24px',color: (this.state.statusColor['create_cosecha']==undefined)?('#7A7A7A'):(this.state.statusColor['create_cosecha'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionCosecha)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-apple-alt" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Cosecha</span>
                </Col>

{/*             <li id="transporte" onClick={()=>{this.pushSection(SectionTransport)}} className="inactive eael-tab-item-trigger" aria-selected="false" data-tab="4" role="tab"  aria-controls="transporte-tab" aria-expanded="false">
                <i style={{color: '#204d74'}} className="fas fa-truck"></i>                                                            
                <span className="eael-tab-title title-after-icon">Transporte</span>
                </li>*/}

                <Col id="planta" aria-selected="false" data-tab="5" role="tab"  aria-controls="planta-tab" aria-expanded="false" className='text-center'>
{/*                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionPlanta)}} className="eael-tab-item-trigger">
                	<i style={{color: '#204d74'}} className="fas fa-boxes"></i>                                                            
                </li>
                	<Col>
                		<i style={{fontSize: '24px',color: (this.state.statusColor['create_plant_product']==undefined)?('#7A7A7A'):(this.state.statusColor['create_plant_product'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionPlanta)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-boxes" ></i>
	                </li>
                	<span className="eael-tab-title title-after-icon">Planta</span>
                </Col>

                <Col id="logistica" aria-selected="false" data-tab="6" role="tab"  aria-controls="logistica-tab" aria-expanded="false" className='text-center'>
{/*					<li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionEmbarque)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-network-wired"></i>                                                            
	                </li>
	                <Col>
	                	<i style={{fontSize: '24px',color: (this.state.statusColor['logistics_transport_shipment']==undefined)?('#7A7A7A'):(this.state.statusColor['logistics_transport_shipment'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionEmbarque)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-network-wired" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Logistica</span>
                </Col>

                <Col id="documentos" aria-selected="false" data-tab="7" role="tab"  aria-controls="documentos-tab" aria-expanded="false" className='text-center'>
{/*	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-clipboard-check"></i>
					</li>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionDocuments)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-clipboard-check" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Documentos</span>
                </Col>

                <Col id="comercializacion" aria-selected="false" data-tab="8" role="tab"  aria-controls="comercializacion-tab" aria-expanded="false" className='text-center'>
{/*	                <li style={{width: 'auto'}} onClick={()=>{this.pushSection(SectionCommerce)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-handshake"></i>                                                            
	                </li>
	               	<Col>
	               		<i style={{fontSize: '24px',color: (this.state.statusColor['commercialization_market']==undefined)?('#7A7A7A'):(this.state.statusColor['commercialization_market'])}} className={(this.state.statusClass['create_cosecha']==undefined)?('fas fa-minus-circle'):(this.state.statusClass['create_cosecha'])}></i>
	                </Col>*/}
	                <li style={{width: '50px',height: '50px',borderRadius: '50px',margin: 'auto',backgroundColor: "#f8d01400"}} 
	                onClick={()=>{this.pushSection(SectionCommerce)}} className="eael-tab-item-trigger">
	                <i style={{color: '#204d74'}} className="fas fa-handshake" ></i>
	                </li>
	                <span className="eael-tab-title title-after-icon">Comercialización</span>
                </Col>


{/*                <li id="consumidor" onClick={()=>{this.pushSection(SectionTraza)}} className="inactive eael-tab-item-trigger" aria-selected="false" data-tab="9" role="tab"  aria-controls="consumidor-tab" aria-expanded="false">
                <i style={{color: '#204d74'}} className="fas fa-shopping-cart"></i>                                                            
                <span className="eael-tab-title title-after-icon">Consumidor</span>
                </li>*/}
                </ul>
                </div>
                <hr />
            {(this.state.allDataTrza==null)?('Contenido.'):(< this.state.components DataRow={this.state} />)}
               	{/*<SectionTraza DataRow={this.state} />
            	<SectionProduction DataRow={this.state} />
            	<SectionCosecha DataRow={this.state}/>
                <SectionTransport DataRow={this.state}/>
                <SectionPlanta DataRow={this.state}/>
                <SectionEmbarque DataRow={this.state}/>
                <SectionDocuments DataRow={this.state}/>
                <SectionCommerce DataRow={this.state}/>

            
            
                    <div id="consumidor-tab" className="clearfix eael-tab-content-item inactive" data-title-link="consumidor-tab">
                                  <h4 ><a href="http://www.mercadeotrazable.com"><span >Mercadeo Trazable</span></a></h4><p><a href="http://www.mercadeotrazable.com"><img loading="lazy" className="size-medium wp-image-5636 aligncenter" src="https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-300x127.jpg" alt="" width="300" height="127" srcset="https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-300x127.jpg 300w, https://www.food-chain.site/wp-content/uploads/2021/09/mappanama-768x324.jpg 768w, https://www.food-chain.site/wp-content/uploads/2021/09/mappanama.jpg 1004w" sizes="(max-width: 300px) 100vw, 300px" /></a></p>                                   
                                  </div>
               */}
        </div>
    </div>
        </div>
          </div>
    </div>
              </div>
    </section>

    </div>
    </>
  );
 }
}

