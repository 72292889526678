import { Container, Row, Col } from "reactstrap";

function AdminFooter() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © Foodchain Trace 2019 - {new Date().getFullYear()}.{" "}
                Todos los derechos reservados.
              </div>
            </Col>
            <Col lg="6">
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
