import React, {useState} from 'react';
import {
    Navbar,
    NavbarBrand,
    NavLink,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
  import { NavLink as ComponentLink } from "react-router-dom";
  //import { get_permission } from "network/ApiAxios";
  import {UserContext} from "layouts/store";
  import jQuery from "jquery";
  class VegetablesNavBar extends React.Component {
    static contextType = UserContext
    state ={
      modal:false,
      color:'transparent',
      trazabilidad:'none',
      produccion:'none',
      cosecha:'none',
      planta:'none',
      logistica:'none',
      documento:'none',
      comercializa:'none',
      trazabilidad_end:'none',
      company_id:false,
      setinfoState:false
    }
   async componentDidMount(){
    jQuery(".documentFormAll").hide()
    jQuery("#insertDocumentShow").val('none')
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.getParams).get("company");
    var idcompany = null;
    // if (jsondata.userType==1) {
    //   var dataPermission = await get_permission(jsondata._id,null)
    // }
    if (jsondata.userType==2) {
      if (gerParam!=null) {
        this.setState({company_id:true})
        idcompany = gerParam
      }
    }
    //await get_permission(jsondata._id,idcompany)
    this.setinfoInit()
    }
    componentDidUpdate(){
      this.setinfoInit()
    }    
  setinfoInit = () =>{
    if (this.state.setinfoState) {
    return;  
    }
    var getPermission = this.context.valueGlobal
    if (getPermission.user_id_connection==null) {
      jQuery("#insertIdUser").val(getPermission.user_id)
    }else{
      jQuery("#insertIdUser").val(getPermission.user_id_connection)
    }
      if (getPermission.role==1) {
        jQuery("#insertDocumentShow").val('block')
        jQuery(".documentFormAll").show()
            this.setState({
              trazabilidad:'block',
              produccion:'block',
              cosecha:'block',
              planta:'block',
              logistica:'block',
              documento:'block',
              comercializa:'block',
              trazabilidad_end:'block',
            })
      }else{
        if (getPermission.type=='options') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad:(getPermission.trazabilidad==0)?'none':'block',
              produccion:(getPermission.produccion==0)?'none':'block',
              cosecha:(getPermission.cosecha==0)?'none':'block',
              planta:(getPermission.planta==0)?'none':'block',
              logistica:(getPermission.logistica==0)?'none':'block',
              documento:(getPermission.documento==0)?'none':'block',
              comercializa:(getPermission.comercializa==0)?'none':'block',
              trazabilidad_end:'none',
            })
        }
        if (getPermission.type=='admin') {
          if (getPermission.documento==0) {
            jQuery("#insertDocumentShow").val('none')
            jQuery(".documentFormAll").remove()
          }else{
            jQuery("#insertDocumentShow").val('block')
            jQuery(".documentFormAll").show()
          }
            this.setState({
              trazabilidad:'block',
              produccion:'block',
              cosecha:'block',
              planta:'block',
              logistica:'block',
              documento:'block',
              comercializa:'block',
              trazabilidad_end:'none',
            })
        }

      }
        if (this.context.valueGlobal.length!=0) {
        this.setState({setinfoState:true})
        }
      }

    render(){
    const openToggler = (valOpen) =>{
      if (this.state.modal==true) {
       this.setState({modal:false,color:'transparent'})
      }else{
       this.setState({modal:true,color:'#2d597f'})
      }
    }
    return (
      <>
      <input type="hidden" id="insertIdUser" />
      <input type="hidden" id="insertDocumentShow" />
        <div>

          <Navbar style={{backgroundColor:'#7AC142', height: '50px'}} expand="lg" light>
            {/*<NavbarBrand href="/" style={{display:this.state., color:'white', fontWeight:'bold'}}>Vegetales y frutas</NavbarBrand>*/}
            <NavbarToggler onClick={() => {openToggler(true)}} />
            <Collapse isOpen={this.state.modal} navbar style={{zIndex: '10', backgroundColor:this.state.color}}>
              <Nav className="me-auto" navbar style={{fontSize: '14px'}}>
                <NavItem nav="true" style={{display: this.state.trazabilidad, padding: '0.25rem 0.75rem'}}>
                    <ComponentLink
                      style={{color:'white', fontWeight:'bold'}}
                      to={"/admin/start-trace/vegetables-fruits"+this.props.getParams}
                    >
                      Trazabilidad
                    </ComponentLink>
                </NavItem>
                {

                  (this.props.getParams=="")
                  ?
                  (null)
                  :
                  (<>
                    <UncontrolledDropdown style={{display: this.state.produccion}} inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Producción
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/production"+this.props.getParams}>
                                                            Ingresar Datos
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/fertilizer"+this.props.getParams}>
                                                            Agroquímicos
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                  
                                                  <UncontrolledDropdown style={{display: this.state.cosecha}} inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Cosecha
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/harvest"+this.props.getParams}>
                                                            Ingresar Datos
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/FieldTransport"+this.props.getParams}>
                                                            Transporte Campo
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                  
                                                  <UncontrolledDropdown style={{display: this.state.planta}} inNavbar nav>
                                                    <DropdownToggle caret nav style={{color:'white', fontWeight:'bold'}}>
                                                      Planta
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/plant"+this.props.getParams}>
                                                            Ingresar Datos
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/packaging"+this.props.getParams}>
                                                            Empaque
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                      <DropdownItem>
                                                        <div style={{ paddingLeft: "1vw" }}>
                                                          <ComponentLink to={"/admin/fruits-vegetables/Freezing"+this.props.getParams}>
                                                            Camara de Frio
                                                          </ComponentLink>
                                                        </div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                  <NavLink style={{display: this.state.logistica}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/fruits-vegetables/logistics"+this.props.getParams}
                                                    >
                                                      Logistica y Transporte
                                                    </ComponentLink>
                                                  </NavLink>
                                                  <NavLink style={{display: this.state.documento}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/docs"+this.props.getParams}
                                                    >
                                                      Documentos
                                                    </ComponentLink>
                                                  </NavLink>
                                                  <NavLink style={{display: this.state.comercializa}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/fruits-vegetables/Comercialization"+this.props.getParams}
                                                    >
                                                      Comercialización
                                                    </ComponentLink>
                                                  </NavLink>
                                                  <NavLink style={{display: this.state.trazabilidad_end}}>
                                                    <ComponentLink
                                                      style={{color:'white', fontWeight:'bold' }}
                                                      to={"/admin/fruits-vegetables/finalice-traza"+this.props.getParams}
                                                    >
                                                      Finalizar trazabilidad
                                                    </ComponentLink>
                                                  </NavLink>
                                                  </>
                                                  )
}
              </Nav>
            </Collapse>
          </Navbar>
        </div>        
      </>
    );
   }
  }
  
  export default VegetablesNavBar;
  